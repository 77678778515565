import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import ReactNotification from 'react-notifications-component';
import Form from "react-validation/build/form";
import DatePicker from "react-datepicker";
import ReportService from "../../../services/Report/ReportService";
import AddQCReportTab from './AddQCReportTab';
import QcInspection from '../../../services/QCInspection/QcInspection';
import AddQCStyleItemTab from './AddQCStyleItemTab';
import AddQcNotes from './AddQcNotes';
import AddEditPPMeeting from './AddEditPPMeeting';
import AddQCMeasurement from './AddQCMeasurement';
import Nodify from "../../Common/ReactNotification";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { each } from 'jquery';
import ListPreviousReports from './ListPreviousReports';
import SweetAlert from 'react-bootstrap-sweetalert';
const AddQCinternalinspection = (props) => {

    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getMainInputField, setMainInputField] = useState({ FactoryLocationID: 0, FactoryMerchID: '', FactoryMerchForEdit: '', ReportDate: '', CheckedAreaID: 0 })
    const [getExFactoryList, setExFactoryList] = useState([])
    const [FactoryDroplist, setFactoryDroplist] = useState([])
    const [CheckedAreaDroplist, setCheckedAreaDroplist] = useState([])
    const [getnewimagevalue, setnewimagevalue] = useState([]);
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [rerenderval, setrerenderval] = useState(0);
    const [tabName, setTabName] = useState("Report");
    // Quantity State
    const [addStyleConfirmation, setAddStyleConfirmation] = useState({ showPopupConfirm: false, Params: {} });
    const [getQuantityInfoList, setQuantityInfoList] = useState([])
    const [getCommentInfoList, setCommentInfoList] = useState([])
    const [getReportFileUploadList, setReportFileUploadList] = useState([])
    const [getQcConclusionList, setQcConclusionList] = useState()
    const [getIsvalidationReport, setIsvalidationReport] = useState(false)
    //Style Item State
    const [getStrikeoffList, setStrikeoffList] = useState([])
    const [getTrimList, setTrimList] = useState([])
    const [IsvalidationStyleItem, setIsvalidationStyleItem] = useState(false)
    const [IsvalidationQcDp, setIsvalidationQcDp] = useState(false)
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [refershstate, setrefershstate] = useState(0)
    //PPmeeting
    const [getPPQuantityInfoList, setPPQuantityInfoList] = useState([])
    const [getPPDropdownList, setPPDropdownList] = useState({})
    //Measurement
    const [getMeasurementFileList, setMeasurementFileList] = useState();
    const [IsSaveValidation, setIsSaveValidation] = useState(false);
    //end
    const [getPPTrimList, setPPTrimList] = useState([])
    const [getPPWorkManshipList, setPPWorkManshipList] = useState([])
    const [getPPIsvalidation, setPPIsvalidation] = useState(false)
    const [getPPIsvalidationIn, setPPIsvalidationIn] = useState(false)
    const values = { ...getMainInputField }
    const { user: currentUser } = useSelector((state) => state.auth);
    const QcinpsectionId = JSON.parse(localStorage.getItem("QCInspectionID"));

    useEffect(() => {
        showLoader();
        (async () => {

            //setTabName("Report")
            let Exfaclist = [];
            let Inputfilds = [];
            if (props.location.state.params.TaskStatus) {

                let FactoryMerchList = [];
                let CheckedAreaList = [];
                await QcInspection.getCommonResultList(1, props.location.state.params.details.supplierId).then((res) => {
                    if (res.data.checkedAreaList) {
                        res.data.checkedAreaList.map((element) => {
                            CheckedAreaList.push({
                                value: element.checkResultId,
                                label: element.checkResultName
                            })
                        })
                    }
                    if (res.data.factoryMerchList) {
                        res.data.factoryMerchList.map((element) => {
                            FactoryMerchList.push({
                                value: element.employeeID,
                                label: element.employeeName
                            })
                        })
                    }
                    setCheckedAreaDroplist(CheckedAreaList);
                    setFactoryDroplist(FactoryMerchList);
                    hideLoader();
                })
                await ReportService.GetCommentsReportExFactory(props.location.state.params.details.supplierId, 1, 0).then((res) => {
                    if (res.data) {
                        res.data.exFacCommon.map((x, index) => {
                            Exfaclist.push({
                                value: x.value,
                                label: x.label
                            })
                        })
                        setExFactoryList(Exfaclist);
                    }
                })
                if (QcinpsectionId !== null || props.location.state.params.TNAQCInspectionID !== undefined) {
                    await QcInspection.getAllQcInfoList(1, props.location.state.params.details.tnaid, props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID).then((res) => {
                        if (res.data.qCinspectionMainDetails.length > 0) {
                            res.data.qCinspectionMainDetails.map((x) => {
                                values.FactoryLocationID = x.factoryLocationID;
                                values.FactoryMerchID = x.factoryMerchID;
                                values.ReportDate = new Date(x.reportDate);
                                values.FactoryMerchForEdit = FactoryMerchList.filter((data) => {
                                    if (x.factoryMerchID.split(",").includes(data.value.toString())) {
                                        return data;
                                    }
                                })
                                // let arr = x.factoryMerchID.split(',');
                                // let arrVal = [];
                                // for (let size of arr) {
                                //     QcInspection.getCommonResultList(1, props.location.state.params.details.supplierId).then((res) => {
                                //         if (res.data) {
                                //             let sendtoId = res.data.filter(x => x.value === parseInt(size))
                                //             arrVal.push({
                                //                 value: sendtoId[0].value,
                                //                 label: sendtoId[0].label
                                //             })
                                //             values.FactoryMerchForEdit = arrVal;
                                //             //setInputFields(values)
                                //         }
                                //         // setInputFields(values)
                                //     }).catch(() => { });

                                // }
                                values.CheckedAreaID = x.checkedAreaID;
                            })
                            setMainInputField(values);

                        }

                        if (res.data.qCinspectionSkuDetails.length > 0) {
                            let newvalueSku = [];
                            res.data.qCinspectionSkuDetails.map((x) => {
                                newvalueSku.push({
                                    TNAQCInspectionSkuID: x.tnaqcInspectionSkuID,
                                    skuName: x.skuFileName,
                                    skuFrontPicPath: x.skuFilePath
                                })
                            })
                            setnewimagevalue(newvalueSku)
                        }
                    })
                }
            }



            if (QcinpsectionId === null && props.location.state.params.TaskStatus === 1) {
                let Multiselecteddate = [];
                Multiselecteddate = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1)
                setCurrimageindex(0);
                var uniqueimages = [...new Map(Multiselecteddate.map(item =>
                    [item['skuName'], item])).values()];
                setnewimagevalue(uniqueimages)
            }
        })();
    }, [refershstate])


    function ResetOperation(e) {

        if (e === "Back") {
            if (props.location.state.params.ParentTask === "TNAEdit") {
                props.history.push('/TNAEdit', { params: props.location.state.params.details });
            }
            else if (props.location.state.params.ParentTask === 'TNAView') {
                //localStorage.removeItem("InspectionID");
                props.history.push('/TNAView', { params: props.location.state.params.details });
                // setRefreash(true)
            }
            else if (props.location.state.params.ParentTask === 'TNAReport') {
                props.history.push('/TNAReport')
            } else if (props.location.state.params.ParentTask === 'Dashboard') {
                props.history.push('/Dashboard')
            } else {
                props.history.push('/InternalQAAuditMain', { params: props.location.state.params });
            }
            localStorage.removeItem("QCInspectionID");
        } else {
            window.location.reload();
        }
    }

    function TabClick(Tab) {
        setTabName(Tab)
    }


    const previmage = () => {
        if (getCurrimageindex === 0) {
            setCurrimageindex(0);
        }
        else {
            setCurrimageindex(getCurrimageindex - 1);
        }
    }

    const nextimage = () => {
        if (getCurrimageindex === getnewimagevalue.length - 1) {
            setCurrimageindex(getnewimagevalue.length - 1);
        }
        else {
            setCurrimageindex(getCurrimageindex + 1);
        }
    }
    function SaveCompany(e) {
        ;
        setButtonLoader(true);
        let isvalidstr = false;
        let Allisvalid = true;
        let sizeandqtyfordisplay = "";
        let isvalid = false
        let SkuImageInfo = []
        let Qualityinfo = []
        let commentInfo = []
        let commentSubInfo = []
        let Fileuploadinfo = []
        let StrikeoffInfo = []
        let StrikeoffSubInfo = []
        let StrikeoffImages = []
        let TrimInfo = []
        let TrimSubInfo = []
        let TrimImages = []
        //PP Meeting
        var PPQualityInfo = [];
        var PPTrimVal = [];
        var PPWorkmanship = [];
        //Measuremnt
        let MeasurmentFiles = []
        let MeasurmentCategory = []
        let MeasurmentPoints = []

        if (props.location.state.params.TaskType !== "PP Meeting") {

            if (tabName === "Report") {
                if (values.FactoryLocationID === 0 || values.FactoryMerchID === 0 || values.ReportDate === '' || (props.location.state.params.TaskType === "In and Mid Line Inspection" && values.CheckedAreaID === 0)) {
                    setSubmitted(true)
                    Allisvalid = false;
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields');
                }


                else if (getQuantityInfoList) {
                    for (var i = 0; i < getQuantityInfoList.length; i++) {

                        if (getQuantityInfoList[i].prodqty === undefined || getQuantityInfoList[i].prodqty === '' || getQuantityInfoList[i].prodqty === null) {
                            setIsvalidationReport(true)
                            Allisvalid = false; setButtonLoader(false);
                            Nodify('Warning!', 'warning', 'Please fill the mandatory * fields in Quantity Information');
                        } else if (getQuantityInfoList[i].prodqty === 0) {
                            setIsvalidationReport(true)
                            Allisvalid = false; setButtonLoader(false);
                            Nodify('Warning!', 'warning', 'Please enter digit greater than 0 in Quantity information');
                        }
                    }
                }
                if (getCommentInfoList.length > 0) {
                    for (var i = 0; i < getCommentInfoList.length; i++) {
                        if (getCommentInfoList[i].CommnetsID === 0) {
                            setIsvalidationReport(true)
                            Allisvalid = false;
                            setButtonLoader(false);
                            Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields');
                        }
                    }
                    if (e !== "Drafted") {
                        if (props.location.state.params.TaskType !== "In and Mid Line Inspection") {
                            if (getQcConclusionList.QcDropdownID === 0) {
                                setIsvalidationQcDp(true)
                                Allisvalid = false;
                                setButtonLoader(false);
                                Nodify('Warning!', 'warning', 'Please fill the mandatory * fields');
                            }
                            else if (getQcConclusionList.QcDropdownID === 3 && getQcConclusionList.QARemarks === "") {
                                setIsvalidationQcDp(true)
                                Allisvalid = false;
                                setButtonLoader(false);
                                Nodify('Warning!', 'warning', 'Please fill the mandatory * fields');
                            }
                        }
                    }
                }
            }
            if (e !== "Drafted") {
                if (tabName === "StyleItem") {
                    if (e !== "Drafted") {
                        for (var i = 0; i < getStrikeoffList.length; i++) {
                            if (getStrikeoffList[i].QualityID === 0 || getStrikeoffList[i].PositionID === 0) {
                                isvalid = true
                                Allisvalid = false;
                                isvalidstr = true;
                                setIsvalidationStyleItem(true)
                                //Nodify('Warning!', 'warning', 'Please fill the mandatory * fields');
                            }
                        }
                        for (var i = 0; i < getTrimList.length; i++) {
                            isvalid = true
                            if (getTrimList[i].TrimQualityID === 0 || getTrimList[i].TrimPositionID === 0) {
                                Allisvalid = false;
                                isvalid = true
                                setIsvalidationStyleItem(true)
                                isvalidstr = true;
                                // Nodify('Warning!', 'warning', 'Please fill the mandatory * fields');
                            }
                        }
                        if (isvalidstr) {
                            setButtonLoader(false);
                            Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields in Style Item Tab');
                        }
                    }
                } else if (tabName === 'Measurement') {

                    if (getMeasurementFileList !== undefined && getMeasurementFileList.Measurementdata[0].MeasurementCheckedPeices === '') {
                        Allisvalid = false;
                        setButtonLoader(false);
                        setIsSaveValidation(true);
                        Nodify('Warning!', 'warning', 'Please fill the  mandatory(*) fields in Measurement Information.');
                        return false;
                    }
                    if (getMeasurementFileList !== undefined && getMeasurementFileList.Files.length > 0) {
                        for (var i = 0; i < getMeasurementFileList.Files.length; i++) {
                            if (getMeasurementFileList.Files[i].Result === 0) {
                                setIsSaveValidation(true);
                                Allisvalid = false;
                                setButtonLoader(false);
                                Nodify('Warning!', 'warning', 'Please fill the  mandatory(*) fields in Measurement Information.');
                                return false;
                            }
                            else if (getMeasurementFileList.Files[i].Result === 2 && getMeasurementFileList.Files[i].FindingsRemarks === "") {
                                setIsSaveValidation(true);
                                Allisvalid = false;
                                setButtonLoader(false);
                                Nodify('Warning!', 'warning', 'Please fill the  mandatory(*) fields in Measurement Information.');
                                return false;
                            }
                        }
                    }
                }
            }
        }
        else {
            if (values.FactoryLocationID === 0 || values.FactoryMerchID === 0 || values.ReportDate === '') {
                setSubmitted(false)
                setButtonLoader(false);
                Allisvalid = false;
                Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields');
            }
            else if (getPPDropdownList.TNAQCInspecPPmeetingID === undefined || getPPDropdownList.ParticipantsID === 0) {
                //|| getPPDropdownList.MeetingID === 0
                setPPIsvalidation(true)
                setButtonLoader(false);
                Allisvalid = false;
                Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields');
            } else if (getPPWorkManshipList) {
                for (var i = 0; i < getPPWorkManshipList.length; i++) {
                    if (getPPWorkManshipList[i].WorkmanResultID === 0) {
                        Allisvalid = false;
                        setPPIsvalidationIn(true);
                        setButtonLoader(false);
                        Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields');
                    }
                }
            }

        }

        if (Allisvalid) {
            getnewimagevalue.map(x => {
                SkuImageInfo.push({
                    TNAQCInspectionSkuID: x.TNAQCInspectionSkuID === undefined ? 0 : x.TNAQCInspectionSkuID,
                    TNAID: props.location.state.params.details.tnaid,
                    SkuFileName: x.skuName,
                    SkuFilePath: x.skuFrontPicPath,
                })
            })

            getQuantityInfoList.map((x, imdex) => {
                if (sizeandqtyfordisplay === '') {
                    sizeandqtyfordisplay = x.skuName;
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + x.skuName
                }
                Qualityinfo.push({
                    TNAQCinspcQualityinfoID: x.TNAQCinspcQualityinfoID === undefined ? 0 : x.TNAQCinspcQualityinfoID,
                    TNAID: props.location.state.params.details.tnaid,
                    SkuID: parseInt(x.refInspecSKUID),
                    PurchaseOrderSkuID: parseInt(x.purchaseOrderSkuID),
                    SubTaskID: x.tnaExFactoryId,
                    StyleNo: x.styleNo,
                    SkuName: x.skuName,
                    EndCustomer: x.endCustomer,
                    IDNO: x.idNo,
                    FocusPo: '0',
                    ProductQty: x.prodqty,
                    POQty: x.orderQuantity === undefined ? '' : x.orderQuantity.toString(),
                    IsDeleted: x.IsDeleted,
                    IsInpsectionStatus: props.location.state.params.TaskType === 'PP Meeting' ||
                        props.location.state.params.TaskType === "In and Mid Line Inspection" ? (e === "Drafted" ? 1 : 2) :
                        (getQcConclusionList.QcDropdownID === 1 ? 2 : getQcConclusionList.QcDropdownID === 2 ? 3 : 4),
                    StyleProgramID: x.styleProgramID
                })
            })
            getCommentInfoList.map((x, index) => {
                commentInfo.push({
                    TNAQCInspecCommentID: x.TNAQCInspecCommentID,
                    TNAID: props.location.state.params.details.tnaid,
                    CommentMainIndex: index,
                    CommnetsID: x.CommnetsID,
                    ResultID: x.ResultID,
                    Skunames: sizeandqtyfordisplay,
                    IsDeleted: x.IsDeleted
                })
                x.ImagesList.map((y, yindex) => {
                    commentSubInfo.push({
                        TNAQCInspeCommentsSubID: y.TNAQCInspeCommentsSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        Mainindex: index,
                        UploadedImagePath1: y.UploadedImagePath1,
                        UploadedImagePath2: y.UploadedImagePath2,
                        IsDeleted: y.IsDeleted
                    })
                })

            })
            getReportFileUploadList.map((x, index) => {
                Fileuploadinfo.push({
                    TNAQcInspectionFileupID: x.QCReportFileID,
                    TNAID: props.location.state.params.details.tnaid,
                    QCFileIndex: index,
                    FileType: x.FileType,
                    FileName: x.FileName,
                    Filepath: x.FilePath,
                    IsDeleted: x.IsDeleted
                })
            })

            getStrikeoffList.map((x, i) => {
                StrikeoffInfo.push({
                    TNAQCStrikeoffID: x.TNAQCStrikeoffID,
                    TNAID: props.location.state.params.details.tnaid,
                    Mainindex: i,
                    StrikeOffName: x.StrikeOffName,
                    SkuName: x.SkuName,
                    QualityID: x.QualityID,
                    PositionID: x.PositionID,
                    FindingsRemarks: x.FindingsRemarks
                })

                x.ImagesList.map((y, Subindex) => {
                    StrikeoffSubInfo.push({
                        TNAQCStrikeoffSubID: y.TNAQCStrikeoffSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        Mainindex: i,
                        Subindex: Subindex,
                        ExpectedFilename: y.ExpectedFilename,
                        ExpectedFilepath: y.skuFrontPicPath,
                    })

                    y.StrikeoffImagesList.map((child, childindex) => {
                        StrikeoffImages.push({
                            TNAQcInspecStrikeOffImagesID: child.TNAQcInspecStrikeOffImagesID,
                            TNAID: props.location.state.params.details.tnaid,
                            Mainindex: i,
                            ImageIndex: childindex,
                            Subindex: Subindex,
                            UploadedImagePath: child.UploadedImagePath,
                            IsDeleted: child.IsDeleted
                        })
                    })
                })

            })

            getTrimList.map((x, i) => {
                TrimInfo.push({
                    TNAQCInspecTrimID: x.TNAQCInspecTrimID,
                    TNAID: props.location.state.params.details.tnaid,
                    TrimName: x.TrimName,
                    SkuName: x.SkuName,
                    TrimQualityID: x.TrimQualityID,
                    TrimPositionID: x.TrimPositionID,
                    TrimFindingsRemarks: x.TrimFindingsRemarks,
                    Mainindex: i
                })
                x.ImagesList.map((y, Subindex) => {
                    TrimSubInfo.push({
                        TNAQCInspecTrimSubID: y.TNAQCInspecTrimSubID,
                        TNAID: props.location.state.params.details.tnaid,
                        Mainindex: i,
                        Subindex: Subindex,
                        //TrimFilePath: y.UploadedImagePath,
                        ExpectedFilename: y.ExpectedFilename,
                        ExpectedFilepath: y.skuFrontPicPath
                    })
                    y.TrimimagesList.map((child, childindex) => {
                        TrimImages.push({
                            TNAQcInspecTrimImagesID: child.TNAQCInspecTrimImagesID,
                            TNAID: props.location.state.params.details.tnaid,
                            Mainindex: i,
                            Subindex: Subindex,
                            ImageIndex: childindex,
                            UploadedImagePath: child.UploadedImagePath,
                            IsDeleted: child.IsDeleted
                        })
                    })
                })
            })
            getPPQuantityInfoList.map((x, imdex) => {
                PPQualityInfo.push({
                    TNAQCinspcQualityinfoID: x.TNAQCinspcQualityinfoID === undefined ? 0 : x.TNAQCinspcQualityinfoID,
                    TNAID: props.location.state.params.details.tnaid,
                    SkuID: parseInt(x.refInspecSKUID),
                    PurchaseOrderSkuID: parseInt(x.purchaseOrderSkuID),
                    SubTaskID: x.tnaExFactoryId,
                    StyleNo: x.styleNo,
                    SkuName: x.skuName,
                    EndCustomer: x.endCustomer,
                    IDNO: x.idNo,
                    FocusPo: '0',
                    ProductQty: 0,
                    POQty: x.orderQuantity === undefined ? '' : x.orderQuantity.toString(),
                    IsDeleted: x.IsDeleted
                })
            })
            getPPTrimList.map((x, index) => {
                PPTrimVal.push({
                    TNAQCInspecPPTrimID: x.TNAQcInspecPPTrimID,
                    TNAID: props.location.state.params.details.tnaid,
                    Mainindex: index,
                    TrimName: x.TrimName,
                    SkuName: x.SkuName,
                    TrimFindingsRemarks: x.FindingsRemarks,
                    TrimLogoPath: x.TrimLogopath
                })
            })
            getPPWorkManshipList.map((x, index) => {
                PPWorkmanship.push({
                    TNAQcPPWorkManshipID: x.TNAQCInspecPPworkmanshipID,
                    TNAID: props.location.state.params.details.tnaid,
                    Mainindex: index,
                    WorkmanshipName: x.workmanshipName,
                    WorkmanResultID: x.WorkmanResultID,
                    WorkmanshipNotes: x.WorkmanFindingsRemarks

                })
            })
            if (getMeasurementFileList !== undefined && getMeasurementFileList.Measurementdata.length > 0 &&
                getMeasurementFileList.Measurementdata[0].MeasurementSpec !== '') {

                getMeasurementFileList.Measurementdata.map(md => {
                    md.Category.map(c => {
                        MeasurmentCategory.push({
                            TNAQCinsMeasurementCategoryId: c.TNAQCinsMeasurementCategoryId,
                            StyleMeasurementCategoryID: c.StyleMeasurementCategoryID,
                            MeasurementCategoryID: c.MeasurementCategoryID,
                            CategoryTypeName: c.CategoryTypeName,
                            CategoryIndexName: c.CategoryIndexName.toString(),
                            SkuName: c.SkuName,
                            SizeHeaderNames: '',
                            StyleMeasurementID: c.StyleMeasurementID,
                            CategoryRowNo: c.CategoryRowNo,
                            Remark: c.Remark,
                        })

                        let currcatindex = MeasurmentCategory.length - 1;
                        let headernames = '';
                        c.SizeHeaderNames.map(s => {
                            if (headernames === '') {
                                headernames = s.HeaderName;
                            }
                            else {
                                headernames = headernames + "," + s.HeaderName;
                            }
                        })

                        MeasurmentCategory[currcatindex].SizeHeaderNames = headernames;

                        c.Point.map((point, pindex) => {
                            MeasurmentPoints.push({
                                TNAQCinsMeasurementPointsId: point.TNAQCinsMeasurementPointsId,
                                StyleMeasurementID: point.StyleMeasurementID,
                                MeasurementPoints: point.MeasurementPoints,
                                MeasurementGranding: point.MeasurementGranding,
                                TolerancePlus: point.TolerancePlus,
                                ToleranceMinus: point.ToleranceMinus,
                                GradingSizes: headernames,
                                GradingSizesValue: '',
                                CategoryIndexName: point.CategoryIndexName.toString(),
                                SkuName: point.SkuName,
                            })

                            let currpointindex = MeasurmentPoints.length - 1;
                            let sizevalues = '';
                            let headers = headernames.split(',')
                            headers.map(val => {
                                if (sizevalues === '') {
                                    point.arrGradingSizes[0][val] = (point.arrGradingSizes[0][val] === undefined || point.arrGradingSizes[0][val] === '') ? 0 : point.arrGradingSizes[0][val];
                                    sizevalues = point.arrGradingSizes[0][val];
                                }
                                else {
                                    point.arrGradingSizes[0][val] = (point.arrGradingSizes[0][val] === undefined || point.arrGradingSizes[0][val] === '') ? 0 : point.arrGradingSizes[0][val];
                                    sizevalues = sizevalues + "," + point.arrGradingSizes[0][val];
                                }
                            })

                            MeasurmentPoints[currpointindex].GradingSizesValue = sizevalues
                        })


                    })

                })
            }


            getMeasurementFileList !== undefined && getMeasurementFileList.Files.map((x, index) => {
                MeasurmentFiles.push({
                    TNAQCInsMeasurementFileupID: x.TNAQCInsMeasurementFileupID,
                    TNAID: props.location.state.params.details.tnaid,
                    SkuName: x.SkuName,
                    MeasurementIndex: index,
                    FileType: x.FileType,
                    FileName: x.FileName,
                    Filepath: x.FilePath,
                    Result: x.Result,
                    FindingsRemarks: x.FindingsRemarks,
                    IsDeleted: x.IsDeleted
                })
            })
                ;
            let Inputmaster = {
                CalculationType: (getMeasurementFileList != undefined && getMeasurementFileList.Measurementdata != undefined) ? getMeasurementFileList.Measurementdata[0].CalculationType : 0,
                Operation: props.location.state.params.Mode === 'AddInspection' ? 1 : 2,
                TNAQCInspectionID: props.location.state.params.TaskStatus === 1 && QcinpsectionId === null ? 0 : props.location.state.params.TaskStatus === 2 ? props.location.state.params.TNAQCInspectionID : QcinpsectionId,
                TNAID: props.location.state.params.details.tnaid,
                PurchaseOrderID: props.location.state.params.details.purchaseOrderID,
                BuyerID: props.location.state.params.buyerID,
                SeasonID: props.location.state.params.seasonID,
                BrandID: props.location.state.params.brandID,
                StyleID: props.location.state.params.styleID,
                SupplierID: props.location.state.params.details.supplierId,
                FactoryLocationID: values.FactoryLocationID,
                StyleNameAndNo: props.location.state.params.styleName + '-' + props.location.state.params.styleNo,
                IDPONO: props.location.state.params.details.idPoNos,
                ReportDate: values.ReportDate,
                CheckedAreaID: values.CheckedAreaID,
                FactoryMerchID: values.FactoryMerchID,
                SaveType: e === 'Drafted' ? 1 : 2,
                InspectionType: (props.location.state.params.Mode === 'AddInspection' || props.location.state.params.Mode === "EditInspection") ? 1 : 2,
                QCRemarks: props.location.state.params.TaskType !== "PP Meeting" ? getQcConclusionList.QARemarks : '',
                QCConclusionID: props.location.state.params.TaskType !== "PP Meeting" ? getQcConclusionList.QcDropdownID : 0,
                ParticipantID: getPPDropdownList.ParticipantsID === undefined ? 0 : getPPDropdownList.ParticipantsID,
                ParticipantName: getPPDropdownList.FindingRemarks === undefined ? '' : getPPDropdownList.FindingRemarks,
                TNAQCInspecPPmeetingID: getPPDropdownList.TNAQCInspecPPmeetingID === undefined ? 0 : getPPDropdownList.TNAQCInspecPPmeetingID,
                ScopeNotes: getPPDropdownList.ScopeRemarks === undefined ? '' : getPPDropdownList.ScopeRemarks,
                MeetingDDID: getPPDropdownList.MeetingID,
                Isproduction: props.location.state.params.Isproduction,
                ReportType: props.location.state.params.TaskType,
                QCinspecSKUInfomation: SkuImageInfo,
                QCinspecQualityinfomation: props.location.state.params.TaskType !== "PP Meeting" ? Qualityinfo : PPQualityInfo,
                QCinspecCommentinfomation: commentInfo,
                QCinspecCommentsubinformation: commentSubInfo,
                QCFileuploadinformation: Fileuploadinfo,
                QCinspecStrikeOffInfomation: StrikeoffInfo,
                QCinspecStrikeOffSubfomation: StrikeoffSubInfo,
                QCinspecStrikeOffImageInfomation: StrikeoffImages,
                QCinspecTrimInfomation: TrimInfo,
                QCinspecTrimSubfomation: TrimSubInfo,
                QCinspecTrimImageInfomation: TrimImages,
                QCinspecPPTrimInformation: PPTrimVal,
                QCinspecPPWorkManshipInfomation: PPWorkmanship,
                MeasurementCheckedPeices: getMeasurementFileList !== undefined &&
                    getMeasurementFileList.Measurementdata[0].MeasurementCheckedPeices !== '' ?
                    parseInt(getMeasurementFileList.Measurementdata[0].MeasurementCheckedPeices) : 0,
                TNAQCinsMeasurementMainId: getMeasurementFileList !== undefined ?
                    getMeasurementFileList.Measurementdata[0].TNAQCinsMeasurementMainId : 0,
                StyleMeasurementID: getMeasurementFileList !== undefined ?
                    getMeasurementFileList.Measurementdata[0].StyleMeasurementID : 0,
                MeasurementSpec: getMeasurementFileList !== undefined ?
                    getMeasurementFileList.Measurementdata[0].MeasurementSpec : '',
                QCinspecMeasurementCategoryinformation: MeasurmentCategory,
                QCinspecMeasurementPointsformation: MeasurmentPoints,
                QCinspecMeasurementFilesinformation: MeasurmentFiles,
                CreatedBy: currentUser.employeeinformationID,
                FinalInspectionIds: props.location.state.params.FinalInspectionIds !== undefined ?
                    props.location.state.params.FinalInspectionIds : '',
            }
                ;
            QcInspection.InsertQCInspectionGrid(Inputmaster).then((res) => {
                setButtonLoader(false);
                if (res.data.outputResult === "1") {

                    // Nodify('Success!', 'success', 'QAInspection saved Successfully');
                    Nodify('Success!', 'success', `${props.location.state.params.TaskType} ${e} Successfully`);
                    localStorage.setItem('QCInspectionID', (res.data.outputResultStyleID));
                    let params = { tabPage: "MainInfo", Parentask: props.location.state.params.ParentTask };
                    setAddStyleConfirmation({ showPopupConfirm: true, Params: params });
                } else if (res.data.outputResult === "2") {

                }
                else if (res.data.outputResult === "0") {
                    //setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
            }, (error) => {
                setButtonLoader(false);
            })
            //onsole.log(Inputmaster);
        }
    }


    //Report Tab Callback
    function QuantityCallback(value) {
        setQuantityInfoList(value)
    }
    function commentcallback(value) {
        setCommentInfoList(value)
    }
    function Rerendercallback(value) {

        setrerenderval(value)
    }
    function FileupCallback(value) {
        setReportFileUploadList(value)
    }
    function QcconclusionCallback(value) {
        setQcConclusionList(value)
    }
    //Report Tab callback's over

    //Style tab callbacks
    function StrikeoffCallback(value) {
        setStrikeoffList(value)
    }
    function TrimCallback(value) {
        setTrimList(value)
    }
    //PP MEETING
    function QualityCallback(value) {
        setPPQuantityInfoList(value)
    }
    function DropDownCallback(value) {
        setPPDropdownList(value)
    }
    function PPTrimCallback(value) {
        setPPTrimList(value)
    }
    function PPWorkManshipCallback(value) {
        setPPWorkManshipList(value)
    }
    function Messurecallback(value) {
        setMeasurementFileList(value)
    }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }

    function handleMainChange(e, FieldName) {

        if (FieldName === "Factory") {
            if (e !== null) {
                values.FactoryLocationID = e.value;
            } else {
                values.FactoryLocationID = 0;
            }
        } else if (FieldName === "FactoryMerch") {
            if (e.length === 0) {
                values.FactoryMerchID = "";
                values.FactoryMerchForEdit = '';

            } else {
                let strValue = "";
                for (let Sku of e) {
                    if (strValue === "") {
                        strValue = Sku.value.toString();
                    } else {
                        strValue = strValue + "," + Sku.value;
                    }
                }
                values.FactoryMerchID = strValue;
                values.FactoryMerchForEdit = e;
            }
        }
        else if (FieldName === "ReportDate") {
            if (e && e !== '') {
                values.ReportDate = new Date(e);
            } else {
                values.ReportDate = '';
            }
        } else {
            if (e !== null) {
                values.CheckedAreaID = e.value;
            } else {
                values.CheckedAreaID = 0;
            }
        }
        setMainInputField(values);
    }
    const handleClose = (value) => {
        //props.location.state.params.Mode = 'InspectionAdd';
        setAddStyleConfirmation({ showPopupConfirm: false, Params: [] });
        let x = Math.floor((Math.random() * 100) + 1);
        setrefershstate(x);
    }
    const copystyle = () => {
        if (props.location.state.params.ParentTask === 'TNAEdit') {
            localStorage.removeItem("QCInspectionID");
            props.history.push('/TNAEdit', { params: props.location.state.params.details });
        } else if (props.location.state.params.ParentTask === 'TNAView') {
            localStorage.removeItem("QCInspectionID");
            props.history.push('/TNAView', { params: props.location.state.params.details });
        } else if (props.location.state.params.ParentTask === 'TNAReport') {
            props.history.push('/TNAReport')
            localStorage.removeItem("QCInspectionID");
        } else if (props.location.state.params.ParentTask === 'Dashboard') {
            props.history.push('/Dashboard')
            localStorage.removeItem("QCInspectionID");
        } else {
            let params = { localStorage: true };
            props.history.push('/InternalQAAuditMain', { params: params })
            localStorage.removeItem("QCInspectionID");
        }
    }
    let confirmBtnText = 'Yes,do it!'
    let cancelBtnText = 'Cancel'
    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submitted && props.value === '' ? '1px solid red' : '', backgroundColor: 'white' }}
            />
        )
    }
    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{(props.location.state.params.IsAddorEditReinspection === 'Add Inspection' ? 'Add' : props.location.state.params.IsAddorEditReinspection === 'View Inspection' ? 'View' : 'Edit') + ' ' + props.location.state.params.TaskType + ''}</h3>
                            <div className="buttons-preview" align="right">

                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>
                                <button className="btn btn-warning" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                    onClick={
                                        () => SaveCompany("Drafted")
                                    } disabled={buttonLoader}>
                                    <i className="fa fa-rocket"></i>
                                    &nbsp;Draft</button>
                                <button type="submit" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }} className="btn btn-success" onClick={event => SaveCompany('Saved')} >

                                    <i className="fa fa-check right"></i> &nbsp;Save
                                </button>
                            </div>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="col-md-9">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className='form-group'>
                                                        <label htmlFor="txtBuyerSeasonBrand">Buyer-Season-Brand</label>
                                                        <span className='input-icon icon-right'>
                                                            <input type="text" className='form-control' placeholder="Enter Buyer-Season-Brand" id="txtBuyerSeasonBrand"
                                                                name="buyerseasonbrand" autoComplete="off" maxLength="100"
                                                                value={props.location.state.params.buyerName + " - " + props.location.state.params.seasonName + " - " + props.location.state.params.brandName}
                                                                disabled />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='form-group'>
                                                        <label htmlFor="ddlSupplier">Supplier</label>
                                                        <span className='input-icon icon-right'>
                                                            <input type="text" className='form-control' placeholder="Enter Buyer-Season-Brand" id="txtBuyerSeasonBrand"
                                                                name="buyerseasonbrand" autoComplete="off" maxLength="100"
                                                                value={props.location.state.params.supplierName}
                                                                disabled />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='form-group'>
                                                        <label htmlFor="ddlFactoryOrLocation">Factory {/* / Location */}<span className="text-danger">*</span></label>

                                                        <span className='input-icon icon-right'>
                                                            <Reactselect className="basic-single" name="factoryorlocation"
                                                                id={
                                                                    "ddlFactoryOrLocation"
                                                                }
                                                                isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={getExFactoryList}
                                                                styles={submitted && getMainInputField.FactoryLocationID === 0 ? styles : ''}
                                                                value={getExFactoryList.filter(function (option) {
                                                                    return option.value === getMainInputField.FactoryLocationID;
                                                                })}
                                                                onChange={event => handleMainChange(event, 'Factory')}
                                                            ></Reactselect>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className='form-group'>
                                                        <label htmlFor="ddlStyleName">Style Name</label>
                                                        <span className='input-icon icon-right'>
                                                            <input type="text" className='form-control' placeholder="Enter Buyer-Season-Brand" id="txtBuyerSeasonBrand"
                                                                name="buyerseasonbrand" autoComplete="off" maxLength="100"
                                                                value={props.location.state.params.styleName}
                                                                disabled />
                                                        </span>
                                                    </div>
                                                </div>
                                                {props.location.state.params.TaskType !== 'PP Meeting' ? < div className="col-sm-4">
                                                    <div className='form-group'>
                                                        <label htmlFor="txtInspectionLoad">PO/ID No</label>
                                                        <span className='input-icon icon-right'>
                                                            <input type="text" className='form-control' id="InspectionLevel   "
                                                                name="InspectionLevel" autoComplete="off"
                                                                //onChange={event => handleMainChange(event, 'InspectionLevel')}
                                                                value={props.location.state.params.details.idPoNos}
                                                                maxLength="100" disabled />

                                                        </span>
                                                    </div>
                                                </div> : ''}
                                                <div className="col-sm-4">
                                                    <div className='form-group'>
                                                        <label htmlFor="txtAQLLevel">Factory Merch <span className='text-danger'>*</span></label>
                                                        <span className='input-icon icon-right'>
                                                            <Reactselect className="basic-single" name="factoryorlocation"
                                                                id={
                                                                    "ddlFactoryOrLocation"
                                                                }
                                                                isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isMulti
                                                                isSearchable={true}
                                                                options={FactoryDroplist}
                                                                styles={submitted && getMainInputField.FactoryMerchID === '' ? styles : ''}
                                                                value={values.FactoryMerchForEdit}
                                                                onChange={event => handleMainChange(event, 'FactoryMerch')}
                                                            ></Reactselect>
                                                        </span>
                                                    </div>
                                                </div>
                                                {props.location.state.params.TaskType === "PP Meeting" ? <div className="col-sm-4">
                                                    <div className='form-group'>
                                                        <label htmlFor="dteReportDate">Report Date<span className="text-danger">*</span></label>
                                                        <DatePicker className="form-control" name="ReceivedDate"
                                                            id={
                                                                "dteReportDate"
                                                            }
                                                            selected={
                                                                getMainInputField.ReportDate
                                                            }
                                                            onChange={event => handleMainChange(event, 'ReportDate')}
                                                            dateFormat="dd/MM/yyyy"
                                                            peekNextMonth
                                                            ///disabled={props.location.state.params.Action === 'View' ? true : false}
                                                            //showMonthDropdown
                                                            //showYearDropdown
                                                            dropdownMode="scroll"
                                                            autoComplete="off"
                                                            customInput={<CustomInput />}
                                                            isClearable={getMainInputField.ReportDate === null ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                                    : ''}
                                            </div>
                                            {props.location.state.params.TaskType !== "PP Meeting" ? <div className="row">
                                                <div className="col-sm-4">
                                                    <div className='form-group'>
                                                        <label htmlFor="dteReportDate">Report Date<span className="text-danger">*</span></label>
                                                        <DatePicker className="form-control" name="ReceivedDate"
                                                            id={
                                                                "dteReportDate"
                                                            }
                                                            selected={
                                                                getMainInputField.ReportDate
                                                            }
                                                            onChange={event => handleMainChange(event, 'ReportDate')}
                                                            dateFormat="dd/MM/yyyy"
                                                            peekNextMonth
                                                            ///disabled={props.location.state.params.Action === 'View' ? true : false}
                                                            //showMonthDropdown
                                                            //showYearDropdown
                                                            dropdownMode="scroll"
                                                            autoComplete="off"
                                                            customInput={<CustomInput />}
                                                            isClearable={getMainInputField.ReportDate === null ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                                {props.location.state.params.TaskType === "In and Mid Line Inspection" ? <div className="col-sm-4">
                                                    <div className='form-group'>
                                                        <label htmlFor="ddlCustomerShipmentRefNo">Checked Area <span className='text-danger'>*</span></label>
                                                        <span className='input-icon icon-right'>
                                                            <Reactselect className="basic-single" name="factoryorlocation"
                                                                id={
                                                                    "ddlFactoryOrLocation"
                                                                }
                                                                //isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={CheckedAreaDroplist}
                                                                styles={submitted && getMainInputField.CheckedAreaID === 0 ? styles : ''}
                                                                value={CheckedAreaDroplist.filter(function (option) {
                                                                    return option.value === getMainInputField.CheckedAreaID;
                                                                })}
                                                                onChange={event => handleMainChange(event, 'CheckedArea')}
                                                            ></Reactselect>
                                                        </span>
                                                    </div>
                                                </div> : ''}

                                            </div> : ''}
                                        </div>
                                        <div className="col-sm-3 pull-right">
                                            <div className="form-group">

                                                <label style={{ marginLeft: "55px" }} htmlFor="exampleInputEmail2">SKU Image</label>
                                                <br />
                                                {
                                                    getnewimagevalue.length > 0 ?
                                                        <>
                                                            <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i>
                                                            <img src={window.$APIBaseURL + "Images" + getnewimagevalue[getCurrimageindex].skuFrontPicPath} alt="" width="150px" height="150px" />
                                                            <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i>
                                                        </> : ''
                                                }
                                                <br />
                                                {
                                                    getnewimagevalue.length > 0 ?

                                                        <label htmlFor="" style={{ marginLeft: "85px", marginTop: "1rem" }}>{getnewimagevalue[getCurrimageindex].skuName}</label>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className="row">
                <div className="col-md-12">
                    {props.location.state.params.TaskType !== "PP Meeting" ? <div className="tabbable">
                        <ul className="nav nav-tabs nav-justified" id="myTab5">
                            <li className="active" style={{ zIndex: '0' }}>
                                <a data-toggle="tab" href="#idReport" onClick={event => TabClick("Report", event)}>
                                    <h5>Report</h5>
                                </a>
                            </li>
                            <li className="" style={{ zIndex: '0' }}>
                                <a data-toggle="tab" href="#idStyleItem" onClick={event => TabClick("StyleItem")}>
                                    <h5>Style Item</h5>
                                </a>
                            </li>
                            <li className="" style={{ zIndex: '0' }}>
                                <a data-toggle="tab" href="#idMeasurement" onClick={event => TabClick("Measurement")}>
                                    <h5>Measurement</h5>
                                </a>
                            </li>
                            <li className="" style={{ zIndex: '0' }}>
                                <a data-toggle="tab" href="#idQCNotes" onClick={event => TabClick("QCNotes")}>
                                    <h5>QA Notes</h5>
                                </a>
                            </li><li className="" style={{ zIndex: '0' }}>
                                <a data-toggle="tab" href="#idPreviousReports" onClick={event => TabClick("PreviousReports")}>
                                    <h5>Previous Reports</h5>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">
                            <div id="idReport" className="tab-pane in active" style={{ width: "100%" }}>
                                {tabName === "Report" && <AddQCReportTab
                                    basicDetail={props.location.state.params}
                                    props={props} setrerenderstate={Rerendercallback} Qunatitylist={QuantityCallback} commentList={commentcallback} FileuploadList={FileupCallback}
                                    QcConclusionList={QcconclusionCallback} IsvalidationCallback={getIsvalidationReport} rerenderdep={refershstate} QCDpvalidation={IsvalidationQcDp}
                                />
                                }
                            </div>
                            <div id="idStyleItem" className="tab-pane" style={{ width: "100%" }}>
                                {tabName === "StyleItem" && <AddQCStyleItemTab props={props} setrenderval={rerenderval} StrikeofflistVal={StrikeoffCallback} TrimListVal={TrimCallback} isTrimvalidation={IsvalidationStyleItem}
                                    rerenderdep={refershstate} />}
                            </div>
                            <div id="idMeasurement" className="tab-pane" style={{ width: "100%" }}>

                                {tabName === "Measurement" && <AddQCMeasurement props={props} setrenderval={rerenderval} Messurecallback={Messurecallback} Savevalidationcallback={IsSaveValidation} />}

                            </div>
                            <div id="idQCNotes" className="tab-pane" style={{ width: "100%" }}>

                                {tabName === "QCNotes" && <AddQcNotes props={props} setrenderval={rerenderval} />}

                            </div>
                            <div id="idPreviousReports" className="tab-pane" style={{ width: "100%" }}>

                                {tabName === "PreviousReports" && <ListPreviousReports props={props} />}

                            </div>
                        </div>
                    </div> : <AddEditPPMeeting props={props} rerenderdep={refershstate} QualityinfoCallback={QualityCallback}
                        DropDownListCallback={DropDownCallback} TrimCallback={PPTrimCallback} WorkManshipCallback={PPWorkManshipCallback}
                        validtioneff={getPPIsvalidation} IsvalidationWrk={getPPIsvalidationIn} />}
                </div>
            </div>
            {
                addStyleConfirmation.showPopupConfirm ?
                    <SweetAlert
                        show={addStyleConfirmation.showPopupConfirm}
                        //warning
                        info
                        showCancel
                        showConfirm={true}
                        confirmBtnText={confirmBtnText}
                        cancelBtnText={cancelBtnText}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="primary"
                        closeOnClickOutside={true}
                        inputType={'new'}
                        // closeOnConfirm
                        // closeOnCancel
                        title={addStyleConfirmation.Params.Parentask === "TNAEdit" ? "Do you want to Redirect to TNA Edit" : addStyleConfirmation.Params.Parentask === "TNAReport" ? 'Do you want to Redirect to TNA Report' : "Do you want to Redirect to Qc Inspection - List Page"}
                        onConfirm={copystyle}
                        onCancel={handleClose}
                        showCloseButton
                        focusCancelBtn
                    // style={{ backgroundColor: 'blue', color: 'white' }}
                    /> : ''

            }
            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </span>
                        <span className="btn btn-danger" onClick={() => ResetOperation()}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>
                        <button className="btn btn-warning" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                            onClick={
                                () => SaveCompany("Drafted")
                            } disabled={buttonLoader}>
                            <i className="fa fa-rocket"></i>
                            &nbsp;Draft</button>
                        <button type="submit" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                            className="btn btn-success" onClick={() => SaveCompany('Saved')} disabled={buttonLoader}>

                            <i className="fa fa-check right"></i> &nbsp;save
                        </button>
                    </div>
                </div>
            </div>
            {loader}
        </div >
    )
}

export default AddQCinternalinspection;