import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import FabricTesting from './FabricTesting';
import YarnTesting from './YarnTesting';
import StrikeOffTesting from './StrikeOffTesting';
import TrimTesting from './TrimTesting';
import GarmentTesting from './GarmentTesting';
import styleService from "../../../services/Style/styleService";
import { loadCubeBuyerSupplierEmp } from "../../../actions/common";
import moment from "moment";
import { retrieveStyle } from "../../../actions/style";
import ReactNotification from 'react-notifications-component';
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { GetTestDetailsReportList } from "../../../actions/tna";
const TestingParent = (props) => {
    let BuyerListOptions = [] = [];
    const [tabName, setTabName] = useState("FABRIC TEST");
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;

    //const { user: currentUser } = reducerState.auth;
    // let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    // let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;

    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: null, //defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            // startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultExStartDate = new Date();
    const [stateExDate, setStateExDate] = useState([
        {
            startDate: null, //defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            // startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);

    var defaultTNAStartDate = new Date();
    const [stateTNADate, setStateTNADate] = useState([
        {

            startDate: defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    function TabClick(Tab) {
        setTabName(Tab);
    }
    useEffect(() => {
       ;
        let TestName = 'FABRIC TEST';
        if (props.location.state != undefined && (props.location.state === null ||
            props.location.state.params.PageName === "FABRIC TEST" ||
            props.location.state.params.PageName === undefined)) {
            ActivateFabricTab();
            setTabName("FABRIC TEST");

        }
        else if (props.location.state != null && props.location.state.params.PageName == "YARN TEST") {
            // ActivateEntryTab();

            ActivateYarnTab();
            //tabName = 'YARN TEST';
            setTabName("YARN TEST");
            TestName = 'YARN TEST';
        }
        else if (props.location.state != null && props.location.state.params.PageName == "Strike off Test") {
            //ActivateReinspectionTab();
            setTabName("Strike off Test");
            TestName = 'Strike off Test';
            ActivateStrikeTab();
        } else if (props.location.state != null && props.location.state.params.PageName == "Trims Test") {
            setTabName("Trims Test");
            TestName = 'Trims Test';
            ActivateTrimTab();
        } else if (props.location.state != null && props.location.state.params.PageName == "GARMENT TEST") {
            setTabName("GARMENT TEST");
            TestName = 'GARMENT TEST';
            ActivateGarmentTab();
        }
        else { ActivateFabricTab(); setTabName("FABRIC TEST"); }

        showLoader();
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            Operation: (TestName === 'FABRIC TEST' || TestName === 'YARN TEST' || TestName === 'GARMENT TEST') ? 1 : TestName === 'Strike off Test' ? 2 : 3
            , TaskDetailsName: TestName
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TestStartDate: '01/01/1970',
            TestEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
        }
        dispatch(GetTestDetailsReportList(params))
        hideLoader();
        console.log(params)

    }, [props.location.state, IsProduction])




    const ActivateFabricTab = () => {
        document.getElementById("liFabric").className = "active";
        var liYarn = document.getElementById("liYarn");
        liYarn.classList.remove("active");
        var liStrike = document.getElementById("liStrike");
        liStrike.classList.remove("active");
        var liTrim = document.getElementById("liTrim");
        liTrim.classList.remove("active");
        var liGarment = document.getElementById("liGarment");
        liGarment.classList.remove("active");

        document.getElementById("Fabric").className = "tab-pane col-lg-12 active";
        document.getElementById("Yarn").className = "tab-pane col-lg-12";
        document.getElementById("Strike").className = "tab-pane col-lg-12";
        document.getElementById("Trim").className = "tab-pane col-lg-12";
        document.getElementById("Garment").className = "tab-pane col-lg-12";
    }


    const ActivateYarnTab = () => {
        document.getElementById("liYarn").className = "active";
        var liFabric = document.getElementById("liFabric");
        liFabric.classList.remove("active");
        var liStrike = document.getElementById("liStrike");
        liStrike.classList.remove("active");
        var liTrim = document.getElementById("liTrim");
        liTrim.classList.remove("active");
        var liGarment = document.getElementById("liGarment");
        liGarment.classList.remove("active");

        document.getElementById("Fabric").className = "tab-pane col-lg-12";
        document.getElementById("Yarn").className = "tab-pane col-lg-12 active";
        document.getElementById("Strike").className = "tab-pane col-lg-12";
        document.getElementById("Trim").className = "tab-pane col-lg-12";
        document.getElementById("Garment").className = "tab-pane col-lg-12";
    }

    const ActivateStrikeTab = () => {
        document.getElementById("liStrike").className = "active";
        var liFabric = document.getElementById("liFabric");
        liFabric.classList.remove("active");
        var liYarn = document.getElementById("liYarn");
        liYarn.classList.remove("active");
        var liTrim = document.getElementById("liTrim");
        liTrim.classList.remove("active");
        var liGarment = document.getElementById("liGarment");
        liGarment.classList.remove("active");

        document.getElementById("Fabric").className = "tab-pane col-lg-12";
        document.getElementById("Yarn").className = "tab-pane col-lg-12";
        document.getElementById("Strike").className = "tab-pane col-lg-12 active";
        document.getElementById("Trim").className = "tab-pane col-lg-12";
        document.getElementById("Garment").className = "tab-pane col-lg-12";
    }


    const ActivateTrimTab = () => {
        document.getElementById("liTrim").className = "active";
        var liFabric = document.getElementById("liFabric");
        liFabric.classList.remove("active");
        var liYarn = document.getElementById("liYarn");
        liYarn.classList.remove("active");
        var liStrike = document.getElementById("liStrike");
        liStrike.classList.remove("active");
        var liGarment = document.getElementById("liGarment");
        liGarment.classList.remove("active");

        document.getElementById("Fabric").className = "tab-pane col-lg-12";
        document.getElementById("Yarn").className = "tab-pane col-lg-12";
        document.getElementById("Strike").className = "tab-pane col-lg-12";
        document.getElementById("Trim").className = "tab-pane col-lg-12 active";
        document.getElementById("Garment").className = "tab-pane col-lg-12";
    }


    const ActivateGarmentTab = () => {
        document.getElementById("liGarment").className = "active";
        var liFabric = document.getElementById("liFabric");
        liFabric.classList.remove("active");
        var liYarn = document.getElementById("liYarn");
        liYarn.classList.remove("active");
        var liStrike = document.getElementById("liStrike");
        liStrike.classList.remove("active");
        var liTrim = document.getElementById("liTrim");
        liTrim.classList.remove("active");

        document.getElementById("Fabric").className = "tab-pane col-lg-12";
        document.getElementById("Yarn").className = "tab-pane col-lg-12";
        document.getElementById("Strike").className = "tab-pane col-lg-12";
        document.getElementById("Trim").className = "tab-pane col-lg-12";
        document.getElementById("Garment").className = "tab-pane col-lg-12 active";
    }


    //   const ActivateEntryTab = () => {

    //     document.getElementById("liEntry").className = "active";
    //     var elementDetails = document.getElementById("liDetails");
    //     elementDetails.classList.remove("active");
    //     var elementReInspection = document.getElementById("liReInspectionDetails");
    //     elementReInspection.classList.remove("active");
    //     document.getElementById("idInspectionDetail").className = "tab-pane col-lg-12";
    //     document.getElementById("idInspectionEntry").className = "tab-pane col-lg-12 active";
    //     document.getElementById("idReInspection").className = "tab-pane col-lg-12";
    //     ; let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
    //     setIsProductionStatussearch(Defaultvalue);

    //     let StartDate = null;
    //     let EndDate = null;
    //     if (moment(stateDate[0].startDate).isValid()) {
    //       StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    //     }
    //     if (moment(stateDate[0].endDate).isValid()) {
    //       EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    //     }

    //     let StartApDate = null;
    //     let EndApDate = null;
    //     if (moment(stateApDate[0].startDate).isValid()) {
    //       StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
    //       //StartDate.setMinutes(StartDate.getMinutes() + 370);
    //     }
    //     if (moment(stateApDate[0].endDate).isValid()) {
    //       EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
    //       //EndDate.setMinutes(EndDate.getMinutes() + 370);
    //     }
    //     let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 0 }

    //     dispatch(LoadFinalInspectionList(params));
    //   }

    //   const ActivateReinspectionTab = () => {

    //     document.getElementById("liReInspectionDetails").className = "active";
    //     var elementDetails = document.getElementById("liDetails");
    //     elementDetails.classList.remove("active");
    //     var elementEntry = document.getElementById("liEntry");
    //     elementEntry.classList.remove("active");
    //     document.getElementById("idInspectionDetail").className = "tab-pane col-lg-12";
    //     document.getElementById("idInspectionEntry").className = "tab-pane col-lg-12";
    //     document.getElementById("idReInspection").className = "tab-pane col-lg-12 active";
    //     let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
    //     setIsProductionStatussearch(Defaultvalue);
    //     let StartDate = null;
    //     let EndDate = null;
    //     if (moment(stateDate[0].startDate).isValid()) {
    //       StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    //     }
    //     if (moment(stateDate[0].endDate).isValid()) {
    //       EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    //     }

    //     let StartApDate = null;
    //     let EndApDate = null;
    //     if (moment(stateApDate[0].startDate).isValid()) {
    //       StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
    //       //StartDate.setMinutes(StartDate.getMinutes() + 370);
    //     }
    //     if (moment(stateApDate[0].endDate).isValid()) {
    //       EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
    //       //EndDate.setMinutes(EndDate.getMinutes() + 370);
    //     }
    //     let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
    //     dispatch(LoadReFinalInspectionList(params));
    //   }

    // useEffect(() => {
    //     showLoader();
    //     dispatch(GetTestDetailsReportList(
    //         (tabName === 'FABRIC TEST' || tabName === 'YARN TEST' || tabName === 'GARMENT TEST') ? 1 : tabName === 'Strike off Test' ? 2 : 3
    //         , IsProduction
    //         , tabName
    //         , moment(getExFacStartDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
    //         , moment(getExFacStartDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")));
    //      hideLoader();
    // }, [IsProduction]);

    return (
        <Fragment>
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tabbable">
                                        <ul className="nav nav-tabs nav-justified" id="myTab5">

                                            <li className="" id='liFabric'>
                                                <a data-toggle="tab" href="#Fabric" onClick={event => TabClick("FABRIC TEST", event)}>
                                                    <h5>Fabric Test</h5>
                                                </a>
                                            </li>
                                            <li className="" id='liYarn'>
                                                <a data-toggle="tab" href="#Yarn" onClick={event => TabClick("YARN TEST", event)}>
                                                    <h5>Yarn Test</h5>
                                                </a>
                                            </li>
                                            <li className="" id='liStrike'>
                                                <a data-toggle="tab" href="#Strike" onClick={event => TabClick("Strike off Test", event)}>
                                                    <h5>Strike Off Test</h5>
                                                </a>
                                            </li>
                                            <li className="" id='liTrim'>
                                                <a data-toggle="tab" href="#Trim" onClick={event => TabClick("Trims Test", event)}>
                                                    <h5>Trim Test</h5>
                                                </a>
                                            </li>
                                            <li className="" id='liGarment'>
                                                <a data-toggle="tab" href="#Garment" onClick={event => TabClick("GARMENT TEST", event)}>
                                                    <h5>Garment Test</h5>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="Fabric" className="tab-pane" style={{ width: "100%" }}>
                                                {tabName === 'FABRIC TEST' && <FabricTesting props={props} TaskValue={tabName} />}
                                            </div>
                                            <div id="Yarn" className="tab-pane" style={{ width: "100%" }}>
                                                {tabName === 'YARN TEST' && <YarnTesting props={props} TaskValue={tabName} />}
                                            </div>
                                            <div id="Strike" className="tab-pane" style={{ width: "100%" }}>
                                                {tabName === 'Strike off Test' && <StrikeOffTesting props={props} TaskValue={tabName} />}
                                            </div>
                                            <div id="Trim" className="tab-pane" style={{ width: "100%" }}>
                                                {tabName === 'Trims Test' && <TrimTesting props={props} TaskValue={tabName} />}
                                            </div>
                                            <div id="Garment" className="tab-pane" style={{ width: "100%" }}>
                                                {tabName === 'GARMENT TEST' && <GarmentTesting props={props} TaskValue={tabName} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loader}
                    </div>
                    {loader}
                </div>
                {/* // {loader} */}
            </div>
        </Fragment>
    )

};
export default TestingParent;