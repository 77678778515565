import Input from "react-validation/build/input";
import React, { useState, useEffect, Fragment, Suspense, useRef } from "react";
import Form from "react-validation/build/form";
import "react-notifications-component/dist/theme.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import Nodify from "../Common/ReactNotification";
import "react-datepicker/dist/react-datepicker.css";
import Lightbox from "react-image-lightbox";
import { Modal, Button } from "react-bootstrap";
import $, { get } from "jquery";
import { useSelector } from "react-redux";
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import axios from "axios";
import TnaService from "../../services/TNA/TNAService";
import ProgramService from "../../services/Style/ProgramService";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import CommonService from '../../services/Common/CommonService';
import SweetAlertPopup from '../Common/SweetAlertPopup';
import { LabdipSubmissionApprovalId } from "../Common/PageActionNumber";
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import SweetAlert from 'react-bootstrap-sweetalert';
 import Select from "react-validation/build/select";
import { retrieveFabricType } from "../../actions/fabricType";
import FabricService from "../../services/Master/FabricService";

import Reactselect from 'react-select';
const LabdibSubmit = (props) => {

  const { user: currentUser } = useSelector((state) => state.auth);
  //const [submitted, setSubmitted] = useState(false);
  const [getHeader, setHeader] = useState("Add");
  const [submittedpopup, setSubmittedpopup] = useState(false);
  const [submittedawb, setSubmittedawb] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [getImagename, setImagename] = useState();
  const [showawb, setshowawb] = useState(false);
  const [getOrderinfo, setOrderinfo] = useState([]);
  const [ShowPopup, setShowPopup] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submittedOption, setSubmittedOption] = useState(false);
  const [FabricDetails, setFabricDetails] = useState([]);
  const [example, setexample] = useState([]);
  const [getTaskholder, setTaskholder] = useState([]);
  const [MultiCloseDatas, setMultiCloseDatas] = useState([]);

  const [selectall, setselectall] = useState(false);
  const [showMultiClosediv, setshowMultiClosediv] = useState();
  const [showSubmissiondiv, setshowSubmissiondiv] = useState();

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [getID, setID] = useState({ showPopupDelete: false, Params: {} });

  const [Swatchdownload, setSwatchdownload] = useState({ Condition: false, Format: "0" });
  const [SwatchCubeorBuyer, setSwatchCubeorBuyer] = useState('');
  const pdfRef = useRef(null);

  const [inputFields, setInputFields] = useState([{
    TNAID: 0, BuyerID: 0, BrandID: 0, BuyerName: '',
    SeasonID: 0, SupplierID: 0, SupplierName: "", BuyerBrandSeasonName: "",
    SubmittedDate: "", SendTo: "", SendToEdit: '', SendToDisplay: '', SendThrough: 0,
    StyleID: 0, StyleName: "", StyleNo: "", IdpoNo: "", EndCustomer: "",
    AWBName: "", AWBDate: "", AWBDateandNo: "",
    SubmissionApprovalStatus: 0, PantoneID: 0, PantoneCode: "", RefColorName: "",
    PantoneNameOrSwatchName: "", PantoneColorOrOrSwatchImage: "", PantoneTypeName: "",
    FabricID: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
    TNALabdibSubmittedOption: [],
    Operation: 0, StyleProgramId: 0, ApprovalRemark: '', SubmissionApprovalID: 0,
    LabdibFabricInfoId: 0,
    IsAllFabricLoad: 0,
    hasAllDependencyStartPopup: false, MultiDependencyDate: null, MultiDependencyComment: '', submitDependencyStart: false
  }]);

  const [chkDataExists, setchkDataExists] = useState(false);
  const values = [...inputFields]

  const [buttonLoader, setbuttonLoader] = useState(false);


  const [basicdataLabdipPdf, setbasicdataLabdipPdf] = useState([]);
  const [AllsubmitoptiosLabdipPdf, setAllsubmitoptiosLabdipPdf] = useState([]);
  const [submitoptiosRefnoLabdipPdf, setsubmitoptiosRefnoLabdipPdf] = useState([]);
  const [rowsanddatacountforPdf, setrowsanddatacountforPdf] = useState([]);
  const [swatchcardimages, setswatchcardimages] = useState([]);
  const [showswatchcardimages, setshowswatchcardimages] = useState([]);
  const [SwatchDownloadformat, setSwatchDownloadformat] = useState(false);
  const [multiplefiles, setmultiplefiles] = useState([]);
  const [submittedSwatchFormat, setSubmittedSwatchFormat] = useState(false);

  // If check production or sampling
  const activeMenus = useSelector((state) => state.auth.activeMenuList);
  let IsProduction = activeMenus.IsProduction;
  // End

  const CustomInputDependency = (props) => {
    return (
      <input
        className="form-control DatePickerCalenderHoliday"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        menuPosition="fixed"
        placeholder="DD/MM/YYYY"
        style={{ width: "100%", border: (!values[0].submitDependencyStart) || props.value ? '' : '1px solid red' }}
      />
    )

  }
  let styles1 = {
    control: styles1 => ({ ...styles1, width: "230px" }),
  }

  let styles2 = {
    control: styles2 => ({ ...styles2, width: "230", border: '1px solid red' }),
  }

  useEffect(() => {
   
    if (props.deleteLabdipSubmission === true || props.editLabdipSubmission === true) {
      let Params = {
        Operation: 48,
        Id: props.LabdibFabricInfoId,
      };
      CommonService.CheckMappedItem(Params).then(res => {
        if (res.data.outputResult === "-2") {

          let Title = "Warning!";
          let message = "Approval exists against this Submission.";
          let type = "warning";
          Nodify(Title, type, message);
          props.LapdipSubmissionCallback(false)
        }
        else {
          if (props.deleteLabdipSubmission === true) {
            setID({ showPopupDelete: true, Params: props.LabdibFabricInfoId });
            TnaService.ViewLabdipSubmissionApprovalList(props.LabdibFabricInfoId, IsProduction).then((response) => {
              if (response.data) {
                setOrderinfo(response.data.labdipSubmissionOrderInfoList);
              }
            }).catch(() => { hideLoader(); });

          }
          else {
            let getDateValue = new Date(values[0].SubmittedDate);
            var dd = getDateValue.getDate();
            var mm = getDateValue.getMonth() + 1;
            var yyyy = getDateValue.getFullYear();

            if (dd < 10) {
              dd = '0' + dd;
            }
            if (mm < 10) {
              mm = '0' + mm;
            }
            values[0].SubmittedDate = getDateValue;
            setInputFields(values);
          }
        }
      });
    }
    else if (props.LabdipswatchDownload === true) {
      setSwatchDownloadformat(true);
    }
    else if (props.LabdipswatchUpload === true) {
      $('#upload-button').trigger('click');

    }
    else {
      showLoader();
      if (props.TaskStatus !== 3) {
        values[0].TNALabdibSubmittedOption.push(
          { OptionName: "A", SupplierReferenceNo: "" },
          { OptionName: "B", SupplierReferenceNo: "" },
          { OptionName: "C", SupplierReferenceNo: "" },
          { OptionName: "D", SupplierReferenceNo: "" },
        )
      }
      ProgramService.LoadStyleFabricDetailsList(props.StyleId).then((response) => {
        if (response.data) {
          setFabricDetails(response.data);
        }
      }).catch(() => { });
      TnaService.TaskHolderList(props.TNAId).then((response) => {
        if (response.data) {
          setTaskholder(response.data);
        }
      }).catch(() => { });

      if (props.TaskStatus !== 3) {
        setshowMultiClosediv(true);
        setshowSubmissiondiv(false);

        TnaService.LapdipSubmissionList(1, props.LabdibFabricInfoId, IsProduction).then((response) => {
          if (response.data) {
            values[0].TNAID = response.data[0].tnaId;
            values[0].BrandID = response.data[0].brandID;
            values[0].BuyerID = response.data[0].buyerID;
            values[0].SeasonID = response.data[0].seasonID;
            values[0].SupplierID = response.data[0].supplierID;
            values[0].SupplierName = response.data[0].supplierName;
            values[0].BuyerBrandSeasonName = response.data[0].buyerBrandSeasonName;
            values[0].StyleID = response.data[0].styleId;
            values[0].StyleName = response.data[0].styleName;
            values[0].StyleNo = response.data[0].styleNo;
            values[0].IdpoNo = response.data[0].idpoNo;
            values[0].EndCustomer = response.data[0].endCustomerNameIdList;
            values[0].PantoneID = response.data[0].pantoneId;
            values[0].PantoneCode = response.data[0].pantoneCode;
            // values[0].PantoneTypeName = response.data[0].pantoneTypeName
            values[0].RefColorName = response.data[0].refColorName;
            values[0].PantoneNameOrSwatchName = response.data[0].pantoneNameOrSwatchName;
            values[0].PantoneColorOrOrSwatchImage = response.data[0].pantoneColorOrOrSwatchImage;
            values[0].FabricID = response.data[0].fabricId;
            values[0].PantoneTypeName = response.data[0].pantoneTypeName;
            values[0].StyleProgramId = response.data[0].styleProgramId;
            values[0].LabdibFabricInfoId = props.LabdibFabricInfoId;
            values[0].SubmissionApprovalID = 0;
            values[0].IsAllFabricLoad = 0;


            setInputFields(values);
          }
        }).catch(() => { });
        TnaService.GetLabdipSubmissionOrderInfoList(3, props.LabdibFabricInfoId, IsProduction).then((response) => {

          if (response.data) {
            var result = response.data;
            result.forEach(element => {
              if (element.labdibFabricInfoId === props.LabdibFabricInfoId) {
                element.isSelected = 1;
              }
            })

            result.map((r, i) => {
              props.AllChildvalues.map(c => {
                if (r.labdibFabricInfoId === c.ChildId) {
                  result[i].IsDepandency = c.IsDepandency
                  result[i].Depandency = c.Depandency
                  result[i].dependencyStartstop = c.intDependency;
                }
              })
            })
            setMultiCloseDatas(result);
            hideLoader();
          }
        }).catch(() => { hideLoader(); });
      }
      else {
        setshowMultiClosediv(false);
        setshowSubmissiondiv(true);

        TnaService.ViewLabdipSubmissionApprovalList(props.LabdibFabricInfoId, IsProduction).then((response) => {
          ;
          if (response.data) {
            values[0].TNAID = response.data.labdibSubmitApprovalList[0].tnaId;
            values[0].BrandID = response.data.labdibSubmitApprovalList[0].brandID;
            values[0].BuyerID = response.data.labdibSubmitApprovalList[0].buyerID;
            values[0].SeasonID = response.data.labdibSubmitApprovalList[0].seasonID;
            values[0].SupplierID = response.data.labdibSubmitApprovalList[0].supplierID;
            values[0].SupplierName = response.data.labdibSubmitApprovalList[0].supplierName;
            values[0].BuyerBrandSeasonName = response.data.labdibSubmitApprovalList[0].buyerBrandSeasonName;
            values[0].BuyerName = response.data.labdibSubmitApprovalList[0].buyerName;
            values[0].StyleID = response.data.labdibSubmitApprovalList[0].styleId;
            values[0].StyleName = response.data.labdibSubmitApprovalList[0].styleName;
            values[0].StyleNo = response.data.labdibSubmitApprovalList[0].styleNo;
            values[0].IdpoNo = response.data.labdibSubmitApprovalList[0].idpoNo;
            values[0].EndCustomer = response.data.labdibSubmitApprovalList[0].endCustomerNameIdList;
            values[0].PantoneTypeName = response.data.labdibSubmitApprovalList[0].pantoneTypeName;
            values[0].PantoneID = response.data.labdibSubmitApprovalList[0].pantoneId;
            values[0].PantoneCode = response.data.labdibSubmitApprovalList[0].pantoneCode;
            values[0].RefColorName = response.data.labdibSubmitApprovalList[0].refColorName;
            values[0].PantoneNameOrSwatchName = response.data.labdibSubmitApprovalList[0].pantoneNameOrSwatchName;
            values[0].PantoneColorOrOrSwatchImage = response.data.labdibSubmitApprovalList[0].pantoneColorOrOrSwatchImage;
            values[0].FabricID = response.data.labdibSubmitApprovalList[0].fabricId;

            values[0].StyleProgramId = response.data.labdibSubmitApprovalList[0].styleProgramId;
            values[0].LabdibFabricInfoId = props.LabdibFabricInfoId;
            values[0].SubmissionApprovalID = response.data.labdibSubmitApprovalList[0].submissionApprovalID;
            values[0].IsAllFabricLoad = response.data.labdibSubmitApprovalList[0].isAllFabricLoad;
            //values[0].SubmittedDate = response.data.labdibSubmitApprovalList[0].submittedDate;
            if (props.TaskStatus === 3 && values[0].IsAllFabricLoad === 1) {
              FabricService.GetFabricList(response.data.labdibSubmitApprovalList[0].fabricId, "F").then((response) => {
                ;
                if (response.data) {
                  setFabricDetails(response.data)
                }
              })
            }
            let getDateValue = new Date(response.data.labdibSubmitApprovalList[0].submittedDate);
            var dd = getDateValue.getDate();
            var mm = getDateValue.getMonth() + 1;
            var yyyy = getDateValue.getFullYear();

            if (dd < 10) {
              dd = '0' + dd;
            }
            if (mm < 10) {
              mm = '0' + mm;
            }
            // let coverted = yyyy + '-' + mm + '-' + dd;
            let coverted = mm + '/' + dd + '/' + yyyy;
            values[0].SubmittedDate = getDateValue;
            if (response.data.labdibSubmitApprovalList[0].awbDate !== "") {
              let awbdatevalue = new Date(response.data.labdibSubmitApprovalList[0].awbDate);
              var dd = awbdatevalue.getDate();
              var mm = awbdatevalue.getMonth() + 1;
              var yyyy = awbdatevalue.getFullYear();

              if (dd < 10) {
                dd = '0' + dd;
              }
              if (mm < 10) {
                mm = '0' + mm;
              }
              // let coverted = yyyy + '-' + mm + '-' + dd;
              let coverteddate = dd + '/' + mm + '/' + yyyy;
              if (response.data.labdibSubmitApprovalList[0].sendThrough === 2) {
                setshowawb(true);
                //setShowTrimPopup(true);
                values[0].AWBDateandNo = response.data.labdibSubmitApprovalList[0].awbName + ' & ' + coverteddate;
                values[0].AWBDate = new Date(response.data.labdibSubmitApprovalList[0].awbDate);
                values[0].AWBName = response.data.labdibSubmitApprovalList[0].awbName;

              }
            }
            else {
              if (response.data.labdibSubmitApprovalList[0].sendThrough === 2) {
                setshowawb(true);
                values[0].AWBDateandNo = '' + '  ' + '';
                values[0].AWBDate = response.data.labdibSubmitApprovalList[0].awbDate === "" ? "" : new Date(response.data.labdibSubmitApprovalList[0].awbDate)
                values[0].AWBName = response.data.labdibSubmitApprovalList[0].awbName;

              }

            }


            values[0].SendThrough = response.data.labdibSubmitApprovalList[0].sendThrough;

            values[0].SendTo = response.data.labdibSubmitApprovalList[0].sendTo;
            values[0].SendToDisplay = response.data.labdibSubmitApprovalList[0].sendToNames;
            values[0].SendToEdit = response.data.labdibSubmitApprovalList[0].sendToNames;
            let arr = response.data.labdibSubmitApprovalList[0].sendTo.split(',');
            let arrVal = [];
            for (let size of arr) {
              TnaService.TaskHolderList(props.TNAId).then((res) => {
                if (res.data) {
                  let sendtoId = res.data.filter(x => x.value === parseInt(size))
                  arrVal.push({
                    value: sendtoId[0].value,
                    label: sendtoId[0].label
                  })
                  values[0].SendToEdit = arrVal;
                  //setInputFields(values)
                }
                // setInputFields(values)
              }).catch(() => { });

            }
            values[0].SwatchCardIamgePath = response.data.labdibSubmitApprovalList[0].swatchCardImagePath;
            values[0].SwatchCardImageName = response.data.labdibSubmitApprovalList[0].swatchCardImageName;
            values[0].Remarks = response.data.labdibSubmitApprovalList[0].remarks;

            values[0].TNALabdibSubmittedOption = [];
            response.data.labdibSubmittedOption.forEach(element => {
              values[0].TNALabdibSubmittedOption.push({
                OptionName: element.optionName,
                SupplierReferenceNo: element.supplierReferenceNo
              })
            })

            setInputFields(values);
            setOrderinfo(response.data.labdipSubmissionOrderInfoList);
            hideLoader();
          }
        }).catch(() => { hideLoader(); });
      }
    }

    //setOrderinfo(getOrderinfo);
  }, [props.editLabdipSubmission === true || props.deleteLabdipSubmission === true || props.LabdipswatchDownload === true || props.LabdipswatchUpload === true])

  // For when using Edit cant able to delete this information, so we using unique useeffect for delete
  useEffect(() => {
   
    if (props.deleteLabdipSubmission === true || props.editLabdipSubmission === true) {
      let Params = {
        Operation: 48,
        Id: props.LabdibFabricInfoId,
      };
      CommonService.CheckMappedItem(Params).then(res => {
        if (res.data.outputResult === "-2") {

          let Title = "Warning!";
          let message = "Approval exists against this Submission.";
          let type = "warning";
          Nodify(Title, type, message);
          props.LapdipSubmissionCallback(false)
        }
        else {
          if (props.deleteLabdipSubmission === true) {
            setID({ showPopupDelete: true, Params: props.LabdibFabricInfoId });
            TnaService.ViewLabdipSubmissionApprovalList(props.LabdibFabricInfoId, IsProduction).then((response) => {
              if (response.data) {
                setOrderinfo(response.data.labdipSubmissionOrderInfoList);
              }
            }).catch(() => { hideLoader(); });

          }
          else {
            let getDateValue = new Date(values[0].SubmittedDate);
            var dd = getDateValue.getDate();
            var mm = getDateValue.getMonth() + 1;
            var yyyy = getDateValue.getFullYear();

            if (dd < 10) {
              dd = '0' + dd;
            }
            if (mm < 10) {
              mm = '0' + mm;
            }
            values[0].SubmittedDate = getDateValue;
            setInputFields(values);
          }
          //props.LapdipSubmissionCallback(false)
        }
      });
    }
    else if (props.LabdipswatchDownload === true) {
      setSwatchDownloadformat(true);
    }
    else if (props.LabdipswatchUpload === true) {
      $('#upload-button').trigger('click');

    }

  }, [props.deleteLabdipSubmission === true && props.editLabdipSubmission === true])

  const selector = useSelector((state) => state);
  const isSideBarEnable = selector.auth.isSideBarEnable;
  const isFullSideBarEnable = selector.auth.isFullSideBarEnable;


  const headers = [
    { name: "Style Name", field: "StyleName", sortable: false },
    { name: "Style No", field: "StyleNo", sortable: false },
    { name: "PO/ID No", field: "IDNo", sortable: false },
    { name: "Details", field: "Details", sortable: false },
    { name: "Task Holder/Follower", field: "", sortable: false },
    { name: "Action", field: "", sortable: false },
  ];
  //const values = [...inputFields];
  // const [getSubmitOptions, setSubmitOptions] = useState([]);





  let BrowserWidth = isFullSideBarEnable
    ? isSideBarEnable
      ? $(window).width() - 130
      : $(window).width() + 50
    : $(window).width() + 100;

  let DynamicClassName = "col-lg-12 col-sm-12 col-xs-12";
  //  const selector = useSelector((state) => state);
  //   const isSideBarEnable = selector.auth.isSideBarEnable;
  //const isFullSideBarEnable = selector.auth.isFullSideBarEnable;
  //   const [tabStatus, setTabStatus] = useState(1);

  // let BrowserWidth = isFullSideBarEnable
  // ? isSideBarEnable
  //     ? $(window).width() - 130
  //     : $(window).width() + 50
  // : $(window).width() + 100;
  const handleRemoveFields = index => {
    values[0].TNALabdibSubmittedOption.splice(index, 1);
    setInputFields(values);
  };


  const handleAddField = (index) => {
    var isvalid = true;

    inputFields.forEach((element, index) => {
      if (values[0].TNALabdibSubmittedOption[index].OptionName === '' || values[0].TNALabdibSubmittedOption[index].SupplierReferenceNo === '') {
        isvalid = false;
      }
    })
    if (isvalid) {
      values[0].TNALabdibSubmittedOption.push({ OptionName: '', SupplierReferenceNo: '' });
      setInputFields(values);
      setSubmitted(false);
    }
    else {
      Nodify('Warning!', 'warning', 'Please fill Option and Supplier Reference No.');
      setSubmitted(true);
    }
  }



  var ImgClassName = "upload-btn-wrapper2"



  const UploadImages = async (e) => {
    ;

    const files = e.target.files;
    var file = null;
    var Firstfile = files[0];
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      file = files[i];
      if (file !== null && file !== '' && file !== undefined) {
        const fileType = file['type'];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
          Nodify('Warning!', 'warning', 'Invalid file format selected');
          $('#FileUpload').val("");
        }
        else {

          formData.append("FormFile", file);
          // formData.append("FileName", file.name);
          // formData.append("Page", "LabdipSwatchCardPdf");
          try {
            //  const res = await axios.get(window.$APIBaseURL + "api/file/", formData);
            swatchcardimages.push({
              src: "Images/LabdipSwatchCardPdf/" + file.name,
              height: ''
            })


          } catch (ex) {
            console.log(ex);
          }
        }
      }
    }
    for (const value of formData.values()) {
      console.log(value);
    }
    const res = await axios.post(window.$APIBaseURL + "api/file/LabdipSwatchCardUpload", formData);
    var FileName = Firstfile.name.substr(0, Firstfile.name.lastIndexOf('.'));
    values[0].SwatchCardImageName = Firstfile.name;
    values[0].SwatchCardIamgePath = "Images/LabdipSwatchCardPdf/" + Firstfile.name;
    setInputFields(values);
    $('#FileUpload').val("");
    setshowswatchcardimages(true);
    UploadLabdipSwatchCardPdf(FileName);
  }

  const UploadLabdipSwatchCardPdf = (FileName) => {
    var timeout = 5000;
    showLoader();
    const doc = new jsPDF('p', 'pt', 'a4');
    const Page1 = document.getElementById("UploadPage1");
    html2canvas(Page1, { scale: 4 }).then(canvas => {
      var img = new Image();

      img.src = window.$APIBaseURL + swatchcardimages[0].src;
      const imgWidth = 590;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');



      if (document.getElementById("UploadPage2") !== null) {
        const Page2 = document.getElementById("UploadPage2");
        html2canvas(Page2, { scale: 4 }).then(canvas => {
          var img = new Image();
          img.src = window.$APIBaseURL + swatchcardimages[1].src;
          const imgWidth = 590;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          doc.addPage();
          doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


        })
      }
      if (document.getElementById("UploadPage3") !== null) {
        const Page3 = document.getElementById("UploadPage3");
        html2canvas(Page3, { scale: 4 }).then(canvas => {
          var img = new Image();
          img.src = window.$APIBaseURL + swatchcardimages[2].src;
          const imgWidth = 590;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          doc.addPage();
          doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


        })
      }

      if (document.getElementById("UploadPage4") !== null) {
        const Page4 = document.getElementById("UploadPage4");
        html2canvas(Page4, { scale: 4 }).then(canvas => {
          var img = new Image();
          img.src = window.$APIBaseURL + swatchcardimages[3].src;
          const imgWidth = 590;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          doc.addPage();
          doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


        })
      }
      // if (document.getElementById("UploadPage5") !== null) {
      //   const Page5 = document.getElementById("UploadPage5");
      //   html2canvas(Page5, { scale: 4 }).then(canvas => {
      //     var img = new Image();
      //     img.src = window.$APIBaseURL + swatchcardimages[4].src;
      //     const imgWidth = 590;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


      //   })
      // }

      // if (document.getElementById("UploadPage6") !== null) {
      //   timeout = 10000;
      //   const Page6 = document.getElementById("UploadPage6");
      //   html2canvas(Page6, { scale: 4 }).then(canvas => {
      //     var img = new Image();
      //     img.src = window.$APIBaseURL + swatchcardimages[5].src;
      //     const imgWidth = 590;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


      //   })
      // }

      // if (document.getElementById("UploadPage7") !== null) {
      //   const Page7 = document.getElementById("UploadPage7");
      //   html2canvas(Page7, { scale: 4 }).then(canvas => {
      //     var img = new Image();
      //     img.src = window.$APIBaseURL + swatchcardimages[6].src;
      //     const imgWidth = 590;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


      //   })
      // }

      // if (document.getElementById("UploadPage8") !== null) {
      //   const Page8 = document.getElementById("UploadPage8");
      //   html2canvas(Page8, { scale: 4 }).then(canvas => {
      //     var img = new Image();
      //     img.src = window.$APIBaseURL + swatchcardimages[7].src;
      //     const imgWidth = 590;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


      //   })
      // }

      // if (document.getElementById("UploadPage9") !== null) {
      //   const Page9 = document.getElementById("UploadPage9");
      //   html2canvas(Page9, { scale: 4 }).then(canvas => {
      //     var img = new Image();
      //     img.src = window.$APIBaseURL + swatchcardimages[8].src;
      //     const imgWidth = 590;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


      //   })
      // }

      // if (document.getElementById("UploadPage10") !== null) {
      //   const Page10 = document.getElementById("UploadPage10");
      //   html2canvas(Page10, { scale: 4 }).then(canvas => {
      //     var img = new Image();
      //     img.src = window.$APIBaseURL + swatchcardimages[9].src;
      //     const imgWidth = 590;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');


      //   })
      // }




      setTimeout(() => {

        //  var file = doc.output("blob");
        const file = new File([doc.output("blob")], "filename.pdf", { type: "image/jpg" });
        //  doc.save("LabdipSwatchCard_" + props.LabdibFabricInfoId + ".pdf");
        hideLoader();
        setshowswatchcardimages(false);

        const formData = new FormData();
        formData.append("FormFile", file);
        formData.append("FileName", FileName + ".pdf");
        formData.append("Page", "LabdipSwatchCardPdf");
        const res = axios.post(window.$APIBaseURL + "api/file", formData);
        TnaService.UpdateLabdipSwatchStatus(props.LabdibFabricInfoId, 2, values[0].SwatchCardImageName, values[0].SwatchCardIamgePath, 1).then((res) => {
          if (res.data) {
            props.LapdipSubmissionCallback(true, getOrderinfo, 'Save', res.data.outputResult)
          }
        })
      }, timeout);

    });
  }

  const downloadSwatchcard = () => {
    var FileName = values[0].SwatchCardImageName.substr(0, values[0].SwatchCardImageName.lastIndexOf('.'));
    fetch(window.$APIBaseURL + 'Images/LabdipSwatchCardPdf/' + FileName + '.pdf').then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const text = new Response(blob).text();
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'Submitted_' + FileName + '.pdf';
        alink.click();
        window.open(fileURL, "_blank");
      })
    })
  }

  const ViewFile = (event, filename) => { // event.preventDefault();
    setIsOpen(true);
    setImagename(filename);
  }

  const openFileUpload = () => {
    $('#upload-button').trigger('click');
  }
  const handlemultidd = (event, index) => {
    ;
    if (event.length === 0) {
      values[0].SendTo = "";
      values[0].SendToEdit = '';
      // values[index].SkuColorNameIds = "";
    } else {
      let strValue = "";
      for (let Sku of event) {
        if (strValue === "") {
          strValue = Sku.value.toString();
        } else {
          strValue = strValue + "," + Sku.value;
        }
      }
      values[0].SendTo = strValue;
      values[0].SendToEdit = event;
    }

    setInputFields(values);
  }
  const handleChange = (event, feild) => {
    ;
    let input = '';
    if (feild === "SubmittedDate") {
      values[0].SubmittedDate = event;
    }
    else if (feild === "Sendthrough") {

      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].SendThrough = parseInt(input);
      if (input === "2") {
        setshowawb(true);
      }
      else if (input === "1") {
        values[0].AWBDateandNo = ''
        values[0].AWBDate = ''
        values[0].AWBName = ''
        setSubmittedawb(false)
        setshowawb(false);
      }
      else {
        setshowawb(false);
      }
    }

    else if (feild === "Remarks") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].Remarks = input;
    }
    else if (feild === "FabricID") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].FabricID = input;
    }
    setInputFields(values);
  }
  const SaveAwbnoDate = () => {
   ;
    let values = [...inputFields]

    if (values[0].AWBName === "" || values[0].AWBDate === "" || values[0].AWBDate === null) {
      values[0].AWBDateandNo = values[0].AWBName + values[0].AWBDate;
      setShowPopup(true);
      setSubmittedawb(true)
    }
    else {
      values[0].AWBDateandNo = values[0].AWBName + " & " + values[0].AWBDate.toLocaleDateString('en-GB');
      setShowPopup(false);
    }
   ;
    setInputFields(values);
  }

  const CloseAwbpopup = () => {
    if (props.TaskStatus !== 3) {
      values[0].AWBName = '';
      values[0].AWBDate = '';
      values[0].AWBDateandNo = '';
      setInputFields(values);
    }
    setShowPopup(false);
  }
  const handlepopupchange = (event, feild) => {
    let input = '';

    if (feild === "AWBName") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].AWBName = input;
    }
    else if (feild === "AWBDate") {
      values[0].AWBDate = event;
    }
    setInputFields(values);
  }
  const ontransfer = async (event, FabricID) => {

    setFabricDetails([]);
    if (event.target.checked === true) {
      values[0].IsAllFabricLoad = 1;
      FabricService.GetFabricList(0, "F").then((response) => {
        ;
        if (response.data) {
          setFabricDetails(response.data)
        }
      })
    }
    if (event.target.checked === false) {
      values[0].IsAllFabricLoad = 0;
      await TnaService.ViewLabdipSubmissionApprovalList(props.LabdibFabricInfoId, IsProduction).then((response) => {
        if (response.data.labdibSubmitApprovalList.length > 0)
          values[0].FabricID = response.data.labdibSubmitApprovalList[0].fabricId;
      })
      await ProgramService.LoadStyleFabricDetailsList(props.StyleId).then((response) => {

        if (response.data) {
          setFabricDetails(response.data);


        }
      }).catch(() => { });

    }

    setInputFields(values)
  }
  //new


  const handleSubmitoptionchange = (event, feild, index) => {
   ;
    let input = '';
    if (event.target.value.trim() !== '') {
      input = event.target.value;
    }
    if (feild === "OptionName") {
      values[0].TNALabdibSubmittedOption[index].OptionName = input;
    }
    else if (feild === "SupplierReferenceNo") {
      values[0].TNALabdibSubmittedOption[index].SupplierReferenceNo = input;
    }
    if (values[0].TNALabdibSubmittedOption[index].OptionName !== '' && values[0].TNALabdibSubmittedOption[index].SupplierReferenceNo !== '') {
      let Existoption = values[0].TNALabdibSubmittedOption.filter(x =>
        x.OptionName.toLowerCase() === values[0].TNALabdibSubmittedOption[index].OptionName.toLowerCase()
        && x.SupplierReferenceNo.toLowerCase() === values[0].TNALabdibSubmittedOption[index].SupplierReferenceNo.toLowerCase())
      if (Existoption.length > 1) {
        values[0].TNALabdibSubmittedOption[index].SupplierReferenceNo = '';
        setSubmitted(true);
        Nodify('Warning!', 'warning', 'Supplier Reference No Already Exists.');
      }
    }
    setInputFields(values);

  }

  const OnChangeOrderinfo = (event, index) => {

    const orderinfo = [...getOrderinfo];
    let SelectedCount = 0;
    orderinfo.forEach(element => {
      if (element.selectedDetail === 1) {
        SelectedCount = SelectedCount + 1;
      }
    })
    if (event.target.checked === true) {
      setFabricDetails([]);
      values[0].IsAllFabricLoad = 0;
      if (SelectedCount === 1) {
        orderinfo[index].SelectedDetail = 0;
        Nodify('Warning!', 'warning', 'Please select only one.');
      }
      else {
        orderinfo[index].selectedDetail = 1;
      }
    }
    else {
      orderinfo[index].selectedDetail = 0;
    }
    setOrderinfo(orderinfo);

    let SelectedDetail = '';
    let SelectedStyleID = 0;
    orderinfo.forEach(element => {
      if (element.selectedDetail === 1) {
        SelectedDetail = element.fabricId;
        SelectedStyleID = element.styleId;
      }
    })

    if (SelectedStyleID !== 0) {
      ProgramService.LoadStyleFabricDetailsList(SelectedStyleID).then((response) => {
        if (response.data) {
          setFabricDetails(response.data);
        }
      }).catch(() => { });

      values[0].FabricID = SelectedDetail;
      setInputFields(values);
    }
    else {
      ProgramService.LoadStyleFabricDetailsList(props.StyleId).then((response) => {
        if (response.data) {
          setFabricDetails(response.data);
        }
      }).catch(() => { });

    }



  }



  const SaveSubmission = () => {
   

    let CheckSubmittedOption = true;

    values[0].TNALabdibSubmittedOption.forEach(element => {
      if (element.OptionName === '' || element.SupplierReferenceNo === '') {
        CheckSubmittedOption = false;
      }
    })
    if (values[0].FabricID === "") {
      Nodify("Warning!", "warning", "Please Select that checkbox in order information");
      setSubmittedpopup(true);
      // setSubmitted(true);
      setbuttonLoader(false);
    }
    if (values[0].SubmittedDate === '' || values[0].SubmittedDate === null || values[0].SendTo === "" || values[0].SendThrough === 0 ||

      values[0].FabricID === "0") {
      Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
      setSubmittedpopup(true);
      // setSubmitted(true);
      setbuttonLoader(false);
    }
    else if (CheckSubmittedOption === false) {
      Nodify("Warning!", "warning", "Please fill the mandatory(*) fields in Submitted Option Section.");
      setSubmitted(true);
      setbuttonLoader(false);
    }

    else {
      setbuttonLoader(true);
      values[0].SubmittedDate = new Date(values[0].SubmittedDate.setMinutes(values[0].SubmittedDate.getMinutes() + 370))
      if (values[0].AWBDate === '' || values[0].AWBDate === null) {
        values[0].AWBDate = ''
      }
      else {
        values[0].AWBDate = new Date(values[0].AWBDate.setMinutes(values[0].AWBDate.getMinutes() + 370))
      }
      values[0].SubmissionApprovalStatus = 1;
      values[0].Operation = props.editLabdipSubmission === false || props.editLabdipSubmission === undefined ? 1 : 2;
      values[0].FabricID = parseInt(values[0].FabricID)
      values[0].TNALabdibOrderInfo = getOrderinfo;
      TnaService.InsertUpdateLabdipSubmissionApproval(values[0]).then(res => {

        if (res.data.outputResult === "1") {
          Nodify('Success!', 'success', 'Lab Dip Submission Added Successfully');
          props.LapdipSubmissionCallback(true, getOrderinfo, 'Save', 0)
        } else if (res.data.outputResult === "2") {
          Nodify('Success!', 'success', 'Lab Dip Submission Updated Successfully');
          props.LapdipSubmissionCallback(true, getOrderinfo, 'Update', 0)
        }
        else if (res.data.outputResult === "0") {
          Nodify("Error!", "Error Occured!");
        }
      });
    }

  }

  const handleRemoveOrderInfo = (index) => {

    const value = [...getOrderinfo]
    value.splice(index, 1);
    setOrderinfo(value);

  }

  const OnSelectRecords = (event, index) => {
    const value = [...MultiCloseDatas];

    if (event.target.checked === true) {
      value[index].isSelected = 1;
    }
    else {
      value[index].isSelected = 0;
      setselectall(0);
    }
    setMultiCloseDatas(value);
  }

  const SelectAll = (event) => {
    let IsSelected = 0;

    if (event.target.checked === true) {
      IsSelected = 1;
      MultiCloseDatas.forEach(element => {
        if (element.Depandency !== '' && element.dependencyStartstop === 1) {
          element.isSelected = 1;
        }
        else if (element.Depandency === '') {
          element.isSelected = 1;
        }
      })
    }
    else {
      IsSelected = 0;
      MultiCloseDatas.forEach(element => {
        element.isSelected = 0;
      })
    }
    setselectall(IsSelected);
  }

  const AddRows = () => {

    let FileteredRecords = MultiCloseDatas.filter(x => x.isSelected === 1);
    if (FileteredRecords.length === 0) {
      Nodify("Warning!", "warning", "Select atleast one row.");
    }
    else {
      setshowMultiClosediv(false);
      setshowSubmissiondiv(true);
      setOrderinfo(FileteredRecords);
    }
  }

  const ShowHideDivs = () => {
    setshowMultiClosediv(true);
    setshowSubmissiondiv(false);
  }

  function getSelectedDependencyMap(SelectedDependency) {
    let Dependency = true;
    if (SelectedDependency) {
      let arrstatus = [];
      arrstatus = SelectedDependency.split(',');
      if (arrstatus.length > 0) {
        arrstatus.map(element => {
          let val = [];
          val = element.split('-');
          let valIndex = props.values[parseInt(val[0]) - 1].StatusID;
          if (valIndex !== 3) {
            Dependency = false;
          }
        });
      }

    }

    return Dependency;
  }

  const handleChangeMultiDependentStart = (event, feild, IsSingleTask) => {
    values[0].submitDependencyStart = false;

    let input = '';

    if (feild === "Comment") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      values[0].MultiDependencyComment = input;
    }
    else {
      values[0].MultiDependencyDate = event;
    }
    setInputFields(values);

  }

  const OpenAllDependentStartPopup = () => {
    showLoader();

    values[0].hasAllDependencyStart = 1;
    values[0].hasAllDependencyStartPopup = true;

    setInputFields(values);
    hideLoader();
  }

  const CloseAllDependencyStartPopup = () => {
    values[0].hasAllDependencyStartPopup = false;
    setInputFields(values);

  }

  function convertUTCDateToLocalDate(date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }

  const handleSaveAllDependencyStart = () => {
    ;
    showLoader();
    let dependencyComments = "";
    let dependencydate = null;
    let CommonIdList = "";
    ;
    dependencyComments = values[0].MultiDependencyComment;
    dependencydate = values[0].MultiDependencyDate;

    if (!dependencyComments || !dependencydate) {
      values[0].submitDependencyStart = true;
      setInputFields(values);
      Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
      hideLoader();
      return false;
    }
    else {

      let ManualCloseData = [...MultiCloseDatas];
      let ManualCloseFilter = ManualCloseData.filter(d => d.dependencyStartstop !== 1);

      ManualCloseFilter.map((item) => {
        if (CommonIdList === "") {
          CommonIdList = item.subTaskInfoId.toString();
        } else {
          CommonIdList = CommonIdList + "," + item.subTaskInfoId.toString();
        }

        //item.dependencyStartstop = 1;

      });

      let params = {
        TNAId: props.TNAId,
        TaskDetailId: props.commonParams.TaskID,
        CommonIdList: CommonIdList,
        DependencyDate: convertUTCDateToLocalDate(new Date(dependencydate)),
        Comments: dependencyComments,
        IsSingleTask: 0,
        CreatedBy: currentUser.employeeinformationID
      }

      TnaService.InsertTNAMultiDependencyStartHistory(params).then((res) => {
        if (res.data.outputResult === "1") {
          ManualCloseData.map((item) => {
            item.dependencyStartstop = 1;
            item.IsDepandency = 0;
          });

          values[0].hasAllDependencyStartPopup = false;
          setMultiCloseDatas(ManualCloseData);

          setInputFields(values);
          Nodify('Success!', 'success', "Dependency Added Successfully");
          hideLoader();
        } else if (res.data.outputResult === "2") {
          Nodify('Warning!', 'warning', "This dependency date already added");
          hideLoader();
        } else if (res.data.outputResult === "-1") {
          Nodify('Warning!', 'warning', "Error Occured");
          hideLoader();
        }
      })
    }
  }
  const PdfOnhide = (value) => {

    setSwatchDownloadformat(value)
  }

  const Delcallback = (value) => {

    if (value === true) {
      setID({ showPopupDelete: false, Params: {} });
      props.LapdipSubmissionCallback(true, getOrderinfo, 'Delete')
    }
    else {
      setID({ showPopupDelete: false, Params: {} });
      props.LapdipSubmissionCallback(false, '', '', 0)
    }
  }


  // const styles = StyleSheet.create({
  //   page: {
  //     flexDirection: 'row',
  //   },
  //   section: {
  //     flexGrow: 1,
  //   },
  // });




  const GenerateLabdipSwatchCardPdf = (CubeorBuyer) => {
    ;
    let Isvalid = true;
    if (Swatchdownload.Format === "0") {
      Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
      setSubmittedSwatchFormat(true);
      Isvalid = false;
    }
    else {
      if (Swatchdownload.Format === '1') {
        Isvalid = true;
      }
      else if (values[0].BuyerName === "Focus International" && Swatchdownload.Format === '2') {
        Isvalid = true;
      } else {
        Isvalid = false;
        Swatchdownload.Format = '0';
        Nodify('Warning!', 'warning', 'Buyer only selectable in FocusInternational');
      }
    }
    if (Isvalid) {
      setSwatchDownloadformat(false);
      var ValAllSubmittedOption = [...AllsubmitoptiosLabdipPdf]
      TnaService.ViewLabdipSubmissionApprovalList(props.LabdibFabricInfoId, IsProduction).then((response) => {
        if (response.data) {
          setbasicdataLabdipPdf(response.data.labdibSubmitApprovalList);
          setsubmitoptiosRefnoLabdipPdf([]);

          var filtereddata = [...new Map(response.data.labdibSubmittedOption.map(item =>
            [item['supplierReferenceNo'], item])).values()];

          var rowsanddatacount = [];
          var SumofDataCounts = [];
          var NoofRows = 0; var datacount = 0; var datacountbal = 0;
          filtereddata.map(rn => {
            var curr = 0;
            datacount = response.data.labdibSubmittedOption.filter(x => x.supplierReferenceNo === rn.supplierReferenceNo).length;
            datacountbal = datacount;

            // for get no of rows going to be fetch in pdf
            if (datacount > 3) {
              if (datacount % 3 === 0) {
                NoofRows = NoofRows + (datacount / 3);
                curr = curr + (datacount / 3);
              }
              else {
                // NoofRows = NoofRows + Math.round((datacount / 3) + 1);
                // curr = curr + Math.round((datacount / 3) + 1);
                var beforeroundoff = parseInt(datacount / 3);
                var afterroundoff = parseInt(Math.round((datacount / 3)));
                if (beforeroundoff === afterroundoff) {
                  NoofRows = NoofRows + Math.round((datacount / 3) + 1);
                  curr = curr + Math.round((datacount / 3) + 1);
                }
                else {
                  NoofRows = NoofRows + Math.round((datacount / 3));
                  curr = curr + Math.round((datacount / 3));
                }
              }
            }
            else {
              NoofRows = NoofRows + 1;
              curr = curr + 1;
            }

            //for get no of records for each row (i.e no of options)
            for (var i = 1; i <= curr; i++) {
              // if (datacountbal !== 0) {
              if (datacountbal > 3) {
                rowsanddatacount.push({
                  count: 3
                })
                if (i === 1) {
                  datacountbal = datacount - 3;
                }
                else {
                  datacountbal = datacountbal - 3;
                }
              }
              else {
                rowsanddatacount.push({
                  count: datacountbal
                })
              }

              //}
            }

          })

          //add multiple row counts into single value for get no of sheets
          var addedcount = 0; var totalcountforpdf = 0; var pageno = 0;
          for (var j = 0; j < rowsanddatacount.length; j++) {
            totalcountforpdf = totalcountforpdf + rowsanddatacount[j].count;
            addedcount++;
            if (addedcount === 3 || (j === rowsanddatacount.length - 1)) {
              SumofDataCounts.push({
                totcountfotpdf: totalcountforpdf,
                Pageno: pageno + 1
              })
              pageno = pageno + 1;
              totalcountforpdf = 0;
              addedcount = 0;
            }

          }

          setrowsanddatacountforPdf(SumofDataCounts);
          var subvalue = [...submitoptiosRefnoLabdipPdf]
          var fixedrows = [];
          var slicestart = 0; var sliceend = 0;
          //split datas for bind multiple sheets
          for (var k = 1; k <= SumofDataCounts.length; k++) {

            if (k === 1) {
              slicestart = 0;
              sliceend = SumofDataCounts[0].totcountfotpdf;
              fixedrows = [...new Map(response.data.labdibSubmittedOption.sort((a, b) => a.supplierReferenceNo - b.supplierReferenceNo).slice(slicestart, sliceend).map(item =>
                [item['supplierReferenceNo'], item])).values()];

              fixedrows.map(item => {
                subvalue.push({
                  Refno: item.supplierReferenceNo,
                  Pageno: k
                })
              })
              setsubmitoptiosRefnoLabdipPdf(subvalue);
              var SlicedData = response.data.labdibSubmittedOption.sort((a, b) => a.supplierReferenceNo - b.supplierReferenceNo).slice(slicestart, sliceend);
              SlicedData.map(pg => { pg.Pageno = k })
              //  setsubmitoptiosLabdipPdf(SlicedData);
              ValAllSubmittedOption.push(
                SlicedData
              )

              setAllsubmitoptiosLabdipPdf(ValAllSubmittedOption);

            }
            else {

              let startno = 0;
              for (let x = 0; x < k - 1; x++) {
                startno = startno + SumofDataCounts[x].totcountfotpdf;
              }
              slicestart = startno;
              sliceend = SumofDataCounts[k - 1].totcountfotpdf;
              fixedrows = [...new Map(response.data.labdibSubmittedOption.sort((a, b) => a.supplierReferenceNo - b.supplierReferenceNo).slice(slicestart, slicestart + sliceend).map(item =>
                [item['supplierReferenceNo'], item])).values()];

              fixedrows.map(item => {
                subvalue.push({
                  Refno: item.supplierReferenceNo,
                  Pageno: k
                })
              })
              setsubmitoptiosRefnoLabdipPdf(subvalue);

              var AdditionalSlicedData = response.data.labdibSubmittedOption.sort((a, b) => a.supplierReferenceNo - b.supplierReferenceNo).slice(slicestart, slicestart + sliceend);
              AdditionalSlicedData.map(pg => { pg.Pageno = k })
              //  setsubmitoptiosLabdipPdf(AdditionalSlicedData);
              ValAllSubmittedOption.push(
                AdditionalSlicedData
              )

              setAllsubmitoptiosLabdipPdf(ValAllSubmittedOption);
            }


          }

          setSwatchdownload({ Condition: true, Format: Swatchdownload.Format });
          // setSwatchCubeorBuyer(CubeorBuyer)
          DownloadLabdipSwatchCardPdf(CubeorBuyer);


        }
      }).catch(() => { });
    }

  }

  const DownloadLabdipSwatchCardPdf = (Format) => {
    var timeout = 5000;
    showLoader();
    const doc = new jsPDF('p', 'pt', 'a4', true);
    const Page1 = document.getElementById("Page1");
    html2canvas(Page1, { scale: 4 }).then(canvas => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const imgWidth = 630;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');
      if (document.getElementById("Page2") !== null) {
        const Page2 = document.getElementById("Page2");
        html2canvas(Page2, { scale: 4 }).then(canvas => {
          const imgData = canvas.toDataURL('image/png', 1.0);
          const imgWidth = 630;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');


        })
      }
      if (document.getElementById("Page3") !== null) {
        const Page3 = document.getElementById("Page3");
        html2canvas(Page3, { scale: 4 }).then(canvas => {
          const imgData = canvas.toDataURL('image/png', 1.0);
          const imgWidth = 630;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');


        })
      }
      if (document.getElementById("Page4") !== null) {
        const Page4 = document.getElementById("Page4");
        html2canvas(Page4, { scale: 4 }).then(canvas => {
          const imgData = canvas.toDataURL('image/png', 4);
          const imgWidth = 630;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');


        })
      }

      // if (document.getElementById("Page5") !== null) {
      //   const Page5 = document.getElementById("Page5");
      //   html2canvas(Page5, { scale: 4 }).then(canvas => {
      //     const imgData = canvas.toDataURL('image/png', 1.0);
      //     const imgWidth = 630;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

      //   })
      // }
      // if (document.getElementById("Page6") !== null) {
      //   timeout = 10000;
      //   const Page6 = document.getElementById("Page6");
      //   html2canvas(Page6, { scale: 5 }).then(canvas => {
      //     const imgData = canvas.toDataURL('image/png', 1.0);
      //     const imgWidth = 630;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

      //   })
      // }

      // if (document.getElementById("Page7") !== null) {
      //   const Page7 = document.getElementById("Page7");
      //   html2canvas(Page7, { scale: 5 }).then(canvas => {
      //     const imgData = canvas.toDataURL('image/png', 1.0);
      //     const imgWidth = 630;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

      //   })
      // }

      // if (document.getElementById("Page8") !== null) {
      //   const Page8 = document.getElementById("Page8");
      //   html2canvas(Page8, { scale: 5 }).then(canvas => {
      //     const imgData = canvas.toDataURL('image/png', 1.0);
      //     const imgWidth = 630;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

      //   })
      // }

      // if (document.getElementById("Page9") !== null) {
      //   const Page9 = document.getElementById("Page9");
      //   html2canvas(Page9, { scale: 5 }).then(canvas => {
      //     const imgData = canvas.toDataURL('image/png', 1.0);
      //     const imgWidth = 630;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

      //   })
      // }

      // if (document.getElementById("Page10") !== null) {
      //   const Page10 = document.getElementById("Page10");
      //   html2canvas(Page10, { scale: 5 }).then(canvas => {
      //     const imgData = canvas.toDataURL('image/png', 1.0);
      //     const imgWidth = 630;
      //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //     doc.addPage();
      //     doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight, undefined, 'FAST');

      //   })
      // }
      setTimeout(() => {
        var Format = Swatchdownload.Format === "1" ? "Cube" : "Buyer";
        doc.save(Format + "LabdipSwatchCard_" + props.LabdibFabricInfoId + ".pdf");
        // doc.save('dataurlnewwindow');
        doc.output('dataurlnewwindow', { filename: Format + "LabdipSwatchCard_" + props.LabdibFabricInfoId + ".pdf" });
        hideLoader();
        setSwatchdownload({ Condition: false, Format: '' });

        TnaService.UpdateLabdipSwatchStatus(props.LabdibFabricInfoId, 1, '', '', 1).then((res) => {
          if (res.data) {
            props.LapdipSubmissionCallback(true, getOrderinfo, 'Save', res.data.outputResult)

          }
        })
      }, timeout);

    });

  }

  const handleSwatchFormat = (event, feild) => {

    let input = '';
    if (event.target.value !== '') {
      input = event.target.value;
    }

    if (feild === "Format") {
      Swatchdownload.Format = input;

    }
    setSwatchdownload({ Format: Swatchdownload.Format, RowsandColumn: Swatchdownload.RowsandColumn });


  }

  const CustomInput = (props) => {
    return (
      <input
        className="form-control DatePickerCalenderHoliday"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        placeholder="DD/MM/YYYY"
        style={{
          border: submittedpopup && props.value === '' ? '1px solid red' : '', textTransform: "uppercase"
        }}
      />
    )
  }
  const CustomInputAWB = (props) => {
    return (
      <input
        className="form-control DatePickerCalenderHoliday"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        placeholder="DD/MM/YYYY"
        style={{ border: submittedawb && props.value === '' ? '1px solid red' : '' }}
      />
    )
  }
  // const MultiValueLabel = (props) => {
  //   const { innerProps, children, data } = props;
  //   return (
  //     <div title={data.name} {...innerProps}>
  //       {children}
  //     </div>
  //   );
  // };







  return (
    // <Modal show={showpopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => handlePopupClose()}>
    //     <Modal.Header closeButton>
    //     <Modal.Title>Add Group Type</Modal.Title>
    //     </Modal.Header>
    //       <Modal.Body>
    <div id="registration-form">
      <Form>
        {
          showMultiClosediv === true ?

            <>

              <div className="row">
                <div className="col-lg-12">
                  <div className="alert alert-info">
                    Note : If you want to submit multiple, you can select multiple items.
                  </div>
                  <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                    {
                      MultiCloseDatas.length !== 0 ?

                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Style Name</th>
                              <th>Style No</th>
                              <th>PO/ID No</th>
                              <th>Details</th>
                              <th>Task Holder / Follower</th>
                              <th>
                                <label style={{ marginTop: '8px' }}>
                                  <input type="checkbox" name="IsSelected"

                                    onChange={event => (SelectAll(event))}
                                    checked={selectall === 1 ? true : false}
                                    className="colored-blue clearOpacity" />
                                  <span className="text"></span>
                                </label> &nbsp;&nbsp;&nbsp;


                                {/* {props.commonParams &&
                                  props.commonParams.SelectedDependency &&
                                  getSelectedDependencyMap(props.commonParams.SelectedDependency) === false &&

                                  <button type="button" className={props.commonParams.hasAllDependencyStart === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                    onClick={() => OpenAllDependentStartPopup()}
                                  >
                                    <i className={props.commonParams.hasAllDependencyStart === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>
                                  </button>
                                } */}
                                <span style={{ float: 'right' }}>
                                  <button type="button" className={props.commonParams.hasAllDependencyStart === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                    onClick={() => OpenAllDependentStartPopup()}
                                  >
                                    <i className={props.commonParams.hasAllDependencyStart === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>
                                  </button>
                                </span>

                              </th>

                            </tr>
                          </thead>
                          <tbody>
                            {

                              MultiCloseDatas.map((item, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{item.styleName}</td>
                                      <td>{item.styleNo}</td>
                                      <td>{item.idpoNo}</td>
                                      <td>{item.fabricDetails}</td>
                                      <td>{item.taskOwnerNameList}</td>
                                      <td className="Actionbutton fixed-column">
                                        <div style={{ width: "80px" }}>
                                          <label style={{ marginTop: '8px' }}>
                                            {
                                              props.commonParams !== undefined ?
                                                // (childdata.dependencyStartstop === 1 || childdata.isSelected === 1 || (!props.commonParams.SelectedDependency) || getSelectedDependencyMap(props.commonParams.SelectedDependency) === true
                                                // ) &&
                                                (item.Depandency !== '' && item.dependencyStartstop === 1) ?
                                                  <input type="checkbox" name="IsSelected"
                                                    onChange={event => (OnSelectRecords(event, index))}
                                                    checked={item.isSelected === 1 ? true : false}
                                                    className="colored-blue clearOpacity" />
                                                  :
                                                  item.Depandency === '' ?
                                                    <input type="checkbox" name="IsSelected"
                                                      onChange={event => (OnSelectRecords(event, index))}
                                                      checked={item.isSelected === 1 ? true : false}
                                                      className="colored-blue clearOpacity" /> : ''

                                                :
                                                <input type="checkbox" name="IsSelected"
                                                  onChange={event => (OnSelectRecords(event, index))}
                                                  checked={item.isSelected === 1 ? true : false}
                                                  className="colored-blue clearOpacity" />

                                            }
                                            <span className="text"></span>
                                          </label>&nbsp;&nbsp;&nbsp;

                                          {
                                            // props.commonParams && props.commonParams.SelectedDependency &&
                                            //  getSelectedDependencyMap(props.commonParams.SelectedDependency) === false &&
                                            item.Depandency !== '' &&
                                            <button type="button" className={item.dependencyStartstop === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)" >
                                              <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>
                                            </button>
                                          }

                                        </div>

                                      </td>
                                    </tr>
                                  </>

                                )
                              })
                            }

                          </tbody>
                        </table>
                        :
                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                          <TableHeader headers={headers} />
                          <tbody>
                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                          </tbody>
                        </table>
                    }
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-sm-12" align="right">
                  <button type="button" className="btn btn-success" onClick={() => AddRows()}>

                    <i className="fa fa-check right"></i> Add
                  </button>
                </div>
              </div>
            </>
            : ''
        }
        {
          showSubmissiondiv === true ?
            <>
              <div className="row" style={{ overflowX: "hidden", height: "700px", padding: "5px", margin: "0px" }}>
                <div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="BuyerID">
                          Buyer-Season-Brand
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <span className="input-icon icon-right">
                          <input
                            placeholder="Buyer-Season-Brand"
                            className="form-control"
                            title={inputFields[0].BuyerBrandSeasonName}
                            name=" Buyer-Season-Brand"
                            id={" Buyer-Season-Brand"}
                            value={inputFields[0].BuyerBrandSeasonName}
                            disabled

                          ></input>
                        </span>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="SupplierID">
                          Supplier
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <span className="input-icon icon-right">
                          <input
                            placeholder="Supplier"
                            className="form-control"
                            title={inputFields[0].SupplierName}
                            name="SupplierID"
                            id={"SupplierID"}
                            value={inputFields[0].SupplierName}
                            disabled

                          ></input>
                        </span>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="Name">
                          Submit Date
                          <span className="text-danger">*</span>
                        </label>
                        <span className="input-icon icon-right">
                          {
                            props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ?

                              <DatePicker className="form-control" name="SubmittedDate"
                                id={
                                  "SubmittedDate"
                                }
                                selected={
                                  inputFields[0].SubmittedDate
                                }

                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                dropdownMode="scroll"
                                autoComplete="off"
                                customInput={<CustomInput />}
                                disabled
                              />

                              // <input
                              //   placeholder="Supplier"
                              //   className="form-control"
                              //   title={inputFields[0].SubmittedDate}
                              //   name="SupplierID"
                              //   id={"SupplierID"}
                              //   value={inputFields[0].SubmittedDate}
                              //   disabled

                              // ></input>
                              :
                              <DatePicker className="form-control" name="SubmittedDate"
                                id={
                                  "SubmittedDate"
                                }
                                selected={
                                  inputFields[0].SubmittedDate
                                }
                                onChange={event => handleChange(event, 'SubmittedDate')}
                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                // style={{
                                //   border: submittedpopup && inputFields[0].SubmittedDate === '' ? '1px solid red' : '', textTransform: "uppercase"
                                // }}
                                dropdownMode="scroll"
                                autoComplete="off"
                                customInput={<CustomInput />}
                                isClearable={inputFields[0].SubmittedDate === null ? false : true}
                              />

                            // <input
                            //   id="SubmittedDate"
                            //   name="SubmittedDate"
                            //   value={inputFields[0].SubmittedDate}
                            //   placeholder="DD/MM/YYYY"
                            //   type="date"
                            //   autoComplete="off"
                            //   className={"form-control"}
                            //   onKeyDown={(e) => e.preventDefault()}
                            //   style={{
                            //     border: submittedpopup && inputFields[0].SubmittedDate === '' ? '1px solid red' : '', textTransform: "uppercase"
                            //   }}
                            //   onChange={event => handleChange(event, 'SubmittedDate')}
                            // />

                          }

                        </span>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor={"SendToID"}>Send To<span className="text-danger">*</span></label>

                        {
                          (props.editLabdipSubmission === false || chkDataExists === true) && props.TaskStatus === 3 ?
                            <span>
                              <input
                                className="form-control"
                                title={inputFields[0].SendToDisplay}
                                value={inputFields[0].SendToDisplay}
                                disabled
                              />
                            </span> :
                            <span title={Array.isArray(inputFields[0].SendToEdit) ? inputFields[0].SendToEdit.map(item => item.name).join(', ') : ''}>
                              <Reactselect
                                className="basic-single SentTo SendToHeight"
                                name={"Sku"}
                                id={
                                  "Sent To"
                                }
                                // value={item.MeasurementPoints}
                                //isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isSearchable={true}
                                options={getTaskholder}
                                value={inputFields[0].SendToEdit}
                                isMulti
                                onChange={event => handlemultidd(event, 'SendTo')}
                                menuPosition="fixed"
                                styles={submittedpopup && inputFields[0].SendTo === "" ? styles2 : styles1}
                              // readOnly
                              // isDisabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                              />
                            </span>



                        }



                        {/* <span className="input-icon icon-right">


                          <select id={"SendTo"} name="SendTo" className="form-select"
                            value={inputFields[0].SendTo}
                            onChange={(event) => handleChange(event, 'SendTo')}
                            style={{ border: submittedpopup && inputFields[0].SendTo === "0" ? '1px solid red' : '' }}
                            disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                          >
                            <option value="0">-Select SendTo-</option>
                            {
                              getTaskholder.map(item => (
                                <option key={item.id} value={item.value}>
                                  {item.label}
                                </option>
                              ))
                            }
                          </select>
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">


                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor={"StyleName"}>
                          Style Name
                          <span className="text-danger">*</span>
                        </label>
                        <span className="input-icon icon-right">
                          <input
                            placeholder="Enter the StyleName"
                            className="form-control"
                            title={inputFields[0].StyleName}
                            name="StyleName"
                            id={"StyleName"}
                            value={inputFields[0].StyleName}
                            disabled

                          ></input>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor={" StyleNoID"}>
                          Style No
                          <span className="text-danger">*</span>
                        </label>
                        <span className="input-icon icon-right">
                          <input
                            placeholder="Enter the  StyleNo"
                            className="form-control"
                            title={inputFields[0].StyleNo}
                            name=" StyleNo"
                            id={" StyleNo"}
                            value={inputFields[0].StyleNo}
                            disabled

                          ></input>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor={"ID/PO No"}>
                          PO/ID No
                          <span className="text-danger">*</span>
                        </label>
                        <span className="input-icon icon-right">
                          <input
                            placeholder="Enter the PO/ID No"
                            className="form-control"
                            title={inputFields[0].IdpoNo}
                            name="ID/PO No"
                            id={"ID/PO No"}
                            value={inputFields[0].IdpoNo}
                            disabled

                          ></input>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor={"Endcustomer"}>
                          End Customer
                          <span className="text-danger">*</span>
                        </label>
                        <span className="input-icon icon-right" style={{ width: "100%" }}>
                          <input
                            placeholder="Enter the End Customer"
                            className="form-control"
                            title={inputFields[0].EndCustomer}
                            name="End Customer No"
                            id={"End Customer"}
                            value={inputFields[0].EndCustomer}
                            disabled

                          ></input>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor={"Sendthrough"}>Send Through<span className="text-danger">*</span></label>
                        <select id={"Sendthrough"} name="Sendthrough" className="form-select"
                          value={inputFields[0].SendThrough}
                          onChange={event => handleChange(event, 'Sendthrough')}
                          style={{ border: submittedpopup && inputFields[0].SendThrough === 0 ? '1px solid red' : '' }}
                          disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                        >
                          <option value="0" key="0" style={{ color: '#bfdea1' }}> - Select Send Through- </option>
                          <option value="1" key="1">Hand Carried</option>
                          <option value="2" key="2">Courier - DHL</option>
                        </select>
                      </div>
                    </div>
                    {
                      showawb === true ?
                        <div className="col-sm-3">
                          <div className='form-group'>
                            <label htmlFor="Awbdate" >AWB/Date</label>
                            <span className='input-icon icon-right'>
                              <input
                                placeholder="AWB/Date"
                                className="form-control"
                                name="AWBDateandNo"
                                id={
                                  "AWBDateandNo"
                                }
                                onKeyDown={(e) => e.preventDefault()}
                                value={inputFields[0].AWBDateandNo}
                                // style={{ border: submittedpopup && inputFields[0].AWBDateandNo === '' ? '1px solid red' : '' }}
                                onClick={() => setShowPopup(true)}
                                disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                              ></input>
                            </span>
                          </div>
                        </div> : ''
                    }
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="tabbable">
                      <ul
                        className="nav nav-tabs nav-justified"
                        id="myTab5"
                      >
                        <li
                          className="active">
                          <a
                            data-toggle="tab"
                            href="#idSubmitInformation"
                          >
                            <h5>Submit Information</h5>
                          </a>
                        </li>

                        <li
                          className=""
                        >
                          <a
                            data-toggle="tab"
                            href="#idOrderInformation"
                          >
                            <h5>Order Information</h5>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div id="idSubmitInformation" className="tab-pane in active" style={{ width: "100%" }}>
                          <div className="row">
                            <div className="col-sm-10">
                              <div className="row">
                                <div className="col-sm-3">
                                  <div className='form-group'>
                                    {
                                      inputFields[0].PantoneTypeName !== "Others" ?
                                        <>
                                          <label htmlFor="BuyerID">Pantone #</label>
                                          <span className='input-icon icon-right' title={inputFields[0].PantoneTypeName + '-' + inputFields[0].PantoneCode + "-" + inputFields[0].PantoneNameOrSwatchName}>
                                            <Input
                                              placeholder="Pantone #"
                                              className="form-control"

                                              name="PantoneCodeandType"
                                              id={
                                                "PantoneCodeandType"
                                              }

                                              value={
                                                inputFields[0].PantoneTypeName + '-' + inputFields[0].PantoneCode + "-" + inputFields[0].PantoneNameOrSwatchName
                                              }
                                              disabled

                                            ></Input>
                                          </span>
                                        </>
                                        :

                                        <>
                                          {
                                            inputFields && inputFields[0] &&
                                            <>
                                              <label htmlFor="SwatchName">Swatch Name<span className="text-danger">*</span></label>
                                              <span className='input-icon icon-right'>
                                                <input
                                                  placeholder="Swatch Name"
                                                  className="form-control"
                                                  // title={inputFields[0].PantoneNameOrSwatchName}
                                                  name="SwatchName"
                                                  id={
                                                    "SwatchName"
                                                  }
                                                  value={
                                                    inputFields[0].PantoneNameOrSwatchName
                                                  }
                                                  disabled

                                                ></input>
                                              </span>
                                            </>
                                          }
                                        </>

                                    }

                                  </div>
                                </div>

                                <div className="col-sm-3">
                                  <div className='form-group'>
                                    <label htmlFor="SeasonID" >Ref.Color Name</label>
                                    <span className='input-icon icon-right' title={inputFields[0].RefColorName}>
                                      <Input
                                        placeholder="Ref.Color Name"
                                        className="form-control"
                                        title={inputFields[0].RefColorName}
                                        name="RefColorName"
                                        id={
                                          "RefColorName"
                                        }
                                        value={
                                          inputFields[0].RefColorName
                                        }
                                        
                                        disabled
                                      ></Input>
                                    </span>
                                  </div>
                                </div>


                                <div className="col-sm-3">
                                  <div className="form-group">
                                    {
                                      inputFields[0].PantoneTypeName !== "Others" ?
                                        <>
                                          <label htmlFor={"Quarter"}>Color / Image</label>
                                          {
                                            inputFields[0].PantoneColorOrOrSwatchImage !== '' ?
                                              <div style={{ background: inputFields[0].PantoneColorOrOrSwatchImage, width: '100%', height: '34px', borderRadius: '2px' }}></div>

                                              :
                                              <div style={{ width: '100%', height: '34px', borderRadius: '2px' }}></div>
                                          }
                                        </> :
                                        <>
                                          <img src={inputFields[0].PantoneColorOrOrSwatchImage === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].PantoneColorOrOrSwatchImage}
                                            alt="Picture" style={{ marginBottom: '5px', height: '100px', width: '50%', border: "1px solid #d5d5d5" }}

                                          />
                                        </>
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor={"FabricID"}>

                                      Fabric Details<span className="text-danger">*</span></label>


                                    <span className="input-icon icon-right">
                                      {
                                        //props.TaskStatus !== 3 ?
                                        <select id={"FabricID"} name="FabricID" className="form-select"
                                          value={inputFields[0].FabricID}
                                          onChange={(event) => handleChange(event, 'FabricID')}
                                          style={{ border: submittedpopup && inputFields[0].FabricID === "0" ? '1px solid red' : '' }}
                                          disabled={(props.editLabdipSubmission === false || chkDataExists === true) && props.TaskStatus === 3 ? true : false}
                                        >
                                          <option value="0">-Select Fabric-</option>
                                          {
                                            FabricDetails.map(item => (
                                              <option key={item.id} value={item.value}>
                                                {item.label}
                                              </option>
                                            ))
                                          }
                                        </select>
                                        // <select id={"FabricID"} name="FabricID" className="form-select"
                                        //   value={inputFields[0].FabricID}
                                        //   style={{ border: submittedpopup && inputFields[0].FabricID === "0" ? '1px solid red' : '' }}
                                        //   disabled={props.TaskStatus === 3 ? true : false}
                                        // />
                                      }
                                    </span>


                                  </div>
                                </div>
                                <div className="col-lg-3">

                                  <label style={{ marginTop: '32px' }}>
                                    <input type="checkbox" name="SelectedDetail"
                                      className="colored-blue clearOpacity"
                                      onChange={event => (ontransfer(event, values[0].FabricID))}
                                      checked={values[0].IsAllFabricLoad === 1 ? 1 : 0}
                                      disabled={(props.editLabdipSubmission === false || chkDataExists === true) && props.TaskStatus === 3 ? true : false}
                                      style={{ border: "1px solid blue" }}
                                    />

                                    <span className="text"></span>
                                    <label htmlFor="">Load All Fabric</label>
                                  </label>


                                </div>
                              </div>



                              <div>
                                <table>
                                  <tr>
                                    <td><label>Submit Options</label> </td>
                                    <td><label>Submission Swatch Card
                                      <br></br>(Click to download)</label></td>
                                  </tr>
                                  <tr>
                                    <td> <div className="col-sm-6" style={{ paddingLeft: "0%" }}>
                                      <div className="form-group">
                                        {/* <label htmlFor={"ReceivedOn"}>Submit Options<span className="text-danger">*</span></label> */}

                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup"
                                          style={{ width: "500px" }}>
                                          <thead>
                                            <tr>
                                              <th >Options <span className="text-danger">*</span></th>
                                              <th>Supplier Reference No <span className="text-danger">*</span></th>
                                              <th >Action</th>

                                            </tr>

                                          </thead>
                                          <tbody>
                                            {
                                              inputFields[0].TNALabdibSubmittedOption.map((item, index) => {
                                                return (
                                                  <>
                                                    <tr>
                                                      <td>
                                                        <span className="input-icon icon-right">
                                                          <input
                                                            placeholder="Option Name"
                                                            className="form-control"
                                                            title={item.OptionName}
                                                            name="OptionName"
                                                            id={
                                                              "OptionName"
                                                            }
                                                            value={
                                                              item.OptionName
                                                            }
                                                            onChange={event => (handleSubmitoptionchange(event, 'OptionName', index))}
                                                            style={{ width: "250px", border: submitted && item.OptionName === '' ? '1px solid red' : '' }}
                                                            disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                          />


                                                        </span>
                                                      </td>
                                                      <td>
                                                        <span className='input-icon icon-right'>
                                                          <input
                                                            placeholder=""
                                                            className="form-control cut_text"
                                                            title={item.SupplierReferenceNo}
                                                            name="SupplierReferenceNo"
                                                            id={
                                                              "SupplierReferenceNo"
                                                            }
                                                            value={
                                                              item.SupplierReferenceNo
                                                            }
                                                            autocomplete="off"
                                                            onChange={event => (handleSubmitoptionchange(event, 'SupplierReferenceNo', index))}

                                                            style={{ width: "250px", border: submitted && item.SupplierReferenceNo === '' ? '1px solid red' : '' }}
                                                            disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                                          />
                                                        </span>

                                                      </td>
                                                      <td className="Actionbutton fixed-column">
                                                        {" "}
                                                        <div style={{ width: "80px" }}>
                                                          {inputFields[0].TNALabdibSubmittedOption.length !== 1 && (
                                                            <button
                                                              type="button"
                                                              className="btn  btn-xs  btn-danger"
                                                              title="Delete option"
                                                              onClick={() =>
                                                                handleRemoveFields(index)
                                                              }
                                                              disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || props.editLabdipSubmission === undefined || chkDataExists === true) ? true : false}
                                                            >
                                                              <i className="fa fa-trash-o"></i>
                                                            </button>
                                                          )}
                                                          &nbsp;
                                                          {inputFields[0].TNALabdibSubmittedOption.length === index + 1 && (
                                                            <button
                                                              type="button"
                                                              className="btn  btn-xs  btn-success"
                                                              title="Add option"
                                                              onClick={() =>
                                                                handleAddField(index)
                                                              }
                                                              disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || props.editLabdipSubmission === undefined || chkDataExists === true) ? true : false}
                                                            >
                                                              <i className="fa fa-plus"></i>
                                                            </button>
                                                          )}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </>

                                                )
                                              })
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                    </div></td>
                                    <td>
                                      <div className={ImgClassName}
                                        htmlFor="upload-button"
                                      >
                                        {
                                          props.SwatchStatus === 2 ?
                                            <img
                                              src={inputFields[0].SwatchCardIamgePath === "" || inputFields[0].SwatchCardIamgePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}
                                              onClick={downloadSwatchcard}
                                              alt="AltPicture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                            :
                                            <>
                                              <img
                                                src={inputFields[0].SwatchCardIamgePath === "" || inputFields[0].SwatchCardIamgePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + inputFields[0].SwatchCardIamgePath}
                                                // src={"assets/img/emptyImage.jpg"}
                                                onClick={openFileUpload}
                                                alt="AltPicture" style={{ marginBottom: '5px', height: '200px', width: '200px', border: "1px solid #d5d5d5" }} />
                                              <input type="file" id="upload-button" accept=".jpg,.jpeg,.png" onChange={(event) => UploadImages(event)} value=''
                                                disabled={(props.LabdipswatchUpload === true && props.SwatchStatus === 1) ? false : true}
                                                multiple
                                              //disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                                              />
                                            </>

                                        }

                                        {
                                          inputFields.SwatchImageFileName !== "assets/img/uploadimage.jpg" && (
                                            <div style={
                                              {
                                                marginTop: '8px',
                                                float: 'right',
                                                bottom: '10px',
                                                position: 'relative',
                                              }
                                            }>
                                              {/* {
                                                            inputFields[0].SwatchCardIamgePath !== "" && inputFields[0].SwatchCardIamgePath !== "assets/img/uploadimage.jpg" ?
                                                              <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                style={{ marginLeft: "15px", position: "absolute" }}
                                                                onClick={
                                                                  event => ViewFile(event, inputFields.SwatchCardIamgePath)
                                                                }></i>
                                                              : ''

                                                          } */}

                                            </div>
                                          )
                                        }

                                      </div>
                                    </td>

                                  </tr>
                                </table>




                                <div> {
                                  isOpen && (

                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                      alt="bg image"
                                      onCloseRequest={
                                        () => setIsOpen(false)
                                      } />
                                  )
                                } </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="idOrderInformation" className="tab-pane" style={{ width: "100%" }}>
                          <div className="row">
                            <div className="col-sm-12" align={"right"}>
                              <button type="button" className="btn btn-primary" onClick={() => ShowHideDivs()}
                                disabled={props.TaskStatus === 3 ? true : false}>
                                <i className="fa fa-plus"></i> &nbsp;Add
                              </button>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="dataTables_wrapper no-footer" style={{ overflowX: "auto" }}>
                                {
                                  getOrderinfo.length !== 0 ?

                                    <table className="table table-bordered">
                                      <TableHeader headers={headers} />
                                      <tbody>
                                        {

                                          getOrderinfo.map((item, index) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>{item.styleName}</td>
                                                  <td>{item.styleNo}</td>
                                                  <td>{item.idpoNo}</td>
                                                  <td>{item.fabricDetails}</td>
                                                  <td>{item.taskOwnerNameList}</td>
                                                  <td className="Actionbutton fixed-column">
                                                    <div style={{ width: "80px" }}>
                                                      <label style={{ marginTop: '8px' }}>
                                                        <input type="checkbox" name="SelectedDetail"
                                                          disabled={props.TaskStatus === 3 ? true : false}
                                                          onChange={event => (OnChangeOrderinfo(event, index))}
                                                          checked={item.selectedDetail === 1 ? true : false}
                                                          className="colored-blue clearOpacity" />
                                                        <span className="text"></span>
                                                      </label>
                                                      &nbsp;&nbsp;

                                                      <button style={{ display: getOrderinfo.length === 1 ? 'none' : '' }}
                                                        disabled={props.TaskStatus === 3 ? true : false}
                                                        type="button"
                                                        className="btn  btn-xs  btn-danger"
                                                        title="Delete"
                                                        onClick={() =>
                                                          handleRemoveOrderInfo(index)
                                                        }
                                                      >
                                                        <i className="fa fa-trash-o"></i>
                                                      </button>


                                                    </div>

                                                  </td>
                                                </tr>
                                              </>

                                            )
                                          })
                                        }

                                      </tbody>
                                    </table>
                                    :
                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                      <TableHeader headers={headers} />
                                      <tbody>
                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                      </tbody>
                                    </table>
                                }
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div className="mt-10">
                  <div className="row">
                    <div className="col-sm-8 col-lg-8">
                      <div className='form-group'>
                        <label htmlFor="Remarks">Remarks</label>
                        <textarea maxLength="300" name="Remarks" value={inputFields[0].Remarks}
                          onChange={event => handleChange(event, 'Remarks')}
                          disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) ? true : false}
                          rows="4" style={{ width: "99%" }}></textarea>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12" align="right">
                  <button type="button" className="btn btn-success margin-Align" onClick={() => SaveSubmission()}
                    disabled={props.TaskStatus === 3 && (props.editLabdipSubmission === false || chkDataExists === true) || buttonLoader ? true : false}
                  >
                    <i className="fa fa-check right"></i> &nbsp;{(props.editLabdipSubmission === false || props.editLabdipSubmission === undefined || chkDataExists === true) ? 'Save' : 'Update'}
                  </button>
                </div>
              </div>
              {
                ShowPopup === true ?
                  <Modal show={ShowPopup} size="md" dragable backdrop="static" keyboard={false} onHide={() => CloseAwbpopup()}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        AWB and Date
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                        <thead>
                          <tr>
                            <th style={{ width: "350px" }}>AWB<span className="text-danger">*</span></th>
                            <th style={{ width: "300px" }}>Date<span className="text-danger">*</span></th>
                            {/* <th styles={{ width: "100px" }}>Action</th> */}
                          </tr>

                        </thead>
                        <tbody>

                          <tr>
                            <td>
                              <span className='input-icon icon-right'>
                                <input type="text"
                                  placeholder="AWB Name"
                                  id={"AWBName"}
                                  name="AWBName"
                                  value={inputFields[0].AWBName}
                                  style={{ border: submittedawb && !inputFields[0].AWBName ? '1px solid red' : '' }}
                                  autoFocus
                                  maxLength="25"
                                  className={'form-control'}
                                  onChange={event => handlepopupchange(event, 'AWBName')}
                                // disabled={ChildField.DependencyID === 0 ? true : false}
                                />
                              </span>

                            </td>
                            <td>
                              <DatePicker className="form-control" name="AWBDate"
                                id={
                                  "AWBDate"
                                }
                                selected={
                                  inputFields[0].AWBDate
                                }
                                onChange={event => handlepopupchange(event, 'AWBDate')}
                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                style={{ border: submittedawb && inputFields[0].AWBDate === '' ? '1px solid red' : '' }}
                                dropdownMode="scroll"
                                autoComplete="off"
                                customInput={<CustomInputAWB />}
                                isClearable={inputFields[0].AWBDate === null ? false : true}
                              />

                              {/* <span className="input-icon icon-right">
                                <input id={"AWBDate"} name="AWBDate"
                                  value={inputFields[0].AWBDate} placeholder="DD/MM/YYYY"
                                  type="date" autoComplete="off" onKeyDown={(e) => e.preventDefault()}
                                  className={'form-control'}
                                  style={{ border: submittedawb && inputFields[0].AWBDate === '' ? '1px solid red' : '' }}
                                  onChange={event => handlepopupchange(event, 'AWBDate')}
                                />
                              </span> */}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="success" onClick={() => SaveAwbnoDate()}>
                        <i className="fa fa-check right"></i>&nbsp;
                        Save
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  : ''
              }
            </>
            : ''
        }
        {
          values[0].hasAllDependencyStartPopup &&
          <Modal show={values[0].hasAllDependencyStartPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseAllDependencyStartPopup()}>
            <Modal.Header closeButton>
              <Modal.Title>
                Multi Dependency
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Dependencytable">
                <thead>
                  <tr>
                    <th>Date<span className="text-danger">*</span></th>
                    <th>Comments<span className="text-danger">*</span></th>

                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <td>
                      {
                        <DatePicker className="form-control" name="DependencyDate"


                          // selected={new Date(ManualInfo[0].manualCloseDate)}
                          selected={Date.parse(moment(values[0].MultiDependencyDate, 'MM/DD/YYYY').toISOString())}
                          onChange={event => handleChangeMultiDependentStart(event, "CommentDate", false)}
                          dateFormat="dd/MM/yyyy"
                          peekNextMonth
                          showMonthDropdown
                          dropdownMode="scroll"
                          autoComplete="off"
                          minDate={new Date()}
                          //  maxDate={new Date()}
                          customInput={<CustomInputDependency />}

                        />


                      }

                    </td>
                    <td style={{ width: "600px" }} >
                      <textarea
                        rows="3"
                        placeholder="Enter Comments here..."
                        autoFocus onFocus={""} autoComplete="off"
                        maxLength="500" name="EnterRemarks"
                        value={values[0].MultiDependencyComment}
                        onChange={event => handleChangeMultiDependentStart(event, "Comment", false)}
                        style={{ width: "100%", border: values[0].submitDependencyStart && !values[0].MultiDependencyComment ? '1px solid red' : '' }}>
                      </textarea>
                    </td>

                  </tr>
                </tbody>

              </table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={() => handleSaveAllDependencyStart()}>

                Save
              </Button>
            </Modal.Footer>
          </Modal>

        }
        {
          getID.showPopupDelete ?
            <SweetAlertPopup data={getID.Params}
              deleteCallback={Delcallback}
              showpopup={true}
              pageActionId={LabdipSubmissionApprovalId}
              Msg={"Lab Dip Submission Deleted successfully."} />
            : null
        }

        {
          SwatchDownloadformat && SwatchDownloadformat === true ?
            <div align="center">
              <Modal show={SwatchDownloadformat} dialogClassName='centerModal' size="md" dragable backdrop="static" keyboard={false} onHide={() => PdfOnhide(false)}>
                <Modal.Header closeButton style={{ background: "white" }}>
                  <Modal.Title>
                    Select Cube or Buyer Format
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "14vh" }}>
                  <div className="row">


                    <div className="col-sm-6">
                      <div className='form-group'>
                        <label htmlFor="Awbdate" >Swatch Format</label><span className="text-danger">*</span>
                        <span className='input-icon icon-right'>
                          <Select placeholder="" id="Format" name="Format" //className="form-control"
                            value={Swatchdownload.Format}
                            onChange={event => handleSwatchFormat(event, "Format")}
                            type="text"
                            autoComplete="off"
                            maxLength="50"
                            className={'form-control'}
                            style={{ border: submittedSwatchFormat && Swatchdownload.Format === "0" ? '1px solid red' : '' }}
                          >
                            <option value="0">- Select Format -</option>
                            <option value="1">Cube</option>
                            <option value="2">Buyer</option>

                          </Select>
                        </span>
                      </div>
                    </div>

                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="success" onClick={() => GenerateLabdipSwatchCardPdf()}>
                    Add / Create
                  </Button>
                </Modal.Footer>

              </Modal>
            </div>
            : ''

        }



        {
          Swatchdownload.Condition === true && Swatchdownload.Format === '1' ?
            rowsanddatacountforPdf.map(pages => {
              return (
                <>
                  <div id={"Page" + pages.Pageno} style={{ background: "white" }}  >
                    <div className="page-body CustomPdfFont"  >
                      <div className="row">
                        <div class="col-xs-6 col-md-8">
                          <div className="widget" style={{ width: '900px' }}>
                            <div className="header" style={{ border: "1px solid black", display: "flex", justifyContent: "center", background: "white" }}>
                              <div style={{ display: "inline-flex", alignItems: "center" }}>
                                <img src="assets/img/MainMenu/ICube_logo.PNG" alt="" width={"150%"} height="80vh" />
                              </div>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <div >
                                <h1 className='text-center'> <b>CUBE FASHIONS</b>  </h1>
                                <h3 style={{ margin: "0" }} className='text-center'> <b>LAB DIP SUBMISSION FORM</b></h3> <br />
                              </div>
                            </div>

                            <div style={{
                              border: "1px solid black", padding: "2rem", borderBottom: "none", borderTop: "none",
                              background: "white", fontSize: "16px"
                            }}>
                              <div className="row" style={{ marginTop: "-15px" }} >
                                <div className="col-lg-6">
                                  <ul className='cfformUL'>
                                    <li className='cfformLI'><b className='boldtext'>Attn</b> : {basicdataLabdipPdf[0].sendToNames}</li>
                                    <li className='cfformLI'><b className='boldtext'>Buyer</b>: {basicdataLabdipPdf[0].buyerName}</li>
                                    <li className='cfformLI'><b className='boldtext'>Brand</b>: {basicdataLabdipPdf[0].brandName}</li>
                                    <li className='cfformLI'><b className='boldtext'>Supplier</b>: {basicdataLabdipPdf[0].supplierName}</li>
                                    {/* <li className='cfformLI'><b className='boldtext'>End Customer</b>: {basicdataLabdipPdf[0].endCustomerNameIdList}</li> */}


                                  </ul>
                                </div>
                                <div className="col-lg-6">
                                  <ul className='cfformUL'>
                                    <li className='cfformLI'><b className='boldtext'>Date  </b>: {basicdataLabdipPdf[0].submittedDate}</li>
                                    <li className='cfformLI'><b className='boldtext'>Season</b>: {basicdataLabdipPdf[0].seasonName}</li>
                                    <li className='cfformLI'><b className='boldtext'>ID / PO No</b>: {basicdataLabdipPdf[0].idpoNo}</li>
                                    {/* <li className='cfformLI'><b className='boldtext'>Style Name</b>: {basicdataLabdipPdf[0].styleName}</li> */}
                                    <li className='cfformLI'><b className='boldtext'>Submitted For</b>: SMS </li>

                                    {/* <li className='cfformLI'><b className='boldtext'>Content </b>: {basicdataLabdipPdf[0].fabricContent} </li> */}
                                  </ul>
                                </div>

                              </div>
                              <div className="row"  >
                                <div className="col-lg-12" style={{ marginTop: "-10px" }} >
                                  <ul className='cfformUL'>
                                    <li className='cfformLI'><b >Fabric Details</b>&nbsp; &nbsp;: {basicdataLabdipPdf[0].fabricName} - {basicdataLabdipPdf[0].fabricContent}</li>
                                    {/* <li className='cfformLI'><b className='boldtext'>Ref/Order No</b>: {basicdataLabdipPdf[0].sendToNames}</li>
                                    <li className='cfformLI'><b className='boldtext'>Story</b>: {basicdataLabdipPdf[0].storyName}</li>
                                    <li className='cfformLI'><b className='boldtext'>Theme</b>: {basicdataLabdipPdf[0].themeName}</li> */}


                                  </ul>
                                </div>
                              </div>
                              <div className="row"  >
                                <div className="col-lg-12" style={{ marginTop: "-10px" }} >
                                  <ul className='cfformUL'>
                                    <li className='cfformLI'><b>Style Name</b>&nbsp; &nbsp; &nbsp; &nbsp; : {basicdataLabdipPdf[0].styleName}</li>
                                    {/* <li className='cfformLI'><b className='boldtext'>Ref/Order No</b>: {basicdataLabdipPdf[0].sendToNames}</li>
                                    <li className='cfformLI'><b className='boldtext'>Story</b>: {basicdataLabdipPdf[0].storyName}</li>
                                    <li className='cfformLI'><b className='boldtext'>Theme</b>: {basicdataLabdipPdf[0].themeName}</li> */}


                                  </ul>
                                </div>
                              </div>

                              <div className="row" style={{ marginTop: "22px" }}>
                                <div className="col-lg-12">
                                  <ul className='cfformUL'>
                                    <li className='cfformLI'><b className='boldtext'>Color </b>&nbsp; &nbsp; &nbsp;  : {basicdataLabdipPdf[0].pantoneColorOrOrSwatchImage} </li>

                                    <li className='cfformLI'><b className='boldtext'>Code </b>&nbsp; &nbsp; &nbsp; : {basicdataLabdipPdf[0].pantoneCode} - {basicdataLabdipPdf[0].pantoneNameOrSwatchName} - {basicdataLabdipPdf[0].pantoneTypeName}</li>

                                  </ul>
                                </div>

                                {/* <div className="col-lg-4">

                                </div> */}
                              </div>



                              <div className="row" style={{ height: '690px' }}>



                                <div className="row">
                                </div>
                                {
                                  submitoptiosRefnoLabdipPdf.filter(x => x.Pageno === pages.Pageno).map(refnos => {
                                    return (
                                      <>
                                        <div className="row" style={{ marginLeft: "2px" }}>
                                          <div className="col-lg-4">
                                            <ul className='cfformUL'>
                                              <li className='cfformLI'><b className='boldtext'>Pantone/CR Ref</b>: {refnos.Refno}</li>

                                            </ul>

                                          </div>
                                          <div className="col-lg-4">

                                          </div>
                                          <div className="col-lg-4">

                                          </div>

                                        </div>
                                        {

                                          AllsubmitoptiosLabdipPdf[pages.Pageno - 1].filter(so => so.supplierReferenceNo === refnos.Refno && so.Pageno === refnos.Pageno).map((options, row) => {

                                            return (
                                              <>
                                                {

                                                  // <div className="row">
                                                  <div className="col-lg-4" align="center">

                                                    <label htmlFor="" style={{ fontSize: "14px" }} ><b>Option</b> "{options.optionName}"</label>
                                                    <div className="row">
                                                      <div className="col-lg-6">
                                                        <textarea name="" id="" cols="25" rows="7"></textarea>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  // </div>
                                                }

                                              </>)

                                          })



                                        }
                                        <div className="row">

                                        </div>




                                      </>
                                    )



                                  })

                                }





                              </div>

                              <div className="row" style={{ marginTop: '28px' }}>
                                <div className="col-lg-12">
                                  <ul className='cfformUL'>
                                    <li className='cfformLI'><b className='boldtext'>Submission Remarks  </b>: {basicdataLabdipPdf[0].remarks}</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <ul className='cfformUL'>
                                    <li className='cfformLI'><b className='boldtext'>Approval Remarks  </b>:</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="widget-footer" style={{ border: "1px solid black", backdropFilter: "white", paddingLeft: "1rem", backgroundColor: "white" }}>
                              <h2 style={{ marginTop: "5px", marginBottom: "2px" }}>Cube Fashions (P) Ltd</h2>

                              <h5 style={{ marginBottom: "2px" }}>20/4,Jai Nagar Maniyakaram Palayam Road, Rakkiya Palayam Cross, Kangayam Road Tirupur, INDIA - 641606</h5>

                              <h5 style={{ marginBottom: "2px" }}>  Tel: 0091 421 4355958, 4255959, Fax: 0091 421 4355671, Email: info@cubefashions.com</h5>
                            </div>
                            &nbsp;
                            <div className="row">
                              <div className="col-lg-6" align='right'>
                                <b>Page {pages.Pageno} of {rowsanddatacountforPdf.length}</b>
                              </div>
                              <div className="col-lg-6" align='right'>
                                <b>Ref no : {props.LabdibFabricInfoId}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </>
              )
            })
            :
            Swatchdownload.Condition === true && Swatchdownload.Format === '2' ?
              rowsanddatacountforPdf.map(pages => {
                return (
                  <>
                    <div id={"Page" + pages.Pageno} >
                      <div className="page-body CustomPdfFont" style={{ background: "white" }}>
                        <div className="row">
                          <div class="col-xs-6 col-md-6">
                            <div className="widget" style={{ width: '900px' }}>
                              <div className="header" style={{ border: "1px solid black", display: "flex", background: "black", height: "9.2vh" }}>
                                <div style={{ display: "inline-flex", alignItems: "center" }}>
                                  <img src="assets/img/MainMenu/Capture.PNG" alt="" width={"221%"} height={"68vh"} />
                                </div>
                                <div style={{ display: "inline-flex" }}>
                                  <h1 className='text-center' >  <span style={{ color: "red" }}> &nbsp; LAB DIP</span>  <span style={{ color: "white" }}>SUBMISSION FORM</span> </h1>

                                </div>
                              </div>

                              <div style={{
                                border: "1px solid black", borderBottom: "none",
                                borderTop: "none", padding: "0rem", background: "white", fontSize: "16px"
                              }}>
                                <div className="row">
                                  <div className="col-lg-12" >
                                    <table style={{ width: "100%" }}>
                                      <tr>
                                        <th style={{ width: "20%" }} className='formtable' >SUPPLIER</th>
                                        <td style={{ width: "30%" }} className='formtable'> {basicdataLabdipPdf[0].supplierName}</td>
                                        <th style={{ width: "21%" }} className='formtable'>DATE OF ISSUE</th>
                                        <td className='formtable'>{basicdataLabdipPdf[0].submittedDate}</td>
                                      </tr>
                                      <tr>
                                        <th className='formtable' >FROM</th>
                                        <td className='formtable'>Cube Fashions</td>
                                        <th className='formtable'>SUBMITTED FOR</th>
                                        <td className='formtable'>SMS</td>
                                      </tr>
                                      <tr>
                                        <th className='formtable' >ATTN</th>
                                        <td className='formtable'>{basicdataLabdipPdf[0].sendToNames}</td>
                                        <th className='formtable'>STYLES</th>
                                        <td className='formtable'> {basicdataLabdipPdf[0].styleName}</td>
                                      </tr>
                                      <tr>
                                        <th className='formtable' >ID NO/CUSTOMER</th>
                                        <td className='formtable'>{basicdataLabdipPdf[0].idpoNo}</td>
                                        <th className='formtable'>COUNTRY OF ORIGIN</th>
                                        <td className='formtable'>India</td>
                                      </tr>
                                      <tr>
                                        <th colSpan={2} className='formtable' >FAB REFS /COMPOSITION /CONSTRUCTION /COUNT</th>
                                        <td colSpan={3} className='formtable'> {basicdataLabdipPdf[0].fabricName}/{basicdataLabdipPdf[0].fabricContent}/{basicdataLabdipPdf[0].gsMorCounts}</td>

                                      </tr>
                                      <td class="formtable" colspan="4" style={{ height: "5vh" }} ></td>

                                    </table>
                                  </div>
                                </div>



                                <div className="row">
                                  <div className="col-lg-12">
                                    <span style={{ marginLeft: "15px", lineHeight: "2" }}><b > COLOUR:</b> {basicdataLabdipPdf[0].pantoneColorOrOrSwatchImage} </span>

                                  </div>
                                  <div className="row">
                                  </div>

                                  <div className="col-lg-12">
                                    <span style={{ marginLeft: "15px", lineHeight: "1" }}><b > CODE:</b>{basicdataLabdipPdf[0].pantoneCode} - {basicdataLabdipPdf[0].pantoneNameOrSwatchName} - {basicdataLabdipPdf[0].pantoneTypeName}  </span>

                                  </div>




                                  <div className="row" style={{ height: '825px' }}>
                                    {
                                      submitoptiosRefnoLabdipPdf.filter(x => x.Pageno === pages.Pageno).map(refnos => {
                                        return (
                                          <>
                                            <div className="row" style={{
                                              marginLeft: "32px"
                                            }}>
                                              <div className="col-lg-4">
                                                <ul className='cfformUL'>
                                                  <li className='cfformLI'><b className='boldtext'>PANTONE/CR:</b> {refnos.Refno} </li>

                                                </ul>


                                              </div>
                                              <div className="col-lg-4">

                                              </div>
                                              <div className="col-lg-4">

                                              </div>

                                            </div>
                                            {

                                              AllsubmitoptiosLabdipPdf[pages.Pageno - 1].filter(so => so.supplierReferenceNo === refnos.Refno && so.Pageno === refnos.Pageno).map((options, row) => {

                                                return (
                                                  <>
                                                    {

                                                      // <div className="row">
                                                      <div className="col-lg-4" >
                                                        <label htmlFor="" className='text-center' style={{ marginLeft: "105px" }} ><b className='text-center'>{options.optionName}</b></label>
                                                        <table style={{ width: "80%", marginLeft: "9.5%" }}>

                                                          <tr>
                                                            <th className='formtable' colspan="2" scope="colgroup" style={{ height: "21vh" }}></th>
                                                          </tr>
                                                          <tr>
                                                            <th className='formtable' style={{ padding: "0rem", textAlign: "center" }} scope="col">APPROVED</th>
                                                            <th className='formtable' style={{ padding: "0rem", textAlign: "center" }} scope="col">REJECTED</th>

                                                          </tr>

                                                        </table>
                                                      </div>
                                                      // </div>
                                                    }

                                                  </>)

                                              })



                                            }
                                            <div className="row">

                                            </div>


                                          </>
                                        )



                                      })

                                    }
                                  </div>

                                </div>
                              </div>
                              <div className="widget-footer" style={{ backdropFilter: "white" }}>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <table className="commentsTable" width={"100%"}>
                                      <tr className="commentsTable">
                                        <td className="commentsTable" style={{ padding: "0px" }}>
                                          <h5 style={{ marginLeft: "15px" }}><b>SUPPLIER COMMENTS :&nbsp;</b>  {basicdataLabdipPdf[0].remarks}  </h5>
                                        </td>

                                      </tr>
                                      <tr className="commentsTable">
                                        <td className="commentsTable" style={{ padding: "0px" }}>
                                          <h5 style={{ marginLeft: "15px" }}><b>FOCUS COMMENTS: :</b>  </h5>
                                        </td>
                                      </tr>
                                    </table>
                                    {/* <div>
                                      <h5><b>SUPPLIER COMMENTS :</b>  </h5>
                                    </div>
                                    <div>
                                      <h5><b>FOCUS COMMENTS :</b>  </h5>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                              &nbsp;
                              <div className="row">
                                <div className="col-lg-6" align='right'>
                                  <b>Page {pages.Pageno} of {rowsanddatacountforPdf.length}</b>
                                </div>
                                <div className="col-lg-6" align='right'>
                                  <b>Ref no : {props.LabdibFabricInfoId}</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </>
                )
              })
              : ''

        }

        {
          showswatchcardimages === true &&
          swatchcardimages.map((images, index) => {
            return (
              <>

                <div id={"UploadPage" + (index + 1)} style={{ width: '950px', height: '1350px' }}>
                  <img
                    src={window.$APIBaseURL + images.src}
                    alt="No Data" />
                </div>
              </>
            )
          })
        }



        {loader}
      </Form>
    </div>

  )


};

export default LabdibSubmit;
