
import React, { useState, useEffect, Fragment, useMemo } from "react";
import Reactselect from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { TableHeader } from "../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import StyleService from "../../../services/Style/styleService";
import TNAService from "../../../services/TNA/TNAService";
import moment from "moment";
import { DateRangePicker } from 'react-date-range';
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { LoadReQAInspectionList } from "../../../actions/inspectionQC";
import QAService from "../../../services/InspectionQC/InspectionQCService";
import { Pagination, Search, PageCount, ReactSearch } from "../../Datatable";
import Nodify from "../../Common/ReactNotification";
import { Modal, Button } from "react-bootstrap";
import { GetTestEntryDetails } from "../../../actions/tna";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
const Retest = ({ props, Taskcallback }) => {
    const [getPlaceholder] = useState("Buyer / Style Name / Style No / Season / Brand / Story / Theme  / Group Type");
    const [currentPage, setCurrentPage] = useState(1);
    const [getSeasonList, setSeasonList] = useState();
    const [getTestEntryNoSearch, setTestEntryNoSearch] = useState("");
    const [Commentpopup, setCommentpopup] = useState(false);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const comments = [];
    const [skuqtylist, setskuqtylist] = useState([]);
    const [sizeqtypopup, setsizeqtypopup] = useState(false);
    const [getheaderName, setheaderName] = useState("");
    const [getFIRemark, setFIRemark] = useState([])
    const [Ecomment, setFIRemarks] = useState();
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [openReInspopup, setopenReInspopup] = useState(false);
    const [ReInspectionDetailList, setReInspectionDetailList] = useState([]);
    const [selectall, setselectall] = useState(false);
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getSkuSearch, setSkuSearch] = useState("");
    const [getFactorySearch, setFactorySearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [getSuppliersearch, setsupplierSearch] = useState("");
    const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [getCategorysearch, setCategorySearch] = useState("");
    const [getStyleNamesearch, setStyleNameSearch] = useState("");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getStyleNosearch, setStyleNoSearch] = useState("");
    const [getBuyerEmpsearch, setBuyerEmpSearch] = useState("");
    const [getCubeEmpsearch, setCubeEmpSearch] = useState("");
    const [getOrderStatussearch, setOrderStatusSearch] = useState("");
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
    //const [showmodal, setShowmodal] = useState({ EnableComments: 0, PurchaseOrderSkuID: 0, reportStatus: [] });
    const [selectedOption, setSelectedOptions] = useState({ value: 1, label: 'Yet to Start' })
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [Submitted, setSubmitted] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [getOrdStatusID, setOrdStatusID] = useState({ showPopupDelete: false, Params: {} });
    const [getInspectionInfo, setInspectionInfo] = useState({});
    const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    const [getIDfinalEntry, setIDfinalEntry] = useState({ showPopupDelete: false, Params: {} });
    const [getIDcomment, setIDcomment] = useState({ showPopupDelete: false, Params: {} });
    const [show, setShow] = useState(false);
    const [getTestingTaskInfo, setTestingTaskInfo] = useState([{
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    }]);
    const [checkboxStates, setCheckboxStates] = useState({
        EntryNo: true,
        TestType: true,
        ReInspectionDoneAgainst: true,
        Brand: true,
        Season: true,
        Supplier: true,
        StyleName: true,
        SKUName: true,
        SupplierRefNo: true,
        IDNo: true,
        StrikeoffTrimName: true,
        Image: true,
        LotNo: true,
        LotWeight: true,
        ReportQuantity: true,
        TestResult: true,
        ApprovalResult: true,
        ReTestStatus: true,
        BalanceForReTest: true,
        CompletedReTestReportNo: true,
    });
    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultApStartDate = new Date();
    const [stateApDate, setStateApDate] = useState([
        {

            startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = useSelector((state) => state.auth);

    const QAReInspectionList = reducerState.tna.testingEntryDetailsList !== undefined ? reducerState.tna.testingEntryDetailsList : reducerState.tna.testingEntryDetailsList;
   
    const isLoadingQADetailsReport = reducerState.inspectionQC.isLoadingQADetailsReport;
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    QAReInspectionList.map((x) => {
        if (x.quarterName === null || x.quarterName === undefined) {
            x.quarterName = ''
        }
    })
    useEffect(() => {
        let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(Defaultvalue);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }
        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        // let params = { IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }

        // dispatch(LoadReQAInspectionList(params));
        dispatch(GetTestEntryDetails(IsProduction
            , Taskcallback
            , moment(stateDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
            , 3));
    }, [dispatch]);
    // loader
    // useEffect(() => {
    //     if (!isLoadingQADetailsReport) {
    //         hideLoader();
    //     } else {
    //         showLoader();
    //     }
    // }, [isLoadingQADetailsReport]);
    let size = '130px';
    const garmenttableHeader = [

        {
            name: "#",
            field: "",
            sortable: false,
            left: 0,
            padding: "30px !important",
            widthsize:'2%',
        }
        ,
        // checkboxStates.CreatedDate && (
        //     {
        //         name: "Created Date",
        //         field: "insCreatedDate",
        //         sortable: true,
        //         widthsize: "3%",
        //         left: 0,

        //     }
        // )
        ,
        checkboxStates.EntryNo && ({
            name: "Entry No/ Date",
            field: "qcInspectionID",
            sortable: true,
            widthsize: size,
            left: "120px",

        })

        // , checkboxStates.TestType && ({
        //     name: "Test Type",
        //     field: "reportType",
        //     sortable: true,

        //     widthsize: "5%",
        //     left: "240px",

        // })

        , checkboxStates.Supplier && ({
            name: "Supplier",
            field: "supplierName",
            sortable: true,
            //width: "5%",
            widthsize: size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        }), checkboxStates.Supplier && ({
            name: "Category",
            field: "fabricDetailName",
            sortable: true,
            //width: "5%",
            widthsize: size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        }),
        checkboxStates.Supplier && ({
            name: "Style Name",
            field: "styleName",
            sortable: true,
            //width: "5%",
            widthsize: size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        }),
        checkboxStates.Supplier && ({
            name: "SKU Name",
            field: "skuName",
            sortable: true,
            //width: "5%",
            widthsize: size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        }),
        // , checkboxStates.Supplier && ({
        //     name: "SupplierRefNo",
        //     field: "supplierRefNo",
        //     sortable: true,
        //     //width: "5%",
        //     widthsize: "6%",
        //     //position: "sticky",
        //     left: "736px",
        //     //background: "#222d32",
        // })
        , checkboxStates.IDNo && ({
            name: "PO/ID No",
            field: "idpono",
            sortable: true,
            widthsize: size,
            //width: "4%",
        }), checkboxStates.Image && ({ name: "Image", field: "swatchFilepath", sortable: true, widthsize: size, })
        // , checkboxStates.LotWeight && ({
        //     name: "Lot Weight",
        //     field: "lotWeight",
        //     sortable: true,
        //     //width: "4%",
        //     widthsize: "5%",
        //     // position: "sticky",
        //     left: "858px",
        //     //background: "#222d32",
        // })
        , checkboxStates.ReportQuantity && ({
            name: "Order Qty",
            field: "reportQty",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        , checkboxStates.TestResult && ({
            name: "Test Result",
            field: "testResult",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        , checkboxStates.ApprovalResult && ({
            name: "Approval Result",
            field: "approvalResult",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        , checkboxStates.ReTestStatus && ({
            name: "Re Test Status",
            field: "reTestStatus",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        // , checkboxStates.BalanceForReTest && ({
        //     name: "Balance For Re Test",
        //     field: "balanceForReTest",
        //     sortable: true,
        //     widthsize: size,
        //     // width: "5%",
        // })
        ,
        // checkboxStates.CompletedReTestReportNo && ({
        //     name: "Completed Re Test Report No",
        //     field: "completedReTestReportNo",
        //     sortable: true,
        //     widthsize: "10%",
        //     // width: "5%",
        // }),
        {
            name: "Action",
            field: "Action",
            className: "text-center ActionWidth fixed-column-header-Route",
            //fixed- column - header - Route
            sortable: false,
            //width: "120px",
            widthsize: size,
            //left: "736px",
        }
    ]
    const tableHeader = [
        // checkboxStates.CreatedDate && (
        //     {
        //         name: "Created Date",
        //         field: "insCreatedDate",
        //         sortable: true,
        //         widthsize: "3%",
        //         left: 0,

        //     }
        // )
        ,
        {
            name: "#",
            field: "",
            sortable: false,
            left: 0,
            padding: "30px !important"
        }
        ,
        checkboxStates.EntryNo && ({
            name: "Entry No/ Date",
            field: "qcInspectionID",
            sortable: true,
            widthsize: size,
            left: "120px",

        })

        // , checkboxStates.TestType && ({
        //     name: "Test Type",
        //     field: "reportType",
        //     sortable: true,

        //     widthsize: "5%",
        //     left: "240px",

        // })

        , checkboxStates.Supplier && ({
            name: "Supplier",
            field: "supplierName",
            sortable: true,
            //width: "5%",
            widthsize: size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        }), checkboxStates.Supplier && ({
            name: (Taskcallback === 'FABRIC TEST' ? "Fabric Details" : 'Yarn Details'),
            field: "fabricDetailName",
            sortable: true,
            width: size,
        })
        , checkboxStates.Supplier && ({
            name: "Color Name",
            field: "colorname",
            sortable: true,
            //width: "5%",
            widthsize: size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        }), checkboxStates.Supplier && ({
            name: "Style Name",
            field: "styleName",
            sortable: true,
            //width: "5%",
            widthsize: size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        }),
        // , checkboxStates.Supplier && ({
        //     name: "SupplierRefNo",
        //     field: "supplierRefNo",
        //     sortable: true,
        //     //width: "5%",
        //     widthsize: "6%",
        //     //position: "sticky",
        //     left: "736px",
        //     //background: "#222d32",
        // })
        , checkboxStates.IDNo && ({
            name: "PO/ID No",
            field: "idpono",
            sortable: true,
            widthsize: size,
            //width: "4%",
        }), checkboxStates.Image && ({ name: "Image", field: "swatchFilepath", sortable: true, widthsize: size, })
        , checkboxStates.LotNo && ({
            name: "Lot No/Weight",
            field: "lotNo",
            sortable: true,
            //width: "4%",
            widthsize: size,
            // position: "sticky",
            left: "858px",
            //background: "#222d32",
        })
        // , checkboxStates.LotWeight && ({
        //     name: "Lot Weight",
        //     field: "lotWeight",
        //     sortable: true,
        //     //width: "4%",
        //     widthsize: "5%",
        //     // position: "sticky",
        //     left: "858px",
        //     //background: "#222d32",
        // })
        , checkboxStates.ReportQuantity && ({
            name: "Order Qty",
            field: "reportQty",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        , checkboxStates.TestResult && ({
            name: "Test Result",
            field: "testResult",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        , checkboxStates.ApprovalResult && ({
            name: "Approval Result",
            field: "approvalResult",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        , checkboxStates.ReTestStatus && ({
            name: "Re Test Status",
            field: "reTestStatus",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        // , checkboxStates.BalanceForReTest && ({
        //     name: "Balance For Re Test",
        //     field: "balanceForReTest",
        //     sortable: true,
        //     widthsize: size,
        //     // width: "5%",
        // })
        ,
        // checkboxStates.CompletedReTestReportNo && ({
        //     name: "Completed Re Test Report No",
        //     field: "completedReTestReportNo",
        //     sortable: true,
        //     widthsize: "10%",
        //     // width: "5%",
        // }),
        {
            name: "Action",
            field: "Action",
            className: "text-center ActionWidth fixed-column-header-Route",
            //fixed- column - header - Route
            sortable: false,
            //width: "120px",
            widthsize: size,
            //left: "736px",
        }
    ];
    const StrikeofftableHeader = [
        // checkboxStates.CreatedDate && (
        //     {
        //         name: "Created Date",
        //         field: "insCreatedDate",
        //         sortable: true,
        //         widthsize: "3%",
        //         left: 0,

        //     }
        // )
        ,
        {
            name: "#",
            field: "",
            sortable: false,
            left: 0,
            padding: "30px !important",
            widthsize: '2%',
        }
        ,
        checkboxStates.EntryNo && ({
            name: "Entry No/ Date",
            field: "qcInspectionID",
            sortable: true,
            widthsize: size,
            left: "120px",

        })
        , checkboxStates.Supplier && ({
            name: "Supplier",
            field: "supplierName",
            sortable: true,
            //width: "5%",
            widthsize: size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        })
        , checkboxStates.Supplier && ({
            name: "Style Name",
            field: "styleName",
            sortable: true,
            //width: "5%",
            widthsize: size,
            //position: "sticky",
            left: "736px",
            //background: "#222d32",
        }),

        , checkboxStates.TestType && ({
            name: (Taskcallback === 'Strike off Test' ? "Strike off Name" : 'Trim Name'),
            field: "strikeoffTrimName", sortable: true
        })

        , checkboxStates.StrikeoffTrimName && (
            {
                name: (Taskcallback === "Strike off Test" ? "Strike Off Code" : "Trim Code"),
                field: "strikeoffTrimCode",
                sortable: true,
                width: "6%",
            }
        ),

        // , checkboxStates.TestType && ({
        //     name: "Test Type",
        //     field: "reportType",
        //     sortable: true,

        //     widthsize: "5%",
        //     left: "240px",

        // })


        // , checkboxStates.Supplier && ({
        //     name: "SupplierRefNo",
        //     field: "supplierRefNo",
        //     sortable: true,
        //     //width: "5%",
        //     widthsize: "6%",
        //     //position: "sticky",
        //     left: "736px",
        //     //background: "#222d32",
        // })
        , checkboxStates.IDNo && ({
            name: "ID No",
            field: "idpono",
            sortable: true,
            widthsize: size,
            //width: "4%",
        })

        , checkboxStates.Image && ({ name: "Image", field: "swatchFilepath", sortable: true })
        // , checkboxStates.Image && ({ name: "Image", field: "swatchFilepath", sortable: true })
        // , checkboxStates.ReportQuantity && ({
        //     name: "Report Qty",
        //     field: "reportQty",
        //     sortable: true,
        //     widthsize: "5%",
        //     // width: "5%",
        // })
        , checkboxStates.TestResult && ({
            name: "Test Result",
            field: "testResult",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        , checkboxStates.ApprovalResult && ({
            name: "Approval Result",
            field: "approvalResult",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        , checkboxStates.ReTestStatus && ({
            name: "Re Test Status",
            field: "reTestStatus",
            sortable: true,
            widthsize: size,
            // width: "5%",
        })
        // , checkboxStates.BalanceForReTest && ({
        //     name: "Balance For Re Test",
        //     field: "balanceForReTest",
        //     sortable: true,
        //     widthsize: "9%",
        //     // width: "5%",
        // })
        // , checkboxStates.CompletedReTestReportNo && ({
        //     name: "Completed Re Test Report No",
        //     field: "completedReTestReportNo",
        //     sortable: true,
        //     widthsize: "10%",
        //     // width: "5%",
        // })
        , {
            name: "Action",
            field: "Action",
            className: "text-center ActionWidth fixed-column-header-Route",
            //fixed- column - header - Route
            sortable: false,
            width: "120px",
            widthsize: size,
            //left: "736px",
        }
    ];

    const options = {
        thumbnails: {
            showThumbnails: false,
        }
    }
    const clearDateSelect = () => {

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);

        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const NewemptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }


        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        // let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
        // dispatch(LoadReQAInspectionList(params));
        dispatch(GetTestEntryDetails(IsProduction
            , Taskcallback
            , moment(stateDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
            , 3));
    }
    function handleChange(e, filterName) {
        let inputText = '';
        if (filterName === 'Buyer' && filterName !== '') {

            // let values = [...getBuyersearch];
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            // values= inputText;
            setBuyerSearch(inputText);
        }
        else if (filterName === 'StyleNo') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNoSearch(inputText);
        }
        else if (filterName === 'StyleName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNameSearch(inputText);
        }
        else if (filterName === 'Season') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSeasonSearch(inputText);
        }
        else if (filterName === 'Brand') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setBrandSearch(inputText);
        }
        else if (filterName === 'ID/NO') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setIdPoSearch(inputText);
        }
        else if (filterName === 'Category') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setCategorySearch(inputText);
        }
        else if (filterName === 'Supplier') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setsupplierSearch(inputText);
        }
        else if (filterName === 'EndCustomer') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setEndCustomerSearch(inputText);
        }
        else if (filterName === 'OrderStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setOrderStatusSearch(inputText);


        }
        else if (filterName === 'SKUName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSkuSearch(inputText);
        }
        else if (filterName === 'Factory') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setFactorySearch(inputText);
        }
        else if (filterName === 'IsProductionStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
                // let params = { Operation: 1 ,IsProduction: inputText}
                // dispatch(LoadFlDetailsreport(params));
            }
            setIsProductionStatussearch(inputText);
        }
        else if (filterName === 'TestEntryNo') {

            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setTestEntryNoSearch(inputText);
        }
        else if (filterName === 'Quarter') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setQuarterSearch(inputText);
        }
    };
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates({
            ...checkboxStates,
            [name]: checked
        });
    };

    const resetFinalIns = () => {
        setBuyerSearch('');
        setSkuSearch('');
        setFactorySearch('');
        setQuarterSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setsupplierSearch('');
        setIdPoSearch('');
        setsupplierSearch('');
        setEndCustomerSearch('');
        setCategorySearch(''); 
        setStyleNameSearch('');
        setStyleNoSearch('');
        setBuyerEmpSearch('');
        setCubeEmpSearch('');
        setOrderStatusSearch('');
        setIsProductionStatussearch('');
        setTestEntryNoSearch('')

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const emptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        setStateDate(emptyStateDate);
        setStateApDate(emptyStateExDate);

        let value = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(value);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        // let params = { IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }

        // dispatch(LoadReQAInspectionList(params));
        dispatch(GetTestEntryDetails(IsProduction
            , Taskcallback
            , moment(stateDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
            , 3));
        //EmptyCheck();
    }
    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        let getStateApDate = [...stateApDate];
        getStateDate[0].isShow = isShow;
        getStateApDate[0].isShow = false;
        setStateApDate(getStateApDate);
        setStateDate(getStateDate);
    }

    const handleclick = () => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateApDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateApDate(getStateExDate);
        setStateDate(getStateDate);

    }
    function DateRangechange(item) {

        stateDate[0].startDate = item[0].startDate;
        stateDate[0].endDate = item[0].endDate
        setStateDate(stateDate)
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        // let params = { IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }

        // dispatch(LoadReQAInspectionList(params));
        dispatch(GetTestEntryDetails(IsProduction
            , Taskcallback
            , moment(stateDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
            , moment(stateDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
            , 3));
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const commentsData = useMemo(() => {
       
        let computedComments = [];
        computedComments = QAReInspectionList;
        let drpvalue = [];

        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSkuSearch) {
            computedComments = computedComments.filter(comment =>
                comment.skuName.toLowerCase().includes(getSkuSearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }

        if (getStyleNamesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStyleNamesearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getStyleNosearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleNo.toLowerCase().includes(getStyleNosearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }


        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getQuartersearch) {
            computedComments = computedComments.filter(comment =>
                comment.quarterName.toLowerCase().includes(getQuartersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getSuppliersearch) {
            computedComments = computedComments.filter(comment =>
                comment.supplierName.toLowerCase().includes(getSuppliersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getFactorySearch) {
            computedComments = computedComments.filter(comment =>
                comment.factory.toLowerCase().includes(getFactorySearch.toLowerCase()) // Need to be Change
            )
            setTotalItems(computedComments.length);
        }
        if (getEndCustomersearch) {
            computedComments = computedComments.filter(comment => {
                comment.customerName == null ? comment.customerName = "" : comment.customerName = comment.customerName;
                comment.customerName.toLowerCase().includes(getEndCustomersearch.toLowerCase());
            }
            );
            setTotalItems(computedComments.length);
        }
        if (getCategorysearch) {
            computedComments = computedComments.filter(comment =>
                comment.categoryName.toLowerCase().includes(getCategorysearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getOrderStatussearch) {
            if (getOrderStatussearch.value === 1) {
                computedComments = computedComments.filter(comment =>
                    comment.orderStatus === null || comment.orderStatus === "" || comment.orderStatus === undefined
                    // || parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            } else {
                computedComments = computedComments.filter(comment =>
                    parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            }
            setTotalItems(drpvalue && drpvalue.length);
        }
        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            );

            setTotalItems(computedComments.length);
        }
        if (getTestEntryNoSearch) {
            computedComments = computedComments.filter(comment =>
                comment.qcInspectionID.toString().includes(getTestEntryNoSearch)
            );
            setTotalItems(computedComments.length);
        }
        let format = "MM/DD/YYYY";
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = new Date(moment(stateDate[0].startDate, "MM/DD/YYYY").format("MM/DD/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = new Date(moment(stateDate[0].endDate, "MM/DD/YYYY").format("MM/DD/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        // if (stateDate[0].startDate && stateDate[0].endDate) {
        //     computedComments = computedComments.filter(comment =>
        //         new Date(moment(comment.insCreatedDate, format)) >= stateDate[0].startDate && new Date(moment(comment.insCreatedDate, format)) <= stateDate[0].endDate
        //     )
        // }

        setTotalItems(computedComments.length);
        //sorting table
        if (sorting.field === "typeInterOrExtern") {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * (a[sorting.field] - (b[sorting.field])));
        }
        else if (sorting.field) {
            // computedComments.map((x) => {
            //     x.qcInspectionID = x.qcInspectionID.toString();
            // })
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));

        }



        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }

        // Current Page slice
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    },
        [
            QAReInspectionList,
            currentPage,
            // search,
            getTestEntryNoSearch,
            getBuyersearch,
            getSkuSearch,
            getFactorySearch,
            getSeasonsearch,
            getBrandsearch,
            getQuartersearch,
            getIdPosearch,
            getSuppliersearch,
            getEndCustomersearch,
            getCategorysearch,
            getStyleNamesearch,
            getStyleNosearch,
            getBuyerEmpsearch,
            getCubeEmpsearch,
            getOrderStatussearch,
            stateDate,
            stateApDate,
            sorting,
            ITEMS_PER_PAGE,
        ]);
    const GetComments = async (comment) => {
       
        showLoader();
        let Approvallist = [];
        let Approval = [{ FinalInspectionID: comment.testInspectionID, InsCreatedDate: comment.insCreatedDate }]
        setheaderName(Approval);
        setCommentpopup(true);
        QAService.GetReinspectionCommentsList(comment.testInspectionID, Taskcallback).then(async (response) => {
            if (response.data) {
                setFIRemark(response.data)
                hideLoader();
            }
        }).catch(() => { hideLoader(); });
    }

    async function openReInspectionDetailpopup(QCInspectionID, TnaId, comment) {
       
        await TNAService.GetTestReInspectionDetailList((Taskcallback === 'FABRIC TEST' || Taskcallback === 'YARN TEST' || Taskcallback === 'GARMENT TEST') ? 2 : Taskcallback === 'Strike off Test' ? 4 : 6, QCInspectionID, TnaId, Taskcallback).then((response) => {
            if (response.data) {
                setopenReInspopup(true);
                setReInspectionDetailList(response.data)
                getTestingTaskInfo.buyerName = comment.buyerName;
                getTestingTaskInfo.seasonName = comment.seasonName;
                getTestingTaskInfo.brandName = comment.brandName;
                getTestingTaskInfo.buyerID = comment.buyerID;
                getTestingTaskInfo.brandID = comment.brandID;
                getTestingTaskInfo.supplierId = comment.supplierID;
                getTestingTaskInfo.supplierName = comment.supplierName;
                getTestingTaskInfo.seasonID = comment.seasonID;
                getTestingTaskInfo.styleID = comment.styleID;
                getTestingTaskInfo.styleName = comment.styleName;
                getTestingTaskInfo.styleName = comment.styleNo;
                getTestingTaskInfo.TaskStatus = 1;
                getTestingTaskInfo.TaskType = Taskcallback;
                getTestingTaskInfo.TnaID = comment.tnaid;
                getTestingTaskInfo.type = comment.typeInternorExtern;//For InterNal Or External
                getTestingTaskInfo.quantityinfo = { TaskId: comment.taskDetailsID }
                setTestingTaskInfo(getTestingTaskInfo)
            }
            hideLoader();
        }).catch(() => { hideLoader(); });

    }

    const GetSKUandQty = (finalInspectionID) => {
        //setskuid('');
        QAService.GetPurchaseOrderSKUQty(finalInspectionID).then((response) => {
            if (response.data) {

                setskuqtylist(response.data)
            }
        }).catch(() => { });
        setsizeqtypopup(true);
        //setskuid({ skuID: PurchaseOrderSkuID, qrty: orderQty })
    }
    const handleFullClose = (Feildname, comment) => {
        setCommentpopup(false);
        setFIRemark([]);
        setFIRemarks('');
    }
    const handleChangeComment = (e) => {
        setSubmitted(false);
        let inputtext = "";
        if (e.target.value.trim() && e.target.value.trim() !== '') {
            inputtext = e.target.value;
            setSubmitted(false);
        }
        else {
            //setSubmitted(true);
        }
        setFIRemarks(inputtext);

    }
    const handleSaveApproval = (e, comment) => {
       
        let len = 0;
        if (Ecomment === undefined || Ecomment === '') {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        } else {
            // // var Data = [];

            var data = {
                CreatedBy: currentUser.employeeinformationID, ReinpsectionCommentsID: 0, InspectionId: comment.testInspectionID
                , CommentsDetail: Ecomment, TaskName: Taskcallback
            };

            QAService.InsertUpdateReinspectionComment(data).then((res) => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', "Comment Added for " + comment.qcInspectionID + " Successfully");
                    setCommentpopup(false);
                    setFIRemarks('');
                    let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
                    setIsProductionStatussearch(Defaultvalue);
                    let StartDate = null;
                    let EndDate = null;
                    if (moment(stateDate[0].startDate).isValid()) {
                        StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
                    }
                    if (moment(stateDate[0].endDate).isValid()) {
                        EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
                    }
                    let StartApDate = null;
                    let EndApDate = null;
                    if (moment(stateApDate[0].startDate).isValid()) {
                        StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateApDate[0].endDate).isValid()) {
                        EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }
                    //  let params = { IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
                    //   dispatch(LoadReQAInspectionList(params));
                    dispatch(GetTestEntryDetails(IsProduction
                        , Taskcallback
                        , moment(stateDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
                        , moment(stateDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
                        , moment(stateDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
                        , moment(stateDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
                        , 3));
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }
                //setComment(Values);

            })

        }

    }

    const closeReInspectionDetailpopup = () => {
        setopenReInspopup(false);
    }
    const SelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            ReInspectionDetailList.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            ReInspectionDetailList.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectRecords = (event, index) => {
        const value = [...ReInspectionDetailList];

        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;

        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);

        setReInspectionDetailList(value);
    }
    const AddReInspection = () => {
       
        const Values = [...ReInspectionDetailList]
        let isvalid = true;
        isvalid = !!ReInspectionDetailList.find(x => x.isSelected === 1)
        if (isvalid) {
            let sizeandqtyfordisplay = "";
            let StrikeoffORTrimID = '';
            let NewArr = [];
            NewArr = ReInspectionDetailList.filter(x => x.isSelected === 1)
            if (Taskcallback === "FABRIC TEST" || Taskcallback === "YARN TEST" || Taskcallback === "GARMENT TEST") {
                NewArr.map((Purchaseorder) => {
                    if (sizeandqtyfordisplay === '') {
                        sizeandqtyfordisplay = Purchaseorder.refInspecSKUID.toString();
                    } else {
                        sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
                    }
                })
            } else if (Taskcallback === 'Strike off Test') {
                NewArr.map((Purchaseorder) => {
                    if (StrikeoffORTrimID === '') {
                        StrikeoffORTrimID = Purchaseorder.strikeOffID.toString();
                    } else {
                        StrikeoffORTrimID = StrikeoffORTrimID + ',' + Purchaseorder.strikeOffID
                    }
                })
            } else {
                NewArr.map((Purchaseorder) => {
                    if (StrikeoffORTrimID === '') {
                        StrikeoffORTrimID = Purchaseorder.trimInfoID.toString();
                    } else {
                        StrikeoffORTrimID = StrikeoffORTrimID + ',' + Purchaseorder.trimInfoID
                    }
                })
            }

            let selectedInspIds = '';
            NewArr.map(x => {
                if (x.isSelected === 1) {
                    if (selectedInspIds === '') {
                        if (!selectedInspIds.includes(x.tnaTestingInspectionID)) {
                            selectedInspIds = x.tnaTestingInspectionID.toString();
                        }
                    }
                    else {
                        if (!selectedInspIds.includes(x.tnaTestingInspectionID)) {
                            selectedInspIds = selectedInspIds + "," + x.tnaTestingInspectionID.toString();
                        }
                    }
                }
            })

            let uniquevalues = [...new Map(NewArr.map(item =>
                [item['skuName'], item])).values()];
            let Finaldata = uniquevalues;
            uniquevalues.map(x => {
                let totqty = 0;
                x.FinalInspectionIds = selectedInspIds;
            })
            uniquevalues.map(x => {
                let totqty = 0;
                x.refInspecSKUID = x.skuID;
            })
            var maindetails = {
                supplierId: Finaldata[0].supplierId
                , supplierName: Finaldata[0].supplierName
                , tnaid: Finaldata[0].tnaid
                , purchaseOrderID: Finaldata[0].purchaseOrderID

            }
            getTestingTaskInfo.multiselecteddatas = uniquevalues;
            getTestingTaskInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;

            // getTestingTaskInfo.supplierID = props.location.state.params.supplierId;
            getTestingTaskInfo.multiselecteddatas = ReInspectionDetailList;
            // For Binding Selected checkbox values in quantity inforamtion
            getTestingTaskInfo.details = maindetails;

            getTestingTaskInfo.StrikeoffTrimIDlist = StrikeoffORTrimID;
            getTestingTaskInfo.SkuID = sizeandqtyfordisplay;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
            getTestingTaskInfo.Isproduction = IsProduction;
            getTestingTaskInfo.page = 'ReInspection';//For InspectionType(Reinspection Or New inspection)
            getTestingTaskInfo.pageType = 'AddInspection';//For HeaderName Add,view or edit
            getTestingTaskInfo.ParentTask = "TestModule";//Task Based back or save redirect function

            props.history.push(`/createTesting`, { params: getTestingTaskInfo });
        }
    };

    return (
        <>
            <div className="page-body" style={{ background: "none" }}>
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-body" style={{ boxShadow: "none", padding: "0px" }}>
                                <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12 col-xs-12">
                                            <div className="widget">
                                                <div className="widget-body no-padding">
                                                    <div className="widget-main ">
                                                        <div className="panel-group accordion" id="accordion1">
                                                            <div className="panel panel-default">
                                                                <div className="panel-heading ">
                                                                    <h4 className="panel-title">
                                                                        <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne1">
                                                                            Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                        </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOne1" className="panel-collapse collapse in">
                                                                    <div className="panel-body border-red">
                                                                        <div className="row">
                                                                            <div className="col-md-12">

                                                                                <div className="col-md-2">
                                                                                    <label>Buyer</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Buyer'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Buyer'}
                                                                                            onChange={e => handleChange(e, 'Buyer')}
                                                                                            value={getBuyersearch}
                                                                                        />

                                                                                    </span>
                                                                                </div>


                                                                                <div className="col-md-2">
                                                                                <label>Season</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'Season'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Season'}
                                                                                            onChange={e => handleChange(e, 'Season')}
                                                                                            value={getSeasonsearch}
                                                                                        />

                                                                                    </span>



                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                <label>Brand</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'Brand'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Brand'}
                                                                                            onChange={e => handleChange(e, 'Brand')}
                                                                                            value={getBrandsearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                <label>Quarter</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Quarter'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Quarter'}
                                                                                            onChange={e => handleChange(e, 'Quarter')}
                                                                                            value={getQuartersearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                <label>Supplier</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'Supplier'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Supplier'}
                                                                                            onChange={e => handleChange(e, 'Supplier')}
                                                                                            value={getSuppliersearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                <label>PO/ID No.</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'PO/ID No'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'PO/ID No'}
                                                                                            onChange={e => handleChange(e, 'ID/NO')}
                                                                                            value={getIdPosearch}
                                                                                        />

                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        &nbsp;
                                                                        <div className="row">
                                                                            <div className="col-md-12">

                                                                                <div className="col-md-2">
                                                                                    <label>&nbsp;</label>
                                                                                    <label>Style Name</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Style Name'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Style Name'}
                                                                                            onChange={e => handleChange(e, 'StyleName')}
                                                                                            value={getStyleNamesearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>

                                                                                <div className="col-md-2">
                                                                                    <label>&nbsp;</label>
                                                                                    <label>SKU Name</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'SKU Name'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'SKU Name'}
                                                                                            onChange={e => handleChange(e, 'SKUName')}
                                                                                            value={getSkuSearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>Re Test Status Filter</label>
                                                                                    <span className='input-icon icon-right' onClick={handleclick}>
                                                                                        <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                            isClearable={true}
                                                                                        ></Reactselect>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-2" >
                                                                                <label>Test Entry No.</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Test Entry No'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Test Entry No'}
                                                                                            onChange={e => handleChange(e, 'TestEntryNo')}
                                                                                            value={getTestEntryNoSearch}
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>Test Date</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('MM/DD/YYYY') + ' To ') +
                                                                                            ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateDate[0].endDate).format('MM/DD/YYYY'))}
                                                                                            onClick={event => handleDateSelect(event, !stateDate[0].isShow)}

                                                                                            type="text"
                                                                                            className={'form-control styledatapickerwidth100'}
                                                                                            placeholder="Select Date"

                                                                                        />
                                                                                        <i className="fa fa-times stylemlefttesting" onClick={clearDateSelect} ></i>
                                                                                    </span>


                                                                                    <span className='input-icon icon-right mb-5'>
                                                                                        <br />
                                                                                        {stateDate[0].isShow &&
                                                                                            <DateRangePicker
                                                                                                // onChange={item => setStateDate([item.selection])}
                                                                                                onChange={item => DateRangechange([item.selection])}
                                                                                                showSelectionPreview={true}
                                                                                                moveRangeOnFirstSelection={false}
                                                                                                months={2}
                                                                                                ranges={stateDate}
                                                                                                showDateDisplay={false}
                                                                                                direction="vertical"
                                                                                                className={'StyleDateRangePicker TNADateRangeStyle'}

                                                                                            />

                                                                                        }
                                                                                    </span>
                                                                                </div>

                                                                                <div className="col-md-2">
                                                                                    <div className="col-md-1 margin-top-30">
                                                                                        <span className="input-icon icon-right">
                                                                                            <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetFinalIns()}
                                                                                            >
                                                                                                <i className="fa fa-close"> Reset</i>
                                                                                            </button>
                                                                                        </span>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                    &nbsp;

                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            {/* <div className="col-md-3" style={{ marginTop: '23px' }}>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Factory'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Factory'}
                                                                                            onChange={e => handleChange(e, 'Factory')}
                                                                                            value={getFactorySearch}
                                                                                        />
                                                                                    </span>
                                                                                </div> */}


                                                                        </div>
                                                                    </div>

                                                                    &nbsp;
                                                                </div>
                                                            </div>
                                                            &nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
                            <div className="fixTableHeadTnadetail">
                                <Modal.Header closeButton>
                                    <Modal.Title>Show Hide Header</Modal.Title>
                                </Modal.Header>
                                {/* , overflowY: "auto" */}
                                <Modal.Body className="customdialogbody">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="EntryNo"
                                                                checked={checkboxStates.EntryNo}
                                                            />
                                                            &nbsp;<label htmlFor="EntryNo">Entry No</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="TestType"
                                                                checked={checkboxStates.TestType}
                                                            />
                                                            &nbsp;<label htmlFor="TestType">Test Type</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="Season"
                                                                checked={checkboxStates.Season}
                                                            />
                                                            &nbsp;<label htmlFor="Season">Season</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="Supplier"
                                                                checked={checkboxStates.Supplier}
                                                            />
                                                            &nbsp;<label htmlFor="Supplier">Supplier</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="SupplierRefNo"
                                                                checked={checkboxStates.SupplierRefNo}
                                                            />
                                                            &nbsp;<label htmlFor="Supplier">Supplier Ref No</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="SKUName"
                                                                checked={checkboxStates.SKUName}
                                                            />
                                                            &nbsp;
                                                            <label htmlFor="SKUName">SKU Name</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="IDNo"
                                                                checked={checkboxStates.IDNo}
                                                            />
                                                            &nbsp;
                                                            <label htmlFor="IDNo">PO/ID No</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="LotNo"
                                                                checked={checkboxStates.LotNo}
                                                            />
                                                            &nbsp;
                                                            <label htmlFor="LotNo">Lot No</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="LotWeight"
                                                                checked={checkboxStates.LotWeight}
                                                            />
                                                            &nbsp;
                                                            <label htmlFor="LotWeight">Lot Weight</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="TestResult"
                                                                checked={checkboxStates.TestResult}
                                                            />
                                                            &nbsp;
                                                            <label htmlFor="TestResult">Test Result</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="ApprovalResult"
                                                                checked={checkboxStates.ApprovalResult}
                                                            />
                                                            &nbsp;
                                                            <label htmlFor="ApprovalResult">Approval Result</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="ReTestStatus"
                                                                checked={checkboxStates.ReTestStatus}
                                                            />
                                                            &nbsp;
                                                            <label htmlFor="ReTestStatus">Re Test Status</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="BalanceForReTest"
                                                                checked={checkboxStates.BalanceForReTest}
                                                            />
                                                            &nbsp;
                                                            <label htmlFor="BalanceForReTest">Balance For Re Test</label>
                                                        </li>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <li
                                                            className="listmodal"
                                                            onChange={handleCheckboxChange}>
                                                            <input
                                                                className="hidecheckbox"
                                                                type="checkbox"
                                                                name="CompletedReTestReportNo"
                                                                checked={checkboxStates.CompletedReTestReportNo}
                                                            />
                                                            &nbsp;
                                                            <label htmlFor="CompletedReTestReportNo">Completed Re Test Report No</label>
                                                        </li>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </div>
                        </Modal>
                        <div className="row">
                            <div className="col-lg-11">
                            </div>
                            <button style={{ marginTop: '8px', marginBottom: '7px' }} title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>
                            {/* <label style={{ marginTop: '7px', marginLeft: "34px" }} title={isAllSelected ? "Un Select All" : "Select All"}>

                            <input type="checkbox" name="IsAllSelected"
                                onChange={event => (handleAllOnSelect(event, 'IsAllSelected', ITEMS_PER_PAGE, totalItems))}
                                value={isAllSelected ? true : false}
                                checked={isAllSelected ? true : false}
                                className="selectallcheckbox"
                            />
                        </label> */}
                            {commentsData.length !== 0 && (
                                <ReactHTMLTableToExcel
                                    id="test-table-s-button-final"
                                    className="fa fa-file-excel-o fa-fa-file-excelcomt"
                                    table={"table-to-xlsentry"}
                                    filename={"ToDoReInspectionList"}
                                    sheet="ToDoReInspection"
                                    buttonText=""
                                />
                            )}
                            <div className="col-md-12">
                                <div style={{ overflow: "auto" }}>
                                    <table data-role="table" data-mode="columntoggle" className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "110vm" }}>
                                        <TableHeader headers={(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? StrikeofftableHeader : Taskcallback === 'GARMENT TEST' ? garmenttableHeader : tableHeader}
                                            onSorting={
                                                (field, order) => setSorting({ field, order })
                                            }
                                        />
                                        <tbody>
                                            {
                                                commentsData.map((comment,i) => (
                                                    <Fragment key={i}>
                                                    <>
                                                        {Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test' ? <tr >
                                                        <td>{i+1}</td>
                                                            {
                                                                
                                                                checkboxStates.EntryNo && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.testInspectionID}>
                                                                        {comment.testInspectionID}
                                                                    </td>
                                                                )
                                                            }


                                                            {
                                                                checkboxStates.Supplier && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.supplierName}>
                                                                        {comment.supplierName}
                                                                    </td>

                                                                )
                                                            }
                                                            {
                                                                checkboxStates.Supplier && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.styleName}>
                                                                        {comment.styleName}
                                                                    </td>

                                                                )
                                                            }
                                                            {
                                                                checkboxStates.TestType && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.strikeoffTrimName}>
                                                                        {comment.strikeoffTrimName}
                                                                    </td>
                                                                )
                                                            }
                                                            {/* { checkboxStates.TestType && (
                                                               <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.strikeoffTrimName}>
                                                                    {comment.strikeoffTrimName}
                                                           </td>
                                                                )
                                                            } */}
                                                            {/* {checkboxStates.StrikeoffTrimName && (
                                                                    <td
                                                                        className="cut_textInTNA"
                                                                        title={comment.strikeoffTrimName}>
                                                                            {(comment.strikeoffTrimName === '' || comment.strikeoffTrimName === null) ? '-' : comment.strikeoffTrimName}
                                                                    </td>
                                                            )} */}
                                                            {/* {
                                                                checkboxStates.SupplierRefNo && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.supplierRefNo}>
                                                                        {comment.supplierRefNo}
                                                                    </td>

                                                                )
                                                            } */}
                                                            {
                                                                checkboxStates.IDNo && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={(comment.idpono === '' || comment.idpono === null) ? '-' : comment.idpono}>
                                                                        {(comment.idpono === '' || comment.idpono === null) ? '-' : comment.idpono}
                                                                    </td>
                                                                )
                                                            }

                                                            {checkboxStates.Image && <td>{< SimpleReactLightbox >
                                                                <SRLWrapper options={options}>
                                                                    {
                                                                        < img className="width_manual"
                                                                            src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.swatchFilepath}
                                                                        // alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)}
                                                                        />

                                                                    }

                                                                </SRLWrapper>
                                                            </SimpleReactLightbox>}</td>}
                                                            {/* {
                                                                checkboxStates.ReportQuantity && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.inspectionQty}>
                                                                        {comment.inspectionQty}
                                                                    </td>
                                                                )
                                                            } */}
                                                            {
                                                                checkboxStates.TestResult && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        className={comment.inspectionResult === 'Satisfied' ? 'success' : comment.inspectionResult === 'Not Satisfied' ? 'danger' : 'warning'}
                                                                        title={comment.inspectionResult}>
                                                                        {comment.inspectionResult}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.ApprovalResult && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.approvalResult}>
                                                                        {comment.approvalResult}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.ReTestStatus && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.reTestStatus}>
                                                                        {comment.reTestStatus}
                                                                    </td>
                                                                )
                                                            }
                                                            {/* {
                                                                checkboxStates.BalanceForReTest && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        title={comment.balanceForReTest}>
                                                                        {comment.balanceForReTest}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.CompletedReTestReportNo && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        title={comment.completedReTestReportNo}>
                                                                        {comment.completedReTestReportNo}
                                                                    </td>
                                                                )
                                                            } */}



                                                            {< td className="text-center fixed-column-header-CommentReport" style={{ width: "5%", }}>
                                                                < span >
                                                                    <button type="button" style={{
                                                                        background: (comment.currentComments === '' || comment.currentComments === null) ? '#427fed' : 'red',
                                                                        color: 'white',
                                                                        padding: '3px 6px',
                                                                        borderRadius: '1px',
                                                                        border: (comment.currentComments === '' || comment.currentComments === null) ? '#427fed' : 'red'
                                                                    }} title='Comment'
                                                                        onClick={() => GetComments(comment, 'Comments')}>
                                                                        <i className="fa fa-comment"></i>
                                                                    </button>
                                                                    &nbsp;
                                                                    <button type="button" className="btn btn-xs btn-success" title='Add Re Inspection'
                                                                        // onClick={() => handleFinalInspecView(comment, 'Edit', '')}
                                                                        onClick={() => openReInspectionDetailpopup(comment.testInspectionID, comment.tnaid, comment)}

                                                                    >
                                                                        <i className="fa fa-plus "></i>
                                                                    </button>
                                                                </span >
                                                            </td>}
                                                            {
                                                                sizeqtypopup === true ?

                                                                    <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                                                                        {/* <Modal.Header closeButton>
                                                      <Modal.Title>Size and Qty </Modal.Title>

                                                  </Modal.Header> */}

                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>SKU and Qty
                                                                            </Modal.Title>


                                                                        </Modal.Header>
                                                                        <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                                                                            {/* <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                        <div className="col-lg-6">

                                                        </div>
                                                        <div className="col-lg-2">
                                                            <h5>Total : {getskuid.qrty}</h5>
                                                        </div>*/}

                                                                        </div>

                                                                        <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                                            <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ width: "350px" }}>PO-SKUID</th>
                                                                                        <th style={{ width: "350px" }}>SKU</th>
                                                                                        <th style={{ width: "160px" }}>Qty</th>
                                                                                    </tr>

                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        skuqtylist.map((item, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span className="input-icon icon-right">
                                                                                                                {item.purchaseOrderSkuID}
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <span className="input-icon icon-right">
                                                                                                                {item.skuName}
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <span className="input-icon icon-right">
                                                                                                                {item.offeredQty}
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>

                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>

                                                                        </Modal.Footer>

                                                                    </Modal> : ''
                                                            }
                                                            {
                                                                Commentpopup === true ?
                                                                    // style={{ height: "25%" }}
                                                                    <Modal show={Commentpopup === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose('reportcmt')}>
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>
                                                                                Comments

                                                                            </Modal.Title>
                                                                        </Modal.Header>
                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Comments<span className="text-danger">*</span></th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colSpan='2'>
                                                                                        <textarea
                                                                                            rows="4"
                                                                                            placeholder="Enter comments here..."
                                                                                            autoFocus autoComplete="off"
                                                                                            maxLength="500" name="EnterComment"
                                                                                            id={"EnterComment"}
                                                                                            value={Ecomment}
                                                                                            onChange={event => handleChangeComment(event, "reportComment")}
                                                                                            style={{ width: "100%", border: Submitted && (Ecomment === undefined || Ecomment === '') ? '1px solid red' : '' }}>
                                                                                        </textarea>
                                                                                    </td>

                                                                                </tr >

                                                                            </tbody >
                                                                        </table >
                                                                        <Modal.Body style={{ overflow: "auto", height: "520px" }}>

                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        {/* <th>Results</th> */}
                                                                                        <th>Comments</th>
                                                                                        <th>Created By</th>
                                                                                        <th>Approval Date</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        getFIRemark.length === 0 ?
                                                                                            <tr>
                                                                                                <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                                                                            </tr> :
                                                                                            getFIRemark.map((Commentitem) => {
                                                                                                return (

                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            {Commentitem.commentsDetail}
                                                                                                        </td>
                                                                                                        <td>{Commentitem.createdBy}</td>
                                                                                                        <td>
                                                                                                            {moment(Commentitem.commentsDate).format('MM/DD/YYYY')}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                );
                                                                                            })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <Button variant="success" onClick={(event) => handleSaveApproval(event, comment)}>
                                                                                Save
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal >
                                                                    : ''
                                                            }
                                                            {
                                                                openReInspopup === true &&
                                                                <Modal show={openReInspopup === true} size="lg" dragable backdrop="static" keyboard={false}
                                                                    onHide={() => closeReInspectionDetailpopup()}
                                                                >
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                            ADD Re Inspection

                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                                                            <table className="table table-bordered">
                                                                                <thead>
                                                                                    <tr style={{ whiteSpace: "nowrap" }}>
                                                                                        <th>Report No</th>
                                                                                        <th>Style Name</th>
                                                                                        <th>Style No</th>
                                                                                        <th>SKU ID</th>
                                                                                        <th>SKU Name</th>
                                                                                        <th>PO/ID No</th>
                                                                                        <th>FO PO No</th>
                                                                                        {/* <th>Factory</th> */}
                                                                                        {(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? '' : <th>Inspected Qty</th>}
                                                                                        <th>Task Holder / Follower</th>
                                                                                        <th>
                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                <input type="checkbox" name="IsSelected"

                                                                                                    onChange={event => (SelectAll(event))}
                                                                                                    checked={selectall === 1 ? true : false}
                                                                                                    className="colored-blue clearOpacity" />
                                                                                                <span className="text"></span>
                                                                                            </label>
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        ReInspectionDetailList.length !== 0 ?
                                                                                            ReInspectionDetailList.map((item, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td>{item.tnaTestingInspectionID}</td>
                                                                                                            <td>{item.styleName}</td>
                                                                                                            <td>{item.styleNo}</td>
                                                                                                            {(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? <td>{item.skuIdList}</td> : <td>{item.skuID}</td>}
                                                                                                            <td>{item.skuName}</td>
                                                                                                            <td>{item.idNo}</td>
                                                                                                            <td>{item.poNo}</td>
                                                                                                            {/* <td>{item.factory}</td> */}
                                                                                                            {(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? '' : <td>{item.inspectedQty}</td>}
                                                                                                            <td className="cut_textInTNA" title={item.taskOwnerNameList}>{item.taskOwnerNameList}</td>
                                                                                                            <td className="Actionbutton">
                                                                                                                <div style={{ width: "80px" }}>
                                                                                                                    {

                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                            <input type="checkbox" name="IsSelected"
                                                                                                                                disabled={item.balanceQuantity === "0" ? true : false}
                                                                                                                                onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                checked={item.isSelected === 1 ? true : false}
                                                                                                                                className="colored-blue clearOpacity" />
                                                                                                                            <span className="text"></span>
                                                                                                                        </label>
                                                                                                                    }&nbsp;&nbsp;&nbsp;
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>

                                                                                                )
                                                                                            }) :
                                                                                            <tr>
                                                                                                <td colSpan="12" className='norecordfound'><span>No Records Found</span></td>
                                                                                            </tr>
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        {
                                                                            <Button variant="success" onClick={() => AddReInspection()}>
                                                                                Save
                                                                            </Button>
                                                                        }
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            }
                                                        </tr> : Taskcallback === 'GARMENT TEST' ?
                                                            <tr >
                                                                {
                                                                    checkboxStates.EntryNo && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.testInspectionID}>
                                                                            {comment.testInspectionID}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.Supplier && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.supplierName}>
                                                                            {comment.supplierName}
                                                                        </td>

                                                                    )
                                                                }
                                                                {checkboxStates.Supplier && <td title={comment.fabricDetailName}>{comment.fabricDetailName}</td>}
                                                                {/* {checkboxStates.Supplier && <td>{(comment.colorname === '' || comment.colorname === null) ? '-' : comment.colorname}</td>} */}
                                                                {
                                                                    checkboxStates.StyleName && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.styleName}>
                                                                            {comment.styleName}
                                                                        </td>

                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.SKUName && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.SKUName}>
                                                                            {comment.skuName}
                                                                        </td>

                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.IDNo && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={(comment.idpono === '' || comment.idpono === null) ? '-' : comment.idpono}>
                                                                            {(comment.idpono === '' || comment.idpono === null) ? '-' : comment.idpono}
                                                                        </td>
                                                                    )
                                                                }
                                                                {checkboxStates.Image && <td>{< SimpleReactLightbox >
                                                                    <SRLWrapper options={options}>
                                                                        {
                                                                            < img className="width_manual"
                                                                                src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.swatchFilepath}
                                                                            // alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)}
                                                                            />

                                                                        }

                                                                    </SRLWrapper>
                                                                </SimpleReactLightbox>}</td>}

                                                                {/* {
                                                                checkboxStates.LotWeight && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={'Lot Weigth'}>
                                                                        {comment.lotWeight}
                                                                    </td>
                                                                )
                                                            } */}
                                                                {
                                                                    checkboxStates.ReportQuantity && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.inspectionQty}>
                                                                            {comment.inspectionQty}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.TestResult && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.inspectionResult}>
                                                                            {comment.inspectionResult}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.ApprovalResult && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.approvalResult}>
                                                                            {comment.approvalResult}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.ReTestStatus && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.reTestStatus}>
                                                                            {comment.reTestStatus}
                                                                        </td>
                                                                    )
                                                                }
                                                                {/* {
                                                                checkboxStates.BalanceForReTest && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        title={comment.balanceForReTest}>
                                                                        {comment.balanceForReTest}
                                                                    </td>
                                                                )
                                                            } */}
                                                                {/* {
                                                                checkboxStates.CompletedReTestReportNo && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        title={comment.completedReTestReportNo}>
                                                                        {comment.completedReTestReportNo}
                                                                    </td>
                                                                )
                                                            } */}



                                                                {< td className="text-center fixed-column-header-CommentReport" style={{ width: "5%", }}>
                                                                    < span >
                                                                        <button type="button"
                                                                            style={{
                                                                                background: (comment.currentComments === '' || comment.currentComments === null) ? '#427fed' : 'red',
                                                                                color: 'white',
                                                                                padding: '3px 6px',
                                                                                borderRadius: '1px',
                                                                                border: (comment.currentComments === '' || comment.currentComments === null) ? '#427fed' : 'red'
                                                                            }} title='Comment'
                                                                            onClick={() => GetComments(comment, 'Comments')}>
                                                                            <i className="fa fa-comment"></i>
                                                                        </button>
                                                                        &nbsp;
                                                                        <button type="button" className="btn btn-xs btn-success" title='Add Re Inspection'
                                                                            // onClick={() => handleFinalInspecView(comment, 'Edit', '')}
                                                                            onClick={() => openReInspectionDetailpopup(comment.testInspectionID, comment.tnaid, comment)}

                                                                        >
                                                                            <i className="fa fa-plus "></i>
                                                                        </button>
                                                                    </span >
                                                                </td>}
                                                                {
                                                                    sizeqtypopup === true ?

                                                                        <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                                                                            {/* <Modal.Header closeButton>
                                                      <Modal.Title>Size and Qty </Modal.Title>

                                                  </Modal.Header> */}

                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>SKU and Qty
                                                                                </Modal.Title>


                                                                            </Modal.Header>
                                                                            <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                                                                                {/* <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                        <div className="col-lg-6">

                                                        </div>
                                                        <div className="col-lg-2">
                                                            <h5>Total : {getskuid.qrty}</h5>
                                                        </div>*/}

                                                                            </div>

                                                                            <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                                                <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: "350px" }}>PO-SKUID</th>
                                                                                            <th style={{ width: "350px" }}>SKU</th>
                                                                                            <th style={{ width: "160px" }}>Qty</th>
                                                                                        </tr>

                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            skuqtylist.map((item, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span className="input-icon icon-right">
                                                                                                                    {item.purchaseOrderSkuID}
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="input-icon icon-right">
                                                                                                                    {item.skuName}
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="input-icon icon-right">
                                                                                                                    {item.offeredQty}
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>

                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </Modal.Body>
                                                                            <Modal.Footer>

                                                                            </Modal.Footer>

                                                                        </Modal> : ''
                                                                }
                                                                {
                                                                    Commentpopup === true ?
                                                                        // style={{ height: "25%" }}
                                                                        <Modal show={Commentpopup === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose('reportcmt')}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>
                                                                                    Comments

                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Comments<span className="text-danger">*</span></th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td colSpan='2'>
                                                                                            <textarea
                                                                                                rows="4"
                                                                                                placeholder="Enter comments here..."
                                                                                                autoFocus autoComplete="off"
                                                                                                maxLength="500" name="EnterComment"
                                                                                                id={"EnterComment"}
                                                                                                value={Ecomment}
                                                                                                onChange={event => handleChangeComment(event, "reportComment")}
                                                                                                style={{ width: "100%", border: Submitted && (Ecomment === undefined || Ecomment === '') ? '1px solid red' : '' }}>
                                                                                            </textarea>
                                                                                        </td>

                                                                                    </tr >

                                                                                </tbody >
                                                                            </table >
                                                                            <Modal.Body style={{ overflow: "auto", height: "520px" }}>

                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            {/* <th>Results</th> */}
                                                                                            <th>Comments</th>
                                                                                            <th>Created By</th>
                                                                                            <th>Approval Date</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            getFIRemark.length === 0 ?
                                                                                                <tr>
                                                                                                    <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                                                                                </tr> :
                                                                                                getFIRemark.map((Commentitem) => {
                                                                                                    return (

                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                {Commentitem.commentsDetail}
                                                                                                            </td>
                                                                                                            <td>{Commentitem.createdBy}</td>
                                                                                                            <td>
                                                                                                                {moment(Commentitem.commentsDate).format('MM/DD/YYYY')}
                                                                                                            </td>
                                                                                                        </tr>

                                                                                                    );
                                                                                                })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="success" onClick={(event) => handleSaveApproval(event, comment)}>
                                                                                    Save
                                                                                </Button>
                                                                            </Modal.Footer>
                                                                        </Modal >
                                                                        : ''
                                                                }
                                                                {
                                                                    openReInspopup === true &&
                                                                    <Modal show={openReInspopup === true} size="xl" dragable backdrop="static" keyboard={false}
                                                                        onHide={() => closeReInspectionDetailpopup()}
                                                                    >
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>
                                                                                {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                                ADD Re Inspection

                                                                            </Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                                                                <table className="table table-bordered">
                                                                                    <thead>
                                                                                        <tr style={{ whiteSpace: "nowrap" }}>
                                                                                            <th>Report No</th>
                                                                                            <th>Style Name</th>
                                                                                            <th>Style No</th>
                                                                                            <th>SKU ID</th>
                                                                                            <th>SKU Name</th>
                                                                                            <th>PO/ID No</th>
                                                                                            <th>FO PO No</th>
                                                                                            {/* <th>Factory</th> */}
                                                                                            {(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? '' : <th>Order Qty For Testing</th>}
                                                                                            <th>Task Holder / Follower</th>
                                                                                            <th>
                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                    <input type="checkbox" name="IsSelected"

                                                                                                        onChange={event => (SelectAll(event))}
                                                                                                        checked={selectall === 1 ? true : false}
                                                                                                        className="colored-blue clearOpacity" />
                                                                                                    <span className="text"></span>
                                                                                                </label>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            ReInspectionDetailList.length !== 0 ?
                                                                                                ReInspectionDetailList.map((item, index) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <tr>
                                                                                                                <td>{item.tnaTestingInspectionID}</td>
                                                                                                                <td>{item.styleName}</td>
                                                                                                                <td>{item.styleNo}</td>
                                                                                                                {(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? <td>{item.skuIdList}</td> : <td>{item.skuID}</td>}
                                                                                                                <td>{item.skuName}</td>
                                                                                                                <td>{item.idNo}</td>
                                                                                                                <td>{item.poNo}</td>
                                                                                                                {/* <td>{item.factory}</td> */}
                                                                                                                {(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? '' : <td>{item.inspectedQty}</td>}
                                                                                                                <td className="cut_textInTNA" title={item.taskOwnerNameList}>{item.taskOwnerNameList}</td>
                                                                                                                <td className="Actionbutton">
                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                        {

                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                    disabled={item.balanceQuantity === "0" ? true : false}
                                                                                                                                    onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                <span className="text"></span>
                                                                                                                            </label>
                                                                                                                        }&nbsp;&nbsp;&nbsp;
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </>

                                                                                                    )
                                                                                                }) :
                                                                                                <tr>
                                                                                                    <td colSpan="12" className='norecordfound'><span>No Records Found</span></td>
                                                                                                </tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            {
                                                                                <Button variant="success" onClick={() => AddReInspection()}>
                                                                                    Save
                                                                                </Button>
                                                                            }
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                }
                                                            </tr>
                                                            :
                                                            <tr >
                                                                {
                                                                    checkboxStates.EntryNo && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.testInspectionID}>
                                                                            {comment.testInspectionID}
                                                                        </td>
                                                                    )
                                                                }

                                                                {/* {
                                                                checkboxStates.TestType && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.reportType}>
                                                                        {comment.reportType}
                                                                    </td>
                                                                )
                                                            } */}
                                                                {
                                                                    checkboxStates.Supplier && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.supplierName}>
                                                                            {comment.supplierName}
                                                                        </td>

                                                                    )
                                                                }
                                                                {checkboxStates.Supplier && <td title={comment.fabricDetailName}>{comment.fabricDetailName}</td>}
                                                                {checkboxStates.Supplier && <td>{(comment.colorname === '' || comment.colorname === null) ? '-' : comment.colorname}</td>}
                                                                {
                                                                    checkboxStates.StyleName && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.styleName}>
                                                                            {comment.styleName}
                                                                        </td>

                                                                    )
                                                                }
                                                                {/* {
                                                                checkboxStates.SupplierRefNo && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.supplierRefNo}>
                                                                        {comment.supplierRefNo}
                                                                    </td>

                                                                )
                                                            } */}
                                                                {
                                                                    checkboxStates.IDNo && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={(comment.idpono === '' || comment.idpono === null) ? '-' : comment.idpono}>
                                                                            {(comment.idpono === '' || comment.idpono === null) ? '-' : comment.idpono}
                                                                        </td>
                                                                    )
                                                                }

                                                                {checkboxStates.Image && <td>{< SimpleReactLightbox >
                                                                    <SRLWrapper options={options}>
                                                                        {
                                                                            < img className="width_manual"
                                                                                src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.swatchFilepath}
                                                                            // alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)}
                                                                            />

                                                                        }

                                                                    </SRLWrapper>
                                                                </SimpleReactLightbox>}</td>}
                                                                {
                                                                    checkboxStates.LotNo && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.lotWeight}>
                                                                            {comment.lotWeight}
                                                                        </td>
                                                                    )
                                                                }
                                                                {/* {
                                                                checkboxStates.LotWeight && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={'Lot Weigth'}>
                                                                        {comment.lotWeight}
                                                                    </td>
                                                                )
                                                            } */}
                                                                {
                                                                    checkboxStates.ReportQuantity && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.inspectionQty}>
                                                                            {comment.inspectionQty}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.TestResult && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.inspectionResult}>
                                                                            {comment.inspectionResult}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.ApprovalResult && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.approvalResult}>
                                                                            {comment.approvalResult}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.ReTestStatus && (

                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.reTestStatus}>
                                                                            {comment.reTestStatus}
                                                                        </td>
                                                                    )
                                                                }
                                                                {/* {
                                                                checkboxStates.BalanceForReTest && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        title={comment.balanceForReTest}>
                                                                        {comment.balanceForReTest}
                                                                    </td>
                                                                )
                                                            } */}
                                                                {/* {
                                                                checkboxStates.CompletedReTestReportNo && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        title={comment.completedReTestReportNo}>
                                                                        {comment.completedReTestReportNo}
                                                                    </td>
                                                                )
                                                            } */}



                                                                {< td className="text-center fixed-column-header-CommentReport" style={{ width: "5%", }}>
                                                                    < span >
                                                                        <button type="button"
                                                                            style={{
                                                                                background: (comment.currentComments === '' || comment.currentComments === null) ? '#427fed' : 'red',
                                                                                color: 'white',
                                                                                padding: '3px 6px',
                                                                                borderRadius: '1px',
                                                                                border: (comment.currentComments === '' || comment.currentComments === null) ? '#427fed' : 'red'
                                                                            }} title='Comment'
                                                                            onClick={() => GetComments(comment, 'Comments')}>
                                                                            <i className="fa fa-comment"></i>
                                                                        </button>
                                                                        &nbsp;
                                                                        <button type="button" className="btn btn-xs btn-success" title='Add Re Inspection'
                                                                            // onClick={() => handleFinalInspecView(comment, 'Edit', '')}
                                                                            onClick={() => openReInspectionDetailpopup(comment.testInspectionID, comment.tnaid, comment)}

                                                                        >
                                                                            <i className="fa fa-plus "></i>
                                                                        </button>
                                                                    </span >
                                                                </td>}
                                                                {
                                                                    sizeqtypopup === true ?

                                                                        <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                                                                            {/* <Modal.Header closeButton>
                                                      <Modal.Title>Size and Qty </Modal.Title>

                                                  </Modal.Header> */}

                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>SKU and Qty
                                                                                </Modal.Title>


                                                                            </Modal.Header>
                                                                            <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                                                                                {/* <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                        <div className="col-lg-6">

                                                        </div>
                                                        <div className="col-lg-2">
                                                            <h5>Total : {getskuid.qrty}</h5>
                                                        </div>*/}

                                                                            </div>

                                                                            <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                                                <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: "350px" }}>PO-SKUID</th>
                                                                                            <th style={{ width: "350px" }}>SKU</th>
                                                                                            <th style={{ width: "160px" }}>Qty</th>
                                                                                        </tr>

                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            skuqtylist.map((item, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span className="input-icon icon-right">
                                                                                                                    {item.purchaseOrderSkuID}
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="input-icon icon-right">
                                                                                                                    {item.skuName}
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className="input-icon icon-right">
                                                                                                                    {item.offeredQty}
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>

                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </Modal.Body>
                                                                            <Modal.Footer>

                                                                            </Modal.Footer>

                                                                        </Modal> : ''
                                                                }
                                                                {
                                                                    Commentpopup === true ?
                                                                        // style={{ height: "25%" }}
                                                                        <Modal show={Commentpopup === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose('reportcmt')}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>
                                                                                    Comments

                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Comments<span className="text-danger">*</span></th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td colSpan='2'>
                                                                                            <textarea
                                                                                                rows="4"
                                                                                                placeholder="Enter comments here..."
                                                                                                autoFocus autoComplete="off"
                                                                                                maxLength="500" name="EnterComment"
                                                                                                id={"EnterComment"}
                                                                                                value={Ecomment}
                                                                                                onChange={event => handleChangeComment(event, "reportComment")}
                                                                                                style={{ width: "100%", border: Submitted && (Ecomment === undefined || Ecomment === '') ? '1px solid red' : '' }}>
                                                                                            </textarea>
                                                                                        </td>

                                                                                    </tr >

                                                                                </tbody >
                                                                            </table >
                                                                            <Modal.Body style={{ overflow: "auto", height: "520px" }}>

                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            {/* <th>Results</th> */}
                                                                                            <th>Comments</th>
                                                                                            <th>Created By</th>
                                                                                            <th>Approval Date</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            getFIRemark.length === 0 ?
                                                                                                <tr>
                                                                                                    <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                                                                                </tr> :
                                                                                                getFIRemark.map((Commentitem) => {
                                                                                                    return (

                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                {Commentitem.commentsDetail}
                                                                                                            </td>
                                                                                                            <td>{Commentitem.createdBy}</td>
                                                                                                            <td>
                                                                                                                {moment(Commentitem.commentsDate).format('MM/DD/YYYY')}
                                                                                                            </td>
                                                                                                        </tr>

                                                                                                    );
                                                                                                })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="success" onClick={(event) => handleSaveApproval(event, comment)}>
                                                                                    Save
                                                                                </Button>
                                                                            </Modal.Footer>
                                                                        </Modal >
                                                                        : ''
                                                                }
                                                                {
                                                                    openReInspopup === true &&
                                                                    <Modal show={openReInspopup === true} size="xl" dragable backdrop="static" keyboard={false}
                                                                        onHide={() => closeReInspectionDetailpopup()}
                                                                    >
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>
                                                                                {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                                ADD Re Inspection

                                                                            </Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                                                                <table className="table table-bordered">
                                                                                    <thead>
                                                                                        <tr style={{ whiteSpace: "nowrap" }}>
                                                                                            <th>Report No</th>
                                                                                            <th>Style Name</th>
                                                                                            <th>Style No</th>
                                                                                            <th>SKU ID</th>
                                                                                            <th>SKU Name</th>
                                                                                            <th>PO/ID No</th>
                                                                                            <th>FO PO No</th>
                                                                                            {/* <th>Factory</th> */}
                                                                                            {(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? '' : <th>Order Qty For Testing</th>}
                                                                                            <th>Task Holder / Follower</th>
                                                                                            <th>
                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                    <input type="checkbox" name="IsSelected"

                                                                                                        onChange={event => (SelectAll(event))}
                                                                                                        checked={selectall === 1 ? true : false}
                                                                                                        className="colored-blue clearOpacity" />
                                                                                                    <span className="text"></span>
                                                                                                </label>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            ReInspectionDetailList.length !== 0 ?
                                                                                                ReInspectionDetailList.map((item, index) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <tr>
                                                                                                                <td>{item.tnaTestingInspectionID}</td>
                                                                                                                <td>{item.styleName}</td>
                                                                                                                <td>{item.styleNo}</td>
                                                                                                                {(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? <td>{item.skuIdList}</td> : <td>{item.skuID}</td>}
                                                                                                                <td>{item.skuName}</td>
                                                                                                                <td>{item.idNo}</td>
                                                                                                                <td>{item.poNo}</td>
                                                                                                                {/* <td>{item.factory}</td> */}
                                                                                                                {(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? '' : <td>{item.inspectedQty}</td>}
                                                                                                                <td className="cut_textInTNA" title={item.taskOwnerNameList}>{item.taskOwnerNameList}</td>
                                                                                                                <td className="Actionbutton">
                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                        {

                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                    disabled={item.balanceQuantity === "0" ? true : false}
                                                                                                                                    onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                <span className="text"></span>
                                                                                                                            </label>
                                                                                                                        }&nbsp;&nbsp;&nbsp;
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </>

                                                                                                    )
                                                                                                }) :
                                                                                                <tr>
                                                                                                    <td colSpan="12" className='norecordfound'><span>No Records Found</span></td>
                                                                                                </tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            {
                                                                                <Button variant="success" onClick={() => AddReInspection()}>
                                                                                    Save
                                                                                </Button>
                                                                            }
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                }
                                                            </tr>}
                                                    </>
                                                    </Fragment>
                                                ))
                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="50" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }

                                        </tbody >

                                        <table data-role="table" data-mode="columntoggle" id="table-to-xlsentry"
                                            className="table table-striped table-bordered table-hover dataTable no-footer"
                                            style={{ width: "200%", display: 'none' }}>
                                            <thead>
                                                {
                                                    <div style={{ display: 'inline-block' }}>
                                                        {
                                                            (stateDate[0].startDate !== null && stateDate[0].endDate !== null) && (stateApDate[0].startDate !== null && stateApDate[0].endDate !== null) ?
                                                                <>
                                                                    <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('MM/DD/YYYY') + ' To ') +
                                                                        ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                            moment(stateDate[0].endDate).format('MM/DD/YYYY'))}</label>&nbsp; AND &nbsp;

                                                                    < label > <b>Ex-Fac:</b> {(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('MM/DD/YYYY') + ' To ') +
                                                                        ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                            moment(stateApDate[0].endDate).format('MM/DD/YYYY'))}</label>
                                                                </>


                                                                :

                                                                (stateDate[0].startDate !== null && stateDate[0].endDate !== null) ?
                                                                    <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('MM/DD/YYYY') + ' To ') +
                                                                        ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                            moment(stateDate[0].endDate).format('MM/DD/YYYY'))}</label>
                                                                    :

                                                                    (stateApDate[0].startDate !== null && stateApDate[0].endDate !== null) ?
                                                                        < label > <b>Ex-Fac:</b> {(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('MM/DD/YYYY') + ' To ') +
                                                                            ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                                moment(stateApDate[0].endDate).format('MM/DD/YYYY'))}</label> : ''

                                                        }
                                                    </div>
                                                }
                                                <tr>
                                                    {checkboxStates.EntryNo === true ? <th style={{ width: '6%' }}> Entry No/Date </th> : false}
                                                    {checkboxStates.TestType === true ? <th style={{ width: '5%' }}> Test Type</th> : false}
                                                    {checkboxStates.Supplier === true ? <th style={{ width: '5%' }}> Supplier </th> : false}
                                                    {checkboxStates.SupplierRefNo === true ? <th style={{ width: '6%' }}> Supplier Ref No </th> : false}
                                                    {checkboxStates.IDNo === true ? <th style={{ width: '5%' }}> ID No</th> : false}
                                                    {checkboxStates.Image === true ? <th style={{ width: '5%' }}> Image</th> : false}
                                                    {checkboxStates.LotNo === true ? <th style={{ width: '5%' }}> Lot No</th> : false}
                                                    {checkboxStates.LotWeight === true ? <th style={{ width: '5%' }}> Lot Weight</th> : false}
                                                    {checkboxStates.ReportQuantity === true ? <th style={{ width: '5%' }}> Report Quantity</th> : false}
                                                    {checkboxStates.TestResult === true ? <th style={{ width: '7%' }}> Test Result</th> : false}
                                                    {checkboxStates.ApprovalResult === true ? <th style={{ width: '7%' }}> Approval Result</th> : false}
                                                    {checkboxStates.ReTestStatus === true ? <th style={{ width: '7%' }}> Re Test Status</th> : false}
                                                    {checkboxStates.BalanceForReTest === true ? <th style={{ width: '9%' }}> Balance For Re Test</th> : false}
                                                    {checkboxStates.CompletedReTestReportNo === true ? <th style={{ width: '10%' }}>Completed Re Test Report No</th> : false}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    commentsData.map(comment => (
                                                        <tr key={comment.finalInspectionID}>

                                                            {
                                                                checkboxStates.CreatedDate && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        // className="stickyone "
                                                                        title={comment.insCreatedDate}>
                                                                        {comment.insCreatedDate}

                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.EntryNo && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.qcInspectionID}>
                                                                        {comment.qcInspectionID}
                                                                    </td>
                                                                )
                                                            }

                                                            {
                                                                checkboxStates.TestType && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.reportType}>
                                                                        {comment.reportType}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.Supplier && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.supplierName}>
                                                                        {comment.supplierName}
                                                                    </td>

                                                                )
                                                            }
                                                            {
                                                                checkboxStates.SupplierRefNo && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.supplierRefNo}>
                                                                        {comment.supplierRefNo}
                                                                    </td>

                                                                )
                                                            }
                                                            {
                                                                checkboxStates.IDNo && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={'ID No'}>
                                                                        {comment.idpono}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.Image && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={'Image'}>
                                                                        {comment.image}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.LotNo && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={'Lot No'}>
                                                                        {comment.lotNo}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.LotWeight && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={'Lot Weight'}>
                                                                        {comment.lotWeight}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.ReportQuantity && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.reportQuantity}>
                                                                        {comment.reportQuantity}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.TestResult && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.testResult}>
                                                                        {comment.testResult}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.ApprovalResult && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.approvalResult}>
                                                                        {comment.approvalResult}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.ReTestStatus && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.reTestStatus}>
                                                                        {comment.reTestStatus}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.BalanceForReTest && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.balanceForReTest}>
                                                                        {comment.balanceForReTest}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.CompletedReTestReportNo && (

                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.completedReTestReportNo}>
                                                                        {comment.completedReTestReportNo}
                                                                    </td>
                                                                )
                                                            }

                                                        </tr>
                                                    ))

                                                }
                                                {
                                                    totalItems === 0 ?
                                                        <tr>
                                                            <td colSpan="22" className='norecordfound'><span>No Records Found</span></td>
                                                        </tr> : ''
                                                }

                                            </tbody>
                                        </table>
                                    </table >
                                </div >
                            </div >


                        </div >

                    </div >

                    <PageCount onPageCount={
                        value => {
                            setITEMS_PER_PAGE(value);
                            setCurrentPage(1);
                        }
                    } />
                    <Pagination total={totalItems}
                        itemsPerPage={
                            parseInt(ITEMS_PER_PAGE)
                        }
                        currentPage={currentPage}
                        onPageChange={
                            page => setCurrentPage(page)
                        } />
                </div >
                {loader}



            </div >
        </>
    )
}

export default Retest