import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
import FinalinspecUpload from "../../Common/FinalinspecUpload";
import TrimService from "../../../services/Style/TrimService";
import Lightbox from "react-image-lightbox"
import Nodify from "../../Common/ReactNotification";
const FinalInsTrim = ({ props, BasicDetails, finalTrimcallback, getexfacTrimlist, IsValidationCallback, orderDetailsCheck }) => {

  const [gettrimValue, SettrimValue] = useState([]);
  const [trimExpandState, settrimExpandState] = useState({});
  const [trimexpandedRows, settrimExpandedRows] = useState([]);
  const [getCommonResult, setCommonResult] = useState([]);
  const [Buttonvisible, SetButtonvisible] = useState("Show");
  const [isOpen, setIsOpen] = useState(false);
  const [getImagename, setImagename] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [isOpenUpl, setIsOpenUpl] = useState(false);
  const [getImagenameUpl, setImagenameUpl] = useState();

  useEffect(() => {
    setSubmitted(IsValidationCallback);
  }, [IsValidationCallback === true]);
  const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
  const Trimwork = JSON.parse(localStorage.getItem("Exfacconfirm"));
  useEffect(() => {

    if (props.location.state.params.ParentTask === "ProductionFLDetails") {
      props.location.state.params.styleID = props.location.state.params.NewDetails.styleID
    }
    let FinalInsTrimEditData = []
    if (props.location.state.params.TaskStatus === 1) {

      //const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
      if (inpsectionId !== null) {
        FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, props.location.state.params.details.tnaid).then((res) => {

          if (res.data.finalInsTrimList.length > 0) {
            res.data.finalInsTrimList.map((y, index) => {
              FinalInsTrimEditData.push({
                TNAFinalInspecTrimID: y.tnaFinalInspecTrimID,
                ResultID: y.result,
                FindingsRemarks: y.findingsRemarks,
                SkuName: y.skuName,
                TrimName: y.trimDetails,
                MainIndex: y.trimMainindex,
                ImagesList: []
              })
              res.data.finalInsTrimSubList.filter(x => x.trimMainindex === y.trimMainindex).map((x, i) => {
                FinalInsTrimEditData[index].ImagesList.push({
                  TNAFinalInspecTrimSubID: x.tnaFinalInspecTrimSubID,
                  //UploadedImagePath: x.trimFilePath,
                  skuFrontPicPath: x.expectedFilepath,
                  MainIndex: y.trimMainindex,
                  SubIndex: x.subIndex,
                  TrimimagesList: []
                })
                res.data.finalInsTrimImageList.filter(chl => chl.subIndex === x.subIndex && x.trimMainindex === chl.trimMainindex).map((Image, Imageindx) => {
                  FinalInsTrimEditData[index].ImagesList[i].TrimimagesList.push({
                    TNAFinalInspecTrimImagesID: Image.tnaFinalInspecTrimImagesID,
                    UploadedImagePath: Image.uploadedImagePath,
                    MainIndex: Image.trimMainindex,
                    ImageIndex: Image.imageIndex,
                    SubIndex: Image.subIndex
                  })
                })
              })
            })
            SettrimValue(FinalInsTrimEditData)
            finalTrimcallback(FinalInsTrimEditData)
          }
          else {
            let Multiselecteddate = []
            let FinalInsTrimSaveData = []
            if (getexfacTrimlist.length > 0) {
              Multiselecteddate = getexfacTrimlist
            } else {
              Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
            }

            FinalinspectionService.GetFinalTrimStrikeoffList(2, props.location.state.params.styleID, '0').then((res) => {
              //Multiselecteddate.map((y, multiindex) => (
              res.data.finalInsTrimlist.map((x, index) => {
                if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
                  FinalInsTrimSaveData.push({
                    TNAFinalInspecTrimID: 0,
                    ResultID: 0,
                    FindingsRemarks: "",
                    TrimName: x.trimFinalInspe + ' (' + x.skuName + ')',
                    SkuName: x.skuName,
                    MainIndex: index,
                    ImagesList: []
                  })
                  res.data.finalInsTrimSkuImageList.filter(child => child.skuName === x.skuName && child.trimInfoID === x.trimInfoID).map((Child, childindex) => {
                    FinalInsTrimSaveData[index].ImagesList.push({
                      TNAFinalInspecTrimSubID: 0,
                      //UploadedImagePath: "",
                      skuFrontPicPath: Child.trimLogoName,
                      MainIndex: index,
                      ExpectedFilename: Child.trimLogoName,
                      SubIndex: childindex,
                      TrimimagesList: []
                    })
                    FinalInsTrimSaveData[index].ImagesList[childindex].TrimimagesList.push({
                      TNAFinalInspecTrimImagesID: 0,
                      UploadedImagePath: "",
                      MainIndex: index,
                      ImageIndex: 0,
                      SubIndex: childindex
                    })
                  })
                }

              })
              //))
              let uniquevalues = [...new Map(FinalInsTrimSaveData.map(item =>
                [item['SkuName'], item])).values()];
              SettrimValue(uniquevalues)
              finalTrimcallback(uniquevalues)
            })
          }
        })
      }
      else {
        let Multiselecteddate = []
        let FinalInsTrimSaveData = []
        if (getexfacTrimlist.length > 0) {
          Multiselecteddate = getexfacTrimlist
        } else {
          Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
        }

        FinalinspectionService.GetFinalTrimStrikeoffList(2, props.location.state.params.styleID, '0').then((res) => {

          //Multiselecteddate.map((y, multiindex) => (
          let mainindex = 0;
          res.data.finalInsTrimlist.map((x, index) => {
            if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
              FinalInsTrimSaveData.push({
                TNAFinalInspecTrimID: 0,
                ResultID: 0,
                FindingsRemarks: "",
                TrimName: x.trimFinalInspe + ' (' + x.skuName + ')',
                SkuName: x.skuName,
                MainIndex: mainindex,
                ImagesList: []
              })
              res.data.finalInsTrimSkuImageList.filter(child => child.skuName === x.skuName && child.trimInfoID === x.trimInfoID).map((Child, childindex) => {
                FinalInsTrimSaveData[mainindex].ImagesList.push({
                  TNAFinalInspecTrimSubID: 0,
                  //UploadedImagePath: "",
                  skuFrontPicPath: Child.trimLogoName,
                  MainIndex: mainindex,
                  ExpectedFilename: Child.trimLogoName,
                  SubIndex: childindex,
                  TrimimagesList: []
                })
                FinalInsTrimSaveData[mainindex].ImagesList[childindex].TrimimagesList.push({
                  TNAFinalInspecTrimImagesID: 0,
                  UploadedImagePath: "",
                  MainIndex: mainindex,
                  ImageIndex: 0,
                  SubIndex: childindex,
                })
              })
              mainindex++;
            }
          })
          //))
          let uniquevalues = [...new Map(FinalInsTrimSaveData.map(item =>
            [item['TrimName'], item])).values()];
          SettrimValue(uniquevalues)
          finalTrimcallback(uniquevalues)
        })
      }
    }
  }, [inpsectionId !== null && props.location.state.params.TaskStatus === 1])

  useEffect(() => {

    if (orderDetailsCheck === 1) {

      //localStorage.removeItem("Exfacconfirm");
      //if (props.location.state.params.TaskStatus === 1) {
      let Multiselecteddate = []
      // if (getexfacTrimlist.length > 0) {
      //   Multiselecteddate = getexfacTrimlist
      // } else {
      //   Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
      // }

      var ExistingValue = gettrimValue;

      Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)

      //compare and remove already exist data
      ExistingValue.map(ev => {
        Multiselecteddate.map((ms, index) => {
          if (ev.SkuName === ms.skuName) {
            Multiselecteddate.splice(index, 1)
          }
        })
      })
      let CurrIndex = ExistingValue.length;
      FinalinspectionService.GetFinalTrimStrikeoffList(2, props.location.state.params.styleID, '0').then((res) => {
        //Multiselecteddate.map(y => (
        res.data.finalInsTrimlist.map((x, index) => {
          if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
            ExistingValue.push({
              TNAFinalInspecTrimID: 0,
              ResultID: 0,
              FindingsRemarks: "",
              TrimName: x.trimFinalInspe + ' (' + x.skuName + ')',
              SkuName: x.skuName,
              MainIndex: CurrIndex,
              ImagesList: []
            })
            res.data.finalInsTrimSkuImageList.filter(child => child.skuName === x.skuName && child.trimInfoID === x.trimInfoID).map((Child, childindex) => {
              ExistingValue[CurrIndex].ImagesList.push({
                TNAFinalInspecTrimSubID: 0,
                //UploadedImagePath: "",
                skuFrontPicPath: Child.trimLogoName,
                MainIndex: CurrIndex,
                ExpectedFilename: Child.trimLogoName,
                SubIndex: childindex,
                TrimimagesList: []
              })
              ExistingValue[CurrIndex].ImagesList[childindex].TrimimagesList.push({
                TNAFinalInspecTrimImagesID: 0,
                UploadedImagePath: "",
                MainIndex: CurrIndex,
                SubIndex: childindex,
                ImageIndex: 0
              })
            })
            CurrIndex++;
          }

        })
        //))
        let uniquevalues = [...new Map(ExistingValue.map(item =>
          [item['TrimName'], item])).values()];
        SettrimValue(uniquevalues)
        finalTrimcallback(uniquevalues)
      })
      //}
    } else {

      var ExistingValue = gettrimValue;
      let Multiselecteddate = [];

      Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)

      //compare and remove data
      ExistingValue.map(ev => {
        if (Multiselecteddate.filter(x => x.skuName === ev.SkuName).length === 0) {
          let index = ExistingValue.findIndex(x => x.skuName === ev.SkuName);
          ExistingValue.splice(index, 1)
        }
      })
      let uniquevalues = [...new Map(ExistingValue.map(item =>
        [item['TrimName'], item])).values()];
      SettrimValue(uniquevalues)
      finalTrimcallback(uniquevalues)
    }
  }, [getexfacTrimlist])

  useEffect(() => {
    if (props.location.state.params.ParentTask === "ProductionFLDetails") {
      props.location.state.params.styleID = props.location.state.params.NewDetails.styleID
    }
    let FinalInsTrimEditData = []
    if (props.location.state.params.TaskStatus === 2) {
      FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then((res) => {

        if (res.data.finalInsTrimList.length > 0) {
          res.data.finalInsTrimList.map((y, index) => {
            FinalInsTrimEditData.push({
              TNAFinalInspecTrimID: y.tnaFinalInspecTrimID,
              ResultID: y.result,
              FindingsRemarks: y.findingsRemarks,
              SkuName: y.skuName,
              TrimName: y.trimDetails,
              MainIndex: y.trimMainindex,
              ImagesList: []
            })
            res.data.finalInsTrimSubList.filter(x => x.trimMainindex === y.trimMainindex).map((x, i) => {
              FinalInsTrimEditData[index].ImagesList.push({
                TNAFinalInspecTrimSubID: x.tnaFinalInspecTrimSubID,
                //UploadedImagePath: x.trimFilePath,
                skuFrontPicPath: x.expectedFilepath,
                MainIndex: x.trimMainindex,
                SubIndex: x.subIndex,
                TrimimagesList: []
              })
              res.data.finalInsTrimImageList.filter(chl => chl.subIndex === x.subIndex && x.trimMainindex === chl.trimMainindex).map((Image, Imageindx) => {
                FinalInsTrimEditData[index].ImagesList[i].TrimimagesList.push({
                  TNAFinalInspecTrimImagesID: Image.tnaFinalInspecTrimImagesID,
                  UploadedImagePath: Image.uploadedImagePath,
                  MainIndex: Image.trimMainindex,
                  ImageIndex: Image.imageIndex,
                  SubIndex: Image.subIndex,
                })
              })
            })
          })
          SettrimValue(FinalInsTrimEditData)
          finalTrimcallback(FinalInsTrimEditData)
        }
        else {
          let Multiselecteddate = []
          let FinalInsTrimSaveData = []
          if (getexfacTrimlist.length > 0) {
            Multiselecteddate = getexfacTrimlist
          } else {
            Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
          }

          FinalinspectionService.GetFinalTrimStrikeoffList(2, props.location.state.params.styleID, '0').then((res) => {

            //Multiselecteddate.map(y => (
            let mainindex = 0;
            res.data.finalInsTrimlist.map((x, index) => {
              if (Multiselecteddate.filter(m => m.skuName === x.skuName).length > 0) {
                FinalInsTrimSaveData.push({
                  TNAFinalInspecTrimID: 0,
                  ResultID: 0,
                  FindingsRemarks: "",
                  TrimName: x.trimFinalInspe + ' (' + x.skuName + ')',
                  SkuName: x.skuName,
                  MainIndex: mainindex,
                  ImagesList: []
                })
                res.data.finalInsTrimSkuImageList.filter(child => child.skuName === x.skuName && child.trimInfoID === x.trimInfoID).map((Child, childindex) => {
                  FinalInsTrimSaveData[mainindex].ImagesList.push({
                    TNAFinalInspecTrimSubID: 0,
                    //UploadedImagePath: "",
                    skuFrontPicPath: Child.trimLogoName,
                    MainIndex: mainindex,
                    ExpectedFilename: Child.trimLogoName,
                    SubIndex: childindex,
                    TrimimagesList: []
                  })
                  FinalInsTrimSaveData[mainindex].ImagesList[childindex].TrimimagesList.push({
                    TNAFinalInspecTrimImagesID: 0,
                    UploadedImagePath: "",
                    MainIndex: mainindex,
                    SubIndex: childindex,
                    ImageIndex: 0

                  })
                })
                mainindex++
              }
            })
            //))
            let uniquevalues = [...new Map(FinalInsTrimSaveData.map(item =>
              [item['TrimName'], item])).values()];
            SettrimValue(uniquevalues)
            finalTrimcallback(uniquevalues)
          })
        }
      })
    }

    // let CommonResult = [];
    // FinalinspectionService.getCommonResultList(0).then((res) => {
    //   if (res.data) {
    //     res.data.map((x) => {
    //       CommonResult.push({
    //         value: x.resultID,
    //         label: x.resultName
    //       })
    //     })
    //     setCommonResult(CommonResult);
    //   }
    // })
    let CommonResult = [];
    FinalinspectionService.getCommonResultList(0).then((res) => {
      if (res.data) {
        res.data.map((element) => {

          let LabelColor = '';
          if (element.resultID === 1) {
            LabelColor = <><span style={{ color: "green" }}><b>Ok</b></span></>
          }
          else if (element.resultID === 2) {
            LabelColor = <><span style={{ color: "Red" }}><b>Not ok</b></span></>
          }
          else if (element.resultID === 3) {
            LabelColor = <><span style={{ color: "blue" }}><b>Not Applicable</b></span></>
          }

          CommonResult.push({
            value: element.resultID,
            label: LabelColor,
            subLabel: element.value,
          })
        })
        setCommonResult(CommonResult);
      }
    })

  }, [props.location.state.params.TaskStatus === 2])

  // useEffect(() => {

  // }, [inpsectionId === null])

  let styles = {
    control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
  }
  const ImageCallback = (value, index, childindex, imageindex) => {

    var imagevalues = [...gettrimValue]
    let profile = imagevalues !== '' ? "/Style/SKU/" : '';
    imagevalues[index].ImagesList[childindex].TrimimagesList[imageindex].MainIndex = index;
    imagevalues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImageName = value;
    imagevalues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImagePath = profile + value;
    SettrimValue(imagevalues)

  }


  const handleCommonResultInputChange = (index, e, FeildName) => {

    const values = [...gettrimValue];
    let inputText = ""
    if (FeildName === "InspectionResult") {
      if (e !== null) {
        values[index].ResultID = e.value;
        values[index].ResultName = e.label;
        values[index].MainIndex = index;
      } else {
        values[index].ResultID = 0;
        values[index].ResultName = '';
      }
    } else {
      if (e.target.value.trim() !== '') {
        inputText = e.target.value;
      }
      values[index].FindingsRemarks = inputText;
    }
    SettrimValue(values);
    finalTrimcallback(values)
  };

  const handleTrimExpandRow = (rowNumber) => {
    settrimExpandedRows([]);
    let currentExpandedRows;
    let isRowExpanded = false;
    currentExpandedRows = trimexpandedRows;
    isRowExpanded = currentExpandedRows.includes(rowNumber);
    let obj = {};
    isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
    settrimExpandState(obj);
    currentExpandedRows = [];
    const newExpandedRows = isRowExpanded ?
      currentExpandedRows.filter(id => id !== rowNumber) :
      currentExpandedRows.concat(rowNumber);
    settrimExpandedRows(newExpandedRows);
  }

  const deleteFile = (index, childindex, imageindex) => {
    var imagevalues = [...gettrimValue]
    imagevalues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImageName = ''
    imagevalues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImagePath = "";
    SettrimValue(imagevalues);
    finalTrimcallback(imagevalues)
  }
  function ViewFile(event, filename, ViewName) {
   
    if (ViewName === 'Image1') {
      setIsOpen(true);
      setImagename(filename);
    } else {
      setIsOpenUpl(true);
      setImagenameUpl(filename);
    }
  }

  function handleAddFields(index, childindex, imageindex, skuFrontPicPath, ExpectedFilename) {

    var imagevalues = [...gettrimValue]
    let isvalid = true;
    if (imagevalues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImagePath === "") {
      isvalid = false;
      Nodify('Warning!', 'warning', 'Please Upload the Image');
    }

    if (isvalid) {
      imagevalues[index].ImagesList[childindex].TrimimagesList.push({ UploadedImagePath: "", UploadedImageName: '', MainIndex: index, imageindex: imageindex + 1 })
    }
    SettrimValue(imagevalues);
    finalTrimcallback(imagevalues)
  }

  function handleRemoveFields(index, childindex, imageindex, length) {
    var imagevalues = [...gettrimValue]
    if (length === 1) {
      imagevalues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImageName = ''
      imagevalues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImagePath = "";
    } else {
      imagevalues[index].ImagesList[childindex].TrimimagesList.splice(imageindex, 1);
    }
    SettrimValue(imagevalues);
    finalTrimcallback(imagevalues)
  }

  return (
    <table className="table table-striped table-bordered table-hover dataTable no-footer">
      <thead>
        <tr>
          <th className="InsMainInfoDetailsCol">Details</th>
          <th className="InsMainInfoResultsCol">Trims Result<span className="text-danger"> *</span></th>
          <th className="InsMainInfoRemarksCol">Finding / Remarks<span className="text-danger"> *</span></th>
        </tr>
      </thead>
      <tbody>
        {
          gettrimValue.map((comment, index) => (

            <Fragment>
              <tr key={
                index
              }>
                <td title={comment.TrimName} className="cut_text">
                  <i
                    className={
                      trimExpandState[index] ?
                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                    } onClick={event => handleTrimExpandRow(index)}
                  ></i>&nbsp;
                  <b>{comment.TrimName}</b>
                  {/* -{comment.SkuName} */}
                </td>
                <td>   <Reactselect className="basic-single" name="Result"
                  id={
                    "Result" + index
                  }
                  isDisabled={props.location.state.params.Action === 'View' ? true : false}
                  isLoading={false}
                  isClearable={true}
                  isSearchable={true}
                  styles={submitted && comment.ResultID === 0 ? styles : ''}
                  onChange={(event) => handleCommonResultInputChange(index, event, 'InspectionResult')}
                  options={getCommonResult}
                  value={getCommonResult.filter(function (option) {
                    return option.value === comment.ResultID;
                  })}

                ></Reactselect></td>
                <td><input type="text" className="form-control" name={index + "_txtRemarks"}
                  id={index + "_txtRemarks"} autoComplete="off"
                  value={comment.FindingsRemarks}
                  maxLength="200"
                  disabled={props.location.state.params.Action === 'View' ? true : false}
                  style={{ border: submitted && (comment.ResultID === 2 && comment.FindingsRemarks === '') ? '1px solid red' : '' }}
                  onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')} /></td>
              </tr><>
                {
                  trimexpandedRows.includes(index) ?
                    <tr>
                      <td colspan="3">
                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                          <tbody>
                            {
                              comment.ImagesList.map((child, childindex) => (
                                <Fragment>
                                  <tr key={
                                    child.index
                                  }>

                                    {

                                      <td className="table-width-40">
                                        <td className="table-width-40">
                                          {<span>Expected :</span>}
                                          <br />
                                          <br />
                                          {
                                            <img src={window.$APIBaseURL + "Images/Style/Trim/" + child.skuFrontPicPath} alt="" width="150px" height="150px" />
                                          }
                                          &nbsp;&nbsp;
                                          <i className="btn btn-info fa fa-eye Packingeyeadjust" title="View Logo" style={{ marginRight: "6px" }}
                                            onClick={
                                              event => ViewFile(event, child.skuFrontPicPath, 'Image1')
                                            }></i></td>
                                      </td>



                                    }
                                    {<td className="table-width-60">
                                      <div className="row">
                                        <div className="col-md-6">
                                          Found :
                                        </div>

                                      </div>
                                      <div className="row">
                                        {child.TrimimagesList.map((ImageList, imageindex) => (
                                          <div className="col-lg-6 text-center">
                                            <span className="text-danger">Click on the image to upload new</span><br /><br />
                                            <div>
                                              <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, imageindex)} PageName='Style/SKU' Buttonview={Buttonvisible} ShowDiv="1" filePath={ImageList.UploadedImagePath} />
                                            </div>

                                            {ImageList.UploadedImagePath !== '' ? <div className="row">
                                              <div className="col-md-7">
                                                {/* <i style={{ marginRight: "0" }} className="btn btn-danger fa fa-trash-o FloatRight" title="Delete Logo"
                                                  onClick={event => deleteFile(index, childindex, imageindex, "UploadedImagePath1")}></i> */}

                                              </div>
                                            </div> : ''}
                                            <span className="TnaFinaspecwrkmans" style={{ left: "120px" }}>
                                              {ImageList.UploadedImagePath !== '' ? <button onClick={
                                                event => ViewFile(event, ImageList.UploadedImagePath, 'Image2')
                                              } className="btn btn-info fa fa-eye" style={{ padding: "5px 6px", width: "7%" }}>
                                                <i title="View Logo" style={{ marginRight: "6px" }}
                                                ></i>
                                              </button> : ''}

                                              &nbsp;

                                              {props.location.state.params.Action !== 'View' ? <button type="button" style={{ display: gettrimValue[index].ImagesList[childindex].TrimimagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                                onClick={() => handleAddFields(index, childindex, imageindex, child.skuFrontPicPath, child.ExpectedFilename)}
                                              >
                                                <i className="fa fa-plus"></i>
                                              </button> : ''}
                                              &nbsp;
                                              <button type="button"
                                                className="btn btn-xs btn-danger" title="Delete packing Image" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                onClick={() => handleRemoveFields(index, childindex, imageindex, gettrimValue[index].ImagesList[childindex].TrimimagesList.length)}
                                              >
                                                <i className="fa fa-trash-o"></i>
                                              </button>
                                            </span>
                                          </div>
                                        ))}
                                      </div>

                                    </td>
                                    }
                                  </tr>
                                </Fragment>
                              ))
                            }
                          </tbody>
                        </table>
                      </td>
                    </tr> : ""
                }</>
              <div> {
                isOpen && (

                  <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/Trim/' + getImagename}
                    alt="bg image"
                    onCloseRequest={
                      () => setIsOpen(false)
                    } />
                )
              } </div>
              <div> {
                isOpenUpl && (

                  <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagenameUpl}
                    alt="bg image"
                    onCloseRequest={
                      () => setIsOpenUpl(false)
                    } />
                )
              } </div>
            </Fragment>



          ))
        }
        {
          gettrimValue.length === 0 ?
            <tr>
              <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
            </tr> : ''
        }
      </tbody>
    </table>
  )
}

export default FinalInsTrim