import React, { useState, useEffect, useMemo, useRef, Fragment } from "react";
import { DateRangePicker } from 'react-date-range';
import Reactselect from 'react-select';
import moment from "moment";
import { LoadFlDetailsreport, UpdateCommentsreportViewAllCheckBox, UpdateCommentsreportViewCheckBox } from "../../../../actions/finalInspection";
import Nodify from "../../../Common/ReactNotification";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
// import ReactNotification from 'react-notifications-component';
//import SearchRetain from "../../../../../Common/SearchRetain";
import { Modal, Button } from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';
import { Pagination, Search, PageCount, ReactSearch } from "../../../Datatable";
//import TableHeader from '../../../Datatable/Header/commetstyle'
import TableHeader from '../../../Datatable/Header/FinalInspectionDetailsHeader';
//import CommentReportService from '../../services/Report/ReportService'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import ReportService from "../../../../services/Report/ReportService";
import * as XLSX from 'xlsx';
import axios from "axios";
import $ from "jquery";
import Aqlfinalinspection from "../../../../services/Master/Aqlfinalinspection";
import Lightbox from "react-image-lightbox"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FLReportSkuComments, FLReportSkuOrderStatus, FinalinspectionID } from "../../../Common/PageActionNumber";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { loadTNADetailList, GetSubChildMultExFactoryFormSKUList } from "../../../../actions/tna";
//import "../../../Reports/TNA.css"
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import AddInspection from "../../../Production/Inspection_Quality Control/InspectionQC/AddInspection";
import ReInspection from "../../../Production/Inspection_Quality Control/InspectionQC/ReInspection";
import FinalInspectionService from './../../../../services/FinalInspection/FinalinspectionService';
import CommonService from '../../../../services/Common/CommonService';
const FinalInspectionList = ({ props }) => {
    //)
   ;
    const [getPlaceholder] = useState("Buyer / Style Name / Style No / Season / Brand / Story / Theme  / Group Type");
    const [currentPage, setCurrentPage] = useState(1);
    const [getSeasonList, setSeasonList] = useState();
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const comments = [];
    const [getTNAId, setTNAId] = useState();
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [showmodal, setShowmodal] = useState(false);
    const [selectstatus, setselectstatus] = useState({ value: 3, label: "Minor" });
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getSkuSearch, setSkuSearch] = useState("");
    const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    const [getFactorySearch, setFactorySearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [getSuppliersearch, setsupplierSearch] = useState("");
    const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [getCategorysearch, setCategorySearch] = useState("");
    const [getStyleNamesearch, setStyleNameSearch] = useState("");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getStyleNosearch, setStyleNoSearch] = useState("");
    const [getBuyerEmpsearch, setBuyerEmpSearch] = useState("");
    const [getCubeEmpsearch, setCubeEmpSearch] = useState("");
    const [getOrderStatussearch, setOrderStatusSearch] = useState("");
    const [getInspectionAndApprovalSearch, setInspectionAndApprovalSearch] = useState("");
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
    //const [showmodal, setShowmodal] = useState({ EnableComments: 0, PurchaseOrderSkuID: 0, reportStatus: [] });
    const [selectedOption, setSelectedOptions] = useState({ value: 1, label: 'Yet to Start' })
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [Submitted, setSubmitted] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [getOrdStatusID, setOrdStatusID] = useState({ showPopupDelete: false, Params: {} });
    const [getInspectionInfo, setInspectionInfo] = useState({});
    const [getskusingleID, setskusingleID] = useState();
    const [checkboxStates, setCheckboxStates] = useState({
        supplier: true,
        idNo: true,
        styleName: true,
        styleNo: true,
        skuName: true,
        skuImg: true,
        size: true,
        orderQty: true,
        inspectionStatus: true,
        approvalStatus: true,
        exFac: true,
        orderStatus: true,
        currentStatus: true,
        cubeEmp: true,
        buyerEmp: true,
        stsCreated: true,
        cmtCreated: true,
        skuID: true,
        category: true,
        PlanEndDate: true,
        Factory: true,
        FOPONo: true,
        BalQtyforNewInspection: true,
        BalQtyforReNewInspection: true

    });

    const [getHideID, setHideID] = useState({
        Po: true,
        season: true,
        Quarter: true,
        brand: true,
        customer: true,
        supplier: true,
        idNo: true,
        styleName: true,
        styleNo: true,
        skuName: true,
        skuImg: true,
        size: true,
        orderQty: true,
        inspectionStatus: true,
        approvalStatus: true,
        exFac: true,
        orderStatus: true,
        currentStatus: true,
        cubeEmp: true,
        buyerEmp: true,
        stsCreated: true,
        cmtCreated: true,
        skuID: true,
        category: true,
        // styCatRecived: true,
        // smsPo: true,
        // LdSubmit: true,
        // LdApproved: true,
        // strikeSubmission: true,
        // strikeApprv: true,
        // strikeOrderNominate: true,
        // strikeReceiptNominate: true,
        // strikeReceiptBuyer: true,
        // TrimSubmission: true,
        // TrimApproval: true,
        // TrimOrderNominate: true,
        // Trimrcptfrombuyer: true,
        // TrimReceiptNominate: true,
        // TrimOrderLocalSupplier: true,
        // TrimReciptLocalSupplier: true,
        // protoSubmission: true,
        // protoApproval: true,
        // fitSubmission: true,
        // fitApproval: true,
        // smsOrder: true,
        // smsInHouse: true,
        // smsProduction: true,
        // barcodeRecipt: true,
        // barcodeApprvl: true,
        // apperanceAftWash: true,
        // finalInspec: true,
        // smsSubmis: true,
        // smsApprvl: true,
    });
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    // const [getComment, setComment] = useState({ EnableComments: 0, EnterComment: '', PurchaseOrderSkuID: 0, reportComments: [] })
    //const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    //const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [checked, setchecked] = useState('0');

    const [table, settable] = useState('0');
    const [show, setShow] = useState(false);

    const [status, setStatus] = useState(true);
    const [seasoncheck, setseasoncheck] = useState(true)
    const [Quartercheck, setQuartercheck] = useState(true)

    const [sizeqtypopup, setsizeqtypopup] = useState(false);

    const [InspectionAndApprovalpopup, SetinspectionAndApprovalpopup] = useState(false);
    const [statusType, Setstatustype] = useState(false);
    const [sizeqtylist, setsizeqtylist] = useState([]);
    const [approvalOrInspectionList, setapprovalOrInspectionList] = useState([]);
    const [isInspectionStarted, setisInspectionStarted] = useState([]);
    const [getskuid, setskuid] = useState({ skuID: 0, qrty: 0 });
    // FOR COMMENTS
    const [Commentpopup, setCommentpopup] = useState(false);
    const [getComment, setComment] = useState([])
    const [getpurchaseId, setPurchaseId] = useState("0");
    const [getSupplierID, setSupplierID] = useState("0")
    const [getindex, setindex] = useState(0);
    const [getExfacindex, setExfacindex] = useState(0);
    const [reportcomments, setreportcomments] = useState([])
    const [getExFactoryList, setExFactoryList] = useState([])
    const [getCmtExFactoryList, setCmtExFactoryList] = useState([])
    const [Ecomment, setEcomment] = useState();
    const [ExfacList, setExfacList] = useState([]);
    const [getnewfinalReportlist, setnewfinalReportlist] = useState([]);
    const [getnewheaderdata, setnewheaderdata] = useState([]);
    //End Comments
    const [getFiles, setFiles] = useState([])
    //For OrderStatus
    const [selectedOptionStatus, setSelectedOptionsStatus] = useState({ value: 0, label: 'Select Order Status' })
    const [selectedCommentExfactory, setSelectedCommentExfactory] = useState()
    const [getTNAPurchaseOrderID, setTNAPurchaseOrderID] = useState();
    const [getorderStatus, setorderStatus] = useState([]);
    const [getordindex, setOrdindex] = useState(0);
    //End OrdStatus


    const EmptyInputFields = [];

    const [inputFields, setInputFields] = useState(EmptyInputFields);

    const Input = { ...inputFields };

    // PO/season/Quarter
    //const [getHideID, setHideID] = useState({ Po: true, season: true, Quarter: true });
    const Values = { ...getComment }
    const OrdStatus = { ...showmodal }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    activeMenu = menulist.filter(i => i.menuName === "FinalInspection")
        ;
    // const activeMenus = useSelector((state) => state.auth.activeMenuList);
    // let IsProduction = activeMenus.IsProduction;

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End



    let InspectionandApproval = [
        {
            value: 1,
            label: 'Inspection Yet to Start',
            selected: false,

        },
        {
            value: 2,
            label: 'Inspection Progressing',
            selected: false,

        },
        {
            value: 3,
            label: 'Inspection Pass',
            selected: false,

        },
        {
            value: 4,
            label: 'Inspection Fail',
            selected: false,

        },
        {
            value: 5,
            label: 'New Inspection',
            selected: false,

        },
        , {
            value: 6,
            label: 'Re Inspection',
            selected: false,

        }, {
            value: 7,
            label: 'Re Inspection Pending',
            selected: false,

        }, {
            value: 8,
            label: 'Inspection Under Approval',
            selected: false,

        },
        {
            value: 9,
            label: 'Inspection Approved',
            selected: false,

        }
        ,
        {
            value: 10,
            label: 'Inspection Commercially Approved',
            selected: false,

        }
        ,
        {
            value: 11,
            label: 'Inspection Rejected',
            selected: false,

        }
        ,
        {
            value: 12,
            label: 'Balance for New Inspection',
            selected: false,

        }
        ,
        {
            value: 13,
            label: 'Balance for Re Inspection',
            selected: false,

        }
    ]



    var defaultStartDate = new Date();
    // defaultStartDate.setDate(defaultStartDate.getDate() - 90)
    const [stateDate, setStateDate] = useState([
        {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultExStartDate = new Date();
    const [stateExDate, setStateExDate] = useState([
        {

            startDate: null, //defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);

    var defaultInspectionStartDate = new Date();
    const [stateInsDate, setStateInsDate] = useState([
        {

            startDate: null, //defaultInspectionStartDate.setDate(defaultInspectionStartDate.getDate() - 90),
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);

    var defaultTNAStartDate = new Date();
    const [stateTNADate, setStateTNADate] = useState([
        {

            startDate: defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);


    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    let isLoadingStyle = reducerState.style.isLoadingStyle;
    const { user: currentUser } = useSelector((state) => state.auth);

    let IsProductionStatus = [
        {
            value: 1,
            label: 'Sampling',
            selected: true,

        },
        {
            value: 2,
            label: 'Production',
            selected: false,

        }
    ]

    async function delStatusCallback(value) {

        let Defaultvalue = { label: "Sampling", selected: true, value: 1 }

        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        // Get the current date
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction
            , PoStartDate: '01/01/1970'
            , PoEndDate: formattedDate
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
            , InspectionStartDate: '01/01/1970'
            , InspectionEndDate: formattedDate
        }

        let ordStatus = { ...selectedOption }
        if (value) {
            await FinalinspectionService.GetFLPurchaseOrderSkuReportComments(getpurchaseId, 2).then((response) => {
                if (response) {
                    if (response.data) {
                        setorderStatus(response.data.flReportStatus)
                        ordStatus.value = 0;
                        ordStatus.label = 'Select Order Status';
                        setSelectedOptions(ordStatus)
                    }
                    else {
                        setSelectedOptions(ordStatus)
                    }
                } if (response.data.flReportStatus.length === 0) {
                    setShowmodal(false)
                }
            }).catch(() => { });
            dispatch(LoadFlDetailsreport(params));
        } else {
            setOrdStatusID({ showPopupDelete: false, Params: [] });
        }
    }

    async function delCallback(value) {

        let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        // Get the current date
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction
            , PoStartDate: '01/01/1970'
            , PoEndDate: formattedDate
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
            , InspectionStartDate: '01/01/1970'
            , InspectionEndDate: formattedDate
        }
        if (value) {

            await FinalinspectionService.GetFLPurchaseOrderSkuReportComments(getpurchaseId, 1).then(async (response) => {
                if (response.data) {
                    setreportcomments(response.data.flReportCommentDetails)
                    setFiles(response.data.flReportfileupload)
                }
                if (response.data.flReportCommentDetails.length === 0) {
                    setCommentpopup(false)
                }
            }).catch(() => { });

            dispatch(LoadFlDetailsreport(params));
        } else {
            setID({ showPopupDelete: false, Params: [] });
        }
    }


    const handleSaveComment = (e, comment) => {

        if (comment === undefined || comment === '' || selectedCommentExfactory === undefined || selectedCommentExfactory.value === 0) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        } else {
            // var Data = [];
            var CommentInformationData = [];
            var ManualFileInformationData = [];
            let len = 0;
            //const PurchaseOrderSkuIDstatus = parseInt(getpurchaseId);
            let Selecteditems = commentsData.filter(x => x.isSelected === true && getSupplierID === x.supplierID)
            Selecteditems.map((item, index) => {
                if (item && item !== 0) {
                    len = len + 1;
                    CommentInformationData.push({
                        CommentIndex: len,
                        PurchaseOrderCommentsID: parseInt(item.purchaseOrderSkuID),
                        CommentDetail: Ecomment,
                        IsStatus: selectstatus.value,
                        SupplierInfo: selectedCommentExfactory.value,
                        Supplierfactory: selectedCommentExfactory.label
                    })
                    getFiles.filter(x => x.reportManualFileId === 0).map((x, indexs) => {
                        ManualFileInformationData.push({
                            ReportManualFileId: x.reportManualFileId,
                            PurchaseOrderSkuID: parseInt(item.purchaseOrderSkuID),
                            FileType: x.fileType,
                            FileName: x.fileName,
                            FilePath: x.filePath,
                            ReportIndexName: x.reportIndexName,
                            ReportFileIndexName: x.reportFileIndexName,
                            IsDeleted: x.IsDeleted
                        })
                    })
                }
            })

            var Data = { TNAID: getTNAId, CreatedBy: currentUser.employeeinformationID, userLat: '0', userLong: '0', CommentInformation: CommentInformationData, ReportManualFileInformation: ManualFileInformationData };

            FinalinspectionService.InsertUpdateFLreportComment(Data).then((res) => {

                if (res.data.outputResult === "1") {
                    let Selecteditems = commentsData.filter(x => x.isSelected === true && getSupplierID === x.supplierID);
                    Nodify('Success!', 'success', "Comment Added for " + Selecteditems[0].supplierName + " Successfully");
                    setSubmitted(false);
                    setCommentpopup(false);
                    setEcomment('');
                    let params = {
                        isChecked: false, index: getindex
                    };
                    dispatch(UpdateCommentsreportViewCheckBox(params));
                    setIsAllSelected(false);
                    dispatch(UpdateCommentsreportViewAllCheckBox(false));
                    //let param = { Operation: 1, IsProduction: 1 }
                    let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
                    let TNAStartDate = null;
                    let TNAEndDate = null;
                    if (moment(stateTNADate[0].startDate).isValid()) {
                        TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateTNADate[0].endDate).isValid()) {
                        TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }
                    const currentDate = new Date();

                    // Create a moment object from the current date and add one year
                    const futureDate = moment(currentDate).add(1, 'year');

                    // Format the future date
                    const formattedDate = futureDate.format('MM/DD/YYYY');
                    let Params = {
                        IsProduction: IsProduction
                        , PoStartDate: '01/01/1970'
                        , PoEndDate: formattedDate
                        , ExfacStartDate: '01/01/1970'
                        , ExfacEndDate: formattedDate
                        , TNAStartDate: TNAStartDate
                        , TNAEndDate: TNAEndDate
                        , InspectionStartDate: '01/01/1970'
                        , InspectionEndDate: formattedDate
                    }
                    setSelectedCommentExfactory("");
                    dispatch(LoadFlDetailsreport(Params));
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }
                //setComment(Values);

            })

        }

        //setEditedCommentIndex('');
    };
    const handleSaveStatus = () => {

        const ordStatus = { ...selectedOption }
        var StatusInformationData = [];
        let len = 0;
        let isvalid = true;
        if (ordStatus.value === 0) {
            Nodify('Warning!', 'warning', "please select the order status");
            setSubmitted(true)
            isvalid = false;
        }
        if (isvalid) {
            setSubmitted(false)
            let Selecteditems = commentsData.filter(x => x.isSelected === true)
            Selecteditems.map(item => {
                if (item && item !== 0) {
                    len = len + 1;
                    StatusInformationData.push({
                        CommentIndex: len,
                        PurchaseOrderCommentsID: parseInt(item.purchaseOrderSkuID),
                        Status: ordStatus.value
                    })
                }
            })
            var Data = { TNAID: getTNAId, CreatedBy: currentUser.employeeinformationID, StatusInformation: StatusInformationData };

            FinalinspectionService.InsertUpdatereportOrderStatus(Data).then((res) => {

                if (res.data.outputResult === "1") {

                    Nodify('Success!', 'success', "Order Status saved successfully");
                    // setSelectedOptions(ordStatus);
                    setShowmodal(false);
                    let params = {
                        isChecked: false, index: getordindex
                    };
                    dispatch(UpdateCommentsreportViewCheckBox(params));
                    setIsAllSelected(false);
                    dispatch(UpdateCommentsreportViewAllCheckBox(false));
                    let ISproduc = getIsProductionStatussearch;
                    let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
                    let TNAStartDate = null;
                    let TNAEndDate = null;
                    if (moment(stateTNADate[0].startDate).isValid()) {
                        TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateTNADate[0].endDate).isValid()) {
                        TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }
                    const currentDate = new Date();

                    // Create a moment object from the current date and add one year
                    const futureDate = moment(currentDate).add(1, 'year');

                    // Format the future date
                    const formattedDate = futureDate.format('MM/DD/YYYY');
                    let Params = {
                        IsProduction: IsProduction
                        , PoStartDate: '01/01/1970'
                        , PoEndDate: formattedDate
                        , ExfacStartDate: '01/01/1970'
                        , ExfacEndDate: formattedDate
                        , TNAStartDate: TNAStartDate
                        , TNAEndDate: TNAEndDate
                        , InspectionStartDate: '01/01/1970'
                        , InspectionEndDate: formattedDate
                    }
                    dispatch(LoadFlDetailsreport(Params));
                }

                else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }
                //setComment(Values);
            })
        }
    }

    useEffect(() => {
        if (!isLoadingStyle) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingStyle])
    let Ischeckedval = JSON.parse(localStorage.getItem("Ischecked"));
    useEffect(() => {
       
        if (Ischeckedval) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
    }, [Ischeckedval])
    useEffect(() => {
       ;
        if (props.location.state && props.location.state.params.localStorage !== undefined && props.location.state.params.localStorage !== false) {
            const PoStartdate = localStorage.getItem("PodateStatedate")
            const POenddate = localStorage.getItem("Podateenddate")
            const PoExStartdate = localStorage.getItem("PodateExStatedate")
            const POExenddate = localStorage.getItem("PodateExenddate")
            //////////// multi Filter
            const buyer = localStorage.getItem("Buyer")
            const season = localStorage.getItem("Season")
            const brand = localStorage.getItem("Brand")
            const quarter = localStorage.getItem("Quarter")
            const supplier = localStorage.getItem("Supplier")
            const poidno = localStorage.getItem("ID/NO")
            const endCustomer = localStorage.getItem("EndCustomer")
            const category = localStorage.getItem("Category")
            const stylename = localStorage.getItem("StyleName")
            const styleno = localStorage.getItem("StyleNo")
            const skuname = localStorage.getItem("SKUName")
            const factory = localStorage.getItem("Factory")
            const ProductionStatus = localStorage.getItem("IsProductionStatus")
            const orderStatus = localStorage.getItem("OrderStatus")

            const currentDate = new Date();
            const formattedDate = moment(currentDate).format('MM/DD/YYYY');
            stateDate[0].startDate = PoStartdate === null ? null : new Date(PoStartdate);
            stateDate[0].endDate = POenddate === null ? null : new Date(POenddate);
            stateExDate[0].startDate = PoExStartdate === null ? null : new Date(PoExStartdate);
            stateExDate[0].endDate = POExenddate === null ? null : new Date(POExenddate);
            setStateDate(stateDate);
            setStateExDate(stateExDate);
            if (skuname !== null && skuname !== "") {
                if (skuname) {
                    setSkuSearch(skuname);
                }
                else {
                    localStorage.removeItem('SKUName');
                    setSkuSearch("");
                }
            }
            if (buyer !== null && buyer !== "") {
                if (buyer) {
                    setBuyerSearch(buyer)
                }
                else {
                    localStorage.removeItem('Buyer');
                    setBuyerSearch("");
                }
            }
            if (season !== null && season !== "") {

                if (season) {
                    setSeasonSearch(season)
                }
                else {
                    localStorage.removeItem('Season');
                    setSeasonSearch("");
                }
            }
            if (brand !== null && brand !== "") {

                if (brand) {
                    setBrandSearch(brand)
                }
                else {
                    localStorage.removeItem('Brand');
                    setBrandSearch("");
                }
            }
            if (quarter !== null && quarter !== "") {

                if (quarter) {
                    setQuarterSearch(quarter)
                }
                else {
                    localStorage.removeItem('Quarter');
                    setQuarterSearch("");
                }
            }
            if (supplier !== null && supplier !== "") {

                if (supplier) {
                    setsupplierSearch(supplier)
                }
                else {
                    localStorage.removeItem('Supplier');
                    setsupplierSearch("");
                }
            }
            if (poidno !== null && poidno !== "") {

                if (poidno) {
                    setIdPoSearch(poidno)
                }
                else {
                    localStorage.removeItem('ID/NO');
                    setIdPoSearch("");
                }
            }
            if (endCustomer !== null && endCustomer !== "") {

                if (endCustomer) {
                    setEndCustomerSearch(endCustomer)
                }
                else {
                    localStorage.removeItem('EndCustomer');
                    setEndCustomerSearch("");
                }
            }
            if (category !== null && category !== "") {

                if (category) {
                    setCategorySearch(category)
                }
                else {
                    localStorage.removeItem('Category');
                    setCategorySearch("");
                }
            }
            if (stylename !== null && stylename !== "") {

                if (stylename) {
                    setStyleNameSearch(stylename)
                }
                else {
                    localStorage.removeItem('StyleName');
                    setStyleNameSearch("");
                }
            }
            if (styleno !== null && styleno !== "") {

                if (styleno) {
                    setStyleNoSearch(styleno)
                }
                else {
                    localStorage.removeItem('StyleNo');
                    setStyleNoSearch("");
                }
            }
            if (factory !== null && factory !== "") {

                if (factory) {
                    setFactorySearch(factory)
                }
                else {
                    localStorage.removeItem('Factory');
                    setFactorySearch("");
                }
            }
            if (orderStatus !== null && orderStatus !== "") {

                if (orderStatus) {
                    setOrderStatusSearch(orderStatus)
                }
                else {
                    localStorage.removeItem('OrderStatus');
                    setOrderStatusSearch("");
                }
            }
            if (ProductionStatus !== null && ProductionStatus !== "") {

                if (ProductionStatus) {
                    setIsProductionStatussearch(ProductionStatus)
                }
                else {
                    localStorage.removeItem('IsProductionStatus');
                    setIsProductionStatussearch("");
                }
            }
        }
        //////////////////////////

        if (props.location.state !== null) {
            let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
            setIsProductionStatussearch(Defaultvalue);
            let TNAStartDate = null;
            let TNAEndDate = null;
            if (moment(stateTNADate[0].startDate).isValid()) {
                TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateTNADate[0].endDate).isValid()) {
                TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            // Get the current date
            const currentDate = new Date();

            // Create a moment object from the current date and add one year
            const futureDate = moment(currentDate).add(1, 'year');

            // Format the future date
            const formattedDate = futureDate.format('MM/DD/YYYY');
            let params = {
                IsProduction: IsProduction
                , PoStartDate: '01/01/1970'
                , PoEndDate: formattedDate
                , ExfacStartDate: '01/01/1970'
                , ExfacEndDate: formattedDate
                , TNAStartDate: TNAStartDate
                , TNAEndDate: TNAEndDate
                , InspectionStartDate: '01/01/1970'
                , InspectionEndDate: formattedDate
            }
            dispatch(LoadFlDetailsreport(params));
        }
        //setIsAllSelected(false)
    }, [dispatch]);



    window.addEventListener('beforeunload', function () {
        // Clear localStorage
        localStorage.removeItem('SKUName')

    });







    //const FlDetailsReportList = reducerState.fldetails.FlDetailsReportList;

    const FlDetailsReportList = reducerState.fldetails.FlDetailsReportList.item1 !== undefined ? reducerState.fldetails.FlDetailsReportList.item1 : reducerState.fldetails.FlDetailsReportList;
    if (FlDetailsReportList.length > 0) {
        for (let index = 0; index < FlDetailsReportList.length; index++) {
            let Parent = FlDetailsReportList[index];
            for (let i = 0; i < reducerState.fldetails.FlDetailsReportList.item2.length; i++) {
                let Child = reducerState.fldetails.FlDetailsReportList.item2[i];
                if (Child.purchaseOrderID === Parent.purchaseOrderID && Child.indexName === Parent.indexName) {
                    Parent.styleID = Child.styleID; Parent.styleName = Child.styleName; Parent.styleNo = Child.styleNo; Parent.categoryName = Child.categoryName;
                    Parent.buyerEmployee = Child.buyerEmployee; Parent.cubeEmployee = Child.cubeEmployee;
                }
            }
        }
    }

    const isLoadingFlDetailsReport = reducerState.fldetails.isLoadingFlDetailsReport;
    // loader
    useEffect(() => {

        if (!isLoadingFlDetailsReport) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingFlDetailsReport,]);


    const handleChanges = (value) => {
        setSelectedOptions(value);
        // setComment(Values)
    };


    // User Location
    const [getIsShowGeoLocModal, setgetIsShowGeoLocModal] = useState(false);
    const [getCurrentLocation, setCurrentLocation] = useState({
        Latitude: '',
        Longitude: ''
    });
    const delsCallback = (value) => {
        if (value === true) {
            Input.Finalview = 0;
            setInputFields(Input);
            let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
            setIsProductionStatussearch(Defaultvalue);
            let TNAStartDate = null;
            let TNAEndDate = null;
            if (moment(stateTNADate[0].startDate).isValid()) {
                TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateTNADate[0].endDate).isValid()) {
                TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            // Get the current date
            const currentDate = new Date();

            // Create a moment object from the current date and add one year
            const futureDate = moment(currentDate).add(1, 'year');

            // Format the future date
            const formattedDate = futureDate.format('MM/DD/YYYY');
            let params = {
                IsProduction: IsProduction
                , PoStartDate: '01/01/1970'
                , PoEndDate: formattedDate
                , ExfacStartDate: '01/01/1970'
                , ExfacEndDate: formattedDate
                , TNAStartDate: TNAStartDate
                , TNAEndDate: TNAEndDate
                , InspectionStartDate: '01/01/1970'
                , InspectionEndDate: formattedDate
            }
            dispatch(LoadFlDetailsreport(params));

        } else {
            setIDSingleTask({ showPopupDelete: false, Params: [] });
        }
    }
    function handleDeletefinalinspection(type, TNAInspectionID, Action) {
        let PackingParams = {
            Operation: 104,
            Id: TNAInspectionID,
        }
        CommonService.CheckMappedItem(PackingParams).then(res => {
            if (res.data.outputResult === "-2") {
                let Title = "Warning!";
                let message = "This finalinspection has already approved in inspection Entry Tab, So action not allowed to Delete.";
                let type = "warning";
                Nodify(Title, type, message);
            }
            else {
                setIDSingleTask({ showPopupDelete: true, Params: PackingParams });
            }
        })
    }
    function RemoveOrderStatus(Commentitem) {

        let Param = {
            Operation: FLReportSkuOrderStatus,
            Id: Commentitem.statusId,
        };
        setOrdStatusID({ showPopupDelete: true, Params: Param });
    }

    const handleOnSelect = (event, FieldName, purchaseOrderSkuID) => {
        let isChecked = false;
        let indexes = FlDetailsReportList.findIndex(obj => obj.purchaseOrderSkuID === purchaseOrderSkuID)
        if (event.target.checked) {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateCommentsreportViewCheckBox(params));
    }


    const GetStatus = (PurchaseOrderSkuID, Feildname, Cmt) => {
        showLoader();
        setPurchaseId('');
        setOrdindex(0);
        setShowmodal(true);
        setPurchaseId(PurchaseOrderSkuID);
        setTNAId(Cmt.tnaid)
        //
        let isChecked = false;
        let indexes = FlDetailsReportList.findIndex(obj => obj.purchaseOrderSkuID === PurchaseOrderSkuID)
        setOrdindex(indexes)
        let ordStatus = { ...selectedOptionStatus }
        ordStatus.value = 1;
        ordStatus.label = 'Yet to Start';
        setSelectedOptions(ordStatus)
        if (Feildname === 'OrderStatus') {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateCommentsreportViewCheckBox(params));

        FinalinspectionService.GetFLPurchaseOrderSkuReportComments(PurchaseOrderSkuID, 2).then((response) => {
            if (response.data) {
                setorderStatus(response.data.flReportStatus)
                ordStatus.value = 0;
                ordStatus.label = '-Select Order Status-';
                setSelectedOptionsStatus(ordStatus)
            }
            else {
                setSelectedOptionsStatus(ordStatus)
            }
            hideLoader();
        }).catch(() => {
            hideLoader();
        });

    }

    const GetComments = async (supplierID, PurchaseOrderSkuID, Feildname, Cmt) => {
       
        showLoader();
        let Exfaclist = [];
        setindex(0);
        setTNAId(Cmt.tnaid)
        setPurchaseId(PurchaseOrderSkuID);
        setSupplierID(supplierID);
        setCommentpopup(true)
        let isChecked = false;
        let indexes = FlDetailsReportList.findIndex(obj => obj.purchaseOrderSkuID === PurchaseOrderSkuID)
        setindex(indexes);
        if (Feildname === 'Comments') {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateCommentsreportViewCheckBox(params));

        await FinalinspectionService.GetFLPurchaseOrderSkuReportComments(PurchaseOrderSkuID, 1).then(async (response) => {

            if (await response.data) {
                setreportcomments(await response.data.flReportCommentDetails)
                setFiles(await response.data.flReportfileupload)
            }
            hideLoader();
        }).catch(() => { hideLoader(); });
        await FinalinspectionService.GetFLCommentsReportExFactory(supplierID, 1, 0).then((res) => {

            if (res.data) {
                res.data.flexFacCommon.map((x, index) => {
                    Exfaclist.push({
                        value: x.value,
                        label: x.label
                    })
                })
                setCmtExFactoryList(Exfaclist);
            }
            hideLoader();
        })

    }


    const StatusClose = (Feildname) => {
        let isChecked = false;
        if (Feildname === 'ordStatus') {
            isChecked = false;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: getordindex
        };
        dispatch(UpdateCommentsreportViewCheckBox(params));
        setShowmodal(false);
        setSubmitted(false);
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates({
            ...checkboxStates,
            [name]: checked
        });
    };
    let Size = '120px';
    const tableHeader = [

        {
            name: "#",
            field: "",
            sortable: false,
            widthsize: '2%',
            left: 0,
            //padding: "30px !important"
        }
        ,
        {
            name: "Plan End Date",
            field: "planEndDate",
            sortable: true,
            widthsize: Size,
            left: 0,
            // padding: "30px !important"
        }
        ,



        {
            name: "PO SKU ID",
            field: "purchaseOrderSkuID",
            sortable: true,
            widthsize: Size,
            //position: "sticky",
            left: "120px",
            //background: "#222d32",
        }
        // ,


        // {
        //     name: "Season",
        //     field: "seasonName",
        //     sortable: true,
        //     width: "3%",
        //     widthsize: "120px",
        //     //position: "sticky",
        //     left: "240px",
        //     //background: "#222d32",
        // }
        // ,


        // {
        //     name: "Quarter",
        //     field: "quarterName",
        //     sortable: true,
        //     width: "1%",
        //     widthsize: "120px",
        //     //position: "sticky",
        //     left: "360px",
        //     //background: "#222d32",
        // }
        // ,


        // {
        //     name: "Brand",
        //     field: "brandName",
        //     sortable: true,
        //     width: "5%",
        //     widthsize: "120px",
        //     //position: "sticky",
        //     left: "480px",
        //     //background: "#222d32",
        // }
        ,
        {
            name: "Customer",
            field: "customerName",
            sortable: true,
            // width: "5%",
            widthsize: Size,
            //position: "sticky",
            left: "600px",
            //background: "#222d32",
        }
        ,
        checkboxStates.styleName && (
            {
                name: "Style Name",
                field: "styleName",
                sortable: true,
                widthsize: Size,
                // width: "6%",
            }
        ),
        checkboxStates.skuName && (
            {
                name: "SKU Name",
                field: "skuName",
                sortable: true,
                // width: "5%",
                widthsize: Size,
                //position: "sticky",
                left: "736px",
                //background: "#222d32",
            }
        ),
        checkboxStates.idNo && (
            {
                name: "PO/ID No",
                field: "idpono",
                sortable: true,
                widthsize: Size,
            }
        ),
        checkboxStates.FOPONo && (
            {
                name: "Focus PO No",
                field: "foidpono",
                sortable: true,
                widthsize: Size,
            }
        ),
        checkboxStates.skuImg && (
            {
                name: "SKU Image",
                field: "skuImage",
                sortable: true,
                widthsize: Size,
                // position: "sticky",
                left: "858px",
                //background: "#222d32",
            }
        ),
        checkboxStates.supplier && (
            {
                name: "Supplier",
                field: "supplierName",
                sortable: true,
                widthsize: Size,
            }
        ),
        // checkboxStates.Factory && (
        //     {
        //         name: "Factory",
        //         field: "factory",
        //         sortable: true,
        //         width: "4%",
        //     }
        // ),


        // checkboxStates.category && (
        //     {
        //         name: "Category",
        //         field: "categoryName",
        //         sortable: true,
        //         width: "4%",
        //     }
        // ),


        // checkboxStates.styleNo && (
        //     {
        //         name: "Style No",
        //         field: "styleNo",
        //         sortable: true,
        //         width: "6%",
        //     }
        // ),

        // checkboxStates.size && (
        //     {
        //         name: "Size",
        //         field: "size",
        //         sortable: true,
        //         width: "8%",
        //     }
        // ),
        checkboxStates.orderQty && (
            {
                name: "Order Qty",
                field: "quantity",
                sortable: true,
                widthsize: Size,
            }
        ),

        checkboxStates.exFac && (
            {
                name: "Ex-Fac Date",
                field: "exFacDate",
                sortable: true,
                widthsize: Size,
            }
        ),

        checkboxStates.inspectionStatus && (
            {
                name: "Inspection Status",
                field: "inspectionStatus",
                className: "text-center",
                sortable: false,
                widthsize: Size,
                //minWidth: "150px"
            }
        ),
        checkboxStates.approvalStatus && (
            {
                name: "Approval Status",
                field: "approvalStatus",
                className: "text-center",
                sortable: false,
                widthsize: Size,
                // minWidth: "200px"
            }
        ),

        checkboxStates.BalQtyforNewInspection && (
            {
                name: "New Ins. Bal",
                field: "balQtyInspection",
                className: "text-center",
                sortable: true,
                widthsize: Size,
                // minWidth: "245px"
            }
        ),
        checkboxStates.BalQtyforReNewInspection && (
            {
                name: "Re Ins. Bal",
                field: "balQtyReInspection",
                className: "text-center",
                sortable: true,
                widthsize: Size,
                //minWidth: "245px"
            }
        ),






        // checkboxStates.orderStatus && (
        //     {
        //         name: "Order Status",
        //         field: "orderStatus",
        //         sortable: true,
        //         width: "5%",
        //     }
        // ),
        // checkboxStates.stsCreated && (
        //     {
        //         name: "	Status Created By",
        //         field: "statusCreatedBy",
        //         sortable: true,
        //         width: "2%",
        //         minWidth: "160px"
        //     }
        // ),

        // checkboxStates.currentStatus && (
        //     {
        //         name: "Current Status Comment",
        //         field: "currentComments",
        //         sortable: true,
        //         width: "7%",
        //         minWidth: "210px"
        //     }
        // ),
        // checkboxStates.cmtCreated && (
        //     {
        //         name: "Comment Created By",
        //         field: "commentsCreatedBy",
        //         sortable: true,
        //         width: "3%",
        //         minWidth: "185px"
        //     }
        // ),
        {
            name: "Action",
            field: "Action",
            className: "text-center fixed-column-header-Route",
            sortable: false,
            widthsize: Size,
            //widthsize: "7%",
            //left: "736px",
        }
    ];



    let orderStatus = [
        {
            value: 1,
            label: 'Yet to Start',
            selected: false,

        },
        {
            value: 2,
            label: 'In-Progress',
            selected: false,

        },
        {
            value: 3,
            label: 'Completed',
            selected: false,

        },
        {
            value: 4,
            label: 'Hold',
            selected: false,

        },
        {
            value: 5,
            label: 'Cancel',
            selected: false,

        }
    ]

    const handleAllOnSelect = (event, FieldName, page, total) => {

        let Pagecount = parseInt(page);
        if (event.target.checked) {
            for (let i = 0; i < Pagecount; i++) {
                commentsData.map(x => {
                    x.isSelected = true;
                })
                setIsAllSelected(true);
                localStorage.setItem("Ischecked", true)
            }
            dispatch(UpdateCommentsreportViewAllCheckBox(true));

        } else {
            for (let i = 0; i < Pagecount; i++) {
                commentsData.map(x => {
                    x.isSelected = false;
                })
                setIsAllSelected(false);
                localStorage.setItem("Ischecked", false)
            }
            dispatch(UpdateCommentsreportViewAllCheckBox(false));
        }
    }
    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        let getStateInsDate = [...stateInsDate];
        let getStateTNADate = [...stateTNADate];
        getStateDate[0].isShow = isShow;
        getStateExDate[0].isShow = false;
        getStateInsDate[0].isShow = false;
        getStateTNADate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);

    }
    const handleExDateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        let getStateInsDate = [...stateInsDate];
        let getStateTNADate = [...stateTNADate];
        getStateExDate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        getStateInsDate[0].isShow = false;
        getStateTNADate[0].isShow = false;
        setStateDate(getStateDate);
        setStateExDate(getStateExDate);
    }

    const handleInsDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        let getStateTNADate = [...stateTNADate];
        let getStateExDate = [...stateExDate];
        let getStateInsDate = [...stateInsDate];
        getStateInsDate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        getStateExDate[0].isShow = false;
        getStateTNADate[0].isShow = false;
        setStateDate(getStateDate);
        setStateInsDate(getStateInsDate);
    }

    const handleTNADateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        let getStateTNADate = [...stateTNADate];
        let getStateExDate = [...stateExDate];
        let getStateInsDate = [...stateInsDate];
        getStateTNADate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        getStateExDate[0].isShow = false;
        getStateInsDate[0].isShow = false;
        setStateDate(getStateDate);
        setStateTNADate(getStateTNADate);
    }





    function DateExfaconchange(item) {
        stateExDate[0].startDate = item[0].startDate;
        stateExDate[0].endDate = item[0].endDate
        setStateExDate(item)
        stateDate[0].startDate = null;
        stateDate[0].endDate = new Date('')
        setStateDate(stateDate)
        stateInsDate[0].startDate = null;
        stateInsDate[0].endDate = new Date('');
        setStateInsDate(stateInsDate)
        stateTNADate[0].startDate = null;
        stateTNADate[0].endDate = new Date('');
        setStateTNADate(stateTNADate)
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        let InspectionStartDate = null;
        let InspectionEndDate = null;
        if (moment(stateInsDate[0].startDate).isValid()) {
            InspectionStartDate = moment(stateInsDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateInsDate[0].endDate).isValid()) {
            InspectionEndDate = moment(stateInsDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        localStorage.setItem('PodateExStatedate', moment(stateExDate[0].startDate).format('MM/DD/YYYY'))
        localStorage.setItem('PodateExenddate', moment(stateExDate[0].endDate).format('MM/DD/YYYY'))
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction
            , PoStartDate: '01/01/1970'
            , PoEndDate: formattedDate
            , ExfacStartDate: StartExDate
            , ExfacEndDate: EndExDate
            , TNAStartDate: '01/01/1970'
            , TNAEndDate: formattedDate
            , InspectionStartDate: '01/01/1970'
            , InspectionEndDate: formattedDate
        }
        dispatch(LoadFlDetailsreport(params));
    }
    function DateRangechange(item) {
       
        stateDate[0].startDate = item[0].startDate;
        stateDate[0].endDate = item[0].endDate
        setStateDate(item)
        stateExDate[0].startDate = null;
        stateExDate[0].endDate = new Date('');
        setStateExDate(stateExDate)
        stateInsDate[0].startDate = null;
        stateInsDate[0].endDate = new Date('');
        setStateInsDate(stateInsDate)
        stateTNADate[0].startDate = null;
        stateTNADate[0].endDate = new Date('');
        setStateTNADate(stateTNADate)

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        let InspectionStartDate = null;
        let InspectionEndDate = null;
        if (moment(stateInsDate[0].startDate).isValid()) {
            InspectionStartDate = moment(stateInsDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateInsDate[0].endDate).isValid()) {
            InspectionEndDate = moment(stateInsDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        localStorage.setItem('PodateStatedate', moment(stateDate[0].startDate).format('MM/DD/YYYY'))
        localStorage.setItem('Podateenddate', moment(stateDate[0].endDate).format('MM/DD/YYYY'))
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction
            , PoStartDate: StartDate
            , PoEndDate: EndDate
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: '01/01/1970'
            , TNAEndDate: formattedDate
            , InspectionStartDate: '01/01/1970'
            , InspectionEndDate: formattedDate
        }
        dispatch(LoadFlDetailsreport(params));
    }

    function DateInsfaconchange(item) {
        stateInsDate[0].startDate = item[0].startDate;
        stateInsDate[0].endDate = item[0].endDate
        setStateInsDate(item)
        stateExDate[0].startDate = null;
        stateExDate[0].endDate = new Date('')
        setStateExDate(stateExDate)
        stateDate[0].startDate = null;
        stateDate[0].endDate = new Date('')
        setStateDate(stateDate)
        stateTNADate[0].startDate = null;
        stateTNADate[0].endDate = new Date('');
        setStateTNADate(stateTNADate)
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        let InspectionStartDate = null;
        let InspectionEndDate = null;
        if (moment(stateInsDate[0].startDate).isValid()) {
            InspectionStartDate = moment(stateInsDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateInsDate[0].endDate).isValid()) {
            InspectionEndDate = moment(stateInsDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction
            , PoStartDate: '01/01/1970'
            , PoEndDate: formattedDate
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: '01/01/1970'
            , TNAEndDate: formattedDate
            , InspectionStartDate: InspectionStartDate
            , InspectionEndDate: InspectionEndDate
        }
        dispatch(LoadFlDetailsreport(params));
    }

    function DateTNAchange(item) {
        stateTNADate[0].startDate = item[0].startDate;
        stateTNADate[0].endDate = item[0].endDate
        setStateTNADate(item)
        stateInsDate[0].startDate = null;
        stateInsDate[0].endDate = new Date('')
        setStateInsDate(stateInsDate)
        stateExDate[0].startDate = null;
        stateExDate[0].endDate = new Date('')
        setStateExDate(stateExDate)
        stateDate[0].startDate = null;
        stateDate[0].endDate = new Date('')
        setStateDate(stateDate)
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        let InspectionStartDate = null;
        let InspectionEndDate = null;
        if (moment(stateInsDate[0].startDate).isValid()) {
            InspectionStartDate = moment(stateInsDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateInsDate[0].endDate).isValid()) {
            InspectionEndDate = moment(stateInsDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction
            , PoStartDate: '01/01/1970'
            , PoEndDate: formattedDate
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
            , InspectionStartDate: '01/01/1970'
            , InspectionEndDate: formattedDate
        }
        dispatch(LoadFlDetailsreport(params));
    }




    const clearDateSelect = () => {

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);

        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction
            , PoStartDate: '01/01/1970'
            , PoEndDate: formattedDate
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
            , InspectionStartDate: '01/01/1970'
            , InspectionEndDate: formattedDate
        }
        dispatch(LoadFlDetailsreport(params));
    }
    const clearExDateSelect = () => {
        var defaultExStartDate = new Date();
        const emptyStateExDate = ([
            {
                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        setStateExDate(emptyStateExDate);
        var defaultStartDate = new Date();
        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction
            , PoStartDate: '01/01/1970'
            , PoEndDate: formattedDate
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
            , InspectionStartDate: '01/01/1970'
            , InspectionEndDate: formattedDate
        }
        dispatch(LoadFlDetailsreport(params));
    }

    const clearInsDateSelect = () => {
        const emptyStateDate = [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }];
        setStateInsDate(emptyStateDate);
    }

    const clearTNADateSelect = () => {
        const emptyStateDate = [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }];
        setStateTNADate(emptyStateDate);
    }


    const commentsData = useMemo(() => {

        let computedComments = FlDetailsReportList;
        let drpvalue = orderStatus;
       

        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSkuSearch) {
            computedComments = computedComments.filter(comment =>
                comment.skuName.toLowerCase().includes(getSkuSearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }

        if (getFactorySearch) {
            computedComments = computedComments.filter(comment =>
                comment.factory.toLowerCase().includes(getFactorySearch.toLowerCase()) // Need to be Change
            )
            setTotalItems(computedComments.length);
        }

        if (getStyleNamesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStyleNamesearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getStyleNosearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleNo.toLowerCase().includes(getStyleNosearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }


        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getQuartersearch) {
            computedComments = computedComments.filter(comment =>
                comment.quarterName.toLowerCase().includes(getQuartersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getSuppliersearch) {
            computedComments = computedComments.filter(comment =>
                comment.supplierName.toLowerCase().includes(getSuppliersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }

        if (getEndCustomersearch) {
            computedComments = computedComments.filter(comment =>
                comment.customerName.toLowerCase().includes(getEndCustomersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getCategorysearch) {
            computedComments = computedComments.filter(comment =>
                comment.categoryName.toLowerCase().includes(getCategorysearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        // if (getBuyerEmpsearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.buyerEmployee.toLowerCase().includes(getBuyerEmpsearch.toLowerCase())
        //     );
        //     setTotalItems(computedComments.length);
        // }
        // if (getCubeEmpsearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.cubeEmployee.toLowerCase().includes(getCubeEmpsearch.toLowerCase())
        //     );
        //     setTotalItems(computedComments.length);
        // }
        if (getInspectionAndApprovalSearch) {
            if (getInspectionAndApprovalSearch.value === 1
                || getInspectionAndApprovalSearch.value === 2
                || getInspectionAndApprovalSearch.value === 5) { //yet to start && pregressing && New Inspection
                computedComments = computedComments.filter(comment =>
                    comment.inspectionCreatedDate == null
                );
            }
            else if (getInspectionAndApprovalSearch.value === 3) { //pass count
                computedComments = computedComments.filter(comment =>
                    comment.passCount > 0
                );
            }
            else if (getInspectionAndApprovalSearch.value === 4) { //draft count
                computedComments = computedComments.filter(comment =>
                    comment.failCount > 0
                );
            }
            else if (getInspectionAndApprovalSearch.value === 6) { //re inspection
                computedComments = computedComments.filter(comment =>
                    comment.inspectionType == 2
                );
            }
            else if (getInspectionAndApprovalSearch.value === 7) { //re inspection pending
                computedComments = computedComments.filter(comment =>
                    comment.inspectionType == 2 && comment.pendingCount > 0
                );
            }
            else if (getInspectionAndApprovalSearch.value === 8) { //Inspection Under Approval
                computedComments = computedComments.filter(comment =>
                    comment.approvalCount === 0
                    && (comment.commercialApprovalCount + comment.rejectedCount + comment.pendingCount) > 0
                );
            }
            else if (getInspectionAndApprovalSearch.value === 9) { //approved
                computedComments = computedComments.filter(comment =>
                    comment.approvalCount > 0
                );
            }
            else if (getInspectionAndApprovalSearch.value === 10) { // commercially approved
                computedComments = computedComments.filter(comment =>
                    comment.commercialApprovalCount > 0
                );
            }
            else if (getInspectionAndApprovalSearch.value === 11) { // rejected
                computedComments = computedComments.filter(comment =>
                    comment.rejectedCount > 0
                );
            }
            else if (getInspectionAndApprovalSearch.value === 12) { // balance qty for new inspection
                computedComments = computedComments.filter(comment =>
                    comment.balQtyInspection > 0
                );
            }
            else if (getInspectionAndApprovalSearch.value === 13) { // balance qty for re inspection
                computedComments = computedComments.filter(comment =>
                    comment.balQtyReInspection > 0
                );
            }


        }

        if (getOrderStatussearch) {
            if (getOrderStatussearch.value === 1) {
                computedComments = computedComments.filter(comment =>
                    comment.orderStatus === null || comment.orderStatus === "" || comment.orderStatus === undefined
                    // || parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            } else {
                computedComments = computedComments.filter(comment =>
                    parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            }

            setTotalItems(drpvalue && drpvalue.length);
        }
       ;
        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            );

            setTotalItems(computedComments.length);
        }
        // if (getIdPosearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.fpidpono.toLowerCase().includes(getIdPosearch.toLowerCase())
        //     );

        //     setTotalItems(computedComments.length);
        // }
        let format = "DD/MM/YYYY";
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = new Date(moment(stateDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = new Date(moment(stateDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        if (stateDate[0].startDate && stateDate[0].endDate) {
            computedComments = computedComments.filter(comment =>
                new Date(moment(comment.poDate, format)) >= stateDate[0].startDate && new Date(moment(comment.poDate, format)) <= stateDate[0].endDate
            )
        }
        setTotalItems(computedComments.length);
        let Exformat = "DD/MM/YYYY";
        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = new Date(moment(stateExDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = new Date(moment(stateExDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        if (stateExDate[0].startDate && stateExDate[0].endDate) {
            computedComments = computedComments.filter(comment =>
                new Date(moment(comment.exFacDate, Exformat)) >= stateExDate[0].startDate && new Date(moment(comment.exFacDate, Exformat)) <= stateExDate[0].endDate
            )
        }
        setTotalItems(computedComments.length);

        if (sorting.field === "planEndDate") {
            const y = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => {
                const valueA = a[sorting.field] || ""; // Handle null value with an empty string
                const valueB = b[sorting.field] || ""; // Handle null value with an empty string

                return y * valueA.localeCompare(valueB);
            });
        }

        // if (sorting.field === "planEndDate") {
        //     const reversed = sorting.order === "asc" ? 1 : -1;
        //     computedComments = computedComments.sort((a, b) => reversed * (a[sorting.field].localeCompare(b[sorting.field])));
        // }
        //sorting table
        else if (sorting.field) {
            computedComments.map((x) => {
                x.purchaseOrderSkuID = x.purchaseOrderSkuID.toString();
            })
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));

        }



        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }

        // Current Page slice
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));


    },
        [
            FlDetailsReportList,
            currentPage,
            // search,
            getBuyersearch,
            getSkuSearch,
            getFactorySearch,
            getSeasonsearch,
            getBrandsearch,
            getQuartersearch,
            getIdPosearch,
            getSuppliersearch,
            getEndCustomersearch,
            getCategorysearch,
            getStyleNamesearch,
            getStyleNosearch,
            getBuyerEmpsearch,
            getCubeEmpsearch,
            getOrderStatussearch,
            getInspectionAndApprovalSearch,
            stateDate,
            stateExDate,
            sorting,
            ITEMS_PER_PAGE,
        ]);

    const resetStyle = () => {
        setBuyerSearch('');
        setSkuSearch('');
        setFactorySearch('');
        setQuarterSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setsupplierSearch('');
        setIdPoSearch('');
        setsupplierSearch('');
        setEndCustomerSearch('');
        setCategorySearch('');
        setStyleNameSearch('');
        setStyleNoSearch('');
        setBuyerEmpSearch('');
        setCubeEmpSearch('');
        setOrderStatusSearch('');
        setIsProductionStatussearch('');
        setInspectionAndApprovalSearch('');

        var defaultTNAStartDate = new Date();
        const emptyStateTNADate = ([
            {

                startDate: defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        // stateTNADate[0].startDate = defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 90);
        // stateTNADate[0].endDate = new Date();
        setStateTNADate(emptyStateTNADate)
        if (props.location.state && props.location.state.params.localStorage === true) {
            props.location.state.params.localStorage = false;
        }
        localStorage.removeItem("PodateStatedate");
        localStorage.removeItem("Podateenddate");
        localStorage.removeItem("PodateExStatedate");
        localStorage.removeItem("PodateExenddate");
        // setStateDate(emptyStateDate);
        // setStateExDate(emptyStateExDate);
        // setStateInsDate(emptyStateInsDate);
        //setStateTNADate(emptyStateTNADate);
        let value = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(value);


        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        // Get the current date
        const currentDate = new Date();

        // Create a moment object from the current date and add one year
        const futureDate = moment(currentDate).add(1, 'year');

        // Format the future date
        const formattedDate = futureDate.format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction
            , PoStartDate: '01/01/1970'
            , PoEndDate: formattedDate
            , ExfacStartDate: '01/01/1970'
            , ExfacEndDate: formattedDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
            , InspectionStartDate: '01/01/1970'
            , InspectionEndDate: formattedDate
        }
        dispatch(LoadFlDetailsreport(params));

        stateExDate[0].startDate = null;
        stateExDate[0].endDate = new Date('')
        setStateExDate(stateExDate)
        stateDate[0].startDate = null;
        stateDate[0].endDate = new Date('')
        setStateDate(stateDate)
        stateInsDate[0].startDate = null;
        stateInsDate[0].endDate = new Date('');
        setStateInsDate(stateInsDate)
    }
    const handleChangeOrdStatus = (value) => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);
        //setInspectionAndApprovalSearch(value);
        setOrderStatusSearch(value);
    }
    const handleChangeInspectionandApprovalStatus = (value) => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);
        setInspectionAndApprovalSearch(value);
        //setIsProductionStatussearch(value);
    }
    const handleclick = () => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateExDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateExDate(getStateExDate);
        setStateDate(getStateDate);

    }
    const onFocus = (e, field) => {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    const ViewFile = (event, filename) => { // event.preventDefault();
        // let arr;
        // arr = filename.split(',')
        // for (let sku of arr) {
        //
        // }
        setIsOpenimg(true);
        setImagename(filename);
    }
    function RemoveComments(Commentitem) {

        let Param = {
            Operation: FLReportSkuComments,
            Id: Commentitem.commentsID,
        };
        setID({ showPopupDelete: true, Params: Param });
    }

    const filevals = [...getFiles]
    const AppendFiles = async e => {

        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "ReportStatus");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    let Index = FlDetailsReportList.findIndex(obj => obj.purchaseOrderSkuID === getpurchaseId.toString())
                    filevals.push({
                        reportManualFileId: 0,
                        fileName: res.data + "_" + file.name,
                        filePath: "Images/Report/" + file.name,
                        fileType: file.type,
                        reportIndexName: Index.toString(),
                        reportFileIndexName: getFiles.length.toString(),
                        IsDeleted: 0
                    })
                    setFiles(filevals);
                    // let Split = getFiles[0].FileName.split("_");
                    // getFiles[0].FileName = Split[1];
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const RemoveImages = async (index, files, ReportManualFileId) => {

        if (ReportManualFileId !== 0) {
            getFiles[index].IsDeleted = 1;
        } else {
            filevals.splice(index, 1);
        }
        setFiles(filevals);
    }

    const handleFullClose = (Feildname, comment) => {
        if (selectedCommentExfactory) {
            selectedCommentExfactory.value = 0;
            selectedCommentExfactory.label = '';
            setSelectedCommentExfactory(selectedCommentExfactory);
        }
        let isChecked = false;
        if (Feildname === 'reportcmt') {
            isChecked = false;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: getindex
        };
        if (comment === undefined || comment === '') {
            setSubmitted(false);
        }
        if (getFiles.reportManualFileId === 0) {
            setFiles([]);
        }
        dispatch(UpdateCommentsreportViewCheckBox(params));
        setCommentpopup(false);
        setreportcomments([]);
        setEcomment('');


    }
    const handleChangeComment = (e) => {
        setSubmitted(false);
        let inputtext = "";
        if (e.target.value.trim() && e.target.value.trim() !== '') {
            inputtext = e.target.value;
            setSubmitted(false);
        }
        else {
            //setSubmitted(true);
        }
        setEcomment(inputtext);

    }
    function handleCmtEXFactoryChanges(value) {

        setSelectedCommentExfactory(value)
    }

    const handleChangeIsProductionStatus = (value) => {
        ;
        if (value === null) {
            value = { label: "Sampling", selected: true, value: 1 }
        }

        setIsProductionStatussearch(value);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateExDate[0].startDate).isValid()) {
            StartExDate = moment(stateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateExDate[0].endDate).isValid()) {
            EndExDate = moment(stateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        let TNAStartDate = null;
        let TNAEndDate = null;
        if (moment(stateTNADate[0].startDate).isValid()) {
            TNAStartDate = moment(stateTNADate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTNADate[0].endDate).isValid()) {
            TNAEndDate = moment(stateTNADate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        let InspectionStartDate = null;
        let InspectionEndDate = null;
        if (moment(stateInsDate[0].startDate).isValid()) {
            InspectionStartDate = moment(stateInsDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateInsDate[0].endDate).isValid()) {
            InspectionEndDate = moment(stateInsDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = {
            IsProduction: IsProduction
            , PoStartDate: StartDate
            , PoEndDate: EndDate
            , ExfacStartDate: StartExDate
            , ExfacEndDate: EndExDate
            , TNAStartDate: TNAStartDate
            , TNAEndDate: TNAEndDate
            , InspectionStartDate: InspectionStartDate
            , InspectionEndDate: InspectionEndDate
        }
        dispatch(LoadFlDetailsreport(params));
    }
    function handleChange(e, filterName) {
       
        let inputText = '';
        if (filterName === 'Buyer' && filterName !== '') {

            // let values = [...getBuyersearch];
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            // values= inputText;
            localStorage.setItem('Buyer', inputText)
            setBuyerSearch(inputText);
        }
        else if (filterName === 'StyleNo') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('StyleNo', inputText)
            setStyleNoSearch(inputText);
        }
        else if (filterName === 'StyleName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('StyleName', inputText)
            setStyleNameSearch(inputText);
        }
        else if (filterName === 'Season') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Season', inputText)
            setSeasonSearch(inputText);
        }
        else if (filterName === 'Brand') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Brand', inputText)
            setBrandSearch(inputText);
        }
        else if (filterName === 'ID/NO') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('ID/NO', inputText)
            setIdPoSearch(inputText);
        }
        else if (filterName === 'Category') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Category', inputText)
            setCategorySearch(inputText);
        }
        else if (filterName === 'Supplier') {

            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Supplier', inputText)
            setsupplierSearch(inputText);
        }
        else if (filterName === 'EndCustomer') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('EndCustomer', inputText)
            setEndCustomerSearch(inputText);
        }
        else if (filterName === 'OrderStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('OrderStatus', inputText)
            setOrderStatusSearch(inputText);


        }
        else if (filterName === 'SKUName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('SKUName', inputText)
            setSkuSearch(inputText);
        }
        else if (filterName === 'Factory') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Factory', inputText)
            setFactorySearch(inputText);
        }
        else if (filterName === 'IsProductionStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
                // let params = { Operation: 1 ,IsProduction: inputText}
                // dispatch(LoadFlDetailsreport(params));
            }
            localStorage.setItem('IsProductionStatus', inputText)
            setIsProductionStatussearch(inputText);
        }


        else if (filterName === 'Quarter') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Quarter', inputText)
            setQuarterSearch(inputText);
        }
    };



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const options = {
        thumbnails: {
            showThumbnails: false,
        }
    }

    // const GetSizeandQty = (PurchaseOrderSkuID,) => {
    //     ;
    //     ReportService.GetPurchaseOrderSizeQty(PurchaseOrderSkuID).then((response) => {
    //         ;
    //         if (response.data) {
    //             setsizeqtylist(response.data)
    //         }

    //     }).catch(() => { });
    //     setsizeqtypopup(true);

    // }
    const GetSizeandQty = (PurchaseOrderSkuID, orderQty) => {
        setskuid('');
        ReportService.GetPurchaseOrderSizeQty(PurchaseOrderSkuID).then((response) => {
            ;
            if (response.data) {
                setsizeqtylist(response.data)
            }

        }).catch(() => { });
        setsizeqtypopup(true);
        setskuid({ skuID: PurchaseOrderSkuID, qrty: orderQty })
    }

    const GetInspectionAndApprovalStatus = (PurchaseOrderSkuIDOrInspectionID, statusType) => {
        setskuid('');
        showLoader();
        FinalInspectionService.GetInspectionandApprovalList(statusType, PurchaseOrderSkuIDOrInspectionID).then((response) => {
            if (response.data && (statusType == 1 && response.data.inspectionInfo.length > 0 || statusType == 2 && response.data.approvalInfo
                .length > 0)) {
                setisInspectionStarted(1);
                statusType == 1 ? setapprovalOrInspectionList(response.data.inspectionInfo) : setapprovalOrInspectionList(response.data.approvalInfo);
            }
            else {
                setisInspectionStarted(0);
            }
            hideLoader();
        }).catch(() => {
            hideLoader();
        });
        setskuid({ skuID: PurchaseOrderSkuIDOrInspectionID, qrty: 0 })
        Setstatustype(statusType);
        SetinspectionAndApprovalpopup(true);
    }

    const Drag = (props) => {
        return <Draggable handle=".modal-title"><ModalDialog {...props} /></Draggable>
    }

    const handleCloseExFactoryFormPopup = () => {
        Input.IsExFactoryForm = 0;
        setInputFields(Input);
        setfinalinspectionMulti([])
    };


    const handleFinalInspecViewOpen = (comment, Isfinal, TNAExFactoryId) => {
        showLoader();
        SetCommentListForEditr(comment)
        Input.Finalview = Isfinal;
        // Input.Finalview = 0
        setInputFields(Input);
        let balanceqty = 0;
        FinalinspectionService.GetTNAExfacReportList(TNAExFactoryId).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            // res.data.map((inspc) => {
            //     if (balanceqty === 0) {
            //         balanceqty = (parseInt(inspc.poQty) + parseInt(inspc.tolerance)) - parseInt(inspc.inspectedQty);
            //         inspc.balanceQuantity = balanceqty;
            //     }
            //     else {
            //         inspc.balanceQuantity = (parseInt(inspc.poQty) + parseInt(inspc.tolerance)) - parseInt(inspc.inspectedQty);
            //         balanceqty = inspc.balanceQuantity;
            //     }

            // })

            setnewfinalReportlist(res.data)
            setnewheaderdata(uniquevalues)
            hideLoader();
        })
        getInspectionInfo.TaskStatus = 2;
        setInspectionInfo(getInspectionInfo);
        let params = {
            Operation: 2,
            BuyerId: comment.buyerID,
            BrandId: comment.brandID, SeasonId: comment.seasonID,
            SupplierId: comment.supplierID, TaskId: comment.taskDetailsID,
            StyleId: comment.styleID,
            SkuId: 0,
            TaskOwnerIdList: comment.taskOwner,
            TaskHolderId: 0,
            tnaExFactoryId: comment.tnaExFactoryId,
            status: 0,
            selectedDependency: '',
            //NewID is subID
            NewID: comment.skuSubID,
            Isproduction: IsProduction
            , BuyerName: comment.buyerName
        };
        setAddInspectionListParam(params);
        dispatch(GetSubChildMultExFactoryFormSKUList(params));

    }

    const handleFinalInspecViewclose = () => {
        Input.Finalview = 0;
        setInputFields(Input);
    };



    const handleExFactoryFormOpen = (comment, IsExFactoryFormPop, Taskid) => {
        setTNAPurchaseOrderID(comment)
        // let Operation = 1
        // let Dependency = false;
        // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

        //     if (values[index].SelectedDependency) {
        //         let arrstatus = [];
        //         arrstatus = values[index].SelectedDependency.split(',');
        //         if (arrstatus.length > 0) {
        //             arrstatus.forEach(element => {

        //                 let val = [];
        //                 val = element.split('-');
        //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
        //                 if (valIndex !== 3) {
        //                     Dependency = true;
        //                 }
        //             });
        //         }
        //         if (Dependency) {
        //             Nodify('Warning!', 'warning', "Please close dependency task");
        //         }
        //         else {

        //         }
        //     }
        // }

        // if (!Dependency) {

        //     let params = {
        //         Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
        //         BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
        //         SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
        //         SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
        //         tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId
        //     };
        //     setAddInspectionListParam(params);

        let params = {
            Operation: 2,
            BuyerId: comment.buyerID, BrandId: comment.brandID, SeasonId: comment.seasonID,
            SupplierId: comment.supplierID, TaskId: Taskid, StyleId: comment.styleID,
            SkuId: 0, TaskOwnerIdList: comment.taskOwner, TaskHolderId: 0,
            tnaExFactoryId: 0, status: 1, selectedDependency: '', NewID: 0,
            Isproduction: IsProduction, TnaId: comment.tnaid, BuyerName: comment.buyerName
        };
        setAddInspectionListParam(params);

        setskusingleID(comment.purchaseOrderSkuID)
        Input.IsExFactoryForm = IsExFactoryFormPop;
        Input.strTaskName = "FINAL INSPECTION";
        Input.StatusID = 1;
        setInputFields(Input);

        getInspectionInfo.TaskStatus = IsExFactoryFormPop;
        setInspectionInfo(getInspectionInfo);


    };
    //For Incorrect Supplier Name Displayed
    const [getCommentListForEditr, SetCommentListForEditr] = useState();
    const [getFinalInspectionPageView, setFinalInspectionPageView] = useState('AddInspection');
    const [getAddInspectionListParam, setAddInspectionListParam] = useState([]);
    const [getIsAddFinalInspection, setIsAddFinalInspection] = useState(false);
    const [getfinalinspectionMulti, setfinalinspectionMulti] = useState([]);
    const [commonSubmitApprovalParams, setCommonSubmitApprovalParams] = useState({ SelectedDependency: '', TaskID: 0, IsSingleTask: true, hasAllDependencyStart: 0 });
    const pageRedirectForFinalInspection = (Page) => {
        setFinalInspectionPageView(Page);
    }

    function FinalInspectionCallback(value) {
       
        setfinalinspectionMulti(value)
    }

    const AddExFactoryForm = async () => {
       
        // let Multiselected = [];
        // const Values = [...getfinalinspectionMulti]
        // let isvalid = true;
        // isvalid = !!getfinalinspectionMulti.find(x => x.isSelected === 1)
        // if (isvalid) {
        //     Multiselected = Values.concat(getfinalinspectionMulti.filter(x => x.isSelected === 1))
        //     setIsAddFinalInspection(true);
        // } else {
        //     Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
        //     return false;
        // }
        let isvalid = true;
        isvalid = !!getfinalinspectionMulti.find(x => x.isSelected === 1)
        if (isvalid) {

            let isvalid = true;
            isvalid = !!getfinalinspectionMulti.find(x => x.isSelected === 1)
            if (isvalid) {
                let Multiselected = [];
                const Values = [...getfinalinspectionMulti]
                let Isvalid = false
                await Aqlfinalinspection.GetAqlFinalInspection(0, 2).then((res) => {
                    if (res.data.length > 0) {
                        Isvalid = !!!res.data.find(m => m.buyerID === getAddInspectionListParam.BuyerId)
                    }
                })

                if (!Isvalid) {
                    Multiselected = Values.concat(getfinalinspectionMulti.filter(x => x.isSelected === 1))
                    setIsAddFinalInspection(true);
                }
                else {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields in AQL Inspection & AQL Tolerance in Master.');
                    return false;
                }
            } else {
                Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
                return false;
            }
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }

        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 1;}

    };
    const handleCloseInspectionConfirmationPopup = (index, subIndex, childIndex) => {
        setIsAddFinalInspection(false);
    };


    const handleFinalInspecViewDetails = async (type, TNAInspectionID, comment, Action, inpectionpage, item) => {
       ;
        let isvalid = true;
        if (Action === 'View') {
            isvalid = true;
        } else {
            isvalid = false;
        }
        if (!isvalid) {
            let PackingParams = {
                Operation: 104,
                Id: TNAInspectionID,
            }
            await CommonService.CheckMappedItem(PackingParams).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This finalinspection has already approved in inspection Entry Tab, So action not allowed to Edit.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
                    let sizeandqtyfordisplay = "";
                    let NewArr = [];
                    NewArr = TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID))
                    NewArr.map((Purchaseorder) => {
                        if (sizeandqtyfordisplay === '') {
                            sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                        } else {
                            sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                        }
                    })
                    TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID)).map((element) => {
                        element.IscheckReportSelect = 1;
                        element.isSelected = 1;
                    })
                    getInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
                    getInspectionInfo.quantityinfo = getAddInspectionListParam
                    getInspectionInfo.details = { tnaid: getCommentListForEditr.tnaid, purchaseOrderID: getCommentListForEditr.purchaseOrderID };;
                    getInspectionInfo.type = type;
                    getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay
                    getInspectionInfo.TNAInspectionID = TNAInspectionID;
                    getInspectionInfo.Isproduction = IsProduction
                    getInspectionInfo.page = getFinalInspectionPageView;//Refer TNAEDIT For why we using This Task Name
                    getInspectionInfo.pageType = inpectionpage;
                    getInspectionInfo.ParentTask = "ProductionFLDetails";
                    getInspectionInfo.NewDetails = getCommentListForEditr;
                    getInspectionInfo.Action = Action;
                    getInspectionInfo.Mode = item.reportType === '2' ? 'ReInspection' : "InspectionView";
                    getInspectionInfo.IsAddorEditReinspection = item.reportType === '2' ? Action : '';
                    props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
                }
            })

        } else {
            const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
            let sizeandqtyfordisplay = "";
            let NewArr = [];
            NewArr = TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID))
            NewArr.map((Purchaseorder) => {
                if (sizeandqtyfordisplay === '') {
                    sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                }
            })
            TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID)).map((element) => {
                element.IscheckReportSelect = 1;
                element.isSelected = 1;
            })
            getInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
            getInspectionInfo.quantityinfo = getAddInspectionListParam
            getInspectionInfo.details = { tnaid: getCommentListForEditr.tnaid, purchaseOrderID: getCommentListForEditr.purchaseOrderID };;
            getInspectionInfo.type = type;
            getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay
            getInspectionInfo.TNAInspectionID = TNAInspectionID;
            getInspectionInfo.Isproduction = IsProduction
            getInspectionInfo.page = getFinalInspectionPageView;//Refer TNAEDIT For why we using This Task Name
            getInspectionInfo.pageType = inpectionpage;
            getInspectionInfo.ParentTask = "ProductionFLDetails";
            getInspectionInfo.NewDetails = getCommentListForEditr;
            getInspectionInfo.Action = Action;
            getInspectionInfo.Mode = item.reportType === '2' ? 'ReInspection' : "InspectionView";
            getInspectionInfo.IsAddorEditReinspection = item.reportType === '2' ? Action : '';
            props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
        }
    };

    async function CreateFinalInspection(type, comment) { // type=1=> internal, 2=> external

        let isvalid = false;
        await FinalinspectionService.GetTNAFinalinspecTestingReportList(4, getTNAPurchaseOrderID.buyerID, getTNAPurchaseOrderID.brandID).then((res) => {
            if (res.data[0] !== undefined) {
                if (res.data[0].offerQty) {
                    isvalid = true
                }
            } else {
                Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields in AQL Inspection & AQL Tolerance in Master.')
            }
        })

        if (isvalid) {
            let sizeandqtyfordisplay = "";
            let NewArr = [];
            NewArr = getfinalinspectionMulti.filter(x => x.isSelected === 1)
            NewArr.map((Purchaseorder) => {
                if (sizeandqtyfordisplay === '') {
                    sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                }
            })
            getfinalinspectionMulti.filter(x => x.isSelected === 1).map((x) => {
                if (x.isSelected === 1) {
                    x.IscheckReportSelect = 1;
                } else {
                    x.IscheckReportSelect = 0;
                }

            })
            if (getFinalInspectionPageView === "ReInspection") {
                let selectedInspIds = '';
                NewArr.map(x => {
                    if (x.isSelected === 1) {
                        if (selectedInspIds === '') {
                            if (!selectedInspIds.includes(x.tnaFinalInspectionID)) {
                                selectedInspIds = x.tnaFinalInspectionID.toString();
                            }
                        }
                        else {
                            if (!selectedInspIds.includes(x.tnaFinalInspectionID)) {
                                selectedInspIds = selectedInspIds + "," + x.tnaFinalInspectionID.toString();
                            }
                        }
                    }
                })

                let uniquevalues = [...new Map(getfinalinspectionMulti.map(item =>
                    [item['skuName'], item])).values()];

                uniquevalues.map(x => {
                    let totqty = 0;
                    if (NewArr.filter(v => v.skuName === x.skuName && v.isSelected === 1).length !== 0) {
                        let sameskudetails = NewArr.filter(v => v.skuName === x.skuName);
                        sameskudetails.map(s => {
                            if (totqty === 0) {
                                totqty = s.balanceQuantity;
                            }
                            else {
                                totqty = totqty + s.balanceQuantity;
                            }
                        })
                    }
                    x.balanceQuantity = totqty;
                    x.FinalInspectionIds = selectedInspIds;
                    totqty = 0;
                })

                getInspectionInfo.multiselecteddatas = uniquevalues;
                getInspectionInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                    ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
            }
            else {
                getInspectionInfo.multiselecteddatas = getfinalinspectionMulti;
            }
           
            getInspectionInfo.quantityinfo = getAddInspectionListParam
            getInspectionInfo.details = { tnaid: getTNAPurchaseOrderID.tnaid, purchaseOrderID: getTNAPurchaseOrderID.purchaseOrderID };
            getInspectionInfo.type = type;
            getInspectionInfo.styleID = getTNAPurchaseOrderID.styleID;
            getInspectionInfo.Isproduction = IsProduction;
            getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;
            getInspectionInfo.page = getFinalInspectionPageView;
            getInspectionInfo.pageType = 'AddInspection';
            getInspectionInfo.ParentTask = "ProductionFLDetails";
            getInspectionInfo.NewDetails = getTNAPurchaseOrderID;
            getInspectionInfo.Mode = "InspectionAdd";
            getInspectionInfo.TnaID = getTNAPurchaseOrderID.tnaid
            getInspectionInfo.IsAddorEditReinspection = 'Add';
            getInspectionInfo.Mode = getFinalInspectionPageView === "ReInspection" ? 'ReInspection' : "InspectionAdd";//For Some CalculationWorks
            props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
        }
    }
    return (
        <div className="page-body" style={{ background: "none" }}>
            {/* <ReactNotification /> */}
            <div class="row">
                <div class="col-xs-12 col-md-12" >
                    <div className="widget">
                        {/* <div className="widget-header" style={{ boxShadow: "none", padding: "0px" ReactHTMLTableToExcel}}> */}
                        {/* <span className="widget-caption" style={{ padding: "15px 0px 0px" }}>Final Inspection Details Report List (91 Days) */}


                        {/* <button className="btn btn-success float-right" title="Export to Excel"
                                  columns={columns} data={ExcelValues}
                                  onClick={() => DownloadExcel()}
                              >
                                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                              </button> */}

                        {/* </span> */}

                        {/* </div> */}
                        <div className="widget-body" style={{ boxShadow: "none", padding: "0px" }}>
                            <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                        <div className="widget">
                                            <div className="widget-body no-padding">
                                                <div className="widget-main ">
                                                    <div className="panel-group accordion" id="accordion">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading ">
                                                                <h4 className="panel-title">
                                                                    <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                        Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                    </a>
                                                                </h4>
                                                            </div>
                                                            <div id="collapseOne" className="panel-collapse collapse in">
                                                                <div className="panel-body border-red">
                                                                    <div className="row">
                                                                        <div className="col-md-12">

                                                                            <div className="col-md-2">
                                                                                <label>Buyer</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Buyer'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Buyer'}
                                                                                        onChange={e => handleChange(e, 'Buyer')}
                                                                                        value={getBuyersearch}
                                                                                    />

                                                                                </span>
                                                                            </div>


                                                                            <div className="col-md-2">
                                                                                <label>Season</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Season'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Season'}
                                                                                        onChange={e => handleChange(e, 'Season')}
                                                                                        value={getSeasonsearch}
                                                                                    />

                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Brand</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Brand'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Brand'}
                                                                                        onChange={e => handleChange(e, 'Brand')}
                                                                                        value={getBrandsearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Quarter</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Quarter'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Quarter'}
                                                                                        onChange={e => handleChange(e, 'Quarter')}
                                                                                        value={getQuartersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Supplier</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Supplier'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Supplier'}
                                                                                        onChange={e => handleChange(e, 'Supplier')}
                                                                                        value={getSuppliersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>PO/ID No.</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'PO/ID No'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'PO/ID No'}
                                                                                        onChange={e => handleChange(e, 'ID/NO')}
                                                                                        value={getIdPosearch}
                                                                                    />

                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    &nbsp;

                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="col-md-2">
                                                                                <label>End Customer</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'End Customer'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'End Customer'}
                                                                                        onChange={e => handleChange(e, 'EndCustomer')}
                                                                                        value={getEndCustomersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Category</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Category'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Category'}
                                                                                        onChange={e => handleChange(e, 'Category')}
                                                                                        value={getCategorysearch}
                                                                                    />

                                                                                </span>
                                                                            </div>


                                                                            <div className="col-md-2">
                                                                                <label>Style Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Style Name'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Style Name'}
                                                                                        onChange={e => handleChange(e, 'StyleName')}
                                                                                        value={getStyleNamesearch}
                                                                                    />
                                                                                </span>

                                                                            </div>

                                                                            <div className="col-md-2">
                                                                                <label>Style No</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Style No'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Style No'}
                                                                                        onChange={e => handleChange(e, 'StyleNo')}
                                                                                        value={getStyleNosearch}
                                                                                    />

                                                                                </span>



                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>SKU Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'SKU Name'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'SKU Name'}
                                                                                        onChange={e => handleChange(e, 'SKUName')}
                                                                                        value={getSkuSearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Factory</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Factory'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Factory'}
                                                                                        onChange={e => handleChange(e, 'Factory')}
                                                                                        value={getFactorySearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    &nbsp;

                                                                    <div className="row">
                                                                        <div className="col-md-12">


                                                                            <div className="col-md-2">
                                                                                <label>Inspection and Approval Filter</label>
                                                                                <span className='input-icon icon-right' onClick={handleclick}>
                                                                                    <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                        options={InspectionandApproval}
                                                                                        isClearable={true}
                                                                                        onChange={e => handleChangeInspectionandApprovalStatus(e, 'OrderStatus')}

                                                                                        value={getInspectionAndApprovalSearch}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>



                                                                            <div className="col-md-2">
                                                                                <label>PO Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleDateSelect(event, !stateDate[0].isShow)}

                                                                                        type="text"
                                                                                        className={'form-control adjustabledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            // onChange={item => setStateDate([item.selection])}
                                                                                            onChange={item => DateRangechange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Ex-Fac Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleExDateSelect(event, !stateExDate[0].isShow)}
                                                                                        //onBlur={event => handleDateBlur()}
                                                                                        type="text"
                                                                                        className={'form-control adjustabledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times" onClick={clearExDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateExDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            //onChange={item => setStateExDate([item.selection])}
                                                                                            onChange={item => DateExfaconchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateExDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>TNA Date Range</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateTNADate[0].startDate === null ? '' : moment(stateTNADate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateTNADate[0].endDate === null || !moment(stateTNADate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateTNADate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleTNADateSelect(event, !stateTNADate[0].isShow)}
                                                                                        //onBlur={event => handleDateBlur()}
                                                                                        type="text"
                                                                                        className={'form-control adjustabledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times" onClick={clearTNADateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateTNADate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            // onChange={item => setStateExDate([item.selection])}
                                                                                            onChange={item => DateTNAchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateTNADate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>

                                                                            <div className="col-md-2">
                                                                                <label>Inspection Date Filter</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateInsDate[0].startDate === null ? '' : moment(stateInsDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateInsDate[0].endDate === null || !moment(stateInsDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateInsDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleInsDateSelect(event, !stateInsDate[0].isShow)}
                                                                                        //onBlur={event => handleDateBlur()}
                                                                                        type="text"
                                                                                        className={'form-control adjustabledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times" onClick={clearInsDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateInsDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            //onChange={item => setStateExDate([item.selection])}
                                                                                            onChange={item => DateInsfaconchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateInsDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Status</label>
                                                                                <span className='input-icon icon-right' onClick={handleclick}>
                                                                                    <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                        options={orderStatus}
                                                                                        isClearable={true}
                                                                                        onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}

                                                                                        value={getOrderStatussearch}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                    &nbsp;

                                                                    <div className="row">
                                                                        <div className="col-md-12">

                                                                            <div className="col-md-2">
                                                                                <div className="col-md-1 margin-top-30">
                                                                                    <span className="input-icon icon-right">
                                                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                                                                        >
                                                                                            <i className="fa fa-close"> Reset</i>
                                                                                        </button>
                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                &nbsp;
                                                            </div>
                                                        </div>
                                                        &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
                        <div className="fixTableHeadTnadetail">
                            {/* <Modal.Header closeButton>
                                <Modal.Title>Show Hide Header</Modal.Title>
                            </Modal.Header> */}
                            {/* , overflowY: "auto" */}
                            <Modal.Body className="customdialogbody">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="row">
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(1)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="podate"
                                                        checked={getHideID.Po}
                                                        disabled
                                                    />
                                                    &nbsp;<label htmlFor="podate">PO Date</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(21)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="podate"
                                                        checked={getHideID.skuID}
                                                    />
                                                    &nbsp;<label htmlFor="skuid">SKU ID</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(2)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="season"
                                                        checked={getHideID.season}
                                                    />
                                                    &nbsp;<label htmlFor="season">Season</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(3)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.Quarter}
                                                    />
                                                    &nbsp;<label htmlFor="quarter">Quarter</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(4)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.brand}
                                                    />
                                                    &nbsp;<label htmlFor="brand">Brand</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(5)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.customer}
                                                    />
                                                    &nbsp;<label htmlFor="customer">Customer</label>
                                                </li>
                                            </div> */}
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="supplier"
                                                            checked={checkboxStates.supplier}
                                                        />
                                                        &nbsp;<label htmlFor="supplier">Supplier </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="idNo"
                                                            checked={checkboxStates.idNo}
                                                        />
                                                        &nbsp;<label htmlFor="idNo">ID No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="styleName"
                                                            checked={checkboxStates.styleName}
                                                        />
                                                        &nbsp;<label htmlFor="styleName">Style Name</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="styleNo"
                                                            checked={checkboxStates.styleNo}
                                                        />
                                                        &nbsp;<label htmlFor="styleNo">Style No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="skuName"
                                                            checked={checkboxStates.skuName}
                                                        />
                                                        &nbsp;<label htmlFor="skuName">SKU Name</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="skuImg"
                                                            checked={checkboxStates.skuImg}
                                                        />
                                                        &nbsp;<label htmlFor="skuImg">SKU Image</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="size"
                                                            checked={checkboxStates.size}
                                                        />
                                                        &nbsp;<label htmlFor="size">Size</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="orderQty"
                                                            checked={checkboxStates.orderQty}
                                                        />
                                                        &nbsp;<label htmlFor="orderQty">Order Qty</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="inspectionStatus"
                                                            checked={checkboxStates.inspectionStatus}
                                                        />
                                                        &nbsp;<label htmlFor="inspectionStatus">Inspection Status</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="approvalStatus"
                                                            checked={checkboxStates.approvalStatus}
                                                        />
                                                        &nbsp;<label htmlFor="approvalStatus">Approval Status</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="exFac"
                                                            checked={checkboxStates.exFac}
                                                        />
                                                        &nbsp;<label htmlFor="exFac">Ex-Fac Date</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="orderStatus"
                                                            checked={checkboxStates.orderStatus}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="orderStatus">Order Status</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="currentStatus"
                                                            checked={checkboxStates.currentStatus}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="currentStatus">Comments</label>
                                                    </li>
                                                </div>


                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="stsCreated"
                                                            checked={checkboxStates.stsCreated}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="stsCreated">Status Created By</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="cmtCreated"
                                                            checked={checkboxStates.cmtCreated}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="cmtCreated">Comment Created By</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="category"
                                                            checked={checkboxStates.category}
                                                        />
                                                        &nbsp;<label htmlFor="category">Category</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Factory"
                                                            checked={checkboxStates.Factory}
                                                        />
                                                        &nbsp;<label htmlFor="Factory">Factory</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="FOPONo"
                                                            checked={checkboxStates.FOPONo}
                                                        />
                                                        &nbsp;<label htmlFor="FOPONo">FO PO No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="BalQtyforNewInspection"
                                                            checked={checkboxStates.BalQtyforNewInspection}
                                                        />
                                                        &nbsp;<label htmlFor="BalQtyforNewInspection">Bal Qty for New Inspection</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="BalQtyforReNewInspection"
                                                            checked={checkboxStates.BalQtyforReNewInspection}
                                                        />
                                                        &nbsp;<label htmlFor="BalQtyforReNewInspection">Bal Qty for Re Inspection</label>
                                                    </li>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>

                        </div>
                        <Modal.Footer>
                            <button className="btn btn-xs btn-danger" onClick={handleClose}><i className="fa fa-times"></i>&nbsp;Close</button>
                        </Modal.Footer>
                    </Modal>
                    <div className="row" style={{ marginTop: '5px' }}>
                        <div className="col-lg-10">
                        </div>
                        <div className="col-md-2" style={{ textAlign: 'right' }}>
                            {commentsData.length !== 0 && (
                                <ReactHTMLTableToExcel
                                    id="test-table-s-button-final"
                                    className="fa fa-file-excel-o  btn btn-success excelcomt"
                                    table={"table-to-xls"}
                                    filename={"InspectionDetailsList"}
                                    sheet="InspectionDetails"
                                    buttonText=""
                                />
                            )}&nbsp;
                            <button title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>
                            <label style={{ marginTop: '7px', marginLeft: "8px" }} title={isAllSelected ? "Un Select All" : "Select All"}>

                                <input type="checkbox" name="IsAllSelected"
                                    onChange={event => (handleAllOnSelect(event, 'IsAllSelected', ITEMS_PER_PAGE, totalItems))}
                                    value={isAllSelected ? true : false}
                                    checked={isAllSelected ? true : false}
                                    className="selectallcheckbox"
                                />
                            </label>
                        </div>


                        <div className="col-md-12">
                            <div style={{ overflow: "auto" }}>
                                <table data-role="table" data-mode="columntoggle" className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "170vm" }}>
                                    <TableHeader headers={tableHeader}
                                        onSorting={
                                            (field, order) => setSorting({ field, order })
                                        }
                                    />
                                    <tbody>
                                        {
                                            commentsData.map((comment, i) => (
                                                <Fragment key={i}>

                                                    <tr>

                                                        {/* <td
                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                        // className="stickyone "
                                                        title={comment.poDate}>
                                                        {comment.poDate}

                                                    </td> */}
                                                        <td>{i + 1}</td>
                                                        <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            // className="stickyone "
                                                            title={comment.planEndDate}>
                                                            {comment.planEndDate}

                                                        </td>


                                                        <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            //className="stickytwo "
                                                            title={comment.purchaseOrderSkuID}>
                                                            {comment.purchaseOrderSkuID}
                                                        </td>
                                                        <td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            //className="stickysix "
                                                            title={comment.customerName}>
                                                            {comment.customerName}
                                                        </td>
                                                        {checkboxStates.styleName && <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>}


                                                        {/* <td
                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                        //className="stickythree "
                                                        title={comment.seasonName}>
                                                        {comment.seasonName}
                                                    </td>


                                                    <td
                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                        //className="stickyfour "
                                                        title={comment.quarterName}>
                                                        {comment.quarterName}
                                                    </td>


                                                    <td
                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                        //className="stickyfive "
                                                        title={comment.brandName}>
                                                        {comment.brandName}
                                                    </td> */}




                                                        {checkboxStates.skuName && < td
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            // className="stickyseven "
                                                            title={comment.skuName}> {
                                                                comment.skuName
                                                            }</td>}
                                                        {checkboxStates.idNo && <td className="cut_textCommentsreport " title={comment.idpono}>
                                                            {comment.idpono}
                                                        </td>}
                                                        {checkboxStates.FOPONo && <td className="cut_textCommentsreport " title={comment.foidpono}>
                                                            {/* {comment.foidpono} */}

                                                            {comment.foidpono === "" ? "-" : comment.foidpono}
                                                        </td>}

                                                        {checkboxStates.skuImg && <td className="text-center"
                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                        >{
                                                                < SimpleReactLightbox >
                                                                    <SRLWrapper options={options}>
                                                                        {
                                                                            < img className="width_manual"
                                                                                src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuImagePath}
                                                                                alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)} />

                                                                        }

                                                                    </SRLWrapper>
                                                                </SimpleReactLightbox>
                                                                // comment.skuImage
                                                                // comment.skuImagePath
                                                            }</td>}
                                                        {checkboxStates.supplier && <td className="cut_textCommentsreport  " title={comment.supplierName}>{comment.supplierName}</td>}
                                                        {/* {checkboxStates.Factory && <td className="cut_textCommentsreport  " title={comment.factory}> {comment.factory} </td>}

                                                    {checkboxStates.category && <td className="cut_textCommentsreport " title={comment.categoryName}>{
                                                        comment.categoryName
                                                    }</td>}
                                                    {/* {
                                                      <td>Test</td>
                                                  }

                                                    {checkboxStates.styleNo && <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>}

                                                    {checkboxStates.size && <td className="cut_textCommentsreport " title={comment.size}>{
                                                        comment.size
                                                    }</td>} */}
                                                        {checkboxStates.orderQty &&
                                                            <td className="cut_textCommentsreport " title={comment.quantity} >
                                                                <span>{comment.quantity}</span>
                                                                <span>
                                                                    <span className="btn btn-success" title="View Sizes" style={{ float: "right" }} onClick={() => GetSizeandQty(comment.purchaseOrderSkuID, comment.quantity)}
                                                                    >
                                                                        <i className="fa fa-eye"></i>
                                                                    </span>
                                                                </span>


                                                            </td>}

                                                        {checkboxStates.exFac && <td className="cut_textCommentsreport " title={comment.exFacDate}>{
                                                            comment.exFacDate
                                                        }</td>}
                                                        {checkboxStates.inspectionStatus && <td className="cut_textCommentsreport" title={comment.inspectionStatus}>{
                                                            <table style={{ margin: "auto" }}>
                                                                <tr>
                                                                    <td>
                                                                        <span title='Draft' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                            {comment.draftCount}
                                                                        </span>
                                                                    </td>
                                                                    <td >
                                                                        <span title='Pass' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                            {comment.passCount}
                                                                        </span>

                                                                    </td>
                                                                    <td>
                                                                        <span title='Fail' className="btn  btn-danger" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 1)}>
                                                                            {comment.failCount}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        }</td>}
                                                        {checkboxStates.approvalStatus && <td className="cut_textCommentsreport " title={comment.approvalStatus}>{
                                                            // comment.approvalStatus
                                                            <table style={{ margin: "auto" }}>
                                                                <tr>
                                                                    <td>
                                                                        <span title='pending' className="btn btn-warning" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                            {comment.pendingCount}
                                                                        </span>
                                                                    </td>
                                                                    <td >
                                                                        <span title='Approval' className="btn  btn-success" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                            {comment.approvalCount}
                                                                        </span>

                                                                    </td>
                                                                    <td>
                                                                        <span title='Commercial Approval' className="btn  btn-primary" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                            {comment.commercialApprovalCount}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span title='Rejected' className="btn  btn-danger" onClick={() => GetInspectionAndApprovalStatus(comment.purchaseOrderSkuID, 2)}>
                                                                            {comment.rejectedCount}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        }</td>}


                                                        {checkboxStates.BalQtyforNewInspection && <td className="cut_textCommentsreport " title={comment.balQtyInspection}>{comment.balQtyInspection === "" ? '-' : comment.balQtyInspection}</td>}
                                                        {checkboxStates.BalQtyforReNewInspection && <td className="cut_textCommentsreport " title={comment.balQtyReInspection}>{comment.balQtyReInspection === "" ? '-' : comment.balQtyReInspection}</td>}


                                                        {/* {checkboxStates.orderStatus && <td className={comment.orderStatus == '1' ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                                                        comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                                                            'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                                                comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                                                    'Yet to Start'}>
                                                        {comment.orderStatus == '1' ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                                                            comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :
                                                                'Yet to Start'
                                                        }
                                                    </td>}

                                                    {checkboxStates.stsCreated && <td className="cut_textCommentsreport " title={comment.statusCreatedBy}>{
                                                        comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                                                    }</td>} */}
                                                        {/* {checkboxStates.currentStatus && <td className="cut_textCommentsreport " title={comment.currentComments}>{
                                                        comment.currentComments === "" ? "-" : comment.currentComments
                                                    }</td>} */}
                                                        {/* {checkboxStates.cmtCreated && <td className="cut_textCommentsreport " title={comment.commentsCreatedBy}>{
                                                        comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                                                    }</td>} */}


                                                        {<td className="text-center fixed-column-header-CommentReport" style={{ width: "160px", textAlign: 'left', display: 'block' }}>
                                                            <span style={{ padding: '2px' }}>
                                                                <span className="btn" title="Comments" onClick={() => GetComments(comment.supplierID, comment.purchaseOrderSkuID, 'Comments', comment)}
                                                                    style={{
                                                                        background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                        color: 'white',
                                                                        padding: '3px 6px',
                                                                        borderRadius: '1px',
                                                                        border: comment.currentComments === '' ? '#427fed' : 'red'
                                                                    }}
                                                                >
                                                                    <i className="fa fa-comment"></i>
                                                                </span>
                                                            </span>
                                                            &nbsp;
                                                            <span style={{ padding: '2px' }}>
                                                                <span className="btn" title="Order Status" onClick={() => GetStatus(comment.purchaseOrderSkuID, "OrderStatus", comment)}
                                                                    style={{
                                                                        background: comment.orderStatus === '' ? '#A47BE0' : 'red',
                                                                        color: 'white',
                                                                        padding: '3px 6px',
                                                                        borderRadius: '1px',
                                                                        border: comment.orderStatus === '' ? '#A47BE0' : 'red'
                                                                    }}
                                                                >
                                                                    <i className="fa fa-shopping-cart"></i>
                                                                </span>

                                                            </span>
                                                            &nbsp;
                                                            {comment.exManualCloseComment === '' ? < span style={{ padding: '2px' }}>
                                                                <button className="btn btn-xs fc btn-Report" type="button" title="Report Create" onClick={() => handleExFactoryFormOpen(comment, 1, comment.taskDetailsID)}

                                                                // style={{
                                                                //     background: comment.factory === '' || comment.factory === undefined || comment.factory === null ? '#609B4E' : 'red',
                                                                //     color: 'white',
                                                                //     padding: '3px 6px',
                                                                //     borderRadius: '1px',
                                                                //     border: comment.factory === '' || comment.factory === undefined || comment.factory === null ? '#609B4E' : 'red'
                                                                // }}
                                                                >
                                                                    <i class="fa fa-external-link"></i>
                                                                </button>

                                                            </span> : ''}
                                                            {
                                                                Input.Finalview === 1 ?
                                                                    <Modal show={(Input.Finalview === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFinalInspecViewclose()} >
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>
                                                                                <b> Final Inspection List</b>
                                                                            </Modal.Title>

                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            {
                                                                                getnewheaderdata.length !== 0 && getnewheaderdata.map((item) => {
                                                                                    return (
                                                                                        <>
                                                                                            <h5><b>SKU ID &nbsp;: &nbsp; </b>{item.skuID}&nbsp; &nbsp; <b>PO/ID No &nbsp;: &nbsp;</b> {item.idno}&nbsp; &nbsp;  {getAddInspectionListParam.BuyerName === 'Focus International' ? <><b>Focus PO No &nbsp;:</b> {item.pono}&nbsp; </> : ''} &nbsp; <b>Style &nbsp;: &nbsp; </b>{item.styleName}&nbsp; &nbsp;<b> SKU &nbsp;: &nbsp; </b> {item.skuName}&nbsp; &nbsp; <b> PO Qty &nbsp;: &nbsp; </b> {item.poQty}&nbsp; &nbsp; <b>Ex-Fac &nbsp;: &nbsp; </b> {moment(item.exFacdate).format('DD/MM/YYYY')}</h5>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }

                                                                            <table className="table table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Report No. & Date</th>
                                                                                        <th>Report Type</th>
                                                                                        <th>Factory</th>
                                                                                        <th>Inspected Qty</th>
                                                                                        <th>Bal. for Inspection</th>
                                                                                        <th>Created By</th>
                                                                                        <th>Report Conclusion</th>
                                                                                        <th>Approval Result</th>
                                                                                        <th>Action</th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        getnewfinalReportlist.length !== 0 && getnewfinalReportlist.map((item, index) => (
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td >
                                                                                                        {item.reportNo}

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {item.reportType}

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {item.factory}

                                                                                                    </td>
                                                                                                    <td >
                                                                                                        {item.inspectedQty}
                                                                                                    </td>
                                                                                                    <td >
                                                                                                        {item.balanceQuantity}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {item.createdBy}

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {item.reportConclusion === null ? '-' : item.reportConclusion}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {item.approvalResult === null ? 'TBA' : item.approvalResult}
                                                                                                    </td>
                                                                                                    <td style={{ width: "13%" }}>
                                                                                                        <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                            onClick={() => handleFinalInspecViewDetails(item.confirmInspectionType, item.tnaFinalInspectionID, comment, 'View', 'ViewInspection', item)}>
                                                                                                            <i className="fa fa-eye "></i>
                                                                                                        </button>
                                                                                                        &nbsp;
                                                                                                        {
                                                                                                            getnewfinalReportlist.length === (index + 1) && (<button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                onClick={() => handleFinalInspecViewDetails(item.confirmInspectionType, item.tnaFinalInspectionID, comment, 'Edit', 'EditInspection', item)}>
                                                                                                                <i className="fa fa-edit"></i>
                                                                                                            </button>)}
                                                                                                        &nbsp;
                                                                                                        {getnewfinalReportlist.length === (index + 1) && <button type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                                                            onClick={() => handleDeletefinalinspection(item.confirmInspectionType, item.tnaFinalInspectionID, 'delete')}
                                                                                                        >
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </button>}
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </>
                                                                                        ))
                                                                                    }
                                                                                </tbody>

                                                                            </table>


                                                                        </Modal.Body>
                                                                    </Modal>
                                                                    : ''
                                                            }
                                                            &nbsp;
                                                            {
                                                                comment.isDraft === 1 ?
                                                                    <span style={{ padding: '2px' }}>
                                                                        <button className=" btn btn-xs btn-ReportView" title="Report View" type="button"

                                                                            // style={{
                                                                            //     background: comment.view || comment.view === undefined || comment.view === null === '' ? '#F6A230' : 'red',
                                                                            //     color: 'white',
                                                                            //     padding: '3px 6px',
                                                                            //     borderRadius: '1px',
                                                                            //     border: comment.view === '' || comment.view === undefined || comment.view === null ? '#F6A230' : 'red'
                                                                            // }}
                                                                            onClick={() => handleFinalInspecViewOpen(comment, 1, comment.tnaExFactoryId)}
                                                                        >
                                                                            <i class="fa fa-check-square"></i>
                                                                        </button>

                                                                    </span>
                                                                    : ''

                                                            }

                                                            &nbsp;
                                                            <label style={{ marginTop: '8px' }}>
                                                                <input type="checkbox" name="IsSelected"
                                                                    onChange={event => (handleOnSelect(event, 'IsSelected', comment.purchaseOrderSkuID,))}
                                                                    value={comment.isSelected ? true : false}
                                                                    checked={comment.isSelected ? true : false}
                                                                    className="clearOpacity textdesign" />
                                                                <span className="text"></span>
                                                            </label>
                                                            &nbsp;


                                                            {
                                                                <Modal show={getIsAddFinalInspection === true} size="sm" dragable backdrop="static" keyboard={false} onHide={() => handleCloseInspectionConfirmationPopup()}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>
                                                                            CONFIRM INSPECTION TYPE
                                                                        </Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="row">
                                                                            <div className="col-md-6 btn-action-center">
                                                                                <button className="btn btn-sm btn-success" onClick={() => CreateFinalInspection(1, comment)}>Internal</button>
                                                                            </div>
                                                                            <div className="col-md-6 btn-action-center">
                                                                                <button className="btn btn-sm btn-primary" onClick={() => CreateFinalInspection(2, comment)}>External</button>
                                                                            </div>
                                                                        </div>
                                                                    </Modal.Body>
                                                                </Modal>
                                                            }
                                                        </td>}
                                                    </tr>
                                                </Fragment>
                                            ))

                                        }


                                        {
                                            sizeqtypopup === true ?

                                                <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                                                    {/* <Modal.Header closeButton>
                                                      <Modal.Title>Size and Qty </Modal.Title>

                                                  </Modal.Header> */}

                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Size and Qty
                                                        </Modal.Title>


                                                    </Modal.Header>
                                                    <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                                                        <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                        <div className="col-lg-6">

                                                        </div>
                                                        <div className="col-lg-2">
                                                            <h5>Total : {getskuid.qrty}</h5>
                                                        </div>

                                                    </div>

                                                    <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                        <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "350px" }}>Size</th>
                                                                    <th style={{ width: "160px" }}>Qty</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    sizeqtylist.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.sizes}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {(item.qtys != null && item.qtys != undefined && item.qtys != "" ? item.qtys : '-')}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <button className="btn btn-xs btn-danger" onClick={() => setsizeqtypopup(false)}><i className="fa fa-times"></i>&nbsp;Close</button>
                                                    </Modal.Footer>

                                                </Modal> : ''
                                        }
                                        {
                                            InspectionAndApprovalpopup === true ?

                                                <Modal show={InspectionAndApprovalpopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => SetinspectionAndApprovalpopup(false)}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>{statusType == 1 ? 'Inspection Status' : 'Approval Status'}
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <div className="row" style={{ padding: "0px 15px 0px 20px" }}>
                                                        <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                    </div>

                                                    {statusType == 1 && <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                        {isInspectionStarted == 1 &&
                                                            <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ width: "160px" }}>Report No.</th>
                                                                        <th style={{ width: "160px" }}>Inspection Status</th>
                                                                        <th style={{ width: "160px" }}>Offer Qty</th>
                                                                        {/* <th style={{ width: "350px" }}>Created By</th> */}
                                                                        <th style={{ width: "700px" }}>Created On</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        approvalOrInspectionList.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.tnaFinalInspectionID}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* {item.inspectionStatus} */}
                                                                                                {<span className={item.inspectionStatus == "Draft" ? "warning" : (item.inspectionStatus == "Fail") ? "danger" : "success"}><b>{item.inspectionStatus}</b></span>}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.offeredQuantity}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* {item.createdBy} */}
                                                                                                {item.createdDate != null ? item.createdDate + ' on ' + item.createdBy : '-'}
                                                                                                {/* {item.createdDate != null ? item.createdBy + ' on ' + item.createdDate : '-'} */}
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>}
                                                        {isInspectionStarted == 0 &&
                                                            <h4 className="text-info"><b>Inspection not yet started</b></h4>
                                                        }
                                                    </Modal.Body>
                                                    }
                                                    {statusType == 2 && <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                        {isInspectionStarted == 1 &&
                                                            <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                <thead>
                                                                    <tr>
                                                                        {/* <th style={{ width: "350px" }}>Created Date</th> */}
                                                                        <th style={{ width: "160px" }}>Report No.</th>
                                                                        <th style={{ width: "160px" }}>Approval Status</th>
                                                                        <th style={{ width: "160px" }}>Quantity</th>
                                                                        <th style={{ width: "700px" }}>Created On</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        approvalOrInspectionList.map((item, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        {/* <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.createdDate != null ? item.createdDate : '-'}
                                                                                            </span>
                                                                                        </td> */}
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.finalInspectionID}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* {item.inspectionStatus} */}
                                                                                                {<span className={item.inspectionStatus == "Approved" ? "success" : (item.inspectionStatus == "Pending") ? "warning" : (item.inspectionStatus == "Rejected") ? "danger" : "primary"}><b>{item.inspectionStatus}</b></span>}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {item.offeredQuantity}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* {item.createdDate != null ? item.createdBy + ' on ' + item.createdDate : '-'} */}
                                                                                                {item.createdDate != null ? item.createdDate + ' on ' + item.createdBy : '-'}
                                                                                                {/* {item.createdDate != null ? item.createdDate : '-'} {item.createdBy != null ? item.createdBy : '-'} */}
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>

                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>}
                                                        {isInspectionStarted == 0 &&
                                                            <h4 className="text-info"><b>Approval not yet started</b></h4>
                                                        }
                                                    </Modal.Body>}
                                                    <Modal.Footer>
                                                        <button className="btn btn-sm btn-danger"
                                                            onClick={() => SetinspectionAndApprovalpopup(false)}><i className="fa fa-times"></i>&nbsp;Close</button>
                                                    </Modal.Footer>
                                                </Modal> : ""
                                        }
                                        {
                                            totalItems === 0 ?
                                                <tr>
                                                    <td colSpan="50" className='norecordfound'><span>No Records Found</span></td>
                                                </tr> : ''
                                        }
                                    </tbody>
                                    <table data-role="table" data-mode="columntoggle" id="table-to-xls"
                                        className="table table-striped table-bordered table-hover dataTable no-footer"
                                        style={{ width: "200%", display: 'none' }}>
                                        <thead>
                                            {
                                                <div style={{ display: 'inline-block' }}>
                                                    {
                                                        (stateDate[0].startDate !== null && stateDate[0].endDate !== null) && (stateExDate[0].startDate !== null && stateExDate[0].endDate !== null) ?
                                                            <>
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>&nbsp; AND &nbsp;

                                                                < label > <b>Ex-Fac:</b> {(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                            </>
                                                            :
                                                            (stateDate[0].startDate !== null && stateDate[0].endDate !== null) ?
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                                :

                                                                (stateExDate[0].startDate !== null && stateExDate[0].endDate !== null) ?
                                                                    < label > <b>Ex-Fac:</b> {(stateExDate[0].startDate === null ? '' : moment(stateExDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                        ((stateExDate[0].endDate === null || !moment(stateExDate[0].endDate).isValid()) ? '' :
                                                                            moment(stateExDate[0].endDate).format('DD/MM/YYYY'))}</label> : ''
                                                    }
                                                </div>
                                            }
                                            <tr>
                                                {getHideID.Po === true ? <th style={{ width: '5%' }}> PO Date </th> : false}
                                                {getHideID.skuID === true ? <th style={{ width: '2%' }}> SKU ID </th> : false}
                                                {getHideID.season === true ? <th style={{ width: '3%' }}> Season</th> : false}
                                                {getHideID.Quarter === true ? <th style={{ width: '1%' }}> Quarter</th> : false}
                                                {getHideID.brand === true ? <th style={{ width: '5%' }}> Brand</th> : false}
                                                {getHideID.customer === true ? <th style={{ width: '5%' }}> Customer</th> : false}
                                                {getHideID.supplier === true ? <th style={{ width: '4%' }}> Supplier </th> : false}
                                                {getHideID.idNo === true ? <th style={{ width: '6%' }}> ID No</th> : false}
                                                {getHideID.idNo === true ? <th style={{ width: '6%' }}> FOPONo</th> : false}
                                                {getHideID.category === true ? <th style={{ width: '7%' }}>Category</th> : false}
                                                {getHideID.styleName === true ? <th style={{ width: '6%' }}> Style Name</th> : false}
                                                {getHideID.styleNo === true ? <th style={{ width: '6%' }}> Style No</th> : false}
                                                {getHideID.skuName === true ? <th style={{ width: '5%' }}> SKU Name</th> : false}
                                                {getHideID.skuImg === true ? <th style={{ width: '4% ' }}> SKU Image</th> : false}
                                                {getHideID.size === true ? <th style={{ width: '8%' }}> Size</th> : false}
                                                {getHideID.orderQty === true ? <th style={{ width: '5%' }}> Order Qty</th> : false}
                                                {getHideID.inspectionStatus === true ? <th style={{ width: '5%' }}> Inspection Status</th> : false}
                                                {getHideID.approvalStatus === true ? <th style={{ width: '7%' }}> Approval Status</th> : false}
                                                {getHideID.exFac === true ? <th style={{ width: '4%' }}> Ex-Fac Date</th> : false}
                                                {getHideID.cubeEmp === true ? <th style={{ width: '5%' }}> Cube Follower</th> : false}
                                                {getHideID.buyerEmp === true ? <th style={{ width: '5%' }}> Buyer Follower</th> : false}
                                                {getHideID.orderStatus === true ? <th style={{ width: '5%' }}> Order Status</th> : false}
                                                {getHideID.stsCreated === true ? <th style={{ width: '2%' }}>  Status Created By</th> : false}
                                                {getHideID.currentStatus === true ? <th style={{ width: '5%' }}> Current Status Comment </th> : false}
                                                {getHideID.cmtCreated === true ? <th style={{ width: '2%' }}>  Comment Created By</th> : false}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                commentsData.map(comment => (
                                                    <tr key={comment.purchaseOrderSkuID}>
                                                        {getHideID.Po && <td className="" title={comment.poDate}>{
                                                            comment.poDate
                                                        }</td>}
                                                        {getHideID.skuID && <td className="cut_textCommentsreport" title={comment.purchaseOrderSkuID}>{
                                                            comment.purchaseOrderSkuID
                                                        }</td>}
                                                        {getHideID.season && <td className="cut_textCommentsreport" title={comment.seasonName}>{comment.seasonName}</td>}
                                                        {getHideID.Quarter && <td className="cut_textCommentsreport" title={comment.quarterName}>{
                                                            comment.quarterName
                                                        }</td>}
                                                        {getHideID.brand && <td className="cut_textCommentsreport" title={comment.brandName}>{
                                                            comment.brandName
                                                        }</td>}
                                                        {getHideID.customer && <td className="cut_textCommentsreport" title={comment.customerName}>{comment.customerName}</td>}
                                                        {getHideID.supplier && <td className="cut_textCommentsreport" title={comment.supplierName}>{comment.supplierName}</td>}
                                                        {getHideID.factory && <td className="cut_textCommentsreport" title={comment.factory}>{comment.factory}</td>}
                                                        {getHideID.idNo && <td className="cut_textCommentsreport" title={comment.idpono}>
                                                            {comment.idpono}
                                                        </td>}
                                                        {getHideID.idNo && <td className="cut_textCommentsreport" title={comment.foidpono}>
                                                            {comment.foidpono}
                                                        </td>}
                                                        {getHideID.category && <td className="cut_textCommentsreport" title={comment.categoryName}>{
                                                            comment.categoryName
                                                        }</td>}
                                                        {getHideID.styleName && <td className="cut_textCommentsreport" title={comment.styleName}>{comment.styleName}</td>}
                                                        {getHideID.styleNo && <td className="cut_textCommentsreport" title={comment.styleNo}>{comment.styleNo}</td>}

                                                        {getHideID.skuName && < td className="cut_textCommentsreport" title={comment.skuName}> {
                                                            comment.skuName
                                                        }</td>}
                                                        {getHideID.skuImg && <td> {
                                                            <div className="text-right">
                                                                <img src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.skuImagePath} width='3%' height={'3%'} />
                                                            </div>

                                                        }</td>}
                                                        {getHideID.size && <td className="cut_textCommentsreport" title={comment.size}>{
                                                            comment.size
                                                        }</td>}
                                                        {getHideID.orderQty &&
                                                            <td className="cut_textCommentsreport" title={comment.quantity}>{
                                                                comment.quantity
                                                            }</td>}
                                                        {getHideID.inspectionStatus &&
                                                            <td className="cut_textCommentsreport" title={comment.inspectionStatus}>{
                                                                comment.inspectionStatus
                                                            }</td>}
                                                        {getHideID.approvalStatus &&
                                                            <td className="cut_textCommentsreport" title={comment.approvalStatus}>{
                                                                comment.approvalStatus
                                                            }</td>}
                                                        {getHideID.exFac && <td className="cut_textCommentsreport" title={comment.exFacDate}>{
                                                            comment.exFacDate
                                                        }</td>}
                                                        {getHideID.cubeEmp && <td className="cut_textCommentsreport" title={comment.cubeEmployee}>{
                                                            comment.cubeEmployee
                                                        }</td>}
                                                        {getHideID.buyerEmp && <td className="cut_textCommentsreport" title={comment.buyerEmployee}>{
                                                            comment.buyerEmployee
                                                        }</td>}

                                                        {getHideID.orderStatus && <td className={comment.orderStatus == '1' ? 'cut_textCommentsreport info' : comment.orderStatus == '2' ? 'cut_textCommentsreport warning' :
                                                            comment.orderStatus == '3' ? 'cut_textCommentsreport success' : comment.orderStatus == '4' ? 'cut_textCommentsreport danger' : comment.orderStatus == '5' ? 'cut_textCommentsreport danger' :
                                                                'cut_textCommentsreport info'} title={comment.orderStatus == 1 ? 'Yet to Start' : comment.orderStatus == 2 ? 'In-Progress' :
                                                                    comment.orderStatus == 3 ? 'Completed' : comment.orderStatus == 4 ? 'Hold' : comment.orderStatus == 5 ? 'Cancel' :
                                                                        'Yet to Start'}>
                                                            {comment.orderStatus == '1' ? 'Yet to Start' : comment.orderStatus == '2' ? 'In-Progress' :
                                                                comment.orderStatus == '3' ? 'Completed' : comment.orderStatus == '4' ? 'Hold' : comment.orderStatus == '5' ? 'Cancel' :
                                                                    'Yet to Start'
                                                            }
                                                        </td>}

                                                        {getHideID.stsCreated && <td className="cut_textCommentsreport" title={comment.statusCreatedBy}>{
                                                            comment.statusCreatedBy === "" ? "-" : comment.statusCreatedBy
                                                        }</td>}
                                                        {getHideID.currentStatus && <td className="text-center cut_textCommentsreport" title={comment.currentComments}>{
                                                            comment.currentComments === "" ? "-" : comment.currentComments
                                                        }</td>}
                                                        {getHideID.cmtCreated && <td className="cut_textCommentsreport" title={comment.commentsCreatedBy}>{
                                                            comment.commentsCreatedBy === "" ? "-" : comment.commentsCreatedBy
                                                        }</td>}

                                                    </tr>
                                                ))

                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="22" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }

                                        </tbody>
                                    </table>
                                </table>
                            </div>
                            {Input.IsExFactoryForm === 1 &&
                                <Modal dialogClassName="CreatePopup" show={Input.IsExFactoryForm === 1} size="lg" dragable backdrop="static" keyboard={false}
                                    onHide={() => handleCloseExFactoryFormPopup()}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                            ADD {Input.strTaskName} &nbsp;

                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                            <li className="active">
                                                <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForFinalInspection('AddInspection')}>
                                                    <h5>Add Inspection </h5>
                                                </a>
                                            </li>
                                            <li>
                                                <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForFinalInspection('ReInspection')}>
                                                    <h5>Re Inspection </h5>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            {getFinalInspectionPageView === "AddInspection" ? <><AddInspection skuSelectData={getskusingleID} props={props} finalInspectionList={getAddInspectionListParam} commonSubmitApprovalParams={commonSubmitApprovalParams} FinalInspectionCallback={FinalInspectionCallback} /></> : ''}
                                            {getFinalInspectionPageView === "ReInspection" ? <><ReInspection props={props} Reinspectionparams={getAddInspectionListParam}
                                                FinalInspectionCallback={FinalInspectionCallback} /></> : ''}
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {
                                            getfinalinspectionMulti.length === 0 ? '' :
                                                <Button variant="success" onClick={() => AddExFactoryForm()}>
                                                    Save
                                                </Button>
                                        }
                                    </Modal.Footer>
                                </Modal>
                            }
                            {
                                Commentpopup === true ?
                                    <Modal show={Commentpopup === true} dialogAs={Drag} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose('reportcmt', Ecomment)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Comments
                                            </Modal.Title>
                                        </Modal.Header>
                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Comments<span className="text-danger">*</span></th>
                                                    <th>Supplier Factory<span className="text-danger">*</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td >
                                                        <textarea
                                                            rows="4"
                                                            placeholder="Enter comments here..."
                                                            autoFocus onFocus={onFocus} autoComplete="off"
                                                            maxLength="500" name="EnterComment"
                                                            id={"EnterComment"}
                                                            value={Ecomment}
                                                            onChange={event => handleChangeComment(event, "reportComment")}
                                                            style={{ width: "100%", border: Submitted && (Ecomment === undefined || Ecomment === '') ? '1px solid red' : '' }}>
                                                        </textarea>
                                                    </td>
                                                    <td colSpan='2'>
                                                        <Reactselect
                                                            options={getCmtExFactoryList}
                                                            value={selectedCommentExfactory}
                                                            onChange={handleCmtEXFactoryChanges}
                                                            styles={Submitted && selectedCommentExfactory === undefined || selectedCommentExfactory === null ? styles : ''}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}>
                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                            <tbody>
                                                                <tr>
                                                                    <div className="col-sm-12 col-lg-12">
                                                                        <div style={{ paddingLeft: 0 }}>
                                                                            <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Image Only)</span></span>
                                                                        </div>
                                                                    </div>
                                                                    < div className="col-sm-12 col-lg-12">
                                                                        <div className="row">
                                                                            <div className="col-sm-8 col-lg-8">
                                                                                <div className='form-group'>
                                                                                    <tr>
                                                                                        <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png" ></input></td>
                                                                                    </tr>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12 col-sm-12">
                                                                        <div className="row no-margin">
                                                                            {
                                                                                getFiles && getFiles.length !== 0 ?
                                                                                    getFiles.filter(x => x.IsDeleted !== 1 && x.reportManualFileId === 0).map((files, fileindex) => (
                                                                                        // manitem.Files.filter(x => x.IsDeleted === 0) &&
                                                                                        <> {
                                                                                            <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                <tr title={files.fileName}>
                                                                                                    <td className="cut_textInTNA">
                                                                                                        <span style={{ marginRight: '7px' }}>
                                                                                                            {
                                                                                                                files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                        <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                        files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                            files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                            <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                            }
                                                                                                        </span>  {files.fileName.substr(33)}
                                                                                                    </td>

                                                                                                    <td className="button_style_uploda">




                                                                                                        {
                                                                                                            files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/Report/' + files.fileName} download><i class="fa fa-download"></i> </a> :


                                                                                                                <i className="btn btn-info fa fa-eye" title="View Image"

                                                                                                                    onClick={
                                                                                                                        event => ViewFile(event, files.fileName)
                                                                                                                    }></i>
                                                                                                        }

                                                                                                        &nbsp;

                                                                                                        <p title='Delete File' onClick={(e) => RemoveImages(fileindex, files, files.reportManualFileId)}
                                                                                                            className="btn btn-danger btn-xs delete">
                                                                                                            <i className="fa fa-trash-o"></i>

                                                                                                        </p>
                                                                                                    </td>
                                                                                                </tr>



                                                                                                {

                                                                                                    <div> {
                                                                                                        isOpenimg && (

                                                                                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/Report/' + getImagename}
                                                                                                                alt="bg image"
                                                                                                                onCloseRequest={
                                                                                                                    () => setIsOpenimg(false)
                                                                                                                } />
                                                                                                        )
                                                                                                    } </div>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                        </>
                                                                                    ))
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        <Modal.Body style={{ overflow: "auto", height: "650px" }}>

                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                <thead>
                                                    <tr>
                                                        <th>Image</th>
                                                        <th>Comments</th>
                                                        <th>Supplier Factory</th>
                                                        <th style={{ width: '16%' }}>Date</th>
                                                        <th style={{ width: '17%' }}>Created By</th>
                                                        <th style={{ width: '5%' }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        reportcomments.map((Commentitem, subChildIndex2) => {
                                                            return (
                                                                Commentitem.comments !== '' ?
                                                                    <tr>
                                                                        <td>
                                                                            {

                                                                                (Commentitem.fileName && Commentitem.fileName !== '') && Commentitem.length !== 0 ?
                                                                                    <div>
                                                                                        <tr title={Commentitem.fileName}>
                                                                                            <td >
                                                                                                {
                                                                                                    Commentitem.fileName !== null && Commentitem.fileName !== '' ?
                                                                                                        < SimpleReactLightbox >
                                                                                                            <SRLWrapper >
                                                                                                                {
                                                                                                                    getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).map((x, index) => {
                                                                                                                        return (
                                                                                                                            < img className="width_manual" title={x.flFileName.substr(33)} style={{ display: index === 0 ? '' : 'none' }}
                                                                                                                                src={x.flFileName === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images/Report/' + x.flFileName}
                                                                                                                                alt={x.flFileName.substr(33)} />
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </SRLWrapper>
                                                                                                        </SimpleReactLightbox>

                                                                                                        : 'No file selected'


                                                                                                }

                                                                                            </td>
                                                                                            <span className={getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length === 2 ? "label label-success" :
                                                                                                getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length === 3 ? "label label-danger" :
                                                                                                    getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length === 4 ? "label label-info" :
                                                                                                        getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length === 5 ? "label label-warning" :
                                                                                                            getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length === 6 ? "label label-primary" :
                                                                                                                getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length > 6 ? "label label-default" : ''

                                                                                            } style={{ display: 'inline-block' }}>{getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length > 1 ?
                                                                                                getFiles.filter(x => x.flReportManualFileId !== 0 && x.flCommentsID === Commentitem.commentsID).length : ''}</span>
                                                                                        </tr>
                                                                                    </div>
                                                                                    : 'No file selected'

                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {Commentitem.flCommentsDetail.length === 0 ? 'No Record Found' : Commentitem.flCommentsDetail}
                                                                        </td>
                                                                        <td>
                                                                            {Commentitem.factoryName === null ? 'TBD' : Commentitem.factoryName}
                                                                        </td>
                                                                        {/* <td>
                                        {Commentitem.isStatus === 2 ? "Major" : Commentitem.isStatus === 1 ? 'Critical' : "Minor"}
                                      </td> */}
                                                                        <td>
                                                                            {Commentitem.commentsDate.length === 0 ? 'No Record Found' : moment(Commentitem.commentsDate).format('DD/MM/YYYY')}
                                                                        </td>
                                                                        <td>
                                                                            {Commentitem.createdBy.length === 0 ? 'No Record Found' : Commentitem.createdBy}
                                                                        </td>
                                                                        <td>
                                                                            <span style={{ padding: '2px', display: Commentitem.PurOrdSkuComments === '' ? "none" : "", float: "right" }}>
                                                                                <span>
                                                                                    <span className="btn btn-danger" title="Delete Comment"
                                                                                        onClick={() => RemoveComments(Commentitem)}
                                                                                    >
                                                                                        <i className="fa fa-trash-o"></i>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    : ''
                                                            );
                                                        })

                                                    }
                                                    {
                                                        reportcomments.length === 0 ?
                                                            <tr>
                                                                <td colSpan="6" className='norecordfound'><span>No Records Found</span></td>
                                                            </tr> : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="success" onClick={(event) => handleSaveComment(event, Ecomment)}>
                                                Save
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    : ''
                            }
                            <Modal show={showmodal === true} backdrop="static" onHide={() => StatusClose('ordStatus')} size="md" >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        Order Status
                                    </Modal.Title>
                                </Modal.Header>
                                <div className="row" style={{ marginBottom: "5px", marginLeft: "0px" }}>


                                    <div className="col-lg-11" style={{ width: "97%", marginTop: "5px" }}>

                                        <Reactselect
                                            options={orderStatus}
                                            value={selectedOption}
                                            onChange={handleChanges}
                                            styles={Submitted && selectedOption.value === 0 ? styles : ''}
                                        />

                                    </div>
                                </div>
                                <Modal.Body style={{ overflow: "auto", height: "650px" }}>
                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                        <thead>
                                            <tr>
                                                <th>Order Status</th>
                                                <th style={{ width: '16%' }}>Date</th>
                                                <th style={{ width: '17%' }}>Created By</th>
                                                <th style={{ width: '5%' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                getorderStatus.map((Commentitem, subChildIndex2) => {
                                                    return (
                                                        Commentitem.status !== '' ?
                                                            <tr>
                                                                <td>
                                                                    {Commentitem.status}
                                                                </td>
                                                                <td>
                                                                    {moment(Commentitem.statusModifiedDate).format('DD/MM/YYYY')}
                                                                </td>
                                                                <td>
                                                                    {Commentitem.createdBy}
                                                                </td>
                                                                <td>
                                                                    <span style={{ padding: '2px', display: Commentitem.PurOrdSkuComments === '' ? "none" : "", float: "right" }}>
                                                                        <span>
                                                                            <span className="btn btn-danger" title="Delete Order Status"
                                                                                onClick={() => RemoveOrderStatus(Commentitem)}
                                                                            >
                                                                                <i className="fa fa-trash-o"></i>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            : ''
                                                    );
                                                })
                                            }
                                            {
                                                getorderStatus.length === 0 ?
                                                    <tr>
                                                        <td colSpan="6" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="success" onClick={(event) => handleSaveStatus(event)}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>


                    </div>

                </div>

                <PageCount onPageCount={
                    value => {
                        setITEMS_PER_PAGE(value);
                        setCurrentPage(1);
                    }
                } />
                <Pagination total={totalItems}
                    itemsPerPage={
                        parseInt(ITEMS_PER_PAGE)
                    }
                    currentPage={currentPage}
                    onPageChange={
                        page => setCurrentPage(page)
                    } />
                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={delCallback}
                        showpopup={true}
                        pageActionId={FLReportSkuComments}
                        Msg={"Comments deleted successfully"} /> : null
                }
                {
                    getIDSingleTask.showPopupDelete ? <SweetAlertPopup data={
                        getIDSingleTask.Params
                    }
                        deleteCallback={delsCallback}
                        showpopup={true}
                        pageActionId={FinalinspectionID}
                        Msg={"Final Inspection deleted successfully"} /> : null
                }
                {
                    getOrdStatusID.showPopupDelete ? <SweetAlertPopup data={
                        getOrdStatusID.Params
                    }
                        deleteCallback={delStatusCallback}
                        showpopup={true}
                        pageActionId={FLReportSkuOrderStatus}
                        Msg={"Order Status deleted successfully"} /> : null
                }
            </div>
            {loader}


        </div >
    )
}

export default FinalInspectionList
