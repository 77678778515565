import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { retrieveTestStandard } from '../../../../../actions/teststandard';
import Reactselect from 'react-select';
import { event } from 'jquery';
import TNAService from '../../../../../services/TNA/TNAService';
import useFullPageLoader from '../../../../hooks/useFullPageLoader';
import { Modal, Button } from "react-bootstrap";
import Nodify from "../../../../Common/ReactNotification";

const FabricTest = ({ props, FabricDetials, labdata, labremarkdata, Testingdetail, FabriclabDetails, FabricUOMDetails, Detailwashchildcall, Detailwashinfosubcall }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(''); // Initialize input value with "hi"
  const [getTestNames, setTestNames] = useState([]);
  const [gettestselect, settestselect] = useState([]);
  const [getFabricHeaderInfo, setFabricHeaderInfo] = useState([]);
  const [getYarnDetailInfo, setYarnDetailInfo] = useState([]);
  const [getStandardOptions, setStandardOptions] = useState([]);
  const [getGradeValue, setGradeValue] = useState([]);
  const reducerState = useSelector((state) => state);
  const [getGSMInfo, setGSMInfo] = useState([]);
  // Define a state variable to hold the initial ResultName
  const [initialResultName, setInitialResultName] = useState('');
  const [getOrder, setOrder] = useState([]);
  const [getUnitInfo, setUnitInfo] = useState([]);
  const [getStablityInfo, setStablityInfo] = useState([]);
  const [getSpiralityInfo, setSpiralityInfo] = useState([]);

  const [getcopyvalue, setcopyvalue] = useState([...getSpiralityInfo]);
  const [getPillingInfo, setPillingInfo] = useState([]);
  const [getPHInfo, setPHInfo] = useState([]);
  const [getRubbingInfo, setRubbingInfo] = useState([]);
  const [getWashingInfo, setWashingInfo] = useState([]);
  const [getWaterInfo, setWaterInfo] = useState([]);
  const [getAlkalineInfo, setAlkalineInfo] = useState([]);
  const [getAcidInfo, setAcidInfo] = useState([]);
  const [show, setShow] = useState(false);
  const [getSpiralDetailedInformaion, setSpiralDetailedInformaion] = useState([]);
  const [getDetailedInformaion, setDetailedInformaion] = useState([]);
  const comments = reducerState.TestStandardReducer.TestStandardList;
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [submittedlabcomments, setSubmittedlabcomments] = useState(false);
  const [submittedlabremark, setSubmittedlabremark] = useState(false);
  const [submittedfabric, setSubmittedfabric] = useState(false);
  const [getLabComments, setLabComments] = useState([
    {
      value: 0,
      label: "",
      Remarks: ""
    }
  ]);
  const inpsectionId = JSON.parse(localStorage.getItem("TestingInspectionID"));
  let labResult = [
    {
      value: 1,
      label: <><span style={{ color: "green" }}><b>Statisfied</b></span></>,


    },
    {
      value: 2,
      label: <><span style={{ color: "Red" }}><b>Not Statisfy</b></span></>,


    }
  ]


  let IsResult = [
    {
      value: 1,
      label: <><span style={{ color: "green" }}><b>Pass</b></span></>,


    },
    {
      value: 2,
      label: <><span style={{ color: "Red" }}><b>Fail</b></span></>,


    }
  ]

  const [checkboxStates, setCheckboxStates] = useState({
    IsShowUnit: true,
    IsShowStablity: true,
    IsShowSpirality: true,
    IsShowPill: true,
    IsShowWater: true,
    IsShowPH: true,
    IsShowRub: true,
    IsShowWash: true,
    IsShowAlkal: true,
    IsShowAcid: true,
  });

  const UOMValues = [
    { value: 1, label: "CMS" },
    { value: 2, label: "Inches" },
    { value: 3, label: "Meter" },
    { value: 4, label: "MM" }
  ];

  const [getUOMLabel, setUOMLabel] = useState(
    [
      {
        value: 1,
        label: "CMS",
        TestName: "",
        TNATestingInspectionID: inpsectionId === null ? props.location.state.params.TestingInspectionID : inpsectionId
      }
    ]
  );


  const [getSpiUOMLabel, setSpiUOMLabel] = useState(
    [
      {
        value: 1,
        label: "CMS",
        TestName: "",
        TNATestingInspectionID: inpsectionId === null ? props.location.state.params.TestingInspectionID : inpsectionId
      }
    ]
  );
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),
  }
  const textareaStyles = {
    border: '1px solid red',  // Red border for highlighting 
  };

  const inputstyles = {
    border: '1px solid red',
  };
  useEffect(() => {


    setSubmittedlabcomments(labdata)
  }, [labdata === true])
  useEffect(() => {

    setSubmittedlabremark(labremarkdata)
  }, [labremarkdata === true])

  useEffect(() => {

    setSubmittedfabric(Testingdetail)
  }, [Testingdetail === true])

  useEffect(() => {

    let TnaPantoneinfoList = [];
    let StablityUOMValue = [];
    let SpiralityUOMValue = [];
    let TestNameList = []
    let GradeList = [];
    let UnitInfo = []
    let StablityInfo = []
    let SpiralityInfo = []
    let PillingInfo = []
    let WaterInfo = [];
    let PHInfo = []
    let RubbingInfo = []
    let WashingInfo = []
    let AlkalineInfo = []
    let params = {
      Operation: 1, SkuIDList: props.location.state.params.SkuID, SkuId: 0, StyleId: 0,
      BuyerId: 0, BrandId: 0, SeasonId: 0, SupplierId: 0,
      TaskId: 0, MainId: 0, IsProduction: props.location.state.params.Isproduction
    };
    let AcidInfo = [];

    (async () => {
      if (props.location.state.params.TaskStatus === 1) {

        if (props.location.state.params.multiselecteddatas.length > 0) {
          let StyleIDs = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).map((data => { return data.styleID })).join(",");
          await TNAService.LoadTestInfoDetails(inpsectionId, props.location.state.params.TnaID, 3).then((res) => {

            if (res.data.item2.length > 0) {
              if (res.data.item2.length > 0) {
                setFabricHeaderInfo(res.data.item1)
                setOrder(res.data.item3)
                if (res.data.item2) {
                  res.data.item2.filter(xy => xy.testName === "MASS PER UNIT AREA (GSM)").map((x, indexy) => {
                    UnitInfo.push({
                      id: x.id,
                      testID: x?.testID,
                      testTypeID: x?.testTypeID,
                      testName: x?.testName,
                      group: x?.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: x.resultID,
                      ResultName: x.resultName,
                      gradeID: x.gradeID,
                      gradeName: x.gradeName,
                      ChangeIn: x.changeIn,
                      rowindex: x.rowIndex,
                      Observation: x.observation,
                      beforeWashChangeIn: x.beforeWashChangeIn,
                      beforeWash: x.beforeWash,
                      afterWash: x.afterWash,
                      afterWashChangeIn: x.afterWashChangeIn,
                      foundGSM: x.foundGSM,
                      AWDeviation: x.awDeviation,
                      BWDeviation: x.bwDeviation,
                      ReqGSM: x.reqGSM,
                      isChecked: x.isChecked
                    })
                  })

                  res.data.item2.filter(xy => xy.testName === "DIMENSIONAL STABILTY TO WASHING").map((x, indexy) => {
                    StablityInfo.push({
                      id: x.id,
                      testID: x.testID,
                      testTypeID: x.testTypeID,
                      testName: x.testName,
                      group: x.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: x.resultID,
                      ResultName: x.resultName,
                      gradeID: x.gradeID,
                      gradeName: x.gradeName,
                      ChangeIn: x.changeIn,
                      rowindex: x.rowIndex,
                      found: x.found,
                      beforeWashChangeIn: x.beforeWashChangeIn,
                      beforeWash: x.beforeWash,
                      afterWash: x.afterWash,
                      afterWashChangeIn: x.afterWashChangeIn,
                      foundGSM: x.foundGSM,
                      ReqGSM: "",
                      AWDeviation: x.awDeviation,
                      BWDeviation: x.bwDeviation,
                      isChecked: x.isChecked
                    })
                  })


                  res.data.item2.filter(xy => xy.testName === "SPIRALITY").map((x, indexy) => {
                    SpiralityInfo.push({
                      id: x.id,
                      testID: x.testID,
                      testTypeID: x.testTypeID,
                      testName: x.testName,
                      group: x.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: x.resultID,
                      ResultName: x.resultName,
                      gradeID: x.gradeID,
                      gradeName: x.gradeName,
                      ChangeIn: x.changeIn,
                      rowindex: x.rowIndex,
                      found: x.found,
                      beforeWashChangeIn: x.beforeWashChangeIn,
                      beforeWash: x.beforeWash,
                      afterWash: x.afterWash,
                      afterWashChangeIn: x.afterWashChangeIn,
                      foundGSM: x.foundGSM,
                      ReqGSM: "",
                      AWDeviation: x.awDeviation,
                      BWDeviation: x.bwDeviation,
                      isChecked: x.isChecked
                    })
                  })


                  res.data.item2.filter(xy => xy.testName === "PILLING").map((x, indexy) => {
                    PillingInfo.push({
                      id: x.id,
                      testID: x.testID,
                      testTypeID: x.testTypeID,
                      testName: x.testName,
                      group: x.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: x.resultID,
                      ResultName: x.resultName,
                      gradeID: x.gradeID,
                      gradeName: x.gradeName,
                      ChangeIn: x.changeIn,
                      rowindex: x.rowIndex,
                      found: x.found,
                      beforeWashChangeIn: x.beforeWashChangeIn,
                      beforeWash: x.beforeWash,
                      afterWash: x.afterWash,
                      afterWashChangeIn: x.afterWashChangeIn,
                      foundGSM: x.foundGSM,
                      ReqGSM: "",
                      AWDeviation: x.awDeviation,
                      BWDeviation: x.bwDeviation,
                      isChecked: x.isChecked
                    })
                  })


                  res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO WATER").map((x, indexy) => {
                    WaterInfo.push({
                      id: x.id,
                      testID: x.testID,
                      testTypeID: x.testTypeID,
                      testName: x.testName,
                      group: x.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: x.resultID,
                      ResultName: x.resultName,
                      gradeID: x.gradeID,
                      gradeName: x.gradeName,
                      ChangeIn: x.changeIn,
                      rowindex: x.rowIndex,
                      found: x.found,
                      beforeWashChangeIn: x.beforeWashChangeIn,
                      beforeWash: x.beforeWash,
                      afterWash: x.afterWash,
                      afterWashChangeIn: x.afterWashChangeIn,
                      foundGSM: x.foundGSM,
                      ReqGSM: "",
                      AWDeviation: x.awDeviation,
                      BWDeviation: x.bwDeviation,
                      isChecked: x.isChecked
                    })
                  })



                  res.data.item2.filter(xy => xy.testName === "PH").map((x, indexy) => {
                    PHInfo.push({
                      id: x.id,
                      testID: x.testID,
                      testTypeID: x.testTypeID,
                      testName: x.testName,
                      group: x.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: x.resultID,
                      ResultName: x.resultName,
                      gradeID: x.gradeID,
                      gradeName: x.gradeName,
                      ChangeIn: x.changeIn,
                      rowindex: x.rowIndex,
                      found: x.found,
                      beforeWashChangeIn: x.beforeWashChangeIn,
                      beforeWash: x.beforeWash,
                      afterWash: x.afterWash,
                      afterWashChangeIn: x.afterWashChangeIn,
                      foundGSM: x.foundGSM,
                      ReqGSM: "",
                      AWDeviation: x.awDeviation,
                      BWDeviation: x.bwDeviation,
                      isChecked: x.isChecked
                    })
                  })

                  res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO RUBBING").map((x, indexy) => {
                    RubbingInfo.push({
                      id: x.id,
                      testID: x.testID,
                      testTypeID: x.testTypeID,
                      testName: x.testName,
                      group: x.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: x.resultID,
                      ResultName: x.resultName,
                      gradeID: x.gradeID,
                      gradeName: x.gradeName,
                      ChangeIn: x.changeIn,
                      rowindex: x.rowIndex,
                      found: x.found,
                      beforeWashChangeIn: x.beforeWashChangeIn,
                      beforeWash: x.beforeWash,
                      afterWash: x.afterWash,
                      afterWashChangeIn: x.afterWashChangeIn,
                      foundGSM: x.foundGSM,
                      ReqGSM: "",
                      AWDeviation: x.awDeviation,
                      BWDeviation: x.bwDeviation,
                      isChecked: x.isChecked
                    })
                  })

                  res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO WASHING").map((x, indexy) => {
                    WashingInfo.push({
                      id: x.id,
                      testID: x.testID,
                      testTypeID: x.testTypeID,
                      testName: x.testName,
                      group: x.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: x.resultID,
                      ResultName: x.resultName,
                      gradeID: x.gradeID,
                      gradeName: x.gradeName,
                      ChangeIn: x.changeIn,
                      rowindex: x.rowIndex,
                      found: x.found,
                      beforeWashChangeIn: x.beforeWashChangeIn,
                      beforeWash: x.beforeWash,
                      afterWash: x.afterWash,
                      afterWashChangeIn: x.afterWashChangeIn,
                      foundGSM: x.foundGSM,
                      ReqGSM: "",
                      AWDeviation: x.awDeviation,
                      BWDeviation: x.bwDeviation,
                      isChecked: x.isChecked
                    })
                  })
                  res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ALKALINE").map((x, indexy) => {
                    AlkalineInfo.push({
                      id: x.id,
                      testID: x.testID,
                      testTypeID: x.testTypeID,
                      testName: x.testName,
                      group: x.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: x.resultID,
                      ResultName: x.resultName,
                      gradeID: x.gradeID,
                      gradeName: x.gradeName,
                      ChangeIn: x.changeIn,
                      rowindex: x.rowIndex,
                      found: x.found,
                      beforeWashChangeIn: x.beforeWashChangeIn,
                      beforeWash: x.beforeWash,
                      afterWash: x.afterWash,
                      afterWashChangeIn: x.afterWashChangeIn,
                      foundGSM: x.foundGSM,
                      ReqGSM: "",
                      AWDeviation: x.awDeviation,
                      BWDeviation: x.bwDeviation,
                      isChecked: x.isChecked
                    })
                  })

                  res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ACID").map((x, indexy) => {
                    AcidInfo.push({
                      id: x.id,
                      testID: x.testID,
                      testTypeID: x.testTypeID,
                      testName: x.testName,
                      group: x.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: x.resultID,
                      ResultName: x.resultName,
                      gradeID: x.gradeID,
                      gradeName: x.gradeName,
                      ChangeIn: x.changeIn,
                      rowindex: x.rowIndex,
                      found: x.found,
                      beforeWashChangeIn: x.beforeWashChangeIn,
                      beforeWash: x.beforeWash,
                      afterWash: x.afterWash,
                      afterWashChangeIn: x.afterWashChangeIn,
                      foundGSM: x.foundGSM,
                      ReqGSM: "",
                      AWDeviation: x.awDeviation,
                      BWDeviation: x.bwDeviation,
                      isChecked: x.isChecked
                    })
                  })

                  setUnitInfo(UnitInfo)
                  setStablityInfo(StablityInfo)
                  setSpiralityInfo(SpiralityInfo)
                  setPillingInfo(PillingInfo)
                  setWaterInfo(WaterInfo)
                  setPHInfo(PHInfo)
                  setRubbingInfo(RubbingInfo)
                  setWashingInfo(WashingInfo)
                  setAlkalineInfo(AlkalineInfo)
                  setAcidInfo(AcidInfo)
                  handleChangeNewchange(UnitInfo, StablityInfo, SpiralityInfo, PillingInfo, WaterInfo, RubbingInfo, PHInfo, WashingInfo, AlkalineInfo, AcidInfo);
                  console.log(3)
                  LoadSpiralDetailwashinformation(SpiralityInfo, "Response2");
                  LoadDetailwashinformation(StablityInfo, "Response2");

                }
              }
            } else {
              TNAService.GetTestInfoDetails(props.location.state.params.buyerID, props.location.state.params.brandID, 1, StyleIDs, inpsectionId).then((response) => {
                // // Function to filter and get the latest entries

                const getLatestEntriesWithChildLatestDate = () => {
                  // Create an object to store the latest entries by testID and latestDate
                  const latestEntries = {};

                  // Iterate through the response data to store entries and their indexes
                  const entriesWithIndexes = response.data.map((item, index) => ({ item, index }));

                  entriesWithIndexes.forEach(({ item, index }) => {
                    const { testID, latestDate, childLatestDate } = item;
                    const latestDateStr = new Date(latestDate).toJSON();
                    const childLatestDateStr = new Date(childLatestDate).toJSON();

                    if (!latestEntries[testID] || latestDateStr >= latestEntries[testID].latestDate) {
                      // If it's the latest date or the same date, update the latest entry
                      if (!latestEntries[testID] || latestDateStr > latestEntries[testID].latestDate) {
                        latestEntries[testID] = { latestDate: latestDateStr, entries: [] };
                      }

                      // Check if childLatestDate is greater or equal to the current childLatestDate
                      if (!latestEntries[testID].childLatestDate || childLatestDateStr >= latestEntries[testID].childLatestDate) {
                        // If it's the child latest date or the same date, update the child latest entry
                        if (!latestEntries[testID].childLatestDate || childLatestDateStr > latestEntries[testID].childLatestDate) {
                          latestEntries[testID].childLatestDate = childLatestDateStr;
                          latestEntries[testID].entries = [];
                        }
                        latestEntries[testID].entries.push({ item, index });
                      }
                    }
                  });

                  // Convert the object to an array of the latest entries while maintaining the original order
                  const latestEntriesArray = Object.values(latestEntries).reduce((acc, entry) => {
                    return [...acc, ...entry.entries];
                  }, []);

                  // Sort the latest entries based on their original order in the response.data array
                  latestEntriesArray.sort((a, b) => a.index - b.index);

                  return latestEntriesArray.map(entry => entry.item);
                };

                const latestEntriesWithChildLatestDate = getLatestEntriesWithChildLatestDate();

                setFabricHeaderInfo(latestEntriesWithChildLatestDate)

                TNAService.GetGSMCountFabric(inpsectionId).then((res) => {
                  if (res.data) {
                    res.data.map((y, i) => {

                      latestEntriesWithChildLatestDate.filter(xy => xy.testName === "MASS PER UNIT AREA (GSM)").map((x, indexy) => {
                        UnitInfo.push({
                          id: x.id,
                          testID: x?.testID,
                          testTypeID: x.testTypeID,
                          testName: x.testName,
                          group: x.group,
                          fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                          fabricDetails: x.fabricDetails,
                          standard: x.standard,
                          standardName: x.standardName,
                          genderID: x.genderID,
                          colorDepthID: x.colorDepthID,
                          colorDepth: x.colorDepth,
                          minResult: x.minResult,
                          maxResult: x.maxResult,
                          field: x.field == undefined ? "" : x.field.toString(),
                          fieldID: x.field == undefined ? "" : x.field.toString(),
                          fieldName: x.fieldName,
                          resultType: x.resultType,
                          resultTypeID: x.resultType,
                          optionName: x.optionName == undefined ? "" : x.optionName,
                          gradeValue: x.gradeValue,
                          gradeOptionID: x.gradeOptionID,
                          ResultID: 0,
                          ResultName: "",
                          gradeID: 0,
                          gradeName: '',
                          ChangeIn: 0,
                          rowindex: x.rowIndex,
                          found: "",
                          beforeWashChangeIn: 0,
                          beforeWash: 0,
                          afterWash: 0,
                          afterWashChangeIn: 0,
                          foundGSM: "",
                          ReqGSM: y.gsMorCounts,
                          Observation: "",
                          isChecked: 1
                        })
                      })
                    })
                  }

                  UnitInfo.map((item, index) => {
                    item.Observation = "Fabric Bit" + " " + (parseInt(index) + 1 + "")
                  })
                  setUnitInfo(UnitInfo)
                })
                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "DIMENSIONAL STABILTY TO WASHING").map((x, indexy) => {
                  StablityInfo.push({
                    id: x.id,
                    testID: x?.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    ChangeIn: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    beforeWash: '',
                    afterWash: '',
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    ReqGSM: "",
                    isChecked: 1
                  })
                })
                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "SPIRALITY").map((x, indexy) => {
                  SpiralityInfo.push({
                    id: x.id,
                    testID: x?.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    ChangeIn: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    beforeWash: '',
                    afterWash: '',
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    ReqGSM: "",
                    isChecked: 1
                  })
                })
                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "PILLING").map((x, indexy) => {
                  PillingInfo.push({
                    id: x.id,
                    testID: x?.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    ChangeIn: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    beforeWash: 0,
                    afterWash: 0,
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    ReqGSM: "",
                    isChecked: 1
                  })
                })
                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO WATER").map((x, indexy) => {
                  WaterInfo.push({
                    id: x.id,
                    testID: x?.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    ChangeIn: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    beforeWash: 0,
                    afterWash: 0,
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    ReqGSM: "",
                    isChecked: 1
                  })
                })
                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "PH").map((x, indexy) => {
                  PHInfo.push({
                    id: x.id,
                    testID: x?.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    ChangeIn: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    beforeWash: 0,
                    afterWash: 0,
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    ReqGSM: "",
                    isChecked: 1
                  })
                })
                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO RUBBING").map((x, indexy) => {
                  RubbingInfo.push({
                    id: x.id,
                    testID: x?.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    ChangeIn: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    beforeWash: 0,
                    afterWash: 0,
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    ReqGSM: "",
                    isChecked: 1
                  })
                })

                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO WASHING").map((x, indexy) => {
                  WashingInfo.push({
                    id: x.id,
                    testID: x?.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    ChangeIn: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    beforeWash: 0,
                    afterWash: 0,
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    ReqGSM: "",
                    isChecked: 1
                  })
                })

                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ALKALINE").map((x, indexy) => {
                  AlkalineInfo.push({
                    id: x.id,
                    testID: x?.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    ChangeIn: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    beforeWash: 0,
                    afterWash: 0,
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    ReqGSM: "",
                    isChecked: 1
                  })
                })

                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ACID").map((x, indexy) => {
                  AcidInfo.push({
                    id: x.id,
                    testID: x?.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    ChangeIn: 0,
                    beforeWashChangeIn: 0,
                    beforeWash: 0,
                    afterWash: 0,
                    afterWashChangeIn: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    foundGSM: "",
                    ReqGSM: "",
                    isChecked: 1
                  })
                })


                setStablityInfo(StablityInfo)
                setSpiralityInfo(SpiralityInfo)
                setPillingInfo(PillingInfo)
                setWaterInfo(WaterInfo)
                setPHInfo(PHInfo)
                setRubbingInfo(RubbingInfo)
                setWashingInfo(WashingInfo)
                setAlkalineInfo(AlkalineInfo)
                setAcidInfo(AcidInfo)
                handleChangeNewchange(UnitInfo, StablityInfo, SpiralityInfo, PillingInfo, WaterInfo, RubbingInfo, PHInfo, WashingInfo, AlkalineInfo, AcidInfo);
                console.log(2)
                LoadSpiralDetailwashinformation(SpiralityInfo, "Response1");
                LoadDetailwashinformation(StablityInfo, "Response1");
                // setYarnDetailInfo(YarnInfoDetails);
                //FabricDetials(YarnInfoDetails)


                hideLoader();
              }, (error => {
                hideLoader();
              }))
            }
          })


        }
      }
      else {

        await TNAService.LoadTestInfoDetails(props.location.state.params.TestingInspectionID, props.location.state.params.TnaID, 1).then((res) => {
          if (res.data.item2.length > 0) {
            setFabricHeaderInfo(res.data.item1)
            setOrder(res.data.item3)
            if (res.data.item2) {
              res.data.item2.filter(xy => xy.testName === "MASS PER UNIT AREA (GSM)").map((x, indexy) => {
                UnitInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  Observation: x.observation,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  ReqGSM: x.reqGSM,
                  isChecked: x.isChecked
                })
              })

              res.data.item2.filter(xy => xy.testName === "DIMENSIONAL STABILTY TO WASHING").map((x, indexy) => {
                StablityInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  found: x.found,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  ReqGSM: "",
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  isChecked: x.isChecked
                })
              })


              res.data.item2.filter(xy => xy.testName === "SPIRALITY").map((x, indexy) => {
                SpiralityInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  found: x.found,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  ReqGSM: "",
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  isChecked: x.isChecked
                })
              })


              res.data.item2.filter(xy => xy.testName === "PILLING").map((x, indexy) => {
                PillingInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  found: x.found,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  ReqGSM: "",
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  isChecked: x.isChecked
                })
              })


              res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO WATER").map((x, indexy) => {
                WaterInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  found: x.found,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  ReqGSM: "",
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  isChecked: x.isChecked
                })
              })



              res.data.item2.filter(xy => xy.testName === "PH").map((x, indexy) => {
                PHInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  found: x.found,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  ReqGSM: "",
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  isChecked: x.isChecked
                })
              })

              res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO RUBBING").map((x, indexy) => {
                RubbingInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  found: x.found,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  ReqGSM: "",
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  isChecked: x.isChecked
                })
              })

              res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO WASHING").map((x, indexy) => {
                WashingInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  found: x.found,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  ReqGSM: "",
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  isChecked: x.isChecked
                })
              })
              res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ALKALINE").map((x, indexy) => {
                AlkalineInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  found: x.found,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  ReqGSM: "",
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  isChecked: x.isChecked
                })
              })

              res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ACID").map((x, indexy) => {
                AcidInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  found: x.found,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  ReqGSM: "",
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  isChecked: x.isChecked
                })
              })

              setUnitInfo(UnitInfo)
              setStablityInfo(StablityInfo)
              setSpiralityInfo(SpiralityInfo)
              setPillingInfo(PillingInfo)
              setWaterInfo(WaterInfo)
              setPHInfo(PHInfo)
              setRubbingInfo(RubbingInfo)
              setWashingInfo(WashingInfo)
              setAlkalineInfo(AlkalineInfo)
              setAcidInfo(AcidInfo)
              console.log(3)
              handleChangeNewchange(UnitInfo, StablityInfo, SpiralityInfo, PillingInfo, WaterInfo, RubbingInfo, PHInfo, WashingInfo, AlkalineInfo, AcidInfo);
              LoadSpiralDetailwashinformation(SpiralityInfo, "Response2");
              LoadDetailwashinformation(StablityInfo, "Response2");
            }
          }
          else {

            let StyleIDs = props.location.state.params.multiselecteddatas.map((data => { return data.styleID })).join(",");
            TNAService.GetTestInfoDetails(props.location.state.params.buyerID, props.location.state.params.brandID, 1, props.location.state.params.styleID, props.location.state.params.TestingInspectionID).then((response) => {

              // // Function to filter and get the latest entries

              const getLatestEntriesWithChildLatestDate = () => {
                // Create an object to store the latest entries by testID and latestDate
                const latestEntries = {};

                // Iterate through the response data to store entries and their indexes
                const entriesWithIndexes = response.data.map((item, index) => ({ item, index }));

                entriesWithIndexes.forEach(({ item, index }) => {
                  const { testID, latestDate, childLatestDate } = item;
                  const latestDateStr = new Date(latestDate).toJSON();
                  const childLatestDateStr = new Date(childLatestDate).toJSON();

                  if (!latestEntries[testID] || latestDateStr >= latestEntries[testID].latestDate) {
                    // If it's the latest date or the same date, update the latest entry
                    if (!latestEntries[testID] || latestDateStr > latestEntries[testID].latestDate) {
                      latestEntries[testID] = { latestDate: latestDateStr, entries: [] };
                    }

                    // Check if childLatestDate is greater or equal to the current childLatestDate
                    if (!latestEntries[testID].childLatestDate || childLatestDateStr >= latestEntries[testID].childLatestDate) {
                      // If it's the child latest date or the same date, update the child latest entry
                      if (!latestEntries[testID].childLatestDate || childLatestDateStr > latestEntries[testID].childLatestDate) {
                        latestEntries[testID].childLatestDate = childLatestDateStr;
                        latestEntries[testID].entries = [];
                      }
                      latestEntries[testID].entries.push({ item, index });
                    }
                  }
                });

                // Convert the object to an array of the latest entries while maintaining the original order
                const latestEntriesArray = Object.values(latestEntries).reduce((acc, entry) => {
                  return [...acc, ...entry.entries];
                }, []);

                // Sort the latest entries based on their original order in the response.data array
                latestEntriesArray.sort((a, b) => a.index - b.index);

                return latestEntriesArray.map(entry => entry.item);
              };

              const latestEntriesWithChildLatestDate = getLatestEntriesWithChildLatestDate();



              setFabricHeaderInfo(latestEntriesWithChildLatestDate)

              TNAService.GetGSMCountFabric(props.location.state.params.TestingInspectionID).then((res) => {

                res.data.map((y, i) => {
                  latestEntriesWithChildLatestDate.filter(xy => xy.testName === "MASS PER UNIT AREA (GSM)").map((x, indexy) => {
                    UnitInfo.push({
                      id: x.id,
                      testID: x.testID,
                      testTypeID: x.testTypeID,
                      testName: x.testName,
                      group: x.group,
                      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                      fabricDetails: x.fabricDetails,
                      standard: x.standard,
                      standardName: x.standardName,
                      genderID: x.genderID,
                      colorDepthID: x.colorDepthID,
                      colorDepth: x.colorDepth,
                      minResult: x.minResult,
                      maxResult: x.maxResult,
                      field: x.field == undefined ? "" : x.field.toString(),
                      fieldID: x.field == undefined ? "" : x.field.toString(),
                      fieldName: x.fieldName,
                      resultType: x.resultType,
                      resultTypeID: x.resultType,
                      optionName: x.optionName == undefined ? "" : x.optionName,
                      gradeValue: x.gradeValue,
                      gradeOptionID: x.gradeOptionID,
                      ResultID: 0,
                      ResultName: "",
                      gradeID: 0,
                      gradeName: '',
                      ChangeIn: 0,
                      rowindex: x.rowIndex,
                      found: "",
                      beforeWashChangeIn: 0,
                      beforeWash: 0,
                      afterWash: 0,
                      afterWashChangeIn: 0,
                      foundGSM: "",
                      ReqGSM: y.gsMorCounts,
                      Observation: "",
                      isChecked: 1
                    })
                  })
                })


                UnitInfo.map((item, index) => {
                  item.Observation = "Fabric Bit" + " " + (parseInt(index) + 1 + "")
                })
                setUnitInfo(UnitInfo)
              })
              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "DIMENSIONAL STABILTY TO WASHING").map((x, indexy) => {
                StablityInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  ChangeIn: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  beforeWashChangeIn: 0,
                  beforeWash: '',
                  afterWash: '',
                  afterWashChangeIn: 0,
                  foundGSM: "",
                  ReqGSM: "",
                  isChecked: 1
                })
              })
              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "SPIRALITY").map((x, indexy) => {
                SpiralityInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  ChangeIn: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  beforeWashChangeIn: 0,
                  beforeWash: '',
                  afterWash: '',
                  afterWashChangeIn: 0,
                  foundGSM: "",
                  ReqGSM: "",
                  isChecked: 1
                })
              })
              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "PILLING").map((x, indexy) => {
                PillingInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  ChangeIn: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  beforeWashChangeIn: 0,
                  beforeWash: 0,
                  afterWash: 0,
                  afterWashChangeIn: 0,
                  foundGSM: "",
                  ReqGSM: "",
                  isChecked: 1
                })
              })
              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO WATER").map((x, indexy) => {
                WaterInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  ChangeIn: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  beforeWashChangeIn: 0,
                  beforeWash: 0,
                  afterWash: 0,
                  afterWashChangeIn: 0,
                  foundGSM: "",
                  ReqGSM: "",
                  isChecked: 1
                })
              })
              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "PH").map((x, indexy) => {
                PHInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  ChangeIn: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  beforeWashChangeIn: 0,
                  beforeWash: 0,
                  afterWash: 0,
                  afterWashChangeIn: 0,
                  foundGSM: "",
                  ReqGSM: "",
                  isChecked: 1
                })
              })
              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO RUBBING").map((x, indexy) => {
                RubbingInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  ChangeIn: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  beforeWashChangeIn: 0,
                  beforeWash: 0,
                  afterWash: 0,
                  afterWashChangeIn: 0,
                  foundGSM: "",
                  ReqGSM: "",
                  isChecked: 1
                })
              })

              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO WASHING").map((x, indexy) => {
                WashingInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  ChangeIn: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  beforeWashChangeIn: 0,
                  beforeWash: 0,
                  afterWash: 0,
                  afterWashChangeIn: 0,
                  foundGSM: "",
                  ReqGSM: "",
                  isChecked: 1
                })
              })

              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ALKALINE").map((x, indexy) => {
                AlkalineInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  ChangeIn: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  beforeWashChangeIn: 0,
                  beforeWash: 0,
                  afterWash: 0,
                  afterWashChangeIn: 0,
                  foundGSM: "",
                  ReqGSM: "",
                  isChecked: 1
                })
              })

              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ACID").map((x, indexy) => {
                AcidInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  ChangeIn: 0,
                  beforeWashChangeIn: 0,
                  beforeWash: 0,
                  afterWash: 0,
                  afterWashChangeIn: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  foundGSM: "",
                  ReqGSM: "",
                  isChecked: 1
                })
              })


              setStablityInfo(StablityInfo)
              setSpiralityInfo(SpiralityInfo)
              setPillingInfo(PillingInfo)
              setWaterInfo(WaterInfo)
              setPHInfo(PHInfo)
              setRubbingInfo(RubbingInfo)
              setWashingInfo(WashingInfo)
              setAlkalineInfo(AlkalineInfo)
              setAcidInfo(AcidInfo)
              handleChangeNewchange(UnitInfo, StablityInfo, SpiralityInfo, PillingInfo, WaterInfo, RubbingInfo, PHInfo, WashingInfo, AlkalineInfo, AcidInfo);
              LoadSpiralDetailwashinformation(SpiralityInfo, "Response1");
              LoadDetailwashinformation(StablityInfo, "Response1");
              console.log(4)
              // setYarnDetailInfo(YarnInfoDetails);
              //FabricDetials(YarnInfoDetails)

              hideLoader();
            }, (error => {
              hideLoader();
            }))

          }


        })


        await TNAService.getAllQcInfoList(1, 0, props.location.state.params.TestingInspectionID).then((res) => {
          let newdata = [];
          if (res.data.testingMainDetails) {
            res.data.testingMainDetails.map((x) => {
              newdata.push({
                value: x.labCommentID,
                Remarks: x.labRemarks
              })

            })
            setLabComments(newdata)
            handleChangeNew(newdata)
            hideLoader();
          }
        })

        await TNAService.LoadUOMInfo(props.location.state.params.TestingInspectionID).then((res) => {
          if (res.data.length > 0) {
            res.data.filter(xy => xy.testName === "DIMENSIONAL STABILTY TO WASHING").map((x, indexy) => {
              StablityUOMValue.push({
                TestName: x.testName,
                value: x.value,
                label: x.label,
                TestUOMID: x.testUOMID
              })
            })
            res.data.filter(xy => xy.testName === "SPIRALITY").map((x, indexy) => {
              SpiralityUOMValue.push({
                TestName: x.testName,
                value: x.value,
                label: x.label,
                TestUOMID: x.testUOMID
              })
            })

            setUOMLabel(StablityUOMValue)
            setSpiUOMLabel(SpiralityUOMValue)
          }
        })




      }
    })();



    TNAService.LoadGradeDetail().then((res) => {
      if (res.data) {
        res.data.map((x, index) => {
          GradeList.push({
            Id: x.id,
            value: x.value,
            label: x.label
          })
        })

      }
      setGradeValue(GradeList)
    })







    if (getFabricHeaderInfo.length > 0) {
      dispatch(retrieveTestStandard(0));
      for (var i = 0; i <= getFabricHeaderInfo.length - 1; i++) {

        if (TestNameList.length == 0) {
          let StandardOptions;
          StandardOptions = getFabricHeaderInfo.filter((data => {
            if (data.testID == getFabricHeaderInfo[i].testID) {
              return getFabricHeaderInfo[i].standard;
            }
          })).map(a => a.standard);
          TestNameList.push({
            testID: getFabricHeaderInfo[i].testID
            , ID: getFabricHeaderInfo[i].id
            , testName: getFabricHeaderInfo[i].testName
            , standard: StandardOptions.join(","),
            isChecked: getFabricHeaderInfo[i].isChecked
          });
        }
        else if (TestNameList.length > 0) {
          let filterDuplicate = TestNameList.filter((data => {
            return data.testID == getFabricHeaderInfo[i].testID;
          }))
          if (filterDuplicate.length > 0) {

          }
          else {
            TestNameList.push({
              testID: getFabricHeaderInfo[i].testID
              , ID: getFabricHeaderInfo[i].id
              , testName: getFabricHeaderInfo[i].testName
              , standard: getFabricHeaderInfo[i].standard
              , isChecked: getFabricHeaderInfo[i].isChecked
            });
          }
        }
      }
      setTestNames(...getTestNames, TestNameList);
      settestselect(...getTestNames, TestNameList);
    }
  }, [getFabricHeaderInfo && getFabricHeaderInfo.length > 0]);

  // useEffect(() => {
  //   let TnaPantoneinfoList = [];
  //   let StablityUOMValue = [];
  //   let SpiralityUOMValue = [];
  //   let TestNameList = [];
  //   let GradeList = [];
  //   let UnitInfo = [];
  //   let StablityInfo = [];
  //   let SpiralityInfo = [];
  //   let PillingInfo = [];
  //   let WaterInfo = [];
  //   let PHInfo = [];
  //   let RubbingInfo = [];
  //   let WashingInfo = [];
  //   let AlkalineInfo = [];
  //   let AcidInfo = [];

  //   let params = {
  //     Operation: 1,
  //     SkuIDList: props.location.state.params.SkuID,
  //     SkuId: 0,
  //     StyleId: 0,
  //     BuyerId: 0,
  //     BrandId: 0,
  //     SeasonId: 0,
  //     SupplierId: 0,
  //     TaskId: 0,
  //     MainId: 0,
  //     IsProduction: props.location.state.params.Isproduction
  //   };

  //   (async () => {
  //     try {
  //       // Check Task Status
  //       if (props.location.state.params.TaskStatus === 1) {
  //         if (props.location.state.params.multiselecteddatas.length > 0) {
  //           
  //           let StyleIDs = props.location.state.params.multiselecteddatas
  //             .filter(x => x.isSelected === 1)
  //             .map(data => data.styleID)
  //             .join(",");

  //           const res = await TNAService.LoadTestInfoDetails(inpsectionId, props.location.state.params.TnaID, 3);

  //           if (res.data.item2.length > 0) {
  //             setFabricHeaderInfo(res.data.item1);
  //             setOrder(res.data.item3);

  //             const processData = (testName, array, data) => {
  //               data.filter(xy => xy.testName === testName).forEach((x) => {
  //                 array.push({
  //                   id: x.id,
  //                   testID: x.testID,
  //                   testTypeID: x.testTypeID,
  //                   testName: x.testName,
  //                   group: x.group,
  //                   fabricDetailsID: x.fabricDetailID || "",
  //                   fabricDetails: x.fabricDetails,
  //                   standard: x.standard,
  //                   standardName: x.standardName,
  //                   genderID: x.genderID,
  //                   colorDepthID: x.colorDepthID,
  //                   colorDepth: x.colorDepth,
  //                   minResult: x.minResult,
  //                   maxResult: x.maxResult,
  //                   field: x.field ? x.field.toString() : "",
  //                   fieldID: x.field ? x.field.toString() : "",
  //                   fieldName: x.fieldName,
  //                   resultType: x.resultType,
  //                   resultTypeID: x.resultType,
  //                   optionName: x.optionName || "",
  //                   gradeValue: x.gradeValue,
  //                   gradeOptionID: x.gradeOptionID,
  //                   ResultID: x.resultID,
  //                   ResultName: x.resultName,
  //                   gradeID: x.gradeID,
  //                   gradeName: x.gradeName,
  //                   ChangeIn: x.changeIn,
  //                   rowindex: x.rowIndex,
  //                   found: x.found,
  //                   beforeWashChangeIn: x.beforeWashChangeIn,
  //                   beforeWash: x.beforeWash,
  //                   afterWash: x.afterWash,
  //                   afterWashChangeIn: x.afterWashChangeIn,
  //                   foundGSM: x.foundGSM,
  //                   AWDeviation: x.awDeviation,
  //                   BWDeviation: x.bwDeviation,
  //                   isChecked: x.isChecked
  //                 });
  //               });
  //             };

  //             processData("MASS PER UNIT AREA (GSM)", UnitInfo, res.data.item2);
  //             processData("DIMENSIONAL STABILTY TO WASHING", StablityInfo, res.data.item2);
  //             processData("SPIRALITY", SpiralityInfo, res.data.item2);
  //             processData("PILLING", PillingInfo, res.data.item2);
  //             processData("COLOUR FASTNESS TO WATER", WaterInfo, res.data.item2);
  //             processData("PH", PHInfo, res.data.item2);
  //             processData("COLOUR FASTNESS TO RUBBING", RubbingInfo, res.data.item2);
  //             processData("COLOUR FASTNESS TO WASHING", WashingInfo, res.data.item2);
  //             processData("COLOUR FASTNESS TO PRESPIRATION - ALKALINE", AlkalineInfo, res.data.item2);
  //             processData("COLOUR FASTNESS TO PRESPIRATION - ACID", AcidInfo, res.data.item2);

  //             setUnitInfo(UnitInfo);
  //             setStablityInfo(StablityInfo);
  //             setSpiralityInfo(SpiralityInfo);
  //             setPillingInfo(PillingInfo);
  //             setWaterInfo(WaterInfo);
  //             setPHInfo(PHInfo);
  //             setRubbingInfo(RubbingInfo);
  //             setWashingInfo(WashingInfo);
  //             setAlkalineInfo(AlkalineInfo);
  //             setAcidInfo(AcidInfo);

  //             LoadSpiralDetailwashinformation(SpiralityInfo, "Response2");
  //             LoadDetailwashinformation(StablityInfo, "Response2");
  //           }
  //         }
  //       }

  //       // Load Grade Details
  //       const gradeResponse = await TNAService.LoadGradeDetail();
  //       if (gradeResponse.data) {
  //         const gradeList = gradeResponse.data.map((x) => ({
  //           Id: x.id,
  //           value: x.value,
  //           label: x.label
  //         }));
  //         setGradeValue(GradeList.concat(gradeList));
  //       }

  //       // Additional logic for UOM and Test Standards
  //       if (getFabricHeaderInfo.length > 0) {
  //         dispatch(retrieveTestStandard(0));

  //         getFabricHeaderInfo.forEach((info) => {
  //           const existingTest = TestNameList.find(test => test.testID === info.testID);
  //           if (!existingTest) {
  //             let StandardOptions = getFabricHeaderInfo
  //               .filter(data => data.testID === info.testID)
  //               .map(a => a.standard);

  //             TestNameList.push({
  //               testID: info.testID,
  //               ID: info.id,
  //               testName: info.testName,
  //               standard: StandardOptions.join(","),
  //               isChecked: info.isChecked
  //             });
  //           }
  //         });

  //         setTestNames([...getTestNames, ...TestNameList]);
  //         settestselect([...getTestNames, ...TestNameList]);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  // }, [getFabricHeaderInfo && getFabricHeaderInfo.length > 0]);

  useEffect(() => {

    if (comments && comments.item2 && comments.item2.length > 0) {
      let Options = [];
      comments.item2.forEach(element => {
        Options.push({ id: element.commentID, value: element.commentID, name: element.comment, label: element.comment });
      });
      setStandardOptions(Options);
    }
  }, [comments && comments.item2 && comments.item2.length > 0]);

  // Function to handle changes and send data to the parent
  const handleChange = () => {


    // const combinedData = {
    //   ...getUnitInfo,
    //   ...getSpiralityInfo,
    //   ...getStablityInfo,
    //   ...getPillingInfo,
    //   ...getWaterInfo,
    //   ...getPHInfo,
    //   ...getRubbingInfo,
    //   ...getWashingInfo,
    //   ...getAlkalineInfo,
    //   ...getAcidInfo
    // };
    ///test
    // Creating individual objects with related data inside arrays
    const arry1 = { getUnitInfo: getUnitInfo };
    const arry2 = { getSpiralityInfo: getSpiralityInfo };
    const arry3 = { getStablityInfo: getStablityInfo };
    const arry4 = { getPillingInfo: getPillingInfo };
    const arry5 = { getWaterInfo: getWaterInfo };
    const arry6 = { getPHInfo: getPHInfo };
    const arry7 = { getRubbingInfo: getRubbingInfo };
    const arry8 = { getWashingInfo: getWashingInfo };
    const arry9 = { getAlkalineInfo: getAlkalineInfo };
    const arry10 = { getAcidInfo: getAcidInfo };

    // Combining all objects into a single array
    const combinedArray = [
      arry1,
      arry2,
      arry3,
      arry4,
      arry5,
      arry6,
      arry7,
      arry8,
      arry9,
      arry10,
    ];
    FabricDetials(combinedArray);
    FabriclabDetails(getLabComments);
  };
  const handleChangeNewchange = (UnitInfo, StablityInfo, SpiralityInfo, PillingInfo, RubbingInfo, WaterInfo, PHInfo, WashingInfo, AlkalineInfo, AcidInfo) => {

    // Creating individual objects with related data inside arrays
    const arry1 = { getUnitInfo: UnitInfo };
    const arry2 = { getSpiralityInfo: SpiralityInfo };
    const arry3 = { getStablityInfo: StablityInfo };
    const arry4 = { getPillingInfo: PillingInfo };
    const arry5 = { getWaterInfo: WaterInfo };
    const arry6 = { getPHInfo: PHInfo };
    const arry7 = { getRubbingInfo: RubbingInfo };
    const arry8 = { getWashingInfo: WashingInfo };
    const arry9 = { getAlkalineInfo: AlkalineInfo };
    const arry10 = { getAcidInfo: AcidInfo };
    //let labComments = [...getLabComments]
    // Combining all objects into a single array
    const combinedArray = [
      arry1,
      arry2,
      arry3,
      arry4,
      arry5,
      arry6,
      arry7,
      arry8,
      arry9,
      arry10,
    ];
    FabricDetials(combinedArray);
    FabriclabDetails(getLabComments);
  };
  const handleChangeNew =(newdata) =>{
    FabriclabDetails(newdata);
  }
  async function LoadSpiralDetailwashinformation(val, Feild) {

    let Arraylist = [
      {
        ArrayAwheaders: [],
        ArrayActionheaders: [],
        Gradingpoints: []
      }
    ]
    if (Feild === 'Response1') {
      for (var i = 0; i < 1; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })

        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          Name1: 'Dev',
          Name2: 'Change In',
          TestID: val[0]?.testID,
          TestTypeID: val[0]?.testTypeID,
          TestName: val[0]?.testName
        })
      }
      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: 0,
          id: x.id,
          testID: x?.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID === undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard === undefined ? "" : x.standard,
          standardName: x.standardName === undefined ? "" : x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.field === undefined ? "" : x.field.toString(),
          fieldID: x.field === undefined ? "" : x.field.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName === undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: 0,
          ResultName: "",
          gradeID: 0,
          gradeName: '',
          ChangeIn: 0,
          rowindex: x.rowIndex,
          found: "",
          beforeWashChangeIn: 0,
          beforeWash: '',
          afterWash: '',
          afterWashChangeIn: 0,
          foundGSM: "",
          isChecked: 1,
          GradingAwchangeIn: []
        })
        for (var i = 0; i < 1; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: '',
            Deviation: '',
            ChangeIn: '',
          })
        }
      })
    } else {
      for (var i = 0; i < 1; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })

        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          Name1: 'Dev',
          Name2: 'Change In',
          TestID: val[0]?.testID,
          TestTypeID: val[0]?.testTypeID,
          TestName: val[0]?.testName
        })
      }
      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: x.TestingInfoID,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard,
          standardName: x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.fieldID == undefined ? "" : x.fieldID.toString(),
          fieldID: x.fieldID == undefined ? "" : x.fieldID.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName == undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: x.ResultID,
          ResultName: x.ResultName,
          gradeID: x.gradeID,
          gradeName: x.gradeName,
          ChangeIn: x.ChangeIn,
          rowindex: x.rowIndex,
          found: x.foundGSM,
          beforeWashChangeIn: x.beforeWashChangeIn,
          beforeWash: x.beforeWash,
          afterWash: x.afterWash,
          afterWashChangeIn: x.afterWashChangeIn,
          foundGSM: x.foundGSM,
          AWDeviation: x.AWDeviation,
          BWDeviation: x.BWDeviation,
          isChecked: x.isChecked,
          GradingAwchangeIn: []
        })
        for (var i = 0; i < 1; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: '',
            Deviation: '',
            ChangeIn: '',
          })
        }
      })
    }

    //Arraylist[0].Gradingpoints = val;
    setSpiralDetailedInformaion(Arraylist)
  }


  async function LoadDetailwashinformation(val, Feild) {

    let Arraylist = [
      {
        ArrayAwheaders: [],
        ArrayActionheaders: [],
        Gradingpoints: []
      }
    ]
    if (Feild === 'Response1') {
      for (var i = 0; i < 1; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })

        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          Name: 'Change In',
          TestID: val[0]?.testID,
          TestTypeID: val[0]?.testTypeID,
          TestName: val[0]?.testName
        })
      }
      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: 0,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID === undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard === undefined ? "" : x.standard,
          standardName: x.standardName === undefined ? "" : x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.field === undefined ? "" : x.field.toString(),
          fieldID: x.field === undefined ? "" : x.field.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName === undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: 0,
          ResultName: "",
          gradeID: 0,
          gradeName: '',
          ChangeIn: 0,
          rowindex: x.rowIndex,
          found: "",
          beforeWashChangeIn: 0,
          beforeWash: '',
          afterWash: '',
          afterWashChangeIn: 0,
          foundGSM: "",
          isChecked: 1,
          GradingAwchangeIn: []
        })
        for (var i = 0; i < 1; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: '',
            ChangeIn: '',
          })
        }
      })
    } else {
      for (var i = 0; i < 1; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })

        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          Name: 'Change In',
          TestID: val[0]?.testID,
          TestTypeID: val[0]?.testTypeID,
          TestName: val[0]?.testName
        })
      }
      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: x.TestingInfoID,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard,
          standardName: x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.fieldID === undefined ? "" : x.fieldID.toString(),
          fieldID: x.fieldID === undefined ? "" : x.fieldID.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName === undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: x.ResultID,
          ResultName: x.ResultName,
          gradeID: x.gradeID,
          gradeName: x.gradeName,
          ChangeIn: x.ChangeIn,
          rowindex: x.rowIndex,
          found: x.foundGSM,
          beforeWashChangeIn: x.beforeWashChangeIn,
          beforeWash: x.beforeWash,
          afterWash: x.afterWash,
          afterWashChangeIn: x.afterWashChangeIn,
          foundGSM: x.foundGSM,
          AWDeviation: x.AWDeviation,
          BWDeviation: x.BWDeviation,
          isChecked: x.isChecked,
          GradingAwchangeIn: []
        })
        for (var i = 0; i < 1; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: '',
            ChangeIn: '',
          })
        }
      })
    }

    //Arraylist[0].Gradingpoints = val;
    setDetailedInformaion(Arraylist)
  }

  // Function to handle changes and send data to the parent
  const handleChangeUOM = () => {

    const combinedData = {
      getUOMLabel,
      getSpiUOMLabel

    };
    //test
    FabricUOMDetails(combinedData);
  };




  // Function to handle changes and send data to the parent
  const handleFabriclabChange = () => {
    //test
    FabriclabDetails(getLabComments);
  };
  const WashCountValues = [
    { value: 1, label: "AW1" },
    { value: 2, label: "AW2" },
    { value: 3, label: "AW3" },
    { value: 4, label: "AW4" },
    { value: 5, label: "AW5" },
    { value: 6, label: "AW6" },
    { value: 7, label: "AW7" },
    { value: 8, label: "AW8" },
    { value: 9, label: "AW9" },
    { value: 10, label: "AW10" },
    { value: 11, label: "AW11" },
    { value: 12, label: "AW12" },
    { value: 13, label: "AW13" },
    { value: 14, label: "AW14" },
    { value: 15, label: "AW15" },
    { value: 16, label: "AW16" },
    { value: 17, label: "AW17" },
    { value: 18, label: "AW18" },
    { value: 19, label: "AW19" },
    { value: 20, label: "AW20" },
    { value: 21, label: "AW21" },
    { value: 22, label: "AW22" },
    { value: 23, label: "AW23" },
    { value: 24, label: "AW24" },
    { value: 25, label: "AW25" },
    { value: 26, label: "AW26" },
    { value: 27, label: "AW27" },
    { value: 28, label: "AW28" },
    { value: 29, label: "AW29" },
    { value: 30, label: "AW30" },
    { value: 31, label: "AW31" },
    { value: 32, label: "AW32" },
    { value: 33, label: "AW33" },
    { value: 34, label: "AW34" },
    { value: 35, label: "AW35" },
    { value: 36, label: "AW36" },
    { value: 37, label: "AW37" },
    { value: 38, label: "AW38" },
    { value: 39, label: "AW39" },
    { value: 40, label: "AW40" },
    { value: 41, label: "AW41" },
    { value: 42, label: "AW42" },
    { value: 43, label: "AW43" },
    { value: 44, label: "AW44" },
    { value: 45, label: "AW45" },
    { value: 46, label: "AW46" },
    { value: 47, label: "AW47" },
    { value: 48, label: "AW48" },
    { value: 49, label: "AW49" },
    { value: 50, label: "AW50" },
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCheckboxChange = (event, testname) => {

    // Create a copy of the headerData array
    const updatedHeaderData = [...gettestselect];
    //Create a copy of the detailed array
    let UnitResult = [...getUnitInfo]
    let WaterResult = [...getWaterInfo]
    let StabilityResult = [...getStablityInfo]
    let SpiralityResult = [...getSpiralityInfo]
    let PillingResult = [...getPillingInfo]
    let PHResult = [...getPHInfo]
    let RubbingResult = [...getRubbingInfo]
    let WashingResult = [...getWashingInfo]
    let AlkalaineResult = [...getAlkalineInfo]
    let AcidResult = [...getAcidInfo]

    // Find the index of the object with the matching testName
    const index = updatedHeaderData.findIndex((item) => item.testName === testname);

    if (index !== -1) {
      // Update the isChecked property of the matching object to 1 (checked)
      updatedHeaderData[index].isChecked = updatedHeaderData[index].isChecked === 1 ? 0 : 1;;

      // Update the state with the modified headerData

      const checkedItems = updatedHeaderData.filter(item => item.isChecked === 1);

      // Update the detailed information arrays
      updateDetailedInfoArray(UnitResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(WaterResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(StabilityResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(SpiralityResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(PillingResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(PHResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(RubbingResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(WashingResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(AlkalaineResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(AcidResult, testname, updatedHeaderData[index].isChecked);
      setTestNames(checkedItems);
    }
  };


  // Function to update the isChecked property in a detailed information array
  const updateDetailedInfoArray = (detailedInfoArray, testName, isChecked) => {

    const updatedArray = [...detailedInfoArray];
    const index = updatedArray.findIndex((item) => item.testName === testName);
    if (index !== -1) {

      updatedArray.forEach((item) => {
        if (item.testName === testName) {
          item.isChecked = isChecked;
        }
      })
    }
    // You may want to set the state for the detailed information array here
    // setStateForDetailedInfoArray(updatedArray);

  };




  const handleLabResultOnchange = (value, index, fieldName) => {
    let labComments = [...getLabComments]
    if (value !== null && fieldName === 'labcomment') {
      labComments[index].label = value.label
      labComments[index].value = value.value
    }
    else if (fieldName === 'Remarks') {
      labComments[index].Remarks = value.target.value
    }
    else {
      labComments[index].label = ''
      labComments[index].value = 0
    }
    //text
    setLabComments(labComments)
    handleFabriclabChange()
    //FabriclabDetails(labComments);
  };


  const handleStabilityOnChange = (e, index, field) => {

    let StabilityResult = [...getStablityInfo]
    let StabilityCal = [...getDetailedInformaion];
    let inputtext = ''
    if (field === "BeforeWash") {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }
      StabilityResult[index].beforeWash = inputtext;
      StabilityCal[0].Gradingpoints[index].beforeWash = inputtext;
      ;
      if (StabilityResult[index].afterWash !== undefined && StabilityResult[index].afterWash !== '') {
        let finalvalue = StabilityResult[index].afterWash - inputtext
        let newfinal = finalvalue / inputtext * 100

        //let newfinal = inputtext / StabilityResult[index].afterWash * 100
        //let newfinal = StabilityResult[index].afterWash /  inputtext  * 100
        StabilityResult[index].ChangeIn = parseFloat(newfinal.toFixed(1))

        if (StabilityCal[0].Gradingpoints[index].GradingAwchangeIn[0].AwchangeIN !== '') {

          const AwBW = StabilityCal[0].Gradingpoints[index].GradingAwchangeIn[0].AwchangeIN - inputtext;
          const NewReturnVal = (AwBW / inputtext) * 100;
          StabilityCal[0].Gradingpoints[index].GradingAwchangeIn[0].ChangeIn = isNaN(NewReturnVal) ? '' : parseFloat(NewReturnVal.toFixed(1));
        }
      }
    }


    else if (StabilityResult[index].resultType === 2) {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }


      let beforeWash = StabilityResult[index].beforeWash
      let afterwash = inputtext
      if (afterwash === '') {
        afterwash = 1
        beforeWash = 1
      }
      else {
        afterwash = afterwash
        beforeWash = beforeWash
      }
      let finalvalue = afterwash - beforeWash
      let newfinal = finalvalue / beforeWash * 100

      StabilityResult[index].ChangeIn = parseFloat(newfinal.toFixed(1))


      if (parseInt(StabilityResult[index].minResult) <= newfinal && parseInt(StabilityResult[index].maxResult) >= newfinal) {
        StabilityResult[index].ResultName = "Pass"
        StabilityResult[index].ResultID = 1

        StabilityResult[index].afterWash = inputtext;

      }
      else {
        StabilityResult[index].ResultName = "Fail"
        StabilityResult[index].ResultID = 2

        StabilityResult[index].afterWash = inputtext;

      }
    }

    setStablityInfo(StabilityResult);
    setDetailedInformaion(StabilityCal);
    handleChange()
  };


  const handleSpiralityWashOnChange = (e, index, field) => {
    ;
    let SpiralityResult = [...getSpiralityInfo];
    let inputtext = ''

    if (field === "BeforeWash") {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }
      SpiralityResult[index].beforeWash = inputtext;
      if (SpiralityResult[index].BWDeviation !== undefined && SpiralityResult[index].BWDeviation !== '') {
        //let newfinal = inputtext / SpiralityResult[index].BWDeviation * 100
        let newfinal = SpiralityResult[index].BWDeviation / inputtext * 100
        SpiralityResult[index].beforeWashChangeIn = parseFloat(newfinal.toFixed(1))
      }
    }
    else if (field === "AfterWash") {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }
      SpiralityResult[index].afterWash = inputtext;
      if (SpiralityResult[index].BWDeviation !== undefined && SpiralityResult[index].BWDeviation !== '') {
        //let newfinal = inputtext / SpiralityResult[index].BWDeviation * 100
        let newfinal = SpiralityResult[index].BWDeviation / inputtext * 100
        SpiralityResult[index].afterWashChangeIn = parseFloat(newfinal.toFixed(1))
      }
    }



    setSpiralityInfo(SpiralityResult)
    handleChange()
  };

  const handleSpiralityOnChange = (e, index, field) => {
    ;
    let SpiralityResult = JSON.parse(JSON.stringify(getSpiralityInfo));

    let inputtext = ''


    if (field === "BeforeWash Deviation") {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }
      SpiralityResult[index].BWDeviation = inputtext;

      if (SpiralityResult[index].resultType === 2) {
        if (e.target.value !== '') {
          inputtext = parseFloat(e.target.value)

        }
        else {
          inputtext = ''
        }


        let beforeWash = SpiralityResult[index].beforeWash
        let afterwash = inputtext
        let finalvalue = SpiralityResult[index].beforeWash - inputtext
        let newfinal = inputtext / SpiralityResult[index].beforeWash * 100



        SpiralityResult[index].beforeWashChangeIn = parseFloat(newfinal.toFixed(1))
        //SpiralityResult[index].beforeWashChangeIn = Math.round(newfinal);


      }
    }
    else if (field === "AfterWash Deviation") {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }
      SpiralityResult[index].AWDeviation = inputtext;

      if (SpiralityResult[index].resultType === 2) {
        if (e.target.value !== '') {
          inputtext = parseFloat(e.target.value)

        }
        else {
          inputtext = ''
        }


        let afterWash = SpiralityResult[index].afterWash
        let afterwashDeviation = inputtext
        let finalvalue = SpiralityResult[index].afterWash - inputtext
        let newfinal = inputtext / SpiralityResult[index].afterWash * 100

        SpiralityResult[index].afterWashChangeIn = parseFloat(newfinal.toFixed(1))
        //SpiralityResult[index].afterWashChangeIn = Math.round(newfinal);

        if (parseInt(SpiralityResult[index].minResult) <= newfinal && parseInt(SpiralityResult[index].maxResult) >= newfinal) {
          SpiralityResult[index].ResultName = "Pass"
          SpiralityResult[index].ResultID = 1

        }
        else {
          SpiralityResult[index].ResultName = "Fail"
          SpiralityResult[index].ResultID = 2

        }

      }

    }


    setSpiralityInfo(SpiralityResult)
    //setcopyvalue(SpiralityResult)
    // let AppearanceResult = [...getAppearanceInfo];
    // let WashValue = [...getAppearanceInfo.WashCount];
    // AppearanceResult.WashCount = WashValue;
    // setAppearanceInfo(AppearanceResult);
    handleChange()
  };



  // Function to handle reset
  const handleReset = () => {
    // Reset the ResultName to the initial value
    getcopyvalue.map(item => {
      if (item.ResultName !== initialResultName) {
        item.ResultName = initialResultName;
        item.ResultID = 1; // Assuming a default value for ResultID
      }
      return item;
    });

    // Update the state with reset ResultName
    setcopyvalue(getcopyvalue);
  };







  // Function to handle changes in the input field
  const handleInputChange = (e, index) => {

    let inputtext = ''
    let UnitResult = [...getUnitInfo];
    if (e.target.value !== '') {
      inputtext = e.target.value
      UnitResult[index].Observation = inputtext

    }
    else {
      inputtext = ''
    }

    setUnitInfo(UnitResult)
    handleChange()

    //setInputValue(event.target.value); // Combine "hi" with user's input
  };











  const handleUnitOnChange = (e, index) => {
    ;
    let UnitResult = [...getUnitInfo]
    let inputtext = ''

    if (UnitResult[index].resultType === 2) {
      if (e.target.value !== '') {
        inputtext = parseInt(e.target.value)

      }
      else {
        inputtext = ''
      }


      let reqGsmResult = UnitResult[index].ReqGSM
      let inputtextvalue = inputtext

      if (inputtextvalue === '') {
        reqGsmResult = 1
        inputtextvalue = 1
      }
      else {
        reqGsmResult = reqGsmResult
        inputtextvalue = inputtextvalue
      }




      let changeInResult = inputtextvalue - parseInt(reqGsmResult)
      let finalResult = changeInResult / parseInt(reqGsmResult) * 100
      UnitResult[index].ChangeIn = finalResult

      if (parseInt(UnitResult[index].minResult) <= finalResult && parseInt(UnitResult[index].maxResult) >= finalResult) {
        UnitResult[index].ResultName = "Pass"
        UnitResult[index].ResultID = 1
        //UnitResult[index].found = inputtext;
        UnitResult[index].foundGSM = inputtext;
      }
      else {
        UnitResult[index].ResultName = "Fail"
        UnitResult[index].ResultID = 2
        //UnitResult[index].found = inputtext;
        UnitResult[index].foundGSM = inputtext

      }

    }


    setUnitInfo(UnitResult)
    handleChange()
  };

  const handleWaterOnChange = (e, index) => {
    let WaterResult = [...getWaterInfo]
    let inputtext = ''

    if (WaterResult[index].resultType === 1) {
      if (e.target.value !== '') {
        inputtext = parseInt(e.target.value)

      }
      else {
        inputtext = ''
      }

      if (parseInt(WaterResult[index].minResult) <= inputtext && parseInt(WaterResult[index].maxResult) >= inputtext) {
        WaterResult[index].ResultName = "Pass"
        WaterResult[index].ResultID = 1
        WaterResult[index].found = inputtext;
        WaterResult[index].foundGSM = inputtext;

      }
      else {
        WaterResult[index].ResultName = "Fail"
        WaterResult[index].ResultID = 2
        WaterResult[index].found = inputtext;
        WaterResult[index].foundGSM = inputtext;

      }

    }
    if (WaterResult[index].resultType === 2) {
      if (e.target.value !== '') {
        inputtext = parseInt(e.target.value)

      }
      else {
        inputtext = ''
      }

      if (parseInt(WaterResult[index].minResult) <= inputtext && parseInt(WaterResult[index].maxResult) >= inputtext) {
        WaterResult[index].ResultName = "Pass"
        WaterResult[index].ResultID = 1
        WaterResult[index].found = inputtext;
        WaterResult[index].foundGSM = inputtext;
      }
      else {
        WaterResult[index].ResultName = "Fail"
        WaterResult[index].ResultID = 2
        WaterResult[index].found = inputtext;
        WaterResult[index].foundGSM = inputtext

      }

    }

    if (WaterResult[index].resultType === 3) {
      if (e !== null) {
        WaterResult[index].gradeID = e.value
        WaterResult[index].gradeName = e.label
      }
      else {
        WaterResult[index].gradeID = ''
        WaterResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (WaterResult[index].gradeOptionID >= e.value) {
        WaterResult[index].ResultName = "Pass"
        WaterResult[index].ResultID = 1

      } else {
        WaterResult[index].ResultName = "Fail"
        WaterResult[index].ResultID = 2

      }


    }

    if (WaterResult[index].resultType === 4) {
      if (e.target.value !== '') {
        inputtext = e.target.value
        WaterResult[index].found = inputtext;
        WaterResult[index].foundGSM = inputtext;
      }
      else {
        inputtext = ''
        WaterResult[index].found = '';
        WaterResult[index].foundGSM = '';
      }
    }

    setWaterInfo(WaterResult)
    handleChange()
  };
  const handlepillingOnChange = (e, index) => {
    let PillingResult = [...getPillingInfo]
    let inputtext = ''
    if (PillingResult[index].resultType === 3) {
      if (e !== null) {
        PillingResult[index].gradeID = e.value
        PillingResult[index].gradeName = e.label
      }
      else {
        PillingResult[index].gradeID = ''
        PillingResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (PillingResult[index].gradeOptionID >= e.value) {
        PillingResult[index].ResultName = "Pass"
        PillingResult[index].ResultID = 1

      } else {
        PillingResult[index].ResultName = "Fail"
        PillingResult[index].ResultID = 2

      }


    }
    setPillingInfo(PillingResult)
    handleChange()
  };





  const handlePHOnChange = (e, index) => {
    let PHResult = [...getPHInfo]
    let inputtext = ''

    if (PHResult[index].resultType === 1) {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }

      if (parseInt(PHResult[index].minResult) <= inputtext && parseInt(PHResult[index].maxResult) >= inputtext) {
        PHResult[index].ResultName = "Pass"
        PHResult[index].ResultID = 1
        PHResult[index].found = inputtext;
        PHResult[index].foundGSM = inputtext;

      }
      else {
        PHResult[index].ResultName = "Fail"
        PHResult[index].ResultID = 2
        PHResult[index].found = inputtext;
        PHResult[index].foundGSM = inputtext;

      }

    }


    setPHInfo(PHResult)
    handleChange()
  };

  // Handle Rubbing Changes with validation
  const handleRubbingOnChange = (e, index) => {
    let RubbingResult = [...getRubbingInfo];

    if (RubbingResult[index].resultType === 3) {
      if (e !== null) {
        RubbingResult[index].gradeID = e.value;
        RubbingResult[index].gradeName = e.label;

        // Check if the required column value is within the range of the dropdown value
        if (RubbingResult[index].gradeOptionID >= e.value) {
          RubbingResult[index].ResultName = "Pass";
          RubbingResult[index].ResultID = 1;
        } else {
          RubbingResult[index].ResultName = "Fail";
          RubbingResult[index].ResultID = 2;
        }
      } else {
        // Clear the fields and show a toast error
        RubbingResult[index].gradeID = '';
        RubbingResult[index].gradeName = '';
        RubbingResult[index].ResultName = '';
        RubbingResult[index].ResultID = '';
      }
    }

    // Update the state and handle further changes
    setRubbingInfo(RubbingResult);
    handleChange();
  };

  // const handleRubbingOnChange = (e, index) => {
  //   let RubbingResult = [...getRubbingInfo]
  //   let inputtext = ''

  //   if (RubbingResult[index].resultType === 3) {
  //     if (e !== null) {
  //       RubbingResult[index].gradeID = e.value
  //       RubbingResult[index].gradeName = e.label
  //     }
  //     else {
  //       RubbingResult[index].gradeID = ''
  //       RubbingResult[index].gradeName = ''
  //     }

  //     // Check if the required column value is within the range of the dropdown value
  //     if (RubbingResult[index].gradeOptionID >= e.value) {
  //       RubbingResult[index].ResultName = "Pass"
  //       RubbingResult[index].ResultID = 1

  //     } else {
  //       RubbingResult[index].ResultName = "Fail"
  //       RubbingResult[index].ResultID = 2

  //     }


  //   }


  //   setRubbingInfo(RubbingResult)
  //   handleChange()
  // };

  const handleWashingOnChange = (e, index) => {
    let WashingResult = [...getWashingInfo]
    let inputtext = ''

    if (WashingResult[index].resultType === 3) {
      if (e !== null) {
        WashingResult[index].gradeID = e.value
        WashingResult[index].gradeName = e.label
      }
      else {
        WashingResult[index].gradeID = ''
        WashingResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (WashingResult[index].gradeOptionID >= e.value) {
        WashingResult[index].ResultName = "Pass"
        WashingResult[index].ResultID = 1

      } else {
        WashingResult[index].ResultName = "Fail"
        WashingResult[index].ResultID = 2

      }


    }

    setWashingInfo(WashingResult)
    handleChange()
  };

  const handleAlkalineOnChange = (e, index) => {
    let AlkalaineResult = [...getAlkalineInfo]
    let inputtext = ''

    if (AlkalaineResult[index].resultType === 3) {
      if (e !== null) {
        AlkalaineResult[index].gradeID = e.value
        AlkalaineResult[index].gradeName = e.label
      }
      else {
        AlkalaineResult[index].gradeID = ''
        AlkalaineResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (AlkalaineResult[index].gradeOptionID >= e.value) {
        AlkalaineResult[index].ResultName = "Pass"
        AlkalaineResult[index].ResultID = 1

      } else {
        AlkalaineResult[index].ResultName = "Fail"
        AlkalaineResult[index].ResultID = 2

      }


    }

    setAlkalineInfo(AlkalaineResult)
    handleChange()
  };

  const handleAcidOnChange = (e, index) => {
    let AcidResult = [...getAcidInfo]
    let inputtext = ''

    if (AcidResult[index].resultType === 3) {
      if (e !== null) {
        AcidResult[index].gradeID = e.value
        AcidResult[index].gradeName = e.label
      }
      else {
        AcidResult[index].gradeID = ''
        AcidResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (AcidResult[index].gradeOptionID >= e.value) {
        AcidResult[index].ResultName = "Pass"
        AcidResult[index].ResultID = 1

      } else {
        AcidResult[index].ResultName = "Fail"
        AcidResult[index].ResultID = 2

      }


    }


    setAcidInfo(AcidResult)
    handleChange()
  };



  const handleAfterWashUOMOnChange = (e, index, fieldName) => {

    let UomResult = [...getUOMLabel]
    if (e !== null && fieldName === "DIMENSIONAL STABILTY TO WASHING") {
      UomResult[index].label = e.label
      UomResult[index].value = e.value
      UomResult[index].TestName = fieldName
    }
    else {
      UomResult[index].label = ''
      UomResult[index].value = 0
      UomResult[index].TestName = fieldName
    }

    setUOMLabel(UomResult)
    handleChangeUOM()
  };

  const handleSPIUOMOnChange = (e, index, fieldName) => {

    let UomResult = [...getSpiUOMLabel]
    if (e !== null && fieldName === "SPIRALITY") {
      UomResult[index].label = e.label
      UomResult[index].value = e.value
      UomResult[index].TestName = fieldName
    }
    else {
      UomResult[index].label = ''
      UomResult[index].value = 0
      UomResult[index].TestName = fieldName
    }

    setSpiUOMLabel(UomResult)
    handleChangeUOM()
  };
  const CombineArrayHearder = () => {

    let Arry1 = getDetailedInformaion[0]?.ArrayAwheaders;
    let Arry3 = getSpiralDetailedInformaion[0]?.ArrayAwheaders;
    let CombinedArray = Arry1?.concat(Arry3);
    Detailwashchildcall(CombinedArray);
  }
  const handledetailwashrow = () => {

    let Arry1 = getDetailedInformaion[0]?.Gradingpoints;
    Detailwashinfosubcall(Arry1);
  }
  function handleSpiralwashTypeChange(e, index, headerindex, sectionValue) {

    // const greaterOrEqual = String.fromCharCode(8805);
    const Values = [...getSpiralDetailedInformaion];
    if (e !== null) {
      if (
        headerindex > 0 && sectionValue.value <= Values[0].ArrayAwheaders[headerindex - 1].Awvalue
      ) {
        // Here you can handle the case where the selection is not allowed (e.g., show an error message)
        Nodify('Warning!', 'warning', "Please select a value greater than the previous selection.");
        return;
      }
      Values[0].ArrayAwheaders[headerindex].Awlabel = e.label;
      Values[0].ArrayAwheaders[headerindex].Awvalue = e.value;
      Values[0].ArrayAwheaders[headerindex].index = index;
    } else {
      Values[0].ArrayAwheaders[headerindex].Awlabel = '';
      Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
    }
    setSpiralDetailedInformaion(Values);
    CombineArrayHearder();
  }
  const addSizeHeader = (index, actIndex, info) => {

    const Values = [...getDetailedInformaion];

    Values[0].ArrayActionheaders.push({
      Actionrowno: actIndex
    })

    Values[0].ArrayAwheaders.push({
      TestID: info.Gradingpoints[0].testID,
      TestTypeID: info.Gradingpoints[0].testTypeID,
      Awlabel: '',
      Awvalue: 0,
      Name: 'Change In',
      TestName: info.Gradingpoints[0].testName
    })
    Values[0].Gradingpoints.forEach((x) => {
      // Ensure there is an object at actIndex in the GradingAwchangeIn array
      if (!x.GradingAwchangeIn[actIndex]) {
        x.GradingAwchangeIn[actIndex] = {};
      }

      // Set the properties AwchangeIN and ChangeIn
      x.GradingAwchangeIn[actIndex].AwchangeIN = '';
      x.GradingAwchangeIn[actIndex].ChangeIn = '';
    });
    setDetailedInformaion(Values);
    CombineArrayHearder();
    handledetailwashrow();
  }

  const RemoveHeader = (index, actIndex) => {
    ;
    const Values = [...getDetailedInformaion];
    Values[0].ArrayAwheaders.splice(actIndex, 1);
    Values[0].ArrayActionheaders.splice(actIndex, 1);
    Values[0].Gradingpoints.map((x, ind) => {
      x.GradingAwchangeIn.splice(actIndex, 1)
    })
    setDetailedInformaion(Values);
    CombineArrayHearder();
    handledetailwashrow();
  }

  function handleDetailWashTypeChange(e, index, headerindex, sectionValue) {
    // const greaterOrEqual = String.fromCharCode(8805);
    const Values = [...getDetailedInformaion];
    if (e) {
      if (
        headerindex > 0 && sectionValue.value <= Values[0].ArrayAwheaders[headerindex - 1].Awvalue
      ) {
        // Here you can handle the case where the selection is not allowed (e.g., show an error message)
        Nodify('Warning!', 'warning', "Please select a value greater than the previous selection.");
        return;
      }
      Values[0].ArrayAwheaders[headerindex].Awlabel = e.label;
      Values[0].ArrayAwheaders[headerindex].Awvalue = e.value;
      Values[0].ArrayAwheaders[headerindex].index = index;
    } else {
      Values[0].ArrayAwheaders[headerindex].Awlabel = '';
      Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
    }

    setDetailedInformaion(Values);
    CombineArrayHearder();
  }
  function handleDuplicateDetailWash(e, index, headerindex) {
    ;
    const Values = [...getDetailedInformaion];
    let val = Values[0].ArrayAwheaders.length;
    for (var i = 0; i < val; i++) {
      if (i !== headerindex) {
        if (Values[0].ArrayAwheaders[i].Awvalue === Values[0].ArrayAwheaders[headerindex].Awvalue) {
          Values[0].ArrayAwheaders[headerindex].Awlabel = '';
          Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
          setDetailedInformaion(Values);
          Nodify('Warning!', 'warning', "Aw field already exists");
          return false;
        }
      }
    }
  }
  const addSpiralSizeHeader = (index, actIndex, info) => {
    const Values = [...getSpiralDetailedInformaion];

    Values[0].ArrayActionheaders.push({
      Actionrowno: actIndex
    })

    Values[0].ArrayAwheaders.push({
      TestID: info.Gradingpoints[0].testID,
      TestTypeID: info.Gradingpoints[0].testTypeID,
      Awlabel: '',
      Awvalue: 0,
      Name1: 'Dev',
      Name2: 'Change In',
      TestName: info.Gradingpoints[0].testName
    })
    Values[0].Gradingpoints.forEach((x) => {
      // Ensure there is an object at actIndex in the GradingAwchangeIn array
      if (!x.GradingAwchangeIn[actIndex]) {
        x.GradingAwchangeIn[actIndex] = {};
      }

      // Set the properties AwchangeIN and ChangeIn
      x.GradingAwchangeIn[actIndex].AwchangeIN = '';
      x.GradingAwchangeIn[actIndex].ChangeIn = '';
    });
    setSpiralDetailedInformaion(Values);
    CombineArrayHearder();
    Spiralhandledetailwashrow();
  }
  const RemoveSpiralHeader = (index, actIndex) => {
    const Values = [...getSpiralDetailedInformaion];
    Values[0].ArrayAwheaders.splice(actIndex, 1);
    Values[0].ArrayActionheaders.splice(actIndex, 1);
    Values[0].Gradingpoints.map((x, ind) => {
      x.GradingAwchangeIn.splice(actIndex, 1)
    })
    setSpiralDetailedInformaion(Values);
    CombineArrayHearder();
    Spiralhandledetailwashrow();
  }


  function handleSpiralDuplicateDetailWash(e, index, headerindex) {
    const Values = [...getSpiralDetailedInformaion];
    let val = Values[0].ArrayAwheaders.length;
    for (var i = 0; i < val; i++) {
      if (i !== headerindex) {
        if (Values[0].ArrayAwheaders[i].Awvalue === Values[0].ArrayAwheaders[headerindex].Awvalue) {
          Values[0].ArrayAwheaders[headerindex].Awlabel = '';
          Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
          setSpiralDetailedInformaion(Values);
          Nodify('Warning!', 'warning', "Aw field already exists");
          return false;
        }
      }
    }
  }
  // const handleDimDetailwashChange = (e, index, smindex, cindex, chil) => {
  //   
  //   const Values = [...getDetailedInformaion];
  //   if (chil.beforeWash) {
  //     Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN = e.target.value;
  //     let AwBW = parseInt(e.target.value) - chil.beforeWash;
  //     let NewReturnVal = AwBW / chil.beforeWash * 100;
  //     Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].ChangeIn = isNaN(NewReturnVal) ? '' : parseFloat(NewReturnVal.toFixed(1));  // Math.round(NewReturnVal) 
  //   }
  //   setDetailedInformaion(Values);
  //   handledetailwashrow();
  // }

  const handleDimDetailwashChange = (e, index, smindex, cindex, chil) => {
    const Values = [...getDetailedInformaion];
    const inputValue = e.target.value;
    ;
    // Ensure the input value is parsed correctly for decimal numbers
    const parsedValue = parseFloat(inputValue);

    if (chil.beforeWash && !isNaN(parsedValue)) {
      // Update AwchangeIN with the input value
      Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN = inputValue;

      // Calculate the change based on beforeWash and handle decimal values
      let AwBW = parsedValue - chil.beforeWash;
      let NewReturnVal = (AwBW / chil.beforeWash) * 100;

      // Update ChangeIn with a formatted decimal value
      Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].ChangeIn = isNaN(NewReturnVal) ? '' : parseFloat(NewReturnVal.toFixed(1));
    } else {
      // Reset or handle case when input is invalid
      Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN = '';
      Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].ChangeIn = '';
    }

    // Update state
    setDetailedInformaion(Values);
    handledetailwashrow();
  }

  const handleSpiralDetailwashChange = (e, index, smindex, cindex, chil, Feild) => {

    const Values = [...getSpiralDetailedInformaion];
    const { value } = e.target;
    if (Feild === 'AWB') {
      if (value === '' || /^[0-9]+(\.[0-9]?)?$/.test(value)) {
        Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN = value;
      }
    } else {
      if (value === '' || /^[0-9]+(\.[0-9]?)?$/.test(value)) {
        Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].Deviation = value;
      }
    }


    //  let beforeWash = SpiralityResult[index].beforeWash
    //   let afterwash = inputtext
    //   let finalvalue = SpiralityResult[index].beforeWash - inputtext
    //   let newfinal = inputtext / SpiralityResult[index].beforeWash * 100


    let AwBW = Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN;
    let NewReturnVal = parseFloat(Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].Deviation) / AwBW * 100;
    Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].ChangeIn = isNaN(NewReturnVal) ? '' : parseFloat(NewReturnVal.toFixed(1)); // Math.round(NewReturnVal);
    setSpiralDetailedInformaion(Values);
    Spiralhandledetailwashrow();
  }

  const Spiralhandledetailwashrow = () => {
    let Arry1 = getSpiralDetailedInformaion[0].Gradingpoints;
    // SpiralDetailwashinfosubcall(Arry1);
  }

  const handleResultOnchange = (value, index, fieldName) => {

    let UnitResult = [...getUnitInfo]
    let WaterResult = [...getWaterInfo]
    let StabilityResult = [...getStablityInfo]
    let SpiralityResults = [...getSpiralityInfo]
    let PillingResult = [...getPillingInfo]
    let PHResult = [...getPHInfo]
    let RubbingResult = [...getRubbingInfo]
    let WashingResult = [...getWashingInfo]
    let AlkalaineResult = [...getAlkalineInfo]
    let AcidResult = [...getAcidInfo]
    if (value !== null && fieldName === "PH") {
      PHResult[index].ResultName = value.label
      PHResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "COLOUR FASTNESS TO RUBBING") {
      RubbingResult[index].ResultName = value.label
      RubbingResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "PILLING") {
      PillingResult[index].ResultName = value.label
      PillingResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "MASS PER UNIT AREA (GSM)") {
      UnitResult[index].ResultName = value.label
      UnitResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "COLOUR FASTNESS TO WATER") {
      WaterResult[index].ResultName = value.label
      WaterResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "COLOUR FASTNESS TO WASHING") {
      WashingResult[index].ResultName = value.label
      WashingResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "COLOUR FASTNESS TO PRESPIRATION - ALKALINE") {
      AlkalaineResult[index].ResultName = value.label
      AlkalaineResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "COLOUR FASTNESS TO PRESPIRATION - ACID") {
      AcidResult[index].ResultName = value.label
      AcidResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "DIMENSIONAL STABILTY TO WASHING") {
      StabilityResult[index].ResultName = value.label
      StabilityResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "SPIRALITY") {
      SpiralityResults[index].ResultName = value.label
      SpiralityResults[index].ResultID = value.value
    }

    setWaterInfo(WaterResult)
    setPillingInfo(PillingResult)
    setUnitInfo(UnitResult)
    setPHInfo(PHResult)
    setRubbingInfo(RubbingResult)
    setWashingInfo(WashingResult)
    setAlkalineInfo(AlkalaineResult)
    setAcidInfo(AcidResult)
    setStablityInfo(StabilityResult)
    setSpiralityInfo(SpiralityResults)
    handleChange()
  };


  const AddUnitAreaRow = (e, index, x) => {


    let unitValue = [...getUnitInfo]
    unitValue.push({
      TestingInfoID: 0,
      id: x.id,
      testID: x.testID,
      testTypeID: x.testTypeID,
      testName: x.testName,
      group: x.group,
      fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
      fabricDetails: x.fabricDetails,
      standard: x.standard,
      standardName: x.standardName,
      genderID: x.genderID,
      colorDepthID: x.colorDepthID,
      colorDepth: x.colorDepth,
      minResult: x.minResult,
      maxResult: x.maxResult,
      field: x.field == undefined ? "" : x.field.toString(),
      fieldID: x.field == undefined ? "" : x.field.toString(),
      fieldName: x.fieldName,
      resultType: x.resultType,
      resultTypeID: x.resultType,
      optionName: x.optionName == undefined ? "" : x.optionName,
      gradeValue: x.gradeValue,
      gradeOptionID: x.gradeOptionID,
      ResultID: 0,
      ResultName: "",
      gradeID: 0,
      gradeName: '',
      ChangeIn: 0,
      rowindex: x.rowIndex,
      found: "",
      beforeWashChangeIn: 0,
      beforeWash: 0,
      afterWash: 0,
      afterWashChangeIn: 0,
      foundGSM: "",
      ReqGSM: x.ReqGSM,
      Observation: "",
      isChecked: 1
    })

    unitValue.map((item, index) => {
      item.Observation = "Fabric Bit" + " " + (parseInt(index) + 1 + "")
    })
    setUnitInfo(unitValue)
  }

  const DeleteUnitAreaRow = index => {
    const values = [...getUnitInfo];
    values.splice(index, 1)
    setUnitInfo(values)
  };

  return (
    <div className="widget-body">
      <div id="registration-form">
        <div className="row">
          <h1>
            <span>
              {/* <input className='btn btn-info' onClick={handleShow}>Test Selection</input> */}
              <button className='btn btn-info' onClick={handleShow}>Test Selection</button>
            </span>
          </h1>

          <form>
            {
              getTestNames.length > 0 ?
                getTestNames != undefined && getTestNames.map((fabric, index) => (
                  <Fragment>
                    <div
                      style={{
                        display: fabric.isChecked !== 1 ? "none" : ''
                      }}
                      className="row">
                      <div className="col-md-12">
                        <div className="panel-group accordion" id="acc_Garment">
                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info"
                                  href={"#" + fabric.testID + "Accordian"}>
                                  {index + 1 + ". " + fabric.testName}
                                </a>
                              </h4>
                            </div>
                            <div id={fabric.testID + "Accordian"} className="panel-collapse collapse">
                              <div className="panel-body border-red">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <div className='col-md-4'>

                                      <label>Standard</label>
                                      <Reactselect className="basic-single"
                                        name={"StandardDetails" + index}
                                        id={"StandardDetails" + index}
                                        isDisabled={true}
                                        isLoading={false}
                                        isClearable={false}
                                        isSearchable={true}
                                        isMulti
                                        menuPosition="fixed"
                                        value={getStandardOptions.filter(data => {
                                          return fabric.standard.split(",").includes((data.id).toString());
                                        })}
                                        options={getStandardOptions}
                                      >
                                      </Reactselect>

                                    </div>
                                  </div>
                                  <div className="col-md-12" style={{ marginTop: "10px" }}>

                                    {fabric.testName === "MASS PER UNIT AREA (GSM)" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found<span className='text-danger'>*</span></th>
                                          <th>Change In</th>
                                          <th>Allowed Tol</th>
                                          <th>Result</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {

                                          getUnitInfo !== undefined && getUnitInfo.map((x, index) => (
                                            <>
                                              {
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <div>
                                                          <label>

                                                            <input
                                                              className='form-control'
                                                              type="text"
                                                              value={x.Observation}
                                                              onChange={e => handleInputChange(e, index)}
                                                            />
                                                          </label>


                                                        </div>

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }
                                                  </td>
                                                  <td>
                                                    {x.ReqGSM}

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 2 ? <input type="number" step="any" className='form-control' value={x.foundGSM}
                                                        style={submittedfabric && (x.foundGSM === '' || x.foundGSM === null || x.foundGSM === undefined || x.foundGSM === 0) ? inputstyles : {}}
                                                        onChange={e => handleUnitOnChange(e, index,)} onKeyPress={(e) => {
                                                          if (e.key === 'e' || e.key === 'E') {
                                                            e.preventDefault();
                                                          }
                                                        }}
                                                      />

                                                        : ""

                                                    }

                                                  </td>
                                                  <td>
                                                    {x.ChangeIn.toFixed(2)}
                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 2 ? <span> {x.minResult + "  " + "%" + "  " + "to" + "  " + x.maxResult + "  " + "%"} </span>
                                                        : ""

                                                      }
                                                    </>

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>
                                                  <td>
                                                    {
                                                      getUnitInfo.length === (index + 1) && (
                                                        <span title='Add' className="btn btn-success btn-xs"
                                                          onClick={event => AddUnitAreaRow(event, index, x)}
                                                        >
                                                          <i className='fa fa-plus'></i>
                                                        </span>
                                                      )
                                                    }
                                                    &nbsp;
                                                    {
                                                      getUnitInfo.length !== 1 && (
                                                        <span title='Add' className="btn btn-danger btn-xs"
                                                          onClick={event => DeleteUnitAreaRow(index)}
                                                        >
                                                          <i className='fa fa-trash'></i>
                                                        </span>
                                                      )}

                                                  </td>


                                                </tr>
                                              }
                                            </>

                                          ))

                                        }
                                      </tbody>
                                    </table>}

                                    {fabric.testName === "DIMENSIONAL STABILTY TO WASHING" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>

                                          <th style={{ width: "20%" }}>

                                            <tr style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                              <th>
                                                Details
                                              </th>
                                              <th>
                                                {
                                                  getUOMLabel.map((x, index) => (

                                                    <Reactselect className="basic-single" name="IsProductionStatusID"
                                                      options={UOMValues}
                                                      classNamePrefix="washcount"
                                                      isClearable={true}
                                                      onChange={e => handleAfterWashUOMOnChange(e, index, "DIMENSIONAL STABILTY TO WASHING")}
                                                      //value={GetResult}
                                                      value={UOMValues.filter(function (option) {
                                                        return option.value === x.value;
                                                      })}
                                                    ></Reactselect>
                                                  )
                                                  )
                                                }

                                              </th>
                                            </tr>

                                          </th>
                                          <th>Before Wash </th>
                                          <th>After Wash (Final)</th>
                                          <th>Change In</th>
                                          <th>Allowed Tol</th>
                                          <th>Result</th>


                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getStablityInfo !== undefined && getStablityInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control'

                                                        />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.beforeWash}
                                                      onChange={e => handleStabilityOnChange(e, index, "BeforeWash")}
                                                      //style={submittedfabric && x.beforeWash === '' ? styles : ''}
                                                      style={submittedfabric && (x.beforeWash === '' || x.beforeWash === null || x.beforeWash === undefined || x.beforeWash === 0) ? inputstyles : {}}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.afterWash}
                                                      style={submittedfabric && (x.afterWash === '' || x.afterWash === null || x.afterWash === undefined || x.afterWash === 0) ? inputstyles : {}}
                                                      onChange={e => handleStabilityOnChange(e, index)} />
                                                  </td>

                                                  <td>
                                                    {x.ChangeIn.toFixed(2)}
                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 2 ? <span> {x.minResult + "  " + "%" + "  " + "to" + "  " + x.maxResult + "  " + "%"} </span>
                                                        : ""
                                                      }
                                                    </>

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>

                                          ))
                                        }
                                      </tbody>
                                    </table>}

                                    {fabric.testName === "SPIRALITY" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th style={{ width: "20%" }}>

                                            <tr style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                              <th>
                                                Details
                                              </th>
                                              <th>
                                                {
                                                  getSpiUOMLabel.map((x, index) => (

                                                    <Reactselect className="basic-single" name="IsProductionStatusID"
                                                      options={UOMValues}
                                                      classNamePrefix="washcount"
                                                      isClearable={true}
                                                      onChange={e => handleSPIUOMOnChange(e, index, "SPIRALITY")}
                                                      //value={GetResult}
                                                      value={UOMValues.filter(function (option) {
                                                        return option.value === x.value;
                                                      })}
                                                    ></Reactselect>
                                                  )
                                                  )
                                                }

                                              </th>
                                            </tr>

                                          </th>
                                          <th>Before Wash </th>
                                          <th>BW Deviation </th>
                                          <th>Change In</th>
                                          <th>After Wash</th>
                                          <th>AW Deviation </th>
                                          <th>Change In</th>
                                          <th>AllowedTol</th>
                                          <th>Result</th>


                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getSpiralityInfo != undefined && getSpiralityInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.beforeWash}
                                                      style={submittedfabric && (x.beforeWash === '' || x.beforeWash === null || x.beforeWash === undefined || x.beforeWash === 0) ? inputstyles : {}}
                                                      onChange={e => handleSpiralityWashOnChange(e, index, "BeforeWash")} />
                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.BWDeviation}

                                                      onChange={e => handleSpiralityOnChange(e, index, "BeforeWash Deviation")} />
                                                  </td>
                                                  <td>
                                                    {x.beforeWashChangeIn + '%'}
                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.afterWash}
                                                      style={submittedfabric && (x.afterWash === '' || x.afterWash === null || x.afterWash === undefined || x.afterWash === 0) ? inputstyles : {}}
                                                      onChange={e => handleSpiralityWashOnChange(e, index, "AfterWash")} />
                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.AWDeviation} onChange={e => handleSpiralityOnChange(e, index, "AfterWash Deviation")} />
                                                  </td>
                                                  <td>
                                                    {x.afterWashChangeIn + '%'}
                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 2 ? <span> {x.minResult + "  " + "%" + "  " + "to" + "  " + x.maxResult + "  " + "%"} </span>
                                                        : x.resultType === 3 ? <span> {x.gradeValue}</span>
                                                          : <span> {x.minResult + "  " + "to" + "  " + x.maxResult}</span>
                                                      }
                                                    </>

                                                  </td>
                                                  {/* <td>
                                                {

                                                  (x.resultType === 1) || (x.resultType === 2) ? <input type="number" className='form-control' value={x.foundGSM} onChange={e => handleSpiralityOnChange(e, index)} />
                                                    : x.resultType === 4 ? <input type="text" className='form-control' value={x.foundGSM} onChange={e => handleSpiralityOnChange(e, index)} />
                                                      : x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleSpiralityOnChange(e, index)}
                                                        //value={GetResult}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                      ></Reactselect>

                                                        : ""

                                                }

                                              </td> */}
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}

                                    {fabric.testName === "PILLING" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>


                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getPillingInfo !== undefined && getPillingInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 3 ? <span> {x.gradeValue}</span>
                                                        : ''
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handlepillingOnChange(e, index)}
                                                        //value={GetResult}
                                                        styles={submittedfabric && x.gradeID === 0 ? styles : ''}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                      ></Reactselect>

                                                        : ""

                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>

                                          ))
                                        }
                                      </tbody>
                                    </table>}

                                    {fabric.testName === "PH" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>


                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getPHInfo !== undefined && getPHInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      <span> {x.minResult + "  " + "to" + "  " + x.maxResult}</span>

                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 1 ? <input type="number" className='form-control' value={x.foundGSM}
                                                        style={submittedfabric && (x.foundGSM === '' || x.foundGSM === null || x.foundGSM === undefined || x.foundGSM === 0) ? inputstyles : {}}
                                                        onChange={e => handlePHOnChange(e, index)} onKeyPress={(e) => {
                                                          if (e.key === 'e' || e.key === 'E') {
                                                            e.preventDefault();
                                                          }
                                                        }}
                                                      />


                                                        : ""

                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>

                                          ))
                                        }
                                      </tbody>
                                    </table>}

                                    {fabric.testName === "COLOUR FASTNESS TO RUBBING" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>


                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getRubbingInfo != undefined && getRubbingInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {
                                                        x.resultType === 3 ? <span> {x.gradeValue}</span>
                                                          : ''
                                                      }

                                                    </>

                                                  </td>
                                                  <td>
                                                    {
                                                      x.resultType === 3 ? (
                                                        <Reactselect
                                                          className="basic-single"
                                                          name="IsProductionStatusID"
                                                          options={getGradeValue}
                                                          classNamePrefix="brand"
                                                          isClearable={true}
                                                          onChange={e => handleRubbingOnChange(e, index)}
                                                          value={getGradeValue.filter(option => option.value === x.gradeID)}
                                                          styles={submittedfabric && x.gradeID === 0 ? styles : ''}
                                                        />
                                                      ) : (
                                                        ""
                                                      )
                                                    }
                                                  </td>
                                                  {/* <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleRubbingOnChange(e, index)}
                                                        //value={GetResult}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                      ></Reactselect>

                                                        : ""

                                                    }

                                                  </td> */}
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}

                                    {fabric.testName === "COLOUR FASTNESS TO WASHING" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>

                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getWashingInfo != undefined && getWashingInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {
                                                        x.resultType === 3 ? <span>  {x.gradeValue}</span>
                                                          : ''
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleWashingOnChange(e, index)}
                                                        //value={GetResult}
                                                        styles={submittedfabric && x.gradeID === 0 ? styles : ''}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                      ></Reactselect>

                                                        : ""

                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>

                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}

                                    {fabric.testName === "COLOUR FASTNESS TO WATER" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>

                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getWaterInfo != undefined && getWaterInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 3 ? <span>  {x.gradeValue}</span>
                                                        : ''
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleWaterOnChange(e, index)}
                                                        //value={GetResult}
                                                        styles={submittedfabric && x.gradeID === 0 ? styles : ''}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                      ></Reactselect>

                                                        : ""

                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>

                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}

                                    {fabric.testName === "COLOUR FASTNESS TO PRESPIRATION - ALKALINE" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>



                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getAlkalineInfo != undefined && getAlkalineInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 3 ? <span>  {x.gradeValue}</span>
                                                        : ''
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleAlkalineOnChange(e, index)}
                                                        //value={GetResult}
                                                        styles={submittedfabric && x.gradeID === 0 ? styles : ''}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                      ></Reactselect>

                                                        : ""

                                                    }
                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}


                                    {fabric.testName === "COLOUR FASTNESS TO PRESPIRATION - ACID" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>



                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getAcidInfo != undefined && getAcidInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 3 ? <span> {x.gradeValue}</span>
                                                        : ''
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleAcidOnChange(e, index)}
                                                        //value={GetResult}
                                                        styles={submittedfabric && x.gradeID === 0 ? styles : ''}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                      ></Reactselect>

                                                        : ""

                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}
                                    {fabric.testName === "SPIRALITY" && <div className="row" style={{ padding: '14px 14px 14px 14px' }}>
                                      <div className="panel-group accordion" id="acc_Spiralitywash_Detailinfo">
                                        <div className="panel panel-default">
                                          <div className="panel-heading">
                                            <h4 className="panel-title">
                                              <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Spiralitywash_Detailinfo" href="#collapse_acc_Spiralitywash_Detailinfo">
                                                Detailed Washing Information
                                              </a>
                                            </h4>
                                          </div>
                                          <div id="collapse_acc_Spiralitywash_Detailinfo" className="panel-collapse collapse">
                                            <div className="panel-body border-red">
                                              <div className="col-md-12">
                                                <div className="row" >
                                                  {getSpiralDetailedInformaion && getSpiralDetailedInformaion.map((info, index) => (
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                      <thead>
                                                        <tr>
                                                          <th rowspan="3" style={{ width: '40px' }}>#</th>
                                                          <th rowspan="3" style={{ width: '500px' }}>Details</th>
                                                          {info.ArrayActionheaders && info.ArrayActionheaders.map((Act, Actindex) => {
                                                            return (
                                                              <th colspan="3" > <button className='btn btn-xs btn-success' type='button' onClick={() => addSpiralSizeHeader(index, Actindex + 1, info)}>
                                                                <i className='fa fa-plus'></i></button>&nbsp;
                                                                {<button className='btn btn-xs btn-danger' type='button' onClick={() => RemoveSpiralHeader(index, Actindex, info)}><i className='fa fa-trash'></i></button>} </th>
                                                            )
                                                          })}
                                                        </tr>
                                                        <tr>
                                                          {info.ArrayAwheaders && info.ArrayAwheaders.map((head, awindex) => {
                                                            return (<>
                                                              <th style={{ width: '171px' }}> <Reactselect className="basic-single " name="IsProductionStatusID"
                                                                options={WashCountValues}
                                                                onChange={e => handleSpiralwashTypeChange(e, index, awindex, WashCountValues.find(option => option.value === parseInt(e.value)))}
                                                                onBlur={e => handleSpiralDuplicateDetailWash(e, index, awindex)}
                                                                value={WashCountValues.filter(function (option) {
                                                                  return option.value === head.Awvalue;
                                                                })}
                                                                isDisabled={head.Awvalue !== null && WashCountValues.Awvalue < head.Awvalue}
                                                                isClearable={true}
                                                                classNamePrefix="washcount"
                                                              ></Reactselect></th>
                                                              <th style={{ width: '171px' }}>{head.Name1}</th>
                                                              <th style={{ width: '171px' }}>{head.Name2}</th>
                                                            </>)

                                                          })}
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {
                                                          info.Gradingpoints !== undefined && info.Gradingpoints.map((x, smindex) => (
                                                            <>
                                                              <tr>
                                                                <td>{smindex + 1}</td>
                                                                <td> {x.fieldName}</td>
                                                                {x.GradingAwchangeIn.map((chil, chilindex) => (
                                                                  <>
                                                                    <td> <input type="number" max='10' className='form-control' value={chil.AwchangeIN} onChange={e => handleSpiralDetailwashChange(e, index, smindex, chilindex, x, 'AWB')} /></td>
                                                                    <td> <input type="number" max='10' className='form-control' value={chil.Deviation} onChange={e => handleSpiralDetailwashChange(e, index, smindex, chilindex, x, 'Dev')} /></td>
                                                                    <td>{chil.ChangeIn === '' ? '%' : `${chil.ChangeIn} %`}</td>
                                                                  </>
                                                                ))
                                                                }

                                                              </tr>
                                                            </>
                                                          ))}
                                                      </tbody>
                                                    </table>
                                                  ))
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>}

                                    {fabric.testName === "DIMENSIONAL STABILTY TO WASHING" && <div className="row" style={{ padding: '14px 14px 14px 14px' }}>
                                      <div className="panel-group accordion" id="acc_Dimensionalwash_Detailinfo">
                                        <div className="panel panel-default">
                                          <div className="panel-heading">
                                            <h4 className="panel-title">
                                              <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Dimensionalwash_Detailinfo" href="#collapse_acc_Dimensionalwash_Detailinfo">
                                                Detailed Washing Information
                                              </a>
                                            </h4>
                                          </div>
                                          <div id="collapse_acc_Dimensionalwash_Detailinfo" className="panel-collapse collapse">
                                            <div className="panel-body border-red">
                                              <div className="col-md-12">
                                                <div className="row" >
                                                  {getDetailedInformaion !== undefined && getDetailedInformaion.map((info, index) => (
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                      <thead>
                                                        <tr>
                                                          <th rowspan="2" style={{ width: '40px' }}>#</th>
                                                          <th rowspan="2" style={{ width: '500px' }}>Details</th>
                                                          {info.ArrayActionheaders && info.ArrayActionheaders.map((Act, Actindex) => {
                                                            return (
                                                              <th colspan="2" > <button className='btn btn-xs btn-success' type='button' onClick={() => addSizeHeader(index, Actindex + 1, info)}>
                                                                <i className='fa fa-plus'></i></button>&nbsp;
                                                                {<button className='btn btn-xs btn-danger' type='button' onClick={() => RemoveHeader(index, Actindex, info)}><i className='fa fa-trash'></i></button>} </th>
                                                            )
                                                          })}
                                                        </tr>
                                                        <tr>
                                                          {info.ArrayAwheaders && info.ArrayAwheaders.map((head, awindex) => {
                                                            return (<>
                                                              <th style={{ width: '171px' }}> <Reactselect className="basic-single " name="IsProductionStatusID"
                                                                options={WashCountValues}
                                                                onChange={e => handleDetailWashTypeChange(e, index, awindex, WashCountValues.find(option => option.value === parseInt(e.value)))}
                                                                onBlur={e => handleDuplicateDetailWash(e, index, awindex)}
                                                                value={WashCountValues.filter(function (option) {
                                                                  return option.value === head.Awvalue;
                                                                })}
                                                                isDisabled={head.Awvalue !== null && WashCountValues.Awvalue < head.Awvalue}
                                                                isClearable={true}
                                                                classNamePrefix="washcount"
                                                              ></Reactselect></th>
                                                              <th style={{ width: '171px' }}>{head.Name}</th></>)
                                                          })}
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {
                                                          info.Gradingpoints !== undefined && info.Gradingpoints.map((x, smindex) => (
                                                            <>
                                                              <tr>
                                                                <td>{smindex + 1}</td>
                                                                <td> {x.fieldName}</td>
                                                                {x.GradingAwchangeIn.map((chil, chilindex) => (
                                                                  <>
                                                                    <td> <input type="number" max='10' className='form-control'
                                                                      value={chil.AwchangeIN} onChange={e => handleDimDetailwashChange(e, index, smindex, chilindex, x)} /></td>
                                                                    <td>{chil.ChangeIn === '' ? '%' : `${chil.ChangeIn} %`}</td>
                                                                  </>
                                                                ))
                                                                }

                                                              </tr>
                                                            </>
                                                          ))}
                                                      </tbody>
                                                    </table>
                                                  ))
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))
                : <tr><td colSpan="8" className='norecordfound'><span>No Records Found Please Check Masters</span></td></tr>
            }
          </form>
        </div>
        <br />
        <br />

        {
          getLabComments.map((x, index) => (
            <>
              <div className="row">
                <div className="col-lg-4">
                  <label htmlFor="">Lab Comments</label>
                  <br />

                  <Reactselect className="basic-single " name="IsProductionStatusID"
                    options={labResult}
                    isClearable={true}
                    onChange={e => handleLabResultOnchange(e, index, "labcomment")}
                    //value={GetResult}
                    styles={submittedlabcomments && x.value === 0 ? styles : ''}
                    value={labResult.filter(function (option) {
                      return option.value === x.value;
                    })}
                  ></Reactselect>
                </div>
                <div className="col-lg-12">
                  <label htmlFor="">Remarks {x.value === 2 ? <span className="text-danger">*</span> : ""} </label>
                  <br />
                  <textarea name="" id=""
                    style={submittedlabremark && (x.value === 2 && x.Remarks === "") ? textareaStyles : {}}
                    onChange={e => handleLabResultOnchange(e, index, "Remarks")} value={x.Remarks} cols="150" rows="10"></textarea>
                </div>
              </div>
            </>
          ))
        }
      </div>
      <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
        <div className="fixTableHeadTnadetail">
          <Modal.Header closeButton>
            <Modal.Title>TEST SELECTION POPUP</Modal.Title>
          </Modal.Header>
          {/* , overflowY: "auto" */}
          <Modal.Body className="customdialogbody">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  {
                    gettestselect.map((fabric, index) => (
                      <div className="col-lg-6">
                        <li
                          className="listmodal"
                        >
                          <input
                            className="hidecheckbox"
                            type="checkbox"
                            name="IsShowAW"
                            checked={fabric.isChecked}
                            onChange={e => handleCheckboxChange(e, fabric.testName)}
                          />
                          &nbsp;<label htmlFor="IsShowAW">{fabric.testName}</label>
                        </li>

                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-info' onClick={handleClose}>Save</button>
          </Modal.Footer>
        </div>
      </Modal>
      {loader}
    </div >
  )
}

export default FabricTest