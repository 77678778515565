import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSubChildMultExFactoryFormSKUList } from './../../../../actions/tna';
import Nodify from "../../../Common/ReactNotification";

const AddQCInspection = ({ props, skuSelectData, QCfinalInspectionList, QCcommonSubmitApprovalParams, QCFinalInspectionCallback, SubTaskInfo }) => {

    const [selectall, setselectall] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
    let StartStopInfo;
    let activeMenu;
    let activeMenuSub;
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === 'TNA');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === 'TNAstartstop') {
                        StartStopInfo = activeMenuSub[index];
                    }
                }
            }
        }
    }

    useEffect(() => {

        dispatch(GetSubChildMultExFactoryFormSKUList(QCfinalInspectionList));
    }, []);


    // useMemo(() => {


    //     if(TNAAddFinalInspectionList.length > 0)
    //         {
    //           TNAAddFinalInspectionList.map((r, i) => {
    //                 SubTaskInfo.map(c => {
    //                     if (r.subTaskInfoId === c.ChildId) {
    //                         TNAAddFinalInspectionList[i].IsDepandency = c.IsDepandency;
    //                         TNAAddFinalInspectionList[i].Depandency = c.Depandency;
    //                         TNAAddFinalInspectionList[i].dependencyStartstop = c.intDependency;
    //                         TNAAddFinalInspectionList[i].dependencyStartstop = c.intDependency;
    //                     }
    //                 })
    //             })

    //     let computedComments = TNAAddFinalInspectionList;
    //     computedComments.forEach(element => {
    //        if(QCfinalInspectionList.tnaExFactoryId === element.tnaExFactoryId)
    //        {
    //           element.isSelected = 1;
    //        }

    //     });

    //     setMultiCloseDatas(computedComments);
    //     QCFinalInspectionCallback(computedComments);
    //     return computedComments;
    // }

    // }, [TNAAddFinalInspectionList]);
    useMemo(() => {
        debugger
        const validTNAAddFinalInspectionList = TNAAddFinalInspectionList || []; // Default to empty array
        const validSubTaskInfo = SubTaskInfo || []; // Default to empty array

        if (validTNAAddFinalInspectionList.length > 0) {
            validTNAAddFinalInspectionList.forEach((r, i) => {
                validSubTaskInfo.forEach(c => {
                    if (r.subTaskInfoId === c.ChildId) {
                        validTNAAddFinalInspectionList[i].IsDepandency = c.IsDepandency;
                        validTNAAddFinalInspectionList[i].Depandency = c.Depandency;
                        validTNAAddFinalInspectionList[i].dependencyStartstop = c.intDependency;
                    }
                });
            });

            // Ensure QCfinalInspectionList is valid before accessing its properties
            if (QCfinalInspectionList) {
                validTNAAddFinalInspectionList.forEach(element => {
                    if (QCfinalInspectionList.tnaExFactoryId === element.tnaExFactoryId) {
                        element.isSelected = 1;
                    }
                });
            }

            let computedComments = validTNAAddFinalInspectionList;
            setMultiCloseDatas(computedComments);
            QCFinalInspectionCallback(computedComments);
            return computedComments;
        }
        return [];
    }, [TNAAddFinalInspectionList, SubTaskInfo]);


    // useMemo(() => {

    //     let computedComments = TNAAddFinalInspectionList;
    //     computedComments.map((res, index) => {
    //         if (res.skuID === skuSelectData) {
    //             res.isSelected = 1;
    //             return res;
    //         } else {
    //             return res;
    //         }
    //     })

    //     setMultiCloseDatas(computedComments)
    //     QCFinalInspectionCallback(computedComments)


    //     return computedComments;



    // }, [TNAAddFinalInspectionList]);

    // const computedComments = useMemo(() => {
    //     debugger;
    //     return TNAAddFinalInspectionList.map(element => {
    //         if (QCfinalInspectionList.tnaExFactoryId === element.tnaExFactoryId) {
    //             return { ...element, isSelected: 1 };
    //         }
    //         return element;
    //     });
    // }, [TNAAddFinalInspectionList, QCfinalInspectionList]);

    // useEffect(() => {
    //     setMultiCloseDatas(computedComments);
    // }, [computedComments]);

    // const SelectAllExFactory = (event) => {

    //     let IsSelected = 0;
    //     if (event.target.checked === true) {
    //         IsSelected = 1;
    //         TNAAddFinalInspectionList.forEach(element => {
    //             element.isSelected = 1;
    //         })
    //         MultiSelecteddate(TNAAddFinalInspectionList)
    //     }
    //     else {
    //         IsSelected = 0;
    //         TNAAddFinalInspectionList.forEach(element => {
    //             element.isSelected = 0;
    //         });
    //     }
    //     setselectall(IsSelected);
    // }

    const SelectAllExFactory = (event) => {
        debugger;
        const IsSelected = event.target.checked ? 1 : 0;

        // TNAAddFinalInspectionList.forEach(element => {
        //     element.isSelected = (IsSelected === 1 && QCfinalInspectionList.selectedDependency !== null && QCfinalInspectionList.selectedDependency.trim() !== '' ) 
        //         ? 1 
        //         : 0;
        // });

        let updatedList = TNAAddFinalInspectionList.map(element => ({
            ...element,
            isSelected: QCfinalInspectionList.selectedDependency !== null && QCfinalInspectionList.selectedDependency.trim() !== '' && IsSelected === 1
                ? (element.dependencyStartstop === 1 ? 1 : 0)
                : IsSelected
        }));

        setselectall(IsSelected);
        MultiSelecteddate(updatedList);
        QCFinalInspectionCallback(updatedList);
    };

    const OnSelectExFactoryRecords = (event, index) => {

        const value = [...MultiCloseDatas];
        //  if (value[index].dependencyStartstop === 1) {
        if (event.target.checked === true) {
            value[index].isSelected = 1;
            MultiSelecteddate(value)
        }
        else {
            value[index].isSelected = 0;

        }
        //}
        //         else {
        //     Nodify('Warning!', 'warning', 'please select the dependency for particular task');
        // }
        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);
        setMultiCloseDatas(value);

    }
    const MultiSelecteddate = (value) => {
        QCFinalInspectionCallback(value);
    }

    function getSelectedDependencyMap(SelectedDependency) {
        let Dependency = true;
        // if (SelectedDependency) {
        //     let arrstatus = [];
        //     arrstatus = SelectedDependency.split(',');
        //     if (arrstatus.length > 0) {
        //         arrstatus.map(element => {
        //             let val = [];
        //             val = element.split('-');
        //             let valIndex = QCfinalInspectionList[parseInt(val[0]) - 1].statusID;
        //             if (valIndex !== 3) {
        //                 Dependency = false;
        //             }
        //         });
        //     }
        // }
        return Dependency;
    }

    return (
        <Fragment>
            <div className="inspection" style={{ height: "70vh", overflowX: "scroll", overflowY: "scroll", width: "100%" }}>
                <label className="alert alert-info comment-report-note"><b>Note : </b>Added comments report factory info, shown here!</label>
                <table className="table table-bordered">
                    <thead>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            {/* <th>Style Name</th> */}
                            <th>Style No</th>
                            <th>SKU ID</th>
                            <th>SKU Name</th>
                            <th>PO/ID No</th>
                            <th>PO No</th>
                            <th>Factory</th>
                            <th>Order Qty</th>
                            {/* <th>Bal. for Inspection</th> */}
                            <th>Task Holder / Follower</th>
                            <th>
                                <label style={{ marginTop: '8px' }}>
                                    <input type="checkbox" name="IsSelected"

                                        onChange={event => (SelectAllExFactory(event))}
                                        checked={selectall === 1 ? true : false}
                                        className="colored-blue clearOpacity" />
                                    <span className="text"></span>
                                </label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            MultiCloseDatas.length !== 0 && MultiCloseDatas.map((item, index) => {
                                return (
                                    <>
                                        <tr>
                                            {/* <td>{item.styleName}</td> */}
                                            <td>{item.styleNo}</td>
                                            <td>{item.skuID}</td>
                                            <td>{item.skuName}</td>
                                            <td>{item.idNo}</td>
                                            <td>{item.poNo}</td>
                                            <td >{item.factory === null ? '-' : item.factory}</td>
                                            <td>{item.orderQuantity}</td>
                                            {/* <td>{item.balanceQuantity}</td> */}
                                            <td className="cut_textInTNA" title={item.taskOwnerNameList} >{item.taskOwnerNameList}</td>
                                            {/* <td className="Actionbutton">
                                                <div style={{ width: "80px" }}>
                                                    {
                                                        // (item.dependencyStartstop === 1 || (!QCfinalInspectionList.selectedDependency) || getSelectedDependencyMap(QCfinalInspectionList.selectedDependency) === true || item.isSelected === 1
                                                        // ) &&
                                                        <label style={{ marginTop: '8px' }}>
                                                            <input type="checkbox" name="IsSelected"
                                                                onChange={event => (OnSelectExFactoryRecords(event, index))}
                                                                checked={item.isSelected === 1 ? true : false}
                                                                className="colored-blue clearOpacity" />
                                                            <span className="text"></span>
                                                        </label>
                                                    }&nbsp;&nbsp;&nbsp;
                                                </div>
                                            </td> */}

                                            {/* <td className="Actionbutton">
                                              <div style={{ width: "80px" }}>
                                                  {
                                                     // (item.dependencyStartstop === 1 || (!QCfinalInspectionList.selectedDependency) || getSelectedDependencyMap(QCfinalInspectionList.selectedDependency) === true || item.isSelected === 1
                                                     (QCfinalInspectionList.selectedDependency !== '' && item.dependencyStartstop === 1 
                                                      ) ?
                                                      <label style={{ marginTop: '8px' }}>
                                                          <input type="checkbox" name="IsSelected"
                                                              //   disabled={item.balanceQuantity === "0" ? true : false}
                                                              onChange={event => (OnSelectExFactoryRecords(event, index,item))}
                                                              checked={item.isSelected === 1 ? true : false}
                                                              className="colored-blue clearOpacity" />
                                                          <span className="text"></span>
                                                      </label>
                                                      : 
                                                      QCfinalInspectionList.selectedDependency === '' ?
                                                      <label style={{ marginTop: '8px' }}>
                                                          <input type="checkbox" name="IsSelected"
                                                              //   disabled={item.balanceQuantity === "0" ? true : false}
                                                              onChange={event => (OnSelectExFactoryRecords(event, index,item))}
                                                              checked={item.isSelected === 1 ? true : false}
                                                              className="colored-blue clearOpacity" />
                                                          <span className="text"></span>
                                                      </label>
                                                      :''

                                                  } &nbsp;&nbsp;&nbsp;



                                                                                                                                                                                                                 {
                                                                                                                                                                                                                      // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                                                                                                                                                                                      // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                                                                                                                                                                                      item.Depandency !== '' && QCfinalInspectionList.selectedDependency !== '' &&
                                                                                                                                                                                                                      <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                                                                                      // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                                                                                      >
                                                                                                                                                                                                                          <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                                                                                      </button>
                                                                                                                                                                                                                  }
                                                  
                                              </div>
                                             </td> */}


                                            {/* {props.location.pathname === '/TNAEdit'? 
                            <td className="Actionbutton">
                           <div style={{ width: "80px" }}>
                               {
                                  // (item.dependencyStartstop === 1 || (!QCfinalInspectionList.selectedDependency) || getSelectedDependencyMap(QCfinalInspectionList.selectedDependency) === true || item.isSelected === 1
                                  (QCfinalInspectionList.selectedDependency !== '' && item.dependencyStartstop === 1 
                                   ) ?
                                   <label style={{ marginTop: '8px' }}>
                                       <input type="checkbox" name="IsSelected"
                                           //   disabled={item.balanceQuantity === "0" ? true : false}
                                           onChange={event => (OnSelectExFactoryRecords(event, index,item))}
                                           checked={item.isSelected === 1 ? true : false}
                                           className="colored-blue clearOpacity" />
                                       <span className="text"></span>
                                   </label>
                                   : 
                                   QCfinalInspectionList.selectedDependency === '' ?
                                   <label style={{ marginTop: '8px' }}>
                                       <input type="checkbox" name="IsSelected"
                                           //   disabled={item.balanceQuantity === "0" ? true : false}
                                           onChange={event => (OnSelectExFactoryRecords(event, index,item))}
                                           checked={item.isSelected === 1 ? true : false}
                                           className="colored-blue clearOpacity" />
                                       <span className="text"></span>
                                   </label>
                                   :''

                               }  &nbsp;&nbsp;&nbsp; { 


                                                                                                                                                                                                   // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                                                                                                                                                                   // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                                                                                                                                                                   item.Depandency !== '' && QCfinalInspectionList.selectedDependency !== '' &&
                                                                                                                                                                                                   <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                                                                   // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                                                                   >
                                                                                                                                                                                                       <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                                                                   </button>
                                                                                                                                                                                               }
                               
                           </div>
                           </td> :
                           <td className="Actionbutton">
                           <label style={{ marginTop: '8px' }}>
                               <input type="checkbox"
                                   name="IsSelected"
                                   onChange={event => OnSelectExFactoryRecords(event, index, item)}
                                   checked={item.isSelected === 1}
                                   className="colored-blue clearOpacity" />
                               <span className="text"></span>
                           </label>
                          </td>}  */}


                                            {props.location.pathname === '/TNAEdit' ?
                                                <td className="Actionbutton fixed-column">
                                                    {

                                                        (item.Depandency !== '' && item.dependencyStartstop === 1) ?
                                                            <label style={{ marginTop: '8px' }}>
                                                                <input type="checkbox" name="IsSelected"
                                                                    onChange={event => (OnSelectExFactoryRecords(event, index, item))}
                                                                    checked={item.isSelected === 1 ? true : false}
                                                                    className="colored-blue h clearOpacity" />
                                                                <span className="text"></span>
                                                            </label>
                                                            :
                                                            item.Depandency === '' ?
                                                                <label style={{ marginTop: '8px' }}>
                                                                    <input type="checkbox" name="IsSelected"
                                                                        onChange={event => (OnSelectExFactoryRecords(event, index, item))}
                                                                        checked={item.isSelected === 1 ? true : false}
                                                                        className="colored-blue hd clearOpacity" />
                                                                    <span className="text"></span>
                                                                </label> : ''
                                                    }&nbsp;&nbsp;&nbsp;

                                                    {
                                                        // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                        // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                        item.Depandency !== '' &&
                                                        <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                        // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                        >
                                                            <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                        </button>
                                                    }

                                                </td>

                                                :
                                                <td className="Actionbutton">
                                                    <label style={{ marginTop: '8px' }}>
                                                        <input type="checkbox"
                                                            name="IsSelected"
                                                            onChange={event => OnSelectExFactoryRecords(event, index, item)}
                                                            checked={item.isSelected === 1}
                                                            className="colored-blue clearOpacity" />
                                                        <span className="text"></span>
                                                    </label>
                                                </td>}

                                        </tr>
                                    </>

                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
};
export default AddQCInspection;