import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { getOrderSamplesDetailsViewList, clearSamplesDetailsViewSubRow } from "../../../../actions/orderProtoFitSms";
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
// import { event } from "jquery";


const ProtoFitViewDetailTab = (props) => {

  const dispatch = useDispatch();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [IsProtoExpanded, setIsProtoExpanded] = useState(false);
  const [IsFitExpanded, setIsFitExpanded] = useState(false);
  const [IsNonSampleExpanded, setIsNonSampleExpanded] = useState(false);
  const [IsSizeSampleExpanded, setIsSizeSampleExpanded] = useState(false);
  const [IsPreProductionExpanded, setIsPreProductionExpanded] = useState(false);
  const [IsShipmentExpanded, setIsShipmentExpanded] = useState(false);
  const [IsPhotoSampleExpanded, setIsPhotoSampleExpanded] = useState(false);
  //reducer
  ;
  const ordSampleList = useSelector((state) => state.orderProtoFitSms.ordSampleList);
  const ProtoFitSampleDetailList = useSelector((state) => state.orderProtoFitSms.orderSampleViewDetailList);
  const ProtoFitSampleDetailParams = useSelector((state) => state.orderProtoFitSms.orderSampleViewDetailParams);
  let isLoadingProtoFitSms = useSelector((state) => state.orderProtoFitSms.isLoadingProtoFitSms);
  let OLDStatusList = useSelector((state) => state.orderProtoFitSms.OLDStatusList);

  // If check production or sampling
  const activeMenus = useSelector((state) => state.auth.activeMenuList);
  // let IsProduction = activeMenus.IsProduction;

  let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
  let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;



  // End
  const [isOpen, setIsOpen] = useState(false);
  const [getSearchvalues, setSearchvalues] = useState({
    SupplierId: 0,
    StatusId: 0,
    SearchTxt: "",
    StyleName: "",
    StyleNo: "",
    IdPoNo: "",
    ApprovalTypeId: 0,
    Samplecolor: '',
    SampleName: '',
    stateDate: [
      {
        startDate: null,
        endDate: new Date(""),
        key: 'selection',
        isShow: false
      }
    ]

  });

  const Searchvalues = { ...getSearchvalues };


  const handleExpandRow = (submitfor) => {
    ;
    if (submitfor === "ProtoSample") {
      if (IsProtoExpanded === true) {
        setIsProtoExpanded(false);
      }
      else {
        setIsProtoExpanded(true);
      }
    }
    if (submitfor === "FitSample") {
      if (IsFitExpanded === true) {
        setIsFitExpanded(false);
      }
      else {
        setIsFitExpanded(true);
      }
    }
    if (submitfor === "NoneSample") {
      if (IsNonSampleExpanded === true) {
        setIsNonSampleExpanded(false);
      }
      else {
        setIsNonSampleExpanded(true);
      }
    }
    if (submitfor === "Size Set Sample") {
      if (IsSizeSampleExpanded === true) {
        setIsSizeSampleExpanded(false);
      }
      else {
        setIsSizeSampleExpanded(true);
      }
    }
    if (submitfor === "Pre Production Sample") {
      if (IsPreProductionExpanded === true) {
        setIsPreProductionExpanded(false);
      }
      else {
        setIsPreProductionExpanded(true);
      }
    }
    if (submitfor === "Shipment Sample") {
      if (IsShipmentExpanded === true) {
        setIsShipmentExpanded(false);
      }
      else {
        setIsShipmentExpanded(true);
      }
    }
    if (submitfor === "Photo Sample") {
      if (IsPhotoSampleExpanded === true) {
        setIsPhotoSampleExpanded(false);
      }
      else {
        setIsPhotoSampleExpanded(true);
      }
    }



  }

  //#region  ------------------  search details --------------------------------

  const searchSamples = () => {
   
    Searchvalues.stateDate[0].isShow = false;
    setSearchvalues(Searchvalues);

    let StartDate = null;
    let EndDate = null;
    if (moment(Searchvalues.stateDate[0].startDate).isValid()) {
      StartDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].startDate));
      //StartDate.setMinutes(StartDate.getMinutes() + 370);
    }
    if (moment(Searchvalues.stateDate[0].endDate).isValid()) {
      EndDate = convertUTCDateToLocalDate(new Date(Searchvalues.stateDate[0].endDate));
      //EndDate.setMinutes(EndDate.getMinutes() + 370);
    }
    ;
    let params = {
      BuyerId: ProtoFitSampleDetailParams.BuyerId,
      BrandId: ProtoFitSampleDetailParams.BrandId,
      SeasonId: ProtoFitSampleDetailParams.SeasonId,
      Operation: 1,
      Samplecolor: Searchvalues.Samplecolor,
      SampleName: Searchvalues.SampleName,
      StatusId: Searchvalues.StatusId ? Searchvalues.StatusId : null,
      SupplierId: Searchvalues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: Searchvalues.StyleName,
      StyleNo: Searchvalues.StyleNo,
      IdPoNo: Searchvalues.IdPoNo,
      SampleName: Searchvalues.SampleName,
      IsProduction: IsProduction
    };
    dispatch(getOrderSamplesDetailsViewList(params));
  }

  const resetSamples = () => {
    Searchvalues.stateDate[0].startDate = null;
    Searchvalues.stateDate[0].endDate = new Date("");
    Searchvalues.stateDate[0].key = 'selection';
    Searchvalues.stateDate[0].isShow = false;
    Searchvalues.Samplecolor = "";
    Searchvalues.StatusId = "";
    Searchvalues.SearchTxt = "";
    Searchvalues.ApprovalTypeId = "";
    Searchvalues.StyleName = "";
    Searchvalues.StyleNo = "";
    Searchvalues.IdPoNo = "";
    Searchvalues.SampleName = "";

    setSearchvalues(Searchvalues);
    searchSamples();

  }


  const clearDateSelect = () => {
    Searchvalues.stateDate[0].startDate = null;
    Searchvalues.stateDate[0].endDate = new Date("");
    Searchvalues.stateDate[0].key = 'selection';
    Searchvalues.stateDate[0].isShow = false;

    setSearchvalues(Searchvalues);
  }
  const handleDateSelect = (event, isShow) => {

    Searchvalues.stateDate[0].isShow = isShow;
    setSearchvalues(Searchvalues);
  }

  const handlePopupDateselection = (item) => {

    Searchvalues.stateDate = [item.selection];
    setSearchvalues(Searchvalues);
  }

  const handleChange = (e, FieldName) => {

    let inputText = '';
    if (FieldName === 'SearchTxt' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo'
      || FieldName === 'SampleName' || FieldName === 'Samplecolor') {
      if (e && e.target.value.trim() !== '') {
        inputText = e.target.value;
      }
    } else if (FieldName === 'StatusId' || FieldName === 'ApprovalType') {
      if (e) {
        inputText = e.value
      }
    }

    if (FieldName === 'StatusId') {
      Searchvalues.StatusId = inputText;
    } else if (FieldName === 'ApprovalType') {
      Searchvalues.ApprovalTypeId = inputText;
    } else if (FieldName === 'SearchTxt') {
      Searchvalues.SearchTxt = inputText;
    } else if (FieldName === 'StyleName') {
      Searchvalues.StyleName = inputText;
    }
    else if (FieldName === 'StyleNo') {
      Searchvalues.StyleNo = inputText;
    }
    else if (FieldName === 'IdPoNo') {
      Searchvalues.IdPoNo = inputText;
    }
    else if (FieldName === 'Samplecolor') {
      Searchvalues.Samplecolor = inputText;
    }
    else if (FieldName === 'SampleName') {
      Searchvalues.SampleName = inputText;
    }
    setSearchvalues(Searchvalues);

  }


  function convertUTCDateToLocalDate(date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }

  //#end  ------------------  search details end --------------------------------


  useEffect(() => {
   
    if (!isLoadingProtoFitSms) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [isLoadingProtoFitSms])


let overallindex =1;

  return (
    <Fragment>
      <div> {
        isOpen && (
          <Lightbox
            mainSrc={isOpen}
            alt="bg image"

            onCloseRequest={
              () => setIsOpen(false)
            } />
        )
      } </div>
      <div className="widget-body">
        <div id="registration-form">
          <form>
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-2">
                  <label htmlFor="Status">
                    Style Name
                  </label>
                  <span className="input-icon icon-right">
                    <input
                      type="text"
                      className="form-control StyleList"
                      title={'Style Name'}
                      placeholder={'Style Name'}
                      value={Searchvalues.StyleName}
                      onChange={e => handleChange(e, 'StyleName')}
                    />
                  </span>
                </div>

                <div className="col-md-2">
                  <label htmlFor="Status">
                    Style No
                  </label>
                  <span className="input-icon icon-right">
                    <input
                      type="text"
                      className="form-control StyleList"
                      title={'Style No'}
                      placeholder={'Style No'}
                      value={Searchvalues.StyleNo}
                      onChange={e => handleChange(e, 'StyleNo')}
                    />
                  </span>
                </div>

                <div className="col-md-2">
                  <label htmlFor="Status">
                    Sample Color
                  </label>
                  <span className="input-icon icon-right">
                    <input
                      type="text"
                      className="form-control StyleList"
                      title={'Sample color'}
                      placeholder={'Sample color'}
                      value={Searchvalues.Samplecolor}
                      onChange={e => handleChange(e, 'Samplecolor')}
                    />
                  </span>
                </div>
                <div className="col-md-2">
                  <label htmlFor="Status">
                    ID/PO NO
                  </label>
                  <span className="input-icon icon-right">
                    <input
                      type="text"
                      className="form-control StyleList"
                      title={'ID/PO No'}
                      placeholder={'ID/PO NO'}
                      value={Searchvalues.IdPoNo}
                      onChange={e => handleChange(e, 'IdPoNo')}
                    />
                  </span>

                </div>
                <div className="col-md-2">
                  <label htmlFor="Status">
                    Sample Name
                  </label>
                  <span className="input-icon icon-right">
                    <input
                      type="text"
                      className="form-control StyleList"
                      title={'SampleName'}
                      placeholder={'Sample Name'}
                      value={Searchvalues.SampleName}
                      onChange={e => handleChange(e, 'SampleName')}
                    />
                  </span>
                </div>

                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="StatusId">
                      Status
                    </label>
                    <span className="input-icon icon-right">
                      <Reactselect className="basic-single" name="StatusId"
                        id={
                          "StatusId"
                        }
                        isLoading={false}
                        isClearable={true}
                        isSearchable={true}
                        onChange={event => handleChange(event, 'StatusId')}
                        value={OLDStatusList.filter(function (option) {
                          return option.value === Searchvalues.StatusId;
                        })}
                        options={OLDStatusList}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                      ></Reactselect>
                    </span>
                  </div>
                  </div>


              </div>
              </div>
              

                     
                

                <div className="col-md-2">

                  <div className="form-group">
                  <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box cause datepicker to appear and disappear">(Clicking on the text box...)</span> </label>
                    <span className="input-icon icon-right">
                      <input
                        value={(Searchvalues.stateDate[0].startDate === null ? '' : moment(Searchvalues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((Searchvalues.stateDate[0].endDate === null || !moment(Searchvalues.stateDate[0].endDate).isValid()) ? '' : moment(Searchvalues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                        onClick={event => handleDateSelect(event, !Searchvalues.stateDate[0].isShow)}
                        //onBlur={event => handleDateBlur()}
                        type="text"
                        className={'form-control OrdProtofit'}
                        placeholder="Select Date"
                      />
                      <i className="fa fa-times OrdProtofit" onClick={clearDateSelect} ></i> 
                    </span>
                  </div>


                  {Searchvalues.stateDate[0].isShow &&
                    <DateRangePicker
                      //onChange={item => setStateDate([item.selection])}
                      onChange={item => handlePopupDateselection(item)}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={Searchvalues.stateDate}
                      showDateDisplay={false}
                      direction="vertical"
                      className={'TNAReportDatePicker'}

                    />

                  }

                </div>

                <div className="col-sm-4" style={{ paddingTop: '29px' }}>
                  <span className="input-icon icon-right">
                    <button type="button" className="btn btn-success" title="Search" onClick={event => searchSamples()}
                      disabled={Searchvalues.Buyer === 0 || Searchvalues.Brand === 0 ||
                        Searchvalues.Season === 0 || Searchvalues.Supplier === 0 ? true : false}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                    &nbsp;&nbsp;
                    <button type="button" className="btn btn-danger" title="Reset" onClick={resetSamples}
                    >
                      <i className="fa fa-close"></i>
                    </button>
                    <br />
                  </span>
                </div>
                <br />
              

              <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
              <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                <thead>
                  <tr>
                    <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "40px" }}> #</th>
                    <th className="col-action-or-other">Quarter</th>
                    <th className="col-action-or-other">Style Name</th>
                    <th className="col-action-or-other">Style No</th>
                    <th className="col-action-or-other">ID/PO NO</th>
                    <th className="col-action-or-other">Sample Color</th>
                    <th className="col-action-or-other">Size and Qty</th>
                    <th className="col-action-or-other">Follower</th>
                    <th className="col-action-or-other">Status</th>

                  </tr>
                </thead>
                <tbody>
                  <Fragment>
                    <tr>
                      <td>{overallindex++}</td>
                      <td colSpan="9" style={{ width: "200px" }}>
                        <i
                          className={
                            IsProtoExpanded === true ?
                              'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                          }
                          onClick={event => handleExpandRow("ProtoSample")}

                        ></i>
                        Proto Sample</td>
                    </tr>
                    {
                      IsProtoExpanded === true ?

                        <>
                          {
                            ordSampleList.filter(x => x.sampleRequestName === 'Proto Sample').length > 0 ?
                              ordSampleList.filter(x => x.sampleRequestName === 'Proto Sample').map((x, index) => (
                                <tr>
                                  <td>
                                    {index + 1}
                                  </td>
                                  <td>
                                    {x.storyName}
                                  </td>
                                  <td>
                                    {x.styleName}
                                  </td>
                                  <td>
                                    {x.styleNo}
                                  </td>
                                  <td>
                                    {x.poNo}
                                  </td>
                                  <td>
                                    {x.sampleColor}
                                  </td>
                                  <td>
                                    {x.sizeQty}
                                  </td>
                                  <td>
                                    {x.follower}
                                  </td>
                                  <td>
                                    {x.status}
                                  </td>
                                </tr>
                              ))

                              : <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>
                          }
                        </>





                        : ''
                    }
                  </Fragment>

                  <Fragment>
                    <tr>
                    <td>{overallindex++}</td>
                      <td colSpan="9" style={{ width: "200px" }}>
                        <i
                          className={
                            IsFitExpanded === true ?
                              'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                          }
                          onClick={event => handleExpandRow("FitSample")}

                        ></i>
                        Fit Sample</td>
                    </tr>
                    {
                      IsFitExpanded === true ?

                        <>
                          {
                            ordSampleList.filter(x => x.sampleRequestName === 'Fit Sample').length > 0 ?
                              ordSampleList.filter(x => x.sampleRequestName === 'Fit Sample').map((x, index) => (
                                <tr>
                                  <td>
                                    {index + 1}
                                  </td>
                                  <td>
                                    {x.storyName}
                                  </td>
                                  <td>
                                    {x.styleName}
                                  </td>
                                  <td>
                                    {x.styleNo}
                                  </td>
                                  <td>
                                    {x.poNo}
                                  </td>
                                  <td>
                                    {x.sampleColor}
                                  </td>
                                  <td>
                                    {x.sizeQty}
                                  </td>
                                  <td>
                                    {x.follower}
                                  </td>
                                  <td>
                                    {x.status}
                                  </td>
                                </tr>
                              ))

                              : <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>
                          }

                        </>






                        : ''
                    }
                  </Fragment>
                  {
                    IsProduction === 2 ?
                      <Fragment>
                        <tr>
                        <td>{overallindex++}</td>
                          <td colSpan="9" style={{ width: "200px" }}>
                            <i
                              className={
                                IsSizeSampleExpanded === true ?
                                  'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                              }
                              onClick={event => handleExpandRow("Size Set Sample")}

                            ></i>
                            Size Set Sample</td>
                        </tr>
                        {
                          IsSizeSampleExpanded === true ?

                            <>
                              {
                                ordSampleList.filter(x => x.sampleRequestName === 'Size Set Sample').length > 0 ?

                                  ordSampleList.filter(x => x.sampleRequestName === 'Size Set Sample').map((x, index) => (
                                    <tr>
                                      <td>
                                        {index + 1}
                                      </td>
                                      <td>
                                        {x.storyName}
                                      </td>
                                      <td>
                                        {x.styleName}
                                      </td>
                                      <td>
                                        {x.styleNo}
                                      </td>
                                      <td>
                                        {x.poNo}
                                      </td>
                                      <td>
                                        {x.sampleColor}
                                      </td>
                                      <td>
                                        {x.sizeQty}
                                      </td>
                                      <td>
                                        {x.follower}
                                      </td>
                                      <td>
                                        {x.status}
                                      </td>
                                    </tr>
                                  ))

                                  : <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>
                              }

                            </>






                            : ''
                        }
                      </Fragment>
                      : ''
                  }
                  {
                    IsProduction === 2 ?
                      <Fragment>
                        <tr>
                        <td>{overallindex++}</td>
                          <td colSpan="9" style={{ width: "200px" }}>
                            <i
                              className={
                                IsPreProductionExpanded === true ?
                                  'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                              }
                              onClick={event => handleExpandRow("Pre Production Sample")}

                            ></i>
                            Pre Production Sample</td>
                        </tr>
                        {
                          IsPreProductionExpanded === true ?

                            <>
                              {

                                ordSampleList.filter(x => x.sampleRequestName === 'Pre Production Sample').length > 0 ?

                                  ordSampleList.filter(x => x.sampleRequestName === 'Pre Production Sample').map((x, index) => (
                                    <tr>
                                      <td>
                                        {index + 1}
                                      </td>
                                      <td>
                                        {x.storyName}
                                      </td>
                                      <td>
                                        {x.styleName}
                                      </td>
                                      <td>
                                        {x.styleNo}
                                      </td>
                                      <td>
                                        {x.poNo}
                                      </td>
                                      <td>
                                        {x.sampleColor}
                                      </td>
                                      <td>
                                        {x.sizeQty}
                                      </td>
                                      <td>
                                        {x.follower}
                                      </td>
                                      <td>
                                        {x.status}
                                      </td>
                                    </tr>
                                  ))

                                  : <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>

                              }
                            </>



                            : ''
                        }
                      </Fragment>
                      : ''
                  }
                  {
                    IsProduction === 2 ?
                      <Fragment>
                        <tr>
                        <td>{overallindex++}</td>
                          <td colSpan="9" style={{ width: "200px" }}>

                            <i
                              className={
                                IsShipmentExpanded === true ?
                                  'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                              }
                              onClick={event => handleExpandRow("Shipment Sample")}

                            ></i>
                            Shipment Sample</td>
                        </tr>
                        {
                          IsShipmentExpanded === true ?

                            <>

                              {
                                ordSampleList.filter(x => x.sampleRequestName === 'Shipment Sample').length > 0 ?

                                  ordSampleList.filter(x => x.sampleRequestName === 'Shipment Sample').map((x, index) => (
                                    <tr>
                                      <td>
                                        {index + 1}
                                      </td>
                                      <td>
                                        {x.storyName}
                                      </td>
                                      <td>
                                        {x.styleName}
                                      </td>
                                      <td>
                                        {x.styleNo}
                                      </td>
                                      <td>
                                        {x.poNo}
                                      </td>
                                      <td>
                                        {x.sampleColor}
                                      </td>
                                      <td>
                                        {x.sizeQty}
                                      </td>
                                      <td>
                                        {x.follower}
                                      </td>
                                      <td>
                                        {x.status}
                                      </td>
                                    </tr>
                                  ))

                                  : <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>
                              }

                            </>





                            : ''
                        }
                      </Fragment>
                      : ''
                  }
                  {
                    IsProduction === 2 ?
                      <Fragment>
                        <tr>
                        <td>{overallindex++}</td>
                          <td colSpan="9" style={{ width: "200px" }}>
                            <i
                              className={
                                IsPhotoSampleExpanded === true ?
                                  'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                              }
                              onClick={event => handleExpandRow("Photo Sample")}

                            ></i>
                            Photo Sample</td>
                        </tr>
                        {
                          IsPhotoSampleExpanded === true ?

                            <>

                              {
                                ordSampleList.filter(x => x.sampleRequestName === 'Photo Sample').length > 0 ?

                                  ordSampleList.filter(x => x.sampleRequestName === 'Photo Sample').map((x, index) => (
                                    <tr>
                                      <td>
                                        {index + 1}
                                      </td>
                                      <td>
                                        {x.storyName}
                                      </td>
                                      <td>
                                        {x.styleName}
                                      </td>
                                      <td>
                                        {x.styleNo}
                                      </td>
                                      <td>
                                        {x.poNo}
                                      </td>
                                      <td>
                                        {x.sampleColor}
                                      </td>
                                      <td>
                                        {x.sizeQty}
                                      </td>
                                      <td>
                                        {x.follower}
                                      </td>
                                      <td>
                                        {x.status}
                                      </td>
                                    </tr>
                                  ))

                                  : <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>

                              }

                            </>



                            : ''
                        }
                      </Fragment>
                      : ''
                  }
                  <Fragment>
                    <tr>
                    <td>{overallindex++}</td>
                      <td colSpan="9" style={{ width: "200px" }}>
                        <i
                          className={
                            IsNonSampleExpanded === true ?
                              'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                          }
                          onClick={event => handleExpandRow("NoneSample")}

                        ></i>
                        None Sample Require Styles</td>
                    </tr>
                    {
                      IsNonSampleExpanded === true ?

                        <>

                          {
                            ProtoFitSampleDetailList.length > 0 ?

                              ProtoFitSampleDetailList.map((x, index) => (
                                <tr>
                                  <td>
                                    {index + 1}
                                  </td>
                                  <td>
                                    {x.storyName}
                                  </td>
                                  <td>
                                    {x.styleName}
                                  </td>
                                  <td>
                                    {x.styleNo}
                                  </td>
                                  <td>
                                    {x.poNo}
                                  </td>
                                  <td>
                                    {x.sampleColor}
                                  </td>
                                  <td>
                                    {x.sizeQty}
                                  </td>
                                  <td>
                                    {x.follower}
                                  </td>
                                  <td>
                                    {x.status}
                                  </td>
                                </tr>
                              ))

                              : <tr><td colSpan="11" className='norecordfound'><span>No Records Found</span></td></tr>

                          }

                        </>


                        : ''
                    }
                  </Fragment>




                </tbody>
              </table>
            </div>
          </form>

        </div>
        {loader}
      </div >



    </Fragment >

  )
}

export default ProtoFitViewDetailTab