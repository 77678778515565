import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { retrieveTestStandard } from '../../../../../actions/teststandard';
import Reactselect from 'react-select';
import TNAService from '../../../../../services/TNA/TNAService';
import useFullPageLoader from '../../../../hooks/useFullPageLoader';
import { Modal, Button } from "react-bootstrap";
import Nodify from "../../../../Common/ReactNotification";

const StrikeOff = ({ props, StrikeOffDetails, labdata, labremarkdata, Testingdetail, StrikeOffLabDetails, Detailwashchildcall, Detailwashinfosubcall }) => {
  const dispatch = useDispatch();
  const [getTestNames, setTestNames] = useState([]);
  const [gettestselect, settestselect] = useState([]);
  const [getStrikeOffHeaderInfo, setStrikeOffHeaderInfo] = useState([]);
  const [getStrikeOffDetailInfo, setStrikeOffDetailInfo] = useState([]);
  const [getStandardOptions, setStandardOptions] = useState([]);
  const [getOrder, setOrder] = useState([]);
  const [getGradeValue, setGradeValue] = useState([]);
  const reducerState = useSelector((state) => state);
  const [show, setShow] = useState(false);
  const [getDurabilityInfo, setDurabilityInfo] = useState([]);
  const [submittedlabcomments, setSubmittedlabcomments] = useState(false);
  const [submittedlabremark, setSubmittedlabremark] = useState(false);
  const inpsectionId = JSON.parse(localStorage.getItem("TestingInspectionID"));
  const [getApperenceDetailedInformaion, setApperenceDetailedInformaion] = useState([]);
  const [submittedtestingdetails, setSubmittedtestingdetails] = useState(false);
  const [getLabComments, setLabComments] = useState([
    {
      value: 0,
      label: "",
      Remarks: ""
    }
  ]);
  const comments = reducerState.TestStandardReducer.TestStandardList;
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  let labResult = [
    {
      value: 1,
      label: <><span style={{ color: "green" }}><b>Statisfied</b></span></>,


    },
    {
      value: 2,
      label: <><span style={{ color: "Red" }}><b>Not Statisfy</b></span></>,


    }
  ]

  let IsResult = [
    {
      value: 1,
      label: <><span style={{ color: "green" }}><b>Pass</b></span></>,


    },
    {
      value: 2,
      label: <><span style={{ color: "Red" }}><b>Fail</b></span></>,


    }
  ]
  const [checkboxStates, setCheckboxStates] = useState({
    IsShowDur: true,
  });
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),
  }
  const textareaStyles = {
    border: '1px solid red',  // Red border for highlighting 
  };
  useEffect(() => {
    debugger
    //setSubmittedlabcomments(false)
    setSubmittedlabcomments(labdata)
  }, [labdata === true])

  const handleChangeNew =(newdata) =>{
    StrikeOffLabDetails(newdata);
  }
  useEffect(() => {
    dispatch(retrieveTestStandard(0));
    const fetchData = async () => {
      try {
        let TestNameList = [];
        let GradeList = [];
        let DurabilityInfo = [];

        if (props.location.state.params.TaskStatus === 1) {
          if (props.location.state.params.multiselecteddatas.length > 0) {
            const StyleIDs = props.location.state.params.multiselecteddatas
              .filter(x => x.isSelected === 1)
              .map(data => data.styleID)
              .join(",");

            // Load durability info details
            const res = await TNAService.LoadTestInfoDetails(inpsectionId, props.location.state.params.TnaID, 4);

            if (res.data.item2.length > 0) {
              setStrikeOffHeaderInfo(res.data.item2);
              // Process durability info
              res.data.item2.filter(xy => xy.testName === "DURABILITY").forEach((x, indexy) => {
                DurabilityInfo.push({
                  TestingInfoID: x.testingInfoID,
                  id: x.id,
                  testID: x?.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.fieldID == undefined ? "" : x.fieldID.toString(),
                  fieldID: x.fieldID == undefined ? "" : x.fieldID.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  ChangeIn: x.changeIn,
                  rowindex: x.rowIndex,
                  found: x.foundGSM,
                  beforeWashChangeIn: x.beforeWashChangeIn,
                  beforeWash: x.beforeWash,
                  afterWash: x.afterWash,
                  afterWashChangeIn: x.afterWashChangeIn,
                  foundGSM: x.foundGSM,
                  AWDeviation: x.awDeviation,
                  BWDeviation: x.bwDeviation,
                  isChecked: x.isChecked
                });
                setDurabilityInfo(DurabilityInfo);
                handleChangeinitalload(DurabilityInfo)
              });
              // Load QC info list
              const qcRes = await TNAService.getAllQcInfoList(2, 0, inpsectionId);
              let Arraylist = [
                {
                  ArrayAwheaders: [],
                  ArrayActionheaders: [],
                  Gradingpoints: []
                }
              ];
              if (qcRes.data.detailWashTypeHeader.length !== 0) {
                await qcRes.data.detailWashTypeHeader.filter(x => x.testName === 'DURABILITY').forEach((x, index) => {
                  Arraylist[0].ArrayActionheaders.push({
                    Actionrowno: index
                  });

                  Arraylist[0].ArrayAwheaders.push({
                    DetailWashID: x.detailWashID,
                    Awlabel: '',
                    Awvalue: x.afterWashType,
                    TestID: x.testID,
                    TestTypeID: x.testTypeID,
                    TestName: x.testName
                  });
                });

                await qcRes.data.detailWashTypeinfo.filter(x => x.testName === 'DURABILITY').forEach((x, index) => {
                  Arraylist[0].Gradingpoints.push({
                    DetailWashID: x.detailWashID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    testID: x.testID,
                    fieldID: x.feildID,
                    fieldName: x.feildName,
                    afterWash: x.afterWash,
                    beforeWash: x.beforeWash,
                    GradingAwchangeIn: x.afterWashType.split(",").map(id => parseInt(id.trim(), 10)).map(val => ({ AwchangeIN: val }))
                  });
                });

                setApperenceDetailedInformaion(Arraylist);
                hideLoader();
              } else {
                LoadAppDetailwashinformation(DurabilityInfo, "Response1");
              }
            }
            else {
              TNAService.GetTestInfoDetails(props.location.state.params.buyerID, props.location.state.params.brandID, 4, StyleIDs, inpsectionId).then((response) => {

                // // Function to filter and get the latest entries

                const getLatestEntriesWithChildLatestDate = () => {
                  // Create an object to store the latest entries by testID and latestDate
                  const latestEntries = {};

                  // Iterate through the response data to store entries and their indexes
                  const entriesWithIndexes = response.data.map((item, index) => ({ item, index }));

                  entriesWithIndexes.forEach(({ item, index }) => {
                    const { testID, latestDate, childLatestDate } = item;
                    const latestDateStr = new Date(latestDate).toJSON();
                    const childLatestDateStr = new Date(childLatestDate).toJSON();

                    if (!latestEntries[testID] || latestDateStr >= latestEntries[testID].latestDate) {
                      // If it's the latest date or the same date, update the latest entry
                      if (!latestEntries[testID] || latestDateStr > latestEntries[testID].latestDate) {
                        latestEntries[testID] = { latestDate: latestDateStr, entries: [] };
                      }

                      // Check if childLatestDate is greater or equal to the current childLatestDate
                      if (!latestEntries[testID].childLatestDate || childLatestDateStr >= latestEntries[testID].childLatestDate) {
                        // If it's the child latest date or the same date, update the child latest entry
                        if (!latestEntries[testID].childLatestDate || childLatestDateStr > latestEntries[testID].childLatestDate) {
                          latestEntries[testID].childLatestDate = childLatestDateStr;
                          latestEntries[testID].entries = [];
                        }
                        latestEntries[testID].entries.push({ item, index });
                      }
                    }
                  });

                  // Convert the object to an array of the latest entries while maintaining the original order
                  const latestEntriesArray = Object.values(latestEntries).reduce((acc, entry) => {
                    return [...acc, ...entry.entries];
                  }, []);

                  // Sort the latest entries based on their original order in the response.data array
                  latestEntriesArray.sort((a, b) => a.index - b.index);

                  return latestEntriesArray.map(entry => entry.item);
                };

                const latestEntriesWithChildLatestDate = getLatestEntriesWithChildLatestDate();


                setStrikeOffHeaderInfo(latestEntriesWithChildLatestDate);
                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "DURABILITY").map((x, indexy) => {
                  DurabilityInfo.push({
                    id: x.id,
                    testID: x.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID === undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard === undefined ? "" : x.standard.toString(),
                    standardName: x.standardName === undefined ? "" : x.standardName.toString(),
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field === undefined ? "" : x.field.toString(),
                    fieldID: x.field === undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName === undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    changein: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    afterWash: "",
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    observation: "",
                    isChecked: 1
                  });
                  setDurabilityInfo(DurabilityInfo);
                  handleChangeinitalload(DurabilityInfo)
                  LoadAppDetailwashinformation(DurabilityInfo, "Response2");
                  hideLoader();
                  console.log(1)
                });
              }, (error => {
                hideLoader();
              }));
            }
          }
        } else {

          const res = await TNAService.LoadTestInfoDetails(props.location.state.params.TestingInspectionID, props.location.state.params.TnaID, 4);
          setStrikeOffHeaderInfo(res.data.item2);

          if (res.data.item2.length > 0) {
            res.data.item2.filter(xy => xy.testName === "DURABILITY").forEach((x, indexy) => {
              DurabilityInfo.push({
                id: x.id,
                testID: x.testID,
                testTypeID: x.testTypeID,
                testName: x.testName,
                group: x.group,
                fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                fabricDetails: x.fabricDetails,
                standard: x.standard == undefined ? "" : x.standard.toString(),
                standardName: x.standardName == undefined ? "" : x.standardName.toString(),
                genderID: x.genderID,
                colorDepthID: x.colorDepthID,
                colorDepth: x.colorDepth,
                minResult: x.minResult,
                maxResult: x.maxResult,
                field: x.field === undefined ? "" : x.field.toString(),
                fieldID: x.field === undefined ? "" : x.field.toString(),
                fieldName: x.fieldName,
                resultType: x.resultType,
                resultTypeID: x.resultType,
                optionName: x.optionName === undefined ? "" : x.optionName,
                gradeValue: x.gradeValue,
                gradeOptionID: x.gradeOptionID,
                ResultID: x.resultID,
                ResultName: x.resultName,
                gradeID: x.gradeID,
                gradeName: x.gradeName,
                changein: 0,
                rowindex: x.rowIndex,
                found: x.foundGSM,
                beforeWashChangeIn: 0,
                afterWash: "",
                afterWashChangeIn: 0,
                foundGSM: x.foundGSM,
                observation: x.observation,
                isChecked: x.isChecked
              });
            });
            setDurabilityInfo(DurabilityInfo);
            handleChangeinitalload(DurabilityInfo)
          } else {
            TNAService.GetTestInfoDetails(props.location.state.params.buyerID, props.location.state.params.brandID, 4, props.location.state.params.styleID, props.location.state.params.TestingInspectionID).then((response) => {

              // // Function to filter and get the latest entries

              const getLatestEntriesWithChildLatestDate = () => {
                // Create an object to store the latest entries by testID and latestDate
                const latestEntries = {};

                // Iterate through the response data to store entries and their indexes
                const entriesWithIndexes = response.data.map((item, index) => ({ item, index }));

                entriesWithIndexes.forEach(({ item, index }) => {
                  const { testID, latestDate, childLatestDate } = item;
                  const latestDateStr = new Date(latestDate).toJSON();
                  const childLatestDateStr = new Date(childLatestDate).toJSON();

                  if (!latestEntries[testID] || latestDateStr >= latestEntries[testID].latestDate) {
                    // If it's the latest date or the same date, update the latest entry
                    if (!latestEntries[testID] || latestDateStr > latestEntries[testID].latestDate) {
                      latestEntries[testID] = { latestDate: latestDateStr, entries: [] };
                    }

                    // Check if childLatestDate is greater or equal to the current childLatestDate
                    if (!latestEntries[testID].childLatestDate || childLatestDateStr >= latestEntries[testID].childLatestDate) {
                      // If it's the child latest date or the same date, update the child latest entry
                      if (!latestEntries[testID].childLatestDate || childLatestDateStr > latestEntries[testID].childLatestDate) {
                        latestEntries[testID].childLatestDate = childLatestDateStr;
                        latestEntries[testID].entries = [];
                      }
                      latestEntries[testID].entries.push({ item, index });
                    }
                  }
                });

                // Convert the object to an array of the latest entries while maintaining the original order
                const latestEntriesArray = Object.values(latestEntries).reduce((acc, entry) => {
                  return [...acc, ...entry.entries];
                }, []);

                // Sort the latest entries based on their original order in the response.data array
                latestEntriesArray.sort((a, b) => a.index - b.index);

                return latestEntriesArray.map(entry => entry.item);
              };

              const latestEntriesWithChildLatestDate = getLatestEntriesWithChildLatestDate();


              setStrikeOffHeaderInfo(latestEntriesWithChildLatestDate);
              latestEntriesWithChildLatestDate.filter(xy => xy.testName === "DURABILITY").map((x, indexy) => {
                DurabilityInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID === undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard === undefined ? "" : x.standard.toString(),
                  standardName: x.standardName === undefined ? "" : x.standardName.toString(),
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field === undefined ? "" : x.field.toString(),
                  fieldID: x.field === undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName === undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: 0,
                  ResultName: "",
                  gradeID: 0,
                  gradeName: '',
                  changein: 0,
                  rowindex: x.rowIndex,
                  found: "",
                  beforeWashChangeIn: 0,
                  afterWash: "",
                  afterWashChangeIn: 0,
                  foundGSM: "",
                  observation: "",
                  isChecked: 1
                });
                setDurabilityInfo(DurabilityInfo);
                handleChangeinitalload(DurabilityInfo)
                LoadAppDetailwashinformation(DurabilityInfo, "Response1");
                hideLoader();
                console.log(1)
              });
            }, (error => {
              hideLoader();
            }));
          }
          // LoadAppDetailwashinformation(DurabilityInfo, "Response1");
          const qcRess = await TNAService.getAllQcInfoList(2, 0, props.location.state.params.TestingInspectionID);
          let Arraylist = [
            {
              ArrayAwheaders: [],
              ArrayActionheaders: [],
              Gradingpoints: []
            }
          ];
          if (qcRess.data.detailWashTypeHeader.length !== 0) {
            await qcRess.data.detailWashTypeHeader.filter(x => x.testName === 'DURABILITY').forEach((x, index) => {
              Arraylist[0].ArrayActionheaders.push({
                Actionrowno: index
              });

              Arraylist[0].ArrayAwheaders.push({
                DetailWashID: x.detailWashID,
                Awlabel: '',
                Awvalue: x.afterWashType,
                TestID: x.testID,
                TestTypeID: x.testTypeID,
                TestName: x.testName
              });
            });

            await qcRess.data.detailWashTypeinfo.filter(x => x.testName === 'DURABILITY').forEach((x, index) => {
              Arraylist[0].Gradingpoints.push({
                DetailWashID: x.detailWashID,
                testTypeID: x.testTypeID,
                testName: x.testName,
                testID: x.testID,
                fieldID: x.feildID,
                fieldName: x.feildName,
                afterWash: x.afterWash,
                beforeWash: x.beforeWash,
                GradingAwchangeIn: x.afterWashType.split(",").map(id => parseInt(id.trim(), 10)).map(val => ({ AwchangeIN: val }))
              });
            });
            setApperenceDetailedInformaion(Arraylist);
            hideLoader();
          }
          //  else {
          //   LoadAppDetailwashinformation(DurabilityInfo, "Response1");
          // }

          const qcRes = await TNAService.getAllQcInfoList(1, 0, props.location.state.params.TestingInspectionID);
          let newdata = [];
          if (qcRes.data.testingMainDetails) {
            qcRes.data.testingMainDetails.forEach(x => {
              newdata.push({
                value: x.labCommentID,
                Remarks: x.labRemarks
              });
            });
            setLabComments(newdata);
            handleChangeNew(newdata)
            hideLoader();
          }
        }

        const gradeRes = await TNAService.LoadGradeDetail();
        GradeList = gradeRes.data.map(x => ({
          Id: x.id,
          value: x.value,
          label: x.label
        }));
        setGradeValue(GradeList);

        // Update TestNameList
        if (getStrikeOffHeaderInfo.length > 0) {
          debugger
          getStrikeOffHeaderInfo.forEach(item => {
            const existingItem = TestNameList.find(data => data.testID === item.testID);
            if (!existingItem) {
              TestNameList.push({
                testID: item.testID,
                ID: item.id,
                testName: item.testName,
                standard: item.standard,
                isChecked: item.isChecked
              });
            }
          });
          setTestNames(TestNameList);
          settestselect(TestNameList);
        }
      } catch (error) {
        console.error("Error in fetching data:", error);
        hideLoader();
      }
    };

    fetchData();
  }, [props.location.state.params, getStrikeOffHeaderInfo.length]);

  useEffect(() => {
    debugger
    setSubmittedlabremark(labremarkdata)
  }, [labremarkdata === true])

  useEffect(() => {
    debugger
    setSubmittedtestingdetails(Testingdetail)
  }, [Testingdetail === true])
  useEffect(() => {
    if (comments && comments.item2 && comments.item2.length > 0) {
      let Options = [];
      comments.item2.forEach(element => {
        Options.push({ id: element.commentID, value: element.commentID, name: element.comment, label: element.comment });
      });
      setStandardOptions(Options);
    }
  }, [comments && comments.item2 && comments.item2.length > 0]);
  async function LoadAppDetailwashinformation(val, Feild) {

    let Arraylist = [
      {
        ArrayAwheaders: [],
        ArrayActionheaders: [],
        Gradingpoints: []
      }
    ]
    if (Feild === 'Response1') {
      for (var i = 0; i <= 2; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })
        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          TestID: val[0]?.testID,
          TestTypeID: val[0]?.testTypeID,
          TestName: val[0]?.testName
        })

      }

      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: 0,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID === undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard === undefined ? "" : x.standard,
          standardName: x.standardName === undefined ? "" : x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.field === undefined ? "" : x.field.toString(),
          fieldID: x.field === undefined ? "" : x.field.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName === undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: 0,
          ResultName: "",
          gradeID: 0,
          gradeName: '',
          ChangeIn: 0,
          rowindex: x.rowIndex,
          found: "",
          beforeWashChangeIn: 0,
          beforeWash: '',
          afterWash: '',
          afterWashChangeIn: 0,
          foundGSM: "",
          isChecked: 1,
          GradingAwchangeIn: []
        })
        for (var i = 0; i <= 2; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: 0,
          })
        }
      })
    } else {
      for (var i = 0; i <= 2; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })
        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          TestID: val[0].testID,
          TestTypeID: val[0].testTypeID,
          TestName: val[0].testName
        })

      }

      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: x.TestingInfoID,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard,
          standardName: x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.fieldID == undefined ? "" : x.fieldID.toString(),
          fieldID: x.fieldID == undefined ? "" : x.fieldID.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName == undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: x.ResultID,
          ResultName: x.ResultName,
          gradeID: x.gradeID,
          gradeName: x.gradeName,
          ChangeIn: x.ChangeIn,
          rowindex: x.rowIndex,
          found: x.foundGSM,
          beforeWashChangeIn: x.beforeWashChangeIn,
          beforeWash: x.beforeWash,
          afterWash: x.afterWash,
          afterWashChangeIn: x.afterWashChangeIn,
          foundGSM: x.foundGSM,
          AWDeviation: x.AWDeviation,
          BWDeviation: x.BWDeviation,
          isChecked: x.isChecked,
          GradingAwchangeIn: []
        })
        for (var i = 0; i <= 2; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: 0,
          })
        }
      })
    }
    //Arraylist[0].Gradingpoints = val;
    setApperenceDetailedInformaion(Arraylist)
  }
  const handleChange = () => {
    StrikeOffDetails(getDurabilityInfo);
    StrikeOffLabDetails(getLabComments);
  };

  const handleChangeinitalload = (DurabilityInfo) => {
    StrikeOffDetails(DurabilityInfo);
    StrikeOffLabDetails(getLabComments);
  };
  const handleLabChange = () => {
    StrikeOffLabDetails(getLabComments);
  };

  // const handleDurabilityOnChange = (e, index) => {
  //   let DurabilityResult = [...getDurabilityInfo];
  //   let inputtext = '';

  //   if (DurabilityResult[index].resultType === 3) {
  //     if (e !== null) {
  //       DurabilityResult[index].gradeID = e.value;
  //       DurabilityResult[index].gradeName = e.label;
  //     }
  //     else {
  //       DurabilityResult[index].gradeID = '';
  //       DurabilityResult[index].gradeName = '';
  //     }
  //     if (DurabilityResult[index].gradeOptionID >= e.value) {
  //       DurabilityResult[index].ResultName = "Pass";
  //       DurabilityResult[index].ResultID = 1;

  //     } else {
  //       DurabilityResult[index].ResultName = "Fail";
  //       DurabilityResult[index].ResultID = 2;
  //     }
  //   }
  //   setDurabilityInfo(DurabilityResult);
  //   handleChange();
  // }

  const handleDurabilityOnChange = (e, index) => {
    let DurabilityResult = [...getDurabilityInfo];
  
    if (e && e.value !== undefined) {
      // When a valid option is selected
      DurabilityResult[index].gradeID = e.value;
      DurabilityResult[index].gradeName = e.label;
  
      // Update result based on gradeOptionID comparison
      if (DurabilityResult[index].gradeOptionID >= e.value) {
        DurabilityResult[index].ResultName = "Pass";
        DurabilityResult[index].ResultID = 1;
      } else {
        DurabilityResult[index].ResultName = "Fail";
        DurabilityResult[index].ResultID = 2;
      }
    } else {
      // When the selection is cleared
      DurabilityResult[index].gradeID = '';
      DurabilityResult[index].gradeName = '';
      DurabilityResult[index].ResultName = '';
      DurabilityResult[index].ResultID = null;
    }
  
    // Update the state with the modified DurabilityResult array
    setDurabilityInfo(DurabilityResult);
  
    // Call handleChange to propagate changes further
    handleChange();
  };
  

  const handleResultOnchange = (value, index, fieldName) => {
    let DurabilityResult = [...getDurabilityInfo];
    if (value !== null && fieldName === "DURABILITY") {
      DurabilityResult[index].ResultName = value.label;
      DurabilityResult[index].ResultID = value.value;
    }
    else {
      DurabilityResult[index].ResultName = "";
      DurabilityResult[index].ResultID = 0;
    }
    setDurabilityInfo(DurabilityResult);
    handleChange();
  }

  const handleLabResultOnchange = (value, index, fieldName) => {
    let labComments = [...getLabComments]
    if (value !== null && fieldName === 'labcomment') {
      labComments[index].label = value.label;
      labComments[index].value = value.value;
    }
    else if (fieldName === 'Remarks') {
      labComments[index].Remarks = value.target.value;
    }
    else {
      labComments[index].label = ''
      labComments[index].value = 0
    }
    setLabComments(labComments);
    handleLabChange();
  };
  const WashCountValues = [
    { value: 1, label: "AW1" },
    { value: 2, label: "AW2" },
    { value: 3, label: "AW3" },
    { value: 4, label: "AW4" },
    { value: 5, label: "AW5" },
    { value: 6, label: "AW6" },
    { value: 7, label: "AW7" },
    { value: 8, label: "AW8" },
    { value: 9, label: "AW9" },
    { value: 10, label: "AW10" },
    { value: 11, label: "AW11" },
    { value: 12, label: "AW12" },
    { value: 13, label: "AW13" },
    { value: 14, label: "AW14" },
    { value: 15, label: "AW15" },
    { value: 16, label: "AW16" },
    { value: 17, label: "AW17" },
    { value: 18, label: "AW18" },
    { value: 19, label: "AW19" },
    { value: 20, label: "AW20" },
    { value: 21, label: "AW21" },
    { value: 22, label: "AW22" },
    { value: 23, label: "AW23" },
    { value: 24, label: "AW24" },
    { value: 25, label: "AW25" },
    { value: 26, label: "AW26" },
    { value: 27, label: "AW27" },
    { value: 28, label: "AW28" },
    { value: 29, label: "AW29" },
    { value: 30, label: "AW30" },
    { value: 31, label: "AW31" },
    { value: 32, label: "AW32" },
    { value: 33, label: "AW33" },
    { value: 34, label: "AW34" },
    { value: 35, label: "AW35" },
    { value: 36, label: "AW36" },
    { value: 37, label: "AW37" },
    { value: 38, label: "AW38" },
    { value: 39, label: "AW39" },
    { value: 40, label: "AW40" },
    { value: 41, label: "AW41" },
    { value: 42, label: "AW42" },
    { value: 43, label: "AW43" },
    { value: 44, label: "AW44" },
    { value: 45, label: "AW45" },
    { value: 46, label: "AW46" },
    { value: 47, label: "AW47" },
    { value: 48, label: "AW48" },
    { value: 49, label: "AW49" },
    { value: 50, label: "AW50" },
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCheckboxChange = (event, testname) => {

    // Create a copy of the headerData array
    const updatedHeaderData = [...gettestselect];
    let DurabilityInfo = [...getDurabilityInfo]


    // Find the index of the object with the matching testName
    const index = updatedHeaderData.findIndex((item) => item.testName === testname);

    if (index !== -1) {
      // Update the isChecked property of the matching object to 1 (checked)
      updatedHeaderData[index].isChecked = updatedHeaderData[index].isChecked === 1 ? 0 : 1;;

      const checkedItems = updatedHeaderData.filter(item => item.isChecked === 1);

      // Update the detailed information arrays
      updateDetailedInfoArray(DurabilityInfo, testname, updatedHeaderData[index].isChecked);
      setTestNames(checkedItems);
    }
  };


  // Function to update the isChecked property in a detailed information array
  const updateDetailedInfoArray = (detailedInfoArray, testName, isChecked) => {

    const updatedArray = [...detailedInfoArray];
    const index = updatedArray.findIndex((item) => item.testName === testName);
    if (index !== -1) {
      updatedArray.forEach((item) => {
        if (item.testName === testName) {
          item.isChecked = isChecked;
        }
      })
    }
    // You may want to set the state for the detailed information array here
    // setStateForDetailedInfoArray(updatedArray);
  };
  const addApperenceSizeHeader = (index, actIndex, info) => {
    const Values = [...getApperenceDetailedInformaion];

    Values[0].ArrayActionheaders.push({
      Actionrowno: actIndex
    })

    Values[0].ArrayAwheaders.push({
      Awlabel: '',
      Awvalue: 0,
      TestName: info.Gradingpoints[0].testName
    })
    Values[0].Gradingpoints.forEach((x) => {
      // Ensure there is an object at actIndex in the GradingAwchangeIn array
      // if (!x.GradingAwchangeIn[actIndex]) {
      //   x.GradingAwchangeIn[actIndex] = {};
      // }
      x.GradingAwchangeIn.push({});
      // Set the properties AwchangeIN and ChangeIn
      x.GradingAwchangeIn[actIndex].AwchangeIN = '';
      x.GradingAwchangeIn[actIndex].ChangeIn = '';
    });
    setApperenceDetailedInformaion(Values);
    CombineArrayHearder();
    handledetailwashrow();
  }
  const RemoveApperenceHeader = (index, actIndex) => {
    ;
    const Values = [...getApperenceDetailedInformaion];
    Values[0].ArrayAwheaders.splice(actIndex, 1);
    Values[0].ArrayActionheaders.splice(actIndex, 1);
    Values[0].Gradingpoints.map((x, ind) => {
      x.GradingAwchangeIn.splice(actIndex, 1)
    })
    setApperenceDetailedInformaion(Values);
    CombineArrayHearder();
    handledetailwashrow();
  }
  function handleAppWashTypeChange(e, head, index, headerindex, sectionValue) {

    const Values = [...getApperenceDetailedInformaion];
    if (e) {
      if (
        headerindex > 0 && sectionValue.value <= Values[0].ArrayAwheaders[headerindex - 1].Awvalue
      ) {
        // Here you can handle the case where the selection is not allowed (e.g., show an error message)
        Nodify('Warning!', 'warning', "Please select a value greater than the previous selection.");
        return;
      }
      Values[0].ArrayAwheaders[headerindex].Awlabel = e.label;
      Values[0].ArrayAwheaders[headerindex].Awvalue = e.value;
      Values[0].ArrayAwheaders[headerindex].index = index;
    } else {
      Values[0].ArrayAwheaders[headerindex].Awlabel = '';
      Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
    }

    setApperenceDetailedInformaion(Values);
    CombineArrayHearder();
  }

  function handleAppDuplicateDetailWash(e, head, index, headerindex) {
    const Values = [...getApperenceDetailedInformaion];
    let val = Values[0].ArrayAwheaders.length;
    for (var i = 0; i < val; i++) {
      if (i !== headerindex) {
        if (Values[0].ArrayAwheaders[i].Awvalue === Values[0].ArrayAwheaders[headerindex].Awvalue) {
          Values[0].ArrayAwheaders[headerindex].Awlabel = '';
          Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
          setApperenceDetailedInformaion(Values);
          Nodify('Warning!', 'warning', "Aw field already exists");
          return false;
        }
      }
    }
  }

  const handleDimAppDetailwashChange = (e, index, smindex, cindex) => {
    const Values = [...getApperenceDetailedInformaion];
    Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN = e.value;
    setApperenceDetailedInformaion(Values);
    handledetailwashrow();
  }
  const CombineArrayHearder = () => {

    let Arry2 = getApperenceDetailedInformaion[0]?.ArrayAwheaders;
    Detailwashchildcall(Arry2);
  }
  const handledetailwashrow = () => {

    let Arry2 = getApperenceDetailedInformaion[0]?.Gradingpoints;
    Detailwashinfosubcall(Arry2);
  }
  return (
    <div className="widget-body">
      <div id="registration-form">
        <div className="row">
          <h1>
            <span>
              {/* <input className='btn btn-info' onClick={handleShow}>Test Selection</input> */}
              <button className='btn btn-info' disabled={true} onClick={handleShow}>Test Selection</button>
            </span>
          </h1>

          <form>
            {
              getTestNames.length > 0 ?

                getTestNames != undefined && getTestNames.map((fabric, index) => (
                  <Fragment>
                    <div
                      style={{
                        display: fabric.isChecked !== 1 ? "none" : ''
                      }}

                      className="row">


                      <div className="col-md-12">
                        <div className="panel-group accordion" id="acc_StrikeOff">
                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info"
                                  href={"#" + fabric.testID + "Accordian"}>
                                  {index + 1 + ". " + fabric.testName}
                                </a>
                              </h4>
                            </div>
                            <div id={fabric.testID + "Accordian"} className="panel-collapse collapse">
                              <div className="panel-body border-red">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <div className="row">


                                        <div className='col-md-3'>

                                          <label>Standard</label>
                                          <Reactselect className="basic-single"
                                            name={"StandardDetails" + index}
                                            id={"StandardDetails" + index}
                                            isDisabled={true}
                                            isLoading={false}
                                            isClearable={false}
                                            isSearchable={true}
                                            isMulti
                                            menuPosition="fixed"
                                            value={getStandardOptions.filter(data => {
                                              return fabric?.standard?.split(",").includes((data.id).toString());
                                            })}
                                            options={getStandardOptions}
                                          >
                                          </Reactselect>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12" style={{ marginTop: "10px" }}>

                                    {
                                      fabric.testName === "DURABILITY" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Details</th>
                                            <th>Required </th>
                                            <th>Found </th>
                                            <th>Result</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            getDurabilityInfo !== undefined && getDurabilityInfo.map((x, index) => (
                                              <>
                                                {
                                                  <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{x.fieldName}</td>
                                                    <td>
                                                      <>
                                                        {x.resultType === 3 ? <span>  {x.gradeValue}</span>
                                                          : ''
                                                        }
                                                      </>

                                                    </td>
                                                    <td> {

                                                      // x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      //   options={getGradeValue}
                                                      //   classNamePrefix="brand"
                                                      //   isClearable={true}
                                                      //   onChange={e => handleDurabilityOnChange(e, index)}
                                                      //   value={getGradeValue.filter(function (option) {
                                                      //     return option.value === x.gradeID;
                                                      //   })}
                                                      //   styles={submittedtestingdetails && x.gradeID === 0 ? styles : ''}
                                                      // ></Reactselect>
                                                     
                                                      x.resultType === 3 ? (
                                                        <Reactselect
                                                          className="basic-single"
                                                          name="IsProductionStatusID"
                                                          options={getGradeValue}
                                                          classNamePrefix="brand"
                                                          isClearable={true}
                                                          onChange={(e) => handleDurabilityOnChange(e, index)}
                                                          value={
                                                            getGradeValue.find(option => option.value === x.gradeID) || null // find single matching value
                                                          }
                                                          styles={submittedtestingdetails && x.gradeID === 0 ? styles : {}}
                                                        />
                                                      )
                                                        : ""

                                                    }</td>

                                                    <td> <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect></td>
                                                  </tr>
                                                }
                                              </>
                                            ))
                                          }
                                        </tbody>
                                      </table>
                                    }



                                  </div>
                                  <div className="col-md-12" style={{ marginTop: "10px" }}> {fabric.testName === "DURABILITY" && <div className="row" style={{ padding: '14px 14px 14px 14px' }}>
                                    <div className="panel-group accordion" id="acc_DURABILITY_Detailinfo">
                                      <div className="panel panel-default">
                                        <div className="panel-heading">
                                          <h4 className="panel-title">
                                            <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_DURABILITY_Detailinfo" href="#collapse_acc_DURABILITY_Detailinfo">
                                              Detailed Washing Information
                                            </a>
                                          </h4>
                                        </div>
                                        <div id="collapse_acc_DURABILITY_Detailinfo" className="panel-collapse collapse">
                                          <div className="panel-body border-red">
                                            <div className="col-md-12">
                                              <div className="row" >
                                                {getApperenceDetailedInformaion !== undefined && getApperenceDetailedInformaion.map((info, index) => (
                                                  <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                    <thead>
                                                      <tr>
                                                        <th rowspan="2" style={{ width: '25px' }}>#</th>
                                                        <th rowspan="2" style={{ width: '171px' }}>Details</th>
                                                        {info.ArrayActionheaders && info.ArrayActionheaders.map((Act, Actindex) => {
                                                          return (
                                                            <th colspan="1"> <button className='btn btn-xs btn-success' type='button' onClick={() => addApperenceSizeHeader(index, Actindex + 1, info)}>
                                                              <i className='fa fa-plus'></i></button>&nbsp;
                                                              {<button className='btn btn-xs btn-danger' type='button' onClick={() => RemoveApperenceHeader(index, Actindex)}><i className='fa fa-trash'></i></button>} </th>
                                                          )
                                                        })}
                                                      </tr>
                                                      <tr>
                                                        {info.ArrayAwheaders && info.ArrayAwheaders.map((head, headerindex) => {
                                                          return (
                                                            <>

                                                              <th style={{ width: '90px' }}> <Reactselect className="basic-single " name="IsProductionStatusID"
                                                                options={WashCountValues}
                                                                onChange={e => handleAppWashTypeChange(e, head, index, headerindex, WashCountValues.find(option => option.value === parseInt(e.value)))}
                                                                onBlur={e => handleAppDuplicateDetailWash(e, head, index, headerindex)}
                                                                value={WashCountValues.filter(function (option) {
                                                                  return option.value === head.Awvalue;
                                                                })}
                                                                isClearable={true}
                                                                classNamePrefix="washcount"
                                                                is
                                                              ></Reactselect></th>
                                                              {/* <th>{head.Name}</th> */}
                                                            </>
                                                          )
                                                        })}
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {
                                                        info.Gradingpoints !== undefined && info.Gradingpoints.map((x, Smindex) => (
                                                          <>
                                                            <tr>
                                                              <td>{Smindex + 1}</td>
                                                              <td> {x.fieldName}</td>
                                                              {x.GradingAwchangeIn.map((chil, chilindex) => (
                                                                <>

                                                                  <td>
                                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                                      options={getGradeValue}
                                                                      classNamePrefix="brand"
                                                                      isClearable={true}
                                                                      onChange={e => handleDimAppDetailwashChange(e, index, Smindex, chilindex)}
                                                                      //value={GetResult}
                                                                      value={getGradeValue.filter(function (option) {
                                                                        return option.value === chil.AwchangeIN;
                                                                      })}
                                                                    ></Reactselect>
                                                                  </td>
                                                                  {/* <td> <input type="number" maxLength='10' className='form-control' value={chil.AwchangeIN} onChange={e => handleDimAppDetailwashChange(e, index, Smindex, chilindex)} /></td> */}
                                                                </>
                                                              ))
                                                              }

                                                            </tr>
                                                          </>
                                                        ))}
                                                    </tbody>
                                                  </table>
                                                ))
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))
                : <tr><td colSpan="8" className='norecordfound'><span>No Records Found Please Check Masters</span></td></tr>
            }
          </form>
        </div>
        <br />
        <br />

        {
          getLabComments.map((x, index) => (
            <>
              <div className="row">
                <div className="col-lg-4">
                  <label htmlFor="">Lab Comments</label>
                  <br />

                  <Reactselect className="basic-single " name="IsProductionStatusID"
                    options={labResult}
                    isClearable={true}
                    onChange={e => handleLabResultOnchange(e, index, "labcomment")}
                    //value={GetResult}
                    styles={submittedlabcomments && getLabComments[0].value === 0 ? styles : ''}
                    value={labResult.filter(function (option) {
                      return option.value === x.value;
                    })}
                  ></Reactselect>
                </div>
                <div className="col-lg-12">
                  <label htmlFor="">Remarks</label>
                  <br />
                  <textarea name="" id=""
                    style={submittedlabremark && (getLabComments[0].value === 2 && getLabComments[0].Remarks === "") ? textareaStyles : {}}
                    onChange={e => handleLabResultOnchange(e, index, "Remarks")}
                    value={x.Remarks} cols="150" rows="10"></textarea>
                </div>
              </div>
            </>
          ))
        }
      </div>
      <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
        <div className="fixTableHeadTnadetail">
          <Modal.Header closeButton>
            <Modal.Title>TEST SELECTION POPUP</Modal.Title>
          </Modal.Header>
          {/* , overflowY: "auto" */}
          <Modal.Body className="customdialogbody">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="row">
                    {
                      gettestselect && gettestselect.map((fabric, index) => (
                        <div className="col-lg-6">
                          <li
                            className="listmodal"
                          >
                            <input
                              className="hidecheckbox"
                              type="checkbox"
                              name="IsShowAW"
                              checked={fabric.isChecked}
                              onChange={e => handleCheckboxChange(e, fabric.testName)}
                            />
                            &nbsp;<label htmlFor="IsShowAW">{fabric.testName}</label>
                          </li>

                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-info' onClick={handleClose}>Save</button>
          </Modal.Footer>
        </div>
      </Modal>
      {loader}
    </div>
  )
}

export default StrikeOff