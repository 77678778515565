
import React, { useState, useEffect, Fragment, useMemo } from "react";
import Reactselect from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import TableHeader from '../../Datatable/Header/QAauditinspectionHeader';
import StyleService from "../../../services/Style/styleService";
import TNAService from "../../../services/TNA/TNAService";
import moment from "moment";
import { DateRangePicker } from 'react-date-range';
import { GetTestEntryDetails } from "../../../actions/tna";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import Nodify from "./../../Common/ReactNotification";
import { Modal } from "react-bootstrap";
import SweetAlertPopup from "../../Common/SweetAlertPopup";
import { Pagination, PageCount } from "../../Datatable"
import { TestingMainlist } from "../../Common/PageActionNumber";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TestEntryComment } from "../../Common/PageActionNumber";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
const TestEntries = ({ props, Taskcallback }) => {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    const [totalItems, setTotalItems] = useState(0);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    const [getAddTestInspectionListParam, setTestAddInspectionListParam] = useState([]);
    const [getQARemark, setQARemark] = useState({ ReceivedDate: '', ApprovalResult: 0, Comments: '', Inspectionnodate: '' });
    const [getQARemarks, setQARemarks] = useState([]);
    const [getTNAPurchaseOrderID, setTNAPurchaseOrderID] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [getTestEntryStatus, setTestEntryStatus] = useState();
    const [Submitted, setSubmitted] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getIDcommentNew, setIDcommentNew] = useState({ showPopupDelete: false, Params: {} });
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    const comments = reducerState.tna.testingEntryDetailsList;
    const EmptyInputFields = {
        BuyerName: ""
        , SeasonName: ""
        , BrandName: ""
        , QuarterName: ""
        , SupplierName: ""
        , IDPONO: ""
        , StyleName: ""
        , SKUName: ""
        , TestID: ""
        , TestIDForEdit: []
        , TestEntryNo: ""
    };
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [getBrandOptions, setBrandOptions] = useState([]);
    const [getSKUOptions, setSKUOptions] = useState([]);
    const [getIDTestTask, setIDTestTask] = useState({ showPopupDelete: false, Params: {} });
    const [gettestBuyerbrandListParam, settestBuyerbrandListParam] = useState({});
    const [getQuarterOptions, setQuarterOptions] = useState([
        { id: 1, value: 1, label: "Q1", name: "Q1" }
        , { id: 2, value: 2, label: "Q2", name: "Q2" }
        , { id: 3, value: 3, label: "Q3", name: "Q3" }
        , { id: 4, value: 4, label: "Q4", name: "Q4" }]);
    const [getTestStatusOptions, setTestStatusOptions] = useState([
        { id: 1, value: 1, name: "Draft", label: "Draft" }
        , { id: 2, value: 2, name: "Satisfied", label: "Satisfied" }
        , { id: 3, value: 3, name: "Not Satisfied", label: "Not Satisfied" }
    ]);
    const [HideShow, setHideShow] = useState(false);
    const [getApprovalPopupShow, setApprovalPopupShow] = useState(false);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getTestInspectionInfo, setTestInspectionInfo] = useState({
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    });

    const [checkboxStates, setCheckboxStates] = useState({
        Reportdate: true,
        EntryNo: true,
        TestType: true,
        ReTestDoneAgainst: true,
        Supplier: true,
        SupplierRefNo: true,
        IDPONO: true,
        StrikeoffTrimName: true,
        StrikeoffCode: true,
        StyleName: true,
        SKUName: true,
        Image: true,
        LotNo: true,
        LotWeight: true,
        Quantity: true,
        TestResult: true,
        CreatedBy: true,
        ApprovalResult: true
    });

    let size = '130px';

    const Garmentheaders = [
        { name: "#", field: "", sortable: false, widthsize: '2%' },
        checkboxStates.Reportdate && ({ name: "Created Date", field: "reportDate", sortable: true, width: size, })
        , checkboxStates.EntryNo && ({ name: "Entry No", field: "testInspectionType", sortable: true, width: size, })
        // , checkboxStates.TestType && ({ name: "Test Type", field: "testType", sortable: true })
        // , checkboxStates.ReTestDoneAgainst && ({ name: "Re Test Done Against Report No.", field: "retestDoneAgainst", sortable: true,  width: size, })
        , checkboxStates.Supplier && ({ name: "Supplier", field: "supplier", sortable: true, width: size, })
        , checkboxStates.Supplier && ({ name: "Catagory", field: "fabricDetailName", sortable: true, width: size, })
        , checkboxStates.Supplier && ({ name: "Style Name", field: "styleName", sortable: true, width: size, })
        , checkboxStates.Supplier && ({ name: "SKU Name", field: "skuName", sortable: true, width: size, })
        // , checkboxStates.SupplierRefNo && ({ name: "Supplier Ref No.", field: "supplierRefNo", sortable: true ,  width: size,})
        , checkboxStates.IDPONO && ({ name: "PO/ID No.", field: "IdNo", sortable: true, width: size, })
        , checkboxStates.Image && ({ name: "Image", field: "swatchFilepath", sortable: true, width: size, })
        // , checkboxStates.LotNo && ({ name: "Lot No/Weight", field: "lotWeight", sortable: true, width: size, })
        , //checkboxStates.LotWeight && ({ name: "Lot Weight", field: "lotWeight", sortable: true ,  width: size,})
        , checkboxStates.Quantity && ({ name: "Order Qty", field: "testQty", sortable: true, width: size, })
        , checkboxStates.TestResult && ({ name: "Test Result", field: "testResult", sortable: true, width: size, })
        // , checkboxStates.CreatedBy && ({ name: "Created By", field: "createdBy", sortable: true ,  width: size,})
        , checkboxStates.ApprovalResult && ({ name: "Approval Result", field: "approvalResult", sortable: true, width: size, })
        , {
            name: "Action",
            field: "Action",
            className: "text-center fixed-column-header-Route",
            sortable: false,
            width: size,
            // width: "6%"
        }
    ];
    const headers = [
        { name: "#", field: "", sortable: false },
        checkboxStates.Reportdate && ({ name: "Created Date", field: "reportDate", sortable: true, width: size, })
        , checkboxStates.EntryNo && ({ name: "Entry No", field: "testInspectionType", sortable: true, width: size, })
        // , checkboxStates.TestType && ({ name: "Test Type", field: "testType", sortable: true })
        // , checkboxStates.ReTestDoneAgainst && ({ name: "Re Test Done Against Report No.", field: "retestDoneAgainst", sortable: true,  width: size, })
        , checkboxStates.Supplier && ({ name: "Supplier", field: "supplier", sortable: true, width: size, })
        , checkboxStates.Supplier && ({ name: (Taskcallback === 'FABRIC TEST' ? "Fabric Details" : 'Yarn Details'), field: "fabricDetailName", sortable: true, width: size, })
        , checkboxStates.Supplier && ({ name: "Color Name", field: "colorname", sortable: true, width: size, })
        , checkboxStates.Supplier && ({ name: "Style Name", field: "styleName", sortable: true, width: size, })
        // , checkboxStates.SupplierRefNo && ({ name: "Supplier Ref No.", field: "supplierRefNo", sortable: true ,  width: size,})
        , checkboxStates.IDPONO && ({ name: "PO/ID No.", field: "IdNo", sortable: true, width: size, })
        , checkboxStates.Image && ({ name: "Image", field: "swatchFilepath", sortable: true, width: size, })
        , checkboxStates.LotNo && ({ name: "Lot No/Weight", field: "lotWeight", sortable: true, width: size, })
        , //checkboxStates.LotWeight && ({ name: "Lot Weight", field: "lotWeight", sortable: true ,  width: size,})
        , checkboxStates.Quantity && ({ name: "Order Qty", field: "testQty", sortable: true, width: size, })
        , checkboxStates.TestResult && ({ name: "Test Result", field: "testResult", sortable: true, width: size, })
        // , checkboxStates.CreatedBy && ({ name: "Created By", field: "createdBy", sortable: true ,  width: size,})
        , checkboxStates.ApprovalResult && ({ name: "Approval Result", field: "approvalResult", sortable: true, width: size, })
        , {
            name: "Action",
            field: "Action",
            className: "text-center fixed-column-header-Route",
            sortable: false,
            width: size,
            // width: "6%"
        }
    ];
    const Strikeoffheaders = [
        { name: "#", field: "", sortable: false, widthsize: '2%' },
        checkboxStates.Reportdate && ({ name: "Created Date", field: "reportDate", sortable: true })
        , checkboxStates.EntryNo && ({ name: "Entry No", field: "testInspectionType", sortable: true })
        // , checkboxStates.ReTestDoneAgainst && ({ name: "Re Test Done Against Report No.", field: "retestDoneAgainst", sortable: true })
        , checkboxStates.Supplier && ({ name: "Supplier", field: "supplier", sortable: true })
        , checkboxStates.StyleName && ({ name: "Style Name", field: "styleName", sortable: true })
        // , checkboxStates.SupplierRefNo && ({ name: "Supplier Ref No.", field: "supplierRefNo", sortable: true })
        , checkboxStates.TestType && ({ name: (Taskcallback === 'Strike off Test' ? "Strike off Name" : 'Trim Name'), field: "strikeoffTrimName", sortable: true })
        , checkboxStates.StrikeoffCode && ({ name: (Taskcallback === 'Strike off Test' ? "Strike off Code" : 'Trim Code'), field: "strikeoffCode", sortable: true })
        , checkboxStates.IDPONO && ({ name: "PO/ID No", field: "IdNo", sortable: true })
        , checkboxStates.Image && ({ name: "Image", field: "swatchFilepath", sortable: true })
        // , checkboxStates.Quantity && ({ name: "Test Qty", field: "testQty", sortable: true })
        , checkboxStates.TestResult && ({ name: "Test Result", field: "testResult", sortable: true })
        // , checkboxStates.CreatedBy && ({ name: "Created By", field: "createdBy", sortable: true })
        , checkboxStates.ApprovalResult && ({ name: "Approval Result", field: "approvalResult", sortable: true })
        , {
            name: "Action",
            field: "Action",
            className: "text-center fixed-column-header-Route",
            sortable: false,
            width: "6%"
        }
    ];

    var defaultExStartDate = new Date();

    var defaultAppStartDate = new Date();
    const [stateTestDate, setStateTestDate] = useState([
        {
            startDate: defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    const [stateApprovalDate, setStateApprovalDate] = useState([
        {
            startDate: null, //defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            // startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);

    const handleBuyerChange = (e) => {
        let Values = [...inputFields];
        if (e !== null) {
            Values[0].BuyerID = e.value;
            Values[0].BuyerIDForEdit = e;
            StyleService.LoadBrandThemeStoryEndCustomer(5, e.value).then((response) => {
                if (response.data) {
                    setBrandOptions(response.data);
                }
            }).catch(() => { });
        }
        else if (e === null) {
            Values[0].BuyerID = 0;
            Values[0].BuyerIDForEdit = [];
            Values[0].BrandID = 0;
            Values[0].BrandIDForEdit = [];
            setBrandOptions([]);
        }
        setInputFields(Values);
    }

    const handleTextBoxChanges = (e, field) => {
        let Values = [...inputFields];
        let inputText = '';
        if (field == "BuyerName") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values[0].BuyerName = inputText;
        }
        else if (field == "SeasonName") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values[0].SeasonName = inputText;
        }
        else if (field == "BrandName") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values[0].BrandName = inputText;
        }
        else if (field == "QuarterName") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values[0].QuarterName = inputText;
        }
        else if (field == "SupplierName") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values[0].SupplierName = inputText;
        }
        else if (field == "IDPONO") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values[0].IDPONO = inputText;
        }
        else if (field == "StyleName") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values[0].StyleName = inputText;
        }
        else if (field == "SKUName") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values[0].SKUName = inputText;
        }
        else if (field == "TestEntryNo") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values[0].TestEntryNo = inputText;
        }
        setInputFields(Values);
    }

    const OnTestFilterChange = (e) => {
        let Values = [...inputFields];
        if (e != null) {
            Values[0].TestID = e.value;
            Values[0].TestIDForEdit = e;
        }
        else {
            Values[0].TestID = 0;
            Values[0].TestIDForEdit = [];
        }
        setInputFields(Values);
    }


    const ResetTestDetails = () => {
        setInputFields([EmptyInputFields]);
    }

    const handleTestDateSelect = (event, isShow) => {
        let getStateExDate = [...stateTestDate];
        let getStateApprovalDate = [...stateApprovalDate];
        getStateExDate[0].isShow = isShow;
        getStateApprovalDate[0].isShow = false;
        setStateTestDate(getStateExDate);
        setStateApprovalDate(getStateApprovalDate);
    }

    const handleApprovalDateSelect = (event, isShow) => {
        let getStateApprovalDate = [...stateApprovalDate];
        let getStateExDate = [...stateTestDate];
        getStateApprovalDate[0].isShow = isShow;
        getStateExDate[0].isShow = false;
        setStateApprovalDate(getStateApprovalDate);
        setStateTestDate(getStateExDate);
    }

    const clearTestDateSelect = () => {
        var defaultExStartDate = new Date();
        const emptyStateExDate = ([
            {
                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        setStateTestDate(emptyStateExDate);
        let StartExDate = null;
        let EndExDate = null;
        if (moment(emptyStateExDate[0].startDate).isValid()) {
            StartExDate = moment(emptyStateExDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(emptyStateExDate[0].endDate).isValid()) {
            EndExDate = moment(emptyStateExDate[0].endDate).format('MM/DD/YYYY');
        }
        dispatch(GetTestEntryDetails(IsProduction
            , Taskcallback
            , StartExDate
            , EndExDate
            , moment(stateApprovalDate[0].startDate).subtract(90, "days").format("MM/DD/YYYY")
            , moment(stateApprovalDate[0].endDate).subtract(1, "days").add(1, "days").format("MM/DD/YYYY")
            , 0));
    }


    const clearApprovalDateSelect = () => {
        var defaultExStartDate = new Date();
        const emptyStateExDate = ([
            {
                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        setStateApprovalDate(emptyStateExDate);
        let StartExDate = null;
        let EndExDate = null;
        if (moment(emptyStateExDate[0].startDate).isValid()) {
            StartExDate = moment(emptyStateExDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(emptyStateExDate[0].endDate).isValid()) {
            EndExDate = moment(emptyStateExDate[0].endDate).format('MM/DD/YYYY');
        }
        let params = {
            IsProduction: IsProduction,
            TaskDetailsName: Taskcallback
            , ExfacStartDate: StartExDate
            , ExfacEndDate: EndExDate
        }
    }

    function DateExfaconchange(item) {
        stateApprovalDate[0].startDate = null;;
        stateApprovalDate[0].endDate = new Date('')
        setStateApprovalDate(stateApprovalDate)

        // stateTestDate[0].startDate = item[0].startDate;
        // stateTestDate[0].endDate = item[0].endDate
        // setStateTestDate(stateTestDate)
        const updatedstateTestDate = [
            {
                ...stateTestDate[0],
                startDate: item[0].startDate,
                endDate: item[0].endDate
            }
        ];

        setStateTestDate(updatedstateTestDate);

        let StartExDate = null;
        let EndExDate = null;
        if (moment(updatedstateTestDate[0].startDate).isValid()) {
            StartExDate = moment(updatedstateTestDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(updatedstateTestDate[0].endDate).isValid()) {
            EndExDate = moment(updatedstateTestDate[0].endDate).format('MM/DD/YYYY');
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        dispatch(GetTestEntryDetails(IsProduction
            , Taskcallback
            , StartExDate
            , EndExDate
            , '01/01/1970'
            , formattedDate
            , 0));
    }
    function DateAppfaconchange(item) {
        stateTestDate[0].startDate = null;
        stateTestDate[0].endDate = new Date('');
        setStateTestDate(stateTestDate)
        // stateApprovalDate[0].startDate = item[0].startDate;
        // stateApprovalDate[0].endDate = item[0].endDate
        // setStateApprovalDate(stateApprovalDate)
        const updatedstateApprovalDate = [
            {
                ...stateApprovalDate[0],
                startDate: item[0].startDate,
                endDate: item[0].endDate
            }
        ];

        setStateApprovalDate(updatedstateApprovalDate);
        let StartAppDate = null;
        let AppEndDate = null;
        if (moment(updatedstateApprovalDate[0].startDate).isValid()) {
            StartAppDate = moment(updatedstateApprovalDate[0].startDate).format('MM/DD/YYYY');
        }
        // if (moment(stateApprovalDate[0].endDate).isValid()) {
        //     AppEndDate = moment(stateApprovalDate[0].endDate).format('MM/DD/YYYY');
        // }
        if (moment(updatedstateApprovalDate[0].endDate).isValid()) {
            AppEndDate = moment(updatedstateApprovalDate[0].endDate).format('MM/DD/YYYY');
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        dispatch(GetTestEntryDetails(IsProduction
            , Taskcallback
            , '01/01/1970'
            , formattedDate
            , StartAppDate
            , AppEndDate
            , 0));
    }
    const Reset = () => {

        //clearTestDateSelect();
        //clearApprovalDateSelect();
        setIsProductionStatussearch('');
        var defaultExStartDate = new Date();
        const emptyStateExDate = ([
            {
                startDate: defaultExStartDate.setDate(defaultExStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);
        setStateTestDate(emptyStateExDate);
        const ApprovalDate = ([
            {

                startDate: null,// defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
                endDate: new Date(''),
                key: 'selection',
                isShow: false
            }
        ]);
        setStateApprovalDate(ApprovalDate)
        let StartExDate = null;
        let EndExDate = null;
        if (moment(emptyStateExDate[0].startDate).isValid()) {
            StartExDate = moment(emptyStateExDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(emptyStateExDate[0].endDate).isValid()) {
            EndExDate = moment(emptyStateExDate[0].endDate).format('MM/DD/YYYY');
        }
        let StartApDate = null;
        let EndApDate = null;
        var defaultAppStartDate = new Date();
        defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
        if (moment(defaultAppStartDate).isValid()) {
            StartApDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApprovalDate[0].endDate).isValid()) {
            EndApDate = moment(stateApprovalDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        dispatch(GetTestEntryDetails(IsProduction
            , Taskcallback
            , StartExDate
            , EndExDate
            , StartApDate
            , formattedDate
            , 0));
        inputFields[0].QuarterName = ''
        inputFields[0].BuyerName = ""
        inputFields[0].SeasonName = ""
        inputFields[0].BrandName = ""
        inputFields[0].QuarterName = ""
        inputFields[0].SupplierName = ""
        inputFields[0].IDPONO = ""
        inputFields[0].StyleName = ""
        inputFields[0].SKUName = ""
        inputFields[0].TestID = ""
        inputFields[0].TestEntryNo = ""
        inputFields[0].TestIDForEdit = ''

        setInputFields(inputFields);

    }

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="MM/DD/YYYY"
            />
        )
    }

    const handleShow = () => setHideShow(true);

    const handleCloseHideShow = () => setHideShow(false);

    const OpenApprobalPopup = async (comment) => {
        const values = { ...getQARemark };
        values.Inspectionnodate = comment.insCreatedDate + ' - ' + comment.testInspectionID;
        setTestEntryStatus(comment);
        await TNAService.GetTestApprovalList1(comment.testInspectionID).then(async (response) => {
            if (response.data) {
                setQARemarks(response.data)
            }
            hideLoader();
        }).catch(() => { hideLoader(); });

        setQARemark(values);
        setApprovalPopupShow(true);
        setSubmitted(false);
    }

    const CloseApprobalPopup = () => {
        setApprovalPopupShow(false);
        setSubmitted(false);
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates({
            ...checkboxStates,
            [name]: checked
        });
    };
    function delCallback(value) {
        if (value) {
            showLoader();

            let StartTestDate = null;
            let EndTestDate = null;
            if (moment(stateTestDate[0].startDate).isValid()) {
                StartTestDate = moment(stateTestDate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateTestDate[0].endDate).isValid()) {
                EndTestDate = moment(stateTestDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }

            let AppStartDate = null;
            let AppEndDate = null;
            var defaultAppStartDate = new Date();
            defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
            if (moment(defaultAppStartDate).isValid()) {
                AppStartDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateApprovalDate[0].endDate).isValid()) {
                AppEndDate = moment(stateApprovalDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }

            const currentDate = new Date();
            const formattedDate = moment(currentDate).format('MM/DD/YYYY');

            dispatch(GetTestEntryDetails(IsProduction
                , Taskcallback
                , StartTestDate
                , EndTestDate
                , AppStartDate
                , formattedDate
                , 0));
            hideLoader();
        } else {

        }
    }
    function handleDeleteTestinspection(item) {

        let PackingParams = {
            Operation: 126,
            Id: item.testInspectionID,
        }
        setIDTestTask({ showPopupDelete: true, Params: PackingParams });
    }
    function handleTestInspecViewDetails(item, Type) {

        setTNAPurchaseOrderID(item);
        const TNAAddFinalInspectionList = reducerState.tna.tnaAddTestinginspection !== undefined ? reducerState.tna.tnaAddTestinginspection : [];
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.refInspecSKUID.toString();
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
            }
        });
        StyleService.GetStyleList(item.styleID, IsProduction, 0, '01/01/1949', moment(stateTestDate[0].endDate).format('MM/DD/YYYY')).then((response) => {
            if (response.data) {
                settestBuyerbrandListParam(response.data.item1[0])
            }
        }).catch(() => { });
        let params = {
            Operation: 2,
            BuyerId: item.buyerID,
            BrandId: item.brandID, SeasonId: item.seasonID,
            SupplierId: item.supplierID, TaskId: item.taskDetailsID,
            StyleId: item.styleID,
            SkuId: 0,
            TaskOwnerIdList: item.taskOwner,
            TaskHolderId: 0,
            tnaExFactoryId: item.tnaExFactoryId,
            status: 0,
            tnaid: item.tnaid,
            selectedDependency: '',
            //NewID is subID
            NewID: item.skuSubID,
            Isproduction: IsProduction
        };
        setTestAddInspectionListParam(params);
        getTestInspectionInfo.buyerName = item.buyerName;
        getTestInspectionInfo.buyerID = item.buyerID;
        getTestInspectionInfo.brandID = item.brandID;
        getTestInspectionInfo.brandName = item.brandName;
        getTestInspectionInfo.seasonID = item.seasonID;
        getTestInspectionInfo.seasonName = item.seasonName;
        getTestInspectionInfo.supplierID = item.supplierID;
        getTestInspectionInfo.supplierName = item.supplierName;
        getTestInspectionInfo.styleID = item.styleID;
        getTestInspectionInfo.styleName = item.styleName;
        getTestInspectionInfo.styleNo = item.styleNo;
        getTestInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getTestInspectionInfo.quantityinfo = { tnaid: item.tnaid, purchaseOrderID: item.purchaseOrderID, TaskId: item.taskDetailsID }// For Binding Selected checkbox values in quantity inforamtion
        getTestInspectionInfo.details = getAddTestInspectionListParam;
        getTestInspectionInfo.TnaID = item.tnaid;
        getTestInspectionInfo.type = item.typeInternorExtern;//For InterNal Or External
        getTestInspectionInfo.SkuID = sizeandqtyfordisplay;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
        getTestInspectionInfo.Isproduction = IsProduction;
        getTestInspectionInfo.TestingInspectionID = item.testInspectionID;
        getTestInspectionInfo.TaskStatus = 2;
        // getTestInspectionInfo.page = getTestingReportPageView;//For InspectionType(Reinspection Or New inspection)
        getTestInspectionInfo.pageType = Type;//For HeaderName Add,view or edit
        getTestInspectionInfo.TaskType = Taskcallback;
        getTestInspectionInfo.ParentTask = "TestingProd";//Task Based back or save redirect function
        props.history.push(`/createTesting`, { params: getTestInspectionInfo });
    }

    function handleChangeQARemarks(e, FieldName, result) {
        let inputText = '';
        const values = { ...getQARemark }
        if (FieldName === 'ReceivedDate') {
            if (e !== null) {
                values.ReceivedDate = e;
            }
            else {
                values.ReceivedDate = null;
            }
        } else if (FieldName === 'Approvalstatus') {
            values.ApprovalResult = result;
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values.Comments = inputText;
        }
        setQARemark(values);
    }

    function handleSaveQAApproval(e, comment) {
        const values = { ...getQARemark }
        if (values.ReceivedDate === '' || values.Comments === '' || values.ApprovalResult === 0 || !getQARemark.ReceivedDate) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            var CommentInformationData = [];
            CommentInformationData.push({
                QAApprovalID: 0,
                ApprovalID: getQARemark.ApprovalResult,
                ApprovalRemarks: getQARemark.Comments,
                InspectionNoanddate: getQARemark.Inspectionnodate,
                InspectionApprovalDate: getQARemark.ReceivedDate
                //IsLatest : 1
            });
            var data = {
                CreatedBy: currentUser.employeeinformationID, TestInspectionID: comment.testInspectionID,
                ApprovalListInformation: CommentInformationData, TaskNmae: Taskcallback
            };
            TNAService.InsertTestEntryDetailsComments(data).then((res) => {
                if (res.data.outputResult === "1") {
                    setApprovalPopupShow(false);
                    Nodify('Success!', 'success', "Comment Added for " + comment.testInspectionID + " Successfully");
                    showLoader();

                    let StartTestDate = null;
                    let EndTestDate = null;
                    if (moment(stateTestDate[0].startDate).isValid()) {
                        StartTestDate = moment(stateTestDate[0].startDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateTestDate[0].endDate).isValid()) {
                        EndTestDate = moment(stateTestDate[0].endDate).format('MM/DD/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }

                    let AppStartDate = null;
                    let AppEndDate = null;
                    var defaultAppStartDate = new Date();
                    defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
                    if (moment(defaultAppStartDate).isValid()) {
                        AppStartDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateApprovalDate[0].endDate).isValid()) {
                        AppEndDate = moment(stateApprovalDate[0].endDate).format('MM/DD/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }

                    const currentDate = new Date();
                    const formattedDate = moment(currentDate).format('MM/DD/YYYY');

                    dispatch(GetTestEntryDetails(IsProduction
                        , Taskcallback
                        , StartTestDate
                        , EndTestDate
                        , AppStartDate
                        , formattedDate
                        , 0));
                    hideLoader();
                    setQARemark();
                }
                else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }
            })

        }
    }

    function handleDeleteQAinsPopup(comment) {
        let commentparams = {
            Operation: TestEntryComment,
            Id: comment.testInspectionID,
        }
        setIDcommentNew({ showPopupDelete: true, Params: commentparams });
    }

    function delApprovalCallback1(value) {
        if (value === true) {
            let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
            setIsProductionStatussearch(Defaultvalue);
            showLoader();

            let StartTestDate = null;
            let EndTestDate = null;
            if (moment(stateTestDate[0].startDate).isValid()) {
                StartTestDate = moment(stateTestDate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateTestDate[0].endDate).isValid()) {
                EndTestDate = moment(stateTestDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }

            let AppStartDate = null;
            let AppEndDate = null;
            var defaultAppStartDate = new Date();
            defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
            if (moment(defaultAppStartDate).isValid()) {
                AppStartDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateApprovalDate[0].endDate).isValid()) {
                AppEndDate = moment(stateApprovalDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }

            const currentDate = new Date();
            const formattedDate = moment(currentDate).format('MM/DD/YYYY');

            dispatch(GetTestEntryDetails(IsProduction
                , Taskcallback
                , StartTestDate
                , EndTestDate
                , AppStartDate
                , formattedDate
                , 0));
            hideLoader();
            setApprovalPopupShow(false);
        }
        else {
            setIDcommentNew({ showPopupDelete: false, Params: [] });
        }
    }

    useEffect(() => {
        TNAService.LoadSKUNames().then((response) => {
            setSKUOptions(response.data);
        }).catch(() => { });
    }, []);

    useEffect(() => {

        showLoader();

        let StartTestDate = null;
        let EndTestDate = null;
        if (moment(stateTestDate[0].startDate).isValid()) {
            StartTestDate = moment(stateTestDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateTestDate[0].endDate).isValid()) {
            EndTestDate = moment(stateTestDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        let AppStartDate = null;
        let AppEndDate = null;
        var defaultAppStartDate = new Date();
        defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
        if (moment(defaultAppStartDate).isValid()) {
            AppStartDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApprovalDate[0].endDate).isValid()) {
            AppEndDate = moment(stateApprovalDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');

        dispatch(GetTestEntryDetails(IsProduction
            , Taskcallback
            , StartTestDate
            , EndTestDate
            , AppStartDate
            , formattedDate
            , 0));
        hideLoader();
    }, [IsProduction]);
    const options = {
        thumbnails: {
            showThumbnails: false,
        }
    }
    const commentsData = useMemo(() => {
        debugger
        let computedComments = comments;
        let Defaultvalue = { label: "Sampling", selected: true, value: 1 };
        setIsProductionStatussearch(Defaultvalue);
        if (comments != undefined && comments.length > 0) {
            if (inputFields[0].BuyerName !== null && inputFields[0].BuyerName != "") {
                computedComments = computedComments.filter(comment =>
                    comment.buyerName.toLowerCase().includes(inputFields[0].BuyerName.toLowerCase())
                );
            }
            if (inputFields[0].SeasonName !== null && inputFields[0].SeasonName != "") {
                computedComments = computedComments.filter(comment =>
                    comment.seasonName.toLowerCase().includes(inputFields[0].SeasonName.toLowerCase())
                );
            }
            if (inputFields[0].BrandName !== null && inputFields[0].BrandName != "") {
                computedComments = computedComments.filter(comment =>
                    comment.brandName.toLowerCase().includes(inputFields[0].BrandName.toLowerCase())
                );
            }
            if (inputFields[0].QuarterName !== null && inputFields[0].QuarterName != "") {
                computedComments = computedComments.filter(comment =>
                    comment.brandName.toLowerCase().includes(inputFields[0].QuarterName.toLowerCase())
                );
            }
            if (inputFields[0].SupplierName !== null && inputFields[0].SupplierName != "") {
                computedComments = computedComments.filter(comment =>
                    comment.brandName.toLowerCase().includes(inputFields[0].SupplierName.toLowerCase())
                );
            }
            if (inputFields[0].IDPONO !== null && inputFields[0].IDPONO != "") {
                computedComments = computedComments.filter(comment =>
                    comment.brandName.toLowerCase().includes(inputFields[0].IDPONO.toLowerCase())
                );
            }
            if (inputFields[0].StyleName !== null && inputFields[0].IDPONO != "") {
                computedComments = computedComments.filter(comment =>
                    comment.brandName.toLowerCase().includes(inputFields[0].StyleName.toLowerCase())
                );
            }
            if (inputFields[0].SKUName !== null && inputFields[0].SKUName != "") {
                computedComments = computedComments.filter(comment =>
                    comment.brandName.toLowerCase().includes(inputFields[0].SKUName.toLowerCase())
                );
            }
            if (inputFields[0].TestIDForEdit != '' && inputFields[0].TestIDForEdit !== null) {
                debugger
                computedComments = computedComments.filter(comment =>
                    comment.inspectionResult.toLowerCase().includes(inputFields[0].TestIDForEdit.label.toLowerCase())
                );
            }
            if (sorting.field) {
                const reversed = sorting.order === "asc" ? 1 : -1;
                computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
            }
            let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
            if (records.length === 0 && currentPage !== 1) {
                setCurrentPage(currentPage - 1);
            }
        }
        setTotalItems(computedComments.length);
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));

    }, [comments, inputFields])

    return (
        <>
            <div className="page-body">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget">
                                <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12 col-xs-12">
                                            <div className="widget-body no-padding">
                                                <div className="widget-main ">
                                                    <div className="panel-group accordion" id="accordion">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading ">
                                                                <h4 className="panel-title">
                                                                    <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                        Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                    </a>
                                                                </h4>
                                                            </div>
                                                            <div id="collapseOne" className="panel-collapse collapse in">
                                                                <div className="panel-body border-red">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="col-md-2">
                                                                                <label>Buyer</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Buyer'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Buyer'}
                                                                                        value={inputFields[0].BuyerName}
                                                                                        onChange={event => { handleTextBoxChanges(event, "BuyerName") }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Season</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Season'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Season'}
                                                                                        value={inputFields[0].SeasonName}
                                                                                        onChange={event => { handleTextBoxChanges(event, "SeasonName") }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Brand</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Brand'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Brand'}
                                                                                        value={inputFields[0].BrandName}
                                                                                        onChange={event => { handleTextBoxChanges(event, "BrandName") }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Quarter</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Quarter'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Quarter'}
                                                                                        value={inputFields[0].QuarterName}
                                                                                        onChange={event => { handleTextBoxChanges(event, "QuarterName") }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Supplier</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Supplier'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Supplier'}
                                                                                        value={inputFields[0].SupplierName}
                                                                                        onChange={event => { handleTextBoxChanges(event, "SupplierName") }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>PO/ID NO.</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'PO/ID NO'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'PO/ID NO'}
                                                                                        value={inputFields[0].IDPONO}
                                                                                        onChange={event => { handleTextBoxChanges(event, "IDPONO") }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>&nbsp;
                                                                    <div className="row">
                                                                        <div className="col-md-12">

                                                                            <div className="col-md-2">
                                                                                <label>&nbsp;</label>
                                                                                <label>Style Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'StyleName'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Style Name'}
                                                                                        value={inputFields[0].StyleName}
                                                                                        onChange={event => { handleTextBoxChanges(event, "StyleName") }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>&nbsp;</label>
                                                                                <label>SKU Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'SKUName'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'SKU Name'}
                                                                                        value={inputFields[0].SKUName}
                                                                                        onChange={event => { handleTextBoxChanges(event, "SKUName") }}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Test Filter</label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <Reactselect className="basic-single reportstatus" name="test"
                                                                                        isClearable={true}
                                                                                        options={getTestStatusOptions}
                                                                                        onChange={event => { OnTestFilterChange(event) }}
                                                                                        value={inputFields[0].TestIDForEdit}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>

                                                                            <div className="col-md-2">
                                                                                <label>Test Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateTestDate[0].startDate === null ? '' : moment(stateTestDate[0].startDate).format('MM/DD/YYYY') + ' To ') +
                                                                                            ((stateTestDate[0].endDate === null || !moment(stateTestDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateTestDate[0].endDate).format('MM/DD/YYYY'))}
                                                                                        onClick={event => handleTestDateSelect(event, !stateTestDate[0].isShow)}
                                                                                        type="text"
                                                                                        className={'form-control adjustabledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times" onClick={clearTestDateSelect} ></i>
                                                                                </span>
                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateTestDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            onChange={item => DateExfaconchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateTestDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}
                                                                                        />
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Approval Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateApprovalDate[0].startDate === null ? '' : moment(stateApprovalDate[0].startDate).format('MM/DD/YYYY') + ' To ') +
                                                                                            ((stateApprovalDate[0].endDate === null || !moment(stateApprovalDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateApprovalDate[0].endDate).format('MM/DD/YYYY'))}
                                                                                        onClick={event => handleApprovalDateSelect(event, !stateApprovalDate[0].isShow)}
                                                                                        type="text"
                                                                                        className={'form-control adjustabledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times" onClick={clearApprovalDateSelect} ></i>
                                                                                </span>
                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateApprovalDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            onChange={item => DateAppfaconchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateApprovalDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker TNADateRangeStyle'}
                                                                                        />
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Test Entry No.</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <div className="col-md-3" ></div>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'TestEntryNo'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Test Entry No.'}
                                                                                        value={inputFields[0].TestEntryNo}
                                                                                        onChange={event => { handleTextBoxChanges(event, "TestEntryNo") }}
                                                                                    />

                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="row">
                                                                        <div className="col-md-12">

                                                                        </div>
                                                                    </div>
                                                                    &nbsp; */}
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            {/* <div className="col-md-9">

                                                                            </div> */}
                                                                            <div className="col-md-2" style={{ marginTop: "15px" }}>
                                                                                <button className="btn btn-xs btn-danger" onClick={Reset}><i className="fa fa-times"></i>&nbsp;Reset</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                &nbsp;
                                <div className="row row_left10" style={{ width: '100%' }}>
                                    <div className="col-md-12">
                                        <div style={{ float: 'right' }}>
                                            <button title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>
                                            {commentsData != undefined && commentsData.length > 0 && <ReactHTMLTableToExcel
                                                id="test-table-s-button-final"
                                                className="fa fa-file-excel-o  btn btn-success excelcomt"
                                                table={"table-to-xls"}
                                                filename={"TestEntryDetailsList"}
                                                sheet="TestEntryDetails"
                                                buttonText=""
                                            />}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ overflow: 'auto', marginTop: '1%' }}>
                                    <table className="table table-striped table-bordered table-hover dataTable no-footer table-responsive" data-role="table" data-mode="columntoggle" id="table-to-xls" style={{ width: '110vw' }}>
                                        <TableHeader headers={(Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test') ? Strikeoffheaders : Taskcallback === 'GARMENT TEST' ? Garmentheaders : headers} onSorting={(field, order) => setSorting({ field, order })} />
                                        {
                                            <tbody >
                                                {
                                                    commentsData !== undefined && commentsData.map((comment, index) => (
                                                        <Fragment key={index}>
                                                            <>
                                                                {Taskcallback === 'Strike off Test' || Taskcallback === 'Trims Test' ? <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{comment.reportDate}</td>
                                                                    {checkboxStates.EntryNo && <td>{comment.testInspectionType}</td>}
                                                                    {/* {checkboxStates.TestType && <td>{comment.reportType}</td>} */}
                                                                    {/* {checkboxStates.ReTestDoneAgainst && <td className="text-danger">{comment.reInspectionAgainst === 0 || comment.reInspectionAgainst === null ? '-' : comment.reInspectionAgainst}</td>}*/}
                                                                    {checkboxStates.Supplier && <td>{comment.supplierName}</td>}
                                                                    {checkboxStates.StyleName && <td>{comment.styleName}</td>}
                                                                    {checkboxStates.StrikeoffTrimName && <td className="cut_textInTNA" title={comment.strikeoffTrimName}>{(comment.strikeoffTrimName === '' || comment.strikeoffTrimName === null) ? '-' : comment.strikeoffTrimName}</td>}
                                                                    {checkboxStates.StrikeoffCode && <td>{comment.strikeoffTrimcode}</td>}
                                                                    {/* {checkboxStates.SupplierRefNo && <td >{comment.supplierRefNo}</td>} */}
                                                                    {checkboxStates.IDPONO && <td>{comment.idpono}</td>}
                                                                    {checkboxStates.Image && <td>{< SimpleReactLightbox >
                                                                        <SRLWrapper options={options}>
                                                                            {
                                                                                < img className="width_manual"
                                                                                    src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.swatchFilepath}
                                                                                // alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)}
                                                                                />

                                                                            }

                                                                        </SRLWrapper>
                                                                    </SimpleReactLightbox>}</td>}
                                                                    {/* {checkboxStates.Quantity && <td>{comment.inspectionQty}</td>} */}
                                                                    {checkboxStates.ApprovalResult && <td className={(comment.inspectionResult === 'Satisfied' || comment.inspectionResult === 'Save') ? 'success' : comment.inspectionResult === 'Not Satisfied' ? 'danger' : 'warning'}>{comment.inspectionResult + ' By ' + comment.createdBy}</td>}
                                                                    {/* {checkboxStates.CreatedBy && <td>{comment.createdBy}</td>} */}
                                                                    {checkboxStates.ApprovalResult && <td> <span className={comment.approvalResult === 'Approved' ? 'success' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span></td>}
                                                                    <td className="text-center fixed-column-header-CommentReport" style={{ width: '180px' }}>
                                                                        <button className="btn btn-xs btn-success" title="Edit" onClick={() => handleTestInspecViewDetails(comment, 'EditInspection')}><i className="fa fa-edit"></i></button>&nbsp;
                                                                        {comment.approvalResult !== 'Approved' && <button className="btn btn-xs btn-danger" title="Delete" onClick={() => handleDeleteTestinspection(comment, 'Delete')}><i className="fa fa-trash"></i></button>}&nbsp;
                                                                        {comment.inspectionResult === 'Satisfied' && <button className={comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"}
                                                                            title="Test Approved" onClick={() => OpenApprobalPopup(comment)}><i className="fa fa-check"></i></button>}&nbsp;
                                                                        <button className="btn btn-xs btn-blue" title="View" onClick={() => handleTestInspecViewDetails(comment, 'ViewInspection')}><i className="fa fa-eye"></i></button>&nbsp;
                                                                        <button className="btn btn-xs btn-magenta" title="PDF"><i className="fa fa-file-pdf-o"></i></button>&nbsp;
                                                                    </td>
                                                                </tr> : Taskcallback === 'GARMENT TEST' ? <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{comment.reportDate}</td>
                                                                    {checkboxStates.EntryNo && <td>{comment.testInspectionType}</td>}
                                                                    {/* {checkboxStates.TestType && <td>{comment.reportType}</td>} */}
                                                                    {/* {checkboxStates.ReTestDoneAgainst && <td className="text-danger">{comment.reInspectionAgainst === 0 || comment.reInspectionAgainst === null ? '-' : comment.reInspectionAgainst}</td>} */}
                                                                    {checkboxStates.Supplier && <td>{comment.supplierName}</td>}
                                                                    {checkboxStates.Supplier && <td title={comment.fabricDetailName}>{comment.fabricDetailName}</td>}
                                                                    {checkboxStates.StyleName && <td>{comment.styleName}</td>}
                                                                    {checkboxStates.SKUName && <td>{comment.skuName}</td>}
                                                                    {/* {checkboxStates.SupplierRefNo && <td >{comment.supplierRefNo}</td>} */}
                                                                    {checkboxStates.IDPONO && <td>{(comment.idpono === '' || comment.idpono === null) ? '-' : comment.idpono}</td>}

                                                                    {checkboxStates.Image && <td>{< SimpleReactLightbox >
                                                                        <SRLWrapper options={options}>
                                                                            {
                                                                                < img className="width_manual"
                                                                                    src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.swatchFilepath}
                                                                                //alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)}
                                                                                />

                                                                            }
                                                                        </SRLWrapper>
                                                                    </SimpleReactLightbox>}</td>}
                                                                    {/* {checkboxStates.LotNo && <td >{comment.lotWeight}</td>} */}
                                                                    {/* {checkboxStates.LotWeight && <td>{comment.lotWeight}</td>} */}
                                                                    {checkboxStates.Quantity && <td>{comment.inspectionQty}</td>}
                                                                    {checkboxStates.ApprovalResult && <td className={comment.inspectionResult === 'Satisfied' ? 'success' : comment.inspectionResult === 'Not Satisfied' ? 'danger' : 'warning'}>{comment.inspectionResult}</td>}
                                                                    {/* {checkboxStates.CreatedBy && <td>{comment.createdBy}</td>} */}
                                                                    {checkboxStates.ApprovalResult && <td> <span className={comment.approvalResult === 'Approved' ? 'success' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span></td>}
                                                                    <td className="text-center fixed-column-header-CommentReport" style={{ width: '180px' }}>
                                                                        <button className="btn btn-xs btn-success" title="Edit" onClick={() => handleTestInspecViewDetails(comment, 'EditInspection')}><i className="fa fa-edit"></i></button>&nbsp;
                                                                        {comment.approvalResult !== 'Approved' && <button className="btn btn-xs btn-danger" title="Delete" onClick={() => handleDeleteTestinspection(comment, 'Delete')}><i className="fa fa-trash"></i></button>}&nbsp;
                                                                        {comment.inspectionResult === 'Satisfied' && <button className={comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"}
                                                                            title="Test Approval" onClick={() => OpenApprobalPopup(comment)}><i className="fa fa-check"></i></button>}&nbsp;
                                                                        <button className="btn btn-xs btn-blue" title="View" onClick={() => handleTestInspecViewDetails(comment, 'ViewInspection')}><i className="fa fa-eye"></i></button>&nbsp;
                                                                        <button className="btn btn-xs btn-magenta" title="PDF"><i className="fa fa-file-pdf-o"></i></button>&nbsp;
                                                                    </td>
                                                                </tr> : <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{comment.reportDate}</td>
                                                                    {checkboxStates.EntryNo && <td>{comment.testInspectionType}</td>}
                                                                    {/* {checkboxStates.TestType && <td>{comment.reportType}</td>} */}
                                                                    {/* {checkboxStates.ReTestDoneAgainst && <td className="text-danger">{comment.reInspectionAgainst === 0 || comment.reInspectionAgainst === null ? '-' : comment.reInspectionAgainst}</td>} */}
                                                                    {checkboxStates.Supplier && <td>{comment.supplierName}</td>}
                                                                    {checkboxStates.Supplier && <td title={comment.fabricDetailName}>{comment.fabricDetailName}</td>}
                                                                    {checkboxStates.Supplier && <td>{(comment.colorname === '' || comment.colorname === null) ? '-' : comment.colorname}</td>}
                                                                    {checkboxStates.StyleName && <td>{comment.styleName}</td>}
                                                                    {/* {checkboxStates.IDPONO && <td>{comment.idpono}</td>} */}
                                                                    {/* {checkboxStates.SupplierRefNo && <td >{comment.supplierRefNo}</td>} */}
                                                                    {checkboxStates.IDPONO && <td>{(comment.idpono === '' || comment.idpono === null) ? '-' : comment.idpono}</td>}
                                                                    {checkboxStates.Image && <td>{< SimpleReactLightbox >
                                                                        <SRLWrapper options={options}>
                                                                            {
                                                                                < img className="width_manual"
                                                                                    src={comment.skuImagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.swatchFilepath}
                                                                                //alt={comment.skuImage.length > 32 ? comment.skuImage.substr(33) : comment.skuImage.substr(5)}
                                                                                />

                                                                            }

                                                                        </SRLWrapper>
                                                                    </SimpleReactLightbox>}</td>}
                                                                    {checkboxStates.LotNo && <td >{comment.lotWeight}</td>}
                                                                    {/* {checkboxStates.LotWeight && <td>{comment.lotWeight}</td>} */}
                                                                    {checkboxStates.Quantity && <td>{comment.inspectionQty}</td>}
                                                                    {checkboxStates.ApprovalResult && <td className={comment.inspectionResult === 'Satisfied' ? 'success' : comment.inspectionResult === 'Not Satisfied' ? 'danger' : 'warning'}>{comment.inspectionResult}</td>}
                                                                    {/* {checkboxStates.CreatedBy && <td>{comment.createdBy}</td>} */}
                                                                    {checkboxStates.ApprovalResult && <td> <span className={comment.approvalResult === 'Approved' ? 'success' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span></td>}
                                                                    <td className="text-center fixed-column-header-CommentReport" style={{ width: '180px' }}>
                                                                        <button className="btn btn-xs btn-success" title="Edit" onClick={() => handleTestInspecViewDetails(comment, 'EditInspection')}><i className="fa fa-edit"></i></button>&nbsp;
                                                                        {comment.approvalResult !== 'Approved' && <button className="btn btn-xs btn-danger" title="Delete" onClick={() => handleDeleteTestinspection(comment, 'Delete')}><i className="fa fa-trash"></i></button>}&nbsp;
                                                                        {comment.inspectionResult === 'Satisfied' && <button className={comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"}
                                                                            title="Approve" onClick={() => OpenApprobalPopup(comment)}><i className="fa fa-check"></i></button>}&nbsp;
                                                                        <button className="btn btn-xs btn-blue" title="View" onClick={() => handleTestInspecViewDetails(comment, 'ViewInspection')}><i className="fa fa-eye"></i></button>&nbsp;
                                                                        <button className="btn btn-xs btn-magenta" title="PDF"><i className="fa fa-file-pdf-o"></i></button>&nbsp;
                                                                    </td>
                                                                </tr>}
                                                            </>
                                                        </Fragment>
                                                    ))
                                                }
                                                {
                                                    HideShow && <Modal show={HideShow} onHide={handleCloseHideShow} backdrop="static" dialogClassName='customdialog'>
                                                        <Modal.Header closeButton >
                                                            <Modal.Title>
                                                                Show and Hide
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="row">
                                                                <div className="col-lg-12"></div>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="EntryNo"
                                                                                checked={checkboxStates.EntryNo}

                                                                            />
                                                                            &nbsp;<label htmlFor="podate">Entry No</label>
                                                                        </li>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="TestType"
                                                                                checked={checkboxStates.TestType}

                                                                            />
                                                                            &nbsp;<label htmlFor="podate">Test Type</label>
                                                                        </li>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="ReTestDoneAgainst"
                                                                                checked={checkboxStates.ReTestDoneAgainst}

                                                                            />
                                                                            &nbsp;<label htmlFor="podate">Re Test Done Against</label>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="Supplier"
                                                                                checked={checkboxStates.Supplier}

                                                                            />
                                                                            &nbsp;<label htmlFor="podate">Supplier Name</label>
                                                                        </li>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="SupplierRefNo"
                                                                                checked={checkboxStates.SupplierRefNo}
                                                                            />
                                                                            &nbsp;<label htmlFor="podate">Supplier Ref No</label>
                                                                        </li>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="IDPONO"
                                                                                checked={checkboxStates.IDPONO}

                                                                            />
                                                                            &nbsp;<label htmlFor="podate">IDPONO</label>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="Image"
                                                                                checked={checkboxStates.Image}

                                                                            />
                                                                            &nbsp;<label htmlFor="podate">Image</label>
                                                                        </li>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="LotNo"
                                                                                checked={checkboxStates.LotNo}

                                                                            />
                                                                            &nbsp;<label htmlFor="podate">Lot No.</label>
                                                                        </li>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="LotWeight"
                                                                                checked={checkboxStates.LotWeight}

                                                                            />
                                                                            &nbsp;<label htmlFor="podate">Lot Weight</label>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="Quantity"
                                                                                checked={checkboxStates.Quantity}

                                                                            />
                                                                            &nbsp;<label htmlFor="podate">Quantity</label>
                                                                        </li>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <li className="listmodal">
                                                                            <input
                                                                                onChange={handleCheckboxChange}
                                                                                className="hidecheckbox"
                                                                                type="checkbox"
                                                                                name="TestResult"
                                                                                checked={checkboxStates.TestResult}

                                                                            />
                                                                            &nbsp;<label htmlFor="podate">Test Result</label>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                }
                                                {
                                                    getApprovalPopupShow &&
                                                    <Modal show={getApprovalPopupShow} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseApprobalPopup()}>
                                                        <Modal.Header closeButton >
                                                            <Modal.Title>
                                                                Test Entry Report Approval
                                                            </Modal.Title>
                                                            {
                                                                getQARemarks.length >= 1 ?
                                                                    <button style={{ marginLeft: "50rem" }} type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                        onClick={() => handleDeleteQAinsPopup(getTestEntryStatus)}
                                                                    >
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>
                                                                    : ''
                                                            }
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            {getTestEntryStatus.approvalResult === null &&
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Inspection Report No & Date</th>
                                                                                    <th>Inspection Approval Date<span className="text-danger">*</span></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <input type="text" name="ItemName"
                                                                                            id="ItemName"
                                                                                            value={getTestEntryStatus.insCreatedDate + ' - ' + getTestEntryStatus.testInspectionID}
                                                                                            maxLength="25"
                                                                                            className={
                                                                                                'form-control'
                                                                                            }
                                                                                            autoFocus
                                                                                            placeholder="Enter Item Name"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right"
                                                                                            style={{ border: Submitted && !getQARemark.ReceivedDate ? '1px solid red' : '' }}>
                                                                                            <DatePicker className="form-control" name="ReceivedDate"
                                                                                                id={
                                                                                                    "ReceivedDate"
                                                                                                }
                                                                                                selected={
                                                                                                    getQARemark.ReceivedDate
                                                                                                }
                                                                                                onChange={
                                                                                                    event => handleChangeQARemarks(event, "ReceivedDate", 1)
                                                                                                }
                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                peekNextMonth
                                                                                                dropdownMode="scroll"
                                                                                                autoComplete="off"
                                                                                                customInput={<CustomInput />}
                                                                                                isClearable={getQARemark.ReceivedDate === null ? false : true}
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <span><label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Approval Result<span className="text-danger">*</span></label></span>
                                                                    </div>
                                                                    <br />
                                                                    <div className="col-lg-12">
                                                                        <div className="col-sm-4">
                                                                            <button className={"btn btn-lg btn-block"} style={{ backgroundColor: getQARemark.ApprovalResult === 1 ? '#53a93f' : '', color: getQARemark.ApprovalResult === 1 ? 'white' : '#444444' }} onClick={event => { handleChangeQARemarks(event, 'Approvalstatus', 1) }}>Approved</button>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <button className={"btn btn-lg btn-block"} style={{ backgroundColor: getQARemark.ApprovalResult === 2 ? '#f4b400' : '', color: getQARemark.ApprovalResult === 2 ? 'white' : '#444444' }} onClick={event => { handleChangeQARemarks(event, 'Approvalstatus', 2) }}>Approved With Comments</button>
                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <button className={'btn  btn-lg btn-block'} style={{ backgroundColor: getQARemark.ApprovalResult === 3 ? '#d73d32' : '', color: getQARemark.ApprovalResult === 3 ? 'white' : '#444444' }} onClick={event => { handleChangeQARemarks(event, 'Approvalstatus', 3) }}>Recommended for Re Test</button>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="col-lg-12">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <label htmlFor="">
                                                                                    Approval Remarks<span className="text-danger">*</span>
                                                                                </label>
                                                                                <br />
                                                                                <textarea
                                                                                    cols={110}
                                                                                    rows="4"
                                                                                    placeholder="Enter comments here..."
                                                                                    autoFocus onFocus={''} autoComplete="off"
                                                                                    maxLength="1000" name="EnterComment"
                                                                                    id={"EnterComment"}
                                                                                    value={getQARemark.Comments}
                                                                                    onChange={event => handleChangeQARemarks(event, "Comments", 1)}
                                                                                    style={{ width: "100%", border: Submitted && (getQARemark.Comments === '') ? '1px solid red' : '' }}
                                                                                >
                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {getTestEntryStatus.approvalResult !== null && <div className="col-md-12" >
                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Approval Date</th>
                                                                            <th>Approval Result</th>
                                                                            <th>Inspection Approval Date</th>
                                                                            <th>Inspection Report No & Date</th>
                                                                            <th>Approval Remark</th>
                                                                            <th>Created By</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            getQARemarks.length === 0 ?
                                                                                <tr>
                                                                                    <td colSpan="6" className='norecordfound'><span>No Records Found</span></td>
                                                                                </tr> :
                                                                                getQARemarks.map((Commentitem) => {
                                                                                    return (

                                                                                        <tr>
                                                                                            <td>
                                                                                                {Commentitem.createdDate}
                                                                                            </td>
                                                                                            <td className={Commentitem.approvalID === 1 ? 'text-success' : Commentitem.approvalID === 2 ? 'text-warning' : 'text-danger'}>
                                                                                                <b>{Commentitem.approvalID === 1 ? 'Approved' : Commentitem.approvalID === 2 ? 'Approved With Comments' : 'Recommended for Re Audit'}</b>
                                                                                            </td>

                                                                                            <td>
                                                                                                {Commentitem.inspectionApprovalDate}
                                                                                            </td>
                                                                                            <td>
                                                                                                {Commentitem.inspectionNoanddate}
                                                                                            </td>
                                                                                            <td>
                                                                                                {Commentitem.approvalRemarks}
                                                                                            </td>
                                                                                            <td>
                                                                                                {Commentitem.createdBy}
                                                                                            </td>

                                                                                        </tr>

                                                                                    );
                                                                                })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>}
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <div className="col-md-12" style={{ marginTop: "10px" }}>
                                                                {
                                                                    getTestEntryStatus.approvalResult === null &&
                                                                    <button className="btn btn-xs btn-success" onClick={(event) => handleSaveQAApproval(event, getTestEntryStatus)}><i className="fa fa-save"></i>&nbsp;Save</button>}
                                                                <button className="btn btn-xs btn-danger" onClick={() => CloseApprobalPopup()}><i className="fa fa-times"></i>&nbsp;Close</button>
                                                            </div>
                                                        </Modal.Footer>
                                                    </Modal>

                                                }
                                                {
                                                    commentsData.length === 0 ?
                                                        <tr>
                                                            <td colSpan="22" className='norecordfound'><span>No Records Found</span></td>
                                                        </tr> : ''
                                                }
                                            </tbody>
                                        }
                                    </table>
                                    {
                                        getIDTestTask.showPopupDelete ? <SweetAlertPopup data={
                                            getIDTestTask.Params
                                        }
                                            deleteCallback={delCallback}
                                            showpopup={true}
                                            pageActionId={TestingMainlist}
                                            Msg={"Test Inspection deleted successfully"} /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <PageCount onPageCount={
                        value => {
                            setITEMS_PER_PAGE(value);
                            setCurrentPage(1);
                        }
                    } />
                    <Pagination total={totalItems}
                        itemsPerPage={
                            parseInt(ITEMS_PER_PAGE)
                        }
                        currentPage={currentPage}
                        onPageChange={
                            page => setCurrentPage(page)
                        } />
                    {
                        getIDcommentNew.showPopupDelete ? <SweetAlertPopup data={
                            getIDcommentNew.Params
                        }
                            deleteCallback={delApprovalCallback1}
                            showpopup={true}
                            pageActionId={TestEntryComment}
                            Msg={"Test Approval comment deleted successfully"} /> : null
                    }
                </div>
                {loader}
            </div >
        </>
    )
}

export default TestEntries