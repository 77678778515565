import React, { useState, useEffect, Fragment } from 'react';
import QcInspection from '../../../services/QCInspection/QcInspection';
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { TrimandAccesssories } from './PPMeeting/TrimandAccesssories';
import PPWorkmanshipList from './PPMeeting/PPWorkmanshipList';
import { Modal, Button } from "react-bootstrap";
import Reactselect from 'react-select';
import Nodify from "../../Common/ReactNotification";
import { values } from 'pdf-lib';
import moment from "moment";
import ProtoFitSMSApproval from '../../TNA/ProtoFitSMSApproval';
const AddEditPPMeeting = ({ props, rerenderdep, QualityinfoCallback, DropDownListCallback, TrimCallback, WorkManshipCallback, validtioneff, IsvalidationWrk }) => {
    debugger
    const [getFinalInspectionPopup, setFinalInspectionPopup] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [selectall, setselectall] = useState(false);
    const [getTotalValues, setTotalValues] = useState({ PoQty: 0 });
    const [getInspectionQtyInfo, setInspectionQtyInfo] = useState([]);
    const [getMeetingDDlist, setMeetingDDlist] = useState([]);
    const [getParticipantslist, setParticipantslist] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getInputFeildlist, setInputFeildlist] = useState({ TNAQCInspecPPmeetingID: 0, ParticipantsID: 0, FindingRemarks: '', MeetingID: 0, ScopeRemarks: '' });
    const [getrerenderstate, setrerenderstate] = useState(0);
    const [getSKUSampleApproval, setSKUSampleApproval] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getParentTask, setParentTask] = useState();
    const [getViewSamplesApproval, setViewSamplesApproval] = useState({ isShow: false, value: '' });
    const QcinpsectionId = JSON.parse(localStorage.getItem("QCInspectionID"));
    const Values = { ...getInputFeildlist }
    useEffect(() => { setSubmitted(validtioneff) }, [validtioneff === true])

    useEffect(() => {
        var MeetingConclusionList = [];
        var MeetingParticipantsList = [];
        var newvalue = [];
        QcInspection.GetTNAPPApprovalList(props.location.state.params.styleID).then((response) => {
            if (response.data) {
                setSKUSampleApproval(response.data);
            }
        }).catch(() => { hideLoader(); });
        QcInspection.LoadPPmeetingDpList().then((res) => {
            if (res.data) {
                res.data.ppMeetingConclusionList.map((x) => {
                    MeetingConclusionList.push({
                        value: x.meetingConID,
                        label: x.meetingConName
                    })
                })
                setMeetingDDlist(MeetingConclusionList);
            }
        })

        QcInspection.GetPPMeetingTrimList(2, props.location.state.params.styleID, props.location.state.params.supplierID).then((res) => {
            res.data.inputPPParticipants.map((x, index) => {
                MeetingParticipantsList.push({
                    value: x.employeeID,
                    label: x.employeeName.replace('(Company)', '').trim()
                })
            })
            setParticipantslist(MeetingParticipantsList)
        })

        QcInspection.getAllQcInfoList(4, props.location.state.params.details.tnaid, props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID).then((res) => {
           
            if (res.data.qcppDropDownListDetails) {
                res.data.qcppDropDownListDetails.map((x) => {
                    Values.FindingRemarks = x.findingRemarks;
                    Values.ParticipantsID = x.participantsID;
                    Values.MeetingID = x.meetingID;
                    Values.ScopeRemarks = x.scopeRemarks;
                    Values.TNAQCInspecPPmeetingID = x.tnaqcInspecPPmeetingID
                })
                setInputFeildlist(Values)
                DropDownListCallback(Values);
            }
        })
        QcInspection.getAllQcInfoList(2, props.location.state.params.details.tnaid, props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID).then((res) => {
           
            let newvalue = [];
            if (res.data.qCinspectionSkuQualityDetails.length > 0) {
                res.data.qCinspectionSkuQualityDetails.map((x) => {
                    newvalue.push({
                        TNAQCinspcQualityinfoID: x.tnaqCinspcQualityinfoID,
                        skuID: x.skuID,
                        purchaseOrderSkuID: x.purchaseOrderSkuID,
                        styleNo: x.styleNo,
                        tnaExFactoryId: x.subTaskID,
                        skuName: x.skuName,
                        endCustomer: x.endCustomer,
                        idNo: x.idno,
                        isSelected: 1,
                        orderQuantity: x.poQty,
                        IsDeleted: 0,
                        prodqty: x.productQty,
                        refInspecSKUID: x.skuID
                    })
                })
                props.location.state.params.multiselecteddatas = newvalue;
                QualityinfoCallback(newvalue);
                setInspectionQtyInfo(newvalue);
                MainInfoCommonCalculation(newvalue);
            }
        })
        var Multiselecteddate = [];
        if (QcinpsectionId === null) {
            Multiselecteddate = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1)
            setInspectionQtyInfo(Multiselecteddate);
            MainInfoCommonCalculation(Multiselecteddate);
            QualityinfoCallback(Multiselecteddate)
        }
    }, [rerenderdep])

    const handleCloseSamplesApproval = () => {
        setViewSamplesApproval({ isShow: false, value: null });

    }

    function MainInfoCommonCalculation(val) {
        let poqty = 0;
        let prodqty = 0;
        val.map(x => {
            if (poqty === 0) {
                poqty = parseFloat(x.orderQuantity);
            }
            else {
                poqty = poqty + parseFloat(x.orderQuantity);
            }
            if (prodqty === 0 && x.prodqty !== undefined && x.prodqty !== '') {
                prodqty = parseFloat(x.prodqty);
            }
            else if (prodqty !== 0 && x.prodqty !== undefined && x.prodqty !== '') {
                prodqty = parseFloat(prodqty) + parseFloat(x.prodqty);
            }
        })
        setTotalValues({ PoQty: poqty, Prodqty: prodqty })
    }

    function OpenPopup() {
       ;
        setselectall(false)
        let Multiselected = [];
        Multiselected = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 0)
        setMultiCloseDatas(Multiselected)
        setFinalInspectionPopup(true)
    }

    function handleClosePopup() {
        setFinalInspectionPopup(false)
    }

    function RemoveQuantity(e, index, purchaseOrderSkuID, comment) {
        let multiselecteddatas = [];
        const values = [...getInspectionQtyInfo]
        props.location.state.params.multiselecteddatas.filter(x => parseInt(x.purchaseOrderSkuID) === parseInt(purchaseOrderSkuID)).map(element => {
            element.isSelected = 0;
        })
        values[index].IsDeleted = 1;
        multiselecteddatas = values.filter(x => x.isSelected === 1)
        values.splice(index, 1);
        setInspectionQtyInfo(values)
        MainInfoCommonCalculation(values)
        QualityinfoCallback(values)
        setrerenderstate(props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).length);
    }


    function AddExFactoryForm() {
        let Multiselected = [];
        const Values = [...getInspectionQtyInfo]
        let isvalid = true;
        isvalid = !!MultiCloseDatas.find(x => x.isSelected === 1)
        if (isvalid) {
            Multiselected = Values.concat(MultiCloseDatas.filter(x => x.isSelected === 1))
            setMultiCloseDatas(Multiselected)
            setInspectionQtyInfo(Multiselected)
            setFinalInspectionPopup(false);
            MainInfoCommonCalculation(Multiselected)
            QualityinfoCallback(Multiselected)
            setrerenderstate(props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).length);
        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }

    }
    const OnSelectExFactoryRecords = (event, index, item) => {
       ;
        const value = [...MultiCloseDatas];
        if (event.target.checked === true) {
            value[index].isSelected = 1;
            value[index].IscheckReportSelect = 2;
            value[index].offerqty = '';
            props.location.state.params.multiselecteddatas.filter(x => x.purchaseOrderSkuID === item.purchaseOrderSkuID).map((e) => {
                e.isSelected = 1;
            })
        }
        else {
            value[index].isSelected = 0;
            value[index].IscheckReportSelect = 0;
            props.location.state.params.multiselecteddatas.filter(x => x.purchaseOrderSkuID === item.purchaseOrderSkuID).map((e) => {
                e.isSelected = 0;
            })
        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);
        setMultiCloseDatas(value);

    }

    const SelectAllExFactory = (event) => {

        let IsSelected = 0;
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
                element.IscheckReportSelect = 2;
                element.offerqty = '';
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
                element.IscheckReportSelect = 0;
            });
        }
        setselectall(IsSelected);
    }
    const ViewSamplesApproval = (SampleApproval) => {
        showLoader();
        setParentTask(SampleApproval.taskName);
        setViewSamplesApproval({ isShow: true, value: SampleApproval });
        hideLoader();
    }
    function handleChange(e, FeildName) {
       

        let inputText = '';
        if (FeildName === 'ParticipantsDp') {
            if (e !== null) {
                Values.ParticipantsID = e.value;
                //Values.Meetingname = e.label;
            } else {
                Values.ParticipantsID = 0;
                // Values.Meetingname = '';
            }
        } else if (FeildName === 'QCRemarks') {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values.FindingRemarks = inputText;
        } else if (FeildName === 'ScopeRemarks') {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            Values.ScopeRemarks = inputText;
        } else {
            if (e !== null) {
                Values.MeetingID = e.value;
            } else {
                Values.MeetingID = 0;
            }
        }
        setInputFeildlist(Values);
        DropDownListCallback(Values);
    }

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    function TrimvalCallback(value) {
        TrimCallback(value)
    }
    function WorkmanshipListcallback(value) {
        WorkManshipCallback(value)
    }
    return (
        <div className="row" style={{ pointerEvents: props.location.state.params.Action === 'View' ? 'none' : '' }}>
            <div className='col-sm-12'>
                <div className="panel-group accordion" id="acc_Quantity_Info">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info" href="#collapse_acc_Quantity_Info">
                                    Quantity Information
                                </a>
                            </h4>
                        </div>
                        <div id="collapse_acc_Quantity_Info" className="panel-collapse">
                            <div className="panel-body border-red">
                                <div className="mb-10-float-right">
                                    {/* <Label ><label className="btn btn-sm btn-warning">Tolerance: {gettolerance + '%'}</label></Label>&nbsp;&nbsp; */}
                                    {props.location.state.params.Action !== "View" && <button type="button" className="btn btn-sm btn-success mb-10-float-right" onClick={OpenPopup}>Order Details</button>}
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                            <thead>
                                                <tr>
                                                    <th>SKU ID(PO)</th>
                                                    <th>Style No</th>
                                                    <th>SKU Name</th>
                                                    <th>End Customer</th>
                                                    <th>PO/ID No</th>
                                                    <th>FO PO No</th>
                                                    <th>Order Qty</th>
                                                    <th style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    getInspectionQtyInfo.map((comment, index) => (
                                                        <tr>
                                                            <td>{comment.purchaseOrderSkuID}</td>
                                                            <td>{comment.styleNo}</td>
                                                            <td>{comment.skuName}</td>
                                                            <td>{comment.endCustomer}</td>
                                                            <td>{comment.idNo}</td>
                                                            <td>-</td>
                                                            <td>{comment.orderQuantity}</td>
                                                            <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}> <span>
                                                                <span className="btn btn-danger"
                                                                    style={{ pointerEvents: getInspectionQtyInfo.length === 1 ? "none" : '', opacity: getInspectionQtyInfo.length === 1 ? '0.6' : '' }}
                                                                    onClick={event => RemoveQuantity(event, index, comment.purchaseOrderSkuID, comment)}>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </span></td>
                                                        </tr>

                                                    ))
                                                }
                                                <tr>
                                                    <td colSpan="6" className="text-right"><b>Total</b></td>
                                                    <td>{getTotalValues.PoQty}</td>
                                                    <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}></td>
                                                    {/* <td></td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    getFinalInspectionPopup === true ? <Modal dialogClassName="CreatePopup" show={getFinalInspectionPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClosePopup()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                ADD PP Meeting &nbsp;
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr style={{ whiteSpace: "nowrap" }}>
                                            <th>Style Name</th>
                                            <th>Style No</th>
                                            <th>SKU Name</th>
                                            <th>PO/ID No</th>
                                            <th>FO PO No</th>
                                            <th>Factory</th>
                                            <th>Order Qty</th>
                                            <th>Bal. for Inspection</th>
                                            <th>Task Holder / Follower</th>
                                            <th>
                                                <label style={{ marginTop: '8px' }}>
                                                    <input type="checkbox" name="IsSelected"

                                                        onChange={event => (SelectAllExFactory(event))}
                                                        checked={selectall === 1 ? true : false}
                                                        className="colored-blue clearOpacity" />
                                                    <span className="text"></span>
                                                </label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            MultiCloseDatas.length !== 0 && MultiCloseDatas.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{item.styleName}</td>
                                                            <td>{item.styleNo}</td>
                                                            <td>{item.skuName}</td>
                                                            {/* <td>-</td> */}
                                                            <td>{item.idNo}</td>
                                                            <td>{item.poNo}</td>
                                                            <td>{item.factory}</td>
                                                            <td>{item.orderQuantity}</td>
                                                            <td>{item.balanceQuantity}</td>
                                                            <td className="cut_textInTNA" title={item.taskOwnerNameList} >{item.taskOwnerNameList}</td>
                                                            <td className="Actionbutton fixed-column">
                                                                <div style={{ width: "80px" }}>
                                                                    {
                                                                        <label style={{ marginTop: '8px' }}>
                                                                            <input type="checkbox" name="IsSelected"
                                                                                disabled={item.balanceQuantity === "0" ? true : false}
                                                                                onChange={event => (OnSelectExFactoryRecords(event, index, item))}
                                                                                checked={item.isSelected === 1 ? true : false}
                                                                                className="colored-blue clearOpacity" />
                                                                            <span className="text"></span>
                                                                        </label>
                                                                    }&nbsp;&nbsp;&nbsp;
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>

                                                )
                                            })

                                        }
                                        {
                                            MultiCloseDatas.length === 0 ?
                                                <tr>
                                                    <td colSpan="10" className='norecordfound'><span>No record found</span></td>
                                                </tr> : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={() => AddExFactoryForm()}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal> : ''
                }

            </div>
            <br />
            <div className='col-sm-12'>
                <div className="panel-group accordion" id="acc_Quantity_Info1">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info1" href="#collapse_acc_Quantity_Info1">
                                    Participants (Company - Employees)
                                </a>
                            </h4>
                        </div>
                        <div id="collapse_acc_Quantity_Info1" className="panel-collapse">
                            <div className="panel-body border-red">
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='form-group'>
                                            <span className='input-icon icon-right'>
                                                <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                    id={
                                                        "TaskHolderOrFollower"
                                                    }
                                                    isLoading={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={event => handleChange(event, 'ParticipantsDp')}
                                                    value={getParticipantslist.filter(function (option) {
                                                        return option.value === getInputFeildlist.ParticipantsID;
                                                    })}
                                                    options={getParticipantslist}
                                                    styles={submitted && getInputFeildlist.ParticipantsID === 0 ? styles : ''}
                                                    //isDisabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true :
                                                    //props.props.location.pathname !== '/TNAEdit' && props.TaskStatus === 3 ? true : false}
                                                    menuPosition="fixed"
                                                ></Reactselect>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-sm-8'>
                                        <div className='form-group'>
                                            <textarea maxLength="300" name="Remarks" placeholder="Enter Findings / Remarks" value={getInputFeildlist.FindingRemarks} onChange={event => handleChange(event, 'QCRemarks')} rows="4" style={{ width: "100%" }}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='col-sm-12'>
                <div className="panel-group accordion" id="acc_Quantity_Info8">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info8" href="#collapse_acc_Quantity_Info8">
                                    View PP Approval
                                </a>
                            </h4>
                        </div>
                        <div id="collapse_acc_Quantity_Info8" className="panel-collapse">
                            <div className="panel-body border-red">
                                <div className='col-lg-12'>
                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                        <thead>
                                            <tr>
                                                <th>Sample Details</th>
                                                <th>Form / Manual Close</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                getSKUSampleApproval.length !== 0 ?
                                                    getSKUSampleApproval.map((SampleApproval, index) => (
                                                        <>
                                                            <tr>
                                                                <td>

                                                                    {SampleApproval.manualCloseComment === '' ?
                                                                        <>


                                                                            {SampleApproval.sampleDetails} - {SampleApproval.skuName === null ? <b> <span class='primary'>Any Color</span></b> : <b><span class='primary'>{SampleApproval.skuName}</span></b>} - <b>{SampleApproval.approvedStatus === 'Rejected' ? <span class='danger'>{SampleApproval.approvedStatus}</span> : <span class='success'>{SampleApproval.approvedStatus}</span>} </b>
                                                                            On {moment(SampleApproval.approvedDate).format("DD/MM/YYYY")} By {SampleApproval.approvedBy}

                                                                        </> : <>{SampleApproval.sampleDetails} - {SampleApproval.skuName === null ? <b><span class='primary'>Any Color</span></b> : <b><span class='primary'>{SampleApproval.skuName}</span></b>} </>}


                                                                    {/* {SampleApproval.approvedStatus} { SampleApproval.approvedDate}- {SampleApproval.approvedBy} */}

                                                                    {/* {SampleApproval.manualCloseComment === '' &&
                                                                                <>
                                                                                 {SampleApproval.sampleDetails} - {SampleApproval.skuName === null ? 'Any Color' : SampleApproval.skuName} - {SampleApproval.approvedStatus - SampleApproval.approvedDate - SampleApproval.approvedBy}
                                                                                 </>
                                                                                } */}


                                                                </td>
                                                                <td>
                                                                    {SampleApproval.manualCloseComment === '' ? 'Form' : 'Manual Close'}

                                                                </td>
                                                                <td>

                                                                    <button type="button" onClick={() => ViewSamplesApproval(SampleApproval)}
                                                                        className="btn btn-xs btn-warning" title="View">
                                                                        <i className="fa fa-eye"></i>
                                                                    </button>

                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))
                                                    : <tr>
                                                        <td colSpan="3" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        getViewSamplesApproval.isShow && <>
                                            <Modal dialogClassName="CreatePopup" show={getViewSamplesApproval.isShow} size="md" dragable backdrop="static" keyboard={false}
                                                onHide={() => handleCloseSamplesApproval()}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>
                                                        Approval Result
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="row">

                                                        <div className="col-md-12">
                                                            {getViewSamplesApproval.value.manualCloseComment !== '' ?
                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>ManualClose Comment</th>
                                                                            <th>ManualClose Date</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                {getViewSamplesApproval.value.manualCloseComment}
                                                                            </td>
                                                                            <td>
                                                                                {moment(getViewSamplesApproval.value.manualCloseDate).format("DD/MM/YYYY")}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                                : ''
                                                            }
                                                            {getViewSamplesApproval.value.manualCloseComment === '' ?
                                                                <div>
                                                                    <ProtoFitSMSApproval props={props}
                                                                        ParentTask={getParentTask}
                                                                        TaskStatus={3}
                                                                        TNAProtoFitId={getViewSamplesApproval.value.sampleDetailsID}
                                                                    ></ProtoFitSMSApproval>
                                                                </div>
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button class="btn btn-xs btn-danger" onClick={() => handleCloseSamplesApproval()}>close</button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='col-sm-12'>
                <div className="panel-group accordion" id="acc_Quantity_Info2">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info2" href="#collapse_acc_Quantity_Info2">
                                    Scope
                                </a>
                            </h4>
                        </div>
                        <div id="collapse_acc_Quantity_Info2" className="panel-collapse">
                            <div className="panel-body border-red">
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='form-group'>
                                            <textarea maxLength="300" name="Remarks" placeholder="Enter Findings / Remarks" value={getInputFeildlist.ScopeRemarks} onChange={event => handleChange(event, 'ScopeRemarks')} rows="4" style={{ width: "100%" }}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='col-sm-12'>
                <div className="panel-group accordion" id="acc_Quantity_Info3">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info3" href="#collapse_acc_Quantity_Info3">
                                    Trims and Accessories
                                </a>
                            </h4>
                        </div>
                        <div id="collapse_acc_Quantity_Info3" className="panel-collapse">
                            <div className="panel-body border-red">
                                <TrimandAccesssories props={props} rendarstate={getrerenderstate} Trimaccesscallback={TrimvalCallback} Rerender={rerenderdep} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='col-sm-12'>
                <div className="panel-group accordion" id="acc_Quantity_Info4">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info4" href="#collapse_acc_Quantity_Info4">
                                    Construction & Workmanship
                                </a>
                            </h4>
                        </div>
                        <div id="collapse_acc_Quantity_Info4" className="panel-collapse">
                            <div className="panel-body border-red">
                                <PPWorkmanshipList props={props} WorkmanhsipContList={WorkmanshipListcallback} Rerender={rerenderdep} trimvalidation={IsvalidationWrk} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {props.location.state.params.TaskType === "PP Meeting" ? '' : <div className="col-sm-3">
                <div className='form-group'>
                    <label htmlFor="dteReportDate">Meeting Conclusion<span className="text-danger">*</span></label>
                    <Reactselect className="basic-single" name="factoryorlocation"
                        id={
                            "ddlFactoryOrLocation"
                        }
                        //isDisabled={props.location.state.params.Action === 'View' ? true : false}
                        isLoading={false}
                        isClearable={true}
                        isSearchable={true}
                        options={getMeetingDDlist}
                        styles={submitted && getInputFeildlist.MeetingID === 0 ? styles : ''}
                        value={getMeetingDDlist.filter(function (option) {
                            return option.value === getInputFeildlist.MeetingID;
                        })}
                        onChange={event => handleChange(event, 'CheckedArea')}
                    ></Reactselect>
                </div>
            </div>}
            {loader}
        </div>
    )
}

export default AddEditPPMeeting