import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-validation/build/form";
import BrandWiseStandardService from './../../../../services/Master/Brandwisestandard';
import styleService from '../../../../services/Style/styleService';
import Reactselect from 'react-select';
import StyleService from "./../../../../services/Style/styleService";
import DatePicker from "react-datepicker";
import EditBrandStandardFabric from './EditBrandwiseMaster/EditBrandStandardFabric';
import EditBrandStandardYarn from './EditBrandwiseMaster/EditBrandStandardYarn';
import EditBrandStandardGarment from './EditBrandwiseMaster/EditBrandStandardGarment';
import EditBrandStandardStrikeOff from './EditBrandwiseMaster/EditBrandStandardStrikeOff';
import EditBrandStandardTrims from './EditBrandwiseMaster/EditBrandStandardTrims';
import { retrieveGender } from '../../../../actions/gender';
import { retrieveColorDepth } from '../../../../actions/colordepth';
import { retrieveTestField } from '../../../../actions/testfield';
import Nodify from "../../../Common/ReactNotification";
import ReactNotification from 'react-notifications-component';
import SearchRetain from "../../../Common/SearchRetain";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import ConfirmationPopup from "../../../Common/ConfirmationPopup";
import Masterservice from './../../../../services/Master/FabricService';
const BrandwiseEdit = (props) => {
    const EmptyInputFields = {
        BrandStandardID: 0
        , BuyerID: 0
        , BrandID: 0
        , InternalRefID: null
        , BuyerVersionDate: ''
        , ReceivedDate: ''
        , EffectFromDate: ''
    };

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
            />
        )
    }


    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }

    let BuyerListOptions = [] = [];

    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [getCurrentBuyerID, setCurrentBuyerID] = useState(null);
    const [getCurrentBrandID, setCurrentBrandID] = useState(null);
    const Value = [...inputFields];
    const [getBuyerOptions, setBuyerOptions] = useState([]);
    const [getBrandOptions, setBrandOptions] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [getFabricTestNames, setFabricTestNames] = useState([]);
    const [getFabricAndGroupDetails, setFabricAndGroupDetails] = useState([]);
    const [getStandardAndGenderDetails, setStandardAndGenderDetails] = useState([]);
    const [getFabricOptions, setFabricOptions] = useState([]);
    const [getStandardOptions, setStandardOptions] = useState([]);
    const [getGenderOptions, setGenderOptions] = useState([]);
    const reducerState = useSelector((state) => state);
    const dispatch = useDispatch();
    const commentsGender = reducerState.gender.genderList;
    const commentsColorDepth = reducerState.ColorDepthReducer.ColorDepthList.item1;
    const commentsTestField = reducerState.testfield.TestFieldNameList.item1;
    const [getColorDepthOptions, setColorDepthOptions] = useState([]);
    const [getFieldOptions, setFieldOptions] = useState([]);
    const [getInternalGridDetails, setInternalGridDetails] = useState([]);
    const [getIsReloadColorDepthOfParent, setIsReloadColorDepthOfParent] = useState({});
    const [getIsReloadTestFieldOfParent, setIsReloadTestFieldOfParent] = useState({});
    const [getIsReloadTestStandardOfParent, gstIsReloadTestStandardOfParent] = useState({});
    const [getFabricTabData, setFabricTabData] = useState([]);
    const [getStrikeOffTabData, setStrikeOffTabData] = useState([]);
    const [getYarnTabData, setYarnTabData] = useState([]);
    const [getGarmentTabData, setGarmentTabData] = useState([]);
    const [getTrimsTabData, setTrimsTabData] = useState([]);
    const [getCurrentDataID, setCurrentDataID] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getAllTabData, setAllTabData] = useState([]);
    const [getisParentSaveFailed, setisParentSaveFailed] = useState([]);
    const [getActionMode, setActionMode] = useState(null);
    const [getGarmaentdpData, setGarmaentdpData] = useState([]);
    const [getStrikeoffDpData, setStrikeoffDpData] = useState([]);
    const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;
    const [getPage, setPage] = useState([]);
    const [getID, setID] = useState({ showPopupConfirm: false, Params: {} }); 
    const [getResultTypeGradeOptions, setResultTypeGradeOptions] = useState([
        { id: 1, value: 1, label: "5", name: "5" }
        , { id: 2, value: 2, label: "4-5", name: "4-5" }
        , { id: 3, value: 3, label: "4", name: "4" }
        , { id: 4, value: 4, label: "3-4", name: "3-4" }
        , { id: 5, value: 5, label: "3", name: "3" }
        , { id: 6, value: 6, label: "2-3", name: "2-3" }
        , { id: 7, value: 7, label: "2", name: "2" }
        , { id: 8, value: 8, label: "1-2", name: "1-2" }
        , { id: 9, value: 9, label: "1", name: "1" }
        , { id: 10, value: 10, label: "0-1", name: "0-1" }
        , { id: 11, value: 11, label: "No Changes", name: "No Changes" }
        , { id: 12, value: 12, label: "Changes Observed", name: "Changes Observed" }
    ]);

    const [getRedirectConfirmation, setRedirectConfirmation] = useState({ showPopupConfirm: false, Params: {} });
    const handleChangeDate = (index, e, FieldName) => {
        let Value = [...inputFields];
        if (FieldName === "ReceivedDate") {
            if (e !== null) {
                Value[0].ReceivedDate = e;
            }
            else {
                Value[0].ReceivedDate = null;
            }
            Value[0].ReceivedDate = e;
        }
        if (FieldName === "EffectFromDate") {
            if (e !== null) {
                Value[0].EffectFromDate = e;
            }
            else {
                Value[0].EffectFromDate = null;
            }
            Value[0].EffectFromDate = e;
        }
        setInputFields(Value);
    }

    const ParentStatusUpdationCallBack = (testNameID, statusID) => {
        let Values = [...getFabricTestNames];
        Values.forEach(element => {
            if (element.testID == testNameID) {
                element.status = statusID
            }
        });
        // setFabricTestNames(Values);
    }

    const AddedColorDepthData = (isColorValueUpdated) => {
        setIsReloadColorDepthOfParent(isColorValueUpdated);
    }

    const AddedTestFieldData = (isFieldValueUpdated) => {
        setIsReloadTestFieldOfParent(isFieldValueUpdated);
    }

    const AddedTestStandardData = (isFieldValueUpdated) => {
        gstIsReloadTestStandardOfParent(isFieldValueUpdated);
    }

    const SubmitFabricDataCallBack = (value) => {
        setFabricTabData(value);
    }

    const SubmitYarnDataCallBack = (value) => {
        let FabricDetails = value.filter(data => {
            if (data.testType == 1) {
                return data.parentFields;
            }
        })
        setYarnTabData(value);
    }

    const SubmitGarmentDataCallBack = (value) => {
        setGarmentTabData(value);
    }

    const SubmitStrikeOffDataCallBack = (value) => {
        setStrikeOffTabData(value);
    }

    const SubmitTrimsDataCallBack = (value) => {
        setTrimsTabData(value);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/BrandWiseStandardList')
        } else {
            window.location.reload();
        }
    }

    const handleTextBoxChange = (e) => {
        let Values = [...inputFields];
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
        }
        Value[0].BuyerVersionDate = inputText;
        setInputFields(Values);
    }

    const UpdateBrandWiseStandardMaster = (e) => {
        setSubmitted(true);
        setButtonLoader(true);
        showLoader();
        setPage('BrandwiseEdit')
        let Values = [...inputFields];
        if (!Values[0].BuyerVersionDate || !Values[0].ReceivedDate || !Values[0].EffectFromDate) {
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
            hideLoader();
            setButtonLoader(false);
            return false;
        }
        else {
            let fabricsData = [];
            let genderData = [];
            let internalData = [];
            setAllTabData([]);
            Array.prototype.push.apply(getAllTabData, getFabricTabData);
            Array.prototype.push.apply(getAllTabData, getYarnTabData);
            Array.prototype.push.apply(getAllTabData, getGarmentTabData);
            Array.prototype.push.apply(getAllTabData, getStrikeOffTabData);
            Array.prototype.push.apply(getAllTabData, getTrimsTabData);
            let IsFabricTabDataExists = getFabricTestNames.filter((data => {
                if (data.testType == 1) {
                    return data;
                }
            }));
            let IsYarnTabDataExists = getFabricTestNames.filter((data => {
                if (data.testType == 2) {
                    return data;
                }
            }));
            let IsGarmentTabDataExists = getFabricTestNames.filter((data => {
                if (data.testType == 3) {
                    return data;
                }
            }));
            let IsStrikeOffTabDataExists = getFabricTestNames.filter((data => {
                if (data.testType == 4) {
                    return data;
                }
            }));
            let IsTrimsTabDataExists = getFabricTestNames.filter((data => {
                if (data.testType == 5) {
                    return data;
                }
            }));
            for (var m = 0; m < getAllTabData.length; m++) {
                for (var i = 0; i < getAllTabData[m].parentFields.length; i++) {
                    let CreatedOndate=getAllTabData[m].parentFields[i].CreatedOn
                    CreatedOndate.setMinutes(CreatedOndate.getMinutes() + 370);
                    fabricsData.push({
                        ID: getAllTabData[m].parentFields[i].ID
                        , TestType: parseInt(getAllTabData[m].testType)
                        , TestID: getAllTabData[m].testID
                        //, CreatedOn: getAllTabData[m].parentFields[i].CreatedOn
                        ,CreatedOn: CreatedOndate
                        , Group: getAllTabData[m].parentFields[i].Group
                        , FabricDetails: getAllTabData[m].parentFields[i].FabricDetails
                        , FabricIndexID: getAllTabData[m].parentFields[i].FabricIndexID
                    });
                    for (var j = 0; j < getAllTabData[m].parentFields[i].StandardResultRequirement.length; j++) {
                        let Createddate=getAllTabData[m].parentFields[i].StandardResultRequirement[j].CreatedOn
                        Createddate.setMinutes(Createddate.getMinutes() + 370);
                        let EffectDate=getAllTabData[m].parentFields[i].StandardResultRequirement[j].EffectFromDate
                        EffectDate.setMinutes(EffectDate.getMinutes() + 370);
                        genderData.push({
                           
                            ID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].ID
                            , TestID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].TestID
                            //, CreatedOn: getAllTabData[m].parentFields[i].StandardResultRequirement[j].CreatedOn
                            , CreatedOn: Createddate
                            //, EffectFromDate: getAllTabData[m].parentFields[i].StandardResultRequirement[j].EffectFromDate
                            ,EffectFromDate: EffectDate
                            , Standard: getAllTabData[m].parentFields[i].StandardResultRequirement[j].StandardID
                            , GenderID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].GenderID
                            , FabricDetailID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].FabricDetailID
                            , FabricIndexID: getAllTabData[m].parentFields[i].FabricIndexID
                            , StandardIndexID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].StandardIndexID
                            , GenderIndexID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].GenderIndexID
                            , TestType: getAllTabData[m].parentFields[i].StandardResultRequirement[j].TestType
                        })
                        for (var k = 0; k < getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid.length; k++) {
                            internalData.push({
                                ID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].ID
                                , FabricDetailID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].FabricDetailID
                                , StandardID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].StandardID
                                , TestID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].TestID
                                , ColorDepth: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].ColorDepth
                                , Field: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].Field
                                , ResultType: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].ResultType
                                , MinResult: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].MinResult.toString()
                                , MaxResult: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].MaxResult.toString()
                                , FabricIndexID: getAllTabData[m].parentFields[i].FabricIndexID
                                , StandardIndexID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].StandardIndexID
                                , GenderIndexID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].GenderIndexID
                                , InternalIndexID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].InternalIndexID
                                , TestTypeID: getAllTabData[m].parentFields[i].StandardResultRequirement[j].InternalGrid[k].TestTypeID
                            })
                        }
                    }
                }
            }
            let CheckMandoryFields = [...getAllTabData];
            let FabricData = CheckMandoryFields.filter((data => {
                return data.testType == 1;
            }));
            let YarnData = CheckMandoryFields.filter((data => {
                return data.testType == 2;
            }))
            let GarmentData = CheckMandoryFields.filter((data => {
                return data.testType == 3;
            }))
            let StrikeOffData = CheckMandoryFields.filter((data => {
                return data.testType == 4;
            }))
            let TrimsData = CheckMandoryFields.filter((data => {
                return data.testType == 5;
            }))
            if (IsFabricTabDataExists.length > 0) {
                for (var l = 0; l < FabricData.length; l++) {
                    for (var i = 0; i < FabricData[l].parentFields.length; i++) {
                        let FabricDetails = FabricData[l].parentFields;
                        let SandardDetails = FabricData[l].parentFields[i].StandardResultRequirement;
                        if (!FabricDetails[i].Group || !FabricDetails[i].CreatedOn || !FabricDetails[i].FabricDetails) {
                            hideLoader();
                            setButtonLoader(false);
                            setisParentSaveFailed(true);
                            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Fabric tab -' + FabricData[l].testName + '.');
                            return false;
                        }
                        else {
                            for (var j = 0; j < SandardDetails.length; j++) {
                                let InternalDetails = FabricData[l].parentFields[i].StandardResultRequirement[j].InternalGrid;
                                if (!SandardDetails[j].CreatedOn || !SandardDetails[j].EffectFromDate || !SandardDetails[j].GenderID
                                    || !SandardDetails[j].StandardID) {
                                    hideLoader();
                                    setButtonLoader(false);
                                    setisParentSaveFailed(true);
                                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Fabric tab -' + FabricData[l].testName + '.');
                                    return false;
                                }
                                else {
                                    for (var k = 0; k < InternalDetails.length; k++) {
                                        if (!InternalDetails[k].ColorDepth || !InternalDetails[k].Field || !InternalDetails[k].ResultType || (!InternalDetails[k].MinResult)
                                            || ((InternalDetails[k].ResultType != 3 && InternalDetails[k].ResultType != 4)
                                                && (!InternalDetails[k].MaxResult))
                                        ) {
                                            hideLoader();
                                            setButtonLoader(false);
                                            setisParentSaveFailed(true);
                                            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Fabric tab -' + FabricData[l].testName + '.');
                                            return false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (IsYarnTabDataExists.length > 0) {
                for (var i = 0; i < YarnData[0].parentFields.length; i++) {
                    let FabricDetails = YarnData[0].parentFields;
                    let SandardDetails = YarnData[0].parentFields[i].StandardResultRequirement;
                    if (!FabricDetails[i].Group || !FabricDetails[i].CreatedOn || !FabricDetails[i].FabricDetails) {
                        hideLoader();
                        setButtonLoader(false);
                        setisParentSaveFailed(true);
                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Yarn tab - ' + YarnData[0].testName);
                        return false;
                    }
                    else {
                        for (var j = 0; j < SandardDetails.length; j++) {
                            let InternalDetails = YarnData[0].parentFields[i].StandardResultRequirement[j].InternalGrid;
                            if (!SandardDetails[j].CreatedOn || !SandardDetails[j].EffectFromDate || !SandardDetails[j].GenderID
                                || !SandardDetails[j].StandardID) {
                                hideLoader();
                                setButtonLoader(false);
                                setisParentSaveFailed(true);
                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Yarn tab - ' + YarnData[0].testName);
                                return false;
                            }
                            else {
                                for (var k = 0; k < InternalDetails.length; k++) {
                                    if (!InternalDetails[k].ColorDepth || !InternalDetails[k].Field || !InternalDetails[k].ResultType || (!InternalDetails[k].MinResult)
                                        || ((InternalDetails[k].ResultType != 3 && InternalDetails[k].ResultType != 4)
                                            && (!InternalDetails[k].MaxResult))
                                    ) {
                                        hideLoader();
                                        setButtonLoader(false);
                                        setisParentSaveFailed(true);
                                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Yarn tab - ' + YarnData[0].testName);
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (IsGarmentTabDataExists.length > 0) {
                for (var i = 0; i < GarmentData[0].parentFields.length; i++) {
                    let FabricDetails = GarmentData[0].parentFields;
                    let SandardDetails = GarmentData[0].parentFields[i].StandardResultRequirement;
                    if (!FabricDetails[i].Group || !FabricDetails[i].CreatedOn || !FabricDetails[i].FabricDetails) {
                        hideLoader();
                        setButtonLoader(false);
                        setisParentSaveFailed(true);
                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Garment tab -' + GarmentData[0].testName);
                        return false;
                    }
                    else {
                        for (var j = 0; j < SandardDetails.length; j++) {
                            let InternalDetails = GarmentData[0].parentFields[i].StandardResultRequirement[j].InternalGrid;
                            if (!SandardDetails[j].CreatedOn || !SandardDetails[j].EffectFromDate || !SandardDetails[j].GenderID
                                || !SandardDetails[j].StandardID) {
                                hideLoader();
                                setButtonLoader(false);
                                setisParentSaveFailed(true);
                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Garment tab - ' + GarmentData[0].testName);
                                return false;
                            }
                            else {
                                for (var k = 0; k < InternalDetails.length; k++) {
                                    if (!InternalDetails[k].ColorDepth || !InternalDetails[k].Field || !InternalDetails[k].ResultType || (!InternalDetails[k].MinResult)
                                        || ((InternalDetails[k].ResultType != 3 && InternalDetails[k].ResultType != 4)
                                            && (!InternalDetails[k].MaxResult))
                                    ) {
                                        hideLoader();
                                        setButtonLoader(false);
                                        setisParentSaveFailed(true);
                                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Garment tab - ' + GarmentData[0].testName);
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (IsStrikeOffTabDataExists.length > 0) {
                for (var i = 0; i < StrikeOffData[0].parentFields.length; i++) {
                    let FabricDetails = StrikeOffData[0].parentFields;
                    let SandardDetails = StrikeOffData[0].parentFields[i].StandardResultRequirement;
                    if (!FabricDetails[i].Group || !FabricDetails[i].CreatedOn || !FabricDetails[i].FabricDetails) {
                        hideLoader();
                        setButtonLoader(false);
                        setisParentSaveFailed(true);
                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Strike Off tab - ' + StrikeOffData[0].testName);
                        return false;
                    }
                    else {
                        for (var j = 0; j < SandardDetails.length; j++) {
                            let InternalDetails = StrikeOffData[0].parentFields[i].StandardResultRequirement[j].InternalGrid;
                            if (!SandardDetails[j].CreatedOn || !SandardDetails[j].EffectFromDate || !SandardDetails[j].GenderID
                                || !SandardDetails[j].StandardID) {
                                hideLoader();
                                setButtonLoader(false);
                                setisParentSaveFailed(true);
                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Strike Off tab - ' + StrikeOffData[0].testName);
                                return false;
                            }
                            else {
                                for (var k = 0; k < InternalDetails.length; k++) {
                                    if (!InternalDetails[k].ColorDepth || !InternalDetails[k].Field || !InternalDetails[k].ResultType || (!InternalDetails[k].MinResult)
                                        || ((InternalDetails[k].ResultType != 3 && InternalDetails[k].ResultType != 4)
                                            && (!InternalDetails[k].MaxResult))
                                    ) {
                                        hideLoader();
                                        setButtonLoader(false);
                                        setisParentSaveFailed(true);
                                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Strike Off tab - ' + StrikeOffData[0].testName);
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (IsTrimsTabDataExists.length > 0) {
                for (var i = 0; i < TrimsData[0].parentFields.length; i++) {
                    let FabricDetails = TrimsData[0].parentFields;
                    let SandardDetails = TrimsData[0].parentFields[i].StandardResultRequirement;
                    if (!FabricDetails[i].Group || !FabricDetails[i].CreatedOn || !FabricDetails[i].FabricDetails) {
                        hideLoader();
                        setButtonLoader(false);
                        setisParentSaveFailed(true);
                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Trims tab - ' + TrimsData[0].testName);
                        return false;
                    }
                    else {
                        for (var j = 0; j < SandardDetails.length; j++) {
                            let InternalDetails = TrimsData[0].parentFields[i].StandardResultRequirement[j].InternalGrid;
                            if (!SandardDetails[j].CreatedOn || !SandardDetails[j].EffectFromDate || !SandardDetails[j].GenderID
                                || !SandardDetails[j].StandardID) {
                                hideLoader();
                                setButtonLoader(false);
                                setisParentSaveFailed(true);
                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Trims tab - ' + TrimsData[0].testName);
                                return false;
                            }
                            else {
                                for (var k = 0; k < InternalDetails.length; k++) {
                                    if (!InternalDetails[k].ColorDepth || !InternalDetails[k].Field || !InternalDetails[k].ResultType || (!InternalDetails[k].MinResult)
                                        || ((InternalDetails[k].ResultType != 3 && InternalDetails[k].ResultType != 4)
                                            && (!InternalDetails[k].MaxResult))
                                    ) {
                                        hideLoader();
                                        setButtonLoader(false);
                                        setisParentSaveFailed(true);
                                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Trims tab - ' + TrimsData[0].testName);
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            let ReceivedOn=new Date(Values[0].ReceivedDate)
            ReceivedOn.setMinutes(ReceivedOn.getMinutes() + 370);
            let EffectFromDate=new Date(Values[0].EffectFromDate)
            EffectFromDate.setMinutes(EffectFromDate.getMinutes() + 370);
            let Params = {  
                StandardMaster: {
                    ID: getCurrentDataID
                    , BuyerID: getCurrentBuyerID
                    , BrandID: getCurrentBrandID
                    , BuyerVersionAndDate: Values[0].BuyerVersionDate
                    // , ReceivedOn: new Date(Values[0].ReceivedDate)
                    // , EffectFromDate: new Date(Values[0].EffectFromDate)
                     ,ReceivedOn: ReceivedOn
                    , EffectFromDate: EffectFromDate
                    , ModifiedBy: currentUser.employeeinformationID
                }
                , UpdateBrandwiseStandardFabricDetails: fabricsData
                , UpdateBrandwiseStandardGenderDetails: genderData
                , UpdateBrandwiseStandardInternalDetails: internalData
            }
            setisParentSaveFailed(false);
            BrandWiseStandardService.UpdateBrandwiseStandardMaster(Params).then((res) => {
                hideLoader();
                setSubmitted(false);
                var page = "Remove";
                 let params = { tabPage: "BrandwiseEdit", Func: "Edit" }; 
                // setRedirectConfirmation({ showPopupConfirm: true, Params: params });
                SearchRetain(page);
                let Func = 'Add';
                res.data.tabPage = getPage;
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    setID({ showPopupConfirm: true, Params: params, });
                    //PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    setID({ showPopupConfirm: true, Params:params });
                    //PageRedirect(Func);
                }
                res.data.Func = Func;
                setButtonLoader(false);
                
            }).catch((err) => {
                setSubmitted(false);
                hideLoader();
                setButtonLoader(false);
            });
            setSubmitted(false);
            hideLoader();
            setButtonLoader(false);
        }
    }
    const Delcallback = (value) => {
        if (value === true) {
            setID({ showPopupConfirm: false, Params: [] });
            
        } 
    }

    const redirectionConfirmation = (value) => {
        window.location.reload();
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/BrandWiseStandardList",
            state: {
                message: Func,
            },
        }
        );
    };

    useEffect(() => {
        if (commentsGender != undefined && commentsGender.length != 0) {
            let computedGenderOptions = commentsGender.map((data => {
                data.id = data.genderSizeID;
                data.value = data.genderSizeID;
                data.label = data.genderSizeName;
                data.name = data.genderSizeName;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setGenderOptions(computedGenderOptions);
        }
    }, [commentsGender]);


    useEffect(() => {
        if (commentsColorDepth != undefined && commentsColorDepth.length != 0) {
            let computedColorDepthOptions = commentsColorDepth.map((data => {
                data.id = data.colorDepthID;
                data.value = data.colorDepthID;
                data.label = data.colorDepth;
                data.name = data.colorDepth;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setColorDepthOptions(computedColorDepthOptions);
        }
    }, [commentsColorDepth]);

    useEffect(() => {
        if (commentsTestField != undefined && commentsTestField.length != 0) {
            let computedFieldOptions = commentsTestField.map((data => {
                data.id = data.testFieldID;
                data.value = data.testFieldID;
                data.label = data.testField;
                data.name = data.testField;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setFieldOptions(computedFieldOptions);
        }
    }, [commentsTestField]);
    useEffect(() => {
       
        (async () => {
            await Masterservice.LoadMasterbrandWiseTypeList(1, 0, 0).then((res) => {
                if (res.data) {
                    setGarmaentdpData(res.data)
                }
            })

        })();
    }, [])
    useEffect(() => {
        if (props.location.state) {
            if (props.location.state.params) {
                setActionMode(props.location.state.params.Action);
                showLoader();
                styleService.LoadBuyer(2)
                    .then((BuyerResponse) => {
                        if (BuyerResponse.data) {
                            BuyerListOptions = BuyerResponse.data;
                            setBuyerOptions(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));
                            StyleService.LoadBrandThemeStoryEndCustomer(5, props.location.state.params.buyerID).then((Brandresponse) => {
                                if (Brandresponse.data) {
                                    setBrandOptions(Brandresponse.data);
                                    BrandWiseStandardService.LoadMappedTestsForBrandwiseStandard().then((response) => {
                                        if (response.data) {
                                            setStandardOptions((response.data.item2));
                                        }
                                        BrandWiseStandardService.LoadBuyerBrandBasedBrandwiseStandard(props.location.state.params.buyerID
                                            , props.location.state.params.brandID, props.location.state.params.internalRefID).then(res => {
                                                setCurrentDataID(res.data.item1[0].id);
                                                Value[0].BuyerID = res.data.item1[0].buyerID;
                                                setCurrentBuyerID(res.data.item1[0].buyerID);
                                                Value[0].BuyerIDForEdit = BuyerResponse.data.filter((buyer => {
                                                    return buyer.id == props.location.state.params.buyerID;
                                                }));
                                                Value[0].BrandID = res.data.item1[0].brandID;
                                                setCurrentBrandID(res.data.item1[0].brandID);
                                                Value[0].BrandIDForEdit = Brandresponse.data.filter((brand => {
                                                    return brand.id == props.location.state.params.brandID;
                                                }));
                                                Value[0].InternalRefID = res.data.item1[0].internalRefID;
                                                Value[0].BuyerVersionDate = res.data.item1[0].buyerVersionAndDate;
                                                Value[0].ReceivedDate = new Date(res.data.item1[0].receivedOn);
                                                Value[0].EffectFromDate = new Date(res.data.item1[0].effectFromDate);
                                                setInputFields(Value);
                                               
                                                setFabricTestNames(res.data.item2);
                                                setFabricOptions(res.data.item4);
                                                res.data.item5.forEach((item5, item5index) => {
                                                    item5.createdOn = new Date(item5.createdOn);
                                                    item5.effectFromDate = new Date(item5.effectFromDate);
                                                })
                                                setStandardAndGenderDetails(res.data.item5);
                                                setInternalGridDetails(res.data.item6);
                                                setFabricAndGroupDetails(res.data.item3);
                                                let FabricTabGroupdata = res.data.item3.filter((fabric => {
                                                    return fabric.testType == 1;
                                                }));
                                                let FabricTabGenderdata = res.data.item5.filter((fabric => {
                                                    return fabric.testType == 1;
                                                }));
                                                let FabricTabInternaldata = res.data.item6.filter((fabric => {
                                                    return fabric.testTypeID == 1;
                                                }));
                                                let FormattedFabricGroupData = [];
                                                var UnmappedFabricData = res.data.item2.filter((data) => {
                                                    return data.testType == 1 && data.isMapped == 0;
                                                })
                                                FabricTabGroupdata.forEach(fabric => {
                                                    FormattedFabricGroupData.push({
                                                        testID: fabric.testID
                                                        , testType: fabric.testType
                                                        , parentFields: [{
                                                            ID: fabric.id
                                                            , TestType: parseInt(fabric.testType)
                                                            , TestID: fabric.testID
                                                            , CreatedOn: new Date(fabric.createdOn)
                                                            , Group: fabric.group
                                                            , FabricDetails: fabric.fabricDetails
                                                            , FabricIndexID: fabric.fabricIndexID
                                                            , StandardResultRequirement: FabricTabGenderdata.filter((gender => {
                                                                if (gender.fabricIndexID == fabric.fabricIndexID) {
                                                                    return true;
                                                                }
                                                                else {
                                                                    return false;
                                                                }
                                                            })).map((gender => {
                                                                if (gender.fabricIndexID == fabric.fabricIndexID) {
                                                                    return {
                                                                        ID: gender.id
                                                                        , TestID: gender.testID
                                                                        , CreatedOn: new Date(gender.createdOn)
                                                                        , EffectFromDate: gender.effectFromDate
                                                                        , StandardID: gender.standard
                                                                        , GenderID: gender.genderID
                                                                        , FabricDetailID: gender.fabricDetailID
                                                                        , FabricIndexID: gender.fabricIndexID
                                                                        , StandardIndexID: gender.standardIndexID
                                                                        , GenderIndexID: gender.genderIndexID
                                                                        , TestType: parseInt(gender.testType)
                                                                        , InternalGrid: FabricTabInternaldata.filter(internal => {
                                                                            if (internal.testID == gender.testID && internal.fabricDetailID == gender.fabricDetailID
                                                                                && internal.fabricIndexID == gender.fabricIndexID
                                                                                && internal.standardIndexID == gender.standardIndexID) {
                                                                                return true;
                                                                            }
                                                                            else {
                                                                                return false;
                                                                            }
                                                                        }).map((internal => {
                                                                            if (internal.testID == gender.testID && internal.fabricDetailID == gender.fabricDetailID
                                                                                && internal.fabricIndexID == gender.fabricIndexID
                                                                                && internal.standardIndexID == gender.standardIndexID) {
                                                                                return {
                                                                                    ID: internal.id
                                                                                    , FabricDetailID: internal.fabricDetailID
                                                                                    , StandardID: internal.standardID
                                                                                    , TestID: internal.testID
                                                                                    , ColorDepth: internal.colorDepth
                                                                                    , Field: internal.field
                                                                                    , ResultType: internal.resultType
                                                                                    , MinResult: internal.minResult.toString()
                                                                                    , MinResultForEdit: internal.resultType == 3 ? getResultTypeGradeOptions.filter((data => {
                                                                                        return data.id == parseInt(internal.minResult)
                                                                                    })) : []
                                                                                    , MaxResult: internal.maxResult.toString()
                                                                                    , FabricIndexID: internal.fabricIndexID
                                                                                    , StandardIndexID: internal.standardIndexID
                                                                                    , GenderIndexID: internal.genderIndexID
                                                                                    , InternalIndexID: internal.internalIndexID
                                                                                    , TestTypeID: internal.testTypeID
                                                                                };
                                                                            }
                                                                        }))
                                                                    }
                                                                }
                                                            }))
                                                        }]
                                                    });
                                                });
                                                if (UnmappedFabricData.length >= 0) {
                                                    UnmappedFabricData.forEach(garment => {
                                                        FormattedFabricGroupData.push({
                                                            testID: garment.testID
                                                            , testType: garment.testType
                                                            , testName: garment.testName
                                                            , parentFields: [{
                                                                ID: 0
                                                                , TestType: 1
                                                                , TestID: garment.testID
                                                                , CreatedOn: ""
                                                                , Group: ""
                                                                , FabricDetails: ""
                                                                , FabricIndexID: 0
                                                                , StandardResultRequirement: [
                                                                    {
                                                                        ID: 0
                                                                        , TestID: garment.testID
                                                                        , CreatedOn: new Date()
                                                                        , EffectFromDate: ""
                                                                        , StandardID: ""
                                                                        , GenderID: ""
                                                                        , FabricDetailID: 0
                                                                        , FabricIndexID: 0
                                                                        , StandardIndexID: 0
                                                                        , GenderIndexID: 0
                                                                        , TestType: 1
                                                                        , InternalGrid: [{
                                                                            ID: 0
                                                                            , FabricDetailID: 0
                                                                            , StandardID: ""
                                                                            , TestID: garment.testID
                                                                            , ColorDepth: ""
                                                                            , Field: ""
                                                                            , ResultType: ""
                                                                            , MinResult: ""
                                                                            , MinResultForEdit: []
                                                                            , MaxResult: ""
                                                                            , FabricIndexID: ""
                                                                            , StandardIndexID: ""
                                                                            , InternalIndexID: ""
                                                                            , GenderIndexID: ""
                                                                            , TestTypeID: 1
                                                                        }]
                                                                    }
                                                                ]
                                                            }]
                                                        })
                                                    })
                                                }
                                                setFabricTabData(FormattedFabricGroupData);
                                                let YarnTabGroupdata = res.data.item3.filter((fabric => {
                                                    return fabric.testType == 2;
                                                }));
                                                let YarnTabGenderdata = res.data.item5.filter((fabric => {
                                                    return fabric.testType == 2;
                                                }));
                                                let YarnTabInternaldata = res.data.item6.filter((fabric => {
                                                    return fabric.testTypeID == 2;
                                                }));
                                                var UnmappedYarnData = res.data.item2.filter((data) => {
                                                    return data.testType == 2 && data.isMapped == 0;
                                                })
                                                let FormattedYarnGroupData = [];
                                                YarnTabGroupdata.forEach(fabric => {
                                                    FormattedYarnGroupData.push({
                                                        id: fabric.id
                                                        , testID: fabric.testID
                                                        , testType: fabric.testType
                                                        , parentFields: [{
                                                            ID: fabric.id
                                                            , TestType: parseInt(fabric.testType)
                                                            , TestID: fabric.testID
                                                            , CreatedOn: new Date(fabric.createdOn)
                                                            , Group: fabric.group
                                                            , FabricDetails: fabric.fabricDetails
                                                            , FabricIndexID: fabric.fabricIndexID
                                                            , StandardResultRequirement: YarnTabGenderdata.filter(gender => {
                                                                if (gender.fabricIndexID == fabric.fabricIndexID && gender.testID == fabric.testID) {
                                                                    return true;
                                                                }
                                                                else {
                                                                    return false;
                                                                }
                                                            }).map((gender => {
                                                                if (gender.fabricIndexID == fabric.fabricIndexID) {
                                                                    return {
                                                                        ID: gender.id
                                                                        , TestID: gender.testID
                                                                        , CreatedOn: new Date(gender.createdOn)
                                                                        , EffectFromDate: gender.effectFromDate
                                                                        , StandardID: gender.standard
                                                                        , GenderID: gender.genderID
                                                                        , FabricDetailID: gender.fabricDetailID
                                                                        , FabricIndexID: gender.fabricIndexID
                                                                        , StandardIndexID: gender.standardIndexID
                                                                        , GenderIndexID: gender.genderIndexID
                                                                        , TestType: parseInt(gender.testType)
                                                                        , InternalGrid: YarnTabInternaldata.filter(internal => {
                                                                            if (internal.testID == gender.testID && internal.fabricDetailID == gender.fabricDetailID
                                                                                && internal.fabricIndexID == gender.fabricIndexID
                                                                                && internal.standardIndexID == gender.standardIndexID) {
                                                                                return true;
                                                                            }
                                                                            else {
                                                                                return false;
                                                                            }
                                                                        }).map((internal => {
                                                                            if (internal.testID == gender.testID && internal.fabricDetailID == gender.fabricDetailID
                                                                                && internal.fabricIndexID == gender.fabricIndexID
                                                                                && internal.standardIndexID == gender.standardIndexID) {
                                                                                return {
                                                                                    ID: internal.id
                                                                                    , FabricDetailID: internal.fabricDetailID
                                                                                    , StandardID: internal.standardID
                                                                                    , TestID: internal.testID
                                                                                    , ColorDepth: internal.colorDepth
                                                                                    , Field: internal.field
                                                                                    , ResultType: internal.resultType
                                                                                    , MinResult: internal.minResult.toString()
                                                                                    , MinResultForEdit: internal.resultType == 3 ? getResultTypeGradeOptions.filter((data => {
                                                                                        return data.id == parseInt(internal.minResult)
                                                                                    })) : []
                                                                                    , MaxResult: internal.maxResult.toString()
                                                                                    , FabricIndexID: internal.fabricIndexID
                                                                                    , StandardIndexID: internal.standardIndexID
                                                                                    , GenderIndexID: internal.genderIndexID
                                                                                    , InternalIndexID: internal.internalIndexID
                                                                                    , TestTypeID: internal.testTypeID
                                                                                };
                                                                            }
                                                                        }))
                                                                    }
                                                                }
                                                                else {
                                                                    return false;
                                                                }
                                                            }))
                                                        }]
                                                    });
                                                })
                                                if (UnmappedYarnData.length >= 0) {
                                                    UnmappedYarnData.forEach(garment => {
                                                        FormattedYarnGroupData.push({
                                                            testID: garment.testID
                                                            , testType: garment.testType
                                                            , testName: garment.testName
                                                            , parentFields: [{
                                                                ID: 0
                                                                , TestType: 2
                                                                , TestID: garment.testID
                                                                , CreatedOn: ""
                                                                , Group: ""
                                                                , FabricDetails: ""
                                                                , FabricIndexID: 0
                                                                , StandardResultRequirement: [
                                                                    {
                                                                        ID: 0
                                                                        , TestID: garment.testID
                                                                        , CreatedOn: new Date()
                                                                        , EffectFromDate: ""
                                                                        , StandardID: ""
                                                                        , GenderID: ""
                                                                        , FabricDetailID: 0
                                                                        , FabricIndexID: 0
                                                                        , StandardIndexID: 0
                                                                        , GenderIndexID: 0
                                                                        , TestType: 2
                                                                        , InternalGrid: [{
                                                                            ID: 0
                                                                            , FabricDetailID: 0
                                                                            , StandardID: ""
                                                                            , TestID: garment.testID
                                                                            , ColorDepth: ""
                                                                            , Field: ""
                                                                            , ResultType: ""
                                                                            , MinResult: ""
                                                                            , MinResultForEdit: []
                                                                            , MaxResult: ""
                                                                            , FabricIndexID: ""
                                                                            , StandardIndexID: ""
                                                                            , InternalIndexID: ""
                                                                            , GenderIndexID: ""
                                                                            , TestTypeID: 2
                                                                        }]
                                                                    }
                                                                ]
                                                            }]
                                                        })
                                                    })
                                                }
                                                setYarnTabData(FormattedYarnGroupData);
                                                let GarmentTabGroupdata = res.data.item3.filter((fabric => {
                                                    return fabric.testType == 3;
                                                }));
                                                let GarmentTabGenderdata = res.data.item5.filter((fabric => {
                                                    return fabric.testType == 3;
                                                }));
                                                let GarmentTabInternaldata = res.data.item6.filter((fabric => {
                                                    return fabric.testTypeID == 3;
                                                }));
                                                var UnmappedGarmetData = res.data.item2.filter((data) => {
                                                    return data.testType == 3 && data.isMapped == 0;
                                                })
                                                let FormattedGarmentGroupData = [];
                                                GarmentTabGroupdata.forEach(fabric => {
                                                    FormattedGarmentGroupData.push({
                                                        testID: fabric.testID
                                                        , testType: fabric.testType
                                                        , parentFields: [{
                                                            ID: fabric.id
                                                            , TestType: parseInt(fabric.testType)
                                                            , TestID: fabric.testID
                                                            , CreatedOn: new Date(fabric.createdOn)
                                                            , Group: fabric.group
                                                            , FabricDetails: fabric.fabricDetails
                                                            , FabricIndexID: fabric.fabricIndexID
                                                            , StandardResultRequirement: GarmentTabGenderdata.filter(gender => {
                                                                if (gender.fabricIndexID == fabric.fabricIndexID && fabric.testID == gender.testID) {
                                                                    return true;
                                                                }
                                                                else {
                                                                    return false;
                                                                }
                                                            }).map((gender => {
                                                                if (gender.fabricIndexID == fabric.fabricIndexID) {
                                                                    return {
                                                                        ID: gender.id
                                                                        , TestID: gender.testID
                                                                        , CreatedOn: new Date(gender.createdOn)
                                                                        , EffectFromDate: gender.effectFromDate
                                                                        , StandardID: gender.standard
                                                                        , GenderID: gender.genderID
                                                                        , FabricDetailID: gender.fabricDetailID
                                                                        , FabricIndexID: gender.fabricIndexID
                                                                        , StandardIndexID: gender.standardIndexID
                                                                        , GenderIndexID: gender.genderIndexID
                                                                        , TestType: parseInt(gender.testType)
                                                                        , InternalGrid: GarmentTabInternaldata.filter(internal => {
                                                                            if (internal.testID == gender.testID && internal.fabricDetailID == gender.fabricDetailID
                                                                                && internal.fabricIndexID == gender.fabricIndexID
                                                                                && internal.standardIndexID == gender.standardIndexID) {
                                                                                return true;
                                                                            }
                                                                            else {
                                                                                return false;
                                                                            }
                                                                        }).map((internal => {
                                                                            if (internal.testID == gender.testID && internal.fabricDetailID == gender.fabricDetailID
                                                                                && internal.fabricIndexID == gender.fabricIndexID
                                                                                && internal.standardIndexID == gender.standardIndexID) {
                                                                                return {
                                                                                    ID: internal.id
                                                                                    , FabricDetailID: internal.fabricDetailID
                                                                                    , StandardID: internal.standardID
                                                                                    , TestID: internal.testID
                                                                                    , ColorDepth: internal.colorDepth
                                                                                    , Field: internal.field
                                                                                    , ResultType: internal.resultType
                                                                                    , MinResult: internal.minResult.toString()
                                                                                    , MinResultForEdit: internal.resultType == 3 ? getResultTypeGradeOptions.filter((data => {
                                                                                        return data.id == parseInt(internal.minResult)
                                                                                    })) : []
                                                                                    , MaxResult: internal.maxResult.toString()
                                                                                    , FabricIndexID: internal.fabricIndexID
                                                                                    , StandardIndexID: internal.standardIndexID
                                                                                    , GenderIndexID: internal.genderIndexID
                                                                                    , InternalIndexID: internal.internalIndexID
                                                                                    , TestTypeID: internal.testTypeID
                                                                                };
                                                                            }
                                                                        }))
                                                                    }
                                                                }
                                                                else {
                                                                    return false;
                                                                }
                                                            }))
                                                        }]
                                                    });
                                                })
                                                if (UnmappedGarmetData.length >= 0) {
                                                    UnmappedGarmetData.forEach(garment => {
                                                        FormattedGarmentGroupData.push({
                                                            testID: garment.testID
                                                            , testType: garment.testType
                                                            , testName: garment.testName
                                                            , parentFields: [{
                                                                ID: 0
                                                                , TestType: 3
                                                                , TestID: garment.testID
                                                                , CreatedOn: ""
                                                                , Group: ""
                                                                , FabricDetails: ""
                                                                , FabricIndexID: 0
                                                                , StandardResultRequirement: [
                                                                    {
                                                                        ID: 0
                                                                        , TestID: garment.testID
                                                                        , CreatedOn: new Date()
                                                                        , EffectFromDate: ""
                                                                        , StandardID: ""
                                                                        , GenderID: ""
                                                                        , FabricDetailID: 0
                                                                        , FabricIndexID: 0
                                                                        , StandardIndexID: 0
                                                                        , GenderIndexID: 0
                                                                        , TestType: 3
                                                                        , InternalGrid: [{
                                                                            ID: 0
                                                                            , FabricDetailID: 0
                                                                            , StandardID: ""
                                                                            , TestID: garment.testID
                                                                            , ColorDepth: ""
                                                                            , Field: ""
                                                                            , ResultType: ""
                                                                            , MinResult: ""
                                                                            , MinResultForEdit: []
                                                                            , MaxResult: ""
                                                                            , FabricIndexID: ""
                                                                            , StandardIndexID: ""
                                                                            , GenderIndexID: ""
                                                                            , InternalIndexID: ""
                                                                            , TestTypeID: 3
                                                                        }]
                                                                    }
                                                                ]
                                                            }]
                                                        })
                                                    })
                                                }
                                                setGarmentTabData(FormattedGarmentGroupData);
                                                let StrikeOffTabGroupdata = res.data.item3.filter((fabric => {
                                                    return fabric.testType == 4;
                                                }));
                                                let StrikeOffTabGenderdata = res.data.item5.filter((fabric => {
                                                    return fabric.testType == 4;
                                                }));
                                                let StrikeOffTabInternaldata = res.data.item6.filter((fabric => {
                                                    return fabric.testTypeID == 4;
                                                }));
                                                var UnmappedStrikeOffData = res.data.item2.filter((data) => {
                                                    return data.testType == 4 && data.isMapped == 0;
                                                })
                                                let FormattedStrikeOffGroupData = [];
                                                StrikeOffTabGroupdata.forEach(fabric => {
                                                    FormattedStrikeOffGroupData.push({
                                                        testID: fabric.testID
                                                        , testType: fabric.testType
                                                        , parentFields: [{
                                                            ID: fabric.id
                                                            , TestType: parseInt(fabric.testType)
                                                            , TestID: fabric.testID
                                                            , CreatedOn: new Date(fabric.createdOn)
                                                            , Group: fabric.group
                                                            , FabricDetails: fabric.fabricDetails
                                                            , FabricIndexID: fabric.fabricIndexID
                                                            , StandardResultRequirement: StrikeOffTabGenderdata.filter(gender => {
                                                                if (gender.fabricIndexID == fabric.fabricIndexID && fabric.testID == gender.testID) {
                                                                    return true;
                                                                }
                                                                else {
                                                                    return false;
                                                                }
                                                            }).map((gender => {
                                                                if (gender.fabricIndexID == fabric.fabricIndexID) {
                                                                    return {
                                                                        ID: gender.id
                                                                        , TestID: gender.testID
                                                                        , CreatedOn: new Date(gender.createdOn)
                                                                        , EffectFromDate: gender.effectFromDate
                                                                        , StandardID: gender.standard
                                                                        , GenderID: gender.genderID
                                                                        , FabricDetailID: gender.fabricDetailID
                                                                        , FabricIndexID: gender.fabricIndexID
                                                                        , StandardIndexID: gender.standardIndexID
                                                                        , GenderIndexID: gender.genderIndexID
                                                                        , TestType: parseInt(gender.testType)
                                                                        , InternalGrid: StrikeOffTabInternaldata.filter(internal => {
                                                                            if (internal.testID == gender.testID && internal.fabricDetailID == gender.fabricDetailID
                                                                                && internal.fabricIndexID == gender.fabricIndexID
                                                                                && internal.standardIndexID == gender.standardIndexID) {
                                                                                return true;
                                                                            }
                                                                            else {
                                                                                return false;
                                                                            }
                                                                        }).map((internal => {
                                                                            if (internal.testID == gender.testID && internal.fabricDetailID == gender.fabricDetailID
                                                                                && internal.fabricIndexID == gender.fabricIndexID
                                                                                && internal.standardIndexID == gender.standardIndexID) {
                                                                                return {
                                                                                    ID: internal.id
                                                                                    , FabricDetailID: internal.fabricDetailID
                                                                                    , StandardID: internal.standardID
                                                                                    , TestID: internal.testID
                                                                                    , ColorDepth: internal.colorDepth
                                                                                    , Field: internal.field
                                                                                    , ResultType: internal.resultType
                                                                                    , MinResult: internal.minResult.toString()
                                                                                    , MinResultForEdit: internal.resultType == 3 ? getResultTypeGradeOptions.filter((data => {
                                                                                        return data.id == parseInt(internal.minResult)
                                                                                    })) : []
                                                                                    , MaxResult: internal.maxResult.toString()
                                                                                    , FabricIndexID: internal.fabricIndexID
                                                                                    , StandardIndexID: internal.standardIndexID
                                                                                    , GenderIndexID: internal.genderIndexID
                                                                                    , InternalIndexID: internal.internalIndexID
                                                                                    , TestTypeID: internal.testTypeID
                                                                                };
                                                                            }
                                                                        }))
                                                                    }
                                                                }
                                                                else {
                                                                    return false;
                                                                }
                                                            }))
                                                        }]
                                                    });
                                                })
                                                if (UnmappedStrikeOffData.length >= 0) {
                                                    UnmappedStrikeOffData.forEach(garment => {
                                                        FormattedStrikeOffGroupData.push({
                                                            testID: garment.testID
                                                            , testType: garment.testType
                                                            , testName: garment.testName
                                                            , parentFields: [{
                                                                ID: 0
                                                                , TestType: 4
                                                                , TestID: garment.testID
                                                                , CreatedOn: ""
                                                                , Group: ""
                                                                , FabricDetails: ""
                                                                , FabricIndexID: 0
                                                                , StandardResultRequirement: [
                                                                    {
                                                                        ID: 0
                                                                        , TestID: garment.testID
                                                                        , CreatedOn: new Date()
                                                                        , EffectFromDate: ""
                                                                        , StandardID: ""
                                                                        , GenderID: ""
                                                                        , FabricDetailID: 0
                                                                        , FabricIndexID: 0
                                                                        , StandardIndexID: 0
                                                                        , GenderIndexID: 0
                                                                        , TestType: 4
                                                                        , InternalGrid: [{
                                                                            ID: 0
                                                                            , FabricDetailID: 0
                                                                            , StandardID: ""
                                                                            , TestID: garment.testID
                                                                            , ColorDepth: ""
                                                                            , Field: ""
                                                                            , ResultType: ""
                                                                            , MinResult: ""
                                                                            , MinResultForEdit: []
                                                                            , MaxResult: ""
                                                                            , FabricIndexID: ""
                                                                            , StandardIndexID: ""
                                                                            , GenderIndexID: ""
                                                                            , InternalIndexID: ""
                                                                            , TestTypeID: 4
                                                                        }]
                                                                    }
                                                                ]
                                                            }]
                                                        })
                                                    })
                                                }
                                                setStrikeOffTabData(FormattedStrikeOffGroupData);
                                                let TrimsTabGroupdata = res.data.item3.filter((fabric => {
                                                    return fabric.testType == 5;
                                                }));
                                                let TrimsTabGenderdata = res.data.item5.filter((fabric => {
                                                    return fabric.testType == 5;
                                                }));
                                                let TrimsTabInternaldata = res.data.item6.filter((fabric => {
                                                    return fabric.testTypeID == 5;
                                                }));
                                                var UnmappedTrimsData = res.data.item2.filter((data) => {
                                                    return data.testType == 5 && data.isMapped == 0;
                                                })
                                                let FormattedTrimsGroupData = [];
                                                TrimsTabGroupdata.forEach(fabric => {
                                                    FormattedTrimsGroupData.push({
                                                        testID: fabric.testID
                                                        , testType: fabric.testType
                                                        , parentFields: [{
                                                            ID: fabric.id
                                                            , TestType: parseInt(fabric.testType)
                                                            , TestID: fabric.testID
                                                            , CreatedOn: new Date(fabric.createdOn)
                                                            , Group: fabric.group
                                                            , FabricDetails: fabric.fabricDetails
                                                            , FabricIndexID: fabric.fabricIndexID
                                                            , StandardResultRequirement: TrimsTabGenderdata.filter(gender => {
                                                                if (gender.fabricIndexID == fabric.fabricIndexID && fabric.testID == gender.testID) {
                                                                    return true;
                                                                }
                                                                else {
                                                                    return false;
                                                                }
                                                            }).map((gender => {
                                                                if (gender.fabricIndexID == fabric.fabricIndexID) {
                                                                    return {
                                                                        ID: gender.id
                                                                        , TestID: gender.testID
                                                                        , CreatedOn: new Date(gender.createdOn)
                                                                        , EffectFromDate: gender.effectFromDate
                                                                        , StandardID: gender.standard
                                                                        , GenderID: gender.genderID
                                                                        , FabricDetailID: gender.fabricDetailID
                                                                        , FabricIndexID: gender.fabricIndexID
                                                                        , StandardIndexID: gender.standardIndexID
                                                                        , GenderIndexID: gender.genderIndexID
                                                                        , TestType: parseInt(gender.testType)
                                                                        , InternalGrid: TrimsTabInternaldata.filter(internal => {
                                                                            if (internal.testID == gender.testID && internal.fabricDetailID == gender.fabricDetailID
                                                                                && internal.fabricIndexID == gender.fabricIndexID
                                                                                && internal.standardIndexID == gender.standardIndexID) {
                                                                                return true;
                                                                            }
                                                                            else {
                                                                                return false;
                                                                            }
                                                                        }).map((internal => {
                                                                            if (internal.testID == gender.testID && internal.fabricDetailID == gender.fabricDetailID
                                                                                && internal.fabricIndexID == gender.fabricIndexID
                                                                                && internal.standardIndexID == gender.standardIndexID) {
                                                                                return {
                                                                                    ID: internal.id
                                                                                    , FabricDetailID: internal.fabricDetailID
                                                                                    , StandardID: internal.standardID
                                                                                    , TestID: internal.testID
                                                                                    , ColorDepth: internal.colorDepth
                                                                                    , Field: internal.field
                                                                                    , ResultType: internal.resultType
                                                                                    , MinResult: internal.minResult.toString()
                                                                                    , MinResultForEdit: internal.resultType == 3 ? getResultTypeGradeOptions.filter((data => {
                                                                                        return data.id == parseInt(internal.minResult)
                                                                                    })) : []
                                                                                    , MaxResult: internal.maxResult.toString()
                                                                                    , FabricIndexID: internal.fabricIndexID
                                                                                    , StandardIndexID: internal.standardIndexID
                                                                                    , GenderIndexID: internal.genderIndexID
                                                                                    , InternalIndexID: internal.internalIndexID
                                                                                    , TestTypeID: internal.testTypeID
                                                                                };
                                                                            }
                                                                        }))
                                                                    }
                                                                }
                                                                else {
                                                                    return false;
                                                                }
                                                            }))
                                                        }]
                                                    });
                                                })
                                                if (UnmappedTrimsData.length >= 0) {
                                                    UnmappedTrimsData.forEach(garment => {
                                                        FormattedTrimsGroupData.push({
                                                            testID: garment.testID
                                                            , testType: garment.testType
                                                            , testName: garment.testName
                                                            , parentFields: [{
                                                                ID: 0
                                                                , TestType: 5
                                                                , TestID: garment.testID
                                                                , CreatedOn: ""
                                                                , Group: ""
                                                                , FabricDetails: ""
                                                                , FabricIndexID: 0
                                                                , StandardResultRequirement: [
                                                                    {
                                                                        ID: 0
                                                                        , TestID: garment.testID
                                                                        , CreatedOn: new Date()
                                                                        , EffectFromDate: ""
                                                                        , StandardID: ""
                                                                        , GenderID: ""
                                                                        , FabricDetailID: 0
                                                                        , FabricIndexID: 0
                                                                        , StandardIndexID: 0
                                                                        , GenderIndexID: 0
                                                                        , TestType: 5
                                                                        , InternalGrid: [{
                                                                            ID: 0
                                                                            , FabricDetailID: 0
                                                                            , StandardID: ""
                                                                            , TestID: garment.testID
                                                                            , ColorDepth: ""
                                                                            , Field: ""
                                                                            , ResultType: ""
                                                                            , MinResult: ""
                                                                            , MinResultForEdit: []
                                                                            , MaxResult: ""
                                                                            , FabricIndexID: ""
                                                                            , StandardIndexID: ""
                                                                            , GenderIndexID: ""
                                                                            , InternalIndexID: ""
                                                                            , TestTypeID: 5
                                                                        }]
                                                                    }
                                                                ]
                                                            }]
                                                        })
                                                    })
                                                }
                                                setTrimsTabData(FormattedTrimsGroupData);

                                                hideLoader();
                                            }).catch(err => {
                                                hideLoader();
                                            });
                                    })
                                        .catch(() => {
                                            hideLoader();
                                        });
                                }
                            }).catch(() => {
                                hideLoader();
                            });
                        }
                    })
                    .catch(() => {
                        hideLoader();
                    });
            }
        }
    }, []);

    useEffect(() => {
        let GenderSizeID = 0;
        dispatch(retrieveGender(GenderSizeID));
        dispatch(retrieveColorDepth());
        dispatch(retrieveTestField());
    }, [dispatch])

    useEffect(() => {
        dispatch(retrieveColorDepth());
    }, [dispatch, getIsReloadColorDepthOfParent]);

    useEffect(() => {
        dispatch(retrieveTestField());
    }, [dispatch, getIsReloadTestFieldOfParent]);

    useEffect(() => {
        BrandWiseStandardService.LoadMappedTestsForBrandwiseStandard().then((response) => {
            if (response.data) {
                setStandardOptions((response.data.item2));
            }
        }
        )
    }, [dispatch, getIsReloadTestStandardOfParent]);


    // useEffect(() => {
    // }, [getFabricTabData && getFabricTabData.length > 0]);

    // useEffect(() => {
    // }, [getYarnTabData && getYarnTabData.length > 0]);

    // useEffect(() => {
    // }, [getGarmentTabData && getGarmentTabData.length > 0]);

    // useEffect(() => {
    // }, [getStrikeOffTabData && getStrikeOffTabData.length > 0]);

    // useEffect(() => {
    // }, [getTrimsTabData && getTrimsTabData.length > 0]);

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common"> {getActionMode} Brand Wise Standard</h3>
                                <div className="buttons-preview" align="right">
                                    <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back
                                    </span>
                                    {getActionMode != "View" && <><span className="btn btn-danger" onClick={() => ResetOperation()}>
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset
                                    </span>
                                        <button type="button" className="btn btn-success" onClick={UpdateBrandWiseStandardMaster} disabled={buttonLoader}>
                                            <i className="fa fa-check right"></i>&nbsp;
                                            Update
                                        </button></>}
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <Form>
                                        {
                                            inputFields.map((inputField, index) => (
                                                <>
                                                    <div className="row row_left10">
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Buyer<span className="text-danger">*</span>
                                                                </label>
                                                                <Reactselect className="basic-single" name="BuyerID"
                                                                    id={
                                                                        "BuyerID"
                                                                    }
                                                                    isDisabled={true}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    value={inputField.BuyerIDForEdit}
                                                                    options={getBuyerOptions}
                                                                    autoFocus
                                                                    styles={submitted && inputField.BuyerID === 0 ? styles : styles1}

                                                                ></Reactselect>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Brand<span className="text-danger">*</span>
                                                                </label>
                                                                <Reactselect className="basic-single" name="BuyerID"
                                                                    id={
                                                                        "BrandID"
                                                                    }
                                                                    isDisabled={true}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    // onChange={handleBrandChange}
                                                                    value={inputField.BrandIDForEdit}
                                                                    options={getBrandOptions}
                                                                    styles={submitted && inputField.BrandID === 0 ? styles : styles1}

                                                                ></Reactselect>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Internal Ref No.<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type='test' className='form-control' name="buyerversiondate"
                                                                        id="buyerversiondate" disabled={true}
                                                                        autoComplete="off" maxLength="200" value={inputField.InternalRefID} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Buyer Version and Date<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type='test' className='form-control' name="buyerversiondate"
                                                                        style={{ border: submitted && !inputField.BuyerVersionDate ? '1px solid red' : '' }}
                                                                        id="buyerversiondate" disabled={false} onChange={handleTextBoxChange}
                                                                        autoComplete="off" maxLength="200" value={inputField.BuyerVersionDate} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Received Date<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right"
                                                                    style={{ border: submitted && !inputField.ReceivedDate ? '1px solid red' : '' }}>
                                                                    <DatePicker className="form-control" name="ReceivedDate"
                                                                        id={
                                                                            "ReceivedDate"
                                                                        }
                                                                        selected={
                                                                            inputField.ReceivedDate
                                                                        }
                                                                        onChange={
                                                                            event => handleChangeDate(index, event, "ReceivedDate")
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        customInput={<CustomInput />}
                                                                        isClearable={inputField.ReceivedDate === null ? false : true}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Effect From Date<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right"
                                                                    style={{ border: submitted && !inputField.EffectFromDate ? '1px solid red' : '' }}>
                                                                    <DatePicker className="form-control" name="ReceivedDate"
                                                                        id={
                                                                            "EffectFromDate"
                                                                        }
                                                                        selected={
                                                                            inputField.EffectFromDate
                                                                        }
                                                                        onChange={
                                                                            event => handleChangeDate(index, event, "EffectFromDate")
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        customInput={<CustomInput />}
                                                                        isClearable={inputField.EffectFromDate === null ? false : true}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </Form>
                                    <div className='col-md-12' style={{ marginTop: '15px' }}>
                                        <div className="tabbable" style={{ width: "100%" }}>
                                            <ul className="nav nav-tabs nav-justified" id="myTab5" >
                                                <li className="active" id="liFabric" style={{ zIndex: '0' }}>
                                                    <a data-toggle="tab" href="#idFabric" id="aidfabric" >
                                                        <h5>FABRIC</h5>
                                                    </a>
                                                </li>
                                                <li className="" id="liYarn" >
                                                    <a data-toggle="tab" href="#idYarn" id="aidyarn" style={{ zIndex: '0' }}>
                                                        <h5>YARN</h5>
                                                    </a>
                                                </li>

                                                <li className="" id="liGarment">
                                                    <a data-toggle="tab" href="#idGarment" id="aidgarment" style={{ zIndex: '0' }}>
                                                        <h5>GARMENT</h5>
                                                    </a>
                                                </li>
                                                <li className="" id="liStikrOff">
                                                    <a data-toggle="tab" href="#idStrikeOff" id="aidstrikeoff" style={{ zIndex: '0' }}>
                                                        <h5>STRIKE OFF</h5>
                                                    </a>
                                                </li>
                                                <li className="" id="liTrims">
                                                    <a data-toggle="tab" href="#idTrims" id="aidtrims" style={{ zIndex: '0' }}>
                                                        <h5>TRIMS</h5>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content Dashboard" style={{ padding: "0px", marginBottom: '20px' }}>
                                                <div id="idFabric" className="active tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    <EditBrandStandardFabric props={props} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions}
                                                        FabricTestNameList={getFabricTestNames}
                                                        FabricOptions={getFabricOptions} FabricAndGroupDetails={getFabricAndGroupDetails}
                                                        StandardAndGenderDetails={getStandardAndGenderDetails} InteralGridDetails={getInternalGridDetails}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} BuyerID={getCurrentBuyerID} BrandID={getCurrentBrandID}
                                                        SubmitFabricDataCallBack={SubmitFabricDataCallBack} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed} ActionMode={getActionMode}
                                                    ></EditBrandStandardFabric>
                                                </div>
                                                <div id="idYarn" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    <EditBrandStandardYarn props={props} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions}
                                                        YarnTestNameList={getFabricTestNames}
                                                        FabricOptions={getFabricOptions} FabricAndGroupDetails={getFabricAndGroupDetails}
                                                        StandardAndGenderDetails={getStandardAndGenderDetails} InteralGridDetails={getInternalGridDetails}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} BuyerID={getCurrentBuyerID} BrandID={getCurrentBrandID}
                                                        SubmitYarnDataCallBack={SubmitYarnDataCallBack} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed} ActionMode={getActionMode}></EditBrandStandardYarn>
                                                </div>
                                                <div id="idGarment" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    <EditBrandStandardGarment props={props} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions}
                                                        GarmentTestNameList={getFabricTestNames}
                                                        FabricOptions={getGarmaentdpData} FabricAndGroupDetails={getFabricAndGroupDetails}
                                                        StandardAndGenderDetails={getStandardAndGenderDetails} InteralGridDetails={getInternalGridDetails}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} BuyerID={getCurrentBuyerID} BrandID={getCurrentBrandID}
                                                        SubmitGarmentDataCallBack={SubmitGarmentDataCallBack} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed} ActionMode={getActionMode}></EditBrandStandardGarment>
                                                </div>
                                                <div id="idStrikeOff" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    <EditBrandStandardStrikeOff props={props} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions}
                                                        StrikeOffTestNameList={getFabricTestNames}
                                                        FabricOptions={getStrikeoffDpData} FabricAndGroupDetails={getFabricAndGroupDetails}
                                                        StandardAndGenderDetails={getStandardAndGenderDetails} InteralGridDetails={getInternalGridDetails}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} BuyerID={getCurrentBuyerID} BrandID={getCurrentBrandID}
                                                        SubmitStrikeOffDataCallBack={SubmitStrikeOffDataCallBack} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed} ActionMode={getActionMode}></EditBrandStandardStrikeOff>
                                                </div>
                                                <div id="idTrims" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    <EditBrandStandardTrims props={props} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions}
                                                        TrimsTestNameList={getFabricTestNames}
                                                        FabricOptions={getFabricOptions} FabricAndGroupDetails={getFabricAndGroupDetails}
                                                        StandardAndGenderDetails={getStandardAndGenderDetails} InteralGridDetails={getInternalGridDetails}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} BuyerID={getCurrentBuyerID} BrandID={getCurrentBrandID}
                                                        SubmitTrimsDataCallBack={SubmitTrimsDataCallBack} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed} ActionMode={getActionMode}></EditBrandStandardTrims>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="" style={{ marginTop: '10px' }}>
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            {getActionMode != "View" && <><span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                                <button type="button" className="btn btn-success" onClick={UpdateBrandWiseStandardMaster} disabled={buttonLoader}>
                                                    <i className="fa fa-check right"></i>&nbsp;
                                                    Update
                                                </button></>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                       {
                            getID.showPopupConfirm ? <ConfirmationPopup props={props} data={
                                getID.Params
                            }
                                deleteCallback={Delcallback}
                                showpopup={true}
                            /> : null
                       }
                        </div>
                    </div>
                </div>

            </div>
            {loader}
        </>
    )
}
export default BrandwiseEdit;