import React, { useState, useEffect, Fragment } from 'react'
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import FinalinspecUpload from "../../Common/FinalinspecUpload";
import axios from "axios";
import $ from "jquery";
import Nodify from "../../Common/ReactNotification";
import Lightbox from "react-image-lightbox"
import QcInspection from '../../../services/QCInspection/QcInspection';
import { Label } from "reactstrap";
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
import QACommentPointService from '../../../services/Master/QACommentPointService';
const AddQCReportTab = ({ props, basicDetail, setrerenderstate, Qunatitylist, commentList, FileuploadList, QcConclusionList, IsvalidationCallback, rerenderdep, QCDpvalidation }) => {
    debugger
    const [gettolerance, settolerance] = useState(0);
    const [getInspectionQtyInfo, setInspectionQtyInfo] = useState([]);
    const [getTotalValues, setTotalValues] = useState({ PoQty: 0, Prodqty: 0 });
    const [getFinalInspectionPopup, setFinalInspectionPopup] = useState(false);
    const EmptyInputFields = { CommentID: 0, Comment: '' };
    const [ExistingList, setExistingList] = useState([]);
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [selectall, setselectall] = useState(false);
    const [getDefect, setDefect] = useState([{ Id: 0, Name: "", }]);
    const [getDefectPopup, setDefectPopup] = useState(false);
    const [getCommentsList, setCommentsList] = useState([]);
    const [TestReportExpandState, setTestReportExpandState] = useState({});
    const [TestReportexpandedRows, setTestReportExpandedRows] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [submittedQA, setSubmittedQA] = useState(false);
    const [submittedQCDp, setSubmittedQCDp] = useState(false);
    const [getFiles, setFiles] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const actiondropdown = [{ value: 1, label: 'Yes' },]
    const [dropDownlist, setDropdownlist] = useState([])
    const [CommentDroplist, setCommentDroplist] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [getImagenameupload, setImagenameupload] = useState();
    const [getQcremarksdrpdwn, setQcremarksdrpdwn] = useState({ QARemarks: '', QcDropdownID: 0, QCDropDownn: '' });
    const Commentvalue = [...getCommentsList];
    const { user: currentUser } = useSelector((state) => state.auth);
    useEffect(() => {
        setSubmitted(IsvalidationCallback);
    }, [IsvalidationCallback === true]);
    useEffect(() => {
        setSubmittedQCDp(QCDpvalidation);
    }, [QCDpvalidation === true]);

    const QcinpsectionId = JSON.parse(localStorage.getItem("QCInspectionID"));
    useEffect(() => {

        QACommentPointService.GetQACommentPointList().then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
        if (props.location.state.params.TaskStatus === 1) {

            if (QcinpsectionId !== null) {
                QcInspection.getAllQcInfoList(1, props.location.state.params.details.tnaid, QcinpsectionId).then((res) => {
                    if (res.data.qCinspectionMainDetails.length > 0) {
                        res.data.qCinspectionMainDetails.map((x) => {
                            getQcremarksdrpdwn.QARemarks = x.qcRemarks;
                            getQcremarksdrpdwn.QcDropdownID = x.qcConclusionID;
                        })
                        setQcremarksdrpdwn(getQcremarksdrpdwn);
                        QcConclusionList(getQcremarksdrpdwn);
                    }
                })

                QcInspection.getAllQcInfoList(2, props.location.state.params.details.tnaid, QcinpsectionId).then((res) => {
                    let newvalue = [];
                    if (res.data.qCinspectionSkuQualityDetails.length > 0) {
                        res.data.qCinspectionSkuQualityDetails.map((x) => {
                            newvalue.push({
                                TNAQCinspcQualityinfoID: x.tnaqCinspcQualityinfoID,
                                skuID: x.skuID,
                                refInspecSKUID: x.skuID,
                                purchaseOrderSkuID: x.purchaseOrderSkuID,
                                styleNo: x.styleNo,
                                tnaExFactoryId: x.subTaskID,
                                skuName: x.skuName,
                                endCustomer: x.endCustomer,
                                idNo: x.idno,
                                isSelected: 1,
                                orderQuantity: x.poQty,
                                prodqty: x.productQty,
                                IsDeleted: 0,
                                styleProgramID: x.styleProgramID
                            })
                        })
                        Qunatitylist(newvalue);
                        setInspectionQtyInfo(newvalue);
                        MainInfoCommonCalculation(newvalue);
                    }
                    let TestReportSaveData = [];
                    if (res.data.qcCommentsDetails) {
                        if (res.data.qcCommentsDetails.length > 0) {
                            res.data.qcCommentsDetails.map((x, i) => {
                                TestReportSaveData.push({
                                    TNAQCInspecCommentID: x.tnaqcInspecCommentID,
                                    CommnetsID: x.commnetsID,
                                    ResultID: x.resultID,
                                    CommentMainIndex: x.commentMainIndex,
                                    IsDeleted: 0,
                                    ImagesList: []
                                })
                                res.data.qcCommentsSubDetails.filter(nex => nex.mainindex === x.commentMainIndex).map((y, chilindex) => {
                                    TestReportSaveData[i].ImagesList.push({
                                        TNAQCInspeCommentsSubID: y.tnaqcInspeCommentsSubID,
                                        UploadedImagePath1: y.uploadedImagePath1,
                                        UploadedImagePath2: y.uploadedImagePath2,
                                        MainIndex: i,
                                        IsDeleted: 0
                                    })
                                })
                            })
                        } else {
                            TestReportSaveData.push({
                                TNAQCInspecCommentID: 0,
                                CommnetsID: 0,
                                ResultID: 0,
                                CommentMainIndex: 0,
                                IsDeleted: 0,
                                ImagesList: []
                            })
                            TestReportSaveData[0].ImagesList.push({
                                TNAQCInspeCommentsSubID: 0,
                                UploadedImagePath1: "",
                                UploadedImagePath2: "",
                                MainIndex: 0,
                                IsDeleted: 0
                            })
                        }
                        commentList(TestReportSaveData);
                        setCommentsList(TestReportSaveData);
                    }
                    if (res.data.qcFileuploadDetails) {
                        let FileList = [];
                        if (res.data.qcFileuploadDetails.length > 0) {
                            res.data.qcFileuploadDetails.map((x, i) => {
                                FileList.push({
                                    QCReportFileID: x.tnaQcInspectionFileupID,
                                    FileName: x.fileName,
                                    FilePath: x.filepath,
                                    FileType: x.fileType,
                                    IsDeleted: 0,
                                })
                                setFiles(FileList);
                                FileuploadList(FileList)
                            })
                        }
                    }
                })
            } else {
                let CommentsAddData = [];
                let Multiselecteddate = [];
                CommentsAddData.push({
                    TNAQCInspecCommentID: 0,
                    CommnetsID: 0,
                    ResultID: 0,
                    CommentMainIndex: 0,
                    IsDeleted: 0,
                    ImagesList: []
                })
                CommentsAddData[0].ImagesList.push({
                    TNAQCInspeCommentsSubID: 0,
                    UploadedImagePath1: "",
                    UploadedImagePath2: "",
                    MainIndex: 0,
                    IsDeleted: 0
                })
                commentList(CommentsAddData);
                setCommentsList(CommentsAddData);

                Multiselecteddate = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1)
                setInspectionQtyInfo(Multiselecteddate);
                Qunatitylist(Multiselecteddate)
                MainInfoCommonCalculation(Multiselecteddate);
                QcConclusionList(getQcremarksdrpdwn)
            }
        }
        //use for Dropdown With multiResultset
        let CommonResult = [];
        let Commentlist = [];
        FinalinspectionService.GetTNAFinalinspecTestingReportList(4, props.location.state.params.buyerID, props.location.state.params.brandID).then((res) => {
            if (res.data.length > 0) {
                settolerance(res.data[0].offerQty)
            }
        })

        QcInspection.getCommonResultList(1, props.location.state.params.details.supplierId).then((res) => {

            if (res.data) {
                res.data.qcDropDownListDetails.map((element) => {
                    CommonResult.push({
                        value: element.resultID,
                        label: element.resultName
                    })

                })
                res.data.commentPointList.map((element) => {
                    Commentlist.push({
                        value: element.value,
                        label: element.label
                    })
                })
                setDropdownlist(CommonResult);
                setCommentDroplist(Commentlist);
            }
        })

    }, [rerenderdep])
    useEffect(() => {
        if (props.location.state.params.TaskStatus === 2) {
            QcInspection.getAllQcInfoList(1, props.location.state.params.details.tnaid, props.location.state.params.TNAQCInspectionID).then((res) => {
                if (res.data.qCinspectionMainDetails.length > 0) {
                    res.data.qCinspectionMainDetails.map((x) => {
                        getQcremarksdrpdwn.QARemarks = x.qcRemarks;
                        getQcremarksdrpdwn.QcDropdownID = x.qcConclusionID;
                    })
                    setQcremarksdrpdwn(getQcremarksdrpdwn);
                    QcConclusionList(getQcremarksdrpdwn);
                }
            })

            QcInspection.getAllQcInfoList(2, props.location.state.params.details.tnaid, props.location.state.params.TNAQCInspectionID).then((res) => {
                let newvalue = [];
                if (res.data.qCinspectionSkuQualityDetails.length > 0) {
                    res.data.qCinspectionSkuQualityDetails.map((x) => {
                        newvalue.push({
                            TNAQCinspcQualityinfoID: x.tnaqCinspcQualityinfoID,
                            skuID: x.skuID,
                            purchaseOrderSkuID: x.purchaseOrderSkuID,
                            styleNo: x.styleNo,
                            tnaExFactoryId: x.subTaskID,
                            skuName: x.skuName,
                            endCustomer: x.endCustomer,
                            idNo: x.idno,
                            isSelected: 1,
                            orderQuantity: x.poQty,
                            IsDeleted: 0,
                            prodqty: x.productQty,
                            refInspecSKUID: x.skuID,
                            styleProgramID: x.styleProgramID
                        })
                    })
                    props.location.state.params.multiselecteddatas = newvalue;
                    Qunatitylist(newvalue);
                    setInspectionQtyInfo(newvalue);
                    MainInfoCommonCalculation(newvalue);
                }
                let TestReportSaveData = [];
                if (res.data.qcCommentsDetails) {
                    if (res.data.qcCommentsDetails.length > 0) {
                        res.data.qcCommentsDetails.map((x, i) => {
                            TestReportSaveData.push({
                                TNAQCInspecCommentID: x.tnaqcInspecCommentID,
                                CommnetsID: x.commnetsID,
                                ResultID: x.resultID,
                                CommentMainIndex: x.commentMainIndex,
                                IsDeleted: 0,
                                ImagesList: []
                            })
                            res.data.qcCommentsSubDetails.filter(nex => nex.mainindex === x.commentMainIndex).map((y, chilindex) => {
                                TestReportSaveData[i].ImagesList.push({
                                    TNAQCInspeCommentsSubID: y.tnaqcInspeCommentsSubID,
                                    UploadedImagePath1: y.uploadedImagePath1,
                                    UploadedImagePath2: y.uploadedImagePath2,
                                    MainIndex: i,
                                    IsDeleted: 0
                                })
                            })
                        })
                    } else {
                        TestReportSaveData.push({
                            TNAQCInspecCommentID: 0,
                            CommnetsID: 0,
                            ResultID: 0,
                            CommentMainIndex: 0,
                            IsDeleted: 0,
                            ImagesList: []
                        })
                        TestReportSaveData[0].ImagesList.push({
                            TNAQCInspeCommentsSubID: 0,
                            UploadedImagePath1: "",
                            UploadedImagePath2: "",
                            MainIndex: 0,
                            IsDeleted: 0
                        })
                    }
                    commentList(TestReportSaveData);
                    setCommentsList(TestReportSaveData);
                }
                if (res.data.qcFileuploadDetails) {
                    let FileList = [];
                    if (res.data.qcFileuploadDetails.length > 0) {
                        res.data.qcFileuploadDetails.map((x, i) => {
                            FileList.push({
                                QCReportFileID: x.tnaQcInspectionFileupID,
                                FileName: x.fileName,
                                FilePath: x.filepath,
                                FileType: x.fileType,
                                IsDeleted: 0,
                            })
                            setFiles(FileList);
                            FileuploadList(FileList)
                        })
                    }
                }
            })
            // let Multiselecteddate = [];
            // Multiselecteddate = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1)
            // setInspectionQtyInfo(Multiselecteddate);
            // Qunatitylist(Multiselecteddate)
            // MainInfoCommonCalculation(Multiselecteddate);
            // QcConclusionList(getQcremarksdrpdwn)

        }
        //use for Dropdown With multiResultset
        let CommonResult = [];
        let Commentlist = [];

        QcInspection.getCommonResultList(1, props.location.state.params.details.supplierId).then((res) => {

            if (res.data) {
                res.data.qcDropDownListDetails.map((element) => {
                    CommonResult.push({
                        value: element.resultID,
                        label: element.resultName
                    })

                })
                res.data.commentPointList.map((element) => {
                    Commentlist.push({
                        value: element.value,
                        label: element.label
                    })
                })
                setDropdownlist(CommonResult);
                setCommentDroplist(Commentlist);
            }
        })

    }, [rerenderdep])

    function handleOfferQty(e, index) {
        let isvalid = false;
        const values = [...getInspectionQtyInfo]
        let inputText = ""
        const regexNumber = /^[0-9\b]+$/;
        if (e.target.value.trim() !== '') {
            if (e.target.value.match(regexNumber)) {
                inputText = e.target.value;
            } else {
                inputText = '';
            }
        }
        let balqty = parseInt(values[index].orderQuantity) + Math.round(parseInt(values[index].orderQuantity) * gettolerance / 100);
        if (parseInt(inputText) <= balqty) {
            values[index].prodqty = parseInt(inputText);
        }
        else {
            values[index].prodqty = '';
            if (inputText !== '') {
                isvalid = true;
            }
        }
        if (isvalid) {
            Nodify('Warning!', 'warning', 'offer qty cannot exceed ' + balqty);
        }
        setInspectionQtyInfo(values)
        MainInfoCommonCalculation(values);
        Qunatitylist(values);
    }

    function RemoveQuantity(e, index, purchaseOrderSkuID, comment) {

        let multiselecteddatas = [];
        const values = [...getInspectionQtyInfo]
        props.location.state.params.multiselecteddatas.filter(x => parseInt(x.purchaseOrderSkuID) === parseInt(purchaseOrderSkuID)).map(element => {
            element.isSelected = 0;
        })
        values[index].IsDeleted = 1;
        multiselecteddatas = values.filter(x => x.isSelected === 1)
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        multiselecteddatas.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        props.location.state.params.PurchaseOrderSkuID = sizeandqtyfordisplay;
        values.splice(index, 1);
        setInspectionQtyInfo(values)
        MainInfoCommonCalculation(values)
        Qunatitylist(values)
        setrerenderstate(props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).length);
    }

    function OpenPopup() {

        setselectall(false)
        let Multiselected = [];
        Multiselected = basicDetail.multiselecteddatas.filter(x => x.isSelected === 0)

        Multiselected.map((ms, index) => {
            ms.prodqty = '';
        })

        setMultiCloseDatas(Multiselected)
        setFinalInspectionPopup(true)
    }

    function handleChange(e, fieldName) {

        let InputText = '';
        setSubmitted(false);
        const values = { ...getQcremarksdrpdwn };
        if (fieldName === 'QCRemarks') {
            if (e.target.value.trim() !== '') {
                InputText = e.target.value;
            }
            values.QARemarks = InputText;
        } else {
            if (e !== null) {
                values.QcDropdownID = e.value;
                values.QCDropDownn = e.label;
            } else {
                values.QcDropdownID = 0;
                values.QCDropDownn = '';
            }
        }
        setQcremarksdrpdwn(values)
        QcConclusionList(values)
    }

    const filevals = [...getFiles]
    const AppendFiles = async e => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "QcFinalinspection");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    filevals.push({
                        QCReportFileID: 0,
                        FileName: res.data + '-' + file.name,
                        FilePath: "Images/QcFinalinspection/" + res.data + '_' + file.name,
                        IsDeleted: 0,
                        FileType: file.type
                    })
                    setFiles(filevals);
                    FileuploadList(filevals)
                    $('#FileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const RemoveFiles = async (e, index) => {
        filevals.splice(index, 1);
        setFiles(filevals);
        FileuploadList(filevals)
    }

    const ViewAppendFile = (event, filename) => { // event.preventDefault();

        setIsOpenimg(true);
        setImagename(filename);
    }

    function handleClosePopup() {
        setFinalInspectionPopup(false)
    }

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        let inputText = '';
        if (event.target.value.trim() !== '') {
            inputText = event.target.value;
        }
        values[index].Comment = inputText;
        setInputFields(values);
    }
    function SaveComment(e, index) {


        let values = [...inputFields]
        if (inputFields.length === 1 && inputFields[0].Comment === '') {
            //setButtonLoader(false);
            setSubmittedQA(true);
            Nodify('Warning!', 'warning', "Please fill atleast one QC Comment Point.");
        } else {
            // let Params = { Createdby: currentUser.employeeinformationID, QACommentPoint: inputFields };
            // QACommentPointService.InsertQACommentPoints(Params).then(res => {

            //     if (res.data.outputResult === "1") {
            //         Nodify('Success!', 'success', 'Comments saved Successfully');
            //         QcInspection.getCommonResultList(1, props.location.state.params.details.supplierId).then((res) => {
            //             let Commentlist = [];
            //             if (res.data) {
            //                 res.data.commentPointList.map((element) => {
            //                     Commentlist.push({
            //                         value: element.value,
            //                         label: element.label
            //                     })
            //                 })
            //                 setCommentDroplist(Commentlist);
            //             }
            //         })
            //         setDefectPopup(false)
            //     } else if (res.data.outputResult === "-2") {
            //         Nodify('Warning!', 'warning', "This QC Comment Point Name is already existyy.");
            //     }
            //     else if (res.data.outputResult === "0") {
            //         Nodify('Warning!', 'warning', "Error Occured.");
            //     }
            // });

            if (CommentDroplist) {

                let isvalid = true

                for (var i = 0; i < CommentDroplist.length; i++) {
                    if (CommentDroplist[i].label.trim().toLowerCase() === values[0].Comment.trim().toLowerCase()) {
                        values[0].Comment = "";
                        setInputFields(values);
                        Nodify('Warning!', 'warning', "This QC Comment Point Name is already exist-test.");
                        isvalid = false
                        return false;
                    }
                }

                if (isvalid) {
                    let Params = { Createdby: currentUser.employeeinformationID, QACommentPoint: inputFields };
                    QACommentPointService.InsertQACommentPoints(Params).then(res => {
                        if (res.data.outputResult === "1") {
                            Nodify('Success!', 'success', 'Comments saved Successfully');
                            QcInspection.getCommonResultList(1, props.location.state.params.details.supplierId).then((res) => {
                                let Commentlist = [];
                                if (res.data) {
                                    res.data.commentPointList.map((element) => {
                                        Commentlist.push({
                                            value: element.value,
                                            label: element.label
                                        })
                                    })
                                    setCommentDroplist(Commentlist);
                                }
                            })
                            setDefectPopup(false)
                        }
                    })
                }
            }


        }
    }
    const CheckDuplicate = (index) => {
        const values = [...inputFields];
        if (ExistingList.item1) {
            for (var i = 0; i < ExistingList.item1.length; i++) {
                if (ExistingList.item1[i].comment.trim().toLowerCase() === values[index].Comment.trim().toLowerCase()) {
                    values[index].Comment = '';
                    setInputFields(values);
                    Nodify('Warning!', 'warning', "This QC Comment Point Name is already exist.");
                    return false;
                }
            }
            for (var i = 0; i < (inputFields.length); i++) {
                if (i !== index) {
                    if (inputFields[i].Comment.trim().toLowerCase() === inputFields[index].Comment.trim().toLowerCase()) {
                        values[index].Comment = "";
                        setInputFields(values);
                        Nodify('Warning!', 'warning', 'This QC Comment Point Name is already exist.');
                        return false;
                    }
                }
            }
        }
    }

    function MainInfoCommonCalculation(val) {
        let poqty = 0;
        let prodqty = 0;
        val.map(x => {
            if (poqty === 0) {
                poqty = parseFloat(x.orderQuantity);
            }
            else {
                poqty = poqty + parseFloat(x.orderQuantity);
            }
            if (prodqty === 0 && x.prodqty !== undefined && x.prodqty !== '') {
                prodqty = parseFloat(x.prodqty);
            }
            else if (prodqty !== 0 && x.prodqty !== undefined && x.prodqty !== '') {
                prodqty = parseFloat(prodqty) + parseFloat(x.prodqty);
            }
        })
        setTotalValues({ PoQty: poqty, Prodqty: prodqty })
    }

    const OnSelectExFactoryRecords = (event, index, item) => {

        const value = [...MultiCloseDatas];
        if (event.target.checked === true) {
            value[index].isSelected = 1;
            value[index].IscheckReportSelect = 2;
            value[index].offerqty = '';
            props.location.state.params.multiselecteddatas.filter(x => x.purchaseOrderSkuID === item.purchaseOrderSkuID).map((e) => {
                e.isSelected = 1;
            })
        }
        else {
            value[index].isSelected = 0;
            value[index].IscheckReportSelect = 0;
            props.location.state.params.multiselecteddatas.filter(x => x.purchaseOrderSkuID === item.purchaseOrderSkuID).map((e) => {
                e.isSelected = 0;
            })
        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);
        setMultiCloseDatas(value);

    }
    function OpenPopup1() {

        setDefect([{ Id: 0, Name: "" }])
        setInputFields([{ CommentID: 0, Comment: "" }])
        setSubmittedQA(false);
        setDefectPopup(true)

    }

    function handleClosePopup1() {
        setDefectPopup(false)
    }
    function AddExFactoryForm() {

        let Multiselected = [];
        const Values = [...getInspectionQtyInfo]
        let isvalid = true;
        isvalid = !!MultiCloseDatas.find(x => x.isSelected === 1)
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = Values.concat(MultiCloseDatas.filter(x => x.isSelected === 1))
        NewArr.map((Purchaseorder) => {
            Purchaseorder.IsDeleted = 0;
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })

        props.location.state.params.PurchaseOrderSkuID = sizeandqtyfordisplay;
        if (isvalid) {
            Multiselected = Values.concat(MultiCloseDatas.filter(x => x.isSelected === 1))
            setMultiCloseDatas(Multiselected)
            setInspectionQtyInfo(Multiselected)
            setFinalInspectionPopup(false);
            MainInfoCommonCalculation(Multiselected)
            Qunatitylist(Multiselected)
            setrerenderstate(props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).length);
        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }

    }

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }

    const handleTestReportCheckExpandRow = (rowNumber) => {
        setTestReportExpandedRows([]);
        let currentExpandedRows;
        let isRowExpanded = false;
        currentExpandedRows = TestReportexpandedRows;
        isRowExpanded = currentExpandedRows.includes(rowNumber);
        let obj = {};
        isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
        setTestReportExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== rowNumber) :
            currentExpandedRows.concat(rowNumber);
        setTestReportExpandedRows(newExpandedRows);
    }

    function ViewFile(e, filepath) {

        setIsOpen(true);
        setImagenameupload(filepath);
    }

    function ImageCallback(value, index, childindex, FeildName) {

        let profile = Commentvalue !== '' ? "/QcFinalinspection/" : '';
        if (FeildName === "Image1") {
            //Commentvalue[index].ImagesList[childindex].MainIndex = index;
            Commentvalue[index].ImagesList[childindex].UploadedImagePath1 = profile + value;
        } else {
            Commentvalue[index].ImagesList[childindex].UploadedImagePath2 = profile + value;
        }
        setCommentsList(Commentvalue);
    }
    function deleteFile(value, index, childindex, FeildName) {
        if (FeildName === "Image1") {
            Commentvalue[index].ImagesList[childindex].UploadedImagePath1 = '';
        } else {
            Commentvalue[index].ImagesList[childindex].UploadedImagePath2 = '';
        }
        setCommentsList(Commentvalue);
    }
    function handleCommonResultInputChange(index, e, FieldName) {
        if (FieldName === "TestingReport") {
            if (e !== null) {
                Commentvalue[index].CommnetsID = e.value
            } else {
                Commentvalue[index].CommnetsID = 0
            }
        } else {
            if (e !== null) {
                Commentvalue[index].ResultID = e.value
            } else {
                Commentvalue[index].ResultID = 0
            }
        }
        setCommentsList(Commentvalue);
        commentList(Commentvalue)
    }

    function handleAddFields(index) {

        if (Commentvalue[index].CommnetsID === 0) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Please fill the Mandatory(*) feilds');
        } else {
            setSubmitted(false);
            Commentvalue.push({
                TNAQCInspecCommentID: 0,
                CommnetsID: 0,
                ResultID: 0,
                CommentMainIndex: index + 1,
                ImagesList: []
            })
            let newindex = Commentvalue.length - 1;
            Commentvalue[newindex].ImagesList.push({
                TNAQCInspeCommentsSubID: 0,
                UploadedImagePath1: "",
                UploadedImagePath2: "",
                MainIndex: index + 1
            })
        }
        setCommentsList(Commentvalue);
        commentList(Commentvalue)
    }
    function handleRemoveFields(index) {
        Commentvalue.splice(index, 1)
        //Commentvalue[index].IsDeleted = 1;
        setCommentsList(Commentvalue);
        commentList(Commentvalue)
    }
    const SelectAllExFactory = (event) => {

        let IsSelected = 0;
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
                element.IscheckReportSelect = 2;
                element.offerqty = '';
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
                element.IscheckReportSelect = 0;
            });
        }
        setselectall(IsSelected);
    }
    return (
        <div className="row" style={{ width: '100%', marginLeft: "1px" }}>
            <div className="panel-group accordion" id="acc_Quantity_Info">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h4 className="panel-title">
                            <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info" href="#collapse_acc_Quantity_Info">
                                Quantity Information
                            </a>
                        </h4>
                    </div>
                    <div id="collapse_acc_Quantity_Info" className="panel-collapse">
                        <div className="panel-body border-red">
                            <div className="mb-10-float-right">
                                <Label ><label className="btn btn-sm btn-warning">Tolerance: {gettolerance + '%'}</label></Label>&nbsp;&nbsp;
                                <button type="button" className="btn btn-sm btn-success mb-10-float-right" onClick={OpenPopup}>Order Details</button>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                        <thead>
                                            <tr>
                                                <th>SKU ID(PO)</th>
                                                <th>Style No</th>
                                                <th>SKU Name</th>
                                                <th>End Customer</th>
                                                <th>PO/ID No</th>
                                                <th>FO PO No</th>
                                                <th>Order Qty</th>
                                                <th>Prod Qty <span className='text-danger'>*</span></th>
                                                <th style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                getInspectionQtyInfo.map((comment, index) => (
                                                    <tr>
                                                        <td>{comment.purchaseOrderSkuID}</td>
                                                        <td>{comment.styleNo}</td>
                                                        <td>{comment.skuName}</td>
                                                        <td>{comment.endCustomer}</td>
                                                        <td>{comment.idNo}</td>
                                                        <td>-</td>
                                                        <td>{comment.orderQuantity}</td>
                                                        <td><input className="form-control" type="text"
                                                            name="" id="" value={comment.prodqty}
                                                            style={{ border: submitted && comment.prodqty === undefined || comment.prodqty === '' ? '1px solid red' : '' }}
                                                            onChange={event => handleOfferQty(event, index)}
                                                        /></td>
                                                        <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}> <span>
                                                            <span className="btn btn-danger"
                                                                style={{ pointerEvents: getInspectionQtyInfo.length === 1 ? "none" : '', opacity: getInspectionQtyInfo.length === 1 ? '0.6' : '' }}
                                                                onClick={event => RemoveQuantity(event, index, comment.purchaseOrderSkuID, comment)}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </span></td>
                                                    </tr>

                                                ))
                                            }
                                            <tr>
                                                <td colSpan="6" className="text-right">Total</td>
                                                <td>{getTotalValues.PoQty}</td>
                                                <td>{getTotalValues.Prodqty}</td>
                                                <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}></td>
                                                {/* <td></td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                getFinalInspectionPopup === true ? <Modal dialogClassName="CreatePopup" show={getFinalInspectionPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClosePopup()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {/* ADD Final Inspection &nbsp; */}
                            ADD {props.location.state.params.TaskType}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                            <table className="table table-bordered">
                                <thead>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Style Name</th>
                                        <th>Style No</th>
                                        <th>SKU Name</th>
                                        <th>PO/ID No</th>
                                        <th>FO PO No</th>
                                        <th>Factory</th>
                                        <th>Order Qty</th>
                                        <th>Task Holder / Follower</th>
                                        <th>
                                            <label style={{ marginTop: '8px' }}>
                                                <input type="checkbox" name="IsSelected"

                                                    onChange={event => (SelectAllExFactory(event))}
                                                    checked={selectall === 1 ? true : false}
                                                    className="colored-blue clearOpacity" />
                                                <span className="text"></span>
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        MultiCloseDatas.length !== 0 && MultiCloseDatas.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{item.styleName}</td>
                                                        <td>{item.styleNo}</td>
                                                        <td>{item.skuName}</td>
                                                        {/* <td>-</td> */}
                                                        <td>{item.idNo}</td>
                                                        <td>{item.poNo}</td>
                                                        <td>{item.factory}</td>
                                                        <td>{item.orderQuantity}</td>
                                                        <td className="cut_textInTNA" title={item.taskOwnerNameList} >{item.taskOwnerNameList}</td>
                                                        <td className="Actionbutton fixed-column">
                                                            <div style={{ width: "80px" }}>
                                                                {
                                                                    <label style={{ marginTop: '8px' }}>
                                                                        <input type="checkbox" name="IsSelected"
                                                                            disabled={item.balanceQuantity === "0" ? true : false}
                                                                            onChange={event => (OnSelectExFactoryRecords(event, index, item))}
                                                                            checked={item.isSelected === 1 ? true : false}
                                                                            className="colored-blue clearOpacity" />
                                                                        <span className="text"></span>
                                                                    </label>
                                                                }&nbsp;&nbsp;&nbsp;
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>

                                            )
                                        })

                                    }
                                    {
                                        MultiCloseDatas.length === 0 ?
                                            <tr>
                                                <td colSpan="10" className='norecordfound'><span>No record found</span></td>
                                            </tr> : ''
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => AddExFactoryForm()}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal> : ''
            }
            {
                getDefectPopup === true ? <Modal show={getDefectPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClosePopup1()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            QA Comments Master&nbsp;
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <span className="input-icon icon-right">

                                <textarea className='form-control' style={{ minHeight: '109px', border: submittedQA && !inputFields[0].Comment ? '1px solid red' : '' }} name="findingsorremarks"
                                    id="txtFindingsOrRemarks" rows="10" placeholder="Enter Findings / Remarks" onChange={event => handleInputChange(0, event)}
                                    autoComplete="off" value={inputFields[0].Comment} maxLength="200" onBlur={() => CheckDuplicate(0)}></textarea>

                            </span>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={SaveComment} >
                            {/* onClick={() => AddExFactoryForm()} */}
                            Save
                        </Button>
                    </Modal.Footer>


                </Modal> : ''
            }
            <br />
            <table className="table table-striped table-bordered table-hover dataTable no-footer">
                <thead>
                    <tr>
                        <th>#</th>
                        <th><button type='button' className="glyphicon glyphicon-plus AddPlusIconTemplate" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                            onClick={() => OpenPopup1()}
                            title="Add Size">
                        </button>Comments<span className="text-danger"> *</span></th>
                        <th>Action to be taken</th>
                        <th style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getCommentsList.map((item, index) => (
                            <Fragment>
                                <tr key={index}>
                                    <td >
                                        {<i
                                            className={
                                                TestReportExpandState[index] ?
                                                    'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                            } onClick={event => handleTestReportCheckExpandRow(index)}
                                        ></i>}&nbsp;
                                        <b>{index + 1}</b></td>
                                    <td ><Reactselect className="basic-single" name="Result"
                                        id={
                                            "Result" + index
                                        }
                                        isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        styles={submitted && item.CommnetsID === 0 ? styles : ''}
                                        onChange={(event) => handleCommonResultInputChange(index, event, 'TestingReport')}
                                        options={CommentDroplist}
                                        value={CommentDroplist.filter(function (option) {
                                            return option.value === item.CommnetsID;
                                        })}

                                    ></Reactselect></td>
                                    <td><Reactselect className="basic-single" name="Result"
                                        id={
                                            "Result" + index
                                        }
                                        isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                        isLoading={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        //styles={submitted && item.CommnetsID === 0 ? styles : ''}
                                        onChange={(event) => handleCommonResultInputChange(index, event, 'TestingReport1')}
                                        options={actiondropdown}
                                        value={actiondropdown.filter(function (option) {
                                            return option.value === item.ResultID;
                                        })}

                                    ></Reactselect></td>
                                    <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>
                                        <button type="button" style={{ display: getCommentsList[index].ImagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                            onClick={() => handleAddFields(index)}
                                        >
                                            <i className="fa fa-plus"></i>
                                        </button>
                                        &nbsp;
                                        {getCommentsList.length !== 1 ? <button type="button"
                                            className="btn btn-xs btn-danger" title="Delete packing Image"
                                            onClick={() => handleRemoveFields(index)}
                                            style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                        >
                                            <i className="fa fa-trash-o"></i>
                                        </button> : ''}
                                    </td>
                                </tr>
                                <Fragment>
                                    {
                                        TestReportexpandedRows.includes(index) ?
                                            <tr>
                                                <td colspan="4">
                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                        <tbody>
                                                            {
                                                                item.ImagesList.map((child, childindex) => (
                                                                    <Fragment>
                                                                        <tr>
                                                                            <td className="table-width-40">
                                                                                <div className="row">

                                                                                    <div className="row">
                                                                                        <div className="text-center">
                                                                                            <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                            <div className="text-center">
                                                                                                <div className="row">
                                                                                                    <div className="text-center">
                                                                                                        <div style={{ width: "50%", marginLeft: "20rem" }}>
                                                                                                            <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, 'Image1')} PageName='QcFinalinspection' ShowDiv="1" filePath={child.UploadedImagePath1} />
                                                                                                        </div>
                                                                                                        {child.UploadedImagePath1 !== '' ? <div className="row" style={{ position: "relative", left: "40px", bottom: "155px" }}>
                                                                                                            <div className="col-md-8 position-relative">
                                                                                                                <i className="btn btn-info fa fa-eye" title="View Logo" style={{ position: 'absolute', padding: '2px 6px', right: '31px' }}



                                                                                                                    onClick={
                                                                                                                        event => ViewFile(event, child.UploadedImagePath1, index)
                                                                                                                    }></i>&nbsp;
                                                                                                                <i className="btn btn-danger fa fa-trash" title="Delete Image" style={{ fontSize: "13px", position: 'absolute', padding: "2px 7px ", right: 0 }}
                                                                                                                    onClick={
                                                                                                                        event => deleteFile(event, index, childindex, 'Image1')
                                                                                                                    }></i>
                                                                                                            </div>
                                                                                                        </div> : ''}
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td className="table-width-60">

                                                                                <div className="row">
                                                                                    <div className="text-center col-lg-6">
                                                                                        <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                        <div >
                                                                                            <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, 'Image2')} PageName='QcFinalinspection' ShowDiv="1" filePath={child.UploadedImagePath2} />
                                                                                        </div>
                                                                                        {child.UploadedImagePath2 !== '' ? <div className="row">
                                                                                            <div className="col-md-7">
                                                                                            </div>
                                                                                        </div> : ''}
                                                                                    </div>

                                                                                </div>
                                                                                <span className="TnaFinaspecwrkmans" style={{ left: "290px" }}>

                                                                                    {child.UploadedImagePath2 !== '' ? <button className="btn btn-info fa fa-eye" style={{ marginLeft: "75px", padding: "3px 6px", width: "3.5%" }} onClick={
                                                                                        event => ViewFile(event, child.UploadedImagePath2, index)
                                                                                    }>
                                                                                        <i c title="View Logo" style={{ marginRight: "8px" }}
                                                                                        ></i>&nbsp;
                                                                                        <i className="btn btn-danger fa fa-trash" title="Delete Image" style={{ marginLeft: '5px', marginBottom: '1px' }}
                                                                                            onClick={
                                                                                                event => deleteFile(event, index, childindex, 'Image2')
                                                                                            }></i>
                                                                                    </button> : ''}

                                                                                    {/* &nbsp;
                                                                                    {props.location.state.params.Action !== 'View' ? <button type="button" style={{ display: getCommentsList[index].ImagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                                                                        onClick={() => handleAddFields(index, childindex)}
                                                                                    >
                                                                                        <i className="fa fa-plus"></i>
                                                                                    </button> : ''}
                                                                                    &nbsp;
                                                                                    {getCommentsList[index].ImagesList.length !== 1 && (<button type="button"
                                                                                        className="btn btn-xs btn-danger" title="Delete packing Image"
                                                                                        onClick={() => handleRemoveFields(index, childindex)}
                                                                                        style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                    >
                                                                                        <i className="fa fa-trash-o"></i>
                                                                                    </button>)} */}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>

                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr> : ''
                                    }
                                    <div> {
                                        isOpen && (

                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagenameupload}
                                                alt="bg image"
                                                onCloseRequest={
                                                    () => setIsOpen(false)
                                                } />
                                        )
                                    } </div>
                                </Fragment>
                            </Fragment>
                        ))
                    }
                </tbody >
            </table>
            <br />
            <br />
            <div className="col-sm-12 col-lg-12">
                <div className="widget-header" style={{ paddingLeft: 0 }}>
                    <span className="lowCaseText widget-caption" style={{ fontSize: '14px' }}>&nbsp;File Upload <span style={{ color: 'red', fontSize: '14px' }} >(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                </div>
            </div>
            <div className="col-sm-12 col-lg-12">
                <div className="row">
                    <div className="col-sm-8 col-lg-8">
                        <div className='form-group'>
                            <tr>
                                <td> <input type="file" id="FileUpload" style={{ fontSize: '14px' }} onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input></td>
                            </tr>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-sm-12">
                <div className="row no-margin">
                    {
                        getFiles.length !== 0 ?
                            getFiles.map((files, fileindex) => (
                                <div className="col-lg-4 col-sm-4 file_list_upload">
                                    <tr title={files.FileName}>
                                        <td className="cut_text">
                                            <span style={{ marginRight: '7px' }}>
                                                {
                                                    files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                        files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.ms-excel" ?
                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                            files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                }
                                            </span>  {files.FileName.substr(33)}
                                        </td>

                                        <td className="button_style_uploda">


                                            {
                                                files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    || files.FileType === 'application/msword' || files.FileType === "application/vnd.ms-excel" ?
                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + files.FilePath} download><i class="fa fa-download"></i> </a> :
                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                        onClick={
                                                            event => ViewAppendFile(event, files.FilePath)
                                                        }></i>
                                            }

                                            &nbsp;

                                            <p title='Delete File'
                                                onClick={(e) => RemoveFiles(e, fileindex)} style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                className="btn btn-danger btn-xs delete">
                                                <i className="fa fa-trash-o"></i>

                                            </p>
                                        </td>
                                    </tr>
                                    {

                                        <div> {
                                            isOpenimg && (
                                                <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                    alt="bg image"
                                                    onCloseRequest={
                                                        () => setIsOpenimg(false)
                                                    } />
                                            )
                                        } </div>
                                    }
                                </div>
                            ))
                            : ''
                    }
                </div>
            </div>
            <br />
            <div className="col-sm-12 col-lg-12">
                <div className="row">
                    <div className="col-sm-7 col-lg-7">
                        <div className='form-group'>
                            <label htmlFor="Remarks"> QC Remarks {getQcremarksdrpdwn.QcDropdownID === 3 && getQcremarksdrpdwn.QARemarks === '' ? <span className="text-danger">*</span> : ""}   </label>
                            <textarea maxLength="300" name="Remarks" value={getQcremarksdrpdwn.QARemarks} onChange={event => handleChange(event, 'QCRemarks')} rows="4" style={{ width: '100%', border: getQcremarksdrpdwn.QcDropdownID === 3 && getQcremarksdrpdwn.QARemarks === '' ? '1px solid red' : '' }} ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {props.location.state.params.TaskType !== 'PP Meeting' && props.location.state.params.TaskType !== 'In and Mid Line Inspection' ? <div className="row">
                <div className="col-sm-3" style={{ marginLeft: '17px' }}>
                    <div className='form-group'>
                        <label htmlFor="Name" >Q.C Conclusion<span className="text-danger">*</span></label>
                        <span className='input-icon icon-right'>
                            <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                id={
                                    "TaskHolderOrFollower"
                                }
                                isLoading={false}
                                isClearable={true}
                                isSearchable={true}
                                onChange={event => handleChange(event, 'QCResult')}
                                value={dropDownlist.filter(function (option) {
                                    return option.value === getQcremarksdrpdwn.QcDropdownID;
                                })}
                                options={dropDownlist}
                                styles={submittedQCDp && getQcremarksdrpdwn.QcDropdownID === 0 ? styles : ''}
                                //isDisabled={props.TaskStatus === 3 && props.editProtoFitSmsApproval === false ? true :
                                //props.props.location.pathname !== '/TNAEdit' && props.TaskStatus === 3 ? true : false}
                                menuPosition="fixed"

                            ></Reactselect>

                        </span>
                    </div>
                </div>


            </div>
                : ''}
        </div>



    )
}

export default React.memo(AddQCReportTab);