import React, { Fragment, useState, useMemo, useEffect } from "react";
import UploadImage from "../../../Common/UploadImage";
import Reactselect from 'react-select';
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import Nodify from "../../../Common/ReactNotification";
import $ from "jquery";
import axios from "axios";
import Lightbox from "react-image-lightbox"
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import Input from "react-validation/build/input";
import { useSelector } from "react-redux";
import StrikeoffService from "../../../../services/Style/StrikeoffService";
import CategoryService from "../../../../services/Master/CategoryService";
import MeasurementService from "../../../../services/Style/measurementService";

const FinalInspectionMeasurement = ({ props, Messurecallback, Savevalidationcallback, rerenderdep }) => {
    const selector = useSelector((state) => state);
    const [getMessureValue, setMessureValue] = useState([]);
    const [getCommonResult, setCommonResult] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [submitted, setSubmitted] = useState(false);
    const [getCalcType, setCalcType] = useState({ calcID: 2, calcIDForEdit: [{ id: 2, label: "Difference", name: "Difference", value: 2 }] });



    const [expandState, setExpandState] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);

    const [getFoundDiffOptions, setFoundDiffOptions] = useState([
        {
            id: 1, value: 1, label: "Found", name: "Found"
        },
        {
            id: 2, value: 2, label: "Difference", name: "Difference"
        }
    ]);

    useEffect(() => {
        setSubmitted(Savevalidationcallback);
    }, [Savevalidationcallback === true]);

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }

    const emptyFields = [
        {
            TNAFinalinsMeasurementMainId: 0,
            MeasurementCheckedPeices: '',
            StyleMeasurementID: 0,
            MeasurementSpec: '',
            SkuName: '',
            Category: [{
                StyleMeasurementCategoryID: 0,
                MeasurementCategoryID: "0",
                CategoryTypeName: '',
                CategoryIndexName: '',
                SkuName: '',
                CurrentSize: '',
                SizeHeaderNames: [],
                StyleMeasurementID: 0,
                CategoryRowNo: 0,
                Remark: '',
                Point: [{
                    TNAFinalinsMeasurementPointsId: 0,
                    MeasurementPoints: 0,
                    MeasurementGranding: '',
                    TolerancePlus: '',
                    ToleranceMinus: '',
                    GradingSizes: '',
                    GradingSizesId: '',
                    GradingSizesValue: '',
                    arrGradingSizes: [],
                    CategoryIndexName: '',
                    SkuName: '',
                    SizeValues: [],
                    StyleMeasurementID: 0,

                }]
            }],
        }
    ];
    const [inputFields, setInputFields] = useState(emptyFields);
    const [getCategoryType, setCategoryType] = useState([]);

    const isSideBarEnable = selector.auth.isSideBarEnable;
    const isFullSideBarEnable = selector.auth.isFullSideBarEnable;
    const [getGradingSize, setGradingSize] = useState([]);
    const [getMeasurement, setMeasurement] = useState([]);

    const [getCurrentSizeForColors, setCurrentSizeForColors] = useState("S");

    const [isStyleMeasurementempty, setisStyleMeasurementempty] = useState(false);
    let BrowserWidth = isFullSideBarEnable
        ? isSideBarEnable
            ? $(window).width() - 160
            : $(window).width() + 50
        : $(window).width() + 100;

    const values = [...inputFields];

    const AssignCurrentSize = (currentsize, catindex, sku) => {
        setCurrentSizeForColors(currentsize);
        values[0].Category[catindex].CurrentSize = currentsize;
        setInputFields(values);
    }

    useEffect(() => {
       
        showLoader();
        let stylesizes = [];
        CategoryService.LoadCategoryTypeList().then((response) => {
            if (response.data) {
                setCategoryType(response.data);
            }
        }).catch(() => { });

        StrikeoffService.LoadGroupSizeDetailsList(props.location.state.params.styleID).then((response) => {
            if (response.data) {
                setGradingSize(response.data);

            }
        }).catch(() => { });

        MeasurementService.LoadMeasurement().then((response) => {
            if (response.data) {
                setMeasurement(response.data);
            }
        }).catch(() => { });

        let CommonResult = [];
        FinalinspectionService.getCommonResultList(0).then((res) => {
            if (res.data) {
                res.data.map((element) => {

                    let LabelColor = '';
                    if (element.resultID === 1) {
                        LabelColor = <><span style={{ color: "green" }}><b>Ok</b></span></>
                    }
                    else if (element.resultID === 2) {
                        LabelColor = <><span style={{ color: "Red" }}><b>Not ok</b></span></>
                    }
                    else if (element.resultID === 3) {
                        LabelColor = <><span style={{ color: "blue" }}><b>Not Applicable</b></span></>
                    }

                    CommonResult.push({
                        value: element.resultID,
                        label: LabelColor,
                        subLabel: element.resultName,
                    })
                })
                setCommonResult(CommonResult);
            }
        })


        const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));

        if (props.location.state.params.TaskStatus === 1) {
            let newdatass = []
            let skudetails = [];
            FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TaskStatus === 1 ? inpsectionId :
                props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then((res) => {
                    //load files data
                    if (res.data.finalInsMeasurementfileList.length > 0) {
                       
                        res.data.finalInsMeasurementfileList.map((x, index) => {
                            newdatass.push({
                                TNAFinalInsMeasurementFileupID: x.tnaFinalInsMeasurementFileupID,
                                Result: x.result,
                                FindingsRemarks: x.findingsRemarks,
                                SkuName: x.skuName,
                                MainIndex: x.measurementIndex,
                                FileType: x.fileType,
                                FileName: x.fileName,
                                FilePath: x.filepath,
                            })
                        })
                    } else {
                        props.location.state.params.multiselecteddatas.filter(multi => multi.isSelected === 1).map((x, index) => {
                            newdatass.push({
                                TNAFinalInsMeasurementFileupID: 0,
                                Result: 0,
                                FindingsRemarks: "",
                                SkuName: x.skuName,
                                MainIndex: index,
                                FileType: '',
                                FileName: '',
                                FilePath: '',
                                IsDeleted: 0
                            })
                        })

                    }

                   ;

                    //load measurement data
                    if (res.data.finalInsMeasurementMainList.length > 0) {
                        values[0].StyleMeasurementID = res.data.finalInsMeasurementMainList[0].styleMeasurementID;
                        values[0].MeasurementSpec = res.data.finalInsMeasurementMainList[0].measurementSpec;
                        values[0].TNAFinalinsMeasurementMainId = res.data.finalInsMeasurementMainList[0].tnaFinalinsMeasurementMainId;
                        values[0].MeasurementCheckedPeices = res.data.finalInsMeasurementMainList[0].measurementCheckedPeices;
                        values[0].Category = [];
                        if (res.data.finalInsMeasurementCategoryList.length > 0) {
                            res.data.finalInsMeasurementCategoryList.map((category, catIndex) => {

                                skudetails.push({
                                    SkuName: category.skuName
                                })

                                values[0].Category.push({
                                    TNAFinalinsMeasurementCategoryId: category.tnaFinalinsMeasurementCategoryId,
                                    StyleMeasurementCategoryID: category.styleMeasurementCategoryID,
                                    MeasurementCategoryID: category.measurementCategoryID,
                                    StyleMeasurementID: category.styleMeasurementID,
                                    CategoryTypeName: category.categoryTypeName,
                                    CategoryIndexName: category.categoryIndexName,
                                    SizeHeaderNames: category.sizeHeaderNames,
                                    SkuName: category.skuName,
                                    CurrentSize: '',
                                    Remark: category.remark,
                                    Point: []
                                });
                                // setcurrentSizeheaders(Sizeheadernames);

                                let pointList = res.data.finalInsMeasurementPointList.filter(x => x.categoryIndexName === category.categoryIndexName && x.skuName === category.skuName);
                                pointList.map((point, pointIndex) => {
                                    //Revise
                                    // point.styleMeasurementCategoryPointsID = MeasurementList.measurementOpreation === 1 ? 0 : point.styleMeasurementCategoryPointsID;
                                    values[0].Category[catIndex].Point.push({
                                        TNAFinalinsMeasurementPointsId: point.tnaFinalinsMeasurementPointsId,
                                        StyleMeasurementID: point.styleMeasurementID,
                                        MeasurementPoints: point.measurementPoints,
                                        MeasurementGranding: point.measurementGranding,
                                        TolerancePlus: point.tolerancePlus,
                                        ToleranceMinus: point.toleranceMinus,
                                        GradingSizes: point.gradingSizes,
                                        GradingSizesValue: point.gradingSizesValue,
                                        arrGradingSizes: [],
                                        CategoryIndexName: point.categoryIndexName,
                                        SkuName: point.skuName,

                                    });
                                    let arrGradingSizes = point.gradingSizes.split(",");
                                    let arrGradingSizesValue = point.gradingSizesValue.split(",");
                                    let arrcount = 0;

                                    let Reqval = arrGradingSizesValue[0];
                                    let Rangefrom = parseFloat(Reqval) - parseFloat(point.toleranceMinus);
                                    let Rangeto = parseFloat(Reqval) + parseFloat(point.tolerancePlus);



                                    arrGradingSizes.forEach((val, valIndex) => {
                                        if (arrcount === 0) {
                                            arrcount++;
                                            values[0].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                                                [val]: arrGradingSizesValue[valIndex],
                                                [val + "Color"]: (Rangefrom <= parseFloat(arrGradingSizesValue[valIndex]) &&
                                                    Rangeto >= parseFloat(arrGradingSizesValue[valIndex])) ? 'green' : 'red'
                                                //[val + "Color"]: 'green'
                                            });
                                        }
                                        else {
                                            values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val] =
                                                arrGradingSizesValue[valIndex];
                                            values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val + "Color"] = 
                                                (Rangefrom <= parseFloat(arrGradingSizesValue[valIndex]) &&
                                                    Rangeto >= parseFloat(arrGradingSizesValue[valIndex])) ? 'green' : 'red'

                                        }
                                    });

                                });
                            });
                            setCurrentSize(values[0].Category);

                        }
                    }
                    else {
                       
                        props.location.state.params.multiselecteddatas.filter(multi => multi.isSelected === 1).map((x, index) => {
                            skudetails.push({
                                SkuName: x.skuName
                            })
                        })
                        FinalinspectionService.GetTNAFinalinspecMeasurementList(props.location.state.params.styleID).then((res) => {
                            if (res.data) {
                                if (res.data.finalInsMeasurementSpec.length > 0) {
                                    values[0].StyleMeasurementID = res.data.finalInsMeasurementSpec[0].styleMeasurementID;
                                    values[0].MeasurementSpec = res.data.finalInsMeasurementSpec[0].measurementSpecReason;
                                    //  values[0].MeasurementGrandingSize = response.data.specList[0].measurementGrandingSize;
                                    // values[0].MeasurementOperation = MeasurementList.measurementOpreation;
                                    values[0].Category = [];

                                }
                                else {
                                    setisStyleMeasurementempty(true);
                                }
                                if (res.data.finalInsMeasurementCatagory.length > 0) {
                                    res.data.finalInsMeasurementCatagory.map((category, catIndex) => {
                                        //Revise
                                        let Sizeheadernames = [];

                                        values[0].Category.push({
                                            StyleMeasurementCategoryID: category.styleMeasurementCategoryID,
                                            MeasurementCategoryID: category.measurementCategoryID,
                                            StyleMeasurementID: category.styleMeasurementID,
                                            CategoryTypeName: category.categoryTypeName,
                                            CategoryIndexName: category.indexName,
                                            SizeHeaderNames: Sizeheadernames,
                                            SkuName: '',
                                            CurrentSize: '',
                                            Remark: '',
                                            Point: []
                                        });

                                        let pointList = res.data.finalInsMeasurementPoints.filter(x => x.indexName === category.indexName);
                                        pointList.map((point, pointIndex) => {

                                            values[0].Category[catIndex].Point.push({
                                                StyleMeasurementID: point.styleMeasurementID,
                                                MeasurementPoints: point.measurementPoints,
                                                MeasurementGranding: point.measurementGranding,
                                                TolerancePlus: point.tolerancePlus,
                                                ToleranceMinus: point.toleranceMinus,
                                                GradingSizes: point.gradingSizes,
                                                GradingSizesId: point.gradingSizesId,
                                                GradingSizesValue: point.gradingSizesValue,
                                                arrGradingSizes: [],
                                                CategoryIndexName: point.indexName,
                                                SkuName: '',

                                            });
                                            let arrGradingSizes = point.gradingSizes.split(",");
                                            let arrGradingSizesValue = point.gradingSizesValue.split(",");
                                            let arrcount = 0;

                                            arrGradingSizes.forEach((val, valIndex) => {
                                                if (arrcount === 0) {
                                                    arrcount++;
                                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                                                        [val + "-Req"]: arrGradingSizesValue[valIndex],
                                                        SkuName: '',
                                                        [val + "-ReqColor"]: 'black',
                                                    });
                                                }
                                                else {
                                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val + "-Req"] =
                                                        arrGradingSizesValue[valIndex];
                                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0].SkuName = ''
                                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val + "-ReqColor"] = 'black';

                                                }
                                            });

                                        });
                                    });

                                }
                                var uniqueimagesMSD = [...new Map(skudetails.map(item =>
                                    [item['SkuName'], item])).values()];
                                loadMeasurementSizedetails(uniqueimagesMSD, res.data.finalInsMeasurementPoints, res.data.finalInsMeasurementCatagory);
                            }
                        })
                    }

                    setInputFields(values);
                    var uniqueimages = [...new Map(newdatass.map(item =>
                        [item['SkuName'], item])).values()];
                    setMessureValue(uniqueimages);
                    let MeasrurementTabdata = { Files: uniqueimages, Measurementdata: values };
                    Messurecallback(MeasrurementTabdata)
                    hideLoader();
                })

        } else {
            let newdatass = [];
            let skudetails = [];
            FinalinspectionService.GetTNAFinalinspecMainInfo(props.location.state.params.TaskStatus === 1 ? inpsectionId :
                props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then((res) => {
                    //load files data
                    if (res.data.finalInsMeasurementfileList.length > 0) {
                        if (res.data.finalInsMeasurementMainList.length > 0 && res.data.finalInsMeasurementMainList[0].calculationType !== undefined) {
                            setCalcType({
                                calcID: res.data.finalInsMeasurementMainList[0].calculationType, calcIDForEdit: getFoundDiffOptions.filter(data => {
                                    return data.id === res.data.finalInsMeasurementMainList[0].calculationType;
                                })
                            });
                        }
                        res.data.finalInsMeasurementfileList.map((x, index) => {
                            newdatass.push({
                                TNAFinalInsMeasurementFileupID: x.tnaFinalInsMeasurementFileupID,
                                Result: x.result,
                                FindingsRemarks: x.findingsRemarks,
                                SkuName: x.skuName,
                                MainIndex: x.measurementIndex,
                                FileType: x.fileType,
                                FileName: x.fileName,
                                FilePath: x.filepath,
                            })
                        })

                    } else {
                       
                        props.location.state.params.multiselecteddatas.filter(multi => multi.isSelected === 1).map((x, index) => {
                            newdatass.push({
                                TNAFinalInsMeasurementFileupID: 0,
                                Result: 0,
                                FindingsRemarks: "",
                                SkuName: x.skuName,
                                MainIndex: index,
                                FileType: '',
                                FileName: '',
                                FilePath: '',
                                IsDeleted: 0
                            })
                        })

                    }

                    //load measurement data
                    if (res.data.finalInsMeasurementMainList.length > 0) {
                        values[0].StyleMeasurementID = res.data.finalInsMeasurementMainList[0].styleMeasurementID;
                        values[0].MeasurementSpec = res.data.finalInsMeasurementMainList[0].measurementSpec;
                        values[0].TNAFinalinsMeasurementMainId = res.data.finalInsMeasurementMainList[0].tnaFinalinsMeasurementMainId;
                        values[0].MeasurementCheckedPeices = res.data.finalInsMeasurementMainList[0].measurementCheckedPeices;
                        values[0].Category = [];

                        if (res.data.finalInsMeasurementCategoryList.length > 0) {
                            res.data.finalInsMeasurementCategoryList.map((category, catIndex) => {

                                skudetails.push({
                                    SkuName: category.skuName
                                })

                                values[0].Category.push({
                                    TNAFinalinsMeasurementCategoryId: category.tnaFinalinsMeasurementCategoryId,
                                    StyleMeasurementCategoryID: category.styleMeasurementCategoryID,
                                    MeasurementCategoryID: category.measurementCategoryID,
                                    StyleMeasurementID: category.styleMeasurementID,
                                    CategoryTypeName: category.categoryTypeName,
                                    CategoryIndexName: category.categoryIndexName,
                                    SizeHeaderNames: category.sizeHeaderNames,
                                    SkuName: category.skuName,
                                    CurrentSize: 'S',
                                    Remark: category.remark,
                                    Point: []
                                });
                                // setcurrentSizeheaders(Sizeheadernames);

                                let pointList = res.data.finalInsMeasurementPointList.filter(x => x.categoryIndexName === category.categoryIndexName && x.skuName === category.skuName);
                                pointList.map((point, pointIndex) => {
                                    //Revise
                                    // point.styleMeasurementCategoryPointsID = MeasurementList.measurementOpreation === 1 ? 0 : point.styleMeasurementCategoryPointsID;
                                    values[0].Category[catIndex].Point.push({
                                        TNAFinalinsMeasurementPointsId: point.tnaFinalinsMeasurementPointsId,
                                        StyleMeasurementID: point.styleMeasurementID,
                                        MeasurementPoints: point.measurementPoints,
                                        MeasurementGranding: point.measurementGranding,
                                        TolerancePlus: point.tolerancePlus,
                                        ToleranceMinus: point.toleranceMinus,
                                        GradingSizes: point.gradingSizes,
                                        GradingSizesValue: point.gradingSizesValue,
                                        arrGradingSizes: [],
                                        CategoryIndexName: point.categoryIndexName,
                                        SkuName: point.skuName,

                                    });
                                    let arrGradingSizes = point.gradingSizes.split(",");
                                    let arrGradingSizesValue = point.gradingSizesValue.split(",");
                                    let arrcount = 0;
                                    let Reqval = arrGradingSizesValue[0];
                                    let Rangefrom = parseFloat(Reqval) - parseFloat(point.toleranceMinus);
                                    let Rangeto = parseFloat(Reqval) + parseFloat(point.tolerancePlus);



                                    arrGradingSizes.forEach((val, valIndex) => {
                                        if (arrcount === 0) {
                                            arrcount++;
                                            values[0].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                                                [val]: arrGradingSizesValue[valIndex],
                                                [val + "Color"]: (parseFloat(arrGradingSizesValue[valIndex]) === 0  || (Rangefrom <= parseFloat(arrGradingSizesValue[valIndex]) && 
                                                Rangeto >= parseFloat(arrGradingSizesValue[valIndex]))) ? 'green' : 'red'
                                                //[val + "Color"]: 'green'
                                            });
                                        }
                                        else {
                                            values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val] =
                                                arrGradingSizesValue[valIndex];
                                            values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val + "Color"] =  
                                            (parseFloat(arrGradingSizesValue[valIndex]) === 0 ||  (Rangefrom <= parseFloat(arrGradingSizesValue[valIndex]) &&
                                                    Rangeto >= parseFloat(arrGradingSizesValue[valIndex]))) ?  'green' : 'red'

                                        }
                                    });

                                });
                            });

                            setCurrentSize(values[0].Category);

                        }
                    }
                    else {
                       
                        props.location.state.params.multiselecteddatas.filter(multi => multi.isSelected === 1).map((x, index) => {
                            skudetails.push({
                                SkuName: x.skuName
                            })
                        })
                        FinalinspectionService.GetTNAFinalinspecMeasurementList(props.location.state.params.styleID).then((res) => {
                            if (res.data) {
                                if (res.data.finalInsMeasurementSpec.length > 0) {

                                    values[0].StyleMeasurementID = res.data.finalInsMeasurementSpec[0].styleMeasurementID;
                                    values[0].MeasurementSpec = res.data.finalInsMeasurementSpec[0].measurementSpecReason;
                                    //  values[0].MeasurementGrandingSize = response.data.specList[0].measurementGrandingSize;
                                    // values[0].MeasurementOperation = MeasurementList.measurementOpreation;
                                    values[0].Category = [];

                                }
                                else {
                                    setisStyleMeasurementempty(true);
                                }
                                if (res.data.finalInsMeasurementCatagory.length > 0) {
                                    res.data.finalInsMeasurementCatagory.map((category, catIndex) => {
                                        //Revise
                                        let Sizeheadernames = [];

                                        values[0].Category.push({
                                            StyleMeasurementCategoryID: category.styleMeasurementCategoryID,
                                            MeasurementCategoryID: category.measurementCategoryID,
                                            StyleMeasurementID: category.styleMeasurementID,
                                            CategoryTypeName: category.categoryTypeName,
                                            CategoryIndexName: category.indexName,
                                            SizeHeaderNames: Sizeheadernames,
                                            SkuName: '',
                                            CurrentSize: '',
                                            Remark: '',
                                            Point: []
                                        });

                                        let pointList = res.data.finalInsMeasurementPoints.filter(x => x.indexName === category.indexName);
                                        pointList.map((point, pointIndex) => {

                                            values[0].Category[catIndex].Point.push({
                                                StyleMeasurementID: point.styleMeasurementID,
                                                MeasurementPoints: point.measurementPoints,
                                                MeasurementGranding: point.measurementGranding,
                                                TolerancePlus: point.tolerancePlus,
                                                ToleranceMinus: point.toleranceMinus,
                                                GradingSizes: point.gradingSizes,
                                                GradingSizesId: point.gradingSizesId,
                                                GradingSizesValue: point.gradingSizesValue,
                                                arrGradingSizes: [],
                                                CategoryIndexName: point.indexName,
                                                SkuName: '',

                                            });
                                            let arrGradingSizes = point.gradingSizes.split(",");
                                            let arrGradingSizesValue = point.gradingSizesValue.split(",");
                                            let arrcount = 0;

                                            arrGradingSizes.forEach((val, valIndex) => {
                                                if (arrcount === 0) {
                                                    arrcount++;
                                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                                                        [val + "-Req"]: arrGradingSizesValue[valIndex],
                                                        SkuName: '',
                                                        [val + "-ReqColor"]: 'black',
                                                    });
                                                }
                                                else {
                                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val + "-Req"] =
                                                        arrGradingSizesValue[valIndex];
                                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0].SkuName = ''
                                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val + "-ReqColor"] = 'black'

                                                }
                                            });

                                        });
                                    });

                                }
                                var uniqueimagesMSD = [...new Map(skudetails.map(item =>
                                    [item['SkuName'], item])).values()];
                                loadMeasurementSizedetails(uniqueimagesMSD, res.data.finalInsMeasurementPoints, res.data.finalInsMeasurementCatagory);
                            }
                        })
                    }

                    setInputFields(values);
                   ;
                    var uniqueimages = [...new Map(newdatass.map(item =>
                        [item['SkuName'], item])).values()];
                    setMessureValue(uniqueimages)
                    let MeasrurementTabdata = { Files: uniqueimages, Measurementdata: values };
                    Messurecallback(MeasrurementTabdata)
                    hideLoader();
                })
        }
    }, [rerenderdep])

    useEffect(() => {
        if (getCalcType.calcID != 0) {
            let values = [...inputFields];
            values.forEach((element, index) => {
                let ElementCategory = element.Category;
                ElementCategory.forEach((ec, ecindex) => {
                    let CurrentSize = ec.CurrentSize;
                    let GetPoints = ec.Point;
                    if (GetPoints != undefined) {
                        GetPoints.forEach((pointelement, pointindex) => {
                            let TolerancePlus = pointelement.TolerancePlus;
                            let ToleranceMinus = pointelement.ToleranceMinus;
                            let Samples = pointelement.arrGradingSizes;

                            const arrGradingSizes = pointelement.arrGradingSizes;

                            
                            Samples.forEach((sampleelement, sampleindex) => {
                                let GetAllCurrentSeries = Object.keys(sampleelement).filter(function (k) {
                                    return k.indexOf(CurrentSize + "-") == 0;
                                }).reduce(function (newData, k) {
                                    newData[k] = sampleelement[k];
                                    return newData;
                                }, {});
                                var SizesWithValuesRegex = /^[A-Z]-[0-9]+$/;
                                let RequiredValue = parseInt(GetAllCurrentSeries[CurrentSize + "-Req"]);
                                let MatchedSizez = [];
                                for (const key in GetAllCurrentSeries) {
                                    if (SizesWithValuesRegex.test(key)) {
                                        MatchedSizez.push({ [key]: GetAllCurrentSeries[key] });
                                    }
                                }
                                if (MatchedSizez.length > 0) {
                                    MatchedSizez.forEach((size, sizeindex) => {
                                        if (Object.values(size)[0] != "") {
                                            if (getCalcType.calcID == 1) {
                                                // Recursive function to traverse nested objects
                                                function updateValues(obj, baseKey) {
                                                    for (const key in obj) {
                                                        const currentKey = "M-Req";  // Set the currentKey to "M-Req"
                        
                                                        // Check if the value is an object
                                                        if (typeof obj[key] === "object") {
                                                        // If it's an object, recursively call the function
                                                        updateValues(obj[key], key);
                                                        } else if (obj[key] === "0") {
                                                        // If it's "0", assign the value of "M-Req"
                                                            obj[key] = obj[currentKey];
                                                        }
                                                    }
                                                    }
                        
                                                    // Call the function to update values
                                                    updateValues(arrGradingSizes, "");
                                
                                                let AllowableTolMinus = parseInt(RequiredValue) - parseInt(ToleranceMinus);
                                                let AllowableTolPlus = parseInt(RequiredValue) + parseInt(TolerancePlus);
                                                if (((parseFloat(Object.values(size)[0]) >= (parseInt(RequiredValue))) && (parseFloat(Object.values(size)[0]) <= AllowableTolPlus))
                                                    ||
                                                    ((parseFloat(Object.values(size)[0]) <= (parseInt(RequiredValue))) && (parseFloat(Object.values(size)[0]) >= AllowableTolMinus))) {
                                                    element.Category[ecindex].Point[pointindex].arrGradingSizes[sampleindex][(Object.keys(size)) + "Color"] = "green";
                                                }
                                                else {
                                                    element.Category[ecindex].Point[pointindex].arrGradingSizes[sampleindex][(Object.keys(size)) + "Color"] = "red";
                                                }
                                            }
                                            else if (getCalcType.calcID == 2) {
                                                if (parseFloat(Object.values(size)[0]) === 0 || parseInt(ToleranceMinus) <= parseFloat(Object.values(size)[0])
                                                    && parseFloat(TolerancePlus) >= parseFloat(Object.values(size)[0])) {
                                                    element.Category[ecindex].Point[pointindex].arrGradingSizes[sampleindex][(Object.keys(size)) + "Color"] = "green";
                                                }
                                                else {
                                                    element.Category[ecindex].Point[pointindex].arrGradingSizes[sampleindex][(Object.keys(size)) + "Color"] = "red";
                                                }
                                            }
                                        }
                                    })
                                }
                            });
                        });
                        return false;
                    }
                })
            });
            setInputFields(values);
        }
    }, [getCalcType, getCurrentSizeForColors]);


    function loadMeasurementSizedetails(skudata, msrpoinstdata, msrcategorydata) {
        StrikeoffService.LoadGroupSizeDetailsList(props.location.state.params.styleID).then((response) => {
            if (response.data) {
                // setGradingSize(response.data);
                var allsizes = response.data;
                let CategoryRowNo = 1;
                skudata.map((m, mindex) => {


                    if (mindex === 0) {
                        values[0].Category.map((c, cindex) => {

                            let Sizeheadernames = [];
                            allsizes.map(s => {
                                for (var i = 1; i <= 3; i++) {

                                    if (i === 1) {
                                        Sizeheadernames.push({
                                            SizeName: s.label,
                                            HeaderName: s.label + "-Req",
                                            SkuName: m.SkuName,
                                            CategoryRowNo: CategoryRowNo
                                        })
                                    }
                                    Sizeheadernames.push({
                                        SizeName: s.label,
                                        HeaderName: s.label + "-" + i,
                                        SkuName: m.SkuName,
                                        CategoryRowNo: CategoryRowNo
                                    })
                                }
                            })

                            values[0].Category[cindex].SizeHeaderNames = Sizeheadernames;
                            values[0].Category[cindex].SkuName = m.SkuName;
                            values[0].Category[cindex].CurrentSize = allsizes[0].label;
                            values[0].Category[cindex].CategoryRowNo = CategoryRowNo;

                            values[0].Category[cindex].Point.map((p, pindex) => {
                                values[0].Category[cindex].Point[pindex].SkuName = c.SkuName;

                                allsizes.map(s => {
                                    for (var i = 1; i <= 3; i++) {
                                        let columnname = s.label + "-" + i;
                                        values[0].Category[cindex].Point[pindex].arrGradingSizes[0][columnname] = '';
                                        values[0].Category[cindex].Point[pindex].arrGradingSizes[0][columnname + "Color"] = 'black';

                                    }
                                })
                            })
                            CategoryRowNo++;
                        })
                    }
                    else {
                        msrcategorydata.map((cd, cdindex) => {

                            let Sizeheadernames = [];
                            allsizes.map(s => {
                                for (var i = 1; i <= 3; i++) {

                                    if (i === 1) {
                                        Sizeheadernames.push({
                                            SizeName: s.label,
                                            HeaderName: s.label + "-Req",
                                            SkuName: m.SkuName,
                                            CategoryRowNo: CategoryRowNo
                                        })
                                    }
                                    Sizeheadernames.push({
                                        SizeName: s.label,
                                        HeaderName: s.label + "-" + i,
                                        SkuName: m.SkuName,
                                        CategoryRowNo: CategoryRowNo
                                    })
                                }
                            })

                            values[0].Category.push({

                                StyleMeasurementCategoryID: cd.styleMeasurementCategoryID,
                                MeasurementCategoryID: cd.measurementCategoryID,
                                StyleMeasurementID: cd.styleMeasurementID,
                                CategoryTypeName: cd.categoryTypeName,
                                CategoryIndexName: cd.indexName,
                                SizeHeaderNames: Sizeheadernames,
                                SkuName: m.SkuName,
                                CurrentSize: response.data[0].label,
                                CategoryRowNo: CategoryRowNo,
                                Remark: '',
                                Point: [],
                            })

                            let currcatindex = values[0].Category.length - 1;

                            let pointList = msrpoinstdata.filter(x => x.indexName === values[0].Category[currcatindex].CategoryIndexName);
                            pointList.map((point, pointIndex) => {
                                values[0].Category[currcatindex].Point.push({
                                    StyleMeasurementCategoryPointsID: point.styleMeasurementCategoryPointsID,
                                    StyleMeasurementID: point.styleMeasurementID,
                                    MeasurementPoints: point.measurementPoints,
                                    MeasurementGranding: point.measurementGranding,
                                    TolerancePlus: point.tolerancePlus,
                                    ToleranceMinus: point.toleranceMinus,
                                    GradingSizes: point.gradingSizes,
                                    GradingSizesId: point.gradingSizesId,
                                    GradingSizesValue: point.gradingSizesValue,
                                    arrGradingSizes: [],
                                    CategoryIndexName: point.indexName,
                                    SkuName: values[0].Category[currcatindex].SkuName,
                                })

                                let arrGradingSizes = point.gradingSizes.split(",");
                                let arrGradingSizesValue = point.gradingSizesValue.split(",");
                                let arrcount = 0;

                                arrGradingSizes.forEach((val, valIndex) => {
                                    if (arrcount === 0) {
                                        arrcount++;
                                        values[0].Category[currcatindex].Point[pointIndex].arrGradingSizes.push({
                                            [val + "-Req"]: arrGradingSizesValue[valIndex],
                                            SkuName: '',
                                            [val + "-ReqColor"]: 'black',
                                        });
                                    }
                                    else {
                                        values[0].Category[currcatindex].Point[pointIndex].arrGradingSizes[0][val + "-Req"] =
                                            arrGradingSizesValue[valIndex];
                                        values[0].Category[currcatindex].Point[pointIndex].arrGradingSizes[0].SkuName = ''
                                        values[0].Category[currcatindex].Point[pointIndex].arrGradingSizes[0][val + "-ReqColor"] = 'black'

                                    }
                                });
                            })

                            values[0].Category[currcatindex].Point.map((p, pindex) => {
                                // values[0].Category[currcatindex].Point[pindex].SkuName = c.SkuName;

                                allsizes.map(s => {
                                    for (var i = 1; i <= 3; i++) {
                                        let columnname = s.label + "-" + i;
                                        values[0].Category[currcatindex].Point[pindex].arrGradingSizes[0][columnname] = '';
                                        values[0].Category[currcatindex].Point[pindex].arrGradingSizes[0][columnname + "Color"] = 'black';
                                    }
                                })
                            })

                            CategoryRowNo++;
                        })
                    }
                })

            }
        }).catch(() => { });

    }


    function setCurrentSize(CategoryList) {
        StrikeoffService.LoadGroupSizeDetailsList(props.location.state.params.styleID).then((response) => {
            if (response.data) {
                // setGradingSize(response.data);
                var allsizes = response.data;
                let CategoryRowNo = 1;



                CategoryList.map((c, cindex) => {
                    let Sizeheadernames = [];
                    let splitheadername = c.SizeHeaderNames.split(',')
                    splitheadername.map(s => {
                        let size = s.split('-');
                        Sizeheadernames.push({
                            SizeName: size[0],
                            HeaderName: s,
                            SkuName: c.SkuName,
                            CategoryRowNo: CategoryRowNo
                        })
                    })
                    values[0].Category[cindex].SizeHeaderNames = Sizeheadernames;
                    values[0].Category[cindex].CurrentSize = allsizes[0].label;
                    values[0].Category[cindex].CategoryRowNo = CategoryRowNo;
                    CategoryRowNo++;
                })
                values[0].Category = CategoryList;

            }
        }).catch(() => { });

    }


    const AppendFiles = async (e, index) => {
        let data = [...getMessureValue]
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "FinalInspectionMeasuerent");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);

                    data[index].FileName = res.data + "_" + file.name
                    data[index].FilePath = "Images/Finalinspection/Measurement/" + res.data + "_" + file.name
                    data[index].FileType = file.type
                    setMessureValue(data);
                    let MeasrurementTabdata = { Files: data, Measurementdata: values };
                    Messurecallback(MeasrurementTabdata)
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }

        }
    }

    const RemoveImages = (index) => {
        let Filevalue = [...getMessureValue]
        Filevalue[index].FileType = '';
        Filevalue[index].FileName = '';
        Filevalue[index].FilePath = '';
        Filevalue[index].IsDeleted = 1;
        setMessureValue(Filevalue);
        let MeasrurementTabdata = { Files: Filevalue, Measurementdata: values };
        Messurecallback(MeasrurementTabdata)
    }
    const RemoveFiles = async (e, index) => {
        let Filevalue = [...getMessureValue]
        Filevalue[index].FileType = '';
        Filevalue[index].FileName = '';
        Filevalue[index].FilePath = '';
        Filevalue[index].IsDeleted = 1;
        setMessureValue(Filevalue);
        let MeasrurementTabdata = { Files: Filevalue, Measurementdata: values };
        Messurecallback(MeasrurementTabdata)
    }

    const ViewFile = (index, filename) => { // event.preventDefault();
        //getFiles[index].FileName = filename;
        setImagename(filename);
        setIsOpenimg(true);
    }

    const handleCommonResultInputChange = (index, e, FeildName) => {
        const fileval = [...getMessureValue];
        let inputText = ""
        if (FeildName === "InspectionResult") {
            if (e !== null) {
                fileval[index].Result = e.value;
                fileval[index].ResultName = e.subLabel;
                fileval[index].MainIndex = index;
            } else {
                fileval[index].Result = 0;
                fileval[index].ResultName = '';
            }
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            fileval[index].FindingsRemarks = inputText;
        }

        setMessureValue(fileval);
        let MeasrurementTabdata = { Files: fileval, Measurementdata: values };
        Messurecallback(MeasrurementTabdata)
    };


    const addSizeHeader = (index, sizename, catindex, Skuname, CategoryRowNo) => {
        let newsizename = sizename + "-" + (parseInt(index) + 1);

        values[0].Category[catindex].SizeHeaderNames.push({
            SizeName: sizename,
            HeaderName: newsizename,
            SkuName: Skuname,
            CategoryRowNo: CategoryRowNo
        })

        values.map(v => {
            v.Category.filter(c => c.SkuName === Skuname).map(c => {
                c.Point.filter(p => p.SkuName === Skuname).map(p => {
                    p.arrGradingSizes[0][newsizename] = ''
                })
            })
        })

        setInputFields(values);
        let MeasrurementTabdata = { Files: getMessureValue, Measurementdata: values };
        Messurecallback(MeasrurementTabdata)

    }
    const CopySizeValues = (index, sizename, catindex, Skuname, CategoryRowNo) => {
        if (values[0].MeasurementCheckedPeices === '') {
            Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Measurement Information.');
            return false;
        }
        else {
            let newsizename = sizename + "-" + (parseInt(index) + 1);
            let oldsizename = sizename + "-" + (parseInt(index) === 0 ? 'Req' : (parseInt(index)));
            values.map(v => {
                v.Category.filter(c => c.SkuName === Skuname).map(c => {
                    c.Point.filter(p => p.SkuName === Skuname).map(p => {
                        p.arrGradingSizes[0][newsizename] = p.arrGradingSizes[0][oldsizename]
                        p.arrGradingSizes[0][newsizename + "Color"] = p.arrGradingSizes[0][oldsizename + "Color"]
                    })
                })
            })

            setInputFields(values);
            let MeasrurementTabdata = { Files: getMessureValue, Measurementdata: values };
            Messurecallback(MeasrurementTabdata)
        }

    }


    const RemoveSizeHeader = (index, sizename, catindex, Skuname, CategoryRowNo) => {
        let sizeindex = values[0].Category[catindex].SizeHeaderNames.findIndex(s => s.HeaderName === sizename);
        values[0].Category[catindex].SizeHeaderNames.splice(sizeindex, 1);
        setInputFields(values);

        let MeasrurementTabdata = { Files: getMessureValue, Measurementdata: values };
        Messurecallback(MeasrurementTabdata)
    }

    const handleExpandRow = (CategoryRowNo, SkuNmae) => {
        Expandrow(CategoryRowNo);
    }

    const Expandrow = (CategoryRowNo) => {
        setExpandedRows([]);
        // Expand row Start
        let currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(CategoryRowNo);
        let obj = {};
        isRowExpanded ? (obj[CategoryRowNo] = false) : (obj[CategoryRowNo] = true);
        setExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== CategoryRowNo) :
            currentExpandedRows.concat(CategoryRowNo);
        setExpandedRows(newExpandedRows);
        // Expand row End


    }

    const handleChangedetails = (e, feild, index, cindex, pindex) => {
       ;
        let CalculationType = getCalcType.calcID;
        let inputtext = '';
        var regexPeices = /^[0-9]+$/;
        var regexPoints = /^[0-9.-]+$/;
        //const Regex = new RegExp('^[0-9]+$');


        if (e !== '') {
            inputtext = e.target.value;
        }

        if (feild === "MsrCheckedPoints") {
            let isValidPeices = regexPeices.test(inputtext);
            if (isValidPeices) {
                inputtext = e.target.value;
            } else {
                inputtext = '';
            }
            values[index].MeasurementCheckedPeices = inputtext;
        }
        else if (feild === "Remark") {

            if (values[index].MeasurementCheckedPeices === '') {
                Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Measurement Information.');
                return false;
            }
            else {
                values[0].Category[cindex].Remark = inputtext;
            }

        }
        else {
            if (values[index].MeasurementCheckedPeices === '' || getCalcType.calcID === 0) {
                Nodify('Warning!', 'warning', 'Please fill The  mandatory(*) fields in Measurement Information.');
                return false;
            }
            else {
                let isValid = regexPoints.test(inputtext);
                if (isValid) {
                    inputtext = e.target.value;
                } else {
                    inputtext = '';
                }

                let Sizename = feild.split('-');
                let Reqval = values[0].Category[cindex].Point[pindex].arrGradingSizes[0][Sizename[0] + "-Req"]
                values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild] = inputtext;

                let Rangefrom = parseFloat(Reqval) - parseFloat(values[0].Category[cindex].Point[pindex].ToleranceMinus);
                let Rangeto = parseFloat(Reqval) + parseFloat(values[0].Category[cindex].Point[pindex].TolerancePlus);
                let ToleranceMinus = parseFloat(values[0].Category[cindex].Point[pindex].ToleranceMinus);
                let TolerancePlus = parseFloat(values[0].Category[cindex].Point[pindex].TolerancePlus);

                if (isNaN(ToleranceMinus) || isNaN(TolerancePlus)) {
                    ToleranceMinus = 0;
                    TolerancePlus = 0;
                }

                if (getCalcType.calcID == 1) {
                    let AllowableTolMinus = parseFloat(Reqval) - parseFloat(ToleranceMinus);
                    let AllowableTolPlus = parseFloat(Reqval) + parseFloat(TolerancePlus);

                    if (ToleranceMinus === 0 && TolerancePlus === 0) {
                        if (inputtext === "0") {
                            values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'green';
                        }
                        else {
                            values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'red';
                        }
                    }
                    else if ((parseFloat(inputtext) >= (parseInt(Reqval))) && (parseFloat(inputtext) <= AllowableTolPlus)

                        || (parseFloat(inputtext) <= (parseInt(Reqval))) && (parseFloat(inputtext)  >= AllowableTolMinus.toFixed(2))
                        
                    ) {
                        values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'green';
                    }
                    else {
                        values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'red';
                    }
                }
                else if (getCalcType.calcID == 2) {
                    let modifiedToleranceMinus = parseFloat(ToleranceMinus) * -1;
                    if (ToleranceMinus === 0 && TolerancePlus === 0) {
                        if (inputtext === "0") {
                            values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'green';
                        }
                        else {
                            values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'red';
                        }
                    }
                    else if (modifiedToleranceMinus <= parseFloat(inputtext) && parseFloat(TolerancePlus) >= parseFloat(inputtext)) {
                        values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'green';
                    }
                    else if (ToleranceMinus === 0 && TolerancePlus === 0) {
                        values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'green';
                    } else {
                        values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'red';
                    }
                }
                // if (Rangefrom <= parseFloat(inputtext) && Rangeto >= parseFloat(inputtext)) {
                //     values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'green'
                // }
                // else {
                //     values[0].Category[cindex].Point[pindex].arrGradingSizes[0][feild + "Color"] = 'red'
                // }
            }

        }
        setInputFields(values);
        let MeasrurementTabdata = { Files: getMessureValue, Measurementdata: values };
        Messurecallback(MeasrurementTabdata)
    }

    const handleCalculationChange = (event) => {
       ;
        if (event != null) {
            setCalcType({ calcID: event.id, calcIDForEdit: event });
            inputFields.forEach((element, eleindex) => {
                inputFields[eleindex].CalculationType = event.id;
            });
            setInputFields(inputFields);
            let MeasrurementTabdata = { Files: getMessureValue, Measurementdata: inputFields };
            Messurecallback(MeasrurementTabdata)
        }
        else {
            setCalcType({ calcID: 2, calcIDForEdit: [{ id: 2, label: "Difference", name: "Difference", value: 2 }] });
            inputFields.forEach((element, eleindex) => {
                inputFields[eleindex].CalculationType = 0;
            });
            let MeasrurementTabdata = { Files: getMessureValue, Measurementdata: inputFields };
            Messurecallback(MeasrurementTabdata);
        }
    }

    return (
        <Fragment>
            <div className="page-body">
                <div className="col-sm-4">
                    <div className='form-group'>
                        <label htmlFor="MeasurementCheckedPieces">Measurement Checked Pieces<span className="text-danger">*</span></label>
                        <span className='input-icon icon-right'>
                            <input id="MeasurementCheckedPieces"
                                name="MeasurementCheckedPieces"
                                value={inputFields[0].MeasurementCheckedPeices}
                                onChange={event => handleChangedetails(event, 'MsrCheckedPoints', 0, 0, 0)}
                                type="text"
                                autoComplete="off"
                                maxLength="5"
                                className={'form-control'}
                                styles={submitted && inputFields[0].MeasurementCheckedPeices === '' ? styles : ''}

                            />
                        </span>
                    </div>
                </div>
                <div className="col-sm-5"></div>
                <div className="col-sm-3">
                    <div className='form-group'>
                        <label htmlFor="Remark">Calculation Type<span className="text-danger">*</span></label>
                        <span className='input-icon icon-right'>
                            <Reactselect
                                className="basic-single"
                                name={"CalculationType"}
                                id={
                                    "CalculationType"
                                }
                                // value={Pointitem.MeasurementPoints}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isSearchable={true}
                                onChange={e => { handleCalculationChange(e) }}
                                options={getFoundDiffOptions}
                                value={getCalcType.calcIDForEdit[0]} 
                            ></Reactselect>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            {/* <h2 class="black">Work In Progress</h2> */}
                            {/* <input type="text" className='form-control' placeholder="Enter Measurement Checked Pieces" id="txtCheckedPieces"
                                name="measurementcheckedpieces" autoComplete="off" maxLength="100" /> */}
                        </div>
                        {
                            getMessureValue.map((comment, index) => (
                                <div className="row">
                                    <div className="panel-group accordion" id="acc_trims_Info">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a id={index} className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_trims_Info" href={"#collapse_acc_trims_Info" + index}>
                                                        {comment.SkuName}
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id={"collapse_acc_trims_Info" + index} className="panel-collapse collapse panel-body">
                                                <div id={index} className="" >
                                                    <div className="">
                                                        {
                                                            isStyleMeasurementempty === false ?
                                                                inputFields.map((item, subindex) => (

                                                                    // item.SkuName === comment.SkuName &&
                                                                    <Fragment key={`${item}~${subindex}`}>
                                                                        <div id="" className="panel-body border-red">

                                                                            <div className="row">
                                                                                <div className="col-lg-12">
                                                                                    <div className="row">

                                                                                        <div className="col-md-3">
                                                                                            <div className="form-group">
                                                                                                <label htmlFor={"MeasurementSpec" + subindex}>Spec Reason</label><span className="text-danger">*</span>
                                                                                                <input placeholder="Enter Spec Reason"
                                                                                                    id={"MeasurementSpec" + subindex}
                                                                                                    name="MeasurementSpec"
                                                                                                    value={item.MeasurementSpec}
                                                                                                    //  onChange={event => specInputChange(event, index, "MeasurementSpec")}
                                                                                                    type="text"
                                                                                                    maxLength="50" autoComplete="off" className='form-control'
                                                                                                    disabled
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <table className="table table-bordered" id="editabledatatable">

                                                                                <tbody>

                                                                                    {
                                                                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                                                                            return (
                                                                                                <>

                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <i
                                                                                                                className={
                                                                                                                    expandState[Catitem.CategoryRowNo] ?
                                                                                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                }
                                                                                                                onClick={event => handleExpandRow(Catitem.CategoryRowNo)}
                                                                                                            ></i>
                                                                                                            <span className="BuyerPaddingLeft">
                                                                                                                {
                                                                                                                    Catitem.CategoryTypeName
                                                                                                                }
                                                                                                            </span>
                                                                                                        </td>

                                                                                                    </tr>
                                                                                                    <>
                                                                                                        {
                                                                                                            expandedRows.includes(Catitem.CategoryRowNo) ?
                                                                                                                <tr>
                                                                                                                    <td colSpan="150">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-lg-12">
                                                                                                                                <div className="dataTables_wrapper no-footer" style={{ width: '99%', overflowX: "auto" }}>
                                                                                                                                    {
                                                                                                                                        inputFields[0].Category[0].Point.length > 0 ?
                                                                                                                                            <>
                                                                                                                                                <div className="col-sm-8">
                                                                                                                                                    <div className='form-group'>

                                                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                                                            <textarea
                                                                                                                                                                id="Remark" name="Remark" //className="form-control"
                                                                                                                                                                value={Catitem.Remark}
                                                                                                                                                                onChange={event => handleChangedetails(event, 'Remark', subindex, Catitem.CategoryRowNo - 1, 0)}
                                                                                                                                                                type="text"
                                                                                                                                                                autoComplete="off"
                                                                                                                                                                maxLength="50"
                                                                                                                                                                placeholder="Enter the value"

                                                                                                                                                                className={'form-control'}
                                                                                                                                                            />

                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                {

                                                                                                                                                    <table className="table table-bordered" >
                                                                                                                                                        <thead className="text-center">
                                                                                                                                                            <tr>
                                                                                                                                                                <th colSpan={4}></th>
                                                                                                                                                                <th className="text-center " colSpan={150}>
                                                                                                                                                                    {
                                                                                                                                                                        getGradingSize.map((size, i) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <span>
                                                                                                                                                                                    <button style={{ width: "10%" }} type="button" className={size.label === Catitem.CurrentSize ? "btn btn-sm btn-success" : "btn btn-sm btn-primary"}
                                                                                                                                                                                        onClick={() => AssignCurrentSize(size.label, Catitem.CategoryRowNo - 1, Catitem.SkuName)}>{size.label}</button>
                                                                                                                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                                                                                                </span>
                                                                                                                                                                            )
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                </th>
                                                                                                                                                            </tr>
                                                                                                                                                            <tr>
                                                                                                                                                                <th>#</th>
                                                                                                                                                                <th style={{ width: "23%" }} className="word_warp_padding text-center ">
                                                                                                                                                                    Points
                                                                                                                                                                </th>
                                                                                                                                                                <th className="text-center ">Grading</th>
                                                                                                                                                                <th className="text-center ">Tol +<span className='text-danger'> *</span></th>
                                                                                                                                                                <th className="text-center ">Tol -<span className='text-danger'> *</span></th>
                                                                                                                                                                {/* <th className="text-center ">Req</th> */}
                                                                                                                                                                {
                                                                                                                                                                    Catitem.SizeHeaderNames.filter(x => x.SizeName === Catitem.CurrentSize && x.SkuName === comment.SkuName && x.CategoryRowNo === Catitem.CategoryRowNo).map((size, i) => {
                                                                                                                                                                        return (
                                                                                                                                                                            // <th className="text-center ">{size.label}</th>
                                                                                                                                                                            // Size === size.label ? <span className="text-danger">*</span> : ''
                                                                                                                                                                            <th className="text-center ">

                                                                                                                                                                                <span>{size.HeaderName}

                                                                                                                                                                                </span>
                                                                                                                                                                                <br />
                                                                                                                                                                                {Catitem.SizeHeaderNames.filter(x => x.SizeName === Catitem.CurrentSize).length <= 10 &&
                                                                                                                                                                                    <button style={{ display: props.location.state.params.Action === 'View' ? 'none' : '', marginTop: "4px" }} className="btn btn-sm btn-success" onClick={() => addSizeHeader(i, size.SizeName, Catitem.CategoryRowNo - 1, Catitem.SkuName, Catitem.CategoryRowNo)}
                                                                                                                                                                                    ><i className="fa fa-plus"></i></button>}
                                                                                                                                                                                &nbsp;
                                                                                                                                                                                <button style={{ display: props.location.state.params.Action === 'View' ? 'none' : '', marginTop: "4px" }} className="btn btn-sm btn-primary" onClick={() => CopySizeValues(i, size.SizeName, Catitem.CategoryRowNo - 1, Catitem.SkuName, Catitem.CategoryRowNo)}
                                                                                                                                                                                ><i className="fa fa-copy"></i></button>
                                                                                                                                                                                &nbsp;
                                                                                                                                                                                {Catitem.SizeHeaderNames.filter(x => x.SizeName === Catitem.CurrentSize).length !== 1 && !size.HeaderName.includes('Req') &&
                                                                                                                                                                                    <button style={{ display: props.location.state.params.Action === 'View' ? 'none' : '', marginTop: "4px" }} className="btn btn-sm btn-danger" onClick={() => RemoveSizeHeader(i, size.HeaderName, Catitem.CategoryRowNo - 1, Catitem.SkuName, Catitem.CategoryRowNo)}
                                                                                                                                                                                    ><i className="fa fa-trash"></i></button>}

                                                                                                                                                                            </th>
                                                                                                                                                                        )

                                                                                                                                                                    })
                                                                                                                                                                }


                                                                                                                                                            </tr>
                                                                                                                                                        </thead>
                                                                                                                                                        <tbody>
                                                                                                                                                            {


                                                                                                                                                                Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                                                                                                                                    // Catitem.Point.map((Pointitem, pointIndex) => {
                                                                                                                                                                    return (
                                                                                                                                                                        <>

                                                                                                                                                                            <tr key={pointIndex}>
                                                                                                                                                                               <td>{pointIndex+1}</td>
                                                                                                                                                                                <td className='' >
                                                                                                                                                                                    <Reactselect
                                                                                                                                                                                        className="basic-single"
                                                                                                                                                                                        name={"MeasurementPoints"}
                                                                                                                                                                                        id={
                                                                                                                                                                                            "MeasurementPoints" + pointIndex
                                                                                                                                                                                        }
                                                                                                                                                                                        // value={Pointitem.MeasurementPoints}
                                                                                                                                                                                        isDisabled={true}
                                                                                                                                                                                        isLoading={false}
                                                                                                                                                                                        isClearable={true}
                                                                                                                                                                                        isSearchable={true}
                                                                                                                                                                                        options={getMeasurement}
                                                                                                                                                                                        value={getMeasurement.filter(function (option) {
                                                                                                                                                                                            return option.value === Pointitem.MeasurementPoints;
                                                                                                                                                                                        })}
                                                                                                                                                                                    ></Reactselect>
                                                                                                                                                                                </td>
                                                                                                                                                                                <td className=''>
                                                                                                                                                                                    <input placeholder=""
                                                                                                                                                                                        id={
                                                                                                                                                                                            "MeasurementGranding" + pointIndex
                                                                                                                                                                                        }
                                                                                                                                                                                        name={
                                                                                                                                                                                            "MeasurementGranding"
                                                                                                                                                                                        }
                                                                                                                                                                                        value={Pointitem.MeasurementGranding}
                                                                                                                                                                                        type="text"

                                                                                                                                                                                        maxLength="5" autoComplete="off" className='form-control'
                                                                                                                                                                                        style={{ width: "60px" }}
                                                                                                                                                                                        disabled
                                                                                                                                                                                    />
                                                                                                                                                                                </td>
                                                                                                                                                                                <td className=''>
                                                                                                                                                                                    <input
                                                                                                                                                                                        //className=""
                                                                                                                                                                                        placeholder=""
                                                                                                                                                                                        id={
                                                                                                                                                                                            "TolerancePlus" + pointIndex
                                                                                                                                                                                        }
                                                                                                                                                                                        name={
                                                                                                                                                                                            "TolerancePlus"
                                                                                                                                                                                        }
                                                                                                                                                                                        value={Pointitem.TolerancePlus}
                                                                                                                                                                                        type="text"

                                                                                                                                                                                        maxLength="5" autoComplete="off" className='form-control sizePadding'
                                                                                                                                                                                        style={{ width: "60px" }}
                                                                                                                                                                                        disabled
                                                                                                                                                                                    />
                                                                                                                                                                                </td>
                                                                                                                                                                                <td className=''>
                                                                                                                                                                                    <input
                                                                                                                                                                                        //className=""
                                                                                                                                                                                        placeholder=""
                                                                                                                                                                                        id={
                                                                                                                                                                                            "ToleranceMinus" + pointIndex
                                                                                                                                                                                        }
                                                                                                                                                                                        name={
                                                                                                                                                                                            "ToleranceMinus"
                                                                                                                                                                                        }
                                                                                                                                                                                        value={Pointitem.ToleranceMinus}
                                                                                                                                                                                        type="text"
                                                                                                                                                                                        maxLength="5" autoComplete="off" className='form-control sizePadding'
                                                                                                                                                                                        style={{ width: "60px" }}
                                                                                                                                                                                        disabled
                                                                                                                                                                                    />
                                                                                                                                                                                </td>


                                                                                                                                                                                {
                                                                                                                                                                                    Catitem.SizeHeaderNames.filter(x => x.SizeName === Catitem.CurrentSize && x.SkuName === comment.SkuName &&
                                                                                                                                                                                        x.CategoryRowNo === Catitem.CategoryRowNo).map((sizeval, i) => {
                                                                                                                                                                                            return (
                                                                                                                                                                                                <td>
                                                                                                                                                                                                    <input

                                                                                                                                                                                                        id={
                                                                                                                                                                                                            sizeval.HeaderName + "_" + pointIndex
                                                                                                                                                                                                        }
                                                                                                                                                                                                        name={
                                                                                                                                                                                                            sizeval.HeaderName
                                                                                                                                                                                                        }
                                                                                                                                                                                                        // value={
                                                                                                                                                                                                        //     Pointitem.arrGradingSizes[0][sizeval.HeaderName] !== '0' ? Pointitem.arrGradingSizes[0][sizeval.HeaderName] : ''
                                                                                                                                                                                                        // }

                                                                                                                                                                                                        value={Pointitem.arrGradingSizes[0][sizeval.HeaderName]}

                                                                                                                                                                                                        onChange={event => handleChangedetails(event, sizeval.HeaderName, subindex, Catitem.CategoryRowNo - 1, pointIndex)}

                                                                                                                                                                                                        type="text"
                                                                                                                                                                                                        maxLength="5" autoComplete="off" className='form-control sizePadding'
                                                                                                                                                                                                        style={{
                                                                                                                                                                                                            //color: Pointitem.arrGradingSizes[0][sizeval.HeaderName + "Color"],
                                                                                                                                                                                                            color: sizeval.HeaderName.includes('Req') ? "black" : Pointitem.arrGradingSizes[0][sizeval.HeaderName + "Color"]
                                                                                                                                                                                                        }}
                                                                                                                                                                                                        disabled={sizeval.HeaderName.includes('Req') ? true : false}
                                                                                                                                                                                                    // style={{ width: "60px", border: Size.toUpperCase() === size.label.toUpperCase() && submitted ? '1px solid red' : '' }}
                                                                                                                                                                                                    />


                                                                                                                                                                                                </td>

                                                                                                                                                                                            );
                                                                                                                                                                                        })
                                                                                                                                                                                }


                                                                                                                                                                            </tr>
                                                                                                                                                                        </>

                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                            }
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>
                                                                                                                                                }
                                                                                                                                            </>
                                                                                                                                            :
                                                                                                                                            <>
                                                                                                                                                {

                                                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                                                                        <thead className="text-center">
                                                                                                                                                            <tr>
                                                                                                                                                                <th className="word_warp_padding text-center " >Points</th>
                                                                                                                                                                <th className="text-center ">Grading</th>
                                                                                                                                                                <th className="text-center ">Tol +</th>
                                                                                                                                                                <th className="text-center ">Tol -</th>

                                                                                                                                                                <th className="text-center ">Remarks</th>
                                                                                                                                                                <th className="text-center Actionbutton fixed-column-header">Action</th>
                                                                                                                                                            </tr>
                                                                                                                                                        </thead>
                                                                                                                                                        <tbody>
                                                                                                                                                            <tr><td colSpan="6" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>


                                                                                                                                                }

                                                                                                                                            </>
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr> : null
                                                                                                        }
                                                                                                    </>
                                                                                                </>
                                                                                            )

                                                                                        })
                                                                                    }

                                                                                </tbody>
                                                                            </table>



                                                                        </div>
                                                                    </Fragment>
                                                                ))
                                                                : <div className='form-group' style={{ marginLeft: "18px" }}>
                                                                    <br />
                                                                    <span className="widget-header"
                                                                        style={{ color: 'red', fontSize: '14px' }}>*No Measurement Data Found</span>

                                                                </div>
                                                        }

                                                    </div >


                                                    <div className='form-group' style={{ marginLeft: "18px" }}>


                                                        <br />
                                                        <div className="widget-header" style={{ paddingLeft: 0 }}>
                                                            <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span
                                                                style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                                                        </div>
                                                        <div className='form-group'>
                                                            <tr>
                                                                <td> <input type="file" id="FileUpload" disabled={comment.FileName !== '' ? true : false}
                                                                    onChange={(e) => AppendFiles(e, index)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input></td>
                                                            </tr>
                                                        </div>
                                                    </div>
                                                    {
                                                        <div className="col-lg-12 col-sm-12">
                                                            <div className="row no-margin">
                                                                {comment.FileName !== '' ? comment.FileType === "image/jpeg" || comment.FileType === "image/jpg" || comment.FileType === "image/png" ||
                                                                    comment.FileType === "image/gif" ?
                                                                    <div className="float-Left_washcare file_Image_uploads">
                                                                        {/* <div className="col-sm-3"> */}
                                                                        <div className="client_tumblineWashcare">

                                                                            <img className="width_manual1"
                                                                                src={window.$APIBaseURL + comment.FilePath}
                                                                            //onClick={() => setIsOpenimg(window.$APIBaseURL + 'Images/Style/WashCare/' + getImagename)}
                                                                            />

                                                                            <table className="table_thum">
                                                                                <tr className="table_rwo">
                                                                                    {/* <td title={comment.FileName.length > 32 ? comment.FileName.substr(33) : comment.FileName.substr(5)} className="bold_text width_20 sku_textoverflow">
                                                                                        {comment.FileName.length > 32 ? comment.FileName.substr(33) : comment.FileName.substr(5)}
                                                                                    </td> */}
                                                                                </tr>
                                                                            </table>

                                                                            <a className="washcareView" title='View Wash Care' onClick={() => ViewFile(index, comment.FilePath)}>
                                                                                <i className="btn btn-info fa fa-eye" ></i>
                                                                            </a>&nbsp;
                                                                            <a style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }} title='Remove Wash Care' onClick={() => RemoveImages(index)}>
                                                                                <i className="btn btn-danger fa fa-trash-o"></i>
                                                                            </a>

                                                                        </div>
                                                                        {/* </div> */}
                                                                        {

                                                                            < div > {
                                                                                isOpenimg && (

                                                                                    <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                                        alt="bg image"
                                                                                        onCloseRequest={
                                                                                            () => setIsOpenimg(false)
                                                                                        } />
                                                                                )
                                                                            } </div>
                                                                        }
                                                                    </div> : ''
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    }
                                                    &nbsp;
                                                    &nbsp;
                                                    {

                                                        <div className="col-lg-12 col-sm-12">
                                                            <div className="row no-margin">
                                                                {comment.FileName !== '' ?
                                                                    comment.FileType === "application/pdf" || comment.FileType === "application/msword" || comment.FileType === "application/vnd.ms-excel"
                                                                        || comment.FileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || comment.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                                        <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                            <tr title={comment.FileName}>
                                                                                <td className="cut_textInTNA">
                                                                                    <span style={{ marginRight: '6px' }}>
                                                                                        {
                                                                                            comment.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                comment.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                    <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                    comment.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                        comment.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                        <i class="fa fa-picture-o fa-lg image_icon_style"></i>

                                                                                        }
                                                                                        {
                                                                                            comment.FileType === "image/jpeg" || comment.FileType === "image/jpg" || comment.FileType === "image/png" ||
                                                                                                comment.FileType === "image/gif" ? <i class="fa fa-picture-o fa-lg image_icon_style"></i> : ''
                                                                                        }
                                                                                    </span>  {comment.FileName}
                                                                                </td>

                                                                                <td className="button_style_uploda">
                                                                                    {
                                                                                        comment.FileType === "application/pdf" || comment.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                                            comment.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                            || comment.FileType === 'application/msword' || comment.FileType === "application/vnd.ms-excel" ?
                                                                                            <a type="button" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                                title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                                href={window.$APIBaseURL + comment.FilePath} download><i class="fa fa-download"></i> </a> : ''
                                                                                    }
                                                                                    &nbsp;
                                                                                    <p title='Delete File'
                                                                                        style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                        onClick={(e) => RemoveFiles(e, index)}
                                                                                        className="btn btn-danger btn-xs delete">
                                                                                        <i className="fa fa-trash-o"></i>

                                                                                    </p>
                                                                                </td>
                                                                            </tr>
                                                                        </div> : '' : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <br />
                                                <div className="">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                                <thead>
                                                                    <tr>

                                                                        <th>Result<span className="text-danger">*</span></th>
                                                                        <th>Finding / Remarks{comment.Result === 2 && comment.FindingsRemarks === '' && <span className="text-danger"> *</span>}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    <Fragment>
                                                                        <tr key={
                                                                            comment.index
                                                                        }>
                                                                            <td>
                                                                                <Reactselect
                                                                                    className="basic-single"

                                                                                    name="Result"
                                                                                    id={
                                                                                        "Result" + index
                                                                                    }
                                                                                    isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                                                                    isLoading={false}
                                                                                    isClearable={true}
                                                                                    isSearchable={true}
                                                                                    onChange={(event) => handleCommonResultInputChange(index, event, 'InspectionResult')}
                                                                                    options={getCommonResult}
                                                                                    styles={submitted && comment.Result === 0 ? styles : ''}
                                                                                    value={getCommonResult.filter(function (option) {
                                                                                        return option.value === comment.Result;
                                                                                    })}

                                                                                ></Reactselect>
                                                                            </td>
                                                                            <td><input type="text" className="form-control" name={"_txtRemarks"}
                                                                                id={index + "_txtRemarks"}
                                                                                value={comment.FindingsRemarks}
                                                                                autoComplete="off"
                                                                                maxLength="200"
                                                                                disabled={props.location.state.params.Action === 'View' ? true : false}
                                                                                style={{ border: submitted && (comment.Result === 2 && comment.FindingsRemarks === '') ? '1px solid red' : '' }}
                                                                                onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')}
                                                                            /></td>
                                                                        </tr>
                                                                    </Fragment>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>
            {loader}
        </Fragment >
    )
};
export default FinalInspectionMeasurement;