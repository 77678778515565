import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Fabric from './TestingInfo/Fabric'
import Garment from './TestingInfo/Garment'
import StrikeOff from './TestingInfo/StrikeOff'
import Trim from './TestingInfo/Trim'
import Yarn from './TestingInfo/Yarn'
import TNAService from "../../../../services/TNA/TNAService";
import FabricTesti from './TestingInfo/FabricTesti';
import useFullPageLoader from '../../../../hooks/useFullPageLoader';
import { error } from 'jquery';
import YarnTest from './TestingInfo/YarnTest';
import GarmentTest from './TestingInfo/GarmentTest';
import FabricTest from './TestingInfo/FabricTest';
import TrimTest from './TestingInfo/TrimTest';

const TestingInformation = ({ props, TestingInfo, labremarkDetail, Labcomments, Labremark, Testingdetails, rerenderdep, UOMDetailsList, Detailwashinfocall, Detailwashsubcall, SpiralDetailwashsubcall }) => {

     const [getAllInfo, setAllInfo] = useState([]);
     const [getFabricInfo, setFabricInfo] = useState([]);
     const [getFabricDetail, SetFabricDetail] = useState([]);
     const [getTestingDetails, setTestingDetails] = useState([]);
     const [loader, showLoader, hideLoader] = useFullPageLoader();
     const [getTestingChildDetails, setTestingChildDetails] = useState([]);

     const [getlabandRemarks, setlabandRemarks] = useState([]);


     const [childData, setChildData] = useState({
          getPHInfo: [],
          getRubbingInfo: [],
          getWashingInfo: [],
          getAlkalineInfo: [],
          getAcidInfo: []
     });

     const [GarmentchildData, setGarmentChildData] = useState({
          getStabilityInfo: [],
          getSpiralityInfo: [],
          getAppearanceInfo: [],
     });

     const [TrimchildData, setTrimChildData] = useState({
          getAttachmentInfo: [],
     });


     const [FabricchildData, setFabricChildData] = useState({
          getUnitInfo: [],
          getSpiralityInfo: [],
          getStablityInfo: [],
          getPillingInfo: [],
          getWaterInfo: [],
          getPHInfo: [],
          getRubbingInfo: [],
          getWashingInfo: [],
          getAlkalineInfo: [],
          getAcidInfo: []
     });







     useEffect(() => {
          if (props.location.state.params.multiselecteddatas.length > 0) {
               showLoader();
               let StyleIDs = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).map((data => { return data.styleID })).join(",");
               let TestType = 0;
               if (props.location.state.params.TaskType === "FABRIC TEST") {
                    TestType = 1;
               }
               if (props.location.state.params.TaskType === "YARN TEST") {
                    TestType = 2;
               }
               else if (props.location.state.params.TaskType === "GARMENT TEST") {
                    TestType = 3;
               }
               else if (props.location.state.params.TaskType === "STRIKE OFF TEST") {
                    TestType = 4;
               }
               else if (props.location.state.params.TaskType === "TRIMS TEST") {
                    TestType = 5;
               }
               TNAService.GetTestInfoDetails(props.location.state.params.buyerID, props.location.state.params.brandID, TestType, StyleIDs).then((response) => {
                    setTestingDetails(response.data.item1);
                    setTestingChildDetails(response.data.item2);
                    hideLoader();
               }, (error => {
                    hideLoader();
               }))
          }

     }, []);



     const handleReceiveDataFromFabricChild = (data) => {
          debugger;
          setFabricChildData(data);
          let FabricData = [];

          // Dynamically iterate over each item in the data array and push the relevant information
          data.forEach((item) => {
               if (item.getUnitInfo) Array.prototype.push.apply(FabricData, item.getUnitInfo);
               if (item.getSpiralityInfo) Array.prototype.push.apply(FabricData, item.getSpiralityInfo);
               if (item.getStablityInfo) Array.prototype.push.apply(FabricData, item.getStablityInfo);
               if (item.getPillingInfo) Array.prototype.push.apply(FabricData, item.getPillingInfo);
               if (item.getWaterInfo) Array.prototype.push.apply(FabricData, item.getWaterInfo);
               if (item.getPHInfo) Array.prototype.push.apply(FabricData, item.getPHInfo);
               if (item.getRubbingInfo) Array.prototype.push.apply(FabricData, item.getRubbingInfo);
               if (item.getWashingInfo) Array.prototype.push.apply(FabricData, item.getWashingInfo);
               if (item.getAlkalineInfo) Array.prototype.push.apply(FabricData, item.getAlkalineInfo);
               if (item.getAcidInfo) Array.prototype.push.apply(FabricData, item.getAcidInfo);
               //if (item.getlabandRemarks) Array.prototype.push.apply(FabricData, item.getlabandRemarks);
          });

          // Processing FabricData elements to ensure correct data types
          FabricData.forEach((element) => {
               element.ReqGSM = element.ReqGSM ? element.ReqGSM.toString() : "";
               element.foundGSM = element.foundGSM ? element.foundGSM.toString() : "";
               element.genderID = element.genderID ? element.genderID.toString() : "";
          });

          // Calling TestingInfo with processed data
          TestingInfo(FabricData);
     };

     // Callback function to receive the state object from the child component
     const handleReceiveDataFromChild = (data) => {
          setChildData(data);
          let YarnData = [];
          data.forEach((item) => {
               if (item.getPHInfo) Array.prototype.push.apply(YarnData, item.getPHInfo);
               if (item.getRubbingInfo) Array.prototype.push.apply(YarnData, item.getRubbingInfo);
               if (item.getWashingInfo) Array.prototype.push.apply(YarnData, item.getWashingInfo);
               if (item.getAlkalineInfo) Array.prototype.push.apply(YarnData, item.getAlkalineInfo);
               if (item.getAcidInfo) Array.prototype.push.apply(YarnData, item.getAcidInfo);
               if (item.getWaterInfo) Array.prototype.push.apply(YarnData, item.getWaterInfo);
          })
          YarnData.forEach(element => {
               element.afterWash === "" ? element.afterWash = 0 : element.afterWash = element.afterWash;
               element.foundGSM = element.foundGSM.toString();
               element.genderID = element.genderID.toString()
          });
          TestingInfo(YarnData);
     };

     const handleReceiveDataFromStrikeOff = (data) => {
          debugger
          data.forEach(element => {
               element.afterWash == "" ? element.afterWash = 0 : element.afterWash = element.afterWash;
               element.foundGSM = element.foundGSM.toString();
               element.genderID = element.genderID.toString()
          });
          TestingInfo(data);
     };

     // Callback function to receive the state object from the Garment component
     const handleReceiveDataFromGarmentChild = (data) => {
          debugger;
          setGarmentChildData(data);
          let GarmentData = [];
          let AfterWashData = [];
          // Assuming 'data' is an array of objects and 'GarmentData' is an array to which we want to push the processed data.

          data.forEach((item) => {
               // Check and push stability info
               if (item.getStabilityInfo) {
                    Array.prototype.push.apply(GarmentData, item.getStabilityInfo);
               }

               // Check and push spirality info
               if (item.getSpiralityInfo) {
                    Array.prototype.push.apply(GarmentData, item.getSpiralityInfo);
               }

               // Check and process appearance info
               if (item.getAppearanceInfo) {
                    item.getAppearanceInfo.forEach((ele, index) => {
                         const AfterWashRegex = /^[A-Z][A-Z][0-9]+$/;

                         // Filter and reduce to get all current series
                         const GetAllCurrentSeries = Object.keys(ele).filter((k) => k.indexOf("AW") === 0).reduce((newData, k) => {
                              newData[k] = ele[k];
                              return newData;
                         }, {});

                         const MatchedAfterWashes = [];

                         // Match and push after wash data
                         for (const key in GetAllCurrentSeries) {
                              if (AfterWashRegex.test(key)) {
                                   MatchedAfterWashes.push({ [key]: GetAllCurrentSeries[key] });
                              }
                         }

                         if (MatchedAfterWashes.length > 0) {
                              MatchedAfterWashes.forEach((_, k) => {
                                   AfterWashData.push({
                                        AfterWashType: ele.WashHeadID,
                                        WashTypeValue: GetAllCurrentSeries[`AW${k + 1}`],
                                        TestID: ele.testID,
                                        TestTypeID: ele.testTypeID,
                                        TestName: ele.testName,
                                        FieldID: ele.fieldID,
                                        FieldName: ele.fieldName
                                   });
                              });
                         }
                    });
               }
          });

          data.forEach((item) => {
               if (item.getStabilityInfo) Array.prototype.push.apply(GarmentData, item.getStabilityInfo);
               if (item.getSpiralityInfo) Array.prototype.push.apply(GarmentData, data.getSpiralityInfo);
               if (item.getAppearanceInfo) Array.prototype.push.apply(GarmentData, data.getAppearanceInfo);
          })
          GarmentData.forEach(element => {
               //element.beforeWash == "" ? element.beforeWash = 0 : element.beforeWash = element.beforeWash;
               //element.afterWash == "" ? element.afterWash = 0 : element.afterWash = element.afterWash;
               element.foundGSM = element.foundGSM.toString();
               element.genderID = element.genderID.toString()
          });
          ;
          // GarmentData.DetailedWashCountInfo = data.getAppearanceInfo.WashCount.map((data, index) => {
          //      return { IndexID: index, AfterWashType: data.WashValue, TestTypeID: data.TestTypeID, TestID: data.TestID };
          // });

          GarmentData.DetailedAfterWashDetail = AfterWashData;
          GarmentData.AfterWashDetails = "";
          TestingInfo(GarmentData);
     };

     // Callback function to receive the state object from the Trim component
     const handleReceiveDataFromTrimChild = (data) => {
          setTrimChildData(data);
          let TrimData = [];
          data.forEach((item) => {
               if (item.getAttachmentInfo) Array.prototype.push.apply(TrimData, item.getAttachmentInfo);
          })

          TrimData.forEach(element => {
               element.afterWash == "" ? element.afterWash = 0 : element.afterWash = element.afterWash;
               element.foundGSM = element.foundGSM.toString();
               element.genderID = element.genderID.toString()
          });
          TestingInfo(TrimData);
     };
     // Callback function to receive the state object from the Trim component
     const handlelabdetails = (data) => {
          labremarkDetail(data);

     };

     const handleGarmentlabdetails = (data) => {
          labremarkDetail(data);

     };

     const handlefabriclabdetails = (data) => {
          debugger
          labremarkDetail(data);

     };

     const handleTrimlabdetails = (data) => {
          labremarkDetail(data);

     };

     const handleStrikeOfflabdetails = (data) => {
          labremarkDetail(data);

     };


     const handleUomGarment = (data) => {
          let GarmentUOM = [];
          Array.prototype.push.apply(GarmentUOM, data.getUOMLabel);
          Array.prototype.push.apply(GarmentUOM, data.getSpiUOMLabel);
          //Array.prototype.push.apply(GarmentUOM, data.getWashingInfo);
          UOMDetailsList(GarmentUOM);

     };


     const handleUomFabric = (data) => {

          let FabricUOM = [];
          Array.prototype.push.apply(FabricUOM, data.getUOMLabel);
          Array.prototype.push.apply(FabricUOM, data.getSpiUOMLabel);
          //Array.prototype.push.apply(FabricUOM, data.getWashingInfo);
          UOMDetailsList(FabricUOM);

     };

     const Handledetailwash = (data) => {
          Detailwashinfocall(data)
     }
     const Handledetailwashsub = (data) => {

          Detailwashsubcall(data)
     }

     const SpiralHandledetailwashsub = (data) => {
          SpiralDetailwashsubcall(data)
     }

     return (
          <div>
               {
                    props.location.state.params.TaskType === "FABRIC TEST" ? <FabricTest props={props} FabricUOMDetails={handleUomFabric} FabricDetials={handleReceiveDataFromFabricChild} labdata={Labcomments} labremarkdata={Labremark} Testingdetail={Testingdetails}
                         FabriclabDetails={handlefabriclabdetails} DependencyReload={rerenderdep} Detailwashchildcall={Handledetailwash} Detailwashinfosubcall={Handledetailwashsub} /> :
                         props.location.state.params.TaskType === "GARMENT TEST" ? <GarmentTest props={props} UOMDetails={handleUomGarment} GarmentDetails={handleReceiveDataFromGarmentChild} GarmentlabDetails={handleGarmentlabdetails} labdata={Labcomments} labremarkdata={Labremark} Testingdetail={Testingdetails} DependencyReload={rerenderdep}
                              Detailwashchildcall={Handledetailwash} Detailwashinfosubcall={Handledetailwashsub}
                              SpiralDetailwashinfosubcall={SpiralHandledetailwashsub} /> :
                              props.location.state.params.TaskType === "Strike off Test" ? <StrikeOff props={props} StrikeOffDetails={handleReceiveDataFromStrikeOff} labdata={Labcomments} labremarkdata={Labremark} Testingdetail={Testingdetails}
                                   StrikeOffLabDetails={handleStrikeOfflabdetails} DependencyReload={rerenderdep} Detailwashchildcall={Handledetailwash} Detailwashinfosubcall={Handledetailwashsub} /> :
                                   props.location.state.params.TaskType === "Trims Test" ? <TrimTest props={props} TrimDetails={handleReceiveDataFromTrimChild} TrimlabDetails={handleTrimlabdetails} labdata={Labcomments} labremarkdata={Labremark} Testingdetail={Testingdetails} DependencyReload={rerenderdep} /> :
                                        props.location.state.params.TaskType === "YARN TEST" ? <YarnTest props={props} YarnDetails={handleReceiveDataFromChild} labDetails={handlelabdetails} labdata={Labcomments} labremarkdata={Labremark} Testingdetail={Testingdetails}  DependencyReload={rerenderdep} /> : ''
               }
               {loader}
          </div>
     )
}

export default TestingInformation