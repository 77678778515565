import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Reactselect from 'react-select';
import UploadImage from "../../../Common/UploadImage";
import { loadCubeBuyerSupplierEmp } from "../../../../actions/common";
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import { event } from "jquery";
import TNAService from "../../../../services/TNA/TNAService";
import { Modal, Button, ModalBody } from "react-bootstrap";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import Nodify from "../../../Common/ReactNotification";
import Confirmavailableinfo from '../../CommonInspec/confimavailable'
import StyleAndColors from '../../CommonInspec/StyleAndColors'
import WorkmanshipTemp from '../../CommonInspec/FinalWorkmanshiptemp'
import TestReportCheck from '../../CommonInspec/TestReportCheck'
import ReportService from "../../../../services/Report/ReportService";
import ArtWorks from '../../CommonInspec/artWorks'
import Trims from '../../CommonInspec/FinalInsTrim'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Csr from "../../CommonInspec/Csr";
import Packing from "../../CommonInspec/Packing";
import AQLList from '../../../../services/Master/AqlService';
import DatePicker from "react-datepicker";
import { Label } from "reactstrap";
import Lightbox from "react-image-lightbox";
import $ from "jquery";
import axios from "axios";
import { hideAll } from "bootbox";
import { parse } from "node-xlsx";
const FinalInspectionMainInformation = ({ props, BasicDetails, Inspectioncallback, MainInfoMation, Quantityinfo, QuantitySubInfo, Confomationinfo
    , Stylecolor, ArtworkInfo, TrimInfo, Workmainshipinfo, TestRepotCheck, CSRInfo, Imagevalue, totalvalue, MajorminorLvl, InspectionID, IsValidationCallback,
    Savevalidationcallback, PackingClback, RefreshState, ExternalFiles, rerenderdep }) => {
    const [getMainInputField, setMainInputField] = useState({
        FinalInspectionID: 0, BuyerFID: 0, BrandFID: 0, SeasonFID: 0, SupplierFID: 0, FactoryLocationID: 0,
        MajorMinAQLID: 0, MajorMinorAql: '',
        FactoryLocation: '', StyleName: '', InspectionStandardID: 0, InspectionStandard: '', InspectionLevelID: 0, InspectionLevel: ''
        , AQLLevelID: 0, AQLLevel: '', CustomerShipmentRefNo: '', ReportDate: '', PreviousInspectionReports: '',
        ExAqlResultID: 0, ExAqlResult: '',
        ExRemarks: '', ExReportNo: '', ExAgencyName: '', ExInspectorConclusion: '', ReportType: 1
    });
    const [getSupplierOptions, setSupplierOptions] = useState([{ id: 0, name: '', label: '' }]);

    const [getExAQLResult, setExAQLResult] = useState([
        { value: 1, label: 'Within AQL' },
        { value: 2, label: 'Beyond AQL' },
    ]);

    const [Buttonview, SetButtonview] = useState("Hide");
    const EmptyInputFields =
        [{
            FileName: '',
            FilePath: '',
            FileType: '',
        }]
    const [getFiles, setFiles] = useState([]);
    const [getPage, setPage] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getStyleNameOptions, setStyleNameOptions] = useState([]);
    const [getInspectionLevelDP, setInspectionLevelDP] = useState([]);
    const [getloadAqlLevel, setloadAqlLevel] = useState([]);
    const [getpurchaseOrderskuid, setpurchaseOrderskuid] = useState();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getInspectionQtyInfo, setInspectionQtyInfo] = useState([]);
    const [getFinalInspectionPopup, setFinalInspectionPopup] = useState(false);

    const [getPreInsRepPopup, setPreInsRepPopup] = useState(false);
    const [getprevreportQtyInfo, setprevreportQtyInfo] = useState([]);
    const [getWarehousePopup, setWarehousePopup] = useState(false);
    const [getnewwarehousedata, setnewwarehousedata] = useState([]);

    const [selectall, setselectall] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [getInspectionResult, setInspectionResult] = useState([]);
    const [getMainInfoBasicDetails, setMainInfoBasicDetails] = useState([]);
    const MainInfoDetails = { BuyerName: '', SeasonName: '', BrandName: '' }
    const [getMainInfo, setMainInfo] = useState([MainInfoDetails]);
    const [getTotalValues, setTotalValues] = useState({ PoQty: 0, offqty: 0, offerper: 0 });
    const [getCurrimageindex, setCurrimageindex] = useState(0);
    const [getnewimagevalue, setnewimagevalue] = useState([]);
    const [getartWorksexfacValue, setartWorksexfacValue] = useState([]);
    const [gettrimExfacValue, settrimExfacValue] = useState([]);
    const [getExFactoryList, setExFactoryList] = useState([])
    const [gettolerance, settolerance] = useState(0);
    const [getCommonResult, setCommonResult] = useState([]);
    const [getconfirmationResult, setconfirmationResult] = useState([]);
    const [getAqlDetails, setAqlDetails] = useState([]);
    const [getInspectionID, setInspectionID] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [checkorderdetails, setcheckorderdetails] = useState(0);
    const [getQuantysubDetails, setQuantysubDetails] = useState({
        TNAFinalQualityinfoDetailsID: 0,
        SamplingSize: '',
        quantityavailable: '',
        cartonbox: '',
        inspectedCarton: '',
        SampleInspectedPieces: '',
        Result: '',
        FindingsRemarks: ''
    });

    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;

    const [AllDataLoaded, setAllDataLoaded] = useState(false);

    useEffect(() => {
        setSubmitted(IsValidationCallback);
    }, [IsValidationCallback === true]);

    // useEffect(() => {
    //     setSubmittedQnt(Savevalidationcallback);
    // }, [Savevalidationcallback === true]);

    const customStyles = {
        option: (styles, { data }) => {
            return {
                ...styles,
                color: data.value === 1 ? 'green' : data.value === 2 ? "Red" : "Blue"
            };
        }
    }

    const inpsectionId = JSON.parse(localStorage.getItem("InspectionID"));
    useEffect(() => {
        //showLoader();
        if (props.location.state.params.ParentTask !== "TNAEdit" && props.location.state.params.ParentTask !== "TNAView" &&
            props.location.state.params.ParentTask !== "TNAReport" && props.location.state.params.ParentTask !== "ReInspection") {
            BasicDetails.buyerName = BasicDetails.NewDetails.buyerName
            BasicDetails.seasonName = BasicDetails.NewDetails.seasonName
            BasicDetails.brandName = BasicDetails.NewDetails.brandName
            BasicDetails.buyerID = BasicDetails.NewDetails.buyerID
            BasicDetails.brandID = BasicDetails.NewDetails.brandID
            BasicDetails.seasonID = BasicDetails.NewDetails.seasonID
            BasicDetails.supplierID = BasicDetails.NewDetails.supplierID
            BasicDetails.supplierName = BasicDetails.NewDetails.supplierName
            BasicDetails.styleID = BasicDetails.NewDetails.styleID
            BasicDetails.styleName = BasicDetails.NewDetails.styleName

        }
        if (props.location.state.params.ParentTask === "TNAEdit" || props.location.state.params.ParentTask === "TNAView" ||
            props.location.state.params.ParentTask === "TNAReport" || props.location.state.params.ParentTask === "ReInspection") {
            BasicDetails.supplierID = BasicDetails.details.supplierId
            BasicDetails.supplierName = BasicDetails.details.supplierName
        }
        if (BasicDetails) {
            setMainInfo({
                BuyerName: BasicDetails.buyerName
                , SeasonName: BasicDetails.seasonName
                , BrandName: BasicDetails.brandName
                , BuyerID: BasicDetails.buyerID
                , BrandID: BasicDetails.brandID
                , SeasonID: BasicDetails.seasonID
                , SupplierID: BasicDetails.details.supplierId
                , SupplierName: BasicDetails.details.supplierName
            });
            // settolerance(50);
            setSupplierOptions([{ value: BasicDetails.supplierID, label: BasicDetails.supplierName, name: BasicDetails.supplierName }]);
            setStyleNameOptions([{ id: BasicDetails.styleID, label: BasicDetails.styleName, name: BasicDetails.styleName }]);
        }
        (async () => {
            if (BasicDetails.TaskStatus === 2) {
                ;
                showLoader();
                await FinalinspectionService.GetTNAFinalinspecMainInfo(BasicDetails.TNAInspectionID, BasicDetails.details.tnaid).then(async (res) => {
                    await res.data.finalInsMainList.map(x => {
                        getMainInputField.FactoryLocationID = x.factoryLocationID
                        getMainInputField.FactoryLocation = x.factoryLocation
                        getMainInputField.InspectionStandardID = x.inspectionStandard
                        getMainInputField.InspectionLevel = x.inspectionLevelName
                        getMainInputField.InspectionLevelID = x.inspectionLevel
                        getMainInputField.AQLLevel = x.aqlLevelName
                        getMainInputField.AQLLevelID = x.aqlLevel
                        getMainInputField.ReportDate = new Date(x.reportDate)
                        getMainInputField.CustomerShipmentRefNo = x.customerShipmentRefNo
                        getMainInputField.ExAgencyName = x.exAgencyName
                        getMainInputField.ExReportNo = x.exReportNo
                        // getFiles[0].FileType = x.exFileType
                        // getFiles[0].FileName = x.exFileName
                        // getFiles[0].FilePath = x.exFilePath
                        getMainInputField.ExAqlResultID = x.exAqlResultID
                        getMainInputField.ExAqlResult = x.exAqlResult
                        getMainInputField.ExRemarks = x.exRemarks
                        getMainInputField.ExInspectorConclusion = x.exInspectorConclusion
                        if (x.reportType === 2) {
                            getMainInputField.ReportType = 2;
                        }

                    })

                    let ExternalData = [];
                    await res.data.finalExternalfileList.map(x => {
                        ExternalData.push({
                            ExternalFileupID: x.externalFileupID,
                            FileName: x.fileName,
                            Filepath: x.filepath,
                            FileType: x.fileType
                        })
                    })

                    setFiles(ExternalData)
                    ExternalFiles(ExternalData)
                    setMainInputField(getMainInputField)
                    Inspectioncallback(props.location.state.params.TNAInspectionID)
                    MainInfoMation(getMainInputField)
                    let newvalue = [];
                    let POSkuIDForPacImage = "";
                    let POSkuIDSample = "";
                    await res.data.finalInsQuantityInfoList.map(x => {
                        newvalue.push({
                            TNAFinalinspcQualityinfoID: x.tnaFinalinspcQualityinfoID,
                            skuID: x.skuID,
                            purchaseOrderSkuID: x.purchaseOrderSkuID,
                            styleNo: x.styleName,
                            tnaExFactoryId: x.subTaskID,
                            skuName: x.skuName,
                            endCustomer: x.endCustomer,
                            idNo: x.idno,
                            isSelected: 1,
                            Tolerance: x.tolerance,
                            orderQuantity: x.poQty,
                            PoandbalanceQty: parseInt(x.poQty) + parseInt(x.tolerance),
                            balanceQuantity: parseInt(x.balanceQuantity),
                            offerqty: x.offeredQty,
                            OfferPercentage: x.offeredpercent,
                            existqty: x.offeredQty,
                            //existofferqty: x.offeredQty,
                            duplofferqty: x.offeredQty,
                            isclosed: x.isClosed,
                            actualBalQty: x.actualBalQty,
                            totofferQty: x.totofferQty,
                            IsDeleted: 0
                        })

                        if (POSkuIDForPacImage === '') {
                            //&& POSkuIDSample === ''
                            POSkuIDForPacImage = x.purchaseOrderSkuID;
                            //POSkuIDSample = x.skuID;
                        } else {
                            POSkuIDForPacImage = POSkuIDForPacImage + ',' + x.purchaseOrderSkuID
                            //POSkuIDSample = POSkuIDSample + ',' + x.skuID

                        }
                    })
                    //props.location.state.params.multiselecteddatas = newvalue;
                    props.location.state.params.PurchaseOrderSkuID = POSkuIDForPacImage;
                    props.location.state.params.SkuID = POSkuIDSample

                    Quantityinfo(newvalue)
                    setInspectionQtyInfo(newvalue)
                    MainInfoCommonCalculation(newvalue)
                    //setAllDataLoaded(false);
                    await res.data.finalInsMainBasicList.map((x) => {

                        getQuantysubDetails.TNAFinalQualityinfoDetailsID = x.tnaFinalQualityinfoDetailsID
                        getQuantysubDetails.SamplingSize = (x.samplingSize !== undefined && x.samplingSize !== null) ? x.samplingSize.toString() : ''
                        getQuantysubDetails.quantityavailable = x.quantityAvailableInspection === 0 ? '' : x.quantityAvailableInspection
                        getQuantysubDetails.cartonbox = x.totalCartonBox === 0 ? '' : x.totalCartonBox
                        getQuantysubDetails.inspectedCarton = x.inspectedCartonBox === 0 ? '' : x.inspectedCartonBox
                        getQuantysubDetails.SampleInspectedPieces = x.sampleInspectedPieces
                        getQuantysubDetails.Result = x.result
                        getQuantysubDetails.FindingsRemarks = x.findingsRemarks
                    })
                    setQuantysubDetails(getQuantysubDetails);
                    QuantitySubInfo(getQuantysubDetails)

                    let newvalueSku = [];
                    await res.data.finalInsSkuImageList.map((x) => {
                        newvalueSku.push({
                            TNAFinalInspectionSkuID: x.tnaFinalInspectionSkuID,
                            skuName: x.skuFileName,
                            skuFrontPicPath: x.skuFilePath
                        })
                    })
                    Imagevalue(newvalueSku)
                    setnewimagevalue(newvalueSku)
                    //();

                    // await res.data.finalInsMainBasicList.map(x => {
                    //     getQuantysubDetails.TNAFinalQualityinfoDetailsID = x.tnaFinalQualityinfoDetailsID
                    //     getQuantysubDetails.SamplingSize = x.samplingSize.toString()
                    //     getQuantysubDetails.quantityavailable = x.quantityAvailableInspection === 0 ? '' : x.quantityAvailableInspection
                    //     getQuantysubDetails.cartonbox = x.totalCartonBox === 0 ? '' : x.totalCartonBox
                    //     getQuantysubDetails.inspectedCarton = x.inspectedCartonBox === 0 ? '' : x.inspectedCartonBox
                    //     getQuantysubDetails.SampleInspectedPieces = x.sampleInspectedPieces
                    //     getQuantysubDetails.Result = x.result
                    //     getQuantysubDetails.FindingsRemarks = x.findingsRemarks
                    // })

                    // await res.data.finalInsSkuImageList.map(x => {
                    //     getnewimagevalue.push({
                    //         TNAFinalInspectionSkuID: x.tnaFinalInspectionSkuID,
                    //         skuName: x.skuFileName,
                    //         skuFrontPicPath: x.skuFilePath
                    //     })
                    // })
                    // Imagevalue(getnewimagevalue)
                    // QuantitySubInfo(getQuantysubDetails)
                    // setnewimagevalue(getnewimagevalue)
                    //seteditconfirmdata(res.data.finalInsConfirmAvailList)
                    hideLoader();
                })
                let InspecAqlLevel = [];
                let AqlLevel = [];
                let majonlvl = [];

                await FinalinspectionService.GetFinalInspectionMainInformation(2, getMainInputField.InspectionStandardID, BasicDetails.buyerID).then((res) => {
                    if (res.data) {
                        res.data.subInspecstandard.map((x) => {
                            InspecAqlLevel.push({
                                value: x.inspectionLevelID,
                                label: x.inspectionLevel
                            })
                            AqlLevel.push({
                                value: x.majorMinAQLID,
                                label: x.majorMinorAql
                            })
                            //     majonlvl.push({
                            //         Major: x.majorAQL,
                            //         Minor: x.minorAQL
                            //     })
                        })
                        // MajorminorLvl(majonlvl)
                        setloadAqlLevel(AqlLevel)
                        setInspectionLevelDP(InspecAqlLevel);
                    }
                })
                //();
            }
            let AqlList = [];
            await AQLList.getAllAqlList(0).then(async (res) => {
                if (res.data) {
                    res.data.map((x) => {
                        AqlList.push({
                            InspectionLevel: x.inspectionLevel,
                            LotSizeFromm: parseInt(x.lotSizeFromm),
                            LotSizeTo: parseInt(x.lotSizeTo),
                            SampleSize: parseInt(x.sampleSize),
                            AQL: parseInt(x.aql),
                            Accept: parseInt(x.accept),
                        })
                    })
                    setAqlDetails(AqlList);
                }
            })

            let Exfaclist = [];
            let InspectionStandard = [];
            let Multiselecteddate = [];
            await FinalinspectionService.GetFinalInspectionMainInformation(2, 0, BasicDetails.buyerID).then((res) => {
                if (res.data) {
                    res.data.subInspecstandard.map((x) => (
                        getMainInputField.InspectionLevelID = x.inspectionLevelID,
                        getMainInputField.InspectionLevel = x.inspectionLevel,
                        getMainInputField.MajorMinAQLID = x.majorMinAQLID,
                        getMainInputField.MajorMinorAql = x.majorMinorAql,
                        getMainInputField.InspectionStandardID = x.aqlInspectionID,
                        getMainInputField.InspectionStandard = x.inspectionStandard
                    ))
                    setMainInputField(getMainInputField)
                }
            })
            await FinalinspectionService.GetFinalInspectionMainInformation(3, getMainInputField.InspectionStandardID, BasicDetails.buyerID).then((res2) => {

                if (res2.data.inspectionstandardMajor) {
                    res2.data.inspectionstandardMajor.map((x) => {
                        majonlvl.push({
                            Major: x.majorAQLID,
                            Minor: x.minorAQLID
                        })
                    })
                    MajorminorLvl(majonlvl)
                }
            })

            await FinalinspectionService.GetTNAFinalinspecTestingReportList(4, BasicDetails.buyerID, BasicDetails.brandID).then((res) => {
                if (res.data.length > 0) {
                    BasicDetails.multiselecteddatas.map((element) => {
                        element.PoandbalanceQty = Math.round((res.data[0].offerQty / 100) * parseInt(element.balanceQuantity) + parseInt(element.orderQuantity))
                    })
                } else {
                    BasicDetails.multiselecteddatas.map((element) => {
                        element.PoandbalanceQty = Math.round((0 / 100) * parseInt(element.balanceQuantity) + element.orderQuantity)
                    })
                }
            })
            //if (inpsectionId === null && BasicDetails.TaskStatus === 1) {
            FinalinspectionService.GetTNAFinalinspecTestingReportList(4, BasicDetails.buyerID, BasicDetails.brandID).then((res) => {
                if (res.data.length > 0) {
                    BasicDetails.multiselecteddatas.map((element) => {
                        //element.orderQuantity = (gettolerance / 100) * element.orderQuantity;
                        if (element.isDraft === 0) {
                            element.balanceQuantity = Math.round((res.data[0].offerQty / 100) * parseInt(element.balanceQuantity) + element.orderQuantity);
                        }
                        element.Tolerance = Math.round((res.data[0].offerQty / 100) * element.orderQuantity)
                    })
                    settolerance(res.data[0].offerQty)
                    //BasicDetails.multiselecteddatas.map((element) => { element.PoandbalanceQty = gettolerance + element.orderQuantity })
                } else {
                    BasicDetails.multiselecteddatas.map((element) => {
                        //element.orderQuantity = (gettolerance / 100) * element.orderQuantity;
                        if (element.isDraft === 0) {
                            element.balanceQuantity = Math.round((0 / 100) * parseInt(element.balanceQuantity) + element.orderQuantity)
                            //element.PoandbalanceQty = Math.round((0 / 100) * parseInt(element.balanceQuantity) + element.orderQuantity)
                        }
                        element.Tolerance = Math.round((0 / 100) * element.orderQuantity)

                    })
                    settolerance(0)
                }


            })
            //}



            if (inpsectionId === null) {
                //For Show List OrderBy Selected Checkbox record
                if (BasicDetails.TaskStatus === 1) {
                    ;
                    if (InspectionID === 0) {
                        Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
                        let sizeandqtyfordisplay = "";
                        Multiselecteddate.map((Purchaseorder) => {
                            Purchaseorder.IsDeleted = 0;
                            if (sizeandqtyfordisplay === '') {
                                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                            } else {
                                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                            }
                        })


                        // BasicDetails.multiselecteddatas.map((element) => {
                        //     //element.orderQuantity = (gettolerance / 100) * element.orderQuantity;
                        //     element.balanceQuantity = Math.round((gettolerance / 100) * parseInt(element.balanceQuantity) + element.orderQuantity)
                        //     element.Tolerance = Math.round((gettolerance / 100))
                        // })
                        setpurchaseOrderskuid(sizeandqtyfordisplay)
                        setInspectionQtyInfo(Multiselecteddate)
                        Quantityinfo(Multiselecteddate)
                    }
                    setCurrimageindex(0);
                    ;
                    var uniqueimages = [...new Map(Multiselecteddate.map(item =>
                        [item['skuName'], item])).values()];
                    setnewimagevalue(uniqueimages)
                    Imagevalue(uniqueimages)
                    hideLoader();
                }
            }
            //Factory/Location

            await ReportService.GetCommentsReportExFactory(BasicDetails.supplierID, 1, 0).then((res) => {
                if (res.data) {
                    res.data.exFacCommon.map((x, index) => {
                        Exfaclist.push({
                            value: x.value,
                            label: x.label
                        })
                    })
                    setExFactoryList(Exfaclist);
                }
            })
            //----------------------------------
            let Result = [];
            await FinalinspectionService.getResultList(0).then((res) => {
                if (res.data) {
                    // res.data.map((x) => {
                    //     InspectionResult.push({
                    //         value: x.resultID,
                    //         label: x.resultName
                    //     })
                    // });
                    res.data.map((element) => {
                        let LabelColor = '';
                        if (element.resultID === 1) {
                            LabelColor = <><span style={{ color: "green" }}><b>Within AQL</b></span></>
                        }
                        else if (element.resultID === 2) {
                            LabelColor = <><span style={{ color: "Red" }}><b>Beyond AQL</b></span></>
                        }

                        Result.push({
                            value: element.resultID,
                            label: LabelColor,
                            subLabel: element.resultName,
                        })
                    })
                    setExAQLResult(Result);
                }
            })
            //------------xxxxx-----------------
            let InspectionResult = [];
            await FinalinspectionService.getResultList(0).then((res) => {
                if (res.data) {
                    // res.data.map((x) => {
                    //     InspectionResult.push({
                    //         value: x.resultID,
                    //         label: x.resultName
                    //     })
                    // });
                    res.data.map((element) => {
                        let LabelColor = '';
                        if (element.resultID === 1) {
                            LabelColor = <><span style={{ color: "green" }}><b>Ok</b></span></>
                        }
                        else if (element.resultID === 2) {
                            LabelColor = <><span style={{ color: "Red" }}><b>NOT OK</b></span></>
                        }

                        InspectionResult.push({
                            value: element.resultID,
                            label: LabelColor,
                            subLabel: element.resultName,
                        })
                    })





                    setInspectionResult(InspectionResult);
                }
            })
            let CommonResult = [];
            await FinalinspectionService.getCommonResultList(0).then((res) => {
                if (res.data) {
                    res.data.map((x) => {
                        CommonResult.push({
                            value: x.resultID,
                            label: x.resultName
                        })
                    })
                    setCommonResult(CommonResult);
                }
            })

            let ConfirmationResult = [];
            await FinalinspectionService.getConfrimationResultList(0).then((res) => {
                if (res.data) {
                    res.data.map((element) => {

                        let LabelColor = '';
                        if (element.resultID === 1) {
                            LabelColor = <><span style={{ color: "green" }}><b>Yes</b></span></>
                        }
                        else if (element.resultID === 2) {
                            LabelColor = <><span style={{ color: "Red" }}><b>No</b></span></>
                        }
                        else if (element.resultID === 3) {
                            LabelColor = <><span style={{ color: "blue" }}><b>Not Applicable</b></span></>
                        }

                        ConfirmationResult.push({
                            value: element.resultID,
                            label: LabelColor,
                            subLabel: element.value,
                        })
                    })
                    setconfirmationResult(ConfirmationResult);
                    // if (res.data) {
                    //     res.data.map((x) => {
                    //         ConfirmationResult.push({
                    //             value: x.resultID,
                    //             label: x.resultName
                    //         })
                    //     })
                    //
                }
            })



            if (inpsectionId === null) {
                if (Multiselecteddate.length > 0) {
                    setAllDataLoaded(true);
                }
            }
        })();

    }, [RefreshState === false])

    let InspecAqlLevel = [];
    let AqlLevel = [];
    let majonlvl = [];

    useEffect(() => {
        //showLoader();
        (async () => {

            if (inpsectionId) {
                if (inpsectionId !== null) {
                    setInspectionID(inpsectionId === null ? 0 : inpsectionId)
                }
                showLoader();
                await FinalinspectionService.GetTNAFinalinspecMainInfo(inpsectionId, BasicDetails.details.tnaid).then(async (res) => {

                    res.data.finalInsMainList.map(x => {
                        getMainInputField.FactoryLocationID = x.factoryLocationID
                        getMainInputField.FactoryLocation = x.factoryLocation
                        getMainInputField.InspectionStandardID = x.inspectionStandard
                        getMainInputField.InspectionStandard = x.inspectionStandardName
                        getMainInputField.InspectionLevel = x.inspectionLevelName
                        getMainInputField.InspectionLevelID = x.inspectionLevel
                        getMainInputField.AQLLevel = x.aqlLevelName
                        getMainInputField.AQLLevelID = x.aqlLevel
                        getMainInputField.ReportDate = new Date(x.reportDate)
                        getMainInputField.CustomerShipmentRefNo = x.customerShipmentRefNo
                        getMainInputField.ExAgencyName = x.exAgencyName
                        getMainInputField.ExReportNo = x.exReportNo
                        // getFiles[0].FileType = x.exFileType
                        // getFiles[0].FileName = x.exFileName
                        // getFiles[0].FilePath = x.exFilePath
                        getMainInputField.ExAqlResultID = x.exAqlResultID
                        getMainInputField.ExAqlResult = x.exAqlResult
                        getMainInputField.ExRemarks = x.exRemarks
                        getMainInputField.ExInspectorConclusion = x.exInspectorConclusion
                        if (x.reportType === 2) {
                            getMainInputField.ReportType = 2;
                        }

                    })

                    let getFiles = [];
                    await res.data.finalExternalfileList.map(x => {
                        getFiles.push({
                            ExternalFileupID: x.externalFileupID,
                            FileName: x.fileName,
                            Filepath: x.filepath,
                            FileType: x.fileType
                        })
                    })

                    setFiles(getFiles)
                    ExternalFiles(getFiles)

                    Inspectioncallback(res.data.finalInsMainList[0].tnaFinalInspectionID)
                    MainInfoMation(getMainInputField)
                    setMainInputField(getMainInputField)
                    let newvalue = [];
                    let POSkuIDForPacImage = "";
                    let POSkuIDSample = "";
                    await res.data.finalInsQuantityInfoList.map(x => {
                        newvalue.push({
                            TNAFinalinspcQualityinfoID: x.tnaFinalinspcQualityinfoID,
                            skuID: x.skuID,
                            purchaseOrderSkuID: x.purchaseOrderSkuID,
                            styleNo: x.styleName,
                            tnaExFactoryId: x.subTaskID,
                            skuName: x.skuName,
                            endCustomer: x.endCustomer,
                            idNo: x.idno,
                            isSelected: 1,
                            Tolerance: x.tolerance,
                            orderQuantity: x.poQty,
                            PoandbalanceQty: parseInt(x.poQty) + parseInt(x.tolerance),
                            balanceQuantity: x.balanceQuantity,
                            offerqty: x.offeredQty,
                            duplofferqty: x.offeredQty,
                            existofferqty: x.offeredQty,
                            OfferPercentage: x.offeredpercent,
                            IsDeleted: 0
                        })

                        if (POSkuIDForPacImage === '') {
                            //&& POSkuIDSample === ''
                            POSkuIDForPacImage = x.purchaseOrderSkuID;
                            //POSkuIDSample = x.skuID;
                        } else {
                            POSkuIDForPacImage = POSkuIDForPacImage + ',' + x.purchaseOrderSkuID
                            //POSkuIDSample = POSkuIDSample + ',' + x.skuID

                        }
                    })
                    //props.location.state.params.multiselecteddatas = newvalue;
                    props.location.state.params.PurchaseOrderSkuID = POSkuIDForPacImage;
                    /// props.location.state.params.SkuID = POSkuIDSample
                    props.location.state.params.multiselecteddatas = newvalue
                    setInspectionQtyInfo(newvalue)
                    MainInfoCommonCalculation(newvalue)
                    Quantityinfo(newvalue)
                    setAllDataLoaded(false);

                    await res.data.finalInsMainBasicList.map((x) => {
                        getQuantysubDetails.TNAFinalQualityinfoDetailsID = x.tnaFinalQualityinfoDetailsID
                        getQuantysubDetails.SamplingSize = (x.samplingSize !== undefined && x.samplingSize !== null) ? x.samplingSize.toString() : ''
                        getQuantysubDetails.quantityavailable = x.quantityAvailableInspection === 0 ? '' : x.quantityAvailableInspection
                        getQuantysubDetails.cartonbox = x.totalCartonBox === 0 ? '' : x.totalCartonBox
                        getQuantysubDetails.inspectedCarton = x.inspectedCartonBox === 0 ? '' : x.inspectedCartonBox
                        getQuantysubDetails.SampleInspectedPieces = x.sampleInspectedPieces
                        getQuantysubDetails.Result = x.result
                        getQuantysubDetails.FindingsRemarks = x.findingsRemarks
                    })
                    setQuantysubDetails(getQuantysubDetails);
                    QuantitySubInfo(getQuantysubDetails)
                    let newvalueSku = [];
                    await res.data.finalInsSkuImageList.map((x) => {
                        ;
                        newvalueSku.push({
                            TNAFinalInspectionSkuID: x.tnaFinalInspectionSkuID,
                            skuName: x.skuFileName,
                            skuFrontPicPath: x.skuFilePath
                        })
                    })
                    Imagevalue(newvalueSku)
                    setnewimagevalue(newvalueSku)
                    hideLoader();
                    //seteditconfirmdata(res.data.finalInsConfirmAvailList)
                })
                await FinalinspectionService.GetFinalInspectionMainInformation(2, getMainInputField.InspectionStandardID, BasicDetails.buyerID).then((res1) => {
                    if (res1.data) {
                        res1.data.subInspecstandard.map((x) => {
                            InspecAqlLevel.push({
                                value: x.inspectionLevelID,
                                label: x.inspectionLevel
                            })
                            AqlLevel.push({
                                value: x.majorMinAQLID,
                                label: x.majorMinorAql
                            })

                        })
                        setloadAqlLevel(AqlLevel)
                        setInspectionLevelDP(InspecAqlLevel);
                    }
                })
                await FinalinspectionService.GetFinalInspectionMainInformation(3, getMainInputField.InspectionStandardID, BasicDetails.buyerID).then((res2) => {

                    if (res2.data.inspectionstandardMajor) {
                        res2.data.inspectionstandardMajor.map((x) => {
                            majonlvl.push({
                                Major: x.majorAQLID,
                                Minor: x.minorAQLID
                            })
                        })
                        MajorminorLvl(majonlvl)
                    }
                })
            }

        })();
    }, [RefreshState === false])

    useEffect(() => {
        if (getInspectionQtyInfo.length > 0) {
            if (AllDataLoaded === true) {
                MainInfoCommonCalculation(getInspectionQtyInfo)
                setAllDataLoaded(false);
            }
        }
    }, [AllDataLoaded === true])


    const confirmavailinfovalue = (value) => {
        Confomationinfo(value)
    }

    const styleAndColorsCallback = (value) => {
        Stylecolor(value)
    }

    const WorkmanshipCallback = (value) => {
        Workmainshipinfo(value)
    }
    const ArtWorksCallback = (value) => {
        ArtworkInfo(value)
    }
    const FinalInsTrimCallback = (value) => {
        TrimInfo(value)
    }

    const TestReportCheckCallback = (value) => {
        TestRepotCheck(value)
    }

    const CSRDetailsCallback = (value) => {
        CSRInfo(value)
    }
    const PackingCallback = (value) => {
        PackingClback(value)
    }

    const previmage = () => {
        if (getCurrimageindex === 0) {
            setCurrimageindex(0);
        }
        else {
            setCurrimageindex(getCurrimageindex - 1);
        }
    }

    const nextimage = () => {
        if (getCurrimageindex === getnewimagevalue.length - 1) {
            setCurrimageindex(getnewimagevalue.length - 1);
        }
        else {
            setCurrimageindex(getCurrimageindex + 1);
        }
    }

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    function handleMainChange(e, FeildName) {
        debugger
        let InputText = '';
        const Values = { ...getMainInputField };

        if (FeildName === "Factory") {
            if (e !== null) {
                Values.FactoryLocationID = e.value;
                Values.FactoryLocation = e.label;
            }
            else {
                Values.FactoryLocationID = 0;
                Values.FactoryLocation = '';

            }
        }

        else if (FeildName === 'EXAQL') {
            if (e !== null) {
                Values.ExAqlResultID = e.value;
                Values.ExAqlResult = e.value === 1 ? 'Within AQL' : 'Beyond AQL';
            }
            else {
                Values.ExAqlResultID = 0;
                Values.ExAqlResult = '';

            }
        }

        else if (FeildName === "CustomerShipmentRefNo") {
            if (e.target.value.trim() !== '') {
                InputText = e.target.value;
            }
            Values.CustomerShipmentRefNo = InputText;
        }
        else if (FeildName === "AgencyName") {
            if (e.target.value.trim() !== '') {
                InputText = e.target.value;
            }
            Values.ExAgencyName = InputText;
        }
        else if (FeildName === "ReportNo") {
            if (e.target.value.trim() !== '') {
                InputText = e.target.value;
            }
            Values.ExReportNo = InputText;
        }
        else if (FeildName === "ExFindingRemarks") {
            if (e.target.value.trim() !== '') {
                InputText = e.target.value;
            }
            Values.ExRemarks = InputText;
        }
        else if (FeildName === "ExInspectorConclusion") {
            if (e.target.value.trim() !== '') {
                InputText = e.target.value;
            }
            Values.ExInspectorConclusion = InputText;
        }

        else if (FeildName === "ReportDate") {
            if (e !== null) {
                Values.ReportDate = e;
            }
            else {
                Values.ReportDate = null;
            }

        }
        setMainInputField(Values)
        MainInfoMation(Values)
    }

    function handleClosePopup() {

        let Multiselected = [];
        Multiselected = MultiCloseDatas.forEach(element => {
            element.isSelected = 0;
        })
        setMultiCloseDatas(Multiselected)
        setFinalInspectionPopup(false)
    }

    function OpenPopup() {

        var ExistingValue = [];
        ExistingValue = getInspectionQtyInfo;

        // For OrderDetails not Selected record
        setselectall(false)
        let Multiselected = [];
        Multiselected = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 0)
        if (ExistingValue.length > 0) {
            ExistingValue.map(ev => {
                Multiselected.map((ms, index) => {
                    ms.existofferqty = 0;
                    ms.IsDeleted = 0;
                    if (ev.skuName === ms.skuName) {
                        Multiselected.splice(index, 1)
                    }
                })
            })
        }
        if (BasicDetails.Mode === 'InspectionView') {
            Multiselected.map((ms, index) => {
                ms.existqty = 0;
                ms.actualBalQty = ms.balanceQuantity;
            })
        }
        setMultiCloseDatas(Multiselected)
        setFinalInspectionPopup(true)

    }


    const fetchData = async () => {
        let Multiselecteddate = [];
        Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
        try {
            const res = await FinalinspectionService.GetTNAFinalinspecMainInfo(
                props.location.state.params.FinalInspectionIds,
                BasicDetails.details.tnaid
            );

            const newData = Multiselecteddate.flatMap((y) =>
                res.data.finalInsQuantityInfoList
                    .filter((x) => x.skuID === y.skuID)
                    .map(({ tnaFinalinspcQualityinfoID, skuID, purchaseOrderSkuID, styleName, subTaskID, skuName, endCustomer, idno, tolerance, poQty, balanceQuantity, offeredQty, offeredpercent }) => ({
                        TNAFinalinspcQualityinfoID: tnaFinalinspcQualityinfoID,
                        skuID,
                        purchaseOrderSkuID,
                        styleNo: styleName,
                        tnaExFactoryId: subTaskID,
                        skuName,
                        endCustomer,
                        idNo: idno,
                        isSelected: 1,
                        Tolerance: tolerance,
                        orderQuantity: poQty,
                        PoandbalanceQty: parseInt(poQty) + parseInt(tolerance),
                        balanceQuantity,
                        offerqty: offeredQty,
                        duplofferqty: offeredQty,
                        existofferqty: offeredQty,
                        OfferPercentage: offeredpercent,
                        IsDeleted: 0,
                    }))
            );

            // Use the 'newData' array as needed
            console.log(newData);

            setprevreportQtyInfo(newData)
        } catch (error) {
            // Handle error
            console.error('Error fetching data:', error);
        }
        setPreInsRepPopup(true)
    };







    function handleClosePopupPreIns() {
        setPreInsRepPopup(false)
    }



    function OpenWarehousePopup() {
        let Multiselecteddate = []
        let finaldata = []
        Multiselecteddate = BasicDetails.multiselecteddatas.filter(x => x.isSelected === 1)
        let uniquevalues = [...new Map(Multiselecteddate.map(item =>
            [item['endCustomer'], item])).values()];

        FinalinspectionService.GetBuyerCustomerWarehouseList(BasicDetails.buyerID).then((res) => {
            uniquevalues.map(y => (
                res.data.map((x, index) => {
                    if (x.customerName.includes(y.endCustomer) === true) {
                        finaldata.push({
                            BuyerID: x.buyerID,
                            BuyerName: x.buyerName,
                            CustomerID: x.customerID,
                            CustomerName: x.customerName,
                            WarehouseID: x.warehouseID,
                            WarehouseName: x.warehouseName,
                            FileName: x.fileName,
                            FilePath: x.filePath,
                            FileType: x.fileType,
                            FileIndex: x.fileIndex,
                            CreatedDate: x.createdDate,
                        })

                    }
                })
            ))
            // let uniquevalues = [...new Map(finaldata.map(item =>
            //     [item['CustomerName'], item])).values()];
            setnewwarehousedata(finaldata)

        })
        setWarehousePopup(true)
    }


    function handleCloseWarehousePopup() {
        setWarehousePopup(false)
    }

    function AddExFactoryForm() {

        let Multiselected = [];
        const Values = [...getInspectionQtyInfo]
        let isvalid = true;
        isvalid = !!MultiCloseDatas.find(x => x.isSelected === 1)
        let sizeandqtyfordisplay = "";
        let SkuIDSample = "";
        let NewArr = [];
        NewArr = Values.concat(MultiCloseDatas.filter(x => x.isSelected === 1))
        NewArr.map((Purchaseorder) => {
            Purchaseorder.IsDeleted = 0;
            if (sizeandqtyfordisplay === '') {
                //&& SkuIDSample === ''
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                // SkuIDSample = (props.location.state.params.TaskStatus === 1 && inpsectionId === null) ? Purchaseorder.refInspecSKUID : Purchaseorder.refInspecSKUID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                //SkuIDSample = SkuIDSample + ',' + Purchaseorder.refInspecSKUID
            }
        })
        BasicDetails.PurchaseOrderSkuID = sizeandqtyfordisplay;

        setpurchaseOrderskuid(sizeandqtyfordisplay)
        props.location.state.params.PurchaseOrderSkuID = sizeandqtyfordisplay
        props.location.state.params.SkuID = SkuIDSample
        props.location.state.params.RemovedData = Values;
        props.location.state.params.IsSkuRemoved = false;
        if (isvalid) {
            Multiselected = Values.concat(MultiCloseDatas.filter(x => x.isSelected === 1))
            setcheckorderdetails(1)
            setMultiCloseDatas(Multiselected)
            setInspectionQtyInfo(Multiselected)
            setFinalInspectionPopup(false);
            MainInfoCommonCalculation(Multiselected)
            setartWorksexfacValue(Multiselected)
            settrimExfacValue(Multiselected)
            localStorage.setItem('Exfacconfirm', 1);
        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }
    }


    const filevals = [...getFiles]


    const AppendFiles = async e => {
        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "FinalInspection");
                try {
                    //const valuess = [...getFiles]
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    // valuess[0].FileName = res.data + "_" + file.name
                    // valuess[0].FilePath = "Images/Finalinspection/ExternalMainInfo/" + res.data + "_" + file.name
                    // valuess[0].FileType = file.type


                    filevals.push({
                        ExternalFileupID: 0,
                        FileName: res.data + "_" + file.name,
                        Filepath: "Images/Finalinspection/ExternalMainInfo/" + res.data + "_" + file.name,
                        FileType: file.type,
                        IsDeleted: 0
                    })
                    setFiles(filevals);
                    ExternalFiles(filevals)
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }

            setPage('LISTWASHCARE');
        }
    }

    const RemoveImages = (index) => {
        const filesvalue = [...getFiles]
        filesvalue.splice(index, 1);
        setFiles(filesvalue);
        ExternalFiles(filesvalue)

    }
    const RemoveFiles = async (e, index) => {
        const filesvalue = [...getFiles]
        filesvalue.splice(index, 1);
        setFiles(filesvalue);
        ExternalFiles(filesvalue)
    }

    const ViewFile = (index, filename) => {
        // event.preventDefault();
        //getFiles[index].FileName = filename;
        setImagename(filename);
        setIsOpenimg(true);
    }



    function handleOfferQty(e, index) {
        const values = [...getInspectionQtyInfo]
        let inputText = ""
        let finalvalue = 0;

        //let Regex = /^[A-Za-z]+$/ --for Letters only both upper and lowercase
        //var Regex = /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/; // For only Numbers
        //var Regex = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
        //const regexString = /^[a-zA-Z\s]*$/;
        // const regexDecimal = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
        const regexNumber = /^[0-9\b]+$/;
        //const Regex = new RegExp('^[0-9]+$');
        if (e.target.value.trim() !== '') {
            if (e.target.value.match(regexNumber)) {
                inputText = e.target.value;
            } else {
                inputText = '';
            }
        }

        let Balanceqty;
        if (BasicDetails.Mode === 'InspectionView' && values[index].isclosed === 1) {
            if (parseInt(inputText) > values[index].existqty) {
                Nodify('Warning!', 'warning', 'offer qty cannot exceed ' + values[index].existqty);
            }
            else {
                if (BasicDetails.Mode === 'InspectionAdd' && RefreshState === true) {
                    Balanceqty = parseInt(values[index].balanceQuantity);
                }
                else if (BasicDetails.Mode === 'InspectionAdd' && RefreshState === false) {
                    Balanceqty = parseInt(values[index].existofferqty) + parseInt(values[index].balanceQuantity);
                }
                else {
                    Balanceqty = parseInt(values[index].orderQuantity) + parseInt(values[index].Tolerance);
                }

            }
        }
        else if (BasicDetails.Mode === 'InspectionView') {

            Balanceqty = parseInt(values[index].existqty) + parseInt(values[index].actualBalQty);


        }
        else if (BasicDetails.Mode === 'ReInspection') {
            Balanceqty = parseInt(values[index].balanceQuantity);
        }
        else {
            if (BasicDetails.Mode === 'InspectionAdd' && RefreshState === true) {
                Balanceqty = parseInt(values[index].balanceQuantity);
            }
            else if (BasicDetails.Mode === 'InspectionAdd' && RefreshState === false) {
                Balanceqty = parseInt(values[index].existofferqty) + parseInt(values[index].balanceQuantity);
            }
            else {
                Balanceqty = parseInt(values[index].orderQuantity) + parseInt(values[index].Tolerance);
            }

        }

        if (Balanceqty >= parseInt(inputText)) {
            values[index].offerqty = inputText;
            finalvalue = ((parseInt(inputText) - parseInt(values[index].orderQuantity)) / parseInt(values[index].orderQuantity)) * 100
            if (finalvalue !== undefined && finalvalue !== -1) {
                values[index].OfferPercentage = parseInt(finalvalue)
            } else {
                values[index].OfferPercentage = 0
            }

        } else {
            values[index].offerqty = '';
            values[index].OfferPercentage = 0
        }

        setInspectionQtyInfo(values)
        MainInfoCommonCalculation(values)
        Quantityinfo(values)
    }

    function SampleSizeCalulation(val) {

        const samplevalues = { ...getQuantysubDetails }
        if (val !== 0 || val !== undefined) {
            let Aqlsample = getAqlDetails.filter(x => x.LotSizeFromm <= parseInt(val) && x.LotSizeTo >= parseInt(val))
            let SampleSize = Aqlsample.find(x => x.SampleSize)
            if (SampleSize !== undefined) {
                samplevalues.SamplingSize = SampleSize.SampleSize.toString();
            } else {
                samplevalues.SamplingSize = '';
            }
        } else {
            samplevalues.SamplingSize = '';
        }
        setQuantysubDetails(samplevalues);
        QuantitySubInfo(samplevalues);
    }

    function MainInfoCommonCalculation(val) {

        let poqty = 0;
        let offqty = 0;
        let offerper = 0;
        val.map(x => {
            if (poqty === 0) {
                poqty = parseFloat(x.orderQuantity);
            }
            else {
                poqty = poqty + parseFloat(x.orderQuantity);
            }
            if (offqty === 0 && x.offerqty !== undefined && x.offerqty !== '') {
                offqty = parseFloat(x.offerqty);
            }
            else if (offqty !== 0 && x.offerqty !== undefined && x.offerqty !== '') {
                offqty = parseFloat(offqty) + parseFloat(x.offerqty);
            }
            if (offerper === 0 && x.OfferPercentage !== undefined && x.OfferPercentage !== '') {
                offerper = Math.round(x.OfferPercentage);
            }
            else if (offerper !== 0 && x.OfferPercentage !== undefined && x.OfferPercentage !== '') {
                offerper = Math.round((offqty - poqty) / poqty * 100);
            }
        })
        setTotalValues({ PoQty: poqty, offqty: offqty, offerper: offerper })
        totalvalue({ PoQty: poqty, offqty: offqty, offerper: offerper })
        SampleSizeCalulation(offqty);
    }

    function RemoveQuantity(e, index, purchaseOrderSkuID, comment) {

        let multiselecteddatas = [];
        let SkuIDSample = "";
        const values = [...getInspectionQtyInfo]
        let isvalid = true;
        FinalinspectionService.GetTNAFinalinspecMainInfo((props.location.state.params.TaskStatus === 1 && inpsectionId === null) ? 0 : (props.location.state.params.TaskStatus === 1 && inpsectionId !== null) ? inpsectionId :
            props.location.state.params.TNAInspectionID, props.location.state.params.details.tnaid).then((res) => {
                isvalid = !!res.data.finalInsMeasurementfileList.find(x => x.skuName === comment.skuName)
                if (isvalid) {
                    Nodify('Warning!', 'warning', 'This Sku mapped in Measurement Tab')
                } else {
                    BasicDetails.multiselecteddatas.filter(x => parseInt(x.purchaseOrderSkuID) === parseInt(purchaseOrderSkuID)).map(element => {
                        element.isSelected = 0;
                    })
                    values[index].IsDeleted = 1;
                    multiselecteddatas = values.filter(x => x.isSelected === 1)
                    let sizeandqtyfordisplay = "";
                    let NewArr = [];
                    multiselecteddatas.map((Purchaseorder) => {
                        if (sizeandqtyfordisplay === '' && SkuIDSample === '') {
                            sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                            SkuIDSample = (props.location.state.params.TaskStatus === 1 && inpsectionId === null) ? Purchaseorder.refInspecSKUID : Purchaseorder.skuID;
                        } else {
                            sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                            SkuIDSample = SkuIDSample + ',' + Purchaseorder.refInspecSKUID
                        }
                    })
                    BasicDetails.PurchaseOrderSkuID = sizeandqtyfordisplay;
                    props.location.state.params.PurchaseOrderSkuID = sizeandqtyfordisplay;
                    props.location.state.params.SkuID = SkuIDSample
                    props.location.state.params.RemovedData = multiselecteddatas;
                    props.location.state.params.IsSkuRemoved = true;
                    setcheckorderdetails(2);
                    setpurchaseOrderskuid(sizeandqtyfordisplay)
                    settrimExfacValue(multiselecteddatas)
                    setartWorksexfacValue(multiselecteddatas)
                    values.splice(index, 1);
                    setInspectionQtyInfo(values)
                    MainInfoCommonCalculation(values)
                    localStorage.removeItem('Exfacconfirm', 1);
                }

            })
    }

    // async function CheckInspectionLevel(e) {

    //     let InspecAqlLevel = [];
    //     let AqlLevel = [];
    //     await FinalinspectionService.GetFinalInspectionMainInformation(2, e.value, BasicDetails.buyerID).then((res) => {
    //         if (res.data) {
    //             res.data.subInspecstandard.map((x) => {
    //                 InspecAqlLevel.push({
    //                     value: x.inspectionLevelID,
    //                     label: x.inspectionLevel
    //                 })
    //                 AqlLevel.push({
    //                     value: x.majorMinAQLID,
    //                     label: x.majorMinorAql
    //                 })
    //             })
    //             setloadAqlLevel(AqlLevel)
    //             setInspectionLevelDP(InspecAqlLevel);
    //         }
    //     })
    // }

    const SelectAllExFactory = (event) => {
        let IsSelected = 0;
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
                element.IscheckReportSelect = 2;
                element.offerqty = '';
                //element.duplofferqty = element.balanceQuantity
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
                element.IscheckReportSelect = 0;
            });
        }
        setselectall(IsSelected);
    }

    const OnSelectExFactoryRecords = (event, index) => {
        const value = [...MultiCloseDatas];
        if (event.target.checked === true) {
            value[index].isSelected = 1;
            value[index].IscheckReportSelect = 2;
            value[index].offerqty = '';
            value[index].duplofferqty = value[index].balanceQuantity
        }
        else {
            value[index].isSelected = 0;
            value[index].IscheckReportSelect = 0;
        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);
        setMultiCloseDatas(value);

    }

    function handleInputQuantitySub(e, FieldName) {
        const values = { ...getQuantysubDetails }
        //const reg = new RegExp('^[0-9]+$');
        const regexNumber = /^[0-9\b]+$/;
        let inputText = ""
        if (FieldName === 'SamplingSize') {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values.SamplingSize = inputText
        }
        else if (FieldName === "Qtyavailable") {
            if (e.target.value.match(regexNumber)) {
                if (e.target.value !== '0') {
                    inputText = e.target.value.trim();
                } else {
                    inputText = ''
                }
                values.quantityavailable = inputText
            }
            else {
                inputText = ""
                values.quantityavailable = inputText
                //values[index].offerqty = inputText
            }
        }
        else if (FieldName === "cartonbox") {
            if (e.target.value.match(regexNumber)) {
                inputText = e.target.value.trim();
                values.cartonbox = inputText
            }
            else {
                inputText = ""
                values.cartonbox = inputText
                //values[index].offerqty = inputText
            }
        }
        else if (FieldName === "inspectCartonBox") {
            //if (e.target.value.match(regexNumber)) {
            inputText = e.target.value.trim();
            values.inspectedCarton = inputText
            // }
            // else {
            //     inputText = ""
            //     values.inspectedCarton = inputText
            //     //values[index].offerqty = inputText
            // }
        } else if (FieldName === 'SampleInspectedPieces') {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values.SampleInspectedPieces = inputText
        }
        else if (FieldName === 'txtResult') {
            if (e !== null) {
                values.Result = e.value;
            } else {
                values.Result = 0;
            }
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values.FindingsRemarks = inputText
        }
        setQuantysubDetails(values)
        QuantitySubInfo(values)
    }

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
                style={{ border: submitted && props.value === '' ? '1px solid red' : '', backgroundColor: 'white' }}
            />
        )
    }
    function AlertFun() {
        Nodify('Warning!', 'warning', 'Please fill offered quantity');
    }
    const handleButtonClick = () => {
        if (getQuantysubDetails.SamplingSize.trim() === '') {
            AlertFun();
        }
    };

    return (
        <Fragment>
            <div className="page-body">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="txtBuyerSeasonBrand">Buyer-Season-Brand</label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" className='form-control' placeholder="Enter Buyer-Season-Brand" id="txtBuyerSeasonBrand"
                                            name="buyerseasonbrand" autoComplete="off" maxLength="100"
                                            value={getMainInfo.BuyerName + " - " + getMainInfo.SeasonName + " - " + getMainInfo.BrandName}
                                            isDisabled />
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlSupplier">Supplier</label>
                                    <span className='input-icon icon-right'>
                                        <Reactselect className="basic-single" name="supplier"
                                            id={
                                                "ddlSupplier"
                                            }
                                            isDisabled={true}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={getSupplierOptions}
                                            value={getSupplierOptions.filter(function (option) {
                                                return option.value === BasicDetails.supplierID;
                                            })}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlFactoryOrLocation">Factory {/* / Location */}<span className="text-danger">*</span></label>

                                    <span className='input-icon icon-right'>
                                        <Reactselect className="basic-single" name="factoryorlocation"
                                            id={
                                                "ddlFactoryOrLocation"
                                            }
                                            isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={getExFactoryList}
                                            styles={submitted && getMainInputField.FactoryLocationID === 0 ? styles : ''}
                                            value={getExFactoryList.filter(function (option) {
                                                return option.value === getMainInputField.FactoryLocationID;
                                            })}
                                            onChange={event => handleMainChange(event, 'Factory')}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlStyleName">Style Name</label>
                                    <span className='input-icon icon-right'>
                                        <Reactselect className="basic-single" name="factoryorlocation"
                                            id={
                                                "ddlStyleName"
                                            }
                                            // isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                            isDisabled={true}
                                            isLoading={false}
                                            //isClearable={true}
                                            isSearchable={true}
                                            options={getStyleNameOptions}
                                            value={getStyleNameOptions.filter(function (option) {
                                                return option.id === BasicDetails.styleID;
                                            })}
                                        ></Reactselect>
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="txtInspectionStandard">Inspection Standard</label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" className='form-control' id="InspectionStandard   "
                                            name="InspectionStandard" autoComplete="off"
                                            //onChange={event => handleMainChange(event, 'InspectionStandard')}
                                            value={getMainInputField.InspectionStandard}
                                            maxLength="100" />
                                        {/* <Reactselect className="basic-single" name="factoryorlocation"
                                            id={
                                                "ddlStyleName"
                                            }
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={getInspectionLoadOptions}
                                            onChange={event => handleMainChange(event, 'InspectionStandard')}
                                            value={getInspectionLoadOptions.filter(function (option) {
                                                return option.value === getMainInputField.InspectionStandardID;
                                            })}
                                        ></Reactselect> */}
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="txtInspectionLoad">Inspection Level</label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" className='form-control' id="InspectionLevel   "
                                            name="InspectionLevel" autoComplete="off"
                                            //onChange={event => handleMainChange(event, 'InspectionLevel')}
                                            value={getMainInputField.InspectionLevel}
                                            maxLength="100" />

                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="txtAQLLevel">AQL Level</label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" className='form-control' id="AQLLevel"
                                            name="AQLLevel" autoComplete="off"
                                            //onChange={event => handleMainChange(event, 'AQLLevel')}
                                            value={getMainInputField.MajorMinorAql}
                                            maxLength="100" />


                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="ddlCustomerShipmentRefNo">Customer Shipment Ref No.</label>
                                    <span className='input-icon icon-right'>
                                        <input type="text" className='form-control' id="CustomerShipmentRefNo   "
                                            name="CustomerShipmentRefNo" placeholder="Enter Customer Shipment Ref No" autoComplete="off"
                                            onChange={event => handleMainChange(event, 'CustomerShipmentRefNo')}
                                            value={getMainInputField.CustomerShipmentRefNo}
                                            disabled={props.location.state.params.Action === 'View' ? true : false}
                                            maxLength="100" />
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className='form-group'>
                                    <label htmlFor="dteReportDate">Report Date<span className="text-danger">*</span></label>

                                    <DatePicker className="form-control" name="ReceivedDate"
                                        id={
                                            "dteReportDate"
                                        }
                                        selected={
                                            getMainInputField.ReportDate
                                        }
                                        onChange={event => handleMainChange(event, 'ReportDate')}
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        disabled={props.location.state.params.Action === 'View' ? true : false}
                                        //showMonthDropdown
                                        //showYearDropdown
                                        dropdownMode="scroll"
                                        autoComplete="off"
                                        customInput={<CustomInput />}
                                        isClearable={getMainInputField.ReportDate === null ? false : true}

                                    />


                                    {/* <span className='input-icon icon-right'>
                                        <input type="date" className='form-control'
                                            onKeyDown={(e) => e.preventDefault()}
                                            value={getMainInputField.ReportDate}
                                            onChange={event => handleMainChange(event, 'ReportDate')}
                                            placeholder="DD/MM/YYYY" id="dteReportDate"
                                            style={{ border: submitted && getMainInputField.ReportDate === '' ? '1px solid red' : '' }}
                                            name="reportdate" autoComplete="off" maxLength="50" />
                                    </span> */}
                                </div>
                            </div>

                        </div>
                        {
                            props.location.state.params.type === 2 ? < div className="row">
                                {/* <div className="col-sm-4">
                                    <div className='form-group'>
                                        <label htmlFor="txtPreviousInspectionReports">Previous Inspection Reports</label>
                                        <span className='input-icon icon-right'>
                                            <input type="text" className='form-control' placeholder="Enter Previous Inspection Reports" id="txtPreviousInspectionReports"
                                                name="previousinspectionreports" autoComplete="off" maxLength="100" />
                                        </span>
                                    </div>
                                </div> */}
                                <div className="col-sm-4">
                                    <div className='form-group'>
                                        <label htmlFor="txtPreviousInspectionReports">Agency Name<span className="text-danger">*</span></label>
                                        <span className='input-icon icon-right'>
                                            <input type="text" className='form-control' placeholder="Agency Name" id="txtPreviousInspectionReports"
                                                name="previousinspectionreports" autoComplete="off" maxLength="100"
                                                style={{ border: submitted && getMainInputField.ExAgencyName === '' ? '1px solid red' : '' }}
                                                onChange={event => handleMainChange(event, 'AgencyName')}
                                                value={getMainInputField.ExAgencyName} />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className='form-group'>
                                        <label htmlFor="txtPreviousInspectionReports">Report No<span className="text-danger">*</span></label>
                                        <span className='input-icon icon-right'>
                                            <input type="text" className='form-control' placeholder="Report No" id="txtPreviousInspectionReports"
                                                onChange={event => handleMainChange(event, 'ReportNo')}
                                                name="previousinspectionreports"
                                                style={{ border: submitted && getMainInputField.ExReportNo === '' ? '1px solid red' : '' }}
                                                value={getMainInputField.ExReportNo} autoComplete="off" maxLength="100" />
                                        </span>
                                    </div>
                                </div>
                            </div> : ''
                        }
                        {/* <div className="row">
                            <div className="col-lg-4">
                                {BasicDetails.page === 'ReInspection' ?
                                    <div className="previnsreport">
                                        <span className="btn btn-primary"
                                            onClick={OpenPopupPrevInsReport}
                                        >

                                            &nbsp;Previous Inspection Report</span>
                                    </div> : ""}
                            </div>
                        </div> */}


                    </div>
                    <div className="col-sm-3 pull-right">
                        <div className="form-group">

                            <label style={{ marginLeft: "55px" }} htmlFor="exampleInputEmail2">SKU Image</label>
                            <br />
                            {
                                getnewimagevalue.length > 0 ?
                                    <>
                                        <i className="fa fa-chevron-circle-left fa-2x" onClick={() => previmage()} style={{ cursor: "pointer" }}></i>
                                        <img src={window.$APIBaseURL + "Images" + getnewimagevalue[getCurrimageindex].skuFrontPicPath} alt="" width="150px" height="150px" />
                                        <i className="fa fa-chevron-circle-right fa-2x" onClick={() => nextimage()} style={{ cursor: "pointer" }}></i>
                                    </> : ''
                            }
                            <br />
                            {
                                getnewimagevalue.length > 0 ?

                                    <label htmlFor="" style={{ marginLeft: "85px", marginTop: "1rem" }}>{getnewimagevalue[getCurrimageindex].skuName}</label>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <div className="row mt-5" style={{ pointerEvents: props.location.state.params.Action === 'View' ? 'none' : '' }}>
                    <div className="panel-group accordion" id="acc_Quantity_Info">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a className="accordion-toggle clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info" href="#collapse_acc_Quantity_Info">
                                        Quantity Information
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse_acc_Quantity_Info" className="panel-collapse">
                                <div className="panel-body border-red">
                                    <div className="mb-10-float-right">
                                        {BasicDetails.page === 'ReInspection' ?

                                            <label style={{ backgroundColor: "#3f51b5", color: "white" }} className="btn btn-sm"
                                                onClick={fetchData}
                                            >
                                                Previous Inspection Report</label>
                                            : ""}&nbsp;&nbsp;
                                        <label className="btn btn-sm btn-warning">Tolerance: {gettolerance + '%'}</label>&nbsp;&nbsp;
                                        {props.location.state.params.Action != "View" && <button type="button" className="btn btn-sm btn-success mb-10-float-right" onClick={OpenPopup}>Order Details</button>}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                <thead>
                                                    <tr>
                                                        {BasicDetails.page === 'ReInspection' ? <th>ReportNo/BalQty</th> : ''}
                                                        <th>SKU ID(PO)</th>
                                                        <th>Style No</th>
                                                        <th>SKU Name</th>
                                                        <th>End Customer</th>
                                                        <th>PO/ID No</th>
                                                        <th>FO PO No</th>
                                                        <th>PO Qty</th>
                                                        {/* <th>PO Qty + Tol</th>
                                                        <th>Total Ins Qty </th> */}
                                                        {/* <th>Tolerance</th> */}
                                                        {BasicDetails.TaskStatus === 1 && inpsectionId === null ? <th>Bal. for Inspection</th> : BasicDetails.TaskStatus === 2 ? <th style={{ display: 'none' }}>Bal. for Inspection</th> : ''}
                                                        {BasicDetails.TaskStatus === 1 && inpsectionId !== null ? <th>Bal. for Inspection</th> : BasicDetails.TaskStatus === 2 ? <th>Bal. for Inspection</th> : ''}
                                                        <th>
                                                            {
                                                                BasicDetails.Mode === 'ReInspection' ? 'Re Inspection Qty' : ' Offered Qty'
                                                            }

                                                            <span className="text-danger">*</span></th>
                                                        <th>Offered %</th>
                                                        <th style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        getInspectionQtyInfo.map((comment, index) => (
                                                            <tr>
                                                                {BasicDetails.page === 'ReInspection' ? <td>{comment.tnaFinalInspectionID + '/ ' + comment.balanceQuantity}</td> : ''}
                                                                <td>{comment.purchaseOrderSkuID}</td>
                                                                <td>{comment.styleNo}</td>
                                                                <td>{comment.skuName}</td>
                                                                <td>{comment.endCustomer}</td>
                                                                <td>{comment.idNo}</td>
                                                                <td>-</td>
                                                                <td>{comment.orderQuantity}</td>
                                                                {/* <td>{comment.orderQuantity + ' + ' + comment.Tolerance}</td>
                                                                <td>{comment.PoandbalanceQty}</td> */}
                                                                {/* <td>{comment.Tolerance}</td> */}
                                                                {BasicDetails.TaskStatus === 1 && inpsectionId === null ? <td>{comment.balanceQuantity}</td> : BasicDetails.TaskStatus === 2 ? <td style={{ display: 'none' }}>{comment.balanceQuantity}</td> : ''}
                                                                {BasicDetails.TaskStatus === 1 && inpsectionId !== null ? <td>{parseInt(comment.balanceQuantity) + parseInt(comment.duplofferqty)}</td> : BasicDetails.TaskStatus === 2 ?
                                                                    <td>{parseInt(comment.balanceQuantity) + parseInt(comment.duplofferqty)}</td> : ''}
                                                                <td><input className="form-control" type="text"
                                                                    //disabled={(inpsectionId !== null && comment.offerqty !== undefined && comment.offerqty !== "") ? true : false}
                                                                    name="" id="" value={comment.offerqty}
                                                                    style={{ border: submitted && comment.offerqty === undefined || comment.offerqty === '' ? '1px solid red' : '' }}
                                                                    onChange={event => handleOfferQty(event, index)}
                                                                /></td>
                                                                {/* <td>{comment.OfferPercentage}{comment.OfferPercentage !== undefined ? '%' : ''}</td> */}
                                                                <td>{comment.OfferPercentage === undefined ? 0 : comment.OfferPercentage + '%'}</td>
                                                                <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}> <span>
                                                                    <span className="btn btn-danger"
                                                                        style={{ pointerEvents: getInspectionQtyInfo.length === 1 ? "none" : '', opacity: getInspectionQtyInfo.length === 1 ? '0.6' : '' }}
                                                                        onClick={event => RemoveQuantity(event, index, comment.purchaseOrderSkuID, comment)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </span>
                                                                </span></td>
                                                            </tr>

                                                        ))
                                                    }
                                                    <tr>
                                                        <td colSpan="6" className="text-right"><b>Total</b></td>
                                                        <td><b style={{ color: "green" }}>{getTotalValues.PoQty}</b></td>
                                                        <td></td>

                                                        <td><b style={{ color: "hotpink" }}>{getTotalValues.offqty}</b></td>
                                                        <td><b style={{ color: "blue" }}>{getTotalValues.offerper}{getTotalValues.offerper !== 0 ? '%' : ''}</b></td>
                                                        <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}></td>
                                                        {/* <td></td> */}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {
                                                props.location.state.params.type === 1 ?
                                                    <div className="mt-5">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className='form-group'>
                                                                    <label htmlFor="txtSamplingSize">Sampling Size</label>
                                                                    <span className='input-icon icon-right'>
                                                                        <input type="text" className='form-control'
                                                                            value={getQuantysubDetails.SamplingSize}
                                                                            placeholder="Enter Sampling Size" id="txtSamplingSize"
                                                                            name="samplingsize" autoComplete="off" maxLength="100"
                                                                            onClick={handleButtonClick}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className='form-group'>
                                                                    <label htmlFor="txtAvailableForInspection">Quantity % Available for Inspection<span className="text-danger">*</span></label>
                                                                    <span className='input-icon icon-right'>
                                                                        <input type="text" value={getQuantysubDetails.quantityavailable} onChange={event => handleInputQuantitySub(event, 'Qtyavailable')} className='form-control' placeholder="Enter Quantity" id="txtAvailableForInspection"
                                                                            style={{ border: submitted && getQuantysubDetails.quantityavailable === '' ? '1px solid red' : '' }} name="availableforinspection" autoComplete="off" maxLength="100" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className='form-group'>
                                                                    <label htmlFor="txtTotalCartonBox">Total Carton Box</label>
                                                                    <span className='input-icon icon-right'>
                                                                        <input type="text" value={getQuantysubDetails.cartonbox} onChange={event => handleInputQuantitySub(event, 'cartonbox')} className='form-control' placeholder="Enter Total Carton Box" id="txtTotalCartonBox"
                                                                            name="totalcartonbox" autoComplete="off" maxLength="100" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className='form-group'>
                                                                    <label htmlFor="txtNoOfInspectedCartons">No. of the inspected Carton Box</label>
                                                                    <span className='input-icon icon-right'>
                                                                        <input type="text" value={getQuantysubDetails.inspectedCarton} onChange={event => handleInputQuantitySub(event, 'inspectCartonBox')} className='form-control' placeholder="Enter Total Carton Box" id="txtNoOfInspectedCartons"
                                                                            name="noofinspectedcartons" autoComplete="off" maxLength="100" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className='form-group'>
                                                                    <label htmlFor="txtSampleInspectedPieces">Sample Inspected Pieces</label>
                                                                    <span className='input-icon icon-right'>
                                                                        <input type="text" value={getQuantysubDetails.SampleInspectedPieces} onChange={event => handleInputQuantitySub(event, 'SampleInspectedPieces')} className='form-control' placeholder="Enter Sample Inspected Pieces" id="txtSampleInspectedPieces"
                                                                            name="sampleinspectedpieces" autoComplete="off" maxLength="100" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className='form-group' >
                                                                    <label htmlFor="txtResult">Quantity Information Result</label>
                                                                    <Reactselect className="basic-single" name="Result"
                                                                        id={
                                                                            "Result"
                                                                        }
                                                                        isDisabled={false}
                                                                        isLoading={false}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={event => handleInputQuantitySub(event, 'txtResult')}
                                                                        options={getInspectionResult}
                                                                        value={getInspectionResult.filter(function (option) {
                                                                            return option.value === getQuantysubDetails.Result;
                                                                        })}
                                                                        styles={customStyles}
                                                                    ></Reactselect>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className='form-group' style={{ marginTop: "-70px" }}>
                                                                    <label htmlFor="txtFindingsOrRemarks">Findings / Remarks{getQuantysubDetails.Result === 2 ? <span className="text-danger">*</span> : ''}</label>
                                                                    <span className='input-icon icon-right'>
                                                                        <textarea className='form-control' style={{ minHeight: '109px', border: submitted && (getQuantysubDetails.Result === 2 && getQuantysubDetails.FindingsRemarks === '') ? '1px solid red' : '' }}
                                                                            value={getQuantysubDetails.FindingsRemarks} onChange={event => handleInputQuantitySub(event, 'FindingsRemarks')} name="findingsorremarks"
                                                                            id="txtFindingsOrRemarks" rows="10" placeholder="Enter Findings / Remarks"

                                                                            autoComplete="off" maxLength="200"></textarea>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        getFinalInspectionPopup === true ? <Modal dialogClassName="CreatePopup" show={getFinalInspectionPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClosePopup()}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    ADD Final Inspection &nbsp;
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr style={{ whiteSpace: "nowrap" }}>
                                                <th>Style Name</th>
                                                <th>Style No</th>
                                                <th>SKU Name</th>
                                                <th>PO/ID No</th>
                                                <th>FO PO No</th>
                                                <th>Factory</th>
                                                <th>Order Qty</th>
                                                <th>Bal. for Inspection</th>
                                                <th>Task Holder / Follower</th>
                                                <th>
                                                    <label style={{ marginTop: '8px' }}>
                                                        <input type="checkbox" name="IsSelected"

                                                            onChange={event => (SelectAllExFactory(event))}
                                                            checked={selectall === 1 ? true : false}
                                                            className="colored-blue clearOpacity" />
                                                        <span className="text"></span>
                                                    </label>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                MultiCloseDatas.length !== 0 && MultiCloseDatas.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{item.styleName}</td>
                                                                <td>{item.styleNo}</td>
                                                                <td>{item.skuName}</td>
                                                                {/* <td>-</td> */}
                                                                <td>{item.idNo}</td>
                                                                <td>{item.poNo}</td>
                                                                <td>{item.factory}</td>
                                                                <td>{item.orderQuantity}</td>
                                                                <td>{item.balanceQuantity}</td>
                                                                <td className="cut_textInTNA" title={item.taskOwnerNameList} >{item.taskOwnerNameList}</td>
                                                                <td className="Actionbutton fixed-column">
                                                                    <div style={{ width: "80px" }}>
                                                                        {
                                                                            <label style={{ marginTop: '8px' }}>
                                                                                <input type="checkbox" name="IsSelected"
                                                                                    disabled={item.balanceQuantity === "0" ? true : false}
                                                                                    onChange={event => (OnSelectExFactoryRecords(event, index))}
                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                    className="colored-blue clearOpacity" />
                                                                                <span className="text"></span>
                                                                            </label>
                                                                        }&nbsp;&nbsp;&nbsp;
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>

                                                    )
                                                })

                                            }
                                            {
                                                MultiCloseDatas.length === 0 ?
                                                    <tr>
                                                        <td colSpan="10" className='norecordfound'><span>No record found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" onClick={() => AddExFactoryForm()}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal> : ''
                    }


                    {
                        getPreInsRepPopup === true ? <Modal dialogClassName="getPreInsRepPopup" show={getPreInsRepPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleClosePopupPreIns()}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Previous Inspection Report &nbsp;
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr style={{ whiteSpace: "nowrap" }}>
                                                <th>SKU ID(PO)</th>
                                                <th>Style No</th>
                                                <th>SKU Name</th>
                                                <th>End Customer</th>
                                                <th>PO/ID No</th>
                                                <th>FO PO No</th>
                                                <th>PO Qty</th>
                                                <th>Bal. for Inspection</th>
                                                <th>Offered Qty</th>
                                                <th>Offered %</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                getprevreportQtyInfo.map((comment, index) => (
                                                    <tr>

                                                        <td>{comment.purchaseOrderSkuID}</td>
                                                        <td>{comment.styleNo}</td>
                                                        <td>{comment.skuName}</td>
                                                        <td>{comment.endCustomer}</td>
                                                        <td>{comment.idNo}</td>
                                                        <td>-</td>
                                                        <td>{comment.orderQuantity}</td>
                                                        <td>{parseInt(comment.balanceQuantity) + parseInt(comment.duplofferqty)}</td>
                                                        <td>
                                                            <input className="form-control" type="text"
                                                                //disabled={(inpsectionId !== null && comment.offerqty !== undefined && comment.offerqty !== "") ? true : false}
                                                                name="" id="" value={comment.offerqty}
                                                                style={{ border: submitted && comment.offerqty === undefined || comment.offerqty === '' ? '1px solid red' : '' }}

                                                            />
                                                        </td>

                                                        {/* <td>{comment.OfferPercentage}{comment.OfferPercentage !== undefined ? '%' : ''}</td> */}
                                                        <td>{comment.OfferPercentage === undefined ? 0 : comment.OfferPercentage + '%'}</td>

                                                    </tr>

                                                ))
                                            }
                                            {/* <tr>
                                                <td colSpan="6" className="text-right"><b>Total</b></td>
                                                <td>{getTotalValues.PoQty}</td>
                                                <td></td>

                                                <td>{getTotalValues.offqty}</td>
                                                <td>{getTotalValues.offerper}{getTotalValues.offerper !== 0 ? '%' : ''}</td>
                                                <td style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}></td>

                                            </tr> */}




                                        </tbody>

                                    </table>
                                </div>
                            </Modal.Body>

                        </Modal> : ''
                    }









                </div>
                {
                    props.location.state.params.type === 2 ?
                        <div className="row" style={{ marginLeft: "0", marginTop: "2rem" }}>
                            <div className='form-group'>
                                <label htmlFor="InspectosConclusion">Inspection Report Upload<span className="text-danger">*</span> </label>

                                <br />
                                <div className="widget-header" style={{ paddingLeft: 0, background: 'none' }}>
                                    <span className="widget-caption" style={{ fontSize: '14px' }}>File Upload <span
                                        style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span>
                                </div>
                                <div className='form-group'>
                                    <tr>
                                        <td> <input type="file" id="FileUpload" disabled={getFiles.length >= 5 ? true : false}//disabled={getFiles.length === 1 ? true : false}
                                            onChange={(e) => AppendFiles(e)} accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx,.xls" ></input></td>
                                    </tr>
                                </div>
                            </div>
                            {
                                <div className="col-lg-12 col-sm-12">
                                    <div className="row no-margin">
                                        {
                                            getFiles.length !== 0 ?
                                                getFiles.map((files, fileindex) => (
                                                    files.FileType === "image/jpeg" || files.FileType === "image/jpg" || files.FileType === "image/png" ||
                                                        files.FileType === "image/gif" ?
                                                        <div className="float-Left_washcare file_Image_uploads">
                                                            {/* <div className="col-sm-3"> */}
                                                            <div className="client_tumblineWashcare">

                                                                {files.FileName !== '' ? <img className="width_manual1"
                                                                    src={window.$APIBaseURL + files.Filepath}
                                                                //onClick={() => setIsOpenimg(window.$APIBaseURL + 'Images/Style/WashCare/' + getImagename)}
                                                                /> : ''}

                                                                <table className="table_thum">
                                                                    <tr className="table_rwo">
                                                                        {/* <td title={files.FileName.length > 32 ? files.FileName.substr(33) : files.FileName.substr(5)} className="bold_text width_20 sku_textoverflow">
                                                                                                                    {files.FileName.length > 32 ? files.FileName.substr(33) : files.FileName.substr(5)}
                                                                                                                </td> */}
                                                                    </tr>
                                                                </table>

                                                                <a className="washcareView" title='View External image' onClick={() => ViewFile(fileindex, files.Filepath)}>
                                                                    <i className="btn btn-info fa fa-eye" ></i>
                                                                </a>&nbsp;
                                                                <a style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }} title='Remove Wash Care' onClick={() => RemoveImages(fileindex)}>
                                                                    <i className="btn btn-danger fa fa-trash-o"></i>
                                                                </a>

                                                            </div>
                                                            {/* </div> */}
                                                            {

                                                                < div > {
                                                                    isOpenimg && (

                                                                        <Lightbox mainSrc={window.$APIBaseURL + getImagename}
                                                                            alt="bg image"
                                                                            onCloseRequest={
                                                                                () => setIsOpenimg(false)
                                                                            } />
                                                                    )
                                                                } </div>
                                                            }
                                                        </div> : ''
                                                ))
                                                : ''
                                        }
                                    </div>
                                </div>
                            }
                            &nbsp;
                            &nbsp;
                            {

                                <div className="col-lg-12 col-sm-12">
                                    <div className="row no-margin">
                                        {
                                            getFiles.length !== 0 ?
                                                getFiles.map((files, fileindex) => (
                                                    files.FileType === "application/pdf" || files.FileType === "application/msword" || files.FileType === "application/vnd.ms-excel"
                                                        || files.FileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ?
                                                        <div className="col-lg-4 col-sm-4 file_list_upload">
                                                            <tr title={files.FileName}>
                                                                {files.FileName !== '' ? <td className="cut_textInTNA">
                                                                    <span style={{ marginRight: '6px' }}>
                                                                        {
                                                                            files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                    <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                    files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                        files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                        <i class="fa fa-picture-o fa-lg image_icon_style"></i>

                                                                        }
                                                                        {
                                                                            files.FileType === "image/jpeg" || files.FileType === "image/jpg" || files.FileType === "image/png" ||
                                                                                files.FileType === "image/gif" ? <i class="fa fa-picture-o fa-lg image_icon_style"></i> : ''
                                                                        }
                                                                    </span>  {files.FileName}
                                                                </td> : ''}

                                                                <td className="button_style_uploda">
                                                                    {Buttonview !== "View" &&

                                                                        <p title='Delete File'
                                                                            style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                            onClick={(e) => RemoveFiles(e, fileindex)}
                                                                            className="btn btn-danger btn-xs delete">
                                                                            <i className="fa fa-trash-o"></i>

                                                                        </p>
                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                        files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                                                            files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                            || files.FileType === 'application/msword' || files.FileType === "application/vnd.ms-excel" ?
                                                                            <a type="button" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                title="Download" class="btn btn-xs btn-primary" target="_blank"
                                                                                href={window.$APIBaseURL + files.Filepath} download><i class="fa fa-download"></i> </a> : ''
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </div> : ''
                                                ))
                                                : ''
                                        }
                                    </div>
                                </div>
                            }
                        </div> : ""
                }

                {
                    props.location.state.params.type === 2 ?
                        <div className="row mt-5">
                            <div className='form-group' style={{ marginLeft: "13px", marginTop: "2rem" }}>
                                <label htmlFor="reportconclusion">Report Conclusion</label>
                            </div>
                            <div className="col-md-12">
                                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                    <thead>
                                        <tr>
                                            <th>AQL Result </th>
                                            <th>Findings / Remarks {getMainInputField.ExAqlResultID === 2 ? <span className="text-danger">*</span> : ''} </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr style={{ pointerEvents: props.location.state.params.Action === 'View' ? 'none' : '' }}>
                                            <td><Reactselect className="basic-single" name="AQLResult"
                                                id={
                                                    "AQLResult"
                                                }
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                options={getExAQLResult}
                                                value={getExAQLResult.filter(function (option) {
                                                    return option.value === getMainInputField.ExAqlResultID;
                                                })}
                                                onChange={event => handleMainChange(event, 'EXAQL')}
                                            ></Reactselect></td>
                                            <td><textarea

                                                style={{ border: submitted && getMainInputField.ExAqlResultID === 2 && getMainInputField.ExRemarks === '' ? '1px solid red' : '' }}
                                                value={getMainInputField.ExRemarks}
                                                onChange={event => handleMainChange(event, 'ExFindingRemarks')} name="" id="" cols="100" rows="5"></textarea></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div> : ""
                }
                {
                    props.location.state.params.type === 2 ?
                        <div className="row" style={{ marginLeft: "0", marginTop: "2rem" }}>
                            <div className='form-group' style={{ pointerEvents: props.location.state.params.Action === 'View' ? 'none' : '' }}>
                                <label htmlFor="InspectosConclusion">Inspector's Conclusion</label>

                                <br />
                                <textarea value={getMainInputField.ExInspectorConclusion} onChange={event => handleMainChange(event, 'ExInspectorConclusion')} name="" id="" cols="210" rows="5"></textarea>
                            </div>
                        </div> : ""
                }
                {
                    props.location.state.params.type === 1 ?
                        <div className="row">
                            <div className="panel-group accordion" id="acc_Confirmation_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Confirmation_Info" href="#collapse_acc_Confirmation_Info">
                                                Confirmation of Available Information Result
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_acc_Confirmation_Info" className="panel-collapse collapse">
                                        <div className="panel-body border-red">
                                            <div className="col-md-12">
                                                <div className="row" style={{ pointerEvents: props.location.state.params.Action === 'View' ? 'none' : '' }}>
                                                    <Confirmavailableinfo props={props} getInspectionResult={getconfirmationResult} confirmcallback={confirmavailinfovalue} confoInspectionID={getInspectionID}
                                                        IsValidationCallback={Savevalidationcallback} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                }

                {
                    props.location.state.params.type === 1 ?
                        <div className="row">
                            <div className="panel-group accordion" id="acc_StyleandColor_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_StyleandColor_Info" href="#collapse_acc_StyleandColor_Info">
                                                Style and Colors Result
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_acc_StyleandColor_Info" className="panel-collapse collapse">
                                        <div className="panel-body border-red">
                                            <div className="col-md-12">
                                                <div className="row" >
                                                    <StyleAndColors props={props} BasicDetails={BasicDetails} styleandcolorcallback={styleAndColorsCallback} getExfacStrikeoffList={getartWorksexfacValue}
                                                        StyleAndColorsInspectionID={InspectionID} IsValidationCallback={Savevalidationcallback} orderDetailsCheck={checkorderdetails} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                }
                {
                    props.location.state.params.type === 1 ?
                        <div className="row">
                            <div className="panel-group accordion" id="acc_artwork_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_artwork_Info" href="#collapse_acc_artwork_Info">
                                                Strike Off - Artworks
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_acc_artwork_Info" className="panel-collapse collapse">
                                        <div className="panel-body border-red">
                                            <div className="col-md-12">
                                                <div className="row" >
                                                    <ArtWorks props={props} BasicDetails={BasicDetails} artWorkscallback={ArtWorksCallback} getExfacStrikeoffList={getartWorksexfacValue}
                                                        ArtWorksInspectionID={InspectionID} IsValidationCallback={Savevalidationcallback} orderDetailsCheck={checkorderdetails}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                }
                {
                    props.location.state.params.type === 1 ?
                        <div className="row">
                            <div className="panel-group accordion" id="acc_trims_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_trims_Info" href="#collapse_acc_trims_Info">
                                                Trims
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_acc_trims_Info" className="panel-collapse collapse">
                                        <div className="panel-body border-red">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <Trims props={props} BasicDetails={BasicDetails} finalTrimcallback={FinalInsTrimCallback} getexfacTrimlist={gettrimExfacValue}
                                                        TrimsInspectionID={InspectionID} IsValidationCallback={Savevalidationcallback} orderDetailsCheck={checkorderdetails}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                }
                {
                    props.location.state.params.type === 1 ?
                        <div className="row">
                            <div className="panel-group accordion" id="acc_packing_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_packing_Info" href="#collapse_acc_packing_Info">
                                                Packing Item
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_acc_packing_Info" className="panel-collapse collapse">
                                        <div className="panel-body border-red">
                                            <div className="col-md-12">
                                                <div className="row" >
                                                    <div className="text-right mb-8">
                                                        <button onClick={OpenWarehousePopup} className="btn btn-sm btn-success">Customer Warehouse Code</button>&nbsp;
                                                        {/* <button className="btn btn-sm btn-primary">View Packing Guide</button> */}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <Packing props={props} BasicDetails={BasicDetails} PackingCallback={PackingCallback} IsValidationCallback={Savevalidationcallback} PurchaseorderPassVal={getpurchaseOrderskuid} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                getWarehousePopup === true ? <Modal show={getWarehousePopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseWarehousePopup()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Customer Warehouse &nbsp;
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="inspection" style={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Warehouse Name</th>
                                                        <th>Warehouse Image</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        getnewwarehousedata.map((x, index) => (
                                                            <tr key={
                                                                index
                                                            }>
                                                                <td>
                                                                    {x.WarehouseName}

                                                                </td>
                                                                <td>
                                                                    {/* < img className="width_manual width_manual_image"
                                                                        src={window.$APIBaseURL + "Images/Report/" + x.FileName}
                                                                    ></img> */}
                                                                    < SimpleReactLightbox >
                                                                        <SRLWrapper >
                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                {
                                                                                    < img className="width_manual width_manual_image"
                                                                                        src={window.$APIBaseURL + "Images/Report/" + x.FileName}
                                                                                    ></img>
                                                                                }
                                                                                &nbsp;
                                                                                &nbsp;

                                                                            </div>
                                                                        </SRLWrapper>
                                                                    </SimpleReactLightbox>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    {
                                                        getnewwarehousedata.length === 0 ?
                                                            <tr>
                                                                <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                            </tr> : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="danger" onClick={() => handleCloseWarehousePopup()}>
                                            <i className="fa fa-times"></i>&nbsp;Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal> : ''
                            }




                        </div> : ""
                }
                {
                    props.location.state.params.type === 1 ?
                        <div className="row">
                            <div className="panel-group accordion" id="acc_workmanship_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_workmanship_Info" href="#collapse_acc_workmanship_Info">
                                                Workmanship
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_acc_workmanship_Info" className="panel-collapse collapse">
                                        <div className="panel-body border-red">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <WorkmanshipTemp props={props} BasicDetails={BasicDetails} WorkmanshipCallback={WorkmanshipCallback} WorkmanshipTempInspectionID={InspectionID}
                                                        IsValidationCallback={Savevalidationcallback} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                }
                {
                    props.location.state.params.type === 1 ?
                        <div className="row">
                            <div className="panel-group accordion" id="acc_testreportcheck_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_testreportcheck_Info" href="#collapse_acc_testreportcheck_Info">
                                                Test Report Check
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_acc_testreportcheck_Info" className="panel-collapse collapse">
                                        <div className="panel-body border-red">
                                            <div className="col-md-12">
                                                <div className="row" >
                                                    <TestReportCheck props={props} BasicDetails={BasicDetails} TestingReportCallback={TestReportCheckCallback} TestReportInspectionID={InspectionID}
                                                        IsValidationCallback={Savevalidationcallback} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                }
                {
                    props.location.state.params.type === 1 ?
                        <div className="row">
                            <div className="panel-group accordion" id="acc_cusspecialreq_Info">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#cusspecialreq_Info" href="#collapse_acc_cusspecialreq_Info">
                                                Customer Special Requirement
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse_acc_cusspecialreq_Info" className="panel-collapse collapse">
                                        <div className="panel-body border-red">
                                            <div className="col-md-12">
                                                <div className="row" >
                                                    <Csr props={props} BasicDetails={BasicDetails} CSRCallback={CSRDetailsCallback} CSRInspectionID={InspectionID}
                                                        IsValidationCallback={Savevalidationcallback} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                }
            </div>
            {loader}
        </Fragment >
    )
};
export default React.memo(FinalInspectionMainInformation);