import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadTNATaskOwner, getTNACompanyHolidayList, updateTNA, loadTNAChildReferenceUnMappedList, getTNAEditTaskWiseChildList, loadTNASupplierEmployeeList, loadNewPurchaseOrderSKUList, getSkuAndStyleDetails } from "../../actions/tna";
import Reactselect from 'react-select';

import { Modal, Button, ModalBody } from "react-bootstrap";
import taskService from "../../services/Master/TaskService";
import Nodify from "../Common/ReactNotification";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { retrieveTask, loadTaskList, loadTaskHolderList } from "../../actions/task";
import { loadTaskTypeList } from "../../actions/taskType";
import { loadLeaveDaysList } from "../../actions/leaveDay";
import { loadRouteDependencyList } from "../../actions/route";
import DatePicker from "react-datepicker";
import moment from "moment";
import { loadTNADetailList, GetSubChildMultExFactoryFormSKUList, GetTestingMultExFactorySKUList, loadTNADetailActivateList } from "../../actions/tna";
import Lightbox from "react-image-lightbox";
import axios from "axios";
import $ from "jquery";
import ReactNotification from 'react-notifications-component';
import SweetAlertPopup from "../Common/SweetAlertPopup";
import { TNAManualCloseParent, TNAManualCloseChildLibDip, TNAManualCloseChildStrikeOff, TNAManualCloseChildTrim, TNAManualCloseChildProtoFit, TNAManualCloseChildSms, smsapprovalId, TNAManualCloseChildExFactory, TNAManualCloseChildStrikeoffTest, TNAManualCloseChildTrimTest, FinalinspectionID, QAInspectionId, TestingMainlist } from "../Common/PageActionNumber";
import CommonStyle from "../Style/CommonStyle";
import TrimSubmission from "../TNA/TrimSubmission";
import TrimApproval from "../TNA/TrimApproval";
import LabdibSubmission from "../TNA/LabdibSubmission";
import LabdibApproval from "../TNA/LabdibApproval";
import StrikeOffSubmission from "../TNA/StrikeOffSubmission";
import StrikeApproval from "../TNA/StrikeApproval";
import ProtoFitSMSSubmission from "../TNA/ProtoFitSMSSubmission";
import ProtoFitSMSApproval from "../TNA/ProtoFitSMSApproval";
import AfterWashList from "../TNA/AppearanceAfterWash";
import TnaService from "../../services/TNA/TNAService";
import TNAService from "../../services/TNA/TNAService";
import PurchaseOrderSKUPopup from "../TNA/PurchaseOrderSKUPopup";
// import ProgramService from "../../services/Style/ProgramService";
import Aqlfinalinspection from "../../services/Master/Aqlfinalinspection";
// import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
// import FabricService from "../../services/Master/FabricService";
import CommonService from '../../services/Common/CommonService';
import FinalinspectionService from "../../services/FinalInspection/FinalinspectionService";
import StyleService from "../../services/Style/styleService";
import { defaultGroupByFn } from "react-table";
import AddInspection from "./../Production/Inspection_Quality Control/InspectionQC/AddInspection";
import ReInspection from "./../Production/Inspection_Quality Control/InspectionQC/ReInspection";
import { Redirect } from 'react-router-dom';
import AddQCInspection from "../Production/Inspection_Quality Control/InspectionQC/AddQCinspection";
import QcInspection from "../../services/QCInspection/QcInspection";
import QAReInspection from "../Production/Inspection_Quality Control/InspectionQC/QAReinspection";
import NewTesting from "../Production/Inspection_Quality Control/InspectionQC/NewTesting";
import TestingPopup from '../Production/Inspection_Quality Control/InspectionQC/TestingPopupList';
import ReTesting from "../Production/Inspection_Quality Control/InspectionQC/ReTesting";
import FabricService from "../../services/Master/FabricService";
import ConfirmationPopup from "../Common/ConfirmationPopup";
import { decodeFromBase64DataUri } from "pdf-lib";
import { parse } from "node-xlsx";

const EditTNARoute = (props) => {

    let InfoSelectedDependency;
    let InfoIncreament;
    let CountSameColor = 0;
    //const getRouteList = useSelector((state) => state.tna.drpTNARouteList);

    // Copy the TNAEdit page and paste it the new window,have empty Probs
    // if (props.location.state === undefined) {
    //     return <Redirect to="/TNAList" />;
    //     // props.history.push('/TNAList', { params: [] });
    // }
    // End
    // const activeMenus = useSelector((state) => state.auth.activeMenuList);
    // let IsProduction = activeMenus.IsProduction;
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;

    const reducerState = useSelector((state) => state);
    const AllRouteInfo = useSelector((state) => state.tna.TNADetailList);
    const getTaskTypeList = useSelector((state) => state.taskType.drpTaskTypeMapList);
    const { user: currentUser } = useSelector((state) => state.auth);
    const getLeaveDaysList = useSelector((state) => state.leaveDay.drpLeaveDaysList);
    const ExistingList = useSelector((state) => state.task.taskList);


    const getDependencyList = useSelector((state) => state.route.routeDependencyList);
    const taskHolderList = useSelector((state) => state.task.drpTaskHolderList);
    const drpTNATaskOwnerList = useSelector((state) => state.tna.drpTNATaskOwnerList);
    const getCompanyHolidayList = useSelector((state) => state.tna.TNACompanyHolidayList);
    const isLoadingTNA = useSelector((state) => state.tna.isLoadingTNA);
    const getTNAStatusList = useSelector((state) => state.tna.TNAStatusList);
    const TNAMultiTask = useSelector((state) => state.tna.TNAMultiTask);
    const StaticTask = useSelector((state) => state.tna.StaticTask);
    const SupplierEmployeeList = useSelector((state) => state.tna.drpSupplierEmployeeList);
    const NewPurchaseOrderSKUList = useSelector((state) => state.tna.newPurchaseOrderSKUList);
    const styleSampleRequestSKUList = useSelector((state) => state.tna.styleSampleRequestSKUList);
    const styleStrikeOffAndTrimSKUList = useSelector((state) => state.tna.styleStrikeOffAndTrimSKUList);

    const [getTrimmodalpopup, setTrimmodalpopup] = useState(false);
    const [getCommentSaveText, setCommentSaveText] = useState("Save");
    const [getTrimApprovalpopup, setTrimApprovalpopup] = useState(false);
    const [getLapmodal, setLapmodal] = useState(false);
    const [getOpenLabdipApppopup, setOpenLabdipApppopup] = useState(false);
    const [strikeoffModalpopup, setStrikeOffmodalpopup] = useState(false);
    const [getstrikeoffApppopup, setStrikeOffApppopup] = useState(false);
    const [getSampleSubModalpopup, setSampleSubModalpopup] = useState(false);
    const [getSampleAppModalpopup, setSampleAppModalpopup] = useState(false);
    const [getApperancemodalpopup, setApperancemodalpopup] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [getID, setID] = useState(0);
    const [getsID, setsID] = useState(0);
    const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    // FOr QA Audit
    const [getIDQATask, setIDQATask] = useState({ showPopupDelete: false, Params: {} });
    const [getIDTestTask, setIDTestTask] = useState({ showPopupDelete: false, Params: {} });
    const [editLabdipSubmission, seteditLabdipSubmission] = useState(false);
    const [deleteLabdipSubmission, setdeleteLabdipSubmission] = useState(false);
    const [SubmitChildId, setSubmitChildId] = useState(0);

    const [submitdate, setdates] = useState(false)
    //for disable
    const [Labdipdisable, setLabdipdisable] = useState(false);
    const [Strikeoffdisable, setStrikeoffdisable] = useState(false);
    const [Trimdisable, setTrimdisable] = useState(false);
    const [getskusingleID, setskusingleID] = useState();
    const [editLabdipApproval, seteditLabdipApproval] = useState(false);
    const [getexistsubmission, setexistsubmission] = useState(false);
    const [deleteLabdipApproval, setdeleteLabdipApproval] = useState(false);

    const [editStrikeOffSubmission, seteditStrikeOffSubmission] = useState(false);
    const [delteStrikeOffSubmission, setdeleteStrikeOffSubmission] = useState(false);

    const [editStrikeOffApproval, seteditStrikeOffApproval] = useState(false);
    const [delteStrikeOffApproval, setdeleteStrikeOffApproval] = useState(false);

    const [editTrimSubmission, seteditTrimSubmission] = useState(false);
    const [delteTrimSubmission, setdeleteTrimSubmission] = useState(false);

    const [editTrimApproval, seteditTrimApproval] = useState(false);
    const [deleteTrimApproval, setdeleteTrimApproval] = useState(false);

    const [editProtoFitSmsSubmission, seteditProtoFitSmsSubmission] = useState(false);
    const [delteProtoFitSmsSubmission, setdeleteProtoFitSmsSubmission] = useState(false);

    const [editProtoFitSmsApproval, seteditProtoFitSmsApproval] = useState(false);
    const [delteProtoFitSmsApproval, setdeleteProtoFitSmsApproval] = useState(false);
    const [IsPOSKUPopup, setPOSKUPopup] = useState(false);
    const [IsRemovePOSKUPopup, setRemovePOSKUPopup] = useState(false);

    const [getIsFinalInspectionReportOpen, setIsFinalInspectionReportOpen] = useState(false);

    const [editAppearanceAfterWash, seteditAppearanceAfterWash] = useState(false);
    const [deleteAppearanceAfterWash, setdeleteAppearanceAfterWash] = useState(false);

    const [selectall, setselectall] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [MultExFactorySKUList, setMultExFactorySKUList] = useState([]);

    const [LabdipApprovalList, setLabdipApprovalList] = useState([]);
    //const [Buttonvisible, SetButtonvisible] = useState();

    if (props.location.state === undefined) {

        let storedChildTask = localStorage.getItem('childTask');

        storedChildTask = JSON.parse(storedChildTask);

        // Update the value from props
        storedChildTask.tnaid = storedChildTask.TNAId;
        storedChildTask.styleId = storedChildTask.StyleID;
        props.location.state = { params: storedChildTask };

    }

    const [isLastEdit, setLastEdit] = useState(props.location.state.params.creationStatus === 1 ? false : true);
    const getTNABrandID = props.location.state.params.brandID;
    const getTNASupplierID = props.location.state.params.supplierId;
    const getTNABuyerID = props.location.state.params.buyerID;
    const getTNASeasonID = props.location.state.params.seasonID;
    // const getassignAutocalculation= props.location.state.params.assignAutocalculation;



    const [LabdipswatchDownload, setLabdipswatchDownload] = useState(false);
    const [LabdipswatchUpload, setLabdipswatchUpload] = useState(false);

    const [isSkuAndStyleDetailPopup, setSkuAndStyleDetailPopup] = useState(false);
    const [SkuAndStyleDetails, setSkuAndStyleDetails] = useState([]);

    const [StrikeoffswatchDownload, setStrikeoffswatchDownload] = useState(false);
    const [StrikeoffswatchUpload, setStrikeoffswatchUpload] = useState(false);
    const [TrimswatchDownload, setTrimswatchDownload] = useState(false);
    const [TrimswatchUpload, setTrimswatchUpload] = useState(false);
    //commonstyleheader
    const [getStyleInfo, setStyleInfo] = useState([]);
    const [getCALCParams, setCALCParams] = useState({ isDuration: false, isEndDate: false, index: -1 });
    const CALCParams = { ...getCALCParams };
    const [getInspectionInfo, setInspectionInfo] = useState([{
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    }]);
    const [getQCInspectionInfo, setQCInspectionInfo] = useState([{
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    }]);

    const [getTestingTaskInfo, setTestingTaskInfo] = useState([{
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    }]);



    let Buttonvisible = props.location.state.params.action;
    let IsParentDateShow = false;

    // For Unavailable Task
    const [taskNameList, settaskNameList] = useState(props.location.state.params.taskNameList);
    const [SkuUnMappedList, setSkuUnMappedList] = useState(props.location.state.params.SkuUnMappedList);
    const [SkuUnMappedListCurrPage, setSkuUnMappedListCurrPage] = useState(props.location.state.params.SkuUnMappedListCurrPage);


    const [AllDataLoaded, setAllDataLoaded] = useState(false);

    const [ExistingSkuList, setExistingSkuList] = useState([]);
    const [POSkuIsAllChecked, setPOSkuIsAllChecked] = useState(false);
    const [EDcalconoff, setEDchangecalconoff] = useState(false);
    const [EDcalconoffpopup, setEDcalconoffpopup] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    //Enable Menu


    //Final Inspection
    const [getQAInspectionPageView, setQAInspectionPageView] = useState('AddInspection');
    const [getFinalInspectionPageView, setFinalInspectionPageView] = useState('AddInspection');
    const [getTestingReportPageView, setTestingReportPageView] = useState('AddInspection');
    const [getAddInspectionListParam, setAddInspectionListParam] = useState([]);
    //QC FINALINSPECTION
    const [getAddQCInspectionListParam, setQCAddInspectionListParam] = useState([]);
    // TESTING
    const [getTestingTaskListParam, setTestingTaskListParam] = useState([]);
    const [getSubTaskList, setSubTaskList] = useState([]);

    const [getIsAddFinalInspection, setIsAddFinalInspection] = useState(false);
    const [getfinalinspectionMulti, setfinalinspectionMulti] = useState([]);
    const [getQCfinalinspectionMulti, setQCfinalinspectionMulti] = useState([]);
    const [getTestinginspectionMulti, setTestinginspectionMulti] = useState([]);

    //ADD New SKU Task
    const [newskuTask, setNewskuTask] = useState(false)
    const [skuTask, setSkuTask] = useState([{
        ParentTaskName: '',
        ParentIndex: 0,
        IsExpanded: false,
        checkbox: false,
        SubTaskList: [{
            ParentTaskName: '',
            SubTaskName: '',
            SubIndex: 0,
            isChecked: false,
            IsExpanded: false,
            Childtask: []
        }]
    }])

    let activeMenu;
    let activeMenuSub;
    let TaskCloseCompleteInfo;
    let CommentsInfo;
    let AttachmentInfo;
    let StartStopInfo;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === 'TNA');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);

            if (activeMenuSub.length > 0) {

                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === 'TNACloseComplate') {
                        TaskCloseCompleteInfo = activeMenuSub[index];
                    }
                    else if (activeMenuSub[index].menuName === 'TNAComment') {
                        CommentsInfo = activeMenuSub[index];

                    }
                    else if (activeMenuSub[index].menuName === 'TNAAttachment') {
                        AttachmentInfo = activeMenuSub[index];

                    }
                    else if (activeMenuSub[index].menuName === 'TNAstartstop') {
                        StartStopInfo = activeMenuSub[index];
                    }

                    // else if (activeMenuSub[index].menuName === 'StyleMeasurement') {
                    //     //MeasurementInfo = activeMenuSub[index];

                    // }
                    // else if (activeMenuSub[index].menuName === 'StyleSample') {
                    //    // SampleInfo = activeMenuSub[index];

                    // }
                    // else if (activeMenuSub[index].menuName === 'StyleTNAView') {
                    //     //TNAViewInfo = activeMenuSub[index];

                    // }




                }
            }
        }
    }

    // End Enable Menu

    //declaration

    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    //State

    const [MainFields, setMainFields] = useState([{
        TNAId: props.location.state.params.tnaid,
        CreationStatus: props.location.state.params.creationStatus === 2 ? 3 : 2,
        TNARouteApplyDetails: [],
        TNADependencyDetails: [],
        TNAAdditionalTaskDetails: [],
        TNAChildInformation: [],
        TNASubChildInformation: [],
        TypTNAChildDependencyDetails: [],
        TNADocs: [],
        TNACommentsInformation: [],
        CreatedBy: currentUser.employeeinformationID,
    }]);

    const [MultiManualCloseFeilds, setMultiManualCloseFeilds] = useState({ IdList: '', TaskStatus: 0, ManualCloseDate: '', ManualCloseComment: '', Createdby: 1 });
    const EmptyInputFields = [];

    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [inputFieldsChildDep, setInputFieldsChildDep] = useState([]);
    const [getnewfinalReportlist, setnewfinalReportlist] = useState([]);
    const [getnewheaderdata, setnewheaderdata] = useState([]);
    //Testing
    const [getnewTestingReportlist, setnewTestingReportlist] = useState([]);
    const [getTestingheaderData, setTestingheaderdata] = useState([]);

    //QA
    const [getnewQAReportlist, setnewQAReportlist] = useState([]);
    const [getQAheaderData, setQAheaderdata] = useState([]);

    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getTaskHolderList, setTaskHolderList] = useState([]);
    const [getHeaderName, addHeaderName] = useState("Edit");


    //gowtham
    const [getComment, setComment] = useState(false);
    const [getmaincomment, setmaincomment] = useState(false);
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    //sathish
    const [AtteIndex, setAtteIndex] = useState('');
    const [getFiles, setFiles] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getres, setres] = useState(0);

    const [getTaskIndexFileupload, setTaskIndexFileupload] = useState();
    const [getRevicedIndex, setRevicedIndex] = useState();
    const [getTNAtaskIdFileupload, setTNAtaskIdFileupload] = useState();
    const [getCurrentChildTask, setCurrentChildTask] = useState({ value: 0, lablel: '' });
    const [manualApprovalData, setmanualApprovalData] = useState({ manualCloseComment: '', files: [] });
    const [existingApprovalData, setexistingApprovalData] = useState([]);

    const [getSubRevicedetails, setSubRevicedetails] = useState([]);
    const [initialData, setinitialData] = useState({
        TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
        ReviceComment: '', TaskName: '', Operation: 0,
        StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0
    });
    const [getSubReviceList, setSubReviceList] = useState([]);
    const [getReviceAction, setReviceAction] = useState('Add');
    const [Submitrevice, setSubmitRevice] = useState(false);
    const [ReviceTaskDetailId, setReviceTaskDetailId] = useState();
    const [ReviceTnalId, setReviceTnaId] = useState();
    const [ReviceTnalTask, setReviceTnaTask] = useState();

    const [getRevice, setRevice] = useState(false);

    const [RevicePopup, setRevicePopup] = useState(false);
    //Reschedule Start
    const [getSubRescheduledetails, setSubRescheduledetails] = useState({
        TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
        RescheduleComment: '', TaskName: '', Operation: 0,
        StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
        Createdby: 0, Type: ''
    });
    const [getSubRescheduleList, setSubRescheduleList] = useState([]);
    const [RescheduleComment, setRescheduleComment] = useState('');
    const [getDependencyStartList, setDependencyStartList] = useState([]);
    const [submitDependencyStart, setSubmitDependencyStart] = useState(false);
    const [getRescheduleAction, setRescheduleAction] = useState('Add');
    const [Submitreschedule, setSubmitReschedule] = useState(false);
    const [RescheduleTaskDetailId, setRescheduleTaskDetailId] = useState();
    const [RescheduleTnalId, setRescheduleTnaId] = useState();
    const [RescheduleTnalTask, setRescheduleTnaTask] = useState();

    const [getReschedule, setReschedule] = useState(false);

    const [ReschedulePopup, setReschedulePopup] = useState(false);
    const [ReviceComment, setReviceComment] = useState('');
    const [protofitsmsDisable, setprotofitsmsDisable] = useState(false);

    const [Depandencyrowno, setDepandencyrowno] = useState('');



    const [OrderInfoMulticloseMainDetails, setOrderInfoMulticloseMainDetails] = useState([]);
    const [expandedRowsMultiSelect, setExpandedRowsMultiSelect] = useState([]);
    const [expandListMultiSelect, setexpandListMultiSelect] = useState({});
    const [expandStateMultiSelect, setExpandStateMultiSelect] = useState({});
    const [getid, setid] = useState({ showPopupConfirm: false, Params: {} });
    //Reschedule End
    useEffect(() => {
        // console.log(new Date());

        showLoader();
        dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
        //localStorage.removeItem('childTask');
        dispatch(loadTNASupplierEmployeeList(getTNASupplierID));
    }, []);
    const [ChildDepPopupMode, setChildDepPopupMode] = useState('');
    const ChildDepandencyValues = [...inputFieldsChildDep];
    const [DepandencyAllIndex, setDepandencyAllIndex] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0, RowIndex: 0, MainChildId: 0 });
    const [Indexs, setIndexs] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0, FieldName: '' });
    const values = [...inputFields];
    const MainValues = [...MainFields];
    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 190),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);


    useEffect(() => {
        if (!isLoadingTNA) {
            // hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTNA])


    useEffect(() => {

        StyleService.GetStyleList(props.location.state.params.styleId, IsProduction, 0, '01/01/1949', moment(stateDate[0].endDate).format('MM/DD/YYYY')).then((response) => {
            if (response.data) {
                setStyleInfo(response.data.item1[0]);
                setInspectionInfo({
                    buyerID: response.data.item1[0].buyerID
                    , buyerName: response.data.item1[0].buyerName
                    , brandID: response.data.item1[0].brandID
                    , brandName: response.data.item1[0].brandName
                    , seasonName: response.data.item1[0].seasonName
                    , seasonID: response.data.item1[0].seasonID
                    , supplierID: response.data.item1[0].supplierID
                    , supplierName: props.location.state.params.supplierName
                    , styleID: props.location.state.params.styleId
                    , styleName: props.location.state.params.styleNames
                    , type: 0
                    , TaskStatus: 0
                });
                setQCInspectionInfo({
                    buyerID: response.data.item1[0].buyerID
                    , buyerName: response.data.item1[0].buyerName
                    , brandID: response.data.item1[0].brandID
                    , brandName: response.data.item1[0].brandName
                    , seasonName: response.data.item1[0].seasonName
                    , seasonID: response.data.item1[0].seasonID
                    , supplierID: response.data.item1[0].supplierID
                    , supplierName: props.location.state.params.supplierName
                    , styleID: props.location.state.params.styleId
                    , styleName: props.location.state.params.styleNames
                    , styleNo: response.data.item1[0].styleNo
                    , type: 0
                    , TaskStatus: 0
                })
                setTestingTaskInfo({
                    buyerID: response.data.item1[0].buyerID
                    , buyerName: response.data.item1[0].buyerName
                    , brandID: response.data.item1[0].brandID
                    , brandName: response.data.item1[0].brandName
                    , seasonName: response.data.item1[0].seasonName
                    , seasonID: response.data.item1[0].seasonID
                    //, supplierID: response.data.item1[0].supplierID
                    , supplierName: props.location.state.params.supplierName
                    , styleID: props.location.state.params.styleId
                    , styleName: props.location.state.params.styleNames
                    , styleNo: response.data.item1[0].styleNo
                    , type: 0
                    , TaskStatus: 0
                })
            }
        }).catch(() => { });

        // task holder for sample

        TnaService.TaskHolderList(props.location.state.params.tnaid).then((response) => {
            if (response.data) {
                setTaskholderlist(response.data);
            }
        })




    }, []);


    useMemo(
        () => {

            let Optionvalue = [];
            taskHolderList && taskHolderList.map(element => {
                let LabelColor = '';
                if (element.label === 'Buyer - -') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Buyer - Cube') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Buyer - Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Buyer - Cube,Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - -') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Cube - Buyer') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Cube - Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - Buyer,Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - -') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - Buyer') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Supplier - Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Supplier - Buyer,Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }

                Optionvalue.push({
                    value: element.value,
                    label: LabelColor,
                    subLabel: element.label,
                })
            });

            setTaskHolderList(Optionvalue);

        },
        [taskHolderList]
    );



    useMemo(
        () => {


            if (AllRouteInfo.tnaDetailList) {

                let routeInfo = AllRouteInfo.tnaDetailList;
                let dependentInfo = AllRouteInfo.tnaDetailDependencyList;
                let additionalInfo = AllRouteInfo.tnaDetailTaskList;
                let parentChildList = AllRouteInfo.tnaParentChildList;
                let childList = AllRouteInfo.tnaChildList;
                let Comments = AllRouteInfo.tnaCommentsList;
                let Attachments = AllRouteInfo.tnaAttachmentsList;
                let ChildDepandencyInfo = AllRouteInfo.tnaChildDependencyDetails;

                let routeInformation = [];
                let ChildIndex = 1;
                let RownoforAllTasks = 1;

                if (AllRouteInfo) {
                    showLoader();
                    if (routeInfo) {
                        if (routeInfo.length > 0) {
                            //setInputFields([]);

                            routeInfo.map((route, i) => {

                                let employee1 = "";
                                let employee2 = "";
                                let employee3 = "";

                                if (route.taskHolderName && route.taskHolderName) {
                                    let TaskHolderName = route.taskHolderName.split('-');
                                    if (TaskHolderName[0] !== undefined) {
                                        employee1 = TaskHolderName[0].trim();

                                        if (TaskHolderName[1] !== undefined) {
                                            let FollowerName = TaskHolderName[1].split(',');
                                            if (FollowerName[0] !== undefined) {
                                                employee2 = FollowerName[0].trim();

                                                if (FollowerName[1] !== undefined) {
                                                    employee3 = FollowerName[1].trim();
                                                } else {
                                                    employee3 = "";
                                                }
                                            } else {
                                                employee2 = "";
                                                employee3 = "";
                                            }

                                        } else {
                                            employee2 = "";
                                            employee3 = "";
                                        }
                                    }
                                }


                                let StartDate = new Date(route.planStartDate);
                                // StartDate.setMinutes(StartDate.getMinutes() + 370);
                                let EndDate = new Date(route.endDate);
                                //EndDate.setMinutes(EndDate.getMinutes() + 370);
                                let ImageUrl = "";

                                if (route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                                    ImageUrl = 'Images/Style/Strikeoff/';
                                } else if (route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                                    ImageUrl = 'Images/Style/Trim/';
                                }


                                let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === route.taskName.toLowerCase());
                                if (route.detailTypeID === 4) { // Ex-Factory  (Detail Type)
                                    IsSingleTask = false;
                                }
                                if (route.detailTypeID === 1) { //common
                                    IsSingleTask = true;
                                }

                                routeInformation.push({
                                    TNARouteApplyID: route.tnaRouteApplyID,
                                    TaskFieldEnable: 0,
                                    TaskType: route.taskType,
                                    TaskTypeTxt: route.taskTypeName,
                                    TaskID: route.taskNameID,
                                    strTaskName: route.taskName,
                                    EndDate: EndDate,
                                    StartDate: StartDate,
                                    PreStartDate: StartDate,
                                    PreEndDate: EndDate,
                                    Duration: route.duration,
                                    intDependency: route.dependencyStartstop,
                                    hasAllDependencyStart: 0,
                                    hasAllDependencyStartPopup: false,
                                    arrDependency: [],
                                    arrComment: [],
                                    arrAttachment: [],
                                    arrManual: [],
                                    isProgramNewtask: false,
                                    ManualComments: route.manualCloseComment,
                                    //ManualCloseDate: route.ManualCloseDate,
                                    CommentsCount: route.taskCommentsCount,
                                    AttachmentCount: route.taskDocumentsCount,
                                    TaskHolderList: '',
                                    TaskHolderOrFollower: route.taskHolderID,
                                    TaskHolderOrFollowerTxt: route.taskHolderName && route.taskHolderName.replace(" - -", ""),
                                    TaskHolderOwnerTxtList: route.taskOwnerNames,
                                    TaskHolderIDList: route.taskHolderIDList,
                                    FollowerIDList1: route.followerIDList1,
                                    FollowerIDList2: route.followerIDList2,
                                    TaskHolderBuyer: route.taskHolderIDList,
                                    FollowerCube: route.followerIDList1,
                                    FollowerSupplier: route.followerIDList2,
                                    TaskHolderOwnerIdList: route.taskOwner,
                                    titleEmployee1: employee1,
                                    titleEmployee2: employee2,
                                    titleEmployee3: employee3,
                                    LeaveDays: route.leaveDays,
                                    IsTHFirstTime: 0,
                                    LeaveDaysTxt: route.leaveDayName,
                                    AdditionalFollowup: additionalInfo.filter(x => x.indexName === route.indexName && x.isTaskSelected === 1).length,
                                    IsFollowupExist: additionalInfo.filter(x => x.indexName === route.indexName).length,
                                    DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
                                    IndexName: route.indexName,
                                    //PreferenceId: route.preferenceId,
                                    IsPopupOpen: 0,
                                    IsDependencyOpen: 0,
                                    IsTHLeaveDaysOpen: 0,
                                    IsComment: 0,
                                    IsAttachment: 0,
                                    IsSelectAll: route.isSelectAll,
                                    FollowupDetails: [],
                                    SelectedDependency: route.dependency,
                                    arrTaskHolderBuyer: [],
                                    arrFollowerCube: [],
                                    arrFollowerSupplier: [],
                                    TaskHolderBuyerTxt: '',
                                    FollowerCubeTxt: '',
                                    FollowerSupplierTxt: '',
                                    IsSingleTask: IsSingleTask,
                                    IsNewRow: true,
                                    StatusID: route.routeApplyStatus === 0 ? 1 : route.routeApplyStatus,
                                    AWBStatus: route.awbStatus,
                                    ActualEndDate: route.actualEndDate,
                                    ManualCloseDate: route.actualEndDate === null || route.actualEndDate === "" ? '' : new Date(route.actualEndDate),
                                    SubTaskList: [],
                                    EndDateChange: 0,
                                    IDuration: route.duration,
                                    IEndDate: EndDate,
                                    IStartDate: StartDate,
                                    RevicePopup: 0,
                                    IsReviced: route.isReviced,
                                    DependencyDate: route.dependencyDate,
                                    DependencyComment: route.dependencyComment,
                                    IsRescheduled: route.isRescheduled,
                                    IsDependencyStartPopup: false,
                                    ReviceSelected: 0,
                                    RescheduleSelected: 0,
                                    ReschedulePopup: 0,
                                    MainRevicedetails:
                                    {
                                        TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                                        ReviceComment: '', TaskName: '', Operation: 0,
                                        StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                        Createdby: 0, Type: ''
                                    },
                                    MainRescheduledetails:
                                    {
                                        TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                                        RescheduleComment: '', TaskName: '', Operation: 0,
                                        StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                        Createdby: 0, Type: ''
                                    },
                                    DetailTypeID: route.detailTypeID,
                                    IsExpanded: false,
                                    ReviceAdded: 0,
                                    RescheduleAdded: 0,
                                    IsNotMatchSku: false,
                                    RownoforAllTasks: RownoforAllTasks
                                    // IsRescheduled: route.IsRescheduled
                                });



                                let ParentActualdate = false;
                                if (IsSingleTask === false) {
                                    parentChildList && parentChildList.filter(d => d.taskID === route.taskNameID).map((element, p) => {
                                        routeInformation[i].SubTaskList.push({
                                            ParentId: element.parentId,
                                            TaskID: element.taskID,
                                            MainId: element.mainId,
                                            Code: element.code,
                                            Color: element.color,
                                            ColorOrName: element.colorOrName,
                                            ImagePath: element.imagePath,
                                            ImageUrl: ImageUrl,
                                            StrikeOffOrTrimInfoId: element.strikeOffIdOrTrimInfoId,
                                            IsExpanded: false,
                                            hasAutoOpen: false,
                                            ChildTaskList: []
                                        })

                                        //check detailtype if it ex-factory then sort data by exfactory date
                                        if (route.detailTypeID === 4) { // Ex-Factory  (Detail Type)

                                            childList && childList.filter(d => d.taskID === route.taskNameID && d.mainId === element.mainId)
                                                .sort((a, b) => new Date(a.exFacDate) - new Date(b.exFacDate))
                                                .map((child, c) => {
                                                    let childStartDate = isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate);
                                                    // childStartDate.setMinutes(childStartDate.getMinutes() + 370);
                                                    let childEndDate = isLastEdit ? new Date(child.endDate) : new Date(route.endDate);
                                                    // childEndDate.setMinutes(childEndDate.getMinutes() + 370);
                                                    routeInformation[i].SubTaskList[p].ChildTaskList.push({
                                                        ChildId: child.childId,
                                                        SubId: child.subId,
                                                        Name: child.name,
                                                        TaskName: route.taskName,
                                                        TaskID: child.taskID,
                                                        Isdraft: child.isDraft,
                                                        IsQAdraft: child.isQAdraft,
                                                        IsTestDraft: child.isTestDraft,
                                                        IsStrikedraft: child.isStrikedraft,
                                                        IsTrimDraft: child.isTrimDraft,
                                                        MainId: child.mainId,
                                                        Duration: isLastEdit ? child.duration : route.duration,
                                                        EndDate: childEndDate,
                                                        StartDate: childStartDate,
                                                        PreStartDate: childStartDate,
                                                        PreEndDate: childEndDate,
                                                        TaskHolderList: '',
                                                        TaskHolderOrFollower: route.taskHolderID,
                                                        TaskHolderOrFollowerTxt: route.taskHolderName && route.taskHolderName.replace(" - -", ""),
                                                        TaskHolderOwnerTxtList: route.taskOwnerNames,
                                                        TaskHolderIDList: route.taskHolderIDList,
                                                        FollowerIDList1: route.followerIDList1,
                                                        FollowerIDList2: route.followerIDList2,
                                                        TaskHolderBuyer: route.taskHolderIDList,
                                                        FollowerCube: route.followerIDList1,
                                                        FollowerSupplier: route.followerIDList2,
                                                        arrTaskHolderBuyer: [],
                                                        arrFollowerCube: [],
                                                        arrFollowerSupplier: [],
                                                        TaskHolderBuyerTxt: '',
                                                        FollowerCubeTxt: '',
                                                        FollowerSupplierTxt: '',
                                                        TaskHolderOwnerIdList: '',
                                                        titleEmployee1: employee1,
                                                        titleEmployee2: employee2,
                                                        titleEmployee3: employee3,
                                                        IsTHFirstTime: 0,
                                                        LeaveDays: child.leaveDays === 0 ? route.leaveDays : child.leaveDays,
                                                        LeaveDaysTxt: child.leaveDaysName === null ? route.leaveDayName : child.leaveDaysName,
                                                        StatusID: isLastEdit ? child.routeApplyStatus : 1,
                                                        AWBStatus: '',
                                                        IsAddChildTaskOpen: 0,
                                                        StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
                                                        drpChildTaskList: [],
                                                        IsManualClose: 0,
                                                        IsExFactoryForm: 0,
                                                        ManualCloseComment: child.manualCloseComment,
                                                        //ManualCloseDate: child.manualCloseDate,
                                                        IsShowSubmitButton: child.isShowSubmitButton,
                                                        IsShowApprovalButton: child.isShowApprovalButton,
                                                        ManualCloseTaskwise: child.ManualClose,
                                                        ChildActualEndDate: child.actualEndDate,
                                                        ManualCloseDate: child.actualEndDate === null || child.actualEndDate === "" ? '' : new Date(child.actualEndDate),
                                                        EndDateChange: 0,
                                                        ManualApprovalType: 0,
                                                        ExistingApprovalType: 0,
                                                        RevicePopup: 0,
                                                        IsReviced: child.isReviced,
                                                        intDependency: child.dependencyStartstop,
                                                        hasAllDependencyStart: 0,
                                                        hasAllDependencyStartPopup: false,
                                                        DependencyDate: child.dependencyDate,
                                                        DependencyComment: child.dependencyComment,
                                                        MultiDependencyDate: "",
                                                        MultiDependencyComment: "",
                                                        IsRescheduled: child.isRescheduled,
                                                        IsDependencyStartPopup: false,
                                                        IDuration: isLastEdit ? child.duration : route.duration,
                                                        IEndDate: childEndDate,
                                                        IStartDate: childStartDate,
                                                        ReviceSelected: 0,
                                                        RescheduleSelected: 0,
                                                        ReschedulePopup: 0,
                                                        ReviceAdded: 0,
                                                        RescheduleAdded: 0,
                                                        SubRevicedetails:
                                                        {
                                                            TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                                                            ReviceComment: '', TaskName: '', Operation: 0,
                                                            StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                                            Createdby: 0, Type: ''
                                                        },
                                                        SubRescheduledetails:
                                                        {
                                                            TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                                                            RescheduleComment: '', TaskName: '', Operation: 0,
                                                            StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                                            Createdby: 0, Type: ''
                                                        },
                                                        SwatchStatus: child.swatchStatus,
                                                        SKUIdList: child.subId.toString(),
                                                        PurchaseOrderSkuID: child.purchaseOrderSkuID,
                                                        StyleStrikeOffGarmentSizeIdList: child.styleStrikeOffGarmentSizeIdList,
                                                        hasAutoOpen: false,
                                                        IsChildDepandencyOpen: 0,
                                                        ChildIndex: ChildIndex,
                                                        arrDependency: [
                                                        ],
                                                        DependencyCount: child.depandencyCount,
                                                        SelectedDependency: child.selectedDependency === null ? '' : child.selectedDependency,
                                                        AssignedDepandency: child.assignedDepandency === null ? '' : child.assignedDepandency,
                                                        AssignedStartDate: '',
                                                        AssignedEndDate: '',
                                                        hasSKUNotMatch: false,
                                                        IsDepandency: 0,
                                                        ExFacDate: child.exFacDate,
                                                        TotalHoliday: '',
                                                        RownoforAllTasks: RownoforAllTasks

                                                    });

                                                    ChildDepandencyInfo && ChildDepandencyInfo.filter(x => x.mainChildId === child.childId).map((item, itemindex) => {

                                                        routeInformation[i].SubTaskList[p].ChildTaskList[c].arrDependency.push({
                                                            TNAChildDependencyID: item.tnaChildDependencyID,
                                                            DependentTaskId: item.dependentTaskId,
                                                            DependencyID: item.dependencyID,
                                                            LogDays: item.logDays,
                                                            IndexName: item.indexName,
                                                            Mainindex: item.mainChildIndex,
                                                            StartDate: new Date(item.startDate),
                                                            EndDate: new Date(item.endDate),
                                                            ChildIndex: isLastEdit ? itemindex + 1 : itemindex - 2,
                                                            //ChildIndex: isLastEdit ? itemindex - 1 : itemindex - 2,
                                                            IsSingleTask: routeInformation.filter(x => x.TNARouteApplyID === item.dependentTaskId).length > 0 ?
                                                                routeInformation.filter(x => x.TNARouteApplyID === item.dependentTaskId)[0].IsSingleTask : false

                                                        })
                                                    })
                                                    //  .map((item, itemindex = (!routeInformation[itemindex].IsSingleTask) ? 0 : itemindex) => {
                                                    // ChildDepandencyInfo
                                                    // .filter(x => x.mainChildId === child.childId)
                                                    // .map((item, index) => {
                                                    //     
                                                    //     const isSingleTask = routeInformation.filter(x => x.TNARouteApplyID === item.dependentTaskId).length > 0 ?
                                                    //     routeInformation.filter(x => x.TNARouteApplyID === item.dependentTaskId)[0].IsSingleTask : false;

                                                    //     let itemindex = isSingleTask ? index : 0;

                                                    //     let dependencyItem = {
                                                    //         TNAChildDependencyID: item.tnaChildDependencyID,
                                                    //         DependentTaskId: item.dependentTaskId,
                                                    //         DependencyID: item.dependencyID,
                                                    //         LogDays: item.logDays,
                                                    //         IndexName: item.indexName,
                                                    //         Mainindex: item.mainChildIndex,
                                                    //         StartDate: new Date(item.startDate),
                                                    //         EndDate: new Date(item.endDate),
                                                    //         ChildIndex: isLastEdit ? itemindex + 1 : itemindex - 2,
                                                    //         IsSingleTask: routeInformation.filter(x => x.TNARouteApplyID === item.dependentTaskId).length > 0 ?
                                                    //             routeInformation.filter(x => x.TNARouteApplyID === item.dependentTaskId)[0].IsSingleTask : false
                                                    //     };

                                                    //     routeInformation[i].SubTaskList[p].ChildTaskList[c].arrDependency.push(dependencyItem);
                                                    // });

                                                    ChildIndex++;
                                                    RownoforAllTasks++;
                                                    // If not need i think
                                                    // For Parent Actual date clear when was child task not have a close date
                                                    if (!ParentActualdate && child.actualEndDate === null) {
                                                        ParentActualdate = true;
                                                        routeInformation[i].ActualEndDate = null;
                                                    }

                                                    if (!ParentActualdate) {
                                                        routeInformation[i].ActualEndDate = child.actualEndDate;
                                                    }
                                                });
                                        }
                                        //else no sorting
                                        else {

                                            //let Chk ;

                                            childList && childList.filter(d => d.taskID === route.taskNameID && d.mainId === element.mainId).map((child, c) => {
                                                let childStartDate = isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate);
                                                // childStartDate.setMinutes(childStartDate.getMinutes() + 370);
                                                let childEndDate = isLastEdit ? new Date(child.endDate) : new Date(route.endDate);
                                                // childEndDate.setMinutes(childEndDate.getMinutes() + 370);
                                                routeInformation[i].SubTaskList[p].ChildTaskList.push({
                                                    ChildId: child.childId,
                                                    SubId: child.subId,
                                                    Name: child.name,
                                                    TaskName: route.taskName,
                                                    TaskID: child.taskID,
                                                    Isdraft: child.isDraft,
                                                    IsQAdraft: child.isQAdraft,
                                                    IsTestDraft: child.isTestDraft,
                                                    IsStrikedraft: child.isStrikedraft,
                                                    IsTrimDraft: child.isTrimDraft,
                                                    MainId: child.mainId,
                                                    Duration: isLastEdit ? child.duration : route.duration,
                                                    EndDate: childEndDate,
                                                    StartDate: childStartDate,
                                                    PreStartDate: childStartDate,
                                                    PreEndDate: childEndDate,
                                                    TaskHolderList: '',
                                                    TaskHolderOrFollower: route.taskHolderID,
                                                    TaskHolderOrFollowerTxt: route.taskHolderName && route.taskHolderName.replace(" - -", ""),
                                                    TaskHolderOwnerTxtList: route.taskOwnerNames,
                                                    TaskHolderIDList: route.taskHolderIDList,
                                                    FollowerIDList1: route.followerIDList1,
                                                    FollowerIDList2: route.followerIDList2,
                                                    TaskHolderBuyer: route.taskHolderIDList,
                                                    FollowerCube: route.followerIDList1,
                                                    FollowerSupplier: route.followerIDList2,
                                                    arrTaskHolderBuyer: [],
                                                    arrFollowerCube: [],
                                                    arrFollowerSupplier: [],
                                                    TaskHolderBuyerTxt: '',
                                                    FollowerCubeTxt: '',
                                                    FollowerSupplierTxt: '',
                                                    TaskHolderOwnerIdList: '',
                                                    titleEmployee1: employee1,
                                                    titleEmployee2: employee2,
                                                    titleEmployee3: employee3,
                                                    IsTHFirstTime: 0,
                                                    LeaveDays: child.leaveDays === 0 ? route.leaveDays : child.leaveDays,
                                                    LeaveDaysTxt: child.leaveDaysName === null ? route.leaveDayName : child.leaveDaysName,
                                                    StatusID: isLastEdit ? child.routeApplyStatus : 1,
                                                    AWBStatus: '',
                                                    IsAddChildTaskOpen: 0,
                                                    StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
                                                    drpChildTaskList: [],
                                                    IsManualClose: 0,
                                                    IsExFactoryForm: 0,
                                                    ManualCloseComment: child.manualCloseComment,
                                                    //ManualCloseDate: child.manualCloseDate,
                                                    IsShowSubmitButton: child.isShowSubmitButton,
                                                    IsShowApprovalButton: child.isShowApprovalButton,
                                                    ManualCloseTaskwise: child.ManualClose,
                                                    ChildActualEndDate: child.actualEndDate,
                                                    ManualCloseDate: child.actualEndDate === null || child.actualEndDate === "" ? '' : new Date(child.actualEndDate),
                                                    EndDateChange: 0,
                                                    ManualApprovalType: 0,
                                                    ExistingApprovalType: 0,
                                                    RevicePopup: 0,
                                                    IsReviced: child.isReviced,
                                                    intDependency: child.dependencyStartstop,
                                                    hasAllDependencyStart: 0,
                                                    hasAllDependencyStartPopup: false,
                                                    DependencyDate: child.dependencyDate,
                                                    DependencyComment: child.dependencyComment,
                                                    MultiDependencyDate: "",
                                                    MultiDependencyComment: "",
                                                    IsRescheduled: child.isRescheduled,
                                                    IsDependencyStartPopup: false,
                                                    IDuration: isLastEdit ? child.duration : route.duration,
                                                    IEndDate: childEndDate,
                                                    IStartDate: childStartDate,
                                                    ReviceSelected: 0,
                                                    RescheduleSelected: 0,
                                                    ReschedulePopup: 0,
                                                    ReviceAdded: 0,
                                                    RescheduleAdded: 0,
                                                    SubRevicedetails:
                                                    {
                                                        TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                                                        ReviceComment: '', TaskName: '', Operation: 0,
                                                        StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                                        Createdby: 0, Type: ''
                                                    },
                                                    SubRescheduledetails:
                                                    {
                                                        TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                                                        RescheduleComment: '', TaskName: '', Operation: 0,
                                                        StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                                        Createdby: 0, Type: ''
                                                    },
                                                    SwatchStatus: child.swatchStatus,
                                                    SKUIdList: child.skuIdList,
                                                    PurchaseOrderSkuID: child.purchaseOrderSkuID,
                                                    StyleStrikeOffGarmentSizeIdList: child.styleStrikeOffGarmentSizeIdList,
                                                    hasAutoOpen: false,
                                                    IsChildDepandencyOpen: 0,
                                                    ChildIndex: ChildIndex,
                                                    arrDependency: [
                                                    ],
                                                    DependencyCount: child.depandencyCount,
                                                    SelectedDependency: child.selectedDependency === null ? '' : child.selectedDependency,
                                                    AssignedDepandency: child.assignedDepandency === null ? '' : child.assignedDepandency,
                                                    AssignedStartDate: '',
                                                    AssignedEndDate: '',
                                                    hasSKUNotMatch: false,
                                                    IsDepandency: 0,
                                                    ExFacDate: child.exFacDate,
                                                    TotalHoliday: '',
                                                    RownoforAllTasks: RownoforAllTasks

                                                });

                                                ChildDepandencyInfo && ChildDepandencyInfo.filter(x => x.mainChildId === child.childId).map((item, itemindex) => {
                                                    routeInformation[i].SubTaskList[p].ChildTaskList[c].arrDependency.push({
                                                        TNAChildDependencyID: item.tnaChildDependencyID,
                                                        DependentTaskId: item.dependentTaskId,
                                                        DependencyID: item.dependencyID,
                                                        LogDays: item.logDays,
                                                        IndexName: item.indexName,
                                                        Mainindex: item.mainChildIndex,
                                                        StartDate: new Date(item.startDate),
                                                        EndDate: new Date(item.endDate),
                                                        //ChildIndex: isLastEdit ? itemindex - 1 : itemindex - 2,
                                                        ChildIndex: isLastEdit ? itemindex + 1 : itemindex - 2,
                                                        // ChildIndex: itemindex + 1,
                                                        IsSingleTask: routeInformation.filter(x => x.TNARouteApplyID === item.dependentTaskId).length > 0 ?
                                                            routeInformation.filter(x => x.TNARouteApplyID === item.dependentTaskId)[0].IsSingleTask : false

                                                    })
                                                })
                                                ChildIndex++;
                                                RownoforAllTasks++;
                                                // For Parent Actual date clear when was child task not have a close date
                                                if (!ParentActualdate && child.actualEndDate === null) {
                                                    ParentActualdate = true;
                                                    routeInformation[i].ActualEndDate = null;
                                                }

                                                if (!ParentActualdate) {
                                                    routeInformation[i].ActualEndDate = child.actualEndDate;
                                                }


                                            });
                                        }

                                    });
                                }
                                if (IsSingleTask === true) {
                                    RownoforAllTasks++;
                                }

                            });
                        }







                        if (dependentInfo && dependentInfo.length > 0) {
                            dependentInfo.map((element, i) => {
                                let DependencyId = 0;
                                DependencyId = element.tnaDependencyID;
                                try {
                                    routeInformation && routeInformation[parseInt(element.indexName)].arrDependency &&
                                        routeInformation[parseInt(element.indexName)].arrDependency.push({
                                            TNADependencyID: DependencyId,
                                            DependentTaskName: element.dependentTaskName,
                                            TNATaskName: element.tnaTaskName,
                                            DependencyID: element.dependencyID,
                                            LogDays: element.logDays,
                                            IndexName: element.indexName
                                        })
                                } catch { }


                            })
                        }

                        if (additionalInfo.length > 0) {
                            routeInformation.map((add, j) => {
                                additionalInfo.filter(x => x.indexName === add.IndexName).map((element, i) => {

                                    routeInformation[j].FollowupDetails.push({
                                        TNAAddTaskID: element.tnaAddTaskID,
                                        AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                        AddtionalTaskName: element.addtionalTaskName,
                                        IsTaskSelected: element.isTaskSelected,
                                        IndexName: element.indexName
                                    })
                                });

                            })
                        }

                        if (Comments.length > 0) {
                            routeInformation.map((add, j) => {
                                Comments.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {

                                    routeInformation[j].arrComment.push({
                                        TNACommentsID: element.tnaCommentsID,
                                        TNAId: element.tnaId,
                                        TNAComments: element.tnaComments,
                                        IndexName: element.indexName,
                                        TaskIndexName: element.taskIndexName,
                                        //Date1: element.createdDate.toString(),
                                        Date: new Date(element.createdDate),
                                        //Date: element.createdDate.toLocaleDateString('en-US'),
                                        //ModifiedDate: element.modifiedDate
                                    })
                                });

                            })
                        }

                        if (Attachments.length > 0) {
                            //setFiles([]);
                            routeInformation.map((add, j) => {
                                Attachments.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {

                                    routeInformation[j].arrAttachment.push({
                                        TNAFileID: element.tnaFileID,
                                        TNAId: element.tnaId,
                                        FileName: element.fileName,
                                        FilePath: element.filePath,
                                        FileType: element.fileType,
                                        IndexName: element.indexName,
                                        TaskIndexName: parseInt(element.taskIndexName),
                                        Date1: element.createdDate,
                                        //Date: element.createdDate.toLocaleDateString('en-US'),
                                        ModifiedDate: element.modifiedDate
                                    })



                                    // if (getFiles.length > 0)
                                    // {
                                    //     getFiles.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {

                                    //         getFiles.push({
                                    //             TNAFileID: element.tnaFileID,
                                    //             TNAId: element.tnaId,
                                    //             FileName: element.fileName,
                                    //             FilePath: element.filePath,
                                    //             FileType: element.fileType,
                                    //             IndexName: element.indexName,
                                    //             TaskIndexName: element.taskIndexName,
                                    //             Date1: element.createdDate,
                                    //             //Date: element.createdDate.toLocaleDateString('en-US'),
                                    //             ModifiedDate: element.modifiedDate
                                    //         })
                                    //     })

                                    // }
                                    // else
                                    // {
                                    //     getFiles.push({
                                    //         TNAFileID: element.tnaFileID,
                                    //         TNAId: element.tnaId,
                                    //         FileName: element.fileName,
                                    //         FilePath: element.filePath,
                                    //         FileType: element.fileType,
                                    //         IndexName: element.indexName,
                                    //         TaskIndexName: element.taskIndexName,
                                    //         Date1: element.createdDate,
                                    //         //Date: element.createdDate.toLocaleDateString('en-US'),
                                    //         ModifiedDate: element.modifiedDate
                                    //     })

                                    // }
                                });

                            })
                        }



                        setInputFields(routeInformation);
                        hideLoader();

                        if (routeInformation.length > 0) {
                            setAllDataLoaded(true);
                            // CALCParams.isDuration = false;
                            // CALCParams.isEndDate = false;
                            // CALCParams.index = 1;
                            // setCALCParams(CALCParams);

                        }
                        // if (Comments.length > 0) {
                        //     routeInformation.map((add, c) => {
                        //
                        //         Comments.filter(x => x.indexName === add.IndexName).map((element, i) => {
                        //
                        //             routeInformation[c].arrComment.push({
                        //                 TNACommentsID: element.tnaCommentsID,
                        //                 TNAId: element.tnaId,
                        //                 TNAComments: element.tnaComments,
                        //                 IndexName: element.indexName,
                        //                 TaskIndexName: element.taskIndexName,
                        //                 CreatedDate: element.createdDate,
                        //                 ModifiedDate: element.modifiedDate
                        //             })
                        //         })
                        //     })
                        //    setInputFields(routeInformation);
                        // } else { setInputFields(routeInformation); }
                    } else { setInputFields(routeInformation); hideLoader(); }
                } else {
                    hideLoader();
                }
            } else {
                hideLoader();
            }
        },
        [AllRouteInfo.tnaDetailList]
    );
    //sathish 12/11/21
    // useEffect(() => {
    //     LoadTNABasedValues(parseInt(props.location.state.params.buyerID));
    //         TNAService.GetSelectedTNA(props.location.state.params.TNAID).then((response) => {
    //             if (response.data) {
    //                  let DocsTNA = response.data.getTNA;
    //                   if (DocsTNA.length > 0) {
    //                     DocsTNA.forEach((docs, i) => {
    //                         filevals.push({

    //                             FileName: docs.fileName,
    //                             FilePath: docs.filePath,
    //                             FileType: docs.fileType,
    //                             IsDeleted: 0
    //                         })
    //                     });
    //                     setFiles(filevals);
    //                 }
    //                 setInputFields(values);
    //             }
    //         })
    //             .catch(() => { })
    //             .finally(() => { setres(0); });
    //     }
    // ,[]);



    useEffect(() => {
        dispatch(loadTaskTypeList(2));
        dispatch(loadLeaveDaysList());
        dispatch(retrieveTask(0));
        dispatch(loadTaskList(0));
        dispatch(loadRouteDependencyList());
        dispatch(loadTaskHolderList());
        dispatch(getTNACompanyHolidayList(getTNABrandID, getTNASupplierID, currentUser.companyBuyerSupID));
    }, []);

    useEffect(() => {

        if (values.length > 0) {

            if (AllDataLoaded === true && (taskNameList === '' || taskNameList === null || taskNameList === undefined)) {

                commonCalculation(false, false, 0);

                setAllDataLoaded(false);
            }
        }
    }, [AllDataLoaded === true])



    const handleAddFields = (index) => {

        let count = index;
        count++;
        let EmptyList = {};
        EmptyList =
        {
            TNARouteApplyID: 0,
            TaskFieldEnable: 1,
            TaskType: 0,
            TaskID: 0,
            strTaskName: '',
            EndDate: new Date(),
            StartDate: new Date(),
            PreStartDate: new Date(),
            PreEndDate: new Date(),
            Duration: 1,
            intDependency: 0,
            hasAllDependencyStart: 0,
            hasAllDependencyStartPopup: false,
            arrDependency: [],
            arrComment: [],
            arrManual: [],
            ManualComments: '',
            CommentsCount: 0,
            AttachmentCount: 0,
            TaskHolderList: '',
            TaskHolderOrFollower: 0,
            TaskHolderOwnerTxtList: '',
            TaskHolderIDList: '',
            FollowerIDList1: '',
            FollowerIDList2: '',
            TaskHolderBuyer: '',
            FollowerCube: '',
            FollowerSupplier: '',
            TaskHolderOwnerIdList: '',
            titleEmployee1: '',
            titleEmployee2: '',
            titleEmployee3: '',
            LeaveDays: 1,
            IsTHFirstTime: 0,
            LeaveDaysTxt: 'None',
            AdditionalFollowup: 0,
            IsFollowupExist: 0,
            DependencyCount: 0,
            IndexName: 0,
            IsPopupOpen: 0,
            IsDependencyOpen: 0,
            IsTHLeaveDaysOpen: 0,
            IsComment: 0,
            IsAttachment: 0,
            IsSelectAll: 1,
            FollowupDetails: [],
            SelectedDependency: '',
            arrTaskHolderBuyer: [],
            arrFollowerCube: [],
            arrFollowerSupplier: [],
            TaskHolderBuyerTxt: '',
            FollowerCubeTxt: '',
            FollowerSupplierTxt: '',
            IsSingleTask: true,
            IsNewRow: false,
            StatusID: 1,
            AWBStatus: '',
            ActualEndDate: "",
            ManualCloseDate: "",
            SubTaskList: [],
            EndDateChange: 0,
            IDuration: 0,
            IEndDate: new Date(),
            IStartDate: new Date(),
            RevicePopup: 0,
            IsReviced: 0,
            DependencyDate: "",
            DependencyComment: "",
            IsRescheduled: 0,
            IsDependencyStartPopup: false,
            ReviceSelected: 0,
            RescheduleSelected: 0,
            ReschedulePopup: 0,
            MainRevicedetails:
            {
                TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                ReviceComment: '', TaskName: '', Operation: 0,
                StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                Createdby: 0, Type: ''
            },
            MainRescheduledetails:
            {
                TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                RescheduleComment: '', TaskName: '', Operation: 0,
                StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                Createdby: 0, Type: ''
            },
            DetailTypeID: 0,
            IsExpanded: false,
            ReviceAdded: 0,
            RescheduleAdded: 0,
        }

        values.splice(count, 0, EmptyList);

        values.map((Item, i) => {
            //for (let i = 0; i < values.length; i++) {
            let TaskDependency = [];

            values.map((Item2) => {
                // for (let j = 0; j < values.length; j++) {
                if (Item.arrDependency.length > 0) {

                    let AlreadyExist = Item.arrDependency.filter(x => x.DependentTaskName === Item2.TaskID);
                    let DependencyID = 0;
                    let LogDays = '';
                    if (AlreadyExist.length === 0) {
                        DependencyID = 0;
                        LogDays = '';
                    }
                    else {
                        DependencyID = AlreadyExist[0].DependencyID;
                        LogDays = AlreadyExist[0].LogDays;
                    }

                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item2.TaskID,
                            TNATaskName: Item2.strTaskName,
                            DependencyID: DependencyID,
                            LogDays: LogDays,
                            IndexName: (i).toString()
                        }
                    )
                }
                else {
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item2.TaskID,
                            TNATaskName: Item2.strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: (i).toString()
                        }
                    )
                }
            });
            Item.arrDependency = [];

            Item.arrDependency = TaskDependency;

            let DependencyShow = '';
            Item.arrDependency.map((Item3, k) => {
                // for (let k = 0; k < values[i].arrDependency.length; k++) {
                if (i !== k) {
                    let DependentID = Item3.DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (k + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (k + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                    }
                }
            });

            Item.SelectedDependency = DependencyShow;
            Item.DependencyCount = Item.arrDependency.filter(x => x.DependencyID !== 0).length;
            //Item.PreferenceId = i + 1;

            values[i] = Item;

        });

        // if (values.length > 0) {
        //     for (var z = 0; z < values.length; z++) {
        //         values[z].PreferenceId = z + 1;
        //     }
        // }

        setInputFields(values);
        setSubmitted(false);
    };

    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index) => {

        if ((taskNameList === '' || taskNameList === null || taskNameList === undefined)) {
            var btnToggle = document.getElementById("btn-toggle_" + index);
            var iconToggle = document.getElementById("icon-toggle_" + index);
            var rowHiddens = document.querySelectorAll(".parent_" + index);
            //var rowChildHiddens = document.querySelectorAll(".child_" + index);

            var ariaExpanded = btnToggle.getAttribute('aria-expanded');
            if (ariaExpanded === 'false') {
                let i = 0;
                for (let row of rowHiddens) {
                    row.className = row.className.replace("hidden", " ");
                    values[index].SubTaskList[i].IsExpanded = true;
                    i++;
                }
                btnToggle.setAttribute('aria-expanded', 'true');
                iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
                values[index].IsExpanded = true;
                setInputFields(values);
            }
            else {
                let i = 0;
                for (let row of rowHiddens) {
                    if (!row.className.includes("hidden")) {
                        row.className = row.className + ' hidden';

                        values[index].SubTaskList[i].IsExpanded = false;
                    }
                    i++;
                }
                // for (let row2 of rowChildHiddens) {
                //
                //     if (!row2.className.includes("hidden")) {
                //         row2.className = row2.className + ' hidden';
                //         try {
                //             let dataChild = row2.classList[1];
                //         var btnToggleChild = document.getElementById("btn-toggle_" + dataChild);
                //         var iconToggleChild = document.getElementById("icon-toggle_" + dataChild);
                //             btnToggleChild.setAttribute('aria-expanded', 'false');

                //             iconToggleChild.className = iconToggleChild.className.replace("fa-minus-square-o", "fa-plus-square-o");
                //         } catch { }
                //     }
                // }
                btnToggle.setAttribute('aria-expanded', 'false');
                iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
                values[index].IsExpanded = false;
                setInputFields(values);
            }
        }
        else {
            Nodify('Warning!', 'warning', 'Activate TNA first & do actions in TNA Edit.');
        }
    };

    const handleExpandedOnlyToggle = (index) => {
        var btnToggle = document.getElementById("btn-toggle_" + index);
        var iconToggle = document.getElementById("icon-toggle_" + index);
        values[index].SubTaskList.map((value, i) => {
            values[index].SubTaskList[i].IsExpanded = true;
        });
        btnToggle.setAttribute('aria-expanded', 'true');
        iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
        values[index].IsExpanded = true;
        setInputFields(values);

    };

    const handleChildToggle = (index, childIndex) => {
        var btnToggle = document.getElementById("btn-toggle_parentChild_" + index + "_" + childIndex);
        var iconToggle = document.getElementById("icon-toggle_parentChild_" + index + "_" + childIndex);
        var rowHiddens = document.querySelectorAll(".parentChild_" + index + "_" + childIndex);
        var ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            values[index].SubTaskList[childIndex].IsExpanded = true;

            // for (let row of rowHiddens) {
            //     row.className = row.className.replace("hidden", " ");
            // }
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
        }
        else {
            // for (let row of rowHiddens) {
            //     if (!row.className.includes("hidden")) {
            //         row.className = row.className + ' hidden';
            //     }
            // }

            values[index].SubTaskList[childIndex].IsExpanded = false;

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
        setInputFields(values);
    };

    // #endregion  ------------------  Expanded Toggle End --------------------------------

    //Gowtham
    const handleChangeTNAComments = (event, index, feild) => {
        //  const values = { ...inputFields };

        let inputText = '';
        if (feild === 'Comments') {
            if (event.target.value.trim() !== '') { inputText = event.target.value; }
            values[index].Comments = inputText;

        }
        else if (feild === 'ManualComments') {
            if (event.target.value.trim() !== '') { inputText = event.target.value; }
            values[index].ManualComments = inputText;
        }
        else {
            values[index].ManualCloseDate = new Date();

        }
        setInputFields(values);

    }
    const handleSave = (index, Childindex, assignValue, TaskID) => {
        let IsValid = true;
        if (assignValue === '' || assignValue === undefined) {
            setComment(true); IsValid = false;
            Nodify('Warning!', 'warning', "Please fill the mandantory(*) fields");
        }
        if (IsValid) {
            if (values[index].Comments.length !== 0) {
                if (values[index].Comments !== '') {
                    if (EditedCommentIndex !== '') {
                        values[index].arrComment[Childindex - 1].TNAComments = assignValue;
                    }
                    else {
                        values[index].arrComment.push({
                            TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
                            Date: new Date(),

                        });
                    }
                }
                else {
                    values[index].arrComment = {
                        TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
                        Date: new Date(),
                    };
                }
            }
            else {
                values[index].arrComments.push({
                    TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
                    Date: new Date(),
                });
            }
            values[index].Comments = '';
            values[index].IsComment = 0;
            values[index].CommentsCount = values[index].arrComment.length;
            setInputFields(values);
            setEditedCommentIndex('');
            Nodify('Success!', 'success', 'Task Comments saved Successfully');
        }
    };

    const handleRemoveFields = (index, Taskname, RownoforAllTasks) => {

        let SubmitCount = 0;
        if (Taskname === "Lab Dip Submission") {
            let approvalues = values.filter(x => x.strTaskName === "Lab Dip Approval")
            if (approvalues.length > 0) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "Strike Off Submission (Local Development)") {
            let approvalues = values.filter(x => x.strTaskName === "Strike off Approval (Local Development)")
            if (approvalues.length > 0) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "Trim Submission (Local Development)") {
            let approvalues = values.filter(x => x.strTaskName === "Trim Approval (Local Development)")
            if (approvalues.length > 0) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "SMS Submission") {
            let approvalues = values.filter(x => x.strTaskName === "SMS Approval")
            if (approvalues.length > 0) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "Proto Sample Submission") {
            let approvalues = values.filter(x => x.strTaskName === "Proto Sample Approval")
            if (approvalues.length > 0) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "Fit Sample Submission") {
            let approvalues = values.filter(x => x.strTaskName === "Fit Sample Approval")
            if (approvalues.length > 0) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "Shipment Sample Submission") {
            let approvalues = values.filter(x => x.strTaskName === "Shipment Sample Approval")
            if (approvalues.length > 0) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "Pre Production Sample Submission") {
            let approvalues = values.filter(x => x.strTaskName === "Pre Production Sample Approval")
            if (approvalues.length > 0) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "Size Set Sample Submission") {
            let approvalues = values.filter(x => x.strTaskName === "Size Set Sample Approval")
            if (approvalues.length > 0) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "Photo Sample Submission") {
            let approvalues = values.filter(x => x.strTaskName === "Photo Sample Approval")
            if (approvalues.length > 0) {
                SubmitCount = SubmitCount + 1;
            }
        }
        if (SubmitCount > 0) {
            Nodify('Warning!', 'warning', 'Approval exists aganist Submission Tasks.');
        }
        else {
            values.splice(index, 1);
            values.map((Item, j) => {
                //for (let j = 0; j < values.length; j++) {
                Item.arrDependency.splice(index, 1);

                Item.SelectedDependency = '';
                let DependencyShow = '';
                Item.arrDependency.map((Item2, i) => {
                    //for (let i = 0; i < values[j].arrDependency.length; i++) {
                    if (j !== i) {
                        let DependentID = Item2.DependencyID;
                        if (DependentID !== 0) {
                            if (DependencyShow === '') {
                                DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                            }
                            else {
                                DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                            }
                        }
                    }
                });
                Item.SelectedDependency = DependencyShow;
                Item.DependencyCount = Item.arrDependency.filter(x => x.DependencyID !== 0).length;
                values[j] = Item;
            });

            //setInputFields(values);

            // CALCParams.isDuration = false;
            // CALCParams.isEndDate = false;
            // CALCParams.index = -1;
            //  setCALCParams(CALCParams);
            // commonCalculation(false, false, -1);

            setInputFields(values);
            DisabeSelectedTaskName();
            ReassignIndexonParentTaskAddDelete('Remove');

            // if (values[index].IsSingleTask === true) {
            //     ReassignIndexonAddDeleteTask(0, 0, 0, 'Remove', 0, RownoforAllTasks);
            // }
            // else {
            //     values[index].SubTaskList.map(sub => {
            //         sub.ChildTaskList.map(child => {
            //             ReassignIndexonAddDeleteTask(0, 0, 0, 'Remove', 0, child.RownoforAllTasks);
            //         })
            //     })
            // }

        }
    };

    const handleChangeChild = (e, index, subIndex, childIndex, FieldName, ChildIndex) => {

        setSubmitted(false);
        let inputText = '';

        if (FieldName === "Duration") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].Duration = inputText;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDateChange === 1) {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDateChange = 2;
            }
            setIsFocused(false);
        }
        else if (FieldName === "EndDate") {
            let currEnddate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDate;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].PreEndDate =
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDate;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDate = e;
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDateChange === 0) {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDateChange = 1;
                //  }
            }
            setIsFocused(false);
        }
        else if (FieldName === "StatusID") {
            if (e != null) {
                inputText = parseInt(e.value);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID = inputText;
            }
        }
        else if (FieldName === "LeaveDays") {
            if (e != null) {
                inputText = parseInt(e.value);

                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].LeaveDaysTxt = e.label;
            }
            else {
                inputText = 0;
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].LeaveDaysTxt = "";
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].LeaveDays = inputText;

            if (values[index].SubTaskList[subIndex].ChildTaskList[subIndex].Duration !== '') {
                setEDchangecalconoff(false);
                let subchild = values[index].SubTaskList[subIndex].ChildTaskList[childIndex];
                ChildDateCalculation(FieldName, index, subIndex, childIndex, false);
                if (values[index].DetailTypeID === 4) {
                    let currentrow = values[index].SubTaskList[Indexs.SubIndex].ChildTaskList[childIndex];
                    reloadExfactcal(currentrow, index, subIndex, childIndex, values[index].LeaveDays,
                        values[index].titleEmployee1)
                }
                ResetParentTakDate();

                leavedayscountcalc(subchild.StartDate, subchild.EndDate, subchild.LeaveDays, subchild.titleEmployee1,
                    index, subIndex, childIndex, false)
            }
        }



        if (FieldName !== "TaskName") {

            setInputFields(values);
            // if (FieldName === "EndDate") {
            //     CALCParams.isDuration = false;
            //     CALCParams.isEndDate = true;
            //     CALCParams.index = index;
            //     setCALCParams(CALCParams);
            //     //commonCalculation(false, true, index);
            // }
            if (FieldName === "EndDate") {
                // setIndexs({ Index: index, SubIndex: subIndex, ChildIndex: childIndex, FieldName: FieldName })
                //setEDcalconoffpopup(true);
                DepandantTaskcalconoff(index, subIndex, childIndex, FieldName, ChildIndex)

            }
        }

    }

    // const DepandantTaskcalconoff = (index, subIndex, childIndex, FieldName, ChildIndex) => {
    //
    //     let CurrentChildvalues = [];

    //     values.map((Main) => {
    //         Main.SubTaskList.map((Child) => {
    //             Child.ChildTaskList.map((SubChild) => {
    //                 CurrentChildvalues.push({
    //                     Depandency: SubChild.SelectedDependency
    //                 })
    //             })
    //         })
    //     })

    //     let chkexists = false;
    //     CurrentChildvalues.map((c) => {
    //         if (c.Depandency.includes((index + 1) + "-" + ChildIndex)) {

    //             chkexists = true;
    //         }
    //     })
    //     Indexs.Index = index;
    //     Indexs.SubIndex = subIndex;
    //     Indexs.ChildIndex = childIndex;
    //     if (chkexists === true) {
    //         // setIndexs({ Index: index, SubIndex: subIndex, ChildIndex: childIndex, FieldName: FieldName })

    //         setEDcalconoffpopup(true);
    //     }
    //     else {
    //         //  setIndexs({ Index: index, SubIndex: subIndex, ChildIndex: childIndex, FieldName: FieldName })
    //         DepandantTaskcalc(false);
    //     }

    // }

    // const DepandantTaskcalc = (onoff) => {

    //     if (values[Indexs.Index].SubTaskList[Indexs.SubIndex].ChildTaskList[Indexs.ChildIndex].Duration !== '') {
    //         setEDchangecalconoff(onoff);
    //         let subchild = values[Indexs.Index].SubTaskList[Indexs.SubIndex].ChildTaskList[Indexs.ChildIndex];
    //         ChildDateCalculation(Indexs.FieldName, Indexs.Index, Indexs.SubIndex, Indexs.ChildIndex, onoff);
    //         if (values[Indexs.Index].DetailTypeID === 4) {
    //             let currentrow = values[Indexs.Index].SubTaskList[Indexs.SubIndex].ChildTaskList[Indexs.ChildIndex];
    //             reloadExfactcal(currentrow, Indexs.Index, Indexs.SubIndex, Indexs.ChildIndex, values[Indexs.Index].LeaveDays,
    //                 values[Indexs.Index].titleEmployee1)
    //         }
    //         ResetParentTakDate();

    //         setEDcalconoffpopup(false);

    //         leavedayscountcalc(subchild.StartDate, subchild.EndDate, subchild.LeaveDays, subchild.titleEmployee1,
    //             Indexs.Index, Indexs.SubIndex, Indexs.ChildIndex, false)
    //     }
    // }

    const DepandantTaskcalconoff = (index, subIndex, childIndex, FieldName) => {

        setIndexs({ Index: index, SubIndex: subIndex, ChildIndex: childIndex, FieldName: FieldName })
        if (isFocused) {
            console.log('Input was blurred, but it was focused before.');
        } else {
            console.log('Input was blurred, and it was not focused before.');
            if ((values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency !== '' &&
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency.length !== 0)) {
                setEDcalconoffpopup(true);
            } else if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency.length === 0) {
                // setEDcalconoffpopup(true);
                DepandantTaskcalc(true, index, subIndex, childIndex, FieldName)
            }
        }
        setIsFocused(false);
    }
    const handleFocus = () => {
        setIsFocused(true);
    };

    const DepandantTaskcalc = (onoff, Index, SubIndex, ChildIndex, FieldName) => {

        if (!onoff) { setEDcalconoffpopup(false) }
        else {
            //   if (values[Indexs.Index].SubTaskList[Indexs.SubIndex] &&
            //             values[Indexs.Index].SubTaskList[Indexs.SubIndex].ChildTaskList[Indexs.ChildIndex].Duration !== '') {
            //             setEDchangecalconoff(onoff);
            //             let subchild = values[Indexs.Index].SubTaskList[Indexs.SubIndex].ChildTaskList[Indexs.ChildIndex];
            //             ChildDateCalculation(Indexs.FieldName, Indexs.Index, Indexs.SubIndex, Indexs.ChildIndex, onoff);
            //             if (values[Indexs.Index].DetailTypeID === 4) {
            //                 let currentrow = values[Indexs.Index].SubTaskList[Indexs.SubIndex].ChildTaskList[Indexs.ChildIndex];
            //                 reloadExfactcal(currentrow, Indexs.Index, Indexs.SubIndex, Indexs.ChildIndex, values[Indexs.Index].LeaveDays,
            //                     values[Indexs.Index].titleEmployee1)
            //             }
            //             ResetParentTakDate();

            //             setEDcalconoffpopup(false);

            //             leavedayscountcalc(subchild.StartDate, subchild.EndDate, subchild.LeaveDays, subchild.titleEmployee1,
            //                 Indexs.Index, Indexs.SubIndex, Indexs.ChildIndex, false)
            //         }

            if (values[Index].SubTaskList[SubIndex] &&
                values[Index].SubTaskList[SubIndex].ChildTaskList[ChildIndex].Duration !== '') {
                setEDchangecalconoff(onoff);
                let subchild = values[Index].SubTaskList[SubIndex].ChildTaskList[ChildIndex];
                ChildDateCalculation(FieldName, Index, SubIndex, ChildIndex, onoff);
                if (values[Index].DetailTypeID === 4) {
                    let currentrow = values[Index].SubTaskList[SubIndex].ChildTaskList[ChildIndex];
                    reloadExfactcal(currentrow, Index, SubIndex, ChildIndex, values[Index].LeaveDays,
                        values[Index].titleEmployee1)
                }
                ResetParentTakDate();

                setEDcalconoffpopup(false);

                leavedayscountcalc(subchild.StartDate, subchild.EndDate, subchild.LeaveDays, subchild.titleEmployee1,
                    Index, SubIndex, ChildIndex, false)
            }
        }
    }

    const ChildDateCalculation = (FieldName, index, subIndex, childIndex, onoff) => {

        let Duration = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].Duration;
        let StartDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StartDate;
        let EndDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDate;
        let SelectedDepandency = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency;
        let AssignedDepandency = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency;
        let AssignedStartDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedStartDate;
        let AssignedEndDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedEndDate;
        let PreStartDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StartDate;
        let PreEndDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].PreEndDate;
        let LeaveDays = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].LeaveDays;
        let titleEmployee1 = values[index].titleEmployee1;
        let RowIndex = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RownoforAllTasks;
        let total = 0;

        //     const leaveDaysArray = inputFields[index].SubTaskList[0].ChildTaskList[childIndex].LeaveDaysTxt.split(' ')
        //     .map(day => day.trim());

        // const trimmedDaysString = leaveDaysArray.map(day => day.slice(0, 3)).join(',');
        // console.log(trimmedDaysString);

        //calculation without depandency
        if (SelectedDepandency === "" || SelectedDepandency === null) {
            //based on duration
            if (FieldName === "Duration") {
                EndDate = addDays(StartDate, parseInt(Duration) - 1);
                total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                for (let l = 0; l < total; l++) {
                    EndDate = addDays(EndDate, 1);
                    EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                }
            }
            else {
                //based on end date
                let CheckDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                if (!isSameDay(new Date(EndDate), CheckDate)) {
                    Nodify('Warning!', 'warning', 'You select the end date to leave day');
                    EndDate = CheckDate;
                }
                StartDate = subDays(EndDate, parseInt(Duration) - 1);
                total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                for (let l = 0; l < total; l++) {
                    StartDate = subDays(StartDate, 1);
                    StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");

                }
            }

        }

        // else {
        //     //based on duration
        //     if (FieldName === "Duration") {
        //         if (AssignedDepandency === "ES" || AssignedDepandency === "SS") {
        //             EndDate = addDays(StartDate, parseInt(Duration) - 1);
        //             total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);


        //             if(trimmedDaysString === "Sat" || trimmedDaysString === "Sat,Hol") // Sat & Sun,Holiday  data issue
        //             {
        //                 if (EndDate.getDay() === 6) {
        //                     // If it's Sunday, Add 1 day
        //                     EndDate = addDays(EndDate, 1);
        //                 }
        //             }
        //             else if(trimmedDaysString === "Sun" || trimmedDaysString === "Sun,Hol") // Sunday working fine & Sun,Holiday  data issue
        //             {
        //                 if(EndDate.getDay() === 0){
        //                     EndDate = addDays(EndDate, 1);
        //                 }
        //             }
        //             else if(trimmedDaysString === "Sat,Sun" || trimmedDaysString === "Sat,Sun,Hol") // working
        //             {
        //                 if (EndDate.getDay() === 6) {
        //                     // If it's Sunday, Add 1 day
        //                     EndDate = addDays(EndDate, 2);
        //                 }
        //                 else if(EndDate.getDay() === 0){
        //                     EndDate = addDays(EndDate, 1);
        //                 }
        //             }

        //             // else if(leaveDaysArr.length === 1){ // need to check
        //             //     if (EndDate.getDay() === 0) {
        //             //         // If it's Sunday, Add 1 day
        //             //         EndDate = addDays(EndDate, 1);
        //             //     }
        //             // }
        //             // else{
        //             //     EndDate = addDays(EndDate, holidayTotal);
        //             // }
        //             // for (let l = 0; l < total; l++) {
        //             //     EndDate = addDays(EndDate, 1);
        //             //     EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
        //             // }
        //         }
        //         else {
        //             StartDate = subDays(EndDate, parseInt(Duration) - 1);
        //             total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);

        //             if(trimmedDaysString === "Sat" || trimmedDaysString === "Sat,Hol")
        //             {
        //                 if (StartDate.getDay() === 6) {

        //                     StartDate = subDays(StartDate, 1);
        //                 }
        //             }
        //             else if(trimmedDaysString === "Sun" || trimmedDaysString === "Sun,Hol")
        //             {
        //                 if(StartDate.getDay() === 0){
        //                     StartDate = subDays(StartDate, 1);
        //                 }
        //             }
        //             else if(trimmedDaysString === "Sat,Sun" || trimmedDaysString === "Sat,Sun,Hol")
        //             {
        //                 if (StartDate.getDay() === 6) {

        //                     StartDate = subDays(StartDate, 1);
        //                 }
        //                 else if(StartDate.getDay() === 0){
        //                     StartDate = subDays(StartDate, 2);
        //                 }
        //             }

        //             // for (let l = 0; l < total; l++) {
        //             //     StartDate = subDays(StartDate, 1);
        //             //     StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");
        //             // }
        //         }
        //     }
        //calculation with depandency
        else {
            //based on duration
            if (FieldName === "Duration") {
                if (AssignedDepandency === "ES" || AssignedDepandency === "SS") {
                    EndDate = addDays(StartDate, parseInt(Duration) - 1);
                    total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                    for (let l = 0; l < total; l++) {
                        EndDate = addDays(EndDate, 1);
                        EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                    }
                }
                else {
                    StartDate = subDays(EndDate, parseInt(Duration) - 1);
                    total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                    for (let l = 0; l < total; l++) {
                        StartDate = subDays(StartDate, 1);
                        StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");
                    }
                }
            }
            else {
                //based on end date

                if (AssignedDepandency === "SE" || AssignedDepandency === "EE") {
                    Nodify('Warning!', 'warning', 'Dependency set based on End date,it should not less than' + AssignedEndDate);
                    if (new Date(AssignedEndDate) > new Date(EndDate)) {
                        EndDate = PreEndDate;
                    }
                    else {
                        let CheckDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                        if (!isSameDay(new Date(EndDate), CheckDate)) {
                            Nodify('Warning!', 'warning', 'You select the end date to leave day');
                            EndDate = CheckDate;
                        }
                        StartDate = subDays(EndDate, parseInt(Duration) - 1);
                        total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                        for (let l = 0; l < total; l++) {
                            StartDate = subDays(StartDate, 1);
                            StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");
                        }

                    }
                }
                else {
                    let CheckDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                    if (!isSameDay(new Date(EndDate), CheckDate)) {
                        Nodify('Warning!', 'warning', 'You select the end date to leave day');
                        EndDate = CheckDate;
                    }
                    StartDate = subDays(EndDate, parseInt(Duration) - 1);
                    total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                    for (let l = 0; l < total; l++) {
                        StartDate = subDays(StartDate, 1);
                        StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");
                    }

                    if (new Date(AssignedStartDate) > new Date(StartDate)) {
                        Nodify('Warning!', 'warning', 'Depandency set based on Start date,it should not less than' + AssignedStartDate);
                        StartDate = PreStartDate;
                        EndDate = PreEndDate;
                    }
                }
            }

        }
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StartDate = StartDate;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDate = EndDate;

        // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency[RowIndex - 1].StartDate = StartDate;
        // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency[RowIndex - 1].EndDate = EndDate;

        values.map((Main, Mainindex) => {
            LeaveDays = Main.LeaveDays;
            titleEmployee1 = Main.titleEmployee1;
            Main.SubTaskList.map((Child, ChildIndex) => {

                Child.ChildTaskList.map((SubChild, Subchildindex) => {
                    if (SubChild.arrDependency.length > 0) {
                        SubChild.arrDependency[RowIndex - 1].StartDate = StartDate;
                        SubChild.arrDependency[RowIndex - 1].EndDate = EndDate;
                    }
                })

            })

        });

        let allSubTask = [];
        values[index].SubTaskList && values[index].SubTaskList.map((Item2, p) => {
            if (allSubTask.length === 0) {
                allSubTask = [...Item2.ChildTaskList];
            } else {
                allSubTask = [...allSubTask, ...Item2.ChildTaskList];
            }
        });
        let max = allSubTask && allSubTask.reduce(function (a, b) {
            return Math.max(a, parseInt(b.Duration));
        }, 0);
        values[index].Duration = max;
        const sorted = allSubTask.slice().sort((a, b) => b.EndDate - a.EndDate);
        values[index].EndDate = sorted[0].EndDate;

        setInputFields(values);
        if (onoff === true) {
            ChildDepandencyCalculation("childChange", index, subIndex, childIndex);
        }





    }


    const handleChange = (e, index, FieldName) => {

        if ((taskNameList === '' || taskNameList === null || taskNameList === undefined)) {

            setSubmitted(false);
            let inputText = '';
            if (FieldName === "IsSelectAll") {
                if (e.target.checked) {
                    values[index].FollowupDetails.map((Item, i) => {
                        //for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                        values[index].FollowupDetails[i].IsTaskSelected = 1;
                    });

                    values[index][FieldName] = 1;
                } else {
                    values[index].FollowupDetails.map((Item, i) => {
                        //for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                        values[index].FollowupDetails[i].IsTaskSelected = 0;
                    });
                    values[index][FieldName] = 0;

                }

                values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
                values[index].IsFollowupExist = values[index].FollowupDetails.length;

            }
            else if (FieldName === "TaskType") {
                if (e != null) {
                    inputText = parseInt(e.value);
                    values[index].TaskID = 0;
                    values[index].TaskHolderOrFollower = 0;
                    values[index].TaskHolderOrFollowerTxt = '';
                    values[index].LeaveDays = 0;
                    values[index].LeaveDaysTxt = "";
                    values[index].AdditionalFollowup = 0;
                    values[index].IsFollowupExist = 0;
                    values[index].strTaskName = '';
                    values[index].FollowupDetails = [];
                    values[index].TaskTypeTxt = e.label;
                    var SelectedIndex = getTaskTypeList.findIndex(x => x.value === e.value);
                    getTaskTypeList[SelectedIndex].isdisabled = true;
                }
                else {
                    inputText = 0;
                    values[index].TaskID = 0;
                    values[index].TaskTypeTxt = "";
                    values[index].TaskHolderOrFollower = 0;
                    values[index].TaskHolderOrFollowerTxt = '';
                    values[index].LeaveDays = 0;
                    values[index].LeaveDaysTxt = "";
                    values[index].AdditionalFollowup = 0;
                    values[index].IsFollowupExist = 0;
                    values[index].strTaskName = '';
                    values[index].FollowupDetails = [];

                }
                values[index][FieldName] = inputText;
                DisabeSelectedTaskName();

            }
            else if (FieldName === "TaskName") {

                showLoader();
                if (e != null) {

                    inputText = parseInt(e.value);
                    let routeInfo = AllRouteInfo.tnaDetailList;
                    let additionalInfo = AllRouteInfo.tnaDetailTaskList;
                    let parentChildList = AllRouteInfo.tnaParentChildList;
                    let childList = AllRouteInfo.tnaChildList;
                    let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === e.label.toLowerCase());

                    const existingTask = routeInfo.filter(d => d.taskNameID === inputText)[0];

                    let Task_Value = TaskList.filter(x => x.value === e.value)[0];
                    values[index].TaskFieldEnable = 0;
                    values[index].TaskType = Task_Value.taskTypeID;


                    let TaskTypeRow = getTaskTypeList.filter(t => t.value === Task_Value.taskTypeID)[0];
                    values[index].TaskTypeTxt = TaskTypeRow && TaskTypeRow.label;

                    values[index].TaskID = inputText;
                    values[index].strTaskName = e.label;

                    if (existingTask) {
                        let existingEmployee1 = "";
                        let existingEmployee2 = "";
                        let existingEmployee3 = "";

                        if (existingTask.taskHolderName !== null && existingTask.taskHolderName !== undefined) {
                            let TaskHolderName = existingTask.taskHolderName.split('-');
                            if (TaskHolderName[0] !== undefined) {
                                existingEmployee1 = TaskHolderName[0].trim();

                                if (TaskHolderName[1] !== undefined) {
                                    let FollowerName = TaskHolderName[1].split(',');
                                    if (FollowerName[0] !== undefined) {
                                        existingEmployee2 = FollowerName[0].trim();

                                        if (FollowerName[1] !== undefined) {
                                            existingEmployee3 = FollowerName[1].trim();
                                        } else {
                                            existingEmployee3 = "";
                                        }
                                    } else {
                                        existingEmployee2 = "";
                                        existingEmployee3 = "";
                                    }

                                } else {
                                    existingEmployee2 = "";
                                    existingEmployee3 = "";
                                }
                            }
                        }

                        let taskHolderIDList = existingTask.taskHolderIDList;
                        let followerIDList1 = existingTask.followerIDList1;
                        let followerIDList2 = "";
                        if (existingEmployee1 === "Supplier" || existingEmployee2 === "Supplier" || existingEmployee3 === "Supplier") {
                            if (existingTask.followerIDList2) {
                                if (SupplierEmployeeList && SupplierEmployeeList.length > 0) {
                                    let Supplier = SupplierEmployeeList.filter(item => (existingTask.followerIDList2.includes(item.id.toString())));
                                    if (Supplier && Supplier.length > 0) {
                                        followerIDList2 = Supplier.map(function (item) {
                                            return item['id'];
                                        });

                                    }
                                }
                            }
                        } else {
                            followerIDList2 = existingTask.followerIDList2;
                        }

                        let taskHolderOwnerIdList = "";
                        if (existingEmployee1 !== "Supplier" && existingEmployee2 !== "Supplier" && existingEmployee3 !== "Supplier") {
                            taskHolderOwnerIdList = existingTask.taskOwner;
                        } else {
                            if (taskHolderIDList !== "") {
                                taskHolderOwnerIdList = taskHolderIDList;
                            }
                            if (followerIDList1 !== "") {
                                if (taskHolderOwnerIdList === "") {
                                    taskHolderOwnerIdList = followerIDList1;
                                } else {
                                    taskHolderOwnerIdList = taskHolderOwnerIdList + "," + followerIDList1;
                                }
                            }
                            if (followerIDList2 !== "") {
                                if (taskHolderOwnerIdList === "") {
                                    taskHolderOwnerIdList = followerIDList2;
                                } else {
                                    taskHolderOwnerIdList = taskHolderOwnerIdList + "," + followerIDList2;
                                }
                            }
                        }


                        values[index].Duration = existingTask.duration && existingTask.duration.toString();
                        values[index].TaskHolderOrFollower = existingTask.taskHolderID;
                        values[index].TaskHolderOrFollowerTxt = existingTask.taskHolderName && existingTask.taskHolderName.replace(" - -", "");
                        values[index].TaskHolderOwnerTxtList = existingTask.taskOwnerNames;
                        values[index].TaskHolderOwnerIdList = taskHolderOwnerIdList;
                        values[index].TaskHolderIDList = taskHolderIDList;
                        values[index].FollowerIDList1 = followerIDList1;
                        values[index].FollowerIDList2 = followerIDList2;
                        values[index].TaskHolderBuyer = taskHolderIDList;
                        values[index].FollowerCube = followerIDList1;
                        values[index].FollowerSupplier = followerIDList2;
                        values[index].titleEmployee1 = existingEmployee1;
                        values[index].titleEmployee2 = existingEmployee2;
                        values[index].titleEmployee3 = existingEmployee3;
                        values[index].LeaveDays = existingTask.leaveDays;
                        values[index].LeaveDaysTxt = existingTask.leaveDayName;
                        values[index].AdditionalFollowup = additionalInfo.filter(x => x.indexName === existingTask.indexName && x.isTaskSelected === 1).length;
                        values[index].IsFollowupExist = additionalInfo.filter(x => x.indexName === existingTask.indexName).length;
                        values[index].DependencyCount = additionalInfo.filter(x => x.indexName === existingTask.indexName && x.dependencyID !== 0).length;
                        // values[index].PreferenceId = existingTask.preferenceId;
                        values[index].IsPopupOpen = 0;
                        values[index].IsDependencyOpen = 0;
                        values[index].IsTHLeaveDaysOpen = 0;
                        values[index].IsComment = 0;
                        values[index].IsAttachment = 0;
                        values[index].IsSelectAll = existingTask.isSelectAll;


                        values[index].StatusID = existingTask.routeApplyStatus === 0 ? 1 : existingTask.routeApplyStatus;
                        values[index].AWBStatus = existingTask.awbStatus;
                        values[index].ActualEndDate = existingTask.actualEndDate;
                        values[index].IDuration = existingTask.duration;
                        values[index].IEndDate = existingTask.EndDate;
                        values[index].IStartDate = existingTask.StartDate;
                        values[index].IsReviced = existingTask.isReviced;
                        values[index].DependencyDate = existingTask.dependencyDate;
                        values[index].DependencyComment = existingTask.dependencyComment;
                        values[index].IsRescheduled = existingTask.isRescheduled;

                        values[index].SelectedDependency = existingTask.dependency;
                        values[index].IsSingleTask = IsSingleTask;
                        values[index].DetailTypeID = existingTask.detailTypeID;
                        if (IsSingleTask === false) {

                            loadNewParentAndChild(parentChildList, childList, existingTask, index, existingEmployee1, existingEmployee2, existingEmployee3, true);

                        }
                        loadAddtionalTaskDetailsList(index, Task_Value, FieldName, inputText);

                        setInputFields(values);
                        hideLoader();
                    } else {

                        var RespectiveList = ExistingList.filter(x => x.taskDetailsID === e.value);
                        let employee1 = "";
                        let employee2 = "";
                        let employee3 = "";

                        if (RespectiveList[0].taskHolderName !== null && RespectiveList[0].taskHolderName !== undefined) {
                            let TaskHolderName = RespectiveList[0].taskHolderName.split('-');
                            if (TaskHolderName[0] !== undefined) {
                                employee1 = TaskHolderName[0].trim();

                                if (TaskHolderName[1] !== undefined) {
                                    let FollowerName = TaskHolderName[1].split(',');
                                    if (FollowerName[0] !== undefined) {
                                        employee2 = FollowerName[0].trim();

                                        if (FollowerName[1] !== undefined) {
                                            employee3 = FollowerName[1].trim();
                                        } else {
                                            employee3 = "";
                                        }
                                    } else {
                                        employee2 = "";
                                        employee3 = "";
                                    }

                                } else {
                                    employee2 = "";
                                    employee3 = "";
                                }
                            }
                        }

                        values[index].TaskHolderOrFollower = RespectiveList[0].taskHolderOrFollower;
                        values[index].TaskHolderOrFollowerTxt = RespectiveList[0].taskHolderName && RespectiveList[0].taskHolderName.replace(" - -", "");
                        values[index].LeaveDays = RespectiveList[0].leaveDays;
                        values[index].LeaveDaysTxt = RespectiveList[0].leaveDayName;
                        values[index].AdditionalFollowup = RespectiveList[0].additionalFollowup;
                        values[index].IsFollowupExist = RespectiveList[0].additionalFollowup;
                        values[index].titleEmployee1 = employee1;
                        values[index].titleEmployee2 = employee2;
                        values[index].titleEmployee3 = employee3;
                        values[index].IsSingleTask = IsSingleTask;
                        values[index].Duration = "1";

                        values[index].TaskHolderOwnerIdList = "";
                        values[index].TaskHolderIDList = "";
                        values[index].FollowerIDList1 = "";
                        values[index].FollowerIDList2 = "";
                        values[index].TaskHolderBuyer = "";
                        values[index].FollowerCube = "";
                        values[index].FollowerSupplier = "";
                        values[index].DependencyCount = 0;
                        values[index].IsSelectAll = "";
                        values[index].SelectedDependency = "";
                        values[index].DetailTypeID = RespectiveList[0].detailTypeID;

                        loadAddtionalTaskDetailsList(index, Task_Value, FieldName, inputText);

                        if (!IsSingleTask) {
                            dispatch(getTNAEditTaskWiseChildList({ TNAId: props.location.state.params.tnaid, TaskName: e.label }))
                                .then(data => {
                                    if (data != null) {
                                        let newParentChildList = data.tnaParentChildList;
                                        let newChildList = data.tnaChildList;

                                        loadNewParentAndChild(newParentChildList, newChildList, RespectiveList[0], index, employee1, employee2, employee3, false);

                                        setInputFields(values);
                                        hideLoader();
                                    }
                                });
                        } else {
                            setInputFields(values);
                            hideLoader();
                        }
                    }


                }
                else {
                    inputText = 0;
                    values[index].TaskType = 0;
                    values[index].TaskTypeTxt = "";
                    values[index].TaskHolderOrFollower = 0;
                    values[index].TaskHolderOrFollowerTxt = '';
                    values[index].LeaveDays = 0;
                    values[index].LeaveDaysTxt = 'None';
                    values[index].AdditionalFollowup = 0;
                    values[index].IsFollowupExist = 0;
                    values[index].strTaskName = '';
                    values[index].TaskID = inputText;
                    values[index].FollowupDetails = [];
                    values[index].IsSingleTask = true;

                    values.map((Item, i) => {
                        //for (let i = 0; i < values.length; i++) {
                        Item.arrDependency.splice(index, 1);
                        values[i] = Item;
                    });
                    setInputFields(values);
                    hideLoader();
                }
                DisabeSelectedTaskName();
            }
            else if (FieldName === "TaskHolderOrFollower" || FieldName === "LeaveDays") {
                if (e != null) {
                    inputText = parseInt(e.value);
                    if (FieldName === "TaskHolderOrFollower") {
                        // setTaskHolderID(inputText);

                        values[index].TaskHolderOrFollowerTxt = e.subLabel && e.subLabel.replace(" - -", "");
                        let TaskHolderName = e.subLabel.split('-');
                        if (TaskHolderName[0] !== undefined) {
                            values[index].titleEmployee1 = TaskHolderName[0].trim();

                            if (TaskHolderName[1] !== undefined) {
                                let FollowerName = TaskHolderName[1].split(',');
                                if (FollowerName[0] !== undefined) {
                                    values[index].titleEmployee2 = FollowerName[0].trim();

                                    if (FollowerName[1] !== undefined) {
                                        values[index].titleEmployee3 = FollowerName[1].trim();
                                    } else {
                                        values[index].titleEmployee3 = "";
                                    }
                                } else {
                                    values[index].titleEmployee2 = "";
                                    values[index].titleEmployee3 = "";
                                }

                            } else {
                                values[index].titleEmployee2 = "";
                                values[index].titleEmployee3 = "";
                            }
                            if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                                dispatch(loadTNATaskOwner(inputText, getTNABrandID, getTNASupplierID, 0));
                            } else {
                                dispatch(loadTNATaskOwner(inputText, getTNABrandID, getTNASupplierID, currentUser.employeeinformationID));
                            }

                        } else {
                            values[index].titleEmployee1 = "";
                            values[index].titleEmployee2 = "";
                            values[index].titleEmployee3 = "";
                        }


                    } else {
                        values[index].LeaveDaysTxt = e.label;
                        values[index].SubTaskList[0].ChildTaskList = values[index].SubTaskList[0].ChildTaskList.map(childTask => {
                            return {
                                ...childTask,
                                LeaveDaysTxt: e.label
                            };
                        });

                    }
                }
                else {
                    inputText = 0;
                    if (FieldName === "TaskHolderOrFollower") {
                        values[index].TaskHolderOrFollowerTxt = '';
                        values[index].titleEmployee1 = "";
                        values[index].titleEmployee2 = "";
                        values[index].titleEmployee3 = "";
                    }
                    values[index].LeaveDaysTxt = "";
                }
                values[index][FieldName] = inputText;
            }
            else if (FieldName === "Duration") {

                if (e.target.value.trim() !== '') {
                    inputText = e.target.value;
                }
                var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
                inputText = checkNumber[0];
                if (parseInt(inputText) === 0) {
                    inputText = '';
                }
                values[index][e.target.name] = inputText;
                if (values[index].EndDateChange === 1) {
                    values[index].EndDateChange = 2;
                }

                if (values[index].IsSingleTask === false) {
                    values[index].SubTaskList && values[index].SubTaskList.map((Item2, p) => {
                        Item2.ChildTaskList && Item2.ChildTaskList.map((Item3, c) => {
                            values[index].SubTaskList[p].ChildTaskList[c].Duration = inputText;
                        });
                    });
                }
            }
            else if (FieldName === "EndDate") {

                values[index].PreEndDate = values[index].EndDate;
                values[index].EndDate = e;
                if (values[index].EndDateChange === 0) {
                    values[index].EndDateChange = 1;
                }


            }
            else if (FieldName === "StatusID") {
                if (e != null) {
                    inputText = parseInt(e.value);
                    values[index].StatusID = inputText;
                }

            }
            else if (FieldName === "TaskHolderBuyer" || FieldName === "FollowerCube" || FieldName === "FollowerSupplier") {
                let strValue = '';
                let strTxt = '';
                e.map((val) => {
                    if (strValue === '') { strValue = val.value.toString(); strTxt = val.label.toString(); }
                    else { strValue = strValue + "," + val.value; strTxt = strTxt + ", " + val.label.toString(); }
                });
                values[index]["arr" + FieldName] = e;
                values[index][FieldName] = strValue;
                values[index][FieldName + "Txt"] = strTxt;
            } else if (FieldName === "AWBStatus") {
                if (e.target.value.trim() !== '') {
                    inputText = e.target.value;
                }
                values[index][e.target.name] = inputText;
                // values[index].SubTaskList && values[index].SubTaskList.map((Item, p) => {
                //     Item.ChildTaskList.map((Item2, k) => {
                //         Item2.AWBStatus = inputText;
                //     });
                // });
            }
            else {
                if (e.target.value.trim() !== '') {
                    inputText = e.target.value;
                }
                values[index][e.target.name] = inputText;
            }

            if (FieldName !== "TaskName") {
                setInputFields(values);
                if (FieldName === "EndDate") {
                    CALCParams.isDuration = false;
                    CALCParams.isEndDate = true;
                    CALCParams.index = index;
                    // setCALCParams(CALCParams);
                    //commonCalculation(false, true, index);
                }

            }
        }
        else {
            Nodify('Warning!', 'warning', 'Activate TNA first & do actions in TNA Edit.');
        }
    }

    // const handleClick = (e, index, FieldName) => {
    //     if (FieldName === "AWBStatus") {
    //
    //         let inputText = "";
    //         if (e.target.value.trim() !== '') {
    //             inputText = e.target.value;
    //         }
    //         values[index][e.target.name] = inputText;
    //         values[index].SubTaskList && values[index].SubTaskList.map((Item, p) => {
    //             Item.ChildTaskList.map((Item2, k) => {
    //                 Item2.AWBStatus = inputText;
    //             });
    //         });
    //         setInputFields(values);
    //     }
    // }

    function loadNewParentAndChild(parentChildList, childList, existingTask, index, existingEmployee1, existingEmployee2, existingEmployee3, isExisting) {

        values[index].SubTaskList = [];
        let dbTaskID = isExisting ? existingTask.taskNameID : existingTask.taskDetailsID;
        parentChildList && parentChildList.filter(d => d.taskID === dbTaskID).map((element, p) => {
            let ImageUrl = "";

            if (element.taskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || element.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || element.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || element.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || element.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                ImageUrl = 'Images/Style/Strikeoff/';
            } else if (element.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || element.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || element.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || element.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || element.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || element.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || element.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                ImageUrl = 'Images/Style/Trim/';
            }
            values[index].SubTaskList.push({
                ParentId: 0,
                TaskID: element.taskID,
                MainId: element.mainId,
                Code: element.code,
                Color: element.color,
                ColorOrName: element.colorOrName,
                StrikeOffOrTrimInfoId: element.strikeOffIdOrTrimInfoId,
                ImagePath: element.imagePath,
                ImageUrl: ImageUrl,
                IsExpanded: false,
                hasAutoOpen: false,
                // ParentIndex: p,
                // TaskIndex: TaskIndex,
                ChildTaskList: []
            })

            childList && childList.filter(d => d.taskID === dbTaskID && d.mainId === element.mainId).map((child, c) => {
                values[index].SubTaskList[p].ChildTaskList.push({
                    ChildId: isExisting ? isLastEdit ? child.childId : 0 : 0,
                    SubId: child.subId,
                    Name: child.name,
                    TaskName: child.taskName,
                    TaskID: child.taskID,
                    MainId: child.mainId,
                    Duration: isExisting ? isLastEdit ? child.duration : existingTask.duration : 1,
                    EndDate: isExisting ? isLastEdit ? new Date(child.endDate) : new Date(existingTask.endDate) : new Date(),
                    StartDate: isExisting ? isLastEdit ? new Date(child.planStartDate) : new Date(existingTask.planStartDate) : new Date(),
                    PreStartDate: isExisting ? isLastEdit ? new Date(child.planStartDate) : new Date(existingTask.planStartDate) : new Date(),
                    PreEndDate: isExisting ? isLastEdit ? new Date(child.endDate) : new Date(existingTask.endDate) : new Date(),
                    arrDependency: [],
                    TaskHolderList: '',
                    TaskHolderOrFollower: isExisting ? existingTask.taskHolderID : existingTask.taskHolderOrFollower,
                    TaskHolderOrFollowerTxt: isExisting ? (existingTask.taskHolderName && existingTask.taskHolderName.replace(" - -", "")) : "",
                    TaskHolderOwnerTxtList: isExisting ? existingTask.taskOwnerNames : "",
                    TaskHolderIDList: isExisting ? existingTask.taskHolderIDList : "",
                    FollowerIDList1: isExisting ? existingTask.followerIDList1 : "",
                    FollowerIDList2: isExisting ? existingTask.followerIDList2 : "",
                    TaskHolderBuyer: isExisting ? existingTask.taskHolderIDList : "",
                    FollowerCube: isExisting ? existingTask.followerIDList1 : "",
                    FollowerSupplier: isExisting ? existingTask.followerIDList2 : "",
                    arrTaskHolderBuyer: [],
                    arrFollowerCube: [],
                    arrFollowerSupplier: [],
                    TaskHolderBuyerTxt: '',
                    FollowerCubeTxt: '',
                    FollowerSupplierTxt: '',
                    TaskHolderOwnerIdList: '',
                    titleEmployee1: existingEmployee1,
                    titleEmployee2: existingEmployee2,
                    titleEmployee3: existingEmployee3,
                    IsTHFirstTime: 0,
                    DependencyCount: 0,
                    // IsDependencyOpen: 0,
                    // IsTHLeaveDaysOpen: 0,
                    SelectedDependency: '',
                    LeaveDays: isExisting ? "" : existingTask.leaveDays,
                    LeaveDaysTxt: isExisting ? "" : existingTask.leaveDayName,
                    StatusID: isExisting ? isLastEdit ? child.childId : 1 : 1,
                    AWBStatus: '',
                    IsAddChildTaskOpen: 0,
                    // ParentIndex: p,
                    // ChildIndex: c,
                    StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
                    // TaskIndex: 0,
                    drpChildTaskList: [],
                    IsManualClose: 0,
                    IsExFactoryForm: 0,
                    ManualCloseComment: '',
                    EndDateChange: 0,
                    IsReviced: 0,
                    IsRescheduled: 0,
                    DependencyDate: child.dependencyDate,
                    DependencyComment: child.dependencyComment,
                    SKUIdList: child.skuIdList,
                    PurchaseOrderSkuID: child.purchaseOrderSkuID,
                    StyleStrikeOffGarmentSizeIdList: child.styleStrikeOffGarmentSizeIdList,
                    hasAutoOpen: false,
                    ReviceAdded: 0,
                    RescheduleAdded: 0,
                });


            });
        });
        ReassignIndexonParentTaskAddDelete('Add');
        ReassignIndexonAddDeleteTask(0, 0, 0, '', 0);

    }

    const ReassignIndexonParentTaskAddDelete = (operation) => {

        let SubChildIndex = 1; let SubChildIndex1 = 0;
        let CurrentChildvalues = [];
        values.map((Main, MainIndex) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({ DependentTaskId: SubChild.ChildId, MainIndex: MainIndex })
                })
            })
        })


        values.map((Main, Mainindex) => {
            Main.SubTaskList.map((Child, ChildIndex) => {
                Child.ChildTaskList.map((SubChild, Subchildindex) => {
                    SubChild.ChildIndex = SubChildIndex
                    SubChildIndex++;
                    SubChildIndex1 = 0;
                    if (SubChild.arrDependency && SubChild.arrDependency.length > 0) {

                        if (operation === "Add") {
                            SubChildIndex1 = 1;
                            values.map((Main1) => {
                                Main1.SubTaskList.map((Child1) => {
                                    Child1.ChildTaskList.map((SubChild1) => {
                                        let TaskDependency =
                                        {
                                            TNAChildDependencyID: 0,
                                            DependentTaskId: SubChild1.ChildId,
                                            TNATaskName: SubChild1.TaskName,
                                            DependencyID: 0,
                                            LogDays: '',
                                            IndexName: 0,
                                            Mainindex: SubChildIndex1,
                                            StartDate: '',
                                            EndDate: '',
                                            Duration: ''
                                        }
                                        if (SubChild1.ChildId === 0) {
                                            SubChild.arrDependency.splice((SubChildIndex1 - 1), 0, TaskDependency);
                                        }

                                        SubChildIndex1++;

                                    })
                                })
                            })
                        }
                        else {
                            let arrDependency = [];
                            SubChild.arrDependency.map((depitem, i) => {
                                if (CurrentChildvalues.filter(x => x.DependentTaskId === depitem.DependentTaskId).length > 0) {
                                    arrDependency.push(
                                        {
                                            TNAChildDependencyID: 0,
                                            DependentTaskId: depitem.DependentTaskId,
                                            TNATaskName: depitem.TNATaskName,
                                            DependencyID: depitem.DependencyID,
                                            LogDays: depitem.LogDays,
                                            IndexName: 0,
                                            Mainindex: CurrentChildvalues.filter(x => x.DependentTaskId === depitem.DependentTaskId)[0].MainIndex,
                                            StartDate: depitem.StartDate,
                                            EndDate: depitem.EndDate
                                        }
                                    )
                                }

                            })
                            SubChild.arrDependency = arrDependency;
                        }


                        SubChild.arrDependency.map((item, i) => {
                            item.IndexName = i + 1;
                        })
                    }
                })

            })
        })

        setInputFields(values);
    }

    function loadAddtionalTaskDetailsList(index, Task_Value, FieldName, inputText) {
        taskService.GetAddtionalTaskDetailsList(0, Task_Value.taskInformationID).then((response) => {

            if (response.data) {
                values[index].FollowupDetails = [];
                if (response.data.length !== 0) {
                    if (response.data.filter(x => x.indexName.toString() === Task_Value.indexName.toString()).length > 0) {
                        response.data.filter(x => x.indexName.toString() === Task_Value.indexName.toString()).map(element => {
                            values[index].FollowupDetails.push({
                                TNAAddTaskID: 0,
                                AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                AddtionalTaskName: element.addtionalTaskName,
                                IsTaskSelected: 1,
                                IndexName: index.toString()
                            });
                        });
                    }
                    else {
                        values[index].AdditionalFollowup = 0;
                        values[index].IsFollowupExist = 0;
                        values[index].FollowupDetails = [];
                    }
                }
                else {
                    values[index].AdditionalFollowup = 0;
                    values[index].IsFollowupExist = 0;
                    values[index].FollowupDetails = [];
                }
                values[index][FieldName] = inputText;

                values.map((Item, j) => {
                    // for (var j = 0; j < values.length; j++) {
                    let MSG = '';
                    if (index !== j) {
                        if (values[index].TaskType !== 0) { // for avoid empty validation
                            if (Item.TaskType === values[index].TaskType &&
                                Item.TaskID === values[index].TaskID) {
                                if (FieldName === "TaskName") {
                                    values[index].TaskID = 0;
                                    MSG = "This task name is already exist.";
                                }
                                values[j] = Item;
                                setInputFields(values);
                                Nodify('Warning!', 'warning', MSG);
                                return false;
                            }
                        }
                    }
                    values[j] = Item;
                });

            } else {

            }
        });
    }

    //sathish
    const filevals = [...getFiles]
    const AppendFiles = async (e) => {

        const file = e.target.files[0];
        let a = getTaskIndexFileupload;
        let b = getTNAtaskIdFileupload;

        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#TNAFileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNADocUpload");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    if (res.statusText === "Created") { }
                    filevals.push({
                        TNAFileID: 0,
                        TaskNameId: b,
                        FileName: file.name,
                        FilePath: "Images/TNARelatedDocs/" + file.name,
                        FileType: file.type,
                        IndexName: (filevals.length + 1).toString(),
                        TaskIndexName: a,
                    })
                    setFiles(filevals);
                    values[a].arrAttachment = filevals.filter(x => x.TaskIndexName === a);
                    values[a].AttachmentCount = filevals.filter(x => x.TaskIndexName === a).length;
                    setInputFields(values);

                    $('#TNAFileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
    const RemoveFiles = async (e, fileindex, index) => {

        filevals.splice(fileindex, 1);
        values[index].arrAttachment.splice(fileindex, 1);
        values[index].AttachmentCount = filevals.length;
        setInputFields(values);
        setFiles(filevals);
    }
    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    const DisabeSelectedTaskName = () => {
        TaskList.forEach((data, i) => {
            TaskList[i].isdisabled = false;
        })

        values.forEach((data, i) => {
            var SelectedIndex = TaskList.findIndex(x => x.value === data.TaskID);
            if (SelectedIndex !== -1) {
                TaskList[SelectedIndex].isdisabled = true;
            }
        })
    }

    const CheckDuplicate = (index, Field) => {

        if (Field === "Duration") {

            // CALCParams.isDuration = true;
            // CALCParams.isEndDate = false;
            // CALCParams.index = index;
            //  setCALCParams(CALCParams);
            //commonCalculation(true, false, index);
        } else {
            for (var j = 0; j < values.length; j++) {
                let MSG = '';
                if (index !== j) {
                    if (values[index].TaskType !== 0 && values[index].TaskID !== 0) { // for avoid empty validation
                        if (values[j].TaskType === values[index].TaskType &&
                            values[j].TaskID === values[index].TaskID) {
                            if (Field === "TaskName") {
                                values[index].TaskID = 0;
                                MSG = "This task name is already exist.";
                            }
                            else if (Field === "TaskType") {
                                values[index].TaskType = 0;
                                MSG = "This task type is already exist.";
                            }
                            setInputFields(values);
                            Nodify('Warning!', 'warning', MSG);
                            return false;
                        }
                    }
                }
            }
        }
    }

    const CheckChildDuplicate = (index, subIndex, childIndex, Field) => {

        if (Field === "Duration") {
            // CALCParams.isDuration = true;
            // CALCParams.isEndDate = false;
            // CALCParams.index = index;
            //  setCALCParams(CALCParams);
            // commonCalculation(true, false, index);
        }

    }

    const handleFollowup = index => {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            if (values[index].IsFollowupExist === 0) {
                Nodify('Warning!', 'warning', "Additional task doesn't exist for this task.");
            }
            else {
                values[index].IsPopupOpen = 1;
                setInputFields(values);
            }
        }
    };

    const handleClose = (index) => {
        values[index].IsPopupOpen = 0;
        setInputFields(values);
    };


    const handleComment = (index, CommentsCount, TnaId, TaskId) => {

        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            // if (CommentsCount !== 0) {
            //     TnaService.LoadTNACommentsList({ TnaId, TaskId }).then((response3) => {
            //         if (response3.data) {
            //             values[index].arrComment = [];
            //             values[index].arrComment = response3.data;
            //             values[index].IsComment = 1;
            //             setTaskIndexFileupload(index);
            //             setTNAtaskIdFileupload(values[index].TaskID);
            //             setInputFields(values);

            //         }

            //     });
            // }
            // else {
            //     values[index].IsComment = 1;
            //     setTaskIndexFileupload(index);
            //     setTNAtaskIdFileupload(values[index].TaskID);
            //     setInputFields(values);

            // }
            values[index].IsComment = 1;
            setTaskIndexFileupload(index);
            setTNAtaskIdFileupload(values[index].TaskID);
            setComment(false);
            setInputFields(values);

        }
    };

    const handleRevice = (index, TaskId) => {

        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            values[index].RevicePopup = 1;
            setRevicedIndex(index);
            setInputFields(values);
            setRevice(false);
        }
    };


    const handleCloseComment = (index) => {
        setCommentSaveText("Save");
        values[index].IsComment = 0;
        values[index].Comments = ""
        setInputFields(values);
    };

    // const TaskIndexFileupload = '';
    // const TNAtaskIdFileupload = '';





    const handleAttachment = (index) => {

        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {

            values[index].IsAttachment = 1;
            setTaskIndexFileupload(index);
            setTNAtaskIdFileupload(values[index].TaskID);
            setFiles(values[index].arrAttachment);
            // getFiles.push({
            //     TNAFileID: element.tnaFileID,
            //     TNAId: element.tnaId,
            //     FileName: element.fileName,
            //     FilePath: element.filePath,
            //     FileType: element.fileType,
            //     IndexName: element.indexName,
            //     TaskIndexName: element.taskIndexName,
            //     Date1: element.createdDate,
            //     //Date: element.createdDate.toLocaleDateString('en-US'),
            //     ModifiedDate: element.modifiedDate
            // })
            setInputFields(values);
        }
    };

    const handleCloseAttachment = (index, field) => {

        if (field === "Close") {
            // i added this line(values[index].IsSaved === undefined) in close popup (having file clearing).
            if (values[index].IsSaved === undefined || values[index].IsSaved === 1) {
                values[index].IsAttachment = 0;
            }
            else {
                values[index].IsAttachment = 0;
                values[index].arrAttachment = [];
                values[index].AttachmentCount = 0
            }

        }
        else {
            values[index].IsAttachment = 0;
            values[index].IsSaved = 1;
        }
        // values[index].IsAttachment = 0;
        setInputFields(values);
    };
    // const handleSaveManual = (index) => {
    //     values[index].IsAttachment = 0;
    //     values[index].IsSaved = 1;
    //     setInputFields(values);
    // };



    // #region ------------------  TaskHolderLeaveDays Start --------------------------------

    const handleTaskHolderLeaveDays = (index, isTHFirstTime) => {

        showLoader();
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {

            if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, 0));
                dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, 0))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, isTHFirstTime);
                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                        setSubmitted(false);
                        hideLoader();
                    });
            } else {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, currentUser.employeeinformationID));
                dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, currentUser.employeeinformationID))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, isTHFirstTime);
                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                        setSubmitted(false);
                        hideLoader();
                    });
            }
        }
        //hideLoader();
    };

    function setTNATaskOwner(data, index, isTHFirstTime) {

        if (data.buyerEmployeeList && data.buyerEmployeeList.length > 0) {

            let taskHolderBuyer = values[index].TaskHolderIDList && data.buyerEmployeeList.filter(item => (values[index].TaskHolderIDList.split(',').includes(item.value.toString())));
            if (taskHolderBuyer.length > 0) {

                if (values[index].arrTaskHolderBuyer.length === 0 && isTHFirstTime === 0) {
                    values[index].arrTaskHolderBuyer = [...taskHolderBuyer];
                } else {
                    const isData = !!values[index].arrTaskHolderBuyer.filter(d => d.value === taskHolderBuyer.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrTaskHolderBuyer = [...values[index].arrTaskHolderBuyer, ...taskHolderBuyer];
                    }
                }

            }
        }
        if (data.cubeEmployeeList !== undefined) {
            let followerCube = values[index].FollowerIDList1 && data.cubeEmployeeList.filter(item => (values[index].FollowerIDList1.split(',').includes(item.value.toString())));
            if (followerCube.length > 0) {
                if (values[index].arrFollowerCube.length === 0 && isTHFirstTime === 0) {
                    values[index].arrFollowerCube = [...followerCube];
                } else {
                    const isData = !!values[index].arrFollowerCube.filter(d => d.value === followerCube.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrFollowerCube = [...followerCube];
                    }
                }
            }
        }
        if (data.supplierEmployeeList !== undefined) {
            let followerSupplier = values[index].FollowerIDList2 && data.supplierEmployeeList.filter(item => (values[index].FollowerIDList2.split(',').includes(item.value.toString())));
            if (followerSupplier.length > 0) {
                if (values[index].arrFollowerSupplier.length === 0 && isTHFirstTime === 0) {
                    values[index].arrFollowerSupplier = [...followerSupplier];
                } else {
                    const isData = !!values[index].arrFollowerSupplier.filter(d => d.value === followerSupplier.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrFollowerSupplier = [...followerSupplier];
                    }
                }
            }
        }

        if (isTHFirstTime === 0) {
            if (values[index].arrTaskHolderBuyer.length > 0) {
                let names = values[index].arrTaskHolderBuyer.map(x => x.label).join(",");
                values[index].TaskHolderBuyerTxt = names;
            }
            if (values[index].arrFollowerCube.length > 0) {
                let names = values[index].arrFollowerCube.map(x => x.label).join(",");
                values[index].FollowerCubeTxt = names;
            }
            if (values[index].arrFollowerSupplier.length > 0) {
                let names = values[index].arrFollowerSupplier.map(x => x.label).join(",");
                values[index].FollowerSupplierTxt = names;
            }
        }
        values[index].IsTHFirstTime = 1;
    }


    const handleTaskHolderLeaveDaysChild = (index, subIndex, childIndex, isTHFirstTime) => {

        showLoader();
        if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].titleEmployee1 !== "Cube" && values[index].SubTaskList[subIndex].ChildTaskList[childIndex].titleEmployee2 !== "Cube" && values[index].SubTaskList[subIndex].ChildTaskList[childIndex].titleEmployee3 !== "Cube") {
            // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, 0));
            dispatch(loadTNATaskOwner(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, 0))
                .then(data => {

                    if (data != null) {
                        setTNATaskOwnerChild(data, index, subIndex, childIndex, isTHFirstTime);
                    }
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHLeaveDaysOpen = 1;
                    setInputFields(values);
                    hideLoader();
                });
        } else {
            // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, currentUser.employeeinformationID));
            dispatch(loadTNATaskOwner(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, currentUser.employeeinformationID))
                .then(data => {

                    if (data != null) {
                        setTNATaskOwnerChild(data, index, subIndex, childIndex, isTHFirstTime);
                    }
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHLeaveDaysOpen = 1;
                    setInputFields(values);
                    hideLoader();
                });
        }

    };

    function setTNATaskOwnerChild(data, index, subIndex, childIndex, isTHFirstTime) {
        if (data.buyerEmployeeList && data.buyerEmployeeList.length > 0) {

            let taskHolderBuyer = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderIDList && data.buyerEmployeeList.filter(item => (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderIDList.split(',').includes(item.value.toString())));
            if (taskHolderBuyer.length > 0) {

                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.length === 0 && isTHFirstTime === 0) {

                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer = [...taskHolderBuyer];

                } else {
                    const isData = !!values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.filter(d => d.value === taskHolderBuyer.value);
                    if (!isData && isTHFirstTime === 0) {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer = [...values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer, ...taskHolderBuyer];
                    }
                }

            }
        }
        if (data.cubeEmployeeList !== undefined) {
            let followerCube = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList1 && data.cubeEmployeeList.filter(item => (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList1.split(',').includes(item.value.toString())));
            if (followerCube.length > 0) {
                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.length === 0 && isTHFirstTime === 0) {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube = [...followerCube];
                } else {
                    const isData = !!values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.filter(d => d.value === followerCube.value);
                    if (!isData && isTHFirstTime === 0) {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube = [...followerCube];
                    }
                }
            }
        }
        if (data.supplierEmployeeList !== undefined) {
            let followerSupplier = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList2 && data.supplierEmployeeList.filter(item => (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerIDList2.includes(item.value.toString())));
            if (followerSupplier.length > 0) {
                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.length === 0 && isTHFirstTime === 0) {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier = [...followerSupplier];
                } else {
                    const isData = !!values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.filter(d => d.value === followerSupplier.value);
                    if (!isData && isTHFirstTime === 0) {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier = [...followerSupplier];
                    }
                }
            }
        }

        if (isTHFirstTime === 0) {
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.length > 0) {
                let names = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrTaskHolderBuyer.map(x => x.label).join(",");
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderBuyerTxt = names;
            }
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.length > 0) {
                let names = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerCube.map(x => x.label).join(",");
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCubeTxt = names;
            }
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.length > 0) {
                let names = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrFollowerSupplier.map(x => x.label).join(",");
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplierTxt = names;
            }
        }

        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHFirstTime = 1;
    }

    const handleTHLeaveDaysClose = (index) => {
        BindTaskHoldername(values, index);
        values[index].IsTHLeaveDaysOpen = 0;
        setInputFields(values);
        CALCParams.isDuration = false;
        CALCParams.isEndDate = false;
        CALCParams.index = index;
        //  setCALCParams(CALCParams);
        setSubmitted(false);
        //commonCalculation(false, false, index);
    }

    const handleTHLeaveDaysSave = (index) => {
        let Taskhoder = BindTaskHoldername(values, index);
        if (Taskhoder === "") {
            setSubmitted(true);
            Nodify('Warning!', 'warning', 'Please select task holder name');
        } else {
            values[index].IsTHLeaveDaysOpen = 0;
            setInputFields(values);
            CALCParams.isDuration = false;
            CALCParams.isEndDate = false;
            CALCParams.index = index;
            // setCALCParams(CALCParams);
            // commonCalculation(false, false, index);
        }

    }

    // const handleTHLeaveDaysSaveNext = (index) => {
    //
    //     let Taskhoder = BindTaskHoldername(values, index);
    //     if (Taskhoder === '') {
    //         Nodify('Warning!', 'warning', 'Please select the task holder name.');
    //     }
    //     else {
    //         values[index].IsTHLeaveDaysOpen = 0;
    //         index = index + 1;
    //         let totalValue = values.length - 1;
    //         if (totalValue >= index) {
    //             let firstval = values[index].IsTHFirstTime;
    //             if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
    //                 dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, 0))
    //                     .then(data => {
    //                         if (data != null) {
    //                             setTNATaskOwner(data, index, firstval);
    //                         }
    //                         values[index].IsTHLeaveDaysOpen = 1;
    //                         setInputFields(values);
    //                     });
    //             } else {
    //                  dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, currentUser.employeeinformationID))
    //                     .then(data => {
    //                         if (data != null) {
    //                             setTNATaskOwner(data, index, firstval);
    //                         }
    //                         values[index].IsTHLeaveDaysOpen = 1;
    //                         setInputFields(values);
    //                     });
    //             }
    //           }
    //         else { values[index - 1].IsTHLeaveDaysOpen = 0; }
    //         setInputFields(values);
    //         commonCalculation(false, false);
    //     }

    // }

    const handleTHLeaveDaysSaveNext = (index) => {
        let Taskhoder = BindTaskHoldername(values, index);
        //let Taskhoder = 'BindTaskHoldername(values, index)';
        if (Taskhoder === "") {
            Nodify('Warning!', 'warning', 'Please select task holder name');
        } else {
            values[index].IsTHLeaveDaysOpen = 0;
            // commonCalculation(false, false, index);
            // save Next instance
            index = index + 1;
            let totalValue = values.length - 1;
            if (totalValue >= index) {
                handleTaskHolderLeaveDays(index, values[index].IsTHFirstTime)
                //end Next

            }
            else {
                values[index - 1].IsTHLeaveDaysOpen = 0;
                setInputFields(values);
                CALCParams.isDuration = false;
                CALCParams.isEndDate = false;
                CALCParams.index = index;
                //  setCALCParams(CALCParams);
                // commonCalculation(false, false, index);
            }

        }
    }


    const handleTHLeaveDaysChildClose = (index, subIndex, childIndex) => {

        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTHLeaveDaysOpen = 0;
        let TxtList = "";
        let IdList = "";
        if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderBuyer !== '') {
            TxtList = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderBuyerTxt;
            IdList = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderBuyer;
        }
        if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCube !== '') {
            TxtList = TxtList === "" ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCubeTxt : TxtList + ", " + values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCubeTxt;
            IdList = IdList === "" ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCube : IdList + "," + values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerCube;
        }
        if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplier !== '') {
            TxtList = TxtList === "" ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplierTxt : TxtList + ", " + values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplierTxt;
            IdList = IdList === "" ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplier : IdList + "," + values[index].SubTaskList[subIndex].ChildTaskList[childIndex].FollowerSupplier;
        }

        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderOwnerTxtList = TxtList;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].TaskHolderOwnerIdList = IdList;

        setInputFields(values);
        // commonCalculation();
    }


    // #endregion ------------------  TaskHolderLeaveDays End --------------------------------

    //#region ------------------  Dependency Start ---------------------------------------

    const handleDependency = (index) => {

        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            values[index].IsDependencyOpen = 1;
            let TaskDependency = [];
            values.map((Item) => {

                if (values[index].arrDependency && values[index].arrDependency.length > 0) {
                    let AlreadyExist = values[index].arrDependency.filter(x => x.DependentTaskName === Item.TaskID);
                    let DependencyID = 0;
                    let LogDays = '';
                    if (AlreadyExist.length === 0) {
                        DependencyID = 0;
                        LogDays = '';
                    }
                    else {
                        DependencyID = AlreadyExist[0].DependencyID;
                        LogDays = AlreadyExist[0].LogDays;
                    }
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item.TaskID,
                            TNATaskName: Item.strTaskName,
                            DependencyID: DependencyID,
                            LogDays: LogDays,
                            IndexName: (index).toString(),
                            StartDate: Item.StartDate,
                            EndDate: Item.EndDate,
                            Duration: Item.Duration,
                        }
                    )
                }
                else {
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item.TaskID,
                            TNATaskName: Item.strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: (index).toString(),
                            StartDate: Item.StartDate,
                            EndDate: Item.EndDate,
                            Duration: Item.Duration,
                        }
                    )
                }

            });

            values[index].arrDependency = [];
            values[index].arrDependency = TaskDependency;
            setInputFields(values);
        }
    };

    const handleDependencyClose = (index) => {
        values[index].IsDependencyOpen = 0;
        setInputFields(values);
        //  CALCParams.isDuration = false;
        //  CALCParams.isEndDate = false;
        //  CALCParams.index = index;
        //   setCALCParams(CALCParams);
        //commonCalculation(false, false, index);
    };

    //#endregion ------------------  Dependency End --------------------------------


    // #region  ------------------  Child Start --------------------------------


    // #region --------------Child Change-------------
    const handleChildChange = (e, index, childIndex, FieldName) => {
        setSubmitted(false);
        let inputText = '';
        if (FieldName === "DependentTaskName" || FieldName === "DependencyID") {
            if (e != null) {
                inputText = parseInt(e.value);
            }
            else {
                inputText = 0;
                if (FieldName === "DependencyID") {
                    values[index].arrDependency[childIndex].LogDays = '';
                }
            }
            values[index].arrDependency[childIndex][FieldName] = inputText;
        }

        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
                var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
                inputText = checkNumber[0];
            }
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index].arrDependency[childIndex][e.target.name] = inputText;
        }
        if (FieldName === "DependencyID") {
            values[index].SelectedDependency = '';
            let DependencyShow = '';
            for (let i = 0; i < values[index].arrDependency.length; i++) {
                if (index !== i) {
                    let DependentID = values[index].arrDependency[i].DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                    }
                }
            }
            values[index].SelectedDependency = DependencyShow;
        }
        values[index].DependencyCount = values[index].arrDependency.filter(x => x.DependencyID !== 0).length;

        setInputFields(values);
        DepandencyCalculationforCommonTask('childChange');
    }

    // Child Add Popup
    const handleChildAddFieldsClick = (index, subIndex, childIndex, taskName, subParentId) => {

        let referenceIdList = values[index].SubTaskList[subIndex].ChildTaskList && values[index].SubTaskList[subIndex].ChildTaskList.map(x => x.SubId).join(",");
        dispatch(loadTNAChildReferenceUnMappedList({ TaskName: taskName, TNAId: props.location.state.params.tnaid, SubParentId: subParentId, ReferenceIdList: referenceIdList }))
            .then(data => {
                if (data != null) {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].drpChildTaskList = data;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 1;
                    setInputFields(values);
                }
            });

        //setCurrentChildTask({ value: 0, label: '' });
    }

    // Child Add Popup Close
    const handleAddChildTaskClose = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 0;
        setInputFields(values);
    }

    const handleAddProgramkClose = (index) => {
        values[index].isProgramNewtask = false;
        setInputFields(values);
    }
    // Child Add Popup Change
    const handleChangeChildTask = (e) => {

        if (e != null) {
            let inputVal = parseInt(e.value);
            setCurrentChildTask({ value: inputVal, label: e.label });
        } else {
            setCurrentChildTask({ value: 0, label: '' });
        }
    }

    // Child Add Popup Save New Row
    const handleAddRowChildTask = (index, subIndex, childIndex, Rowindex) => {

        if (getCurrentChildTask.value !== "" && getCurrentChildTask.value > 0) {
            let parentRow = values[index];
            let childRow = values[index].SubTaskList[subIndex].ChildTaskList[childIndex];
            let count = childIndex;
            count++;
            let EmptyChildList = {};
            values[index].SubTaskList[subIndex].IsNewRow = true;

            EmptyChildList =
            {
                ChildId: 0,
                SubId: getCurrentChildTask.value,
                Name: getCurrentChildTask.label,
                TaskName: childRow.TaskName,
                TaskID: childRow.TaskID,
                MainId: childRow.MainId,
                Duration: parentRow.Duration,
                EndDate: parentRow.EndDate,
                StartDate: parentRow.StartDate,
                PreStartDate: parentRow.StartDate,
                PreEndDate: parentRow.EndDate,
                // arrDependency: parentRow.arrDependency,
                TaskHolderList: '',
                TaskHolderOrFollower: childRow.TaskHolderOrFollower,
                TaskHolderOrFollowerTxt: childRow.TaskHolderOrFollowerTxt,
                TaskHolderOwnerTxtList: childRow.TaskHolderOwnerTxtList,
                TaskHolderIDList: childRow.TaskHolderIDList,
                FollowerIDList1: childRow.FollowerIDList1,
                FollowerIDList2: childRow.FollowerIDList2,
                TaskHolderBuyer: childRow.TaskHolderBuyer,
                FollowerCube: childRow.FollowerCube,
                FollowerSupplier: childRow.FollowerSupplier,
                arrTaskHolderBuyer: [],
                arrFollowerCube: [],
                arrFollowerSupplier: [],
                TaskHolderBuyerTxt: '',
                FollowerCubeTxt: '',
                FollowerSupplierTxt: '',
                TaskHolderOwnerIdList: '',
                titleEmployee1: childRow.titleEmployee1,
                titleEmployee2: childRow.titleEmployee2,
                titleEmployee3: childRow.titleEmployee3,
                IsTHFirstTime: 0,
                // DependencyCount: 0,
                // IsDependencyOpen: 0,
                // IsTHLeaveDaysOpen: 0,
                //  SelectedDependency: parentRow.SelectedDependency,
                LeaveDays: 0,
                LeaveDaysTxt: '',
                StatusID: 1,
                AWBStatus: '',
                IsAddChildTaskOpen: 0,
                StrikeOffOrTrimInfoId: childRow.StrikeOffOrTrimInfoId,
                drpChildTaskList: [],
                IsManualClose: 0,
                IsExFactoryForm: 0,
                ManualCloseComment: '',
                IsShowSubmitButton: childRow.IsShowSubmitButton,
                IsShowApprovalButton: childRow.IsShowApprovalButton,
                ManualCloseTaskwise: childRow.ManualCloseTaskwise,
                ChildActualEndDate: childRow.ChildActualEndDate,
                ManualCloseDate: childRow.ManualCloseDate === null || childRow.ManualCloseDate === "" ? '' : new Date(childRow.ManualCloseDate),
                EndDateChange: 0,
                ManualApprovalType: 0,

                ExistingApprovalType: 0,
                RevicePopup: 0,
                IsReviced: childRow.IsReviced,
                intDependency: childRow.intDependency,
                hasAllDependencyStart: 0,
                hasAllDependencyStartPopup: false,
                DependencyDate: childRow.DependencyDate,
                DependencyComment: childRow.DependencyComment,
                MultiDependencyDate: "",
                MultiDependencyComment: "",
                IsRescheduled: childRow.IsRescheduled,
                IsDependencyStartPopup: false,
                IDuration: childRow.IDuration,
                IEndDate: parentRow.EndDate,
                IStartDate: parentRow.StartDate,
                ReviceSelected: 0,
                RescheduleSelected: 0,
                ReschedulePopup: 0,
                ReviceAdded: 0,
                RescheduleAdded: 0,
                SubRevicedetails:
                {
                    TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                    ReviceComment: '', TaskName: '', Operation: 0,
                    StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                    Createdby: 0, Type: ''
                },
                SubRescheduledetails:
                {
                    TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                    RescheduleComment: '', TaskName: '', Operation: 0,
                    StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                    Createdby: 0, Type: ''
                },
                SwatchStatus: childRow.SwatchStatus,
                SKUIdList: childRow.SKUIdList,
                PurchaseOrderSkuID: childRow.PurchaseOrderSkuID,
                StyleStrikeOffGarmentSizeIdList: childRow.StyleStrikeOffGarmentSizeIdList,
                hasAutoOpen: false,
                IsChildDepandencyOpen: 0,
                ChildIndex: 0,
                arrDependency: [],
                DependencyCount: 0,
                SelectedDependency: '',
                hasSKUNotMatch: false,
                AssignedDepandency: '',
                IsDepandency: 0,
                //AutoText: ''


            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 0;
            values[index].SubTaskList[subIndex].ChildTaskList.splice(count, 0, EmptyChildList);
            ReassignIndexonAddDeleteTask(index, subIndex, childIndex, 'Add', Rowindex);
            setInputFields(values);

            //  let currchildindex = values[index].SubTaskList[subIndex].ChildTaskList.length - 1;
            // commonCalculationParentAndChild(values[index], values[index].SubTaskList[subIndex].ChildTaskList[childIndex], index, subIndex, childIndex, false, false, false, index);
        } else {
            Nodify('Warning!', 'warning', "Please select the Task");
        }

    }

    const handleChildRowRemoveFields = (index, subIndex, childIndex, Taskname, mainId, SubId, Rowindex, OrgRowno) => {

        let SubmitCount = 0;
        if (Taskname === "Lab Dip Submission") {
            let isvalid = false;
            let labdipApproval = values.filter(x => x.strTaskName === "Lab Dip Approval")
            if (labdipApproval !== undefined && labdipApproval.length > 0) {
                if (labdipApproval[0].SubTaskList[subIndex] === undefined) {
                    isvalid = false;
                } else {
                    isvalid = !!labdipApproval[0].SubTaskList[subIndex].ChildTaskList.find(x => x.SubId === SubId && x.MainId === mainId)
                }
            }
            else {
                isvalid = false;
            }
            if (isvalid) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "Strike off Submission (Local Development)") {
            let isvalid = false;
            let StrikeoffApproval = values.filter(x => x.strTaskName === "Strike off Approval (Local Development)")
            if (StrikeoffApproval !== undefined && StrikeoffApproval.length > 0) {
                if (StrikeoffApproval[0].SubTaskList[subIndex] === undefined) {
                    isvalid = false;
                } else {
                    isvalid = !!StrikeoffApproval[0].SubTaskList[subIndex].ChildTaskList.find(x => x.SubId === SubId && x.MainId === mainId)
                }
            }
            else {
                isvalid = false;
            }
            if (isvalid) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "Trim Submission (Local Development)") {
            let isvalid = false;
            let TrimApproval = values.filter(x => x.strTaskName === "Trim Approval (Local Development)")
            if (TrimApproval !== undefined && TrimApproval.length > 0) {
                if (TrimApproval[0].SubTaskList[subIndex] === undefined) {
                    isvalid = false;
                } else {
                    isvalid = !!TrimApproval[0].SubTaskList[subIndex].ChildTaskList.find(x => x.SubId === SubId && x.MainId === mainId)
                }
            }
            else {
                isvalid = false;
            }
            if (isvalid) {
                SubmitCount = SubmitCount + 1;
            }
        }
        else if (Taskname === "SMS Submission") {
            values.filter(x => x.strTaskName === "SMS Approval").map((parentitem) => {
                parentitem.SubTaskList.map((childitem) => {
                    childitem.ChildTaskList.map((subchilditem) => {
                        if (subchilditem.SubId === SubId) {
                            SubmitCount = SubmitCount + 1;
                        }
                    })
                })
            })
        }
        else if (Taskname === "Proto Sample Submission") {
            values.filter(x => x.strTaskName === "Proto Sample Approval").map((parentitem) => {
                parentitem.SubTaskList.map((childitem) => {
                    childitem.ChildTaskList.map((subchilditem) => {
                        if (subchilditem.SubId === SubId) {
                            SubmitCount = SubmitCount + 1;
                        }
                    })
                })
            })
        }
        else if (Taskname === "Fit Sample Submission") {
            values.filter(x => x.strTaskName === "Fit Sample Approval").map((parentitem) => {
                parentitem.SubTaskList.map((childitem) => {
                    childitem.ChildTaskList.map((subchilditem) => {
                        if (subchilditem.SubId === SubId) {
                            SubmitCount = SubmitCount + 1;
                        }
                    })
                })
            })
        }
        else if (Taskname === "Shipment Sample Submission") {
            values.filter(x => x.strTaskName === "Shipment Sample Approval").map((parentitem) => {
                parentitem.SubTaskList.map((childitem) => {
                    childitem.ChildTaskList.map((subchilditem) => {
                        if (subchilditem.SubId === SubId) {
                            SubmitCount = SubmitCount + 1;
                        }
                    })
                })
            })
        }
        else if (Taskname === "Pre Production Sample Submission") {
            values.filter(x => x.strTaskName === "Pre Production Sample Approval").map((parentitem) => {
                parentitem.SubTaskList.map((childitem) => {
                    childitem.ChildTaskList.map((subchilditem) => {
                        if (subchilditem.SubId === SubId) {
                            SubmitCount = SubmitCount + 1;
                        }
                    })
                })
            })
        }
        else if (Taskname === "Size Set Sample Submission") {
            values.filter(x => x.strTaskName === "Size Set Sample Approval").map((parentitem) => {
                parentitem.SubTaskList.map((childitem) => {
                    childitem.ChildTaskList.map((subchilditem) => {
                        if (subchilditem.SubId === SubId) {
                            SubmitCount = SubmitCount + 1;
                        }
                    })
                })
            })
        }
        else if (Taskname === "Photo Sample Submission") {
            values.filter(x => x.strTaskName === "Photo Sample Approval").map((parentitem) => {
                parentitem.SubTaskList.map((childitem) => {
                    childitem.ChildTaskList.map((subchilditem) => {
                        if (subchilditem.SubId === SubId) {
                            SubmitCount = SubmitCount + 1;
                        }
                    })
                })
            })
        }
        if (SubmitCount > 0) {
            Nodify('Warning!', 'warning', 'Approval exists aganist Submission Tasks.');
        }
        else {

            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 0;
            values[index].SubTaskList[subIndex].ChildTaskList.splice(childIndex, 1);
            setInputFields(values);
            let checkSubTask = values[index].SubTaskList[subIndex].ChildTaskList.length;
            if (checkSubTask === 0) {
                values[index].SubTaskList.splice(subIndex, 1);
                let checkMainTask = values[index].SubTaskList.length;
                if (checkMainTask === 0) {
                    values.splice(index, 1);

                    values.map((Item, j) => {
                        //for (let j = 0; j < values.length; j++) {
                        Item.arrDependency.splice(index, 1);

                        Item.SelectedDependency = '';
                        let DependencyShow = '';
                        Item.arrDependency.map((Item2, i) => {
                            //for (let i = 0; i < values[j].arrDependency.length; i++) {
                            if (j !== i) {
                                let DependentID = Item2.DependencyID;
                                if (DependentID !== 0) {
                                    if (DependencyShow === '') {
                                        DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                                    }
                                    else {
                                        DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                                    }
                                }
                            }
                        });
                        Item.SelectedDependency = DependencyShow;
                        Item.DependencyCount = Item.arrDependency.filter(x => x.DependencyID !== 0).length;
                        values[j] = Item;
                    });
                    setInputFields(values);
                    CALCParams.isDuration = false;
                    CALCParams.isEndDate = false;
                    CALCParams.index = index;
                    //  setCALCParams(CALCParams);
                    //commonCalculation(false, false, index);
                    DisabeSelectedTaskName();
                    ReassignIndexonAddDeleteTask(index, subIndex, childIndex, 'Remove', Rowindex, OrgRowno);
                } else {
                    setInputFields(values);
                    ReassignIndexonAddDeleteTask(index, subIndex, childIndex, 'Remove', Rowindex, OrgRowno);
                }
            } else {
                setInputFields(values);
                ReassignIndexonAddDeleteTask(index, subIndex, childIndex, 'Remove', Rowindex, OrgRowno);
            }

        }

    }


    const ReassignIndexonAddDeleteTask = (index, subIndex, childIndex, AddorRemove, Rowindex, OrgRowno) => {

        let Childrowno = 1;
        let rowforalltask = 1;
        // if (AddorRemove === "Remove") {
        //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency.splice(childIndex, 1);
        // }

        let TaskDependency =
        {
            TNAChildDependencyID: 0,
            DependentTaskId: getCurrentChildTask.value,
            TNATaskName: getCurrentChildTask.lablel,
            DependencyID: 0,
            LogDays: '',
            IndexName: 0,
            Mainindex: Rowindex,
            StartDate: '',
            EndDate: '',
            Duration: ''
        }


        values.map((Main, Mainindex) => {

            Main.SubTaskList.map((Child, ChildIndex) => {
                Child.ChildTaskList.map((SubChild, Subchildindex) => {
                    // SubChild.RownoforAllTasks = rowforalltask;
                    // rowforalltask++

                    SubChild.SelectedDependency = '';
                    let DependencyShow = '';


                    if (SubChild.arrDependency && SubChild.arrDependency.length > 0) {
                        if (AddorRemove === "Remove") {
                            SubChild.arrDependency.splice((OrgRowno - 1), 1);
                            let Childarrrowno = 1;
                            SubChild.arrDependency.map((arr, index) => arr.ChildIndex = Childarrrowno + index)
                        }
                        if (AddorRemove === "Add") {
                            SubChild.arrDependency.splice((OrgRowno), 0, TaskDependency);
                        }
                        SubChild.arrDependency.filter(x => x.DependencyID !== 0).map((Item2, i) => {
                            let DepandencyNumber = Item2.IndexName;
                            let DependentID = Item2.DependencyID;
                            let singletask;
                            let ChildIndexno;

                            let ParentIndexNo = 0;
                            values.map((Main, Mainindex) => {
                                if (Main.IsSingleTask === true) {

                                    if (Main.RownoforAllTasks === DepandencyNumber) {
                                        ParentIndexNo = Mainindex + 1;
                                        singletask = true;
                                    }
                                }
                                //  if (ParentIndexNo === '') {
                                Main.SubTaskList.map((Child, ChildIndex) => {
                                    Child.ChildTaskList.map((SubChild, SubChildIndex) => {
                                        if (SubChild.RownoforAllTasks === DepandencyNumber) {
                                            ParentIndexNo = Mainindex + 1;
                                            ChildIndexno = SubChild.ChildIndex;
                                            singletask = false;
                                        }

                                    })
                                })
                                // }
                            })

                            if (DependentID !== 0) {
                                if (DependencyShow === '') {
                                    if (singletask === true) {
                                        DependencyShow = (ParentIndexNo) +
                                            '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                                    }
                                    else {
                                        DependencyShow = (ParentIndexNo + "-" + ChildIndexno) +
                                            '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                                    }

                                }
                                else {
                                    if (singletask === true) {
                                        DependencyShow = DependencyShow + ',' + (ParentIndexNo) +
                                            '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                                    }
                                    else {
                                        DependencyShow = DependencyShow + ',' + (ParentIndexNo + "-" + ChildIndexno) +
                                            '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                                    }
                                }
                            }
                            // if (DependentID !== 0) {
                            //     if (DependencyShow === '') {
                            //         DependencyShow = (ParentIndexNo + "-" + (i + 1)) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")

                            //     }
                            //     else {
                            //         DependencyShow = DependencyShow + ',' + (ParentIndexNo + "-" + (i + 1)) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")

                            //     }

                            // }
                            //}
                        });
                        SubChild.SelectedDependency = DependencyShow;
                        SubChild.ChildIndex = Childrowno;
                        SubChild.DependencyCount = SubChild.arrDependency.filter(x => x.DependencyID !== 0).length;
                        values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex] = SubChild;

                    }
                    else {
                        SubChild.ChildIndex = Childrowno;
                        values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex] = SubChild;
                    }
                    Childrowno++;
                })

            })
        });

    }



    const ChildDepandencyCalculation = (type, index, subIndex, childIndex) => {
        let Duraion;
        let LeaveDays;
        let titleEmployee1;
        let MultidepItem = [];
        let SetStartdate = new Date();
        let SetEnddate = new Date();

        values.map((Main, Mainindex) => {
            LeaveDays = Main.LeaveDays;
            titleEmployee1 = Main.titleEmployee1;
            Main.SubTaskList.map((Child, ChildIndex) => {

                Child.ChildTaskList.map((SubChild, Subchildindex) => {

                    if (Mainindex === index && ChildIndex === subIndex && Subchildindex === childIndex) {

                    }
                    else {
                        Duraion = SubChild.Duration;

                        SubChild.arrDependency.map((Item2, i) => {

                            let DependentID = Item2.DependencyID;
                            let Startdate = Item2.StartDate;
                            let Enddate = Item2.EndDate;
                            let total = 0;
                            let tempEnddate = new Date();
                            let tempStartdate = new Date();
                            let Logdays = Item2.LogDays === '' || Item2.LogDays === undefined ? 0 : parseInt(Item2.LogDays);

                            if (DependentID !== 0) {
                                // if (DependentID > 0) {
                                //  if (Main.DetailTypeID !== 4) {
                                //End to Start
                                if (DependentID === 1) {

                                    SetStartdate = addDays(Enddate, 1);
                                    if (Logdays !== 0) {
                                        //LogDays Add
                                        SetStartdate = addDays(SetStartdate, parseInt(Logdays));
                                    }
                                    //Calculation Saturday,Sunday and Holiday Count
                                    SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
                                    tempEnddate = addDays(SetStartdate, parseInt(Duraion) - 1);
                                    //Total Saturday,Sunday and Holiday Count
                                    total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                                    for (let l = 0; l < total; l++) {
                                        tempEnddate = addDays(tempEnddate, 1);
                                        tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
                                    }

                                    SetEnddate = tempEnddate;

                                }
                                //Start to Start
                                if (DependentID === 2) {


                                    if (Logdays !== 0) {
                                        SetStartdate = addDays(Startdate, parseInt(Logdays));
                                        SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
                                    } else {
                                        //Calculation Saturday,Sunday and Holiday Count
                                        SetStartdate = checkDateBetweenInHolidayAndWE(Startdate, LeaveDays, titleEmployee1, "add");
                                    }

                                    tempEnddate = addDays(SetStartdate, parseInt(Duraion) - 1);
                                    //Total Saturday,Sunday and Holiday Count
                                    total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                                    for (let l = 0; l < total; l++) {
                                        tempEnddate = addDays(tempEnddate, 1);
                                        tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
                                    }
                                    SetEnddate = tempEnddate;

                                }
                                //Start to End
                                if (DependentID === 3) {
                                    var result = new Date(Startdate);
                                    result.setDate(result.getDate() - 1);
                                    if (Logdays !== 0) {
                                        SetEnddate = addDays(result, parseInt(Logdays));
                                        SetEnddate = checkDateBetweenInHolidayAndWE(SetEnddate, LeaveDays, titleEmployee1, "add");
                                    }
                                    else {
                                        SetEnddate = checkDateBetweenInHolidayAndWE(subDays(Startdate, 1), LeaveDays, titleEmployee1, "sub");
                                    }
                                    tempStartdate = subDays(SetEnddate, parseInt(Duraion) - 1);
                                    //Total Saturday,Sunday and Holiday Count
                                    total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                                    for (let l = 0; l < total; l++) {
                                        tempStartdate = subDays(tempStartdate, 1);
                                        tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
                                    }
                                    SetStartdate = tempStartdate;

                                }
                                //End to end
                                if (DependentID === 4) {

                                    if (Logdays !== 0) {
                                        //LogDays Add
                                        SetEnddate = addDays(Enddate, parseInt(Logdays));
                                        SetEnddate = checkDateBetweenInHolidayAndWE(SetEnddate, LeaveDays, titleEmployee1, "add");
                                    } else {
                                        SetEnddate = checkDateBetweenInHolidayAndWE(Enddate, LeaveDays, titleEmployee1, "sub",);
                                    }

                                    tempStartdate = subDays(SetEnddate, parseInt(Duraion) - 1);
                                    //Total Saturday,Sunday and Holiday Count
                                    total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                                    for (let l = 0; l < total; l++) {
                                        tempStartdate = subDays(tempStartdate, 1);
                                        tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
                                    }
                                    SetStartdate = tempStartdate;

                                }
                                //  }
                                //  else {

                                //  }


                                SubChild.StartDate = SetStartdate;
                                SubChild.EndDate = SetEnddate;
                                let AssignedDepandency = DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE"
                                SubChild.AssignedDepandency = AssignedDepandency;
                                SubChild.AssignedStartDate = SetStartdate;
                                SubChild.AssignedEndDate = SetEnddate;

                                MultidepItem.push(
                                    {
                                        Startdate: SetStartdate,
                                        Enddate: SetEnddate,
                                        AssignedDepandency: AssignedDepandency,
                                        AssignedStartDate: SetStartdate,
                                        AssignedEndDate: SetEnddate
                                    })

                                if (type === "childChange") {
                                    values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[i].StartDate = SetStartdate;
                                    values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[i].EndDate = SetEnddate;
                                }
                                else {
                                    values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[DepandencyAllIndex.RowIndex - 1].StartDate = SetStartdate;
                                    values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[DepandencyAllIndex.RowIndex - 1].EndDate = SetEnddate;
                                }


                            }

                        }
                        )

                        if (MultidepItem.length > 1) {
                            const sorted = MultidepItem.slice().sort((a, b) => b.Enddate - a.Enddate);
                            SubChild.StartDate = sorted[0].Startdate;
                            SubChild.EndDate = sorted[0].Enddate;
                            SubChild.AssignedDepandency = sorted[0].AssignedDepandency;
                            SubChild.AssignedStartDate = sorted[0].AssignedStartDate;
                            SubChild.AssignedEndDate = sorted[0].AssignedEndDate;

                            if (type !== "childChange") {
                                values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[DepandencyAllIndex.RowIndex - 1].StartDate = sorted[0].Startdate;
                                values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[DepandencyAllIndex.RowIndex - 1].EndDate = sorted[0].Enddate;

                            }
                        }


                        values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex] = SubChild;
                        MultidepItem = [];
                    }
                })

            })

        });



    }


    const DepandencyCalculationforCommonTask = (type, index, subIndex, childIndex) => {

        let Duraion;
        let LeaveDays;
        let titleEmployee1;
        let MultidepItem = [];
        let SetStartdate = new Date();
        let SetEnddate = new Date();

        values.map((Main, Mainindex) => {
            if (Main.DetailTypeID === 1) {
                LeaveDays = Main.LeaveDays;
                titleEmployee1 = Main.titleEmployee1;

                Duraion = Main.Duration;

                Main.arrDependency.map((Item2, i) => {

                    let DependentID = Item2.DependencyID;
                    let Startdate = Item2.StartDate;
                    let Enddate = Item2.EndDate;
                    let total = 0;
                    let tempEnddate = new Date();
                    let tempStartdate = new Date();
                    let Logdays = Item2.LogDays === '' || Item2.LogDays === undefined ? 0 : parseInt(Item2.LogDays);

                    if (DependentID !== 0) {
                        //  if (Main.DetailTypeID !== 4) {
                        //End to Start
                        if (DependentID === 1) {

                            SetStartdate = addDays(Enddate, 1);
                            if (Logdays !== 0) {
                                //LogDays Add
                                SetStartdate = addDays(SetStartdate, parseInt(Logdays));
                            }
                            //Calculation Saturday,Sunday and Holiday Count
                            SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
                            tempEnddate = addDays(SetStartdate, parseInt(Duraion) - 1);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                            for (let l = 0; l < total; l++) {
                                tempEnddate = addDays(tempEnddate, 1);
                                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
                            }

                            SetEnddate = tempEnddate;

                        }
                        //Start to Start
                        if (DependentID === 2) {

                            if (Logdays !== 0) {
                                SetStartdate = addDays(Startdate, parseInt(Logdays));
                                SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
                            } else {
                                //Calculation Saturday,Sunday and Holiday Count
                                SetStartdate = checkDateBetweenInHolidayAndWE(Startdate, LeaveDays, titleEmployee1, "add");
                            }

                            tempEnddate = addDays(SetStartdate, parseInt(Duraion) - 1);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                            for (let l = 0; l < total; l++) {
                                tempEnddate = addDays(tempEnddate, 1);
                                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
                            }
                            SetEnddate = tempEnddate;

                        }
                        //Start to End
                        if (DependentID === 3) {
                            if (Logdays !== 0) {
                                SetEnddate = addDays(Startdate, parseInt(Logdays));
                                SetEnddate = checkDateBetweenInHolidayAndWE(SetEnddate, LeaveDays, titleEmployee1, "add");
                            }
                            else {
                                SetEnddate = checkDateBetweenInHolidayAndWE(subDays(Startdate, 1), LeaveDays, titleEmployee1, "sub");
                            }
                            tempStartdate = subDays(SetEnddate, parseInt(Duraion) - 1);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                            for (let l = 0; l < total; l++) {
                                tempStartdate = subDays(tempStartdate, 1);
                                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
                            }
                            SetStartdate = tempStartdate;

                        }
                        //End to end
                        if (DependentID === 4) {

                            if (Logdays !== 0) {
                                //LogDays Add
                                SetEnddate = addDays(Enddate, parseInt(Logdays));
                                SetEnddate = checkDateBetweenInHolidayAndWE(SetEnddate, LeaveDays, titleEmployee1, "add");
                            } else {
                                SetEnddate = checkDateBetweenInHolidayAndWE(Enddate, LeaveDays, titleEmployee1, "sub",);
                            }

                            tempStartdate = subDays(SetEnddate, parseInt(Duraion) - 1);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                            for (let l = 0; l < total; l++) {
                                tempStartdate = subDays(tempStartdate, 1);
                                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
                            }
                            SetStartdate = tempStartdate;

                        }
                        //  }
                        //  else {

                        //  }


                        Main.StartDate = SetStartdate;
                        Main.EndDate = SetEnddate;
                        let AssignedDepandency = DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE"
                        Main.AssignedDepandency = AssignedDepandency;
                        Main.AssignedStartDate = SetStartdate;
                        Main.AssignedEndDate = SetEnddate;

                        MultidepItem.push(
                            {
                                Startdate: SetStartdate,
                                Enddate: SetEnddate,
                                AssignedDepandency: AssignedDepandency,
                                AssignedStartDate: SetStartdate,
                                AssignedEndDate: SetEnddate
                            })

                        if (type === "childChange") {
                            values[Mainindex].arrDependency[i].StartDate = SetStartdate;
                            values[Mainindex].arrDependency[i].EndDate = SetEnddate;
                        }
                        else {
                            values[Mainindex].arrDependency[DepandencyAllIndex.RowIndex - 1].StartDate = SetStartdate;
                            values[Mainindex].arrDependency[DepandencyAllIndex.RowIndex - 1].EndDate = SetEnddate;
                        }


                    }

                })

                if (MultidepItem.length > 1) {
                    const sorted = MultidepItem.slice().sort((a, b) => b.Enddate - a.Enddate);
                    Main.StartDate = sorted[0].Startdate;
                    Main.EndDate = sorted[0].Enddate;
                    Main.AssignedDepandency = sorted[0].AssignedDepandency;
                    Main.AssignedStartDate = sorted[0].AssignedStartDate;
                    Main.AssignedEndDate = sorted[0].AssignedEndDate;

                    if (type !== "childChange") {
                        values[Mainindex].arrDependency[DepandencyAllIndex.RowIndex - 1].StartDate = sorted[0].Startdate;
                        values[Mainindex].arrDependency[DepandencyAllIndex.RowIndex - 1].EndDate = sorted[0].Enddate;

                    }
                }
                values[Mainindex] = Main;
                MultidepItem = [];
            }
        });



    }

    function reloadExfactcal(commonItem, i, p, c, LeaveDays, titleEmployee1) {

        let SkuId = commonItem.SubId;
        let ChildId = commonItem.ChildId;
        let Duration = commonItem.Duration;
        let skugroup = values[i].SubTaskList[p].ChildTaskList.filter(x => x.SubId === SkuId && x.ChildId === ChildId);
        let indexofcurrrow = skugroup.findIndex(x => x.ChildId === ChildId);

        skugroup.map((item, itemindex) => {

            if (((itemindex + 1) > indexofcurrrow) && (itemindex + 1) < skugroup.length) {

                let currexfactdate = skugroup[itemindex + 1].ExFacDate;
                let prevexfactdate = skugroup[itemindex].ExFacDate;

                let Difference_In_Time = new Date(currexfactdate).getTime() - new Date(prevexfactdate).getTime();
                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                let finaldifference = Difference_In_Days === 0 ? 1 : (Difference_In_Days + 1);

                let SetStartdate = new Date(); let SetEnddate = new Date();
                let tempEnddate = new Date(); let tempStartdate = new Date();
                let total = 0;

                //leave days calculation
                SetEnddate = checkDateBetweenInHolidayAndWE(addDays((skugroup[itemindex].EndDate), finaldifference - 1),
                    LeaveDays, titleEmployee1, "add");
                tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, false);
                for (let l = 0; l < total; l++) {
                    tempStartdate = subDays(tempStartdate, 1);
                    tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
                }
                SetStartdate = tempStartdate;

                let currentchildindex = values[i].SubTaskList[p].ChildTaskList.findIndex(x => x.ChildId === skugroup[itemindex + 1].ChildId);

                values[i].SubTaskList[p].ChildTaskList[currentchildindex].EndDate = SetEnddate;
                values[i].SubTaskList[p].ChildTaskList[currentchildindex].StartDate = SetStartdate;
                values[i].SubTaskList[p].ChildTaskList[currentchildindex].PreEndDate = SetEnddate;
                values[i].SubTaskList[p].ChildTaskList[currentchildindex].PreStartDate = SetStartdate;

            }
        })


    }

    // #endregion ------------------  Child Add Fields End --------------------------------

    const handleAddTaskChange = (e, index, childIndex, FieldName) => {

        if (e.target.checked) {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 1;
            let IsSelected = 1;
            let UnselectedRecords = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 0);
            if (UnselectedRecords.length > 0) {
                IsSelected = 0;
            }
            values[index].IsSelectAll = IsSelected;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        } else {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 0;
            values[index].IsSelectAll = 0;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        }
        setInputFields(values);

    }



    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            let params = { ...props.location.state };
            localStorage.setItem('IsBackcnd', true);

            //props.history.push('/TNAList');
            props.history.push('/TNAList', params);


        } else {
            window.location.reload();
        }
    }





    const filterOption = (option, inputValue) => {
        let trimValue = inputValue.trim();
        if (trimValue === "") {
            return option;
        }
        return option.data.subLabel.toLowerCase().includes(trimValue.toLowerCase());
    };
    const CustomInputman = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ border: getComment && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    const CustomInput = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ border: submitdate && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }


    const CustomInputDependency = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ width: "100%", border: (!submitDependencyStart) || props.value ? '' : '1px solid red' }}
            />
        )

    }

    function checkSubTask(data) {
        let taskNameList = '';
        if (data.tnaDetailList !== undefined) {
            let routeInfo = data.tnaDetailList;
            let childList = data.tnaChildList;
            routeInfo.map((Info, i) => {
                let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === Info.taskName.toLowerCase());
                Info.IsChildStatus = false;
                if (IsSingleTask === false) {
                    childList && childList.map(d => d.taskID === Info.taskNameID).map((Child, k) => {
                        if (Child) { Info.IsChildStatus = true; }
                    })
                    if (Info.IsChildStatus === false) {
                        if (taskNameList === "") {
                            taskNameList = Info.taskName;
                        } else {
                            taskNameList = taskNameList + ", " + Info.taskName;
                        }
                    }
                }
            })
        }
        return taskNameList;

    }
    const Delcallback1 = (value) => {

        if (value === true) { }

        else {
            // dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
            //dispatch(loadTNASupplierEmployeeList(getTNASupplierID));
        }
        setid({ showPopupConfirm: false, Params: [] });
    }

    const UpdateTNA = (e) => {

        setButtonLoader(true);
        showLoader();
        e.preventDefault();
        let IsValid = true;

        MainValues[0].TNARouteApplyDetails = [];
        MainValues[0].TNADependencyDetails = [];
        MainValues[0].TNAAdditionalTaskDetails = [];
        MainValues[0].TNAChildInformation = [];
        MainValues[0].TNASubChildInformation = [];
        MainValues[0].TypTNAChildDependencyDetails = [];
        MainValues[0].TNADocs = [];
        MainValues[0].TNAComments = [];
        var AllReviceDetails = [];
        var AllRescheduleDetails = [];
        // MainValues[0].CreationStatus = 2;
        MainValues[0].TNAId = props.location.state.params.tnaid;

        var getSMSData = inputFields.filter(s => s.strTaskName === 'SMS Submission');

        inputFields.map((Info, i) => {

            let titleEmployee1 = Info.titleEmployee1;
            let TaskHolderOwners = []
            if (titleEmployee1 === "Buyer") {
                TaskHolderOwners = Info.TaskHolderBuyer;
            } else if (titleEmployee1 === "Cube") {
                TaskHolderOwners = Info.FollowerCube;
            } else if (titleEmployee1 === "Supplier") {
                TaskHolderOwners = Info.FollowerSupplier;
            }

            if (Info.TaskID === 0 || Info.TaskType === 0 || (!Info.Duration) || (!TaskHolderOwners)) {
                setButtonLoader(false);
                hideLoader();
                if (IsValid === true) {
                    Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                    IsValid = false;
                }
                setSubmitted(true);
                return false;
            }
            else if (getSMSData.length !== 0 && (new Date(getSMSData[0].EndDate).setHours(0, 0, 0, 0) < new Date(Info.EndDate).setHours(0, 0, 0, 0)) &&
                (Info.strTaskName !== 'SMS Submission' && Info.strTaskName !== 'SMS Approval')) {
                setButtonLoader(false);
                hideLoader();
                if (IsValid === true) {
                    Nodify('Warning!', 'warning', "Some Parent Task End Date greater than SMS Submission End Date.");
                    IsValid = false;
                }
                setSubmitted(true);
                return false;
            }
            else {

                MainValues[0].TNARouteApplyDetails.push({
                    TNARouteApplyID: Info.TNARouteApplyID,
                    TaskType: Info.TaskType,
                    TaskNameID: Info.TaskID,
                    TaskName: Info.strTaskName,
                    //Duration: Info.Duration,
                    Duration: Info.Duration.toString(),
                    PlanStartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
                    EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
                    Dependency: Info.SelectedDependency,
                    TaskOwner: Info.TaskHolderOwnerIdList,
                    LeaveDays: Info.LeaveDays,
                    RouteApplyStatus: Info.StatusID,
                    TaskHolderID: Info.TaskHolderOrFollower,
                    TaskHolderIDList: Info.TaskHolderBuyer,
                    FollowerIDList1: Info.FollowerCube,
                    FollowerIDList2: Info.FollowerSupplier,
                    IndexName: i.toString(),
                    AWBStatus: Info.AWBStatus,
                    DependencyStartstop: Info.intDependency,
                    // ManualCloseComment: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].TNATaskComments : '',
                    // ManualCloseDate: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].Date : null,
                    ManualCloseComment: Info.ManualComments,
                    ManualCloseDate: Info.ManualCloseDate,
                    SingleOrMultiTask: Info.IsSingleTask ? 1 : 2,
                    IsReviced: parseInt(Info.IsReviced),
                    IsRescheduled: parseInt(Info.IsRescheduled),

                    //ManualCloseDate: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].Date : null,
                    DependencyDate: Info.DependencyDate ? convertUTCDateToLocalDate(new Date(Info.DependencyDate)) : null,
                    DependencyComment: Info.DependencyComment,
                    IsExpanded: false,
                    IsReviceAdded: parseInt(Info.ReviceAdded),
                    IsRescheduleAdded: parseInt(Info.RescheduleAdded),
                    DetailTypeID: Info.DetailTypeID


                });

                if (Info.IsReviced === 1) {
                    AllReviceDetails.push({
                        StartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
                        EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
                        Duration: parseInt(Info.Duration),
                        TNAId: Info.MainRevicedetails.TNAId,
                        TaskDetailId: Info.MainRevicedetails.TaskDetailId,
                        TaskName: Info.MainRevicedetails.TaskName,
                        Operation: 1,
                        Type: Info.MainRevicedetails.Type === "Main" ? "Main" : "Sub",
                        ReviceComment: Info.MainRevicedetails.ReviceComment,
                        Createdby: currentUser.employeeinformationID,

                    })

                }
                if (Info.IsRescheduled === 1) {
                    AllRescheduleDetails.push({
                        StartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
                        EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
                        Duration: parseInt(Info.Duration),
                        TNAId: Info.MainRescheduledetails.TNAId,
                        TaskDetailId: Info.MainRescheduledetails.TaskDetailId,
                        TaskName: Info.MainRescheduledetails.TaskName,
                        Operation: 1,
                        Type: Info.MainRescheduledetails.Type === "Main" ? "Main" : "Sub",
                        RescheduleComment: Info.MainRescheduledetails.RescheduleComment,
                        Createdby: currentUser.employeeinformationID,

                    })

                }

                let TaskName = Info.strTaskName;
                if (Info.arrDependency && Info.arrDependency.length > 0) {
                    const arrDependency = Info.arrDependency.filter(d => d.TNATaskName !== '');
                    // MainValues[0].TNADependencyDetails = [...MainValues[0].TNADependencyDetails, ...arrDependency];
                    Info.arrDependency.forEach((child, j) => {
                        if (child.TNATaskName !== '') {
                            child.IndexName = i.toString();
                            MainValues[0].TNADependencyDetails.push(child);
                        }
                    });
                }

                if (Info.FollowupDetails && Info.FollowupDetails.length > 0) {
                    // MainValues[0].TNAAdditionalTaskDetails = [...MainValues[0].TNAAdditionalTaskDetails, ...Info.FollowupDetails];
                    Info.FollowupDetails.forEach((child, k) => {
                        child.IndexName = i.toString();
                        MainValues[0].TNAAdditionalTaskDetails.push(child);
                    });
                }

                if (Info.arrAttachment) {

                    Info.arrAttachment.forEach((child) => {

                        child.TaskIndexName = child.TaskIndexName.toString();
                        MainValues[0].TNADocs.push(child);
                    });
                    //MainValues[0].TNADocs = [...MainValues[0].TNADocs, ...Info.arrAttachment];
                }

                if (Info.arrComment) {
                    MainValues[0].TNAComments = [...MainValues[0].TNAComments, ...Info.arrComment];
                }


                let ChildStatus = 0;
                let isTwoTreeView = false;
                let FilterdRow;
                if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                    ChildStatus = 1;
                }
                else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                    TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                    TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                    ChildStatus = 2;

                } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
                    TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                    TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
                    TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

                    ChildStatus = 3;

                } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW ||
                    TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
                    TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                    || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
                    || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                    || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                    || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                    || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL
                    || TaskName.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED_SKU) {

                    ChildStatus = 4;
                }
                else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
                    TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                    ChildStatus = 5;
                } else if (Info.DetailTypeID === 4) {
                    ChildStatus = 6;
                }
                else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                    ChildStatus = 7;
                }
                else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                    ChildStatus = 8;
                }

                if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER ||
                    TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION ||
                    TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH ||
                    TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                    isTwoTreeView = true;
                }

                if (Info.IsSingleTask === false) {

                    Info.SubTaskList && Info.SubTaskList.map((Sub, j) => {

                        if (isTwoTreeView === false) {
                            MainValues[0].TNAChildInformation.push({
                                Id: (Sub.ParentId === null || Sub.ParentId === undefined) ? 0 : Sub.ParentId,
                                TaskNameID: Sub.TaskID,
                                ChildId: Sub.MainId,
                                TaskIndexName: i.toString(),
                                ChildIndexName: j.toString(),
                                StrikeOffOrTrimLogoId: (Sub.StrikeOffOrTrimInfoId === undefined || Sub.StrikeOffOrTrimInfoId === null) ? 0 : parseInt(Sub.StrikeOffOrTrimInfoId),
                                ChildStatus: ChildStatus,
                            });
                        }

                        Sub.ChildTaskList && Sub.ChildTaskList.map((Child, k) => {
                            if (Child.Duration === '') {
                                setButtonLoader(false);
                                hideLoader();
                                if (IsValid === true) {
                                    Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                                    IsValid = false;
                                }
                                setSubmitted(true);
                                return false;
                            }
                            // else if (Child.hasSKUNotMatch === true) {
                            //     setButtonLoader(false);
                            //     if (IsValid === true) {
                            //         Nodify('Warning!', 'warning', "Some Rows don't have matched sku with Depandent Task.");
                            //         IsValid = false;
                            //     }
                            //     //  setSubmitted(true);
                            //     return false;
                            // }

                            // else if (getSMSData.length !== 0 && (new Date(getSMSData[0].EndDate).setHours(0, 0, 0, 0) < new Date(Child.EndDate).setHours(0, 0, 0, 0))
                            //     && (Child.TaskName !== 'SMS Submission' && Child.TaskName !== 'SMS Approval')) {
                            //     setButtonLoader(false);
                            //     hideLoader();
                            //     if (IsValid === true) {
                            //         Nodify('Warning!', 'warning', "Some Child Task End Date greater than SMS Submission End Date.");
                            //         IsValid = false;
                            //     }
                            //     setSubmitted(true);
                            //     return false;
                            // }

                            else {
                                var taskname = '';
                                if (Child.TaskName === 'Lab Dip Submission') {
                                    taskname = 'Lab Dip Approval';
                                }
                                else if (Child.TaskName === 'Lab Dip Approval') {
                                    taskname = 'Lab Dip Submission';
                                }
                                else if (Child.TaskName === 'Strike off Submission (Local Development)') {
                                    taskname = 'Strike off Approval (Local Development)';
                                }
                                else if (Child.TaskName === 'Strike off Approval (Local Development)') {
                                    taskname = 'Strike off Submission (Local Development)';
                                }
                                else if (Child.TaskName === 'Trim Submission (Local Development)') {
                                    taskname = 'Trim Approval (Local Development)';
                                }
                                else if (Child.TaskName === 'Trim Approval (Local Development)') {
                                    taskname = 'Trim Submission (Local Development)';
                                }
                                else if (Child.TaskName === 'Proto Sample Submission') {
                                    taskname = 'Proto Sample Approval';
                                }
                                else if (Child.TaskName === 'Proto Sample Approval') {
                                    taskname = 'Proto Sample Submission';
                                }
                                else if (Child.TaskName === 'SMS Submission') {
                                    taskname = 'SMS Approval';
                                }
                                else if (Child.TaskName === 'SMS Approval') {
                                    taskname = 'SMS Submission';
                                }
                                else if (Child.TaskName === 'Fit Sample Submission') {
                                    taskname = 'Fit Sample Approval';
                                }
                                else if (Child.TaskName === 'Fit Sample Approval') {
                                    taskname = 'Fit Sample Submission';
                                }
                                else if (Child.TaskName === 'Appearance After Wash') {
                                    taskname = 'Appearance After Wash';
                                }
                                else if (Child.TaskName === 'Strike off Test') {
                                    taskname = 'Strike off Test';
                                }
                                else if (Child.TaskName === 'Trim Test') {
                                    taskname = 'Trim Test';
                                }

                                else if (Child.TaskName === 'size set Submission') {
                                    taskname = 'size set Approval';
                                }
                                else if (Child.TaskName === 'size set Approval') {
                                    taskname = 'size set Submission';
                                }
                                else if (Child.TaskName === 'pre production Submission') {
                                    taskname = 'pre production Approval';
                                }
                                else if (Child.TaskName === 'pre production Approval') {
                                    taskname = 'pre production Submission';
                                }
                                else if (Child.TaskName === 'shipment Submission') {
                                    taskname = 'shipment Approval';
                                }
                                else if (Child.TaskName === 'shipment Approval') {
                                    taskname = 'shipment Submission';
                                }
                                else if (Child.TaskName === 'photo Submission') {
                                    taskname = 'photo Approval';
                                }
                                else if (Child.TaskName === 'photo Approval') {
                                    taskname = 'photo Submission';
                                }

                                MainValues[0].TNASubChildInformation.push({
                                    Id: (Child.ChildId === null || Child.ChildId === undefined) ? 0 : Child.ChildId,
                                    TaskNameID: Child.TaskID,
                                    SubChildId: Child.SubId,
                                    FabricOrStrikeOffOrTrimId: (Child.StrikeOffOrTrimInfoId === undefined || Child.StrikeOffOrTrimInfoId === null) ? 0 : parseInt(Child.StrikeOffOrTrimInfoId),
                                    Duration: Child.Duration.toString(),
                                    PlanStartDate: convertUTCDateToLocalDate(new Date(Child.StartDate)),
                                    EndDate: convertUTCDateToLocalDate(new Date(Child.EndDate)),
                                    RouteApplyStatus: Child.StatusID,
                                    TaskIndexName: i.toString(),
                                    ChildIndexName: j.toString(),
                                    SubChildIndexName: k.toString(),
                                    SubChildStatus: ChildStatus,
                                    ManualCloseComment: Child.ManualCloseComment,
                                    ManualCloseDate: Child.ManualCloseDate,
                                    ActualEndDate: Child.ChildActualEndDate,
                                    IsReviced: parseInt(Child.IsReviced),
                                    IsRescheduled: parseInt(Child.IsRescheduled),

                                    DependencyDate: Child.DependencyDate ? convertUTCDateToLocalDate(new Date(Child.DependencyDate)) : null,
                                    DependencyComment: Child.DependencyComment,
                                    SKUIdList: Child.SKUIdList,
                                    PurchaseOrderSkuID: Child.PurchaseOrderSkuID,
                                    StyleStrikeOffGarmentSizeIdList: Child.StyleStrikeOffGarmentSizeIdList,
                                    IsReviceAdded: parseInt(Child.ReviceAdded),
                                    IsRescheduleAdded: parseInt(Child.RescheduleAdded),
                                    Dependency: Child.SelectedDependency,
                                    DepandencyCount: Child.DependencyCount,
                                    AssignedDepandency: Child.AssignedDepandency,
                                    LeaveDays: Child.LeaveDays === '' ? 0 : Child.LeaveDays
                                    // karthik had command below line,incase have issue uncommand and check
                                    // LeaveDays: Child.LeaveDays === '' ? 0 : Child.LeaveDaysTxt === 'None' ? 1 : Child.LeaveDaysTxt === 'Sat, Sun, Holiday' ? 2 :
                                    //     Child.LeaveDaysTxt === "Sat, Sun" ? 3 : Child.LeaveDaysTxt === "Sun" ? 4 : Child.LeaveDaysTxt === "Holiday" ? 5 :
                                    //         Child.LeaveDaysTxt === "Sun, Holiday" ? 6 : Child.LeaveDaysTxt === "Sat" ? 7 : 8,
                                });

                                Child.arrDependency && Child.arrDependency.map((item, i) => {

                                    MainValues[0].TypTNAChildDependencyDetails.push({
                                        TNAChildDependencyID: item.TNAChildDependencyID,
                                        TNAID: props.location.state.params.tnaid,
                                        DependentTaskId: item.DependentTaskId,
                                        DependencyID: item.DependencyID,
                                        LogDays: item.LogDays !== undefined ? item.LogDays.toString() : '',
                                        IndexName: parseInt(i + 1),
                                        MainChildIndex: Child.ChildIndex,
                                        MainChildId: (Child.ChildId === null || Child.ChildId === undefined) ? 0 : Child.ChildId,
                                        StartDate: convertUTCDateToLocalDate(new Date(item.StartDate)),
                                        EndDate: convertUTCDateToLocalDate(new Date(item.EndDate))
                                    })



                                })
                                if (parseInt(Child.ReviceAdded) === 1) {

                                    // if (Child.TaskName === 'Lab Dip Submission' || Child.TaskName === 'Lab Dip Approval'
                                    //     || Child.TaskName === 'Strike off Submission (Local Development)' || Child.TaskName === 'Strike off Approval (Local Development)'
                                    //     || Child.TaskName === 'Trim Submission (Local Development)' || Child.TaskName === 'Trim Approval (Local Development)'
                                    // ) {
                                    inputFields.map(main => {
                                        main.SubTaskList.map(parentrow => {
                                            FilterdRow = parentrow.ChildTaskList.filter(x => x.MainId === Child.MainId && x.ChildId !== Child.ChildId
                                                && x.Name === Child.Name && x.TaskName === taskname);
                                            if (FilterdRow.length !== 0 && FilterdRow[0].ReviceAdded === 0) {
                                                Nodify('Warning!', 'warning',
                                                    "Revice Should be given for Task (" + FilterdRow[0].TaskName + ") Subtask -(" + FilterdRow[0].Name + ")");
                                                IsValid = false;
                                                return false;
                                            }

                                        })
                                    })

                                    // }
                                }
                                if (parseInt(Child.RescheduleAdded) === 1) {

                                    // if (Child.TaskName === 'Lab Dip Submission' || Child.TaskName === 'Lab Dip Approval'
                                    //     || Child.TaskName === 'Strike off Submission (Local Development)' || Child.TaskName === 'Strike off Approval (Local Development)'
                                    //     || Child.TaskName === 'Trim Submission (Local Development)' || Child.TaskName === 'Trim Approval (Local Development)'
                                    // ) {
                                    inputFields.map(main => {
                                        main.SubTaskList.map(parentrow => {
                                            FilterdRow = parentrow.ChildTaskList.filter(x => x.MainId === Child.MainId && x.ChildId !== Child.ChildId
                                                && x.Name === Child.Name && x.TaskName === taskname);
                                            if (FilterdRow.length !== 0 && FilterdRow[0].RescheduleAdded === 0) {
                                                Nodify('Warning!', 'warning',
                                                    "Reschedule Should be given for Task (" + FilterdRow[0].TaskName + ") Subtask -(" + FilterdRow[0].Name + ")");
                                                IsValid = false;
                                                return false;
                                            }

                                        })
                                    })

                                    //}
                                }
                                if (IsValid) {
                                    if (Child.IsReviced === 1) {
                                        AllReviceDetails.push({
                                            StartDate: convertUTCDateToLocalDate(new Date(Child.StartDate)),
                                            EndDate: convertUTCDateToLocalDate(new Date(Child.EndDate)),
                                            Duration: parseInt(Child.Duration),
                                            TNAId: Child.SubRevicedetails.TNAId,
                                            TaskDetailId: Child.SubRevicedetails.TaskDetailId,
                                            TaskName: Child.SubRevicedetails.TaskName,
                                            Operation: 1,
                                            Type: Child.SubRevicedetails.Type === "Main" ? "Main" : "Sub",
                                            ReviceComment: Child.SubRevicedetails.ReviceComment,
                                            Createdby: currentUser.employeeinformationID,

                                        })

                                    }
                                    if (Child.IsRescheduled === 1) {
                                        AllRescheduleDetails.push({
                                            StartDate: convertUTCDateToLocalDate(new Date(Child.StartDate)),
                                            EndDate: convertUTCDateToLocalDate(new Date(Child.EndDate)),
                                            Duration: parseInt(Child.Duration),
                                            TNAId: Child.SubRescheduledetails.TNAId,
                                            TaskDetailId: Child.SubRescheduledetails.TaskDetailId,
                                            TaskName: Child.SubRescheduledetails.TaskName,
                                            Operation: 1,
                                            Type: Child.SubRescheduledetails.Type === "Main" ? "Main" : "Sub",
                                            RescheduleComment: Child.SubRescheduledetails.RescheduleComment,
                                            Createdby: currentUser.employeeinformationID,

                                        })

                                    }
                                }
                            }


                        });
                    });

                }



            }
        });
        // When sub task missing for parent task (functionalities)
        let taskNameList = '';

        if (MainValues[0].TNARouteApplyDetails !== undefined) {
            let routeInfo = MainValues[0].TNARouteApplyDetails;
            let childList = MainValues[0].TNASubChildInformation;

            routeInfo.map((Info, i) => {
                Info.IsChildStatus = false;
                let IsSingleTask = Info.DetailTypeID === 1 ? true : !!!TNAMultiTask.find(element => element.toLowerCase() === Info.TaskName.toLowerCase());
                if (IsSingleTask === false) {
                    childList && childList.map(d => d.TaskNameID === Info.TaskNameID).map((Child, k) => {
                        if (Child) { Info.IsChildStatus = true; }
                    })
                    if (Info.IsChildStatus === false) {
                        if (taskNameList === "") {
                            taskNameList = Info.TaskNameID;
                        } else {
                            taskNameList = taskNameList + ", " + Info.TaskNameID;
                        }
                    }
                }
            })
        }
        // End

        if (taskNameList === '') {
            if (IsValid) {

                AllReviceDetails.filter(c => c.ReviceComment !== undefined && c.ReviceComment !== '').forEach(element => {
                    TnaService.InsertUpdateDeleteTNATaskRevice(element).then((res) => {
                        if (res.data.outputResult === "1") {
                        }
                    })
                })

                AllRescheduleDetails.filter(c => c.RescheduleComment !== undefined && c.RescheduleComment !== '').forEach(element => {
                    TnaService.InsertUpdateDeleteTNATaskReschedule(element).then((res) => {
                        if (res.data.outputResult === "1") {
                        }
                    })
                })

                showLoader();

                TnaService.UpdateTNA(MainValues[0])
                    .then(res => {
                        if (res.data.outputResult === "2") {
                            res.data.Func = 'Edit';
                            res.data.tabPage = 'TNAEdit';
                            setid({ showPopupConfirm: true, Params: res.data });
                            hideLoader();

                            // props.history.push({
                            //     pathname: "/TNAList",
                            //     state: {
                            //         message: "Edit"
                            //     }
                            // });

                        } else if (res.data.outputResult === "-1") {
                            Nodify('Warning!', 'warning', 'TNA name already Exist');
                        }
                        setButtonLoader(false);
                        hideLoader();
                    })
                    .catch(e => {
                        console.log(e);
                        setButtonLoader(false);
                        hideLoader();
                    });
            }
            else {
                setSubmitted(true);
                hideLoader();
                return false;

            }
        } else {
            Nodify('Warning!', 'warning', "Please remove irrelevent task in this style.");
            hideLoader();

        }
    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    // #region   ------------- **** Calculation Begin ***** --------------------------

    //Main Calculation Function

    // const calculation = useMemo(() => expensiveCalculation(count), [count]);

    const commonCalculation = (isDuration, isEndDate, index) => {

        values.map((Item, i) => {
            // Parent Calculation call

            commonCalculationParentAndChild(Item, Item, i, 0, 0, true, isDuration, isEndDate, i);
            Item.SubTaskList && Item.SubTaskList.map((Item2, p) => {
                Item2.ChildTaskList && Item2.ChildTaskList.map((Item3, c) => {
                    // Child Calculation call

                    commonCalculationParentAndChild(Item, Item3, i, p, c, false, isDuration, isEndDate, i);
                    leavedayscountcalc(Item3.StartDate, Item3.EndDate, Item3.LeaveDays, Item3.titleEmployee1, i, p, c, false)

                });
            });
        });
        setTimeout(() => {
            ResetParentTakDate()
        }, 5000)
        setInputFields(values);
    }



    const ResetParentTakDate = () => {

        values.map((item1, index) => {
            if (item1.DetailTypeID !== 1) {
                let allSubTask = [];
                let childcompletedcount = 0;
                item1.SubTaskList && item1.SubTaskList.map((Item2, p) => {
                    if (allSubTask.length === 0) {
                        allSubTask = [...Item2.ChildTaskList];
                    } else {
                        allSubTask = [...allSubTask, ...Item2.ChildTaskList];
                    }
                });
                allSubTask.map(x => {
                    if (x.ChildActualEndDate === '' || x.ChildActualEndDate === null) {
                        childcompletedcount = childcompletedcount + 1;
                    }
                })
                // if (childcompletedcount !== 0) {
                //     values[index].ActualEndDate = null;

                // }
                let max = allSubTask && allSubTask.reduce(function (a, b) {
                    return Math.max(a, parseInt(b.Duration));
                }, 0);
                if (allSubTask.length > 0) {


                    values[index].Duration = max;
                    const sortedStartdate = allSubTask.slice().sort((a, b) => a.StartDate - b.StartDate);
                    const sortedEnddate = allSubTask.slice().sort((a, b) => b.EndDate - a.EndDate);
                    values[index].StartDate = sortedStartdate[0].StartDate;
                    values[index].EndDate = sortedEnddate[0].EndDate;
                }
            }
        })
    }

    // useMemo(
    //     () => commonCalculation(getCALCParams.isDuration, getCALCParams.isEndDate, getCALCParams.index),
    //     [getCALCParams.isDuration, getCALCParams.isEndDate, getCALCParams.index]
    // );

    function commonCalculationParentAndChild(parentItem, commonItem, i, p, c, isParent, isDuration, isEndDate, index) {

        let LeaveDays = parentItem.LeaveDays;
        let titleEmployee1 = parentItem.titleEmployee1;
        let dependencyCount = parentItem.DependencyCount;
        let parentdependencyCount = parentItem.DependencyCount;
        let DetailTypeID = parentItem.DetailTypeID;
        let parentDependency = parentItem.SelectedDependency;
        let SelectedDependency = parentItem.SelectedDependency;
        let strTaskName = parentItem.strTaskName;
        let UnMappedList = '';



        if (!isParent) {
            SelectedDependency = commonItem.SelectedDependency;
            dependencyCount = commonItem.SelectedDependency;
        }

        if ((isEndDate && isParent && index === i) || (isEndDate && isParent === false && index === c)) {
            let CheckDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, parentItem.titleEmployee1, "add");
            if (!isSameDay(new Date(commonItem.EndDate), CheckDate)) {
                Nodify('Warning!', 'warning', 'You select the end date to leave day');
            }
        }


        let Duration = (commonItem.Duration && commonItem.Duration) ? parseInt(commonItem.Duration) > 0 ? parseInt(commonItem.Duration) - 1 : 0 : 0;
        // try {
        //  if (((i === 0 || dependencyCount === 0) && isEndDate === false) || (isDuration === true && dependencyCount === 0)) {
        if (parentdependencyCount === 0) {
            // Dependency Calculation Without
            let dbStartDate = new Date(commonItem.StartDate);
            let total = 0;
            //add Saturday,Sunday and Holiday Count
            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "add");
            let EndDate = addDays(dbStartDate, Duration);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(dbStartDate, EndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                EndDate = addDays(EndDate, 1);
                EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
            }
            commonItem.StartDate = dbStartDate;

            // if (!isParent) { // if this if condition is commented parent end date won't be accurate.
            //     commonItem.EndDate = EndDate;
            // }

            commonItem.EndDate = EndDate;
            commonItem.PreStartDate = dbStartDate;
            commonItem.PreEndDate = EndDate;

            if (isParent) {
                values[i] = commonItem;
            } else {
                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }


        }
        // else if ((i === 0 || dependencyCount === 0) || (isEndDate === true && dependencyCount === 0)) {

        //     let dbEndDate = commonItem.EndDate;
        //     let total = 0;
        //     //add Saturday,Sunday and Holiday Count
        //     dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
        //     let StartDate = subDays(dbEndDate, Duration);
        //     //Total Saturday,Sunday and Holiday Count
        //     total = totalHolidayAndWE(StartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
        //     for (let l = 0; l < total; l++) {
        //         StartDate = subDays(StartDate, 1);
        //         StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");
        //     }

        //     commonItem.StartDate = StartDate;
        //     commonItem.EndDate = dbEndDate;
        //     commonItem.PreStartDate = StartDate;
        //     commonItem.PreEndDate = dbEndDate;
        //     if (isParent) {
        //         values[i] = commonItem;
        //     } else {
        //         values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
        //     }

        // }
        else {
            //Dependency Calculation
            let dependency = SelectedDependency;

            let dbEndDate = new Date();
            let dbStartDate = new Date();
            let total = 0;
            if (dependencyCount > 1) {
                //Multiple Dependency Calculation
                let oldDuration = commonItem.Duration;
                let isAllowed = true;
                let isChildAllowed = true;
                //EndDate Change Duration Increase and Decrease
                if (isEndDate === true && commonItem.EndDateChange !== 2 && ((isParent === true && index === i) || (isParent === false && index === c))) {
                    let chkStartDate = commonItem.StartDate;
                    let chkEndDate = commonItem.EndDate;
                    chkStartDate.setHours(0, 0, 0, 0);
                    chkEndDate.setHours(0, 0, 0, 0);
                    if (chkStartDate <= chkEndDate) {
                        let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, parentItem.titleEmployee1, "add");
                        let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);
                        let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, parentItem.LeaveDays, parentItem.titleEmployee1, i, p, c, isParent);
                        diffDayCount = diffDayCount - holidayTotal;
                        commonItem.Duration = diffDayCount;
                        Duration = diffDayCount - 1;

                    } else {
                        // Item.EndDate = Item.preEndDate;
                        isAllowed = false;
                    }
                } else if (isEndDate === true && commonItem.EndDateChange !== 2 && isParent === false && index === i) {
                    commonItem.Duration = values[i].Duration;
                    commonItem.EndDate = values[i].EndDate;
                    commonItem.StartDate = values[i].StartDate;
                    commonItem.PreEndDate = values[i].EndDate;
                    commonItem.PreStartDate = values[i].StartDate;
                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    isChildAllowed = false;
                }

                if (isChildAllowed) {
                    if (isAllowed) {
                        let dependencyMult = [];
                        let splitDepList = dependency.split(',');

                        splitDepList.map((depItem, d) => {

                            addMultDependencyDate(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, i, p, c);
                        });

                        const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                        let rowSorted = sorted[0];
                        dbEndDate = sorted[0].Date;
                        let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                        if (lastRow.length > 0) {
                            dbStartDate = lastRow[0].Date;
                        } else {
                            dbStartDate = dbEndDate;
                        }
                        // Get Dependency and Index
                        let splitVal = lastRow[0].depItem.split('-');
                        let indexVal = splitVal[0].trim();
                        let dependencyVal = splitVal[1].trim();

                        if (commonItem.EndDateChange === 2) {

                            // ---Manual Calculation function------
                            let newEndDate = checkDateBetweenInHolidayAndWE(commonItem.EndDate, parentItem.LeaveDays, parentItem.titleEmployee1, "sub");
                            let newStartDate = subDays(newEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(newStartDate, newEndDate, parentItem.LeaveDays, parentItem.titleEmployee1, i, p, c, isParent);
                            for (let l = 0; l < total; l++) {
                                newStartDate = subDays(newStartDate, 1);
                                newStartDate = checkDateBetweenInHolidayAndWE(newStartDate, parentItem.LeaveDays, parentItem.titleEmployee1, "sub");
                            }

                            if (dependencyVal === "SS") {
                                //---- Start-Start ---- DependencyID 2------
                                //Get Dependent Map StartDate
                                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2)[0];
                                let preStartDate = subDays(values[indexVal - 1].StartDate, 1);
                                let LogDate = preStartDate;
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                newStartDate.setHours(0, 0, 0, 0);
                                if (LogDate <= newStartDate) {
                                    commonItem.StartDate = newStartDate;
                                    commonItem.EndDate = newEndDate;
                                } else {
                                    commonItem.StartDate = dbStartDate;
                                    commonItem.EndDate = dbEndDate;
                                }
                            } else if (dependencyVal === "SE") {
                                //---- Start-End ----DependencyID 3---((preStartDate - 1) + LogDays = EndDate))-------
                                //Get Dependent Map StartDate
                                let preStartDate = subDays(values[indexVal - 1].StartDate, 1);
                                let LogDate = preStartDate;
                                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3)[0];
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                newEndDate.setHours(0, 0, 0, 0);
                                if (LogDate <= newEndDate) {
                                    commonItem.StartDate = newStartDate;
                                    commonItem.EndDate = newEndDate;
                                } else {
                                    commonItem.StartDate = dbStartDate;
                                    commonItem.EndDate = dbEndDate;
                                }
                            } else if (dependencyVal === "ES") {
                                //Get Dependent Map StartDate ---- End-Start ----DependencyID 1------
                                let preEndDate = values[indexVal - 1].EndDate;
                                let LogDate = newStartDate;
                                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1)[0];

                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = subDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                preEndDate.setHours(0, 0, 0, 0);
                                if (preEndDate < LogDate) {
                                    commonItem.StartDate = newStartDate;
                                    commonItem.EndDate = newEndDate;
                                } else {
                                    commonItem.StartDate = dbStartDate;
                                    commonItem.EndDate = dbEndDate;
                                }
                            } else if (dependencyVal === "EE") {
                                //---- End-End --------DependencyID 4-----------((preEndDate) + LogDays = EndDate))-------
                                //Get Dependent Map StartDate
                                let preEndDate = values[indexVal - 1].EndDate;
                                let LogDate = preEndDate;
                                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4)[0];
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                newEndDate.setHours(0, 0, 0, 0);
                                if (LogDate <= newEndDate) {
                                    commonItem.StartDate = newStartDate;
                                    commonItem.EndDate = newEndDate;
                                } else {
                                    commonItem.StartDate = dbStartDate;
                                    commonItem.EndDate = dbEndDate;
                                }
                            }

                        } else {
                            commonItem.StartDate = dbStartDate;
                            commonItem.EndDate = dbEndDate;
                        }

                        // commonItem.StartDate = dbStartDate;
                        // commonItem.EndDate = dbEndDate;

                    } else {
                        commonItem.EndDate = commonItem.PreEndDate;
                    }

                    if (isParent) {

                        if (commonItem.EndDateChange !== 2 && isEndDate === true && commonItem.IsSingleTask === false) {

                            commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                    commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                });
                            });
                        } else if (commonItem.EndDateChange === 2 && isEndDate === true && commonItem.IsSingleTask === false) {
                            commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                    commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                    commonItem.SubTaskList[k].ChildTaskList[l].StartDate = commonItem.StartDate;
                                    commonItem.SubTaskList[k].ChildTaskList[l].EndDate = commonItem.EndDate;
                                    commonItem.SubTaskList[k].ChildTaskList[l].EndDateChange = 2;
                                });
                            });
                        }


                        values[i] = commonItem;
                    } else {
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }
                }

            } else if (dependencyCount === 1 && dependency.length !== 0) {

                let isChildAllowed = true;
                //---------- **** Single  Dependent Calculation ******----------------
                //Get Dependent Name and Index
                let splitVal = dependency.split('-');
                let indexVal = splitVal[0].trim();
                let dependencyVal = splitVal[1].trim();

                //EndDate Change Duration Increase and Decrease
                if (isEndDate === true && commonItem.EndDateChange !== 2 && ((isParent === true && index === i) || (isParent === false && index === c))) {
                    let chkStartDate = commonItem.StartDate;
                    let chkEndDate = commonItem.EndDate;
                    chkStartDate.setHours(0, 0, 0, 0);
                    chkEndDate.setHours(0, 0, 0, 0);
                    if (chkStartDate <= chkEndDate) {
                        if (dependencyVal === "SS" || dependencyVal === "ES") {
                            let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), LeaveDays, titleEmployee1, "add");
                            let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);
                            let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            diffDayCount = diffDayCount - holidayTotal;
                            commonItem.Duration = diffDayCount;
                            Duration = diffDayCount - 1;
                        }


                    } else {
                        commonItem.EndDate = commonItem.PreEndDate;
                        Nodify('Warning!', 'warning', 'End date should not be less than start date.');
                    }
                }
                else if (isEndDate === true && commonItem.EndDateChange !== 2 && isParent === false && index === i) {

                    commonItem.Duration = values[i].Duration;
                    commonItem.EndDate = values[i].EndDate;
                    commonItem.StartDate = values[i].StartDate;
                    commonItem.PreEndDate = values[i].EndDate;
                    commonItem.PreStartDate = values[i].StartDate;
                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    isChildAllowed = false;
                }

                if (isChildAllowed) {
                    if (dependencyVal === "SS") {
                        //---- Start-Start ---- DependencyID 2------

                        //Get Dependent Map StartDate
                        let preStartDate = values[indexVal - 1].StartDate;
                        let LogDate = new Date();

                        if (commonItem.EndDateChange === 2) {
                            //Manual Calculation function------(preStartDate + LogDays = StartDate))-------
                            dbEndDate = checkDateBetweenInHolidayAndWE(commonItem.EndDate, LeaveDays, titleEmployee1, "sub");
                            dbStartDate = subDays(dbEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            for (let l = 0; l < total; l++) {
                                dbStartDate = subDays(dbStartDate, 1);
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                            }
                            LogDate = preStartDate;
                            let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                            if (Log && Log[0].LogDays !== "" && Log[0].LogDays !== undefined) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Log[0].LogDays));
                                //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                            }
                        }

                        LogDate.setHours(0, 0, 0, 0);
                        dbStartDate.setHours(0, 0, 0, 0);
                        if (LogDate <= dbStartDate && commonItem.EndDateChange === 2) {
                        } else {
                            //Auto Calculation function
                            // dbStartDate = values[indexVal - 1].StartDate;
                            let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                            if (Log && Log[0] && Log[0].LogDays) {
                                dbStartDate = addDays(preStartDate, parseInt(Log[0].LogDays));
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "add");
                            } else {
                                //Calculation Saturday,Sunday and Holiday Count
                                dbStartDate = checkDateBetweenInHolidayAndWE(preStartDate, LeaveDays, titleEmployee1, "add");
                            }

                            dbEndDate = addDays(dbStartDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            for (let l = 0; l < total; l++) {
                                dbEndDate = addDays(dbEndDate, 1);
                                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                            }
                        }

                        commonItem.EndDate = dbEndDate;
                        commonItem.StartDate = dbStartDate;
                        commonItem.PreEndDate = dbEndDate;
                        commonItem.PreStartDate = dbStartDate;
                        if (isParent) {
                            if (commonItem.EndDateChange !== 2 && isEndDate === true && commonItem.IsSingleTask === false) {

                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                    });
                                });
                            } else if (commonItem.EndDateChange === 2 && isEndDate === true && commonItem.IsSingleTask === false) {
                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                        commonItem.SubTaskList[k].ChildTaskList[l].StartDate = commonItem.StartDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDate = commonItem.EndDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDateChange = 2;
                                    });
                                });
                            }
                            values[i] = commonItem;

                        } else {
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }

                    } else if (dependencyVal === "SE") {
                        //---- Start-End ----DependencyID 3---

                        let preStartDate = subDays(values[indexVal - 1].StartDate, 1);
                        let LogDate = preStartDate;
                        let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3)[0];

                        if (isEndDate === true && commonItem.EndDateChange !== 2) {
                            if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Log.LogDays));
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            let newDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), LeaveDays, titleEmployee1, "add");
                            newDate.setHours(0, 0, 0, 0);
                            if (LogDate <= newDate) {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), LeaveDays, titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);

                                let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                diffDayCount = diffDayCount - holidayTotal;
                                commonItem.Duration = diffDayCount;
                                dbStartDate = commonItem.StartDate;
                                dbEndDate = LastDate;
                            } else {
                                dbStartDate = commonItem.StartDate;
                                dbEndDate = commonItem.PreEndDate;
                                commonItem.EndDate = commonItem.PreEndDate;
                            }
                        } else {

                            if (commonItem.EndDateChange === 2) {
                                //Manual Calculation function----------((preStartDate - 1) + LogDays = EndDate))-------
                                dbEndDate = checkDateBetweenInHolidayAndWE(commonItem.EndDate, LeaveDays, titleEmployee1, "sub");
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                                }

                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                    //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                                }
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            dbEndDate.setHours(0, 0, 0, 0);
                            if (LogDate <= dbEndDate && commonItem.EndDateChange === 2) {
                                //Skip
                            } else {
                                //Auto Calculation function
                                if (Log && Log[0] && Log[0].LogDays) {
                                    //LogDays Add
                                    dbEndDate = addDays(preStartDate, parseInt(Log.LogDays));
                                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                                } else {
                                    dbEndDate = checkDateBetweenInHolidayAndWE(preStartDate, LeaveDays, titleEmployee1, "sub");
                                }
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                                }
                            }
                        }

                        commonItem.StartDate = dbStartDate;
                        commonItem.EndDate = dbEndDate;
                        commonItem.PreStartDate = dbStartDate;
                        commonItem.PreEndDate = dbEndDate;

                        if (isParent) {
                            if (commonItem.EndDateChange !== 2 && isEndDate === true && commonItem.IsSingleTask === false) {

                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                    });
                                });
                            } else if (commonItem.EndDateChange === 2 && isEndDate === true && commonItem.IsSingleTask === false) {
                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                        commonItem.SubTaskList[k].ChildTaskList[l].StartDate = commonItem.StartDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDate = commonItem.EndDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDateChange = 2;
                                    });
                                });
                            }

                            values[i] = commonItem;
                        } else {
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }

                    } else if (dependencyVal === "ES") {
                        //Manual Calculation function-------- End-Start ----DependencyID 1------
                        //Get Dependent Map StartDate
                        let preEndDate = values[indexVal - 1].EndDate;
                        let LogDate = new Date();
                        let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1)[0];

                        if (commonItem.EndDateChange === 2) {
                            // ---Manual Calculation function---------((preEndDate + 1) + LogDays = StartDate))-------
                            dbEndDate = checkDateBetweenInHolidayAndWE(commonItem.EndDate, LeaveDays, titleEmployee1, "sub");
                            dbStartDate = subDays(dbEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            for (let l = 0; l < total; l++) {
                                dbStartDate = subDays(dbStartDate, 1);
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                            }
                            LogDate = dbStartDate;

                            if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                //LogDays Add
                                LogDate = subDays(LogDate, parseInt(Log.LogDays));
                            }
                        }
                        LogDate.setHours(0, 0, 0, 0);
                        preEndDate.setHours(0, 0, 0, 0);
                        if (preEndDate < LogDate && commonItem.EndDateChange === 2) {
                        } else {
                            //Auto Calculation function
                            dbStartDate = addDays(preEndDate, 1);
                            if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                //LogDays Add
                                dbStartDate = addDays(dbStartDate, parseInt(Log.LogDays));
                            }
                            //Calculation Saturday,Sunday and Holiday Count
                            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "add");
                            dbEndDate = addDays(dbStartDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            for (let l = 0; l < total; l++) {
                                dbEndDate = addDays(dbEndDate, 1);
                                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                            }
                        }

                        commonItem.EndDate = dbEndDate;
                        commonItem.StartDate = dbStartDate;
                        commonItem.PreStartDate = dbStartDate;
                        commonItem.PreEndDate = dbEndDate;
                        if (isParent) {
                            if (commonItem.EndDateChange !== 2 && isEndDate === true && commonItem.IsSingleTask === false) {

                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                    });
                                });
                            } else if (commonItem.EndDateChange === 2 && isEndDate === true && commonItem.IsSingleTask === false) {
                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                        commonItem.SubTaskList[k].ChildTaskList[l].StartDate = commonItem.StartDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDate = commonItem.EndDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDateChange = 2;
                                    });
                                });
                            }
                            values[i] = commonItem;
                        } else {
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }

                    } else if (dependencyVal === "EE") {
                        //---- End-End --------DependencyID 4-----------((preEndDate) + LogDays = EndDate))-------
                        //Get Dependent Map StartDate

                        let preEndDate = values[indexVal - 1].EndDate;
                        let LogDate = preEndDate;
                        let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4)[0];

                        if (isEndDate === true && commonItem.EndDateChange !== 2) {
                            if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Log.LogDays));
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            let newDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), LeaveDays, titleEmployee1, "add");
                            newDate.setHours(0, 0, 0, 0);
                            if (LogDate <= newDate) {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), LeaveDays, titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);
                                let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                diffDayCount = diffDayCount - holidayTotal;
                                commonItem.Duration = diffDayCount;
                                dbStartDate = commonItem.StartDate;
                                dbEndDate = LastDate;
                            } else {
                                dbStartDate = commonItem.StartDate;
                                dbEndDate = commonItem.PreEndDate;
                                commonItem.EndDate = commonItem.PreEndDate;
                            }
                        } else {

                            if (commonItem.EndDateChange === 2) {
                                // ---Manual Calculation function------
                                dbEndDate = checkDateBetweenInHolidayAndWE(commonItem.EndDate, LeaveDays, titleEmployee1, "sub");
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                                }
                                LogDate = preEndDate;

                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                    //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                                }
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            dbEndDate.setHours(0, 0, 0, 0);
                            if (LogDate <= dbEndDate && commonItem.EndDateChange === 2) {
                            } else {

                                //Auto Calculation function
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    dbEndDate = addDays(preEndDate, parseInt(Log.LogDays));
                                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                                } else {
                                    dbEndDate = checkDateBetweenInHolidayAndWE(preEndDate, LeaveDays, titleEmployee1, "sub",);
                                }

                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                                }
                            }
                        }

                        commonItem.EndDate = dbEndDate;
                        commonItem.StartDate = dbStartDate;
                        commonItem.PreStartDate = dbStartDate;
                        commonItem.PreEndDate = dbEndDate;

                        if (isParent) {
                            if (commonItem.EndDateChange !== 2 && isEndDate === true && commonItem.IsSingleTask === false) {

                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                    });
                                });
                            } else if (commonItem.EndDateChange === 2 && isEndDate === true && commonItem.IsSingleTask === false) {
                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                        commonItem.SubTaskList[k].ChildTaskList[l].StartDate = commonItem.StartDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDate = commonItem.EndDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDateChange = 2;
                                    });
                                });
                            }
                            values[i] = commonItem;
                        } else {
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }
                    }
                }
            } else if (!isParent) { //------** Child Only ***------------------
                //------------------ *** No Dependency (SKU base map Calculation) ***------------

                if (DetailTypeID === 2) { // DetailType => Sku-----------------------------------
                    let SkuId = commonItem.SubId;
                    let TaskName = commonItem.TaskName;

                    if (parentdependencyCount === 1 && parentDependency.length !== 0) {
                        //---------- **** Single  Dependent Calculation ******----------------
                        //Get Parent Dependent Name and Index
                        let parentSplitVal = parentDependency.split('-');
                        let parentIndexVal = parentSplitVal[0].trim();
                        let parentDependencyVal = parentSplitVal[1].trim();

                        // Dependent base compare to  DetailTypeID
                        let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                        if (parentDetailTypeID === 1) { // DetailType => Common
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentDependencyVal, parentDetailTypeID);
                            commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, null, isParent)
                        } else if (parentDetailTypeID === 2) { // DetailType => Sku
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            let preChildDetail = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId)[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                    dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                            } else {
                                commonItem.hasSKUNotMatch = true;

                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                parentItem.IsExpanded = true;
                                commonItem.hasAutoOpen = true;

                                handleExpandedOnlyToggle(index);
                            }


                        } else if (parentDetailTypeID === 3) { // DetailType => Item
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            let preChildDetailList = [];
                            values[parentIndexVal - 1].SubTaskList.map((item) => {
                                let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()))[0];
                                TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                            });
                            //Order by descending ( -1 : 1)


                            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                    dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                            } else {
                                commonItem.hasSKUNotMatch = true;
                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                parentItem.IsExpanded = true;
                                values[i].SubTaskList[p].IsExpanded = true;
                                commonItem.hasAutoOpen = true;

                                handleExpandedOnlyToggle(index);
                            }


                        } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory

                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            let preChildDetailList = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);

                            //Order by ASC a - b
                            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                    dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                            } else {
                                commonItem.hasSKUNotMatch = true;
                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                parentItem.IsExpanded = true;
                                values[i].SubTaskList[p].IsExpanded = true;
                                commonItem.hasAutoOpen = true;

                                handleExpandedOnlyToggle(index);
                            }
                        }

                    } else if (parentdependencyCount > 1) {
                        //---------- **** Multiple  Dependent Calculation ******----------------
                        let oldDuration = commonItem.Duration;
                        let dependencyMult = [];
                        let splitDepList = parentDependency.split(',');
                        splitDepList.map((depItem, d) => {
                            addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId);
                        });

                        if (dependencyMult.length > 0) {
                            const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                            let rowSorted = sorted[0];
                            dbEndDate = sorted[0].Date;
                            let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                            if (lastRow.length > 0) {
                                dbStartDate = lastRow[0].Date;
                            } else {
                                dbStartDate = dbEndDate;
                            }

                            // Get Dependency and Index
                            let splitVal = lastRow[0].depItem.split('-');
                            let indexVal = splitVal[0].trim();
                            let dependencyVal = splitVal[1].trim();
                            commonItem.StartDate = dbStartDate;
                            commonItem.EndDate = dbEndDate;
                            commonItem.AssignedDepandency = dependencyVal;
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;

                            ChildDepandencyTextwithMultiParentdep(DetailTypeID, splitDepList, commonItem, i, p, c, SkuId);
                            // commonItem.hasSKUNotMatch = false;
                        } else {
                            commonItem.hasSKUNotMatch = true;
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                            parentItem.IsExpanded = true;
                            values[i].SubTaskList[p].IsExpanded = true;
                            commonItem.hasAutoOpen = true;

                            handleExpandedOnlyToggle(index);
                        }


                    }


                } else if (DetailTypeID === 3) { // DetailType => Item-------------------------------

                    let SkuId = commonItem.SubId;
                    if (parentdependencyCount === 1 && parentDependency.length !== 0) {
                        //---------- **** Single  Dependent Calculation ******----------------
                        //Get Parent Dependent Name and Index
                        let parentSplitVal = parentDependency.split('-');
                        let parentIndexVal = parentSplitVal[0].trim();
                        let parentDependencyVal = parentSplitVal[1].trim();

                        // Dependent base compare to  DetailTypeID
                        let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                        if (parentDetailTypeID === 1) { // DetailType => Common
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, null, isParent)
                        } else if (parentDetailTypeID === 2) { // DetailType => Sku
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            let preChildDetailList = [];

                            //let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                            let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                            arySkuIdList.map((item) => {
                                let preSkuDetail = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item))[0];
                                preSkuDetail && preChildDetailList.push(preSkuDetail);
                            });

                            //Order by descending ( -1 : 1)
                            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                    dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                            } else {
                                commonItem.hasSKUNotMatch = true;
                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                parentItem.IsExpanded = true;
                                commonItem.hasAutoOpen = true;
                                values[i].SubTaskList[p].IsExpanded = true;
                                handleExpandedOnlyToggle(index);
                            }

                        } else if (parentDetailTypeID === 3) { // DetailType => Item
                            let TaskName = values[index].strTaskName;
                            let preTaskName = values[parentIndexVal - 1].strTaskName;
                            let hasSameItem = false;

                            if ((TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)
                                && (preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)) {
                                hasSameItem = true;

                            } else if ((TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)
                                && (preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)) {
                                hasSameItem = true;

                            } else if ((TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)
                                && (preTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || preTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)) {
                                hasSameItem = true;
                            }

                            if (hasSameItem) { //Same Item Match
                                ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                                let SubId = commonItem.SubId;
                                let MainId = commonItem.MainId;

                                let preChildTaskList = values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0] && values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0].ChildTaskList;
                                let preChildDetail = preChildTaskList && preChildTaskList.filter(d => d.SubId === SubId)[0];
                                if (preChildDetail) {
                                    // commonItem.hasSKUNotMatch = false;
                                    commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                        dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                                } else {
                                    commonItem.hasSKUNotMatch = true;
                                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                    parentItem.IsExpanded = true;
                                    values[i].SubTaskList[p].IsExpanded = true;
                                    commonItem.hasAutoOpen = true;

                                    handleExpandedOnlyToggle(index);
                                }
                            } else { //Other Item Match Sku
                                let preChildDetailList = [];

                                // let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                                let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                                arySkuIdList && arySkuIdList.map((Sku) => {
                                    values[parentIndexVal - 1].SubTaskList.map((item) => {
                                        let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(Sku.toString()))[0];
                                        TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                                        // if (TNASkuIdList && TNASkuIdList.length > 0) {

                                        //     preChildDetailList = (TNASkuIdList && TNASkuIdList.length > 0) ? [preChildDetailList, ...TNASkuIdList] : preChildDetailList;
                                        // }

                                    });
                                });
                                ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                                //Order by descending ( -1 : 1)
                                let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                                if (preChildDetail) {
                                    // commonItem.hasSKUNotMatch = false;
                                    commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                        dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                                } else {
                                    commonItem.hasSKUNotMatch = true;
                                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                    parentItem.IsExpanded = true;
                                    values[i].SubTaskList[p].IsExpanded = true;
                                    commonItem.hasAutoOpen = true;

                                    handleExpandedOnlyToggle(index);
                                }


                            }

                        } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory

                            let preChildDetailList = [];

                            // let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                            let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId

                            arySkuIdList.map((item) => {
                                let preSkuDetail = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item))[0];
                                preSkuDetail && preChildDetailList.push(preSkuDetail);
                            });

                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            //Order by ASC a - b
                            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                    dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                            } else {
                                commonItem.hasSKUNotMatch = true;
                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                parentItem.IsExpanded = true;
                                values[i].SubTaskList[p].IsExpanded = true;
                                commonItem.hasAutoOpen = true;

                                handleExpandedOnlyToggle(index);
                            }
                        }


                    } else if (parentdependencyCount > 1) {
                        //---------- **** Multiple  Dependent Calculation ******----------------
                        let oldDuration = commonItem.Duration;
                        let dependencyMult = [];
                        let splitDepList = parentDependency.split(',');
                        splitDepList.map((depItem, d) => {
                            addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId);
                        });

                        if (dependencyMult.length > 0) {
                            const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                            let rowSorted = sorted[0];
                            dbEndDate = sorted[0].Date;
                            let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                            if (lastRow.length > 0) {
                                dbStartDate = lastRow[0].Date;
                            } else {
                                dbStartDate = dbEndDate;
                            }

                            // Get Dependency and Index
                            let splitVal = lastRow[0].depItem.split('-');
                            let indexVal = splitVal[0].trim();
                            let dependencyVal = splitVal[1].trim();
                            commonItem.StartDate = dbStartDate;
                            commonItem.EndDate = dbEndDate;
                            commonItem.AssignedDepandency = dependencyVal;
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;



                            ChildDepandencyTextwithMultiParentdep(DetailTypeID, splitDepList, commonItem, i, p, c, SkuId);
                            // commonItem.hasSKUNotMatch = false;
                        } else {
                            commonItem.hasSKUNotMatch = true;
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                            parentItem.IsExpanded = true;
                            values[i].SubTaskList[p].IsExpanded = true;
                            commonItem.hasAutoOpen = true;

                            handleExpandedOnlyToggle(index);
                        }



                    }


                } else if (DetailTypeID === 4) { // DetailType => Ex-Factory---------------------------
                    let SkuId = commonItem.SubId;
                    let SubTaskName = commonItem.Name;


                    if (parentdependencyCount === 1 && parentDependency.length !== 0) {
                        //---------- **** Single  Dependent Calculation ******----------------
                        //Get Parent Dependent Name and Index
                        let parentSplitVal = parentDependency.split('-');
                        let parentIndexVal = parentSplitVal[0].trim();
                        let parentDependencyVal = parentSplitVal[1].trim();

                        // Dependent base compare to  DetailTypeID
                        let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                        if (parentDetailTypeID === 1) { // DetailType => Common
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal,
                                parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, null, isParent)
                        } else if (parentDetailTypeID === 2) { // DetailType => Sku
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);

                        } else if (parentDetailTypeID === 3 || parentDetailTypeID === 4) { // DetailType => Item and EX Factory added by karthik (like this only  || parentDetailTypeID === 4)
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                        }


                    } else if (parentdependencyCount > 1) {
                        //---------- **** Multiple  Dependent Calculation ******----------------
                        let oldDuration = commonItem.Duration;
                        let dependencyMult = [];
                        let splitDepList = parentDependency.split(',');
                        splitDepList.map((depItem, d) => {
                            addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId);
                        });

                        ChildDepandencyTextwithMultiParentdep(DetailTypeID, splitDepList, commonItem, i, p, c, SkuId, SubTaskName);

                    }

                }

            }
        }
        //ex-factory calculation
        // Common task not having Ex Factory calculation (Bulk Purchase Order,SMS Purchase Order)
        if (DetailTypeID === 4 && !isParent) { // DetailType => Ex-Factory---------------------------
            // if (DetailTypeID === 4 && !isParent && (commonItem.TaskName !== 'Bulk Purchase Order' && commonItem.TaskName !== 'SMS Purchase Order')) {
            let Name = commonItem.Name;

            // // // Neeed to fix in future problem is adding This (&& isLastEdit === false) not working in AUTO
            // if (DetailTypeID === 4 && !isParent && isLastEdit === false) { // DetailType => Ex-Factory---------------------------
            commonItem.AutoText = "";
            //commonItem.AssignedDepandency = commonItem.AssignedDepandency === 'ES'? '' : commonItem.AssignedDepandency
            //if (commonItem.AssignedDepandency === '' ) { // For multiple SKU (Greater than 2) 
            //if (commonItem.AssignedDepandency === '' || commonItem.AssignedDepandency === 'ES') { // For multiple SKU (Greater than 2) 
            if (commonItem.AssignedDepandency === '') { // For multiple SKU (Greater than 2) 
                // let currexfactdate = commonItem.ExFacDate;

                let SkuId = commonItem.SubId;
                let ChildId = commonItem.ChildId;
                let ExfactDate = commonItem.ExFacDate;
                let Duration = commonItem.Duration;
                let skugroup = values[i].SubTaskList[p].ChildTaskList.filter(x => x.SubId === SkuId);
                // let skugroupCount = values[i].SubTaskList[p].ChildTaskList.filter(x => x.SubId === SkuId && x.ExFacDate === ExfactDate).length;
                let indexofcurrrow = skugroup.findIndex(x => x.ChildId === ChildId);
                let prevexfactdate = indexofcurrrow === 0 ? skugroup[indexofcurrrow].ExFacDate : skugroup[indexofcurrrow - 1].ExFacDate;

                //let PrevskuID = indexofcurrrow === 0  ? skugroup[indexofcurrrow].SubId : skugroup[indexofcurrrow - 1].SubId;

                // prevexfactdate = (indexofcurrrow > 0 && ExfactDate === prevexfactdate) ? skugroup[indexofcurrrow - 2].ExFacDate : prevexfactdate 
                // Same date Exfactory - Added on 02Apr24 by karthik

                let Increment = indexofcurrrow === 1 ? 1 : (indexofcurrrow > 0 && ExfactDate === prevexfactdate) ? 2 : 1;
                // if(ExfactDate === prevexfactdate)
                // {
                //     CountSameColor++;

                // }
                // else
                // {
                //     CountSameColor = 0;
                // }

                // CountSameColor = ExfactDate === prevexfactdate ? CountSameColor++ : 0;
                //CountSameColor = ExfactDate === prevexfactdate ? CountSameColor + 1 : CountSameColor = 1;

                // let Increment = 1;

                // let Increment = indexofcurrrow === 1 ? 1 : 2 ;

                // const shouldUpdate = (
                //     (indexofcurrrow === 0 || ExfactDate !== prevexfactdate || indexofcurrrow > 0) &&
                //     !(indexofcurrrow >= 1 && indexofcurrrow <= 1 && ExfactDate === prevexfactdate)
                // );

                // if (shouldUpdate) {
                //     // Your code here


                // Your code block
                //if((indexofcurrrow === 0 || ExfactDate !== prevexfactdate) ||  (indexofcurrrow > 0 || ExfactDate === prevexfactdate)) // added on28Mar2024 by karthik
                //if ((indexofcurrrow === 0 || ExfactDate !== prevexfactdate || indexofcurrrow > 0))
                if ((InfoSelectedDependency !== undefined || ExfactDate !== prevexfactdate))
                //&& !((indexofcurrrow === 1 || indexofcurrrow === 2  || indexofcurrrow === 3) && ExfactDate === prevexfactdate)) // added on28Mar2024 by karthik
                {

                    if (commonItem.SelectedDependency === '')
                    // || commonItem.SelectedDependency !== '')  // Added on 30Apr24 by karthik
                    {

                        InfoIncreament = ExfactDate !== prevexfactdate ? undefined : InfoIncreament;

                        if (InfoSelectedDependency !== undefined) {

                            if (InfoIncreament === undefined) {
                                InfoIncreament = Increment;
                            }
                            else {
                                InfoIncreament++;
                            }


                            Increment = InfoIncreament;
                        }
                    }  // End added on 30Apr24 by karthik

                    if (indexofcurrrow === 0) {

                        commonItem.EndDate = !isLastEdit ? values[i].EndDate : commonItem.EndDate;
                        commonItem.StartDate = !isLastEdit ? values[i].StartDate : commonItem.StartDate;
                        commonItem.PreEndDate = !isLastEdit ? values[i].EndDate : commonItem.EndDate;
                        commonItem.PreStartDate = !isLastEdit ? values[i].StartDate : commonItem.StartDate;
                        // commonItem.EndDate = values[i].EndDate;
                        // commonItem.StartDate = values[i].StartDate;
                        // commonItem.PreEndDate = values[i].EndDate;
                        // commonItem.PreStartDate = values[i].StartDate;
                        if (commonItem.AssignedDepandency !== '') {
                            let parentSplitVal = parentDependency.split('-');
                            let parentIndexVal = parentSplitVal[0].trim();
                            let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                            //  Setdepandencybasedontaskclose(commonItem.SelectedDependency, i, p, c, parentIndexVal, parentDetailTypeID);
                        }
                    }
                    else {


                        let currexfactdate = commonItem.ExFacDate;
                        if (skugroup[indexofcurrrow - Increment]?.ExFacDate === undefined) { // Temporary solution added by karthik
                            // Access skugroup[indexofcurrrow - Increment].ExFacDate
                            Increment = 1;
                        }
                        let prevexfactdate = skugroup[indexofcurrrow - Increment].ExFacDate;
                        let prevChildId = skugroup[indexofcurrrow - Increment].ChildId;
                        let Difference_In_Time = new Date(currexfactdate).getTime() - new Date(prevexfactdate).getTime();
                        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                        let finaldifference = Difference_In_Days === 0 ? 1 : (Difference_In_Days + 1);
                        //  let enddate = addDays((skugroup[indexofcurrrow - 1].EndDate), finaldifference)
                        //  let startDate = subDays(enddate, parseInt(commonItem.Duration) - 1);
                        let DepandencyIndexeswithValues = [];

                        let SetStartdate = new Date(); let SetEnddate = new Date();
                        let tempEnddate = new Date(); let tempStartdate = new Date();
                        let total = 0;
                        let DependentText;

                        if (commonItem.SelectedDependency === '')   // defauly set ES Client ref information
                        {


                            let DependentID;

                            if (props.location.state?.params?.assignAutocalculation !== undefined) {
                                // This will pause execution here
                                DependentID = props.location.state.params.assignAutocalculation;
                            }
                            DependentText = DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : 'ES';

                            ({ SetStartdate, tempEnddate, total, SetEnddate, tempStartdate } = ExfactoryDepandentApply(DependentID, SetStartdate, skugroup, indexofcurrrow, finaldifference, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, tempStartdate));

                            // // SetStartdate = addDays(skugroup[indexofcurrrow - Increment].EndDate, finaldifference);
                            // // //Calculation Saturday,Sunday and Holiday Count
                            // // SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
                            // // tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
                            // // //Total Saturday,Sunday and Holiday Count
                            // // total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            // // for (let l = 0; l < total; l++) {
                            // //     tempEnddate = addDays(tempEnddate, 1);
                            // //     tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
                            // // }

                            // // SetEnddate = tempEnddate;


                        }
                        else {


                            //                         // Split the string into an array of items
                            // var items = commonItem.SelectedDependency.split(',');

                            // // Extract the last two characters of each item
                            // var lastTwoCharacters = items.map(function(item) {
                            //     return item.slice(-2);
                            // });

                            // let parentDependencyVal = lastTwoCharacters[0];



                            // let parentSplitVal = commonItem.SelectedDependency.split('-');
                            let parentSplitVal = commonItem.SelectedDependency.split(/[-,]/);  /// Added on 03Apr2024 by karthik
                            //let parentIndexVal = parentSplitVal[0].trim();
                            let parentDependencyVal = parentSplitVal[2] !== undefined ? parentSplitVal[2].trim() : parentSplitVal[1].trim();

                            let DependentID = parentDependencyVal === "ES" ? 1 : parentDependencyVal === "SS" ? 2 : parentDependencyVal === "SE" ? 3 : parentDependencyVal === "EE" ? 4 : '';
                            // ({ SetStartdate, tempEnddate, total, SetEnddate, tempStartdate } = DepandencyAssign(DependentID, SetStartdate, Enddate, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, Startdate, tempStartdate));

                            if (props.location.state?.params?.assignAutocalculation !== undefined) {
                                // // This will pause execution here
                                DependentID = props.location.state.params.assignAutocalculation;

                            }
                            DependentText = DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : 'ES';
                            //DependentID = 1; //Just Check ES based calculation as defaultly added on 14may2024 by karthik 

                            //leave days calculation
                            // End to end
                            let indexofcurrrow1 = Increment === 2 ? indexofcurrrow - 1 : indexofcurrrow;
                            ({ SetStartdate, tempEnddate, total, SetEnddate, tempStartdate } = ExfactoryDepandentApply(DependentID, SetStartdate, skugroup, indexofcurrrow1, finaldifference, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, tempStartdate));
                        }

                        commonItem.EndDate = SetEnddate;
                        commonItem.StartDate = SetStartdate;
                        commonItem.PreEndDate = SetEnddate;
                        commonItem.PreStartDate = SetStartdate;



                        if (commonItem.SelectedDependency === '') {
                            commonItem.AutoText = "(Auto)";
                            commonItem.SelectedDependency = (i + 1) + "-" + skugroup[indexofcurrrow - Increment].ChildIndex + "-" + DependentText;
                            // commonItem.IsDepandency = 1;
                            commonItem.DependencyCount = 1;
                            InfoSelectedDependency = commonItem.SelectedDependency;
                            //InfoIncreament = ++Increment;
                            commonItem.AssignedDepandency = DependentText;
                            commonItem.AssignedStartDate = SetStartdate;
                            commonItem.AssignedEndDate = SetEnddate;

                            DepandencyIndexeswithValues.push({
                                index: skugroup[indexofcurrrow - Increment].ChildId,
                                value: DependentText,
                                Logdays: finaldifference - 1,
                                //Logdays: ''
                            })

                            // let parentSplitVal = parentDependency.split('-');
                            // let parentIndexVal = parentSplitVal[0].trim();
                            // let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                            // Setdepandencybasedontaskclose(commonItem.SelectedDependency, i, p, c, parentIndexVal, parentDetailTypeID);

                            CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);
                        }
                        // else {
                        //     Setdepandencybasedontaskclose(commonItem.SelectedDependency, i, p, c);
                        // }
                        else {



                            if ((!commonItem.SelectedDependency.includes(skugroup[indexofcurrrow - Increment].ChildIndex + "-ES")
                                && !commonItem.SelectedDependency.includes(skugroup[indexofcurrrow - Increment].ChildIndex + "-EE")
                                && !commonItem.SelectedDependency.includes(skugroup[indexofcurrrow - Increment].ChildIndex + "-SS")
                                && !commonItem.SelectedDependency.includes(skugroup[indexofcurrrow - Increment].ChildIndex + "-SE"))
                                || finaldifference > 0) { // Added on 29Mar24 by karthik (finaldifference > 0)

                                let parentSplitVal = parentDependency.split(/[-,]/);
                                let parentIndexVal = parentSplitVal[0].trim();
                                let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;

                                if (parentDetailTypeID !== 4) // Added on 04Apr24 by karthik
                                {
                                    commonItem.AutoText = "(Auto)";
                                    commonItem.SelectedDependency = commonItem.SelectedDependency + "," + ((i + 1) + "-" +
                                        skugroup[indexofcurrrow - Increment].ChildIndex + "-" + DependentText);
                                    // Ensure indexOfCurrRow and DependentID are defined
                                    // let CheckDependentID = DependentID;

                                    //skugroup[indexofcurrrow - Increment].arrDependency.filter(x => x.ChildId === prevChildId){x.IsDepandency = DependentID}

                                    //skugroup[indexofcurrrow - Increment].AssignedDepandency = DependentText; // Newly added by karthik 
                                }

                            }
                            commonItem.IsDepandency = 1;
                            commonItem.AssignedDepandency = DependentText;
                            DepandencyIndexeswithValues.push({
                                index: skugroup[indexofcurrrow - Increment].ChildId,
                                value: DependentText,
                                Logdays: finaldifference - 1
                                //Logdays: ''
                            })

                            //CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c); //Uncommanded on 17feb2024

                        }



                    }

                    if (parentdependencyCount === 1 && parentDependency.length !== 0) {

                        CountSameColor = (ExfactDate === prevexfactdate) ? CountSameColor + 1 : 1; // added on 7thmay2024 by karthik

                        let parentSplitVal = parentDependency.split('-');
                        let parentIndexVal = parentSplitVal[0].trim();
                        let parentDependencyVal = parentSplitVal[1].trim();
                        let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;

                        let TestCheck = CountSameColor === 0 ? CountSameColor : CountSameColor - 1; // added on 7thmay2024 by karthik

                        if (parentDetailTypeID === 4) {
                            let matchedrow = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(x => x.SubId === SkuId && x.ExFacDate === ExfactDate)[TestCheck];
                            if (matchedrow === undefined) // added on 8thmay2024 by karthik
                            {
                                matchedrow = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(x => x.SubId === SkuId && x.ExFacDate === ExfactDate)[0];
                            }
                            let Startdate = matchedrow.StartDate;
                            let Enddate = matchedrow.EndDate;
                            let SetStartdate = new Date(); let SetEnddate = new Date();
                            let tempEnddate = new Date(); let tempStartdate = new Date();
                            let total = 0;

                            let DependentID = parentDependencyVal === "ES" ? 1 : parentDependencyVal === "SS" ? 2 : parentDependencyVal === "SE" ? 3 : parentDependencyVal === "EE" ? 4 : '';

                            if (props.location.state?.params?.assignAutocalculation !== undefined) {
                                // This will pause execution here
                                DependentID = props.location.state.params.assignAutocalculation;
                            }
                            //DependentID = 1; //Just Check ES based calculation as defaultly added on 14may2024 by karthik 

                            ({ SetStartdate, tempEnddate, total, SetEnddate, tempStartdate } = DepandencyAssign(DependentID, SetStartdate, Enddate, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, Startdate, tempStartdate));

                            commonItem.EndDate = SetEnddate;
                            commonItem.StartDate = SetStartdate;
                            commonItem.PreEndDate = SetEnddate;
                            commonItem.PreStartDate = SetStartdate;

                            commonItem.SelectedDependency = parentIndexVal + "-" + matchedrow.ChildIndex + "-" + parentDependencyVal;
                            commonItem.DependencyCount = 1;
                            commonItem.IsDepandency = 1;
                            commonItem.AssignedDepandency = parentDependencyVal;
                            let DepandencyIndexeswithValues = [];
                            DepandencyIndexeswithValues.push({
                                index: matchedrow.ChildId,
                                value: parentDependencyVal
                            })

                            CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);
                            // if (commonItem.arrDependency.length === 0) {
                            //     let DepandencyIndexeswithValues = [];
                            //     DepandencyIndexeswithValues.push({
                            //         index: matchedrow.ChildId,
                            //         value: parentDependencyVal
                            //     })

                            //     CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);
                            // }
                            // else {
                            //     let DepandencyIndexeswithValues = []
                            //     DepandencyIndexeswithValues.push({
                            //         index: commonItem.ChildId,
                            //         value: parentDependencyVal
                            //     })

                            //     CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);
                            // }

                        }
                    }

                }
                else {

                    // if (indexofcurrrow === 0) {

                    //     commonItem.EndDate = !isLastEdit ? values[i].EndDate : commonItem.EndDate;
                    //     commonItem.StartDate = !isLastEdit ? values[i].StartDate : commonItem.StartDate;
                    //     commonItem.PreEndDate = !isLastEdit ? values[i].EndDate : commonItem.EndDate;
                    //     commonItem.PreStartDate = !isLastEdit ? values[i].StartDate : commonItem.StartDate;
                    //     if (commonItem.AssignedDepandency !== '') {
                    //         let parentSplitVal = parentDependency.split('-');
                    //         let parentIndexVal = parentSplitVal[0].trim();
                    //         let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                    //         //  Setdepandencybasedontaskclose(commonItem.SelectedDependency, i, p, c, parentIndexVal, parentDetailTypeID);
                    //     }
                    // }
                    if (isLastEdit === false) {
                        commonItem.AutoText = "(Auto)";
                    }

                }
            }
            else {

                if (isLastEdit === false) {
                    commonItem.AutoText = "(Auto)";
                }


                //commonItem.AutoText = "(Auto)";

                // let parentSplitVal = parentDependency.split('-');
                //  let parentIndexVal = parentSplitVal[0].trim();
                // let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                //  Setdepandencybasedontaskclose(commonItem.SelectedDependency, i, p, c, parentIndexVal, parentDetailTypeID);
            }
            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
        }

    }




    function ExfactoryDepandentApply(DependentID, SetStartdate, skugroup, indexofcurrrow, finaldifference, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, tempStartdate) {

        if (DependentID === 1) // ES
        {
            SetStartdate = addDays(skugroup[indexofcurrrow - 1].EndDate, finaldifference);
            //Calculation Saturday,Sunday and Holiday Count
            SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
            tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempEnddate = addDays(tempEnddate, 1);
                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
            }

            SetEnddate = tempEnddate;



        }
        else if (DependentID === 2) //SS
        {

            //Calculation Saturday,Sunday and Holiday Count
            SetStartdate = checkDateBetweenInHolidayAndWE(addDays((skugroup[indexofcurrrow - 1].StartDate), finaldifference - 1), LeaveDays, titleEmployee1, "add");
            tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempEnddate = addDays(tempEnddate, 1);
                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
            }
            SetEnddate = tempEnddate;


        }
        else if (DependentID === 3) // SE
        {
            SetStartdate = addDays(skugroup[indexofcurrrow - 1].StartDate, finaldifference);
            SetEnddate = checkDateBetweenInHolidayAndWE(subDays(SetStartdate, 1), LeaveDays, titleEmployee1, "sub");
            tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempStartdate = subDays(tempStartdate, 1);
                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
            }
            SetStartdate = tempStartdate;


        }
        else if (DependentID === 4) // EE
        {

            SetEnddate = checkDateBetweenInHolidayAndWE(addDays((skugroup[indexofcurrrow - 1].EndDate), finaldifference - 1),
                LeaveDays, titleEmployee1, "add");
            tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempStartdate = subDays(tempStartdate, 1);
                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
            }
            SetStartdate = tempStartdate;

        }
        return { SetStartdate, tempEnddate, total, SetEnddate, tempStartdate };
    }

    function DepandencyAssign(DependentID, SetStartdate, Enddate, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, Startdate, tempStartdate) {
        if (DependentID === 1) {

            SetStartdate = addDays(Enddate, 1);
            //Calculation Saturday,Sunday and Holiday Count
            SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
            tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempEnddate = addDays(tempEnddate, 1);
                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
            }

            SetEnddate = tempEnddate;

        }
        //Start to Start
        if (DependentID === 2) {

            //Calculation Saturday,Sunday and Holiday Count
            SetStartdate = checkDateBetweenInHolidayAndWE(Startdate, LeaveDays, titleEmployee1, "add");
            tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempEnddate = addDays(tempEnddate, 1);
                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
            }
            SetEnddate = tempEnddate;

        }
        //Start to End
        if (DependentID === 3) {

            SetEnddate = checkDateBetweenInHolidayAndWE(subDays(Startdate, 1), LeaveDays, titleEmployee1, "sub");
            tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempStartdate = subDays(tempStartdate, 1);
                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
            }
            SetStartdate = tempStartdate;

        }
        //End to end
        if (DependentID === 4) {

            SetEnddate = checkDateBetweenInHolidayAndWE(Enddate, LeaveDays, titleEmployee1, "sub");
            tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempStartdate = subDays(tempStartdate, 1);
                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
            }
            SetStartdate = tempStartdate;

        }
        return { SetStartdate, tempEnddate, total, SetEnddate, tempStartdate };
    }

    function Setdepandencybasedontaskclose(ChildDepandency, i, p, c, parentDependencyIndex, parentDetailTypeID) {

        let CurrentChildvalues = [];
        values.map((Main, MainIndex) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({ StatusID: SubChild.StatusID, ChildIndex: SubChild.ChildIndex })
                })
            })
        })

        if (ChildDepandency !== '') {
            if (parentDetailTypeID !== 1) {
                if (ChildDepandency.includes(',')) {
                    let IsAllTasksClosed = true;
                    let splitmultiparentchildindex = ChildDepandency.split(',');
                    splitmultiparentchildindex.map(depitem => {
                        let splitparentchildindex = depitem.split('-');
                        if (CurrentChildvalues.filter(x => x.ChildIndex === parseInt(splitparentchildindex[1]))[0].StatusID !== 3) {
                            IsAllTasksClosed = false;
                        }
                    })

                    if (IsAllTasksClosed === true) {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 0;
                    }
                    else {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 1;
                    }

                }
                else {
                    let splitparentchildindex = ChildDepandency.split('-');
                    if (CurrentChildvalues.filter(x => x.ChildIndex === parseInt(splitparentchildindex[1]))[0].StatusID === 3) {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 0;
                    }
                    else {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 1;
                    }
                }
            }
            else {
                if (ChildDepandency.includes(',')) {

                }
                else {

                    if (values[parentDependencyIndex - 1].StatusID === 3) {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 0;
                    }
                    else {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 1;
                    }
                }
            }
        }
    }

    function ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentDependencyIndex, parentDetailTypeID) {

        let ChildDepandency = '';
        let DepandencyIndexeswithValues = [];
        let parentSplitVal = parentDependency.split('-');
        let parentIndexVal = parentSplitVal[0].trim();
        let parentDependencyVal = parentSplitVal[1].trim();
        parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;


        let SkuId = commonItem.SubId;
        let PurchaseOrderSkuID = commonItem.PurchaseOrderSkuID;

        if (DetailTypeID === 1) {
            ChildDepandency = parentDependency;
            DepandencyIndexeswithValues.push({
                index: values[parentIndexVal - 1].TNARouteApplyID,
                value: parentDependencyVal
            })


        }
        else if (DetailTypeID === 2) { // DetailType => Sku-----------------------------------

            // Dependent base compare to  DetailTypeID

            if (parentDetailTypeID === 1) { // DetailType => Common
                ChildDepandency = parentDependency;
                DepandencyIndexeswithValues.push({
                    index: values[parentIndexVal - 1].TNARouteApplyID,
                    value: parentDependencyVal
                })
            }
            else if (parentDetailTypeID === 2) { // DetailType => Sku

                let allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
                allmappedsku && allmappedsku.map(x => {
                    if (ChildDepandency === '') {
                        ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    } else {
                        ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    }
                    DepandencyIndexeswithValues.push({
                        index: x.ChildId,
                        value: parentDependencyVal
                    })
                })

            } else if (parentDetailTypeID === 3) { // DetailType => Item

                let allmappedsku = [];

                values[parentIndexVal - 1].SubTaskList.map((item) => {

                    allmappedsku = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()));
                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        } else {
                            ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        }
                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: parentDependencyVal
                        })
                    })


                });
                //Order by descending ( -1 : 1)



            } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory


                let allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId && d.PurchaseOrderSkuID === PurchaseOrderSkuID);
                allmappedsku && allmappedsku.map(x => {
                    if (ChildDepandency === '') {
                        ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    } else {
                        ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    }
                    DepandencyIndexeswithValues.push({
                        index: x.ChildId,
                        value: parentDependencyVal
                    })
                })
            }

        }
        else if (DetailTypeID === 3) { // DetailType => Item-------------------------------


            if (parentDetailTypeID === 1) { // DetailType => Common
                ChildDepandency = parentDependency;
                DepandencyIndexeswithValues.push({
                    index: values[parentIndexVal - 1].TNARouteApplyID,
                    value: parentDependencyVal
                })
            }
            else if (parentDetailTypeID === 2) { // DetailType => Sku

                //let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                let allmappedsku = [];
                arySkuIdList.map((item) => {
                    allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item));
                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        } else {
                            if (!ChildDepandency.includes(parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal)) {
                                ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                            }
                        }
                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: parentDependencyVal
                        })
                    })

                });

            } else if (parentDetailTypeID === 3) { // DetailType => Item
                let TaskName = values[i].strTaskName;
                let preTaskName = values[parentIndexVal - 1].strTaskName;
                let hasSameItem = false;

                if ((TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)
                    && (preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)) {
                    hasSameItem = true;

                } else if ((TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)
                    && (preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)) {
                    hasSameItem = true;

                } else if ((TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)
                    && (preTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || preTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)) {
                    hasSameItem = true;
                }

                if (hasSameItem) { //Same Item Match
                    let SubId = commonItem.SubId;
                    let MainId = commonItem.MainId;

                    let preChildTaskList = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0] && values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0].ChildTaskList;
                    let allmappedsku = preChildTaskList && preChildTaskList.filter(d => d.SubId === SubId);
                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        } else {
                            if (!ChildDepandency.includes(parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal)) {
                                ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                            }
                        }
                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: parentDependencyVal
                        })
                    })


                } else { //Other Item Match Sku


                    // let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                    let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                    let allmappedsku = [];
                    arySkuIdList && arySkuIdList.map((Sku) => {
                        values[parentIndexVal - 1].SubTaskList.map((item) => {
                            allmappedsku = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(Sku.toString()));
                            allmappedsku && allmappedsku.map(x => {
                                if (ChildDepandency === '') {
                                    ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                                } else {
                                    if (!ChildDepandency.includes(parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal)) {
                                        ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                                    }
                                }

                                DepandencyIndexeswithValues.push({
                                    index: x.ChildId,
                                    value: parentDependencyVal
                                })
                            })

                        });

                    });


                }

            } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory


                // let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                let allmappedsku = [];
                arySkuIdList.map((item) => {
                    allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item));
                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        } else {
                            if (!ChildDepandency.includes(parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal)) {
                                ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                            }
                        }

                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: parentDependencyVal
                        })
                    })


                });
            }

        } else if (DetailTypeID === 4) { // DetailType => Ex-Factory---------------------------


            if (parentDetailTypeID === 1) { // DetailType => Common
                ChildDepandency = parentDependency;
                DepandencyIndexeswithValues.push({
                    index: values[parentIndexVal - 1].TNARouteApplyID,
                    value: parentDependencyVal
                })
            }
            else if (parentDetailTypeID === 2) { // DetailType => Sku

                let allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
                allmappedsku && allmappedsku.map(x => {
                    if (ChildDepandency === '') {
                        ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    } else {
                        ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    }
                    DepandencyIndexeswithValues.push({
                        index: x.ChildId,
                        value: parentDependencyVal
                    })
                })

            } else if (parentDetailTypeID === 3) { // DetailType => Item

                let allmappedsku = [];
                values[parentIndexVal - 1].SubTaskList.map((item) => {

                    allmappedsku = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()));
                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        } else {
                            ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        }
                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: parentDependencyVal
                        })
                    })


                });
                //Order by descending ( -1 : 1)



            } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory


                let allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId && d.PurchaseOrderSkuID === PurchaseOrderSkuID); // added latest by karthik
                allmappedsku && allmappedsku.map(x => {
                    if (ChildDepandency === '') {
                        ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    } else {
                        ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    }
                    DepandencyIndexeswithValues.push({
                        index: x.ChildId,
                        value: parentDependencyVal
                    })
                })
            }
        }

        if (DetailTypeID === 4 && commonItem.AssignedDepandency === '') {

            let skugroup = values[i].SubTaskList[p].ChildTaskList.filter(x => x.SubId === commonItem.SubId);
            let indexofcurrrow = skugroup.findIndex(x => x.ChildId === commonItem.ChildId);

            if (indexofcurrrow > 0) {

                let currexfactdate = commonItem.ExFacDate;
                let prevexfactdate = skugroup[indexofcurrrow - 1].ExFacDate;

                if (currexfactdate === prevexfactdate) // Added on 03Apr2029 by karthik
                {
                    indexofcurrrow = indexofcurrrow === 1 ? indexofcurrrow : indexofcurrrow - 1; // Added on 05Apr2029 by karthik
                    prevexfactdate = skugroup[indexofcurrrow - 1].ExFacDate;
                }
                let Difference_In_Time = new Date(currexfactdate).getTime() - new Date(prevexfactdate).getTime();
                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                let finaldifference = Difference_In_Days === 0 ? 1 : (Difference_In_Days + 1)

                if (currexfactdate !== prevexfactdate) // Added on 09Apr2029 by karthik
                {
                    let DependentID;
                    let DependentText;

                    if (props.location.state?.params?.assignAutocalculation !== undefined && props.location.state?.params?.assignAutocalculation !== 0) {
                        DependentID = props.location.state.params.assignAutocalculation;

                        DependentText = DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : 'ES';

                        if (ChildDepandency === '') {
                            commonItem.SelectedDependency = (i + 1) + "-" + skugroup[indexofcurrrow - 1].ChildIndex + "-" + DependentText;
                        }
                        else {
                            commonItem.SelectedDependency = ChildDepandency + "," + (i + 1) + "-" + skugroup[indexofcurrrow - 1].ChildIndex + "-" + DependentText;
                        }


                        DepandencyIndexeswithValues.push({
                            index: skugroup[indexofcurrrow - 1].ChildId,
                            value: DependentText,
                            Logdays: finaldifference - 1
                        })

                    }
                }

            }

        }



        // Setdepandencybasedontaskclose(values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency === '' ?
        //     ChildDepandency : values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency, i, p, c, parentDependencyIndex, parentDetailTypeID);
        if (values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency === '') {
            values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency = ChildDepandency;
            values[i].SubTaskList[p].ChildTaskList[c].AssignedDepandency = parentSplitVal[1].trim();
            values[i].SubTaskList[p].ChildTaskList[c].DependencyCount = DepandencyIndexeswithValues.length;
            CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c, parentIndexVal);
        }
        if (DetailTypeID === 4 && commonItem.AssignedDepandency === '') {
            values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency = ChildDepandency;
            values[i].SubTaskList[p].ChildTaskList[c].DependencyCount = DepandencyIndexeswithValues.length;
            CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c, parentIndexVal);
        }

    }

    function ChildDepandencyTextwithMultiParentdep(DetailTypeID, splitDepList, commonItem, i, p, c, SkuId, SubTaskName) {

        let ChildDepandency = '';
        let DepandencyIndexeswithValues = [];


        splitDepList.map((depItem, d) => {

            let splitVal = depItem.split('-');
            let indexVal = splitVal[0].trim();
            let dependencyVal = splitVal[1].trim();

            // Dependent base compare to  DetailTypeID
            let parentDetailTypeID = values[indexVal - 1].DetailTypeID;
            if (parentDetailTypeID === 1) { // DetailType => Common
                ChildDepandency = depItem;
                DepandencyIndexeswithValues.push({
                    index: values[indexVal - 1].TNARouteApplyID,
                    value: dependencyVal
                })
            }
            else if (parentDetailTypeID === 2) { // DetailType => Sku


                let allmappedsku = [];
                let uniqueDependencies = {};

                let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                // let arySkuIdList =  commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                arySkuIdList && arySkuIdList.map((Sku) => {
                    values[indexVal - 1].SubTaskList.length > 0 && values[indexVal - 1].SubTaskList.map((item) => {
                        let TNASkuIdList = item.ChildTaskList.filter(d => d.SubId === parseInt(Sku))[0];
                        TNASkuIdList && allmappedsku.push(TNASkuIdList);
                        // if (TNASkuIdList && TNASkuIdList.length > 0) {

                        //     preChildDetailList = (TNASkuIdList && TNASkuIdList.length > 0) ? [preChildDetailList, ...TNASkuIdList] : preChildDetailList;
                        // }

                    });
                });


                // 
                // values[indexVal - 1].SubTaskList.length > 0 && values[indexVal - 1].SubTaskList.map((item) => {
                //     let TNASkuIdList = item.ChildTaskList.filter(d => d.SubId === parseInt(commonItem.SubId))[0];
                //     TNASkuIdList && allmappedsku.push(TNASkuIdList);
                // }); 

                // let allmappedsku = values[indexVal - 1].SubTaskList.length > 0 &&
                //     values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);

                allmappedsku && allmappedsku.map(x => {
                    // if (ChildDepandency === '') {
                    //     ChildDepandency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                    // } else {
                    //     ChildDepandency = ChildDepandency + "," + indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                    // }

                    // DepandencyIndexeswithValues.push({
                    //     index: x.ChildId,
                    //     value: dependencyVal
                    // })



                    ChildDepandency = AvoidDuplicationDep(indexVal, x, dependencyVal, uniqueDependencies, ChildDepandency, DepandencyIndexeswithValues);

                })


            } else if (parentDetailTypeID === 3) { // DetailType => Item

                let TaskName = values[i].strTaskName;
                let preTaskName = values[indexVal - 1].strTaskName;
                let hasSameItem = false;

                if ((TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)
                    && (preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)) {
                    hasSameItem = true;

                } else if ((TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)
                    && (preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)) {
                    hasSameItem = true;

                } else if ((TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)
                    && (preTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || preTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)) {
                    hasSameItem = true;
                }

                if (hasSameItem) { //Same Item Match
                    let SubId = commonItem.SubId;
                    let MainId = commonItem.MainId;
                    let arySkuIdList;
                    let allmappedsku;
                    let uniqueDependencies = {};

                    //     arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString()  && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                    //     arySkuIdList.map((item) => {

                    //        //allmappedsku = values[indexVal - 1].SubTaskList.length > 0 && values[indexVal - 1].SubTaskList.ChildTaskList.filter(d => parseInt(d.SKUIdList) === parseInt(item));
                    //        values[indexVal - 1].SubTaskList.length > 0 && values[indexVal - 1].SubTaskList.map((item1) => {
                    //            allmappedsku = item1.ChildTaskList.filter(d => parseInt(d.SKUIdList) === parseInt(item));

                    //            allmappedsku && allmappedsku.map(x => {
                    //                if (ChildDepandency === '') {
                    //                    ChildDepandency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                    //                } else {
                    //                    ChildDepandency = ChildDepandency + "," + indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                    //                }

                    //                DepandencyIndexeswithValues.push({
                    //                    index: x.ChildId,
                    //                    value: dependencyVal
                    //                })
                    //            })

                    //    })  })



                    //  ------------



                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                        TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT ||
                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {


                        // Based on some condition added 9 may 2024

                        if (
                            values[indexVal - 1].strTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                            values[indexVal - 1].strTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT ||
                            values[indexVal - 1].strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                            values[indexVal - 1].strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                            let preChildTaskList = values[indexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0] && values[indexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0].ChildTaskList;

                            allmappedsku = preChildTaskList && preChildTaskList.filter(d => d.SubId === SubId);

                            allmappedsku && allmappedsku.forEach(x => {
                                if (ChildDepandency === '') {
                                    ChildDepandency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                                } else {
                                    ChildDepandency = ChildDepandency + "," + indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                                }

                                DepandencyIndexeswithValues.push({
                                    index: x.ChildId,
                                    value: dependencyVal
                                });
                            });

                        }
                        else {
                            arySkuIdList = commonItem.SKUIdList === '' ? [commonItem.SubId.toString()] : commonItem.SKUIdList.split(',').map(id => id.trim()); // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                            arySkuIdList.forEach(item => {
                                values[indexVal - 1].SubTaskList.forEach(item1 => {
                                    allmappedsku = item1.ChildTaskList.filter(d => {
                                        // Split SKUIdList into an array of integers
                                        const skuIds = d.SKUIdList.split(',').map(id => parseInt(id.trim()));
                                        // Check if the current item matches any SKUId
                                        return skuIds.includes(parseInt(item));
                                    });

                                    allmappedsku && allmappedsku.forEach(x => {
                                        // if (ChildDepandency === '') {
                                        //     ChildDepandency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                                        // } else {
                                        //     ChildDepandency = ChildDepandency + "," + indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                                        // }

                                        // DepandencyIndexeswithValues.push({
                                        //     index: x.ChildId,
                                        //     value: dependencyVal
                                        // });


                                        ChildDepandency = AvoidDuplicationDep(indexVal, x, dependencyVal, uniqueDependencies, ChildDepandency, DepandencyIndexeswithValues);
                                    });
                                });
                            });



                        }
                    }
                    else {

                        arySkuIdList = commonItem.SKUIdList === '' ? [commonItem.SubId.toString()] : commonItem.SKUIdList.split(',').map(id => id.trim()); // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                        arySkuIdList.forEach(item => {
                            values[indexVal - 1].SubTaskList.forEach(item1 => {
                                // allmappedsku = item1.ChildTaskList.filter(d => {
                                //     // Split SKUIdList into an array of integers
                                //     const skuIds = d.SKUIdList.split(',').map(id => parseInt(id.trim()));
                                //     // Check if the current item matches any SKUId
                                //     return skuIds.includes(parseInt(item));
                                // });

                                allmappedsku = item1.ChildTaskList.filter(d => {
                                    // Split SKUIdList into an array of integers
                                    const skuIds = d.SKUIdList.split(',').map(id => parseInt(id.trim()));

                                    // if(commonItem.TaskName  === "Trim Receipt from Nominated Supplier" ||  commonItem.TaskName  === "Trim Order to Nominated Supplier" ||  commonItem.TaskName  === "Trim Receipt From Buyer"

                                    //   || commonItem.TaskName  === "Strike off Receipt from Nominated Supplier" || commonItem.TaskName  === "Strike off Order to Nominated Supplier" || commonItem.TaskName  === "Strike off Receipt from Buyer" || commonItem.TaskName  === "Trim order to Local Supplier"
                                    // || commonItem.TaskName  === "Trim Receipt from Local Supplier")
                                    // {
                                    //     return skuIds.includes(parseInt(item)) && d.SKUIdList === commonItem.SKUIdList;

                                    // }
                                    // else{

                                    //      // Check if the current item matches any SKUId and the mainId matches
                                    //       return skuIds.includes(parseInt(item)) && d.MainId === commonItem.MainId  && d.SubId  === commonItem.SubId;

                                    // }

                                    const taskNames = [
                                        "Trim Receipt from Nominated Supplier",
                                        "Trim Order to Nominated Supplier",
                                        "Trim Receipt From Buyer",
                                        "Strike off Receipt from Nominated Supplier",
                                        "Strike off Order to Nominated Supplier",
                                        "Strike off Receipt from Buyer",
                                        "Trim order to Local Supplier",
                                        "Trim Receipt from Local Supplier"
                                    ];

                                    const lowerCaseTaskNames = taskNames.map(taskName => taskName.toLowerCase());

                                    if (lowerCaseTaskNames.includes(commonItem.TaskName.toLowerCase())) {
                                        return skuIds.includes(parseInt(item)) && d.SKUIdList === commonItem.SKUIdList;
                                    } else {
                                        // Check if the current item matches any SKUId and the mainId matches
                                        return skuIds.includes(parseInt(item)) && d.MainId === commonItem.MainId && d.SubId === commonItem.SubId;
                                    }


                                });


                                allmappedsku && allmappedsku.forEach(x => {
                                    // if (ChildDepandency === '') {
                                    //     ChildDepandency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                                    // } else {
                                    //     ChildDepandency = ChildDepandency + "," + indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                                    // }

                                    // DepandencyIndexeswithValues.push({
                                    //     index: x.ChildId,
                                    //     value: dependencyVal
                                    // });


                                    ChildDepandency = AvoidDuplicationDep(indexVal, x, dependencyVal, uniqueDependencies, ChildDepandency, DepandencyIndexeswithValues);
                                });
                            });
                        });



                    }








                } else { //Other Item Match Sku

                    let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                    //let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                    let allmappedsku = [];
                    let uniqueDependencies = {};
                    arySkuIdList && arySkuIdList.map((Sku) => {
                        values[indexVal - 1].SubTaskList.map((item) => {
                            allmappedsku = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(Sku.toString()));
                            allmappedsku && allmappedsku.map(x => {


                                ChildDepandency = AvoidDuplicationDep(indexVal, x, dependencyVal, uniqueDependencies, ChildDepandency, DepandencyIndexeswithValues);



                            })

                        });
                    });




                }

            } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory

                // let allmappedsku = values[indexVal - 1].SubTaskList.length > 0 && values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
                // (start) Added on 25apr2024 by karthik
                let SKUID = commonItem.SKUIdList !== '' ? commonItem.SKUIdList : SkuId.toString();

                let SKUIDVal = SKUID.split(',');

                SKUIDVal.forEach(SplitSKUIDVal => {
                    let allmappedsku = values[indexVal - 1].SubTaskList.length > 0 && values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(SplitSKUIDVal)); // Added on 23Apr2014 by karthik

                    if (allmappedsku && allmappedsku.length > 1) {
                        allmappedsku = allmappedsku.sort((a, b) => moment(new Date(a["ExFacDate"])) - moment(new Date(b["ExFacDate"]))); // Sort the array by start date

                        // Create an array to store items with the same start date
                        let resultArray = [allmappedsku[0]];

                        // Iterate through the sorted array to find items with the same start date
                        for (let i = 1; i < allmappedsku.length; i++) {
                            if (moment(new Date(allmappedsku[i]["ExFacDate"])).isSame(moment(new Date(resultArray[0]["ExFacDate"])), 'day')) {
                                resultArray.push(allmappedsku[i]); // Add items with the same start date to the result array
                            } else {
                                break; // Break the loop if a different start date is encountered
                            }
                        }

                        allmappedsku = resultArray; // Assign the result array to allmappedsku
                    }
                    allmappedsku && allmappedsku.map(x => {
                        if (SubTaskName === undefined || SubTaskName === x.Name) // Added on 23Apr2014 by karthik
                        {
                            if (ChildDepandency === '') {
                                ChildDepandency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                            } else {
                                ChildDepandency = ChildDepandency + "," + indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                            }

                            DepandencyIndexeswithValues.push({
                                index: x.ChildId,
                                value: dependencyVal
                            })
                        }
                    })

                })

                //(End) Added on 25apr2024 by karthik


            }



            //   Setdepandencybasedontaskclose(values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency === '' ?
            //      ChildDepandency : values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency, i, p, c, indexVal, parentDetailTypeID);
        })
        if (DetailTypeID === 4 && commonItem.AssignedDepandency === '') {

            let skugroup = values[i].SubTaskList[p].ChildTaskList.filter(x => x.SubId === commonItem.SubId);
            let indexofcurrrow = skugroup.findIndex(x => x.ChildId === commonItem.ChildId);

            if (indexofcurrrow > 0) {

                let currexfactdate = commonItem.ExFacDate;
                let prevexfactdate = skugroup[indexofcurrrow - 1].ExFacDate;
                let Difference_In_Time = new Date(currexfactdate).getTime() - new Date(prevexfactdate).getTime();
                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                let finaldifference = Difference_In_Days === 0 ? 1 : (Difference_In_Days + 1)

                let DependentID;
                let DependentText;

                if (props.location.state?.params?.assignAutocalculation !== undefined) {
                    DependentID = props.location.state.params.assignAutocalculation;
                }
                DependentText = DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : 'ES';


                if (ChildDepandency === '') {
                    commonItem.SelectedDependency = (i + 1) + "-" + skugroup[indexofcurrrow - 1].ChildIndex + "-" + DependentText;
                }
                else {
                    commonItem.SelectedDependency = ChildDepandency + "," + (i + 1) + "-" + skugroup[indexofcurrrow - 1].ChildIndex + "-" + DependentText;
                }

                DepandencyIndexeswithValues.push({
                    index: skugroup[indexofcurrrow - 1].ChildIndex,
                    value: DependentText,
                    Logdays: finaldifference - 1
                    //Logdays: ''
                })

                //CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c); //Added on 17feb2024

            }

        }


        if (values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency === '') {
            values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency = ChildDepandency;
            values[i].SubTaskList[p].ChildTaskList[c].DependencyCount = DepandencyIndexeswithValues.length;
            CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);
        }
        if (DetailTypeID === 4 && commonItem.AssignedDepandency === '') {
            values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency = ChildDepandency;
            values[i].SubTaskList[p].ChildTaskList[c].DependencyCount = DepandencyIndexeswithValues.length;
            CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);
        }
    }

    function CreateChildDepandencydata(DepandencyIndexeswithValues, index, subIndex, childIndex) {

        let RowIndex = 1;
        let TaskDependency = [];

        values.map((Main, Mainindex) => {
            if (Main.IsSingleTask === true) {
                TaskDependency.push(
                    {
                        TNAChildDependencyID: 0,
                        DependentTaskId: Main.TNARouteApplyID,
                        TNATaskName: Main.strTaskName,
                        DependencyID: 0,
                        LogDays: '',
                        IndexName: RowIndex,
                        Mainindex: Mainindex,
                        StartDate: Main.StartDate,
                        EndDate: Main.EndDate,
                        Duration: Main.Duration,
                        ChildIndex: Mainindex + 1,
                        IsSingleTask: true
                    }
                )

                RowIndex++;
            }
            Main.SubTaskList.map((Child, ChildIndex) => {
                Child.ChildTaskList.map((SubChild) => {
                    TaskDependency.push(
                        {
                            TNAChildDependencyID: 0,
                            DependentTaskId: SubChild.ChildId,
                            TNATaskName: SubChild.Name,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: RowIndex,
                            Mainindex: Mainindex,
                            StartDate: SubChild.StartDate,
                            EndDate: SubChild.EndDate,
                            Duration: SubChild.Duration,
                            ChildIndex: SubChild.ChildIndex,
                            IsSingleTask: false
                        }
                    )

                    RowIndex++;
                })
            })


        });

        DepandencyIndexeswithValues.map((x) => {
            TaskDependency.map((t) => {
                if (x.index.toString() === t.DependentTaskId.toString()) {
                    let Depandencyval = x.value;
                    t.DependencyID = Depandencyval === "ES" ? 1 : Depandencyval === "SS" ? 2 : Depandencyval === "SE" ? 3 : Depandencyval === "EE" ? 4 : "";
                    t.LogDays = x.Logdays;
                }
            })
        })

        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency = [];
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency = TaskDependency;
        setInputFields(values);

    }



    // common Child Set Dependency Status (No Dependency for Child and first time load)
    function commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
        dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent) {



        if (commonItem.SelectedDependency === '') {
            let commonPreStartDate = new Date();
            let commonPreEndDate = new Date();
            // let PreChildIndex = '';
            // let PreParentIndex = '';

            if (parentDetailTypeID === 1) { // DetailType => Common
                commonPreStartDate = values[parentIndexVal - 1].StartDate;
                commonPreEndDate = values[parentIndexVal - 1].EndDate;
            } else if (parentDetailTypeID === 2) { // DetailType => Sku
                commonPreStartDate = preChildDetail.StartDate;
                commonPreEndDate = preChildDetail.EndDate;

            } else if (parentDetailTypeID === 3) { // DetailType => Item
                commonPreStartDate = preChildDetail.StartDate;
                commonPreEndDate = preChildDetail.EndDate;
            } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
                commonPreStartDate = preChildDetail.StartDate;
                commonPreEndDate = preChildDetail.EndDate;
            }


            if (parentDependencyVal === "SS") {   //---- Start-Start ---- DependencyID 2------
                //Get Dependent Map StartDate
                let preStartDate = commonPreStartDate;
                let LogDate = new Date();
                LogDate.setHours(0, 0, 0, 0);
                dbStartDate.setHours(0, 0, 0, 0);

                //Auto Calculation function
                // dbStartDate = values[indexVal - 1].StartDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                if (Log && Log[0] && Log[0].LogDays) {
                    dbStartDate = addDays(preStartDate, parseInt(Log[0].LogDays));
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "add");
                } else {
                    //Calculation Saturday,Sunday and Holiday Count
                    dbStartDate = checkDateBetweenInHolidayAndWE(preStartDate, LeaveDays, titleEmployee1, "add");
                }
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                }

                commonItem.EndDate = dbEndDate;
                commonItem.StartDate = dbStartDate;
                commonItem.PreEndDate = dbEndDate;
                commonItem.PreStartDate = dbStartDate;
                // PreChildIndex = preChildDetail.ChildIndex;
                // values.map((Main, Mainindex) => {
                //     Main.SubTaskList.map((Child, ChildIndex) => {
                //         Child.ChildTaskList.map((SubChild) => {
                //             if (SubChild.ChildIndex === PreChildIndex) {
                //                 PreParentIndex = Mainindex + 1;
                //             }

                //         })
                //     })
                // })
                commonItem.AssignedStartDate = dbStartDate;
                commonItem.AssignedEndDate = dbEndDate;
                commonItem.AssignedDepandency = "SS";
                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;

            } else if (parentDependencyVal === "SE") {  //---- Start-End ----DependencyID 3---

                let preStartDate = subDays(commonPreStartDate, 1);
                let LogDate = preStartDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3)[0];
                LogDate.setHours(0, 0, 0, 0);
                dbEndDate.setHours(0, 0, 0, 0);
                if (LogDate <= dbEndDate && commonItem.EndDateChange === 2) {
                    //Skip
                } else {
                    //Auto Calculation function
                    if (Log && Log[0] && Log[0].LogDays) {
                        //LogDays Add
                        dbEndDate = addDays(preStartDate, parseInt(Log.LogDays));
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                    } else {
                        dbEndDate = checkDateBetweenInHolidayAndWE(preStartDate, LeaveDays, titleEmployee1, "sub");
                    }
                    dbStartDate = subDays(dbEndDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                    for (let l = 0; l < total; l++) {
                        dbStartDate = subDays(dbStartDate, 1);
                        dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                    }
                }
                commonItem.StartDate = dbStartDate;
                commonItem.EndDate = dbEndDate;
                commonItem.PreStartDate = dbStartDate;
                commonItem.PreEndDate = dbEndDate;

                commonItem.AssignedStartDate = dbStartDate;
                commonItem.AssignedEndDate = dbEndDate;
                commonItem.AssignedDepandency = "SE";
                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;

            } else if (parentDependencyVal === "ES") {  //Manual Calculation function-------- End-Start ----DependencyID 1------
                //Get Dependent Map StartDate
                let preEndDate = commonPreEndDate;
                let LogDate = new Date();
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1)[0];
                LogDate.setHours(0, 0, 0, 0);
                preEndDate.setHours(0, 0, 0, 0);

                //Auto Calculation function
                dbStartDate = addDays(preEndDate, 1);
                if (Log && Log[0] && Log[0].LogDays) {
                    //LogDays Add
                    dbStartDate = addDays(dbStartDate, parseInt(Log.LogDays));
                }
                //Calculation Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "add");
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                }

                commonItem.EndDate = dbEndDate;
                commonItem.StartDate = dbStartDate;
                commonItem.PreStartDate = dbStartDate;
                commonItem.PreEndDate = dbEndDate;

                commonItem.AssignedStartDate = dbStartDate;
                commonItem.AssignedEndDate = dbEndDate;
                commonItem.AssignedDepandency = "ES";
                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;

            } else if (parentDependencyVal === "EE") {//---- End-End --------DependencyID 4-----------((preEndDate) + LogDays = EndDate))-------
                let preEndDate = commonPreEndDate;
                let LogDate = preEndDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4)[0];
                LogDate.setHours(0, 0, 0, 0);
                dbEndDate.setHours(0, 0, 0, 0);
                //Auto Calculation function
                if (Log && Log[0] && Log[0].LogDays) {
                    //LogDays Add
                    dbEndDate = addDays(preEndDate, parseInt(Log.LogDays));
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                } else {
                    dbEndDate = checkDateBetweenInHolidayAndWE(preEndDate, LeaveDays, titleEmployee1, "sub",);
                }

                dbStartDate = subDays(dbEndDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                for (let l = 0; l < total; l++) {
                    dbStartDate = subDays(dbStartDate, 1);
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                }
                commonItem.EndDate = dbEndDate;
                commonItem.StartDate = dbStartDate;
                commonItem.PreStartDate = dbStartDate;
                commonItem.PreEndDate = dbEndDate;

                commonItem.AssignedStartDate = dbStartDate;
                commonItem.AssignedEndDate = dbEndDate;
                commonItem.AssignedDepandency = "EE";
                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }
        }
        else {
            commonItem.AssignedStartDate = commonItem.StartDate;
            commonItem.AssignedEndDate = commonItem.EndDate;
            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            totalHolidayAndWE(commonItem.StartDate, commonItem.EndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
        }
    }

    function commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult,
        isEndDate, commonItem, dependencyVal, parentIndexVal, parentDetailTypeID, preChildDetail, i, p, c) {
        let commonPreStartDate = new Date();
        let commonPreEndDate = new Date();
        let total = 0;
        let dbEndDate = new Date();
        let dbStartDate = new Date();
        let titleEmployee1 = parentItem.titleEmployee1;

        if (parentDetailTypeID === 1) { // DetailType => Common
            commonPreStartDate = values[parentIndexVal - 1].StartDate;
            commonPreEndDate = values[parentIndexVal - 1].EndDate;
        } else if (parentDetailTypeID === 2) { // DetailType => Sku
            commonPreStartDate = preChildDetail.StartDate;
            commonPreEndDate = preChildDetail.EndDate;

        } else if (parentDetailTypeID === 3) { // DetailType => Item
            commonPreStartDate = preChildDetail.StartDate;
            commonPreEndDate = preChildDetail.EndDate;
        } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
            commonPreStartDate = preChildDetail.StartDate;
            commonPreEndDate = preChildDetail.EndDate;
        }

        try {

            if (dependencyVal === "SS") {
                let preStartDate = commonPreStartDate;
                let LogDate = new Date();
                LogDate.setHours(0, 0, 0, 0);
                dbStartDate.setHours(0, 0, 0, 0);

                //Auto Calculation function
                // dbStartDate = values[indexVal - 1].StartDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                if (Log && Log[0] && Log[0].LogDays) {
                    dbStartDate = addDays(preStartDate, parseInt(Log[0].LogDays));
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "add");
                } else {
                    //Calculation Saturday,Sunday and Holiday Count
                    dbStartDate = checkDateBetweenInHolidayAndWE(preStartDate, parentItem.LeaveDays, titleEmployee1, "add");
                }
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                }

                dependencyMult.push({ Date: dbStartDate, depItem: depItem, depValue: "SS" });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem, depValue: "SS" });

            } else if (dependencyVal === "SE") {
                let preStartDate = subDays(commonPreStartDate, 1);
                let LogDate = preStartDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3)[0];
                LogDate.setHours(0, 0, 0, 0);
                dbEndDate.setHours(0, 0, 0, 0);
                if (LogDate <= dbEndDate && commonItem.EndDateChange === 2) {
                    //Skip
                } else {
                    //Auto Calculation function
                    if (Log && Log[0] && Log[0].LogDays) {
                        //LogDays Add
                        dbEndDate = addDays(preStartDate, parseInt(Log.LogDays));
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                    } else {
                        dbEndDate = checkDateBetweenInHolidayAndWE(preStartDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                    dbStartDate = subDays(dbEndDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                    for (let l = 0; l < total; l++) {
                        dbStartDate = subDays(dbStartDate, 1);
                        dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                }
                dependencyMult.push({ Date: dbStartDate, depItem: depItem, depValue: "SE" });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem, depValue: "SE" });

            } else if (dependencyVal === "ES") {
                //---- End-Start ----
                let preEndDate = commonPreEndDate;
                let LogDate = new Date();
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1)[0];
                LogDate.setHours(0, 0, 0, 0);
                preEndDate.setHours(0, 0, 0, 0);

                //Auto Calculation function
                dbStartDate = addDays(preEndDate, 1);
                if (Log && Log[0] && Log[0].LogDays) {
                    //LogDays Add
                    dbStartDate = addDays(dbStartDate, parseInt(Log.LogDays));
                }
                //Calculation Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "add");
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                }
                dependencyMult.push({ Date: dbStartDate, depItem: depItem, depValue: "ES" });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem, depValue: "ES" });

            } else if (dependencyVal === "EE") {
                let preEndDate = commonPreEndDate;
                let LogDate = preEndDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4)[0];
                LogDate.setHours(0, 0, 0, 0);
                dbEndDate.setHours(0, 0, 0, 0);
                //Auto Calculation function
                if (Log && Log[0] && Log[0].LogDays) {
                    //LogDays Add
                    dbEndDate = addDays(preEndDate, parseInt(Log.LogDays));
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                } else {
                    dbEndDate = checkDateBetweenInHolidayAndWE(preEndDate, parentItem.LeaveDays, titleEmployee1, "sub",);
                }

                dbStartDate = subDays(dbEndDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                for (let l = 0; l < total; l++) {
                    dbStartDate = subDays(dbStartDate, 1);
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "sub");
                }

                dependencyMult.push({ Date: dbStartDate, depItem: depItem, depValue: "EE" });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem, depValue: "EE" });
            }
        }

        catch (e) {
            console.log(e);
        }

    }

    //SKU Base Multiple Dependency Date add
    function addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId) {
        let splitVal = depItem.split('-');
        let indexVal = splitVal[0].trim();
        let dependencyVal = splitVal[1].trim();
        let hasSKUNotMatch = false;


        // Dependent base compare to  DetailTypeID
        let parentDetailTypeID = values[indexVal - 1].DetailTypeID;
        if (parentDetailTypeID === 1) { // DetailType => Common
            commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem,
                dependencyVal, indexVal, parentDetailTypeID, null, i, p, c)
        } else if (parentDetailTypeID === 2) { // DetailType => Sku
            let preChildDetailList = []; let preChildDetail = [];
            let TaskName = values[index].strTaskName;
            let hasSameItem = false;

            if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                hasSameItem = true;

            } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                hasSameItem = true;

            } else if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                hasSameItem = true;
            }

            if (hasSameItem) {
                let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                //let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                arySkuIdList && arySkuIdList.map((Sku) => {
                    values[indexVal - 1].SubTaskList.map((item) => {
                        let TNASkuIdList = item.ChildTaskList.filter(d => d.SubId === parseInt(Sku))[0];
                        TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                    });
                });
                preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                if (preChildDetail) {
                    if (preChildDetail) {
                        commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem,
                            dependencyVal, indexVal, parentDetailTypeID, preChildDetail, i, p, c)
                    }
                    else {
                        hasSKUNotMatch = true;
                        // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }
                }
            }
            else {
                preChildDetail = values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId)[0];

                if (preChildDetail) {
                    commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem,
                        dependencyVal, indexVal, parentDetailTypeID, preChildDetail, i, p, c)
                }
                else {
                    hasSKUNotMatch = true;
                    // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                }
            }
        } else if (parentDetailTypeID === 3) { // DetailType => Item
            let preChildDetailList = [];

            //let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
            let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
            arySkuIdList && arySkuIdList.map((Sku) => {
                values[indexVal - 1].SubTaskList.map((item) => {
                    let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(Sku.toString()))[0];

                    TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                    // if (TNASkuIdList && TNASkuIdList.length > 0) {

                    //     preChildDetailList = (TNASkuIdList && TNASkuIdList.length > 0) ? [preChildDetailList, ...TNASkuIdList] : preChildDetailList;
                    // }

                });
            });

            //Order by descending ( -1 : 1)
            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
            if (preChildDetail) {
                commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate,
                    commonItem, dependencyVal, indexVal, parentDetailTypeID, preChildDetail, i, p, c)
            } else {
                hasSKUNotMatch = true;
                // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }
        } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
            // let preChildDetailList = values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
            let SKUID = commonItem.SKUIdList !== '' ? parseInt(commonItem.SKUIdList) : SkuId;
            let preChildDetailList = values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SKUID); // Added on 23Apr2014 by karthik

            //Order by ASC a - b
            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
            if (preChildDetail) {
                commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate,
                    commonItem, dependencyVal, indexVal, parentDetailTypeID, preChildDetail, i, p, c)
            }
            else {
                hasSKUNotMatch = true;
                // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }

        }


    }



    //Multiple Dependency Date add
    function addMultDependencyDate(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, i, p, c) {
        let splitVal = depItem.split('-');
        let indexVal = splitVal[0].trim();
        let dependencyVal = splitVal[1].trim();
        let total = 0;
        let dbEndDate = new Date();
        let dbStartDate = new Date();
        let titleEmployee1 = parentItem.titleEmployee1;
        try {

            if (dependencyVal === "SS") {
                //---- Start-Start ----
                dbStartDate = values[indexVal - 1].StartDate;
                let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                if (LogDays && LogDays[0] && LogDays.length > 0) {
                    //LogDays Add
                    LogDays = LogDays[0].LogDays;
                    if (LogDays) {
                        dbStartDate = addDays(dbStartDate, parseInt(LogDays));
                    }
                }
                //Calculation Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "add");
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                }

                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });

            } else if (dependencyVal === "SE") {
                //---- Start-End ----
                dbEndDate = subDays(values[indexVal - 1].StartDate, 1);
                let LogDate = dbEndDate;
                let log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3)[0];
                //let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3);

                if (isEndDate === true && commonItem.EndDateChange !== 2) {
                    if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                        //LogDays Add
                        LogDate = addDays(LogDate, parseInt(log.LogDays));
                    }
                    LogDate.setHours(0, 0, 0, 0);
                    let newDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, titleEmployee1, "add");
                    newDate.setHours(0, 0, 0, 0);
                    if (LogDate <= newDate) {
                        let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, titleEmployee1, "add");
                        let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);

                        let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                        diffDayCount = diffDayCount - holidayTotal;
                        commonItem.Duration = diffDayCount;
                        // Duration = diffDayCount - 1;
                        dbStartDate = commonItem.StartDate;
                        dbEndDate = LastDate;
                        // Item.Duration = Duration;
                    } else {
                        dbStartDate = commonItem.StartDate;
                        dbEndDate = commonItem.PreEndDate;
                        commonItem.Duration = oldDuration;

                    }
                } else {
                    if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                        dbEndDate = addDays(dbEndDate, parseInt(log.LogDays));
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                    } else {
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                    dbStartDate = subDays(dbEndDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                    for (let l = 0; l < total; l++) {
                        dbStartDate = subDays(dbStartDate, 1);
                        dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                }
                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });

            } else if (dependencyVal === "ES") {
                //---- End-Start ----
                dbStartDate = addDays(values[indexVal - 1].EndDate, 1);
                let log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1)[0];
                //let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1);
                if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                    //LogDays Add
                    dbStartDate = addDays(dbStartDate, parseInt(log.LogDays));
                }

                //Calculation Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "add");
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                }
                // let flyEndDate = addDays(dbEndDate, total);
                // //Calculation Saturday,Sunday and Holiday Count
                // flyEndDate = checkDateBetweenInHolidayAndWE(flyEndDate, parentItem.LeaveDays, "add");
                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });

            } else if (dependencyVal === "EE") {
                //---- End-End ----
                dbEndDate = values[indexVal - 1].EndDate;
                let LogDate = dbEndDate;
                let log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4)[0];

                if (isEndDate === true && commonItem.EndDateChange !== 2) {
                    if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                        //LogDays Add
                        LogDate = addDays(LogDate, parseInt(log.LogDays));
                    }
                    LogDate.setHours(0, 0, 0, 0);
                    let newDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, titleEmployee1, "add");
                    newDate.setHours(0, 0, 0, 0);
                    if (LogDate <= newDate) {
                        let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, titleEmployee1, "add");
                        let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);
                        let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                        diffDayCount = diffDayCount - holidayTotal;
                        commonItem.Duration = diffDayCount;
                        dbStartDate = commonItem.StartDate;
                        dbEndDate = LastDate;
                    } else {
                        dbStartDate = commonItem.StartDate;
                        dbEndDate = commonItem.PreEndDate;
                        commonItem.Duration = oldDuration;
                    }
                } else {

                    // let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4);
                    if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                        //LogDays Add
                        dbEndDate = addDays(dbEndDate, parseInt(log.LogDays));
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                    } else {
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                    dbStartDate = subDays(dbEndDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                    for (let l = 0; l < total; l++) {
                        dbStartDate = subDays(dbStartDate, 1);
                        dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                }

                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });
            }
        }

        catch (e) {
            console.log(e);
        }

    }

    function diffDays(date, otherDate) {
        return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1;
    }
    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth();
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function subDays(date, days) {

        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function totalHolidayAndWE(startDate, endDate, leaveDays, taskHolderName, i, p, c, isParent) {

        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        let format = "MM/DD/YYYY";
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = getCompanyHolidayList.filter(d => d.taskHolderName === taskHolderName);
                let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        // let Leavetext = '';
        // if (total !== 0 && isParent === false) {
        //     if (leaveDays === 2) {
        //         Leavetext = "Saturday(" + totalSaturday + ") Sunday(" + totalSunday + ") Holiday(" + totalHoliday + ")";;
        //     } else if (leaveDays === 3) {
        //         Leavetext = "Saturday(" + totalSaturday + ") Sunday(" + totalSunday + ")";
        //     } else if (leaveDays === 4) {
        //         Leavetext = "Sunday(" + totalSunday + ")";
        //     } else if (leaveDays === 5) {
        //         Leavetext = "Holiday(" + totalHoliday + ")";
        //     } else if (leaveDays === 6) {
        //         Leavetext = "Sunday(" + totalSunday + ") Holiday(" + totalHoliday + ")";
        //     } else if (leaveDays === 7) {
        //         Leavetext = "Saturday(" + totalSaturday + ")";
        //     } else if (leaveDays === 8) {
        //         Leavetext = "Saturday(" + totalSaturday + ") Holiday(" + totalHoliday + ")";
        //     }
        //     values[i].SubTaskList[p].ChildTaskList[c].LeaveDaysTxt = Leavetext;
        // }
        // setInputFields(values);
        return total;
    }

    function leavedayscountcalc(startDate, endDate, leaveDays, taskHolderName, i, p, c, isParent) {

        let format = "MM/DD/YYYY";
        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = getCompanyHolidayList.filter(d => d.taskHolderName === taskHolderName);
                let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        let Leavetext = '';

        if (total !== 0 && isParent === false) {
            if (leaveDays === 2) {
                Leavetext = "Sat(" + totalSaturday + ") Sun(" + totalSunday + ") Hol(" + totalHoliday + ")";;
            } else if (leaveDays === 3) {
                Leavetext = "Sat(" + totalSaturday + ") Sun(" + totalSunday + ")";
            } else if (leaveDays === 4) {
                Leavetext = "Sun(" + totalSunday + ")";
            } else if (leaveDays === 5) {
                Leavetext = "Hol(" + totalHoliday + ")";
            } else if (leaveDays === 6) {
                Leavetext = "Sun(" + totalSunday + ") Hol(" + totalHoliday + ")";
            } else if (leaveDays === 7) {
                Leavetext = "Sat(" + totalSaturday + ")";
            } else if (leaveDays === 8) {
                Leavetext = "Sat(" + totalSaturday + ") Hol(" + totalHoliday + ")";
            }
            values[i].SubTaskList[p].ChildTaskList[c].LeaveDaysTxt = Leavetext;
        }

        setInputFields(values);
    }

    function checkDateBetweenInHolidayAndWE(flyDate, leaveDays, taskHolderName, status) {

        let format = "MM/DD/YYYY";
        let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
        let holidayList = getCompanyHolidayList.filter(d => d.taskHolderName === taskHolderName);
        let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
        //ReCheck Date in Saturday,Sunday and Holiday Count
        if (flyDate.getDay() === 0 || flyDate.getDay() === 6 || HolidayCount > 0) {
            let i = 0;
            while (i <= 3) {
                //Weekend Date Check
                if (flyDate.getDay() === 0 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 4 || leaveDays === 6)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }
                if (flyDate.getDay() === 6 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 7 || leaveDays === 8)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }

                if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                    let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                    let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
                    if (HolidayCount > 0) {
                        status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                    } else {
                        i++;
                    }
                } else {
                    i++;
                }

                i === 3 ? i = 4 : i = 0;
            }
        }
        return flyDate;
    }

    // #endregion   ------------- **** Calculation End ***** --------------------------

    //----Manual Close-------
    const handleManualclose = (index, TNARouteApplyID, Status) => {

        setdates(false)

        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            if (values[index].intDependency === 0) {
                let Dependency = false;
                if (values[index].SelectedDependency) {
                    let arrstatus = [];
                    arrstatus = values[index].SelectedDependency.split(',');
                    if (arrstatus.length > 0) {
                        arrstatus.forEach(element => {
                            let val = [];
                            val = element.split('-');
                            let valIndex = values[parseInt(val[0]) - 1].StatusID
                            //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID
                            if (valIndex !== 3) {
                                Dependency = true;
                            }


                        });
                    }
                    if (Dependency) {
                        // setComment(true);
                        Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
                    }
                    else {

                        let params = {
                            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                            SupplierId: getTNASupplierID, TaskType: 0, TaskNameId: values[index].TaskID,
                            TaskTodoInProgressCompletedStatus: Status !== 3 ? 4 : 5, StartDate: null, EndDate: null,
                            Operation: 8, ParentIdOrSubChildId: TNARouteApplyID, IsProduction: IsProduction
                        };

                        TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                            if (response.data) {
                                let data = response.data.map((item) => {
                                    if (item.subTaskInfoId === TNARouteApplyID) {
                                        item.isSelected = 1;
                                        return item;
                                    }
                                    return item;
                                    //[item['strikeofforTrimLogoId'], item.subTaskInfoId === ChildId ? (item = item.isSelected = 1) : item]
                                });
                                hideLoader();
                                let isSelectAll = 0;
                                if (data) {
                                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                                    isSelectAll = isSelectAll ? 1 : 0;
                                }

                                setselectall(isSelectAll)

                                if (Status !== 3) {
                                    setMultiCloseDatas(data);
                                }
                                else {
                                    setMultiCloseDatas(data.filter(x => x.subTaskInfoId === TNARouteApplyID));
                                }
                            }
                        }).catch(() => { hideLoader(); });
                        values[index].IsManual = 1;
                    }
                } else {

                    values[index].IsManual = 1;
                    let params = {
                        BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                        SupplierId: getTNASupplierID, TaskType: 0, TaskNameId: values[index].TaskID,
                        TaskTodoInProgressCompletedStatus: Status !== 3 ? 4 : 5, StartDate: null, EndDate: null,
                        Operation: 8, ParentIdOrSubChildId: TNARouteApplyID, IsProduction: IsProduction
                    };

                    TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                        if (response.data) {


                            let data = response.data.map((item) => {
                                if (item.subTaskInfoId === TNARouteApplyID) {
                                    item.isSelected = 1;
                                    return item;
                                }
                                return item;
                                //[item['strikeofforTrimLogoId'], item.subTaskInfoId === ChildId ? (item = item.isSelected = 1) : item]
                            });


                            hideLoader();

                            let isSelectAll = 0;
                            if (data) {
                                isSelectAll = !!!data.find(d => d.isSelected === 0);
                                isSelectAll = isSelectAll ? 1 : 0;
                            }

                            setselectall(isSelectAll)

                            if (Status !== 3) {
                                setMultiCloseDatas(data);
                            }
                            else {
                                setMultiCloseDatas(data.filter(x => x.subTaskInfoId === TNARouteApplyID));
                            }
                            setInputFields(values);

                        }
                    }).catch(() => { hideLoader(); });
                }
                setInputFields(values);

            }
            else {
                values[index].IsManual = 1;
                setInputFields(values);

            }

        }
    };

    const handleCloseManual = (index, cmmnt, Status) => {

        if (cmmnt !== '' && Status !== 3) {
            values[index].IsManual = 0;
            values[index].ManualComments = "";
        }
        else {
            values[index].IsManual = 0;

        }
        setInputFields(values);
        setmaincomment(false)
    };

    const handleCloseRevice = (index) => {

        values[index].RevicePopup = 0;
        setInputFields(values);
    };

    const handleManual = (index, assignValue, CloseDate) => {


        if (assignValue === '' || assignValue === undefined || CloseDate === '' || CloseDate === null) {
            setdates(true);
            setmaincomment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
        }
        // if (CloseDate === '' || CloseDate === null) {
        //     setdates(true)
        //     setmaincomment(true);
        //     Nodify('Warning!', 'warning', "Please enter Close Date");
        // }

        else {
            let strValue = "";
            let selected = 0;
            for (let data of MultiCloseDatas) {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.subTaskInfoId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.subTaskInfoId;
                    }
                }
            }
            if (selected === 0) {
                Nodify('Warning!', 'warning', "Select atleast one Task");
            }
            else {
                values[index].IsManualClose = 0;
                values[index].StatusID = 3;
                values[index].ManualCloseComment = assignValue;
                values[index].ManualCloseDate = new Date();
                values[index].ActualEndDate = new Date();
                values[index].Manual = '';
                values[index].IsManual = 0;


                var ManualCloseData = { ...MultiManualCloseFeilds };
                ManualCloseData.ManualCloseDate = new Date();
                ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // .setDate(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate.getDate() + 1);
                ManualCloseData.ManualCloseComment = assignValue;
                ManualCloseData.IdList = strValue;
                ManualCloseData.TaskStatus = 6;
                ManualCloseData.CreatedBy = currentUser.employeeinformationID;
                TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
                    if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', 'Task Manually Closed Successfully');
                    }
                })
                AutoDepandencyCloseonParentTaskClose(ManualCloseData.ManualCloseDate);
                setInputFields(values);
            }

        }

    }


    const handletrimmPopupClose = () => {
        setTrimmodalpopup(false);
        seteditTrimSubmission(false);
        setdeleteTrimSubmission(false);
        setTrimswatchDownload(false);
        setTrimswatchUpload(false);
    }
    const handleTrimappPopupClose = () => {

        setTrimApprovalpopup(false);
        seteditTrimApproval(false);
        setdeleteTrimApproval(false);
        setTrimswatchDownload(false);
        setTrimswatchUpload(false);
    }
    const handleLabDip = () => {
        setLapmodal(false);
        seteditLabdipSubmission(false);
        setdeleteLabdipSubmission(false);
        setLabdipswatchDownload(false);
        setLabdipswatchUpload(false);
    };

    const handleLabDipApp = () => {

        setOpenLabdipApppopup(false);
        seteditLabdipApproval(false);
        setdeleteLabdipApproval(false);
        setLabdipswatchDownload(false);
        setLabdipswatchUpload(false);

    }


    const handleStrikeoffSub = () => {
        setStrikeOffmodalpopup(false)
        seteditStrikeOffSubmission(false);
        setdeleteStrikeOffSubmission(false);
        setStrikeoffswatchDownload(false);
        setStrikeoffswatchUpload(false);
    }
    const handleStrikeOffApp = () => {
        setStrikeOffApppopup(false);
        seteditStrikeOffApproval(false);
        setdeleteStrikeOffApproval(false);
        setStrikeoffswatchDownload(false);
        setStrikeoffswatchUpload(false);
    }
    const handleprotoSubApp = () => {
        setSampleSubModalpopup(false);
        seteditProtoFitSmsSubmission(false);
        setdeleteProtoFitSmsSubmission(false);
    }
    const handleprotofitApp = () => {
        setSampleAppModalpopup(false);
        seteditProtoFitSmsApproval(false);
        setdeleteProtoFitSmsApproval(false)
    }
    const HandleAfterWashList = () => {
        setApperancemodalpopup(false);
        seteditAppearanceAfterWash(false);
        setdeleteAppearanceAfterWash(false);
    }



    const [TNAId, setTNAId] = useState();
    const [StyleId, setStyleId] = useState();
    const [LabdibFabricInfoId, setLabdibFabricInfoId] = useState();
    const [TNAStrikeOffRefInfoId, setTNAStrikeOffRefInfoId] = useState();
    const [TNATrimRefInfoId, setTNATrimRefInfoId] = useState();
    const [SubmittedOptionListId, setSubmittedOptionListId] = useState();
    const [ColorAppCommentsId, setColorAppCommentsId] = useState();
    const [TNAProtoFitId, setTNAProtoFitId] = useState();
    const [ParentTask, setParentTask] = useState('');
    const [ChildTask, setChildTask] = useState('');
    const [getTaskholder, setTaskholderlist] = useState([]);
    const [getnewlist, setnewlist] = useState();
    const [TaskStatus, setTaskStatus] = useState();
    const [showbutn, setshwbtn] = useState();
    const [SwatchStatus, setSwatchStatus] = useState();

    const [AppareanceTestId, setAppareanceTestId] = useState();
    const [Indexes, setIndexes] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0, FieldName: '', IsParent: 0 });
    const [commonSubmitApprovalParams, setCommonSubmitApprovalParams] = useState({ SelectedDependency: '', TaskID: 0, IsSingleTask: true, hasAllDependencyStart: 0 });

    const [AllChildvalues, setAllChildvalues] = useState([]);
    //check depandant tasks close
    function ChkAllDepandenctTasksClose(arrDependency) {

        let IsAllTasksClosed = true;
        let InCompleteTasks = '';
        if (arrDependency && arrDependency.length > 0) {
            let CurrentChildvalues = [];
            values.map((Main, MainIndex) => {
                if (Main.IsSingleTask === true) {
                    CurrentChildvalues.push({ ParentIndex: MainIndex + 1, ChildIndex: MainIndex + 1, StatusId: Main.StatusID, IsParent: 1, ChildId: Main.TNARouteApplyID })
                }
                Main.SubTaskList.map((Child) => {
                    Child.ChildTaskList.map((SubChild) => {
                        CurrentChildvalues.push({ ParentIndex: MainIndex + 1, ChildIndex: SubChild.ChildIndex, StatusId: SubChild.StatusID, IsParent: 0, ChildId: SubChild.ChildId })
                    })
                })
            })
            arrDependency.filter(x => x.DependencyID !== 0).map((depitem, i) => {
                if (CurrentChildvalues.filter(x => x.ChildId === depitem.DependentTaskId).length > 0) {
                    if (CurrentChildvalues.filter(x => x.ChildId === depitem.DependentTaskId)[0].StatusId !== 3) {
                        IsAllTasksClosed = false;
                        let InCompleterow = CurrentChildvalues.filter(x => x.ChildId === depitem.DependentTaskId)[0];
                        if (InCompleterow.IsParent === 0) {
                            if (InCompleteTasks === '') {
                                InCompleteTasks = InCompleterow.ParentIndex + "-" + InCompleterow.ChildIndex;
                            }
                            else {
                                InCompleteTasks = InCompleteTasks + "," + (InCompleterow.ParentIndex + "-" + InCompleterow.ChildIndex)
                            }
                        }
                        else {
                            if (InCompleteTasks === '') {
                                InCompleteTasks = InCompleterow.ParentIndex;
                            }
                            else {
                                InCompleteTasks = InCompleteTasks + "," + (InCompleterow.ParentIndex)
                            }
                        }
                    }
                }
            })
        }

        return InCompleteTasks;
    }


    const OpenLabdipSubmssionpopup = async (TNAId, LabdibFabricInfoId, StyleId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {
        //check all depandent tasks closed

        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);
        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {

            let Params = {
                Operation: 48,
                Id: LabdibFabricInfoId,
            };
            await CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    setLabdipdisable(true)
                    // let Title = "Warning!";
                    // let message = "Approval exists against this Submission.";
                    // let type = "warning";
                    // Nodify(Title, type, message);
                } else {
                    setLabdipdisable(false)
                }
            })
            let SelectedDependency = values[index].SelectedDependency;
            let TaskID = values[index].TaskID;
            let IsSingleTask = values[index].IsSingleTask;
            let hasAllDependencyStart = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseData = "";
            // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
            //     let Dependency = false;
            //     if (values[index].SelectedDependency) {
            //         let arrstatus = [];
            //         arrstatus = values[index].SelectedDependency.split(',');
            //         if (arrstatus.length > 0) {
            //             arrstatus.forEach(element => {
            //                 let val = [];
            //                 val = element.split('-');
            //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
            //                 if (valIndex !== 3) {
            //                     Dependency = true;
            //                 }
            //             });
            //         }
            //         if (Dependency && StatusId !== 3) {
            //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
            //         }
            //         else {
            //             // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
            //             setTNAId(TNAId);
            //             setLabdibFabricInfoId(LabdibFabricInfoId);
            //             setStyleId(StyleId);
            //             setTaskStatus(StatusId);
            //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
            //             setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //             setLapmodal(true);
            //             setSwatchStatus(SwatchStatus)
            //         }
            //     } else {
            //         //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
            //         setTNAId(TNAId);
            //         setLabdibFabricInfoId(LabdibFabricInfoId);
            //         setStyleId(StyleId);
            //         setTaskStatus(StatusId);
            //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
            //         setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //         setLapmodal(true);
            //         setSwatchStatus(SwatchStatus)
            //     }
            // }
            // else {

            let CurrentChildvalues = [];
            values.map((Main) => {
                Main.SubTaskList.map((Child) => {
                    Child.ChildTaskList.map((SubChild) => {
                        CurrentChildvalues.push({
                            StatusID: SubChild.StatusID,
                            ChildId: SubChild.ChildId,
                            IsDepandency: SubChild.IsDepandency,
                            Depandency: SubChild.SelectedDependency,
                            intDependency: SubChild.intDependency,
                        })
                    })
                })
            })

            setAllChildvalues(CurrentChildvalues);

            setTNAId(TNAId);
            setLabdibFabricInfoId(LabdibFabricInfoId);
            setStyleId(StyleId);
            setTaskStatus(StatusId);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
            setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            setLapmodal(true);
            setSwatchStatus(SwatchStatus)
            //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;

            // }


        }

    }

    const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {

        // TNAService.GetChildId(LabdibFabricInfoId, 1).then((response) => {
        //     if (response.data) {

        //         setSubmitChildId(response.data.outputResult);
        //     }
        // }).catch(() => { })

        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, IsProduction).then((response) => {
                if (response.data.length !== 0) {
                    // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
                    //     let Dependency = false;
                    //     if (values[index].SelectedDependency) {
                    //         let arrstatus = [];
                    //         arrstatus = values[index].SelectedDependency.split(',');
                    //         if (arrstatus.length > 0) {
                    //             arrstatus.forEach(element => {
                    //                 let val = [];
                    //                 val = element.split('-');
                    //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                    //                 if (valIndex !== 3) {
                    //                     Dependency = true;
                    //                 }
                    //             });
                    //         }
                    //         if (Dependency && StatusId !== 3) {
                    //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
                    //         }
                    //         else {
                    //             // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                    //             setLabdibFabricInfoId(LabdibFabricInfoId);
                    //             setSubmittedOptionListId(LabdibFabricInfoId);
                    //             setStyleId(StyleId);
                    //             setTaskStatus(StatusId);
                    //             setSwatchStatus(SwatchStatus)
                    //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //             TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, IsProduction).then((response) => {
                    //                 if (response.data) {
                    //                     setLabdipApprovalList(response.data);
                    //                     setOpenLabdipApppopup(true);
                    //                 }
                    //             }).catch(() => { })
                    //         }
                    //     } else {
                    //         //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                    //         setLabdibFabricInfoId(LabdibFabricInfoId);
                    //         setSubmittedOptionListId(LabdibFabricInfoId);
                    //         setStyleId(StyleId)
                    //         setTaskStatus(StatusId);
                    //         setSwatchStatus(SwatchStatus)
                    //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //         TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, IsProduction).then((response) => {
                    //             if (response.data) {
                    //                 setLabdipApprovalList(response.data);
                    //                 setOpenLabdipApppopup(true);
                    //             }
                    //         }).catch(() => { })
                    //     }
                    // }
                    // else {
                    setLabdibFabricInfoId(LabdibFabricInfoId);
                    setSubmittedOptionListId(LabdibFabricInfoId);
                    setStyleId(StyleId)
                    setTaskStatus(StatusId);
                    setSwatchStatus(SwatchStatus)
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, IsProduction).then((response) => {
                        if (response.data) {
                            setLabdipApprovalList(response.data);
                            setOpenLabdipApppopup(true);
                        }
                    }).catch(() => { })
                    // }
                }
                else {
                    Nodify('Warning!', 'warning', "Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });

        }
    }


    // const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
    //     setLabdibFabricInfoId(LabdibFabricInfoId);
    //     setSubmittedOptionListId(LabdibFabricInfoId);
    //     setStyleId(StyleId)
    //     setOpenLabdipApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenStrikeoffSubmssionpopup = async (TNAId, TNAStrikeOffRefInfoId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {

        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            let Params = {
                Operation: 49,
                Id: TNAStrikeOffRefInfoId,
            };
            await CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    setStrikeoffdisable(true)
                } else {
                    setStrikeoffdisable(false)
                }
            })
            let SelectedDependency = values[index].SelectedDependency;
            let TaskID = values[index].TaskID;
            let IsSingleTask = values[index].IsSingleTask;
            let hasAllDependencyStart = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseData = "";
            // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
            //     let Dependency = false;
            //     if (values[index].SelectedDependency && StatusId !== 3) {
            //         let arrstatus = [];
            //         arrstatus = values[index].SelectedDependency.split(',');
            //         if (arrstatus.length > 0) {
            //             arrstatus.forEach(element => {
            //                 let val = [];
            //                 val = element.split('-');
            //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
            //                 if (valIndex !== 3) {
            //                     Dependency = true;
            //                 }
            //             });
            //         }
            //         if (Dependency && StatusId !== 3) {
            //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
            //         }
            //         else {
            //             setTNAId(TNAId);
            //             setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
            //             setTaskStatus(StatusId);
            //             setSwatchStatus(SwatchStatus)
            //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
            //             setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //             setStrikeOffmodalpopup(true);
            //         }
            //     } else {
            //         setTNAId(TNAId);
            //         setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
            //         setTaskStatus(StatusId);
            //         setSwatchStatus(SwatchStatus)
            //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
            //         setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //         setStrikeOffmodalpopup(true);
            //     }
            // }
            // else {
            //     setTNAId(TNAId);
            //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
            //     setTaskStatus(StatusId);
            //     setSwatchStatus(SwatchStatus)
            //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
            //     setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //     setStrikeOffmodalpopup(true);

            // }
            let CurrentChildvalues = [];
            values.map((Main) => {
                Main.SubTaskList.map((Child) => {
                    Child.ChildTaskList.map((SubChild) => {
                        CurrentChildvalues.push({
                            StatusID: SubChild.StatusID,
                            ChildId: SubChild.ChildId,
                            IsDepandency: SubChild.IsDepandency,
                            Depandency: SubChild.SelectedDependency,
                            intDependency: SubChild.intDependency,
                        })
                    })
                })
            })

            setAllChildvalues(CurrentChildvalues);

            setTNAId(TNAId);
            setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
            setTaskStatus(StatusId);
            setSwatchStatus(SwatchStatus)
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
            setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            setStrikeOffmodalpopup(true);
        }

    }

    // const OpenStrikeoffSubmssionpopup = (TNAId, TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setStrikeOffmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {

        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            TnaService.StrikeoffSubmissionList(3, TNAStrikeOffRefInfoId, IsProduction).then((response) => {
                if (response.data.length !== 0) {
                    // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
                    //     let Dependency = false;
                    //     if (values[index].SelectedDependency && StatusId !== 3) {
                    //         let arrstatus = [];
                    //         arrstatus = values[index].SelectedDependency.split(',');
                    //         if (arrstatus.length > 0) {
                    //             arrstatus.forEach(element => {
                    //                 let val = [];
                    //                 val = element.split('-');
                    //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                    //                 if (valIndex !== 3) {
                    //                     Dependency = true;
                    //                 }
                    //             });
                    //         }
                    //         if (Dependency && StatusId !== 3) {
                    //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
                    //         }
                    //         else {
                    //             setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                    //             setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                    //             setColorAppCommentsId(TNAStrikeOffRefInfoId);
                    //             setTaskStatus(StatusId);
                    //             setSwatchStatus(SwatchStatus)
                    //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //             setStrikeOffApppopup(true);
                    //         }
                    //     } else {
                    //         setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                    //         setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                    //         setColorAppCommentsId(TNAStrikeOffRefInfoId);
                    //         setTaskStatus(StatusId);
                    //         setSwatchStatus(SwatchStatus)
                    //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //         setStrikeOffApppopup(true);
                    //     }
                    // }
                    // else {
                    setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                    setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                    setColorAppCommentsId(TNAStrikeOffRefInfoId);
                    setTaskStatus(StatusId);
                    setSwatchStatus(SwatchStatus)
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    setStrikeOffApppopup(true);

                    // }
                }
                else {
                    Nodify('Warning!', 'warning', "Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });
        }

    }

    // const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setSubmittedOptionListId(TNAStrikeOffRefInfoId);
    //     setColorAppCommentsId(TNAStrikeOffRefInfoId);
    //     setStrikeOffApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenTrimSubmssionpopup = async (TNAId, TNATrimRefInfoId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {
        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            let Params = {
                Operation: 50,
                Id: TNATrimRefInfoId,
            };
            await CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    setTrimdisable(true)
                } else {
                    setTrimdisable(false)
                }
            })
            let SelectedDependency = values[index].SelectedDependency;
            let TaskID = values[index].TaskID;
            let IsSingleTask = values[index].IsSingleTask;
            let hasAllDependencyStart = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseData = "";
            // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
            //     let Dependency = false;
            //     if (values[index].SelectedDependency) {
            //         let arrstatus = [];
            //         arrstatus = values[index].SelectedDependency.split(',');
            //         if (arrstatus.length > 0) {
            //             arrstatus.forEach(element => {
            //                 let val = [];
            //                 val = element.split('-');
            //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
            //                 if (valIndex !== 3) {
            //                     Dependency = true;
            //                 }
            //             });
            //         }
            //         if (Dependency && StatusId !== 3) {
            //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
            //         }
            //         else {
            //             setTNAId(TNAId);
            //             setTNATrimRefInfoId(TNATrimRefInfoId);
            //             setTaskStatus(StatusId);
            //             setSwatchStatus(SwatchStatus)
            //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
            //             setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //             setTrimmodalpopup(true);
            //         }
            //     } else {
            //         setTNAId(TNAId);
            //         setTNATrimRefInfoId(TNATrimRefInfoId);
            //         setTaskStatus(StatusId);
            //         setSwatchStatus(SwatchStatus)
            //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
            //         setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //         setTrimmodalpopup(true);
            //     }
            // }
            // else {
            let CurrentChildvalues = [];
            values.map((Main) => {
                Main.SubTaskList.map((Child) => {
                    Child.ChildTaskList.map((SubChild) => {
                        CurrentChildvalues.push({
                            StatusID: SubChild.StatusID,
                            ChildId: SubChild.ChildId,
                            IsDepandency: SubChild.IsDepandency,
                            Depandency: SubChild.SelectedDependency,
                            intDependency: SubChild.intDependency,
                        })
                    })
                })
            })

            setAllChildvalues(CurrentChildvalues);


            setTNAId(TNAId);
            setTNATrimRefInfoId(TNATrimRefInfoId);
            setTaskStatus(StatusId);
            setSwatchStatus(SwatchStatus)
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
            setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            setTrimmodalpopup(true);

            // }
        }

    }



    const OpenTrimApprovalpopup = (TNATrimRefInfoId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            TnaService.TrimSubmissionList(3, TNATrimRefInfoId, IsProduction).then((response) => {
                if (response.data.length !== 0) {
                    // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
                    //     let Dependency = false;
                    //     if (values[index].SelectedDependency) {
                    //         let arrstatus = [];
                    //         arrstatus = values[index].SelectedDependency.split(',');
                    //         if (arrstatus.length > 0) {
                    //             arrstatus.forEach(element => {
                    //                 let val = [];
                    //                 val = element.split('-');
                    //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                    //                 if (valIndex !== 3) {
                    //                     Dependency = true;
                    //                 }
                    //             });
                    //         }
                    //         if (Dependency && StatusId !== 3) {
                    //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
                    //         }
                    //         else {
                    //             setTNATrimRefInfoId(TNATrimRefInfoId);
                    //             setSubmittedOptionListId(TNATrimRefInfoId);
                    //             setColorAppCommentsId(TNATrimRefInfoId);
                    //             setTaskStatus(StatusId);
                    //             setSwatchStatus(SwatchStatus)
                    //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //             setTrimApprovalpopup(true);
                    //         }
                    //     } else {
                    //         setTNATrimRefInfoId(TNATrimRefInfoId);
                    //         setSubmittedOptionListId(TNATrimRefInfoId);
                    //         setColorAppCommentsId(TNATrimRefInfoId);
                    //         setTaskStatus(StatusId);
                    //         setSwatchStatus(SwatchStatus)
                    //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
                    //         setTrimApprovalpopup(true);
                    //     }
                    // }
                    // else {
                    setTNATrimRefInfoId(TNATrimRefInfoId);
                    setSubmittedOptionListId(TNATrimRefInfoId);
                    setColorAppCommentsId(TNATrimRefInfoId);
                    setTaskStatus(StatusId);
                    setSwatchStatus(SwatchStatus)
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
                    setTrimApprovalpopup(true);

                    // }
                }
                else {
                    Nodify('Warning!', 'warning', "Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });
        }

    }



    const OpenProtoFitSamplepopup = async (TNAId, TNAProtoFitId, index, subIndex, childIndex, ChildTaskName, ParentTask, Styleid, StatusId, arrDependency, intDependency, Taskholderlist) => {
        //check all depandent tasks closed


        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            let Params = {
                Operation: 51,
                Id: TNAProtoFitId,
            };
            await CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    setprotofitsmsDisable(true)
                } else {
                    setprotofitsmsDisable(false)
                }
            })
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseData = "";
            // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
            //     let Dependency = false;
            //     if (values[index].SelectedDependency) {
            //         let arrstatus = [];
            //         arrstatus = values[index].SelectedDependency.split(',');
            //         if (arrstatus.length > 0) {
            //             arrstatus.forEach(element => {
            //                 let val = [];
            //                 val = element.split('-');
            //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
            //                 if (valIndex !== 3) {
            //                     Dependency = true;
            //                 }
            //             });
            //         }
            //         if (Dependency && StatusId !== 3) {
            //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
            //         }
            //         else {
            //             setTNAId(TNAId);
            //             setTNAProtoFitId(TNAProtoFitId);
            //             setChildTask(ChildTaskName);
            //             setParentTask(ParentTask);
            //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //             setStyleId(Styleid);
            //             setTaskStatus(StatusId);
            //             setSampleSubModalpopup(true);
            //         }
            //     } else {
            //         setTNAId(TNAId);
            //         setTNAProtoFitId(TNAProtoFitId);
            //         setChildTask(ChildTaskName);
            //         setParentTask(ParentTask);
            //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //         setStyleId(Styleid);
            //         setTaskStatus(StatusId);
            //         setSampleSubModalpopup(true);
            //     }
            // }
            // else {
            setnewlist(Taskholderlist)
            setTNAId(TNAId);
            setTNAProtoFitId(TNAProtoFitId);
            setChildTask(ChildTaskName);
            setParentTask(ParentTask);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            setStyleId(Styleid);
            setTaskStatus(StatusId);
            setSampleSubModalpopup(true);


            //}
        }

    }


    const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex, ParentTask, StatusId, Operation, arrDependency, intDependency) => {
        //check all depandent tasks closed

        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            TnaService.GetProtoFitSMSSubmission(Operation, TNAProtoFitId).then((response) => {

                if (response.data.length !== 0) {
                    // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
                    //     let Dependency = false;
                    //     if (values[index].SelectedDependency) {
                    //         let arrstatus = [];
                    //         arrstatus = values[index].SelectedDependency.split(',');
                    //         if (arrstatus.length > 0) {
                    //             arrstatus.forEach(element => {
                    //                 let val = [];
                    //                 val = element.split('-');
                    //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                    //                 if (valIndex !== 3) {
                    //                     Dependency = true;
                    //                 }
                    //             });
                    //         }
                    //         if (Dependency && StatusId !== 3) {
                    //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
                    //         }
                    //         else {
                    //             setTNAProtoFitId(TNAProtoFitId);
                    //             setParentTask(ParentTask);
                    //             setTaskStatus(StatusId);
                    //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //             setdeleteProtoFitSmsApproval(false)
                    //             setSampleAppModalpopup(true);
                    //         }
                    //     } else {
                    //         setTNAProtoFitId(TNAProtoFitId);
                    //         setParentTask(ParentTask);
                    //         setTaskStatus(StatusId);
                    //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //         setdeleteProtoFitSmsApproval(false)
                    //         setSampleAppModalpopup(true);
                    //     }
                    // }
                    // else {
                    setTNAProtoFitId(TNAProtoFitId);
                    setParentTask(ParentTask);
                    setTaskStatus(StatusId);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    setdeleteProtoFitSmsApproval(false)
                    setSampleAppModalpopup(true);
                    // }
                }
                else {
                    Nodify('Warning!', 'warning', "Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });
        }

    }


    const OpenAppearenceAfterWashpopup = (TNAId, AppareanceTestId, StyleId, index, subIndex, childIndex, StatusId, IsShowSubmitButton, arrDependency, intDependency) => {
        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
            //     let Dependency = false;
            //     if (values[index].SelectedDependency) {
            //         let arrstatus = [];
            //         arrstatus = values[index].SelectedDependency.split(',');
            //         if (arrstatus.length > 0) {
            //             arrstatus.forEach(element => {
            //                 let val = [];
            //                 val = element.split('-');
            //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
            //                 if (valIndex !== 3) {
            //                     Dependency = true;
            //                 }
            //             });
            //         }
            //         if (Dependency && StatusId !== 3) {
            //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
            //         }
            //         else {
            //             setTNAId(TNAId);
            //             setAppareanceTestId(AppareanceTestId);
            //             setStyleId(StyleId)
            //             setTaskStatus(StatusId);
            //             setshwbtn(IsShowSubmitButton)
            //             setApperancemodalpopup(true);
            //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //         }
            //     } else {
            //         setTNAId(TNAId);
            //         setAppareanceTestId(AppareanceTestId);
            //         setStyleId(StyleId)
            //         setTaskStatus(StatusId);
            //         setshwbtn(IsShowSubmitButton)
            //         setApperancemodalpopup(true);
            //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //     }
            // }
            // else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseData = "";
            setTNAId(TNAId);
            setAppareanceTestId(AppareanceTestId);
            setStyleId(StyleId)
            setTaskStatus(StatusId);
            setshwbtn(IsShowSubmitButton)
            setApperancemodalpopup(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            // }
        }

    }
    const resetAllToggles = () => {
        // Query all toggle buttons and icons
        const btnToggles = document.querySelectorAll(".btn-toggle");
        const iconToggles = document.querySelectorAll(".fa");

        // Loop through each toggle button and reset the state
        btnToggles.forEach(btnToggle => {
            btnToggle.setAttribute('aria-expanded', 'false');
        });

        // Loop through each icon and reset it to the plus icon
        iconToggles.forEach(iconToggle => {
            if (iconToggle.classList.contains("fa-minus-square-o")) {
                iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            }
        });
    };
    const delCallback = (value) => {

        if (value === true) {
            dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
            //dispatch(loadTNASupplierEmployeeList(getTNASupplierID));
            resetAllToggles()
        } else {
            setIDSingleTask({ showPopupDelete: false, Params: [] });
        }
    }

    function delQaCallback(value) {

        if (value === true) {
            //dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
            // dispatch(loadTNASupplierEmployeeList(getTNASupplierID));
        } else {
            setIDQATask({ showPopupDelete: false, Params: [] });
        }
    }

    const DeleteCallback = (value) => {


        // dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
        // dispatch(loadTNASupplierEmployeeList(getTNASupplierID));

        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsManualClose = 0;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 1;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseComment = '';
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = null;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = null;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = null;
            setsID({ showPopupDelete: false, Params: [] });
            //values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsManualClose = 0;

            IsParentDateShow = DateAffectDelete(values, Indexes, IsParentDateShow);
        } else {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsManualClose = 1;
            setsID({ showPopupDelete: false, Params: [] });
        }
        // values[index].SubTaskList[subIndex].ChildTaskList[index1].intDependency = element.dependencyStartstop;
        // values[index].SubTaskList[subIndex].ChildTaskList[index1].DependencyComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyComment;
        // values[index].SubTaskList[subIndex].ChildTaskList[index1].DependencyDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyDate;
        AutoDepandencyCloseonParentTaskClose('');
        setInputFields(values);

    }
    const DeleteCallbacks = (value) => {

        if (value === true) {
            values[Indexes.Index].IsManualClose = 0;
            values[Indexes.Index].StatusID = 1;
            values[Indexes.Index].ManualComments = '';
            values[Indexes.Index].ManualCloseDate = null;
            values[Indexes.Index].ChildActualEndDate = null;
            values[Indexes.Index].ActualEndDate = '';
            setID({ showPopupDelete: false, Params: [] });
            //IsParentDateShow = DateAffectDelete(values, Indexes, IsParentDateShow);
            //AutoDepandencyCloseonParentTaskClose('');
            values[Indexes.Index].IsManual = 0;
        } else {
            setID({ showPopupDelete: false, Params: [] });
            values[Indexes.Index].IsManual = 1;
        }
        AutoDepandencyCloseonParentTaskClose('');
        setInputFields(values);
    }
    // const handledelete = (index, subIndex, childIndex, val) => {
    //
    //     if (val === 'Parent') {
    //         values[index].IsManual = 0;
    //         setID({ TNAManualClose: TNAManualCloseParent, showPopupDelete: true, Params: values[index].TNARouteApplyID });
    //     }
    //     else {
    //         let ManualTaskName;
    //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
    //         //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //         Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

    //         // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
    //         if(val === 81 || val === 82)
    //         {ManualTaskName = TNAManualCloseChildLibDip;}
    //         else   if(val === 59 || val === 60 || val === 68 || val === 69 || val === 78 || val === 79 || val === 80)
    //         {ManualTaskName = TNAManualCloseChildTrim;}
    //         else   if(val === 61 || val === 62 || val === 63 || val === 64 || val === 65)
    //         {ManualTaskName = TNAManualCloseChildStrikeOff;}
    //         else   if(val === 56 || val === 77 || val === 66 || val === 67|| val === 70 || val === 73 || val === 74)
    //         {ManualTaskName = TNAManualCloseChildSms;}
    //         else   if(val === 57 || val === 58 || val === 71 || val === 72)
    //         {ManualTaskName = TNAManualCloseChildProtoFit;}
    //         setID({TNAManualClose:ManualTaskName, showPopupDelete: true, Params: Value });

    //     }


    //  }

    //---------------Commonly used for manual delete but now not in use----------------------
    // const handledelete = (index, subIndex, childIndex, val, childid) => {

    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
    //     let Value = [];
    //     Value.TNAId = props.location.state.params.tnaid;
    //     if (val === 'Parent') {
    //         values[index].IsManual = 0;
    //         Value.Id = values[index].TNARouteApplyID;
    //         setID({ TNAManualCloses: TNAManualCloseParent, showPopupDelete: true, Params: Value });
    //     }
    //     else {

    //         let ManualTaskName;
    //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
    //         //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //         if (values[index].DetailTypeID === 4) {
    //             Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //         } else {
    //             Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;
    //         }


    //         // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
    //         if (val === 81 || val === 82) { ManualTaskName = TNAManualCloseChildLibDip; }
    //         else if (val === 59 || val === 60 || val === 68 || val === 69 || val === 78 || val === 79 || val === 80) { ManualTaskName = TNAManualCloseChildTrim; }
    //         else if (val === 61 || val === 62 || val === 63 || val === 64 || val === 65) { ManualTaskName = TNAManualCloseChildStrikeOff; }
    //         else if (val === 56 || val === 77 || val === 66 || val === 67 || val === 70 || val === 73 || val === 74) { ManualTaskName = TNAManualCloseChildSms; }
    //         else if (val === 57 || val === 58 || val === 71 || val === 72) { ManualTaskName = TNAManualCloseChildProtoFit; }
    //         else if (values[index].DetailTypeID === 4)// Ex-Factory  (Detail Type)
    //         { ManualTaskName = TNAManualCloseChildExFactory; }

    //         setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });

    //     }


    // }
    //---------------currently used for manual delete its check approval against submission ----------------------
    const handledelete = (index, subIndex, childIndex, val, childid, Taskname) => {

        setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
        let Value = [];
        Value.TNAId = props.location.state.params.tnaid;

        if (val === 'Parent') {
            values[index].IsManual = 0;
            Value.Id = values[index].TNARouteApplyID;
            setID({ TNAManualCloses: TNAManualCloseParent, showPopupDelete: true, Params: Value });
        }
        else {

            let ManualTaskName;
            //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
            if (values[index].DetailTypeID === 4 || Taskname.toLowerCase() === StaticTask.STRIKEOFF_TEST ||
                Taskname.toLowerCase() === StaticTask.TRIMS_TEST || Taskname.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION ||
                Taskname.toLowerCase() === StaticTask.LAB_DIP_APPROVAL || Taskname.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                Taskname.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || Taskname.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                Taskname.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION
                || Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL
                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION
                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || Taskname.toLowerCase() === StaticTask.SMS_APPROVAL
                || Taskname.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                || Taskname.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || Taskname.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
                || Taskname.toLowerCase() === StaticTask.SMS_SUBMISSION || Taskname.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
                || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                || Taskname.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || Taskname.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                || Taskname.toLowerCase() === StaticTask.PHOTO_SUBMISSION || Taskname.toLowerCase() === StaticTask.PHOTO_APPROVAL
                || Taskname.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || Taskname.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                || Taskname.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED_SKU) {
                Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
            }
            else {
                Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;
            }


            let IsValid = false;
            if (Taskname === 'Lab Dip Submission' || Taskname === 'Strike off Submission (Local Development)'
                || Taskname === 'Trim Submission (Local Development)' || Taskname === 'Proto Sample Submission' || Taskname === "Fit Sample Submission"
                || Taskname === "SMS Submission" || Taskname === "Size Set Sample Submission"
                || Taskname === "Pre Production Sample Submission"
                || Taskname === "Shipment Sample Submission"
                || Taskname === "Photo Sample Submission") {
                if (Taskname === 'Lab Dip Submission') {
                    let Params = {
                        Operation: 52,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);

                            // setexistsubmission(true)
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";

                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            //Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION ||
                                Taskname.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) { ManualTaskName = TNAManualCloseChildLibDip; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
                else if (Taskname === 'Strike off Submission (Local Development)') {
                    let Params = {
                        Operation: 53,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";
                            // setexistsubmission(true)
                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            //Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                Taskname.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT ||
                                Taskname.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER ||
                                Taskname.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                Taskname.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) { ManualTaskName = TNAManualCloseChildStrikeOff; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
                else if (Taskname === 'Trim Submission (Local Development)') {
                    let Params = {
                        Operation: 54,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";
                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            //Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                                || Taskname.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                || Taskname.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT
                                || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER
                                || Taskname.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER
                            ) { ManualTaskName = TNAManualCloseChildTrim; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
                else if (Taskname === 'Proto Sample Submission') {
                    let Params = {
                        Operation: 55,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";
                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            // Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION
                                || Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION
                                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) { ManualTaskName = TNAManualCloseChildProtoFit; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
                else if (Taskname === "Fit Sample Submission") {
                    let Params = {
                        Operation: 56,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";
                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            // Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;
                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION
                                || Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION
                                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) { ManualTaskName = TNAManualCloseChildProtoFit; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
                else if (Taskname === "SMS Submission"
                    || Taskname === "Size Set Sample Submission"
                    || Taskname === "Pre Production Sample Submission"
                    || Taskname === "Shipment Sample Submission"
                    || Taskname === "Photo Sample Submission") {
                    let Params = {
                        Operation: 57,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";
                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            // Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.SMS_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED_SKU
                                || Taskname.toLowerCase() === StaticTask.SMS_SUBMISSION
                                || Taskname.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
                                || Taskname.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
                                || Taskname.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                                || Taskname.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                                || Taskname.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE
                                || Taskname.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || Taskname.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || Taskname.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.PHOTO_SUBMISSION || Taskname.toLowerCase() === StaticTask.PHOTO_APPROVAL) { ManualTaskName = TNAManualCloseChildSms; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
            }
            else {
                let ManualTaskName;
                //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                //Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                if (Taskname.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION ||
                    Taskname.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) { ManualTaskName = TNAManualCloseChildLibDip; }
                else if (Taskname.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                    || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                    || Taskname.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                    || Taskname.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT
                    || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER
                    || Taskname.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                    || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER
                ) { ManualTaskName = TNAManualCloseChildTrim; }
                else if (Taskname.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) { ManualTaskName = TNAManualCloseChildStrikeOff; }
                else if (Taskname.toLowerCase() === StaticTask.SMS_APPROVAL
                    || Taskname.toLowerCase() === StaticTask.SMS_SUBMISSION
                    || Taskname.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED_SKU
                    || Taskname.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
                    || Taskname.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
                    || Taskname.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                    || Taskname.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                    || Taskname.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE
                    || Taskname.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || Taskname.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                    || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                    || Taskname.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || Taskname.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                    || Taskname.toLowerCase() === StaticTask.PHOTO_SUBMISSION || Taskname.toLowerCase() === StaticTask.PHOTO_APPROVAL) { ManualTaskName = TNAManualCloseChildSms; }
                else if (Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION
                    || Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL
                    || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION
                    || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) { ManualTaskName = TNAManualCloseChildProtoFit; }
                else if (values[index].DetailTypeID === 4)// Ex-Factory  (Detail Type)
                { ManualTaskName = TNAManualCloseChildExFactory; }
                else if (Taskname.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                    ManualTaskName = TNAManualCloseChildStrikeoffTest;
                }
                else if (Taskname.toLowerCase() === StaticTask.TRIMS_TEST) {
                    ManualTaskName = TNAManualCloseChildTrimTest;
                }
                else if (Taskname.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || Taskname.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                    ManualTaskName = TNAManualCloseChildLibDip;
                }
                else if (Taskname.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                    ManualTaskName = TNAManualCloseChildStrikeOff;
                }
                else if (Taskname.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                    ManualTaskName = TNAManualCloseChildStrikeOff;
                }
                else if (Taskname.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                    Taskname.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
                    ManualTaskName = TNAManualCloseChildTrim;
                }
                setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
            }

        }


    }
    // const handledelete = (index, subIndex, childIndex, val, childid, Taskname) => {
    //
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
    //     let Value = [];
    //     Value.TNAId = props.location.state.params.tnaid;
    //     if (val === 'Parent') {
    //         values[index].IsManual = 0;
    //         Value.Id = values[index].TNARouteApplyID;
    //         setID({ TNAManualCloses: TNAManualCloseParent, showPopupDelete: true, Params: Value });
    //     }
    //     else {
    //         let IsValid = false;
    //         if (Taskname === 'Lab Dip Submission' || Taskname === 'Strike off Submission (Local Development)'
    //             || Taskname === 'Trim Submission (Local Development)' || Taskname === 'Proto Sample Submission' || Taskname === "Fit Sample Submission"
    //             || Taskname === "SMS Submission") {
    //             if (Taskname === 'Lab Dip Submission') {
    //                 let Params = {
    //                     Operation: 52,
    //                     Id: childid,
    //                 };
    //                 CommonService.CheckMappedItem(Params).then(res => {
    //                     if (res.data.outputResult === "-2") {
    //                         // setchkDataExists(true);
    //                         let Title = "Warning!";
    //                         let message = "Approval exists against this Submission.";
    //                         let type = "warning";
    //                         Nodify(Title, type, message);
    //                     }
    //                     else {
    //                         let ManualTaskName;
    //                         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
    //                         //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //                         Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

    //                         // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
    //                         if (val === 81 || val === 82) { ManualTaskName = TNAManualCloseChildLibDip; }
    //                         setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
    //                     }
    //                 })
    //             }
    //             else if (Taskname === 'Strike off Submission (Local Development)') {
    //                 let Params = {
    //                     Operation: 53,
    //                     Id: childid,
    //                 };
    //                 CommonService.CheckMappedItem(Params).then(res => {
    //                     if (res.data.outputResult === "-2") {
    //                         // setchkDataExists(true);
    //                         let Title = "Warning!";
    //                         let message = "Approval exists against this Submission.";
    //                         let type = "warning";
    //                         Nodify(Title, type, message);
    //                     }
    //                     else {
    //                         let ManualTaskName;
    //                         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
    //                         //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //                         Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

    //                         // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
    //                         if (val === 61 || val === 62 || val === 63 || val === 64 || val === 65) { ManualTaskName = TNAManualCloseChildStrikeOff; }
    //                         setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
    //                     }
    //                 })
    //             }
    //             else if (Taskname === 'Trim Submission (Local Development)') {
    //                 let Params = {
    //                     Operation: 54,
    //                     Id: childid,
    //                 };
    //                 CommonService.CheckMappedItem(Params).then(res => {
    //                     if (res.data.outputResult === "-2") {
    //                         // setchkDataExists(true);
    //                         let Title = "Warning!";
    //                         let message = "Approval exists against this Submission.";
    //                         let type = "warning";
    //                         Nodify(Title, type, message);
    //                     }
    //                     else {
    //                         let ManualTaskName;
    //                         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
    //                         //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //                         Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

    //                         // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
    //                         if (val === 59 || val === 60 || val === 68 || val === 69 || val === 78 || val === 79 || val === 80) { ManualTaskName = TNAManualCloseChildTrim; }
    //                         setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
    //                     }
    //                 })
    //             }
    //             else if (Taskname === 'Proto Sample Submission') {
    //                 let Params = {
    //                     Operation: 55,
    //                     Id: childid,
    //                 };
    //                 CommonService.CheckMappedItem(Params).then(res => {
    //                     if (res.data.outputResult === "-2") {
    //                         // setchkDataExists(true);
    //                         let Title = "Warning!";
    //                         let message = "Approval exists against this Submission.";
    //                         let type = "warning";
    //                         Nodify(Title, type, message);
    //                     }
    //                     else {
    //                         let ManualTaskName;
    //                         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
    //                         //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //                         Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

    //                         // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
    //                         if (val === 57 || val === 58 || val === 71 || val === 72) { ManualTaskName = TNAManualCloseChildProtoFit; }
    //                         setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
    //                     }
    //                 })
    //             }
    //             else if (Taskname === "Fit Sample Submission") {
    //                 let Params = {
    //                     Operation: 56,
    //                     Id: childid,
    //                 };
    //                 CommonService.CheckMappedItem(Params).then(res => {
    //                     if (res.data.outputResult === "-2") {
    //                         // setchkDataExists(true);
    //                         let Title = "Warning!";
    //                         let message = "Approval exists against this Submission.";
    //                         let type = "warning";
    //                         Nodify(Title, type, message);
    //                     }
    //                     else {
    //                         let ManualTaskName;
    //                         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
    //                         //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //                         Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

    //                         // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
    //                         if (val === 57 || val === 58 || val === 71 || val === 72) { ManualTaskName = TNAManualCloseChildProtoFit; }
    //                         setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
    //                     }
    //                 })
    //             }
    //             else if (Taskname === "SMS Submission") {
    //                 let Params = {
    //                     Operation: 57,
    //                     Id: childid,
    //                 };
    //                 CommonService.CheckMappedItem(Params).then(res => {
    //                     if (res.data.outputResult === "-2") {
    //                         // setchkDataExists(true);
    //                         let Title = "Warning!";
    //                         let message = "Approval exists against this Submission.";
    //                         let type = "warning";
    //                         Nodify(Title, type, message);
    //                     }
    //                     else {
    //                         let ManualTaskName;
    //                         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
    //                         //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //                         Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

    //                         // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
    //                         if (val === 56 || val === 77 || val === 66 || val === 67 || val === 70 || val === 73 || val === 74) { ManualTaskName = TNAManualCloseChildSms; }
    //                         setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
    //                     }
    //                 })
    //             }
    //         }
    //         else {
    //             let ManualTaskName;
    //             values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
    //             //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //             Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

    //             // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
    //             if (val === 81 || val === 82) { ManualTaskName = TNAManualCloseChildLibDip; }
    //             else if (val === 59 || val === 60 || val === 68 || val === 69 || val === 78 || val === 79 || val === 80) { ManualTaskName = TNAManualCloseChildTrim; }
    //             else if (val === 61 || val === 62 || val === 63 || val === 64 || val === 65) { ManualTaskName = TNAManualCloseChildStrikeOff; }
    //             else if (val === 56 || val === 77 || val === 66 || val === 67 || val === 70 || val === 73 || val === 74) { ManualTaskName = TNAManualCloseChildSms; }
    //             else if (val === 57 || val === 58 || val === 71 || val === 72) { ManualTaskName = TNAManualCloseChildProtoFit; }
    //             setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
    //         }
    //     }


    // }
    function setdeleteLabdip() {

        setdeleteLabdipSubmission(true)
        if (editLabdipSubmission) {
            setdeleteLabdipSubmission(true)
            //seteditLabdipSubmission(false)
        }
    }
    const LapdipSubmissionCallback = (value, getOrderinfo, Operation, SwatchStatus) => {
        if (value === true) {
            setLapmodal(false);
            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.labdibFabricInfoId === element1.ChildId) {

                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        //childDate = convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate));
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate).toLocaleString();

                    }
                })

                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })
            if (SwatchStatus !== 0) {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = parseInt(SwatchStatus);
            }
            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 0;

            }
            //  loadCommonDependencyButtonStartstop();
            seteditLabdipSubmission(false);
            setdeleteLabdipSubmission(false);
            setLabdipswatchDownload(false);
            setLabdipswatchUpload(false);
            // dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));

            AutoDepandencyCloseonParentTaskClose(childDate);
            setInputFields(values);
        }
        else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = new Date();
            // })
            // loadCommonDependencyButtonStartstop();
            setexistsubmission(true);
            seteditLabdipSubmission(false);
            setdeleteLabdipSubmission(false);
            setLabdipswatchDownload(false);
            setLabdipswatchUpload(false);
            // setInputFields(values);
        }
    }

    const LapdipApprovalCallback = (value, getOrderinfo, Operation, swatchstatus) => {
        if (value === true) {
            setOpenLabdipApppopup(false);
            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.labdibFabricInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        // childDate = convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate));
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate).toLocaleString();
                    }
                })

                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })
            if (Operation === 'upload') {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 3;
            }
            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = swatchstatus;
            }
            seteditLabdipApproval(false);
            setdeleteLabdipApproval(false);
            setLabdipswatchDownload(false);
            setLabdipswatchUpload(false);

            AutoDepandencyCloseonParentTaskClose(childDate);
            setInputFields(values);
        } else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // })
            seteditLabdipApproval(false);
            setdeleteLabdipApproval(false);
            setLabdipswatchDownload(false);
            setLabdipswatchUpload(false);
            // setInputFields(values);
        }
    }

    const StrikeoffSubmissionCallback = (value, getOrderinfo, Operation, SwatchStatus) => {

        if (value === true) {
            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.tnaStrikeOffRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        //childDate = convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate));
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate).toLocaleString();
                    }
                })

                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })

            if (SwatchStatus !== 0) {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = parseInt(SwatchStatus);
            }
            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 0;

            }
            //loadCommonDependencyButtonStartstop();


            setdeleteStrikeOffSubmission(false);
            seteditStrikeOffSubmission(false);
            setStrikeOffmodalpopup(false);
            setStrikeoffswatchDownload(false);
            setStrikeoffswatchUpload(false);
            // setexistsubmission(false);
            // dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
            AutoDepandencyCloseonParentTaskClose(childDate);
            setInputFields(values);
        } else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = Operation === 'Delete' ? 2 : 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // })
            // loadCommonDependencyButtonStartstop();
            setexistsubmission(true);
            setdeleteStrikeOffSubmission(false);
            seteditStrikeOffSubmission(false);
            //setStrikeOffmodalpopup(false);
            setStrikeoffswatchDownload(false);
            setStrikeoffswatchUpload(false);
            // setInputFields(values);
        }
    }

    const StrikeoffApprovalCallback = (value, getOrderinfo, Operation, Swatchstatus) => {
        if (value === true) {
            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.tnaStrikeOffRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        // childDate = convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate));
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate).toLocaleString();
                    }
                })

                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })

            if (Operation === 'upload') {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 3;
            }

            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = Swatchstatus;
            }

            setdeleteStrikeOffApproval(false);
            seteditStrikeOffApproval(false);
            setStrikeOffApppopup(false);
            setStrikeoffswatchDownload(false);
            setStrikeoffswatchUpload(false);
            AutoDepandencyCloseonParentTaskClose(childDate);
            setInputFields(values);
        } else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // })
            // setInputFields(values);
            setdeleteStrikeOffApproval(false);
            seteditStrikeOffApproval(false);
            //setStrikeOffApppopup(false);
            setStrikeoffswatchDownload(false);
            setStrikeoffswatchUpload(false);
        }
    }

    const TrimSubmissionCallback = (value, getOrderinfo, Operation, SwatchStatus) => {
        if (value === true) {
            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.tnaTrimRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        // childDate = convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate));
                        //childDate = convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate));
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate).toLocaleString();
                    }
                })

                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })

            if (SwatchStatus !== 0) {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = parseInt(SwatchStatus);
            }
            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 0;

            }

            // loadCommonDependencyButtonStartstop();
            setdeleteTrimSubmission(false);
            seteditTrimSubmission(false);
            setTrimmodalpopup(false);
            setTrimswatchDownload(false);
            setTrimswatchUpload(false);
            AutoDepandencyCloseonParentTaskClose(childDate);
            setInputFields(values);
            // dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
        } else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = Operation === 'Delete' ? 2 : 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // })
            // loadCommonDependencyButtonStartstop();
            setexistsubmission(true);
            setdeleteTrimSubmission(false);
            seteditTrimSubmission(false);
            //setTrimmodalpopup(false);
            setTrimswatchDownload(false);
            setTrimswatchUpload(false);
            //  setInputFields(values);
        }
    }

    const TrimApprovalCallback = (value, getOrderinfo, Operation, Swatchstatus) => {
        if (value === true) {

            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.tnaTrimRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        // childDate = convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate));
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate).toLocaleString();
                    }
                })

                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })

            if (Operation === 'upload') {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 3;
            }

            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = Swatchstatus;
            }


            setTrimApprovalpopup(false);
            setdeleteTrimApproval(false);
            seteditTrimApproval(false);
            setTrimswatchDownload(false);
            setTrimswatchUpload(false);
            AutoDepandencyCloseonParentTaskClose(childDate);
            setInputFields(values);
        } else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // })

            // setInputFields(values);
            //setTrimApprovalpopup(false);
            setdeleteTrimApproval(false);
            seteditTrimApproval(false);
            setTrimswatchDownload(false);
            setTrimswatchUpload(false);
        }
    }
    const ProtoFitSMSSubmissionCallback = (value, Operation) => {
        if (value === true) {
            let childDate;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // childDate = convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate));
            childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
            IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            AutoDepandencyCloseonParentTaskClose(childDate);
            setInputFields(values);
            setSampleSubModalpopup(false);
            setdeleteProtoFitSmsSubmission(false);
            seteditProtoFitSmsSubmission(false);
        } else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // setInputFields(values);
            // setSampleSubModalpopup(false);
            setexistsubmission(true);
            setdeleteProtoFitSmsSubmission(false);
            seteditProtoFitSmsSubmission(false);
        }
    }

    const ProtoFitAprovalCallback = (value, Operation) => {

        if (value === true) {
            let childDate;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            //childDate = convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate));
            childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
            IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            AutoDepandencyCloseonParentTaskClose(childDate);
            setInputFields(values);
            setdeleteProtoFitSmsApproval(false);
            seteditProtoFitSmsApproval(false);
            setSampleAppModalpopup(false);

        }
        else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // setInputFields(values);
            setdeleteProtoFitSmsApproval(false);
            seteditProtoFitSmsApproval(false);
            // setSampleAppModalpopup(false);

        }
    }
    const AppareanceTestSubmissionCallback = (value, Operation) => {
        if (value === true) {
            let childDate;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowSubmitButton = Operation === 'Delete' ? 2 : 3;
            // childDate = convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate));
            childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
            IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            AutoDepandencyCloseonParentTaskClose(childDate);
            setInputFields(values);
            seteditAppearanceAfterWash(false);
            setdeleteAppearanceAfterWash(false);
            setApperancemodalpopup(false);


        }
        else if (Operation === "Save") {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowSubmitButton = 3;
            seteditAppearanceAfterWash(false);
            setdeleteAppearanceAfterWash(false);
            setApperancemodalpopup(false);
            setInputFields(values);
        }
        else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            //values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowSubmitButton = Operation === 'Delete' ? 2 : 3;
            //setInputFields(values);
            setApperancemodalpopup(false);
            seteditAppearanceAfterWash(false);
            setdeleteAppearanceAfterWash(false);
            //  setApperancemodalpopup(false);
        }

    }

    // function AutoDepandencyCloseonParentTaskOnly(CloseDate) {
    //

    //    // let IsAllTasksClosed = true;
    //    let CurrrIndex = 0;
    //     values.map((Main) => {
    //         if(Main.DetailTypeID === 1)
    //         {
    //              if (Main.SelectedDependency !== '' && (Main.SelectedDependency.includes(CurrrIndex + "-ES")
    //             || Main.SelectedDependency.includes(CurrrIndex + "-EE")
    //             || Main.SelectedDependency.includes(CurrrIndex + "-SS")
    //             || Main.SelectedDependency.includes(CurrrIndex + "-SE")))
    //             {

    //                 Main.intDependency = 1;
    //                 Main.DependencyDate = CloseDate;
    //             }

    //             CurrrIndex ++;

    //         }

    //     })


    //     }


    function AutoDepandencyCloseonParentTaskClose(CloseDate) {

        let CurrrIndexParent = 0;
        let CurrrIndex = 0;
        let CurrentParentvalues = [];
        let CurrentChildvalues = [];
        let IsAllTasksClosed = true;

        values.map((Main) => {

            if (Main.DetailTypeID === 1)  // Parent Information
            {
                CurrentParentvalues.push({ StatusID: Main.StatusID, ChildIndex: Main.IndexName, SubID: Main.SubId })
            }
            Main.SubTaskList.map((Child) => { // Child Information
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({ StatusID: SubChild.StatusID, ChildIndex: SubChild.ChildIndex, SubID: SubChild.SubId, SelectedDepandency: SubChild.SelectedDependency })
                })
            })
        })


        values.map((MainChild) => {
            if (MainChild.DetailTypeID === 1) // Common
            {
                if (MainChild.SelectedDependency !== '' && (MainChild.SelectedDependency.includes(CurrrIndexParent + "-ES")
                    || MainChild.SelectedDependency.includes(CurrrIndexParent + "-EE")
                    || MainChild.SelectedDependency.includes(CurrrIndexParent + "-SS")
                    || MainChild.SelectedDependency.includes(CurrrIndexParent + "-SE"))) {
                    AutoDepandencyCloseMethodParent(MainChild);
                }

                CurrrIndexParent++;

            }
            else {
                CurrrIndex = values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildIndex;
                MainChild.SubTaskList.map((Child) => {
                    Child.ChildTaskList.map((SubChild) => {
                        //CurrrIndex = SubChild.ChildIndex;
                        if (MainChild.DetailTypeID === 4) {  // Ex Factory
                            if (SubChild.SelectedDependency !== '') {
                                AutoDepandencyCloseMethod(SubChild);
                            }


                        }
                        else { // Item , SKU
                            if (SubChild.SelectedDependency !== '' && (SubChild.SelectedDependency.includes(CurrrIndex + "-ES")
                                || SubChild.SelectedDependency.includes(CurrrIndex + "-EE")
                                || SubChild.SelectedDependency.includes(CurrrIndex + "-SS")
                                || SubChild.SelectedDependency.includes(CurrrIndex + "-SE"))) {

                                AutoDepandencyCloseMethod(SubChild);

                            }

                        }
                    })
                })

            }



        })

        function AutoDepandencyCloseMethodParent(MainChild) {

            // if (MainChild.SelectedDependency.includes(',')) {
            //     let splitmultiparentchildindex = MainChild.SelectedDependency.split(',');
            //     splitmultiparentchildindex.map(depitem => {

            //         let splitparentchildindex = depitem.split('-');
            //
            //         if (CurrentParentvalues.filter(x => x.ChildIndex === parseInt(splitparentchildindex[1]))[0].StatusID !== 3) {
            //             IsAllTasksClosed = false;
            //         }
            //     });
            //     if (IsAllTasksClosed === true) {
            //         MainChild.intDependency = 0;
            //         MainChild.DependencyDate = '';

            //     }
            //     else {
            //         MainChild.intDependency = 1;
            //         MainChild.DependencyDate = CloseDate;
            //     }
            // }

            // else {
            //     //SubChild.intDependency = 1;
            //     MainChild.intDependency = 0;
            //     MainChild.DependencyDate = '';

            //     for (let index = 0; index < CurrentParentvalues.length; index++) {
            //         // const element = array[index];
            //         if (CurrentParentvalues[index].SubID === MainChild.SubId && CurrentParentvalues[index].StatusID === 3) {
            //             MainChild.intDependency = 1;
            //             MainChild.DependencyDate = CloseDate;

            //         }

            //     }

            // }

            MainChild.intDependency = 0;
            MainChild.DependencyDate = '';

            for (let index = 0; index < CurrentParentvalues.length; index++) {
                // const element = array[index];
                if (CurrentParentvalues[index].SubID === MainChild.SubId && CurrentParentvalues[index].StatusID === 3) {
                    MainChild.intDependency = 1;
                    MainChild.DependencyDate = CloseDate;

                }

            }
            //CurrrIndex++;
        }

        function AutoDepandencyCloseMethod(SubChild) {

            if (SubChild.SelectedDependency.includes(',')) {
                let splitmultiparentchildindex = SubChild.SelectedDependency.split(',');
                splitmultiparentchildindex.map(depitem => {

                    let splitparentchildindex = depitem.split('-');
                    if (CurrentChildvalues.filter(x => x.ChildIndex === parseInt(splitparentchildindex[1]))[0].StatusID !== 3) {
                        IsAllTasksClosed = false;
                    }
                });
                if (IsAllTasksClosed === true) {
                    SubChild.intDependency = 0;
                    SubChild.DependencyDate = '';

                }
                else {
                    SubChild.intDependency = 1;
                    SubChild.DependencyDate = CloseDate;
                }
            }

            else {
                //SubChild.intDependency = 1;
                SubChild.intDependency = 0;
                SubChild.DependencyDate = '';
                // if (CurrentChildvalues.filter(x => x.subId ===  SubChild.SubId)[0].StatusID === 3) {
                //    SubChild.intDependency = 1;
                //    IsAllTasksClosed = true;
                // }
                for (let index = 0; index < CurrentChildvalues.length; index++) {
                    // const element = array[index];
                    if (CurrentChildvalues[index].SubID === SubChild.SubId && CurrentChildvalues[index].StatusID === 3) {
                        //if (CurrentChildvalues[index].StatusID === 3) {
                        SubChild.intDependency = 1;
                        SubChild.DependencyDate = CloseDate;

                    }

                }

            }
            CurrrIndex++;
        }
    }

    // function AutoDepandencyCloseonParentTaskClose(CloseDate) {

    //     let CurrrIndex = values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildIndex;

    //     let CurrentChildvalues = [];
    //     let IsAllTasksClosed = true;
    //     values.map((Main) => {
    //         Main.SubTaskList.map((Child) => {
    //             Child.ChildTaskList.map((SubChild) => {

    //                 CurrentChildvalues.push({ StatusID: SubChild.StatusID, ChildIndex: SubChild.ChildIndex, SubID: SubChild.SubId })
    //             })
    //         })
    //     })
    //     values.map((Main) => {
    //         Main.SubTaskList.map((Child) => {
    //             Child.ChildTaskList.map((SubChild) => {

    //                 if (Main.DetailTypeID === 4) {
    //                     if (SubChild.SelectedDependency !== '') {
    //                         AutoDepandencyCloseMethod(SubChild);
    //                     }


    //                 }
    //                 else {
    //                     if (SubChild.SelectedDependency !== '' && (SubChild.SelectedDependency.includes(CurrrIndex + "-ES")
    //                         || SubChild.SelectedDependency.includes(CurrrIndex + "-EE")
    //                         || SubChild.SelectedDependency.includes(CurrrIndex + "-SS")
    //                         || SubChild.SelectedDependency.includes(CurrrIndex + "-SE"))) {

    //                         AutoDepandencyCloseMethod(SubChild);

    //                     }

    //                 }




    //             })
    //         })
    //     })

    //     function AutoDepandencyCloseMethod(SubChild) {
    //         if (SubChild.SelectedDependency.includes(',')) {
    //             let splitmultiparentchildindex = SubChild.SelectedDependency.split(',');
    //             splitmultiparentchildindex.map(depitem => {

    //                 let splitparentchildindex = depitem.split('-');
    //                 if (CurrentChildvalues.filter(x => x.ChildIndex === parseInt(splitparentchildindex[1]))[0].StatusID !== 3) {
    //                     IsAllTasksClosed = false;
    //                 }
    //             });
    //             if (IsAllTasksClosed === true) {
    //                 SubChild.intDependency = 0;
    //                 SubChild.DependencyDate = '';

    //             }
    //             else {
    //                 SubChild.intDependency = 1;
    //                 SubChild.DependencyDate = CloseDate;
    //             }
    //         }

    //         else {
    //             //SubChild.intDependency = 1;
    //             SubChild.intDependency = 0;
    //             SubChild.DependencyDate = '';
    //             // if (CurrentChildvalues.filter(x => x.subId ===  SubChild.SubId)[0].StatusID === 3) {
    //             //    SubChild.intDependency = 1;
    //             //    IsAllTasksClosed = true;
    //             // }
    //             for (let index = 0; index < CurrentChildvalues.length; index++) {
    //                 // const element = array[index];
    //                 if (CurrentChildvalues[index].SubID === SubChild.SubId && CurrentChildvalues[index].StatusID === 3) {
    //                     SubChild.intDependency = 1;
    //                     SubChild.DependencyDate = CloseDate;

    //                 }

    //             }
    //             //alternative code
    //             //                             const filteredValues = CurrentChildvalues.filter(item => item.SubID === SubChild.SubId && item.StatusID === 3);
    //             // // Optionally, you can check if the filtered array has any elements
    //             // if (filteredValues.length > 0) {
    //             //     SubChild.intDependency = 1;
    //             //     // If you only want to process the first matching element, you can access it like this:
    //             //     // SubChild.intDependency = filteredValues[0].someProperty;
    //             // }
    //         }
    //         CurrrIndex++;
    //     }
    // }

    function loadCommonDependencyButtonStartstop() {
        values[Indexes.Index].SubTaskList.map((subTask, subIndex) => {
            subTask && subTask.ChildTaskList.map((ChildTaskList, childIndex) => {
                values[Indexes.Index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency = 1;
            });
        });
    }

    const EditComments = (index, Value, Childindex) => {
        values[index].Comments = Value;
        //values.Comments = Value;
        setCommentSaveText("Update");
        setEditedCommentIndex(Childindex);
        setInputFields(values);
    }

    const RemoveComments = (index, ChildIndex2) => {
        values[index].arrComment.splice(ChildIndex2, 1);
        values[index].Comments = '';
        setEditedCommentIndex('');
        values[index].CommentsCount = values[index].arrComment.length;
        setInputFields(values);
        Nodify('Success!', 'success', "Comments deleted successfully.");

    }

    const handleChildManualclose = async (index, subIndex, childIndex, TaskName, TaskType, TaskID, ChildId, Status, ManualCloseComment,
        arrDependency, intDependency, inputField) => {
        debugger;
        let CurrentChildvalues = [];
        values.map((Main) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({
                        StatusID: SubChild.StatusID,
                        ChildId: SubChild.ChildId,
                        IsDepandency: SubChild.IsDepandency,
                        Depandency: SubChild.SelectedDependency,
                        intDependency: SubChild.intDependency,

                    })
                })
            })
        })


        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            showLoader();
            //check manual submit esist
            setComment(false)
            setdates(false)
            setSubmitRevice(false)
            setSubmitReschedule(false)
            if (Status !== 3) {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = '';
            }
            let Operation;
            if (TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                Operation = 1;
            }
            else if (TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
                Operation = 3;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                Operation = 2;
            }
            else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                Operation = 4;
            }
            else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                Operation = 5;
            }

            else if (TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) {
                Operation = 6;
            }
            else if (TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL) {
                Operation = 8;
            }
            else if (TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL) {
                Operation = 9;
            }
            else if (TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL) {
                Operation = 10;
            }
            else if (TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
                Operation = 11;
            }
            else {
                Operation = 7;

            }


            if (Operation !== 7) { // check submission exists for approvals
                await TnaService.GetTNAManualCloseSubmissionList(ChildId, TaskName).then(async (response) => {
                    if (response.data.length !== 0) {
                        // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0 && (!ManualCloseComment)) {
                        //     let Dependency = false;
                        //     if (values[index].SelectedDependency) {
                        //         let arrstatus = [];
                        //         arrstatus = values[index].SelectedDependency.split(',');
                        //         if (arrstatus.length > 0) {
                        //             arrstatus.forEach(element => {

                        //                 let val = [];
                        //                 val = element.split('-');
                        //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                        //                 if (valIndex !== 3) {
                        //                     Dependency = true;
                        //                 }
                        //             });
                        //         }
                        //         if (Dependency) {
                        //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
                        //         }
                        //         else {
                        //             values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                        //             setInputFields(values);
                        //             hideLoader();
                        //         }
                        //     } else {
                        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                        //         setInputFields(values);
                        //         hideLoader();
                        //     }
                        // }
                        // else {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                        setInputFields(values);
                        hideLoader();
                        // }
                        //get manual close
                        let Operation;
                        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                            Operation = 1;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                            Operation = 3;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
                            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
                            Operation = 2;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                            Operation = 4;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                            Operation = 5;
                        }
                        //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                        else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
                            || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                            || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                            || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                            || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL
                            || TaskName.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED_SKU) {
                            Operation = 6;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                            Operation = 7;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                            Operation = 9;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                            Operation = 10;
                        }
                        else {
                            Operation = 8;
                        }


                        let params = {
                            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                            SupplierId: getTNASupplierID, TaskType: TaskType, TaskNameId: TaskID,
                            TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
                            Operation: Operation, ParentIdOrSubChildId: ChildId, IsProduction: IsProduction
                        };

                        await TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                            if (response.data) {
                                var result = response.data;
                                let manindex = 1;
                                let data = result.map((item) => {
                                    item.index = manindex;
                                    manindex = manindex + 1;

                                    if (item.subTaskInfoId === ChildId) {
                                        item.isSelected = 1;

                                        return item;
                                    } else {
                                        return item;
                                    }
                                });
                                let isSelectAll = 0;
                                if (data) {
                                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                                    isSelectAll = isSelectAll ? 1 : 0;
                                }
                                setselectall(isSelectAll);
                                if (Status !== 3) {
                                    data.map((r, i) => {
                                        CurrentChildvalues.map(c => {
                                            if (r.subTaskInfoId === c.ChildId) {
                                                data[i].IsDepandency = c.IsDepandency;
                                                data[i].Depandency = c.Depandency;
                                                data[i].dependencyStartstop = c.intDependency;
                                                result[i].dependencyStartstop = c.intDependency;
                                            }
                                        })
                                    })
                                    setMultiCloseDatas(data);
                                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];
                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }
                                    hideLoader();
                                }
                                else {
                                    setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];
                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }
                                }
                                hideLoader();
                            }


                        }).catch(() => { hideLoader(); });
                    }
                    else {
                        hideLoader();
                        Nodify('Warning!', 'warning', "Manual Submission not yet added for this task");
                        return false;
                    }

                }).catch(() => { hideLoader(); });
            }
            else {
                let isAllowed = true;
                // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0 && (!ManualCloseComment)) {

                //     let Dependency = false;
                //     if (values[index].SelectedDependency) {

                //         let arrstatus = [];
                //         arrstatus = values[index].SelectedDependency.split(',');
                //         if (arrstatus.length > 0) {
                //             arrstatus.forEach(element => {

                //                 let val = [];
                //                 val = element.split('-');
                //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                //                 if (valIndex !== 3) {
                //                     Dependency = true;
                //                 }
                //             });
                //         }
                //         if (Dependency) {
                //             isAllowed = false;
                //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
                //             hideLoader();
                //         }
                //         else { values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1; setInputFields(values); }
                //     } else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                setInputFields(values);
                //         //hideLoader();
                //     }
                // }

                if (isAllowed) {
                    // else {
                    //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                    //     setInputFields(values);

                    // }

                    //get manual close
                    let Operation;
                    if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                        Operation = 1;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                        || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                        Operation = 3;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
                        Operation = 2;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                        Operation = 4;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                        Operation = 5;
                    }
                    //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                    else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
                        || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED_SKU) {
                        Operation = 6;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                        Operation = 7;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                        Operation = 9;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                        Operation = 10;
                    }
                    else {
                        Operation = 8;
                    }

                    if (values[index].DetailTypeID === 4) {
                        let rowDetails = values[index].SubTaskList[subIndex].ChildTaskList[childIndex];

                        let params = {
                            Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
                            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                            SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
                            //SkuId: rowDetails.SubId,
                            SkuId: 0,//for bring all sku
                            TaskOwnerIdList: values[index].TaskHolderOwnerIdList,
                            TaskHolderId: values[index].TaskHolderOrFollower, IsProduction: IsProduction

                        };
                        TnaService.GetSubChildMultExFactoryFormSKUList(params).then((response) => {
                            if (response.data) {
                                var result = response.data;
                                let manindex = 1;
                                let data = result.map((item) => {
                                    item.index = manindex;
                                    manindex = manindex + 1;

                                    if (item.tnaExFactoryId === ChildId) {
                                        item.isSelected = 1;
                                        return item;
                                    } else {
                                        return item;
                                    }
                                });
                                let isSelectAll = 0;
                                if (data) {
                                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                                    isSelectAll = isSelectAll ? 1 : 0;
                                }
                                setselectall(isSelectAll);
                                if (Status !== 3) {
                                    data.map((r, i) => {
                                        CurrentChildvalues.map(c => {
                                            if (r.subTaskInfoId === c.ChildId) {
                                                data[i].IsDepandency = c.IsDepandency;
                                                data[i].Depandency = c.Depandency;
                                                data[i].dependencyStartstop = c.intDependency;
                                                result[i].dependencyStartstop = c.intDependency;
                                            }
                                        })
                                    })
                                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
                                    setMultiCloseDatas(data);
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];

                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }

                                }
                                else {
                                    setMultiCloseDatas(data.filter(x => x.tnaExFactoryId === ChildId));
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];

                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }
                                }

                                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                                setInputFields(values);
                                hideLoader();
                            }

                        }).catch(() => { hideLoader(); });

                    }
                    else {
                        let params = {
                            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                            SupplierId: getTNASupplierID, TaskType: TaskType, TaskNameId: TaskID,
                            TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
                            Operation: Operation, ParentIdOrSubChildId: ChildId, IsProduction: IsProduction
                        };

                        TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                            if (response.data) {
                                var result = response.data;
                                let manindex = 1;
                                let data = result.map((item) => {
                                    item.index = manindex;
                                    manindex = manindex + 1;
                                    if (item.subTaskInfoId === ChildId) {
                                        item.isSelected = 1;

                                        return item;
                                    } else {
                                        return item;
                                    }

                                });
                                let isSelectAll = 0;
                                if (data) {
                                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                                    isSelectAll = isSelectAll ? 1 : 0;
                                }
                                setselectall(isSelectAll);
                                if (Status !== 3) {
                                    data.map((r, i) => {
                                        CurrentChildvalues.map(c => {
                                            if (r.subTaskInfoId === c.ChildId) {
                                                data[i].IsDepandency = c.IsDepandency;
                                                data[i].Depandency = c.Depandency;
                                                data[i].dependencyStartstop = c.intDependency;
                                                result[i].dependencyStartstop = c.intDependency;
                                            }
                                        })
                                    })
                                    setMultiCloseDatas(data);
                                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];

                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }

                                }
                                else {
                                    setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];

                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data.filter(x => x.subTaskInfoId === ChildId));
                                    }
                                }
                                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                                setInputFields(values);
                                hideLoader();
                            } else {
                                hideLoader();
                                Nodify('Warning!', 'warning', "No data in manual close");
                            }


                        }).catch(() => { hideLoader(); });
                    }
                }
            }
        }

    };


    const DefaultExpandRowMultiSelect = (strikeofforTrimLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = false;
        let obj = {};
        isRowExpanded ? (obj[strikeofforTrimLogoPath] = false) : (obj[strikeofforTrimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeofforTrimLogoPath) :
            currentExpandedRows.concat(strikeofforTrimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);
    }

    const handleExpandRowMultiSelect = (strikeofforTrimLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(strikeofforTrimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[strikeofforTrimLogoPath] = false) : (obj[strikeofforTrimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeofforTrimLogoPath) :
            currentExpandedRows.concat(strikeofforTrimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);
    }


    const handleCloseManualPopup = (StatusID, cmt, date, index, subIndex, childIndex) => {

        if (StatusID !== 3) {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = '';
            // if (cmt !== '' || (date !== '' && date !== null)) {
            //     Nodify('Warning!', 'warning', "closing date or comments not add in this task,please completed fully");
            // }
            // if (cmt !== '' && (date !== '' || date == null)) {
            //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";

            // }
            // else {
            //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = '';
            // }
        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
        }
        setInputFields(values);
    }


    const handleChangeIsManualCloseComments = (event, index, subIndex, childIndex, feild) => {

        setComment(false);
        let input = '';
        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = input;
        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = event;
            // ManualCloseData.ManualCloseDate = event;
        }
        setInputFields(values);
    }

    const SaveChildManulaCloseComments = (index, subIndex, childIndex, value, CloseDate, TaskName) => {

        if (value === '' || value === undefined) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
        }
        else if (CloseDate === '' || CloseDate === null) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter Close Date");
        }
        else {
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID = 3;
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = new Date();
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildActualEndDate = new Date();
            // setInputFields(values);


            let ChildStatus = 0;
            if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                ChildStatus = 1;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                ChildStatus = 2;

            } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
                TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
                TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

                ChildStatus = 3;

            } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
                TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
                || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL
                || TaskName.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED_SKU) {
                ChildStatus = 4;
            }
            else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
                TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                ChildStatus = 5;
            } else if (values[index].DetailTypeID === 4) {
                ChildStatus = 7;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                ChildStatus = 8;
            }
            else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                ChildStatus = 9;
            }

            let strValue = "";
            let selected = 0;
            for (let data of MultiCloseDatas) {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.subTaskInfoId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.subTaskInfoId;
                    }
                }
            }
            if (selected === 0) {
                Nodify('Warning!', 'warning', "Select atleast one Task");
            }
            else {

                var ManualCloseData = { ...MultiManualCloseFeilds };
                ManualCloseData.ManualCloseDate = new Date(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate);
                ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // ManualCloseData.ActualEndDate = new Date(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate);
                // ManualCloseData.ActualEndDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // .setDate(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate.getDate() + 1);
                ManualCloseData.ManualCloseComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment;
                ManualCloseData.IdList = strValue;
                ManualCloseData.TaskStatus = ChildStatus;
                ManualCloseData.CreatedBy = currentUser.employeeinformationID;
                TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
                    if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', 'Task Manually Closed Successfully');
                        let CloseDate = convertUTCDateToLocalDate(new Date(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate));
                        MultiCloseDatas.filter(x => x.isSelected === 1).forEach(element => {
                            values[index].SubTaskList[subIndex].ChildTaskList.forEach((element1, index1) => {
                                if (element.subTaskInfoId === element1.ChildId) {

                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].IsManualClose = 0;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].StatusID = 3;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].ManualCloseComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].ManualCloseDate = CloseDate;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].ChildActualEndDate = CloseDate;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].StrActualEndDate = moment(CloseDate).format("MM/DD/YYYY");
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].intDependency = element.dependencyStartstop;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].DependencyComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyComment;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].DependencyDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyDate;
                                    // values[index].SubTaskList[subIndex].ChildTaskList[index1].DependencyDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyDate !== '' ? values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyDate: CloseDate; // Dependency task enable for Thumb icon afte updating
                                }
                            })


                        })

                        IsParentDateShow = ManualCommentAdd(values, index, IsParentDateShow, CloseDate);
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                        AutoDepandencyCloseonParentTaskClose(CloseDate);
                        setInputFields(values);

                    }
                    else if (res.data.outputResult === "0") {
                        Nodify("Error!", "Error Occured!");
                    }
                });
            }
        }
    }


    const SelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                if (element.Depandency !== '' && element.dependencyStartstop === 1) {
                    element.isSelected = 1;
                }
                else if (element.Depandency === '') {
                    element.isSelected = 1;
                }
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;

        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);

        setMultiCloseDatas(value);
    }

    //for strikeoff and trim manual close only
    const SelectAllST = (event, strikeofforTrimLogoPath, mainindex) => {

        let IsSelected = 0;
        var Maindetails = [...OrderInfoMulticloseMainDetails];
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath).forEach(element => {
                if (element.Depandency !== '' && element.dependencyStartstop === 1) {
                    element.isSelected = 1;
                }
                else if (element.Depandency === '') {
                    element.isSelected = 1;
                }
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath).forEach(element => {
                element.isSelected = 0;
            })
        }
        Maindetails[mainindex].isSelectedAll = IsSelected;
        setOrderInfoMulticloseMainDetails(Maindetails);

        //setselectall({ IsAllSelected: IsSelected, AllSelectedIndex: mainindex });
    }

    const OnSelectRecordsST = (event, index, mainindex) => {
        const value = [...MultiCloseDatas];
        var Maindetails = [...OrderInfoMulticloseMainDetails];
        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }

            Maindetails[mainindex].isSelectedAll = isSelectAll;
            setOrderInfoMulticloseMainDetails(Maindetails);
            // setselectall({ IsAllSelected: isSelectAll, AllSelectedIndex: mainindex });
            setMultiCloseDatas(value);
        }

    }

    const showManualApproval = (index, subIndex, childIndex, childId, TaskName) => {

        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
            Operation = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
            Operation = 5;
        }

        TnaService.getManualApproval(childId, Operation).then((response) => {
            if (response.data) {
                setmanualApprovalData(response.data);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 1;
                setInputFields(values);
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 1;
                setInputFields(values);
            }
        }).catch(() => { });
    }

    const hideManualApproval = (index, subIndex, childIndex) => {

        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 0;
        setInputFields(values);
    }

    const showExistingApproval = (index, subIndex, childIndex, childId, TaskName) => {
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
            Operation = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
            Operation = 5;
        }

        TnaService.getExistingApproval(childId, Operation).then((response) => {
            if (response.data) {
                setexistingApprovalData(response.data);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 1;
                setInputFields(values);
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 1;
                setInputFields(values);
            }
        }).catch(() => { });
    }

    const hideExistingApproval = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 0;
        setInputFields(values);
    }

    function BindTaskHoldername(values, index) {
        let TxtList = "";
        let IdList = "";
        let Taskhoder = "";
        let titleEmployee1 = values[index].titleEmployee1;
        let titleEmployee2 = values[index].titleEmployee2;
        let titleEmployee3 = values[index].titleEmployee3;

        if (values[index].TaskHolderBuyer && (titleEmployee1 === "Buyer" || titleEmployee2 === "Buyer" || titleEmployee3 === "Buyer")) {
            TxtList = values[index].TaskHolderBuyerTxt;
            IdList = values[index].TaskHolderBuyer;
            //Taskhoder = values[index].TaskHolderBuyer;
        } else {
            values[index].TaskHolderBuyer = "";
            values[index].TaskHolderBuyerTxt = "";
            values[index].arrTaskHolderBuyer = [];
        }
        if (values[index].FollowerCubeTxt && (titleEmployee1 === "Cube" || titleEmployee2 === "Cube" || titleEmployee3 === "Cube")) {
            TxtList = TxtList === "" ? values[index].FollowerCubeTxt : TxtList + ", " + values[index].FollowerCubeTxt;
            IdList = IdList === "" ? values[index].FollowerCube : IdList + "," + values[index].FollowerCube;
        }
        else {
            values[index].FollowerCube = "";
            values[index].FollowerCubeTxt = "";
            values[index].arrFollowerCube = [];
        }
        if (values[index].FollowerSupplierTxt && (titleEmployee1 === "Supplier" || titleEmployee2 === "Supplier" || titleEmployee3 === "Supplier")) {
            TxtList = TxtList === "" ? values[index].FollowerSupplierTxt : TxtList + ", " + values[index].FollowerSupplierTxt;
            IdList = IdList === "" ? values[index].FollowerSupplier : IdList + "," + values[index].FollowerSupplier;
        } else {
            values[index].FollowerSupplier = "";
            values[index].FollowerSupplierTxt = "";
            values[index].arrFollowerSupplier = [];
        }

        values[index].TaskHolderOwnerTxtList = TxtList;
        values[index].TaskHolderOwnerIdList = IdList;
        if (values[index].titleEmployee1 === 'Cube') {
            Taskhoder = values[index].FollowerCube;
        }
        else if (values[index].titleEmployee1 === 'Buyer') {
            Taskhoder = values[index].TaskHolderBuyer;
        }
        else { Taskhoder = values[index].FollowerSupplier; }
        return Taskhoder;
    }

    const checkTaskHolderOwer = (index) => {
        let Taskhoder = "";
        if (values[index].titleEmployee1 === 'Cube') {
            Taskhoder = values[index].FollowerCube;
        }
        else if (values[index].titleEmployee1 === 'Buyer') {
            Taskhoder = values[index].TaskHolderBuyer;
        }
        else { Taskhoder = values[index].FollowerSupplier; }
        return Taskhoder;
    }

    const OpenRevicePopup = (index, subIndex, childIndex, TNAId, TaskDetailId, TaskName, StartDate, EndDate, Duration, Type, EndDateChange) => {



        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }

        else {
            if (Type === "Main") {
                if (values[index].MainRevicedetails) {
                    setReviceComment(values[index].MainRevicedetails.ReviceComment)
                }
            }
            else {
                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails) {
                    setReviceComment(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.ReviceComment)
                }
            }


            TNAService.getTNATaskReviceList(TNAId, TaskDetailId).then((response) => {
                if (response.data) {

                    setSubReviceList(response.data);
                    if (Type === "Main") {
                        values[index].RevicePopup = 1;
                        values[index].ReviceSelected = 1;
                    }
                    else {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RevicePopup = 1;
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReviceSelected = 1;

                    }
                    setInputFields(values);
                }
            }).catch(() => { });

        }
    }
    const CloseRevicePopup = (index, subIndex, childIndex, Type, Action) => {
        setSubmitRevice(false)
        if (Type === 'Main') {
            values[index].RevicePopup = 0;

        }
        else {

            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RevicePopup = 0;

        }
        if (Action === 'Close') {
            if (Type === 'Main') {
                values[index].ReviceSelected = 0;
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReviceSelected = 0;
            }
        }

        setReviceComment('');
        setInputFields(values);
        setReviceAction('Add');
        // }

    }

    const EditReviceComments = (TNATaskReviceId, Comment, Date) => {
        setSubRevicedetails({ TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '', ReviceComment: '', Operation: 0 })
        var revice = { ...getSubRevicedetails };
        revice.TNATaskReviceId = TNATaskReviceId;
        revice.ReviceDate = Date;
        revice.ReviceComment = Comment;
        revice.Operation = 2;
        setSubRevicedetails(revice);
        setReviceAction('Edit');
    }

    const RemoveReviceComments = (TNATaskReviceId, index, subIndex, childIndex) => {
        getSubRevicedetails.TNATaskReviceId = TNATaskReviceId;
        getSubRevicedetails.Operation = 3;
        TnaService.InsertUpdateDeleteTNATaskRevice(getSubRevicedetails).then((res) => {

            if (res.data.outputResult === "3") {
                Nodify('Success!', 'success', "Revice Deleted Successfully");
                CloseRevicePopup(index, subIndex, childIndex);
            } else if (res.data.outputResult === "-2") {
                Nodify('Warning!', 'warning', "Error Occured");
            }
        })
    }

    const handleChangeReviceComments = (event, feild) => {
        setSubmitRevice(false);

        let input = '';
        if (event.target.value.trim() !== '') {
            input = event.target.value;
        }
        setReviceComment(input);

    }

    const handleSaveRevice = (index, subIndex, childIndex, TNAId, TaskDetailId, TaskName, StartDate, EndDate, Duration, Type, EndDateChange, Comment) => {
        if ((!Comment)) {
            setSubmitRevice(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {




            if (Comment !== '') {
                if (Type !== 'Main') {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.StartDate = '';
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.EndDate = '';
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.Duration = 0;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.TNAId = TNAId;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.TaskDetailId = TaskDetailId;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.TaskName = TaskName;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.Operation = 1;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.Type = Type === "Main" ? "Main" : "Sub";
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.ReviceComment = Comment;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.Createdby = currentUser.employeeinformationID;
                }
                else {
                    values[index].MainRevicedetails.StartDate = '';
                    values[index].MainRevicedetails.EndDate = '';
                    values[index].MainRevicedetails.Duration = 0;
                    values[index].MainRevicedetails.TNAId = TNAId;
                    values[index].MainRevicedetails.TaskDetailId = TaskDetailId;
                    values[index].MainRevicedetails.TaskName = TaskName;
                    values[index].MainRevicedetails.Operation = 1;
                    values[index].MainRevicedetails.Type = Type === "Main" ? "Main" : "Sub";
                    values[index].MainRevicedetails.ReviceComment = Comment;
                    values[index].MainRevicedetails.Createdby = currentUser.employeeinformationID;
                }
            }
            if (Type === 'Main') {
                values[index].IsReviced = 1;
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsReviced = 1;
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReviceAdded = 1;

            }
            setInputFields(values);
            CloseRevicePopup(index, subIndex, childIndex, Type, 'Add');
            Nodify('Success!', 'success', "Comments Added Successfully");
            // }

        }
    }
    const OpenReschedulePopup = (index, subIndex, childIndex, TNAId, TaskDetailId, TaskName, StartDate, EndDate, Duration, Type, Status) => {

        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');

        }

        else {
            if (Type === "Main") {
                if (values[index].MainRescheduledetails) {
                    setRescheduleComment(values[index].MainRescheduledetails.RescheduleComment)
                }
            }
            else {
                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails) {
                    setRescheduleComment(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.RescheduleComment)
                }
            }

            TNAService.getTNATaskRescheduleList(TNAId, TaskDetailId).then((response) => {
                if (response.data) {
                    setSubRescheduleList(response.data);
                    if (Type === "Main") {
                        values[index].ReschedulePopup = 1;
                        values[index].RescheduleSelected = 1;
                    }
                    else {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReschedulePopup = 1;
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RescheduleSelected = 1;
                    }
                    setInputFields(values);
                }
            }).catch(() => { });

        }
    }
    const CloseReschedulePopup = (index, subIndex, childIndex, Type, Action) => {
        setSubmitReschedule(false)
        if (Type === "Main") {
            values[index].ReschedulePopup = 0;

        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReschedulePopup = 0;

        }

        if (Action === 'Close') {
            if (Type === 'Main') {
                values[index].RescheduleSelected = 0;
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RescheduleSelected = 0;
            }
        }

        setRescheduleComment('');
        setInputFields(values);
        setRescheduleAction('Add');
        setSubmitReschedule(false)
    }
    const handleSaveReschedule = (index, subIndex, childIndex, TNAId, TaskDetailId, TaskName, StartDate, EndDate, Duration, Type, EndDateChange, Comment) => {

        if ((!Comment)) {
            setSubmitReschedule(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {

            if (Comment !== '') {
                if (Type !== 'Main') {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.StartDate = '';
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.EndDate = '';
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildActualEndDate = '';
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.Duration = 0;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.TNAId = TNAId;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.TaskDetailId = TaskDetailId;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.TaskName = TaskName;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.Operation = 1;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.Type = Type === "Main" ? "Main" : "Sub";
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.RescheduleComment = Comment;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.Createdby = currentUser.employeeinformationID;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID = 1;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = '';
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = null;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildActualEndDate = null;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StrActualEndDate = null;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;

                }
                else {
                    values[index].MainRescheduledetails.StartDate = '';
                    values[index].MainRescheduledetails.EndDate = '';
                    values[index].MainRescheduledetails.Duration = 0;
                    values[index].MainRescheduledetails.TNAId = TNAId;
                    values[index].MainRescheduledetails.TaskDetailId = TaskDetailId;
                    values[index].MainRescheduledetails.TaskName = TaskName;
                    values[index].MainRescheduledetails.Operation = 1;
                    values[index].MainRescheduledetails.Type = Type === "Main" ? "Main" : "Sub";
                    values[index].MainRescheduledetails.RescheduleComment = Comment;
                    values[index].MainRescheduledetails.Createdby = currentUser.employeeinformationID;
                }

            }
            if (Type === 'Main') {
                values[index].IsRescheduled = 1;
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsRescheduled = 1;
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RescheduleAdded = 1;
            }

            CloseReschedulePopup(index, subIndex, childIndex, Type, 'Add');
            IsParentDateShow = DateAffectReschedule(values, index, IsParentDateShow);

            setInputFields(values);

            // }


        }
    }
    const handleChangeRescheduleComments = (event, feild) => {
        setSubmitReschedule(false);
        let input = '';
        if (event.target.value.trim() !== '') {
            input = event.target.value;
        }
        setRescheduleComment(input);

    }
    ////////////////  Reschedule end///////////////



    /////////////////////////  Dependency Start & Stop /////////////////////////

    const handleChangeDependentStart = (index, subIndex, childIndex, event, feild, IsSingleTask) => {
        // setSubmitDependencyStart(false);
        let input = '';

        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            if (event.target) {
                if (IsSingleTask) {
                    values[index].DependencyComment = input;
                } else {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyComment = input;
                }
            }

        }
        else {
            if (IsSingleTask) {
                values[index].DependencyDate = event;
            } else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyDate = event;
            }

        }
        setInputFields(values);

    }


    const OpenDependentStartPopup = (index, subIndex, childIndex, intDependency, TNAId, TaskID, ChildId, IsSingleTask) => {

        showLoader();
        setSubmitDependencyStart(false);
        // if (intDependency === 1) {
        //     if (IsSingleTask) {
        //         values[index].DependencyComment = "";
        //         values[index].DependencyDate = "";
        //         values[index].intDependency = 0;
        //     } else {
        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyComment = "";
        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyDate = "";
        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency = 0;
        //     }
        //     setInputFields(values);
        //     hideLoader();
        // } else {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
            hideLoader();
        }
        else {
            let hasSingleTask = IsSingleTask ? 1 : 0;
            TNAService.getTNADependencyStartList(TNAId, TaskID, ChildId, hasSingleTask).then((response) => {
                if (response.data) {
                    setDependencyStartList(response.data);
                    if (IsSingleTask) {

                        values[index].IsDependencyStartPopup = true;
                        values[index].DependencyComment = "";
                        values[index].DependencyDate = intDependency === 1 ? new Date() : "";
                        values[index].intDependency = intDependency;
                    }
                    else {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsDependencyStartPopup = true;
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyComment = "";
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyDate = intDependency === 1 ? new Date() : "";
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency = intDependency;

                    }
                    setInputFields(values);
                    hideLoader();
                }
            }).catch(() => { hideLoader(); });

        }
        // }

    }

    const handleChangeMultiDependentStart = (index, subIndex, childIndex, event, feild, IsSingleTask) => {
        setSubmitDependencyStart(false);


        // let inputText = '';
        if (feild === "Comment") {
            //if (event.target.value.trim() !== '') { inputText = event.target.value; }

            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyComment = event.target.value;
        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyDate = event;
        }
        setInputFields(values);

    }

    const CloseDependencyStartPopup = (index, subIndex, childIndex, IsSingleTask) => {

        if (IsSingleTask) {
            values[index].IsDependencyStartPopup = false;
        } else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsDependencyStartPopup = false;
        }
        setInputFields(values);
        // values[index].ReschedulePopup = 0;
        // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReschedulePopup = 0;
        // setSubRescheduledetails({ TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '', RescheduleComment: '', Operation: 0 })
        // setRescheduleTaskDetailId(0);
        // setRescheduleTnaId(0);
        // setRescheduleTnaTask('');
        // setInputFields(values);
        //setRescheduleAction('Add');
    }

    const handleSaveDependencyStart = (index, subIndex, childIndex, IsSingleTask, intDependency, TaskName, inputField, childTask) => {

        showLoader();
        let ChildStatus = 0;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            ChildStatus = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
            TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
            TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
            ChildStatus = 2;

        } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
            TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
            TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
            TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

            ChildStatus = 3;

        } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
            TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
            || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
            || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
            || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
            || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
            ChildStatus = 4;
        }
        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
            TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
            ChildStatus = 5;
        } else if (values[index].DetailTypeID === 4) {
            ChildStatus = 7;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
            ChildStatus = 8;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
            ChildStatus = 9;
        }

        let strValue = "";
        let selected = 0;
        for (let data of MultiCloseDatas) {
            if (strValue === "") {
                if (data.isSelected === 1) {
                    strValue = data.subTaskInfoId.toString();
                    selected = selected + 1;
                }
            } else {
                if (data.isSelected === 1) {
                    strValue = strValue + "," + data.subTaskInfoId;
                }
            }
        }

        let dependencyComments = "";
        let dependencydate = null;
        let CommonId = 0;
        if (IsSingleTask) {
            dependencyComments = values[index].DependencyComment;
            dependencydate = values[index].DependencyDate;
        } else {
            dependencyComments = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyComment;
            dependencydate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyDate;
            CommonId = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
        }

        if (!dependencyComments || !dependencydate) {
            setSubmitDependencyStart(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            hideLoader();
            return false;
        }
        else {
            let TaskID = values[index].TaskID;
            let params = {
                TNAId: props.location.state.params.tnaid,
                TaskDetailId: TaskID,
                CommonId: CommonId,
                DependencyDate: convertUTCDateToLocalDate(new Date(dependencydate)),
                Comments: dependencyComments,
                IsSingleTask: IsSingleTask ? 1 : 0,
                DependencyStartstop: intDependency === 1 ? 0 : 1,
                CreatedBy: currentUser.employeeinformationID,
                TaskStatus: IsSingleTask === true ? 6 : ChildStatus,
                IdList: IsSingleTask === true ? inputField.TNARouteApplyID.toString() : values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId.toString()
            }

            TnaService.InsertTNADependencyStartHistory(params).then((res) => {
                if (res.data.outputResult === "1") {
                    let msg = intDependency === 1 ? 'Started' : 'Stopped'
                    if (IsSingleTask) {
                        values[index].IsDependencyStartPopup = false;
                        values[index].intDependency = intDependency === 1 ? 0 : 1;
                    }
                    else {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsDependencyStartPopup = false;
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency = intDependency === 1 ? 0 : 1;
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsDepandency = 0;
                    }
                    setInputFields(values);
                    Nodify('Success!', 'success', "Dependency " + msg + " Successfully");
                    hideLoader();
                } else if (res.data.outputResult === "2") {
                    Nodify('Warning!', 'warning', "This dependency date already added");
                    hideLoader();
                } else if (res.data.outputResult === "-1") {
                    Nodify('Warning!', 'warning', "Error Occured");
                    hideLoader();
                }
            })
        }
    }

    const OpenAllDependentStartPopup = (index, subIndex, childIndex, intDependency, TNAId, TaskID, ChildId, IsSingleTask) => {
        debugger;
        showLoader();
        if (intDependency === 1) {
            // if (IsSingleTask) {
            //     values[index].hasAllDependencyStart = 0;
            // } else {
            //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart = 0;
            // }
            // setInputFields(values);
            hideLoader();
        } else {
            if (values[index].TaskID === 0) {
                Nodify('Warning!', 'warning', 'Please select the task name.');
                hideLoader();
            }
            else {
                // let hasSingleTask = IsSingleTask ? 1 : 0;

                if (IsSingleTask) {

                    values[index].hasAllDependencyStartPopup = true;
                }
                else {

                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStartPopup = true;
                }
                setInputFields(values);
                hideLoader();
            }
        }
    }




    const CloseAllDependencyStartPopup = (index, subIndex, childIndex, IsSingleTask, dependencyComments, dependencydate) => {

        if (IsSingleTask) {
            values[index].hasAllDependencyStartPopup = false;
            if (dependencyComments !== '' && dependencydate !== '') {
                values[index].hasAllDependencyStart = 1;
            }
        } else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStartPopup = false;
            if (dependencyComments !== '' && dependencydate !== '') {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart = 1;
            }

        }
        setInputFields(values);

    }

    const handleSaveAllDependencyStart = (index, subIndex, childIndex, TaskID, IsSingleTask) => {

        showLoader();
        let dependencyComments = "";
        let dependencydate = null;
        let CommonIdList = "";

        dependencyComments = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyComment;
        dependencydate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyDate;

        if (!dependencyComments || !dependencydate) {
            setSubmitDependencyStart(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            hideLoader();
            return false;
        }
        else {

            if (IsSingleTask) {
                values[index].hasAllDependencyStart = 1;

            } else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart = 1;
            }
            let ManualCloseData = [...MultiCloseDatas];
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose === 1) {

                ManualCloseData.map((item) => {
                    // if (item.dependencyStartstop !== 1) {
                    if (CommonIdList === "") {
                        CommonIdList = item.subTaskInfoId.toString();

                    } else {
                        CommonIdList = CommonIdList + "," + item.subTaskInfoId.toString();
                    }
                    item.dependencyStartstop = 1;
                    item.IsDepandency = 0;

                    //  }

                });
            }
            else {
                let MultExFactorySKUData = [...MultExFactorySKUList];
                if (values[index].DetailTypeID === 4) {
                    MultExFactorySKUData = MultExFactorySKUData.filter(d => d.dependencyStartstop !== 1);
                    MultExFactorySKUData.map((item) => {
                        if (CommonIdList === "") {
                            CommonIdList = item.tnaExFactoryId.toString();
                        } else {
                            CommonIdList = CommonIdList + "," + item.tnaExFactoryId.toString();
                        }
                        item.dependencyStartstop = 1;
                        item.IsDepandency = 0;
                    });
                }
            }

            let params = {
                TNAId: props.location.state.params.tnaid,
                TaskDetailId: TaskID,
                CommonIdList: CommonIdList,
                DependencyDate: convertUTCDateToLocalDate(new Date(dependencydate)),
                Comments: dependencyComments,
                IsSingleTask: IsSingleTask ? 1 : 0,
                DependencyStartstop: 1,
                CreatedBy: currentUser.employeeinformationID
            }

            TnaService.InsertTNAMultiDependencyStartHistory(params).then((res) => {
                if (res.data.outputResult === "1") {

                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStartPopup = false;
                    // if (values[index].DetailTypeID === 4) {
                    //     setMultExFactorySKUList(MultExFactorySKUData);
                    // } else {
                    setMultiCloseDatas(ManualCloseData);
                    // }
                    setInputFields(values);
                    Nodify('Success!', 'success', "Dependency Added Successfully");
                    hideLoader();
                } else if (res.data.outputResult === "2") {
                    Nodify('Warning!', 'warning', "This dependency date already added");
                    hideLoader();
                } else if (res.data.outputResult === "-1") {
                    Nodify('Warning!', 'warning', "Error Occured");
                    hideLoader();
                }
            })
        }
    }

    function getSelectedDependencyMap(SelectedDependency) {
        let Dependency = true;
        if (SelectedDependency) {
            let arrstatus = [];
            arrstatus = SelectedDependency.split(',');
            if (arrstatus.length > 0) {
                arrstatus.map(element => {
                    let val = [];
                    val = element.split('-');
                    let valIndex = values[parseInt(val[0]) - 1].StatusID
                    if (valIndex !== 3) {
                        Dependency = false;
                    }
                });
            }

        }

        return Dependency;
    }

    /////////////////////////  End /////////////////////////


    // #region --------------- New PO SKU Open -------------------------------
    const handleNewPOSKUOpen = () => {
        showLoader();
        dispatch(loadNewPurchaseOrderSKUList(props.location.state.params.tnaid, props.location.state.params.styleId))
            .then(data => {
                if (data && data.item1 && data.item1.length > 0) {
                    hideLoader();
                    setPOSKUPopup(true);
                } else {
                    hideLoader();
                    Nodify('Warning!', 'warning', 'No New SKU In Purchase Order.');
                }
            });
    }

    const handleNewSKUOpen = async () => {

        showLoader();
        await TnaService.LoadNewStyleProgramtask(props.location.state.params.tnaid, props.location.state.params.styleId).then((res) => {
            if (res.data) {
                let PantoneList = []
                let ind = 0;
                res.data.outputProgramtask.map((x, i) => {
                    PantoneList.push({
                        ParentTaskName: x.parentTaskName,
                        IsExpanded: false,
                        index: ind,
                        SubTaskList: []
                    })
                    res.data.tnaProgramsubtask.filter(y => y.parentTaskName === x.parentTaskName).map((xy, ix) => {
                        PantoneList[ind].SubTaskList.push({
                            ParentTaskName: xy.parentTaskName,
                            StyleProgramID: xy.styleProgramID,
                            Typename: xy.typename,
                            ColorAndlog: xy.colorAndlog,
                            index: ind,
                            SubIndex: ix,
                            PantoneTypeName: xy.pantoneTypeName,
                            Childtask: []
                        })
                        res.data.tnaProgramsubChiltask && res.data.tnaProgramsubChiltask.filter(yx => yx.parentTaskName === x.parentTaskName).map((xyZ, ixz) => {
                            PantoneList[ind].SubTaskList[ix].Childtask.push({
                                ParentTaskName: xyZ.parentTaskName,
                                SubDetails: xyZ.subDetails,
                                StyleProgramID: xyZ.styleProgramID,
                                index: ind,
                                SubIndex: ix,
                            })
                        })
                    })
                    ind++;
                })
                setSkuTask(PantoneList)
                hideLoader()
            }
            hideLoader()
        })
        setNewskuTask(!newskuTask);
    }
    function handleSKUOpen() {
        setNewskuTask(false);
    }

    const handleAddPOSKUCallback = () => {

        setPOSKUPopup(false);

        let POSKUList = NewPurchaseOrderSKUList.filter(d => d.isChecked === true);
        let uniquePOSKUList = [];
        POSKUList.map(x => uniquePOSKUList.filter(a => a.skuId === x.skuId).length > 0 ? null : uniquePOSKUList.push(x));
        let hasExpanded = false;
        // Ex-Factory  (Detail Type 4) filter(d => d.DetailTypeID === 4)
        values && values.map((element, index) => {

            hasExpanded = false;
            if (element.IsSingleTask === false && element.DetailTypeID === 4) { // Ex-Factory  (Detail Type)

                POSKUList && POSKUList.map((child, p) => {
                    let hasTNASku = !!!values[index].SubTaskList[0].ChildTaskList.find(d => d.SubId === child.skuId && d.ChildId === 0 && d.PurchaseOrderSkuID === child.purchaseOrderSkuId);

                    if (hasTNASku) {

                        let currentchildlen = values[index].SubTaskList[0].ChildTaskList.length;
                        let lastindex = values[index].SubTaskList[0].ChildTaskList[currentchildlen - 1].ChildIndex;
                        values[index].SubTaskList[0].ChildTaskList.push({
                            ChildId: 0,
                            SubId: child.skuId,
                            Name: child.skuName + ' - ' + child.otherDetails,
                            TaskName: element.strTaskName,
                            TaskID: element.TaskID,
                            MainId: 0,
                            Duration: 1,
                            EndDate: new Date(),
                            StartDate: new Date(),
                            PreStartDate: new Date(),
                            PreEndDate: new Date(),
                            arrDependency: [],
                            TaskHolderList: '',
                            TaskHolderOrFollower: '',
                            TaskHolderOrFollowerTxt: "",
                            TaskHolderOwnerTxtList: "",
                            TaskHolderIDList: "",
                            FollowerIDList1: "",
                            FollowerIDList2: "",
                            TaskHolderBuyer: "",
                            FollowerCube: "",
                            FollowerSupplier: "",
                            arrTaskHolderBuyer: [],
                            arrFollowerCube: [],
                            arrFollowerSupplier: [],
                            TaskHolderBuyerTxt: '',
                            FollowerCubeTxt: '',
                            FollowerSupplierTxt: '',
                            TaskHolderOwnerIdList: '',
                            titleEmployee1: '',
                            titleEmployee2: '',
                            titleEmployee3: "",
                            IsTHFirstTime: 0,
                            DependencyCount: 0,
                            SelectedDependency: '',
                            LeaveDays: 0,
                            LeaveDaysTxt: "",
                            StatusID: 1,
                            AWBStatus: '',
                            IsAddChildTaskOpen: 0,
                            StrikeOffOrTrimInfoId: 0,
                            drpChildTaskList: [],
                            IsManualClose: 0,
                            IsExFactoryForm: 0,
                            ManualCloseComment: '',
                            EndDateChange: 0,
                            IsReviced: 0,
                            IsRescheduled: 0,
                            DependencyDate: "",
                            DependencyComment: "",
                            SKUIdList: "",
                            PurchaseOrderSkuID: child.purchaseOrderSkuId,
                            StyleStrikeOffGarmentSizeIdList: "",
                            hasAutoOpen: true,
                            IsChildDepandencyOpen: 0,
                            ChildIndex: 0,
                            // arrDependency: [],
                            //  DependencyCount: 0,
                            //  SelectedDependency: '',
                            hasSKUNotMatch: false,
                            AssignedDepandency: '',
                            IsDepandency: 0,
                            ReviceAdded: 0,
                            RescheduleAdded: 0,
                            AutoText: ''
                        });

                        getCurrentChildTask.value = child.skuId;
                        getCurrentChildTask.lablel = child.skuName;
                        ReassignIndexonAddDeleteTask(0, 0, 0, 'Add', lastindex);
                    }
                    hasExpanded = true;
                });
            } else if (element.IsSingleTask === false && element.DetailTypeID === 2) {// Sku (Detail Type)


                if (element.strTaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION  // Sample Request in style
                    || element.strTaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL
                    || element.strTaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION
                    || element.strTaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL
                    || element.strTaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION
                    || element.strTaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                    || element.strTaskName.toLowerCase() === StaticTask.SMS_SUBMISSION
                    || element.strTaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                    || element.strTaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION
                    || element.strTaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                    || element.strTaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION
                    || element.strTaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                    || element.strTaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION
                    || element.strTaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL
                ) {
                    POSKUList && POSKUList.map((item, i) => {
                        let sampleRequestID = 2;
                        if (element.strTaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION
                            || element.strTaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL

                        ) {
                            sampleRequestID = 1;
                        }
                        else if (element.strTaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION
                            || element.strTaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL) {
                            sampleRequestID = 3;
                        }
                        else if (element.strTaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION
                            || element.strTaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL) {
                            sampleRequestID = 4;
                        }
                        else if (element.strTaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION
                            || element.strTaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL) {
                            sampleRequestID = 5;
                        }
                        else if (element.strTaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION
                            || element.strTaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
                            sampleRequestID = 6;
                        }
                        let hasTNASku = !!!values[index].SubTaskList[0].ChildTaskList.find(d => d.SubId === item.skuId);
                        if (hasTNASku) { // Check Already used skuId

                            let hasStyleSRSku = !!styleSampleRequestSKUList.find(d => d.skuId === item.skuId && d.sampleRequestID === sampleRequestID);
                            if (hasStyleSRSku) // Check Mapped  skuId In Sample Request Style
                            {
                                let currentchildlen = values[index].SubTaskList[0].ChildTaskList.length;
                                let lastindex = values[index].SubTaskList[0].ChildTaskList[currentchildlen - 1].ChildIndex;
                                values[index].SubTaskList[0].ChildTaskList.push({
                                    ChildId: 0,
                                    SubId: item.skuId,
                                    Name: item.skuName,
                                    TaskName: element.strTaskName,
                                    TaskID: element.TaskID,
                                    MainId: 0,
                                    Duration: 1,
                                    EndDate: new Date(),
                                    StartDate: new Date(),
                                    PreStartDate: new Date(),
                                    PreEndDate: new Date(),
                                    arrDependency: [],
                                    TaskHolderList: '',
                                    TaskHolderOrFollower: '',
                                    TaskHolderOrFollowerTxt: "",
                                    TaskHolderOwnerTxtList: "",
                                    TaskHolderIDList: "",
                                    FollowerIDList1: "",
                                    FollowerIDList2: "",
                                    TaskHolderBuyer: "",
                                    FollowerCube: "",
                                    FollowerSupplier: "",
                                    arrTaskHolderBuyer: [],
                                    arrFollowerCube: [],
                                    arrFollowerSupplier: [],
                                    TaskHolderBuyerTxt: '',
                                    FollowerCubeTxt: '',
                                    FollowerSupplierTxt: '',
                                    TaskHolderOwnerIdList: '',
                                    titleEmployee1: '',
                                    titleEmployee2: '',
                                    titleEmployee3: "",
                                    IsTHFirstTime: 0,
                                    DependencyCount: 0,
                                    SelectedDependency: '',
                                    LeaveDays: "",
                                    LeaveDaysTxt: "",
                                    StatusID: 1,
                                    AWBStatus: '',
                                    IsAddChildTaskOpen: 0,
                                    StrikeOffOrTrimInfoId: 0,
                                    drpChildTaskList: [],
                                    IsManualClose: 0,
                                    IsExFactoryForm: 0,
                                    ManualCloseComment: '',
                                    EndDateChange: 0,
                                    IsReviced: 0,
                                    IsRescheduled: 0,
                                    DependencyDate: "",
                                    DependencyComment: "",
                                    SKUIdList: "",
                                    PurchaseOrderSkuID: 0,
                                    StyleStrikeOffGarmentSizeIdList: "",
                                    hasAutoOpen: true,
                                    IsChildDepandencyOpen: 0,
                                    ChildIndex: 0,
                                    // arrDependency: [],
                                    //  DependencyCount: 0,
                                    // SelectedDependency: '',
                                    hasSKUNotMatch: false,
                                    AssignedDepandency: '',
                                    IsDepandency: 0,
                                    ReviceAdded: 0,
                                    RescheduleAdded: 0
                                });
                                getCurrentChildTask.value = item.skuId;
                                getCurrentChildTask.lablel = item.skuName;
                                ReassignIndexonAddDeleteTask(0, 0, 0, 'Add', lastindex);
                                hasExpanded = true;
                                // let CurrChildIndex = values[index].SubTaskList[0].ChildTaskList.length - 1;
                                // commonCalculationParentAndChild(values[index], values[index].SubTaskList[0].ChildTaskList[CurrChildIndex], index, 0, CurrChildIndex, false, false, false, index);
                            }
                        }
                    });

                } else { // Other Sku base Task
                    uniquePOSKUList && uniquePOSKUList.map((item, i) => {
                        let hasTNASku = !!!values[index].SubTaskList[0].ChildTaskList.find(d => d.SubId === item.skuId);
                        if (hasTNASku) // Check Mapped  skuId In Sample Request Style
                        {
                            let currentchildlen = values[index].SubTaskList[0].ChildTaskList.length;
                            let lastindex = values[index].SubTaskList[0].ChildTaskList[currentchildlen - 1].ChildIndex;
                            values[index].SubTaskList[0].ChildTaskList.push({
                                ChildId: 0,
                                SubId: item.skuId,
                                Name: item.skuName,
                                TaskName: element.strTaskName,
                                TaskID: element.TaskID,
                                MainId: 0,
                                Duration: 1,
                                EndDate: new Date(),
                                StartDate: new Date(),
                                PreStartDate: new Date(),
                                PreEndDate: new Date(),
                                arrDependency: [],
                                TaskHolderList: '',
                                TaskHolderOrFollower: '',
                                TaskHolderOrFollowerTxt: "",
                                TaskHolderOwnerTxtList: "",
                                TaskHolderIDList: "",
                                FollowerIDList1: "",
                                FollowerIDList2: "",
                                TaskHolderBuyer: "",
                                FollowerCube: "",
                                FollowerSupplier: "",
                                arrTaskHolderBuyer: [],
                                arrFollowerCube: [],
                                arrFollowerSupplier: [],
                                TaskHolderBuyerTxt: '',
                                FollowerCubeTxt: '',
                                FollowerSupplierTxt: '',
                                TaskHolderOwnerIdList: '',
                                titleEmployee1: '',
                                titleEmployee2: '',
                                titleEmployee3: "",
                                IsTHFirstTime: 0,
                                DependencyCount: 0,
                                SelectedDependency: '',
                                LeaveDays: "",
                                LeaveDaysTxt: "",
                                StatusID: 1,
                                AWBStatus: '',
                                IsAddChildTaskOpen: 0,
                                StrikeOffOrTrimInfoId: 0,
                                drpChildTaskList: [],
                                IsManualClose: 0,
                                IsExFactoryForm: 0,
                                ManualCloseComment: '',
                                EndDateChange: 0,
                                IsReviced: 0,
                                IsRescheduled: 0,
                                DependencyDate: "",
                                DependencyComment: "",
                                SKUIdList: "",
                                PurchaseOrderSkuID: 0,
                                StyleStrikeOffGarmentSizeIdList: "",
                                hasAutoOpen: true,
                                IsChildDepandencyOpen: 0,
                                ChildIndex: 0,
                                arrDependency: [],
                                DependencyCount: 0,
                                SelectedDependency: '',
                                hasSKUNotMatch: false,
                                AssignedDepandency: '',
                                IsDepandency: 0,
                                ReviceAdded: 0,
                                RescheduleAdded: 0
                            });
                            getCurrentChildTask.value = item.skuId;
                            getCurrentChildTask.lablel = item.skuName;
                            ReassignIndexonAddDeleteTask(0, 0, 0, 'Add', lastindex);
                            hasExpanded = true;
                        }
                    });

                }

            } else if (element.IsSingleTask === false && element.DetailTypeID === 3) {// item (Detail Type)
                if (element.strTaskName.toLowerCase() !== StaticTask.LAB_DIP_SUBMISSION
                    && element.strTaskName.toLowerCase() !== StaticTask.LAB_DIP_APPROVAL
                    && element.strTaskName.toLowerCase() !== StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT
                    && element.strTaskName.toLowerCase() !== StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                    && element.strTaskName.toLowerCase() !== StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT
                    && element.strTaskName.toLowerCase() !== StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                ) {

                    let ImageUrl = "";
                    if (element.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || element.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || element.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || element.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || element.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                        ImageUrl = 'Images/Style/Strikeoff/';
                    } else if (element.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || element.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || element.strTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || element.strTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || element.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || element.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || element.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                        ImageUrl = 'Images/Style/Trim/';
                    }

                    let styleSOAndTrimSKUListFilter = styleStrikeOffAndTrimSKUList.filter(d => d.taskId === element.TaskID);
                    uniquePOSKUList && uniquePOSKUList.map((item, i) => {

                        element && element.SubTaskList.map((subItem, j) => {

                            styleSOAndTrimSKUListFilter = styleSOAndTrimSKUListFilter.filter(d => d.colorGroupIDOrLogoID !== subItem.MainId);
                            let uniqueStyleSOAndTrimSKUListFilter = [];
                            styleSOAndTrimSKUListFilter.map(x => uniqueStyleSOAndTrimSKUListFilter.filter(a => a.colorGroupIDOrLogoID === x.colorGroupIDOrLogoID).length > 0 ? null : uniqueStyleSOAndTrimSKUListFilter.push(x));
                            styleSOAndTrimSKUListFilter = uniqueStyleSOAndTrimSKUListFilter;

                            subItem && subItem.ChildTaskList.map((childSubItem, k) => {
                                let TNASkuIdList = childSubItem.SKUIdList && childSubItem.SKUIdList.split(',');
                                let hasTNASku = !TNASkuIdList.includes(item.skuId.toString());
                                if (hasTNASku) {
                                    let styleStrikeOffAndTrimList = styleStrikeOffAndTrimSKUList.filter(d => d.taskId === element.TaskID && d.subId === childSubItem.SubId && d.colorGroupIDOrLogoID === childSubItem.MainId && d.strikeOffIdOrTrimInfoId === childSubItem.StrikeOffOrTrimInfoId);
                                    let styleSkuIdList = styleStrikeOffAndTrimList && styleStrikeOffAndTrimList[0] && styleStrikeOffAndTrimList[0].skuIdList && styleStrikeOffAndTrimList[0].skuIdList.split(',');
                                    let hasStyleSku = styleSkuIdList && styleSkuIdList.includes(item.skuId.toString());
                                    if (hasStyleSku) {
                                        values[index].SubTaskList[j].ChildTaskList[k].SKUIdList = values[index].SubTaskList[j].ChildTaskList[k].SKUIdList + "," + item.skuId.toString();
                                        if (element.strTaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST || element.strTaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                                            values[index].SubTaskList[j].ChildTaskList[k].Name = values[index].SubTaskList[j].ChildTaskList[k].Name + "," + item.skuName;
                                        }
                                        values[index].SubTaskList[j].ChildTaskList[k].hasAutoOpen = true;
                                        hasExpanded = true;


                                    }
                                }

                            });
                        });

                        // New 2nd Sub row addd IN (Strikeoff / Trim)
                        if (styleSOAndTrimSKUListFilter && styleSOAndTrimSKUListFilter.length > 0) {

                            styleSOAndTrimSKUListFilter.map((subItem, j) => {

                                let TNASkuIdList = subItem.skuIdList && subItem.skuIdList.split(',');
                                let hasTNASku = TNASkuIdList.includes(item.skuId.toString());

                                if (hasTNASku) {
                                    // New 3nd Sub Child row addd IN (Strikeoff / Trim)
                                    let newStyleSOAndTrimSKUListFilter = styleStrikeOffAndTrimSKUList.filter(d => d.taskId === element.TaskID && d.colorGroupIDOrLogoID === subItem.colorGroupIDOrLogoID);
                                    let newChildTaskList = [];

                                    newStyleSOAndTrimSKUListFilter && newStyleSOAndTrimSKUListFilter.map((childTask, k) => {

                                        newChildTaskList.push({
                                            ChildId: 0,
                                            SubId: childTask.subId,
                                            Name: childTask.name,
                                            TaskName: element.strTaskName,
                                            TaskID: element.TaskID,
                                            MainId: childTask.colorGroupIDOrLogoID,
                                            Duration: 1,
                                            EndDate: new Date(),
                                            StartDate: new Date(),
                                            PreStartDate: new Date(),
                                            PreEndDate: new Date(),
                                            arrDependency: [],
                                            TaskHolderList: '',
                                            TaskHolderOrFollower: '',
                                            TaskHolderOrFollowerTxt: "",
                                            TaskHolderOwnerTxtList: "",
                                            TaskHolderIDList: "",
                                            FollowerIDList1: "",
                                            FollowerIDList2: "",
                                            TaskHolderBuyer: "",
                                            FollowerCube: "",
                                            FollowerSupplier: "",
                                            arrTaskHolderBuyer: [],
                                            arrFollowerCube: [],
                                            arrFollowerSupplier: [],
                                            TaskHolderBuyerTxt: '',
                                            FollowerCubeTxt: '',
                                            FollowerSupplierTxt: '',
                                            TaskHolderOwnerIdList: '',
                                            titleEmployee1: '',
                                            titleEmployee2: '',
                                            titleEmployee3: "",
                                            IsTHFirstTime: 0,
                                            DependencyCount: 0,
                                            SelectedDependency: '',
                                            LeaveDays: "",
                                            LeaveDaysTxt: "",
                                            StatusID: 1,
                                            AWBStatus: '',
                                            IsAddChildTaskOpen: 0,
                                            StrikeOffOrTrimInfoId: subItem.strikeOffIdOrTrimInfoId,
                                            drpChildTaskList: [],
                                            IsManualClose: 0,
                                            IsExFactoryForm: 0,
                                            ManualCloseComment: '',
                                            EndDateChange: 0,
                                            IsReviced: 0,
                                            IsRescheduled: 0,
                                            DependencyDate: "",
                                            DependencyComment: "",
                                            SKUIdList: item.skuId && item.skuId.toString(),
                                            PurchaseOrderSkuID: 0,
                                            StyleStrikeOffGarmentSizeIdList: childTask.styleStrikeOffGarmentSizeIdList && childTask.styleStrikeOffGarmentSizeIdList.toString(),
                                            hasAutoOpen: true,
                                            IsChildDepandencyOpen: 0,
                                            ChildIndex: 0,
                                            arrDependency: [],
                                            DependencyCount: 0,
                                            SelectedDependency: '',
                                            hasSKUNotMatch: false,
                                            AssignedDepandency: '',
                                            IsDepandency: 0,
                                            ReviceAdded: 0,
                                            RescheduleAdded: 0,
                                        });


                                    });

                                    values[index].SubTaskList.push({
                                        ParentId: 0,
                                        TaskID: element.TaskID,
                                        MainId: subItem.colorGroupIDOrLogoID,
                                        Code: subItem.code,
                                        Color: "",
                                        ColorOrName: "",
                                        ImagePath: subItem.imagePath,
                                        ImageUrl: ImageUrl,
                                        StrikeOffOrTrimInfoId: subItem.strikeOffIdOrTrimInfoId,
                                        IsExpanded: true,
                                        hasAutoOpen: true,
                                        ChildTaskList: newChildTaskList
                                    });
                                    hasExpanded = true;
                                }
                            })

                        }
                    })

                }
            }

            if (hasExpanded) {
                //setInputFields(values);
                handleExpandedOnlyToggle(index);
            }

        });

        setInputFields(values);

        // commonCalculation(false, false, 0);


    }
    const handleClosePOSKUPopupCallback = () => {
        setPOSKUPopup(false);
    }

    const handleChildRowToggle = (index, subIndex, childIndex, hasAutoOpen) => {

        if (hasAutoOpen && values[index].SubTaskList[subIndex] !== undefined) {
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex] !== undefined) {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAutoOpen = false;
            }
            setInputFields(values);
        }
    }


    // #endregion ---------- New PO SKU Open  End -----------------

    // #region --------------- Remove PO SKU Open -------------------------------
    const handleRemovePOSKUOpen = () => {
        showLoader();

        let CurrentChildvalues = [];
        values.map((Main) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({
                        PurchaseOrderSkuID: SubChild.PurchaseOrderSkuID
                    })
                })
            })
        })

        let data = [];
        TnaService.LoadExistingPurchaseOrderSKUList(props.location.state.params.tnaid, props.location.state.params.styleId).then((response) => {
            if (response.data) {
                response.data.map((item => {
                    if (CurrentChildvalues.filter(x => x.PurchaseOrderSkuID === item.purchaseOrderSkuId).length > 0) {
                        data.push(item);
                    }
                }))
                setExistingSkuList(data);
                setRemovePOSKUPopup(true);
                hideLoader();
            }
        }).catch(() => { })

    }

    const handleSKUSelect = (e, index) => {
        let value = [...ExistingSkuList]
        let isChecked = false;

        if (e.target.checked) {
            isChecked = true;
        }
        value[index].isChecked = isChecked;
        setExistingSkuList(value);
    }

    const handleSKUSelectAll = (e) => {
        let isChecked = false;

        if (e.target.checked) {
            isChecked = true;
        }


        if (isChecked === true) {
            ExistingSkuList.map(item => {
                item.isChecked = true
            })
            setPOSkuIsAllChecked(true);
        }
        else {
            ExistingSkuList.map(item => {
                item.isChecked = false
            })
            setPOSkuIsAllChecked(false);
        }
    }

    const RemovePoSku = () => {
        ExistingSkuList.filter(x => x.isChecked === true).map(item => {
            values.map((Main, Mainindex) => {
                Main.SubTaskList.map((Child, ChildIndex) => {
                    Child.ChildTaskList.map((SubChild, SubChildIndex) => {
                        if (item.purchaseOrderSkuId === SubChild.PurchaseOrderSkuID && SubChild.StatusID !== 3) {
                            Child.ChildTaskList.splice(SubChildIndex, 1)
                            ReassignIndexonAddDeleteTask(Mainindex, ChildIndex, SubChildIndex, 'Remove', SubChild.ChildIndex, SubChild.RownoforAllTasks);

                        }

                    })
                })
            })
        })
        setRemovePOSKUPopup(false);
        // setInputFields(values);
    }



    // #endregion ---------- Remove PO SKU Open  End -----------------



    // #region---------------------  Popup open and Sku And Style Detail get --------------------------------
    const handleSkuAndStyleDetails = (SubId, MainId, StrikeOffIdOrTrimInfoId, strTaskName, SKUIdList, StyleStrikeOffGarmentSizeIdList, childTask) => {

        showLoader();
        if (SKUIdList) {
            dispatch(getSkuAndStyleDetails({
                TNAId: props.location.state.params.tnaid, SubId: SubId,
                MainId: MainId, StrikeOffIdOrTrimInfoId: StrikeOffIdOrTrimInfoId,
                TaskName: strTaskName, StyleId: props.location.state.params.styleId
                , SKUIdList: SKUIdList, StyleStrikeOffGarmentSizeIdList: StyleStrikeOffGarmentSizeIdList

            }))
                .then(data => {
                    if (data) {
                        setSkuAndStyleDetailPopup(true);
                        setSkuAndStyleDetails(data);
                        hideLoader();
                    }
                });
        } else {
            hideLoader();
            let Title = "Warning!";
            let message = "No SKU in " + strTaskName;
            let type = "warning";
            Nodify(Title, type, message);
        }
    };

    const hideSkuAndStyleDetail = () => {
        setSkuAndStyleDetailPopup(false);
    }

    // #endregion -------------------  End Popup open and Sku And Style Detail get -------------------------------------

    // #region---------------------  Ex-Factory Form --------------------------------
    // const handleExFactoryFormOpen = (index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType) => {
    //     debugger;
    //     let Operation = 1
    //     let Dependency = false;
    //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

    //         if (values[index].SelectedDependency) {
    //             let arrstatus = [];
    //             arrstatus = values[index].SelectedDependency.split(',');
    //             if (arrstatus.length > 0) {
    //                 arrstatus.forEach(element => {

    //                     let val = [];
    //                     val = element.split('-');
    //                     let valIndex = values[parseInt(val[0]) - 1].StatusID
    //                     if (valIndex !== 3) {
    //                         Dependency = true;
    //                     }
    //                 });
    //             }

    //         }

    //         else if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency) // Auto dependency set check
    //         { Dependency = true; }

    //         if (Dependency) {
    //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
    //         }
    //     }

    //     if(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency !== '')
    //         {
    //             SelectedDependency = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency;
    //         }

    //     if (!Dependency) {
    //         //  SubId(SKUID) set 0 for entire record showed
    //         let params = {
    //             Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
    //             BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
    //             SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
    //             SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
    //             tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
    //             Isproduction: IsProduction, TnaId: props.location.state.params.tnaid, BuyerName: getStyleInfo.buyerName
    //         };
    //         setAddInspectionListParam(params);
    //         // TnaService.GetSubChildMultExFactoryFormSKUList(params).then((response) => {

    //         //    if (response.data) {

    //         // let data = response.data.map((item) => {
    //         //     if (item.tnaExFactoryId === TNAExFactoryId) {
    //         //         item.isSelected = 1;
    //         //         return item;
    //         //     } else {
    //         //         return item;
    //         //     }
    //         // });
    //         // let isSelectAll = 0;
    //         // if (data) {
    //         //     isSelectAll = !!!data.find(d => d.isSelected === 0);
    //         //     isSelectAll = isSelectAll ? 1 : 0;
    //         // }
    //         // setselectall(isSelectAll);
    //         // if (Status !== 3) {
    //         //     setMultExFactorySKUList(data);
    //         // }
    //         // else {
    //         //     setMultExFactorySKUList(data.filter(x => x.tnaExFactoryId === TNAExFactoryId));
    //         // }

    //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsExFactoryForm = 1;
    //         setInputFields(values);
    //         //  }
    //         getInspectionInfo.TaskStatus = OperationType;
    //         setInspectionInfo(getInspectionInfo);
    //         //}).catch(() => { });
    //     }
    // };

    const handleExFactoryFormOpen = (
        index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, arrDependency
    ) => {
        debugger;

        let Operation = 1;
        let Dependency = false;
        const childTaskData = values[index].SubTaskList[subIndex].ChildTaskList[childIndex];
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);
        let CurrentChildvalues = [];
        values.map((Main) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({
                        StatusID: SubChild.StatusID,
                        ChildId: SubChild.ChildId,
                        IsDepandency: SubChild.IsDepandency,
                        Depandency: SubChild.SelectedDependency,
                        intDependency: SubChild.intDependency,

                    })
                })
            })
        })
        

        // Check dependency if intDependency is 0
        if (childTaskData.intDependency === 0) {
            if (values[index].SelectedDependency) {
                const dependencies = values[index].SelectedDependency.split(',');

                for (const element of dependencies) {
                    const [valIndex] = element.split('-').map(val => parseInt(val) - 1);
                    if (values[valIndex].StatusID !== 3) {
                        Dependency = true;
                        break;
                    }
                }
            } else if (childTaskData.AssignedDepandency) {
                Dependency = true; // Auto dependency set check
            }

            if (Dependency) {
                //Nodify('Warning!', 'warning', "Dependency Tasks should be closed first");
                Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
                return;
            }
        }

        // Check if AssignedDependency exists and set it to SelectedDependency
        if (childTaskData.AssignedDepandency !== '') {
            SelectedDependency = childTaskData.AssignedDepandency;
        }

        // Proceed if no dependency
        if (!Dependency) {
            const params = {
                Operation: childTaskData.StatusID !== 3 ? 1 : 2,
                BuyerId: getTNABuyerID,
                BrandId: getTNABrandID,
                SeasonId: getTNASeasonID,
                SupplierId: getTNASupplierID,
                TaskId: TaskID,
                StyleId: props.location.state.params.styleId,
                SkuId: 0,
                TaskOwnerIdList: TaskOwnerIdList,
                TaskHolderId: TaskHolderId,
                tnaExFactoryId: TNAExFactoryId,
                status: Status,
                selectedDependency: SelectedDependency,
                NewID: SubId,
                Isproduction: IsProduction,
                TnaId: props.location.state.params.tnaid,
                BuyerName: getStyleInfo.buyerName
            };

            setAddInspectionListParam(params);
            setSubTaskList(CurrentChildvalues);
            setskusingleID(childTaskData.PurchaseOrderSkuID.toString())
            // Uncomment and modify the following block as needed for handling response
            /*
            TnaService.GetSubChildMultExFactoryFormSKUList(params).then((response) => {
                if (response.data) {
                    let data = response.data.map(item => {
                        if (item.tnaExFactoryId === TNAExFactoryId) {
                            item.isSelected = 1;
                        }
                        return item;
                    });
    
                    const isSelectAll = !!!data.find(d => d.isSelected === 0) ? 1 : 0;
                    setselectall(isSelectAll);
    
                    if (Status !== 3) {
                        setMultExFactorySKUList(data);
                    } else {
                        setMultExFactorySKUList(data.filter(x => x.tnaExFactoryId === TNAExFactoryId));
                    }
                }
            }).catch(() => {});
            */

            // Update the form state
            childTaskData.IsExFactoryForm = 1;
            setInputFields(values);

            // Update inspection info
            getInspectionInfo.TaskStatus = OperationType;
            setInspectionInfo(getInspectionInfo);
        }
    };



    // function handleQCInspectionFormOpen(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, hasAllDependencyStart) {
    //     debugger
    //     let Operation = 1
    //     let Dependency = false;
    //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

    //         if (values[index].SelectedDependency) {
    //             let arrstatus = [];
    //             arrstatus = values[index].SelectedDependency.split(',');
    //             if (arrstatus.length > 0) {
    //                 arrstatus.forEach(element => {

    //                     let val = [];
    //                     val = element.split('-');
    //                     let valIndex = values[parseInt(val[0]) - 1].StatusID
    //                     if (valIndex !== 3) {
    //                         Dependency = true;
    //                     }
    //                 });
    //             }

    //         }

    //         else if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency) // Auto dependency set check
    //         { Dependency = true; }

    //         if (Dependency) {
    //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
    //         }
    //     }


    //     if(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency !== '')
    //         {
    //             SelectedDependency = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency;
    //         }

    //     if (!Dependency) {
    //         //  SubId(SKUID) set 0 for entire record showed
    //         let params = {
    //             Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
    //             BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
    //             SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
    //             SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
    //             tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
    //             Isproduction: IsProduction, TnaId: props.location.state.params.tnaid,
    //         };
    //         setQCAddInspectionListParam(params);
    //         setQAInspectionPageView('AddInspection');
    //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQCinsectionFactory = 1;
    //         getQCInspectionInfo.TaskStatus = OperationType;
    //         getQCInspectionInfo.TaskType = TaskName;//is for Midline or pilotrun or set sizecheck
    //         setInputFields(values);
    //         setQCInspectionInfo(getQCInspectionInfo);
    //     }
    // }

    function handleQCInspectionFormOpen(
        index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, hasAllDependencyStart, arrDependency
    ) {
        debugger;

        let Operation = 1;
        let Dependency = false;
        // if(arrDependency.length === 0)
        // {
        //     arrDependency = values[index].arrDependency;
        // }
        const childTaskData = values[index].SubTaskList[subIndex].ChildTaskList[childIndex];
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);

        let CurrentChildvalues = [];
        values.map((Main) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({
                        StatusID: SubChild.StatusID,
                        ChildId: SubChild.ChildId,
                        IsDepandency: SubChild.IsDepandency,
                        Depandency: SubChild.SelectedDependency,
                        intDependency: SubChild.intDependency,

                    })
                })
            })
        })

        // Check dependency if intDependency is 0
        if (childTaskData.intDependency === 0) {
            if (values[index].SelectedDependency) {
                const dependencies = values[index].SelectedDependency.split(',');

                for (const element of dependencies) {
                    const [valIndex] = element.split('-').map(val => parseInt(val) - 1);
                    if (values[valIndex].StatusID !== 3) {
                        Dependency = true;
                        break;
                    }
                }
            } else if (childTaskData.AssignedDepandency) {
                Dependency = true; // Auto dependency set check
            }

            if (Dependency) {
                //Nodify('Warning!', 'warning', "Dependency Tasks should be closed first");
                Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
                return;
            }
        }

        // Check if AssignedDependency exists and set it to SelectedDependency
        if (childTaskData.AssignedDepandency !== '') {
            SelectedDependency = childTaskData.AssignedDepandency;
        }

        // Proceed if no dependency
        if (!Dependency) {
            const params = {
                Operation: childTaskData.StatusID !== 3 ? 1 : 2,
                BuyerId: getTNABuyerID,
                BrandId: getTNABrandID,
                SeasonId: getTNASeasonID,
                SupplierId: getTNASupplierID,
                TaskId: TaskID,
                StyleId: props.location.state.params.styleId,
                SkuId: 0,
                TaskOwnerIdList: TaskOwnerIdList,
                TaskHolderId: TaskHolderId,
                tnaExFactoryId: TNAExFactoryId,
                status: Status,
                selectedDependency: SelectedDependency,
                NewID: SubId,
                Isproduction: IsProduction,
                TnaId: props.location.state.params.tnaid
            };

            setQCAddInspectionListParam(params);
            setQAInspectionPageView('AddInspection');

            // Update form and task information
            childTaskData.IsQCinsectionFactory = 1;
            getQCInspectionInfo.TaskStatus = OperationType;
            getQCInspectionInfo.TaskType = TaskName;
            setSubTaskList(CurrentChildvalues);
            setInputFields(values);
            setQCInspectionInfo(getQCInspectionInfo);
        }
    }


    // Testing function

    // async function handleTestingTaskFormOpen(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, childTask) {
    //     debugger;
    //     let Operation = 1
    //     let Dependency = false;
    //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

    //         if (values[index].SelectedDependency) {
    //             let arrstatus = [];
    //             arrstatus = values[index].SelectedDependency.split(',');
    //             if (arrstatus.length > 0) {
    //                 arrstatus.forEach(element => {

    //                     let val = [];
    //                     val = element.split('-');
    //                     let valIndex = values[parseInt(val[0]) - 1].StatusID
    //                     if (valIndex !== 3) {
    //                         Dependency = true;
    //                     }
    //                 });
    //             }
    //         }
    //         else if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency) // Auto dependency set check
    //         { Dependency = true; }

    //         if (Dependency) {
    //             Nodify('Warning!', 'warning', "Dependency Tasks should be close first");
    //         }
    //     }
    //     debugger;
    //     if(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency !== '')
    //     {
    //         SelectedDependency = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency;
    //     }
    //     // Join the unique IDs into comma-separated strings
    //     const params = {
    //         StyleIDListMulti: props.location.state.params.styleId.toString(),
    //         CheckProgramSkuIDList: childTask.SKUIdList.toString()
    //     };

    //     const res = await TnaService.GetCheckProgramSKUList(params);

    //     if (res.data.outputResult === "1") {
    //         Nodify('Warning!', 'warning', 'There is no program task for some particular SKU');
    //         Dependency = false;
    //         return;
    //     }

    //     if (!Dependency) {
    //         //  SubId(SKUID) set 0 for entire record showed
    //         let params = {
    //             Operation: (TaskName === 'FABRIC TEST' || TaskName === 'YARN TEST' || TaskName === 'GARMENT TEST') ? 1 : TaskName === 'Strike off Test' ? 2 : 3,
    //             //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
    //             BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
    //             SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
    //             SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
    //             tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
    //             Isproduction: IsProduction, TnaId: props.location.state.params.tnaid, PurchaseOrderSkuid: (TaskName === 'Strike off Test' || TaskName === 'Trims Test') ? childTask.StrikeOffOrTrimInfoId : childTask.PurchaseOrderSkuID
    //         };
    //         setTestingTaskListParam(params);
    //         setTestingReportPageView('AddInspection')
    //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingTaskFactory = 1;
    //         getTestingTaskInfo.TaskStatus = OperationType;
    //         getTestingTaskInfo.TaskType = TaskName;//is for Midline or pilotrun or set sizecheck
    //         setInputFields(values);
    //         setTestingTaskInfo(getTestingTaskInfo);
    //     }
    // }

    async function handleTestingTaskFormOpen(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, childTask, arrDependency) {
        debugger;

        let Operation = 1;
        let Dependency = false;
        const childTaskData = values[index].SubTaskList[subIndex].ChildTaskList[childIndex];
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);

        let CurrentChildvalues = [];
        values.map((Main) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({
                        StatusID: SubChild.StatusID,
                        ChildId: SubChild.ChildId,
                        IsDepandency: SubChild.IsDepandency,
                        Depandency: SubChild.SelectedDependency,
                        intDependency: SubChild.intDependency,

                    })
                })
            })
        })

        // Check dependency if intDependency is 0
        if (childTaskData.intDependency === 0) {
            if (values[index].SelectedDependency) {
                const dependencies = values[index].SelectedDependency.split(',');

                for (const element of dependencies) {
                    const [valIndex] = element.split('-').map(val => parseInt(val) - 1);
                    if (values[valIndex].StatusID !== 3) {
                        Dependency = true;
                        break;
                    }
                }
            } else if (childTaskData.AssignedDepandency) {
                Dependency = true; // Auto dependency set check
            }

            if (Dependency) {
                // Nodify('Warning!', 'warning', "Dependency Tasks should be closed first");
                Nodify('Warning!', 'warning', "Dependency Tasks should be close first(" + InCompleteTasks + ")");
                return;
            }
        }

        // Check if AssignedDependency exists and set it to SelectedDependency
        if (childTaskData.AssignedDepandency !== '') {
            SelectedDependency = childTaskData.AssignedDepandency;
        }

        // Validate program SKU list
        const paramsForCheckProgramSKUList = {
            StyleIDListMulti: props.location.state.params.styleId.toString(),
            CheckProgramSkuIDList: childTask.SKUIdList.toString()
        };

        const res = await TnaService.GetCheckProgramSKUList(paramsForCheckProgramSKUList);

        if (res.data.outputResult === "1") {
            Nodify('Warning!', 'warning', 'There is no program task for some particular SKU');
            Dependency = false;
            return;
        }

        // Proceed if no dependency
        if (!Dependency) {
            const OperationMapping = {
                'FABRIC TEST': 1,
                'YARN TEST': 1,
                'GARMENT TEST': 1,
                'Strike off Test': 2
            };

            const paramsForTestingTask = {
                Operation: OperationMapping[TaskName] || 3,
                BuyerId: getTNABuyerID,
                BrandId: getTNABrandID,
                SeasonId: getTNASeasonID,
                SupplierId: getTNASupplierID,
                TaskId: TaskID,
                StyleId: props.location.state.params.styleId,
                SkuId: 0,
                TaskOwnerIdList: TaskOwnerIdList,
                TaskHolderId: TaskHolderId,
                tnaExFactoryId: TNAExFactoryId,
                status: Status,
                selectedDependency: SelectedDependency,
                NewID: SubId,
                Isproduction: IsProduction,
                TnaId: props.location.state.params.tnaid,
                PurchaseOrderSkuid: (TaskName === 'Strike off Test' || TaskName === 'Trims Test')
                    ? childTask.StrikeOffOrTrimInfoId
                    : childTask.PurchaseOrderSkuID
            };

            setTestingTaskListParam(paramsForTestingTask);
            setSubTaskList(CurrentChildvalues);
            setTestingReportPageView('AddInspection');
            childTaskData.IsTestingTaskFactory = 1;
            getTestingTaskInfo.TaskStatus = OperationType;
            getTestingTaskInfo.TaskType = TaskName;
            setInputFields(values);
            setTestingTaskInfo(getTestingTaskInfo);
        }
    }


    //Testing
    function handleTestinInspecView(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, inputField, operation) {
        showLoader();
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingInsView = 1;
        setInputFields(values);
        TnaService.TestingreportList(TNAExFactoryId, operation, props.location.state.params.tnaid).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            setnewTestingReportlist(res.data)
            setTestingheaderdata(uniquevalues);
            hideLoader();
        })
        getTestingTaskInfo.TaskStatus = OperationType;
        getTestingTaskInfo.TaskType = TaskName;
        setTestingTaskInfo(getTestingTaskInfo);

        let params = {
            Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
            SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
            SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
            tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
            Isproduction: IsProduction
        };
        setQCAddInspectionListParam(params);
        dispatch(GetTestingMultExFactorySKUList(params));
    }


    //QAInspection
    function handleQAInspecView(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, inputField) {
        showLoader();
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQAInsView = 1;
        setInputFields(values);
        QcInspection.GetTNAExfacQAReportList(TNAExFactoryId).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            setnewQAReportlist(res.data)
            setQAheaderdata(uniquevalues);
            hideLoader();
        })
        getQCInspectionInfo.TaskStatus = OperationType;
        getQCInspectionInfo.TaskType = TaskName;
        setQCInspectionInfo(getQCInspectionInfo);

        let params = {
            Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
            SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
            SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
            tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
            Isproduction: IsProduction, TnaId: TNAId,
        };

        setQCAddInspectionListParam(params);
        dispatch(GetSubChildMultExFactoryFormSKUList(params));

    }



    const handleCloseFinalInspecView = (index, subIndex, childIndex) => {
        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 0;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsFinalInsView = 0;
        setInputFields(values);
    };
    function handleCloseQAInspecView(index, subIndex, childIndex) {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQAInsView = 0;
        setInputFields(values);
    }
    function handleCloseTestingView(index, subIndex, childIndex) {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingInsView = 0;
        setInputFields(values);
    }
    function handleDeleteTestinspection(item) {

        let PackingParams = {
            Operation: 126,
            Id: item.tnaTestingInspectionID,
        }
        setIDTestTask({ showPopupDelete: true, Params: PackingParams });
    }

    function handleTestInspecViewDetails(item, ActionType) {
        debugger
        const TNAAddFinalInspectionList = reducerState.tna.tnaAddTestinginspection !== undefined ? reducerState.tna.tnaAddTestinginspection : [];
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.refInspecSKUID.toString();
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
            }
        })
        getTestingTaskInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getTestingTaskInfo.quantityinfo = getTestingTaskListParam// For Binding Selected checkbox values in quantity inforamtion
        getTestingTaskInfo.details = props.location.state.params;
        getTestingTaskInfo.TnaID = props.location.state.params.tnaid
        getTestingTaskInfo.type = item.typeInternorExtern;//For InterNal Or External
        getTestingTaskInfo.SkuID = sizeandqtyfordisplay;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
        getTestingTaskInfo.Isproduction = IsProduction;
        getTestingTaskInfo.TestingInspectionID = item.tnaTestingInspectionID;
        getTestingTaskInfo.page = getTestingReportPageView;//For InspectionType(Reinspection Or New inspection)
        getTestingTaskInfo.pageType = ActionType;//For HeaderName Add,view or edit
        getTestingTaskInfo.ParentTask = "TNAEdit";//Task Based back or save redirect function
        props.history.push(`/createTesting`, { params: getTestingTaskInfo });
    }
    function handleQAInspecViewDetails(item, ActionType) {

        const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        getQCInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getQCInspectionInfo.quantityinfo = getAddQCInspectionListParam// For Binding Selected checkbox values in quantity inforamtion
        getQCInspectionInfo.details = props.location.state.params;
        getQCInspectionInfo.TNAQCInspectionID = item.tnaqcInspectionID;
        getQCInspectionInfo.TnaID = props.location.state.params.tnaid
        getQCInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
        getQCInspectionInfo.Isproduction = IsProduction;
        getQCInspectionInfo.Action = ActionType;
        getQCInspectionInfo.ParentTask = "TNAEdit";
        getQCInspectionInfo.Mode = "AddInspection";
        getQCInspectionInfo.IsAddorEditReinspection = item.inspectionType === 1 ? ActionType + ' Inspection' : ActionType + ' ReInspection';
        props.history.push(`/AddQCInspection`, { params: getQCInspectionInfo });
    }
    function handleDeleteQAinspection(item) {
        let PackingParams = {
            Operation: 110,
            Id: item.tnaqcInspectionID,
        }
        setIDQATask({ showPopupDelete: true, Params: PackingParams });
    }
    const handleFinalInspecView = (index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, inputField) => {

        showLoader();
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsFinalInsView = 1;
        setInputFields(values);

        let balanceqty = 0
        FinalinspectionService.GetTNAExfacReportList(TNAExFactoryId).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];

            // res.data.map((inspc) => {
            //     if (balanceqty === 0) {
            //         balanceqty = (parseInt(inspc.poQty) + parseInt(inspc.tolerance)) - parseInt(inspc.inspectedQty);
            //         inspc.balanceQuantity = balanceqty;
            //     }
            //     else {
            //         inspc.balanceQuantity = (parseInt(inspc.poQty) + parseInt(inspc.tolerance)) - parseInt(inspc.inspectedQty);
            //         balanceqty = inspc.balanceQuantity;
            //     }


            // })

            setnewfinalReportlist(res.data)
            setnewheaderdata(uniquevalues)
            hideLoader();
        })

        getInspectionInfo.TaskStatus = OperationType;
        setInspectionInfo(getInspectionInfo);

        //get existing list
        let params = {
            Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
            SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
            SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
            tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
            Isproduction: IsProduction, TnaId: TNAId, BuyerName: getStyleInfo.buyerName
        };
        setAddInspectionListParam(params);
        dispatch(GetSubChildMultExFactoryFormSKUList(params));
    }
    const handleFinalInspecViewDetails = async (type, TNAInspectionID, Action, inpectionpage, item) => {
        let isvalid = true;
        if (Action === 'View') {
            isvalid = true;
        } else {
            isvalid = false;
        }

        if (!isvalid) {
            let PackingParams = {
                Operation: 104,
                Id: TNAInspectionID,
            }
            await CommonService.CheckMappedItem(PackingParams).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This finalinspection has already approved in inspection Entry Tab, So action not allowed to Edit.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
                    let sizeandqtyfordisplay = "";
                    let NewArr = [];
                    NewArr = TNAAddFinalInspectionList.filter(x => parseInt(x.refInspecSKUID) === parseInt(item.skuID))
                    NewArr.map((Purchaseorder) => {
                        if (sizeandqtyfordisplay === '') {
                            sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                        } else {
                            sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                        }
                    })
                    TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID)).map((element) => {
                        element.IscheckReportSelect = 1;
                        element.isSelected = 1;
                    })
                    getInspectionInfo.IsAddorEditReinspection = item.reportType === '2' ? Action : '';
                    getInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
                    getInspectionInfo.quantityinfo = getAddInspectionListParam
                    getInspectionInfo.details = props.location.state.params;
                    getInspectionInfo.type = type;
                    getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;
                    getInspectionInfo.TNAInspectionID = TNAInspectionID;
                    getInspectionInfo.Isproduction = IsProduction
                    getInspectionInfo.page = getFinalInspectionPageView;
                    getInspectionInfo.pageType = inpectionpage;
                    getInspectionInfo.Action = Action;
                    getInspectionInfo.ParentTask = "TNAEdit";
                    getInspectionInfo.Mode = item.reportType === '2' ? 'ReInspection' : "InspectionView";
                    props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
                }
            })
        } else {

            const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
            let sizeandqtyfordisplay = "";
            let NewArr = [];
            NewArr = TNAAddFinalInspectionList.filter(x => parseInt(x.refInspecSKUID) === parseInt(item.skuID))
            NewArr.map((Purchaseorder) => {
                if (sizeandqtyfordisplay === '') {
                    sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                }
            })
            TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID)).map((element) => {
                element.IscheckReportSelect = 1;
                element.isSelected = 1;
            })
            getInspectionInfo.IsAddorEditReinspection = item.reportType === '2' ? Action : '';
            getInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
            getInspectionInfo.quantityinfo = getAddInspectionListParam
            getInspectionInfo.details = props.location.state.params;
            getInspectionInfo.type = type;
            getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;
            getInspectionInfo.TNAInspectionID = TNAInspectionID;
            getInspectionInfo.Isproduction = IsProduction
            getInspectionInfo.page = getFinalInspectionPageView;
            getInspectionInfo.pageType = inpectionpage;
            getInspectionInfo.Action = Action;
            getInspectionInfo.ParentTask = "TNAEdit";
            getInspectionInfo.Mode = item.reportType === '2' ? 'ReInspection' : "InspectionView";
            props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
        }
    };
    function handleDeletefinalinspection(type, TNAInspectionID, Action) {
        let PackingParams = {
            Operation: 104,
            Id: TNAInspectionID,
        }
        CommonService.CheckMappedItem(PackingParams).then(res => {
            if (res.data.outputResult === "-2") {
                let Title = "Warning!";
                let message = "This finalinspection has already approved in inspection Entry Tab, So action not allowed to Delete.";
                let type = "warning";
                Nodify(Title, type, message);
            }
            else {
                setIDSingleTask({ showPopupDelete: true, Params: PackingParams });
            }
        })
    }

    const handleFilanlInspectionReportForm = () => {
        setIsFinalInspectionReportOpen(true);
    }
    const CloseReportOfInspectionModal = () => {
        setIsFinalInspectionReportOpen(false);
    }

    const AddExFactoryForm = async (index, subIndex, childIndex) => {

        let Multiselected = [];
        const Values = [...getfinalinspectionMulti]
        /// let isvalid = true;
        let Isvalid = false
        //Isvalid = !!!getfinalinspectionMulti.find(x => x.isSelected === 1)
        // if (Isvalid) {
        //     Nodify('Warning!', 'warning', 'Please check atleast one checkbox');
        //     isvalid = false;
        //     return false;
        // }
        await Aqlfinalinspection.GetAqlFinalInspection(0, 2).then((res) => {
            if (res.data.length > 0) {
                Isvalid = !!!res.data.find(m => m.buyerID === props.location.state.params.buyerID)
            }
        })

        if (!Isvalid) {
            Multiselected = Values.concat(getfinalinspectionMulti.filter(x => x.isSelected === 1))
            //setMultiCloseDatas(Multiselected)
            // setfinalinspectionMulti(Multiselected)

        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields in AQL Inspection & AQL Tolerance in Master.');
            return false;
        }



        //-----------------------below codes were commented to just ignore the functionality---------------------//
        // let strValue = "";
        // let selected = 0;
        // for (let data of MultExFactorySKUList) {
        //     if (strValue === "") {
        //         if (data.isSelected === 1) {
        //             strValue = data.tnaExFactoryId.toString();
        //             selected = selected + 1;
        //         }
        //     } else {
        //         if (data.isSelected === 1) {
        //             strValue = strValue + "," + data.tnaExFactoryId;
        //         }
        //     }
        // }
        // if (selected === 0) {
        //     Nodify('Warning!', 'warning', "Select atleast one Task");
        // }
        // else {
        //     let SkuId = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;
        //     let TaskName = values[index].strTaskName;
        //     let TaskID = values[index].TaskID;
        //     let dataParams = {
        //         BuyerID: getTNABuyerID, SeasonID: getTNASeasonID,
        //         BrandID: getTNABrandID, SupplierID: getTNASupplierID,
        //         StyleId: props.location.state.params.styleId, SkuId: SkuId,
        //         TaskName: TaskName, TaskID: TaskID,
        //         TnaExFactoryIdList: strValue, MultExFactorySKUList: MultExFactorySKUList,
        //         StyleInfo: getStyleInfo

        //     };
        //     props.history.push(`/ReportCreationMain`, { params: dataParams });
        // }
        /////////////////temporary code////////////////-kokilapriya
        setIsAddFinalInspection(true);
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 1;
        setInputFields(values);
        //props.history.push(`/ReportCreationMain`, { params: '' });
    };

    const AddTestingExFactoryForm = (index, subIndex, childIndex) => {

        let Multiselected = [];
        const Values = [...getTestinginspectionMulti]
        let isvalid = true;
        let isvalidED = true;
        isvalid = !!getTestinginspectionMulti.find(x => x.isSelected === 1)
        if (isvalid) {
            Multiselected = Values.concat(getTestinginspectionMulti.filter(x => x.isSelected === 1))
            //setMultiCloseDatas(Multiselected)
            // setfinalinspectionMulti(Multiselected)


        } else {
            Nodify('Warning!', 'warning', 'Please check atleast one checkbox');
            isvalidED = false;
            return false;
        }
        // }
        /////////////////temporary code////////////////-kokilapriya
        if (isvalidED) {
            setIsAddFinalInspection(true);
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenTestingConfirmationType = 1;
        }
        //props.history.push(`/ReportCreationMain`, { params: '' });
    };


    const handleCloseTestingExFactoryFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenTestingConfirmationType = 0;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsExFactoryForm = 0;
        setInputFields(values);
    };








    const handleCloseExFactoryFormPopup = (index, subIndex, childIndex) => {
        setfinalinspectionMulti([])
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 0;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsExFactoryForm = 0;
        setInputFields(values);
    };
    const handleCloseQCinspectionFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQCinsectionFactory = 0;
        setInputFields(values);
    };

    const handleCloseTestingTaskFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingTaskFactory = 0;
        setInputFields(values);
    };


    const handleCloseInspectionConfirmationPopup = (index, subIndex, childIndex) => {
        setIsAddFinalInspection(false);
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 0;

    };
    //for Qc Form
    async function AddQCFactoryForm() {
        let isvalid = false;
        let Isvalid = false
        Isvalid = !!!getQCfinalinspectionMulti.find(x => x.isSelected === 1)
        if (Isvalid) {
            Nodify('Warning!', 'warning', 'Please check atleast one checkbox');
            return false;
        }
        await FinalinspectionService.GetTNAFinalinspecTestingReportList(4, props.location.state.params.buyerID, props.location.state.params.brandID).then((res) => {
            if (res.data[0] !== undefined) {
                if (res.data[0].offerQty) {
                    isvalid = true
                }
            } else {
                Nodify('Warning!', 'warning', 'Please enter tolerance in master for this buyer')
            }
        })
        if (isvalid && !Isvalid) {
            let sizeandqtyfordisplay = "";
            let NewArr = [];
            NewArr = getQCfinalinspectionMulti.filter(x => x.isSelected === 1)
            NewArr.map((Purchaseorder) => {
                if (sizeandqtyfordisplay === '') {
                    sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                }
            })
            if (getQAInspectionPageView === "ReInspection") {
                let selectedInspIds = '';
                NewArr.map(x => {
                    if (x.isSelected === 1) {
                        if (selectedInspIds === '') {
                            if (!selectedInspIds.includes(x.tnaqcInspectionID)) {
                                selectedInspIds = x.tnaqcInspectionID.toString();
                            }
                        }
                        else {
                            if (!selectedInspIds.includes(x.tnaqcInspectionID)) {
                                selectedInspIds = selectedInspIds + "," + x.tnaqcInspectionID.toString();
                            }
                        }
                    }
                })

                let uniquevalues = [...new Map(NewArr.map(item =>
                    [item['skuName'], item])).values()];

                uniquevalues.map(x => {
                    let totqty = 0;
                    x.FinalInspectionIds = selectedInspIds;
                })
                uniquevalues.map(x => {
                    let totqty = 0;
                    x.refInspecSKUID = x.skuID;
                })

                getQCInspectionInfo.multiselecteddatas = uniquevalues;
                getQCInspectionInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                    ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
            }
            else {
                getQCInspectionInfo.multiselecteddatas = getQCfinalinspectionMulti;
            }
            getQCInspectionInfo.quantityinfo = getAddQCInspectionListParam// For Binding Selected checkbox values in quantity inforamtion
            getQCInspectionInfo.details = props.location.state.params;
            getQCInspectionInfo.TnaID = props.location.state.params.tnaid
            getQCInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
            getQCInspectionInfo.Isproduction = IsProduction;
            getQCInspectionInfo.Action = 'Add';

            getQCInspectionInfo.ParentTask = "TNAEdit";
            getQCInspectionInfo.Mode = getQAInspectionPageView;
            getQCInspectionInfo.IsAddorEditReinspection = getQAInspectionPageView === 'AddInspection' ? 'Add Inspection' : 'ADD ReInspection';
            props.history.push(`/AddQCInspection`, { params: getQCInspectionInfo });
        }
    }

    function pageRedirectForQAInspection(value) {
        setQAInspectionPageView(value);
    }
    async function CreateFinalInspection(type, Inspectionpage) { // type=1=> internal, 2=> external

        let isvalid = true;
        await FinalinspectionService.GetTNAFinalinspecTestingReportList(4, props.location.state.params.buyerID, props.location.state.params.brandID).then((res) => {
            if (res.data[0] !== undefined) {
                if (res.data[0].offerQty) {
                    isvalid = true
                }
            } else {
                Nodify('Warning!', 'warning', 'Please fill the mandatory (*) fields in AQL Inspection & AQL Tolerance in Master.')
            }
        })
        if (isvalid) {
            let sizeandqtyfordisplay = "";
            let SkuidSample = '';
            let NewArr = [];
            NewArr = getfinalinspectionMulti.filter(x => x.isSelected === 1)
            NewArr.map((Purchaseorder) => {
                if (sizeandqtyfordisplay === '' && SkuidSample === '') {
                    sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                    SkuidSample = Purchaseorder.refInspecSKUID;

                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID;
                    SkuidSample = SkuidSample + ',' + Purchaseorder.refInspecSKUID
                }
            })
            getfinalinspectionMulti.filter(x => x.isSelected === 1).map((x) => {
                if (x.isSelected === 1) {
                    x.IscheckReportSelect = 1;
                } else {
                    x.IscheckReportSelect = 0;
                }

            })

            if (getFinalInspectionPageView === "ReInspection") {
                let selectedInspIds = '';
                NewArr.map(x => {
                    if (x.isSelected === 1) {
                        if (selectedInspIds === '') {
                            if (!selectedInspIds.includes(x.tnaFinalInspectionID)) {
                                selectedInspIds = x.tnaFinalInspectionID.toString();
                            }
                        }
                        else {
                            if (!selectedInspIds.includes(x.tnaFinalInspectionID)) {
                                selectedInspIds = selectedInspIds + "," + x.tnaFinalInspectionID.toString();
                            }
                        }
                    }
                })

                let uniquevalues = [...new Map(NewArr.map(item =>
                    [item['skuName'], item])).values()];

                uniquevalues.map(x => {
                    let totqty = 0;
                    if (NewArr.filter(v => v.skuName === x.skuName && v.isSelected === 1).length !== 0) {
                        let sameskudetails = NewArr.filter(v => v.skuName === x.skuName);
                        sameskudetails.map(s => {
                            if (totqty === 0) {
                                totqty = s.balanceQuantity;
                            }
                            else {
                                totqty = totqty + s.balanceQuantity;
                            }
                        })
                    }
                    x.balanceQuantity = totqty;
                    x.FinalInspectionIds = selectedInspIds;
                    totqty = 0;
                })

                getInspectionInfo.multiselecteddatas = uniquevalues;
                getInspectionInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                    ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
            }
            else {
                getInspectionInfo.multiselecteddatas = getfinalinspectionMulti;
            }

            getInspectionInfo.quantityinfo = getAddInspectionListParam// For Binding Selected checkbox values in quantity inforamtion
            getInspectionInfo.details = props.location.state.params;
            getInspectionInfo.TnaID = props.location.state.params.tnaid
            getInspectionInfo.type = type;//For InterNal Or External
            getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
            getInspectionInfo.SkuID = SkuidSample;
            getInspectionInfo.Isproduction = IsProduction;
            getInspectionInfo.page = getFinalInspectionPageView;//For InspectionType(Reinspection Or New inspection)
            getInspectionInfo.IsAddorEditReinspection = 'Add';
            getQCInspectionInfo.styleID = getAddInspectionListParam.StyleId
            getInspectionInfo.pageType = 'AddInspection';//For HeaderName Add,view or edit
            getInspectionInfo.ParentTask = "TNAEdit";//Task Based back or save redirect function
            getInspectionInfo.Mode = getFinalInspectionPageView === "ReInspection" ? 'ReInspection' : "InspectionAdd";//For Some CalculationWorks
            props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
        }
    }


    async function CreateTesting(type, Task) { // type=1=> internal, 2=> external

        let isvalid = true;
        if (isvalid) {
            let sizeandqtyfordisplay = "";
            let StrikeoffORTrimID = '';
            let purchaseOrderSkuID = '';
            let TNAID = '';
            let NewArr = [];
            NewArr = getTestinginspectionMulti.filter(x => x.isSelected === 1)
            if (Task === "FABRIC TEST" || Task === "YARN TEST" || Task === "GARMENT TEST") {
                NewArr.map((Purchaseorder) => {
                    if (sizeandqtyfordisplay === '') {
                        sizeandqtyfordisplay = Purchaseorder.refInspecSKUID.toString();
                    } else {
                        sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
                    }
                })
                NewArr.map((Purchaseorder) => {
                    if (purchaseOrderSkuID === '') {
                        purchaseOrderSkuID = Purchaseorder.purchaseOrderSkuID.toString();
                    } else {
                        purchaseOrderSkuID = purchaseOrderSkuID + ',' + Purchaseorder.purchaseOrderSkuID
                    }
                })
            } else if (Task === 'Strike off Test') {
                NewArr.map((Purchaseorder) => {
                    if (StrikeoffORTrimID === '') {
                        StrikeoffORTrimID = Purchaseorder.strikeOffID.toString();
                    } else {
                        StrikeoffORTrimID = StrikeoffORTrimID + ',' + Purchaseorder.strikeOffID
                    }
                })
            } else {
                NewArr.map((Purchaseorder) => {
                    if (StrikeoffORTrimID === '') {
                        StrikeoffORTrimID = Purchaseorder.trimInfoID.toString();
                    } else {
                        StrikeoffORTrimID = StrikeoffORTrimID + ',' + Purchaseorder.trimInfoID
                    }
                })
            }

            if (getTestingReportPageView === "ReInspection") {
                let selectedInspIds = '';
                NewArr.map(x => {
                    if (x.isSelected === 1) {
                        if (selectedInspIds === '') {
                            if (!selectedInspIds.includes(x.tnaTestingInspectionID)) {
                                selectedInspIds = x.tnaTestingInspectionID.toString();
                            }
                        }
                        else {
                            if (!selectedInspIds.includes(x.tnaTestingInspectionID)) {
                                selectedInspIds = selectedInspIds + "," + x.tnaTestingInspectionID.toString();
                            }
                        }
                    }
                })

                let uniquevalues = [...new Map(NewArr.map(item =>
                    [item['skuName'], item])).values()];

                uniquevalues.map(x => {
                    let totqty = 0;
                    x.FinalInspectionIds = selectedInspIds;
                })
                uniquevalues.map(x => {
                    let totqty = 0;
                    x.refInspecSKUID = x.skuID;
                })

                getTestingTaskInfo.multiselecteddatas = uniquevalues;
                getTestingTaskInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                    ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
            }
            else {
                getTestingTaskInfo.multiselecteddatas = getTestinginspectionMulti;
            }
            getTestingTaskInfo.PurchaseSkuID = purchaseOrderSkuID;
            getTestingTaskInfo.supplierID = props.location.state.params.supplierId;
            getTestingTaskInfo.multiselecteddatas = getTestinginspectionMulti;
            getTestingTaskInfo.quantityinfo = getTestingTaskListParam// For Binding Selected checkbox values in quantity inforamtion
            getTestingTaskInfo.details = props.location.state.params;
            getTestingTaskInfo.TnaID = props.location.state.params.tnaid
            getTestingTaskInfo.type = type;//For InterNal Or External
            getTestingTaskInfo.SkuID = sizeandqtyfordisplay;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
            getTestingTaskInfo.StrikeoffTrimIDlist = StrikeoffORTrimID;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
            getTestingTaskInfo.Isproduction = IsProduction;
            getTestingTaskInfo.page = getTestingReportPageView;//For InspectionType(Reinspection Or New inspection)
            getTestingTaskInfo.pageType = 'AddInspection';//For HeaderName Add,view or edit
            getTestingTaskInfo.ParentTask = "TNAEdit";//Task Based back or save redirect function
            props.history.push(`/createTesting`, { params: getTestingTaskInfo });
        }
    }

    const SelectAllExFactory = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultExFactorySKUList.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultExFactorySKUList.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectExFactoryRecords = (event, index) => {
        const value = [...MultExFactorySKUList];

        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;

        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);

        setMultExFactorySKUList(value);
    }

    const pageRedirectForFinalInspection = (Page) => {

        setFinalInspectionPageView(Page);
        props.location.state.params.page = "ReInspection";
        props.location.state.params.ParentTask = "ReInspection";
        props.location.state.params.Mode = "ReInspection";
    }

    function FinalInspectionCallback(value) {

        setfinalinspectionMulti(value)
        props.location.state.params.page = "InspectionAdd";
        props.location.state.params.ParentTask = "TNAEdit";
        props.location.state.params.Mode = "InspectionAdd";
    }

    function QCFinalInspectionCallback(value) {
        setQCfinalinspectionMulti(value);
    }
    // #endregion -------------------  End Ex-Factory Form -------------------------------------
    function QCInspectionCallback(value) {
        setQCfinalinspectionMulti(value);
    }

    function QCTestingCallback(value) {
        setTestinginspectionMulti(value)
    }
    function pageRedirectForTestingInspection(val) {
        setTestingReportPageView(val)
    }

    let InspectionandApproval = []
    function DateTNAchange(item) { }
    // #region Child depandency popup

    const handleChildDependency = (index, subIndex, childIndex, ChildRowIndex, MainChildId, Mode) => {

        if ((taskNameList === '' || taskNameList === null || taskNameList === undefined)) {
            setChildDepPopupMode(Mode);
            let Valid = true;
            values.map((Main, Mainindex) => {
                Main.SubTaskList.map((Child, ChildIndex) => {
                    Child.ChildTaskList.map((SubChild) => {
                        if (SubChild.ChildId === 0) {
                            Valid = false;
                        }

                    })
                })
            })

            if (Valid === false && isLastEdit === true) {
                Nodify('Warning!', 'warning', 'Newly Added Tasks must be saved first.');
            }
            else {
                if (values[index].TaskID === 0) {
                    Nodify('Warning!', 'warning', 'Please select the task name.');
                }
                else //if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency.length === 0)
                {
                    OpenChildDepandencyPopup(index, subIndex, childIndex, ChildRowIndex, MainChildId);
                }
            }
        }
        else {
            Nodify('Warning!', 'warning', 'Activate TNA first & do actions in TNA Edit.');
        }

    }

    const OpenChildDepandencyPopup = (index, subIndex, childIndex, ChildRowIndex, MainChildId) => {
        // let CurrArrayDep = ExistingDepDetails.length > 0 ? ExistingDepDetails : values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency;

        let CurrArrayDep = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency;
        if (AllRouteInfo.tnaDetailList) {

            let ChildIndex = 1;
            let routeInformation = [];
            if (values.length > 0) {
                //setInputFields([]);

                values.map((route, i) => {

                    let ImageUrl = "";

                    if (route.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || route.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                        route.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || route.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                        route.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || route.strTaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                        ImageUrl = 'Images/Style/Strikeoff/';
                    } else if (route.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || route.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
                        route.strTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || route.strTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                        route.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || route.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
                        route.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER || route.strTaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                        ImageUrl = 'Images/Style/Trim/';
                    }

                    let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === route.strTaskName.toLowerCase());
                    if (route.DetailTypeID === 4) { // Ex-Factory  (Detail Type)
                        IsSingleTask = false;
                    }
                    routeInformation.push({
                        TaskType: route.TaskType,
                        TaskTypeTxt: route.TaskTypeTxt,
                        TaskID: route.TaskID,
                        strTaskName: route.strTaskName,
                        IndexName: route.IndexName,
                        SelectedDependency: route.SelectedDependency,
                        IsSingleTask: IsSingleTask,
                        // DependencyID: CurrArrauDep.length > 0 ? CurrArrauDep.filter(x => x.DependentTaskName === child.childId)[0].DependencyID : 0,
                        // LogDays: CurrArrauDep.length > 0 ? CurrArrauDep.filter(x => x.DependentTaskName === child.childId)[0].LogDays : '',
                        DependencyID: CurrArrayDep.length > 0 && CurrArrayDep.filter(x => x.DependentTaskId === route.TNARouteApplyID).length > 0 ?
                            CurrArrayDep.filter(x => x.DependentTaskId === route.TNARouteApplyID)[0].DependencyID : 0,
                        LogDays: CurrArrayDep.length > 0 && CurrArrayDep.filter(x => x.DependentTaskId === route.TNARouteApplyID).length > 0 ?
                            CurrArrayDep.filter(x => x.DependentTaskId === route.TNARouteApplyID)[0].LogDays : '',

                        DetailTypeID: route.DetailTypeID,
                        SubTaskList: [],
                        //IsExpanded: values[i].IsExpanded,
                        IsExpanded: false,
                        RownoforAllTasks: route.RownoforAllTasks

                    });

                    if (IsSingleTask === false) {
                        values[i].SubTaskList && values[i].SubTaskList.filter(d => d.TaskID === route.TaskID).map((element, p) => {
                            routeInformation[i].SubTaskList.push({
                                ParentId: element.ParentId,
                                TaskID: element.TaskID,
                                MainId: element.MainId,
                                Code: element.Code,
                                Color: element.Color,
                                ColorOrName: element.ColorOrName,
                                ImagePath: element.ImagePath,
                                ImageUrl: ImageUrl,
                                StrikeOffOrTrimInfoId: element.StrikeOffOrTrimInfoId,
                                //  IsExpanded: values[i].SubTaskList[p].IsExpanded,
                                IsExpanded: false,
                                hasAutoOpen: false,

                                ChildTaskList: []
                            })


                            values[i].SubTaskList[p].ChildTaskList && values[i].SubTaskList[p].ChildTaskList.filter(d => d.TaskID === route.TaskID && d.MainId === element.MainId).map((child, c) => {

                                routeInformation[i].SubTaskList[p].ChildTaskList.push({
                                    ChildId: child.ChildId,
                                    SubId: child.SubId,
                                    Name: child.Name,
                                    TaskName: route.TaskName,
                                    TaskID: child.TaskID,
                                    MainId: child.MainId,
                                    StrikeOffOrTrimInfoId: child.StrikeOffOrTrimInfoId,
                                    ChildIndex: ChildIndex,
                                    DependencyCount: 0,
                                    SelectedDependency: child.SelectedDependency,
                                    DependencyID: CurrArrayDep.length > 0 && CurrArrayDep.filter(x => x.DependentTaskId === child.ChildId).length > 0 ?
                                        CurrArrayDep.filter(x => x.DependentTaskId === child.ChildId)[0].DependencyID : 0,
                                    LogDays: CurrArrayDep.length > 0 && CurrArrayDep.filter(x => x.DependentTaskId === child.ChildId).length > 0 ?
                                        CurrArrayDep.filter(x => x.DependentTaskId === child.ChildId)[0].LogDays : '',
                                    StartDate: child.StartDate,
                                    EndDate: child.EndDate,
                                    Duration: child.Duration,
                                    RownoforAllTasks: child.RownoforAllTasks,
                                    SKUIdList: child.SKUIdList,
                                    StyleStrikeOffGarmentSizeIdList: child.StyleStrikeOffGarmentSizeIdList
                                });
                                ChildIndex++;
                            });

                            if (routeInformation[i].SubTaskList[p].ChildTaskList.filter(x => x.DependencyID !== 0).length > 0) {
                                routeInformation[i].IsExpanded = true;
                                // routeInformation[i].SubTaskList.map(item => {
                                //     item.IsExpanded = true;
                                // })
                                routeInformation[i].SubTaskList[p].IsExpanded = true;
                            }
                        });
                    }
                });
            }
            setInputFieldsChildDep(routeInformation);
        }

        let TaskDependency = [];
        if (CurrArrayDep.length === 0) {
            let RowIndex = 1;
            values.map((Main, Mainindex) => {
                if (Main.IsSingleTask === true) {
                    TaskDependency.push(
                        {
                            TNAChildDependencyID: 0,
                            DependentTaskId: Main.TNARouteApplyID,
                            TNATaskName: Main.strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: RowIndex,
                            Mainindex: Mainindex,
                            StartDate: Main.StartDate,
                            EndDate: Main.EndDate,
                            Duration: Main.Duration,
                            ChildIndex: Mainindex + 1,
                            IsSingleTask: true
                        }
                    )

                    RowIndex++;
                }

                Main.SubTaskList.map((Child, ChildIndex) => {
                    Child.ChildTaskList.map((SubChild) => {
                        if (SubChild.arrDependency && SubChild.arrDependency.length > 0) {
                            let AlreadyExist = SubChild.arrDependency.filter(x => x.DependentTaskId === SubChild.TaskID);
                            let DependencyID = 0;
                            let LogDays = '';
                            if (AlreadyExist.length === 0) {
                                DependencyID = 0;
                                LogDays = '';
                            }
                            else {
                                DependencyID = AlreadyExist[0].DependencyID;
                                LogDays = AlreadyExist[0].LogDays;
                            }
                            TaskDependency.push(
                                {
                                    TNAChildDependencyID: 0,
                                    DependentTaskId: SubChild.ChildId,
                                    TNATaskName: SubChild.Name,
                                    DependencyID: DependencyID,
                                    LogDays: LogDays,
                                    IndexName: RowIndex,
                                    Mainindex: Mainindex,
                                    StartDate: SubChild.StartDate,
                                    EndDate: SubChild.EndDate,
                                    Duration: SubChild.Duration,
                                    ChildIndex: SubChild.ChildIndex,
                                    IsSingleTask: false
                                }
                            )
                        }
                        else {
                            TaskDependency.push(
                                {
                                    TNAChildDependencyID: 0,
                                    DependentTaskId: SubChild.ChildId,
                                    TNATaskName: SubChild.Name,
                                    DependencyID: 0,
                                    LogDays: '',
                                    IndexName: RowIndex,
                                    Mainindex: Mainindex,
                                    StartDate: SubChild.StartDate,
                                    EndDate: SubChild.EndDate,
                                    Duration: SubChild.Duration,
                                    ChildIndex: SubChild.ChildIndex,
                                    IsSingleTask: false
                                }
                            )
                        }

                        RowIndex++;
                    })
                })

            });

        }
        else {
            CurrArrayDep.map((item) => {
                TaskDependency.push(
                    {
                        TNAChildDependencyID: item.TNAChildDependencyID,
                        DependentTaskId: item.DependentTaskId,
                        TNATaskName: item.Name,
                        DependencyID: item.DependencyID,
                        LogDays: item.LogDays === undefined ? '' : item.LogDays,
                        IndexName: item.IndexName,
                        Mainindex: item.Mainindex,
                        StartDate: item.StartDate,
                        EndDate: item.EndDate,
                        Duration: item.Duration,
                        ChildIndex: item.ChildIndex,
                        IsSingleTask: item.IsSingleTask
                    }
                )
            })
        }

        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency = [];
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency = TaskDependency;
        setDepandencyrowno((index + 1) + "-" + ChildRowIndex)

        setDepandencyAllIndex({ Index: index, SubIndex: subIndex, ChildIndex: childIndex, RowIndex: ChildRowIndex, MainChildId: MainChildId })
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsChildDepandencyOpen = 1;
        setInputFields(values);
    }

    const handleCloseChildDepandencyPopup = (index, subIndex, childIndex) => {

        setIndx(() => ({
            Index: 0,
            subindex: 0,
            ChildIndex: 0
        }));
        setFirst(true);
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsChildDepandencyOpen = 0;
        setInputFields(values);
        ChildDepandencyCalculation();
    }



    const handleParentChildDepandencyChange = (e, index, subIndex, childIndex, FieldName, Rowindex, IsSingleTask) => {

        setSubmitted(false);
        let inputText = '';
        let AddedChilddep = {};
        if (FieldName === "DependentTaskName" || FieldName === "DependencyID") {
            if (e != null) {
                inputText = parseInt(e.value);
            }
            else {
                inputText = 0;
                if (FieldName === "DependencyID") {
                    ChildDepandencyValues[index].LogDays = '';
                }
            }

            values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[index].DependencyID = inputText;


            ChildDepandencyValues[index].DependencyID = inputText;



            let ChildTasksDeplist = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency;
            let SourceDep; var CheckchildAlreadymapped = false;

            ChildDepandencyValues[index].SubTaskList.map((parent, pindex) => {
                parent.ChildTaskList.map((child, cindex) => {

                    SourceDep = values[index].SubTaskList[pindex].ChildTaskList[cindex].SelectedDependency;

                    if (SourceDep && SourceDep !== '') {
                        CheckchildAlreadymapped = SourceDep.includes(DepandencyAllIndex.RowIndex);
                    }

                    if (CheckchildAlreadymapped === true) {
                        Nodify('Warning!', 'warning', 'Depandency already mapped.');
                        return false;
                    }
                })
            })

            let valid = true;
            let existstartdate = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                ChildTaskList[DepandencyAllIndex.ChildIndex].StartDate;
            let existenddate = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                ChildTaskList[DepandencyAllIndex.ChildIndex].EndDate;

            if (CheckchildAlreadymapped === false) {
                ChildDepandencyValues[index].SubTaskList.map((parent, pindex) => {
                    parent.ChildTaskList.map((child, cindex) => {
                        if (child.ChildIndex !== DepandencyAllIndex.RowIndex) {
                            let Logdays = '';
                            let Duration = child.Duration;
                            let startdate = child.StartDate;
                            let enddate = child.EndDate;
                            if (values[DepandencyAllIndex.Index].DetailTypeID === 4) {
                                valid = ExfactoryCalconDependencychange(e === null || e === undefined ? 0 : parseInt(e.value), Logdays, existstartdate, existenddate,
                                    values[DepandencyAllIndex.Index].LeaveDays, values[DepandencyAllIndex.Index].titleEmployee1,
                                    Duration, startdate, enddate, DepandencyAllIndex.Index, DepandencyAllIndex.SubIndex, DepandencyAllIndex.ChildIndex);

                            }
                            if (valid) {
                                child.DependencyID = inputText;
                            }
                        }
                        if ((ChildTasksDeplist.filter(x => x.DependentTaskId === child.ChildId).length) === 0) {
                            AddedChilddep.TNAChildDependencyID = 0;
                            AddedChilddep.DependentTaskId = child.ChildId;
                            AddedChilddep.TNATaskName = '';
                            AddedChilddep.DependencyID = inputText;
                            AddedChilddep.LogDays = '';
                            AddedChilddep.IndexName = 0;
                            AddedChilddep.Mainindex = Rowindex;
                            values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                                ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency.splice((Rowindex - 1), 0, AddedChilddep)

                            values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                                ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency.map((item, i) => {
                                    item.IndexName = i + 1;

                                })
                        }

                    })
                })
            }

            if (CheckchildAlreadymapped === false) {

                let validcount = 0;
                values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList.map((childitem, childindex) => {
                    childitem.arrDependency.map((arritem, arrindex) => {
                        if ((arritem.Mainindex === index) && arritem.IndexName !== DepandencyAllIndex.RowIndex) {
                            let Logdays = '';
                            let Duration = arritem.Duration;
                            let startdate = arritem.StartDate;
                            let enddate = arritem.EndDate;
                            if (values[DepandencyAllIndex.Index].DetailTypeID === 4) {
                                valid = ExfactoryCalconDependencychange(e === null || e === undefined ? 0 : parseInt(e.value), Logdays, existstartdate, existenddate,
                                    values[DepandencyAllIndex.Index].LeaveDays, values[DepandencyAllIndex.Index].titleEmployee1,
                                    Duration, startdate, enddate, DepandencyAllIndex.Index, DepandencyAllIndex.SubIndex, DepandencyAllIndex.ChildIndex);


                            }
                            if (valid) {
                                arritem.DependencyID = inputText;
                                let CurrentChildvalues = [];
                                values.map((Main, MainIndex) => {
                                    Main.SubTaskList.map((Child) => {
                                        Child.ChildTaskList.map((SubChild) => {
                                            CurrentChildvalues.push({ StatusID: SubChild.StatusID, ChildIndex: SubChild.ChildIndex })
                                        })
                                    })
                                })

                                // if (CurrentChildvalues.filter(x => parseInt(x.ChildIndex) === parseInt(arritem.IndexName))[0].StatusID === 3) {
                                //     values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                                //         ChildTaskList[DepandencyAllIndex.ChildIndex].IsDepandency = 0;
                                // }
                                // else {
                                //     values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                                //         ChildTaskList[DepandencyAllIndex.ChildIndex].IsDepandency = 1;
                                // }

                            }
                            else {
                                validcount = validcount + 1;
                            }
                        }
                    })

                })

                if (validcount > 0) {
                    Nodify('Warning!', 'warning', "few selected dependency end date less than current ex-factory end date.");
                }
            }

        }

        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
                var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
                inputText = checkNumber[0];
            }
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            ChildDepandencyValues[index].LogDays = inputText;
            // values[Rowindex - 1].LogDays = inputText;

            ChildDepandencyValues[index].SubTaskList[subIndex].ChildTaskList.map((item) => {
                if (item.ChildIndex !== DepandencyAllIndex.RowIndex) {
                    item.LogDays = inputText;
                }
            })

            let valid = true; let validcount = 0;
            let existstartdate = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                ChildTaskList[DepandencyAllIndex.ChildIndex].StartDate;
            let existenddate = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                ChildTaskList[DepandencyAllIndex.ChildIndex].EndDate;

            values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList.map((childitem, childindex) => {
                childitem.arrDependency.map((arritem, arrindex) => {
                    if ((arritem.Mainindex === index) && arritem.IndexName !== DepandencyAllIndex.RowIndex) {
                        let Duration = arritem.Duration;
                        let startdate = arritem.StartDate;
                        let enddate = arritem.EndDate;

                        if (childitem.DetailTypeID === 4) {
                            valid = ExfactoryCalconDependencychange(parseInt(arritem.DependencyID), inputText, existstartdate, existenddate,
                                values[DepandencyAllIndex.Index].LeaveDays, values[DepandencyAllIndex.Index].titleEmployee1, Duration,
                                startdate, enddate, DepandencyAllIndex.Index, DepandencyAllIndex.SubIndex, DepandencyAllIndex.ChildIndex);
                        }
                        if (valid) {
                            arritem.LogDays = inputText;
                            let CurrentChildvalues = [];
                            values.map((Main, MainIndex) => {
                                Main.SubTaskList.map((Child) => {
                                    Child.ChildTaskList.map((SubChild) => {
                                        CurrentChildvalues.push({ StatusID: SubChild.StatusID, ChildIndex: SubChild.ChildIndex })
                                    })
                                })
                            })

                            if (CurrentChildvalues.filter(x => parseInt(x.ChildIndex) === parseInt(arritem.IndexName))[0].StatusID === 3) {
                                values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                                    ChildTaskList[DepandencyAllIndex.ChildIndex].IsDepandency = 0;
                            }
                            else {
                                values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                                    ChildTaskList[DepandencyAllIndex.ChildIndex].IsDepandency = 1;
                            }
                        }
                        else {
                            validcount = validcount + 1;
                        }
                    }
                })
            })

            if (validcount > 0) {
                Nodify('Warning!', 'warning', "few selected dependency end date less than current ex-factory end date.");
            }

        }

        setInputFields(values);
        setInputFieldsChildDep(ChildDepandencyValues);
        ChildDepandencySet('', IsSingleTask, index);
    }

    const handleChildDepandencyChange = (e, index, subIndex, childIndex, FieldName, Rowindex, ChildId, RownoforAllTasks, indexforchild) => {

        setSubmitted(false);
        let inputText = '';
        if (ChildId === 0) {
            Nodify('Warning!', 'warning', 'Newly Added Tasks must be saved first.');
        }
        else {
            var CheckchildAlreadymapped = false;
            let valid = true;
            let existstartdate = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                ChildTaskList[DepandencyAllIndex.ChildIndex].StartDate;
            let existenddate = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                ChildTaskList[DepandencyAllIndex.ChildIndex].EndDate;
            let Logdays = '';
            let Duration = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].Duration;
            let startdate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StartDate;
            let enddate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDate;

            if (FieldName === "DependentTaskName" || FieldName === "DependencyID") {



                if (e != null) {
                    let SourceDep = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency;

                    if (SourceDep !== '' && SourceDep !== null) {
                        CheckchildAlreadymapped = SourceDep.includes(
                            DepandencyAllIndex.RowIndex + "-ES" ||
                            DepandencyAllIndex.RowIndex + "-EE" ||
                            DepandencyAllIndex.RowIndex + "-SS" ||
                            DepandencyAllIndex.RowIndex + "-SE");
                    }
                    if (CheckchildAlreadymapped === true) {
                        Nodify('Warning!', 'warning', 'Depandency already mapped.');
                        return false;
                    }
                    else {

                        if (values[DepandencyAllIndex.Index].DetailTypeID === 4) {
                            valid = ExfactoryCalconDependencychange(parseInt(e.value), Logdays, existstartdate, existenddate,
                                values[DepandencyAllIndex.Index].LeaveDays, values[DepandencyAllIndex.Index].titleEmployee1, Duration,
                                startdate, enddate, DepandencyAllIndex.Index, DepandencyAllIndex.SubIndex, DepandencyAllIndex.ChildIndex);
                        }
                        if (valid) {

                            inputText = parseInt(e.value);

                            let CurrentChildvalues = [];
                            values.map((Main, MainIndex) => {
                                if (Main.IsSingleTask === true) {
                                    CurrentChildvalues.push({ StatusID: Main.StatusID, ChildIndex: Main.TNARouteApplyID })
                                }
                                Main.SubTaskList.map((Child) => {
                                    Child.ChildTaskList.map((SubChild) => {
                                        CurrentChildvalues.push({ StatusID: SubChild.StatusID, ChildIndex: SubChild.ChildId })
                                    })
                                })
                            })

                            if (CurrentChildvalues.filter(x => parseInt(x.ChildIndex) === parseInt(ChildId))[0].StatusID === 3) {
                                values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                                    ChildTaskList[DepandencyAllIndex.ChildIndex].IsDepandency = 0;
                            }
                            else {
                                values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                                    ChildTaskList[DepandencyAllIndex.ChildIndex].IsDepandency = 1;
                            }

                        }
                        else {
                            Nodify('Warning!', 'warning', "selected dependency end date less than current ex-factory end date.");
                        }

                    }

                }
                else {

                    inputText = 0; let StartDate = new Date(), EndDate = new Date(), Duration = 0, total = 0;
                    if (FieldName === "DependencyID") {
                        ChildDepandencyValues[index].SubTaskList[subIndex].ChildTaskList[childIndex].LogDays = '';
                    }
                    if (values[DepandencyAllIndex.Index].SelectedDependency === '') {

                        // let LeaveDays = values[DepandencyAllIndex.Index].LeaveDays;
                        // let titleEmployee1 = values[DepandencyAllIndex.Index].titleEmployee1;
                        // Duration = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                        //     ChildTaskList[DepandencyAllIndex.ChildIndex].Duration;

                        // StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "add");
                        // EndDate = addDays(StartDate, parseInt(Duration) - 1);

                        // total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1);
                        // for (let l = 0; l < total; l++) {
                        //     EndDate = addDays(EndDate, 1);
                        //     EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                        // }

                        // values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                        //     ChildTaskList[DepandencyAllIndex.ChildIndex].StartDate = StartDate;
                        // values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                        //     ChildTaskList[DepandencyAllIndex.ChildIndex].EndDate = EndDate;
                    }
                    else {

                        // values.map((Item, i) => {
                        //     // Parent Calculation call
                        //     if (Item.IsSingleTask) {
                        //         //  commonCalculationParentAndChild(Item, Item, i, 0, 0, true, isDuration, isEndDate, index);
                        //     }

                        //     Item.SubTaskList && Item.SubTaskList.map((Item2, p) => {
                        //         Item2 && Item2.ChildTaskList && Item2.ChildTaskList.map((Item3, c) => {
                        //             // Child Calculation call
                        //             SkuBasedCalculationforChild(Item, Item3, i, p, c, false, DepandencyAllIndex.Index);
                        //         });
                        //     });
                        // });
                        let parentItem = values[DepandencyAllIndex.Index];
                        let ChildItem = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex];
                        SkuBasedCalculationforChild(parentItem, ChildItem, DepandencyAllIndex.Index, DepandencyAllIndex.SubIndex, DepandencyAllIndex.ChildIndex, false, DepandencyAllIndex.Index);
                    }
                }

                if (valid) {
                    ChildDepandencyValues[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyID = inputText;

                    if (values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[0].IsSingleTask) {
                        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[RownoforAllTasks - 1].DependencyID = inputText;
                    }
                    else {
                        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[indexforchild - 1].DependencyID = inputText;

                    }

                    let currRowexist = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                        ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency.filter(x => x.DependentTaskId === ChildId);

                    let AddedChilddep = {};
                    if (currRowexist.length === 0) {
                        AddedChilddep.TNAChildDependencyID = 0;
                        AddedChilddep.DependentTaskId = ChildId;
                        AddedChilddep.TNATaskName = '';
                        AddedChilddep.DependencyID = inputText;
                        AddedChilddep.LogDays = '';
                        AddedChilddep.IndexName = 0;
                        AddedChilddep.Mainindex = Rowindex;
                        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                            ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency.splice((Rowindex), 0, AddedChilddep)

                        // values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                        //     ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency.splice((Rowindex - 1), 0, AddedChilddep)

                        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
                            ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency.map((item, i) => {
                                item.IndexName = i + 1;
                            })
                    }
                    else {
                        // values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex]
                        //     .arrDependency[RownoforAllTasks - 1][FieldName] = inputText;
                    }
                }        //                 })

            }

            else {
                if (e.target.value.trim() !== '') {
                    inputText = e.target.value;
                    var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
                    inputText = checkNumber[0];
                }
                if (parseInt(inputText) === 0) {
                    inputText = '';
                }
                if (values[DepandencyAllIndex.Index].DetailTypeID === 4) {
                    let DependentID = ChildDepandencyValues[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyID;
                    valid = ExfactoryCalconDependencychange(parseInt(DependentID), inputText, existstartdate, existenddate,
                        values[DepandencyAllIndex.Index].LeaveDays, values[DepandencyAllIndex.Index].titleEmployee1,
                        Duration, startdate, enddate, DepandencyAllIndex.Index, DepandencyAllIndex.SubIndex, DepandencyAllIndex.ChildIndex);
                }
                if (valid) {
                    ChildDepandencyValues[index].SubTaskList[subIndex].ChildTaskList[childIndex].LogDays = inputText;
                    values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex]
                        .arrDependency[RownoforAllTasks - 1][FieldName] = inputText;
                }
                else {
                    Nodify('Warning!', 'warning', "selected dependency end date less than current ex-factory end date.");
                }

            }

            if (FieldName === "DependencyID") {
                ChildDepandencySet(inputText, false, index);
            }


            setInputFields(values);
            setInputFieldsChildDep(ChildDepandencyValues);
        }


    }

    function ExfactoryCalconDependencychange(DependentID, LogDays, existstartdate, existenddate, LeaveDays, titleEmployee1,
        Duraion, Startdate, Enddate, i, p, c) {

        let valid = true;
        let SetStartdate = new Date();
        let SetEnddate = new Date();

        let total = 0;
        let tempEnddate = new Date();
        let tempStartdate = new Date();
        let Logdays = LogDays === '' ? 0 : parseInt(LogDays);

        if (DependentID === 1) {

            SetStartdate = addDays(Enddate, 1);
            if (Logdays !== 0) {
                //LogDays Add
                SetStartdate = addDays(SetStartdate, parseInt(Logdays));
            }
            //Calculation Saturday,Sunday and Holiday Count
            SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
            tempEnddate = addDays(SetStartdate, parseInt(Duraion) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c);
            for (let l = 0; l < total; l++) {
                tempEnddate = addDays(tempEnddate, 1);
                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
            }

            SetEnddate = tempEnddate;

        }
        //Start to Start
        if (DependentID === 2) {

            if (Logdays !== 0) {
                SetStartdate = addDays(Startdate, parseInt(Logdays));
                SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
            } else {
                //Calculation Saturday,Sunday and Holiday Count
                SetStartdate = checkDateBetweenInHolidayAndWE(Startdate, LeaveDays, titleEmployee1, "add");
            }

            tempEnddate = addDays(SetStartdate, parseInt(Duraion) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c);
            for (let l = 0; l < total; l++) {
                tempEnddate = addDays(tempEnddate, 1);
                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
            }
            SetEnddate = tempEnddate;

        }
        //Start to End
        if (DependentID === 3) {
            if (Logdays !== 0) {
                SetEnddate = addDays(Startdate, parseInt(Logdays));
                SetEnddate = checkDateBetweenInHolidayAndWE(SetEnddate, LeaveDays, titleEmployee1, "add");
            }
            else {
                SetEnddate = checkDateBetweenInHolidayAndWE(subDays(Startdate, 1), LeaveDays, titleEmployee1, "sub");
            }
            tempStartdate = subDays(SetEnddate, parseInt(Duraion) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1);
            for (let l = 0; l < total; l++) {
                tempStartdate = subDays(tempStartdate, 1);
                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
            }
            SetStartdate = tempStartdate;

        }
        //End to end
        if (DependentID === 4) {

            if (Logdays !== 0) {
                //LogDays Add
                SetEnddate = addDays(Enddate, parseInt(Logdays));
                SetEnddate = checkDateBetweenInHolidayAndWE(SetEnddate, LeaveDays, titleEmployee1, "add");
            } else {
                SetEnddate = checkDateBetweenInHolidayAndWE(Enddate, LeaveDays, titleEmployee1, "sub",);
            }

            tempStartdate = subDays(SetEnddate, parseInt(Duraion) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c);
            for (let l = 0; l < total; l++) {
                tempStartdate = subDays(tempStartdate, 1);
                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
            }
            SetStartdate = tempStartdate;

        }

        // if (new Date(SetEnddate) < new Date(existenddate)) {
        //    valid = false;
        //  }
        //  else {
        let currdepcount = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
            ChildTaskList[DepandencyAllIndex.ChildIndex].DependencyCount;
        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
            ChildTaskList[DepandencyAllIndex.ChildIndex].StartDate = SetStartdate;
        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
            ChildTaskList[DepandencyAllIndex.ChildIndex].EndDate = SetEnddate;
        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
            ChildTaskList[DepandencyAllIndex.ChildIndex].PreStartDate = SetStartdate;
        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
            ChildTaskList[DepandencyAllIndex.ChildIndex].PreEndDate = SetEnddate;
        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
            ChildTaskList[DepandencyAllIndex.ChildIndex].DependencyCount = currdepcount + 1;
        //  }
        return valid;
    }

    //sku base calculation for child
    const SkuBasedCalculationforChild = (parentItem, commonItem, i, p, c, isEndDate, index) => {

        let parentdependencyCount = parentItem.DependencyCount;
        let DetailTypeID = parentItem.DetailTypeID;
        let parentDependency = parentItem.SelectedDependency;
        let SelectedDependency = parentItem.SelectedDependency;
        let Duration = (commonItem.Duration && commonItem.Duration) ? parseInt(commonItem.Duration) > 0 ? parseInt(commonItem.Duration) - 1 : 0 : 0;
        let dependency = SelectedDependency;
        let LeaveDays = parentItem.LeaveDays;
        let titleEmployee1 = parentItem.titleEmployee1;
        let dbEndDate = new Date();
        let dbStartDate = new Date();
        let total = 0;


        if (DetailTypeID === 2) { // DetailType => Sku-----------------------------------
            let SkuId = commonItem.SubId;

            if (parentdependencyCount === 1 && parentDependency.length !== 0) {
                //---------- **** Single  Dependent Calculation ******----------------
                //Get Parent Dependent Name and Index
                let parentSplitVal = parentDependency.split('-');
                let parentIndexVal = parentSplitVal[0].trim();
                let parentDependencyVal = parentSplitVal[1].trim();

                // Dependent base compare to  DetailTypeID
                let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                if (parentDetailTypeID === 1) { // DetailType => Common

                    commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, null)
                } else if (parentDetailTypeID === 2) { // DetailType => Sku

                    let preChildDetail = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId)[0];
                    if (preChildDetail) {
                        commonItem.hasSKUNotMatch = false;
                        commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                    } else {
                        commonItem.hasSKUNotMatch = true;
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }


                } else if (parentDetailTypeID === 3) { // DetailType => Item

                    let preChildDetailList = [];
                    values[parentIndexVal - 1].SubTaskList.map((item) => {
                        let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()))[0];
                        TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                    });
                    //Order by descending ( -1 : 1)
                    let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                    if (preChildDetail) {
                        commonItem.hasSKUNotMatch = false;
                        commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                    } else {
                        commonItem.hasSKUNotMatch = true;
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }


                } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
                    let preChildDetailList = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
                    //Order by ASC a - b
                    let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
                    if (preChildDetail) {
                        commonItem.hasSKUNotMatch = false;
                        commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                    } else {
                        commonItem.hasSKUNotMatch = true;
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }
                }

            } else if (parentdependencyCount > 1) {
                //---------- **** Multiple  Dependent Calculation ******----------------
                let oldDuration = commonItem.Duration;
                let dependencyMult = [];
                let splitDepList = parentDependency.split(',');
                splitDepList.map((depItem, d) => {
                    addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId);
                });

                if (dependencyMult.length > 0) {
                    const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                    let rowSorted = sorted[0];
                    dbEndDate = sorted[0].Date;
                    let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                    if (lastRow.length > 0) {
                        dbStartDate = lastRow[0].Date;
                    } else {
                        dbStartDate = dbEndDate;
                    }

                    // Get Dependency and Index
                    let splitVal = lastRow[0].depItem.split('-');
                    let indexVal = splitVal[0].trim();
                    let dependencyVal = splitVal[1].trim();
                    commonItem.StartDate = dbStartDate;
                    commonItem.EndDate = dbEndDate;
                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    commonItem.hasSKUNotMatch = false;
                } else {
                    commonItem.hasSKUNotMatch = true;
                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                }


            }


        } else if (DetailTypeID === 3) { // DetailType => Item-------------------------------

            let SkuId = commonItem.SubId;
            if (parentdependencyCount === 1 && parentDependency.length !== 0) {
                //---------- **** Single  Dependent Calculation ******----------------
                //Get Parent Dependent Name and Index
                let parentSplitVal = parentDependency.split('-');
                let parentIndexVal = parentSplitVal[0].trim();
                let parentDependencyVal = parentSplitVal[1].trim();

                // Dependent base compare to  DetailTypeID
                let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                if (parentDetailTypeID === 1) { // DetailType => Common

                    commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, null)
                } else if (parentDetailTypeID === 2) { // DetailType => Sku
                    let preChildDetailList = [];

                    //let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                    let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                    arySkuIdList.map((item) => {
                        let preSkuDetail = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item))[0];
                        preSkuDetail && preChildDetailList.push(preSkuDetail);
                    });
                    //Order by descending ( -1 : 1)
                    let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                    if (preChildDetail) {
                        commonItem.hasSKUNotMatch = false;
                        commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                    } else {
                        commonItem.hasSKUNotMatch = true;
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }

                } else if (parentDetailTypeID === 3) { // DetailType => Item
                    let TaskName = values[index].strTaskName;
                    let preTaskName = values[parentIndexVal - 1].strTaskName;
                    let hasSameItem = false;

                    if ((TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)
                        && (preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)) {
                        hasSameItem = true;

                    } else if ((TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)
                        && (preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)) {
                        hasSameItem = true;

                    } else if ((TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)
                        && (preTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || preTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)) {
                        hasSameItem = true;
                    }

                    if (hasSameItem) { //Same Item Match
                        let SubId = commonItem.SubId;
                        let MainId = commonItem.MainId;
                        let preChildTaskList = values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0] && values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0].ChildTaskList;
                        let preChildDetail = preChildTaskList && preChildTaskList.filter(d => d.SubId === SubId)[0];
                        if (preChildDetail) {
                            commonItem.hasSKUNotMatch = false;
                            commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                        } else {
                            commonItem.hasSKUNotMatch = true;
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }
                    } else { //Other Item Match Sku
                        let preChildDetailList = [];

                        //let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                        let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId

                        arySkuIdList && arySkuIdList.map((SkuId) => {
                            values[parentIndexVal - 1].SubTaskList.map((item) => {
                                let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()))[0];
                                TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                                // if (TNASkuIdList && TNASkuIdList.length > 0) {

                                //     preChildDetailList = (preChildDetailList && preChildDetailList.length > 0) ? [preChildDetailList, ...TNASkuIdList] : preChildDetailList;
                                // }

                            });
                            //Order by descending ( -1 : 1)
                            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                            if (preChildDetail) {
                                commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                            } else {
                                commonItem.hasSKUNotMatch = true;
                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                            }
                        });

                    }

                } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory

                    let preChildDetailList = [];

                    //let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                    let arySkuIdList = commonItem.SKUIdList === '' ? commonItem.SubId.toString() && commonItem.SubId.toString().split(',') : commonItem.SKUIdList && commonItem.SKUIdList.split(',');   // Added on 17apr2024 by karthik when SKUIdList has enpty directly call commonItem.SubId
                    arySkuIdList.map((item) => {
                        let preSkuDetail = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item))[0];
                        preSkuDetail && preChildDetailList.push(preSkuDetail);
                    });
                    //Order by ASC a - b
                    let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
                    if (preChildDetail) {
                        commonItem.hasSKUNotMatch = false;
                        commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail)
                    } else {
                        commonItem.hasSKUNotMatch = true;
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }
                }


            } else if (parentdependencyCount > 1) {
                //---------- **** Multiple  Dependent Calculation ******----------------
                let oldDuration = commonItem.Duration;
                let dependencyMult = [];
                let splitDepList = parentDependency.split(',');
                splitDepList.map((depItem, d) => {
                    addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId);
                });

                if (dependencyMult.length > 0) {
                    const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                    let rowSorted = sorted[0];
                    dbEndDate = sorted[0].Date;
                    let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                    if (lastRow.length > 0) {
                        dbStartDate = lastRow[0].Date;
                    } else {
                        dbStartDate = dbEndDate;
                    }

                    // Get Dependency and Index
                    let splitVal = lastRow[0].depItem.split('-');
                    let indexVal = splitVal[0].trim();
                    let dependencyVal = splitVal[1].trim();
                    commonItem.StartDate = dbStartDate;
                    commonItem.EndDate = dbEndDate;
                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    commonItem.hasSKUNotMatch = false;
                } else {
                    commonItem.hasSKUNotMatch = true;
                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                }



            }


        } else if (DetailTypeID === 4) { // DetailType => Ex-Factory---------------------------

        }
    }

    const ChildDepandencySet = (depandency, IssingleTask, index) => {

        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].SelectedDependency = '';
        let DependencyShow = '';
        // values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency.map((x, i) => {
        //     x.ChildIndex = i + 1
        // })
        //setInputFields(values)
        for (let i = 0; i < values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency.length; i++) {
            //if ((Rowindex - 1) !== i) {
            let DependentID = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[i].DependencyID;
            let DepandencyNumber = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[i].IndexName;
            let ChildIndex = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[i].ChildIndex;

            //let ChildIndex = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].ChildIndex;
            //if (isLastEdit) { ChildIndex = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[i].IndexName; }
            // if (isLastEdit) { ChildIndex = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[i].IndexName; }

            let IsSingleTask = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[i].IsSingleTask;

            if (DependentID !== 0) {
                let ParentIndexNo = '';
                values.map((Main, Mainindex) => {
                    if (Main.IsSingleTask === true) {
                        if (Mainindex + 1 === DepandencyNumber) {
                            ParentIndexNo = Mainindex + 1;
                        }
                    }
                    Main.SubTaskList.map((Child, ChildIndex) => {
                        Child.ChildTaskList.map((SubChild) => {
                            if (SubChild.RownoforAllTasks === DepandencyNumber) {
                                ParentIndexNo = Mainindex + 1;
                            }

                        })
                    })
                })

                if (DependencyShow === '') {
                    if (IsSingleTask === true) {
                        DependencyShow = (ParentIndexNo) +
                            '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        // ChildDepandencyValues[index].DependencyID = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency[i].DependencyID;
                    }
                    else {
                        DependencyShow = (ParentIndexNo + "-" + ChildIndex) +
                            '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                    }
                }
                else {
                    if (IsSingleTask === true) {
                        DependencyShow = DependencyShow + ',' + (ParentIndexNo) +
                            '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                    }
                    else {
                        DependencyShow = DependencyShow + ',' + (ParentIndexNo + "-" + ChildIndex) +
                            '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                    }
                }
            }
            else {
                //   ChildDepandencyValues[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyID = '';
                //ChildDepandencyValues[0].DependencyID = 0;
            }
            // }
        }
        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].SelectedDependency = DependencyShow;

        values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].DependencyCount
            = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].arrDependency.filter(x => x.DependencyID !== 0).length;
        if (depandency !== '' && depandency !== 0) {
            values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].ChildTaskList[DepandencyAllIndex.ChildIndex].hasSKUNotMatch = false;
        }

        if (DependencyShow === "") {
            ChildDepandencyValues[index].DependencyID = 0;
        }

        setInputFields(values);
        setInputFieldsChildDep(ChildDepandencyValues);
    }


    const handleExpandParentTask = (index) => {
        setSkuTask((prevSkuTask) => {
            const updatedSkuTask = [...prevSkuTask];
            updatedSkuTask[index].IsExpanded = !updatedSkuTask[index].IsExpanded;
            //console.log(updatedSkuTask);
            return updatedSkuTask;
        });
    };

    const handleExpandChildTask = (index, subindex) => {
        setSkuTask((prevSkuTask) => {
            const updatedSkuTask = [...prevSkuTask];
            updatedSkuTask[index].SubTaskList[subindex].IsExpanded = !updatedSkuTask[index].SubTaskList[subindex].IsExpanded;
            //console.log(updatedSkuTask);
            return updatedSkuTask;
        });
    };


    const handleCheckboxChange = (event, index) => {

        const values = [...skuTask]
        const { checked, name } = event.target;
        // setSkuTask(prevSkuTask => {
        //     const updatedSkuTask = [...prevSkuTask];
        //     updatedSkuTask[index] = {
        //         ...updatedSkuTask[index],
        //         checkbox: checked
        //     };
        //     return updatedSkuTask;
        // });
        values[index].isChecked = checked;
        setSkuTask(values);
    };

    function ViewFiles() {

    }
    function ViewFile1() {

    }
    const handleToggleChildDepandency = (index) => {

        var btnToggle = document.getElementById("btn-toggleChildDepandency_" + index);
        var iconToggle = document.getElementById("icon-toggleChildDepandency_" + index);
        var rowHiddens = document.querySelectorAll(".parentChildDepandency_" + index);
        //var rowChildHiddens = document.querySelectorAll(".child_" + index);

        var ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            let i = 0;
            for (let row of rowHiddens) {
                row.className = row.className.replace("hidden", " ");
                ChildDepandencyValues[index].SubTaskList[i].IsExpanded = true;
                i++;
            }
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            ChildDepandencyValues[index].IsExpanded = true;
            setInputFieldsChildDep(ChildDepandencyValues);
        }
        else {
            let i = 0;
            for (let row of rowHiddens) {
                if (!row.className.includes("hidden")) {
                    row.className = row.className + ' hidden';

                    ChildDepandencyValues[index].SubTaskList[i].IsExpanded = false;
                }
                i++;
            }

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            ChildDepandencyValues[index].IsExpanded = false;
            setInputFieldsChildDep(ChildDepandencyValues);
        }
    };

    const handleChildToggleChildDepandency = (index, childIndex) => {

        var btnToggle = document.getElementById("btn-toggle_parentChild_ChildDepandency_" + index + "_" + childIndex);
        var iconToggle = document.getElementById("icon-toggle_parentChild_ChildDepandency_" + index + "_" + childIndex);
        var rowHiddens = document.querySelectorAll(".parentChild_ChildDepandency_" + index + "_" + childIndex);
        var ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            ChildDepandencyValues[index].SubTaskList[childIndex].IsExpanded = true;

            // for (let row of rowHiddens) {
            //     row.className = row.className.replace("hidden", " ");
            // }
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
        }
        else {
            // for (let row of rowHiddens) {
            //     if (!row.className.includes("hidden")) {
            //         row.className = row.className + ' hidden';
            //     }
            // }

            ChildDepandencyValues[index].SubTaskList[childIndex].IsExpanded = false;

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
        setInputFieldsChildDep(ChildDepandencyValues);
    };

    const SaveChildDepandency = (index, subIndex, childIndex, ChildId, TaskName, DetailTypeID) => {
        setIndx(() => ({
            Index: 0,
            subindex: 0,
            ChildIndex: 0
        }));
        setFirst(true);

        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsChildDepandencyOpen = 0;
        setInputFields(values);
        ChildDepandencyCalculation();
        let currentrow = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
            ChildTaskList[DepandencyAllIndex.ChildIndex];
        if (values[DepandencyAllIndex.Index].DetailTypeID === 4 && values[DepandencyAllIndex.Index].DependencyCount === 0) {
            reloadExfactcal(currentrow, DepandencyAllIndex.Index, DepandencyAllIndex.SubIndex,
                DepandencyAllIndex.ChildIndex, values[DepandencyAllIndex.Index].LeaveDays, values[DepandencyAllIndex.Index].titleEmployee1)
        }
    }
    const [indx, setIndx] = useState({
        Index: 0,
        subindex: 0,
        ChildIndex: 0
    })
    const [taskName, setTaskName] = useState('');
    const [first, setFirst] = useState(true);
    const SaveNxtChildDepandency = (index, subIndex, childIndex, ChildId, ChildRowIndex, TaskName, DetailTypeID) => {

        let NxtArrayDep = [];
        let startindex = index;
        let startsubindex = subIndex;

        let Outindex;
        let OutsubIndex;
        let OutchildIndex;


        //console.log(indx)

        if (first === true) {
            setIndx(prevState => {
                // Update the state
                const newState = {
                    ...prevState,
                    Index: index,
                    subindex: subIndex,
                    ChildIndex: childIndex
                };

                // Access the updated state value immediately
                Outindex = newState.Index;
                OutsubIndex = newState.subindex;
                OutchildIndex = newState.ChildIndex;
                //console.log("Updated value of subindex:", val);
                setFirst(false);
                // return newState;
            });
            setFirst(false);
        }


        if (OutsubIndex === 0) {
            const updatedSubIndex = OutsubIndex + 1;
            // setIndx(prevState => ({
            //     ...prevState,
            //     subindex: updatedSubIndex
            // }));

            setIndx(prevState => {
                // Update the state
                const newState = {
                    ...prevState,
                    //Index: index,
                    subindex: updatedSubIndex,
                    //ChildIndex: childIndex
                };

                // Access the updated state value immediately
                //Outindex = newState.Index;
                OutsubIndex = newState.subindex;
                //OutchildIndex = newState.ChildIndex;

            });

            subIndex = updatedSubIndex
            //OutsubIndex = updatedSubIndex;
        }
        else {
            const updatedSubIndex = OutsubIndex + 1
            setIndx(prevState => ({
                ...prevState,
                subindex: updatedSubIndex
            }));
            subIndex = updatedSubIndex
            OutsubIndex = updatedSubIndex;
        }

        if (values[index].SubTaskList[subIndex] === undefined) {
            const updatedindex = Outindex + 1;
            const updatedSubIndex = 0
            setIndx(prevState => ({
                ...prevState,
                Index: updatedindex,
                subindex: updatedSubIndex
            }));
            Outindex = updatedindex;
            subIndex = updatedSubIndex
            OutsubIndex = updatedSubIndex;
        }
        // else{
        //     const updatedindex = index + 1;
        //     setIndx(prevState => ({
        //         ...prevState,
        //         Index : updatedindex,
        //     }));
        // }
        // if(values[index].SubTaskList[subIndex].ChildTaskList.length > 1)
        // {
        //     const updatedchildIndex = childIndex + 1;
        //     setIndx(prevState => ({
        //         ...prevState,
        //         ChildIndex : updatedchildIndex
        //     }));
        //     childIndex = updatedchildIndex
        // }

        let LetEnd = false;
        if (indx.Index === 0) {
            NxtArrayDep = values[index] === undefined ? LetEnd = true : values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency;
        }
        else {
            NxtArrayDep = values[indx.Index] === undefined ? LetEnd = true : values[indx.Index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency;
        }


        if (LetEnd === true) {
            values[startindex].SubTaskList[startsubindex].ChildTaskList[childIndex].IsChildDepandencyOpen = 0;
            setInputFields(values);
            //ChildDepandencyCalculation();
        }

        if (AllRouteInfo.tnaDetailList) {

            let ChildIndex = 1;
            let routeInformation = [];
            if (values.length > 0) {
                //setInputFields([]);
                // if(values[index].SubTaskList[subIndex + 1].ChildTaskList[childIndex].IsShowSubmitButton === 2)
                // {
                values.map((route, i) => {

                    let ImageUrl = "";

                    if (route.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || route.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                        route.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || route.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                        route.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || route.strTaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                        ImageUrl = 'Images/Style/Strikeoff/';
                    } else if (route.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || route.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
                        route.strTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || route.strTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                        route.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || route.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
                        route.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER || route.strTaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                        ImageUrl = 'Images/Style/Trim/';
                    }

                    let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === route.strTaskName.toLowerCase());
                    if (route.DetailTypeID === 4) { // Ex-Factory  (Detail Type)
                        IsSingleTask = false;
                    }
                    //CurrArrayDep = values[index].SubTaskList[subIndex + 1].ChildTaskList[childIndex].arrDependency
                    routeInformation.push({
                        TaskType: route.TaskType,
                        TaskTypeTxt: route.TaskTypeTxt,
                        TaskID: route.TaskID,
                        strTaskName: route.strTaskName,
                        IndexName: route.IndexName,
                        SelectedDependency: route.SelectedDependency,
                        IsSingleTask: IsSingleTask,
                        // DependencyID: CurrArrauDep.length > 0 ? CurrArrauDep.filter(x => x.DependentTaskName === child.childId)[0].DependencyID : 0,
                        // LogDays: CurrArrauDep.length > 0 ? CurrArrauDep.filter(x => x.DependentTaskName === child.childId)[0].LogDays : '',
                        DependencyID: NxtArrayDep.length > 0 && NxtArrayDep.filter(x => x.DependentTaskId === route.TNARouteApplyID).length > 0 ?
                            NxtArrayDep.filter(x => x.DependentTaskId === route.TNARouteApplyID)[0].DependencyID : 0,
                        LogDays: NxtArrayDep.length > 0 && NxtArrayDep.filter(x => x.DependentTaskId === route.TNARouteApplyID).length > 0 ?
                            NxtArrayDep.filter(x => x.DependentTaskId === route.TNARouteApplyID)[0].LogDays : '',

                        DetailTypeID: route.DetailTypeID,
                        SubTaskList: [],
                        //IsExpanded: values[i].IsExpanded,
                        IsExpanded: false,
                        RownoforAllTasks: route.RownoforAllTasks

                    });

                    if (IsSingleTask === false) {
                        values[i].SubTaskList && values[i].SubTaskList.filter(d => d.TaskID === route.TaskID).map((element, p) => {
                            routeInformation[i].SubTaskList.push({
                                ParentId: element.ParentId,
                                TaskID: element.TaskID,
                                MainId: element.MainId,
                                Code: element.Code,
                                Color: element.Color,
                                ColorOrName: element.ColorOrName,
                                ImagePath: element.ImagePath,
                                ImageUrl: ImageUrl,
                                StrikeOffOrTrimInfoId: element.StrikeOffOrTrimInfoId,
                                //  IsExpanded: values[i].SubTaskList[p].IsExpanded,
                                IsExpanded: false,
                                hasAutoOpen: false,

                                ChildTaskList: []
                            })


                            values[i].SubTaskList[p].ChildTaskList && values[i].SubTaskList[p].ChildTaskList.filter(d => d.TaskID === route.TaskID && d.MainId === element.MainId).map((child, c) => {

                                routeInformation[i].SubTaskList[p].ChildTaskList.push({
                                    ChildId: child.ChildId,
                                    SubId: child.SubId,
                                    Name: child.Name,
                                    TaskName: route.TaskName,
                                    TaskID: child.TaskID,
                                    MainId: child.MainId,
                                    StrikeOffOrTrimInfoId: child.StrikeOffOrTrimInfoId,
                                    ChildIndex: ChildIndex,
                                    DependencyCount: 0,
                                    SelectedDependency: child.SelectedDependency,
                                    DependencyID: NxtArrayDep.length > 0 && NxtArrayDep.filter(x => x.DependentTaskId === child.ChildId).length > 0 ?
                                        NxtArrayDep.filter(x => x.DependentTaskId === child.ChildId)[0].DependencyID : 0,
                                    LogDays: NxtArrayDep.length > 0 && NxtArrayDep.filter(x => x.DependentTaskId === child.ChildId).length > 0 ?
                                        NxtArrayDep.filter(x => x.DependentTaskId === child.ChildId)[0].LogDays : '',
                                    StartDate: child.StartDate,
                                    EndDate: child.EndDate,
                                    Duration: child.Duration,
                                    RownoforAllTasks: child.RownoforAllTasks,
                                    SKUIdList: child.SKUIdList,
                                    StyleStrikeOffGarmentSizeIdList: child.StyleStrikeOffGarmentSizeIdList
                                });
                                ChildIndex++;
                            });


                            if (routeInformation[i].SubTaskList[p].ChildTaskList.filter(x => x.DependencyID !== 0).length > 0) {
                                //if(values[index].SubTaskList[subIndex + 1].ChildTaskList[childIndex].IsShowSubmitButton === 2){
                                routeInformation[i].IsExpanded = true;
                                // routeInformation[i].SubTaskList.map(item => {
                                //     item.IsExpanded = true;
                                // })
                                routeInformation[i].SubTaskList[p].IsExpanded = true;
                                //}
                                //subIndex++;
                            }
                        });
                    }
                });
            }
            setInputFieldsChildDep(routeInformation);
            // k++;
            // localStorage.setItem("k", k); // Store the updated value of k in localStorage
        }
        ChildDepandencyCalculation();
        let currentrow = values[DepandencyAllIndex.Index].SubTaskList[DepandencyAllIndex.SubIndex].
            ChildTaskList[DepandencyAllIndex.ChildIndex];
        if (values[DepandencyAllIndex.Index].DetailTypeID === 4 && values[DepandencyAllIndex.Index].DependencyCount === 0) {
            reloadExfactcal(currentrow, DepandencyAllIndex.Index, DepandencyAllIndex.SubIndex,
                DepandencyAllIndex.ChildIndex, values[DepandencyAllIndex.Index].LeaveDays, values[DepandencyAllIndex.Index].titleEmployee1)
        }

    }
    // endregion Child depandency popup

    let AllTaskList = useSelector((state) => state.task.drpAllTaskList);
    if (IsProduction === 1) {
        AllTaskList = AllTaskList.filter(x => x.taskOrderTypeName === "Sample" || x.taskOrderTypeName === "Sample & Production")
    }
    else if (IsProduction === 2) {
        AllTaskList = AllTaskList.filter(x => x.taskOrderTypeName === "Production" || x.taskOrderTypeName === "Sample & Production")
    }
    const TaskList = AllTaskList;

    // if (IsProduction === 1) {
    //     AllTaskList = AllTaskList.filter(x => x.taskOrderTypeName === "Sample" || x.taskOrderTypeName === "Sample & Production")
    // }
    // else if (IsProduction === 2) {
    //     AllTaskList = AllTaskList.filter(x => x.taskOrderTypeName === "Production" || x.taskOrderTypeName === "Sample & Production")
    // }
    // const TaskList = AllTaskList;

    return (
        <Fragment>
            <ReactNotification />
            <div className="page-body">
                <div> {
                    isOpen && (
                        <Lightbox
                            mainSrc={isOpen}
                            alt="bg image"

                            onCloseRequest={
                                () => setIsOpen(false)
                            } />
                    )
                } </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="widget-body" style={{ paddingBottom: "0" }}>
                            {

                                <>
                                    <div className="">
                                        <CommonStyle props={props} Style={getStyleInfo} />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "1rem" }}>
                    <div className="col-xs-12 col-md-12">
                        <div className="flat radius-bordered">
                            <div className="widget-header heading_box_style">


                                <h3 className="heading_style_common">{isLastEdit ? 'Edit' :
                                    SkuUnMappedList !== '' ? 'Activate' : 'Add'} TNA</h3>
                                <div className="buttons-preview" align="right">


                                    <span className="btn btn-outline-info TNANewPOSKU"
                                        onClick={
                                            () => handleNewSKUOpen()
                                        }
                                    >
                                        <i className="fa fa-plus"></i>
                                        &nbsp;New SKU Task</span>
                                    <span className="btn btn-primary"
                                        onClick={
                                            () => ResetOperation("Back")
                                        }
                                    >
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back</span>

                                    <span className="btn btn-danger"
                                        onClick={
                                            () => ResetOperation()
                                        } >
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset</span>
                                    {isLastEdit &&
                                        <span className="btn btn-outline-info TNANewPOSKU"
                                            onClick={
                                                () => handleNewPOSKUOpen()
                                            }
                                        >
                                            <i className="fa fa-plus"></i>
                                            &nbsp;New PO SKU</span>
                                    }



                                    {isLastEdit &&
                                        <span className="btn btn-outline-info TNANewPOSKU"
                                            onClick={
                                                () => handleRemovePOSKUOpen()
                                            }
                                        >
                                            <i className="fa fa-minus"></i>
                                            &nbsp;Remove PO SKU</span>
                                    }

                                    <button type="button" className="btn btn-success"
                                        onClick={UpdateTNA}
                                    >
                                        <i className="fa fa-check right"></i>
                                        &nbsp;{isLastEdit ? 'Update' : 'Save'}
                                    </button>
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        {taskNameList !== '' && taskNameList !== null && taskNameList !== undefined &&
                                            <div className="col-md-12">
                                                <div className="alert alert-danger">
                                                    Note: Oops! Can you remove irrelevant tasks in the below list, for Example,<span><b>{taskNameList}</b></span> and should activate particular TNA!
                                                </div>
                                            </div>
                                        }
                                        {SkuUnMappedList !== '' && SkuUnMappedList !== null && SkuUnMappedList !== undefined && (taskNameList === '' || taskNameList === null || taskNameList === undefined) &&

                                            <span style={{ color: "red" }}><b>oops ! Below Highlighted Tasks has UnMatched Sku with Depandenct Task's, you can delete or Set Depandency Manually after the activation!</b></span>

                                        }
                                        {(SkuUnMappedList === '' || SkuUnMappedList === null || SkuUnMappedList === undefined) && (taskNameList === '' || taskNameList === null || taskNameList === undefined) &&
                                            <span style={{ color: "red" }}><b>&nbsp;&nbsp;&nbsp; Note: UnMatched Sku Rows Highlighted here by Default, you can Delete or Assign Dependency Manually</b></span>
                                        }
                                        {/* {SkuUnMappedListCurrPage !== '' && SkuUnMappedListCurrPage !== null && SkuUnMappedListCurrPage !== undefined &&
                                            <div className="col-md-12">
                                                <div className="alert alert-danger">
                                                    Note: Oops! <span><b>UnMatched Sku Rows Highlighted here by Default, you can Delete or Assign Depandency Manually for Task Close</b></span>
                                                </div>
                                            </div>
                                        } */}
                                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: "100px" }}>

                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "110%" }}>
                                                <thead>
                                                    {/* <tr>
                                                        <th style={{ width: "28rem" }} className="fixed-column-header-first-tna"> #</th>
                                                        <th style={{ width: "52rem" }}>Task Type<span className="text-danger">*</span></th>
                                                        <th style={{ width: "145rem" }}>Task Name<span className="text-danger">*</span></th>
                                                        <th style={{ width: "13rem" }}>Duration<span className="text-danger">*</span></th>
                                                        <th style={{ width: "26rem" }}>Start Date</th>
                                                        <th style={{ width: "50rem" }}>Plan End Date</th>
                                                        <th style={{ width: "50rem" }}>Actual End Date</th>
                                                        <th style={{ width: "120rem" }}>Dependency</th>
                                                        <th style={{ width: "180rem" }}>Task Holder / Follower<span className="text-danger">*</span></th>
                                                        <th style={{ width: "75rem" }}>Leave Days</th>
                                                        <th style={{ width: "68rem" }}>Status</th>
                                                        <th style={{ width: "80rem" }}>AWB & Status</th>
                                                        <th style={{ width: "230rem" }} className="text-center fixed-column-header-Route">Action</th>

                                                    </tr> */}
                                                    <tr>
                                                        <th style={{ width: "210px" }} className="fixed-column-header-first-tna"> #</th>
                                                        <th style={{ width: "450px" }}>Task Type<span className="text-danger">*</span></th>
                                                        <th style={{ width: "1000px" }}>Task Name<span className="text-danger">*</span></th>
                                                        <th style={{ width: "100px" }}>Duration<span className="text-danger">*</span></th>
                                                        <th style={{ width: "440px" }}>Start Date</th>
                                                        <th style={{ width: "290px" }}>Plan End Date</th>
                                                        <th style={{ width: "440px" }}>Actual End Date</th>
                                                        <th style={{ width: "580px" }}>Dependency</th>
                                                        <th style={{ width: "970px" }}>Task Holder / Follower<span className="text-danger">*</span></th>
                                                        <th style={{ width: "320px" }}>Leave Days</th>
                                                        <th style={{ width: "499px" }}>Status</th>
                                                        <th style={{ width: "480px" }}>AWB & Status</th>
                                                        <th style={{ width: "1600px" }} className="text-center fixed-column-header-Route">Action</th>

                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {

                                                        inputFields.map((inputField, index) => (
                                                            <Fragment key={`${inputField}~${index}`}>

                                                                <tr class="parent" data-child={"parent_" + index}>
                                                                    <td className="fixed-column-first-tna">
                                                                        <b> {index + 1}&nbsp;</b>
                                                                        {
                                                                            !inputField.IsSingleTask && <span id={"btn-toggle_" + index} class="btn-toggle clc" aria-expanded="false" onClick={() => handleToggle(index)} aria-labelledby="btn-toggle id-master">
                                                                                <i id={"icon-toggle_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandTNA"></i>
                                                                            </span>
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        <span title={inputField.TaskTypeTxt} className='input-icon icon-right'>
                                                                            <Reactselect className="basic-single" name="TaskType"
                                                                                id={
                                                                                    "TaskType" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "TaskType")}
                                                                                onBlur={() => CheckDuplicate(index, 'TaskType')}
                                                                                value={getTaskTypeList.filter(function (option) {
                                                                                    return option.value === inputField.TaskType;
                                                                                })}
                                                                                options={getTaskTypeList}
                                                                                menuPosition="fixed"
                                                                                // menuPlacement="auto"
                                                                                isDisabled={inputField.IsNewRow}
                                                                                styles={submitted && inputField.TaskType === 0 ? styles : ''}
                                                                            ></Reactselect>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span title={inputField.strTaskName} className='input-icon icon-right'>
                                                                            <Reactselect className="basic-single" name="TaskName"
                                                                                id={
                                                                                    "TaskName" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "TaskName")}
                                                                                onBlur={() => CheckDuplicate(index, 'TaskName')}
                                                                                value={TaskList.filter(function (option) {
                                                                                    return option.value === inputField.TaskID;
                                                                                })}
                                                                                options={inputField.TaskType === 0 ? TaskList : TaskList.filter(x => x.taskTypeID === inputField.TaskType)}
                                                                                isOptionDisabled={(option) => option.isdisabled}
                                                                                menuPosition="fixed"
                                                                                // menuPlacement="auto"
                                                                                isDisabled={inputField.IsNewRow}
                                                                                styles={submitted && inputField.TaskID === 0 ? styles : ''}
                                                                                classNamePrefix="ParentTaskColor"

                                                                            ></Reactselect>
                                                                        </span>
                                                                    </td>

                                                                    <td>
                                                                        {
                                                                            (inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3)) &&
                                                                            <span className='input-icon icon-right'>
                                                                                <input type="text"
                                                                                    placeholder="Duration"
                                                                                    className={'form-control' + (
                                                                                        submitted && !inputField.Duration ? ' is-invalid' : ''
                                                                                    )}

                                                                                    //value={inputField.IDuration}
                                                                                    disabled
                                                                                />
                                                                                &nbsp;
                                                                            </span>

                                                                        }

                                                                        <span className='input-icon icon-right'>
                                                                            <input type="text"
                                                                                placeholder="Duration"
                                                                                id={"Duration" + index}
                                                                                name="Duration"
                                                                                value={inputField.IsSingleTask ? inputField.Duration : ""}
                                                                                onChange={event => handleChange(event, index, "Duration")}
                                                                                onBlur={() => CheckDuplicate(index, 'Duration')}
                                                                                maxLength="3"
                                                                                // readOnly={!inputField.IsSingleTask}
                                                                                className={'form-control' + (
                                                                                    submitted && !inputField.Duration ? ' is-invalid' : ''
                                                                                )}
                                                                                style={{ border: submitted && !inputField.Duration ? '1px solid red' : '' }}
                                                                                disabled={inputField.IsSingleTask ? false : true}
                                                                            />
                                                                        </span>
                                                                    </td>

                                                                    <td>
                                                                        {
                                                                            (inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3)) &&
                                                                            <span className='input-icon icon-right'>
                                                                                <DatePicker className="form-control min_width_Date"
                                                                                    name="ChildStartdate"
                                                                                    selected={Date.parse(moment(inputField.IStartDate, 'MM/DD/YYYY').toISOString())}
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    readOnly={true}
                                                                                    disabled

                                                                                />
                                                                                &nbsp;
                                                                            </span>
                                                                        }

                                                                        <span className="input-icon icon-right">
                                                                            <DatePicker className="form-control min_width_Date"
                                                                                name="StartDate"
                                                                                id={
                                                                                    "StartDate" + index
                                                                                }
                                                                                selected={
                                                                                    inputField.StartDate
                                                                                }
                                                                                dateFormat="dd/MM/yyyy"
                                                                                readOnly={true}

                                                                            />

                                                                        </span>
                                                                    </td>



                                                                    <td>
                                                                        {
                                                                            (inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3)) &&
                                                                            <span className='input-icon icon-right'>
                                                                                <DatePicker className="form-control min_width_Date"
                                                                                    name="ChildStartdate"
                                                                                    selected={Date.parse(moment(inputField.IEndDate, 'MM/DD/YYYY').toISOString())}
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    readOnly={true}
                                                                                    disabled

                                                                                />
                                                                                &nbsp;
                                                                            </span>
                                                                        }
                                                                        <span className="input-icon icon-right">
                                                                            {inputField.IsSingleTask}
                                                                            <DatePicker className="form-control min_width_Date"
                                                                                name="EndDate"
                                                                                id={
                                                                                    "EndDate" + index
                                                                                }
                                                                                selected={
                                                                                    inputField.EndDate
                                                                                }
                                                                                onChange={
                                                                                    event => handleChange(event, index, "EndDate")
                                                                                }
                                                                                // onChange={
                                                                                //     event => handleChangeChild(event, index, subIndex, childIndex, "EndDate", childTask.ChildIndex)
                                                                                // }
                                                                                // onBlur={() => DepandantTaskcalconoff(index, subIndex, childIndex, 'EndDate', childTask.ChildIndex)}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="scroll"
                                                                                autoComplete="off"
                                                                                popperProps={{
                                                                                    positionFixed: true // use this to make the popper position: fixed
                                                                                }}
                                                                                //readOnly={Buttonvisible === 'View' ? true : false}
                                                                                customInput={<CustomInput />}
                                                                                //readOnly={true}
                                                                                //disabled={(inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3)) ? false : true}
                                                                                //disabled={(inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3)) ? false : inputField.StatusID === 3 ? true : false}
                                                                                //disabled={inputField.StatusID === 3 && inputField.IsSingleTask === false ? true :  false}
                                                                                disabled={inputField.IsSingleTask === false ? true : false}
                                                                            //

                                                                            />


                                                                        </span>
                                                                    </td>


                                                                    <td>
                                                                        <span className="input-icon icon-right">
                                                                            <DatePicker className="form-control min_width_Date"
                                                                                name="ActualEndDate"
                                                                                id={
                                                                                    "ActualEndDate" + index
                                                                                }
                                                                                selected={Date.parse(moment(inputField.ActualEndDate, 'MM/DD/YYYY').toISOString())}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                readOnly={true}

                                                                            />

                                                                        </span>


                                                                    </td>


                                                                    <td>

                                                                        {
                                                                            //index === 0 ? '' :
                                                                            <input type="text"
                                                                                value={inputField.SelectedDependency}
                                                                                title={inputField.SelectedDependency}
                                                                                onClick={() => handleDependency(index)}
                                                                                className={'form-control'}
                                                                                disabled={inputField.IsSingleTask === false ? true : false}
                                                                                style={{ width: inputField.DependencyCount === 0 ? "100%" : "82%", float: "left" }}

                                                                            />
                                                                        }
                                                                        {/* {
                                                                            //index !== 0 ?

                                                                            inputField.DependencyCount !== 0 ?
                                                                                <span className="spanDependencyCount"

                                                                                > {inputField.DependencyCount}</span> : ''

                                                                        } */}

                                                                    </td>
                                                                    <td>
                                                                        {/* <input type="text"
                                                                            value={inputField.TaskHolderOrFollowerTxt}
                                                                            title={inputField.TaskHolderOrFollowerTxt}
                                                                            onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                            className={'form-control'}
                                                                            //disabled={Buttonvisible === 'View' ? true : false}
                                                                            //style={{ width: "100%", float: "left" }}
                                                                            style={{ width: "100%", float: "left", border: submitted && !inputField.TaskHolderOwnerIdList ? '1px solid red' : '' }}
                                                                        /> */}

                                                                        <span

                                                                            className={'form-control'} onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                            title="Task Holder /Follower"
                                                                            style={{ width: "100%", paddingTop: "8px", float: "left", border: submitted && !checkTaskHolderOwer(index) ? '1px solid red' : '' }}
                                                                        //<i class="fa fa-eye" ></i>
                                                                        >&nbsp;&nbsp;&nbsp;  <i className="fa fa-eye" style={{ marginRight: '4px' }} title="Task Holder /Follower"></i>
                                                                            {
                                                                                getTaskHolderList.filter(d => d.value === inputField.TaskHolderOrFollower).map(task => {
                                                                                    return task.label;
                                                                                })
                                                                            }

                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text"
                                                                            value={inputField.LeaveDaysTxt}
                                                                            title={inputField.LeaveDaysTxt}
                                                                            //disabled={Buttonvisible === 'View' ? true : false}
                                                                            onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                            className={'form-control'}
                                                                            style={{ width: "100%", float: "left" }}
                                                                        />

                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            <Reactselect className="basic-single" name="StatusID"
                                                                                id={
                                                                                    "Status" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={false}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "StatusID")}
                                                                                value={getTNAStatusList.filter(function (option) {
                                                                                    return option.value === inputField.StatusID;
                                                                                })}
                                                                                //isDisabled={inputField.StatusID === 3 ? true : inputField.IsSingleTask === false ? true : false}
                                                                                isDisabled={inputField.StatusID === 3 || inputField.IsSingleTask || !inputField.IsSingleTask}
                                                                                options={getTNAStatusList}
                                                                                isOptionDisabled={(option) => option.isdisabled}
                                                                                menuPosition="fixed"
                                                                            // menuPlacement="auto"
                                                                            ></Reactselect>


                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            <input type="text"
                                                                                placeholder="AWB & Status"
                                                                                id={"AWBStatus" + index}
                                                                                name="AWBStatus"
                                                                                value={inputField.AWBStatus}
                                                                                onChange={event => handleChange(event, index, "AWBStatus")}
                                                                                className={'form-control' + (
                                                                                    submitted && !inputField.AWBStatus ? ' is-invalid' : ''
                                                                                )}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                    {newskuTask === true &&
                                                                        <Modal show={newskuTask === true} size="lg" dialogClassName="commonwidth" backdrop="static" keyboard={false} onHide={() => handleSKUOpen(index)}>
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>
                                                                                    Add SKU Task
                                                                                </Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "100%" }}>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ width: "50px" }} className="fixed-column-header-first-tna"> #</th>
                                                                                            <th colSpan='2' style={{ width: "300px" }}>Task Name<span className="text-danger">*</span></th>
                                                                                            {/* <th style={{ width: "150px" }}>Dependency<span className="text-danger">*</span></th>
                                                                                            <th style={{ width: "80px" }}>Log Days</th> */}
                                                                                        </tr>

                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {skuTask.map((item, index) => (
                                                                                            <Fragment key={`${item}~${index}`}>
                                                                                                <tr>
                                                                                                    <td className="fixed-column-first-tna" >
                                                                                                        <b> {index + 1}&nbsp;</b>
                                                                                                        {

                                                                                                            <span id={"btn-toggleChildDepandency_" + index} class="btn-toggle " aria-expanded="false" onClick={() => handleExpandParentTask(index)} aria-labelledby="btn-toggleChildDepandency id-master">
                                                                                                                <i id={"icon-toggleChildDepandency_" + index} class={item.IsExpanded ? "fa fa-minus-square-o clsPointer clscollapseexpandTNA" : "fa fa-plus-square-o clsPointer clscollapseexpandTNA"}></i>
                                                                                                            </span>
                                                                                                        }

                                                                                                    </td>

                                                                                                    <td colSpan='2'>
                                                                                                        <span title={item.ParentTaskName}
                                                                                                        //onClick={()=> handleExpandParentTask}
                                                                                                        >
                                                                                                            <b>{item.ParentTaskName}</b>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {

                                                                                                    item.IsExpanded === true &&
                                                                                                    item.SubTaskList.length !== 0 && item.SubTaskList.map((subTask, subIndex) => (


                                                                                                        <tr >
                                                                                                            {/* <td className="fixed-column-first-tna">
                                                                                                                {(index + 1) + ' - ' + (subIndex + 1)}
                                                                                                            </td> */}

                                                                                                            {

                                                                                                                <span id={"btn-toggleChildDepandency_" + index} class="btn-toggle " aria-expanded="false" onClick={() => handleExpandChildTask(index, subIndex)} aria-labelledby="btn-toggleChildDepandency id-master">
                                                                                                                    <i id={"icon-toggleChildDepandency_" + index} class={subTask.IsExpanded ? "fa fa-minus-square-o clsPointer clscollapseexpandTNA" : "fa fa-plus-square-o clsPointer clscollapseexpandTNA"}></i>
                                                                                                                </span>
                                                                                                            }


                                                                                                            <td>
                                                                                                                {subTask.ParentTaskName === 'Program' ?
                                                                                                                    <> {subTask.PantoneTypeName !== "Others" ?
                                                                                                                        <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                                            {
                                                                                                                                subTask.ColorAndlog !== '' ?
                                                                                                                                    <div style={{ background: subTask.ColorAndlog, width: '20%', height: '34px', borderRadius: '2px' }}></div>
                                                                                                                                    :
                                                                                                                                    ''
                                                                                                                            }
                                                                                                                            <b>{subTask.Typename}&nbsp;&nbsp;</b>{subTask.SubDetails}
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        < img src={subTask.ColorAndlog === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.ColorAndlog}
                                                                                                                            alt="Picture" style={{ marginBottom: '5px', height: '40px', width: '50px', border: "1px solid #d5d5d5" }}
                                                                                                                            onClick={
                                                                                                                                event => ViewFiles(event, subTask.ColorAndlog)} />
                                                                                                                    }</> :
                                                                                                                    subTask.ParentTaskName === "StrikeOff" ? <td style={{ display: 'flex', alignItems: 'center' }}>{
                                                                                                                        <img src={subTask.ColorAndlog === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Strikeoff/" + subTask.ColorAndlog}
                                                                                                                            alt="Picture" style={{ marginBottom: '5px', height: '30px', width: '5%', border: "1px solid #d5d5d5" }}
                                                                                                                            onClick={
                                                                                                                                event => ViewFile1(event, subTask.ColorAndlog)} />
                                                                                                                    }<b>{subTask.Typename}&nbsp;&nbsp;</b>{subTask.SubDetails}</td> : <td style={{ display: 'flex', alignItems: 'center' }}>{
                                                                                                                        <img src={subTask.PantoneColor === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + "Images/Style/Trim/" + subTask.ColorAndlog}
                                                                                                                            alt="Picture" style={{ marginBottom: '5px', height: '30px', width: '5%', border: "1px solid #d5d5d5" }}
                                                                                                                            onClick={
                                                                                                                                event => ViewFile1(event, subTask.ColorAndlog)} />
                                                                                                                    }<b>{subTask.Typename}&nbsp;&nbsp;</b>{subTask.SubDetails}</td>

                                                                                                                }
                                                                                                            </td>
                                                                                                            <td> <span
                                                                                                                className="listmodal"
                                                                                                                onChange={(e) => handleCheckboxChange(e, index)}
                                                                                                            >
                                                                                                                <input
                                                                                                                    //style={{ marginLeft: '700px' }}
                                                                                                                    className="hidecheckbox"
                                                                                                                    type="checkbox"
                                                                                                                    name="isChecked"
                                                                                                                    checked={subTask.isChecked}
                                                                                                                />

                                                                                                            </span></td>

                                                                                                        </tr>
                                                                                                        //     {subTask.Childtask.IsExpanded === true && subTask.Childtask && subTask.Childtask.map((childTask, childIndex) => (
                                                                                                        //         <tr key={childIndex}>
                                                                                                        //             <td className="fixed-column-first-tna">
                                                                                                        //                 {(index + 1) + ' - ' + (subIndex + 1)}
                                                                                                        //             </td>
                                                                                                        //             <td style={{ width: "300px" }}>
                                                                                                        //                 {item.ParentTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION ||
                                                                                                        //                  item.ParentTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL ?
                                                                                                        //                     <>
                                                                                                        //                         <i className="fa fa-eye" title="SKU & Other Info"></i>
                                                                                                        //                         <span>{" " + childTask.subDetails}</span>
                                                                                                        //                     </>
                                                                                                        //                     :
                                                                                                        //                     childTask.subDetails
                                                                                                        //                 }
                                                                                                        //             </td>
                                                                                                        //         </tr>
                                                                                                        //     ))}
                                                                                                    ))}
                                                                                            </Fragment>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="success"
                                                                                //onClick={() => handleAddRowChildTask(index)}
                                                                                >
                                                                                    Add
                                                                                </Button>

                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    }
                                                                    <td className="fixed-column-Route">
                                                                        {inputField.IsDependencyOpen === 1 &&

                                                                            <Modal show={inputField.IsDependencyOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleDependencyClose(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        {/* Task Name : {inputField.strTaskName} */}
                                                                                        Dependency - {inputField.strTaskName}
                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body className="dependency-overflow">
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: "50px" }}>#</th>
                                                                                                <th style={{ width: "450px" }}>Task Name</th>
                                                                                                <th style={{ width: "200px" }}>Dependency</th>
                                                                                                <th style={{ width: "150px" }}>Log Days</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                inputField.arrDependency && inputField.arrDependency.map((ChildField, childIndex) => (
                                                                                                    <Fragment key={
                                                                                                        `${ChildField}~${childIndex}`
                                                                                                    }>
                                                                                                        <tr>
                                                                                                            <td>{childIndex + 1}</td>
                                                                                                            <td>{ChildField.TNATaskName}</td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <Reactselect className="basic-single" name="DependencyID"
                                                                                                                        // id={
                                                                                                                        //     "DependencyID" + TaskIndex
                                                                                                                        // }
                                                                                                                        isLoading={false}
                                                                                                                        isClearable={true}
                                                                                                                        isSearchable={true}
                                                                                                                        onChange={event => handleChildChange(event, index, childIndex, "DependencyID")}
                                                                                                                        value={getDependencyList.filter(function (option) {
                                                                                                                            return option.value === ChildField.DependencyID;
                                                                                                                        })}
                                                                                                                        options={getDependencyList}
                                                                                                                        isDisabled={childIndex === index ? true : ChildField.TNATaskName === '' ? true : false}
                                                                                                                    ></Reactselect>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <input type="text"
                                                                                                                        placeholder="    Log days"
                                                                                                                        id={"LogDays" + index}
                                                                                                                        name="LogDays"
                                                                                                                        value={ChildField.LogDays}
                                                                                                                        onChange={event => handleChildChange(event, index, childIndex, "LogDays")}
                                                                                                                        maxLength="3"
                                                                                                                        className={'form-control' + (
                                                                                                                            submitted && !inputField.LogDays ? ' is-invalid' : ''
                                                                                                                        )}
                                                                                                                        disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </Fragment>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="success" onClick={() => handleDependencyClose(index)}>
                                                                                        Save
                                                                                    </Button>

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }

                                                                        {inputField.IsTHLeaveDaysOpen === 1 &&
                                                                            <Modal show={inputField.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysClose(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        Leave Days / In-charges - {inputField.strTaskName}
                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label> Task Holder / Follower</label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                        id={"TaskHolderOrFollower"}
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        onChange={event => handleChange(event, index, "TaskHolderOrFollower")}
                                                                                                        value={getTaskHolderList.filter(function (option) {
                                                                                                            return option.value === inputField.TaskHolderOrFollower;
                                                                                                        })}
                                                                                                        options={getTaskHolderList}
                                                                                                        styles={submitted && inputField.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                        menuPosition="fixed"
                                                                                                        filterOption={filterOption}
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label>Leave Days</label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="LeaveDays"
                                                                                                        id={
                                                                                                            "LeaveDays"
                                                                                                        }
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        onChange={event => handleChange(event, index, "LeaveDays")}
                                                                                                        value={getLeaveDaysList.filter(function (option) {
                                                                                                            return option.value === inputField.LeaveDays;
                                                                                                        })}
                                                                                                        options={getLeaveDaysList}
                                                                                                        styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                                                        menuPosition="fixed"

                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {inputField.titleEmployee1 && <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label htmlFor={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {inputField.titleEmployee1 + " Employee"}<span className="text-danger">*</span> </label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                        isDisabled={false}
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        isMulti
                                                                                                        styles={submitted && (inputField.FollowerCubeTxt === "" || inputField.FollowerSupplierTxt === "") ? styles : ''}
                                                                                                        onChange={event => handleChange(event, index, inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                        options={inputField.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                        value={inputField.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList && inputField.arrTaskHolderBuyer : inputField.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList && inputField.arrFollowerCube : drpTNATaskOwnerList.supplierEmployeeList && inputField.arrFollowerSupplier}
                                                                                                    >  </Reactselect>

                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    }

                                                                                    {
                                                                                        inputField.titleEmployee2 && <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {inputField.titleEmployee2 + " Employee"}</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Reactselect className="basic-single" name={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                            isDisabled={false}
                                                                                                            isLoading={false}
                                                                                                            isClearable={true}
                                                                                                            isSearchable={true}
                                                                                                            isMulti
                                                                                                            onChange={event => handleChange(event, index, inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                            options={inputField.titleEmployee2 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee2 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                            value={inputField.titleEmployee2 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee2 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                        // options={IMPORTANCE}
                                                                                                        // value={getStyleList.arrImportance}
                                                                                                        //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                        >  </Reactselect>

                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {inputField.titleEmployee3 && <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label htmlFor="FollowerSupplier" >Follower - {inputField.titleEmployee3 + " Employee"}</label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                        isDisabled={false}
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        isMulti
                                                                                                        onChange={event => handleChange(event, index, inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                        options={inputField.titleEmployee3 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee3 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                        value={inputField.titleEmployee3 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee3 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                    // options={IMPORTANCE}
                                                                                                    // value={getStyleList.arrImportance}
                                                                                                    //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                    >  </Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    }


                                                                                    {/* <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        <label htmlFor="FollowerSupplier" >Related Upcoming Tasks</label>
                                                                                                             </div>
                                                                                                             </div> */}
                                                                                    {/* <table className="table table-striped table-bordered table-hover dataTable no-footer" id="UpcomingTasks">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>#</th>
                                                                                            <th>Task Name</th>
                                                                                            <th> <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                <label>
                                                                                                    <input type="checkbox"
                                                                                                        name="IsSelectAll"
                                                                                                        //id={"IsSelectAll_" + index}
                                                                                                        isMulti
                                                                                                        onChange={event => handleChange(event, index, "IsSelectAll")}
                                                                                                        checked={inputField.IsSelectAll === 1 ? true : false}
                                                                                                        className="colored-blue clearOpacity" />
                                                                                                    <span className="text"></span>
                                                                                                </label>
                                                                                            </div></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            inputField.FollowupDetails.map((ChildField, childIndex) => (
                                                                                                <Fragment key={
                                                                                                    `${inputField}~${index}`
                                                                                                }>
                                                                                                    <tr>
                                                                                                        <td>{childIndex + 1}</td>
                                                                                                        <td>
                                                                                                            <input type="text"
                                                                                                                value={ChildField.AddtionalTaskName}
                                                                                                                className={'form-control'}
                                                                                                                disabled
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                                <label>
                                                                                                                    <input type="checkbox"
                                                                                                                        name="IsTaskSelected"
                                                                                                                        id={"IsTaskSelected_" + index + "_" + childIndex}
                                                                                                                        isMulti
                                                                                                                        onChange={event => handleAddTaskChange(event, index, childIndex, "IsTaskSelected")}
                                                                                                                        checked={ChildField.IsTaskSelected === 1 ? true : false}
                                                                                                                        className="colored-blue clearOpacity"
                                                                                                                    />
                                                                                                                    <span className="text"></span>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </Fragment>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table> */}

                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="success" onClick={() => handleTHLeaveDaysSave(index)}>
                                                                                        Save
                                                                                    </Button>
                                                                                    <Button variant="success" onClick={() => handleTHLeaveDaysSaveNext(index)}>
                                                                                        Save & Next
                                                                                    </Button>

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                        {/* {inputField.isProgramNewtask === true &&
                                                                            <Modal show={inputField.isProgramNewtask === true} size="lg" backdrop="static" keyboard={false} onHide={() => handleAddProgramkClose(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        Add SkuTask
                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label> Task </label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single"
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        //onChange={event => handleChangeChildTask(event)}
                                                                                                        // value={childTask.filter(function (option) {
                                                                                                        //     return option.value === childTask.subId;
                                                                                                        // })}
                                                                                                        options={NewloadedProgram}
                                                                                                        // styles={childTask.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                        menuPosition="fixed"
                                                                                                        filterOption={filterOption}
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>





                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="success" onClick={() => handleAddRowChildTask(index)}>
                                                                                        Add
                                                                                    </Button>

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        } */}

                                                                        {

                                                                            inputField.IsPopupOpen === 1 &&


                                                                            <Modal show={inputField.IsPopupOpen === 1} size="md" backdrop="static" keyboard={false} onHide={() => handleClose(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Additional Task</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>#</th>
                                                                                                <th>Task Name</th>
                                                                                                {/* <th> <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                    <label>
                                                                                                        <input type="checkbox"
                                                                                                            name="IsSelectAll"
                                                                                                            id={"IsSelectAll_" + index}
                                                                                                            isMulti
                                                                                                            onChange={event => handleChange(event, index, "IsSelectAll")}
                                                                                                            checked={inputField.IsSelectAll === 1 ? true : false}
                                                                                                            className="colored-blue clearOpacity"
                                                                                                            disabled={inputField.TaskFieldEnable === 0 ? true : false}
                                                                                                        />
                                                                                                        <span className="text"></span>
                                                                                                    </label>
                                                                                                </div></th> */}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                inputField.FollowupDetails && inputField.FollowupDetails.map((ChildField, childIndex) => (
                                                                                                    <Fragment key={
                                                                                                        `${inputField.FollowupDetails}~${childIndex}`
                                                                                                    }>
                                                                                                        <tr>
                                                                                                            <td>{childIndex + 1}</td>
                                                                                                            <td>
                                                                                                                <input type="text"
                                                                                                                    value={ChildField.AddtionalTaskName}
                                                                                                                    className={'form-control'}
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </td>
                                                                                                            {/* <td>
                                                                                                                <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                                    <label>
                                                                                                                        <input type="checkbox"
                                                                                                                            name="IsTaskSelected"
                                                                                                                            id={"IsTaskSelected_" + index + "_" + childIndex}
                                                                                                                            isMulti
                                                                                                                            onChange={event => handleAddTaskChange(event, index, childIndex, "IsTaskSelected")}
                                                                                                                            checked={ChildField.IsTaskSelected === 1 ? true : false}
                                                                                                                            className="colored-blue clearOpacity"
                                                                                                                            disabled={inputField.TaskFieldEnable === 0 ? true : false}
                                                                                                                        />
                                                                                                                        <span className="text"></span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </td> */}
                                                                                                        </tr>
                                                                                                    </Fragment>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>

                                                                                    {/* <Button disabled={inputField.TaskFieldEnable === 0 ? true : false} variant="success" onClick={() => handleClose(index)}> Save </Button> */}
                                                                                    <Button className="btn btn-xs btn-danger" onClick={() => handleClose(index)}><i class="fa fa-times"></i>&nbsp;Close</Button>
                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }

                                                                        {/* Gowtham */}
                                                                        {

                                                                            inputField.IsComment === 1 &&
                                                                            <Modal show={inputField.IsComment === 1} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleCloseComment(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Comments</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Comments <span className="text-danger">*</span></th>
                                                                                                <th style={{ width: '16%' }}>Date</th>
                                                                                                <th style={{ width: '10%' }}>Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                inputField.arrComment && inputField.arrComment.map((commentId, index) => {
                                                                                                    return (
                                                                                                        commentId.TNAComments !== '' ?
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    {commentId.TNAComments}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {commentId.Date && moment(commentId.Date).format('DD/MM/YYYY')}
                                                                                                                    {/* &nbsp;
                                                                                                                    {commentId.Date && commentId.Date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                                    {/* {commentId.Date} */}
                                                                                                                    {/* {commentId.Date && commentId.Date.toLocaleDateString('en-US')}
                                                                                                                    &nbsp;
                                                                                                                    {commentId.Date && commentId.Date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        <Fragment>
                                                                                                                            <span style={{ padding: '0px', display: index === (inputField.arrComment.length - 1) ? "" : "none", float: "" }}>

                                                                                                                                <span>
                                                                                                                                    <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(commentId.TaskIndexName, commentId.TNAComments, inputField.arrComment.length)}>
                                                                                                                                        <i className="fa fa-edit"></i>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </Fragment>
                                                                                                                    }
                                                                                                                    &nbsp;

                                                                                                                    {


                                                                                                                        <Fragment>
                                                                                                                            <span>
                                                                                                                                <span>
                                                                                                                                    <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(commentId.TaskIndexName, index)}>
                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </Fragment>
                                                                                                                    }
                                                                                                                </td>
                                                                                                            </tr> : ''
                                                                                                    );
                                                                                                })

                                                                                            }
                                                                                            <tr>
                                                                                                <td colSpan="6">
                                                                                                    <textarea
                                                                                                        rows="3"
                                                                                                        placeholder="Enter comments here..."
                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                        maxLength="500" name="Comments"
                                                                                                        id={"Comments"}
                                                                                                        value={inputField.Comments}
                                                                                                        onChange={event => handleChangeTNAComments(event, index, "Comments")}
                                                                                                        // style={{ width: "100%", border: getComment && inputField.Comments === '' ? '1px solid red' : '' }}
                                                                                                        style={{ width: "100%", border: getComment && (inputField.Comments === '' || inputField.Comments === undefined) ? '1px solid red' : '' }}
                                                                                                    >

                                                                                                    </textarea>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>

                                                                                    {/* <Button variant="success" onClick={() => handleSave(index, inputField.arrComment.length, inputField.Comments)}>
                                                                                    Save
                                                                                </Button> */}

                                                                                    <Button variant="success" onClick={() => handleSave(index, inputField.arrComment.length, inputField.Comments, inputField.TaskID)}>
                                                                                        {getCommentSaveText}
                                                                                    </Button>

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                        {/* Sathish */}
                                                                        {

                                                                            inputField.IsAttachment === 1 &&
                                                                            <Modal show={inputField.IsAttachment === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleCloseAttachment(index, "Close")}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title> <span className="lowCaseText widget-caption" style={{ fontSize: '14px' }}>File  Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span></Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12 col-lg-12">

                                                                                            <div className="row">
                                                                                                <div className="col-sm-8 col-lg-8">
                                                                                                    <div className='form-group'>
                                                                                                        <tr>
                                                                                                            <td> <input type="file" id="TNAFileUpload" style={{ fontSize: '14px' }}
                                                                                                                onChange={(e) => AppendFiles(e)}
                                                                                                                accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx" ></input></td>
                                                                                                        </tr>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-lg-12 col-sm-12">
                                                                                                <div className="row no-margin">
                                                                                                    {
                                                                                                        getFiles.length !== 0 ?
                                                                                                            getFiles.map((files, fileindex) => (
                                                                                                                files.TaskIndexName === getTaskIndexFileupload &&
                                                                                                                <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                    <tr title={files.FileName}>
                                                                                                                        <td className="cut_textInTNA">
                                                                                                                            <span style={{ marginRight: '7px' }}>
                                                                                                                                {
                                                                                                                                    files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                        files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                            files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                }
                                                                                                                            </span>  {files.FileName}
                                                                                                                        </td>
                                                                                                                        <td className="button_style_uploda">
                                                                                                                            {
                                                                                                                                files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.FileType === 'application/msword' ?
                                                                                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNARelatedDocs/' + files.FileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                        onClick={
                                                                                                                                            event => ViewFile(event, files.FileName)
                                                                                                                                        }></i>
                                                                                                                            } &nbsp;
                                                                                                                            <p title='Delete File' onClick={(e) => RemoveFiles(e, fileindex, index)}
                                                                                                                                className="btn btn-danger btn-xs delete">
                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                            </p>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    {
                                                                                                                        <div> {
                                                                                                                            isOpenimg && (

                                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNARelatedDocs/' + getImagename}
                                                                                                                                    alt="bg image"
                                                                                                                                    onCloseRequest={
                                                                                                                                        () => setIsOpenimg(false)
                                                                                                                                    } />
                                                                                                                            )
                                                                                                                        } </div>
                                                                                                                    }
                                                                                                                </div>

                                                                                                            ))
                                                                                                            : ''
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>



                                                                                </Modal.Body>
                                                                                <Modal.Footer>

                                                                                    <Button variant="success" onClick={() => handleCloseAttachment(index)}> Save </Button>

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                        {

                                                                            inputField.IsManual === 1 &&

                                                                            <Modal show={inputField.IsManual === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManual(index, inputField.ManualComments, inputField.StatusID)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Manual Close: {inputField.strTaskName}&nbsp;</Modal.Title>
                                                                                    {
                                                                                        inputField.StatusID === 3 &&

                                                                                        <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete(index, 0, 0, 'Parent')}>
                                                                                            <i className="fa fa-trash-o"></i>
                                                                                        </button>
                                                                                    }
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Comments <span className="text-danger">*</span></th>
                                                                                                <th>Close Date <span className="text-danger">*</span></th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            <tr>
                                                                                                <td >
                                                                                                    <textarea
                                                                                                        rows="3"
                                                                                                        placeholder="Enter Comments here..."
                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                        maxLength="500" name="ManualComments"
                                                                                                        id={"ManualComments"}
                                                                                                        value={inputField.ManualComments}
                                                                                                        disabled={inputField.StatusID === 3}
                                                                                                        onChange={event => handleChangeTNAComments(event, index, "ManualComments")}
                                                                                                        style={{ width: "100%", border: getmaincomment && inputField.ManualComments === '' ? '1px solid red' : '' }}>

                                                                                                    </textarea>
                                                                                                </td>
                                                                                                <td >
                                                                                                    <DatePicker className="form-control" name="HolidayDate"
                                                                                                        id={
                                                                                                            "HolidayDate" + index
                                                                                                        }

                                                                                                        selected={
                                                                                                            inputField.ManualCloseDate
                                                                                                        }
                                                                                                        onChange={event => handleChangeTNAComments(event, index, "ManualCloseDate")}
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        peekNextMonth
                                                                                                        showMonthDropdown
                                                                                                        // showYearDropdown
                                                                                                        dropdownMode="scroll"
                                                                                                        autoComplete="off"
                                                                                                        minDate={new Date()}
                                                                                                        maxDate={new Date()}
                                                                                                        customInput={<CustomInput />}
                                                                                                        disabled={inputField.StatusID === 3 ? true : false}
                                                                                                    // disabled

                                                                                                    // isClearable={inputField.HolidayDate === '' ? false : true}
                                                                                                    />


                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>

                                                                                    </table>
                                                                                    <table className="table table-bordered">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Style Name</th>
                                                                                                <th>Style No</th>
                                                                                                <th>PO/ID No</th>
                                                                                                <th>Details</th>
                                                                                                <th>Task Holder / Follower</th>
                                                                                                <th>
                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                            onChange={event => (SelectAll(event))}
                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                            className="colored-blue clearOpacity" />
                                                                                                        <span className="text"></span>
                                                                                                    </label>
                                                                                                </th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                MultiCloseDatas.length !== 0 ?
                                                                                                    MultiCloseDatas.map((item, index) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <tr>
                                                                                                                    <td>{item.styleName}</td>
                                                                                                                    <td>{item.styleNo}</td>
                                                                                                                    <td>{item.idPoNo}</td>
                                                                                                                    <td>{item.details}</td>
                                                                                                                    <td>{item.taskOwnerNameList}</td>
                                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                <input type="checkbox" name="IsSelected"

                                                                                                                                    onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                <span className="text"></span>
                                                                                                                            </label>

                                                                                                                        </div>

                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </>

                                                                                                        )
                                                                                                    })
                                                                                                    :
                                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                            }

                                                                                        </tbody>
                                                                                    </table>


                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    {
                                                                                        inputField.StatusID !== 3 &&
                                                                                        <Button variant="success" onClick={() => handleManual(index, inputField.ManualComments, inputField.ManualCloseDate)}> Save </Button>
                                                                                    }

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                        {
                                                                            inputField.RevicePopup === 1 &&
                                                                            <Modal show={inputField.RevicePopup === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseRevicePopup(index, 0, 0, 'Main', 'Close')}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        Revise History
                                                                                        {
                                                                                            getSubReviceList.length !== 0 ?
                                                                                                <>
                                                                                                    <br />
                                                                                                    (Initial Date  Duration : {getSubReviceList[0].duration} Start Date : {moment(getSubReviceList[0].startDate, 'DD/MM/YYYY')} End Date : {moment(getSubReviceList[0].endDate, 'DD/MM/YYYY')})
                                                                                                </>
                                                                                                : ''

                                                                                        }

                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>

                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Comments<span className="text-danger">*</span></th>
                                                                                                {/* <th>Revice Date<span className="text-danger">*</span></th> */}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            <tr>
                                                                                                <td style={{ width: "600px" }} >
                                                                                                    <textarea
                                                                                                        rows="3"
                                                                                                        placeholder="Enter Comments here..."
                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                        maxLength="500" name="EnterRemarks"
                                                                                                        id={"EnterRemarks"}
                                                                                                        value={ReviceComment}
                                                                                                        onChange={event => handleChangeReviceComments(event, "Comment")}
                                                                                                        style={{ width: "100%", border: Submitrevice && ReviceComment === '' ? '1px solid red' : '' }}>



                                                                                                    </textarea>
                                                                                                </td>

                                                                                            </tr>
                                                                                        </tbody>

                                                                                    </table>
                                                                                    {
                                                                                        <table className="table table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Duration</th>
                                                                                                    <th>Start Date</th>
                                                                                                    <th>End Date</th>
                                                                                                    <th>Comments</th>
                                                                                                    <th>Reviced Date</th>
                                                                                                    <th>Reviced By</th>
                                                                                                    {/* <th>Action </th> */}
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    getSubReviceList.length !== 0 ?
                                                                                                        getSubReviceList.filter(r => r.isInitialData !== 1).map((item, subindex) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <tr>
                                                                                                                        <td style={{ width: "80px" }}>
                                                                                                                            {item.duration}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.startDate}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.endDate}

                                                                                                                        </td>
                                                                                                                        <td style={{ width: "300px" }}>
                                                                                                                            {item.comments}
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.createdDate}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.revicedBy}

                                                                                                                        </td>

                                                                                                                    </tr>
                                                                                                                </>

                                                                                                            )
                                                                                                        })
                                                                                                        :
                                                                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                }

                                                                                            </tbody>
                                                                                        </table>


                                                                                    }

                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="success" onClick={() => handleSaveRevice(index, 0, 0, props.location.state.params.tnaid,
                                                                                        inputField.TNARouteApplyID, inputField.TaskName, inputField.StartDate, inputField.EndDate, inputField.Duration, 'Main',
                                                                                        inputField.EndDateChange, ReviceComment)}>
                                                                                        Add
                                                                                    </Button>
                                                                                </Modal.Footer>
                                                                            </Modal>

                                                                        }

                                                                        {
                                                                            (inputField.ReschedulePopup === 1) &&
                                                                            <Modal show={(inputField.ReschedulePopup === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseReschedulePopup(index, 0, 0, 'Main', 'Close')}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        Reschedule History
                                                                                        {
                                                                                            getSubRescheduleList.length !== 0 ?
                                                                                                <>
                                                                                                    <br />
                                                                                                    (Initial Date  Duration : <b> {getSubRescheduleList[0].duration}</b>&nbsp; &nbsp; Start Date : <b>{getSubRescheduleList[0].startDate}</b>&nbsp; &nbsp;  End Date : <b>{getSubRescheduleList[0].endDate}</b> )
                                                                                                </>
                                                                                                : ''

                                                                                        }

                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>

                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Comments<span className="text-danger">*</span></th>
                                                                                                {/* <th>Revice Date<span className="text-danger">*</span></th> */}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            <tr>
                                                                                                <td style={{ width: "600px" }} >
                                                                                                    <textarea
                                                                                                        rows="3"
                                                                                                        placeholder="Enter Comments here..."
                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                        maxLength="500" name="EnterRemarks"
                                                                                                        id={"EnterRemarks"}
                                                                                                        value={RescheduleComment}
                                                                                                        onChange={event => handleChangeRescheduleComments(event, "Comment")}
                                                                                                        style={{ width: "100%", border: Submitreschedule && getSubRescheduledetails.RescheduleComment === '' ? '1px solid red' : '' }}>



                                                                                                    </textarea>
                                                                                                </td>

                                                                                            </tr>
                                                                                        </tbody>

                                                                                    </table>
                                                                                    {
                                                                                        <table className="table table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Duration</th>
                                                                                                    <th>Start Date</th>
                                                                                                    <th>End Date</th>
                                                                                                    <th>Comments</th>
                                                                                                    <th>Reschedule Date</th>
                                                                                                    <th>Reschedule By</th>
                                                                                                    {/* <th>Action </th> */}
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {

                                                                                                    getSubRescheduleList.length !== 0 ?
                                                                                                        getSubRescheduleList.filter(r => r.isInitialData !== 1).map((item, subindex) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <tr>
                                                                                                                        <td style={{ width: "80px" }}>
                                                                                                                            {item.duration}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.startDate}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.endDate}

                                                                                                                        </td>
                                                                                                                        <td style={{ width: "300px" }}>
                                                                                                                            {item.comments}
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.createdDate}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.rescheduleBy}

                                                                                                                        </td>

                                                                                                                    </tr>
                                                                                                                </>

                                                                                                            )
                                                                                                        })
                                                                                                        :
                                                                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                }

                                                                                            </tbody>
                                                                                        </table>


                                                                                    }

                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    {
                                                                                        inputField.StatusID === 3 &&
                                                                                        <Button variant="success" onClick={() => handleSaveReschedule(index, 0, 0, props.location.state.params.tnaid,
                                                                                            inputField.TNARouteApplyID, inputField.TaskName, inputField.StartDate, inputField.EndDate, inputField.Duration, 'Main',
                                                                                            inputField.EndDateChange, RescheduleComment)}>
                                                                                            {/* {getReviceAction === 'Add' ? 'Save' : 'Update'} */}
                                                                                            Add
                                                                                        </Button>
                                                                                    }

                                                                                </Modal.Footer>
                                                                            </Modal>

                                                                        }

                                                                        {
                                                                            inputField.IsDependencyStartPopup && inputField.IsSingleTask &&
                                                                            <Modal show={inputField.IsDependencyStartPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseDependencyStartPopup(index, 0, 0, inputField.IsSingleTask)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        Dependency Stop / Start History

                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>

                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Dependencytable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Date<span className="text-danger">*</span></th>
                                                                                                <th>Comments<span className="text-danger">*</span></th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            <tr>
                                                                                                <td >
                                                                                                    {
                                                                                                        <DatePicker className="form-control" name="DependencyDate"


                                                                                                            // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                                                                            selected={Date.parse(moment(inputField.DependencyDate, 'MM/DD/YYYY').toISOString())}
                                                                                                            onChange={event => handleChangeDependentStart(index, 0, 0, event, "CommentDate", true)}
                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                            peekNextMonth
                                                                                                            showMonthDropdown
                                                                                                            dropdownMode="scroll"
                                                                                                            autoComplete="off"
                                                                                                            minDate={new Date()}
                                                                                                            readOnly={inputField.intDependency === 1 ? true : false}
                                                                                                            maxDate={new Date()}
                                                                                                            customInput={<CustomInputDependency />}

                                                                                                        />


                                                                                                    }

                                                                                                </td>
                                                                                                <td style={{ width: "600px" }} >
                                                                                                    <textarea
                                                                                                        rows="3"
                                                                                                        placeholder="Enter Comments here..."
                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                        maxLength="500" name="EnterRemarks"
                                                                                                        value={inputField.DependencyComment}
                                                                                                        onChange={event => handleChangeDependentStart(index, 0, 0, event, "Comment", true)}
                                                                                                        style={{ width: "100%", border: submitDependencyStart && !inputField.DependencyComment ? '1px solid red' : '' }}>
                                                                                                    </textarea>
                                                                                                </td>

                                                                                            </tr>
                                                                                        </tbody>

                                                                                    </table>
                                                                                    {
                                                                                        <table className="table table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Action</th>
                                                                                                    <th>Date</th>
                                                                                                    <th>Comments</th>
                                                                                                    <th>Created By</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    getDependencyStartList && getDependencyStartList.length !== 0 ?
                                                                                                        getDependencyStartList.map((item, itemIndex) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <tr style={{ border: itemIndex === 0 ? "1px solid red" : "" }}>
                                                                                                                        <td>
                                                                                                                            {item.dependencyStartstop === 1 ? "Stop" : "Start"}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.dependencyDate}

                                                                                                                        </td>

                                                                                                                        <td>
                                                                                                                            {item.comments}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.createdBy}

                                                                                                                        </td>


                                                                                                                    </tr>
                                                                                                                </>

                                                                                                            )
                                                                                                        })
                                                                                                        :
                                                                                                        <tr><td colSpan="4" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                }

                                                                                            </tbody>
                                                                                        </table>


                                                                                    }

                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="success" onClick={() => handleSaveDependencyStart(index, 0, 0, inputField.IsSingleTask, inputField.intDependency, inputField.strTaskName, inputField)}>

                                                                                        Save
                                                                                    </Button>
                                                                                </Modal.Footer>
                                                                            </Modal>

                                                                        }

                                                                        {/* Parent Task button*/}

                                                                        <button type="button" className="btn btn-xs btn-primary mb-2" title={(getHeaderName === "Edit" ? "Edit" : getHeaderName === "Export" ? "Add" : "View") + " Additional Task"}
                                                                            onClick={() => handleFollowup(index)}>
                                                                            <b> {inputField.AdditionalFollowup} </b>
                                                                            <i className="fa fa-tasks"></i>
                                                                        </button>
                                                                        &nbsp;


                                                                        {CommentsInfo !== undefined && CommentsInfo.isAdd !== 0 &&
                                                                            <button type="button" className="btn btn-xs btn-magenta mb-2" title="Comments" onClick={() => handleComment(index, inputField.CommentsCount, props.location.state.params.tnaid, inputField.TaskID)}>
                                                                                <b> {inputField.CommentsCount} </b>
                                                                                <i className="fa fa-comment"></i>
                                                                            </button>
                                                                        }
                                                                        &nbsp;
                                                                        {AttachmentInfo !== undefined && AttachmentInfo.isAdd !== 0 &&

                                                                            <button type="button" className="btn btn-xs btn-blue mb-2" title="Attachments" onClick={() => handleAttachment(index)}
                                                                            >
                                                                                <b> {inputField.AttachmentCount} </b>
                                                                                <i className="fa fa-upload"></i>
                                                                            </button>
                                                                        }
                                                                        &nbsp;
                                                                        {StartStopInfo && StartStopInfo.isAdd !== 0 && inputField.IsSingleTask && inputField.SelectedDependency &&
                                                                            //    getSelectedDependencyMap(inputField.SelectedDependency) === false &&

                                                                            <button type="button" className={inputField.intDependency === 1 ? "btn btn-xs btn-success mb-2" : "btn btn-xs btn-danger mb-2"} title="Start and Stop(Dependency)"
                                                                                onClick={() => OpenDependentStartPopup(index, 0, 0, inputField.intDependency, props.location.state.params.tnaid, inputField.TaskID, 0, inputField.IsSingleTask)}
                                                                            >
                                                                                <i className={inputField.intDependency === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                            </button>
                                                                        }
                                                                        &nbsp;
                                                                        {
                                                                            // new Date() > new Date(childTask.EndDate) &&
                                                                            (inputField.StatusID !== 3 && inputField.IsSingleTask) && (inputField.ActualEndDate !== 'Manual' && inputField.ActualEndDate !== 'Existing') &&
                                                                            // inputField.ManualComments === '' &&
                                                                            <button type="button" className={parseInt(inputField.IsReviced) === 0 ? "btn btn-xs btn-yellow mb-2" : "btn btn-xs btn-success mb-2"} title='Child Revise'
                                                                                onClick={() => OpenRevicePopup(index, 0, 0, props.location.state.params.tnaid,
                                                                                    inputField.TNARouteApplyID, inputField.TaskName, inputField.StartDate, inputField.EndDate, inputField.Duration, 'Main', inputField.EndDateChange)}
                                                                            >
                                                                                <i className="fa fa-retweet"></i>
                                                                            </button>
                                                                        }
                                                                        &nbsp;
                                                                        {
                                                                            // new Date() > new Date(childTask.EndDate) &&
                                                                            ((inputField.StatusID === 3 || inputField.IsRescheduled === 1) && inputField.IsSingleTask) && (inputField.ActualEndDate !== 'Manual' && inputField.ActualEndDate !== 'Existing') &&
                                                                            // inputField.ManualComments === '' &&
                                                                            <button type="button" className={inputField.IsRescheduled === 0 ? "btn btn-xs btn-light mb-2" : "btn btn-xs btn-success mb-2"} title='Reschedule'
                                                                                onClick={() => OpenReschedulePopup(index, 0, 0, props.location.state.params.tnaid,
                                                                                    inputField.TNARouteApplyID, inputField.TaskName, inputField.StartDate, inputField.EndDate, inputField.Duration, 'Main')}
                                                                            >
                                                                                <i class="fa fa-calendar"></i>
                                                                            </button>
                                                                        }

                                                                        &nbsp;

                                                                        {isLastEdit && inputField.IsSingleTask ?

                                                                            <Fragment>
                                                                                {TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&

                                                                                    <button type="button" className={inputField.ManualComments === '' || inputField.ManualComments === null ? "btn btn-xs btn-darkorange mb-2" : "btn btn-xs btn-success mb-2"} title="Manual Close"
                                                                                        onClick={() => handleManualclose(index, inputField.TNARouteApplyID, inputField.StatusID)}
                                                                                    >
                                                                                        {/* disabled={inputField.StatusID === 3 ? true : false} */}
                                                                                        {/* <b>{inputField.AdditionalManual} </b> */}
                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                        <i className="fa fa-close"></i>
                                                                                    </button>
                                                                                }
                                                                            </Fragment>
                                                                            : ''
                                                                        }

                                                                        {/* {inputField.IsSingleTask &&
                                                                           <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                            // onClick={() => handleAttachment(index)}
                                                                            >
                                                                                <i className="fa fa-file-text-o"></i>
                                                                            </button>
                                                                        </Fragment>
                                                                        } */}

                                                                        <Fragment>
                                                                            &nbsp;
                                                                            <button type="button" className="btn btn-xs btn-success mb-2" title="Add Task"
                                                                                style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                                                                onClick={() => handleAddFields(index)}>
                                                                                <i className="fa fa-plus"></i>
                                                                            </button>
                                                                            &nbsp;

                                                                        </Fragment>

                                                                        <Fragment>&nbsp;
                                                                            {(inputFields.length !== 1 && getHeaderName !== "View") && (
                                                                                <button type="button" className="btn btn-xs btn-danger mb-2" title="Delete Task"
                                                                                    style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                    onClick={() => handleRemoveFields(index, inputField.strTaskName, inputField.RownoforAllTasks)}>
                                                                                    <i className="fa fa-trash-o"></i>
                                                                                </button>
                                                                            )}
                                                                        </Fragment>

                                                                        {/* Parent Task button End*/}


                                                                    </td>
                                                                </tr>
                                                                {

                                                                    inputField.SubTaskList && inputField.SubTaskList.map((subTask, subIndex) => (

                                                                        <Fragment key={
                                                                            `${subTask}~${subIndex}`
                                                                        }>
                                                                            <tr class={"parentChild parent_" + index + " " + ((inputField.IsExpanded || subTask.IsExpanded) ? "" : "hidden")} data-child={"parentChild_" + index + "_" + subIndex} >
                                                                                <td className="fixed-column-first-tna">
                                                                                    <span id={"btn-toggle_parentChild_" + index + "_" + subIndex} class="btn-toggle " aria-expanded={subTask.IsExpanded} onClick={() => handleChildToggle(index, subIndex)} >
                                                                                        <i id={"icon-toggle_parentChild_" + index + "_" + subIndex} class={subTask.IsExpanded ? "fa fa-minus-square-o clsPointer clscollapseexpand-child" : "fa fa-plus-square-o clsPointer clscollapseexpand-child"}></i>
                                                                                    </span>
                                                                                </td>
                                                                                {/* <td>
                                                                                    {
                                                                                        inputField.strTaskName.toUpperCase() === 'FIT SAMPLE SUBMISSION' || inputField.strTaskName.toUpperCase() === 'FIT SAMPLE APPROVAL' ||
                                                                                            inputField.strTaskName.toUpperCase() === 'PROTO SAMPLE SUBMISSION' || inputField.strTaskName.toUpperCase() === 'PROTO SAMPLE APPROVAL' ||
                                                                                            inputField.strTaskName.toUpperCase() === 'SMS SUBMISSION' || inputField.strTaskName.toUpperCase() === 'SMS APPROVAL' ||
                                                                                            inputField.strTaskName.toUpperCase() === 'APPEARANCE AFTER WASH' || inputField.strTaskName.toUpperCase() === 'SMS FABRIC IN-HOUSE'
                                                                                            || inputField.strTaskName.toUpperCase() === 'SMS FABRIC ORDER' || inputField.strTaskName.toUpperCase() === 'SMS PRODUCTION FOLLOW'
                                                                                            || inputField.DetailTypeID === 4
                                                                                            ?
                                                                                            <span> {inputField.strTaskName} </span> :
                                                                                            <span> {subTask.Code} </span>
                                                                                    }

                                                                                </td> */}
                                                                                <td colSpan="11">
                                                                                    {inputField.strTaskName.toUpperCase() === 'FIT SAMPLE SUBMISSION' || inputField.strTaskName.toUpperCase() === 'FIT SAMPLE APPROVAL' ||
                                                                                        inputField.strTaskName.toUpperCase() === 'PROTO SAMPLE SUBMISSION' || inputField.strTaskName.toUpperCase() === 'PROTO SAMPLE APPROVAL' ||
                                                                                        inputField.strTaskName.toUpperCase() === 'SMS SUBMISSION' || inputField.strTaskName.toUpperCase() === 'SMS APPROVAL' ||
                                                                                        inputField.strTaskName.toUpperCase() === 'APPEARANCE AFTER WASH' || inputField.strTaskName.toUpperCase() === 'SMS FABRIC IN-HOUSE'
                                                                                        || inputField.strTaskName.toUpperCase() === 'SMS FABRIC ORDER' || inputField.strTaskName.toUpperCase() === 'SMS PRODUCTION FOLLOW'
                                                                                        || inputField.DetailTypeID === 4
                                                                                        || inputField.strTaskName.toUpperCase() === 'SIZE SET SAMPLE SUBMISSION' || inputField.strTaskName.toUpperCase() === 'SIZE SET SAMPLE APPROVAL'
                                                                                        || inputField.strTaskName.toUpperCase() === 'PRE PRODUCTION SAMPLE SUBMISSION' || inputField.strTaskName.toUpperCase() === 'PRE PRODUCTION SAMPLE APPROVAL'
                                                                                        || inputField.strTaskName.toUpperCase() === 'SHIPMENT SAMPLE SUBMISSION' || inputField.strTaskName.toUpperCase() === 'SHIPMENT SAMPLE APPROVAL'
                                                                                        || inputField.strTaskName.toUpperCase() === 'PHOTO SAMPLE SUBMISSION' || inputField.strTaskName.toUpperCase() === 'PHOTO SAMPLE APPROVAL'
                                                                                        || inputField.strTaskName.toLowerCase() === 'style catalog received'
                                                                                        //|| inputField.strTaskName.toUpperCase() === 'SIZE SET CHECK'
                                                                                        ?
                                                                                        <span><span style={{ color: "blue" }}>{inputField.strTaskName}</span></span>

                                                                                        :
                                                                                        (subTask.ColorOrName === "" || subTask.ColorOrName === null) ?
                                                                                            <>
                                                                                                <div className="trwidth_manual" style={{ textAlign: "center", display: "contents" }}>
                                                                                                    <img className="width_manual"
                                                                                                        src={subTask.ImagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.ImageUrl + subTask.ImagePath}
                                                                                                        alt="Logo"
                                                                                                        onClick={
                                                                                                            () => setIsOpen(subTask.ImagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.ImageUrl + subTask.ImagePath)
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                                <span> {subTask.Code} </span>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <div style={{ background: subTask.ColorOrName, width: '120px', height: '25px', borderRadius: '2px', display: 'inline-block' }}>
                                                                                                </div>
                                                                                                <span style={{ position: "absolute", marginTop: "3px", marginLeft: '5px' }} > {subTask.Code} </span>
                                                                                            </>

                                                                                    }


                                                                                </td>

                                                                                <td className="fixed-column-Route"></td>

                                                                            </tr>

                                                                            {
                                                                                subTask.ChildTaskList && subTask.ChildTaskList.map((childTask, childIndex) => (

                                                                                    <Fragment key={
                                                                                        `${childTask}~${childIndex}`
                                                                                    }>
                                                                                        <tr onClick={() => handleChildRowToggle(index, subIndex, childIndex, childTask.hasAutoOpen)} class={subTask.IsExpanded ? "child_" + index + " parentChild_" + index + "_" + subIndex + " " + (childTask.hasAutoOpen ? "TNAAutoFocusBorder" : "") : "child_" + index + " parentChild_" + index + "_" + subIndex + " " + (subTask.IsExpanded ? "" : "hidden")} >

                                                                                            <td className="fixed-column-first-tna"><b>{(index + 1)}</b>  {"-" + childTask.ChildIndex}</td>
                                                                                            <td colSpan="2">
                                                                                                {
                                                                                                    inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || inputField.strTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL
                                                                                                        ?
                                                                                                        <>

                                                                                                            <i class="fa fa-eye" title="SKU & Other Info" onClick={() => handleSkuAndStyleDetails(childTask.SubId, childTask.MainId, childTask.StrikeOffOrTrimInfoId, inputField.strTaskName, childTask.SKUIdList, childTask.StyleStrikeOffGarmentSizeIdList, childTask)}></i>
                                                                                                            <span>{" " + childTask.Name}</span>
                                                                                                        </>

                                                                                                        : childTask.Name
                                                                                                }
                                                                                            </td>

                                                                                            <td>

                                                                                                {
                                                                                                    (childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3)) &&
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input type="text"
                                                                                                            placeholder="Duration"
                                                                                                            // id={"Duration" + index}
                                                                                                            // name="Duration"
                                                                                                            value={childTask.IDuration}

                                                                                                            onChange={event => handleChangeChild(event, index, subIndex, childIndex, "Duration", childTask.ChildIndex)}
                                                                                                            //  onBlur={() => CheckChildDuplicate(index, subIndex, childIndex, 'Duration')}
                                                                                                            maxLength="3"
                                                                                                            className={'form-control' + (
                                                                                                                submitted && !childTask.Duration ? ' is-invalid' : ''
                                                                                                            )}
                                                                                                            style={{ border: submitted && !childTask.Duration ? '1px solid red' : '' }}
                                                                                                            disabled
                                                                                                        />
                                                                                                        &nbsp;
                                                                                                    </span>

                                                                                                }


                                                                                                <span className='input-icon icon-right'>
                                                                                                    <input type="text"
                                                                                                        placeholder="Duration"
                                                                                                        // id={"Duration" + index}
                                                                                                        // name="Duration"
                                                                                                        value={childTask.Duration}
                                                                                                        onChange={event => handleChangeChild(event, index, subIndex, childIndex, "Duration", childTask.ChildIndex)}
                                                                                                        onFocus={handleFocus}
                                                                                                        onBlur={() => DepandantTaskcalconoff(index, subIndex, childIndex, 'Duration', childTask.ChildIndex)}
                                                                                                        maxLength="3"
                                                                                                        className={'form-control' + (
                                                                                                            submitted && !childTask.Duration ? ' is-invalid' : ''
                                                                                                        )}
                                                                                                        style={{ border: submitted && !childTask.Duration ? '1px solid red' : '' }}
                                                                                                        disabled={(childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3)) ? false : childTask.StatusID === 3 ? true : false}
                                                                                                    />
                                                                                                </span>
                                                                                            </td>

                                                                                            <td>

                                                                                                {
                                                                                                    (childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3)) &&
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <DatePicker className="form-control min_width_Date"
                                                                                                            name="ChildStartdate"
                                                                                                            id={
                                                                                                                "ChildStartdate" + index + '_' + subIndex + '_' + childIndex
                                                                                                            }
                                                                                                            selected={Date.parse(moment(childTask.IStartDate, 'MM/DD/YYYY').toISOString())}
                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                            readOnly={true}
                                                                                                            disabled

                                                                                                        />
                                                                                                        &nbsp;
                                                                                                    </span>
                                                                                                }

                                                                                                <span className="input-icon icon-right">
                                                                                                    <DatePicker className="form-control min_width_Date"
                                                                                                        name="ChildStartdate"
                                                                                                        id={
                                                                                                            "ChildStartdate" + index + '_' + subIndex + '_' + childIndex
                                                                                                        }
                                                                                                        selected={Date.parse(moment(childTask.StartDate, 'MM/DD/YYYY').toISOString())}
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        readOnly={true}

                                                                                                    />

                                                                                                </span>
                                                                                            </td>

                                                                                            <td>

                                                                                                {
                                                                                                    (childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3)) &&
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <DatePicker className="form-control min_width_Date"
                                                                                                            name="ChildEnddate"
                                                                                                            id={
                                                                                                                "ChildEnddate" + index + '_' + subIndex + '_' + childIndex
                                                                                                            }
                                                                                                            selected={Date.parse(moment(childTask.IEndDate, 'MM/DD/YYYY').toISOString())}
                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                            readOnly={true}
                                                                                                            disabled

                                                                                                        />
                                                                                                        &nbsp;
                                                                                                    </span>
                                                                                                }


                                                                                                <span className="input-icon icon-right">
                                                                                                    <DatePicker className="form-control min_width_Date"
                                                                                                        name="ChildEnddate"
                                                                                                        id={
                                                                                                            "ChildEnddate" + index + '_' + subIndex + '_' + childIndex
                                                                                                        }

                                                                                                        selected={Date.parse(moment(childTask.EndDate, 'MM/DD/YYYY').toISOString())}
                                                                                                        onChange={
                                                                                                            event => handleChangeChild(event, index, subIndex, childIndex, "EndDate", childTask.ChildIndex)
                                                                                                        }
                                                                                                        onBlur={() => DepandantTaskcalconoff(index, subIndex, childIndex, 'EndDate', childTask.ChildIndex)}
                                                                                                        onFocus={handleFocus}
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        peekNextMonth
                                                                                                        showMonthDropdown
                                                                                                        showYearDropdown
                                                                                                        dropdownMode="scroll"
                                                                                                        autoComplete="off"
                                                                                                        popperProps={{
                                                                                                            positionFixed: true // use this to make the popper position: fixed
                                                                                                        }}
                                                                                                        //readOnly={Buttonvisible === 'View' ? true : false}
                                                                                                        customInput={<CustomInput />}
                                                                                                        disabled={(childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3)) ? false : childTask.StatusID === 3 ? true : false}
                                                                                                    />
                                                                                                    {
                                                                                                        childTask.TotalHoliday
                                                                                                    }

                                                                                                </span>
                                                                                            </td>


                                                                                            <td>

                                                                                                <span className="input-icon icon-right">
                                                                                                    {
                                                                                                        childTask.ChildActualEndDate === "Manual" ?
                                                                                                            <p><a style={{ cursor: "pointer" }} onClick={event => showManualApproval(index, subIndex, childIndex,
                                                                                                                childTask.SubId, childTask.TaskName)}>{childTask.ChildActualEndDate}</a></p> :
                                                                                                            childTask.ChildActualEndDate === "Existing" ?
                                                                                                                <p><a style={{ cursor: "pointer" }} onClick={event => showExistingApproval(index, subIndex, childIndex,
                                                                                                                    childTask.SubId, childTask.TaskName)}>{childTask.ChildActualEndDate}</a></p> :
                                                                                                                <DatePicker className="form-control min_width_Date"
                                                                                                                    name="ChildActualEndDate"
                                                                                                                    id={
                                                                                                                        "ChildActualEndDate" + index + '_' + subIndex + '_' + childIndex
                                                                                                                    }
                                                                                                                    selected={Date.parse(moment(childTask.ChildActualEndDate, 'MM/DD/YYYY').toISOString())}
                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                    readOnly={true}

                                                                                                                />
                                                                                                    }


                                                                                                </span>

                                                                                                {
                                                                                                    childTask.ManualApprovalType === 1 && (
                                                                                                        <>
                                                                                                            <Modal show={childTask.ManualApprovalType} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideManualApproval(index, subIndex, childIndex)}>
                                                                                                                <Modal.Header closeButton>
                                                                                                                    <Modal.Title>
                                                                                                                        Manual Approval Information
                                                                                                                    </Modal.Title>
                                                                                                                </Modal.Header>
                                                                                                                <Modal.Body>

                                                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">

                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th style={{ width: "300px" }}>Comments<span className="text-danger">*</span></th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>

                                                                                                                            <tr>
                                                                                                                                <td >
                                                                                                                                    <textarea
                                                                                                                                        rows="3"
                                                                                                                                        placeholder="Enter Comments here..."
                                                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                                                        maxLength="500" name="EnterRemarks"
                                                                                                                                        id={"EnterRemarks"}
                                                                                                                                        value={manualApprovalData.manualCloseComment}
                                                                                                                                        style={{ width: "100%" }}
                                                                                                                                    >
                                                                                                                                    </textarea>
                                                                                                                                </td>

                                                                                                                            </tr>

                                                                                                                            <tr>
                                                                                                                                <td colSpan={2}>
                                                                                                                                    <table className="table table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                        <tbody>  <tr style={{ textAlign: "-webkit-center" }}>

                                                                                                                                            <div className="col-lg-12 col-sm-12">
                                                                                                                                                <div className="row no-margin">
                                                                                                                                                    {
                                                                                                                                                        manualApprovalData.files && manualApprovalData.files.length !== 0 ?
                                                                                                                                                            manualApprovalData.files.map((files, fileindex) => (
                                                                                                                                                                <> {
                                                                                                                                                                    <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                                                                        <tr title={files.fileName}>
                                                                                                                                                                            <td className="cut_textInTNA">
                                                                                                                                                                                <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                    {
                                                                                                                                                                                        files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                            files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                    files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                    <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                    }
                                                                                                                                                                                </span>  {files.fileName}
                                                                                                                                                                            </td>

                                                                                                                                                                            <td className="button_style_uploda">

                                                                                                                                                                                &nbsp;
                                                                                                                                                                                {
                                                                                                                                                                                    files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                        <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                        <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                                            onClick={
                                                                                                                                                                                                event => ViewFile(event, files.fileName)
                                                                                                                                                                                            }></i>
                                                                                                                                                                                }
                                                                                                                                                                            </td>
                                                                                                                                                                        </tr>



                                                                                                                                                                        {

                                                                                                                                                                            <div> {
                                                                                                                                                                                isOpenimg && (

                                                                                                                                                                                    <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                        alt="bg image"
                                                                                                                                                                                        onCloseRequest={
                                                                                                                                                                                            () => setIsOpenimg(false)
                                                                                                                                                                                        } />
                                                                                                                                                                                )
                                                                                                                                                                            } </div>
                                                                                                                                                                        }
                                                                                                                                                                    </div>
                                                                                                                                                                }
                                                                                                                                                                </>
                                                                                                                                                            ))
                                                                                                                                                            : <tr ><td colSpan="8" className='norecordfound'> <div align="center"> <span>No Attachments Founds</span></div></td></tr>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </tr>
                                                                                                                                        </tbody>


                                                                                                                                    </table>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </Modal.Body>

                                                                                                            </Modal>
                                                                                                        </>
                                                                                                    )
                                                                                                }

                                                                                                {
                                                                                                    childTask.ExistingApprovalType === 1 ?
                                                                                                        <Modal show={childTask.ExistingApprovalType === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideExistingApproval(index, subIndex, childIndex)}>
                                                                                                            <Modal.Header closeButton>
                                                                                                                <Modal.Title>
                                                                                                                    Existing Approval Information
                                                                                                                </Modal.Title>
                                                                                                            </Modal.Header>
                                                                                                            <Modal.Body>

                                                                                                                <table className="table table-bordered">
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th>Season Name</th>
                                                                                                                            <th>Approval Date</th>
                                                                                                                            <th>Details</th>
                                                                                                                            <th>Approved By</th>


                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            existingApprovalData && existingApprovalData.length !== 0 ?
                                                                                                                                <>

                                                                                                                                    <tr>
                                                                                                                                        <td>{existingApprovalData[0].seasonName}</td>
                                                                                                                                        <td>{existingApprovalData[0].approvalDate}</td>
                                                                                                                                        <td>{existingApprovalData[0].details}</td>
                                                                                                                                        <td>{existingApprovalData[0].approvedBy}</td>

                                                                                                                                    </tr>
                                                                                                                                </> :
                                                                                                                                <tr>
                                                                                                                                    <td colSpan={4} className='norecordfound'>
                                                                                                                                        <div align="center"> <span>No Records Found</span></div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                        }

                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </Modal.Body>

                                                                                                        </Modal> : ''
                                                                                                }
                                                                                            </td>

                                                                                            <td>




                                                                                                {
                                                                                                    inputField.DetailTypeID !== 4 ?
                                                                                                        // index === 0 ? '' :
                                                                                                        <input type="text"
                                                                                                            value={childTask.SelectedDependency}
                                                                                                            title={childTask.SelectedDependency}
                                                                                                            onClick={() => handleChildDependency(index, subIndex, childIndex, childTask.ChildIndex, childTask.ChildId, 'Add')}
                                                                                                            className={'form-control'}
                                                                                                            // readOnly={true}
                                                                                                            style={{
                                                                                                                width: "100%", float: "left"
                                                                                                                , border: childTask.hasSKUNotMatch === true && (childTask.SelectedDependency === '' || childTask.SelectedDependency === null) ? '2px solid orange' : ''
                                                                                                            }}
                                                                                                            disabled={childTask.StatusID === 3 ? true :
                                                                                                                (childTask.ChildActualEndDate === 'Manual' || childTask.ChildActualEndDate === 'Existing') ? true : false}
                                                                                                        />
                                                                                                        :
                                                                                                        <input type="text"
                                                                                                            value={
                                                                                                                childTask.SelectedDependency !== '' ? childTask.SelectedDependency + (childTask.AutoText !== undefined ? childTask.AutoText : '') : ''}
                                                                                                            title={
                                                                                                                childTask.SelectedDependency + (childTask.AutoText !== undefined ? childTask.AutoText : '')}
                                                                                                            onClick={() => handleChildDependency(index, subIndex, childIndex, childTask.ChildIndex, childTask.ChildId, 'Add')}
                                                                                                            className={'form-control'}
                                                                                                            // readOnly={true}
                                                                                                            style={{
                                                                                                                width: "100%", float: "left"
                                                                                                                , border: (childTask.SelectedDependency === '' || childTask.SelectedDependency === null) ? '2px solid orange' : ''
                                                                                                            }}
                                                                                                            disabled={childTask.StatusID === 3 ? true :
                                                                                                                (childTask.ChildActualEndDate === 'Manual' || childTask.ChildActualEndDate === 'Existing') ? true : false}
                                                                                                        />
                                                                                                    // border: childTask.hasSKUNotMatch === true && (childTask.SelectedDependency === '' || childTask.SelectedDependency === null) ? '2px solid orange' : ''
                                                                                                }
                                                                                                {/* {
                                                                                                    childTask.DependencyCount !== 0 ?
                                                                                                        <span className="spanDependencyCount"
                                                                                                            onClick={() => handleChildDependency(index, subIndex, childIndex, childTask.ChildIndex, childTask.ChildId, 'View')}
                                                                                                            disabled={(childTask.ChildActualEndDate === 'Manual' || childTask.ChildActualEndDate === 'Existing') ? true : false}
                                                                                                        > {childTask.DependencyCount}</span> : ''
                                                                                                } */}
                                                                                            </td>
                                                                                            <td>
                                                                                                {/* <input type="text"
                                                                                                    value={inputField.TaskHolderOrFollowerTxt}
                                                                                                    title={inputField.TaskHolderOrFollowerTxt}
                                                                                                    //onClick={() => handleTaskHolderLeaveDaysChild(index, subIndex, childIndex, childTask.IsTHFirstTime)}
                                                                                                    className={'form-control'}
                                                                                                    readOnly={true}
                                                                                                    //style={{ width: "100%", float: "left" }}
                                                                                                    style={{ width: "100%", float: "left", border: submitted && !childTask.TaskHolderOwnerIdList ? '1px solid red' : '' }}
                                                                                                /> */}

                                                                                                <span
                                                                                                    className={'form-control'}
                                                                                                >
                                                                                                    {
                                                                                                        getTaskHolderList.filter(d => d.value === inputField.TaskHolderOrFollower).map(task => {
                                                                                                            return task.label;
                                                                                                        })
                                                                                                    }
                                                                                                </span>
                                                                                            </td>
                                                                                            <td style={{ width: "12%" }}>
                                                                                                <input type="text"
                                                                                                    value={childTask.LeaveDaysTxt}
                                                                                                    title={childTask.LeaveDaysTxt}
                                                                                                    onClick={() => handleTaskHolderLeaveDaysChild(index, subIndex, childIndex, childTask.IsTHFirstTime)}
                                                                                                    className={'form-control'}
                                                                                                    style={{ width: "100%", float: "left" }}
                                                                                                />

                                                                                            </td>
                                                                                            <td>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="StatusID"
                                                                                                        // id={
                                                                                                        //     "Status" + index
                                                                                                        // }
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        onChange={event => handleChangeChild(event, index, subIndex, childIndex, "StatusID")}
                                                                                                        value={getTNAStatusList.filter(function (option) {
                                                                                                            return option.value === childTask.StatusID;
                                                                                                        })}
                                                                                                        options={getTNAStatusList}
                                                                                                        isDisabled={true}
                                                                                                        menuPosition="fixed"
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <input type="text"
                                                                                                        placeholder="AWB & Status"
                                                                                                        // id={"AWBStatus" + index}
                                                                                                        // name="AWBStatus"
                                                                                                        value={inputField.AWBStatus}
                                                                                                        //onChange={event => handleChangeChild(event, index, subIndex, childIndex, "AWBStatus")}
                                                                                                        className={'form-control'}
                                                                                                        readOnly={true}
                                                                                                    />
                                                                                                </span>
                                                                                            </td>
                                                                                            <td className="fixed-column-Route">
                                                                                                {childTask.IsTHLeaveDaysOpen === 1 &&
                                                                                                    <Modal show={childTask.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysChildClose(index, subIndex, childIndex,)}>
                                                                                                        <Modal.Header closeButton>
                                                                                                            <Modal.Title>
                                                                                                                Leave Days
                                                                                                            </Modal.Title>
                                                                                                        </Modal.Header>
                                                                                                        <Modal.Body>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className='form-group'>
                                                                                                                        <label> Task Holder / Follower </label>
                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                            <Reactselect className="basic-single"
                                                                                                                                //name="TaskHolderOrFollower"
                                                                                                                                // id={"TaskHolderOrFollower"}
                                                                                                                                isLoading={false}
                                                                                                                                isClearable={true}
                                                                                                                                isSearchable={true}
                                                                                                                                onChange={event => handleChangeChild(event, index, subIndex, childIndex, "TaskHolderOrFollower")}
                                                                                                                                value={getTaskHolderList.filter(function (option) {
                                                                                                                                    return option.value === childTask.TaskHolderOrFollower;
                                                                                                                                })}
                                                                                                                                options={getTaskHolderList}
                                                                                                                                styles={submitted && childTask.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                                                menuPosition="fixed"
                                                                                                                                filterOption={filterOption}
                                                                                                                                isDisabled={true}
                                                                                                                            ></Reactselect>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className='form-group'>
                                                                                                                        <label>Leave Days</label>
                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                            <Reactselect className="basic-single"
                                                                                                                                //                     name="LeaveDays"
                                                                                                                                // id={
                                                                                                                                //     "LeaveDays"
                                                                                                                                // }
                                                                                                                                isLoading={false}
                                                                                                                                isClearable={true}
                                                                                                                                isSearchable={true}
                                                                                                                                onChange={event => handleChangeChild(event, index, subIndex, childIndex, "LeaveDays")}
                                                                                                                                value={getLeaveDaysList.filter(function (option) {
                                                                                                                                    return option.value === childTask.LeaveDays;
                                                                                                                                })}
                                                                                                                                options={getLeaveDaysList}
                                                                                                                                styles={submitted && childTask.LeaveDays === 0 ? styles : ''}
                                                                                                                                menuPosition="fixed"

                                                                                                                            ></Reactselect>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {childTask.titleEmployee1 && <div className="row">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className='form-group'>
                                                                                                                        <label htmlFor={childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {childTask.titleEmployee1} </label>
                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                            <Reactselect className="basic-single" name={childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}

                                                                                                                                isLoading={false}
                                                                                                                                isClearable={true}
                                                                                                                                isSearchable={true}
                                                                                                                                isMulti
                                                                                                                                onChange={event => handleChangeChild(event, index, subIndex, childIndex, childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                                options={childTask.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : childTask.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                                value={childTask.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList && childTask.arrTaskHolderBuyer : childTask.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList && childTask.arrFollowerCube : drpTNATaskOwnerList.supplierEmployeeList && childTask.arrFollowerSupplier}
                                                                                                                                isDisabled={true}
                                                                                                                            >  </Reactselect>

                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            }

                                                                                                            {
                                                                                                                childTask.titleEmployee2 && <div className="row">
                                                                                                                    <div className="col-md-12">
                                                                                                                        <div className='form-group'>
                                                                                                                            <label htmlFor={childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {childTask.titleEmployee2}</label>
                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                <Reactselect className="basic-single" name={childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}

                                                                                                                                    isLoading={false}
                                                                                                                                    isClearable={true}
                                                                                                                                    isSearchable={true}
                                                                                                                                    isMulti
                                                                                                                                    onChange={event => handleChangeChild(event, index, subIndex, childIndex, childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                                    options={childTask.titleEmployee2 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : childTask.titleEmployee2 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                                    value={childTask.titleEmployee2 === "Buyer" ? childTask.arrTaskHolderBuyer : childTask.titleEmployee2 === "Cube" ? childTask.arrFollowerCube : childTask.arrFollowerSupplier}
                                                                                                                                    // options={IMPORTANCE}
                                                                                                                                    // value={getStyleList.arrImportance}
                                                                                                                                    //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                                                    isDisabled={true}
                                                                                                                                >  </Reactselect>

                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {childTask.titleEmployee3 && <div className="row">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className='form-group'>
                                                                                                                        <label htmlFor="FollowerSupplier" >Follower - {childTask.titleEmployee3}</label>
                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                            <Reactselect className="basic-single" name={childTask.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}

                                                                                                                                isLoading={false}
                                                                                                                                isClearable={true}
                                                                                                                                isSearchable={true}
                                                                                                                                isMulti
                                                                                                                                onChange={event => handleChangeChild(event, index, subIndex, childIndex, childTask.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                                options={childTask.titleEmployee3 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : childTask.titleEmployee3 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                                value={childTask.titleEmployee3 === "Buyer" ? childTask.arrTaskHolderBuyer : childTask.titleEmployee3 === "Cube" ? childTask.arrFollowerCube : childTask.arrFollowerSupplier}
                                                                                                                                isDisabled={true}
                                                                                                                            >  </Reactselect>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            }

                                                                                                        </Modal.Body>
                                                                                                        <Modal.Footer>
                                                                                                            <Button variant="success" onClick={() => handleTHLeaveDaysChildClose(index, subIndex, childIndex)}>
                                                                                                                Save
                                                                                                            </Button>

                                                                                                        </Modal.Footer>
                                                                                                    </Modal>
                                                                                                }

                                                                                                {childTask.IsAddChildTaskOpen === 1 &&
                                                                                                    <Modal show={childTask.IsAddChildTaskOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleAddChildTaskClose(index, subIndex, childIndex,)}>
                                                                                                        <Modal.Header closeButton>
                                                                                                            <Modal.Title>
                                                                                                                Add Task
                                                                                                            </Modal.Title>
                                                                                                        </Modal.Header>
                                                                                                        <Modal.Body>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className='form-group'>
                                                                                                                        <label> Task </label>
                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                            <Reactselect className="basic-single"
                                                                                                                                isLoading={false}
                                                                                                                                isClearable={true}
                                                                                                                                isSearchable={true}
                                                                                                                                onChange={event => handleChangeChildTask(event)}
                                                                                                                                // value={childTask.filter(function (option) {
                                                                                                                                //     return option.value === childTask.subId;
                                                                                                                                // })}
                                                                                                                                options={childTask.drpChildTaskList}
                                                                                                                                // styles={childTask.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                                                menuPosition="fixed"
                                                                                                                                filterOption={filterOption}
                                                                                                                            ></Reactselect>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>





                                                                                                        </Modal.Body>
                                                                                                        <Modal.Footer>
                                                                                                            <Button variant="success" onClick={() => handleAddRowChildTask(index, subIndex, childIndex, childTask.ChildIndex)}>
                                                                                                                Add
                                                                                                            </Button>

                                                                                                        </Modal.Footer>
                                                                                                    </Modal>
                                                                                                }

                                                                                                {/* <button type="button" className="btn btn-xs btn-magenta" title="Comments" onClick={() => handleComment(index)}>
                                                                                                    <i className="fa fa-comment"></i>
                                                                                                </button>

                                                                                                &nbsp;  <button type="button" className="btn btn-xs btn-blue" title="Attachments" onClick={() => handleAttachment(index)}
                                                                                                >
                                                                                                    <i className="fa fa-upload"></i>
                                                                                                </button>
                                                                                                 &nbsp;  <button type="button" className="btn btn-xs btn-success" title="Depedency Start and Stop"
                                                                                                    onClick={() => handleDependent(index, "Start")}
                                                                                                >
                                                                                                    <i className="fa fa-thumbs-o-up"></i>

                                                                                                </button> */}

                                                                                                &nbsp;
                                                                                                {
                                                                                                    isLastEdit === true ?
                                                                                                        <>



                                                                                                            {
                                                                                                                childTask.IsManualClose === 1 ?
                                                                                                                    <Modal show={childTask.IsManualClose === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup(childTask.StatusID, childTask.ManualCloseComment, childTask.ManualCloseDate, index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {childTask.StatusID == 3 ? 'View ' : ''} Manual Close  : {inputField.strTaskName} &nbsp;
                                                                                                                                {
                                                                                                                                    childTask.StatusID === 3 &&

                                                                                                                                    <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete(index, subIndex, childIndex, childTask.TaskID, childTask.ChildId, childTask.TaskName)}>
                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                    </button>
                                                                                                                                }

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Comments <span className="text-danger">*</span></th>
                                                                                                                                        <th>Close Date <span className="text-danger">*</span></th>
                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>

                                                                                                                                    <tr>
                                                                                                                                        <td style={{ width: "600px" }} >
                                                                                                                                            <textarea
                                                                                                                                                rows="3"
                                                                                                                                                placeholder="Enter Comments here..."
                                                                                                                                                autoFocus onFocus={""} autoComplete="off"
                                                                                                                                                maxLength="500" name="EnterRemarks"
                                                                                                                                                id={"EnterRemarks"}
                                                                                                                                                disabled={childTask.StatusID === 3}
                                                                                                                                                value={childTask.ManualCloseComment}
                                                                                                                                                onChange={event => handleChangeIsManualCloseComments(event, index, subIndex, childIndex, "Comment")}
                                                                                                                                                style={{ width: "100%", border: getComment && childTask.ManualCloseComment === '' ? '1px solid red' : '' }}>

                                                                                                                                            </textarea>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            <DatePicker className="form-control" name="HolidayDate"
                                                                                                                                                id={
                                                                                                                                                    "HolidayDate" + index
                                                                                                                                                }
                                                                                                                                                selected={
                                                                                                                                                    childTask.ManualCloseDate
                                                                                                                                                }
                                                                                                                                                onChange={event => handleChangeIsManualCloseComments(event, index, subIndex, childIndex, "CommentDate")}
                                                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                                                peekNextMonth
                                                                                                                                                showMonthDropdown
                                                                                                                                                // showYearDropdown
                                                                                                                                                dropdownMode="scroll"
                                                                                                                                                disabled={childTask.StatusID === 3}
                                                                                                                                                autoComplete="off"
                                                                                                                                                minDate={new Date()}
                                                                                                                                                maxDate={new Date()}
                                                                                                                                                // style={{ border: getComment && childTask.ManualCloseDate === '' ? '1px solid red' : '' }}
                                                                                                                                                customInput={<CustomInputman />}
                                                                                                                                            // isClearable={inputField.HolidayDate === '' ? false : true}
                                                                                                                                            />
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                            {
                                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || childTask.TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER
                                                                                                                                    ?
                                                                                                                                    <>
                                                                                                                                        {
                                                                                                                                            OrderInfoMulticloseMainDetails.length !== 0 ?

                                                                                                                                                <table className="table table-bordered">

                                                                                                                                                    <tbody>
                                                                                                                                                        {
                                                                                                                                                            OrderInfoMulticloseMainDetails.map((comment, mainindex) => (
                                                                                                                                                                <Fragment>
                                                                                                                                                                    <tr>
                                                                                                                                                                        <td>
                                                                                                                                                                            <div className="row">
                                                                                                                                                                                <div className="col-lg-8">
                                                                                                                                                                                    <i
                                                                                                                                                                                        className={
                                                                                                                                                                                            expandStateMultiSelect[comment.strikeofforTrimLogoPath] ?
                                                                                                                                                                                                'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                                                                                        }
                                                                                                                                                                                        onClick={() => handleExpandRowMultiSelect(comment.strikeofforTrimLogoPath, MultiCloseDatas)}
                                                                                                                                                                                    ></i>
                                                                                                                                                                                    <span className="BuyerPaddingLeft">
                                                                                                                                                                                        {
                                                                                                                                                                                            comment.strikeofforTrimCode
                                                                                                                                                                                        }
                                                                                                                                                                                        -
                                                                                                                                                                                        {
                                                                                                                                                                                            comment.strikeofforTrimName
                                                                                                                                                                                        }
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div className="col-lg-4">
                                                                                                                                                                                    <span className="Actionbutton">
                                                                                                                                                                                        <img src={window.$APIBaseURL + comment.ImageUrl + comment.strikeofforTrimLogoPath} height="50px" width="50px"></img>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </td>

                                                                                                                                                                    </tr>
                                                                                                                                                                    <>
                                                                                                                                                                        {
                                                                                                                                                                            expandedRowsMultiSelect.includes(comment.strikeofforTrimLogoPath) ?

                                                                                                                                                                                <tr>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                                                                            <thead>
                                                                                                                                                                                                <tr>
                                                                                                                                                                                                    <th>Style Name</th>
                                                                                                                                                                                                    <th>Style No</th>
                                                                                                                                                                                                    <th>PO/ID No</th>
                                                                                                                                                                                                    <th>Details</th>
                                                                                                                                                                                                    <th>Task Holder / Follower</th>
                                                                                                                                                                                                    <th>
                                                                                                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                            <input type="checkbox" name="IsSelected"

                                                                                                                                                                                                                onChange={event => (SelectAllST(event, comment.strikeofforTrimLogoPath, mainindex))}
                                                                                                                                                                                                                //  checked={(selectall.IsAllSelected === 1 && selectall.AllSelectedIndex === mainindex) ? true : false}
                                                                                                                                                                                                                checked={comment.isSelectedAll}
                                                                                                                                                                                                                className="colored-blue dm clearOpacity" />
                                                                                                                                                                                                            <span className="text"></span>
                                                                                                                                                                                                        </label>&nbsp;&nbsp;&nbsp;

                                                                                                                                                                                                        <span style={{ float: 'right' }}>
                                                                                                                                                                                                            <button type="button" className={childTask.hasAllDependencyStart === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                                                                                onClick={() => OpenAllDependentStartPopup(index, subIndex, childIndex, childTask.hasAllDependencyStart, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                                                                            >
                                                                                                                                                                                                                <i className={childTask.hasAllDependencyStart === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                                                                            </button>
                                                                                                                                                                                                        </span>


                                                                                                                                                                                                    </th>

                                                                                                                                                                                                </tr>
                                                                                                                                                                                            </thead>
                                                                                                                                                                                            <tbody>
                                                                                                                                                                                                {
                                                                                                                                                                                                    expandListMultiSelect.map((item, index) => (
                                                                                                                                                                                                        <Fragment>

                                                                                                                                                                                                            <tr>
                                                                                                                                                                                                                <td>{item.styleName}</td>
                                                                                                                                                                                                                <td>{item.styleNo}</td>
                                                                                                                                                                                                                <td>{item.idPoNo}</td>
                                                                                                                                                                                                                <td>{item.details}</td>
                                                                                                                                                                                                                <td>{item.taskOwnerNameList}</td>
                                                                                                                                                                                                                <td className="Actionbutton fixed-column">
                                                                                                                                                                                                                    {


                                                                                                                                                                                                                        // (item.dependencyStartstop === 1 || (!inputField.SelectedDependency) || getSelectedDependencyMap(inputField.SelectedDependency) === true || item.isSelected === 1
                                                                                                                                                                                                                        //  ) &&
                                                                                                                                                                                                                        (item.Depandency !== '' && item.dependencyStartstop === 1) ?
                                                                                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                                                                                                                    //onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                                                                                                    onChange={event => (OnSelectRecords(event, item.index - 1))}
                                                                                                                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                                                                    className="colored-blue cy clearOpacity" />
                                                                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                                                                            </label> :
                                                                                                                                                                                                                            item.Depandency === '' ?
                                                                                                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                                                    <input type="checkbox" name="IsSelected"
                                                                                                                                                                                                                                        //onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                                                                                                        onChange={event => (OnSelectRecords(event, item.index - 1))}
                                                                                                                                                                                                                                        checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                                                                        className="colored-blue cy clearOpacity" />
                                                                                                                                                                                                                                    <span className="text"></span>
                                                                                                                                                                                                                                </label> : ''
                                                                                                                                                                                                                    }&nbsp;&nbsp;&nbsp;

                                                                                                                                                                                                                    {
                                                                                                                                                                                                                        // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                                                                                                                                                                                        // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                                                                                                                                                                                        item.Depandency !== '' &&
                                                                                                                                                                                                                        <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                                                                                        // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                                                                                        >
                                                                                                                                                                                                                            <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                                                                                        </button>
                                                                                                                                                                                                                    }

                                                                                                                                                                                                                </td>

                                                                                                                                                                                                            </tr>

                                                                                                                                                                                                        </Fragment>
                                                                                                                                                                                                    ))

                                                                                                                                                                                                }

                                                                                                                                                                                            </tbody>
                                                                                                                                                                                        </table>
                                                                                                                                                                                    </td>
                                                                                                                                                                                </tr> : null
                                                                                                                                                                        }
                                                                                                                                                                    </>
                                                                                                                                                                </Fragment>
                                                                                                                                                            ))
                                                                                                                                                        }






                                                                                                                                                    </tbody>
                                                                                                                                                </table>
                                                                                                                                                :
                                                                                                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                                                                    <thead>
                                                                                                                                                        <tr>
                                                                                                                                                            <th>Style Name</th>
                                                                                                                                                            <th>Style No</th>
                                                                                                                                                            <th>PO/ID No</th>
                                                                                                                                                            <th>Details</th>
                                                                                                                                                            <th>Task Holder / Follower</th>
                                                                                                                                                            <th>Action  </th>

                                                                                                                                                        </tr>
                                                                                                                                                    </thead>
                                                                                                                                                    <tbody>
                                                                                                                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                                                    </tbody>
                                                                                                                                                </table>


                                                                                                                                        }
                                                                                                                                    </>
                                                                                                                                    :

                                                                                                                                    <table className="table table-bordered">     {/* Start Child Manual close  */}

                                                                                                                                        <thead>
                                                                                                                                            <tr>
                                                                                                                                                <th>Style Name</th>
                                                                                                                                                <th>Style No</th>
                                                                                                                                                <th>PO/ID No</th>
                                                                                                                                                <th>SKU Name</th>
                                                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                                                <th>
                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                                            onChange={event => (SelectAll(event))}
                                                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                                                            className="colored-blue xy clearOpacity" />
                                                                                                                                                        <span className="text"></span>
                                                                                                                                                    </label>&nbsp;&nbsp;&nbsp;


                                                                                                                                                    <span style={{ float: 'right' }}>
                                                                                                                                                        <button type="button" className={childTask.hasAllDependencyStart === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                            onClick={() => OpenAllDependentStartPopup(index, subIndex, childIndex, childTask.hasAllDependencyStart, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                        >
                                                                                                                                                            <i className={childTask.hasAllDependencyStart === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                        </button>
                                                                                                                                                    </span>
                                                                                                                                                </th>

                                                                                                                                            </tr>
                                                                                                                                        </thead>
                                                                                                                                        <tbody>
                                                                                                                                            {

                                                                                                                                                MultiCloseDatas.map((item, index) => {
                                                                                                                                                    return (
                                                                                                                                                        <>
                                                                                                                                                            <tr>
                                                                                                                                                                <td>{item.styleName}</td>
                                                                                                                                                                <td>{item.styleNo}</td>
                                                                                                                                                                <td>{item.idPoNo}</td>
                                                                                                                                                                <td>{item.details}</td>
                                                                                                                                                                <td>{item.taskOwnerNameList}</td>
                                                                                                                                                                <td className="Actionbutton fixed-column">
                                                                                                                                                                    {


                                                                                                                                                                        // (item.dependencyStartstop === 1 || (!inputField.SelectedDependency) || getSelectedDependencyMap(inputField.SelectedDependency) === true || item.isSelected === 1
                                                                                                                                                                        //  ) &&
                                                                                                                                                                        (item.Depandency !== '' && item.dependencyStartstop === 1) ?
                                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                                                                    onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                    className="colored-blue h clearOpacity" />
                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                            </label>
                                                                                                                                                                            :
                                                                                                                                                                            item.Depandency === '' ?
                                                                                                                                                                                <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                    <input type="checkbox" name="IsSelected"
                                                                                                                                                                                        onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                                                        checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                        className="colored-blue hd clearOpacity" />
                                                                                                                                                                                    <span className="text"></span>
                                                                                                                                                                                </label> : ''
                                                                                                                                                                    }&nbsp;&nbsp;&nbsp;

                                                                                                                                                                    {
                                                                                                                                                                        // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                                                                                                                                        // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                                                                                                                                        item.Depandency !== '' &&
                                                                                                                                                                        <button type="button" className={(item.dependencyStartstop === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                                        // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, item.dependencyStartstop, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                                        >
                                                                                                                                                                            <i className={item.dependencyStartstop === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                                        </button>
                                                                                                                                                                    }

                                                                                                                                                                </td>
                                                                                                                                                            </tr>
                                                                                                                                                        </>

                                                                                                                                                    )
                                                                                                                                                })
                                                                                                                                            }

                                                                                                                                        </tbody>  {/* END Child Manual close  */}
                                                                                                                                    </table>
                                                                                                                            }

                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                childTask.StatusID !== 3 &&
                                                                                                                                <Button variant="success" onClick={() => SaveChildManulaCloseComments(index, subIndex, childIndex, childTask.ManualCloseComment, childTask.ManualCloseDate, inputField.strTaskName)}>
                                                                                                                                    Save
                                                                                                                                </Button>


                                                                                                                            }


                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }
                                                                                                            {
                                                                                                                <Modal show={childTask.IsOpenInspectionConfirmationType === 1} size="sm" dragable backdrop="static" keyboard={false} onHide={() => handleCloseInspectionConfirmationPopup(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            CONFIRM INSPECTION TYPE
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-success" onClick={() => CreateFinalInspection(1)}>Internal</button>
                                                                                                                            </div>
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-primary" onClick={() => CreateFinalInspection(2)}>External</button>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Modal.Body>
                                                                                                                    {/* <Modal.Footer>
                                                                                                                        {
                                                                                                                            childTask.StatusID !== 3 &&
                                                                                                                            <Button variant="success">
                                                                                                                                Save
                                                                                                                            </Button>
                                                                                                                        }
                                                                                                                    </Modal.Footer> */}
                                                                                                                </Modal>
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsQCinsectionFactory === 1 ?
                                                                                                                    <Modal show={childTask.IsQCinsectionFactory === 1} dialogClassName="commonwidth" size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseQCinspectionFormPopup(index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                                                                                ADD {inputField.strTaskName} &nbsp; {"(Style Name - " + getInspectionInfo.styleName + ")"}

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                                                                                <li className="active">
                                                                                                                                    <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForQAInspection('AddInspection')}>
                                                                                                                                        <h5>Add Inspection </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                                {
                                                                                                                                    inputField.strTaskName !== 'In and Mid Line Inspection' && inputField.strTaskName !== 'PP Meeting' ? <li>
                                                                                                                                        <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForQAInspection('ReInspection')}>
                                                                                                                                            <h5>Re Inspection </h5>
                                                                                                                                        </a>
                                                                                                                                    </li> : ''}
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                {getQAInspectionPageView === 'AddInspection' ? <>
                                                                                                                                    <AddQCInspection props={props} QCfinalInspectionList={getAddQCInspectionListParam}
                                                                                                                                        QCcommonSubmitApprovalParams={commonSubmitApprovalParams} QCFinalInspectionCallback={QCFinalInspectionCallback} SubTaskInfo={getSubTaskList} />
                                                                                                                                </> :
                                                                                                                                    <><QAReInspection props={props} Reinspectionparams={getAddQCInspectionListParam}
                                                                                                                                        QCInspectionCallback={QCInspectionCallback} Taskname={inputField.strTaskName} /></>
                                                                                                                                }
                                                                                                                            </div>

                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                getQCfinalinspectionMulti.length === 0 ? '' :
                                                                                                                                    <Button variant="success" onClick={() => AddQCFactoryForm(index, subIndex, childIndex)}>
                                                                                                                                        Save
                                                                                                                                    </Button>
                                                                                                                            }
                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }

                                                                                                            {
                                                                                                                <Modal show={childTask.IsOpenTestingConfirmationType === 1} size="sm" dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingExFactoryFormPopup(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Inspection Type Confirm
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-success" onClick={() => CreateTesting(1, childTask.TaskName)}>Internal</button>
                                                                                                                            </div>
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-primary" onClick={() => CreateTesting(2, childTask.TaskName)}>External</button>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Modal.Body>

                                                                                                                </Modal>
                                                                                                            }



                                                                                                            {
                                                                                                                childTask.IsTestingTaskFactory === 1 ?
                                                                                                                    <Modal show={childTask.IsTestingTaskFactory === 1} dialogClassName="commonwidth" size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingTaskFormPopup(index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>

                                                                                                                                ADD &nbsp;{inputField.strTaskName}&nbsp;

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                                                                                <li className="active">
                                                                                                                                    <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForTestingInspection('AddInspection')}>
                                                                                                                                        <h5>New Testing</h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                                <li>
                                                                                                                                    <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForTestingInspection('ReInspection')}>
                                                                                                                                        <h5>Re Testing </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                {getTestingReportPageView === 'AddInspection' ? <>
                                                                                                                                    {(inputField.strTaskName === 'FABRIC TEST' || inputField.strTaskName === 'YARN TEST') ? <NewTesting props={props} QCfinalInspectionList={getTestingTaskListParam} Taskname={inputField.strTaskName} SubTaskInfo={getSubTaskList}
                                                                                                                                        QCcommonSubmitApprovalParams={commonSubmitApprovalParams} QCFinalInspectionCallback={QCTestingCallback} /> : <TestingPopup props={props} QCfinalInspectionList={getTestingTaskListParam} Taskname={inputField.strTaskName} SubTaskInfo={getSubTaskList}
                                                                                                                                            QCcommonSubmitApprovalParams={commonSubmitApprovalParams} QCFinalInspectionCallback={QCTestingCallback} />}
                                                                                                                                </> :
                                                                                                                                    <><ReTesting props={props} Reinspectionparams={props.location.state.params}
                                                                                                                                        QCFinalInspectionCallback={QCTestingCallback} Taskname={inputField.strTaskName} /></>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                childTask.StatusID !== 3 &&
                                                                                                                                <Button variant="success" onClick={() => AddTestingExFactoryForm(index, subIndex, childIndex)}>
                                                                                                                                    ADD
                                                                                                                                </Button>
                                                                                                                            }
                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }





                                                                                                            {
                                                                                                                childTask.IsExFactoryForm === 1 ?
                                                                                                                    <Modal dialogClassName="commonwidth" show={childTask.IsExFactoryForm === 1} size="sm" dragable backdrop="static" keyboard={false} onHide={() => handleCloseExFactoryFormPopup(index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                                                                                ADD {inputField.strTaskName} &nbsp;
                                                                                                                                {/* {"(Style Name - " + getInspectionInfo.styleName + ")"} */}

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                                                                                <li className="active">
                                                                                                                                    <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForFinalInspection('AddInspection')}>
                                                                                                                                        <h5>Add Inspection </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                                <li>
                                                                                                                                    <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForFinalInspection('ReInspection')}>
                                                                                                                                        <h5>Re Inspection </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                {getFinalInspectionPageView === "AddInspection" ?
                                                                                                                                    <>
                                                                                                                                        <AddInspection props={props} finalInspectionList={getAddInspectionListParam} skuSelectData={getskusingleID}
                                                                                                                                            commonSubmitApprovalParams={commonSubmitApprovalParams} FinalInspectionCallback={FinalInspectionCallback} SubTaskInfo={getSubTaskList} />
                                                                                                                                    </> : ''}
                                                                                                                                {getFinalInspectionPageView === "ReInspection" ?
                                                                                                                                    <> <ReInspection props={props} Reinspectionparams={getAddInspectionListParam}
                                                                                                                                        FinalInspectionCallback={FinalInspectionCallback} /></> : ''}
                                                                                                                            </div>
                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                getfinalinspectionMulti.length === 0 ? '' :
                                                                                                                                    <Button variant="success" onClick={() => AddExFactoryForm(index, subIndex, childIndex)}>
                                                                                                                                        Save
                                                                                                                                    </Button>
                                                                                                                            }
                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }
                                                                                                            &nbsp;&nbsp;
                                                                                                            {TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                <>

                                                                                                                    {(childTask.IsShowSubmitButton === 1 || childTask.IsShowSubmitButton === 2) && childTask.StatusID !== 3 && childTask.Isdraft !== 1 && childTask.IsQAdraft !== 1 && childTask.IsTestDraft !== 1 && childTask.IsStrikedraft !== 1 && childTask.IsTrimDraft !== 1 ?
                                                                                                                        <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-darkorange mb-2"} title="Manual Close"
                                                                                                                            onClick={() => handleChildManualclose(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskType, inputField.TaskID, childTask.ChildId,
                                                                                                                                childTask.StatusID, childTask.ManualCloseComment, childTask.arrDependency, childTask.intDependency, inputField)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-user"></i>&nbsp;
                                                                                                                            <i className="fa fa-close"></i>
                                                                                                                        </button>
                                                                                                                        </Fragment>
                                                                                                                        : (childTask.ManualCloseComment) && childTask.StatusID === 3 ?
                                                                                                                            <button type="button" className={childTask.ManualCloseComment === '' || childTask.ManualCloseComment === null || childTask.ManualCloseComment === undefined ? "btn btn-xs btn-darkorange mb-2" : "btn btn-xs btn-success mb-2"} title="Manual Close"
                                                                                                                                onClick={() => handleChildManualclose(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskType, inputField.TaskID, childTask.ChildId, childTask.StatusID, childTask.ManualCloseComment)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                                                                <i className="fa fa-close"></i>
                                                                                                                            </button>
                                                                                                                            : ''
                                                                                                                    }
                                                                                                                </>
                                                                                                            }


                                                                                                            {inputField.DetailTypeID === 4 && childTask.StatusID !== 3 && childTask.TaskName === 'FINAL INSPECTION' &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        childTask.StatusID !== 4 ?
                                                                                                                            <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-Report"} style={{ color: '' }} title="Report Create"
                                                                                                                                onClick={() => handleExFactoryFormOpen(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 1, childTask.arrDependency)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-external-link"></i>

                                                                                                                            </button>
                                                                                                                            </Fragment>
                                                                                                                            : ""
                                                                                                                    }

                                                                                                                </>
                                                                                                            }
                                                                                                            {childTask.Isdraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Report View"
                                                                                                                    onClick={() => handleFinalInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, inputField)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {inputField.DetailTypeID === 4 && childTask.StatusID !== 3 && (childTask.TaskName === 'Pilot Run' || childTask.TaskName === "In and Mid Line Inspection" ||
                                                                                                                childTask.TaskName === "Size Set check" || childTask.TaskName === "PP Meeting") &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        childTask.StatusID !== 4 ?
                                                                                                                            <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-Report"} style={{ color: '' }} title="Inspection Create"
                                                                                                                                onClick={() => handleQCInspectionFormOpen(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 1, childTask, childTask.arrDependency)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-external-link"></i>

                                                                                                                            </button>
                                                                                                                            </Fragment>
                                                                                                                            : ""
                                                                                                                    }

                                                                                                                </>
                                                                                                            }
                                                                                                            {childTask.IsQAdraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="QA Report View"
                                                                                                                    onClick={() => handleQAInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, inputField)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }

                                                                                                            {childTask.StatusID !== 3 && (childTask.TaskName === 'Strike off Test' || childTask.TaskName === "Trims Test" ||
                                                                                                                childTask.TaskName === "FABRIC TEST" || childTask.TaskName === "YARN TEST" || childTask.TaskName === "GARMENT TEST") &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        childTask.StatusID !== 4 ?
                                                                                                                            <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-Report"} style={{ color: '' }} title="Testing Report Create"
                                                                                                                                onClick={() => handleTestingTaskFormOpen(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 1, childTask, childTask.arrDependency)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-external-link"></i>

                                                                                                                            </button>
                                                                                                                            </Fragment>
                                                                                                                            : ""
                                                                                                                    }

                                                                                                                </>
                                                                                                            }
                                                                                                            {(childTask.TaskName === "FABRIC TEST" || childTask.TaskName === "YARN TEST" || childTask.TaskName === "GARMENT TEST") && childTask.IsTestDraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Test Report View"
                                                                                                                    onClick={() => handleTestinInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, inputField, 1)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {childTask.TaskName === 'Strike off Test' && childTask.IsStrikedraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Test Report View"
                                                                                                                    onClick={() => handleTestinInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, inputField, 2)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {childTask.TaskName === 'Trims Test' && childTask.IsTrimDraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Test Report View"
                                                                                                                    onClick={() => handleTestinInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, inputField, 3)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }

                                                                                                            {/* Child Task button End*/}
                                                                                                            {
                                                                                                                childTask.IsTestingInsView === 1 ?
                                                                                                                    <Modal show={(childTask.IsTestingInsView === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingView(index, subIndex, childIndex)} >
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {`${childTask.TaskName} List`}
                                                                                                                            </Modal.Title>

                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            {
                                                                                                                                getTestingheaderData.length !== 0 && getTestingheaderData.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; Focus PO No:- {item.pono}
                                                                                                                                                ,&nbsp; &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp;
                                                                                                                                                <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('MM/DD/YYYY')}</h5>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Report No. & Date</th>
                                                                                                                                        <th>Test Type</th>
                                                                                                                                        <th>Test Order Qty</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                        <th>Report Conclusion</th>
                                                                                                                                        <th>Approval Result</th>
                                                                                                                                        {/* <th>Factory</th>
                                                                                        <th>Product Qty</th> */}
                                                                                                                                        <th>Action</th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getnewTestingReportlist.length !== 0 && getnewTestingReportlist.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td >
                                                                                                                                                            {item.reportNo}
                                                                                                                                                        </td>

                                                                                                                                                        <td>
                                                                                                                                                            {item.testType}
                                                                                                                                                        </td>
                                                                                                                                                        {/* <td>
                                                                                                            {/* {item.offerQty} */}
                                                                                                                                                        {/* </td> */}
                                                                                                                                                        <td>
                                                                                                                                                            {item.testOrderQty}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.createdBy}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.reportConclusion}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.approvalResult}
                                                                                                                                                        </td>
                                                                                                                                                        <td style={{ width: "13%" }}>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                                                                onClick={() => handleTestInspecViewDetails(item, 'ViewInspection')}>
                                                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                                                                onClick={() => handleTestInspecViewDetails(item, 'EditInspection')}>
                                                                                                                                                                <i className="fa fa-edit"></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            {getnewTestingReportlist.length === (index + 1) && item.approvalResult !== "Approved" && (
                                                                                                                                                                <button type="button" className="btn btn-xs btn-danger" title="Delete FinalList"
                                                                                                                                                                    onClick={() => handleDeleteTestinspection(item, 'Delete')}>
                                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                                </button>
                                                                                                                                                            )}

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </tbody>

                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                    </Modal>
                                                                                                                    : ""

                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsQAInsView === 1 ?
                                                                                                                    <Modal show={(childTask.IsQAInsView === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseQAInspecView(index, subIndex, childIndex)} >
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {`${inputField.strTaskName} List`}
                                                                                                                            </Modal.Title>

                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            {
                                                                                                                                getQAheaderData.length !== 0 && getQAheaderData.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; Focus PO No:- {item.pono}
                                                                                                                                                ,&nbsp; &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp;
                                                                                                                                                <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('DD/MM/YYYY')}</h5>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Report No. & Date</th>
                                                                                                                                        <th>Factory</th>
                                                                                                                                        <th>Product Qty</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                        <th>Action</th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getnewQAReportlist.length !== 0 && getnewQAReportlist.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td >
                                                                                                                                                            {item.reportNo}
                                                                                                                                                        </td>

                                                                                                                                                        <td>
                                                                                                                                                            {item.factory}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.productQty}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.createdBy}
                                                                                                                                                        </td>
                                                                                                                                                        <td style={{ width: "13%" }}>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                                                                onClick={() => handleQAInspecViewDetails(item, 'View')}>
                                                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                                                                onClick={() => handleQAInspecViewDetails(item, 'Edit')}>
                                                                                                                                                                <i className="fa fa-edit"></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            {getnewQAReportlist.length === (index + 1) && <button type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                                                                                                                onClick={() => handleDeleteQAinspection(item, 'Delete')}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>
                                                                                                                                                            }

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </tbody>

                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                    </Modal>
                                                                                                                    : ""

                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsFinalInsView === 1 ?
                                                                                                                    <Modal show={(childTask.IsFinalInsView === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseFinalInspecView(index, subIndex, childIndex)} >
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                Final Inspection List
                                                                                                                            </Modal.Title>

                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            {
                                                                                                                                getnewheaderdata.length !== 0 && getnewheaderdata.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <h5>SKU ID:{item.skuID}&nbsp; &nbsp; PO/ID No: {item.idno}&nbsp;&nbsp;
                                                                                                                                                {getStyleInfo.buyerName === 'Focus International' ? <>Focus PO No: {item.pono}&nbsp; </> : ''} &nbsp;
                                                                                                                                                Style: {item.styleName}&nbsp; &nbsp; SKU: {item.skuName}&nbsp; &nbsp;
                                                                                                                                                <b>PO Qty: {item.poQty}</b>&nbsp; &nbsp;Ex-Fac: {moment(item.exFacdate).format('DD/MM/YYYY')}</h5>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Report No. & Date</th>
                                                                                                                                        <th>Report Type</th>
                                                                                                                                        <th>Factory</th>
                                                                                                                                        <th>Inspected Qty</th>
                                                                                                                                        <th>Bal. for Inspection</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                        <th>Report Conclusion</th>
                                                                                                                                        <th>Approval Result</th>
                                                                                                                                        <th>Action</th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getnewfinalReportlist.length !== 0 && getnewfinalReportlist.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td >
                                                                                                                                                            {item.reportNo}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.reportType + " - "} {item.ConfirmInspectionType == 1 ? "INT" : "EXT"}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.factory}
                                                                                                                                                        </td>
                                                                                                                                                        <td >
                                                                                                                                                            {item.inspectedQty}
                                                                                                                                                        </td>
                                                                                                                                                        <td >
                                                                                                                                                            {item.balanceQuantity}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.createdBy}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.reportConclusion === null ? '-' : item.reportConclusion}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.approvalResult === null ? 'TBA' : item.approvalResult}
                                                                                                                                                        </td>
                                                                                                                                                        <td style={{ width: "13%" }}>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                                                                onClick={() => handleFinalInspecViewDetails(item.confirmInspectionType, item.tnaFinalInspectionID, 'View', 'ViewInspection', item)}>
                                                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                                                                onClick={() => handleFinalInspecViewDetails(item.confirmInspectionType, item.tnaFinalInspectionID, 'Edit', "EditInspection", item)}>
                                                                                                                                                                <i className="fa fa-edit"></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            {getnewfinalReportlist.length === (index + 1) && <button type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                                                                                                                onClick={() => handleDeletefinalinspection(item.confirmInspectionType, item.tnaFinalInspectionID, 'delete')}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>
                                                                                                                                                            }

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </tbody>

                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                    </Modal>
                                                                                                                    : ""

                                                                                                            }

                                                                                                            {
                                                                                                                (childTask.RevicePopup === 1) &&
                                                                                                                <Modal show={(childTask.RevicePopup === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseRevicePopup(index, subIndex, childIndex, 'Sub', 'Close')}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Revise History
                                                                                                                            {
                                                                                                                                getSubReviceList.length !== 0 ?
                                                                                                                                    <>
                                                                                                                                        <br />
                                                                                                                                        (Initial Date  Duration : {getSubReviceList[0].duration} Start Date : {getSubReviceList[0].startDate} End Date : {getSubReviceList[0].endDate})
                                                                                                                                    </>
                                                                                                                                    : ''

                                                                                                                            }

                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>

                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th>Comments<span className="text-danger">*</span></th>
                                                                                                                                    {/* <th>Revice Date<span className="text-danger">*</span></th> */}
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>

                                                                                                                                <tr>
                                                                                                                                    <td style={{ width: "600px" }} >
                                                                                                                                        <textarea
                                                                                                                                            rows="3"
                                                                                                                                            placeholder="Enter Comments here..."
                                                                                                                                            autoFocus onFocus={""} autoComplete="off"
                                                                                                                                            maxLength="500" name="EnterRemarks"
                                                                                                                                            id={"EnterRemarks"}
                                                                                                                                            value={ReviceComment}
                                                                                                                                            onChange={event => handleChangeReviceComments(event, "Comment")}
                                                                                                                                            style={{ width: "100%", border: Submitrevice && ReviceComment === '' ? '1px solid red' : '' }}>



                                                                                                                                        </textarea>
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                            </tbody>

                                                                                                                        </table>
                                                                                                                        {
                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Duration</th>
                                                                                                                                        <th>Start Date</th>
                                                                                                                                        <th>End Date</th>
                                                                                                                                        <th>Comments</th>
                                                                                                                                        <th>Reviced Date</th>
                                                                                                                                        <th>Reviced By</th>
                                                                                                                                        {/* <th>Action </th> */}
                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getSubReviceList.length !== 0 ?
                                                                                                                                            getSubReviceList.filter(r => r.isInitialData !== 1).map((item, subindex) => {
                                                                                                                                                return (
                                                                                                                                                    <>
                                                                                                                                                        <tr>
                                                                                                                                                            <td style={{ width: "80px" }}>
                                                                                                                                                                {item.duration}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.startDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.endDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td style={{ width: "300px" }}>
                                                                                                                                                                {item.comments}
                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.createdDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.revicedBy}

                                                                                                                                                            </td>
                                                                                                                                                            {/* <td>
                                                                                                                                                                {
                                                                                                                                                                    <>
                                                                                                                                                                        <span style={{ padding: '2px', display: subindex === (getSubReviceList.length - 1) ? "" : "none" }}>
                                                                                                                                                                            <span>
                                                                                                                                                                                <span className="btn btn-success" title="Edit Comment" onClick={() => EditReviceComments(item.tnaTaskReviceId, item.comments, item.reviceDate)}>
                                                                                                                                                                                    <i className="fa fa-edit"></i>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </>
                                                                                                                                                                }
                                                                                                                                                                {

                                                                                                                                                                    <>
                                                                                                                                                                        <span style={{ padding: '2px', display: item.comments === '' ? "none" : "" }}>
                                                                                                                                                                            <span>
                                                                                                                                                                                <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveReviceComments(item.tnaTaskReviceId, index, subIndex, childIndex)}>
                                                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </>
                                                                                                                                                                }
                                                                                                                                                            </td> */}
                                                                                                                                                        </tr>
                                                                                                                                                    </>

                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                                                    }

                                                                                                                                </tbody>
                                                                                                                            </table>


                                                                                                                        }

                                                                                                                    </Modal.Body>
                                                                                                                    <Modal.Footer>
                                                                                                                        <Button variant="success" onClick={() => handleSaveRevice(index, subIndex, childIndex, props.location.state.params.tnaid,
                                                                                                                            childTask.ChildId, childTask.TaskName, childTask.StartDate, childTask.EndDate, childTask.Duration, 'Sub',
                                                                                                                            childTask.EndDateChange, ReviceComment)}>
                                                                                                                            {/* {getReviceAction === 'Add' ? 'Save' : 'Update'} */}
                                                                                                                            Add
                                                                                                                        </Button>
                                                                                                                    </Modal.Footer>
                                                                                                                </Modal>

                                                                                                            }

                                                                                                            {
                                                                                                                (childTask.ReschedulePopup === 1) &&
                                                                                                                <Modal show={(childTask.ReschedulePopup === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseReschedulePopup(index, subIndex, childIndex, 'Sub', 'Close')}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Reschedule History
                                                                                                                            {
                                                                                                                                getSubRescheduleList.length !== 0 ?
                                                                                                                                    <>
                                                                                                                                        <br />
                                                                                                                                        (Initial Date  Duration : <b>{getSubRescheduleList[0].duration}</b> &nbsp; &nbsp; Start Date : <b>{getSubRescheduleList[0].startDate}</b>&nbsp; &nbsp; End Date : <b>{getSubRescheduleList[0].endDate}</b>)
                                                                                                                                    </>
                                                                                                                                    : ''

                                                                                                                            }

                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>

                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th>Comments<span className="text-danger">*</span></th>
                                                                                                                                    {/* <th>Revice Date<span className="text-danger">*</span></th> */}
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>

                                                                                                                                <tr>
                                                                                                                                    <td style={{ width: "600px" }} >
                                                                                                                                        <textarea
                                                                                                                                            rows="3"
                                                                                                                                            placeholder="Enter Comments here..."
                                                                                                                                            autoFocus onFocus={""} autoComplete="off"
                                                                                                                                            maxLength="500" name="EnterRemarks"
                                                                                                                                            id={"EnterRemarks"}
                                                                                                                                            value={RescheduleComment}
                                                                                                                                            onChange={event => handleChangeRescheduleComments(event, "Comment")}
                                                                                                                                            style={{ width: "100%", border: Submitreschedule && getSubRescheduledetails.RescheduleComment === '' ? '1px solid red' : '' }}>



                                                                                                                                        </textarea>
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                            </tbody>

                                                                                                                        </table>
                                                                                                                        {
                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Duration</th>
                                                                                                                                        <th>Start Date</th>
                                                                                                                                        <th>End Date</th>
                                                                                                                                        <th>Comments</th>
                                                                                                                                        <th>Reschedule Date</th>
                                                                                                                                        <th>Reschedule By</th>
                                                                                                                                        {/* <th>Action </th> */}
                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {

                                                                                                                                        getSubRescheduleList.length !== 0 ?
                                                                                                                                            getSubRescheduleList.filter(r => r.isInitialData !== 1).map((item, subindex) => {
                                                                                                                                                return (
                                                                                                                                                    <>
                                                                                                                                                        <tr>
                                                                                                                                                            <td style={{ width: "80px" }}>
                                                                                                                                                                {item.duration}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.startDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.endDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td style={{ width: "300px" }}>
                                                                                                                                                                {item.comments}
                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.createdDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.rescheduleBy}

                                                                                                                                                            </td>

                                                                                                                                                        </tr>
                                                                                                                                                    </>

                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                                                    }

                                                                                                                                </tbody>
                                                                                                                            </table>


                                                                                                                        }

                                                                                                                    </Modal.Body>
                                                                                                                    <Modal.Footer>
                                                                                                                        {
                                                                                                                            childTask.StatusID === 3 &&
                                                                                                                            <Button variant="success" onClick={() => handleSaveReschedule(index, subIndex, childIndex, props.location.state.params.tnaid,
                                                                                                                                childTask.ChildId, childTask.TaskName, childTask.StartDate, childTask.EndDate, childTask.Duration, 'Sub',
                                                                                                                                childTask.EndDateChange, RescheduleComment)}>
                                                                                                                                {/* {getReviceAction === 'Add' ? 'Save' : 'Update'} */}
                                                                                                                                Add
                                                                                                                            </Button>
                                                                                                                        }

                                                                                                                    </Modal.Footer>
                                                                                                                </Modal>

                                                                                                            }

                                                                                                            {/* Child Depandency popup */}

                                                                                                            {childTask.IsChildDepandencyOpen === 1 &&
                                                                                                                <Modal show={childTask.IsChildDepandencyOpen === 1} size="xl" backdrop="static" keyboard={false} onHide={() => handleCloseChildDepandencyPopup(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Child Dependency Map (Selected row  {Depandencyrowno})
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>

                                                                                                                    <Modal.Body style={{ overflowY: "Scroll", height: '91vh' }}>

                                                                                                                        {/* style={{ overflowX: "auto", minHeight: '100px' }} */}
                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "100%" }}>
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th style={{ width: "50px" }} className="fixed-column-header-first-tna"> #</th>
                                                                                                                                    <th style={{ width: "300px" }}>Task Name<span className="text-danger">*</span></th>
                                                                                                                                    <th style={{ width: "150px" }}>Dependency<span className="text-danger">*</span></th>
                                                                                                                                    <th style={{ width: "80px" }}>Log Days</th>

                                                                                                                                </tr>

                                                                                                                            </thead>
                                                                                                                            <tbody>
                                                                                                                                {

                                                                                                                                    inputFieldsChildDep.map((inputField, index) => (
                                                                                                                                        <Fragment key={`${inputField}~${index}`}>

                                                                                                                                            <tr class="parent" data-child={"parentChildDepandency_" + index}>
                                                                                                                                                <td className="fixed-column-first-tna">
                                                                                                                                                    <b> {index + 1}&nbsp;</b>
                                                                                                                                                    {
                                                                                                                                                        !inputField.IsSingleTask && <span id={"btn-toggleChildDepandency_" + index} class="btn-toggle " aria-expanded="false" onClick={() => handleToggleChildDepandency(index)} aria-labelledby="btn-toggleChildDepandency id-master">
                                                                                                                                                            <i id={"icon-toggleChildDepandency_" + index} class={inputField.IsExpanded ? "fa fa-minus-square-o clsPointer clscollapseexpandTNA" : "fa fa-plus-square-o clsPointer clscollapseexpandTNA"}></i>
                                                                                                                                                        </span>
                                                                                                                                                    }

                                                                                                                                                </td>

                                                                                                                                                <td>
                                                                                                                                                    <span title={inputField.strTaskName} className='input-icon icon-right'>
                                                                                                                                                        <Reactselect className="basic-single" name="TaskName"
                                                                                                                                                            id={
                                                                                                                                                                "TaskName" + index
                                                                                                                                                            }
                                                                                                                                                            isLoading={false}
                                                                                                                                                            isClearable={true}
                                                                                                                                                            isSearchable={true}

                                                                                                                                                            value={TaskList.filter(function (option) {
                                                                                                                                                                return option.value === inputField.TaskID;
                                                                                                                                                            })}
                                                                                                                                                            options={inputField.TaskType === 0 ? TaskList : TaskList.filter(x => x.taskTypeID === inputField.TaskType)}
                                                                                                                                                            isOptionDisabled={(option) => option.isdisabled}
                                                                                                                                                            menuPosition="fixed"

                                                                                                                                                            isDisabled
                                                                                                                                                            styles={submitted && inputField.TaskID === 0 ? styles : ''}

                                                                                                                                                        ></Reactselect>
                                                                                                                                                    </span>
                                                                                                                                                </td>

                                                                                                                                                <td style={{ width: "150px" }}>
                                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                                        <Reactselect className="basic-single" name="DependencyID"
                                                                                                                                                            id={
                                                                                                                                                                "DependencyID" + index
                                                                                                                                                            }
                                                                                                                                                            isLoading={false}
                                                                                                                                                            isClearable={true}
                                                                                                                                                            isSearchable={true}
                                                                                                                                                            onChange={event => handleParentChildDepandencyChange(event, index, subIndex, childIndex, "DependencyID", inputField.IsSingleTask)}
                                                                                                                                                            value={getDependencyList.filter(function (option) {
                                                                                                                                                                return option.value === inputField.DependencyID;
                                                                                                                                                            })}
                                                                                                                                                            options={getDependencyList}
                                                                                                                                                            isDisabled={ChildDepPopupMode === "View" ? true
                                                                                                                                                                : index >= DepandencyAllIndex.Index ? true
                                                                                                                                                                    : false}

                                                                                                                                                        ></Reactselect>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                                <td style={{ width: "80px" }}>
                                                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                                                        <input type="text"
                                                                                                                                                            placeholder="    Log days"
                                                                                                                                                            id={"LogDays" + index}
                                                                                                                                                            name="LogDays"
                                                                                                                                                            value={inputField.LogDays}
                                                                                                                                                            onChange={event => handleParentChildDepandencyChange(event, index, subIndex, childIndex, "LogDays", inputField.IsSingleTask)}
                                                                                                                                                            maxLength="3"
                                                                                                                                                            className={'form-control' + (
                                                                                                                                                                submitted && !inputField.LogDays ? ' is-invalid' : ''
                                                                                                                                                            )}
                                                                                                                                                            disabled={inputField.DependencyID === 0 || ChildDepPopupMode === "View" ? true
                                                                                                                                                                : false
                                                                                                                                                            }
                                                                                                                                                        />
                                                                                                                                                    </span>
                                                                                                                                                </td>


                                                                                                                                            </tr>
                                                                                                                                            {

                                                                                                                                                inputField.SubTaskList && inputField.SubTaskList.map((subTask, subIndex) => (

                                                                                                                                                    <Fragment key={
                                                                                                                                                        `${subTask}~${subIndex}`
                                                                                                                                                    }>
                                                                                                                                                        <tr class={"parentChild_ChildDepandency parentChildDepandency_" + index + " " + ((inputField.IsExpanded || subTask.IsExpanded) ? "" : "hidden")} data-child={"parentChild_ChildDepandency_" + index + "_" + subIndex} >
                                                                                                                                                            <td className="fixed-column-first-tna">
                                                                                                                                                                <span id={"btn-toggle_parentChild_ChildDepandency_" + index + "_" + subIndex} class="btn-toggle " aria-expanded={subTask.IsExpanded} onClick={() => handleChildToggleChildDepandency(index, subIndex)} >
                                                                                                                                                                    <i id={"icon-toggle_parentChild_ChildDepandency_" + index + "_" + subIndex} class={subTask.IsExpanded ? "fa fa-minus-square-o clsPointer clscollapseexpand-child" : "fa fa-plus-square-o clsPointer clscollapseexpand-child"}></i>
                                                                                                                                                                </span>
                                                                                                                                                            </td>

                                                                                                                                                            <td colSpan="5">
                                                                                                                                                                {inputField.strTaskName.toUpperCase() === 'FIT SAMPLE SUBMISSION' || inputField.strTaskName.toUpperCase() === 'FIT SAMPLE APPROVAL' ||
                                                                                                                                                                    inputField.strTaskName.toUpperCase() === 'PROTO SAMPLE SUBMISSION' || inputField.strTaskName.toUpperCase() === 'PROTO SAMPLE APPROVAL' ||
                                                                                                                                                                    inputField.strTaskName.toUpperCase() === 'SMS SUBMISSION' || inputField.strTaskName.toUpperCase() === 'SMS APPROVAL' ||
                                                                                                                                                                    inputField.strTaskName.toUpperCase() === 'APPEARANCE AFTER WASH' || inputField.strTaskName.toUpperCase() === 'SMS FABRIC IN-HOUSE'
                                                                                                                                                                    || inputField.strTaskName.toUpperCase() === 'SMS FABRIC ORDER' || inputField.strTaskName.toUpperCase() === 'SMS PRODUCTION FOLLOW'
                                                                                                                                                                    || inputField.DetailTypeID === 4
                                                                                                                                                                    || inputField.strTaskName.toLowerCase() === 'Style Catalog Received'
                                                                                                                                                                    ?
                                                                                                                                                                    <span> {inputField.strTaskName} </span>

                                                                                                                                                                    :
                                                                                                                                                                    (subTask.ColorOrName === "" || subTask.ColorOrName === null) ?
                                                                                                                                                                        <>
                                                                                                                                                                            <div className="trwidth_manual" style={{ textAlign: "center", display: "contents" }}>
                                                                                                                                                                                <img className="width_manual"
                                                                                                                                                                                    src={subTask.ImagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.ImageUrl + subTask.ImagePath}
                                                                                                                                                                                    alt="Logo"
                                                                                                                                                                                    onClick={
                                                                                                                                                                                        () => setIsOpen(subTask.ImagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.ImageUrl + subTask.ImagePath)
                                                                                                                                                                                    }
                                                                                                                                                                                />
                                                                                                                                                                            </div>
                                                                                                                                                                            <span> {subTask.Code} </span>
                                                                                                                                                                        </>
                                                                                                                                                                        :
                                                                                                                                                                        <>
                                                                                                                                                                            <div style={{ background: subTask.ColorOrName, width: '120px', height: '25px', borderRadius: '2px', display: 'inline-block' }}>
                                                                                                                                                                            </div>
                                                                                                                                                                            <span style={{ position: "absolute", marginTop: "3px", marginLeft: '5px' }} > {subTask.Code} </span>
                                                                                                                                                                        </>

                                                                                                                                                                }


                                                                                                                                                            </td>



                                                                                                                                                        </tr>

                                                                                                                                                        {
                                                                                                                                                            subTask.ChildTaskList && subTask.ChildTaskList.map((childTask, childIndex) => (


                                                                                                                                                                <Fragment key={
                                                                                                                                                                    `${childTask}~${childIndex}`}>

                                                                                                                                                                    <tr onClick={() => handleChildRowToggle(index, subIndex, childIndex, childTask.hasAutoOpen)} class={subTask.IsExpanded ? "child_" + index + " parentChild_" + index + "_" + subIndex + " " + (childTask.hasAutoOpen ? "TNAAutoFocusBorder" : "") : "child_" + index + " parentChild_" + index + "_" + subIndex + " " + (subTask.IsExpanded ? "" : "hidden")} >

                                                                                                                                                                        <td style={{ width: "50px" }} className="fixed-column-first-tna"><b>{(index + 1)}</b>  {"-" + childTask.ChildIndex}</td>

                                                                                                                                                                        <td style={{ width: "300px" }}>
                                                                                                                                                                            {
                                                                                                                                                                                inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                                                                                                    inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                                                                                                                                                                    inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER ||
                                                                                                                                                                                    inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT ||
                                                                                                                                                                                    inputField.strTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER ||
                                                                                                                                                                                    inputField.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                                                                                                    inputField.strTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || inputField.strTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL
                                                                                                                                                                                    ?
                                                                                                                                                                                    <>

                                                                                                                                                                                        <i class="fa fa-eye" title="SKU & Other Info" onClick={() => handleSkuAndStyleDetails(childTask.SubId, childTask.MainId, childTask.StrikeOffOrTrimInfoId, inputField.strTaskName, childTask.SKUIdList, childTask.StyleStrikeOffGarmentSizeIdList, childTask)}></i>
                                                                                                                                                                                        <span>{" " + childTask.Name}</span>
                                                                                                                                                                                    </>

                                                                                                                                                                                    : childTask.Name
                                                                                                                                                                            }
                                                                                                                                                                        </td>

                                                                                                                                                                        <td style={{ width: "150px" }}>
                                                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                                                <Reactselect className="basic-single" name="DependencyID"
                                                                                                                                                                                    id={
                                                                                                                                                                                        "DependencyID" + index
                                                                                                                                                                                    }
                                                                                                                                                                                    isLoading={false}
                                                                                                                                                                                    isClearable={true}
                                                                                                                                                                                    isSearchable={true}
                                                                                                                                                                                    onChange={event => handleChildDepandencyChange(event, index, subIndex, childIndex, "DependencyID", childTask.ChildIndex, childTask.ChildId, childTask.RownoforAllTasks, childTask.ChildIndex)}
                                                                                                                                                                                    value={getDependencyList.filter(function (option) {
                                                                                                                                                                                        return option.value === childTask.DependencyID;
                                                                                                                                                                                    })}
                                                                                                                                                                                    options={getDependencyList}
                                                                                                                                                                                    // isDisabled={ChildDepPopupMode === "View" ? true  :
                                                                                                                                                                                    //     index > DepandencyAllIndex.Index ? true
                                                                                                                                                                                    //         : false}
                                                                                                                                                                                    isDisabled={ChildDepPopupMode === "View" ? true : DepandencyAllIndex.RowIndex <= childTask.ChildIndex ? true :
                                                                                                                                                                                        index > DepandencyAllIndex.Index ? true
                                                                                                                                                                                            : false}

                                                                                                                                                                                ></Reactselect>



                                                                                                                                                                            </span>

                                                                                                                                                                        </td>

                                                                                                                                                                        <td style={{ width: "80px" }}>
                                                                                                                                                                            <span className='input-icon icon-right'>
                                                                                                                                                                                <input type="text"
                                                                                                                                                                                    placeholder="    Log days"
                                                                                                                                                                                    id={"LogDays" + index}
                                                                                                                                                                                    name="LogDays"
                                                                                                                                                                                    value={childTask.LogDays}
                                                                                                                                                                                    //value={inputField.LogDays}
                                                                                                                                                                                    onChange={event => handleChildDepandencyChange(event, index, subIndex, childIndex, "LogDays", childTask.ChildIndex, childTask.ChildId, childTask.RownoforAllTasks)}
                                                                                                                                                                                    maxLength="3"
                                                                                                                                                                                    className={'form-control' + (
                                                                                                                                                                                        submitted && !childTask.LogDays ? ' is-invalid' : ''
                                                                                                                                                                                    )}
                                                                                                                                                                                    disabled={ChildDepPopupMode === "View" ? true : childTask.DependencyID === 0 ? true : false}
                                                                                                                                                                                />
                                                                                                                                                                            </span>
                                                                                                                                                                        </td>


                                                                                                                                                                    </tr>
                                                                                                                                                                </Fragment>
                                                                                                                                                            ))
                                                                                                                                                        }
                                                                                                                                                    </Fragment>

                                                                                                                                                ))

                                                                                                                                            }

                                                                                                                                        </Fragment>
                                                                                                                                    ))
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    inputFields.length === 0 ?
                                                                                                                                        <tr>
                                                                                                                                            <td colSpan="13" className='norecordfound'></td>
                                                                                                                                            {
                                                                                                                                                inputFields.length === 0 ?
                                                                                                                                                    <tr>
                                                                                                                                                        <td colSpan="10" className='norecordfound'><span>No Records Found</span></td>

                                                                                                                                                    </tr> : ''
                                                                                                                                            }
                                                                                                                                        </tr> : ''
                                                                                                                                }
                                                                                                                            </tbody>
                                                                                                                        </table>

                                                                                                                    </Modal.Body>



                                                                                                                    <Modal.Footer>
                                                                                                                        {

                                                                                                                            ChildDepPopupMode !== "View" &&
                                                                                                                            <>
                                                                                                                                <Button variant="success" onClick={() => SaveChildDepandency(index, subIndex, childIndex, childTask.ChildId, childTask.TaskName, inputField.DetailTypeID)}>
                                                                                                                                    Save
                                                                                                                                </Button>
                                                                                                                                {/* <Button variant="success"
                                                                                                                                    onClick={() => SaveNxtChildDepandency(index, subIndex, childIndex, childTask.ChildId, childTask.ChildIndex, childTask.TaskName, inputField.DetailTypeID)}>
                                                                                                                                    Save & Next
                                                                                                                                </Button> */}
                                                                                                                            </>
                                                                                                                        }

                                                                                                                    </Modal.Footer>
                                                                                                                </Modal>
                                                                                                            }

                                                                                                            {/* Child Task dep button*/}
                                                                                                            &nbsp;
                                                                                                            {/* Start Child task Dep startStop */}
                                                                                                            {
                                                                                                                StartStopInfo && StartStopInfo.isAdd !== 0 && childTask.IsShowSubmitButton !== 0 &&
                                                                                                                    childTask.StatusID !== 3 && childTask.IsDepandency === 1 ?

                                                                                                                    <button type="button" className={childTask.intDependency === 1 ? "btn btn-xs btn-success mb-2" : "btn btn-xs btn-danger mb-2"} title="Start and Stop(Dependency)"
                                                                                                                        onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, childTask.intDependency, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                    >
                                                                                                                        <i className={childTask.intDependency === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                    </button> :
                                                                                                                    childTask.StatusID !== 3 && childTask.IsDepandency === 0 && childTask.SelectedDependency !== '' ?
                                                                                                                        <button type="button" className={childTask.intDependency === 1 ? "btn btn-xs btn-success mb-2" : "btn btn-xs btn-danger mb-2"} title="Start and Stop(Dependency)"
                                                                                                                            onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, childTask.intDependency, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                        >
                                                                                                                            <i className={childTask.intDependency === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                        </button>
                                                                                                                        : ''


                                                                                                            }
                                                                                                            {/*End Child task Dep startStop */}


                                                                                                            {/* Child Task dep button End*/}

                                                                                                            {/* {StartStopInfo && StartStopInfo.isAdd !== 0 && inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && (!childTask.ManualCloseComment) &&
                                                                                                                childTask.IsShowSubmitButton !== 0 && childTask.StatusID !== 3 &&

                                                                                                                <button type="button" className={childTask.intDependency === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                    onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, childTask.intDependency, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                >
                                                                                                                    <i className={childTask.intDependency === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                </button>
                                                                                                            } */}
                                                                                                            &nbsp;

                                                                                                            {/* Child Task button */}

                                                                                                            {

                                                                                                                TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&

                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning mb-2" title="Submit Form"
                                                                                                                        onClick={() => OpenLabdipSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId,
                                                                                                                            props.location.state.params.styleId, index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency
                                                                                                                            , childTask.intDependency
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.StatusID === 3 && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing')
                                                                                                                        && childTask.ManualCloseComment === '' ?

                                                                                                                        <button type="button" className="btn btn-xs btn-success  mb-2" title='View Submission'
                                                                                                                            // style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }}
                                                                                                                            onClick={() => OpenLabdipSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId,
                                                                                                                                props.location.state.params.styleId, index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus

                                                                                                                            )}
                                                                                                                        //  disabled
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye "></i>
                                                                                                                        </button> :
                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                                <i className="fa fa-check"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }
                                                                                                            {
                                                                                                                TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?

                                                                                                                    <button type="button" className="btn btn-xs btn-warning mb-2" title="Approve Form"
                                                                                                                        onClick={() => OpenLabdipApprovalpopup(childTask.ChildId, props.location.state.params.styleId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency, childTask.intDependency)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-check"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0
                                                                                                                        && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                            onClick={() => OpenLabdipApprovalpopup(childTask.ChildId, props.location.state.params.styleId
                                                                                                                                , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye "></i>
                                                                                                                        </button> :
                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                                <i className="fa fa-check"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }
                                                                                                            {
                                                                                                                TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                        onClick={() => OpenTrimSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency, childTask.intDependency)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.StatusID === 3
                                                                                                                        && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title="View Submission"
                                                                                                                            onClick={() => OpenTrimSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId
                                                                                                                                , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye "></i>
                                                                                                                        </button>
                                                                                                                        :
                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                                <i className="fa fa-check"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }
                                                                                                            {
                                                                                                                TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning mb-2" title="Approve Form"
                                                                                                                        onClick={() => OpenTrimApprovalpopup(childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency, childTask.intDependency)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-check"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.StatusID === 3
                                                                                                                        && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                            onClick={() => OpenTrimApprovalpopup(childTask.ChildId
                                                                                                                                , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye "></i>
                                                                                                                        </button> :
                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                                <i className="fa fa-check"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }

                                                                                                            {
                                                                                                                TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                        onClick={() => OpenStrikeoffSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency, childTask.intDependency)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.StatusID === 3
                                                                                                                        && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                                                            onClick={() => OpenStrikeoffSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId
                                                                                                                                , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye "></i>
                                                                                                                        </button> :
                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                                <i className="fa fa-check"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }
                                                                                                            {
                                                                                                                TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning mb-2" title="Approve Form"
                                                                                                                        onClick={() => OpenStrikeoffApprovalpopup(childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency, childTask.intDependency)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-check"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.StatusID === 3
                                                                                                                        && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                            onClick={() => OpenStrikeoffApprovalpopup(childTask.ChildId
                                                                                                                                , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye "></i>
                                                                                                                        </button> :
                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                                <i className="fa fa-check"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }


                                                                                                            {
                                                                                                                TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                    (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION
                                                                                                                        // || childTask.TaskName.toLowerCase() === StaticTask.STYLE_CATALOG_RECEIVED_SKU
                                                                                                                    ) && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                        onClick={() => OpenProtoFitSamplepopup(props.location.state.params.tnaid, childTask.ChildId, index,
                                                                                                                            subIndex, childIndex, childTask.Name, childTask.TaskName, props.location.state.params.styleId, childTask.StatusID, childTask.arrDependency, childTask.intDependency, getTaskholder)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION) && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                                                            onClick={() => OpenProtoFitSamplepopup(props.location.state.params.tnaid, childTask.ChildId, index,
                                                                                                                                subIndex, childIndex, childTask.Name, childTask.TaskName, props.location.state.params.styleId, childTask.StatusID, childTask, childTask.intDependency, getTaskholder)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye "></i>
                                                                                                                        </button>

                                                                                                                        :
                                                                                                                        (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION
                                                                                                                            || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION
                                                                                                                            || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION
                                                                                                                            || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION) && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Approved' disabled>
                                                                                                                                <i className="fa fa-check"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }



                                                                                                            {
                                                                                                                TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                    (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Approval Form"
                                                                                                                        onClick={() => OpenProtoFitApprovalpopup(childTask.ChildId, index, subIndex, childIndex, childTask.TaskName, childTask.StatusID,
                                                                                                                            childTask.TaskName === 'Proto Sample Approval' ? 7 : childTask.TaskName === 'Fit Sample Approval' ? 8 : 9, childTask.arrDependency, childTask.intDependency)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-check"></i>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                                                                                                            onClick={() => OpenProtoFitApprovalpopup(childTask.ChildId, index, subIndex, childIndex, childTask.TaskName, childTask.StatusID,
                                                                                                                                childTask.TaskName === 'Proto Sample Approval' ? 7 : childTask.TaskName === 'Fit Sample Approval' ? 8 : 9)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye "></i>
                                                                                                                        </button>

                                                                                                                        :
                                                                                                                        (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                                                                                                                            || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                                                                                                                            || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                                                                                                                            || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                                <i className="fa fa-check"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }
                                                                                                            {
                                                                                                                TaskCloseCompleteInfo !== undefined && TaskCloseCompleteInfo.isAdd !== 0 &&
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                        onClick={() => OpenAppearenceAfterWashpopup(props.location.state.params.tnaid, childTask.ChildId, props.location.state.params.styleId,
                                                                                                                            index, subIndex, childIndex, childTask.StatusID, childTask.arrDependency, childTask.intDependency)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 3 && childTask.StatusID !== 3 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-warning" title="Approval Form"
                                                                                                                            onClick={() => OpenAppearenceAfterWashpopup(props.location.state.params.tnaid, childTask.ChildId, props.location.state.params.styleId,
                                                                                                                                index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        :
                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                                                                onClick={() => OpenAppearenceAfterWashpopup(props.location.state.params.tnaid, childTask.ChildId, props.location.state.params.styleId,
                                                                                                                                    index, subIndex, childIndex, childTask.StatusID)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                            </button>

                                                                                                                            // :
                                                                                                                            // childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            //     <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                            //         <i className="fa fa-check-square-o"></i>
                                                                                                                            //     </button>
                                                                                                                            : ''

                                                                                                            }
                                                                                                            &nbsp;
                                                                                                            {
                                                                                                                // new Date() > new Date(childTask.EndDate) &&
                                                                                                                childTask.StatusID !== 3 && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') &&
                                                                                                                (childTask.TaskName !== 'Strike off Order to Nominated Supplier' && childTask.TaskName !== 'Strike off Receipt From Nominated Supplier' &&
                                                                                                                    childTask.TaskName !== 'Strike off Receipt from Buyer' && childTask.TaskName !== 'Trim Receipt From Buyer' &&
                                                                                                                    childTask.TaskName !== 'Trim Order to Nominated Supplier' && childTask.TaskName !== 'Trim Receipt from Nominated Supplier') && //childTask.ManualCloseComment === '' &&
                                                                                                                <button type="button" className={childTask.IsReviced === 0 ? "btn btn-xs btn-yellow mb-2" : "btn btn-xs btn-success mb-2"} title='Child Revise'
                                                                                                                    onClick={() => OpenRevicePopup(index, subIndex, childIndex, props.location.state.params.tnaid,
                                                                                                                        childTask.ChildId, childTask.TaskName, childTask.StartDate, childTask.EndDate, childTask.Duration, 'Sub', childTask.EndDateChange)}
                                                                                                                >
                                                                                                                    <i className="fa fa-retweet"></i>
                                                                                                                </button>
                                                                                                            }
                                                                                                            &nbsp;
                                                                                                            {
                                                                                                                // new Date() > new Date(childTask.EndDate) &&
                                                                                                                (childTask.StatusID === 3 || childTask.IsRescheduled === 1) && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') &&
                                                                                                                (childTask.TaskName !== 'Strike off Order to Nominated Supplier' && childTask.TaskName !== 'Strike off Receipt From Nominated Supplier' &&
                                                                                                                    childTask.TaskName !== 'Strike off Receipt from Buyer' && childTask.TaskName !== 'Trim Receipt From Buyer' &&
                                                                                                                    childTask.TaskName !== 'Trim Order to Nominated Supplier' && childTask.TaskName !== 'Trim Receipt from Nominated Supplier') && //childTask.ManualCloseComment === '' &&
                                                                                                                <button type="button" className={childTask.IsRescheduled === 0 ? "btn btn-xs btn-light mb-2" : "btn btn-xs btn-success mb-2"} title='Child Reschedule '
                                                                                                                    onClick={() => OpenReschedulePopup(index, subIndex, childIndex, props.location.state.params.tnaid,
                                                                                                                        childTask.ChildId, childTask.TaskName, childTask.StartDate, childTask.EndDate, childTask.Duration, 'Sub', childTask.EndDateChange, childTask.childTask)}
                                                                                                                >
                                                                                                                    <i class="fa fa-calendar"></i>
                                                                                                                </button>
                                                                                                            }



                                                                                                        </>


                                                                                                        : ''

                                                                                                }




                                                                                                <Fragment>
                                                                                                    &nbsp;
                                                                                                    <button type="button" className="btn btn-xs btn-success mb-2" title="Add Task"
                                                                                                        style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                                                                                        onClick={() => handleChildAddFieldsClick(index, subIndex, childIndex, childTask.TaskName, childTask.MainId)}>
                                                                                                        <i className="fa fa-plus"></i>
                                                                                                    </button>
                                                                                                    &nbsp;
                                                                                                    <button type="button" className="btn btn-xs btn-danger mb-2" title="Delete Task"
                                                                                                        style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }}
                                                                                                        disabled={childTask.StatusID === 3 ? true : false}
                                                                                                        onClick={() => handleChildRowRemoveFields(index, subIndex, childIndex, inputField.strTaskName, childTask.MainId, childTask.SubId, childTask.ChildIndex, childTask.RownoforAllTasks)}>
                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                    </button>
                                                                                                    &nbsp;


                                                                                                </Fragment>


                                                                                                {
                                                                                                    childTask.IsDependencyStartPopup && !inputField.IsSingleTask &&
                                                                                                    <Modal show={childTask.IsDependencyStartPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseDependencyStartPopup(index, subIndex, childIndex, inputField.IsSingleTask)}>
                                                                                                        <Modal.Header closeButton>
                                                                                                            <Modal.Title>
                                                                                                                Dependency Stop / Start History

                                                                                                            </Modal.Title>
                                                                                                        </Modal.Header>
                                                                                                        <Modal.Body>

                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Dependencytable">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th>Date<span className="text-danger">*</span></th>
                                                                                                                        <th>Comments<span className="text-danger">*</span></th>

                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>

                                                                                                                    <tr>
                                                                                                                        <td >
                                                                                                                            {
                                                                                                                                <DatePicker className="form-control" name="DependencyDate"


                                                                                                                                    // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                                                                                                    selected={Date.parse(moment(childTask.DependencyDate, 'MM/DD/YYYY').toISOString())}
                                                                                                                                    onChange={event => handleChangeDependentStart(index, subIndex, childIndex, event, "CommentDate", false)}
                                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                                    peekNextMonth
                                                                                                                                    showMonthDropdown
                                                                                                                                    dropdownMode="scroll"
                                                                                                                                    autoComplete="off"
                                                                                                                                    minDate={new Date()}
                                                                                                                                    readOnly={childTask.intDependency === 1 ? true : false}
                                                                                                                                    customInput={<CustomInputDependency />}

                                                                                                                                    maxDate={new Date()}

                                                                                                                                />


                                                                                                                            }

                                                                                                                        </td>
                                                                                                                        <td style={{ width: "600px" }} >
                                                                                                                            <textarea
                                                                                                                                rows="3"
                                                                                                                                placeholder="Enter Comments here..."
                                                                                                                                autoFocus onFocus={""} autoComplete="off"
                                                                                                                                maxLength="500" name="EnterRemarks"
                                                                                                                                value={childTask.DependencyComment}
                                                                                                                                onChange={event => handleChangeDependentStart(index, subIndex, childIndex, event, "Comment", false)}
                                                                                                                                style={{ width: "100%", border: submitDependencyStart && !childTask.DependencyComment ? '1px solid red' : '' }}>
                                                                                                                            </textarea>
                                                                                                                        </td>

                                                                                                                    </tr>
                                                                                                                </tbody>

                                                                                                            </table>
                                                                                                            {
                                                                                                                <table className="table table-bordered">
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th>Action</th>
                                                                                                                            <th>Date</th>
                                                                                                                            <th>Comments</th>
                                                                                                                            <th>Created By</th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            getDependencyStartList && getDependencyStartList.length !== 0 ?
                                                                                                                                getDependencyStartList.map((item, itemIndex) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <tr>
                                                                                                                                                <td>
                                                                                                                                                    {item.dependencyStartstop === 1 ? "Stop" : "Start"}

                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    {item.dependencyDate}

                                                                                                                                                </td>

                                                                                                                                                <td>
                                                                                                                                                    {item.comments}

                                                                                                                                                </td>
                                                                                                                                                <td>
                                                                                                                                                    {item.createdBy}

                                                                                                                                                </td>


                                                                                                                                            </tr>
                                                                                                                                        </>

                                                                                                                                    )
                                                                                                                                })
                                                                                                                                :
                                                                                                                                <tr><td colSpan="4" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                                        }

                                                                                                                    </tbody>
                                                                                                                </table>


                                                                                                            }

                                                                                                        </Modal.Body>
                                                                                                        <Modal.Footer>
                                                                                                            <Button variant="success" onClick={() => handleSaveDependencyStart(index, subIndex, childIndex, inputField.IsSingleTask, childTask.intDependency, inputField.strTaskName, inputField, childTask)}>

                                                                                                                Save
                                                                                                            </Button>
                                                                                                        </Modal.Footer>
                                                                                                    </Modal>

                                                                                                }

                                                                                                {
                                                                                                    childTask.hasAllDependencyStartPopup && !inputField.IsSingleTask &&
                                                                                                    <Modal show={childTask.hasAllDependencyStartPopup} size="lg" dragable backdrop="static" keyboard={false}
                                                                                                        onHide={() => CloseAllDependencyStartPopup(index, subIndex, childIndex, inputField.IsSingleTask, childTask.MultiDependencyComment, childTask.MultiDependencyDate)}>
                                                                                                        <Modal.Header closeButton>
                                                                                                            <Modal.Title>
                                                                                                                Multi Dependency
                                                                                                            </Modal.Title>
                                                                                                        </Modal.Header>
                                                                                                        <Modal.Body>

                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Dependencytable">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th>Date<span className="text-danger">*</span></th>
                                                                                                                        <th>Comments<span className="text-danger">*</span></th>

                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>

                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            {
                                                                                                                                <DatePicker className="form-control" name="DependencyDate"


                                                                                                                                    // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                                                                                                    selected={Date.parse(moment(childTask.MultiDependencyDate, 'MM/DD/YYYY').toISOString())}
                                                                                                                                    onChange={event => handleChangeMultiDependentStart(index, subIndex, childIndex, event, "CommentDate", false)}
                                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                                    peekNextMonth
                                                                                                                                    showMonthDropdown
                                                                                                                                    dropdownMode="scroll"
                                                                                                                                    autoComplete="off"
                                                                                                                                    minDate={new Date()}
                                                                                                                                    //  maxDate={new Date()}
                                                                                                                                    customInput={<CustomInputDependency />}

                                                                                                                                />


                                                                                                                            }

                                                                                                                        </td>
                                                                                                                        <td style={{ width: "600px" }} >
                                                                                                                            <textarea
                                                                                                                                rows="3"
                                                                                                                                placeholder="Enter Comments here..."
                                                                                                                                autoFocus onFocus={""} autoComplete="off"
                                                                                                                                maxLength="500" name="EnterRemarks"
                                                                                                                                value={childTask.MultiDependencyComment}
                                                                                                                                onChange={event => handleChangeMultiDependentStart(index, subIndex, childIndex, event, "Comment", false)}
                                                                                                                                style={{ width: "100%", border: submitDependencyStart && !childTask.MultiDependencyComment ? '1px solid red' : '' }}>
                                                                                                                            </textarea>
                                                                                                                        </td>

                                                                                                                    </tr>
                                                                                                                </tbody>

                                                                                                            </table>
                                                                                                        </Modal.Body>
                                                                                                        <Modal.Footer>
                                                                                                            <Button variant="success" onClick={() => handleSaveAllDependencyStart(index, subIndex, childIndex, inputField.TaskID, inputField.IsSingleTask)}>

                                                                                                                Save
                                                                                                            </Button>
                                                                                                        </Modal.Footer>
                                                                                                    </Modal>

                                                                                                }


                                                                                            </td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </Fragment>

                                                                    ))

                                                                }

                                                            </Fragment>
                                                        ))
                                                    }
                                                    {
                                                        inputFields.length === 0 ?
                                                            <tr>
                                                                <td colSpan="12" className='norecordfound'></td>
                                                                {
                                                                    inputFields.length === 0 ?
                                                                        <tr>
                                                                            <td colSpan="10" className='norecordfound'><span>No Records Found</span></td>

                                                                        </tr> : ''
                                                                }
                                                            </tr> : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>

                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>

                                            <span className="btn btn-primary"
                                                onClick={
                                                    () => ResetOperation("Back")
                                                }
                                            >
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back</span>

                                            <span className="btn btn-danger"
                                                onClick={
                                                    () => ResetOperation()
                                                } >
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset</span>


                                            <button type="button" className="btn btn-success"
                                                onClick={UpdateTNA}
                                            >
                                                <i className="fa fa-check right"></i>
                                                &nbsp;{isLastEdit ? 'Update' : 'Save'}</button>

                                        </div>
                                    </div>
                                </div>

                                {getLapmodal === true ? (
                                    <Modal
                                        show={getLapmodal === true}
                                        size="xl"
                                        dragable
                                        backdrop="static"
                                        dialogClassName="commonwidth"
                                        keyboard={false}
                                        onHide={() => handleLabDip()}
                                    >
                                        <Modal.Header closeButton>

                                            <Modal.Title >
                                                {
                                                    TaskStatus === 3 && editLabdipSubmission === false ?
                                                        "View " : (editLabdipSubmission === true && getexistsubmission === false) ? 'Update ' : ''
                                                }
                                                Lab Dip Submission





                                            </Modal.Title>
                                            <div className="button-top-alignment">
                                                <div>

                                                    <span>
                                                        {
                                                            TaskStatus === 3 ?
                                                                <>
                                                                    {
                                                                        (SwatchStatus === 0 || SwatchStatus === 1) ?
                                                                            <span title='Download Swatch Card' onClick={() => setLabdipswatchDownload(true)}>
                                                                                <i style={{ fontSize: "22px" }} class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i>
                                                                            </span>
                                                                            : ''

                                                                    }
                                                                    &nbsp;

                                                                    {
                                                                        SwatchStatus === 1 ?
                                                                            <span title='Upload Submitted Swatch Card'>
                                                                                <i style={{ fontSize: "22px" }} class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setLabdipswatchUpload(true)}></i>
                                                                            </span>
                                                                            : ''

                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                        SwatchStatus === 2 ?
                                                                            <span title='Edit Form' onClick={() => seteditLabdipSubmission(true)}
                                                                                style={{ pointerEvents: Labdipdisable === true ? "none" : '', opacity: Labdipdisable === true ? '0.6' : '', 'margin-left': '680px' }}
                                                                                //disabled={Labdipdisable === true ? true : false}
                                                                                className="btn btn-info btn-xs edit">
                                                                                <i className="fa fa-edit"></i>
                                                                            </span> :
                                                                            <span title='Edit Form' onClick={() => seteditLabdipSubmission(true)}
                                                                                style={{ pointerEvents: Labdipdisable === true ? "none" : '', opacity: Labdipdisable === true ? '0.6' : '' }}
                                                                                className="btn btn-info btn-xs edit">
                                                                                <i className="fa fa-edit"></i>
                                                                            </span>
                                                                    }

                                                                    &nbsp;
                                                                    <span title='Delete Form'
                                                                        style={{ pointerEvents: Labdipdisable === true ? "none" : '', opacity: Labdipdisable === true ? '0.6' : '' }}
                                                                        className="btn btn-danger btn-xs delete" onClick={() => setdeleteLabdip(true)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </span>
                                                                </> : ''
                                                        }
                                                    </span>
                                                </div>
                                            </div>





                                        </Modal.Header>
                                        <Modal.Body className="commonpopupmodal">
                                            <LabdibSubmission props={props}
                                                TNAId={TNAId}
                                                StyleId={StyleId}
                                                LabdibFabricInfoId={LabdibFabricInfoId}
                                                LapdipSubmissionCallback={LapdipSubmissionCallback}
                                                TaskStatus={TaskStatus}
                                                commonParams={commonSubmitApprovalParams}
                                                Indexes={Indexes}
                                                values={values}
                                                editLabdipSubmission={editLabdipSubmission}
                                                deleteLabdipSubmission={deleteLabdipSubmission}
                                                LabdipswatchDownload={LabdipswatchDownload}
                                                LabdipswatchUpload={LabdipswatchUpload}
                                                SwatchStatus={SwatchStatus}
                                                AllChildvalues={AllChildvalues}
                                            > </LabdibSubmission>
                                        </Modal.Body>
                                        <Modal.Footer></Modal.Footer>
                                    </Modal>
                                ) : (
                                    ""
                                )}
                                {getOpenLabdipApppopup === true ? (
                                    <Modal
                                        show={getOpenLabdipApppopup === true}
                                        size="xl"
                                        dragable
                                        backdrop="static"
                                        dialogClassName="commonwidth"
                                        keyboard={false}
                                        onHide={() => handleLabDipApp()}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {
                                                    TaskStatus === 3 && editLabdipApproval === false ?
                                                        "View " : editLabdipApproval === true ? 'Update ' : ''

                                                }
                                                Lab Dip  Approval




                                            </Modal.Title>
                                            <div className="button-top-alignment">
                                                <div>
                                                    <span>
                                                        {

                                                            TaskStatus === 3 ?
                                                                <>


                                                                    {
                                                                        SwatchStatus !== 3 ?
                                                                            <span title='Upload Approved Swatch Card'>
                                                                                <i style={{ fontSize: "22px" }} class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setLabdipswatchUpload(true)}></i>
                                                                            </span>
                                                                            : ''

                                                                    }
                                                                    {/* &nbsp;
                                                            {
                                                                SwatchStatus === 3 ?
                                                                    <span title='Download Approved Swatch Card' style={{ 'margin-left': '670px' }}>
                                                                        <i class="fa fa-lg fa-download" aria-hidden="true" onClick={() => setLabdipswatchDownload(true)}></i>
                                                                    </span>
                                                                    : ''

                                                            } */}
                                                                    &nbsp;
                                                                    {
                                                                        SwatchStatus === 3 ?
                                                                            <span title='Edit Form' onClick={() => seteditLabdipApproval(true)}
                                                                                className="btn btn-info btn-xs edit">
                                                                                <i className="fa fa-edit"></i>
                                                                            </span> :
                                                                            <span title='Edit Form' onClick={() => seteditLabdipApproval(true)}
                                                                                className="btn btn-info btn-xs edit">
                                                                                <i className="fa fa-edit"></i>
                                                                            </span>

                                                                    }
                                                                    {/* <span title='Edit Form' onClick={() => seteditLabdipApproval(true)}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span> */}

                                                                    &nbsp;
                                                                    <span title='Delete Form'
                                                                        className="btn btn-danger btn-xs delete" onClick={() => setdeleteLabdipApproval(true)}>
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </span>
                                                                </> : ''
                                                        }
                                                    </span>
                                                </div>

                                            </div>
                                        </Modal.Header>
                                        <Modal.Body className="commonpopupmodal">
                                            <LabdibApproval props={props}
                                                LapdipApprovalCallback={LapdipApprovalCallback}
                                                StyleId={StyleId}
                                                LabdibFabricInfoId={LabdibFabricInfoId}
                                                SubmittedOptionListId={SubmittedOptionListId}
                                                TaskStatus={TaskStatus}
                                                LabdipApprovalList={LabdipApprovalList}
                                                editLabdipApproval={editLabdipApproval}
                                                deleteLabdipApproval={deleteLabdipApproval}
                                                LabdipswatchDownload={LabdipswatchDownload}
                                                LabdipswatchUpload={LabdipswatchUpload}
                                                SwatchStatus={SwatchStatus}
                                                SubmitChildId={SubmitChildId}

                                            ></LabdibApproval>
                                        </Modal.Body>
                                        <Modal.Footer></Modal.Footer>
                                    </Modal>
                                ) : (
                                    ""
                                )}
                                {
                                    strikeoffModalpopup === true ?

                                        <Modal show={strikeoffModalpopup === true}
                                            size="xl" dragable backdrop="static"
                                            keyboard={false}
                                            dialogClassName="commonwidth"
                                            onHide={() => handleStrikeoffSub()}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {
                                                        TaskStatus === 3 && editStrikeOffSubmission === false ?
                                                            "View " : (editStrikeOffSubmission === true && getexistsubmission === false) ? 'Update ' : ''
                                                    }

                                                    Strike Off Submission

                                                </Modal.Title>
                                                <div className="button-top-alignment">
                                                    {
                                                        TaskStatus === 3 ?
                                                            <>

                                                                {
                                                                    (SwatchStatus === 0 || SwatchStatus === 1) ?
                                                                        <span title='Download Swatch Card' onClick={() => setStrikeoffswatchDownload(true)}>
                                                                            <i style={{ fontSize: "22px" }} class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i>
                                                                        </span>
                                                                        : ''

                                                                }
                                                                &nbsp;

                                                                {
                                                                    SwatchStatus === 1 ?
                                                                        <span title='Upload Submitted Swatch Card'>
                                                                            <i style={{ fontSize: "22px" }} class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setStrikeoffswatchUpload(true)}></i>
                                                                        </span>
                                                                        : ''

                                                                }
                                                                &nbsp;
                                                                {
                                                                    SwatchStatus === 2 ?
                                                                        <span title='Edit Form' onClick={() => seteditStrikeOffSubmission(true)}
                                                                            style={{ pointerEvents: Strikeoffdisable === true ? "none" : '', opacity: Strikeoffdisable === true ? '0.6' : '', 'margin-left': '650px' }}
                                                                            className="btn btn-info btn-xs edit">
                                                                            <i className="fa fa-edit"></i>
                                                                        </span> :
                                                                        <span title='Edit Form' onClick={() => seteditStrikeOffSubmission(true)}
                                                                            style={{ pointerEvents: Strikeoffdisable === true ? "none" : '', opacity: Strikeoffdisable === true ? '0.6' : '' }}
                                                                            className="btn btn-info btn-xs edit">
                                                                            <i className="fa fa-edit"></i>
                                                                        </span>
                                                                }

                                                                &nbsp;
                                                                <span title='Delete Form'
                                                                    style={{ pointerEvents: Strikeoffdisable === true ? "none" : '', opacity: Strikeoffdisable === true ? '0.6' : '' }}
                                                                    className="btn btn-danger btn-xs delete" onClick={() => setdeleteStrikeOffSubmission(true)}>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </> : ''
                                                    }
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body className="commonpopupmodal">
                                                <StrikeOffSubmission props={props}
                                                    StrikeoffSubmissionCallback={StrikeoffSubmissionCallback}
                                                    TNAId={TNAId}
                                                    TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                                    TaskStatus={TaskStatus}
                                                    commonParams={commonSubmitApprovalParams}
                                                    Indexes={Indexes}
                                                    values={values}
                                                    editStrikeOffSubmission={editStrikeOffSubmission}
                                                    deleteStrikeOffSubmission={delteStrikeOffSubmission}
                                                    StrikeoffswatchDownload={StrikeoffswatchDownload}
                                                    StrikeoffswatchUpload={StrikeoffswatchUpload}
                                                    SwatchStatus={SwatchStatus}
                                                    AllChildvalues={AllChildvalues}
                                                ></StrikeOffSubmission>
                                            </Modal.Body>
                                            <Modal.Footer>

                                            </Modal.Footer>

                                        </Modal> : ''
                                }
                                {
                                    getstrikeoffApppopup === true ?

                                        <Modal dialogClassName="commonwidth" show={getstrikeoffApppopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleStrikeOffApp(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {
                                                        TaskStatus === 3 && editStrikeOffApproval === false ?
                                                            "View " : editStrikeOffApproval === true ? 'Update ' : ''

                                                    }
                                                    Strike Off Approval

                                                </Modal.Title>
                                                <div className="button-top-alignment">
                                                    {
                                                        TaskStatus === 3 ?
                                                            <>
                                                                {
                                                                    SwatchStatus !== 3 ?
                                                                        <span title='Upload Approved Swatch Card' >
                                                                            <i style={{ fontSize: "22px" }} class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setStrikeoffswatchUpload(true)}></i>
                                                                        </span>
                                                                        : ''

                                                                }
                                                                {/* &nbsp;
                                                            {
                                                                SwatchStatus === 3 ?
                                                                    <span title='Download Approved Swatch Card' style={{ 'margin-left': '670px' }}>
                                                                        <i class="fa fa-lg fa-download" aria-hidden="true" onClick={() => setLabdipswatchDownload(true)}></i>
                                                                    </span>
                                                                    : ''

                                                            } */}
                                                                &nbsp;
                                                                {
                                                                    SwatchStatus === 3 ?
                                                                        <span title='Edit Form' onClick={() => seteditStrikeOffApproval(true)}
                                                                            className="btn btn-info btn-xs edit">
                                                                            <i className="fa fa-edit"></i>
                                                                        </span> :
                                                                        <span title='Edit Form' onClick={() => seteditStrikeOffApproval(true)}
                                                                            className="btn btn-info btn-xs edit">
                                                                            <i className="fa fa-edit"></i>
                                                                        </span>

                                                                }


                                                                &nbsp;
                                                                <span title='Delete Form'
                                                                    className="btn btn-danger btn-xs delete" onClick={() => setdeleteStrikeOffApproval(true)}>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </> : ''
                                                    }
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body className="commonpopupmodal">
                                                <StrikeApproval props={props}
                                                    StrikeoffApprovalCallback={StrikeoffApprovalCallback}
                                                    TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                                    SubmittedOptionListId={SubmittedOptionListId}
                                                    ColorAppCommentsId={ColorAppCommentsId}
                                                    TaskStatus={TaskStatus}
                                                    editStrikeOffApproval={editStrikeOffApproval}
                                                    delteStrikeOffApproval={delteStrikeOffApproval}
                                                    StrikeoffswatchDownload={StrikeoffswatchDownload}
                                                    StrikeoffswatchUpload={StrikeoffswatchUpload}
                                                    SwatchStatus={SwatchStatus}
                                                ></StrikeApproval>
                                            </Modal.Body>
                                            <Modal.Footer>

                                            </Modal.Footer>

                                        </Modal> : ''
                                }

                                {
                                    getTrimmodalpopup === true ?

                                        <Modal dialogClassName="commonwidth" show={getTrimmodalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handletrimmPopupClose()}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {
                                                        TaskStatus === 3 && editTrimSubmission === false ?
                                                            "View " : (editTrimSubmission === true && getexistsubmission === false) ? 'Update ' : ''
                                                    }Trim Submission

                                                </Modal.Title>
                                                <div className="button-top-alignment">
                                                    {
                                                        TaskStatus === 3 ?
                                                            <>

                                                                {
                                                                    (SwatchStatus === 0 || SwatchStatus === 1) ?
                                                                        <span title='Download Swatch Card' onClick={() => setTrimswatchDownload(true)}>
                                                                            <i style={{ fontSize: "22px" }} class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i>
                                                                        </span>
                                                                        : ''

                                                                }
                                                                &nbsp;

                                                                {
                                                                    SwatchStatus === 1 ?
                                                                        <span title='Upload Submitted Swatch Card'>
                                                                            <i style={{ fontSize: "22px" }} class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setTrimswatchUpload(true)}></i>
                                                                        </span>
                                                                        : ''

                                                                }
                                                                &nbsp;
                                                                {
                                                                    SwatchStatus === 2 ?
                                                                        <span title='Edit Form' onClick={() => seteditTrimSubmission(true)}
                                                                            style={{ pointerEvents: Trimdisable === true ? "none" : '', opacity: Trimdisable === true ? '0.6' : '' }}
                                                                            className="btn btn-info btn-xs edit">
                                                                            <i className="fa fa-edit"></i>
                                                                        </span> :
                                                                        <span title='Edit Form' onClick={() => seteditTrimSubmission(true)}
                                                                            style={{ pointerEvents: Trimdisable === true ? "none" : '', opacity: Trimdisable === true ? '0.6' : '' }}
                                                                            className="btn btn-info btn-xs edit">
                                                                            <i className="fa fa-edit"></i>
                                                                        </span>
                                                                }

                                                                &nbsp;


                                                                <span title='Delete Form'
                                                                    style={{ pointerEvents: Trimdisable === true ? "none" : '', opacity: Trimdisable === true ? '0.6' : '' }}
                                                                    className="btn btn-danger btn-xs delete" onClick={() => setdeleteTrimSubmission(true)}>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </> : ''
                                                    }
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body className="commonpopupmodal">

                                                <TrimSubmission props={props}
                                                    TrimSubmissionCallback={TrimSubmissionCallback}
                                                    TNAId={TNAId}
                                                    TNATrimRefInfoId={TNATrimRefInfoId}
                                                    TaskStatus={TaskStatus}
                                                    commonParams={commonSubmitApprovalParams}
                                                    Indexes={Indexes}
                                                    values={values}
                                                    editTrimSubmission={editTrimSubmission}
                                                    delteTrimSubmission={delteTrimSubmission}
                                                    TrimswatchDownload={TrimswatchDownload}
                                                    TrimswatchUpload={TrimswatchUpload}
                                                    SwatchStatus={SwatchStatus}
                                                    AllChildvalues={AllChildvalues}
                                                ></TrimSubmission>


                                            </Modal.Body>
                                            <Modal.Footer>

                                            </Modal.Footer>

                                        </Modal> : ''
                                }
                                {
                                    getTrimApprovalpopup === true ?

                                        <Modal dialogClassName="commonwidth" show={getTrimApprovalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleTrimappPopupClose()}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {
                                                        TaskStatus === 3 && editTrimApproval === false ?
                                                            "View " : editTrimApproval === true ? 'Update ' : ''

                                                    }Trim Approval

                                                </Modal.Title>
                                                <div className="button-top-alignment">
                                                    {
                                                        TaskStatus === 3 ?
                                                            <>
                                                                {
                                                                    SwatchStatus !== 3 ?
                                                                        <span title='Upload Approved Swatch Card'>
                                                                            <i style={{ fontSize: "22px" }} class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setTrimswatchUpload(true)}></i>
                                                                        </span>
                                                                        : ''

                                                                }
                                                                {/* &nbsp;
                                                            {
                                                                SwatchStatus === 3 ?
                                                                    <span title='Download Approved Swatch Card' style={{ 'margin-left': '670px' }}>
                                                                        <i class="fa fa-lg fa-download" aria-hidden="true" onClick={() => setLabdipswatchDownload(true)}></i>
                                                                    </span>
                                                                    : ''

                                                            } */}
                                                                &nbsp;
                                                                {
                                                                    SwatchStatus === 3 ?
                                                                        <span title='Edit Form' onClick={() => seteditTrimApproval(true)}
                                                                            className="btn btn-info btn-xs edit">
                                                                            <i className="fa fa-edit"></i>
                                                                        </span> :
                                                                        <span title='Edit Form' onClick={() => seteditTrimApproval(true)}
                                                                            className="btn btn-info btn-xs edit">
                                                                            <i className="fa fa-edit"></i>
                                                                        </span>

                                                                }


                                                                &nbsp;
                                                                <span title='Delete Form'
                                                                    className="btn btn-danger btn-xs delete" onClick={() => setdeleteTrimApproval(true)}>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </> : ''
                                                    }
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body className="commonpopupmodal">

                                                <TrimApproval props={props}
                                                    TrimApprovalCallback={TrimApprovalCallback}
                                                    TNATrimRefInfoId={TNATrimRefInfoId}
                                                    SubmittedOptionListId={SubmittedOptionListId}
                                                    ColorAppCommentsId={ColorAppCommentsId}
                                                    TaskStatus={TaskStatus}
                                                    editTrimApproval={editTrimApproval}
                                                    deleteTrimApproval={deleteTrimApproval}
                                                    TrimswatchDownload={TrimswatchDownload}
                                                    TrimswatchUpload={TrimswatchUpload}
                                                    SwatchStatus={SwatchStatus}
                                                ></TrimApproval>

                                            </Modal.Body>
                                            <Modal.Footer>

                                            </Modal.Footer>

                                        </Modal> : ''
                                }

                                {
                                    getSampleSubModalpopup === true ?

                                        <Modal dialogClassName="commonwidth" show={getSampleSubModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotoSubApp(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {
                                                        TaskStatus === 3 && editProtoFitSmsSubmission === false ?
                                                            "View " : (editProtoFitSmsSubmission === true && getexistsubmission === false) ? 'Update ' : ''
                                                    }
                                                    {ParentTask}

                                                </Modal.Title>
                                                <div className="button-top-alignment">
                                                    {
                                                        TaskStatus === 3 ?
                                                            <>
                                                                <span title='Edit Form' onClick={() => seteditProtoFitSmsSubmission(true)}
                                                                    style={{
                                                                        pointerEvents: protofitsmsDisable === true ? "none" : '', opacity: protofitsmsDisable === true ? '0.6' : '',

                                                                    }}
                                                                    className="btn btn-info btn-xs edit">
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                                &nbsp;
                                                                <span title='Delete Form'
                                                                    style={{ pointerEvents: protofitsmsDisable === true ? "none" : '', opacity: protofitsmsDisable === true ? '0.6' : '' }}
                                                                    className="btn btn-danger btn-xs delete" onClick={() => setdeleteProtoFitSmsSubmission(true)}>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </> : ''
                                                    }
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body className="commonpopupmodal">
                                                <ProtoFitSMSSubmission props={props}
                                                    ProtoFitSMSSubmissionCallback={ProtoFitSMSSubmissionCallback}
                                                    TNAId={TNAId}
                                                    TNAProtoFitId={TNAProtoFitId}
                                                    ChildTask={ChildTask}
                                                    getnewlist={getnewlist}
                                                    ParentTask={ParentTask}
                                                    StyleId={StyleId}
                                                    TaskStatus={TaskStatus}
                                                    editProtoFitSmsSubmission={editProtoFitSmsSubmission}
                                                    delteProtoFitSmsSubmission={delteProtoFitSmsSubmission}
                                                ></ProtoFitSMSSubmission>
                                            </Modal.Body>
                                            <Modal.Footer>

                                            </Modal.Footer>

                                        </Modal> : ''
                                }
                                {
                                    getSampleAppModalpopup === true ?

                                        <Modal dialogClassName="commonwidth" show={getSampleAppModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotofitApp(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {
                                                        TaskStatus === 3 && editProtoFitSmsApproval === false ?
                                                            "View " : editProtoFitSmsApproval === true ? 'Update ' : ''

                                                    }
                                                    {ParentTask}

                                                </Modal.Title>
                                                <div className="button-top-alignment">
                                                    {
                                                        TaskStatus === 3 ?
                                                            <>
                                                                <span title='Edit Form' onClick={() => seteditProtoFitSmsApproval(true)}
                                                                    className="btn btn-info btn-xs edit"
                                                                    style={{}}>
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                                &nbsp;
                                                                <span title='Delete Form'
                                                                    className="btn btn-danger btn-xs delete" onClick={() => setdeleteProtoFitSmsApproval(true)}>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </> : ''
                                                    }
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body className="commonpopupmodal">
                                                <ProtoFitSMSApproval props={props}
                                                    ProtoFitAprovalCallback={ProtoFitAprovalCallback}
                                                    TNAId={TNAId}
                                                    TNAProtoFitId={TNAProtoFitId}
                                                    ChildTask={ChildTask}
                                                    ParentTask={ParentTask}
                                                    TaskStatus={TaskStatus}
                                                    editProtoFitSmsApproval={editProtoFitSmsApproval}
                                                    delteProtoFitSmsApproval={delteProtoFitSmsApproval}
                                                ></ProtoFitSMSApproval>
                                            </Modal.Body>
                                            <Modal.Footer>

                                            </Modal.Footer>

                                        </Modal> : ''
                                }
                                {
                                    getid.showPopupConfirm ? <ConfirmationPopup props={props} data={
                                        getid.Params
                                    }
                                        deleteCallback={Delcallback1}
                                        showpopup={true}
                                    /> : null
                                }


                                {
                                    getApperancemodalpopup === true ?

                                        <Modal dialogClassName="commonwidth" show={getApperancemodalpopup === true} size="xl" dragable backdrop="static"
                                            keyboard={false} onHide={() => HandleAfterWashList(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {

                                                        TaskStatus === 3 && editAppearanceAfterWash === false ?
                                                            "View " : editAppearanceAfterWash === true ? 'Update ' : ''
                                                    }
                                                    Appearance After Wash

                                                </Modal.Title>
                                                <div className="button-top-alignment">
                                                    {
                                                        TaskStatus === 3 ?
                                                            <>
                                                                <span title='Edit Form' onClick={() => seteditAppearanceAfterWash(true)}
                                                                    className="btn btn-info btn-xs edit">
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                                &nbsp;
                                                                <span title='Delete Form'
                                                                    className="btn btn-danger btn-xs delete" onClick={() => setdeleteAppearanceAfterWash(true)}>
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </> : ''
                                                    }
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body className="commonpopupmodal" style={{ overflowY: "scroll" }}>
                                                <AfterWashList props={props}
                                                    AppareanceTestSubmissionCallback={AppareanceTestSubmissionCallback}
                                                    TNAId={TNAId}
                                                    StyleId={StyleId}
                                                    AppareanceTestId={AppareanceTestId}
                                                    TaskStatus={TaskStatus}
                                                    editAppearanceAfterWash={editAppearanceAfterWash}
                                                    deleteAppearanceAfterWash={deleteAppearanceAfterWash}
                                                ></AfterWashList>
                                            </Modal.Body>
                                            <Modal.Footer>

                                            </Modal.Footer>

                                        </Modal> : ''

                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    getsID.showPopupDelete ? <SweetAlertPopup

                        // data={''}
                        data={
                            getsID.Params
                        }
                        deleteCallback={DeleteCallback}
                        showpopup={true}
                        pageActionId={getsID.TNAManualClose}
                        Msg={'Manual Close Deleted Successfully'}
                    /> : null
                }
                {
                    getIDSingleTask.showPopupDelete ? <SweetAlertPopup data={
                        getIDSingleTask.Params
                    }
                        deleteCallback={delCallback}
                        showpopup={true}
                        pageActionId={FinalinspectionID}
                        Msg={"Final Inspection deleted successfully"} /> : null
                }
                {
                    getIDQATask.showPopupDelete ? <SweetAlertPopup data={
                        getIDQATask.Params
                    }
                        deleteCallback={delQaCallback}
                        showpopup={true}
                        pageActionId={QAInspectionId}
                        Msg={"QA Inspection deleted successfully"} /> : null
                }
                {
                    getIDTestTask.showPopupDelete ? <SweetAlertPopup data={
                        getIDTestTask.Params
                    }
                        deleteCallback={delCallback}
                        showpopup={true}
                        pageActionId={TestingMainlist}
                        Msg={"Test Inspection deleted successfully"} /> : null
                }
                {
                    getID.showPopupDelete ? <SweetAlertPopup
                        // data={''}
                        data={
                            getID.Params
                        }
                        deleteCallback={DeleteCallbacks}
                        showpopup={true}
                        pageActionId={getID.TNAManualCloses}
                        Msg={'Manual Close Deleted Successfully'}
                    /> : null
                }

                {
                    IsPOSKUPopup &&
                    <PurchaseOrderSKUPopup
                        props={props}
                        IsPOSKUPopup={IsPOSKUPopup}
                        handleAddPOSKUCallback={handleAddPOSKUCallback}
                        handleClosePOSKUPopupCallback={handleClosePOSKUPopupCallback}
                    ></PurchaseOrderSKUPopup>
                }
                {
                    IsRemovePOSKUPopup &&
                    <Modal show={IsRemovePOSKUPopup === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setRemovePOSKUPopup(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Remove Purchase Order SKU List
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>SKU ID</th>
                                        <th>SKU Name</th>
                                        <th>PO/ID No</th>

                                        <th>End Customer</th>
                                        <th>Qty</th>
                                        <th>Ex-Factory</th>
                                        <th>
                                            <div className="checkbox" style={{ marginTop: "0px" }}>
                                                <label>
                                                    <input type="checkbox"
                                                        isMulti
                                                        onChange={event => handleSKUSelectAll(event)}
                                                        value={POSkuIsAllChecked}
                                                        checked={POSkuIsAllChecked}
                                                        // checked={inputField.IsSelectAll === 1 ? true : false}
                                                        className="colored-blue xc clearOpacity"
                                                    />
                                                    <span className="text"></span>
                                                </label>
                                            </div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        ExistingSkuList && ExistingSkuList.map((Field, Index) => (
                                            <Fragment key={
                                                `${Field.purchaseOrderSkuId}`
                                            }>
                                                <tr>
                                                    <td>{Field.purchaseOrderSkuId}</td>
                                                    <td>{Field.skuName}</td>

                                                    <td>
                                                        {
                                                            Field.buyerName === "Focus International" ? Field.focusPono : Field.idpono
                                                        }

                                                    </td>
                                                    <td>{Field.endCustomer}</td>
                                                    <td>{Field.quantity}</td>
                                                    <td>{Field.exFacDate}</td>
                                                    <td>
                                                        <label>
                                                            <input type="checkbox" name="isChecked"
                                                                Name
                                                                onChange={event => handleSKUSelect(event, Index)}
                                                                value={Field.isChecked}
                                                                checked={Field.isChecked}
                                                                className="clearOpacity textdesign" />

                                                            <span className="text"></span>
                                                        </label>

                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={() => RemovePoSku()}>
                                Remove
                            </Button>

                        </Modal.Footer>

                    </Modal>
                }

                {
                    isSkuAndStyleDetailPopup &&
                    <Modal show={isSkuAndStyleDetailPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideSkuAndStyleDetail()}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                SKU and Size Details
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>SKU Name</th>
                                        <th>Size Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{SkuAndStyleDetails.skuNameList}</td>
                                        <td>{SkuAndStyleDetails.sizeNameList ? SkuAndStyleDetails.sizeNameList : " - "}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-danger" disabled={buttonLoader} onClick={hideSkuAndStyleDetail}>
                                <i className="fa fa-times"></i>&nbsp;
                                Close
                            </button>
                        </Modal.Footer>

                    </Modal>
                }

                {
                    EDcalconoffpopup &&
                    <Modal show={EDcalconoffpopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setEDcalconoffpopup(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Depandent Task Calculation On/Off
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>
                                Do you want to change the depandent task's too?
                            </h4>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={() => DepandantTaskcalc(true)}>
                                <i className="fa fa-check right"></i>&nbsp;
                                Yes
                            </button>
                            <button type="button" className="btn btn-danger" disabled={buttonLoader} onClick={() => DepandantTaskcalc(false)}>
                                <i className="fa fa-times"></i>&nbsp;
                                No
                            </button>
                        </Modal.Footer>

                    </Modal>
                }
            </div >
            {loader}
        </Fragment >
    );
}
export default React.memo(EditTNARoute);


function AvoidDuplicationDep(indexVal, x, dependencyVal, uniqueDependencies, ChildDepandency, DepandencyIndexeswithValues) {
    let newDependency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
    if (!uniqueDependencies[newDependency]) {
        uniqueDependencies[newDependency] = true;
        if (ChildDepandency === '') {
            ChildDepandency = newDependency;
        } else {
            ChildDepandency += "," + newDependency;
        }
    }
    DepandencyIndexeswithValues.push({
        index: x.ChildId,
        value: dependencyVal
    });
    return ChildDepandency;
}



function ManualCommentAdd(values, index, IsParentDateShow, CloseDate) {
    for (let indexSubTaskList = 0; indexSubTaskList < values[index].SubTaskList.length; indexSubTaskList++) {
        for (let indexSubTaskList1 = 0; indexSubTaskList1 < values[index].SubTaskList[indexSubTaskList].ChildTaskList.length; indexSubTaskList1++) {
            if (values[index].SubTaskList[indexSubTaskList].ChildTaskList[indexSubTaskList1].ChildActualEndDate === null) {

                IsParentDateShow = true;
            }

        }
    }

    if (!IsParentDateShow) {

        values[index].ActualEndDate = CloseDate;

    }
    return IsParentDateShow;
}

function DateAffectDelete(values, Indexes, IsParentDateShow) {

    for (let indexSubTaskList = 0; indexSubTaskList < values[Indexes.Index].SubTaskList.length; indexSubTaskList++) {
        for (let indexSubTaskList1 = 0; indexSubTaskList1 < values[Indexes.Index].SubTaskList[indexSubTaskList].ChildTaskList.length; indexSubTaskList1++) {
            if (values[Indexes.Index].SubTaskList[indexSubTaskList].ChildTaskList[indexSubTaskList1].ChildActualEndDate === null) {
                IsParentDateShow = true;
            }

        }
    }

    if (IsParentDateShow) {

        values[Indexes.Index].ActualEndDate = null;
        values[Indexes.Index].StatusID = values[Indexes.Index].StatusID !== undefined ? values[Indexes.Index].StatusID = 1 : ''; // For
        //values[Indexes.Index].RouteApplyStatus = 1;
        // values[Indexes.Index].ActualEndDate = null;
    }
    return IsParentDateShow;
}

function DateAffectReschedule(values, Indexes, IsParentDateShow) {

    for (let indexSubTaskList = 0; indexSubTaskList < values[Indexes].SubTaskList.length; indexSubTaskList++) {
        for (let indexSubTaskList1 = 0; indexSubTaskList1 < values[Indexes].SubTaskList[indexSubTaskList].ChildTaskList.length; indexSubTaskList1++) {
            if (values[Indexes].SubTaskList[indexSubTaskList].ChildTaskList[indexSubTaskList1].ChildActualEndDate === null || values[Indexes].SubTaskList[indexSubTaskList].ChildTaskList[indexSubTaskList1].ChildActualEndDate === '') {
                IsParentDateShow = true;
            }

        }
    }

    if (IsParentDateShow) {

        values[Indexes].ActualEndDate = null;
        values[Indexes].ManualCloseDate = null;
        //values[Indexes].RouteApplyStatus = 1;
        //values[Indexes].StatusID = 1;
        values[Indexes].StatusID = values[Indexes].StatusID !== undefined ? values[Indexes].StatusID = 1 : '';
        values[Indexes].IsRescheduled = values[Indexes].IsRescheduled !== undefined ? values[Indexes].IsRescheduled = 1 : '';
        //values[Indexes].IsRescheduled = 1;

        // values[Indexes.Index].ActualEndDate = null;
    }
    return IsParentDateShow;
}



