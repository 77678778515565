import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { useSelector, useDispatch } from 'react-redux';
import Nodify from "../Common/ReactNotification";
import ReactNotification from 'react-notifications-component';
import { LoadCourierDetails } from '../../actions/courier';
import { InsertUpdateCourierDetails, ViewCourierStatus } from "../../actions/courier";
import moment from 'moment';
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Reactselect from 'react-select';

const CourierTracker = (props) => {
    const CurrentUser = useSelector((state) => state.auth.user);
    let isLoadingCourier = useSelector((state) => state.courierReducer.isLoadingCourier);
    let AgencyList = useSelector((state) => state.courierReducer.AgencyList);
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getHeaderName, addHeaderName] = useState("Add");
    const [getSubmitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getstate_CourierID, setstate_CourierID] = useState(0);
    const [getIsView, setIsView] = useState(true);
    const [getBtnText, setBtnText] = useState("Save");
    const [getCourierStatus, setCourierStatus] = useState([]);
    const [getres, setres] = useState(0);
    const [getCourierAvailability, setCourierAvailability] = useState(false);
    const [getCourierEvents, setCourierEvents] = useState([]);
    const AgencyTypeRef = useRef(null);
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    const [getCourierInfo, setCourierInfo] = useState([
        {
            Operation: 1,
            ID: 0,
            AWPNumber: '',
            AgencyID: 0,
            FromAddress: '',
            ToAddress: '',
            CreatedBy: CurrentUser.employeeinformationID,
            Status: 1
        }]
    );
    let courierValues = [...getCourierInfo];
    function SetCourier(props) {
        let CourierValue = {
            Operation: 1,
            ID: 0,
            AWPNumber: '',
            FromAddress: '',
            ToAddress: '',
            CreatedBy: CurrentUser.employeeinformationID,
            ModifiedBy: 0,
            Status: 'in-transit'
        };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.id !== "") {
                    setCourierAvailability(false);
                    setstate_CourierID(props.location.state.params.id);
                    CourierValue.Operation = 2;
                    CourierValue.ID = props.location.state.params.id;
                    CourierValue.AWPNumber = props.location.state.params.awpNumber;
                    CourierValue.FromAddress = props.location.state.params.fromAddress;
                    CourierValue.ToAddress = props.location.state.params.toAddress;
                    CourierValue.CreatedBy = props.location.state.params.createdBy;
                    CourierValue.ModifiedBy = CurrentUser.employeeinformationID;
                    CourierValue.Status = 1;
                    CourierValue.AgencyID = props.location.state.params.agencyID;
                    if (props.location.state.params.action == "View") {
                        setIsView(true);
                        addHeaderName("View");
                        setres(1);
                        dispatch(ViewCourierStatus(CourierValue.AWPNumber)).then((res) => {
                            if (res.data != undefined) {
                                setCourierStatus(res.data.shipments[0]);
                                setCourierEvents(res.data.shipments[0].events);
                                getCourierStatus.id === '' ? setCourierAvailability(false) : setCourierAvailability(true);
                                setres(0);
                            }
                        });
                    }
                    else if (props.location.state.params.action == "Edit") {
                       ;
                        dispatch(ViewCourierStatus(CourierValue.AWPNumber)).then((res) => {
                            if (res.data != undefined) {
                                if (res.data.shipments[0].status.status === "delivered") {
                                    courierValues[0].Status = res.data.shipments[0].status.status;
                                    setCourierInfo(courierValues);
                                }
                            }
                        });
                        setIsView(false);
                        setBtnText("Update");
                        addHeaderName("Update");
                    }
                    return CourierValue;
                }
            }
        }
    }

    useEffect(() => {
        setres(0);
    }, [getCourierStatus]);

    useEffect(() => {
        if (!isLoadingCourier) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingCourier])
    useEffect(() => {
        dispatch(LoadCourierDetails()).then((res) => {

        });
    }, [])
    const handleChange = (e) => {
        if (e.target.value.trim() != '') {
            if (e.target.name === "awpnumber") {
                courierValues[0].AWPNumber = e.target.value;
            }
            else if (e.target.name === "fromaddres") {
                courierValues[0].FromAddress = e.target.value;
            }
            else if (e.target.name === "toaddress") {
                courierValues[0].ToAddress = e.target.value;
            }
            setCourierInfo(courierValues);
        }
        else {
            if (e.target.name === "awpnumber") {
                courierValues[0].AWPNumber = "";
            }
            else if (e.target.name === "fromaddres") {
                courierValues[0].FromAddress = "";
            }
            else if (e.target.name === "toaddress") {
                courierValues[0].ToAddress = "";
            }
            setCourierInfo(courierValues);
        }
    }
    useEffect(() => {
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.id !== "") {
                    showLoader();
                    let getCourier = SetCourier(props);
                    setCourierInfo([getCourier]);
                    hideLoader();
                }
            }
        }
        else {
            setIsView(false);
        }
    }, [AgencyList]);

    const SaveCourierInfo = (e) => {
       ;
        setSubmitted(true);
        if (courierValues[0].AWPNumber.trim() === ''
            && courierValues[0].FromAddress.trim() === ''
            && courierValues[0].AgencyID === 0
            && courierValues[0].ToAddress.trim() === '' && courierValues[0].AWPNumber.trim().length === 10) {
            Nodify('Warning!', 'warning', "Please fill all mandatory (*) fields.");
        }
        else if (courierValues[0].AWPNumber.trim() === '') {
            Nodify('Warning!', 'warning', "AWB Number is required.");
        }
        else if (courierValues[0].FromAddress.trim() === '') {
            Nodify('Warning!', 'warning', "From Address is required.");
        }
        else if (courierValues[0].ToAddress.trim() === '') {
            Nodify('Warning!', 'warning', "To Address is required.");
        }
        else if (courierValues[0].AgencyID === 0) {
            Nodify('Warning!', 'warning', "Agency Name is required.");
        }
        else if (courierValues[0].AWPNumber.trim() !== '' && courierValues[0].FromAddress.trim() !== '' && courierValues[0].ToAddress.trim() !== '' && courierValues[0].AWPNumber.trim().length !== 10) {
            Nodify('Warning!', 'warning', "AWB Number should be 10 digits long.");
        }
        if (courierValues[0].AWPNumber.trim() !== ''
            && courierValues[0].FromAddress.trim() !== ''
            && courierValues[0].AgencyID !== 0
            && courierValues[0].ToAddress.trim() !== ''
            && courierValues[0].AWPNumber.trim().length === 10) {
            dispatch(InsertUpdateCourierDetails(getCourierInfo[0])).then((res) => {
                if (res.data.outputResult == "1") {
                    Nodify('success!', 'success', 'Courier added');
                    setTimeout(() => {
                        props.history.push('/CourierList');
                    }, 500);
                }
                else if (res.data.outputResult == "2") {
                    Nodify('success!', 'success', 'Courier updated');
                    setTimeout(() => {
                        props.history.push('/CourierList');
                    }, 500);
                }
                else {
                    Nodify('Warning!', 'warning', 'Courier Failed.');
                }
            });
        }
    }

    const ResetOperation = (value) => {
        if (value === "Back") {
            props.history.push('/CourierList');
        } else {
            // props.history.push('/BuyerRegister')
            window.location.reload();
        }
    }

    const handleAgencyTypeChange = (e) => {
        if (e !== null) {
            courierValues[0].AgencyID = e.id;
        }
        else {
            courierValues[0].AgencyID = 0;
        }
        setCourierInfo(courierValues);
    }
    const FocusAgencyType = () => {
        AgencyTypeRef.current.focus();
    }
    return (
        <>
            <div className="page-body">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common">{getHeaderName} Courier</h3>
                                {getIsView && <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>}
                            </div>
                            <div className="widget-body">
                                <div id="courier-form">
                                    <Form>
                                        <Row>
                                            <ReactNotification />
                                            <Col md={3}>
                                                <div className='form-group'>
                                                    <label htmlFor="txtAWPNumber">AWB Number<span className="text-danger">*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <Input placeholder="Enter AWB Number"
                                                            id="txtAWPNumber"
                                                            name="awpnumber"
                                                            type="text"
                                                            autoComplete="off"
                                                            maxLength="50"
                                                            autoFocus
                                                            className='form-control'
                                                            onChange={handleChange}
                                                            value={courierValues[0].AWPNumber}
                                                            //  styles={getSubmitted && courierValues[0].AWPNumber === '' ? styles : ''}
                                                            style={
                                                                {
                                                                    border: getSubmitted && (courierValues[0].AWPNumber === '' || (courierValues[0].AWPNumber !== '' && courierValues[0].AWPNumber.length !== 10)) ? '1px solid red' : ''
                                                                }
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className='form-Agency'>
                                                    <label htmlFor="Agency" onClick={FocusAgencyType}>Agency Name<span className="text-danger">*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <Reactselect className="basic-single" name="AgencyID"
                                                            id={
                                                                "Agency"
                                                            }
                                                            ref={AgencyTypeRef}
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={AgencyList}
                                                            value={AgencyList.filter(function (option) {
                                                                return option.value === courierValues[0].AgencyID;
                                                            })}
                                                            onChange={event => handleAgencyTypeChange(event)}
                                                            styles={getSubmitted && courierValues[0].AgencyID === 0 ? styles : ''}
                                                        ></Reactselect>
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className='form-group'>
                                                    <label htmlFor="txtFromAddres">From<span className="text-danger">*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <Input placeholder="Enter From Address"
                                                            id="txtFromAddres"
                                                            name="fromaddres"
                                                            type="text"
                                                            autoComplete="off"
                                                            maxLength="50"
                                                            onChange={handleChange}
                                                            className='form-control'
                                                            value={courierValues[0].FromAddress}
                                                            style={
                                                                {
                                                                    border: getSubmitted && courierValues[0].FromAddress === '' ? '1px solid red' : ''
                                                                }
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className='form-group'>
                                                    <label htmlFor="txtToAddres">To Address<span className="text-danger">*</span></label>
                                                    <span className='input-icon icon-right'>
                                                        <Input placeholder="Enter To Address"
                                                            id="txtToAddres"
                                                            name="toaddress"
                                                            type="text"
                                                            autoComplete="off"
                                                            maxLength="50"
                                                            className='form-control'
                                                            onChange={handleChange}
                                                            value={courierValues[0].ToAddress}
                                                            style={
                                                                {
                                                                    border: getSubmitted && courierValues[0].ToAddress === '' ? '1px solid red' : ''
                                                                }
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>

                                        {getres === 0 && getIsView &&
                                            <Row className='margin-top-20 margin-bottom-20'>
                                                <Col md={1}></Col>
                                                <Col md={10}>
                                                    <div className='CourierContainerOne'>
                                                        <span className='CourierContainerOneText'>Tracking Code: {getCourierStatus.id}</span><br />
                                                        {getCourierStatus.length != 0 && <span className='CourierContainerOneText'>Status:&nbsp;<span className='font-bold'>{getCourierStatus.status.status}</span>&nbsp;&nbsp;
                                                            <span className='Eventfont-bold'>{getCourierStatus.status.status.toLowerCase() == "delivered" && <i className='fa-lg fa fa-check-circle'></i>}</span>
                                                            <span className='Eventfonttransit-bold'>{getCourierStatus.status.status.toLowerCase() == "transit" && <i className='fa-lg fa fa-plane'></i>}</span>
                                                            <span className='Eventfontintransit-bold'>{getCourierStatus.status.status.toLowerCase() == "intransit" && <i className='fa-lg fa fa-send'></i>}</span>
                                                        </span>}
                                                    </div>
                                                    <div className='CourierContainerOne'>
                                                        {getCourierStatus.length != 0 && <span className='CourierContainerOneText font-bold'><i className='fa fa-map-marker fa-lg'></i>&nbsp;{getCourierStatus.status.location.address.addressLocality}</span>}<br />
                                                        {getCourierStatus.length != 0 && <span className='CourierContainerOneText font-bold'>{moment(new Date(getCourierStatus.status.timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}<br />
                                                        {/* <span className='CourierContainerOneText'>02. May 2022 05:33 Local time, ONTARIO SERVICE AREA, ON - CANADA</span> */}
                                                        <br />
                                                        <div className='CourierProcessFlow'>
                                                            {getCourierStatus.length != 0 && <span className='font-bold'>Service Area: {getCourierStatus.origin.address.addressLocality}</span>}<br />
                                                            {/* <div className="block"></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <div className="block"></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <div className="block"></div> */}
                                                            <div className="row">
                                                                <div className="col-md-12 hh-grayBox pt45 pb20">
                                                                    <div className="row justify-content-between">
                                                                        <div className="order-tracking completed col-md-4">
                                                                            <span className="is-complete"></span>
                                                                            <p>{getCourierStatus.length != 0 && <span>{getCourierStatus.events[getCourierStatus.events.length - 1].location.address.addressLocality}</span>}<br />
                                                                                <span>{getCourierStatus.length != 0 && <span> {moment(new Date(getCourierStatus.events[getCourierStatus.events.length - 1].timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}</span></p>

                                                                        </div>
                                                                        <div className="order-tracking completed col-md-4">
                                                                            <span className="is-complete"></span>
                                                                            <p>{getCourierStatus.length != 0 && <span>{getCourierStatus.status.location.address.addressLocality}</span>}<br />
                                                                                <span>{getCourierStatus.length != 0 && <span> {moment(new Date(getCourierStatus.status.timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}</span></p>
                                                                        </div>
                                                                        <div className={getCourierStatus.length != 0 && getCourierStatus.status.status == "delivered" ? "order-tracking completed col-md-4" : "order-tracking  col-md-4"}>
                                                                            <span className="is-complete"></span>
                                                                            <p>{getCourierStatus.length != 0 && <span>{getCourierStatus.destination.address.addressLocality}</span>}<br />
                                                                                <span>{getCourierStatus.length != 0 && getCourierStatus.status.status == "delivered" && <span> {moment(new Date(getCourierStatus.status.timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}</span><br />
                                                                                <span>{getCourierStatus.length != 0 && getCourierStatus.status.status == "delivered" && <span className='Eventfont-bold'> {getCourierStatus.status.status}</span>}</span></p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {getCourierStatus.length != 0 && <span className='font-bold pull-right'>Service Area: {getCourierStatus.destination.address.addressLocality}</span>}<br />
                                                        </div>
                                                    </div>
                                                    <div className='CourierContainerOne'>
                                                        <span className=' CourierProcessFlow font-bold'>More Shipment Details</span><br />
                                                        <Row className='CourierProcessFlow'>
                                                            <Col md={12} className=" pieceIDBG">
                                                                <Col md={6}>
                                                                    <span> Piece ID</span>
                                                                </Col>

                                                                <Col md={6}>
                                                                    {getCourierStatus.length != 0 && <span>
                                                                        {getCourierStatus.details.pieceIds}</span>}
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='CourierContainerTwo'>
                                                        <span className=' CourierProcessFlow font-bold'>All Shipment Updates</span><br />
                                                        <table border="0" className='table table-bordered table-hover margin-top-20'>

                                                            <tbody>
                                                                {
                                                                    getCourierStatus.length != 0 && getCourierEvents.map(event => (
                                                                        <>
                                                                            <tr>
                                                                                <td colSpan="1">
                                                                                    {moment(new Date(event.timestamp)).format("DD/MM/YYYY HH:MM:A")}
                                                                                </td>
                                                                                <td colSpan="5">
                                                                                    <p className='Eventfont-bold'>{event.description}</p><br />
                                                                                    <span>{event.location.address.addressLocality}</span>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {/* <table border="0" className='table table-bordered table-hover margin-top-20'>
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan="5">Courier Details</th>
                                                                    <th >Status :{getCourierStatus.length != 0 && <span className='CourierCurrentStatus'>&nbsp;{getCourierStatus.status.status}</span>} </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Origin:{getCourierStatus.length != 0 && <span className='CourierStatus'>&nbsp;{getCourierStatus.origin.address.addressLocality}</span>} </td>
                                                                    <td>Destination:{getCourierStatus.length != 0 && <span className='CourierStatus'>&nbsp;{getCourierStatus.destination.address.addressLocality}</span>}</td>
                                                                    <td>Number of Pieces: :{getCourierStatus.length != 0 && <span className='CourierStatus'>&nbsp;{getCourierStatus.details.totalNumberOfPieces}</span>}</td>
                                                                    <td>Piece ID: {getCourierStatus.length != 0 && <span className='CourierStatus'>&nbsp;{getCourierStatus.details.pieceIds}</span>}</td>
                                                                    <td colSpan="2">AWP Number: {getCourierStatus.length != 0 && <span className='CourierStatus'>&nbsp;{getCourierStatus.id}</span>}</td>
                                                                </tr>
                                                                <tr >
                                                                    <td colSpan="6">
                                                                        Current Loctaion :{getCourierStatus.length != 0 && <span className='CourierCurrentStatus'>&nbsp;{getCourierStatus.status.location.address.addressLocality}</span>}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th colSpan="1">Date</th>
                                                                    <th colSpan="3">Location</th>
                                                                    <th colSpan="2">Activity</th>
                                                                </tr>
                                                                {
                                                                    getCourierStatus.length != 0 && getCourierEvents.map(event => (
                                                                        <>

                                                                            <tr>
                                                                                <td colSpan="1">{moment(new Date(event.timestamp)).format("DD/MM/YYYY HH:MM:A")}</td>
                                                                                <td colSpan="3">{event.location.address.addressLocality}</td>
                                                                                <td colSpan="2">{event.description}</td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table> */}
                                                    </div>

                                                </Col>
                                            </Row>

                                        }
                                        {!getCourierAvailability && getIsView &&
                                            <Row className='margin-top-20 margin-bottom-20 text-center'>
                                                <span className='alert alert-warning '>
                                                    Sorry, your tracking attempt was not successful. Please check your tracking number.
                                                </span>
                                            </Row>
                                        }
                                    </Form>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </span>
                        {!getIsView && <span className="btn btn-danger" onClick={() => ResetOperation()}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>}
                        {!getIsView && <button type="submit" className="btn btn-success" onClick={SaveCourierInfo}>
                            <i className="fa fa-check right"></i> &nbsp;{getBtnText}
                        </button>}
                    </div>
                </div>

            </div>
            {loader}
        </>
    )
}

export default CourierTracker;