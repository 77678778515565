import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import Lightbox from "react-image-lightbox";
import { getReloadOrderSamplesApprovalViewList, getOrderSamplesApprovalViewList, getReloadOrderSamplesSubmissionViewList } from "../../../../actions/orderProtoFitSms";
import { loadSupplierList, getSkuAndStyleDetails } from "../../../../actions/tna";
import CommonService from "../../../../services/Common/CommonService";
import Nodify from "../../../Common/ReactNotification"
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import TnaService from "../../../../services/TNA/TNAService";
import ProtoFitSMSApproval from "../../../TNA/ProtoFitSMSApproval_Order";
import { TNASamplesChild } from '../../../Common/PageActionNumber'
import { TNAManualCloseParent, TNAManualCloseChildLibDip, TNAManualCloseChildStrikeOff, TNAManualCloseChildTrim, TNAManualCloseChildProtoFit, TNAManualCloseChildSms, smsapprovalId, TNAManualCloseChildExFactory, TNAManualCloseChildStrikeoffTest, TNAManualCloseChildTrimTest, FinalinspectionID } from "../../../Common/PageActionNumber";
const ProtoFitViewApprovalTab = (props) => {

  const dispatch = useDispatch();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [IsProtoExpanded, setIsProtoExpanded] = useState(false);
  const [IsFitExpanded, setIsFitExpanded] = useState(false);
  const [IsNonSampleExpanded, setIsNonSampleExpanded] = useState(false);
  const [IsSizeSampleExpanded, setIsSizeSampleExpanded] = useState(false);
  const [IsPreProductionExpanded, setIsPreProductionExpanded] = useState(false);
  const [IsShipmentExpanded, setIsShipmentExpanded] = useState(false);
  const [IsPhotoSampleExpanded, setIsPhotoSampleExpanded] = useState(false);
  const [currentExpand, setcurrentExpand] = useState({ currindex: "", currStrikeOffId: "", currColorGroupID: "" });
  const [TNAProtoFitId, setTNAProtoFitId] = useState();
  const [ParentTask, setParentTask] = useState('');
  const [ChildTask, setChildTask] = useState('');
  const [TaskStatus, setTaskStatus] = useState();
  const [TNAId, setTNAId] = useState();
  const [getSampleAppModalpopup, setSampleAppModalpopup] = useState(false);
  const [Indexes, setIndexes] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0 });
  const [delteProtoFitSmsApproval, setdeleteProtoFitSmsApproval] = useState(false);
  const StaticTask = useSelector((state) => state.tna.StaticTask);
  ; const [getsID, setsID] = useState(0);
  const [getID, setID] = useState(0);

  const ordSampleList = useSelector((state) => state.orderProtoFitSms.ordSampleApprovalList);

  let isLoadingProtoFitSms = useSelector((state) => state.orderProtoFitSms.isLoadingProtoFitSms);
  const getSupplierList = useSelector((state) => state.tna.drpSupplierList);
  let GLDRequestStatusList = useSelector((state) => state.orderStrikeOff.OLDRequestStatusList);
  const [editProtoFitSmsApproval, seteditProtoFitSmsApproval] = useState(false);
  const [Manualclosedata, setManualclosedata] = useState({ ManualCloseComment: '', ManualCloseDate: '' });
  const [selectall, setselectall] = useState(false);
  const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
  const [showmaunalclose, setshowmaunalclose] = useState(false);
  const [ChildId, setChildId] = useState({ ChildId: 0, Status: 0 });
  const [getComment, setComment] = useState(false);


  let BuyerId = 0;
  let BrandId = 0;
  let SeasonId = 0;

  //get props params
  if (props.props.location.state !== undefined) {

    BuyerId = props.props.location.state.params.buyerId === undefined ? 0 : props.props.location.state.params.buyerId;
    BrandId = props.props.location.state.params.brandId === undefined ? 0 : props.props.location.state.params.brandId;
    SeasonId = props.props.location.state.params.seasonId === undefined ? 0 : props.props.location.state.params.seasonId;

  }

  // If check production or sampling
  const activeMenus = useSelector((state) => state.auth.activeMenuList);
  //let IsProduction = activeMenus.IsProduction;

  let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
  let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
  useEffect(() => {
    if (!isLoadingProtoFitSms) {
      hideLoader();
    } else {
      showLoader();
    }


  }, [isLoadingProtoFitSms])


  const [getSearchReqValues, setSearchReqValues] = useState({
    StatusId: 0,
    SupplierId: 0,
    SearchTxt: "",
    StyleName: "",
    StyleNo: "",
    IdPoNo: "",
    Samplecolor: "",
    SampleName: '',
    stateDate: [
      {
        startDate: null,
        endDate: new Date(""),
        key: 'selection',
        isShow: false
      }
    ]


  });

  const SearchReqValues = { ...getSearchReqValues };

  const [inputFields, setInputFields] = useState([{
    BuyerBrandSeasonName: '',
    styleName: '',
    StyleNo: '',
    Supplier: '',
    SubmittedDate: '',
    SendTo: "0",
    SendThrough: 0,
    EndCustomer: "",
    AWBName: "", AWBDate: "", AWBDateandNo: "",
    StrikeOffId: 0, StrikeoffCode: 0, StrikeoffName: "", Quality: "",
    StrikeoffDimensionId: 0, SwatchCardImageName: "", SwatchCardIamgePath: "", Remarks: "", CreatedBy: 0,
    SubmitStatus: '',
    GenralStrikeOffTNASubmissionAndApprovalId: 0,
    GeneralTNAStrikeOffSubmittedOption: [],
    ColorGroupId: 0
  }]);

  const values = [...inputFields]


  useEffect(() => {
    // dispatch(loadSupplierList());
    // dispatch(loadTaskHolderList());
    // dispatch(loadRouteDependencyList());
    searchStrikeOff();
  }, [])

  const DeleteCallback = (value) => {
    if (value === true) {
      setsID({ showPopupDelete: false, Params: [] });
      searchTrim();
    } else {
      setsID({ showPopupDelete: false, Params: [] })
      setshowmaunalclose(true);
    }
    ReloadSubmission()
  }

  const handleSearchChange = (e, FieldName) => {

    let inputText = '';
    if (FieldName === 'SearchTxt' || FieldName === 'StyleName' || FieldName === 'StyleNo' || FieldName === 'IdPoNo' || FieldName === 'SampleName' || FieldName === 'Samplecolor') {
      if (e && e.target.value.trim() !== '') {
        inputText = e.target.value;
      }
    } else if (FieldName === 'Status' || FieldName === 'SupplierId') {
      if (e) {
        inputText = e.value
      }
    }

    if (FieldName === 'Status') {
      SearchReqValues.StatusId = inputText;
    }
    else if (FieldName === 'SupplierId') {
      SearchReqValues.SupplierId = inputText;
    } else if (FieldName === 'SearchTxt') {
      SearchReqValues.SearchTxt = inputText;
    } else if (FieldName === 'StyleName') {
      SearchReqValues.StyleName = inputText;
    }
    else if (FieldName === 'SampleName') {
      SearchReqValues.SampleName = inputText;
    }
    else if (FieldName === 'Samplecolor') {
      SearchReqValues.Samplecolor = inputText;
    }
    else if (FieldName === 'StyleNo') {
      SearchReqValues.StyleNo = inputText;
    }
    else if (FieldName === 'IdPoNo') {
      SearchReqValues.IdPoNo = inputText;
    }
    setSearchReqValues(SearchReqValues);

  }

  const handledelete = (childid, Taskname) => {
    ;
    let Value = [];

    if (Taskname === 'Proto Sample Approval') {
      let Params = {
        Operation: 55,
        Id: childid,
      };
      //  Value.TNAId = props.location.state.params.tnaid;
      setshowmaunalclose(false);
      Value.Id = ChildId.ChildId;
      setsID({ TNAManualClose: TNAManualCloseChildProtoFit, showPopupDelete: true, Params: Value });
    }
    else if (Taskname === "Fit Sample Approval") {
      let Params = {
        Operation: 56,
        Id: childid,
      };
      setshowmaunalclose(false);
      Value.Id = ChildId.ChildId;
      setsID({ TNAManualClose: TNAManualCloseChildProtoFit, showPopupDelete: true, Params: Value });

    }
    else if (Taskname === "SMS Approval"
      || Taskname === "Size Set Sample Approval"
      || Taskname === "Pre Production Sample Approval"
      || Taskname === "Shipment Sample Approval"
      || Taskname === "Photo Sample Approval") {
      let Params = {
        Operation: 57,
        Id: childid,
      };
      setshowmaunalclose(true);
      // Value.TNAId = props.location.state.params.tnaid;
      Value.Id = ChildId.ChildId;
      setsID({ TNAManualClose: TNAManualCloseChildSms, showPopupDelete: true, Params: Value });
    }



  }
  const resetStrikeOff = () => {
    SearchReqValues.stateDate[0].startDate = null;
    SearchReqValues.stateDate[0].endDate = new Date("");
    SearchReqValues.stateDate[0].key = 'selection';
    SearchReqValues.stateDate[0].isShow = false;

    SearchReqValues.StatusId = 0;
    SearchReqValues.SupplierId = 0;
    SearchReqValues.SearchTxt = "";
    SearchReqValues.StyleName = "";
    SearchReqValues.StyleNo = "";
    SearchReqValues.IdPoNo = "";
    SearchReqValues.SampleName="";
    SearchReqValues.Samplecolor="";
    setSearchReqValues(SearchReqValues);
    searchStrikeOff();

  }


  const clearDateSelect = () => {
    SearchReqValues.stateDate[0].startDate = null;
    SearchReqValues.stateDate[0].endDate = new Date("");
    SearchReqValues.stateDate[0].key = 'selection';
    SearchReqValues.stateDate[0].isShow = false;

    setSearchReqValues(SearchReqValues);
  }
  const handleDateSelect = (event, isShow) => {

    SearchReqValues.stateDate[0].isShow = isShow;
    setSearchReqValues(SearchReqValues);
  }

  const handlePopupDateselection = (item) => {

    SearchReqValues.stateDate = [item.selection];
    setSearchReqValues(SearchReqValues);
  }

  function convertUTCDateToLocalDate(date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }


  const searchStrikeOff = () => {

    SearchReqValues.stateDate[0].isShow = false;
    setSearchReqValues(SearchReqValues);

    let StartDate = null;
    let EndDate = null;
    if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
      StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
    }
    if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
      EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
    }

    let params = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 4,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      SampleName: SearchReqValues.SampleName,
      Samplecolor:SearchReqValues.Samplecolor,
      IsProduction: IsProduction
    };
    dispatch(getOrderSamplesApprovalViewList(params));
  }

  const handleChildManualclose = async (TaskName, TaskType, TaskID, ChildId, Status, Comment, CommentDate) => {
    showLoader();
    setChildId({ ChildId: ChildId, Status: Status });
    var mandata = { ...Manualclosedata }
    mandata.ManualCloseComment = Comment;
    mandata.ManualCloseDate = new Date(CommentDate);
    setManualclosedata(mandata);

    setshowmaunalclose(true);

    // let Operation;
    // if (TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
    //     Operation = 1;
    // }
    // else if (TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
    //     Operation = 3;
    // }
    // else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
    //     Operation = 2;
    // }
    // else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
    //     Operation = 4;
    // }
    // else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
    //     Operation = 5;
    // }

    // else if (TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) {
    //     Operation = 6;
    // }
    // else {
    //     Operation = 7;

    // }


    // if (Operation !== 7) { // check submission exists for approvals
    //     TnaService.GetTNAManualCloseSubmissionList(ChildId, TaskName).then((response) => {
    //         if (response.data.length !== 0) {

    //             setshowmaunalclose(true);

    //             //get manual close
    //             let Operation;
    //             if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
    //                 Operation = 1;
    //             }
    //             else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
    //                 || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
    //                 || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
    //                 || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
    //                 Operation = 3;
    //             }
    //             else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
    //                 || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
    //                 || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
    //                 Operation = 2;
    //             }
    //             else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
    //                 Operation = 4;
    //             }
    //             else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
    //                 Operation = 5;
    //             }
    //             //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
    //             else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
    //                 || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
    //                 || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW) {
    //                 Operation = 6;
    //             }
    //             else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
    //                 Operation = 7;
    //             }
    //             else {
    //                 Operation = 8;
    //             }


    //             let params = {
    //                 BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
    //                 SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
    //                 TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
    //                 Operation: Operation, ParentIdOrSubChildId: ChildId
    //             };

    //             TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
    //                 if (response.data) {
    //                     let data = response.data.map((item) => {

    //                         if (item.subTaskInfoId === ChildId) {
    //                             item.isSelected = 1;

    //                             return item;
    //                         } else {
    //                             return item;
    //                         }
    //                     });
    //                     let isSelectAll = 0;
    //                     if (data) {
    //                         isSelectAll = !!!data.find(d => d.isSelected === 0);
    //                         isSelectAll = isSelectAll ? 1 : 0;
    //                     }
    //                     setselectall(isSelectAll);
    //                     if (Status !== 3) {
    //                         setMultiCloseDatas(data);
    //                     }
    //                     else {
    //                         setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
    //                     }

    //                 }


    //             }).catch(() => { });
    //         }
    //         else {
    //             Nodify('Warning!', 'warning', "Manual Submission not yet added for this task");
    //             return false;
    //         }

    //     }).catch(() => { });
    // }

    setshowmaunalclose(true);

    //get manual close
    let Operation;
    if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
      Operation = 1;
    }
    else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
      || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
      || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
      || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
      Operation = 3;
    }
    else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
      || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
      || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
      Operation = 2;
    }
    else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
      Operation = 4;
    }
    else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
      Operation = 5;
    }
    //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
    else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
      || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
      || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
      || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
      || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
      || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
      || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
      Operation = 6;
    }
    else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
      Operation = 7;
    }
    else {
      Operation = 8;
    }


    let params = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      SupplierId: SearchReqValues.SupplierId, TaskType: TaskType, TaskNameId: TaskID,
      TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
      Operation: Operation, ParentIdOrSubChildId: ChildId
    };

    await TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
      if (response.data) {
        let data = response.data.map((item) => {

          if (item.subTaskInfoId === ChildId) {
            item.isSelected = 1;

            return item;
          } else {
            return item;
          }
        });
        let isSelectAll = 0;
        if (data) {
          isSelectAll = !!!data.find(d => d.isSelected === 0);
          isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);
        if (Status !== 3) {
          setMultiCloseDatas(data);
        }
        else {
          setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
        }
        hideLoader();
      }
      hideLoader();

    }).catch(() => { hideLoader(); });


  };

  function ChkAllDepandenctTasksClose(arrDependency) {
    ;
    let IsAllTasksClosed = true;
    let InCompleteTasks = '';
    if (arrDependency && arrDependency.length > 0) {
      let CurrentChildvalues = [];
      values.map((Main, MainIndex) => {
        Main.SubTaskList.map((Child) => {
          Child.ChildTaskList.map((SubChild) => {
            CurrentChildvalues.push({ ParentIndex: MainIndex + 1, ChildIndex: SubChild.ChildIndex, StatusId: SubChild.StatusID })
          })
        })
      })
      arrDependency.filter(x => x.DependencyID !== 0).map((depitem, i) => {
        if (CurrentChildvalues.filter(x => x.ChildIndex === depitem.IndexName).length > 0) {
          if (CurrentChildvalues.filter(x => x.ChildIndex === depitem.IndexName)[0].StatusId !== 3) {
            IsAllTasksClosed = false;
            let InCompleterow = CurrentChildvalues.filter(x => x.ChildIndex === depitem.IndexName)[0];
            if (InCompleteTasks === '') {
              InCompleteTasks = InCompleterow.ParentIndex + "-" + InCompleterow.ChildIndex;
            }
            else {
              InCompleteTasks = InCompleteTasks + "," + (InCompleterow.ParentIndex + "-" + InCompleterow.ChildIndex)
            }
          }
        }
      })
    }

    return InCompleteTasks;
  }

  const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex, ParentTask, StatusId, Operation, arrDependency, intDependency) => {
    //check all depandent tasks closed
    ;
    let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);
    if (ParentTask === 'Proto Sample Submission') {
      ParentTask = 'Proto Sample Approval'
    }
    else if (ParentTask === 'Fit Sample Submission') {
      ParentTask = 'Fit Sample Approval'
    }
    if (InCompleteTasks !== '' && intDependency === 0) {
      Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
    }
    //if depandent tasks closed then open popup
    else {
      TnaService.GetProtoFitSMSSubmission(Operation, TNAProtoFitId).then((response) => {

        if (response.data.length !== 0) {
          // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
          //     let Dependency = false;
          //     if (values[index].SelectedDependency) {
          //         let arrstatus = [];
          //         arrstatus = values[index].SelectedDependency.split(',');
          //         if (arrstatus.length > 0) {
          //             arrstatus.forEach(element => {
          //                 let val = [];
          //                 val = element.split('-');
          //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
          //                 if (valIndex !== 3) {
          //                     Dependency = true;
          //                 }
          //             });
          //         }
          //         if (Dependency && StatusId !== 3) {
          //             Nodify('Warning!', 'warning', "Please close dependency task");
          //         }
          //         else {
          //             setTNAProtoFitId(TNAProtoFitId);
          //             setParentTask(ParentTask);
          //             setTaskStatus(StatusId);
          //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
          //             setdeleteProtoFitSmsApproval(false)
          //             setSampleAppModalpopup(true);
          //         }
          //     } else {
          //         setTNAProtoFitId(TNAProtoFitId);
          //         setParentTask(ParentTask);
          //         setTaskStatus(StatusId);
          //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
          //         setdeleteProtoFitSmsApproval(false)
          //         setSampleAppModalpopup(true);
          //     }
          // }
          // else {
          setTNAProtoFitId(TNAProtoFitId);
          setParentTask(ParentTask);
          setTaskStatus(StatusId);
          setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
          setdeleteProtoFitSmsApproval(false)
          setSampleAppModalpopup(true);
          // }
        }
        else {
          Nodify('Warning!', 'warning', "Submission not yet added for this task");
          return false;
        }

      }).catch(() => { });
    }

  }
  const handleprotofitApp = () => {
    setSampleAppModalpopup(false);
    seteditProtoFitSmsApproval(false);
    setdeleteProtoFitSmsApproval(false)
  }
  const ReloadSubmission = () => {
    let StartDate = null;
    let EndDate = null;
    if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
      StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
    }
    if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
      EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
    }
    let params = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 4,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };

    let approvalparams = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 3,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };
    dispatch(getReloadOrderSamplesSubmissionViewList(approvalparams));
    dispatch(getOrderSamplesApprovalViewList(params));


  }

  const searchTrim = () => {
    SearchReqValues.stateDate[0].isShow = false;
    setSearchReqValues(SearchReqValues);

    let StartDate = null;
    let EndDate = null;
    if (moment(SearchReqValues.stateDate[0].startDate).isValid()) {
      StartDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].startDate));
    }
    if (moment(SearchReqValues.stateDate[0].endDate).isValid()) {
      EndDate = convertUTCDateToLocalDate(new Date(SearchReqValues.stateDate[0].endDate));
    }

    let params = {
      BuyerId: BuyerId, BrandId: BrandId, SeasonId: SeasonId,
      Operation: 4,
      Samplecolor: SearchReqValues.Samplecolor,
      StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null,
      SupplierId: SearchReqValues.SupplierId,
      StartDate: StartDate, EndDate: EndDate,
      StyleName: SearchReqValues.StyleName,
      StyleNo: SearchReqValues.StyleNo,
      IdPoNo: SearchReqValues.IdPoNo,
      IsProduction: IsProduction
    };
    dispatch(getOrderSamplesApprovalViewList(params));

    // let params = {
    //     BuyerId: TrimViewDetailParams.BuyerId, BrandId: TrimViewDetailParams.BrandId, SeasonId: TrimViewDetailParams.SeasonId,
    //     BuyerName: TrimViewDetailParams.BuyerName, BrandName: TrimViewDetailParams.BrandName, SeasonName: TrimViewDetailParams.SeasonName,
    //     PantoneId: 0, Index: 0, SearchText: SearchReqValues.SearchTxt, StatusId: SearchReqValues.StatusId ? SearchReqValues.StatusId : null, StartDate: StartDate, EndDate: EndDate
    // };
    // dispatch(getGeneralTrimDetailsViewList(params));
    // commonParentExpand();
  }


  const ProtoFitAprovalCallback = (value, Operation) => {


    if (value === true) {
      setSampleAppModalpopup(false);
      setdeleteProtoFitSmsApproval(false);
      seteditProtoFitSmsApproval(false);

      ReloadSubmission();
      searchTrim();
      // handleToggle(currentExpand.currindex, currentExpand.currTrimId, currentExpand.currColorGroupID);
    }


  }
  const handleChangeIsManualCloseComments = (event, feild) => {
    var manualclosedata = { ...Manualclosedata };
    let input = '';

    setComment(false);
    if (feild === "Comment") {
      if (event.target.value.trim() !== '') {
        input = event.target.value;
      }
      manualclosedata.ManualCloseComment = input;
    }
    else {
      manualclosedata.ManualCloseDate = event;
      // ManualCloseData.ManualCloseDate = event;
    }
    setManualclosedata(manualclosedata);
  }
  const CustomInputman = (props) => {

    return (
      <input
        className="form-control DatePickerCalenderHoliday min_width_Date"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        menuPosition="fixed"
        placeholder="DD/MM/YYYY"
        style={{ border: getComment && props.value === '' ? '1px solid red' : '' }}
      />
    )
  }

  return (
    <div className="widget-body">
      <div id="registration-form">
        <form>

          <div className="col-sm-12">
            <div className="row">

              <div className="col-md-2">
                <label htmlFor="Status">
                  Style Name
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'Style Name'}
                    placeholder={'Style Name'}
                    value={SearchReqValues.StyleName}
                    onChange={e => handleSearchChange(e, 'StyleName')}
                  />
                </span>
              </div>

              <div className="col-md-2">
                <label htmlFor="Status">
                  Style No
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'Style No'}
                    placeholder={'Style No'}
                    value={SearchReqValues.StyleNo}
                    onChange={e => handleSearchChange(e, 'StyleNo')}
                  />
                </span>
              </div>

              <div className="col-md-2">
                <label htmlFor="Status">
                  Sample Color
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'Sample Color'}
                    placeholder={'Sample Color'}
                    value={SearchReqValues.Samplecolor}
                    onChange={e => handleSearchChange(e, 'Samplecolor')}
                  />
                </span>
              </div>




              <div className="col-md-2">
                <label htmlFor="Status">
                  ID/PO NO
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'ID/PO No'}
                    placeholder={'ID/PO NO'}
                    value={SearchReqValues.IdPoNo}
                    onChange={e => handleSearchChange(e, 'IdPoNo')}
                  />
                </span>

              </div>
              <div className="col-md-2">
                <label htmlFor="Status">
                  Sample Name
                </label>
                <span className="input-icon icon-right">
                  <input
                    type="text"
                    className="form-control StyleList"
                    title={'Sample Name'}
                    placeholder={'Sample Name'}
                    value={SearchReqValues.SampleName}
                    onChange={e => handleSearchChange(e, 'SampleName')}
                  />
                </span>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="SupplierID">
                    Supplier
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <span className="input-icon icon-right">
                    <Reactselect className="basic-single" name="SupplierId"
                      id={
                        "SupplierId"
                      }
                      isLoading={false}
                      isClearable={true}
                      isSearchable={true}
                      onChange={event => handleSearchChange(event, 'SupplierId')}
                      value={getSupplierList.filter(function (option) {
                        return option.value === SearchReqValues.SupplierId;

                      })}
                      options={getSupplierList}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                    ></Reactselect>

                  </span>
                </div>
              </div>

            </div>
            <div className="row">
             

              <div className="col-md-2">

                <div className="form-group">
                <label >Date Range <span className="rptDateRangeSpan" title="Clicking on the text box cause datepicker to appear and disappear">(Clicking on the text box...)</span> </label>
                  <span className="input-icon icon-right">
                    <input
                      value={(SearchReqValues.stateDate[0].startDate === null ? '' : moment(SearchReqValues.stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') + ((SearchReqValues.stateDate[0].endDate === null || !moment(SearchReqValues.stateDate[0].endDate).isValid()) ? '' : moment(SearchReqValues.stateDate[0].endDate).format('DD/MM/YYYY'))}
                      onClick={event => handleDateSelect(event, !SearchReqValues.stateDate[0].isShow)}
                      //onBlur={event => handleDateBlur()}
                      type="text"
                      className={'form-control OrdProtofit'}
                      placeholder="Select Date"
                    />
                    <i className="fa fa-times OrdProtofit" onClick={clearDateSelect} ></i>
                  </span>
                </div>
 

                {SearchReqValues.stateDate[0].isShow &&
                  <DateRangePicker
                    //onChange={item => setStateDate([item.selection])}
                    onChange={item => handlePopupDateselection(item)}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={SearchReqValues.stateDate}
                    showDateDisplay={false}
                    direction="vertical"
                    className={'TNAReportDatePicker'}

                  />

                }

              </div>
              <div className="col-md-2" style={{ paddingTop: '29px' }}>

                <button type="button" className="btn btn-success" title="Search" onClick={event => searchStrikeOff()}
                >
                  <i className="fa fa-search"></i>
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-danger" title="Reset" onClick={resetStrikeOff}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" >
              <thead>
                <tr>
                  <th className="fixed-column-header-first-tna col-action-or-other" style={{ width: "15px" }}> #</th>
                  <th className="col-action-or-other">Approved Date</th>
                  <th className="col-action-or-other">Style Name</th>
                  <th className="col-action-or-other">SKU Name</th>
                  <th className="col-action-or-other">Style No</th>
                  <th className="col-action-or-other">ID/PO No</th>
                  <th className="col-action-or-other">Supplier</th>
                  <th className="col-action-or-other">Sample Name</th>
                  <th className="col-action-or-other">Details (size & qty)</th>
                  <th className="col-action-or-other">Task Holder / Follower</th>
                  <th className="col-action-or-other">Approval Target</th>
                  <th className="col-action-or-other">Approval Info</th>
                  <th className="col-action-or-other">Submission AWB</th>
                  <th className="col-action-or-other">Action</th>

                </tr>
              </thead>
              <tbody>
                <Fragment>
                  <>
                    {
                      ordSampleList.length > 0 ?

                        ordSampleList.map((subField, index) => (
                          <tr>
                            <td>
                              {index + 1}
                            </td>
                            <td>
                              {subField.approvalDate}
                            </td>
                            <td>
                              {subField.styleName}
                            </td>
                            <td>
                              {subField.sampleColor}
                            </td>
                            <td>
                              {subField.styleNo}
                            </td>
                            <td>
                              {subField.poNo}
                            </td>
                            <td>
                              {subField.supplierName}
                            </td>
                            <td>
                              {subField.sampleRequestName}
                            </td>
                            <td>
                              {subField.sizeQty}
                            </td>
                            <td>
                              {subField.follower}
                            </td>
                            <td>
                              {moment(subField.approvalTarget).format('DD/MM/YYYY')}

                            </td>
                            <td>
                              {subField.approvalInfo == "Approved" && <span className="text-success"><b>{subField.approvalInfo}</b></span>}
                              {subField.approvalInfo == "Approved with Comments" && <span className="text-warning"><b>{subField.approvalInfo}</b></span>}
                              {subField.approvalInfo == "Rejected" && <span className="text-danger"><b>{subField.approvalInfo}</b></span>}
                              {subField.approvalInfo != "Approved" && subField.approvalInfo != "Approved with Comments" && subField.approvalInfo != "Rejected" &&
                                <span>-</span>
                              }
                            </td>
                            <td>

                              {
                                subField.awbDate === null ? "-" : subField.awbDate
                                  ||
                                  subField.awbDate === '' ? "-" : subField.awbDate
                              }
                            </td>
                            <td>
                              {
                                (subField.manualCloseComment !== '' && subField.manualCloseComment !== null && subField.manualCloseComment !== undefined) && subField.statusID === 3 ?
                                  <button type="button" className={subField.manualCloseComment === '' || subField.manualCloseComment === null || subField.manualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"}
                                    title="View Approved Manual Close"
                                    onClick={() => handleChildManualclose(subField.taskName, subField.taskType, subField.taskID, subField.childId, subField.statusID,
                                      subField.manualCloseComment, subField.manualCloseDate)}
                                  >
                                    <i className="fa fa-user"></i>&nbsp;
                                    <i className="fa fa-close"></i>
                                  </button>
                                  : ''
                              }

                              {
                                (subField.taskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || subField.taskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || subField.taskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL || subField.taskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL || subField.taskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL || subField.taskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) && subField.statusID === 3 && subField.isShowSubmitButton === 0
                                  && (subField.childActualEndDate !== 'Manual' && subField.childActualEndDate !== 'Existing') && subField.manualCloseComment === '' ?
                                  <button type="button" className="btn btn-xs btn-success" title='View Approval'
                                    onClick={() => OpenProtoFitApprovalpopup(subField.childId, index, index, index, subField.taskName, subField.statusID,
                                      subField.taskName === 'Proto Sample Approval' ? 7 : subField.taskName === 'Fit Sample Approval' ? 8 : 9, subField.arrDependency, subField.intDependency)}
                                  >
                                    <i className="fa fa-eye "></i>
                                  </button> : ''
                              }
                              {
                                showmaunalclose === true ?
                                  <Modal show={showmaunalclose === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => setshowmaunalclose(false)}>
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        View Manual Close
                                        &nbsp; &nbsp;
                                        {
                                          ChildId.Status === 3 &&
                                          <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete(subField.childId, subField.taskName)}>
                                            <i className="fa fa-trash-o"></i>
                                          </button>
                                        }

                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                        <thead>
                                          <tr>
                                            <th>Comments <span className="text-danger">*</span></th>
                                            <th>Close Date <span className="text-danger">*</span></th>
                                          </tr>
                                        </thead>
                                        <tbody>

                                          <tr>
                                            <td style={{ width: "600px" }} >
                                              <textarea
                                                rows="3"
                                                placeholder="Enter Comments here..."
                                                autoFocus onFocus={""} autoComplete="off"
                                                maxLength="500" name="EnterRemarks"
                                                id={"EnterRemarks"}
                                                value={Manualclosedata.ManualCloseComment}
                                                onChange={event => handleChangeIsManualCloseComments(event, "Comment")}
                                                style={{ width: "100%", border: getComment && Manualclosedata.ManualCloseComment === '' ? '1px solid red' : '' }}
                                                disabled
                                              >

                                              </textarea>
                                            </td>
                                            <td>
                                              <DatePicker className="form-control" name="HolidayDate"
                                                id={
                                                  "HolidayDate"
                                                }
                                                selected={
                                                  Manualclosedata.ManualCloseDate
                                                }
                                                onChange={event => handleChangeIsManualCloseComments(event, "CommentDate")}
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth
                                                showMonthDropdown
                                                // showYearDropdown
                                                dropdownMode="scroll"
                                                autoComplete="off"
                                                minDate={new Date()}
                                                maxDate={new Date()}
                                                style={{ border: getComment && subField.ManualCloseDate === '' ? '1px solid red' : '' }}
                                                customInput={<CustomInputman />}
                                                // isClearable={inputField.HolidayDate === '' ? false : true}
                                                disabled
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>Style Name</th>
                                            <th>Style No</th>
                                            <th>PO/ID No</th>
                                            <th>Details</th>
                                            <th>Task Holder / Follower</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          {

                                            MultiCloseDatas.map((item, index) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td>{item.styleName}</td>
                                                    <td>{item.styleNo}</td>
                                                    <td>{item.idPoNo}</td>
                                                    <td>{item.details}</td>
                                                    <td>{item.taskOwnerNameList}</td>

                                                  </tr>
                                                </>

                                              )
                                            })
                                          }

                                        </tbody>
                                      </table>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      {/* {
                                subField.statusID !== 3 &&
                                <Button variant="success">
                                    Save
                                </Button>


                            } */}


                                    </Modal.Footer>
                                  </Modal> : ''
                              }
                            </td>

                          </tr>
                        ))

                        :
                        <tr><td colSpan="14" className='norecordfound'><span>No Records Found</span></td></tr>

                    }
                  </>
                </Fragment>
              </tbody>
            </table>
          </div>
        </form>

      </div>
      {
        getSampleAppModalpopup === true ?

          <Modal dialogClassName="commonwidth" show={getSampleAppModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotofitApp(false)}>
            <Modal.Header closeButton>
              <Modal.Title>

                {
                  TaskStatus === 3 && editProtoFitSmsApproval === false ?
                    "View " : editProtoFitSmsApproval === true ? 'Update ' : ''

                }
                {ParentTask}
                {
                  TaskStatus === 3 ?
                    <>
                      <span title='Edit Form' onClick={() => seteditProtoFitSmsApproval(true)}
                        className="btn btn-info btn-xs edit"
                        style={{
                          marginLeft: ParentTask === "Shipment Sample Approval" ? '620px' :
                            ParentTask === "Size Set Sample Approval" ? '620px'
                              : ParentTask === "Pre Production Sample Approval" ? '570px' : '800px'
                        }}>
                        <i className="fa fa-edit"></i>
                      </span>
                      &nbsp;
                      <span title='Delete Form'
                        className="btn btn-danger btn-xs delete" onClick={() => setdeleteProtoFitSmsApproval(true)}>
                        <i className="fa fa-trash-o"></i>
                      </span>
                    </> : ''
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProtoFitSMSApproval props={props}
                ProtoFitAprovalCallback={ProtoFitAprovalCallback}
                TNAId={TNAId}
                TNAProtoFitId={TNAProtoFitId}
                ChildTask={ChildTask}
                ParentTask={ParentTask}
                TaskStatus={TaskStatus}
                editProtoFitSmsApproval={editProtoFitSmsApproval}
                delteProtoFitSmsApproval={delteProtoFitSmsApproval}
              ></ProtoFitSMSApproval>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>

          </Modal> : ''
      }

      {getsID.showPopupDelete ? <SweetAlertPopup

        // data={''}
        data={
          getsID.Params
        }
        deleteCallback={DeleteCallback}
        showpopup={true}
        pageActionId={getsID.TNAManualClose}
        Msg={'Deleted Sucessfully'}
      /> : null}

    </div >
  )
}

export default ProtoFitViewApprovalTab