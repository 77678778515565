import React, { useState, useEffect, useMemo } from 'react';
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import { useDispatch } from 'react-redux';
import { LoadCourierDetails } from '../../actions/courier';
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { useSelector } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import SweetAlertPopup from '../Common/SweetAlertPopup';
import { CourierActionId } from "../Common/PageActionNumber";
import SearchRetain from "../Common/SearchRetain";
import Nodify from "../Common/ReactNotification";
import moment from "moment";
import { DateRangePicker } from 'react-date-range';
import { InsertUpdateCourierDetails, ViewCourierStatus } from "../../actions/courier";

const CourierList = (props) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    let isLoadingCourier = useSelector((state) => state.courierReducer.isLoadingCourier);
    let CourierStateList = useSelector((state) => state.courierReducer.CourierList);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getAWPNumberSearch, setAWPNumberSearch] = useState("");
    const [getAgencySearch, setAgencySearch] = useState("");
    const [getFromAddressSearch, setFromAddressSearch] = useState("");
    const [getToAddressSearch, setToAddressSearch] = useState("");
    const [getStatusSearch, setStatusSearch] = useState("");
    const [getID, setID] = useState(0);
    const [getPlaceholder] = useState("Track ID");
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const dispatch = useDispatch();

    var CourierSearchDate = new Date();
    const [getCourierSearchDateRange, setCourierSearchDateRange] = useState([
        {
            startDate: CourierSearchDate.setDate(CourierSearchDate.getDate() - 30),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Courier");
    }
    //End Enable Menu
    const pageRedirect = (CourierParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect == "Add") {
            props.history.push('/AddCourierTracker');
        }
        else if (redirect === 'View') {
            CourierParams.action = "View";
            props.history.push(`/AddCourierTracker`, { params: CourierParams });
        }
        else if (redirect === 'Edit') {
            CourierParams.action = "Edit";
            props.history.push(`/AddCourierTracker`, { params: CourierParams });
        }
        else if (redirect === 'CheckStatus') {
            dispatch(ViewCourierStatus(CourierParams.awpNumber)).then((res) => {
                if (res.data != undefined) {
                    CourierParams.status = res.data.shipments[0].status.status;
                    if (CourierParams.status === "delivered") {
                        CourierParams.Operation = 2;
                        dispatch(InsertUpdateCourierDetails(CourierParams)).then((res) => {
                            // if (res.data.outputResult === "2") {
                            //     dispatch(LoadCourierDetails()).then((res) => {

                            //     });
                            // }

                        });
                    }
                }
            });
        }
    };
    const DeleteCourier = (data) => {
        setID({ showPopupDelete: true, Params: data });
    }
    const Delcallback = () => {
        setID({ showPopupDelete: false, Params: [] });
    }
    useEffect(() => {
        dispatch(LoadCourierDetails()).then((res) => {

        });
    }, [])

    useEffect(() => {
        if (!isLoadingCourier) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingCourier]);
    useEffect(() => {
        const state1 = props.location.state;
        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Style details updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Style details added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }

    }, [dispatch]);
    const
        headers = [
            {
                name: "AWB Number",
                field: "awpNumber",
                sortable: true
            },
            {
                name: "Agency Name",
                field: "agencyName",
                sortable: true
            },
            {
                name: "From Address",
                field: "fromAddress",
                sortable: true
            },
            {
                name: "To Address",
                field: "toAddress",
                sortable: true
            },
            {
                name: "Sent On",
                field: "createdDate",
                sortable: false
            },
            {
                name: "Status",
                field: "status",
                sortable: true
            },
            {
                name: "Action",
                field: "",
                sortable: false,
                display: activeMenu && activeMenu[0].isEdit === 0 &&
                    activeMenu[0].isDelete === 0 &&
                    activeMenu[0].isView === 0 ? 'none' : ''
            },
        ];

    const CourierListData = useMemo(() => {
        let CourierData = CourierStateList;
        if (getAWPNumberSearch) {
            CourierData = CourierData.filter(courier =>
                courier.awpNumber.toLowerCase().includes(getAWPNumberSearch.toLowerCase())
            )
        }
        if (getAgencySearch) {
            CourierData = CourierData.filter(courier =>
                courier.agencyName.toLowerCase().includes(getAgencySearch.toLowerCase())
            )
        }
        if (getFromAddressSearch) {
            CourierData = CourierData.filter(courier =>
                courier.fromAddress.toLowerCase().includes(getFromAddressSearch.toLowerCase())
            )
        }
        if (getToAddressSearch) {
            CourierData = CourierData.filter(courier =>
                courier.toAddress.toLowerCase().includes(getToAddressSearch.toLowerCase())
            )
        }
        if (getStatusSearch) {
            CourierData = CourierData.filter(courier =>
                courier.status.toLowerCase().includes(getStatusSearch.toLowerCase())
            )
        }
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            CourierData = CourierData.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }
        setTotalItems(CourierData.length);
        let records = CourierData.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
        return CourierData.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [CourierStateList,
        getAWPNumberSearch,
        getAgencySearch,
        getFromAddressSearch,
        getToAddressSearch,
        getStatusSearch,
        sorting,
        currentPage,
        ITEMS_PER_PAGE,]);

    const clearDateSelect = () => {
        const emptychartDateRange = [
            {
                startDate: null,
                endDate: new Date(""),
                key: 'selection',
                isShow: false
            }];
        setCourierSearchDateRange(emptychartDateRange);
    }
    const handleDateSelect = (event, isShow) => {
        let SearchDate = [...getCourierSearchDateRange];
        SearchDate[0].isShow = isShow;
        setCourierSearchDateRange(SearchDate);
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className='widget'>
                    <div className="widget-header">
                        <span className="widget-caption">Covering Letter List & Courier Tracking Page</span>
                    </div>
                    <div className="widget-body">
                        <div className="dataTables_wrapper form-inline no-footer">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <div className="widget">
                                        <div className="widget-body no-padding">
                                            <div className="widget-main ">
                                                <div className="panel-group accordion" id="accordion">
                                                    <div className="panel panel-default">
                                                        <div className="panel-heading ">
                                                            <h4 className="panel-title">
                                                                <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                    Search Filter (Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span> )
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="collapseOne" className="panel-collapse collapse in">
                                                            <div className="panel-body border-red">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="col-md-3">
                                                                            <label>AWB Number</label>
                                                                            <span className="input-icon icon-right">
                                                                                <Search onSearch={
                                                                                    value => {
                                                                                        setAWPNumberSearch(value);
                                                                                    }
                                                                                }
                                                                                 className="form-control StyleList"
                                                                                    placeholder="AWB Number" props={props} />
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                             <label>Agency Name</label>
                                                                            <span className="input-icon icon-right">
                                                                                <Search onSearch={
                                                                                    value => {
                                                                                        setAgencySearch(value);
                                                                                    }
                                                                                }
                                                                                    placeholder="Agency Name" props={props} />
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                        <label>From Address</label>
                                                                            <span className="input-icon icon-right">
                                                                                <Search onSearch={
                                                                                    value => {
                                                                                        setFromAddressSearch(value);
                                                                                    }
                                                                                }
                                                                                    placeholder="From Address" props={props}/>
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                        <label>To Address</label>
                                                                            <span className="input-icon icon-right">
                                                                                <Search onSearch={
                                                                                    value => {
                                                                                        setToAddressSearch(value);
                                                                                    }
                                                                                }
                                                                                    placeholder="To Address" props={props} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row margin-top-20">
                                                                    <div className="col-md-12">
                                                                        <div className="col-md-3">
                                                                        <label>Status</label>
                                                                            <span className="input-icon icon-right">
                                                                                <Search onSearch={
                                                                                    value => {
                                                                                        setStatusSearch(value);
                                                                                    }
                                                                                }
                                                                                    placeholder="Status" props={props} />
                                                                            </span>
                                                                        </div>
                                                                        <div className='col-md-3'>
                                                                         <label>Date</label>
                                                                            <span className='input-icon icon-right mb-5'>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        onClick={event => handleDateSelect(event, !getCourierSearchDateRange[0].isShow)}
                                                                                        value={(getCourierSearchDateRange[0].startDate === null ? '' : moment(getCourierSearchDateRange[0].startDate).format('DD/MM/YYYY') + ' To ') + ((getCourierSearchDateRange[0].endDate === null || !moment(getCourierSearchDateRange[0].endDate).isValid()) ? '' : moment(getCourierSearchDateRange[0].endDate).format('DD/MM/YYYY'))}
                                                                                        type="text"
                                                                                        className={'form-control'}
                                                                                        placeholder="Select Date"
                                                                                    />
                                                                                    <i className="fa fa-times" onClick={clearDateSelect}></i>
                                                                                </span>
                                                                                <span className='input-icon icon-right  mb-5'>
                                                                                    <br />
                                                                                    {getCourierSearchDateRange[0].isShow && <DateRangePicker
                                                                                        onChange={item => setCourierSearchDateRange([item.selection])}
                                                                                        showSelectionPreview={true}
                                                                                        moveRangeOnFirstSelection={false}
                                                                                        months={2}
                                                                                        ranges={getCourierSearchDateRange}
                                                                                        showDateDisplay={false}
                                                                                        direction="vertical"
                                                                                        className={'ChartDateRangePicker'}
                                                                                    />}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    &nbsp;


                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="table-toolbar pull-right">
                                &nbsp;
                                <span
                                    style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? "" : 'none' }}
                                    className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)}>
                                    <i className="fa fa-plus"></i>&nbsp;Add
                                </span>
                            </div>

                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                <TableHeader headers={headers} onSorting={
                                    (field, order) => setSorting({ field, order })
                                } />
                                <tbody>
                                    {
                                        CourierListData.map(awp => (
                                            <>
                                                <tr>
                                                    <td>{awp.awpNumber}</td>
                                                    <td>{awp.agencyName}</td>
                                                    <td>{awp.fromAddress}</td>
                                                    <td>{awp.toAddress}</td>
                                                    <td>{moment(awp.createdDate).format('DD/MM/YYYY hh:mm A')}</td>
                                                    <td>
                                                        <span className={awp.status.toLowerCase() === 'in-transit' ? 'text-warning' :
                                                            awp.status.toLowerCase() === 'transit' ? 'text-info' :
                                                                awp.status.toLowerCase() === 'delivered' ? 'text-success' : 'text-danger'}>
                                                            <b>  {awp.status}</b>
                                                        </span>
                                                    </td>
                                                    <td style={{
                                                        display: activeMenu && activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 && activeMenu[0].isView === 0 ? 'none' : ''
                                                    }}>
                                                        < span title='View courier'
                                                            className="btn btn-secondary btn-xs edit"
                                                            style={{ display: activeMenu && activeMenu[0].isView === 1 ? "" : 'none' }}
                                                            onClick={() => pageRedirect(awp, 'View', getPlaceholder)}>
                                                            <i className="fa fa-eye "></i>
                                                        </span>&nbsp;

                                                        <span title='Edit courier'
                                                            style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? "" : 'none' }}
                                                            className="btn btn-success btn-xs edit" onClick={() => pageRedirect(awp, 'Edit', getPlaceholder)}>
                                                            <i className="fa fa-edit "></i>
                                                        </span>&nbsp;
                                                        <span title='Delete courier'
                                                            style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? "" : 'none' }}
                                                            className="btn btn-danger btn-xs edit" onClick={() => DeleteCourier(awp)}>
                                                            <i className="fa fa-trash "></i>
                                                        </span>&nbsp;
                                                        {awp.status !== "delivered" && < span title='Check Status'
                                                            className="btn btn-primary btn-xs edit"
                                                            style={{ display: activeMenu && activeMenu[0].isView === 1 ? "" : 'none' }}
                                                            onClick={() => pageRedirect(awp, 'CheckStatus', getPlaceholder)}>
                                                            <i className="fa fa-check-square-o "></i>
                                                        </span>}
                                                    </td>
                                                </tr>

                                            </>
                                        ))

                                    }
                                    {
                                        totalItems === 0 ?
                                            <tr>
                                                <td colSpan="7" className='norecordfound'><span>No Records Found</span></td>
                                            </tr> : ''
                                    }

                                </tbody>
                            </table>

                            <PageCount onPageCount={
                                value => {
                                    setITEMS_PER_PAGE(value);
                                    setCurrentPage(1);
                                }
                            } />

                            <Pagination total={totalItems}
                                itemsPerPage={
                                    parseInt(ITEMS_PER_PAGE)
                                }
                                currentPage={currentPage}
                                onPageChange={
                                    page => setCurrentPage(page)
                                } />
                        </div>
                    </div>
                </div>
                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={CourierActionId}
                        Msg={"Courier information deleted successfully."} /> : null
                }
            </div>
            {loader}
        </>
    )
}

export default CourierList;