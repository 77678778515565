import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadTNATaskOwner, getTNACompanyHolidayList, loadTNAChildReferenceUnMappedList, getTNAEditTaskWiseChildList, getSkuAndStyleDetails, loadTNASupplierEmployeeList } from "../../actions/tna";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import taskService from "../../services/Master/TaskService";
import Nodify from "../Common/ReactNotification";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { retrieveTask, loadTaskList, loadTaskHolderList } from "../../actions/task";
import { loadTaskTypeList } from "../../actions/taskType";
import { loadLeaveDaysList } from "../../actions/leaveDay";
import { loadRouteDependencyList } from "../../actions/route";
import DatePicker from "react-datepicker";
import moment from "moment";
import { loadTNADetailList, GetSubChildMultExFactoryFormSKUList, GetTestingMultExFactorySKUList } from "../../actions/tna";
import Lightbox from "react-image-lightbox";
import axios from "axios";
import $ from "jquery";
import SweetAlertPopup from "../Common/SweetAlertPopup";
import { TNAManualCloseParent, TNAManualCloseChildLibDip, TNAManualCloseChildStrikeOff, TNAManualCloseChildTrim, TNAManualCloseChildProtoFit, TNAManualCloseChildSms, TNAManualCloseChildExFactory, TNAManualCloseChildStrikeoffTest, TNAManualCloseChildTrimTest, FinalinspectionID, QAInspectionId, TestingMainlist } from "../Common/PageActionNumber";
import TrimSubmission from "./TrimSubmission";
import TrimApproval from "./TrimApproval";
import LabdibSubmission from "./LabdibSubmission";
import LabdibApproval from "./LabdibApproval";
import StrikeOffSubmission from "./StrikeOffSubmission";
import StrikeApproval from "./StrikeApproval";
import ProtoFitSMSSubmission from "./ProtoFitSMSSubmission";
import ProtoFitSMSApproval from "./ProtoFitSMSApproval";
import AfterWashList from "./AppearanceAfterWash";
//import TnaService from "../../services/TNA/TNAService";
//import ProgramService from "../../services/Style/ProgramService";
import ReactNotification from 'react-notifications-component';
//import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
//import FabricService from "../../services/Master/FabricService";
//import TnaService from "../../services/TNA/TNAService";

import TnaService from "../../services/TNA/TNAService";
import TNAService from "../../services/TNA/TNAService";
import StyleService from "../../services/Style/styleService";
import CommonService from '../../services/Common/CommonService';
import CommonStyle from "../Style/CommonStyle";
import { Redirect } from 'react-router-dom';

import AddInspection from "./../Production/Inspection_Quality Control/InspectionQC/AddInspection";
import ReInspection from "./../Production/Inspection_Quality Control/InspectionQC/ReInspection";
import FinalinspectionService from "../../services/FinalInspection/FinalinspectionService";
import NewTesting from "../Production/Inspection_Quality Control/InspectionQC/NewTesting";
import ReTesting from "../Production/Inspection_Quality Control/InspectionQC/ReTesting";
import QcInspection from "../../services/QCInspection/QcInspection";
import QAReInspection from "../Production/Inspection_Quality Control/InspectionQC/QAReinspection";
import AddQCInspection from "../Production/Inspection_Quality Control/InspectionQC/AddQCinspection";
import ConfirmationPopup from "../Common/ConfirmationPopup";
import TestingPopup from '../Production/Inspection_Quality Control/InspectionQC/TestingPopupList'
const EditTNAView = (props) => {
    ;
    //reducer
    const reducerState = useSelector((state) => state);
    const AllRouteInfo = useSelector((state) => state.tna.TNADetailList);
    const getTaskTypeList = useSelector((state) => state.taskType.drpTaskTypeMapList);
    const { user: currentUser } = useSelector((state) => state.auth);
    const getLeaveDaysList = useSelector((state) => state.leaveDay.drpLeaveDaysList);
    const ExistingList = useSelector((state) => state.task.taskList);
    const getAllTaskList = useSelector((state) => state.task.drpAllTaskList);
    const getDependencyList = useSelector((state) => state.route.routeDependencyList);
    const taskHolderList = useSelector((state) => state.task.drpTaskHolderList);
    const drpTNATaskOwnerList = useSelector((state) => state.tna.drpTNATaskOwnerList);
    const getCompanyHolidayList = useSelector((state) => state.tna.TNACompanyHolidayList);
    const isLoadingTNA = useSelector((state) => state.tna.isLoadingTNA);
    const getTNAStatusList = useSelector((state) => state.tna.TNAStatusList);
    const TNAMultiTask = useSelector((state) => state.tna.TNAMultiTask);
    const StaticTask = useSelector((state) => state.tna.StaticTask);
    const [getTestingTaskInfo, setTestingTaskInfo] = useState([{
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    }]);
    const [getIDQATask, setIDQATask] = useState({ showPopupDelete: false, Params: {} });
    const [getQCfinalinspectionMulti, setQCfinalinspectionMulti] = useState([]);
    const [getTestingReportPageView, setTestingReportPageView] = useState('AddInspection');
    const [getTrimmodalpopup, setTrimmodalpopup] = useState(false);
    const [getTrimApprovalpopup, setTrimApprovalpopup] = useState(false);
    const [getLapmodal, setLapmodal] = useState(false);
    const [getOpenLabdipApppopup, setOpenLabdipApppopup] = useState(false);
    const [strikeoffModalpopup, setStrikeOffmodalpopup] = useState(false);
    const [selectall, setselectall] = useState(false);
    const [submitdate, setdates] = useState(false)
    const [getstrikeoffApppopup, setStrikeOffApppopup] = useState(false);
    const [getSampleSubModalpopup, setSampleSubModalpopup] = useState(false);
    const [getSampleAppModalpopup, setSampleAppModalpopup] = useState(false);
    const [getApperancemodalpopup, setApperancemodalpopup] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [getskusingleID, setskusingleID] = useState();
    //const [Buttonvisible, SetButtonvisible] = useState();
    const [getQCInspectionInfo, setQCInspectionInfo] = useState([{
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    }]);
    if (props.location.state === undefined) {

        let storedChildTask = localStorage.getItem('childTask');

        storedChildTask = JSON.parse(storedChildTask);
        // Update the value from props
        storedChildTask.tnaid = storedChildTask.TNAId;
        storedChildTask.styleId = storedChildTask.StyleID;
        props.location.state = { params: storedChildTask };

        console.log(props)
    }



    //storedChildTask.TNAId 
    //QA
    const [getnewQAReportlist, setnewQAReportlist] = useState([]);
    const [getQAheaderData, setQAheaderdata] = useState([]);
    function QCInspectionCallback(value) {
        setQCfinalinspectionMulti(value);
    }

    const [isLastEdit, setLastEdit] = useState(props.Action !== undefined ? props.props.location.state.params.creationStatus === 1 ? false : true
        : props.location.state.params.creationStatus === 1 ? false : true);
    const getTNABrandID = props.Action !== undefined ? props.props.location.state.params.brandID : props.location.state.params.brandID;
    const getTNASupplierID = props.Action !== undefined ? props.props.location.state.params.supplierId : props.location.state.params.supplierId;
    const getTNABuyerID = props.location.state.params.buyerID;
    const getTNASeasonID = props.location.state.params.seasonID;
    //let Buttonvisible = props.location.state.params.action;
    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }
    //for disable
    const [Labdipdisable, setLabdipdisable] = useState(false);
    const [Strikeoffdisable, setStrikeoffdisable] = useState(false);
    const [Trimdisable, setTrimdisable] = useState(false);
    const [protofitsmsDisable, setprotofitsmsDisable] = useState(false);
    const [MultiCloseDatas, setMultiCloseDatas] = useState([]);
    const [getDependencyStartList, setDependencyStartList] = useState([]);
    const [submitDependencyStart, setSubmitDependencyStart] = useState(false);

    const [isSkuAndStyleDetailPopup, setSkuAndStyleDetailPopup] = useState(false);
    const [SkuAndStyleDetails, setSkuAndStyleDetails] = useState([]);
    const [getnewfinalReportlist, setnewfinalReportlist] = useState([]);
    const [getnewheaderdata, setnewheaderdata] = useState([]);
    const [getAddQCInspectionListParam, setQCAddInspectionListParam] = useState([]);
    const [getnewTestingReportlist, setnewTestingReportlist] = useState([]);
    const [getTestingheaderData, setTestingheaderdata] = useState([]);
    const [getTestinginspectionMulti, setTestinginspectionMulti] = useState([]);
    const [getQAInspectionPageView, setQAInspectionPageView] = useState('AddInspection');
    //stylebasedheader
    const [getStyleInfo, setStyleInfo] = useState([]);
    const [getIDTestTask, setIDTestTask] = useState({ showPopupDelete: false, Params: {} });
    //State
    const [MainFields, setMainFields] = useState([{
        TNAId: props.Action !== undefined ? props.props.location.state.params.tnaid : props.location.state.params.tnaid,
        CreationStatus: props.Action !== undefined ? props.props.location.state.params.creationStatus === 2 ? 3 : 2 :
            props.location.state.params.creationStatus === 2 ? 3 : 2,
        TNARouteApplyDetails: [],
        TNADependencyDetails: [],
        TNAAdditionalTaskDetails: [],
        TNAChildInformation: [],
        TNASubChildInformation: [],
        TNADocs: [],
        TNACommentsInformation: [],
        CreatedBy: currentUser.employeeinformationID,
    }]);

    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;

    const EmptyInputFields = [];
    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getTaskHolderList, setTaskHolderList] = useState([]);
    const [getHeaderName, addHeaderName] = useState("Edit");
    // const [101AllRouteInfo, setAllRouteInfo] = useState([]);


    //gowtham
    const [getComment, setComment] = useState(false);
    const [getmaincomment, setmaincomment] = useState(false);
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    const [getFiles, setFiles] = useState([]);
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [getImagename, setImagename] = useState();
    const [getTaskIndexFileupload, setTaskIndexFileupload] = useState();
    const [getTNAtaskIdFileupload, setTNAtaskIdFileupload] = useState();
    const [getCurrentChildTask, setCurrentChildTask] = useState({ value: 0, lablel: '' });
    const [ParentTask, setParentTask] = useState('');
    const [ChildTask, setChildTask] = useState('');
    const [manualApprovalData, setmanualApprovalData] = useState({ manualCloseComment: '', files: [] });
    const [MultiManualCloseFeilds, setMultiManualCloseFeilds] = useState({ IdList: '', TaskStatus: 0, ManualCloseDate: '', ManualCloseComment: '', Createdby: 1 });
    const [getID, setID] = useState(0);
    const [getsID, setsID] = useState(0);
    const [existingApprovalData, setexistingApprovalData] = useState([]);
    const [getSubRevicedetails, setSubRevicedetails] = useState([]);
    const [getSubReviceList, setSubReviceList] = useState([]);
    const [Submitrevice, setSubmitRevice] = useState(false);
    const [ReviceComment, setReviceComment] = useState('');
    const [getReviceAction, setReviceAction] = useState('Add');
    const [getSubRescheduleList, setSubRescheduleList] = useState([]);
    const [RescheduleComment, setRescheduleComment] = useState('');
    const [Submitreschedule, setSubmitReschedule] = useState(false);
    const [getRescheduleAction, setRescheduleAction] = useState('Add');
    const [TaskStatus, setTaskStatus] = useState();
    const [LabdipApprovalList, setLabdipApprovalList] = useState([]);
    const [SwatchStatus, setSwatchStatus] = useState();


    const [getFinalInspectionPageView, setFinalInspectionPageView] = useState('AddInspection');
    const [getAddInspectionListParam, setAddInspectionListParam] = useState([]);
    const [getIsAddFinalInspection, setIsAddFinalInspection] = useState(false);
    const [getfinalinspectionMulti, setfinalinspectionMulti] = useState([]);

    const [getInspectionInfo, setInspectionInfo] = useState([{
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    }]);

    const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    const [MultExFactorySKUList, setMultExFactorySKUList] = useState([]);
    const [getid, setid] = useState({ showPopupConfirm: false, Params: {} });
    let IsParentDateShow = false;

    //Enable Menu

    let activeMenu;
    let activeMenuSub;
    let TaskCloseCompleteInfo;
    let CommentsInfo;
    let AttachmentInfo;
    let StartStopInfo;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined && menulistSub.length > 0) {
        activeMenu = menulist.filter(i => i.menuName === 'TNA');
        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);

            if (activeMenuSub.length > 0) {

                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === 'TNACloseComplate') {
                        TaskCloseCompleteInfo = activeMenuSub[index];
                    }
                    else if (activeMenuSub[index].menuName === 'TNAComment') {
                        CommentsInfo = activeMenuSub[index];

                    }
                    else if (activeMenuSub[index].menuName === 'TNAAttachment') {
                        AttachmentInfo = activeMenuSub[index];

                    }
                    else if (activeMenuSub[index].menuName === 'TNAstartstop') {
                        StartStopInfo = activeMenuSub[index];
                    }

                    // else if (activeMenuSub[index].menuName === 'StyleMeasurement') {
                    //     //MeasurementInfo = activeMenuSub[index];

                    // }
                    // else if (activeMenuSub[index].menuName === 'StyleSample') {
                    //    // SampleInfo = activeMenuSub[index];

                    // }
                    // else if (activeMenuSub[index].menuName === 'StyleTNAView') {
                    //     //TNAViewInfo = activeMenuSub[index];

                    // }




                }
            }
        }
    }

    // End Enable Menu
    // useEffect(() => {
    //     showLoader();
    //     if (props.Action === undefined) {
    //         dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
    //         setAllRouteInfo(AllData);
    //         LoadTNAData(AllData);
    //         hideLoader();
    //     }
    //     else {
    //         var styleID = props.Action !== undefined ? props.props.location.state.params.styleID : props.location.state.params.styleId;
    //         if (props.Action !== undefined) {
    //             TnaService.StylePurchaseOrderTNAList(styleID, 0).then((response) => {
    //                 if (response.data) {
    //                     setAllRouteInfo(response.data);
    //                     LoadTNAData(response.data);
    //                     hideLoader();
    //                 }
    //             })
    //         }
    //         else {
    //             setAllRouteInfo(AllData);
    //             LoadTNAData(AllData);
    //             hideLoader();
    //         }
    //     }

    // }, []);

    // useEffect(() => {
    //     // var styleID = props.Action !== undefined ? props.props.location.state.params.styleID : props.location.state.params.styleId;
    //     // if (props.Action !== undefined) {
    //     //     TnaService.StylePurchaseOrderTNAList(styleID, 0).then((response) => {
    //     //         if (response.data) {
    //     //             setAllRouteInfo(response.data);
    //     //             LoadTNAData(response.data);
    //     //             hideLoader();
    //     //         }
    //     //     })
    //     // }
    //     // else {
    //     //     setAllRouteInfo(AllData);
    //     //     LoadTNAData(AllData);
    //     //     hideLoader();
    //     // }
    // }, []
    // )

    const [editLabdipSubmission, seteditLabdipSubmission] = useState(false);
    const [deleteLabdipSubmission, setdeleteLabdipSubmission] = useState(false);

    const [editLabdipApproval, seteditLabdipApproval] = useState(false);
    const [deleteLabdipApproval, setdeleteLabdipApproval] = useState(false);
    const [getTestingTaskListParam, setTestingTaskListParam] = useState([]);
    const [editStrikeOffSubmission, seteditStrikeOffSubmission] = useState(false);
    const [delteStrikeOffSubmission, setdeleteStrikeOffSubmission] = useState(false);

    const [editStrikeOffApproval, seteditStrikeOffApproval] = useState(false);
    const [delteStrikeOffApproval, setdeleteStrikeOffApproval] = useState(false);

    const [editTrimSubmission, seteditTrimSubmission] = useState(false);
    const [delteTrimSubmission, setdeleteTrimSubmission] = useState(false);

    const [editTrimApproval, seteditTrimApproval] = useState(false);
    const [deleteTrimApproval, setdeleteTrimApproval] = useState(false);

    const [editProtoFitSmsSubmission, seteditProtoFitSmsSubmission] = useState(false);
    const [delteProtoFitSmsSubmission, setdeleteProtoFitSmsSubmission] = useState(false);

    const [editProtoFitSmsApproval, seteditProtoFitSmsApproval] = useState(false);
    const [delteProtoFitSmsApproval, setdeleteProtoFitSmsApproval] = useState(false);


    const [editAppearanceAfterWash, seteditAppearanceAfterWash] = useState(false);
    const [deleteAppearanceAfterWash, setdeleteAppearanceAfterWash] = useState(false);

    const [LabdipswatchDownload, setLabdipswatchDownload] = useState(false);
    const [LabdipswatchUpload, setLabdipswatchUpload] = useState(false);
    const [StrikeoffswatchDownload, setStrikeoffswatchDownload] = useState(false);
    const [StrikeoffswatchUpload, setStrikeoffswatchUpload] = useState(false);
    const [TrimswatchDownload, setTrimswatchDownload] = useState(false);
    const [TrimswatchUpload, setTrimswatchUpload] = useState(false);

    const [AllDataLoaded, setAllDataLoaded] = useState(false);
    const [DepandencyAllIndex, setDepandencyAllIndex] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0, RowIndex: 0, MainChildId: 0 });

    const [OrderInfoMulticloseMainDetails, setOrderInfoMulticloseMainDetails] = useState([]);
    const [expandedRowsMultiSelect, setExpandedRowsMultiSelect] = useState([]);
    const [expandListMultiSelect, setexpandListMultiSelect] = useState({});

    const [expandStateMultiSelect, setExpandStateMultiSelect] = useState({});
    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 190),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);


    const CustomInputDependency = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ width: "100%", border: (!submitDependencyStart) || props.value ? '' : '1px solid red' }}
            />
        )

    }
    useEffect(() => {
        StyleService.GetStyleList(props.location.state.params.styleId, IsProduction, 0, '01/01/1949', moment(stateDate[0].endDate).format('MM/DD/YYYY')).then((response) => {
            if (response.data) {
                setStyleInfo(response.data.item1[0]);
                setInspectionInfo({
                    buyerID: response.data.item1[0].buyerID
                    , buyerName: response.data.item1[0].buyerName
                    , brandID: response.data.item1[0].brandID
                    , brandName: response.data.item1[0].brandName
                    , seasonName: response.data.item1[0].seasonName
                    , seasonID: response.data.item1[0].seasonID
                    , supplierID: response.data.item1[0].supplierID
                    , supplierName: props.location.state.params.supplierName
                    , styleID: props.location.state.params.styleId
                    , styleName: props.location.state.params.styleNames
                    , type: 0
                    , TaskStatus: 0
                });
                setQCInspectionInfo({
                    buyerID: response.data.item1[0].buyerID
                    , buyerName: response.data.item1[0].buyerName
                    , brandID: response.data.item1[0].brandID
                    , brandName: response.data.item1[0].brandName
                    , seasonName: response.data.item1[0].seasonName
                    , seasonID: response.data.item1[0].seasonID
                    , supplierID: response.data.item1[0].supplierID
                    , supplierName: props.location.state.params.supplierName
                    , styleID: props.location.state.params.styleId
                    , styleName: props.location.state.params.styleNames
                    , styleNo: response.data.item1[0].styleNo
                    , type: 0
                    , TaskStatus: 0
                })
                setTestingTaskInfo({
                    buyerID: response.data.item1[0].buyerID
                    , buyerName: response.data.item1[0].buyerName
                    , brandID: response.data.item1[0].brandID
                    , brandName: response.data.item1[0].brandName
                    , seasonName: response.data.item1[0].seasonName
                    , seasonID: response.data.item1[0].seasonID
                    //, supplierID: response.data.item1[0].supplierID
                    , supplierName: props.location.state.params.supplierName
                    , styleID: props.location.state.params.styleId
                    , styleName: props.location.state.params.styleNames
                    , styleNo: response.data.item1[0].styleNo
                    , type: 0
                    , TaskStatus: 0
                })
            }
        }).catch(() => { });
    }, []);
    useEffect(() => {
        showLoader();
        dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
    }, []);

    useMemo(() => {
        if (AllRouteInfo.tnaDetailList !== undefined) {

            let routeInfo = AllRouteInfo.tnaDetailList;
            let dependentInfo = AllRouteInfo.tnaDetailDependencyList;
            let additionalInfo = AllRouteInfo.tnaDetailTaskList;
            let parentChildList = AllRouteInfo.tnaParentChildList;
            let childList = AllRouteInfo.tnaChildList;
            let Comments = AllRouteInfo.tnaCommentsList;
            let Attachments = AllRouteInfo.tnaAttachmentsList;
            let ChildDepandencyInfo = AllRouteInfo.tnaChildDependencyDetails;

            let routeInformation = [];
            let ChildIndex = 1;
            if (AllRouteInfo !== undefined) {
                showLoader();
                if (routeInfo !== undefined) {
                    if (routeInfo.length > 0) {
                        //setInputFields([]);

                        routeInfo.map((route, i) => {

                            let employee1 = "";
                            let employee2 = "";
                            let employee3 = "";

                            if (route.taskHolderName !== null && route.taskHolderName !== undefined) {
                                let TaskHolderName = route.taskHolderName.split('-');
                                if (TaskHolderName[0] !== undefined) {
                                    employee1 = TaskHolderName[0].trim();

                                    if (TaskHolderName[1] !== undefined) {
                                        let FollowerName = TaskHolderName[1].split(',');
                                        if (FollowerName[0] !== undefined) {
                                            employee2 = FollowerName[0].trim();

                                            if (FollowerName[1] !== undefined) {
                                                employee3 = FollowerName[1].trim();
                                            } else {
                                                employee3 = "";
                                            }
                                        } else {
                                            employee2 = "";
                                            employee3 = "";
                                        }

                                    } else {
                                        employee2 = "";
                                        employee3 = "";
                                    }
                                }
                            }


                            // let StartDate = new Date(route.planStartDate);
                            // StartDate.setMinutes(StartDate.getMinutes() + 370);
                            // let EndDate = new Date(route.endDate);
                            // EndDate.setMinutes(EndDate.getMinutes() + 370);
                            let ImageUrl = "";

                            if (route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                                ImageUrl = 'Images/Style/Strikeoff/';
                            } else if (route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || route.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER || route.taskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                                ImageUrl = 'Images/Style/Trim/';
                            }


                            let EndDate = route.endDate;
                            let ActualEndDate = route.actualEndDate;

                            let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === route.taskName.toLowerCase());
                            if (route.detailTypeID === 4) { // Ex-Factory  (Detail Type)
                                IsSingleTask = false;
                            }
                            routeInformation.push({
                                TNARouteApplyID: route.tnaRouteApplyID,
                                TaskFieldEnable: 0,
                                TaskType: route.taskType,
                                TaskID: route.taskNameID,
                                strTaskName: route.taskName,
                                EndDate: new Date(route.endDate),
                                StrEndDate: EndDate,
                                StartDate: new Date(route.planStartDate),
                                StrStartDate: route.planStartDate,
                                Duration: route.duration,
                                intDependency: parseInt(route.dependencyStartstop),
                                arrDependency: [],
                                arrComment: [],
                                arrAttachment: [],
                                arrManual: [],
                                ManualComments: route.manualCloseComment,
                                //ManualCloseDate: route.ManualCloseDate,
                                CommentsCount: route.taskCommentsCount,
                                AttachmentCount: route.taskDocumentsCount,
                                TaskHolderList: '',
                                TaskHolderOrFollower: route.taskHolderID,
                                TaskHolderOwnerTxtList: route.taskOwnerNames,
                                TaskHolderIDList: route.taskHolderIDList,
                                FollowerIDList1: route.followerIDList1,
                                FollowerIDList2: route.followerIDList2,
                                TaskHolderBuyer: route.taskHolderIDList,
                                FollowerCube: route.followerIDList1,
                                FollowerSupplier: route.followerIDList2,
                                TaskHolderOwnerIdList: route.taskOwner,
                                titleEmployee1: employee1,
                                titleEmployee2: employee2,
                                titleEmployee3: employee3,
                                LeaveDays: route.leaveDays,
                                IsTHFirstTime: 0,
                                LeaveDaysTxt: route.leaveDayName,
                                AdditionalFollowup: additionalInfo.filter(x => x.indexName === route.indexName && x.isTaskSelected === 1).length,
                                IsFollowupExist: additionalInfo.filter(x => x.indexName === route.indexName).length,
                                DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
                                IndexName: route.indexName,
                                //PreferenceId: route.preferenceId,
                                IsPopupOpen: 0,
                                IsDependencyOpen: 0,
                                IsTHLeaveDaysOpen: 0,
                                IsComment: 0,
                                IsAttachment: 0,
                                IsSelectAll: route.isSelectAll,
                                FollowupDetails: [],
                                SelectedDependency: route.dependency,
                                arrTaskHolderBuyer: [],
                                arrFollowerCube: [],
                                arrFollowerSupplier: [],
                                TaskHolderBuyerTxt: '',
                                FollowerCubeTxt: '',
                                FollowerSupplierTxt: '',
                                IsSingleTask: IsSingleTask,
                                IsNewRow: true,
                                StatusID: route.routeApplyStatus === 0 ? 1 : route.routeApplyStatus,
                                // StatusName: Statusname,
                                AWBStatus: route.awbStatus,
                                ActualEndDate: route.actualEndDate,
                                StrActualEndDate: ActualEndDate,
                                ManualCloseDate: route.actualEndDate,
                                SubTaskList: [],
                                TaskTypeName: route.taskTypeName,
                                Dependency: route.dependency,
                                IDuration: route.duration,
                                IEndDate: route.endDate,
                                IStartDate: route.planStartDate,
                                RevicePopup: 0,
                                IsReviced: route.isReviced,
                                DependencyDate: route.dependencyDate,
                                DependencyComment: route.dependencyComment,
                                IsRescheduled: route.isRescheduled,
                                IsDependencyStartPopup: false,
                                ReviceSelected: 0,
                                RescheduleSelected: 0,
                                ReschedulePopup: 0,
                                MainRevicedetails:
                                {
                                    TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                                    ReviceComment: '', TaskName: '', Operation: 0,
                                    StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                    Createdby: 0, Type: ''
                                },
                                MainRescheduledetails:
                                {
                                    TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                                    RescheduleComment: '', TaskName: '', Operation: 0,
                                    StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                    Createdby: 0, Type: ''
                                },
                                ReviceAdded: 0,
                                RescheduleAdded: 0,
                                DetailTypeID: route.detailTypeID,

                            });

                            let ParentActualdate = false;
                            if (IsSingleTask === false) {
                                parentChildList && parentChildList.filter(d => d.taskID === route.taskNameID).map((element, p) => {
                                    routeInformation[i].SubTaskList.push({
                                        ParentId: element.parentId,
                                        TaskID: element.taskID,
                                        MainId: element.mainId,
                                        Code: element.code,
                                        Color: element.color,
                                        ColorOrName: element.colorOrName,
                                        ImagePath: element.imagePath,
                                        ImageUrl: ImageUrl,
                                        StrikeOffOrTrimInfoId: element.strikeOffIdOrTrimInfoId,
                                        IsExpanded: false,
                                        // ParentIndex: p,
                                        ChildTaskList: []
                                    })


                                    if (route.detailTypeID === 4) {
                                        childList && childList.filter(d => d.taskID === route.taskNameID && d.mainId === element.mainId)
                                            .sort((a, b) => new Date(a.exFacDate) - new Date(b.exFacDate))
                                            .map((child, c) => {

                                                let EndDate = child.endDate;
                                                let ActualEndDate = child.actualEndDate;

                                                routeInformation[i].SubTaskList[p].ChildTaskList.push({
                                                    ChildId: child.childId,
                                                    SubId: child.subId,
                                                    Name: child.name,
                                                    TaskName: route.taskName,
                                                    TaskID: child.taskID,
                                                    MainId: child.mainId,
                                                    StrEndDate: EndDate,
                                                    StrStartDate: route.planStartDate,
                                                    Duration: isLastEdit ? child.duration : route.duration,
                                                    EndDate: isLastEdit ? new Date(child.endDate) : new Date(route.endDate),
                                                    StartDate: isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate),
                                                    TaskHolderList: '',
                                                    TaskHolderOrFollower: route.taskHolderID,
                                                    TaskHolderOwnerTxtList: route.taskOwnerNames,
                                                    TaskHolderIDList: route.taskHolderIDList,
                                                    FollowerIDList1: route.followerIDList1,
                                                    FollowerIDList2: route.followerIDList2,
                                                    TaskHolderBuyer: route.taskHolderIDList,
                                                    FollowerCube: route.followerIDList1,
                                                    FollowerSupplier: route.followerIDList2,
                                                    arrTaskHolderBuyer: [],
                                                    arrFollowerCube: [],
                                                    arrFollowerSupplier: [],
                                                    TaskHolderBuyerTxt: '',
                                                    FollowerCubeTxt: '',
                                                    FollowerSupplierTxt: '',
                                                    TaskHolderOwnerIdList: '',
                                                    titleEmployee1: employee1,
                                                    titleEmployee2: employee2,
                                                    titleEmployee3: employee3,
                                                    IsTHFirstTime: 0,
                                                    LeaveDays: '',
                                                    LeaveDaysTxt: route.leaveDayName,
                                                    StatusID: isLastEdit ? child.routeApplyStatus : 1,
                                                    AWBStatus: '',
                                                    IsAddChildTaskOpen: 0,
                                                    StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
                                                    drpChildTaskList: [],
                                                    IsManualClose: 0,
                                                    ManualCloseComment: child.manualCloseComment,
                                                    StrActualEndDate: ActualEndDate,
                                                    IsShowSubmitButton: child.isShowSubmitButton,
                                                    IsShowApprovalButton: child.isShowApprovalButton,
                                                    ManualCloseTaskwise: child.ManualClose,
                                                    ChildActualEndDate: child.actualEndDate,
                                                    ManualCloseDate: child.actualEndDate === null || child.actualEndDate === "" ? '' : new Date(child.actualEndDate),
                                                    RevicePopup: 0,
                                                    IsReviced: child.isReviced,
                                                    intDependency: child.dependencyStartstop,
                                                    DependencyDate: child.dependencyDate,
                                                    DependencyComment: child.dependencyComment,
                                                    IsRescheduled: child.isRescheduled,
                                                    IsDependencyStartPopup: false,
                                                    IDuration: isLastEdit ? child.duration : route.duration,
                                                    IEndDate: isLastEdit ? new Date(child.endDate) : new Date(route.endDate),
                                                    IStartDate: isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate),
                                                    ReviceAdded: 0,
                                                    RescheduleAdded: 0,
                                                    ReviceSelected: 0,
                                                    RescheduleSelected: 0,
                                                    ReschedulePopup: 0,
                                                    SubRevicedetails:
                                                    {
                                                        TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                                                        ReviceComment: '', TaskName: '', Operation: 0,
                                                        StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                                        Createdby: 0, Type: ''
                                                    },
                                                    SubRescheduledetails:
                                                    {
                                                        TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                                                        RescheduleComment: '', TaskName: '', Operation: 0,
                                                        StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                                        Createdby: 0, Type: ''
                                                    },
                                                    SwatchStatus: child.swatchStatus,
                                                    SKUIdList: child.subId.toString(),
                                                    StyleStrikeOffGarmentSizeIdList: child.styleStrikeOffGarmentSizeIdList,
                                                    PurchaseOrderSkuID: child.purchaseOrderSkuID,
                                                    ChildIndex: ChildIndex,
                                                    arrDependency: [
                                                    ],
                                                    DependencyCount: child.depandencyCount,
                                                    SelectedDependency: child.selectedDependency === null ? '' : child.selectedDependency,
                                                    AssignedDepandency: child.assignedDepandency === null ? '' : child.assignedDepandency,
                                                    hasSKUNotMatch: false,
                                                    IsDepandency: 0,
                                                    ExFacDate: child.exFacDate,
                                                    TotalHoliday: '',
                                                    Isdraft: child.isDraft,
                                                    IsQAdraft: child.isQAdraft,
                                                    IsTestDraft: child.isTestDraft,
                                                    IsStrikedraft: child.isStrikedraft,
                                                    IsTrimDraft: child.isTrimDraft
                                                });

                                                ChildDepandencyInfo && ChildDepandencyInfo.filter(x => x.mainChildId === child.childId).map((item) => {
                                                    routeInformation[i].SubTaskList[p].ChildTaskList[c].arrDependency.push({
                                                        TNAChildDependencyID: item.tnaChildDependencyID,
                                                        DependentTaskId: item.dependentTaskId,
                                                        DependencyID: item.dependencyID,
                                                        LogDays: item.logDays,
                                                        IndexName: item.indexName,
                                                        Mainindex: item.mainChildIndex,
                                                        StartDate: new Date(item.startDate),
                                                        EndDate: new Date(item.endDate)

                                                    })
                                                })


                                                ChildIndex++;

                                                if (!ParentActualdate && child.actualEndDate === null) {
                                                    ParentActualdate = true;
                                                    // routeInformation[i].ActualEndDate = null;
                                                    routeInformation[i].StrActualEndDate = null;

                                                }

                                                if (!ParentActualdate) {
                                                    // routeInformation[i].ActualEndDate = child.actualEndDate;
                                                    routeInformation[i].StrActualEndDate = child.actualEndDate;
                                                }
                                            });
                                    }
                                    else {
                                        childList && childList.filter(d => d.taskID === route.taskNameID && d.mainId === element.mainId)
                                            .map((child, c) => {

                                                let EndDate = child.endDate;
                                                let ActualEndDate = child.actualEndDate;

                                                routeInformation[i].SubTaskList[p].ChildTaskList.push({
                                                    ChildId: child.childId,
                                                    SubId: child.subId,
                                                    Name: child.name,
                                                    TaskName: route.taskName,
                                                    TaskID: child.taskID,
                                                    MainId: child.mainId,
                                                    StrEndDate: EndDate,
                                                    StrStartDate: route.planStartDate,
                                                    Duration: isLastEdit ? child.duration : route.duration,
                                                    EndDate: isLastEdit ? new Date(child.endDate) : new Date(route.endDate),
                                                    StartDate: isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate),
                                                    TaskHolderList: '',
                                                    TaskHolderOrFollower: route.taskHolderID,
                                                    TaskHolderOwnerTxtList: route.taskOwnerNames,
                                                    TaskHolderIDList: route.taskHolderIDList,
                                                    FollowerIDList1: route.followerIDList1,
                                                    FollowerIDList2: route.followerIDList2,
                                                    TaskHolderBuyer: route.taskHolderIDList,
                                                    FollowerCube: route.followerIDList1,
                                                    FollowerSupplier: route.followerIDList2,
                                                    arrTaskHolderBuyer: [],
                                                    arrFollowerCube: [],
                                                    arrFollowerSupplier: [],
                                                    TaskHolderBuyerTxt: '',
                                                    FollowerCubeTxt: '',
                                                    FollowerSupplierTxt: '',
                                                    TaskHolderOwnerIdList: '',
                                                    titleEmployee1: employee1,
                                                    titleEmployee2: employee2,
                                                    titleEmployee3: employee3,
                                                    IsTHFirstTime: 0,
                                                    LeaveDays: '',
                                                    LeaveDaysTxt: route.leaveDayName,
                                                    StatusID: isLastEdit ? child.routeApplyStatus : 1,
                                                    AWBStatus: '',
                                                    IsAddChildTaskOpen: 0,
                                                    StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
                                                    drpChildTaskList: [],
                                                    IsManualClose: 0,
                                                    ManualCloseComment: child.manualCloseComment,
                                                    StrActualEndDate: ActualEndDate,
                                                    IsShowSubmitButton: child.isShowSubmitButton,
                                                    IsShowApprovalButton: child.isShowApprovalButton,
                                                    ManualCloseTaskwise: child.ManualClose,
                                                    ChildActualEndDate: child.actualEndDate,
                                                    ManualCloseDate: child.actualEndDate === null || child.actualEndDate === "" ? '' : new Date(child.actualEndDate),
                                                    RevicePopup: 0,
                                                    IsReviced: child.isReviced,
                                                    intDependency: child.dependencyStartstop,
                                                    DependencyDate: child.dependencyDate,
                                                    DependencyComment: child.dependencyComment,
                                                    IsRescheduled: child.isRescheduled,
                                                    IsDependencyStartPopup: false,
                                                    IDuration: isLastEdit ? child.duration : route.duration,
                                                    IEndDate: isLastEdit ? new Date(child.endDate) : new Date(route.endDate),
                                                    IStartDate: isLastEdit ? new Date(child.planStartDate) : new Date(route.planStartDate),
                                                    ReviceAdded: 0,
                                                    RescheduleAdded: 0,
                                                    ReviceSelected: 0,
                                                    RescheduleSelected: 0,
                                                    ReschedulePopup: 0,
                                                    SubRevicedetails:
                                                    {
                                                        TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                                                        ReviceComment: '', TaskName: '', Operation: 0,
                                                        StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                                        Createdby: 0, Type: ''
                                                    },
                                                    SubRescheduledetails:
                                                    {
                                                        TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                                                        RescheduleComment: '', TaskName: '', Operation: 0,
                                                        StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                                                        Createdby: 0, Type: ''
                                                    },
                                                    SwatchStatus: child.swatchStatus,
                                                    SKUIdList: child.skuIdList,
                                                    StyleStrikeOffGarmentSizeIdList: child.styleStrikeOffGarmentSizeIdList,
                                                    PurchaseOrderSkuID: child.purchaseOrderSkuID,
                                                    ChildIndex: ChildIndex,
                                                    arrDependency: [
                                                    ],
                                                    DependencyCount: child.depandencyCount,
                                                    SelectedDependency: child.selectedDependency === null ? '' : child.selectedDependency,
                                                    AssignedDepandency: child.assignedDepandency === null ? '' : child.assignedDepandency,
                                                    hasSKUNotMatch: false,
                                                    IsDepandency: 0,
                                                    ExFacDate: child.exFacDate,
                                                    TotalHoliday: '',
                                                    Isdraft: child.isDraft,
                                                    IsQAdraft: child.isQAdraft,
                                                    IsTestDraft: child.isTestDraft,
                                                    IsStrikedraft: child.isStrikedraft,
                                                    IsTrimDraft: child.isTrimDraft
                                                });

                                                ChildDepandencyInfo && ChildDepandencyInfo.filter(x => x.mainChildId === child.childId).map((item) => {
                                                    routeInformation[i].SubTaskList[p].ChildTaskList[c].arrDependency.push({
                                                        TNAChildDependencyID: item.tnaChildDependencyID,
                                                        DependentTaskId: item.dependentTaskId,
                                                        DependencyID: item.dependencyID,
                                                        LogDays: item.logDays,
                                                        IndexName: item.indexName,
                                                        Mainindex: item.mainChildIndex,
                                                        StartDate: new Date(item.startDate),
                                                        EndDate: new Date(item.endDate)

                                                    })
                                                })


                                                ChildIndex++;

                                                if (!ParentActualdate && child.actualEndDate === null) {
                                                    ParentActualdate = true;
                                                    // routeInformation[i].ActualEndDate = null;
                                                    routeInformation[i].StrActualEndDate = null;

                                                }

                                                if (!ParentActualdate) {
                                                    // routeInformation[i].ActualEndDate = child.actualEndDate;
                                                    routeInformation[i].StrActualEndDate = child.actualEndDate;
                                                }


                                            });
                                    }



                                });
                            }

                            // if (routeInformation[i].SubTaskList.length === 0 && IsSingleTask === false) {
                            //     routeInformation.splice(i, 1);
                            // }

                        });
                    }

                    //For empty child list have remove parent task
                    // for (let index = 0; index < routeInformation.length; index++) {
                    //     //const element = routeInformation[index];
                    //
                    //     if (routeInformation[index].SubTaskList.length === 0 && routeInformation[index].IsSingleTask === false) {
                    //         routeInformation.splice(index, 1);
                    //         index = index - 1;
                    //     }

                    // }

                    if (dependentInfo && dependentInfo.length > 0) {
                        dependentInfo.map((element, i) => {
                            let DependencyId = 0;
                            DependencyId = element.tnaDependencyID;
                            try {
                                routeInformation && routeInformation[parseInt(element.indexName)].arrDependency && routeInformation[parseInt(element.indexName)].arrDependency.push({
                                    TNADependencyID: DependencyId,
                                    DependentTaskName: element.dependentTaskName,
                                    TNATaskName: element.tnaTaskName,
                                    DependencyID: element.dependencyID,
                                    LogDays: element.logDays,
                                    IndexName: element.indexName
                                })
                            } catch { }


                        })
                    }

                    if (additionalInfo.length > 0) {
                        routeInformation.map((add, j) => {
                            additionalInfo.filter(x => x.indexName === add.IndexName).map((element, i) => {

                                routeInformation[j].FollowupDetails.push({
                                    TNAAddTaskID: element.tnaAddTaskID,
                                    AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                    AddtionalTaskName: element.addtionalTaskName,
                                    IsTaskSelected: element.isTaskSelected,
                                    IndexName: element.indexName
                                })
                            });

                        })
                    }

                    if (Comments.length > 0) {
                        routeInformation.map((add, j) => {
                            Comments.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {
                                //
                                routeInformation[j].arrComment.push({
                                    TNACommentsID: element.tnaCommentsID,
                                    TNAId: element.tnaId,
                                    TNAComments: element.tnaComments,
                                    IndexName: element.indexName,
                                    TaskIndexName: element.taskIndexName,
                                    //Date1: element.createdDate.toString(),
                                    Date: new Date(element.createdDate),
                                    //Date: element.createdDate.toLocaleDateString('en-US'),
                                    //ModifiedDate: element.modifiedDate
                                })
                            });

                        })
                    }

                    if (Attachments.length > 0) {
                        //setFiles([]);
                        routeInformation.map((add, j) => {
                            Attachments.filter(x => x.taskIndexName === add.IndexName).map((element, i) => {

                                routeInformation[j].arrAttachment.push({
                                    TNAFileID: element.tnaFileID,
                                    TNAId: element.tnaId,
                                    FileName: element.fileName,
                                    FilePath: element.filePath,
                                    FileType: element.fileType,
                                    IndexName: element.indexName,
                                    TaskIndexName: parseInt(element.taskIndexName),
                                    Date1: element.createdDate,
                                    //Date: element.createdDate.toLocaleDateString('en-US'),
                                    ModifiedDate: element.modifiedDate
                                })




                            });

                        })
                    }
                    setInputFields(routeInformation);
                    hideLoader();
                    if (routeInformation.length > 0) {
                        setAllDataLoaded(true);

                    }

                } else { setInputFields(routeInformation); hideLoader(); }
            } else {
                hideLoader();
            }
        } else {
            hideLoader();
        }
    },
        [AllRouteInfo.tnaDetailList]
    );

    const values = [...inputFields];
    const MainValues = [...MainFields];

    useEffect(() => {
        if (!isLoadingTNA) {
            // hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTNA])




    useMemo(
        () => {

            let Optionvalue = [];
            taskHolderList && taskHolderList.map(element => {
                let LabelColor = '';
                if (element.label === 'Buyer - -') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Buyer - Cube') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Buyer - Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Buyer - Cube,Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - -') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Cube - Buyer') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Cube - Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - Buyer,Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - -') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - Buyer') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Supplier - Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Supplier - Buyer,Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }

                Optionvalue.push({
                    value: element.value,
                    label: LabelColor,
                    subLabel: element.label,
                })
            });

            setTaskHolderList(Optionvalue);

        },
        [taskHolderList]
    );





    useEffect(() => {
        // dispatch(loadTNARouteList(PurchaseOrderIDList));
        if (getTaskTypeList.length === 0) {
            dispatch(loadTaskTypeList(2));
        }
        if (getLeaveDaysList.length === 0) {
            dispatch(loadLeaveDaysList());
        }

        if (ExistingList.length === 0) {
            dispatch(retrieveTask(0));
        }

        if (getAllTaskList.length === 0) {
            dispatch(loadTaskList(0));
        }

        if (getDependencyList.length === 0) {
            dispatch(loadRouteDependencyList());
        }

        if (taskHolderList === undefined || taskHolderList.length === 0) {

            dispatch(loadTaskHolderList());
        }

        if (getCompanyHolidayList.length === 0) {
            dispatch(getTNACompanyHolidayList(currentUser.companyBuyerSupID));
        }

    }, []);


    useEffect(() => {
        if (values.length > 0) {
            if (AllDataLoaded === true) {
                commonCalculation(false, false, 0);
                setAllDataLoaded(false);
            }
        }
    }, [AllDataLoaded === true])
    // #region  ------------------  Expanded Toggle --------------------------------

    const handleToggle = (index) => {
        var btnToggle = document.getElementById("btn-toggle_" + index);
        var iconToggle = document.getElementById("icon-toggle_" + index);
        var rowHiddens = document.querySelectorAll(".parent_" + index);
        //var rowChildHiddens = document.querySelectorAll(".child_" + index);

        var ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            let i = 0;
            for (let row of rowHiddens) {
                row.className = row.className.replace("hidden", " ");
                values[index].SubTaskList[i].IsExpanded = true;
                i++;
            }
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
            setInputFields(values);
        }
        else {
            let i = 0;
            for (let row of rowHiddens) {
                if (!row.className.includes("hidden")) {
                    row.className = row.className + ' hidden';

                    values[index].SubTaskList[i].IsExpanded = false;
                }
                i++;
            }
            // for (let row2 of rowChildHiddens) {
            //
            //     if (!row2.className.includes("hidden")) {
            //         row2.className = row2.className + ' hidden';
            //         try {
            //             let dataChild = row2.classList[1];
            //         var btnToggleChild = document.getElementById("btn-toggle_" + dataChild);
            //         var iconToggleChild = document.getElementById("icon-toggle_" + dataChild);
            //             btnToggleChild.setAttribute('aria-expanded', 'false');

            //             iconToggleChild.className = iconToggleChild.className.replace("fa-minus-square-o", "fa-plus-square-o");
            //         } catch { }
            //     }
            // }
            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
            setInputFields(values);
        }
    };
    const handleExpandedOnlyToggle = (index) => {
        var btnToggle = document.getElementById("btn-toggle_" + index);
        var iconToggle = document.getElementById("icon-toggle_" + index);
        values[index].SubTaskList.map((value, i) => {
            values[index].SubTaskList[i].IsExpanded = true;
        });
        btnToggle.setAttribute('aria-expanded', 'true');
        iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
        values[index].IsExpanded = true;
        setInputFields(values);

    };

    const handleChildToggle = (index, childIndex) => {
        var btnToggle = document.getElementById("btn-toggle_parentChild_" + index + "_" + childIndex);
        var iconToggle = document.getElementById("icon-toggle_parentChild_" + index + "_" + childIndex);
        var rowHiddens = document.querySelectorAll(".parentChild_" + index + "_" + childIndex);
        var ariaExpanded = btnToggle.getAttribute('aria-expanded');
        if (ariaExpanded === 'false') {
            values[index].SubTaskList[childIndex].IsExpanded = true;

            // for (let row of rowHiddens) {
            //     row.className = row.className.replace("hidden", " ");
            // }
            btnToggle.setAttribute('aria-expanded', 'true');
            iconToggle.className = iconToggle.className.replace("fa-plus-square-o", "fa-minus-square-o");
        }
        else {
            // for (let row of rowHiddens) {
            //     if (!row.className.includes("hidden")) {
            //         row.className = row.className + ' hidden';
            //     }
            // }

            values[index].SubTaskList[childIndex].IsExpanded = false;

            btnToggle.setAttribute('aria-expanded', 'false');
            iconToggle.className = iconToggle.className.replace("fa-minus-square-o", "fa-plus-square-o");
        }
        setInputFields(values);
    };


    // #endregion  ------------------  Expanded Toggle End --------------------------------

    //Gowtham
    const handleChangeTNAComments = (event, index) => {
        //  const values = { ...inputFields };
        let inputText = '';
        if (event.target.value.trim() !== '') { inputText = event.target.value; }

        if (event.target.name === 'Comments') {
            values[index].Comments = inputText;

        }
        else {
            values[index].ManualComments = inputText;
            values[index].ManualCloseDate = new Date();

        }

        setInputFields(values);

    }
    const handleSave = (index, Childindex, assignValue, TaskID) => {
        let IsValid = true;
        if (assignValue === '' || assignValue === undefined) {
            setComment(true);
            IsValid = false;
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
        }
        if (IsValid) {
            if (values[index].Comments.length !== 0) {
                if (values[index].Comments !== '') {
                    if (EditedCommentIndex !== '') {
                        values[index].arrComment[Childindex - 1].TNAComments = assignValue;
                    }
                    else {
                        values[index].arrComment.push({
                            TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
                            Date: new Date(),

                        });
                    }
                }
                else {
                    values[index].arrComment = {
                        TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
                        Date: new Date(),
                    };
                }
            }
            else {
                values[index].arrComments.push({
                    TNACommentsID: 0, TaskNameId: TaskID, TNAComments: assignValue, IndexName: values[index].arrComment.length.toString(), TaskIndexName: (index).toString(),
                    Date: new Date(),
                });
            }
            values[index].Comments = '';
            values[index].IsComment = 0;
            values[index].CommentsCount = values[index].arrComment.length;
            setInputFields(values);
            setEditedCommentIndex('');
            Nodify('Success!', 'success', 'Task Comments saved Successfully');
        }
    };

    const handleRemoveFields = index => {
        values.splice(index, 1);
        values.map((Item, j) => {
            //for (let j = 0; j < values.length; j++) {
            Item.arrDependency.splice(index, 1);

            Item.SelectedDependency = '';
            let DependencyShow = '';
            Item.arrDependency.map((Item2, i) => {
                //for (let i = 0; i < values[j].arrDependency.length; i++) {
                if (j !== i) {
                    let DependentID = Item2.DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                        }
                    }
                }
            });
            Item.SelectedDependency = DependencyShow;
            Item.DependencyCount = Item.arrDependency.filter(x => x.DependencyID !== 0).length;
            values[j] = Item;
        });

        setInputFields(values);
        commonCalculation(false, false);
        DisabeSelectedTaskName();
    };

    // #region---------------------  Popup open and Sku And Style Detail get --------------------------------
    const handleSkuAndStyleDetails = (SubId, MainId, StrikeOffIdOrTrimInfoId, strTaskName, SKUIdList, StyleStrikeOffGarmentSizeIdList, childTask) => {
        showLoader();
        if (SKUIdList) {
            dispatch(getSkuAndStyleDetails({
                TNAId: props.location.state.params.tnaid, SubId: SubId,
                MainId: MainId, StrikeOffIdOrTrimInfoId: StrikeOffIdOrTrimInfoId,
                TaskName: strTaskName, StyleId: props.location.state.params.styleId
                , SKUIdList: SKUIdList, StyleStrikeOffGarmentSizeIdList: StyleStrikeOffGarmentSizeIdList

            }))
                .then(data => {
                    if (data) {
                        setSkuAndStyleDetailPopup(true);
                        setSkuAndStyleDetails(data);
                        hideLoader();
                    }
                });
        } else {
            hideLoader();
            let Title = "Warning!";
            let message = "No SKU in " + strTaskName;
            let type = "warning";
            Nodify(Title, type, message);
        }
    };

    const hideSkuAndStyleDetail = () => {
        setSkuAndStyleDetailPopup(false);
    }
    const delCallback = (value) => {
        if (value === true) {
            dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
            dispatch(loadTNASupplierEmployeeList(getTNASupplierID));
        } else {
            setIDSingleTask({ showPopupDelete: false, Params: [] });
        }
    }
    function delQaCallback(value) {
        if (value === true) {
            dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
            dispatch(loadTNASupplierEmployeeList(getTNASupplierID));
        } else {
            setIDQATask({ showPopupDelete: false, Params: [] });
        }
    }
    const DeleteCallback = (value) => {
        // dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
        // dispatch(loadTNASupplierEmployeeList(getTNASupplierID));
        if (value === true) {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsManualClose = 0;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = 1;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseComment = '';
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = null;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = null;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = null;
            setsID({ showPopupDelete: false, Params: [] });
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsManualClose = 0;
            IsParentDateShow = DateAffectDelete(values, Indexes, IsParentDateShow);
        } else {
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsManualClose = 1;
            setsID({ showPopupDelete: false, Params: [] });
        }
        setInputFields(values);
    }

    const DeleteCallbacks = (value) => {


        if (value === true) {
            values[Indexes.Index].IsManualClose = 0;
            values[Indexes.Index].StatusID = 1;
            values[Indexes.Index].ManualComments = '';
            values[Indexes.Index].ManualCloseDate = null;
            values[Indexes.Index].ChildActualEndDate = null;
            values[Indexes.Index].StrActualEndDate = null;
            setID({ showPopupDelete: false, Params: [] });
            values[Indexes.Index].IsManual = 0;
        } else {
            values[Indexes.Index].IsManual = 1;
        }
        setInputFields(values);
    }
    const handleChangeChild = (e, index, subIndex, childIndex, FieldName) => {
        setSubmitted(false);
        let inputText = '';

        if (FieldName === "Duration") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].Duration = inputText;
            if (inputText !== '') {
                let durationVal = parseInt(inputText);
                let parentDuration = values[index].Duration;
                if (durationVal > parseInt(parentDuration)) {
                    values[index].Duration = durationVal;
                }
            }

        }
        else if (FieldName === "EndDate") {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDate = e;
        }
        else if (FieldName === "StatusID") {
            if (e != null) {
                inputText = parseInt(e.value);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID = inputText;
            }
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex][e.target.name] = inputText;
        }

        if (FieldName !== "TaskName") {
            setInputFields(values);
            if (FieldName === "Duration" || FieldName === "EndDate") {
                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].Duration !== '') {
                    ChildDateCalculation(FieldName, index, subIndex, childIndex);
                }
            }
        }
    }


    const ChildDateCalculation = (FieldName, index, subIndex, childIndex) => {

        let Duration = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].Duration;
        let StartDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StartDate;
        let EndDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDate;
        let SelectedDepandency = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency;
        let AssignedDepandency = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedDepandency;
        let AssignedStartDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedStartDate;
        let AssignedEndDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].AssignedEndDate;
        let PreStartDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StartDate;
        let PreEndDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].PreEndDate;
        let LeaveDays = values[index].LeaveDays;
        let titleEmployee1 = values[index].titleEmployee1;
        let RowIndex = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildIndex;
        let total = 0;

        //calculation without depandency
        if (SelectedDepandency === "" || SelectedDepandency === null) {
            //based on duration
            if (FieldName === "Duration") {
                EndDate = addDays(StartDate, parseInt(Duration) - 1);
                total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                for (let l = 0; l < total; l++) {
                    EndDate = addDays(EndDate, 1);
                    EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                }
            }
            else {
                //based on end date
                let CheckDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                if (!isSameDay(new Date(EndDate), CheckDate)) {
                    Nodify('Warning!', 'warning', 'You select the end date to leave day');
                    EndDate = CheckDate;
                }
                StartDate = subDays(EndDate, parseInt(Duration) - 1);
                total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                for (let l = 0; l < total; l++) {
                    StartDate = subDays(StartDate, 1);
                    StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");

                }
            }

        }
        //calculation with depandency
        else {
            //based on duration
            if (FieldName === "Duration") {
                if (AssignedDepandency === "ES" || AssignedDepandency === "SS") {
                    EndDate = addDays(StartDate, parseInt(Duration) - 1);
                    total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                    for (let l = 0; l < total; l++) {
                        EndDate = addDays(EndDate, 1);
                        EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                    }
                }
                else {
                    StartDate = subDays(EndDate, parseInt(Duration) - 1);
                    total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                    for (let l = 0; l < total; l++) {
                        StartDate = subDays(StartDate, 1);
                        StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");
                    }
                }
            }
            else {
                //based on end date

                if (AssignedDepandency === "SE" || AssignedDepandency === "EE") {
                    if (new Date(AssignedEndDate) < new Date(EndDate)) {
                        Nodify('Warning!', 'warning', 'Depandency set based on End date,it should not less than' + AssignedEndDate);
                        EndDate = PreEndDate;
                    }
                    else {
                        let CheckDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                        if (!isSameDay(new Date(EndDate), CheckDate)) {
                            Nodify('Warning!', 'warning', 'You select the end date to leave day');
                            EndDate = CheckDate;
                        }
                        StartDate = subDays(EndDate, parseInt(Duration) - 1);
                        total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                        for (let l = 0; l < total; l++) {
                            StartDate = subDays(StartDate, 1);
                            StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");
                        }

                    }
                }
                else {
                    let CheckDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
                    if (!isSameDay(new Date(EndDate), CheckDate)) {
                        Nodify('Warning!', 'warning', 'You select the end date to leave day');
                        EndDate = CheckDate;
                    }
                    StartDate = subDays(EndDate, parseInt(Duration) - 1);
                    total = totalHolidayAndWE(StartDate, EndDate, LeaveDays, titleEmployee1, index, subIndex, childIndex, false);
                    for (let l = 0; l < total; l++) {
                        StartDate = subDays(StartDate, 1);
                        StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");
                    }

                    if (new Date(AssignedStartDate) > new Date(StartDate)) {
                        Nodify('Warning!', 'warning', 'Depandency set based on Start date,it should not less than' + AssignedStartDate);
                        StartDate = PreStartDate;
                        EndDate = PreEndDate;
                    }
                }
            }

        }
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StartDate = StartDate;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].EndDate = EndDate;

        // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency[RowIndex - 1].StartDate = StartDate;
        // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency[RowIndex - 1].EndDate = EndDate;

        values.map((Main, Mainindex) => {
            LeaveDays = Main.LeaveDays;
            titleEmployee1 = Main.titleEmployee1;
            Main.SubTaskList.map((Child, ChildIndex) => {

                Child.ChildTaskList.map((SubChild, Subchildindex) => {
                    if (SubChild.arrDependency.length > 0) {
                        SubChild.arrDependency[RowIndex - 1].StartDate = StartDate;
                        SubChild.arrDependency[RowIndex - 1].EndDate = EndDate;
                    }
                })

            })

        });

        setInputFields(values);
        if ((SelectedDepandency === "" || SelectedDepandency === null)) {
            ChildDepandencyCalculation("childChange", index, subIndex, childIndex);
        }



    }

    const handleChange = (e, index, FieldName) => {


        setSubmitted(false);
        let inputText = '';
        if (FieldName === "IsSelectAll") {
            if (e.target.checked) {
                values[index].FollowupDetails.map((Item, i) => {
                    //for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                    values[index].FollowupDetails[i].IsTaskSelected = 1;
                });

                values[index][FieldName] = 1;
            } else {
                values[index].FollowupDetails.map((Item, i) => {
                    //for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                    values[index].FollowupDetails[i].IsTaskSelected = 0;
                });
                values[index][FieldName] = 0;

            }

            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
            values[index].IsFollowupExist = values[index].FollowupDetails.length;

        }
        else if (FieldName === "TaskType") {
            if (e != null) {
                inputText = parseInt(e.value);
                values[index].TaskID = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = "";
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index].FollowupDetails = [];
                var SelectedIndex = getTaskTypeList.findIndex(x => x.value === e.value);
                getTaskTypeList[SelectedIndex].isdisabled = true;
            }
            else {
                inputText = 0;
                values[index].TaskID = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = "";
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index].FollowupDetails = [];

            }
            values[index][FieldName] = inputText;
            DisabeSelectedTaskName();

        }
        else if (FieldName === "TaskName") {
            showLoader();
            if (e != null) {
                inputText = parseInt(e.value);
                let routeInfo = AllRouteInfo.tnaDetailList;
                let additionalInfo = AllRouteInfo.tnaDetailTaskList;
                let parentChildList = AllRouteInfo.tnaParentChildList;
                let childList = AllRouteInfo.tnaChildList;
                let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === e.label.toLowerCase());
                const existingTask = routeInfo.filter(d => d.taskNameID === inputText);

                let Task_Value = getAllTaskList.filter(x => x.value === e.value)[0];
                values[index].TaskFieldEnable = 0;
                values[index].TaskType = Task_Value.taskTypeID;
                values[index].TaskID = inputText;
                values[index].strTaskName = e.label;

                if (existingTask && existingTask.length > 0) {
                    let existingEmployee1 = "";
                    let existingEmployee2 = "";
                    let existingEmployee3 = "";

                    if (existingTask.taskHolderName !== null && existingTask.taskHolderName !== undefined) {
                        let TaskHolderName = existingTask.taskHolderName.split('-');
                        if (TaskHolderName[0] !== undefined) {
                            existingEmployee1 = TaskHolderName[0].trim();

                            if (TaskHolderName[1] !== undefined) {
                                let FollowerName = TaskHolderName[1].split(',');
                                if (FollowerName[0] !== undefined) {
                                    existingEmployee2 = FollowerName[0].trim();

                                    if (FollowerName[1] !== undefined) {
                                        existingEmployee3 = FollowerName[1].trim();
                                    } else {
                                        existingEmployee3 = "";
                                    }
                                } else {
                                    existingEmployee2 = "";
                                    existingEmployee3 = "";
                                }

                            } else {
                                existingEmployee2 = "";
                                existingEmployee3 = "";
                            }
                        }
                    }

                    values[index].Duration = existingTask.duration && existingTask.duration.toString();
                    values[index].TaskHolderOrFollower = existingTask.taskHolderID;
                    values[index].TaskHolderOwnerTxtList = existingTask.taskOwnerNames;
                    values[index].TaskHolderIDList = existingTask.taskHolderIDList;
                    values[index].FollowerIDList1 = existingTask.followerIDList1;
                    values[index].FollowerIDList2 = existingTask.followerIDList2;
                    values[index].TaskHolderBuyer = existingTask.taskHolderIDList;
                    values[index].FollowerCube = existingTask.followerIDList1;
                    values[index].FollowerSupplier = existingTask.followerIDList2;
                    values[index].titleEmployee1 = existingEmployee1;
                    values[index].titleEmployee2 = existingEmployee2;
                    values[index].titleEmployee3 = existingEmployee3;
                    values[index].LeaveDays = existingTask.leaveDays;
                    values[index].LeaveDaysTxt = existingTask.leaveDayName;
                    values[index].AdditionalFollowup = additionalInfo.filter(x => x.indexName === existingTask.indexName && x.isTaskSelected === 1).length;
                    values[index].IsFollowupExist = additionalInfo.filter(x => x.indexName === existingTask.indexName).length;
                    values[index].DependencyCount = additionalInfo.filter(x => x.indexName === existingTask.indexName && x.dependencyID !== 0).length;
                    // values[index].PreferenceId = existingTask.preferenceId;
                    values[index].IsSelectAll = existingTask.isSelectAll;
                    values[index].SelectedDependency = existingTask.dependency;
                    values[index].IsSingleTask = IsSingleTask;
                    values[index].DetailTypeID = existingTask.detailTypeID;

                    if (IsSingleTask === false) {
                        loadNewParentAndChild(parentChildList, childList, existingTask, index, existingEmployee1, existingEmployee2, existingEmployee3, true);

                    }
                    loadAddtionalTaskDetailsList(index, Task_Value, FieldName, inputText);

                    setInputFields(values);
                    hideLoader();
                } else {

                    var RespectiveList = ExistingList.filter(x => x.taskDetailsID === e.value);
                    let employee1 = "";
                    let employee2 = "";
                    let employee3 = "";

                    if (RespectiveList[0].taskHolderName !== null && RespectiveList[0].taskHolderName !== undefined) {
                        let TaskHolderName = RespectiveList[0].taskHolderName.split('-');
                        if (TaskHolderName[0] !== undefined) {
                            employee1 = TaskHolderName[0].trim();

                            if (TaskHolderName[1] !== undefined) {
                                let FollowerName = TaskHolderName[1].split(',');
                                if (FollowerName[0] !== undefined) {
                                    employee2 = FollowerName[0].trim();

                                    if (FollowerName[1] !== undefined) {
                                        employee3 = FollowerName[1].trim();
                                    } else {
                                        employee3 = "";
                                    }
                                } else {
                                    employee2 = "";
                                    employee3 = "";
                                }

                            } else {
                                employee2 = "";
                                employee3 = "";
                            }
                        }
                    }


                    values[index].TaskHolderOrFollower = RespectiveList[0].taskHolderOrFollower;
                    values[index].TaskHolderOrFollowerTxt = '';
                    values[index].LeaveDays = RespectiveList[0].leaveDays;
                    values[index].LeaveDaysTxt = RespectiveList[0].leaveDayName;
                    values[index].AdditionalFollowup = RespectiveList[0].additionalFollowup;
                    values[index].IsFollowupExist = RespectiveList[0].additionalFollowup;
                    values[index].titleEmployee1 = employee1;
                    values[index].titleEmployee2 = employee2;
                    values[index].titleEmployee3 = employee3;
                    values[index].IsSingleTask = IsSingleTask;
                    values[index].Duration = "1";

                    values[index].TaskHolderOwnerTxtList = "";
                    values[index].TaskHolderIDList = "";
                    values[index].FollowerIDList1 = "";
                    values[index].FollowerIDList2 = "";
                    values[index].TaskHolderBuyer = "";
                    values[index].FollowerCube = "";
                    values[index].FollowerSupplier = "";
                    values[index].DependencyCount = 0;
                    values[index].IsSelectAll = "";
                    values[index].SelectedDependency = "";
                    values[index].DetailTypeID = RespectiveList[0].detailTypeID;

                    loadAddtionalTaskDetailsList(index, Task_Value, FieldName, inputText);

                    if (!IsSingleTask) {
                        dispatch(getTNAEditTaskWiseChildList({ TNAId: props.location.state.params.tnaid, TaskName: e.label }))
                            .then(data => {
                                if (data != null) {
                                    let newParentChildList = data.tnaParentChildList;
                                    let newChildList = data.tnaChildList;
                                    loadNewParentAndChild(newParentChildList, newChildList, RespectiveList[0], index, employee1, employee2, employee3, false);

                                    setInputFields(values);
                                    hideLoader();
                                }
                            });
                    } else {
                        setInputFields(values);
                        hideLoader();
                    }
                }


            }
            else {
                inputText = 0;
                values[index].TaskType = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = '';
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index][FieldName] = inputText;
                values[index].FollowupDetails = [];

                values.map((Item, i) => {
                    //for (let i = 0; i < values.length; i++) {
                    Item.arrDependency.splice(index, 1);
                    values[i] = Item;
                });
                setInputFields(values);
                hideLoader();
            }
            DisabeSelectedTaskName();
        }
        else if (FieldName === "TaskHolderOrFollower" || FieldName === "LeaveDays") {
            if (e != null) {
                inputText = parseInt(e.value);
                if (FieldName === "TaskHolderOrFollower") {
                    // setTaskHolderID(inputText);

                    values[index].TaskHolderOrFollowerTxt = e.subLabel;
                    let TaskHolderName = e.subLabel.split('-');
                    if (TaskHolderName[0] !== undefined) {
                        values[index].titleEmployee1 = TaskHolderName[0].trim();

                        if (TaskHolderName[1] !== undefined) {
                            let FollowerName = TaskHolderName[1].split(',');
                            if (FollowerName[0] !== undefined) {
                                values[index].titleEmployee2 = FollowerName[0].trim();

                                if (FollowerName[1] !== undefined) {
                                    values[index].titleEmployee3 = FollowerName[1].trim();
                                } else {
                                    values[index].titleEmployee3 = "";
                                }
                            } else {
                                values[index].titleEmployee2 = "";
                                values[index].titleEmployee3 = "";
                            }

                        } else {
                            values[index].titleEmployee2 = "";
                            values[index].titleEmployee3 = "";
                        }
                        if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                            dispatch(loadTNATaskOwner(inputText, getTNABrandID, getTNASupplierID, 0));
                        } else {
                            dispatch(loadTNATaskOwner(inputText, getTNABrandID, getTNASupplierID, currentUser.employeeinformationID));
                        }

                    } else {
                        values[index].titleEmployee1 = "";
                        values[index].titleEmployee2 = "";
                        values[index].titleEmployee3 = "";
                    }


                } else {
                    values[index].LeaveDaysTxt = e.label;
                }
            }
            else {
                inputText = 0;
                if (FieldName === "TaskHolderOrFollower") {
                    values[index].TaskHolderOrFollowerTxt = '';
                    values[index].titleEmployee1 = "";
                    values[index].titleEmployee2 = "";
                    values[index].titleEmployee3 = "";
                }
                values[index].LeaveDaysTxt = "";
            }
            values[index][FieldName] = inputText;
        }
        else if (FieldName === "Duration") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index][e.target.name] = inputText;
            if (values[index].IsSingleTask === false) {
                values[index].SubTaskList && values[index].SubTaskList.map((Item2, p) => {
                    Item2.ChildTaskList && Item2.ChildTaskList.map((Item3, c) => {
                        values[index].SubTaskList[p].ChildTaskList[c].Duration = inputText;
                    });
                });
            }
        }
        else if (FieldName === "EndDate") {
            values[index].EndDate = e;

        }
        else if (FieldName === "StatusID") {
            if (e != null) {
                inputText = parseInt(e.value);
                values[index].StatusID = inputText;
            }

        }
        else if (FieldName === "TaskHolderBuyer" || FieldName === "FollowerCube" || FieldName === "FollowerSupplier") {
            let strValue = '';
            let strTxt = '';
            e.map((val) => {
                if (strValue === '') { strValue = val.value.toString(); strTxt = val.label.toString(); }
                else { strValue = strValue + "," + val.value; strTxt = strTxt + ", " + val.label.toString(); }
            });
            values[index]["arr" + FieldName] = e;
            values[index][FieldName] = strValue;
            values[index][FieldName + "Txt"] = strTxt;
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index][e.target.name] = inputText;
        }

        if (FieldName !== "TaskName") {
            setInputFields(values);
            if (FieldName === "EndDate") {
                // commonCalculation(false, true);
            }

        }
    }



    function loadNewParentAndChild(parentChildList, childList, existingTask, index, existingEmployee1, existingEmployee2, existingEmployee3, isExisting) {
        values[index].SubTaskList = [];
        parentChildList && parentChildList.filter(d => d.taskID === existingTask.taskDetailsID).map((element, p) => {
            let ImageUrl = "";

            if (element.taskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || element.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || element.taskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || element.taskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || element.taskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                ImageUrl = 'Images/Style/Strikeoff/';
            } else if (element.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || element.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || element.taskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || element.taskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || element.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || element.taskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || element.taskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                ImageUrl = 'Images/Style/Trim/';
            }
            values[index].SubTaskList.push({
                ParentId: 0,
                TaskID: element.taskID,
                MainId: element.mainId,
                Code: element.code,
                Color: element.color,
                ColorOrName: element.colorOrName,
                StrikeOffOrTrimInfoId: element.strikeOffIdOrTrimInfoId,
                ImagePath: element.imagePath,
                ImageUrl: ImageUrl,
                IsExpanded: false,
                // ParentIndex: p,
                // TaskIndex: TaskIndex,
                ChildTaskList: []
            })
            childList && childList.filter(d => d.taskID === existingTask.taskDetailsID && d.mainId === element.mainId).map((child, c) => {
                values[index].SubTaskList[p].ChildTaskList.push({
                    ChildId: isExisting ? isLastEdit ? child.childId : 0 : 0,
                    SubId: child.subId,
                    Name: child.name,
                    TaskName: child.taskName,
                    TaskID: child.taskID,
                    MainId: child.mainId,
                    Duration: isExisting ? isLastEdit ? child.duration : existingTask.duration : 1,
                    EndDate: isExisting ? isLastEdit ? new Date(child.endDate) : new Date(existingTask.endDate) : new Date(),
                    StartDate: isExisting ? isLastEdit ? new Date(child.planStartDate) : new Date(existingTask.planStartDate) : new Date(),
                    arrDependency: [],
                    TaskHolderList: '',
                    TaskHolderOrFollower: isExisting ? existingTask.taskHolderID : existingTask.taskHolderOrFollower,
                    TaskHolderOwnerTxtList: isExisting ? existingTask.taskOwnerNames : "",
                    TaskHolderIDList: isExisting ? existingTask.taskHolderIDList : "",
                    FollowerIDList1: isExisting ? existingTask.followerIDList1 : "",
                    FollowerIDList2: isExisting ? existingTask.followerIDList2 : "",
                    TaskHolderBuyer: isExisting ? existingTask.taskHolderIDList : "",
                    FollowerCube: isExisting ? existingTask.followerIDList1 : "",
                    FollowerSupplier: isExisting ? existingTask.followerIDList2 : "",
                    arrTaskHolderBuyer: [],
                    arrFollowerCube: [],
                    arrFollowerSupplier: [],
                    TaskHolderBuyerTxt: '',
                    FollowerCubeTxt: '',
                    FollowerSupplierTxt: '',
                    TaskHolderOwnerIdList: '',
                    titleEmployee1: existingEmployee1,
                    titleEmployee2: existingEmployee2,
                    titleEmployee3: existingEmployee3,
                    IsTHFirstTime: 0,
                    DependencyCount: 0,
                    // IsDependencyOpen: 0,
                    // IsTHLeaveDaysOpen: 0,
                    SelectedDependency: '',
                    LeaveDays: isExisting ? "" : existingTask.leaveDays,
                    LeaveDaysTxt: isExisting ? "" : existingTask.leaveDayName,
                    StatusID: isExisting ? isLastEdit ? child.childId : 1 : 1,
                    AWBStatus: '',
                    IsAddChildTaskOpen: 0,
                    // ParentIndex: p,
                    // ChildIndex: c,
                    StrikeOffOrTrimInfoId: child.strikeOffIdOrTrimInfoId,
                    // TaskIndex: 0,
                    SKUIdList: child.skuIdList,
                    drpChildTaskList: [],
                    IsManualClose: 0,
                    ManualCloseComment: '',
                    EndDateChange: 0,
                    IsReviced: 0,
                    IsRescheduled: 0,
                    DependencyDate: child.dependencyDate,
                    DependencyComment: child.dependencyComment,
                    PurchaseOrderSkuID: child.purchaseOrderSkuID,
                    StyleStrikeOffGarmentSizeIdList: child.styleStrikeOffGarmentSizeIdList,
                    hasAutoOpen: false,
                    ReviceAdded: 0,
                    RescheduleAdded: 0,
                });
            });
        });

        ReassignIndexonParentTaskAddDelete('Add');
        ReassignIndexonAddDeleteTask(0, 0, 0, '', 0);

    }

    const ReassignIndexonParentTaskAddDelete = (operation) => {

        let SubChildIndex = 1; let SubChildIndex1 = 0;
        let CurrentChildvalues = [];
        values.map((Main, MainIndex) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({ DependentTaskId: SubChild.ChildId, MainIndex: MainIndex })
                })
            })
        })


        values.map((Main, Mainindex) => {
            Main.SubTaskList.map((Child, ChildIndex) => {
                Child.ChildTaskList.map((SubChild, Subchildindex) => {
                    SubChild.ChildIndex = SubChildIndex
                    SubChildIndex++;
                    SubChildIndex1 = 0;
                    if (SubChild.arrDependency && SubChild.arrDependency.length > 0) {

                        if (operation === "Add") {
                            SubChildIndex1 = 1;
                            values.map((Main1) => {
                                Main1.SubTaskList.map((Child1) => {
                                    Child1.ChildTaskList.map((SubChild1) => {
                                        let TaskDependency =
                                        {
                                            TNAChildDependencyID: 0,
                                            DependentTaskId: SubChild1.ChildId,
                                            TNATaskName: SubChild1.TaskName,
                                            DependencyID: 0,
                                            LogDays: '',
                                            IndexName: 0,
                                            Mainindex: SubChildIndex1,
                                            StartDate: '',
                                            EndDate: ''
                                        }
                                        if (SubChild1.ChildId === 0) {
                                            SubChild.arrDependency.splice((SubChildIndex1 - 1), 0, TaskDependency);
                                        }

                                        SubChildIndex1++;

                                    })
                                })
                            })
                        }
                        else {
                            let arrDependency = [];
                            SubChild.arrDependency.map((depitem, i) => {
                                if (CurrentChildvalues.filter(x => x.DependentTaskId === depitem.DependentTaskId).length > 0) {
                                    arrDependency.push(
                                        {
                                            TNAChildDependencyID: 0,
                                            DependentTaskId: depitem.DependentTaskId,
                                            TNATaskName: depitem.TNATaskName,
                                            DependencyID: depitem.DependencyID,
                                            LogDays: depitem.LogDays,
                                            IndexName: 0,
                                            Mainindex: CurrentChildvalues.filter(x => x.DependentTaskId === depitem.DependentTaskId)[0].MainIndex,
                                            StartDate: depitem.StartDate,
                                            EndDate: depitem.EndDate
                                        }
                                    )
                                }

                            })
                            SubChild.arrDependency = arrDependency;
                        }


                        SubChild.arrDependency.map((item, i) => {
                            item.IndexName = i + 1;
                        })
                    }
                })

            })
        })
    }

    function loadAddtionalTaskDetailsList(index, Task_Value, FieldName, inputText) {
        taskService.GetAddtionalTaskDetailsList(0, Task_Value.taskInformationID).then((response) => {

            if (response.data) {
                values[index].FollowupDetails = [];
                if (response.data.length !== 0) {
                    if (response.data.filter(x => x.indexName.toString() === Task_Value.indexName.toString()).length > 0) {
                        response.data.filter(x => x.indexName.toString() === Task_Value.indexName.toString()).map(element => {
                            values[index].FollowupDetails.push({
                                TNAAddTaskID: 0,
                                AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                AddtionalTaskName: element.addtionalTaskName,
                                IsTaskSelected: 1,
                                IndexName: index.toString()
                            });
                        });
                    }
                    else {
                        values[index].AdditionalFollowup = 0;
                        values[index].IsFollowupExist = 0;
                        values[index].FollowupDetails = [];
                    }
                }
                else {
                    values[index].AdditionalFollowup = 0;
                    values[index].IsFollowupExist = 0;
                    values[index].FollowupDetails = [];
                }
                values[index][FieldName] = inputText;

                values.map((Item, j) => {
                    // for (var j = 0; j < values.length; j++) {
                    let MSG = '';
                    if (index !== j) {
                        if (values[index].TaskType !== 0) { // for avoid empty validation
                            if (Item.TaskType === values[index].TaskType &&
                                Item.TaskID === values[index].TaskID) {
                                if (FieldName === "TaskName") {
                                    values[index].TaskID = 0;
                                    MSG = "This task name is already exist.";
                                }
                                values[j] = Item;
                                setInputFields(values);
                                Nodify('Warning!', 'warning', MSG);
                                return false;
                            }
                        }
                    }
                    values[j] = Item;
                });

            } else {

            }
        });
    }

    //sathish
    const filevals = [...getFiles]
    const AppendFiles = async (e) => {
        const file = e.target.files[0];
        let a = getTaskIndexFileupload;
        let b = getTNAtaskIdFileupload;

        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#TNAFileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "TNADocUpload");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    if (res.statusText === "Created") { }
                    filevals.push({
                        TNAFileID: 0,
                        TaskNameId: b,
                        FileName: file.name,
                        FilePath: "Images/TNARelatedDocs/" + file.name,
                        FileType: file.type,
                        IndexName: (filevals.length + 1).toString(),
                        TaskIndexName: a,
                    })
                    setFiles(filevals);
                    values[a].arrAttachment = filevals.filter(x => x.TaskIndexName === a);
                    values[a].AttachmentCount = filevals.filter(x => x.TaskIndexName === a).length;
                    setInputFields(values);

                    $('#TNAFileUpload').val("");

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
    const RemoveFiles = async (e, index) => {
        filevals.splice(index, 1);
        values[index].arrAttachment.splice(index, 1);
        values[index].AttachmentCount = filevals.length;
        setInputFields(values);
        setFiles(filevals);
    }
    const ViewFile = (event, filename) => { // event.preventDefault();
        setIsOpenimg(true);
        setImagename(filename);
    }

    const DisabeSelectedTaskName = () => {
        getAllTaskList.forEach((data, i) => {
            getAllTaskList[i].isdisabled = false;
        })

        values.forEach((data, i) => {
            var SelectedIndex = getAllTaskList.findIndex(x => x.value === data.TaskID);
            if (SelectedIndex !== -1) {
                getAllTaskList[SelectedIndex].isdisabled = true;
            }
        })
    }

    const CheckDuplicate = (index, Field) => {

        if (Field === "Duration") {
            //  commonCalculation(true, false);
        } else {
            for (var j = 0; j < values.length; j++) {
                let MSG = '';
                if (index !== j) {
                    if (values[index].TaskType !== 0 && values[index].TaskID !== 0) { // for avoid empty validation
                        if (values[j].TaskType === values[index].TaskType &&
                            values[j].TaskID === values[index].TaskID) {
                            if (Field === "TaskName") {
                                values[index].TaskID = 0;
                                MSG = "This task name is already exist.";
                            }
                            else if (Field === "TaskType") {
                                values[index].TaskType = 0;
                                MSG = "This task type is already exist.";
                            }
                            setInputFields(values);
                            Nodify('Warning!', 'warning', MSG);
                            return false;
                        }
                    }
                }
            }
        }
    }

    const CheckChildDuplicate = (index, subIndex, childIndex, Field) => {

        if (Field === "Duration") {
            // commonCalculation(true, false);
        }

    }

    const handleFollowup = index => {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            if (values[index].IsFollowupExist === 0) {
                Nodify('Warning!', 'warning', "Additional task doesn't exist for this task.");
            }
            else {
                values[index].IsPopupOpen = 1;
                setInputFields(values);
            }
        }
    };

    const handleClose = (index) => {
        values[index].IsPopupOpen = 0;
        setInputFields(values);
    };


    const handleComment = (index, CommentsCount, TnaId, TaskId) => {

        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            // if (CommentsCount !== 0) {
            //     TnaService.LoadTNACommentsList({ TnaId, TaskId }).then((response3) => {
            //         if (response3.data) {
            //             values[index].arrComment = [];
            //             values[index].arrComment = response3.data;
            //             values[index].IsComment = 1;
            //             setTaskIndexFileupload(index);
            //             setTNAtaskIdFileupload(values[index].TaskID);
            //             setInputFields(values);

            //         }

            //     });
            // }
            // else {
            //     values[index].IsComment = 1;
            //     setTaskIndexFileupload(index);
            //     setTNAtaskIdFileupload(values[index].TaskID);
            //     setInputFields(values);

            // }
            values[index].IsComment = 1;
            setTaskIndexFileupload(index);
            setTNAtaskIdFileupload(values[index].TaskID);
            setInputFields(values);
            setComment(false);
        }
    };

    const handleCloseComment = (index) => {

        values[index].IsComment = 0;
        values[index].Comments = ""
        setInputFields(values);
    };

    // const TaskIndexFileupload = '';
    // const TNAtaskIdFileupload = '';


    const handleDependent = (index, value) => {

        values[index].intDependency = value === 0 ? 1 : 0;
        setInputFields(values);
    }


    const handleAttachment = (index) => {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {

            values[index].IsAttachment = 1;
            setTaskIndexFileupload(index);
            setTNAtaskIdFileupload(values[index].TaskID);
            setFiles(values[index].arrAttachment);
            // getFiles.push({
            //     TNAFileID: element.tnaFileID,
            //     TNAId: element.tnaId,
            //     FileName: element.fileName,
            //     FilePath: element.filePath,
            //     FileType: element.fileType,
            //     IndexName: element.indexName,
            //     TaskIndexName: element.taskIndexName,
            //     Date1: element.createdDate,
            //     //Date: element.createdDate.toLocaleDateString('en-US'),
            //     ModifiedDate: element.modifiedDate
            // })
            setInputFields(values);
        }
    };

    const handleCloseAttachment = (index) => {
        values[index].IsAttachment = 0;
        setInputFields(values);
    };



    // #region ------------------  TaskHolderLeaveDays Start --------------------------------

    const handleTaskHolderLeaveDays = (index, isTHFirstTime) => {
        showLoader();
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {

            if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, 0));
                dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, 0))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, isTHFirstTime);
                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                        hideLoader();
                    });
            } else {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, currentUser.employeeinformationID));
                dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, currentUser.employeeinformationID))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, isTHFirstTime);
                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                        hideLoader();
                    });
            }
        }
    };

    function setTNATaskOwner(data, index, isTHFirstTime) {
        if (data.buyerEmployeeList && data.buyerEmployeeList.length > 0) {

            let taskHolderBuyer = values[index].TaskHolderIDList && data.buyerEmployeeList.filter(item => (values[index].TaskHolderIDList.split(',').includes(item.value.toString())));
            if (taskHolderBuyer.length > 0) {

                if (values[index].arrTaskHolderBuyer.length === 0 && isTHFirstTime === 0) {
                    values[index].arrTaskHolderBuyer = [...taskHolderBuyer];
                } else {
                    const isData = !!values[index].arrTaskHolderBuyer.filter(d => d.value === taskHolderBuyer.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrTaskHolderBuyer = [...values[index].arrTaskHolderBuyer, ...taskHolderBuyer];
                    }
                }

            }
        }
        if (data.cubeEmployeeList !== undefined) {
            let followerCube = values[index].FollowerIDList1 && data.cubeEmployeeList.filter(item => (values[index].FollowerIDList1.split(',').includes(item.value.toString())));
            if (followerCube.length > 0) {
                if (values[index].arrFollowerCube.length === 0 && isTHFirstTime === 0) {
                    values[index].arrFollowerCube = [...followerCube];
                } else {
                    const isData = !!values[index].arrFollowerCube.filter(d => d.value === followerCube.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrFollowerCube = [...followerCube];
                    }
                }
            }
        }
        if (data.supplierEmployeeList !== undefined) {
            let followerSupplier = values[index].FollowerIDList2 && data.supplierEmployeeList.filter(item => (values[index].FollowerIDList2.includes(item.value.toString())));
            if (followerSupplier.length > 0) {
                if (values[index].arrFollowerSupplier.length === 0 && isTHFirstTime === 0) {
                    values[index].arrFollowerSupplier = [...followerSupplier];
                } else {
                    const isData = !!values[index].arrFollowerSupplier.filter(d => d.value === followerSupplier.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrFollowerSupplier = [...followerSupplier];
                    }
                }
            }
        }

        if (isTHFirstTime === 0) {
            if (values[index].arrTaskHolderBuyer.length > 0) {
                let names = values[index].arrTaskHolderBuyer.map(x => x.label).join(",");
                values[index].TaskHolderBuyerTxt = names;
            }
            if (values[index].arrFollowerCube.length > 0) {
                let names = values[index].arrFollowerCube.map(x => x.label).join(",");
                values[index].FollowerCubeTxt = names;
            }
            if (values[index].arrFollowerSupplier.length > 0) {
                let names = values[index].arrFollowerSupplier.map(x => x.label).join(",");
                values[index].FollowerSupplierTxt = names;
            }
        }
        values[index].IsTHFirstTime = 1;
    }

    const handleTHLeaveDaysClose = (index) => {
        values[index].IsTHLeaveDaysOpen = 0;
        let TxtList = "";
        let IdList = "";
        if (values[index].TaskHolderBuyer !== '') {
            TxtList = values[index].TaskHolderBuyerTxt;
            IdList = values[index].TaskHolderBuyer;
        }
        if (values[index].FollowerCube !== '') {
            TxtList = TxtList === "" ? values[index].FollowerCubeTxt : TxtList + ", " + values[index].FollowerCubeTxt;
            IdList = IdList === "" ? values[index].FollowerCube : IdList + "," + values[index].FollowerCube;
        }
        if (values[index].FollowerSupplier !== '') {
            TxtList = TxtList === "" ? values[index].FollowerSupplierTxt : TxtList + ", " + values[index].FollowerSupplierTxt;
            IdList = IdList === "" ? values[index].FollowerSupplier : IdList + "," + values[index].FollowerSupplier;
        }

        values[index].TaskHolderOwnerTxtList = TxtList;
        values[index].TaskHolderOwnerIdList = IdList;

        setInputFields(values);
        // commonCalculation(false, false);
    }



    // #endregion ------------------  TaskHolderLeaveDays End --------------------------------

    //#region ------------------  Dependency Start ---------------------------------------

    const handleDependency = (index) => {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            values[index].IsDependencyOpen = 1;
            let TaskDependency = [];
            values.map((Item) => {

                if (values[index].arrDependency && values[index].arrDependency.length > 0) {
                    let AlreadyExist = values[index].arrDependency.filter(x => x.DependentTaskName === Item.TaskID);
                    let DependencyID = 0;
                    let LogDays = '';
                    if (AlreadyExist.length === 0) {
                        DependencyID = 0;
                        LogDays = '';
                    }
                    else {
                        DependencyID = AlreadyExist[0].DependencyID;
                        LogDays = AlreadyExist[0].LogDays;
                    }
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item.TaskID,
                            TNATaskName: Item.strTaskName,
                            DependencyID: DependencyID,
                            LogDays: LogDays,
                            IndexName: (index).toString()
                        }
                    )
                }
                else {
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item.TaskID,
                            TNATaskName: Item.strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: (index).toString()
                        }
                    )
                }

            });

            values[index].arrDependency = [];
            values[index].arrDependency = TaskDependency;
            setInputFields(values);
        }
    };

    const handleDependencyClose = (index) => {
        values[index].IsDependencyOpen = 0;
        setInputFields(values);

        //  commonCalculation(false, false);
    };

    //#endregion ------------------  Dependency End --------------------------------


    // #region  ------------------  Child Start --------------------------------


    // Child Change
    const handleChildChange = (e, index, childIndex, FieldName) => {
        setSubmitted(false);
        let inputText = '';
        if (FieldName === "DependentTaskName" || FieldName === "DependencyID") {
            if (e != null) {
                inputText = parseInt(e.value);
            }
            else {
                inputText = 0;
                if (FieldName === "DependencyID") {
                    values[index].arrDependency[childIndex].LogDays = '';
                }
            }
            values[index].arrDependency[childIndex][FieldName] = inputText;
        }

        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
                var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
                inputText = checkNumber[0];
            }
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index].arrDependency[childIndex][e.target.name] = inputText;
        }
        if (FieldName === "DependencyID") {
            values[index].SelectedDependency = '';
            let DependencyShow = '';
            for (let i = 0; i < values[index].arrDependency.length; i++) {
                if (index !== i) {
                    let DependentID = values[index].arrDependency[i].DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                    }
                }
            }
            values[index].SelectedDependency = DependencyShow;
        }
        values[index].DependencyCount = values[index].arrDependency.filter(x => x.DependencyID !== 0).length;

        setInputFields(values);
    }

    // Child Add Popup
    const handleChildAddFieldsClick = (index, subIndex, childIndex, taskName, subParentId) => {

        let referenceIdList = values[index].SubTaskList[subIndex].ChildTaskList && values[index].SubTaskList[subIndex].ChildTaskList.map(x => x.SubId).join(",");
        dispatch(loadTNAChildReferenceUnMappedList({ TaskName: taskName, TNAId: props.location.state.params.tnaid, SubParentId: subParentId, ReferenceIdList: referenceIdList }))
            .then(data => {
                if (data != null) {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].drpChildTaskList = data;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 1;
                    setInputFields(values);
                }
            });

        //setCurrentChildTask({ value: 0, label: '' });
    }

    // Child Add Popup Close
    const handleAddChildTaskClose = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 0;
        setInputFields(values);
    }


    // Child Add Popup Change
    const handleChangeChildTask = (e) => {
        if (e != null) {
            let inputVal = parseInt(e.value);
            setCurrentChildTask({ value: inputVal, label: e.label });
        } else {
            setCurrentChildTask({ value: 0, label: '' });
        }
    }

    // Child Add Popup Save New Row
    const handleAddRowChildTask = (index, subIndex, childIndex) => {

        if (getCurrentChildTask.value !== "" && getCurrentChildTask.value > 0) {
            let parentRow = values[index];
            let childRow = values[index].SubTaskList[subIndex].ChildTaskList[childIndex];
            let count = childIndex;
            count++;
            let EmptyChildList = {};
            values[index].SubTaskList[subIndex].IsNewRow = true;

            EmptyChildList =
            {
                ChildId: 0,
                SubId: getCurrentChildTask.value,
                Name: getCurrentChildTask.label,
                TaskName: childRow.TaskName,
                TaskID: childRow.TaskID,
                MainId: childRow.MainId,
                Duration: parentRow.Duration,
                EndDate: parentRow.EndDate,
                StartDate: parentRow.StartDate,
                PreStartDate: parentRow.StartDate,
                PreEndDate: parentRow.EndDate,
                // arrDependency: parentRow.arrDependency,
                TaskHolderList: '',
                TaskHolderOrFollower: childRow.TaskHolderOrFollower,
                TaskHolderOrFollowerTxt: childRow.TaskHolderOrFollowerTxt,
                TaskHolderOwnerTxtList: childRow.TaskHolderOwnerTxtList,
                TaskHolderIDList: childRow.TaskHolderIDList,
                FollowerIDList1: childRow.FollowerIDList1,
                FollowerIDList2: childRow.FollowerIDList2,
                TaskHolderBuyer: childRow.TaskHolderBuyer,
                FollowerCube: childRow.FollowerCube,
                FollowerSupplier: childRow.FollowerSupplier,
                arrTaskHolderBuyer: [],
                arrFollowerCube: [],
                arrFollowerSupplier: [],
                TaskHolderBuyerTxt: '',
                FollowerCubeTxt: '',
                FollowerSupplierTxt: '',
                TaskHolderOwnerIdList: '',
                titleEmployee1: childRow.titleEmployee1,
                titleEmployee2: childRow.titleEmployee2,
                titleEmployee3: childRow.titleEmployee3,
                IsTHFirstTime: 0,
                // DependencyCount: 0,
                // IsDependencyOpen: 0,
                // IsTHLeaveDaysOpen: 0,
                //  SelectedDependency: parentRow.SelectedDependency,
                LeaveDays: '',
                LeaveDaysTxt: '',
                StatusID: 1,
                AWBStatus: '',
                IsAddChildTaskOpen: 0,
                StrikeOffOrTrimInfoId: childRow.StrikeOffOrTrimInfoId,
                drpChildTaskList: [],
                IsManualClose: 0,
                IsExFactoryForm: 0,
                ManualCloseComment: '',
                IsShowSubmitButton: childRow.IsShowSubmitButton,
                IsShowApprovalButton: childRow.IsShowApprovalButton,
                ManualCloseTaskwise: childRow.ManualCloseTaskwise,
                ChildActualEndDate: childRow.ChildActualEndDate,
                ManualCloseDate: childRow.ManualCloseDate === null || childRow.ManualCloseDate === "" ? '' : new Date(childRow.ManualCloseDate),
                EndDateChange: 0,
                ManualApprovalType: 0,

                ExistingApprovalType: 0,
                RevicePopup: 0,
                IsReviced: childRow.IsReviced,
                intDependency: childRow.intDependency,
                hasAllDependencyStart: 0,
                hasAllDependencyStartPopup: false,
                DependencyDate: childRow.DependencyDate,
                DependencyComment: childRow.DependencyComment,
                MultiDependencyDate: "",
                MultiDependencyComment: "",
                IsRescheduled: childRow.IsRescheduled,
                IsDependencyStartPopup: false,
                IDuration: childRow.IDuration,
                IEndDate: parentRow.EndDate,
                IStartDate: parentRow.StartDate,
                ReviceSelected: 0,
                RescheduleSelected: 0,
                ReschedulePopup: 0,
                ReviceAdded: 0,
                RescheduleAdded: 0,
                SubRevicedetails:
                {
                    TNATaskReviceId: 0, TNAId: 0, TaskDetailId: 0, ReviceDate: '',
                    ReviceComment: '', TaskName: '', Operation: 0,
                    StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                    Createdby: 0, Type: ''
                },
                SubRescheduledetails:
                {
                    TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '',
                    RescheduleComment: '', TaskName: '', Operation: 0,
                    StartDate: '', EndDate: '', Duration: 0, IsInitialData: 0,
                    Createdby: 0, Type: ''
                },
                SwatchStatus: childRow.SwatchStatus,
                SKUIdList: childRow.SKUIdList,
                PurchaseOrderSkuID: childRow.PurchaseOrderSkuID,
                StyleStrikeOffGarmentSizeIdList: childRow.StyleStrikeOffGarmentSizeIdList,
                hasAutoOpen: false,
                IsChildDepandencyOpen: 0,
                ChildIndex: 0,
                arrDependency: [],
                DependencyCount: 0,
                SelectedDependency: '',
                hasSKUNotMatch: false,
                AssignedDepandency: '',
                IsDepandency: 0
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 0;
            values[index].SubTaskList[subIndex].ChildTaskList.splice(count, 0, EmptyChildList);
            setInputFields(values);
        } else {
            Nodify('Warning!', 'warning', "Please select any one");
        }

    }

    const handleChildRowRemoveFields = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsAddChildTaskOpen = 0;
        values[index].SubTaskList[subIndex].ChildTaskList.splice(childIndex, 1);
        // let TaskIndex = 0;
        // values.map((parent, i) => {
        //     if (parent.IsSingleTask) {
        //         TaskIndex++;
        //     }
        //     values[i].IndexName = i.toString();
        //     values[i].TaskIndex = TaskIndex;
        //     if (parent.IsSingleTask === false) {
        //         parent.SubTaskList && parent.SubTaskList.map((parentChild, j) => {
        //             parentChild.ChildTaskList && parentChild.ChildTaskList.map((child, k) => {
        //                 TaskIndex++;
        //                 values[i].SubTaskList[j].ChildTaskList[k].ParentIndex = j.toString();
        //                 values[i].SubTaskList[j].ChildTaskList[k].ChildIndex = k.toString();
        //                 values[i].SubTaskList[j].ChildTaskList[k].TaskIndex = TaskIndex;

        //             })
        //         })
        //     }
        // });


        setInputFields(values);
        //SetCount(1);
    }


    const ReassignIndexonAddDeleteTask = (index, subIndex, childIndex, AddorRemove, Rowindex) => {

        let Childrowno = 1;
        // if (AddorRemove === "Remove") {
        //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency.splice(childIndex, 1);
        // }

        let TaskDependency =
        {
            TNAChildDependencyID: 0,
            DependentTaskId: getCurrentChildTask.value,
            TNATaskName: getCurrentChildTask.lablel,
            DependencyID: 0,
            LogDays: '',
            IndexName: 0,
            Mainindex: Rowindex,
            StartDate: '',
            EndDate: ''
        }


        values.map((Main, Mainindex) => {

            Main.SubTaskList.map((Child, ChildIndex) => {
                Child.ChildTaskList.map((SubChild, Subchildindex) => {

                    SubChild.SelectedDependency = '';
                    let DependencyShow = '';


                    if (SubChild.arrDependency && SubChild.arrDependency.length > 0) {
                        if (AddorRemove === "Remove") {
                            SubChild.arrDependency.splice((Rowindex - 1), 1);
                        }
                        if (AddorRemove === "Add") {
                            SubChild.arrDependency.splice((Rowindex), 0, TaskDependency);
                        }
                        SubChild.arrDependency.map((Item2, i) => {
                            let DepandencyNumber = Item2.IndexName;
                            let DependentID = Item2.DependencyID;

                            let ParentIndexNo = '';
                            values.map((Main, Mainindex) => {
                                Main.SubTaskList.map((Child, ChildIndex) => {
                                    Child.ChildTaskList.map((SubChild) => {
                                        if (SubChild.ChildIndex === DepandencyNumber) {
                                            ParentIndexNo = Mainindex + 1;
                                        }

                                    })
                                })
                            })


                            if (DependentID !== 0) {
                                if (DependencyShow === '') {
                                    DependencyShow = (ParentIndexNo + "-" + (i + 1)) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")

                                }
                                else {
                                    DependencyShow = DependencyShow + ',' + (ParentIndexNo + "-" + (i + 1)) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")

                                }

                            }
                            //}
                        });
                        SubChild.SelectedDependency = DependencyShow;
                        SubChild.ChildIndex = Childrowno;
                        SubChild.DependencyCount = SubChild.arrDependency.filter(x => x.DependencyID !== 0).length;
                        values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex] = SubChild;

                    }
                    else {
                        SubChild.ChildIndex = Childrowno;
                        values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex] = SubChild;
                    }
                    Childrowno++;
                })

            })

        });
    }



    const ChildDepandencyCalculation = (type, index, subIndex, childIndex) => {

        let Duraion;
        let LeaveDays;
        let titleEmployee1;
        let MultidepItem = [];
        let SetStartdate = new Date();
        let SetEnddate = new Date();

        values.filter(x => x.DetailTypeID !== 4).map((Main, Mainindex) => {
            LeaveDays = Main.LeaveDays;
            titleEmployee1 = Main.titleEmployee1;
            Main.SubTaskList.map((Child, ChildIndex) => {

                Child.ChildTaskList.map((SubChild, Subchildindex) => {
                    Duraion = SubChild.Duration;

                    SubChild.arrDependency.map((Item2, i) => {

                        let DependentID = Item2.DependencyID;
                        let Startdate = Item2.StartDate;
                        let Enddate = Item2.EndDate;
                        let total = 0;
                        let tempEnddate = new Date();
                        let tempStartdate = new Date();
                        let Logdays = Item2.LogDays === '' || Item2.LogDays === undefined ? 0 : parseInt(Item2.LogDays);

                        if (DependentID !== 0) {
                            //  if (Main.DetailTypeID !== 4) {
                            //End to Start
                            if (DependentID === 1) {

                                SetStartdate = addDays(Enddate, 1);
                                if (Logdays !== 0) {
                                    //LogDays Add
                                    SetStartdate = addDays(SetStartdate, parseInt(Logdays));
                                }
                                //Calculation Saturday,Sunday and Holiday Count
                                SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
                                tempEnddate = addDays(SetStartdate, parseInt(Duraion) - 1);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                                for (let l = 0; l < total; l++) {
                                    tempEnddate = addDays(tempEnddate, 1);
                                    tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
                                }

                                SetEnddate = tempEnddate;

                            }
                            //Start to Start
                            if (DependentID === 2) {

                                if (Logdays !== 0) {
                                    SetStartdate = addDays(Startdate, parseInt(Logdays));
                                    SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
                                } else {
                                    //Calculation Saturday,Sunday and Holiday Count
                                    SetStartdate = checkDateBetweenInHolidayAndWE(Startdate, LeaveDays, titleEmployee1, "add");
                                }

                                tempEnddate = addDays(SetStartdate, parseInt(Duraion) - 1);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                                for (let l = 0; l < total; l++) {
                                    tempEnddate = addDays(tempEnddate, 1);
                                    tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
                                }
                                SetEnddate = tempEnddate;

                            }
                            //Start to End
                            if (DependentID === 3) {
                                if (Logdays !== 0) {
                                    SetEnddate = addDays(Startdate, parseInt(Logdays));
                                    SetEnddate = checkDateBetweenInHolidayAndWE(SetEnddate, LeaveDays, titleEmployee1, "add");
                                }
                                else {
                                    SetEnddate = checkDateBetweenInHolidayAndWE(subDays(Startdate, 1), LeaveDays, titleEmployee1, "sub");
                                }
                                tempStartdate = subDays(SetEnddate, parseInt(Duraion) - 1);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                                for (let l = 0; l < total; l++) {
                                    tempStartdate = subDays(tempStartdate, 1);
                                    tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
                                }
                                SetStartdate = tempStartdate;

                            }
                            //End to end
                            if (DependentID === 4) {

                                if (Logdays !== 0) {
                                    //LogDays Add
                                    SetEnddate = addDays(Enddate, parseInt(Logdays));
                                    SetEnddate = checkDateBetweenInHolidayAndWE(SetEnddate, LeaveDays, titleEmployee1, "add");
                                } else {
                                    SetEnddate = checkDateBetweenInHolidayAndWE(Enddate, LeaveDays, titleEmployee1, "sub",);
                                }

                                tempStartdate = subDays(SetEnddate, parseInt(Duraion) - 1);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, index, subIndex, childIndex);
                                for (let l = 0; l < total; l++) {
                                    tempStartdate = subDays(tempStartdate, 1);
                                    tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
                                }
                                SetStartdate = tempStartdate;

                            }
                            //  }
                            //  else {

                            //  }


                            SubChild.StartDate = SetStartdate;
                            SubChild.EndDate = SetEnddate;
                            let AssignedDepandency = DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE"
                            SubChild.AssignedDepandency = AssignedDepandency;
                            SubChild.AssignedStartDate = SetStartdate;
                            SubChild.AssignedEndDate = SetEnddate;

                            MultidepItem.push(
                                {
                                    Startdate: SetStartdate,
                                    Enddate: SetEnddate,
                                    AssignedDepandency: AssignedDepandency,
                                    AssignedStartDate: SetStartdate,
                                    AssignedEndDate: SetEnddate
                                })

                            if (type === "childChange") {
                                values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[i].StartDate = SetStartdate;
                                values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[i].EndDate = SetEnddate;
                            }
                            else {
                                // values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[DepandencyAllIndex.RowIndex - 1].StartDate = SetStartdate;
                                //values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[DepandencyAllIndex.RowIndex - 1].EndDate = SetEnddate;
                            }


                        }

                    })

                    if (MultidepItem.length > 1) {
                        const sorted = MultidepItem.slice().sort((a, b) => b.Enddate - a.Enddate);
                        SubChild.StartDate = sorted[0].Startdate;
                        SubChild.EndDate = sorted[0].Enddate;
                        SubChild.AssignedDepandency = sorted[0].AssignedDepandency;
                        SubChild.AssignedStartDate = sorted[0].AssignedStartDate;
                        SubChild.AssignedEndDate = sorted[0].AssignedEndDate;

                        if (type !== "childChange") {
                            //values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[DepandencyAllIndex.RowIndex - 1].StartDate = sorted[0].Startdate;
                            // values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex].arrDependency[DepandencyAllIndex.RowIndex - 1].EndDate = sorted[0].Enddate;

                        }
                    }


                    values[Mainindex].SubTaskList[ChildIndex].ChildTaskList[Subchildindex] = SubChild;
                    MultidepItem = [];
                })

            })

        });
    }



    // #endregion ------------------  Child Add Fields End --------------------------------

    const handleAddTaskChange = (e, index, childIndex, FieldName) => {

        if (e.target.checked) {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 1;
            let IsSelected = 1;
            let UnselectedRecords = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 0);
            if (UnselectedRecords.length > 0) {
                IsSelected = 0;
            }
            values[index].IsSelectAll = IsSelected;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        } else {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 0;
            values[index].IsSelectAll = 0;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        }
        setInputFields(values);

    }



    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/TNAList', props.location.state);
        } else {
            window.location.reload();
        }
    }





    const filterOption = (option, inputValue) => {
        let trimValue = inputValue.trim();
        if (trimValue === "") {
            return option;
        }

        return option.data.subLabel.toLowerCase().includes(trimValue.toLowerCase());
    };


    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ border: getComment && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }

    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }


    // const UpdateTNA = (e) => {
    //     setButtonLoader(true);
    //     e.preventDefault();
    //     let IsValid = true;

    //     MainValues[0].TNARouteApplyDetails = [];
    //     MainValues[0].TNADependencyDetails = [];
    //     MainValues[0].TNAAdditionalTaskDetails = [];
    //     MainValues[0].TNAChildInformation = [];
    //     MainValues[0].TNASubChildInformation = [];
    //     MainValues[0].TNADocs = [];
    //     MainValues[0].TNAComments = [];
    //     var AllReviceDetails = [];
    //     var AllRescheduleDetails = [];

    //     // MainValues[0].CreationStatus = 2;
    //     MainValues[0].TNAId = props.location.state.params.tnaid;
    //     var getSMSData = inputFields.filter(s => s.strTaskName === 'SMS Submission');
    //     let FilterdRow;
    //     inputFields.map((Info, i) => {
    //         if (Info.TaskID === 0 || Info.TaskType === 0 || Info.Duration === '') {
    //             setButtonLoader(false);
    //             if (IsValid === true) {
    //                 Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
    //                 IsValid = false;
    //             }
    //             setSubmitted(true);
    //             return false;
    //         }
    //         else if (getSMSData.length !== 0 && new Date(getSMSData[0].EndDate) < new Date(Info.EndDate)) {
    //             setButtonLoader(false);
    //             if (IsValid === true) {
    //                 Nodify('Warning!', 'warning', "Some Parent Task End Date greater than SMS Submission End Date.");
    //                 IsValid = false;
    //             }
    //             setSubmitted(true);
    //             return false;
    //         }
    //         else if (getSMSData.length !== 0 && (new Date(getSMSData[0].EndDate).setHours(0, 0, 0, 0) < new Date(Info.EndDate).setHours(0, 0, 0, 0)) &&
    //             (Info.strTaskName !== 'SMS Submission' && Info.strTaskName !== 'SMS Approval')) {
    //             setButtonLoader(false);
    //             if (IsValid === true) {
    //                 Nodify('Warning!', 'warning', "Some Parent Task End Date greater than SMS Submission End Date.");
    //                 IsValid = false;
    //             }
    //             setSubmitted(true);
    //             return false;
    //         }
    //         else {
    //             MainValues[0].TNARouteApplyDetails.push({
    //                 TNARouteApplyID: Info.TNARouteApplyID,
    //                 TaskType: Info.TaskType,
    //                 TaskNameID: Info.TaskID,
    //                 //Duration: Info.Duration,
    //                 Duration: Info.Duration.toString(),
    //                 PlanStartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
    //                 EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
    //                 Dependency: Info.SelectedDependency,
    //                 TaskOwner: Info.TaskHolderOwnerIdList,
    //                 LeaveDays: Info.LeaveDays,
    //                 RouteApplyStatus: Info.StatusID,
    //                 TaskHolderID: Info.TaskHolderOrFollower,
    //                 TaskHolderIDList: Info.TaskHolderBuyer,
    //                 FollowerIDList1: Info.FollowerCube,
    //                 FollowerIDList2: Info.FollowerSupplier,
    //                 IndexName: i.toString(),
    //                 AWBStatus: Info.AWBStatus,
    //                 DependencyStartstop: Info.intDependency,
    //                 // ManualCloseComment: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].TNATaskComments : '',
    //                 // ManualCloseDate: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].Date : null,
    //                 ManualCloseComment: Info.ManualComments,
    //                 ManualCloseDate: Info.ManualCloseDate && convertUTCDateToLocalDate(new Date(Info.ManualCloseDate)),
    //                 //ManualCloseDate: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].Date : null,
    //                 IsReviced: parseInt(Info.IsReviced),
    //                 IsRescheduled: parseInt(Info.IsRescheduled),
    //                 IsReviceAdded: parseInt(Info.ReviceAdded),
    //                 IsRescheduleAdded: parseInt(Info.RescheduleAdded)

    //             });

    //             if (Info.IsReviced === 1) {
    //                 AllReviceDetails.push({
    //                     StartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
    //                     EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
    //                     Duration: parseInt(Info.Duration),
    //                     TNAId: Info.MainRevicedetails.TNAId,
    //                     TaskDetailId: Info.MainRevicedetails.TaskDetailId,
    //                     TaskName: Info.MainRevicedetails.TaskName,
    //                     Operation: 1,
    //                     Type: Info.MainRevicedetails.Type === "Main" ? "Main" : "Sub",
    //                     ReviceComment: Info.MainRevicedetails.ReviceComment,
    //                     Createdby: currentUser.employeeinformationID,

    //                 })

    //             }
    //             if (Info.IsRescheduled === 1) {
    //                 AllRescheduleDetails.push({
    //                     StartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
    //                     EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
    //                     Duration: parseInt(Info.Duration),
    //                     TNAId: Info.MainRescheduledetails.TNAId,
    //                     TaskDetailId: Info.MainRescheduledetails.TaskDetailId,
    //                     TaskName: Info.MainRescheduledetails.TaskName,
    //                     Operation: 1,
    //                     Type: Info.MainRescheduledetails.Type === "Main" ? "Main" : "Sub",
    //                     RescheduleComment: Info.MainRescheduledetails.RescheduleComment,
    //                     Createdby: currentUser.employeeinformationID,

    //                 })

    //             }

    //             let TaskName = Info.strTaskName;
    //             if (Info.arrDependency && Info.arrDependency.length > 0) {
    //                 const arrDependency = Info.arrDependency.filter(d => d.TNATaskName !== '');
    //                 // MainValues[0].TNADependencyDetails = [...MainValues[0].TNADependencyDetails, ...arrDependency];
    //                 Info.arrDependency.forEach((child, j) => {
    //                     if (child.TNATaskName !== '') {
    //                         child.IndexName = i.toString();
    //                         MainValues[0].TNADependencyDetails.push(child);
    //                     }
    //                 });
    //             }

    //             if (Info.FollowupDetails && Info.FollowupDetails.length > 0) {
    //                 // MainValues[0].TNAAdditionalTaskDetails = [...MainValues[0].TNAAdditionalTaskDetails, ...Info.FollowupDetails];
    //                 Info.FollowupDetails.forEach((child, k) => {
    //                     child.IndexName = i.toString();
    //                     MainValues[0].TNAAdditionalTaskDetails.push(child);
    //                 });
    //             }
    //             if (Info.arrAttachment) {

    //                 Info.arrAttachment.forEach((child) => {

    //                     child.TaskIndexName = child.TaskIndexName.toString();
    //                     MainValues[0].TNADocs.push(child);
    //                 });
    //                 //MainValues[0].TNADocs = [...MainValues[0].TNADocs, ...Info.arrAttachment];
    //             }

    //             if (Info.arrComment) {
    //                 MainValues[0].TNAComments = [...MainValues[0].TNAComments, ...Info.arrComment];
    //             }


    //             let ChildStatus = 0;
    //             let isTwoTreeView = false;
    //             if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
    //                 ChildStatus = 1;
    //             }
    //             else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
    //                 TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
    //                 TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
    //                 ChildStatus = 2;

    //             } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
    //                 TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
    //                 TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
    //                 TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

    //                 ChildStatus = 3;

    //             } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
    //                 TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
    //                 ChildStatus = 4;
    //             }
    //             else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
    //                 TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
    //                 ChildStatus = 5;
    //             }
    //             if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER ||
    //                 TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION ||
    //                 TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH ||
    //                 TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
    //                 isTwoTreeView = true;
    //             }

    //             if (Info.IsSingleTask === false) {

    //                 Info.SubTaskList && Info.SubTaskList.map((Sub, j) => {

    //                     if (isTwoTreeView === false) {
    //                         MainValues[0].TNAChildInformation.push({
    //                             Id: (Sub.ParentId === null || Sub.ParentId === undefined) ? 0 : Sub.ParentId,
    //                             TaskNameID: Sub.TaskID,
    //                             ChildId: Sub.MainId,
    //                             TaskIndexName: i.toString(),
    //                             ChildIndexName: j.toString(),
    //                             StrikeOffOrTrimLogoId: (Sub.StrikeOffOrTrimInfoId === undefined || Sub.StrikeOffOrTrimInfoId === null) ? 0 : parseInt(Sub.StrikeOffOrTrimInfoId),
    //                             ChildStatus: ChildStatus,
    //                         });
    //                     }

    //                     Sub.ChildTaskList && Sub.ChildTaskList.map((Child, k) => {
    //                         if (Child.Duration === '') {
    //                             setButtonLoader(false);
    //                             if (IsValid === true) {
    //                                 Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
    //                                 IsValid = false;
    //                             }
    //                             setSubmitted(true);
    //                             return false;
    //                         }
    //                         else if (getSMSData.length !== 0 && new Date(getSMSData[0].EndDate) < new Date(Child.EndDate)) {
    //                             setButtonLoader(false);
    //                             if (IsValid === true) {
    //                                 Nodify('Warning!', 'warning', "Some Child Task End Date greater than SMS Submission End Date.");
    //                                 IsValid = false;
    //                             }
    //                             setSubmitted(true);
    //                             return false;
    //                         }
    //                         else {
    //                             var taskname = '';
    //                             if (Child.TaskName === 'Lab Dip Submission') {
    //                                 taskname = 'Lab Dip Approval';
    //                             }
    //                             else if (Child.TaskName === 'Lab Dip Approval') {
    //                                 taskname = 'Lab Dip Submission';
    //                             }
    //                             else if (Child.TaskName === 'Strike off Submission (Local Development)') {
    //                                 taskname = 'Strike off Approval (Local Development)';
    //                             }
    //                             else if (Child.TaskName === 'Strike off Approval (Local Development)') {
    //                                 taskname = 'Strike off Submission (Local Development)';
    //                             }
    //                             else if (Child.TaskName === 'Trim Submission (Local Development)') {
    //                                 taskname = 'Trim Approval (Local Development)';
    //                             }
    //                             else if (Child.TaskName === 'Trim Approval (Local Development)') {
    //                                 taskname = 'Trim Submission (Local Development)';
    //                             }
    //                             else if (Child.TaskName === 'Proto Sample Submission') {
    //                                 taskname = 'Proto Sample Approval';
    //                             }
    //                             else if (Child.TaskName === 'Proto Sample Approval') {
    //                                 taskname = 'Proto Sample Submission';
    //                             }
    //                             else if (Child.TaskName === 'SMS Submission') {
    //                                 taskname = 'SMS Approval';
    //                             }
    //                             else if (Child.TaskName === 'SMS Approval') {
    //                                 taskname = 'SMS Submission';
    //                             }
    //                             else if (Child.TaskName === 'Fit Sample Submission') {
    //                                 taskname = 'Fit Sample Approval';
    //                             }
    //                             else if (Child.TaskName === 'Fit Sample Approval') {
    //                                 taskname = 'Fit Sample Submission';
    //                             }
    //                             else if (Child.TaskName === 'Appearance After Wash') {
    //                                 taskname = 'Appearance After Wash';
    //                             }

    //                             MainValues[0].TNASubChildInformation.push({
    //                                 Id: (Child.ChildId === null || Child.ChildId === undefined) ? 0 : Child.ChildId,
    //                                 TaskNameID: Child.TaskID,
    //                                 SubChildId: Child.SubId,
    //                                 FabricOrStrikeOffOrTrimId: (Child.StrikeOffOrTrimInfoId === undefined || Child.StrikeOffOrTrimInfoId === null) ? 0 : parseInt(Child.StrikeOffOrTrimInfoId),
    //                                 Duration: Child.Duration.toString(),
    //                                 PlanStartDate: convertUTCDateToLocalDate(new Date(Child.StartDate)),
    //                                 EndDate: convertUTCDateToLocalDate(new Date(Child.EndDate)),
    //                                 RouteApplyStatus: Child.StatusID,
    //                                 TaskIndexName: i.toString(),
    //                                 ChildIndexName: j.toString(),
    //                                 SubChildIndexName: k.toString(),
    //                                 SubChildStatus: ChildStatus,
    //                                 ManualCloseComment: Child.ManualCloseComment,
    //                                 ManualCloseDate: Child.ManualCloseDate,
    //                                 ActualEndDate: Child.ChildActualEndDate,
    //                                 IsReviced: parseInt(Child.IsReviced),
    //                                 IsRescheduled: parseInt(Child.IsRescheduled),

    //                                 DependencyDate: Child.DependencyDate ? convertUTCDateToLocalDate(new Date(Child.DependencyDate)) : null,
    //                                 DependencyComment: Child.DependencyComment,
    //                                 SKUIdList: Child.SKUIdList,
    //                                 IsReviceAdded: parseInt(Info.ReviceAdded),
    //                                 IsRescheduleAdded: parseInt(Info.RescheduleAdded)

    //                             });
    //                             if (parseInt(Child.ReviceAdded) === 1) {

    //                                 // if (Child.TaskName === 'Lab Dip Submission' || Child.TaskName === 'Lab Dip Approval'
    //                                 //     || Child.TaskName === 'Strike off Submission (Local Development)' || Child.TaskName === 'Strike off Approval (Local Development)'
    //                                 //     || Child.TaskName === 'Trim Submission (Local Development)' || Child.TaskName === 'Trim Approval (Local Development)'
    //                                 // ) {
    //                                 inputFields.map(main => {
    //                                     main.SubTaskList.map(parentrow => {
    //                                         FilterdRow = parentrow.ChildTaskList.filter(x => x.MainId === Child.MainId && x.ChildId !== Child.ChildId
    //                                             && x.Name === Child.Name && x.TaskName === taskname);
    //                                         if (FilterdRow.length !== 0 && FilterdRow[0].ReviceAdded === 0) {
    //                                             Nodify('Warning!', 'warning',
    //                                                 "Revice Should be given for Task (" + FilterdRow[0].TaskName + ") Subtask -(" + FilterdRow[0].Name + ")");
    //                                             IsValid = false;
    //                                             return false;
    //                                         }

    //                                     })
    //                                 })

    //                                 // }
    //                             }
    //                             if (parseInt(Child.RescheduleAdded) === 1) {

    //                                 // if (Child.TaskName === 'Lab Dip Submission' || Child.TaskName === 'Lab Dip Approval'
    //                                 //     || Child.TaskName === 'Strike off Submission (Local Development)' || Child.TaskName === 'Strike off Approval (Local Development)'
    //                                 //     || Child.TaskName === 'Trim Submission (Local Development)' || Child.TaskName === 'Trim Approval (Local Development)'
    //                                 // ) {
    //                                 inputFields.map(main => {
    //                                     main.SubTaskList.map(parentrow => {
    //                                         FilterdRow = parentrow.ChildTaskList.filter(x => x.MainId === Child.MainId && x.ChildId !== Child.ChildId
    //                                             && x.Name === Child.Name && x.TaskName === taskname);
    //                                         if (FilterdRow.length !== 0 && FilterdRow[0].RescheduleAdded === 0) {
    //                                             Nodify('Warning!', 'warning',
    //                                                 "Reschedule Should be given for Task (" + FilterdRow[0].TaskName + ") Subtask -(" + FilterdRow[0].Name + ")");
    //                                             IsValid = false;
    //                                             return false;
    //                                         }

    //                                     })
    //                                 })

    //                                 //}
    //                             }
    //                             if (IsValid) {
    //                                 if (Child.IsReviced === 1) {
    //                                     AllReviceDetails.push({
    //                                         StartDate: convertUTCDateToLocalDate(new Date(Child.StartDate)),
    //                                         EndDate: convertUTCDateToLocalDate(new Date(Child.EndDate)),
    //                                         Duration: parseInt(Child.Duration),
    //                                         TNAId: Child.SubRevicedetails.TNAId,
    //                                         TaskDetailId: Child.SubRevicedetails.TaskDetailId,
    //                                         TaskName: Child.SubRevicedetails.TaskName,
    //                                         Operation: 1,
    //                                         Type: Child.SubRevicedetails.Type === "Main" ? "Main" : "Sub",
    //                                         ReviceComment: Child.SubRevicedetails.ReviceComment,
    //                                         Createdby: currentUser.employeeinformationID,

    //                                     })

    //                                 }
    //                                 if (Child.IsRescheduled === 1) {
    //                                     AllRescheduleDetails.push({
    //                                         StartDate: convertUTCDateToLocalDate(new Date(Child.StartDate)),
    //                                         EndDate: convertUTCDateToLocalDate(new Date(Child.EndDate)),
    //                                         Duration: parseInt(Child.Duration),
    //                                         TNAId: Child.SubRescheduledetails.TNAId,
    //                                         TaskDetailId: Child.SubRescheduledetails.TaskDetailId,
    //                                         TaskName: Child.SubRescheduledetails.TaskName,
    //                                         Operation: 1,
    //                                         Type: Child.SubRescheduledetails.Type === "Main" ? "Main" : "Sub",
    //                                         RescheduleComment: Child.SubRescheduledetails.RescheduleComment,
    //                                         Createdby: currentUser.employeeinformationID,

    //                                     })

    //                                 }
    //                             }
    //                         }


    //                     });
    //                 });

    //             }

    //         }
    //     });

    //     if (IsValid) {


    //         AllReviceDetails.filter(c => c.ReviceComment !== undefined && c.ReviceComment !== '').forEach(element => {
    //             TnaService.InsertUpdateDeleteTNATaskRevice(element).then((res) => {
    //                 if (res.data.outputResult === "1") {
    //                 }
    //             })
    //         })

    //         AllRescheduleDetails.filter(c => c.RescheduleComment !== undefined && c.RescheduleComment !== '').forEach(element => {
    //             TnaService.InsertUpdateDeleteTNATaskReschedule(element).then((res) => {
    //                 if (res.data.outputResult === "1") {
    //                 }
    //             })
    //         })
    //         TnaService.UpdateTNA(MainValues[0])
    //             .then(res => {
    //                 if (res.data.outputResult === "2") {
    //                     //props.CallBackTNAAdd('Edit');
    //                     // props.history.push('/TNAList');
    //                     props.history.push({
    //                         pathname: "/TNAList",
    //                         state: {
    //                             message: "Edit"
    //                         }
    //                     });

    //                 }

    //                 else if (res.data.outputResult === "-1") {

    //                     Nodify('Warning!', 'warning', 'TNA name already Exist');
    //                 }
    //                 setButtonLoader(false);
    //             })
    //             .catch(e => {
    //                 console.log(e);
    //                 setButtonLoader(false);
    //             });
    //     }
    // }
    const Delcallback1 = (value) => {

        if (value === true) { }
        else {
            dispatch(loadTNADetailList({ TNAId: props.location.state.params.tnaid }));
            dispatch(loadTNASupplierEmployeeList(getTNASupplierID));
        }
        setid({ showPopupConfirm: false, Params: [] });

    }

    const UpdateTNA = (e) => {
        ;
        setButtonLoader(true);
        showLoader();
        e.preventDefault();
        let IsValid = true;

        MainValues[0].TNARouteApplyDetails = [];
        MainValues[0].TNADependencyDetails = [];
        MainValues[0].TNAAdditionalTaskDetails = [];
        MainValues[0].TNAChildInformation = [];
        MainValues[0].TNASubChildInformation = [];
        MainValues[0].TypTNAChildDependencyDetails = [];
        MainValues[0].TNADocs = [];
        MainValues[0].TNAComments = [];
        var AllReviceDetails = [];
        var AllRescheduleDetails = [];
        // MainValues[0].CreationStatus = 2;
        MainValues[0].TNAId = props.location.state.params.tnaid;

        var getSMSData = inputFields.filter(s => s.strTaskName === 'SMS Submission');

        inputFields.map((Info, i) => {

            if (Info.TaskID === 0 || Info.TaskType === 0 || Info.Duration === '' || Info.TaskHolderOwnerIdList.length === 0) {
                setButtonLoader(false);
                if (IsValid === true) {
                    Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                    IsValid = false;
                }
                setSubmitted(true);
                return false;
            }
            else if (getSMSData.length !== 0 && (new Date(getSMSData[0].EndDate).setHours(0, 0, 0, 0) < new Date(Info.EndDate).setHours(0, 0, 0, 0)) &&
                (Info.strTaskName !== 'SMS Submission' && Info.strTaskName !== 'SMS Approval')) {
                setButtonLoader(false);
                if (IsValid === true) {
                    Nodify('Warning!', 'warning', "Some Parent Task End Date greater than SMS Submission End Date.");
                    IsValid = false;
                }
                setSubmitted(true);
                return false;
            }
            else {
                MainValues[0].TNARouteApplyDetails.push({
                    TNARouteApplyID: Info.TNARouteApplyID,
                    TaskType: Info.TaskType,
                    TaskNameID: Info.TaskID,
                    TaskName: Info.strTaskName,
                    //Duration: Info.Duration,
                    Duration: Info.Duration.toString(),
                    PlanStartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
                    EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
                    Dependency: Info.SelectedDependency,
                    TaskOwner: Info.TaskHolderOwnerIdList,
                    LeaveDays: Info.LeaveDays,
                    RouteApplyStatus: Info.StatusID,
                    TaskHolderID: Info.TaskHolderOrFollower,
                    TaskHolderIDList: Info.TaskHolderBuyer,
                    FollowerIDList1: Info.FollowerCube,
                    FollowerIDList2: Info.FollowerSupplier,
                    IndexName: i.toString(),
                    AWBStatus: Info.AWBStatus,
                    DependencyStartstop: Info.intDependency,
                    // ManualCloseComment: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].TNATaskComments : '',
                    // ManualCloseDate: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].Date : null,
                    ManualCloseComment: Info.ManualComments,
                    //ManualCloseDate: convertUTCDateToLocalDate(new Date(Info.ManualCloseDate)),
                    ManualCloseDate: Info.ManualCloseDate,
                    SingleOrMultiTask: Info.IsSingleTask ? 1 : 2,
                    IsReviced: parseInt(Info.IsReviced),
                    IsRescheduled: parseInt(Info.IsRescheduled),

                    //ManualCloseDate: Info.arrManual !== undefined && Info.arrManual.length > 0 ? Info.arrManual[0].Date : null,
                    DependencyDate: Info.DependencyDate ? convertUTCDateToLocalDate(new Date(Info.DependencyDate)) : null,
                    DependencyComment: Info.DependencyComment,
                    IsExpanded: false,
                    IsReviceAdded: parseInt(Info.ReviceAdded),
                    IsRescheduleAdded: parseInt(Info.RescheduleAdded)

                });

                if (Info.IsReviced === 1) {
                    AllReviceDetails.push({
                        StartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
                        EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
                        Duration: parseInt(Info.Duration),
                        TNAId: Info.MainRevicedetails.TNAId,
                        TaskDetailId: Info.MainRevicedetails.TaskDetailId,
                        TaskName: Info.MainRevicedetails.TaskName,
                        Operation: 1,
                        Type: Info.MainRevicedetails.Type === "Main" ? "Main" : "Sub",
                        ReviceComment: Info.MainRevicedetails.ReviceComment,
                        Createdby: currentUser.employeeinformationID,

                    })

                }
                if (Info.IsRescheduled === 1) {
                    AllRescheduleDetails.push({
                        StartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
                        EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
                        Duration: parseInt(Info.Duration),
                        TNAId: Info.MainRescheduledetails.TNAId,
                        TaskDetailId: Info.MainRescheduledetails.TaskDetailId,
                        TaskName: Info.MainRescheduledetails.TaskName,
                        Operation: 1,
                        Type: Info.MainRescheduledetails.Type === "Main" ? "Main" : "Sub",
                        RescheduleComment: Info.MainRescheduledetails.RescheduleComment,
                        Createdby: currentUser.employeeinformationID,

                    })

                }

                let TaskName = Info.strTaskName;
                if (Info.arrDependency && Info.arrDependency.length > 0) {
                    const arrDependency = Info.arrDependency.filter(d => d.TNATaskName !== '');
                    // MainValues[0].TNADependencyDetails = [...MainValues[0].TNADependencyDetails, ...arrDependency];
                    Info.arrDependency.forEach((child, j) => {
                        if (child.TNATaskName !== '') {
                            child.IndexName = i.toString();
                            MainValues[0].TNADependencyDetails.push(child);
                        }
                    });
                }

                if (Info.FollowupDetails && Info.FollowupDetails.length > 0) {
                    // MainValues[0].TNAAdditionalTaskDetails = [...MainValues[0].TNAAdditionalTaskDetails, ...Info.FollowupDetails];
                    Info.FollowupDetails.forEach((child, k) => {
                        child.IndexName = i.toString();
                        MainValues[0].TNAAdditionalTaskDetails.push(child);
                    });
                }
                if (Info.arrAttachment) {

                    Info.arrAttachment.forEach((child) => {

                        child.TaskIndexName = child.TaskIndexName.toString();
                        MainValues[0].TNADocs.push(child);
                    });
                    //MainValues[0].TNADocs = [...MainValues[0].TNADocs, ...Info.arrAttachment];
                }

                if (Info.arrComment) {
                    MainValues[0].TNAComments = [...MainValues[0].TNAComments, ...Info.arrComment];
                }


                let ChildStatus = 0;
                let isTwoTreeView = false;
                let FilterdRow;
                if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                    ChildStatus = 1;
                }
                else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                    TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                    TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                    ChildStatus = 2;

                } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
                    TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                    TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
                    TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

                    ChildStatus = 3;

                } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
                    TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                    ChildStatus = 4;
                }
                else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
                    TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                    ChildStatus = 5;
                } else if (Info.DetailTypeID === 4) {
                    ChildStatus = 6;
                }
                else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                    ChildStatus = 7;
                }
                else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                    ChildStatus = 8;
                }

                if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER ||
                    TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION ||
                    TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH ||
                    TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                    isTwoTreeView = true;
                }

                if (Info.IsSingleTask === false) {

                    Info.SubTaskList && Info.SubTaskList.map((Sub, j) => {
                        if (isTwoTreeView === false) {
                            MainValues[0].TNAChildInformation.push({
                                Id: (Sub.ParentId === null || Sub.ParentId === undefined) ? 0 : Sub.ParentId,
                                TaskNameID: Sub.TaskID,
                                ChildId: Sub.MainId,
                                TaskIndexName: i.toString(),
                                ChildIndexName: j.toString(),
                                StrikeOffOrTrimLogoId: (Sub.StrikeOffOrTrimInfoId === undefined || Sub.StrikeOffOrTrimInfoId === null) ? 0 : parseInt(Sub.StrikeOffOrTrimInfoId),
                                ChildStatus: ChildStatus,
                            });
                        }

                        Sub.ChildTaskList && Sub.ChildTaskList.map((Child, k) => {
                            if (Child.Duration === '') {
                                setButtonLoader(false);
                                if (IsValid === true) {
                                    Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                                    IsValid = false;
                                }
                                setSubmitted(true);
                                return false;
                            }

                            else if (getSMSData.length !== 0 && (new Date(getSMSData[0].EndDate).setHours(0, 0, 0, 0) < new Date(Child.EndDate).setHours(0, 0, 0, 0))
                                && (Child.TaskName !== 'SMS Submission' && Child.TaskName !== 'SMS Approval')) {
                                setButtonLoader(false);
                                if (IsValid === true) {
                                    Nodify('Warning!', 'warning', "Some Child Task End Date greater than SMS Submission End Date.");
                                    IsValid = false;
                                }
                                setSubmitted(true);
                                return false;
                            }

                            else {
                                var taskname = '';
                                if (Child.TaskName === 'Lab Dip Submission') {
                                    taskname = 'Lab Dip Approval';
                                }
                                else if (Child.TaskName === 'Lab Dip Approval') {
                                    taskname = 'Lab Dip Submission';
                                }
                                else if (Child.TaskName === 'Strike off Submission (Local Development)') {
                                    taskname = 'Strike off Approval (Local Development)';
                                }
                                else if (Child.TaskName === 'Strike off Approval (Local Development)') {
                                    taskname = 'Strike off Submission (Local Development)';
                                }
                                else if (Child.TaskName === 'Trim Submission (Local Development)') {
                                    taskname = 'Trim Approval (Local Development)';
                                }
                                else if (Child.TaskName === 'Trim Approval (Local Development)') {
                                    taskname = 'Trim Submission (Local Development)';
                                }
                                else if (Child.TaskName === 'Proto Sample Submission') {
                                    taskname = 'Proto Sample Approval';
                                }
                                else if (Child.TaskName === 'Proto Sample Approval') {
                                    taskname = 'Proto Sample Submission';
                                }
                                else if (Child.TaskName === 'SMS Submission') {
                                    taskname = 'SMS Approval';
                                }
                                else if (Child.TaskName === 'SMS Approval') {
                                    taskname = 'SMS Submission';
                                }
                                else if (Child.TaskName === 'Fit Sample Submission') {
                                    taskname = 'Fit Sample Approval';
                                }
                                else if (Child.TaskName === 'Fit Sample Approval') {
                                    taskname = 'Fit Sample Submission';
                                }
                                else if (Child.TaskName === 'Appearance After Wash') {
                                    taskname = 'Appearance After Wash';
                                }
                                else if (Child.TaskName === 'Strike off Test') {
                                    taskname = 'Strike off Test';
                                }
                                else if (Child.TaskName === 'Trim Test') {
                                    taskname = 'Trim Test';
                                }

                                else if (Child.TaskName === 'size set Submission') {
                                    taskname = 'size set Approval';
                                }
                                else if (Child.TaskName === 'size set Approval') {
                                    taskname = 'size set Submission';
                                }
                                else if (Child.TaskName === 'pre production Submission') {
                                    taskname = 'pre production Approval';
                                }
                                else if (Child.TaskName === 'pre production Approval') {
                                    taskname = 'pre production Submission';
                                }
                                else if (Child.TaskName === 'shipment Submission') {
                                    taskname = 'shipment Approval';
                                }
                                else if (Child.TaskName === 'shipment Approval') {
                                    taskname = 'shipment Submission';
                                }
                                else if (Child.TaskName === 'photo Submission') {
                                    taskname = 'photo Approval';
                                }
                                else if (Child.TaskName === 'photo Approval') {
                                    taskname = 'photo Submission';
                                }
                                MainValues[0].TNASubChildInformation.push({
                                    Id: (Child.ChildId === null || Child.ChildId === undefined) ? 0 : Child.ChildId,
                                    TaskNameID: Child.TaskID,
                                    SubChildId: Child.SubId,
                                    FabricOrStrikeOffOrTrimId: (Child.StrikeOffOrTrimInfoId === undefined || Child.StrikeOffOrTrimInfoId === null) ? 0 : parseInt(Child.StrikeOffOrTrimInfoId),
                                    Duration: Child.Duration.toString(),
                                    PlanStartDate: convertUTCDateToLocalDate(new Date(Child.StartDate)),
                                    EndDate: convertUTCDateToLocalDate(new Date(Child.EndDate)),
                                    RouteApplyStatus: Child.StatusID,
                                    TaskIndexName: i.toString(),
                                    ChildIndexName: j.toString(),
                                    SubChildIndexName: k.toString(),
                                    SubChildStatus: ChildStatus,
                                    ManualCloseComment: Child.ManualCloseComment,
                                    // ManualCloseDate: convertUTCDateToLocalDate(new Date(Child.ManualCloseDate)),
                                    // ActualEndDate: convertUTCDateToLocalDate(new Date(Child.ChildActualEndDate)),
                                    ManualCloseDate: Child.ManualCloseDate,
                                    ActualEndDate: Child.ChildActualEndDate,
                                    IsReviced: parseInt(Child.IsReviced),
                                    IsRescheduled: parseInt(Child.IsRescheduled),

                                    DependencyDate: Child.DependencyDate ? convertUTCDateToLocalDate(new Date(Child.DependencyDate)) : null,
                                    DependencyComment: Child.DependencyComment,
                                    SKUIdList: Child.SKUIdList,
                                    PurchaseOrderSkuID: Child.PurchaseOrderSkuID,
                                    StyleStrikeOffGarmentSizeIdList: Child.StyleStrikeOffGarmentSizeIdList,
                                    IsReviceAdded: parseInt(Child.ReviceAdded),
                                    IsRescheduleAdded: parseInt(Child.RescheduleAdded),
                                    Dependency: Child.SelectedDependency,
                                    DepandencyCount: Child.DependencyCount,
                                    AssignedDepandency: Child.AssignedDepandency,
                                    //LeaveDays: Child.LeaveDays,


                                });

                                Child.arrDependency && Child.arrDependency.map((item, i) => {

                                    MainValues[0].TypTNAChildDependencyDetails.push({
                                        TNAChildDependencyID: item.TNAChildDependencyID,
                                        TNAID: props.location.state.params.tnaid,
                                        DependentTaskId: item.DependentTaskId,
                                        DependencyID: item.DependencyID,
                                        LogDays: item.LogDays !== undefined ? item.LogDays.toString() : '',
                                        IndexName: parseInt(i + 1),
                                        MainChildIndex: Child.ChildIndex,
                                        MainChildId: (Child.ChildId === null || Child.ChildId === undefined) ? 0 : Child.ChildId,
                                        StartDate: convertUTCDateToLocalDate(new Date(item.StartDate)),
                                        EndDate: convertUTCDateToLocalDate(new Date(item.EndDate))
                                    })



                                })
                                if (parseInt(Child.ReviceAdded) === 1) {

                                    // if (Child.TaskName === 'Lab Dip Submission' || Child.TaskName === 'Lab Dip Approval'
                                    //     || Child.TaskName === 'Strike off Submission (Local Development)' || Child.TaskName === 'Strike off Approval (Local Development)'
                                    //     || Child.TaskName === 'Trim Submission (Local Development)' || Child.TaskName === 'Trim Approval (Local Development)'
                                    // ) {
                                    inputFields.map(main => {
                                        main.SubTaskList.map(parentrow => {
                                            FilterdRow = parentrow.ChildTaskList.filter(x => x.MainId === Child.MainId && x.ChildId !== Child.ChildId
                                                && x.Name === Child.Name && x.TaskName === taskname);
                                            if (FilterdRow.length !== 0 && FilterdRow[0].ReviceAdded === 0) {
                                                Nodify('Warning!', 'warning',
                                                    "Revice Should be given for Task (" + FilterdRow[0].TaskName + ") Subtask -(" + FilterdRow[0].Name + ")");
                                                IsValid = false;
                                                return false;
                                            }

                                        })
                                    })

                                    // }
                                }
                                if (parseInt(Child.RescheduleAdded) === 1) {

                                    // if (Child.TaskName === 'Lab Dip Submission' || Child.TaskName === 'Lab Dip Approval'
                                    //     || Child.TaskName === 'Strike off Submission (Local Development)' || Child.TaskName === 'Strike off Approval (Local Development)'
                                    //     || Child.TaskName === 'Trim Submission (Local Development)' || Child.TaskName === 'Trim Approval (Local Development)'
                                    // ) {
                                    inputFields.map(main => {
                                        main.SubTaskList.map(parentrow => {
                                            FilterdRow = parentrow.ChildTaskList.filter(x => x.MainId === Child.MainId && x.ChildId !== Child.ChildId
                                                && x.Name === Child.Name && x.TaskName === taskname);
                                            if (FilterdRow.length !== 0 && FilterdRow[0].RescheduleAdded === 0) {
                                                Nodify('Warning!', 'warning',
                                                    "Reschedule Should be given for Task (" + FilterdRow[0].TaskName + ") Subtask -(" + FilterdRow[0].Name + ")");
                                                IsValid = false;
                                                return false;
                                            }

                                        })
                                    })

                                    //}
                                }
                                if (IsValid) {
                                    ;
                                    if (Child.IsReviced === 1) {
                                        AllReviceDetails.push({
                                            StartDate: convertUTCDateToLocalDate(new Date(Child.StartDate)),
                                            EndDate: convertUTCDateToLocalDate(new Date(Child.EndDate)),
                                            Duration: parseInt(Child.Duration),
                                            TNAId: Child.SubRevicedetails.TNAId,
                                            TaskDetailId: Child.SubRevicedetails.TaskDetailId,
                                            TaskName: Child.SubRevicedetails.TaskName,
                                            Operation: 1,
                                            Type: Child.SubRevicedetails.Type === "Main" ? "Main" : "Sub",
                                            ReviceComment: Child.SubRevicedetails.ReviceComment,
                                            Createdby: currentUser.employeeinformationID,

                                        })

                                    }
                                    if (Child.IsRescheduled === 1) {
                                        AllRescheduleDetails.push({
                                            StartDate: convertUTCDateToLocalDate(new Date(Child.StartDate)),
                                            EndDate: convertUTCDateToLocalDate(new Date(Child.EndDate)),
                                            Duration: parseInt(Child.Duration),
                                            TNAId: Child.SubRescheduledetails.TNAId,
                                            TaskDetailId: Child.SubRescheduledetails.TaskDetailId,
                                            TaskName: Child.SubRescheduledetails.TaskName,
                                            Operation: 1,
                                            Type: Child.SubRescheduledetails.Type === "Main" ? "Main" : "Sub",
                                            RescheduleComment: Child.SubRescheduledetails.RescheduleComment,
                                            Createdby: currentUser.employeeinformationID,

                                        })

                                    }
                                }
                            }


                        });
                    });

                }



            }
        });
        // When sub task missing for parent task (functionalities)
        let taskNameList = '';

        if (MainValues[0].TNARouteApplyDetails !== undefined) {
            let routeInfo = MainValues[0].TNARouteApplyDetails;
            let childList = MainValues[0].TNASubChildInformation;

            routeInfo.map((Info, i) => {
                Info.IsChildStatus = false;
                let IsSingleTask = !!!TNAMultiTask.find(element => element.toLowerCase() === Info.TaskName.toLowerCase());
                if (IsSingleTask === false) {
                    childList && childList.map(d => d.TaskNameID === Info.TaskNameID).map((Child, k) => {
                        if (Child) { Info.IsChildStatus = true; }
                    })
                    if (Info.IsChildStatus === false) {
                        if (taskNameList === "") {
                            taskNameList = Info.TaskNameID;
                        } else {
                            taskNameList = taskNameList + ", " + Info.TaskNameID;
                        }
                    }
                }
            })
        }
        // End

        if (taskNameList === '') {
            if (IsValid) {

                AllReviceDetails.filter(c => c.ReviceComment !== undefined && c.ReviceComment !== '').forEach(element => {
                    TnaService.InsertUpdateDeleteTNATaskRevice(element).then((res) => {
                        if (res.data.outputResult === "1") {
                        }
                    })
                })

                AllRescheduleDetails.filter(c => c.RescheduleComment !== undefined && c.RescheduleComment !== '').forEach(element => {
                    TnaService.InsertUpdateDeleteTNATaskReschedule(element).then((res) => {
                        if (res.data.outputResult === "1") {
                        }
                    })
                })


                TnaService.UpdateTNA(MainValues[0])
                    .then(res => {

                        if (res.data.outputResult === "2") {
                            res.data.Func = 'View';
                            //setPage('TNAEdit');
                            res.data.tabPage = 'TNAView';
                            setid({ showPopupConfirm: true, Params: res.data });
                            hideLoader();

                            // props.history.push({
                            //     pathname: "/TNAList",
                            //     state: {
                            //         message: "Edit"
                            //     }
                            // });

                        } else if (res.data.outputResult === "-1") {
                            Nodify('Warning!', 'warning', 'TNA name already Exist');
                        }
                        setButtonLoader(false);
                    })
                    .catch(e => {
                        console.log(e);
                        setButtonLoader(false);
                    });
            }
            else {
                setSubmitted(true);
                hideLoader();
                return false;


            }
        }
        else {
            Nodify('Warning!', 'warning', "Please remove irrelevent task.");

        }
    }
    // #region   ------------- **** Calculation Begin ***** --------------------------

    //Main Calculation Function
    const commonCalculation = (isDuration, isEndDate, index) => {
        values.map((Item, i) => {
            // Parent Calculation call
            commonCalculationParentAndChild(Item, Item, i, 0, 0, true, isDuration, isEndDate, i);
            Item.SubTaskList && Item.SubTaskList.map((Item2, p) => {
                Item2.ChildTaskList && Item2.ChildTaskList.map((Item3, c) => {
                    // Child Calculation call
                    commonCalculationParentAndChild(Item, Item3, i, p, c, false, isDuration, isEndDate, i);
                    leavedayscountcalc(Item3.StartDate, Item3.EndDate, Item3.LeaveDays, Item3.titleEmployee1, i, p, c, false)

                });
            });
        });


        setTimeout(() => {
            ResetParentTakDate()
        }, 5000)
        setInputFields(values);
    }

    const ResetParentTakDate = () => {
        values.map((item1, index) => {
            if (item1.DetailTypeID !== 1) {
                let allSubTask = [];
                item1.SubTaskList && item1.SubTaskList.map((Item2, p) => {
                    if (allSubTask.length === 0) {
                        allSubTask = [...Item2.ChildTaskList];
                    } else {
                        allSubTask = [...allSubTask, ...Item2.ChildTaskList];
                    }
                });
                let max = allSubTask && allSubTask.reduce(function (a, b) {
                    return Math.max(a, parseInt(b.Duration));
                }, 0);
                if (allSubTask.length > 0) {
                    values[index].Duration = max;
                    const sortedStartdate = allSubTask.slice().sort((a, b) => b.StartDate - a.StartDate);
                    const sortedEnddate = allSubTask.slice().sort((a, b) => b.EndDate - a.EndDate);
                    values[index].StartDate = sortedStartdate[0].StartDate;
                    values[index].EndDate = sortedEnddate[0].EndDate;
                }
            }
        })
    }

    function commonCalculationParentAndChild(parentItem, commonItem, i, p, c, isParent, isDuration, isEndDate, index) {

        let LeaveDays = parentItem.LeaveDays;
        let titleEmployee1 = parentItem.titleEmployee1;
        let dependencyCount = parentItem.DependencyCount;
        let parentdependencyCount = parentItem.DependencyCount;
        let DetailTypeID = parentItem.DetailTypeID;
        let parentDependency = parentItem.SelectedDependency;
        let SelectedDependency = parentItem.SelectedDependency;
        let strTaskName = parentItem.strTaskName;
        let UnMappedList = '';

        if (!isParent) {
            SelectedDependency = commonItem.SelectedDependency;
            dependencyCount = commonItem.SelectedDependency;
        }

        if ((isEndDate && isParent && index === i) || (isEndDate && isParent === false && index === c)) {
            let CheckDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, parentItem.titleEmployee1, "add");
            if (!isSameDay(new Date(commonItem.EndDate), CheckDate)) {
                Nodify('Warning!', 'warning', 'You select the end date to leave day');
            }
        }


        let Duration = (commonItem.Duration && commonItem.Duration) ? parseInt(commonItem.Duration) > 0 ? parseInt(commonItem.Duration) - 1 : 0 : 0;
        // try {
        //  if (((i === 0 || dependencyCount === 0) && isEndDate === false) || (isDuration === true && dependencyCount === 0)) {
        if (parentdependencyCount === 0) {
            // Dependency Calculation Without
            let dbStartDate = new Date(commonItem.StartDate);
            let total = 0;
            //add Saturday,Sunday and Holiday Count
            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "add");
            let EndDate = addDays(dbStartDate, Duration);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(dbStartDate, EndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                EndDate = addDays(EndDate, 1);
                EndDate = checkDateBetweenInHolidayAndWE(EndDate, LeaveDays, titleEmployee1, "add");
            }

            commonItem.StartDate = dbStartDate;
            commonItem.EndDate = EndDate;
            commonItem.PreStartDate = dbStartDate;
            commonItem.PreEndDate = EndDate;

            if (isParent) {
                values[i] = commonItem;
            } else {
                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }

        }
        // else if ((i === 0 || dependencyCount === 0) || (isEndDate === true && dependencyCount === 0)) {

        //     let dbEndDate = commonItem.EndDate;
        //     let total = 0;
        //     //add Saturday,Sunday and Holiday Count
        //     dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
        //     let StartDate = subDays(dbEndDate, Duration);
        //     //Total Saturday,Sunday and Holiday Count
        //     total = totalHolidayAndWE(StartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
        //     for (let l = 0; l < total; l++) {
        //         StartDate = subDays(StartDate, 1);
        //         StartDate = checkDateBetweenInHolidayAndWE(StartDate, LeaveDays, titleEmployee1, "sub");
        //     }

        //     commonItem.StartDate = StartDate;
        //     commonItem.EndDate = dbEndDate;
        //     commonItem.PreStartDate = StartDate;
        //     commonItem.PreEndDate = dbEndDate;
        //     if (isParent) {
        //         values[i] = commonItem;
        //     } else {
        //         values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
        //     }

        // }
        else {
            //Dependency Calculation
            let dependency = SelectedDependency;

            let dbEndDate = new Date();
            let dbStartDate = new Date();
            let total = 0;
            if (dependencyCount > 1) {
                //Multiple Dependency Calculation
                let oldDuration = commonItem.Duration;
                let isAllowed = true;
                let isChildAllowed = true;
                //EndDate Change Duration Increase and Decrease
                if (isEndDate === true && commonItem.EndDateChange !== 2 && ((isParent === true && index === i) || (isParent === false && index === c))) {
                    let chkStartDate = commonItem.StartDate;
                    let chkEndDate = commonItem.EndDate;
                    chkStartDate.setHours(0, 0, 0, 0);
                    chkEndDate.setHours(0, 0, 0, 0);
                    if (chkStartDate <= chkEndDate) {
                        let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, parentItem.titleEmployee1, "add");
                        let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);
                        let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, parentItem.LeaveDays, parentItem.titleEmployee1, i, p, c, isParent);
                        diffDayCount = diffDayCount - holidayTotal;
                        commonItem.Duration = diffDayCount;
                        Duration = diffDayCount - 1;

                    } else {
                        // Item.EndDate = Item.preEndDate;
                        isAllowed = false;
                    }
                } else if (isEndDate === true && commonItem.EndDateChange !== 2 && isParent === false && index === i) {
                    commonItem.Duration = values[i].Duration;
                    commonItem.EndDate = values[i].EndDate;
                    commonItem.StartDate = values[i].StartDate;
                    commonItem.PreEndDate = values[i].EndDate;
                    commonItem.PreStartDate = values[i].StartDate;
                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    isChildAllowed = false;
                }

                if (isChildAllowed) {
                    if (isAllowed) {
                        let dependencyMult = [];
                        let splitDepList = dependency.split(',');

                        splitDepList.map((depItem, d) => {
                            addMultDependencyDate(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, i, p, c);
                        });

                        const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                        let rowSorted = sorted[0];
                        dbEndDate = sorted[0].Date;
                        let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                        if (lastRow.length > 0) {
                            dbStartDate = lastRow[0].Date;
                        } else {
                            dbStartDate = dbEndDate;
                        }
                        // Get Dependency and Index
                        let splitVal = lastRow[0].depItem.split('-');
                        let indexVal = splitVal[0].trim();
                        let dependencyVal = splitVal[1].trim();

                        if (commonItem.EndDateChange === 2) {

                            // ---Manual Calculation function------
                            let newEndDate = checkDateBetweenInHolidayAndWE(commonItem.EndDate, parentItem.LeaveDays, parentItem.titleEmployee1, "sub");
                            let newStartDate = subDays(newEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(newStartDate, newEndDate, parentItem.LeaveDays, parentItem.titleEmployee1, i, p, c, isParent);
                            for (let l = 0; l < total; l++) {
                                newStartDate = subDays(newStartDate, 1);
                                newStartDate = checkDateBetweenInHolidayAndWE(newStartDate, parentItem.LeaveDays, parentItem.titleEmployee1, "sub");
                            }

                            if (dependencyVal === "SS") {
                                //---- Start-Start ---- DependencyID 2------
                                //Get Dependent Map StartDate
                                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2)[0];
                                let preStartDate = subDays(values[indexVal - 1].StartDate, 1);
                                let LogDate = preStartDate;
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                newStartDate.setHours(0, 0, 0, 0);
                                if (LogDate <= newStartDate) {
                                    commonItem.StartDate = newStartDate;
                                    commonItem.EndDate = newEndDate;
                                } else {
                                    commonItem.StartDate = dbStartDate;
                                    commonItem.EndDate = dbEndDate;
                                }
                            } else if (dependencyVal === "SE") {
                                //---- Start-End ----DependencyID 3---((preStartDate - 1) + LogDays = EndDate))-------
                                //Get Dependent Map StartDate
                                let preStartDate = subDays(values[indexVal - 1].StartDate, 1);
                                let LogDate = preStartDate;
                                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3)[0];
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                newEndDate.setHours(0, 0, 0, 0);
                                if (LogDate <= newEndDate) {
                                    commonItem.StartDate = newStartDate;
                                    commonItem.EndDate = newEndDate;
                                } else {
                                    commonItem.StartDate = dbStartDate;
                                    commonItem.EndDate = dbEndDate;
                                }
                            } else if (dependencyVal === "ES") {
                                //Get Dependent Map StartDate ---- End-Start ----DependencyID 1------
                                let preEndDate = values[indexVal - 1].EndDate;
                                let LogDate = newStartDate;
                                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1)[0];

                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = subDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                preEndDate.setHours(0, 0, 0, 0);
                                if (preEndDate < LogDate) {
                                    commonItem.StartDate = newStartDate;
                                    commonItem.EndDate = newEndDate;
                                } else {
                                    commonItem.StartDate = dbStartDate;
                                    commonItem.EndDate = dbEndDate;
                                }
                            } else if (dependencyVal === "EE") {
                                //---- End-End --------DependencyID 4-----------((preEndDate) + LogDays = EndDate))-------
                                //Get Dependent Map StartDate
                                let preEndDate = values[indexVal - 1].EndDate;
                                let LogDate = preEndDate;
                                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4)[0];
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                newEndDate.setHours(0, 0, 0, 0);
                                if (LogDate <= newEndDate) {
                                    commonItem.StartDate = newStartDate;
                                    commonItem.EndDate = newEndDate;
                                } else {
                                    commonItem.StartDate = dbStartDate;
                                    commonItem.EndDate = dbEndDate;
                                }
                            }

                        } else {
                            commonItem.StartDate = dbStartDate;
                            commonItem.EndDate = dbEndDate;
                        }

                        // commonItem.StartDate = dbStartDate;
                        // commonItem.EndDate = dbEndDate;

                    } else {
                        commonItem.EndDate = commonItem.PreEndDate;
                    }

                    if (isParent) {

                        if (commonItem.EndDateChange !== 2 && isEndDate === true && commonItem.IsSingleTask === false) {

                            commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                    commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                });
                            });
                        } else if (commonItem.EndDateChange === 2 && isEndDate === true && commonItem.IsSingleTask === false) {
                            commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                    commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                    commonItem.SubTaskList[k].ChildTaskList[l].StartDate = commonItem.StartDate;
                                    commonItem.SubTaskList[k].ChildTaskList[l].EndDate = commonItem.EndDate;
                                    commonItem.SubTaskList[k].ChildTaskList[l].EndDateChange = 2;
                                });
                            });
                        }


                        values[i] = commonItem;
                    } else {
                        values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }
                }

            } else if (dependencyCount === 1 && dependency.length !== 0) {

                let isChildAllowed = true;
                //---------- **** Single  Dependent Calculation ******----------------
                //Get Dependent Name and Index
                let splitVal = dependency.split('-');
                let indexVal = splitVal[0].trim();
                let dependencyVal = splitVal[1].trim();

                //EndDate Change Duration Increase and Decrease
                if (isEndDate === true && commonItem.EndDateChange !== 2 && ((isParent === true && index === i) || (isParent === false && index === c))) {
                    let chkStartDate = commonItem.StartDate;
                    let chkEndDate = commonItem.EndDate;
                    chkStartDate.setHours(0, 0, 0, 0);
                    chkEndDate.setHours(0, 0, 0, 0);
                    if (chkStartDate <= chkEndDate) {
                        if (dependencyVal === "SS" || dependencyVal === "ES") {
                            let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), LeaveDays, titleEmployee1, "add");
                            let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);
                            let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            diffDayCount = diffDayCount - holidayTotal;
                            commonItem.Duration = diffDayCount;
                            Duration = diffDayCount - 1;
                        }


                    } else {
                        commonItem.EndDate = commonItem.PreEndDate;
                        Nodify('Warning!', 'warning', 'End date should not be less than start date.');
                    }
                } else if (isEndDate === true && commonItem.EndDateChange !== 2 && isParent === false && index === i) {

                    commonItem.Duration = values[i].Duration;
                    commonItem.EndDate = values[i].EndDate;
                    commonItem.StartDate = values[i].StartDate;
                    commonItem.PreEndDate = values[i].EndDate;
                    commonItem.PreStartDate = values[i].StartDate;
                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    isChildAllowed = false;
                }

                if (isChildAllowed) {
                    if (dependencyVal === "SS") {
                        //---- Start-Start ---- DependencyID 2------

                        //Get Dependent Map StartDate
                        let preStartDate = values[indexVal - 1].StartDate;
                        let LogDate = new Date();

                        if (commonItem.EndDateChange === 2) {
                            //Manual Calculation function------(preStartDate + LogDays = StartDate))-------
                            dbEndDate = checkDateBetweenInHolidayAndWE(commonItem.EndDate, LeaveDays, titleEmployee1, "sub");
                            dbStartDate = subDays(dbEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            for (let l = 0; l < total; l++) {
                                dbStartDate = subDays(dbStartDate, 1);
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                            }
                            LogDate = preStartDate;
                            let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                            if (Log && Log[0].LogDays !== "" && Log[0].LogDays !== undefined) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Log[0].LogDays));
                                //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                            }
                        }

                        LogDate.setHours(0, 0, 0, 0);
                        dbStartDate.setHours(0, 0, 0, 0);
                        if (LogDate <= dbStartDate && commonItem.EndDateChange === 2) {
                        } else {
                            //Auto Calculation function
                            // dbStartDate = values[indexVal - 1].StartDate;
                            let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                            if (Log && Log[0] && Log[0].LogDays) {
                                dbStartDate = addDays(preStartDate, parseInt(Log[0].LogDays));
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "add");
                            } else {
                                //Calculation Saturday,Sunday and Holiday Count
                                dbStartDate = checkDateBetweenInHolidayAndWE(preStartDate, LeaveDays, titleEmployee1, "add");
                            }

                            dbEndDate = addDays(dbStartDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            for (let l = 0; l < total; l++) {
                                dbEndDate = addDays(dbEndDate, 1);
                                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                            }
                        }

                        commonItem.EndDate = dbEndDate;
                        commonItem.StartDate = dbStartDate;
                        commonItem.PreEndDate = dbEndDate;
                        commonItem.PreStartDate = dbStartDate;
                        if (isParent) {
                            if (commonItem.EndDateChange !== 2 && isEndDate === true && commonItem.IsSingleTask === false) {

                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                    });
                                });
                            } else if (commonItem.EndDateChange === 2 && isEndDate === true && commonItem.IsSingleTask === false) {
                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                        commonItem.SubTaskList[k].ChildTaskList[l].StartDate = commonItem.StartDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDate = commonItem.EndDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDateChange = 2;
                                    });
                                });
                            }
                            values[i] = commonItem;

                        } else {
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }

                    } else if (dependencyVal === "SE") {
                        //---- Start-End ----DependencyID 3---

                        let preStartDate = subDays(values[indexVal - 1].StartDate, 1);
                        let LogDate = preStartDate;
                        let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3)[0];

                        if (isEndDate === true && commonItem.EndDateChange !== 2) {
                            if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Log.LogDays));
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            let newDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), LeaveDays, titleEmployee1, "add");
                            newDate.setHours(0, 0, 0, 0);
                            if (LogDate <= newDate) {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), LeaveDays, titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);

                                let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                diffDayCount = diffDayCount - holidayTotal;
                                commonItem.Duration = diffDayCount;
                                dbStartDate = commonItem.StartDate;
                                dbEndDate = LastDate;
                            } else {
                                dbStartDate = commonItem.StartDate;
                                dbEndDate = commonItem.PreEndDate;
                                commonItem.EndDate = commonItem.PreEndDate;
                            }
                        } else {

                            if (commonItem.EndDateChange === 2) {
                                //Manual Calculation function----------((preStartDate - 1) + LogDays = EndDate))-------
                                dbEndDate = checkDateBetweenInHolidayAndWE(commonItem.EndDate, LeaveDays, titleEmployee1, "sub");
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                                }

                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                    //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                                }
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            dbEndDate.setHours(0, 0, 0, 0);
                            if (LogDate <= dbEndDate && commonItem.EndDateChange === 2) {
                                //Skip
                            } else {
                                //Auto Calculation function
                                if (Log && Log[0] && Log[0].LogDays) {
                                    //LogDays Add
                                    dbEndDate = addDays(preStartDate, parseInt(Log.LogDays));
                                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                                } else {
                                    dbEndDate = checkDateBetweenInHolidayAndWE(preStartDate, LeaveDays, titleEmployee1, "sub");
                                }
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                                }
                            }
                        }

                        commonItem.StartDate = dbStartDate;
                        commonItem.EndDate = dbEndDate;
                        commonItem.PreStartDate = dbStartDate;
                        commonItem.PreEndDate = dbEndDate;

                        if (isParent) {
                            if (commonItem.EndDateChange !== 2 && isEndDate === true && commonItem.IsSingleTask === false) {

                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                    });
                                });
                            } else if (commonItem.EndDateChange === 2 && isEndDate === true && commonItem.IsSingleTask === false) {
                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                        commonItem.SubTaskList[k].ChildTaskList[l].StartDate = commonItem.StartDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDate = commonItem.EndDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDateChange = 2;
                                    });
                                });
                            }

                            values[i] = commonItem;
                        } else {
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }

                    } else if (dependencyVal === "ES") {
                        //Manual Calculation function-------- End-Start ----DependencyID 1------
                        //Get Dependent Map StartDate
                        let preEndDate = values[indexVal - 1].EndDate;
                        let LogDate = new Date();
                        let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1)[0];

                        if (commonItem.EndDateChange === 2) {
                            // ---Manual Calculation function---------((preEndDate + 1) + LogDays = StartDate))-------
                            dbEndDate = checkDateBetweenInHolidayAndWE(commonItem.EndDate, LeaveDays, titleEmployee1, "sub");
                            dbStartDate = subDays(dbEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            for (let l = 0; l < total; l++) {
                                dbStartDate = subDays(dbStartDate, 1);
                                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                            }
                            LogDate = dbStartDate;

                            if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                //LogDays Add
                                LogDate = subDays(LogDate, parseInt(Log.LogDays));
                            }
                        }
                        LogDate.setHours(0, 0, 0, 0);
                        preEndDate.setHours(0, 0, 0, 0);
                        if (preEndDate < LogDate && commonItem.EndDateChange === 2) {
                        } else {
                            //Auto Calculation function
                            dbStartDate = addDays(preEndDate, 1);
                            if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                //LogDays Add
                                dbStartDate = addDays(dbStartDate, parseInt(Log.LogDays));
                            }
                            //Calculation Saturday,Sunday and Holiday Count
                            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "add");
                            dbEndDate = addDays(dbStartDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                            for (let l = 0; l < total; l++) {
                                dbEndDate = addDays(dbEndDate, 1);
                                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                            }
                        }

                        commonItem.EndDate = dbEndDate;
                        commonItem.StartDate = dbStartDate;
                        commonItem.PreStartDate = dbStartDate;
                        commonItem.PreEndDate = dbEndDate;
                        if (isParent) {
                            if (commonItem.EndDateChange !== 2 && isEndDate === true && commonItem.IsSingleTask === false) {

                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                    });
                                });
                            } else if (commonItem.EndDateChange === 2 && isEndDate === true && commonItem.IsSingleTask === false) {
                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                        commonItem.SubTaskList[k].ChildTaskList[l].StartDate = commonItem.StartDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDate = commonItem.EndDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDateChange = 2;
                                    });
                                });
                            }
                            values[i] = commonItem;
                        } else {
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }

                    } else if (dependencyVal === "EE") {
                        //---- End-End --------DependencyID 4-----------((preEndDate) + LogDays = EndDate))-------
                        //Get Dependent Map StartDate

                        let preEndDate = values[indexVal - 1].EndDate;
                        let LogDate = preEndDate;
                        let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4)[0];

                        if (isEndDate === true && commonItem.EndDateChange !== 2) {
                            if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                //LogDays Add
                                LogDate = addDays(LogDate, parseInt(Log.LogDays));
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            let newDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), LeaveDays, titleEmployee1, "add");
                            newDate.setHours(0, 0, 0, 0);
                            if (LogDate <= newDate) {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), LeaveDays, titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);
                                let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                diffDayCount = diffDayCount - holidayTotal;
                                commonItem.Duration = diffDayCount;
                                dbStartDate = commonItem.StartDate;
                                dbEndDate = LastDate;
                            } else {
                                dbStartDate = commonItem.StartDate;
                                dbEndDate = commonItem.PreEndDate;
                                commonItem.EndDate = commonItem.PreEndDate;
                            }
                        } else {

                            if (commonItem.EndDateChange === 2) {
                                // ---Manual Calculation function------
                                dbEndDate = checkDateBetweenInHolidayAndWE(commonItem.EndDate, LeaveDays, titleEmployee1, "sub");
                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                                }
                                LogDate = preEndDate;

                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                    //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                                }
                            }
                            LogDate.setHours(0, 0, 0, 0);
                            dbEndDate.setHours(0, 0, 0, 0);
                            if (LogDate <= dbEndDate && commonItem.EndDateChange === 2) {
                            } else {

                                //Auto Calculation function
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    dbEndDate = addDays(preEndDate, parseInt(Log.LogDays));
                                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                                } else {
                                    dbEndDate = checkDateBetweenInHolidayAndWE(preEndDate, LeaveDays, titleEmployee1, "sub",);
                                }

                                dbStartDate = subDays(dbEndDate, Duration);
                                //Total Saturday,Sunday and Holiday Count
                                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                                for (let l = 0; l < total; l++) {
                                    dbStartDate = subDays(dbStartDate, 1);
                                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                                }
                            }
                        }

                        commonItem.EndDate = dbEndDate;
                        commonItem.StartDate = dbStartDate;
                        commonItem.PreStartDate = dbStartDate;
                        commonItem.PreEndDate = dbEndDate;

                        if (isParent) {
                            if (commonItem.EndDateChange !== 2 && isEndDate === true && commonItem.IsSingleTask === false) {

                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                    });
                                });
                            } else if (commonItem.EndDateChange === 2 && isEndDate === true && commonItem.IsSingleTask === false) {
                                commonItem.SubTaskList && commonItem.SubTaskList.map((sub, k) => {
                                    sub.ChildTaskList && sub.ChildTaskList.map((Child, l) => {
                                        commonItem.SubTaskList[k].ChildTaskList[l].Duration = commonItem.Duration;
                                        commonItem.SubTaskList[k].ChildTaskList[l].StartDate = commonItem.StartDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDate = commonItem.EndDate;
                                        commonItem.SubTaskList[k].ChildTaskList[l].EndDateChange = 2;
                                    });
                                });
                            }
                            values[i] = commonItem;
                        } else {
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                        }
                    }
                }
            } else if (!isParent) { //------** Child Only ***------------------
                //------------------ *** No Dependency (SKU base map Calculation) ***------------

                if (DetailTypeID === 2) { // DetailType => Sku-----------------------------------
                    let SkuId = commonItem.SubId;

                    if (parentdependencyCount === 1 && parentDependency.length !== 0) {
                        //---------- **** Single  Dependent Calculation ******----------------
                        //Get Parent Dependent Name and Index
                        let parentSplitVal = parentDependency.split('-');
                        let parentIndexVal = parentSplitVal[0].trim();
                        let parentDependencyVal = parentSplitVal[1].trim();

                        // Dependent base compare to  DetailTypeID
                        let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                        if (parentDetailTypeID === 1) { // DetailType => Common
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentDependencyVal, parentDetailTypeID);
                            commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, null, isParent)
                        } else if (parentDetailTypeID === 2) { // DetailType => Sku
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            let preChildDetail = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId)[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                    dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                            } else {
                                commonItem.hasSKUNotMatch = true;

                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                parentItem.IsExpanded = true;
                                commonItem.hasAutoOpen = true;

                                handleExpandedOnlyToggle(index);
                            }


                        } else if (parentDetailTypeID === 3) { // DetailType => Item
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            let preChildDetailList = [];
                            values[parentIndexVal - 1].SubTaskList.map((item) => {
                                let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()))[0];
                                TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                            });
                            //Order by descending ( -1 : 1)


                            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                    dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                            } else {
                                commonItem.hasSKUNotMatch = true;
                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                parentItem.IsExpanded = true;
                                values[i].SubTaskList[p].IsExpanded = true;
                                commonItem.hasAutoOpen = true;

                                handleExpandedOnlyToggle(index);
                            }


                        } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            let preChildDetailList = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);

                            //Order by ASC a - b
                            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                    dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                            } else {
                                commonItem.hasSKUNotMatch = true;
                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                parentItem.IsExpanded = true;
                                values[i].SubTaskList[p].IsExpanded = true;
                                commonItem.hasAutoOpen = true;

                                handleExpandedOnlyToggle(index);
                            }
                        }

                    } else if (parentdependencyCount > 1) {
                        //---------- **** Multiple  Dependent Calculation ******----------------
                        let oldDuration = commonItem.Duration;
                        let dependencyMult = [];
                        let splitDepList = parentDependency.split(',');
                        splitDepList.map((depItem, d) => {
                            addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId);
                        });

                        if (dependencyMult.length > 0) {
                            const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                            let rowSorted = sorted[0];
                            dbEndDate = sorted[0].Date;
                            let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                            if (lastRow.length > 0) {
                                dbStartDate = lastRow[0].Date;
                            } else {
                                dbStartDate = dbEndDate;
                            }

                            // Get Dependency and Index
                            let splitVal = lastRow[0].depItem.split('-');
                            let indexVal = splitVal[0].trim();
                            let dependencyVal = splitVal[1].trim();
                            commonItem.StartDate = dbStartDate;
                            commonItem.EndDate = dbEndDate;
                            commonItem.AssignedDepandency = dependencyVal;
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                            ChildDepandencyTextwithMultiParentdep(DetailTypeID, splitDepList, commonItem, i, p, c, SkuId);
                            // commonItem.hasSKUNotMatch = false;
                        } else {
                            commonItem.hasSKUNotMatch = true;
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                            parentItem.IsExpanded = true;
                            values[i].SubTaskList[p].IsExpanded = true;
                            commonItem.hasAutoOpen = true;

                            handleExpandedOnlyToggle(index);
                        }


                    }


                } else if (DetailTypeID === 3) { // DetailType => Item-------------------------------

                    let SkuId = commonItem.SubId;
                    if (parentdependencyCount === 1 && parentDependency.length !== 0) {
                        //---------- **** Single  Dependent Calculation ******----------------
                        //Get Parent Dependent Name and Index
                        let parentSplitVal = parentDependency.split('-');
                        let parentIndexVal = parentSplitVal[0].trim();
                        let parentDependencyVal = parentSplitVal[1].trim();

                        // Dependent base compare to  DetailTypeID
                        let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                        if (parentDetailTypeID === 1) { // DetailType => Common
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, null, isParent)
                        } else if (parentDetailTypeID === 2) { // DetailType => Sku
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            let preChildDetailList = [];
                            let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                            arySkuIdList.map((item) => {
                                let preSkuDetail = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item))[0];
                                preSkuDetail && preChildDetailList.push(preSkuDetail);
                            });

                            //Order by descending ( -1 : 1)
                            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                    dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                            } else {
                                commonItem.hasSKUNotMatch = true;
                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                parentItem.IsExpanded = true;
                                commonItem.hasAutoOpen = true;
                                values[i].SubTaskList[p].IsExpanded = true;
                                handleExpandedOnlyToggle(index);
                            }

                        } else if (parentDetailTypeID === 3) { // DetailType => Item
                            let TaskName = values[index].strTaskName;
                            let preTaskName = values[parentIndexVal - 1].strTaskName;
                            let hasSameItem = false;

                            if ((TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)
                                && (preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)) {
                                hasSameItem = true;

                            } else if ((TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)
                                && (preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)) {
                                hasSameItem = true;

                            } else if ((TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)
                                && (preTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || preTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)) {
                                hasSameItem = true;
                            }

                            if (hasSameItem) { //Same Item Match
                                ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                                let SubId = commonItem.SubId;
                                let MainId = commonItem.MainId;

                                let preChildTaskList = values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0] && values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0].ChildTaskList;
                                let preChildDetail = preChildTaskList && preChildTaskList.filter(d => d.SubId === SubId)[0];
                                if (preChildDetail) {
                                    // commonItem.hasSKUNotMatch = false;
                                    commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                        dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                                } else {
                                    commonItem.hasSKUNotMatch = true;
                                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                    parentItem.IsExpanded = true;
                                    values[i].SubTaskList[p].IsExpanded = true;
                                    commonItem.hasAutoOpen = true;

                                    handleExpandedOnlyToggle(index);
                                }
                            } else { //Other Item Match Sku
                                let preChildDetailList = [];
                                let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                                arySkuIdList && arySkuIdList.map((Sku) => {
                                    values[parentIndexVal - 1].SubTaskList.map((item) => {
                                        let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(Sku.toString()))[0];
                                        TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                                        // if (TNASkuIdList && TNASkuIdList.length > 0) {

                                        //     preChildDetailList = (TNASkuIdList && TNASkuIdList.length > 0) ? [preChildDetailList, ...TNASkuIdList] : preChildDetailList;
                                        // }

                                    });
                                });
                                ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                                //Order by descending ( -1 : 1)
                                let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                                if (preChildDetail) {
                                    // commonItem.hasSKUNotMatch = false;
                                    commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                        dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                                } else {
                                    commonItem.hasSKUNotMatch = true;
                                    values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                    parentItem.IsExpanded = true;
                                    values[i].SubTaskList[p].IsExpanded = true;
                                    commonItem.hasAutoOpen = true;

                                    handleExpandedOnlyToggle(index);
                                }


                            }

                        } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory

                            let preChildDetailList = [];

                            let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');

                            arySkuIdList.map((item) => {
                                let preSkuDetail = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item))[0];
                                preSkuDetail && preChildDetailList.push(preSkuDetail);
                            });

                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            //Order by ASC a - b
                            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
                            if (preChildDetail) {
                                // commonItem.hasSKUNotMatch = false;
                                commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
                                    dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent)
                            } else {
                                commonItem.hasSKUNotMatch = true;
                                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                                parentItem.IsExpanded = true;
                                values[i].SubTaskList[p].IsExpanded = true;
                                commonItem.hasAutoOpen = true;

                                handleExpandedOnlyToggle(index);
                            }
                        }


                    } else if (parentdependencyCount > 1) {
                        //---------- **** Multiple  Dependent Calculation ******----------------
                        let oldDuration = commonItem.Duration;
                        let dependencyMult = [];
                        let splitDepList = parentDependency.split(',');
                        splitDepList.map((depItem, d) => {
                            addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId);
                        });

                        if (dependencyMult.length > 0) {
                            const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                            let rowSorted = sorted[0];
                            dbEndDate = sorted[0].Date;
                            let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                            if (lastRow.length > 0) {
                                dbStartDate = lastRow[0].Date;
                            } else {
                                dbStartDate = dbEndDate;
                            }

                            // Get Dependency and Index
                            let splitVal = lastRow[0].depItem.split('-');
                            let indexVal = splitVal[0].trim();
                            let dependencyVal = splitVal[1].trim();
                            commonItem.StartDate = dbStartDate;
                            commonItem.EndDate = dbEndDate;
                            commonItem.AssignedDepandency = dependencyVal;
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;


                            ChildDepandencyTextwithMultiParentdep(DetailTypeID, splitDepList, commonItem, i, p, c, SkuId);
                            // commonItem.hasSKUNotMatch = false;
                        } else {
                            commonItem.hasSKUNotMatch = true;
                            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                            parentItem.IsExpanded = true;
                            values[i].SubTaskList[p].IsExpanded = true;
                            commonItem.hasAutoOpen = true;

                            handleExpandedOnlyToggle(index);
                        }



                    }


                } else if (DetailTypeID === 4) { // DetailType => Ex-Factory---------------------------
                    let SkuId = commonItem.SubId;

                    if (parentdependencyCount === 1 && parentDependency.length !== 0) {
                        //---------- **** Single  Dependent Calculation ******----------------
                        //Get Parent Dependent Name and Index
                        let parentSplitVal = parentDependency.split('-');
                        let parentIndexVal = parentSplitVal[0].trim();
                        let parentDependencyVal = parentSplitVal[1].trim();

                        // Dependent base compare to  DetailTypeID
                        let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                        if (parentDetailTypeID === 1) { // DetailType => Common
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                            commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal,
                                parentDependencyVal, dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, null, isParent)
                        } else if (parentDetailTypeID === 2) { // DetailType => Sku
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);

                        } else if (parentDetailTypeID === 3) { // DetailType => Item
                            ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentIndexVal, parentDetailTypeID);
                        }

                    } else if (parentdependencyCount > 1) {
                        //---------- **** Multiple  Dependent Calculation ******----------------
                        let oldDuration = commonItem.Duration;
                        let dependencyMult = [];
                        let splitDepList = parentDependency.split(',');
                        splitDepList.map((depItem, d) => {
                            addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId);
                        });
                        ChildDepandencyTextwithMultiParentdep(DetailTypeID, splitDepList, commonItem, i, p, c, SkuId);

                    }

                }

            }
        }
        //ex-factory calculation 
        // Common task not having Ex Factory calculation (Bulk Purchase Order,SMS Purchase Order)
        //if (DetailTypeID === 4 && !isParent) { // DetailType => Ex-Factory---------------------------
        if (DetailTypeID === 4 && !isParent && (commonItem.TaskName !== 'Bulk Purchase Order' && commonItem.TaskName !== 'SMS Purchase Order')) {
            commonItem.AutoText = "";
            if (commonItem.AssignedDepandency === '') {
                let SkuId = commonItem.SubId;
                let ChildId = commonItem.ChildId;
                let ExfactDate = commonItem.ExFacDate;
                let Duration = commonItem.Duration;
                let skugroup = values[i].SubTaskList[p].ChildTaskList.filter(x => x.SubId === SkuId);
                let indexofcurrrow = skugroup.findIndex(x => x.ChildId === ChildId);
                if (indexofcurrrow === 0) {
                    // commonItem.EndDate = values[i].EndDate;
                    // commonItem.StartDate = values[i].StartDate;
                    // commonItem.PreEndDate = values[i].EndDate;
                    // commonItem.PreStartDate = values[i].StartDate;

                    commonItem.EndDate = !isLastEdit ? values[i].EndDate : commonItem.EndDate;
                    commonItem.StartDate = !isLastEdit ? values[i].StartDate : commonItem.StartDate;
                    commonItem.PreEndDate = !isLastEdit ? values[i].EndDate : commonItem.EndDate;
                    commonItem.PreStartDate = !isLastEdit ? values[i].StartDate : commonItem.StartDate;

                    if (commonItem.AssignedDepandency !== '') {
                        let parentSplitVal = parentDependency.split('-');
                        let parentIndexVal = parentSplitVal[0].trim();
                        let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                        //  Setdepandencybasedontaskclose(commonItem.SelectedDependency, i, p, c, parentIndexVal, parentDetailTypeID);
                    }
                }
                else {
                    let currexfactdate = commonItem.ExFacDate;
                    let prevexfactdate = skugroup[indexofcurrrow - 1].ExFacDate;
                    let Difference_In_Time = new Date(currexfactdate).getTime() - new Date(prevexfactdate).getTime();
                    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                    let finaldifference = Difference_In_Days === 0 ? 1 : (Difference_In_Days + 1);
                    //  let enddate = addDays((skugroup[indexofcurrrow - 1].EndDate), finaldifference)
                    //  let startDate = subDays(enddate, parseInt(commonItem.Duration) - 1);
                    let DepandencyIndexeswithValues = [];

                    let SetStartdate = new Date(); let SetEnddate = new Date();
                    let tempEnddate = new Date(); let tempStartdate = new Date();
                    let total = 0;

                    // //leave days calculation
                    // SetEnddate = checkDateBetweenInHolidayAndWE(addDays((skugroup[indexofcurrrow - 1].EndDate), finaldifference - 1),
                    //     LeaveDays, titleEmployee1, "add");
                    // tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
                    // //Total Saturday,Sunday and Holiday Count
                    // total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
                    // for (let l = 0; l < total; l++) {
                    //     tempStartdate = subDays(tempStartdate, 1);
                    //     tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
                    // }
                    // SetStartdate = tempStartdate;


                    if (commonItem.SelectedDependency === '')   // defauly set ES Client ref information
                    {

                        SetStartdate = addDays(skugroup[indexofcurrrow - 1].EndDate, finaldifference);
                        //Calculation Saturday,Sunday and Holiday Count
                        SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
                        tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
                        //Total Saturday,Sunday and Holiday Count
                        total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
                        for (let l = 0; l < total; l++) {
                            tempEnddate = addDays(tempEnddate, 1);
                            tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
                        }

                        SetEnddate = tempEnddate;


                    }
                    else {



                        let parentSplitVal = commonItem.SelectedDependency.split('-');
                        let parentIndexVal = parentSplitVal[0].trim();
                        let parentDependencyVal = parentSplitVal[2] !== undefined ? parentSplitVal[2].trim() : parentSplitVal[1].trim();

                        let DependentID = parentDependencyVal === "ES" ? 1 : parentDependencyVal === "SS" ? 2 : parentDependencyVal === "SE" ? 3 : parentDependencyVal === "EE" ? 4 : '';
                        // ({ SetStartdate, tempEnddate, total, SetEnddate, tempStartdate } = DepandencyAssign(DependentID, SetStartdate, Enddate, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, Startdate, tempStartdate));



                        //leave days calculation 
                        // End to end
                        ({ SetStartdate, tempEnddate, total, SetEnddate, tempStartdate } = ExfactoryDepandentApply(DependentID, SetStartdate, skugroup, indexofcurrrow, finaldifference, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, tempStartdate));
                    }

                    commonItem.EndDate = SetEnddate;
                    commonItem.StartDate = SetStartdate;
                    commonItem.PreEndDate = SetEnddate;
                    commonItem.PreStartDate = SetStartdate;


                    if (commonItem.SelectedDependency === '') {
                        commonItem.AutoText = "(Auto)";
                        commonItem.SelectedDependency = (i + 1) + "-" + skugroup[indexofcurrrow - 1].ChildIndex + "-ES";
                        // commonItem.IsDepandency = 1;
                        commonItem.DependencyCount = 1;

                        commonItem.AssignedDepandency = "ES";
                        commonItem.AssignedStartDate = SetStartdate;
                        commonItem.AssignedEndDate = SetEnddate;

                        DepandencyIndexeswithValues.push({
                            index: skugroup[indexofcurrrow - 1].ChildId,
                            value: "ES",
                            Logdays: finaldifference - 1
                        })

                        // let parentSplitVal = parentDependency.split('-');
                        // let parentIndexVal = parentSplitVal[0].trim();
                        // let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                        // Setdepandencybasedontaskclose(commonItem.SelectedDependency, i, p, c, parentIndexVal, parentDetailTypeID);

                        CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);
                    }
                    // else {
                    //     Setdepandencybasedontaskclose(commonItem.SelectedDependency, i, p, c);
                    // }
                    else {
                        if ((!commonItem.SelectedDependency.includes(skugroup[indexofcurrrow - 1].ChildIndex + "-ES")
                            && !commonItem.SelectedDependency.includes(skugroup[indexofcurrrow - 1].ChildIndex + "-EE")
                            && !commonItem.SelectedDependency.includes(skugroup[indexofcurrrow - 1].ChildIndex + "-SS")
                            && !commonItem.SelectedDependency.includes(skugroup[indexofcurrrow - 1].ChildIndex + "-SE"))) {

                            commonItem.AutoText = "(Auto)";
                            commonItem.SelectedDependency = commonItem.SelectedDependency + "," + ((i + 1) + "-" +
                                skugroup[indexofcurrrow - 1].ChildIndex + "-ES");

                        }
                        commonItem.IsDepandency = 1;
                        commonItem.AssignedDepandency = "ES";
                        DepandencyIndexeswithValues.push({
                            index: skugroup[indexofcurrrow - 1].ChildId,
                            value: "ES",
                            Logdays: finaldifference - 1
                        })

                        //  CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);

                    }



                }

                if (parentdependencyCount === 1 && parentDependency.length !== 0) {

                    let parentSplitVal = parentDependency.split('-');
                    let parentIndexVal = parentSplitVal[0].trim();
                    let parentDependencyVal = parentSplitVal[1].trim();
                    let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                    if (parentDetailTypeID === 4) {
                        let matchedrow = values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.
                            filter(x => x.SubId === SkuId && x.ExFacDate === ExfactDate)[0];

                        let Startdate = matchedrow.StartDate;
                        let Enddate = matchedrow.EndDate;
                        let SetStartdate = new Date(); let SetEnddate = new Date();
                        let tempEnddate = new Date(); let tempStartdate = new Date();
                        let total = 0;

                        let DependentID = parentDependencyVal === "ES" ? 1 : parentDependencyVal === "SS" ? 2 : parentDependencyVal === "SE" ? 3 : parentDependencyVal === "EE" ? 4 : '';
                        // if (DependentID === 1) {

                        //     SetStartdate = addDays(Enddate, 1);
                        //     //Calculation Saturday,Sunday and Holiday Count
                        //     SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
                        //     tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
                        //     //Total Saturday,Sunday and Holiday Count
                        //     total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
                        //     for (let l = 0; l < total; l++) {
                        //         tempEnddate = addDays(tempEnddate, 1);
                        //         tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
                        //     }

                        //     SetEnddate = tempEnddate;

                        // }
                        // //Start to Start
                        // if (DependentID === 2) {

                        //     //Calculation Saturday,Sunday and Holiday Count
                        //     SetStartdate = checkDateBetweenInHolidayAndWE(Startdate, LeaveDays, titleEmployee1, "add");
                        //     tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
                        //     //Total Saturday,Sunday and Holiday Count
                        //     total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
                        //     for (let l = 0; l < total; l++) {
                        //         tempEnddate = addDays(tempEnddate, 1);
                        //         tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
                        //     }
                        //     SetEnddate = tempEnddate;

                        // }
                        // //Start to End
                        // if (DependentID === 3) {

                        //     SetEnddate = checkDateBetweenInHolidayAndWE(subDays(Startdate, 1), LeaveDays, titleEmployee1, "sub");
                        //     tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
                        //     //Total Saturday,Sunday and Holiday Count
                        //     total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
                        //     for (let l = 0; l < total; l++) {
                        //         tempStartdate = subDays(tempStartdate, 1);
                        //         tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
                        //     }
                        //     SetStartdate = tempStartdate;

                        // }
                        // //End to end
                        // if (DependentID === 4) {

                        //     SetEnddate = checkDateBetweenInHolidayAndWE(Enddate, LeaveDays, titleEmployee1, "sub",);
                        //     tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
                        //     //Total Saturday,Sunday and Holiday Count
                        //     total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
                        //     for (let l = 0; l < total; l++) {
                        //         tempStartdate = subDays(tempStartdate, 1);
                        //         tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
                        //     }
                        //     SetStartdate = tempStartdate;

                        // }

                        ({ SetStartdate, tempEnddate, total, SetEnddate, tempStartdate } = DepandencyAssign(DependentID, SetStartdate, Enddate, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, Startdate, tempStartdate));

                        commonItem.EndDate = SetEnddate;
                        commonItem.StartDate = SetStartdate;
                        commonItem.PreEndDate = SetEnddate;
                        commonItem.PreStartDate = SetStartdate;

                        commonItem.SelectedDependency = parentIndexVal + "-" + matchedrow.ChildIndex + "-" + parentDependencyVal;
                        commonItem.DependencyCount = 1;
                        commonItem.IsDepandency = 1;
                        commonItem.AssignedDepandency = parentDependencyVal;

                        let DepandencyIndexeswithValues = [];
                        DepandencyIndexeswithValues.push({
                            index: matchedrow.ChildId,
                            value: parentDependencyVal
                        })

                        CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);
                    }
                }
            }
            else {
                //commonItem.AutoText = "(Auto)";

                if (isLastEdit === false) {
                    commonItem.AutoText = "(Auto)";
                }
                // let parentSplitVal = parentDependency.split('-');
                //  let parentIndexVal = parentSplitVal[0].trim();
                // let parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;
                //  Setdepandencybasedontaskclose(commonItem.SelectedDependency, i, p, c, parentIndexVal, parentDetailTypeID);
            }
            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
        }

    }



    function ExfactoryDepandentApply(DependentID, SetStartdate, skugroup, indexofcurrrow, finaldifference, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, tempStartdate) {
        ;
        if (DependentID === 1) // ES
        {
            SetStartdate = addDays(skugroup[indexofcurrrow - 1].EndDate, finaldifference);
            //Calculation Saturday,Sunday and Holiday Count
            SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
            tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempEnddate = addDays(tempEnddate, 1);
                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
            }

            SetEnddate = tempEnddate;



        }
        else if (DependentID === 2) //SS
        {

            //Calculation Saturday,Sunday and Holiday Count
            SetStartdate = checkDateBetweenInHolidayAndWE(addDays((skugroup[indexofcurrrow - 1].StartDate), finaldifference - 1), LeaveDays, titleEmployee1, "add");
            tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempEnddate = addDays(tempEnddate, 1);
                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
            }
            SetEnddate = tempEnddate;


        }
        else if (DependentID === 3) // SE
        {
            SetStartdate = addDays(skugroup[indexofcurrrow - 1].StartDate, finaldifference);
            SetEnddate = checkDateBetweenInHolidayAndWE(subDays(SetStartdate, 1), LeaveDays, titleEmployee1, "sub");
            tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempStartdate = subDays(tempStartdate, 1);
                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
            }
            SetStartdate = tempStartdate;


        }
        else if (DependentID === 4) // EE
        {

            SetEnddate = checkDateBetweenInHolidayAndWE(addDays((skugroup[indexofcurrrow - 1].EndDate), finaldifference - 1),
                LeaveDays, titleEmployee1, "add");
            tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempStartdate = subDays(tempStartdate, 1);
                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
            }
            SetStartdate = tempStartdate;

        }
        return { SetStartdate, tempEnddate, total, SetEnddate, tempStartdate };
    }

    function DepandencyAssign(DependentID, SetStartdate, Enddate, LeaveDays, titleEmployee1, tempEnddate, Duration, total, i, p, c, isParent, SetEnddate, Startdate, tempStartdate) {
        if (DependentID === 1) {

            SetStartdate = addDays(Enddate, 1);
            //Calculation Saturday,Sunday and Holiday Count
            SetStartdate = checkDateBetweenInHolidayAndWE(SetStartdate, LeaveDays, titleEmployee1, "add");
            tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempEnddate = addDays(tempEnddate, 1);
                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
            }

            SetEnddate = tempEnddate;

        }
        //Start to Start
        if (DependentID === 2) {

            //Calculation Saturday,Sunday and Holiday Count
            SetStartdate = checkDateBetweenInHolidayAndWE(Startdate, LeaveDays, titleEmployee1, "add");
            tempEnddate = addDays(SetStartdate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(SetStartdate, tempEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempEnddate = addDays(tempEnddate, 1);
                tempEnddate = checkDateBetweenInHolidayAndWE(tempEnddate, LeaveDays, titleEmployee1, "add");
            }
            SetEnddate = tempEnddate;

        }
        //Start to End
        if (DependentID === 3) {

            SetEnddate = checkDateBetweenInHolidayAndWE(subDays(Startdate, 1), LeaveDays, titleEmployee1, "sub");
            tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempStartdate = subDays(tempStartdate, 1);
                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
            }
            SetStartdate = tempStartdate;

        }
        //End to end
        if (DependentID === 4) {

            SetEnddate = checkDateBetweenInHolidayAndWE(Enddate, LeaveDays, titleEmployee1, "sub");
            tempStartdate = subDays(SetEnddate, parseInt(Duration) - 1);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(tempStartdate, SetEnddate, LeaveDays, titleEmployee1, i, p, c, isParent);
            for (let l = 0; l < total; l++) {
                tempStartdate = subDays(tempStartdate, 1);
                tempStartdate = checkDateBetweenInHolidayAndWE(tempStartdate, LeaveDays, titleEmployee1, "sub");
            }
            SetStartdate = tempStartdate;

        }
        return { SetStartdate, tempEnddate, total, SetEnddate, tempStartdate };
    }


    function Setdepandencybasedontaskclose(ChildDepandency, i, p, c, parentDependencyIndex, parentDetailTypeID) {

        let CurrentChildvalues = [];
        values.map((Main, MainIndex) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({ StatusID: SubChild.StatusID, ChildIndex: SubChild.ChildIndex })
                })
            })
        })

        if (ChildDepandency !== '') {
            if (parentDetailTypeID !== 1) {
                if (ChildDepandency.includes(',')) {
                    let IsAllTasksClosed = true;
                    let splitmultiparentchildindex = ChildDepandency.split(',');
                    splitmultiparentchildindex.map(depitem => {
                        let splitparentchildindex = depitem.split('-');
                        if (CurrentChildvalues.filter(x => x.ChildIndex === parseInt(splitparentchildindex[1]))[0].StatusID !== 3) {
                            IsAllTasksClosed = false;
                        }
                    })

                    if (IsAllTasksClosed === true) {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 0;
                    }
                    else {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 1;
                    }

                }
                else {
                    let splitparentchildindex = ChildDepandency.split('-');
                    if (CurrentChildvalues.filter(x => x.ChildIndex === parseInt(splitparentchildindex[1]))[0].StatusID === 3) {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 0;
                    }
                    else {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 1;
                    }
                }
            }
            else {
                if (ChildDepandency.includes(',')) {

                }
                else {

                    if (values[parentDependencyIndex - 1].StatusID === 3) {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 0;
                    }
                    else {
                        values[i].SubTaskList[p].ChildTaskList[c].IsDepandency = 1;
                    }
                }
            }
        }
    }

    function ChildDepandencyTextwithSingleParentdep(DetailTypeID, commonItem, i, p, c, parentDependency, parentDependencyIndex, parentDetailTypeID) {

        let ChildDepandency = '';
        let DepandencyIndexeswithValues = [];
        let parentSplitVal = parentDependency.split('-');
        let parentIndexVal = parentSplitVal[0].trim();
        let parentDependencyVal = parentSplitVal[1].trim();
        parentDetailTypeID = values[parentIndexVal - 1].DetailTypeID;

        let SkuId = commonItem.SubId;

        if (DetailTypeID === 1) {
            ChildDepandency = parentDependency;
            DepandencyIndexeswithValues.push({
                index: values[parentIndexVal - 1].TNARouteApplyID,
                value: parentDependencyVal
            })


        }
        else if (DetailTypeID === 2) { // DetailType => Sku-----------------------------------

            // Dependent base compare to  DetailTypeID

            if (parentDetailTypeID === 1) { // DetailType => Common
                ChildDepandency = parentDependency;
                DepandencyIndexeswithValues.push({
                    index: values[parentIndexVal - 1].TNARouteApplyID,
                    value: parentDependencyVal
                })
            }
            else if (parentDetailTypeID === 2) { // DetailType => Sku
                let allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
                allmappedsku && allmappedsku.map(x => {
                    if (ChildDepandency === '') {
                        ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    } else {
                        ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    }
                    DepandencyIndexeswithValues.push({
                        index: x.ChildId,
                        value: parentDependencyVal
                    })
                })

            } else if (parentDetailTypeID === 3) { // DetailType => Item

                let allmappedsku = [];
                values[parentIndexVal - 1].SubTaskList.map((item) => {

                    allmappedsku = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()));
                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        } else {
                            ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        }
                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: parentDependencyVal
                        })
                    })


                });
                //Order by descending ( -1 : 1)



            } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory


                let allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
                allmappedsku && allmappedsku.map(x => {
                    if (ChildDepandency === '') {
                        ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    } else {
                        ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    }
                    DepandencyIndexeswithValues.push({
                        index: x.ChildId,
                        value: parentDependencyVal
                    })
                })
            }

        }
        else if (DetailTypeID === 3) { // DetailType => Item-------------------------------


            if (parentDetailTypeID === 1) { // DetailType => Common
                ChildDepandency = parentDependency;
                DepandencyIndexeswithValues.push({
                    index: values[parentIndexVal - 1].TNARouteApplyID,
                    value: parentDependencyVal
                })
            }
            else if (parentDetailTypeID === 2) { // DetailType => Sku

                let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                let allmappedsku = [];
                arySkuIdList.map((item) => {
                    allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item));
                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        } else {
                            if (!ChildDepandency.includes(parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal)) {
                                ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                            }
                        }
                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: parentDependencyVal
                        })
                    })

                });

            } else if (parentDetailTypeID === 3) { // DetailType => Item
                let TaskName = values[i].strTaskName;
                let preTaskName = values[parentIndexVal - 1].strTaskName;
                let hasSameItem = false;

                if ((TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)
                    && (preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)) {
                    hasSameItem = true;

                } else if ((TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)
                    && (preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)) {
                    hasSameItem = true;

                } else if ((TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)
                    && (preTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || preTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)) {
                    hasSameItem = true;
                }

                if (hasSameItem) { //Same Item Match
                    let SubId = commonItem.SubId;
                    let MainId = commonItem.MainId;

                    let preChildTaskList = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0] && values[parentIndexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0].ChildTaskList;
                    let allmappedsku = preChildTaskList && preChildTaskList.filter(d => d.SubId === SubId);
                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        } else {
                            if (!ChildDepandency.includes(parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal)) {
                                ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                            }
                        }
                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: parentDependencyVal
                        })
                    })


                } else { //Other Item Match Sku


                    let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                    let allmappedsku = [];
                    arySkuIdList && arySkuIdList.map((Sku) => {
                        values[parentIndexVal - 1].SubTaskList.map((item) => {
                            allmappedsku = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(Sku.toString()));
                            allmappedsku && allmappedsku.map(x => {
                                if (ChildDepandency === '') {
                                    ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                                } else {
                                    if (!ChildDepandency.includes(parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal)) {
                                        ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                                    }
                                }

                                DepandencyIndexeswithValues.push({
                                    index: x.ChildId,
                                    value: parentDependencyVal
                                })
                            })

                        });

                    });


                }

            } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory

                let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                let allmappedsku = [];
                arySkuIdList.map((item) => {
                    allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === parseInt(item));
                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        } else {
                            if (!ChildDepandency.includes(parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal)) {
                                ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                            }
                        }

                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: parentDependencyVal
                        })
                    })


                });
            }

        } else if (DetailTypeID === 4) { // DetailType => Ex-Factory---------------------------


            if (parentDetailTypeID === 1) { // DetailType => Common
                ChildDepandency = parentDependency;
                DepandencyIndexeswithValues.push({
                    index: values[parentIndexVal - 1].TNARouteApplyID,
                    value: parentDependencyVal
                })
            }
            else if (parentDetailTypeID === 2) { // DetailType => Sku
                let allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
                allmappedsku && allmappedsku.map(x => {
                    if (ChildDepandency === '') {
                        ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    } else {
                        ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    }
                    DepandencyIndexeswithValues.push({
                        index: x.ChildId,
                        value: parentDependencyVal
                    })
                })

            } else if (parentDetailTypeID === 3) { // DetailType => Item

                let allmappedsku = [];
                values[parentIndexVal - 1].SubTaskList.map((item) => {

                    allmappedsku = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(SkuId.toString()));
                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        } else {
                            ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                        }
                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: parentDependencyVal
                        })
                    })


                });
                //Order by descending ( -1 : 1)



            } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory


                let allmappedsku = values[parentIndexVal - 1].SubTaskList.length > 0 && values[parentIndexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);
                allmappedsku && allmappedsku.map(x => {
                    if (ChildDepandency === '') {
                        ChildDepandency = parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    } else {
                        ChildDepandency = ChildDepandency + "," + parentIndexVal + "-" + x.ChildIndex + "-" + parentDependencyVal;
                    }
                    DepandencyIndexeswithValues.push({
                        index: x.ChildId,
                        value: parentDependencyVal
                    })
                })
            }
        }

        if (DetailTypeID === 4 && commonItem.AssignedDepandency === '') {

            let skugroup = values[i].SubTaskList[p].ChildTaskList.filter(x => x.SubId === commonItem.SubId);
            let indexofcurrrow = skugroup.findIndex(x => x.ChildId === commonItem.ChildId);

            if (indexofcurrrow > 0) {
                let currexfactdate = commonItem.ExFacDate;
                let prevexfactdate = skugroup[indexofcurrrow - 1].ExFacDate;
                let Difference_In_Time = new Date(currexfactdate).getTime() - new Date(prevexfactdate).getTime();
                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                let finaldifference = Difference_In_Days === 0 ? 1 : (Difference_In_Days + 1)
                if (ChildDepandency === '') {
                    commonItem.SelectedDependency = (i + 1) + "-" + skugroup[indexofcurrrow - 1].ChildIndex + "-ES";
                }
                else {
                    commonItem.SelectedDependency = ChildDepandency + "," + (i + 1) + "-" + skugroup[indexofcurrrow - 1].ChildIndex + "-ES";
                }

                DepandencyIndexeswithValues.push({
                    index: skugroup[indexofcurrrow - 1].ChildId,
                    value: "ES",
                    Logdays: finaldifference - 1
                })

            }

        }



        // Setdepandencybasedontaskclose(values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency === '' ?
        //     ChildDepandency : values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency, i, p, c, parentDependencyIndex, parentDetailTypeID);
        if (values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency === '') {
            values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency = ChildDepandency;
            values[i].SubTaskList[p].ChildTaskList[c].AssignedDepandency = parentSplitVal[1].trim();
            values[i].SubTaskList[p].ChildTaskList[c].DependencyCount = DepandencyIndexeswithValues.length;
            CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c, parentIndexVal);
        }
        if (DetailTypeID === 4 && commonItem.AssignedDepandency === '') {
            values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency = ChildDepandency;
            values[i].SubTaskList[p].ChildTaskList[c].DependencyCount = DepandencyIndexeswithValues.length;
            CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c, parentIndexVal);
        }

    }

    function ChildDepandencyTextwithMultiParentdep(DetailTypeID, splitDepList, commonItem, i, p, c, SkuId) {

        let ChildDepandency = '';
        let DepandencyIndexeswithValues = [];


        splitDepList.map((depItem, d) => {

            let splitVal = depItem.split('-');
            let indexVal = splitVal[0].trim();
            let dependencyVal = splitVal[1].trim();

            // Dependent base compare to  DetailTypeID
            let parentDetailTypeID = values[indexVal - 1].DetailTypeID;
            if (parentDetailTypeID === 1) { // DetailType => Common
                ChildDepandency = depItem;
                DepandencyIndexeswithValues.push({
                    index: values[indexVal - 1].TNARouteApplyID,
                    value: dependencyVal
                })
            }
            else if (parentDetailTypeID === 2) { // DetailType => Sku

                let allmappedsku = [];
                let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                arySkuIdList && arySkuIdList.map((Sku) => {
                    values[indexVal - 1].SubTaskList.length > 0 && values[indexVal - 1].SubTaskList.map((item) => {
                        let TNASkuIdList = item.ChildTaskList.filter(d => d.SubId === parseInt(Sku))[0];
                        TNASkuIdList && allmappedsku.push(TNASkuIdList);
                        // if (TNASkuIdList && TNASkuIdList.length > 0) {

                        //     preChildDetailList = (TNASkuIdList && TNASkuIdList.length > 0) ? [preChildDetailList, ...TNASkuIdList] : preChildDetailList;
                        // }

                    });
                });

                // let allmappedsku = values[indexVal - 1].SubTaskList.length > 0 &&
                //     values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);

                allmappedsku && allmappedsku.map(x => {
                    if (ChildDepandency === '') {
                        ChildDepandency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                    } else {
                        ChildDepandency = ChildDepandency + "," + indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                    }

                    DepandencyIndexeswithValues.push({
                        index: x.ChildId,
                        value: dependencyVal
                    })

                })


            } else if (parentDetailTypeID === 3) { // DetailType => Item

                let TaskName = values[i].strTaskName;
                let preTaskName = values[indexVal - 1].strTaskName;
                let hasSameItem = false;

                if ((TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)
                    && (preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT)) {
                    hasSameItem = true;

                } else if ((TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)
                    && (preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || preTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || preTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER)) {
                    hasSameItem = true;

                } else if ((TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)
                    && (preTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || preTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL)) {
                    hasSameItem = true;
                }

                if (hasSameItem) { //Same Item Match
                    let SubId = commonItem.SubId;
                    let MainId = commonItem.MainId;

                    let preChildTaskList = values[indexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0] && values[indexVal - 1].SubTaskList.filter(d => d.MainId === MainId)[0].ChildTaskList;

                    let allmappedsku = preChildTaskList && preChildTaskList.filter(d => d.SubId === SubId);


                    allmappedsku && allmappedsku.map(x => {
                        if (ChildDepandency === '') {
                            ChildDepandency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                        } else {
                            ChildDepandency = ChildDepandency + "," + indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                        }

                        DepandencyIndexeswithValues.push({
                            index: x.ChildId,
                            value: dependencyVal
                        })
                    })


                } else { //Other Item Match Sku


                    let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                    let allmappedsku = [];
                    arySkuIdList && arySkuIdList.map((Sku) => {
                        values[indexVal - 1].SubTaskList.map((item) => {
                            allmappedsku = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(Sku.toString()));
                            allmappedsku && allmappedsku.map(x => {
                                if (ChildDepandency === '') {
                                    ChildDepandency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                                } else {
                                    ChildDepandency = ChildDepandency + "," + indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                                }

                                DepandencyIndexeswithValues.push({
                                    index: x.ChildId,
                                    value: dependencyVal
                                })
                            })

                        });
                    });


                }

            } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory

                let allmappedsku = values[indexVal - 1].SubTaskList.length > 0 && values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);

                allmappedsku && allmappedsku.map(x => {
                    if (ChildDepandency === '') {
                        ChildDepandency = indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                    } else {
                        ChildDepandency = ChildDepandency + "," + indexVal + "-" + x.ChildIndex + "-" + dependencyVal;
                    }

                    DepandencyIndexeswithValues.push({
                        index: x.ChildId,
                        value: dependencyVal
                    })
                })


            }


            //   Setdepandencybasedontaskclose(values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency === '' ?
            //      ChildDepandency : values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency, i, p, c, indexVal, parentDetailTypeID);
        })
        if (DetailTypeID === 4 && commonItem.AssignedDepandency === '') {

            let skugroup = values[i].SubTaskList[p].ChildTaskList.filter(x => x.SubId === commonItem.SubId);
            let indexofcurrrow = skugroup.findIndex(x => x.ChildId === commonItem.ChildId);

            if (indexofcurrrow > 0) {
                let currexfactdate = commonItem.ExFacDate;
                let prevexfactdate = skugroup[indexofcurrrow - 1].ExFacDate;
                let Difference_In_Time = new Date(currexfactdate).getTime() - new Date(prevexfactdate).getTime();
                let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                let finaldifference = Difference_In_Days === 0 ? 1 : (Difference_In_Days + 1)
                if (ChildDepandency === '') {
                    commonItem.SelectedDependency = (i + 1) + "-" + skugroup[indexofcurrrow - 1].ChildIndex + "-ES";
                }
                else {
                    commonItem.SelectedDependency = ChildDepandency + "," + (i + 1) + "-" + skugroup[indexofcurrrow - 1].ChildIndex + "-ES";
                }

                DepandencyIndexeswithValues.push({
                    index: skugroup[indexofcurrrow - 1].ChildIndex,
                    value: "ES",
                    Logdays: finaldifference - 1
                })

            }

        }


        if (values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency === '') {
            values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency = ChildDepandency;
            values[i].SubTaskList[p].ChildTaskList[c].DependencyCount = DepandencyIndexeswithValues.length;
            CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);
        }
        if (DetailTypeID === 4 && commonItem.AssignedDepandency === '') {
            values[i].SubTaskList[p].ChildTaskList[c].SelectedDependency = ChildDepandency;
            values[i].SubTaskList[p].ChildTaskList[c].DependencyCount = DepandencyIndexeswithValues.length;
            CreateChildDepandencydata(DepandencyIndexeswithValues, i, p, c);
        }
    }
    function CreateChildDepandencydata(DepandencyIndexeswithValues, index, subIndex, childIndex) {

        let RowIndex = 1;
        let TaskDependency = [];

        values.map((Main, Mainindex) => {
            if (Main.IsSingleTask === true) {
                TaskDependency.push(
                    {
                        TNAChildDependencyID: 0,
                        DependentTaskId: Main.TNARouteApplyID,
                        TNATaskName: Main.strTaskName,
                        DependencyID: 0,
                        LogDays: '',
                        IndexName: RowIndex,
                        Mainindex: Mainindex,
                        StartDate: Main.StartDate,
                        EndDate: Main.EndDate,
                        Duration: Main.Duration,
                        ChildIndex: Mainindex + 1,
                        IsSingleTask: true
                    }
                )

                RowIndex++;
            }
            Main.SubTaskList.map((Child, ChildIndex) => {
                Child.ChildTaskList.map((SubChild) => {
                    TaskDependency.push(
                        {
                            TNAChildDependencyID: 0,
                            DependentTaskId: SubChild.ChildId,
                            TNATaskName: SubChild.Name,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: RowIndex,
                            Mainindex: Mainindex,
                            StartDate: SubChild.StartDate,
                            EndDate: SubChild.EndDate,
                            Duration: SubChild.Duration,
                            ChildIndex: SubChild.ChildIndex,
                            IsSingleTask: false
                        }
                    )

                    RowIndex++;
                })
            })


        });

        DepandencyIndexeswithValues.map(x => {
            TaskDependency.map(t => {
                if (x.index.toString() === t.DependentTaskId.toString()) {
                    let Depandencyval = x.value;
                    t.DependencyID = Depandencyval === "ES" ? 1 : Depandencyval === "SS" ? 2 : Depandencyval === "SE" ? 3 : Depandencyval === "EE" ? 4 : "";
                    t.LogDays = x.Logdays;
                }
            })
        })


        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency = [];
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].arrDependency = TaskDependency;
        setInputFields(values);

    }

    //----Manual Close-------
    // const handleManualclose = (index) => {
    //     if (values[index].TaskID === 0) {
    //         Nodify('Warning!', 'warning', 'Please select the task name.');
    //     }
    //     else {
    //         values[index].IsManual = 1;
    //         values[index].StatusID = 3;
    //         setTaskIndexFileupload(index);
    //         setTNAtaskIdFileupload(values[index].TaskID);
    //         // TaskIndexFileupload = index;
    //         // TNAtaskIdFileupload = values[index].TaskName;
    //         setInputFields(values);
    //     }
    //};
    const CustomInputman = (props) => {

        return (
            <input
                className="form-control DatePickerCalenderHoliday min_width_Date"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
                style={{ border: getComment && props.value === '' ? '1px solid red' : '' }}
            />
        )
    }
    const handleManualclose = (index, TNARouteApplyID, Status) => {


        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            if (values[index].intDependency === 0) {
                let Dependency = false;
                if (values[index].SelectedDependency) {
                    let arrstatus = [];
                    arrstatus = values[index].SelectedDependency.split(',');
                    if (arrstatus.length > 0) {
                        arrstatus.forEach(element => {
                            let val = [];
                            val = element.split('-');
                            let valIndex = values[parseInt(val[0]) - 1].StatusID
                            //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID
                            if (valIndex !== 3) {
                                Dependency = true;
                            }


                        });
                    }
                    if (Dependency) {
                        // setComment(true);
                        Nodify('Warning!', 'warning', "Please close dependency task");
                    }
                    else {

                        let params = {
                            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                            SupplierId: getTNASupplierID, TaskType: 0, TaskNameId: values[index].TaskID,
                            TaskTodoInProgressCompletedStatus: Status !== 3 ? 4 : 5, StartDate: null, EndDate: null,
                            Operation: 8, ParentIdOrSubChildId: TNARouteApplyID, IsProduction: IsProduction
                        };

                        TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                            if (response.data) {


                                let data = response.data.map((item) => {
                                    if (item.subTaskInfoId === TNARouteApplyID) {
                                        item.isSelected = 1;
                                        return item;
                                    }
                                    return item;
                                    //[item['strikeofforTrimLogoId'], item.subTaskInfoId === ChildId ? (item = item.isSelected = 1) : item]
                                });


                                hideLoader();

                                let isSelectAll = 0;
                                if (data) {
                                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                                    isSelectAll = isSelectAll ? 1 : 0;
                                }

                                setselectall(isSelectAll)

                                if (Status !== 3) {
                                    setMultiCloseDatas(data);
                                }
                                else {
                                    setMultiCloseDatas(data.filter(x => x.subTaskInfoId === TNARouteApplyID));
                                }


                            }
                        }).catch(() => { hideLoader(); });
                        values[index].IsManual = 1;
                    }
                } else {

                    values[index].IsManual = 1;
                    let params = {
                        BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                        SupplierId: getTNASupplierID, TaskType: 0, TaskNameId: values[index].TaskID,
                        TaskTodoInProgressCompletedStatus: Status !== 3 ? 4 : 5, StartDate: null, EndDate: null,
                        Operation: 8, ParentIdOrSubChildId: TNARouteApplyID, IsProduction: IsProduction
                    };

                    TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                        if (response.data) {


                            let data = response.data.map((item) => {
                                if (item.subTaskInfoId === TNARouteApplyID) {
                                    item.isSelected = 1;
                                    return item;
                                }
                                return item;
                                //[item['strikeofforTrimLogoId'], item.subTaskInfoId === ChildId ? (item = item.isSelected = 1) : item]
                            });


                            hideLoader();

                            let isSelectAll = 0;
                            if (data) {
                                isSelectAll = !!!data.find(d => d.isSelected === 0);
                                isSelectAll = isSelectAll ? 1 : 0;
                            }

                            setselectall(isSelectAll)

                            if (Status !== 3) {
                                setMultiCloseDatas(data);
                            }
                            else {
                                setMultiCloseDatas(data.filter(x => x.subTaskInfoId === TNARouteApplyID));
                            }
                            setInputFields(values);

                        }
                    }).catch(() => { hideLoader(); });
                }
                setInputFields(values);

            }
            else {
                values[index].IsManual = 1;
                setInputFields(values);

            }

        }
    };

    const handleCloseManual = (index, cmmnt, status) => {
        if (status === 3 && cmmnt !== '') {
            values[index].IsManual = 0;
        }
        if (status !== 3 && cmmnt !== '') {
            values[index].IsManual = 0;
            values[index].ManualComments = "";
        }
        else {
            values[index].IsManual = 0;

        }
        setInputFields(values);
        setmaincomment(false)

    };


    const handledelete = (index, subIndex, childIndex, val, childid, Taskname) => {
        setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
        let Value = [];
        Value.TNAId = props.location.state.params.tnaid;
        if (val === 'Parent') {
            values[index].IsManual = 0;
            Value.Id = values[index].TNARouteApplyID;
            setID({ TNAManualCloses: TNAManualCloseParent, showPopupDelete: true, Params: Value });
        }
        else {
            let ManualTaskName;
            //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
            if (values[index].DetailTypeID === 4 || Taskname.toLowerCase() === StaticTask.STRIKEOFF_TEST ||
                Taskname.toLowerCase() === StaticTask.TRIMS_TEST || Taskname.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION ||
                Taskname.toLowerCase() === StaticTask.LAB_DIP_APPROVAL || Taskname.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                Taskname.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || Taskname.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                Taskname.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION
                || Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL
                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION
                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || Taskname.toLowerCase() === StaticTask.SMS_APPROVAL
                || Taskname.toLowerCase() === StaticTask.SMS_SUBMISSION
                || Taskname.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || Taskname.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                || Taskname.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || Taskname.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                || Taskname.toLowerCase() === StaticTask.PHOTO_SUBMISSION || Taskname.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
                Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
            }
            else {
                Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;
            }
            ;

            let IsValid = false;
            if (Taskname === 'Lab Dip Submission' || Taskname === 'Strike off Submission (Local Development)'
                || Taskname === 'Trim Submission (Local Development)' || Taskname === 'Proto Sample Submission' || Taskname === "Fit Sample Submission"
                || Taskname === "SMS Submission" || Taskname === "Size Set Sample Submission"
                || Taskname === "Pre Production Sample Submission"
                || Taskname === "Shipment Sample Submission"
                || Taskname === "Photo Sample Submission") {
                if (Taskname === 'Lab Dip Submission') {
                    let Params = {
                        Operation: 52,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);

                            // setexistsubmission(true)
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";

                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            //Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION ||
                                Taskname.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) { ManualTaskName = TNAManualCloseChildLibDip; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
                else if (Taskname === 'Strike off Submission (Local Development)') {
                    let Params = {
                        Operation: 53,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";
                            // setexistsubmission(true)
                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            //Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                Taskname.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT ||
                                Taskname.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER ||
                                Taskname.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                Taskname.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) { ManualTaskName = TNAManualCloseChildStrikeOff; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
                else if (Taskname === 'Trim Submission (Local Development)') {
                    let Params = {
                        Operation: 54,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";
                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            //Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                                || Taskname.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                || Taskname.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT
                                || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER
                                || Taskname.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER
                            ) { ManualTaskName = TNAManualCloseChildTrim; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
                else if (Taskname === 'Proto Sample Submission') {
                    let Params = {
                        Operation: 55,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";
                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            // Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION
                                || Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION
                                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) { ManualTaskName = TNAManualCloseChildProtoFit; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
                else if (Taskname === "Fit Sample Submission") {
                    let Params = {
                        Operation: 56,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";
                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            // Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION
                                || Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION
                                || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) { ManualTaskName = TNAManualCloseChildProtoFit; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
                else if (Taskname === "SMS Submission"
                    || Taskname === "Size Set Sample Submission"
                    || Taskname === "Pre Production Sample Submission"
                    || Taskname === "Shipment Sample Submission"
                    || Taskname === "Photo Sample Submission") {
                    let Params = {
                        Operation: 57,
                        Id: childid,
                    };
                    CommonService.CheckMappedItem(Params).then(res => {
                        if (res.data.outputResult === "-2") {
                            // setchkDataExists(true);
                            let Title = "Warning!";
                            let message = "Approval exists against this Submission.";
                            let type = "warning";
                            Nodify(Title, type, message);
                        }
                        else {
                            let ManualTaskName;
                            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                            //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                            // Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                            // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                            if (Taskname.toLowerCase() === StaticTask.SMS_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.SMS_SUBMISSION
                                || Taskname.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
                                || Taskname.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
                                || Taskname.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                                || Taskname.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                                || Taskname.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE
                                || Taskname.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || Taskname.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || Taskname.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                                || Taskname.toLowerCase() === StaticTask.PHOTO_SUBMISSION || Taskname.toLowerCase() === StaticTask.PHOTO_APPROVAL) { ManualTaskName = TNAManualCloseChildSms; }
                            setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
                        }
                    })
                }
            }
            else {
                let ManualTaskName;
                //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
                //Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;

                // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
                if (Taskname.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION ||
                    Taskname.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) { ManualTaskName = TNAManualCloseChildLibDip; }
                else if (Taskname.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                    || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                    || Taskname.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                    || Taskname.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT
                    || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER
                    || Taskname.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                    || Taskname.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER
                ) { ManualTaskName = TNAManualCloseChildTrim; }
                else if (Taskname.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) { ManualTaskName = TNAManualCloseChildStrikeOff; }
                else if (Taskname.toLowerCase() === StaticTask.SMS_APPROVAL
                    || Taskname.toLowerCase() === StaticTask.SMS_SUBMISSION
                    || Taskname.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
                    || Taskname.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
                    || Taskname.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                    || Taskname.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                    || Taskname.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE
                    || Taskname.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || Taskname.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                    || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || Taskname.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                    || Taskname.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || Taskname.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                    || Taskname.toLowerCase() === StaticTask.PHOTO_SUBMISSION || Taskname.toLowerCase() === StaticTask.PHOTO_APPROVAL) { ManualTaskName = TNAManualCloseChildSms; }
                else if (Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION
                    || Taskname.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL
                    || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION
                    || Taskname.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) { ManualTaskName = TNAManualCloseChildProtoFit; }
                else if (values[index].DetailTypeID === 4)// Ex-Factory  (Detail Type)
                { ManualTaskName = TNAManualCloseChildExFactory; }
                else if (Taskname.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                    ManualTaskName = TNAManualCloseChildStrikeoffTest;
                }
                else if (Taskname.toLowerCase() === StaticTask.TRIMS_TEST) {
                    ManualTaskName = TNAManualCloseChildTrimTest;
                }
                else if (Taskname.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || Taskname.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                    ManualTaskName = TNAManualCloseChildLibDip;
                }
                else if (Taskname.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                    ManualTaskName = TNAManualCloseChildStrikeOff;
                }
                else if (Taskname.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                    Taskname.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                    ManualTaskName = TNAManualCloseChildStrikeOff;
                }
                else if (Taskname.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                    Taskname.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
                    ManualTaskName = TNAManualCloseChildTrim;
                }
                setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });
            }

        }


    }

    // const handledelete = (index, subIndex, childIndex, val, childid) => {

    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
    //     let Value = [];
    //     Value.TNAId = props.location.state.params.tnaid;
    //     if (val === 'Parent') {
    //         values[index].IsManual = 0;
    //         Value.Id = values[index].TNARouteApplyID;
    //         setID({ TNAManualCloses: TNAManualCloseParent, showPopupDelete: true, Params: Value });
    //     }
    //     else {

    //         let ManualTaskName;
    //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
    //         //Value = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //         if (values[index].DetailTypeID === 4) {
    //             Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
    //         } else {
    //             Value.Id = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;
    //         }


    //         // Hardcoded for Taskname ID  - Refer this select * from MasTaskDetails
    //         if (val === 81 || val === 82) { ManualTaskName = TNAManualCloseChildLibDip; }
    //         else if (val === 59 || val === 60 || val === 68 || val === 69 || val === 78 || val === 79 || val === 80) { ManualTaskName = TNAManualCloseChildTrim; }
    //         else if (val === 61 || val === 62 || val === 63 || val === 64 || val === 65) { ManualTaskName = TNAManualCloseChildStrikeOff; }
    //         else if (val === 56 || val === 77 || val === 66 || val === 67 || val === 70 || val === 73 || val === 74) { ManualTaskName = TNAManualCloseChildSms; }
    //         else if (val === 57 || val === 58 || val === 71 || val === 72) { ManualTaskName = TNAManualCloseChildProtoFit; }
    //         else if (values[index].DetailTypeID === 4)// Ex-Factory  (Detail Type)
    //         { ManualTaskName = TNAManualCloseChildExFactory; }

    //         setsID({ TNAManualClose: ManualTaskName, showPopupDelete: true, Params: Value });

    //     }


    // }

    // const handleManual = (index, assignValue) => {

    //     if (assignValue === '' || assignValue === undefined) {
    //         setmaincomment(true);
    //         Nodify('Warning!', 'warning', "Please enter comments");
    //     }
    //     else {
    //         values[index].IsManualClose = 0;
    //         values[index].StatusID = 3;
    //         values[index].ManualCloseComment = assignValue;
    //         values[index].ManualCloseDate = new Date();
    //         values[index].ActualEndDate = new Date();
    //         // values[index].StrActualEndDate =  moment(values[index].ActualEndDate, 'MM/DD/YYYY').toISOString();
    //         // const yourDate = new Date()
    //         values[index].StrActualEndDate = moment(new Date()).format("MM/DD/YYYY");
    //         //values[index].StrActualEndDate = values[index].ActualEndDate.toString('MM/DD/YYYY');

    //         values[index].Manual = '';
    //         values[index].IsManual = 0;

    //         setInputFields(values);

    //     }

    // }
    const handleManual = (index, assignValue, CloseDate) => {

        if (assignValue === '' || assignValue === undefined) {
            setmaincomment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
        }
        else if (CloseDate === '' || CloseDate === null) {
            setmaincomment(true);
            Nodify('Warning!', 'warning', "Please enter Close Date");
        }
        else {

            values[index].IsManualClose = 0;
            values[index].StatusID = 3;
            values[index].ManualCloseComment = assignValue;
            values[index].ManualCloseDate = new Date();
            values[index].ActualEndDate = new Date();
            values[index].Manual = '';
            values[index].IsManual = 0;


            var ManualCloseData = { ...MultiManualCloseFeilds };
            ManualCloseData.ManualCloseDate = new Date();
            ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
            // .setDate(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate.getDate() + 1);
            ManualCloseData.ManualCloseComment = assignValue;
            ManualCloseData.IdList = values[index].TNARouteApplyID.toString();
            ManualCloseData.TaskStatus = 6;
            ManualCloseData.CreatedBy = 1;

            TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
                if (res.data.outputResult === "2") {
                    Nodify('Success!', 'success', 'Task Manually Closed Successfully');
                }
            })

            setInputFields(values);

        }

    }
    function handleCloseQAInspecView(index, subIndex, childIndex) {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQAInsView = 0;
        setInputFields(values);
    }
    function handleQAInspecViewDetails(item, ActionType) {

        const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        getQCInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getQCInspectionInfo.quantityinfo = getAddQCInspectionListParam// For Binding Selected checkbox values in quantity inforamtion
        getQCInspectionInfo.details = props.location.state.params;
        getQCInspectionInfo.TNAQCInspectionID = item.tnaqcInspectionID;
        getQCInspectionInfo.TnaID = props.location.state.params.tnaid
        getQCInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
        getQCInspectionInfo.Isproduction = IsProduction;
        getQCInspectionInfo.Action = ActionType;
        getQCInspectionInfo.ParentTask = "TNAView";
        getQCInspectionInfo.Mode = "AddInspection";
        getQCInspectionInfo.IsAddorEditReinspection = item.inspectionType === 1 ? ActionType + ' Inspection' : ActionType + ' ReInspection';
        props.history.push(`/AddQCInspection`, { params: getQCInspectionInfo });
    }
    function handleDeleteQAinspection(item) {
        let PackingParams = {
            Operation: 110,
            Id: item.tnaqcInspectionID,
        }
        setIDQATask({ showPopupDelete: true, Params: PackingParams });
    }
    // common Child Set Dependency Status (No Dependency for Child and first time load)
    function commonChildSetDependencyStatus(parentItem, commonItem, i, p, c, index, parentDetailTypeID, parentIndexVal, parentDependencyVal,
        dbStartDate, dbEndDate, LeaveDays, titleEmployee1, Duration, total, preChildDetail, isParent) {

        if (commonItem.SelectedDependency === '') {
            let commonPreStartDate = new Date();
            let commonPreEndDate = new Date();
            // let PreChildIndex = '';
            // let PreParentIndex = '';

            if (parentDetailTypeID === 1) { // DetailType => Common
                commonPreStartDate = values[parentIndexVal - 1].StartDate;
                commonPreEndDate = values[parentIndexVal - 1].EndDate;
            } else if (parentDetailTypeID === 2) { // DetailType => Sku
                commonPreStartDate = preChildDetail.StartDate;
                commonPreEndDate = preChildDetail.EndDate;

            } else if (parentDetailTypeID === 3) { // DetailType => Item
                commonPreStartDate = preChildDetail.StartDate;
                commonPreEndDate = preChildDetail.EndDate;
            } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
                commonPreStartDate = preChildDetail.StartDate;
                commonPreEndDate = preChildDetail.EndDate;
            }


            if (parentDependencyVal === "SS") {   //---- Start-Start ---- DependencyID 2------
                //Get Dependent Map StartDate
                let preStartDate = commonPreStartDate;
                let LogDate = new Date();
                LogDate.setHours(0, 0, 0, 0);
                dbStartDate.setHours(0, 0, 0, 0);

                //Auto Calculation function
                // dbStartDate = values[indexVal - 1].StartDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                if (Log && Log[0] && Log[0].LogDays) {
                    dbStartDate = addDays(preStartDate, parseInt(Log[0].LogDays));
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "add");
                } else {
                    //Calculation Saturday,Sunday and Holiday Count
                    dbStartDate = checkDateBetweenInHolidayAndWE(preStartDate, LeaveDays, titleEmployee1, "add");
                }
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                }

                commonItem.EndDate = dbEndDate;
                commonItem.StartDate = dbStartDate;
                commonItem.PreEndDate = dbEndDate;
                commonItem.PreStartDate = dbStartDate;
                // PreChildIndex = preChildDetail.ChildIndex;
                // values.map((Main, Mainindex) => {
                //     Main.SubTaskList.map((Child, ChildIndex) => {
                //         Child.ChildTaskList.map((SubChild) => {
                //             if (SubChild.ChildIndex === PreChildIndex) {
                //                 PreParentIndex = Mainindex + 1;
                //             }

                //         })
                //     })
                // })

                // commonItem.SelectedDependency = PreParentIndex + "-" + PreChildIndex + "-" + parentDependencyVal;
                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;

            } else if (parentDependencyVal === "SE") {  //---- Start-End ----DependencyID 3---

                let preStartDate = subDays(commonPreStartDate, 1);
                let LogDate = preStartDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3)[0];
                LogDate.setHours(0, 0, 0, 0);
                dbEndDate.setHours(0, 0, 0, 0);
                if (LogDate <= dbEndDate && commonItem.EndDateChange === 2) {
                    //Skip
                } else {
                    //Auto Calculation function
                    if (Log && Log[0] && Log[0].LogDays) {
                        //LogDays Add
                        dbEndDate = addDays(preStartDate, parseInt(Log.LogDays));
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                    } else {
                        dbEndDate = checkDateBetweenInHolidayAndWE(preStartDate, LeaveDays, titleEmployee1, "sub");
                    }
                    dbStartDate = subDays(dbEndDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                    for (let l = 0; l < total; l++) {
                        dbStartDate = subDays(dbStartDate, 1);
                        dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                    }
                }
                commonItem.StartDate = dbStartDate;
                commonItem.EndDate = dbEndDate;
                commonItem.PreStartDate = dbStartDate;
                commonItem.PreEndDate = dbEndDate;

                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;

            } else if (parentDependencyVal === "ES") {  //Manual Calculation function-------- End-Start ----DependencyID 1------
                //Get Dependent Map StartDate
                let preEndDate = commonPreEndDate;
                let LogDate = new Date();
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1)[0];
                LogDate.setHours(0, 0, 0, 0);
                preEndDate.setHours(0, 0, 0, 0);

                //Auto Calculation function
                dbStartDate = addDays(preEndDate, 1);
                if (Log && Log[0] && Log[0].LogDays) {
                    //LogDays Add
                    dbStartDate = addDays(dbStartDate, parseInt(Log.LogDays));
                }
                //Calculation Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "add");
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                }

                commonItem.EndDate = dbEndDate;
                commonItem.StartDate = dbStartDate;
                commonItem.PreStartDate = dbStartDate;
                commonItem.PreEndDate = dbEndDate;

                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;

            } else if (parentDependencyVal === "EE") {//---- End-End --------DependencyID 4-----------((preEndDate) + LogDays = EndDate))-------
                let preEndDate = commonPreEndDate;
                let LogDate = preEndDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4)[0];
                LogDate.setHours(0, 0, 0, 0);
                dbEndDate.setHours(0, 0, 0, 0);
                //Auto Calculation function
                if (Log && Log[0] && Log[0].LogDays) {
                    //LogDays Add
                    dbEndDate = addDays(preEndDate, parseInt(Log.LogDays));
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, LeaveDays, titleEmployee1, "add");
                } else {
                    dbEndDate = checkDateBetweenInHolidayAndWE(preEndDate, LeaveDays, titleEmployee1, "sub",);
                }

                dbStartDate = subDays(dbEndDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
                for (let l = 0; l < total; l++) {
                    dbStartDate = subDays(dbStartDate, 1);
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, LeaveDays, titleEmployee1, "sub");
                }
                commonItem.EndDate = dbEndDate;
                commonItem.StartDate = dbStartDate;
                commonItem.PreStartDate = dbStartDate;
                commonItem.PreEndDate = dbEndDate;


                values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }
        }
        else {
            values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            totalHolidayAndWE(commonItem.StartDate, commonItem.EndDate, LeaveDays, titleEmployee1, i, p, c, isParent);
        }
    }

    function commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult,
        isEndDate, commonItem, dependencyVal, parentIndexVal, parentDetailTypeID, preChildDetail, i, p, c) {

        let commonPreStartDate = new Date();
        let commonPreEndDate = new Date();
        let total = 0;
        let dbEndDate = new Date();
        let dbStartDate = new Date();
        let titleEmployee1 = parentItem.titleEmployee1;

        if (parentDetailTypeID === 1) { // DetailType => Common
            commonPreStartDate = values[parentIndexVal - 1].StartDate;
            commonPreEndDate = values[parentIndexVal - 1].EndDate;
        } else if (parentDetailTypeID === 2) { // DetailType => Sku
            commonPreStartDate = preChildDetail.StartDate;
            commonPreEndDate = preChildDetail.EndDate;

        } else if (parentDetailTypeID === 3) { // DetailType => Item
            commonPreStartDate = preChildDetail.StartDate;
            commonPreEndDate = preChildDetail.EndDate;
        } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
            commonPreStartDate = preChildDetail.StartDate;
            commonPreEndDate = preChildDetail.EndDate;
        }

        try {

            if (dependencyVal === "SS") {
                let preStartDate = commonPreStartDate;
                let LogDate = new Date();
                LogDate.setHours(0, 0, 0, 0);
                dbStartDate.setHours(0, 0, 0, 0);

                //Auto Calculation function
                // dbStartDate = values[indexVal - 1].StartDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                if (Log && Log[0] && Log[0].LogDays) {
                    dbStartDate = addDays(preStartDate, parseInt(Log[0].LogDays));
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "add");
                } else {
                    //Calculation Saturday,Sunday and Holiday Count
                    dbStartDate = checkDateBetweenInHolidayAndWE(preStartDate, parentItem.LeaveDays, titleEmployee1, "add");
                }
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                }

                dependencyMult.push({ Date: dbStartDate, depItem: depItem, depValue: "SS" });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem, depValue: "SS" });

            } else if (dependencyVal === "SE") {
                let preStartDate = subDays(commonPreStartDate, 1);
                let LogDate = preStartDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3)[0];
                LogDate.setHours(0, 0, 0, 0);
                dbEndDate.setHours(0, 0, 0, 0);
                if (LogDate <= dbEndDate && commonItem.EndDateChange === 2) {
                    //Skip
                } else {
                    //Auto Calculation function
                    if (Log && Log[0] && Log[0].LogDays) {
                        //LogDays Add
                        dbEndDate = addDays(preStartDate, parseInt(Log.LogDays));
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                    } else {
                        dbEndDate = checkDateBetweenInHolidayAndWE(preStartDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                    dbStartDate = subDays(dbEndDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                    for (let l = 0; l < total; l++) {
                        dbStartDate = subDays(dbStartDate, 1);
                        dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                }
                dependencyMult.push({ Date: dbStartDate, depItem: depItem, depValue: "SE" });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem, depValue: "SE" });

            } else if (dependencyVal === "ES") {
                //---- End-Start ----
                let preEndDate = commonPreEndDate;
                let LogDate = new Date();
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1)[0];
                LogDate.setHours(0, 0, 0, 0);
                preEndDate.setHours(0, 0, 0, 0);

                //Auto Calculation function
                dbStartDate = addDays(preEndDate, 1);
                if (Log && Log[0] && Log[0].LogDays) {
                    //LogDays Add
                    dbStartDate = addDays(dbStartDate, parseInt(Log.LogDays));
                }
                //Calculation Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "add");
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                }
                dependencyMult.push({ Date: dbStartDate, depItem: depItem, depValue: "ES" });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem, depValue: "ES" });

            } else if (dependencyVal === "EE") {
                let preEndDate = commonPreEndDate;
                let LogDate = preEndDate;
                let Log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4)[0];
                LogDate.setHours(0, 0, 0, 0);
                dbEndDate.setHours(0, 0, 0, 0);
                //Auto Calculation function
                if (Log && Log[0] && Log[0].LogDays) {
                    //LogDays Add
                    dbEndDate = addDays(preEndDate, parseInt(Log.LogDays));
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                } else {
                    dbEndDate = checkDateBetweenInHolidayAndWE(preEndDate, parentItem.LeaveDays, titleEmployee1, "sub",);
                }

                dbStartDate = subDays(dbEndDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                for (let l = 0; l < total; l++) {
                    dbStartDate = subDays(dbStartDate, 1);
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "sub");
                }

                dependencyMult.push({ Date: dbStartDate, depItem: depItem, depValue: "EE" });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem, depValue: "EE" });
            }
        }

        catch (e) {
            console.log(e);
        }

    }
    function pageRedirectForQAInspection(value) {
        setQAInspectionPageView(value);
    }
    function QCFinalInspectionCallback(value) {
        setQCfinalinspectionMulti(value);
    }
    //SKU Base Multiple Dependency Date add
    function addMultDependencyDateSKUBase(values, i, p, c, index, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, SkuId) {

        let splitVal = depItem.split('-');
        let indexVal = splitVal[0].trim();
        let dependencyVal = splitVal[1].trim();
        let hasSKUNotMatch = false;


        // Dependent base compare to  DetailTypeID
        let parentDetailTypeID = values[indexVal - 1].DetailTypeID;
        if (parentDetailTypeID === 1) { // DetailType => Common
            commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem,
                dependencyVal, indexVal, parentDetailTypeID, null, i, p, c)
        } else if (parentDetailTypeID === 2) { // DetailType => Sku
            let preChildDetailList = []; let preChildDetail = [];
            let TaskName = values[index].strTaskName;
            let hasSameItem = false;

            if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                hasSameItem = true;

            } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                hasSameItem = true;

            } else if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                hasSameItem = true;
            }

            if (hasSameItem) {

                let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
                arySkuIdList && arySkuIdList.map((Sku) => {
                    values[indexVal - 1].SubTaskList.map((item) => {
                        let TNASkuIdList = item.ChildTaskList.filter(d => d.SubId === parseInt(Sku))[0];
                        TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                    });
                });
                preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
                if (preChildDetail) {
                    if (preChildDetail) {
                        commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem,
                            dependencyVal, indexVal, parentDetailTypeID, preChildDetail, i, p, c)
                    }
                    else {
                        hasSKUNotMatch = true;
                        // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                    }
                }
            }
            else {
                preChildDetail = values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId)[0];

                if (preChildDetail) {
                    commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem,
                        dependencyVal, indexVal, parentDetailTypeID, preChildDetail, i, p, c)
                }
                else {
                    hasSKUNotMatch = true;
                    // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
                }
            }
        } else if (parentDetailTypeID === 3) { // DetailType => Item
            let preChildDetailList = [];

            let arySkuIdList = commonItem.SKUIdList && commonItem.SKUIdList.split(',');
            arySkuIdList && arySkuIdList.map((Sku) => {
                values[indexVal - 1].SubTaskList.map((item) => {
                    let TNASkuIdList = item.ChildTaskList.filter(d => d.SKUIdList && d.SKUIdList.split(',').includes(Sku.toString()))[0];

                    TNASkuIdList && preChildDetailList.push(TNASkuIdList);
                    // if (TNASkuIdList && TNASkuIdList.length > 0) {

                    //     preChildDetailList = (TNASkuIdList && TNASkuIdList.length > 0) ? [preChildDetailList, ...TNASkuIdList] : preChildDetailList;
                    // }

                });
            });

            //Order by descending ( -1 : 1)
            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["EndDate"])) > moment(new Date(b["EndDate"])) ? -1 : 1))[0];
            if (preChildDetail) {
                commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate,
                    commonItem, dependencyVal, indexVal, parentDetailTypeID, preChildDetail, i, p, c)
            } else {
                hasSKUNotMatch = true;
                // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }
        } else if (parentDetailTypeID === 4) { // DetailType => Ex-Factory
            let preChildDetailList = values[indexVal - 1].SubTaskList[0].ChildTaskList.filter(d => d.SubId === SkuId);

            //Order by ASC a - b
            let preChildDetail = preChildDetailList && preChildDetailList.sort((a, b) => (moment(new Date(a["StartDate"])) - moment(new Date(b["StartDate"]))))[0];
            if (preChildDetail) {
                commonChildSetMultDependencyStatus(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate,
                    commonItem, dependencyVal, indexVal, parentDetailTypeID, preChildDetail, i, p, c)
            }
            else {
                hasSKUNotMatch = true;
                // values[i].SubTaskList[p].ChildTaskList[c] = commonItem;
            }

        }


    }



    //Multiple Dependency Date add
    function addMultDependencyDate(values, parentItem, depItem, oldDuration, Duration, dependencyMult, isEndDate, commonItem, i, p, c) {
        let splitVal = depItem.split('-');
        let indexVal = splitVal[0].trim();
        let dependencyVal = splitVal[1].trim();
        let total = 0;
        let dbEndDate = new Date();
        let dbStartDate = new Date();
        let titleEmployee1 = parentItem.titleEmployee1;
        try {

            if (dependencyVal === "SS") {
                //---- Start-Start ----
                dbStartDate = values[indexVal - 1].StartDate;
                let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 2);
                if (LogDays && LogDays[0] && LogDays.length > 0) {
                    //LogDays Add
                    LogDays = LogDays[0].LogDays;
                    if (LogDays) {
                        dbStartDate = addDays(dbStartDate, parseInt(LogDays));
                    }
                }
                //Calculation Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "add");
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                }

                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });

            } else if (dependencyVal === "SE") {
                //---- Start-End ----
                dbEndDate = subDays(values[indexVal - 1].StartDate, 1);
                let LogDate = dbEndDate;
                let log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3)[0];
                //let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 3);

                if (isEndDate === true && commonItem.EndDateChange !== 2) {
                    if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                        //LogDays Add
                        LogDate = addDays(LogDate, parseInt(log.LogDays));
                    }
                    LogDate.setHours(0, 0, 0, 0);
                    let newDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, titleEmployee1, "add");
                    newDate.setHours(0, 0, 0, 0);
                    if (LogDate <= newDate) {
                        let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, titleEmployee1, "add");
                        let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);

                        let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                        diffDayCount = diffDayCount - holidayTotal;
                        commonItem.Duration = diffDayCount;
                        // Duration = diffDayCount - 1;
                        dbStartDate = commonItem.StartDate;
                        dbEndDate = LastDate;
                        // Item.Duration = Duration;
                    } else {
                        dbStartDate = commonItem.StartDate;
                        dbEndDate = commonItem.PreEndDate;
                        commonItem.Duration = oldDuration;

                    }
                } else {
                    if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                        dbEndDate = addDays(dbEndDate, parseInt(log.LogDays));
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                    } else {
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                    dbStartDate = subDays(dbEndDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                    for (let l = 0; l < total; l++) {
                        dbStartDate = subDays(dbStartDate, 1);
                        dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                }
                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });

            } else if (dependencyVal === "ES") {
                //---- End-Start ----
                dbStartDate = addDays(values[indexVal - 1].EndDate, 1);
                let log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1)[0];
                //let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 1);
                if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                    //LogDays Add
                    dbStartDate = addDays(dbStartDate, parseInt(log.LogDays));
                }

                //Calculation Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "add");
                dbEndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                for (let l = 0; l < total; l++) {
                    dbEndDate = addDays(dbEndDate, 1);
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                }
                // let flyEndDate = addDays(dbEndDate, total);
                // //Calculation Saturday,Sunday and Holiday Count
                // flyEndDate = checkDateBetweenInHolidayAndWE(flyEndDate, parentItem.LeaveDays, "add");
                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });

            } else if (dependencyVal === "EE") {
                //---- End-End ----
                dbEndDate = values[indexVal - 1].EndDate;
                let LogDate = dbEndDate;
                let log = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4)[0];

                if (isEndDate === true && commonItem.EndDateChange !== 2) {
                    if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                        //LogDays Add
                        LogDate = addDays(LogDate, parseInt(log.LogDays));
                    }
                    LogDate.setHours(0, 0, 0, 0);
                    let newDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, titleEmployee1, "add");
                    newDate.setHours(0, 0, 0, 0);
                    if (LogDate <= newDate) {
                        let LastDate = checkDateBetweenInHolidayAndWE(new Date(commonItem.EndDate), parentItem.LeaveDays, titleEmployee1, "add");
                        let diffDayCount = diffDays(new Date(commonItem.StartDate), LastDate);
                        let holidayTotal = totalHolidayAndWE(commonItem.StartDate, LastDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                        diffDayCount = diffDayCount - holidayTotal;
                        commonItem.Duration = diffDayCount;
                        dbStartDate = commonItem.StartDate;
                        dbEndDate = LastDate;
                    } else {
                        dbStartDate = commonItem.StartDate;
                        dbEndDate = commonItem.PreEndDate;
                        commonItem.Duration = oldDuration;
                    }
                } else {

                    // let LogDays = parentItem.arrDependency && parentItem.arrDependency.filter(d => d.DependencyID === 4);
                    if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                        //LogDays Add
                        dbEndDate = addDays(dbEndDate, parseInt(log.LogDays));
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "add");
                    } else {
                        dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                    dbStartDate = subDays(dbEndDate, Duration);
                    //Total Saturday,Sunday and Holiday Count
                    total = totalHolidayAndWE(dbStartDate, dbEndDate, parentItem.LeaveDays, titleEmployee1, i, p, c);
                    for (let l = 0; l < total; l++) {
                        dbStartDate = subDays(dbStartDate, 1);
                        dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, parentItem.LeaveDays, titleEmployee1, "sub");
                    }
                }

                dependencyMult.push({ Date: dbStartDate, depItem: depItem });
                dependencyMult.push({ Date: dbEndDate, depItem: depItem });
            }
        }

        catch (e) {
            console.log(e);
        }

    }

    function diffDays(date, otherDate) {
        return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1;
    }
    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth();
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function subDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function totalHolidayAndWE(startDate, endDate, leaveDays, taskHolderName, i, p, c, isParent) {

        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        let format = "MM/DD/YYYY";
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = getCompanyHolidayList.filter(d => d.taskHolderName === taskHolderName);
                let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        // let Leavetext = '';
        // if (total !== 0 && isParent === false) {
        //     if (leaveDays === 2) {
        //         Leavetext = "Saturday(" + totalSaturday + ") Sunday(" + totalSunday + ") Holiday(" + totalHoliday + ")";;
        //     } else if (leaveDays === 3) {
        //         Leavetext = "Saturday(" + totalSaturday + ") Sunday(" + totalSunday + ")";
        //     } else if (leaveDays === 4) {
        //         Leavetext = "Sunday(" + totalSunday + ")";
        //     } else if (leaveDays === 5) {
        //         Leavetext = "Holiday(" + totalHoliday + ")";
        //     } else if (leaveDays === 6) {
        //         Leavetext = "Sunday(" + totalSunday + ") Holiday(" + totalHoliday + ")";
        //     } else if (leaveDays === 7) {
        //         Leavetext = "Saturday(" + totalSaturday + ")";
        //     } else if (leaveDays === 8) {
        //         Leavetext = "Saturday(" + totalSaturday + ") Holiday(" + totalHoliday + ")";
        //     }
        //     values[i].SubTaskList[p].ChildTaskList[c].LeaveDaysTxt = Leavetext;
        // }
        // setInputFields(values);
        return total;
    }

    function leavedayscountcalc(startDate, endDate, leaveDays, taskHolderName, i, p, c, isParent) {

        let format = "MM/DD/YYYY";
        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = getCompanyHolidayList.filter(d => d.taskHolderName === taskHolderName);
                let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        let Leavetext = '';
        if (total !== 0 && isParent === false) {
            if (leaveDays === 2) {
                Leavetext = "Sat(" + totalSaturday + ") Sun(" + totalSunday + ") Hol(" + totalHoliday + ")";;
            } else if (leaveDays === 3) {
                Leavetext = "Sat(" + totalSaturday + ") Sun(" + totalSunday + ")";
            } else if (leaveDays === 4) {
                Leavetext = "Sun(" + totalSunday + ")";
            } else if (leaveDays === 5) {
                Leavetext = "Hol(" + totalHoliday + ")";
            } else if (leaveDays === 6) {
                Leavetext = "Sun(" + totalSunday + ") Hol(" + totalHoliday + ")";
            } else if (leaveDays === 7) {
                Leavetext = "Sat(" + totalSaturday + ")";
            } else if (leaveDays === 8) {
                Leavetext = "Sat(" + totalSaturday + ") Hol(" + totalHoliday + ")";
            }
            values[i].SubTaskList[p].ChildTaskList[c].LeaveDaysTxt = Leavetext;
        }

        setInputFields(values);
    }

    function checkDateBetweenInHolidayAndWE(flyDate, leaveDays, taskHolderName, status) {
        let format = "MM/DD/YYYY";
        let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
        let holidayList = getCompanyHolidayList.filter(d => d.taskHolderName === taskHolderName);
        let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
        //ReCheck Date in Saturday,Sunday and Holiday Count
        if (flyDate.getDay() === 0 || flyDate.getDay() === 6 || HolidayCount > 0) {
            let i = 0;
            while (i <= 3) {
                //Weekend Date Check
                if (flyDate.getDay() === 0 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 4 || leaveDays === 6)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }
                if (flyDate.getDay() === 6 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 7 || leaveDays === 8)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }

                if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                    let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                    let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
                    if (HolidayCount > 0) {
                        status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                    } else {
                        i++;
                    }
                } else {
                    i++;
                }

                i === 3 ? i = 4 : i = 0;
            }
        }
        return flyDate;
    }

    // #endregion   ------------- **** Calculation End ***** --------------------------


    const handletrimmPopupClose = () => {
        setTrimmodalpopup(false);
        seteditTrimSubmission(false);
        setdeleteTrimSubmission(false);
        setTrimswatchDownload(false);
        setTrimswatchUpload(false);
    }
    const handleTrimappPopupClose = () => {
        setTrimApprovalpopup(false);
        seteditTrimApproval(false);
        setdeleteTrimApproval(false);
        setTrimswatchDownload(false);
        setTrimswatchUpload(false);
    }
    const handleLabDip = () => {
        setLapmodal(false);
        seteditLabdipSubmission(false);
        setdeleteLabdipSubmission(false);
        setLabdipswatchDownload(false);
        setLabdipswatchUpload(false);
    };
    const handleLabDipApp = () => {

        setOpenLabdipApppopup(false);
        seteditLabdipApproval(false);
        setdeleteLabdipApproval(false);
        setLabdipswatchDownload(false);
        setLabdipswatchUpload(false);

    }
    const handleStrikeoffSub = () => {
        setStrikeOffmodalpopup(false)
        seteditStrikeOffSubmission(false);
        setdeleteStrikeOffSubmission(false);
        setStrikeoffswatchDownload(false);
        setStrikeoffswatchUpload(false);
    }
    const handleStrikeOffApp = () => {
        setStrikeOffApppopup(false);
        seteditStrikeOffApproval(false);
        setdeleteStrikeOffApproval(false);
        setStrikeoffswatchDownload(false);
        setStrikeoffswatchUpload(false);
    }
    const handleprotoSubApp = () => {
        setSampleSubModalpopup(false);
        seteditProtoFitSmsSubmission(false);
        seteditProtoFitSmsSubmission(false)
    }
    const handleprotofitApp = () => {
        setSampleAppModalpopup(false);
        seteditProtoFitSmsApproval(false);
        setdeleteProtoFitSmsApproval(false)
    }
    const HandleAfterWashList = () => {
        setApperancemodalpopup(false);
        seteditAppearanceAfterWash(false);
        seteditAppearanceAfterWash(false);
    }


    const [TNAId, setTNAId] = useState();
    const [StyleId, setStyleId] = useState();
    const [LabdibFabricInfoId, setLabdibFabricInfoId] = useState();
    const [TNAStrikeOffRefInfoId, setTNAStrikeOffRefInfoId] = useState();
    const [TNATrimRefInfoId, setTNATrimRefInfoId] = useState();
    const [SubmittedOptionListId, setSubmittedOptionListId] = useState();
    const [ColorAppCommentsId, setColorAppCommentsId] = useState();
    const [TNAProtoFitId, setTNAProtoFitId] = useState();
    const [TNAStrTaskName, setTNATaskName] = useState('');
    const [AnyColorSku, setAnyColorSku] = useState();

    const [AllChildvalues, setAllChildvalues] = useState([]);

    const [AppareanceTestId, setAppareanceTestId] = useState();
    const [Indexes, setIndexes] = useState({ Index: 0, SubIndex: 0, ChildIndex: 0 });
    const [commonSubmitApprovalParams, setCommonSubmitApprovalParams] = useState({ SelectedDependency: '', TaskID: 0, IsSingleTask: true, hasAllDependencyStart: 0 });
    //check depandant tasks close
    function ChkAllDepandenctTasksClose(arrDependency) {

        let IsAllTasksClosed = true;
        let InCompleteTasks = '';
        if (arrDependency && arrDependency.length > 0) {
            let CurrentChildvalues = [];
            values.map((Main, MainIndex) => {
                if (Main.IsSingleTask === true) {
                    CurrentChildvalues.push({ ParentIndex: MainIndex + 1, ChildIndex: MainIndex + 1, StatusId: Main.StatusID, IsParent: 1, ChildId: Main.TNARouteApplyID })
                }
                Main.SubTaskList.map((Child) => {
                    Child.ChildTaskList.map((SubChild) => {
                        CurrentChildvalues.push({ ParentIndex: MainIndex + 1, ChildIndex: SubChild.ChildIndex, StatusId: SubChild.StatusID, IsParent: 0, ChildId: SubChild.ChildId })
                    })
                })
            })
            arrDependency.filter(x => x.DependencyID !== 0).map((depitem, i) => {
                if (CurrentChildvalues.filter(x => x.ChildId === depitem.DependentTaskId).length > 0) {
                    if (CurrentChildvalues.filter(x => x.ChildId === depitem.DependentTaskId)[0].StatusId !== 3) {
                        IsAllTasksClosed = false;
                        let InCompleterow = CurrentChildvalues.filter(x => x.ChildId === depitem.DependentTaskId)[0];
                        if (InCompleterow.IsParent === 0) {
                            if (InCompleteTasks === '') {
                                InCompleteTasks = InCompleterow.ParentIndex + "-" + InCompleterow.ChildIndex;
                            }
                            else {
                                InCompleteTasks = InCompleteTasks + "," + (InCompleterow.ParentIndex + "-" + InCompleterow.ChildIndex)
                            }
                        }
                        else {
                            if (InCompleteTasks === '') {
                                InCompleteTasks = InCompleterow.ParentIndex;
                            }
                            else {
                                InCompleteTasks = InCompleteTasks + "," + (InCompleterow.ParentIndex)
                            }
                        }
                    }
                }
            })
        }

        return InCompleteTasks;
    }

    const OpenLabdipSubmssionpopup = async (TNAId, LabdibFabricInfoId, StyleId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {
        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            let Params = {
                Operation: 48,
                Id: LabdibFabricInfoId,
            };
            await CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    setLabdipdisable(true)
                    // let Title = "Warning!";
                    // let message = "Approval exists against this Submission.";
                    // let type = "warning";
                    // Nodify(Title, type, message);
                } else {
                    setLabdipdisable(false)
                }
            })
            let SelectedDependency = values[index].SelectedDependency;
            let TaskID = values[index].TaskID;
            let IsSingleTask = values[index].IsSingleTask;
            let hasAllDependencyStart = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart;
            // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
            //     let Dependency = false;
            //     if (values[index].SelectedDependency) {
            //         let arrstatus = [];
            //         arrstatus = values[index].SelectedDependency.split(',');
            //         if (arrstatus.length > 0) {
            //             arrstatus.forEach(element => {
            //                 let val = [];
            //                 val = element.split('-');
            //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
            //                 if (valIndex !== 3) {
            //                     Dependency = true;
            //                 }
            //             });
            //         }
            //         if (Dependency && StatusId !== 3) {
            //             Nodify('Warning!', 'warning', "Please close dependency task");
            //         }
            //         else {
            //             // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
            //             setTNAId(TNAId);
            //             setLabdibFabricInfoId(LabdibFabricInfoId);
            //             setStyleId(StyleId)
            //             setLapmodal(true);
            //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //             setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //             setTaskStatus(StatusId);
            //             setSwatchStatus(SwatchStatus)
            //         }
            //     } else {
            //         //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
            //         setTNAId(TNAId);
            //         setLabdibFabricInfoId(LabdibFabricInfoId);
            //         setStyleId(StyleId)
            //         setLapmodal(true);
            //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //         setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //         setTaskStatus(StatusId);
            //         setSwatchStatus(SwatchStatus)
            //     }
            // }
            // else {
            let CurrentChildvalues = [];
            values.map((Main) => {
                Main.SubTaskList.map((Child) => {
                    Child.ChildTaskList.map((SubChild) => {
                        CurrentChildvalues.push({
                            StatusID: SubChild.StatusID, ChildId: SubChild.ChildId,
                            IsDepandency: SubChild.IsDepandency, Depandency: SubChild.SelectedDependency
                        })
                    })
                })
            })

            setAllChildvalues(CurrentChildvalues);
            setTNAId(TNAId);
            setLabdibFabricInfoId(LabdibFabricInfoId);
            setStyleId(StyleId)
            setLapmodal(true);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            setTaskStatus(StatusId);
            setSwatchStatus(SwatchStatus)
            //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;

            // }

        }
    }

    const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {
        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
        }
        else {
            TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, IsProduction).then((response) => {
                if (response.data.length !== 0) {
                    // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
                    //     let Dependency = false;
                    //     if (values[index].SelectedDependency) {
                    //         let arrstatus = [];
                    //         arrstatus = values[index].SelectedDependency.split(',');
                    //         if (arrstatus.length > 0) {
                    //             arrstatus.forEach(element => {
                    //                 let val = [];
                    //                 val = element.split('-');
                    //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                    //                 if (valIndex !== 3) {
                    //                     Dependency = true;
                    //                 }
                    //             });
                    //         }
                    //         if (Dependency && StatusId !== 3) {
                    //             Nodify('Warning!', 'warning', "Please close dependency task");
                    //         }
                    //         else {
                    //             // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                    //             setLabdibFabricInfoId(LabdibFabricInfoId);
                    //             setSubmittedOptionListId(LabdibFabricInfoId);
                    //             setStyleId(StyleId)
                    //             // setOpenLabdipApppopup(true);
                    //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //             setTaskStatus(StatusId);
                    //             setSwatchStatus(SwatchStatus)
                    //             TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, IsProduction).then((response) => {
                    //                 if (response.data) {
                    //                     setLabdipApprovalList(response.data);
                    //                     setOpenLabdipApppopup(true);
                    //                 }
                    //             }).catch(() => { })
                    //         }
                    //     } else {
                    //         //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                    //         setLabdibFabricInfoId(LabdibFabricInfoId);
                    //         setSubmittedOptionListId(LabdibFabricInfoId);
                    //         setStyleId(StyleId)
                    //         // setOpenLabdipApppopup(true);
                    //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //         setTaskStatus(StatusId);
                    //         setSwatchStatus(SwatchStatus)
                    //         TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, IsProduction).then((response) => {
                    //             if (response.data) {
                    //                 setLabdipApprovalList(response.data);
                    //                 setOpenLabdipApppopup(true);
                    //             }
                    //         }).catch(() => { })
                    //     }
                    // }
                    // else {
                    setLabdibFabricInfoId(LabdibFabricInfoId);
                    setSubmittedOptionListId(LabdibFabricInfoId);
                    setStyleId(StyleId)
                    //  setOpenLabdipApppopup(true);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    setTaskStatus(StatusId);
                    setSwatchStatus(SwatchStatus)
                    TnaService.LapdipSubmissionList(2, LabdibFabricInfoId, IsProduction).then((response) => {
                        if (response.data) {
                            setLabdipApprovalList(response.data);
                            setOpenLabdipApppopup(true);
                        }
                    }).catch(() => { })


                    // }
                }
                else {
                    Nodify('Warning!', 'warning', "Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });

        }
    }


    // const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
    //     setLabdibFabricInfoId(LabdibFabricInfoId);
    //     setSubmittedOptionListId(LabdibFabricInfoId);
    //     setStyleId(StyleId)
    //     setOpenLabdipApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenStrikeoffSubmssionpopup = async (TNAId, TNAStrikeOffRefInfoId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {
        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            let Params = {
                Operation: 49,
                Id: TNAStrikeOffRefInfoId,
            };
            await CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    setStrikeoffdisable(true)
                } else {
                    setStrikeoffdisable(false)
                }
            })
            let SelectedDependency = values[index].SelectedDependency;
            let TaskID = values[index].TaskID;
            let IsSingleTask = values[index].IsSingleTask;
            let hasAllDependencyStart = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart;
            // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
            //     let Dependency = false;
            //     if (values[index].SelectedDependency) {
            //         let arrstatus = [];
            //         arrstatus = values[index].SelectedDependency.split(',');
            //         if (arrstatus.length > 0) {
            //             arrstatus.forEach(element => {
            //                 let val = [];
            //                 val = element.split('-');
            //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
            //                 if (valIndex !== 3) {
            //                     Dependency = true;
            //                 }
            //             });
            //         }
            //         if (Dependency && StatusId !== 3) {
            //             Nodify('Warning!', 'warning', "Please close dependency task");
            //         }
            //         else {
            //             setTNAId(TNAId);
            //             setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
            //             setStrikeOffmodalpopup(true);
            //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //             setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //             setTaskStatus(StatusId);
            //             setSwatchStatus(SwatchStatus)
            //         }
            //     } else {
            //         setTNAId(TNAId);
            //         setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
            //         setStrikeOffmodalpopup(true);
            //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //         setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //         setTaskStatus(StatusId);
            //         setSwatchStatus(SwatchStatus)
            //     }
            // }
            // else {
            let CurrentChildvalues = [];
            values.map((Main) => {
                Main.SubTaskList.map((Child) => {
                    Child.ChildTaskList.map((SubChild) => {
                        CurrentChildvalues.push({
                            StatusID: SubChild.StatusID, ChildId: SubChild.ChildId,
                            IsDepandency: SubChild.IsDepandency, Depandency: SubChild.SelectedDependency
                        })
                    })
                })
            })

            setAllChildvalues(CurrentChildvalues);
            setTNAId(TNAId);
            setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);

            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            setTaskStatus(StatusId);
            setSwatchStatus(SwatchStatus)
            setStrikeOffmodalpopup(true);

            // }
        }

    }

    // const OpenStrikeoffSubmssionpopup = (TNAId, TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setStrikeOffmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {
        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            TnaService.StrikeoffSubmissionList(3, TNAStrikeOffRefInfoId, IsProduction).then((response) => {
                if (response.data.length !== 0) {
                    // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
                    //     let Dependency = false;
                    //     if (values[index].SelectedDependency) {
                    //         let arrstatus = [];
                    //         arrstatus = values[index].SelectedDependency.split(',');
                    //         if (arrstatus.length > 0) {
                    //             arrstatus.forEach(element => {
                    //                 let val = [];
                    //                 val = element.split('-');
                    //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                    //                 if (valIndex !== 3) {
                    //                     Dependency = true;
                    //                 }
                    //             });
                    //         }
                    //         if (Dependency && StatusId !== 3) {
                    //             Nodify('Warning!', 'warning', "Please close dependency task");
                    //         }
                    //         else {
                    //             setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                    //             setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                    //             setColorAppCommentsId(TNAStrikeOffRefInfoId);
                    //             setStrikeOffApppopup(true);
                    //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //             setTaskStatus(StatusId);
                    //             setSwatchStatus(SwatchStatus)
                    //         }
                    //     } else {
                    //         setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                    //         setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                    //         setColorAppCommentsId(TNAStrikeOffRefInfoId);
                    //         setStrikeOffApppopup(true);
                    //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //         setTaskStatus(StatusId);
                    //         setSwatchStatus(SwatchStatus)
                    //     }
                    // }
                    // else {
                    setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
                    setSubmittedOptionListId(TNAStrikeOffRefInfoId);
                    setColorAppCommentsId(TNAStrikeOffRefInfoId);

                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    setTaskStatus(StatusId);
                    setSwatchStatus(SwatchStatus)
                    setStrikeOffApppopup(true);
                    // }
                }
                else {
                    Nodify('Warning!', 'warning', "Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });

        }
    }

    // const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setSubmittedOptionListId(TNAStrikeOffRefInfoId);
    //     setColorAppCommentsId(TNAStrikeOffRefInfoId);
    //     setStrikeOffApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenTrimSubmssionpopup = async (TNAId, TNATrimRefInfoId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {
        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            let Params = {
                Operation: 50,
                Id: TNATrimRefInfoId,
            };
            await CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    setTrimdisable(true)
                } else {
                    setTrimdisable(false)
                }
            })
            let SelectedDependency = values[index].SelectedDependency;
            let TaskID = values[index].TaskID;
            let IsSingleTask = values[index].IsSingleTask;
            let hasAllDependencyStart = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart;
            // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
            //     let Dependency = false;
            //     if (values[index].SelectedDependency) {
            //         let arrstatus = [];
            //         arrstatus = values[index].SelectedDependency.split(',');
            //         if (arrstatus.length > 0) {
            //             arrstatus.forEach(element => {
            //                 let val = [];
            //                 val = element.split('-');
            //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
            //                 if (valIndex !== 3) {
            //                     Dependency = true;
            //                 }
            //             });
            //         }
            //         if (Dependency && StatusId !== 3) {
            //             Nodify('Warning!', 'warning', "Please close dependency task");
            //         }
            //         else {
            //             setTNAId(TNAId);
            //             setTNATrimRefInfoId(TNATrimRefInfoId);
            //             setTrimmodalpopup(true);
            //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //             setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //             setTaskStatus(StatusId);
            //             setSwatchStatus(SwatchStatus)
            //         }
            //     } else {
            //         setTNAId(TNAId);
            //         setTNATrimRefInfoId(TNATrimRefInfoId);
            //         setTrimmodalpopup(true);
            //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //         setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            //         setTaskStatus(StatusId);
            //         setSwatchStatus(SwatchStatus)
            //     }
            // }
            // else {
            let CurrentChildvalues = [];
            values.map((Main) => {
                Main.SubTaskList.map((Child) => {
                    Child.ChildTaskList.map((SubChild) => {
                        CurrentChildvalues.push({
                            StatusID: SubChild.StatusID, ChildId: SubChild.ChildId,
                            IsDepandency: SubChild.IsDepandency, Depandency: SubChild.SelectedDependency
                        })
                    })
                })
            })
            setAllChildvalues(CurrentChildvalues);

            setTNAId(TNAId);
            setTNATrimRefInfoId(TNATrimRefInfoId);
            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            setCommonSubmitApprovalParams({ SelectedDependency: SelectedDependency, TaskID: TaskID, IsSingleTask: IsSingleTask, hasAllDependencyStart: hasAllDependencyStart });
            setTaskStatus(StatusId);
            setSwatchStatus(SwatchStatus)
            setTrimmodalpopup(true);

            // }
        }


    }

    // const OpenTrimSubmssionpopup = (TNAId, TNATrimRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNATrimRefInfoId(TNATrimRefInfoId);
    //     setTrimmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenTrimApprovalpopup = (TNATrimRefInfoId, index, subIndex, childIndex, StatusId, SwatchStatus, arrDependency, intDependency) => {
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            TnaService.TrimSubmissionList(3, TNATrimRefInfoId, IsProduction).then((response) => {
                if (response.data.length !== 0) {
                    // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
                    //     let Dependency = false;
                    //     if (values[index].SelectedDependency.length > 0) {
                    //         let arrstatus = [];
                    //         arrstatus = values[index].SelectedDependency.split(',');
                    //         if (arrstatus.length > 0) {
                    //             arrstatus.forEach(element => {
                    //                 let val = [];
                    //                 val = element.split('-');
                    //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                    //                 if (valIndex !== 3) {
                    //                     Dependency = true;
                    //                 }
                    //             });
                    //         }
                    //         if (Dependency && StatusId !== 3) {
                    //             Nodify('Warning!', 'warning', "Please close dependency task");
                    //         }
                    //         else {
                    //             setTNATrimRefInfoId(TNATrimRefInfoId);
                    //             setSubmittedOptionListId(TNATrimRefInfoId);
                    //             setColorAppCommentsId(TNATrimRefInfoId);
                    //             setTrimApprovalpopup(true);
                    //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //             setTaskStatus(StatusId);
                    //             setSwatchStatus(SwatchStatus)
                    //         }
                    //     } else {
                    //         setTNATrimRefInfoId(TNATrimRefInfoId);
                    //         setSubmittedOptionListId(TNATrimRefInfoId);
                    //         setColorAppCommentsId(TNATrimRefInfoId);
                    //         setTrimApprovalpopup(true);
                    //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //         setTaskStatus(StatusId);
                    //         setSwatchStatus(SwatchStatus)
                    //     }
                    // }
                    // else {
                    setTNATrimRefInfoId(TNATrimRefInfoId);
                    setSubmittedOptionListId(TNATrimRefInfoId);
                    setColorAppCommentsId(TNATrimRefInfoId);

                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    setTaskStatus(StatusId);
                    setSwatchStatus(SwatchStatus)
                    setTrimApprovalpopup(true);

                    // }
                }
                else {
                    Nodify('Warning!', 'warning', "Submission not yet added for this task");
                    return false;
                }

            }).catch(() => { });

        }
    }



    // const OpenTrimApprovalpopup = (TNATrimRefInfoId, index, subIndex, childIndex) => {
    //     setTNATrimRefInfoId(TNATrimRefInfoId);
    //     setSubmittedOptionListId(TNATrimRefInfoId);
    //     setColorAppCommentsId(TNATrimRefInfoId);
    //     setTrimApprovalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenProtoFitSamplepopup = async (TNAId, TNAProtoFitId, index, subIndex, childIndex, ChildTaskName, ParentTask, StatusId, Styleid, arrDependency, intDependency) => {
        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            let Params = {
                Operation: 51,
                Id: TNAProtoFitId,
            };
            await CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    setprotofitsmsDisable(true)
                } else {
                    setprotofitsmsDisable(false)
                }
            })
            // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
            //     let Dependency = false;
            //     if (values[index].SelectedDependency) {
            //         let arrstatus = [];
            //         arrstatus = values[index].SelectedDependency.split(',');
            //         if (arrstatus.length > 0) {
            //             arrstatus.forEach(element => {
            //                 let val = [];
            //                 val = element.split('-');
            //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
            //                 if (valIndex !== 3) {
            //                     Dependency = true;
            //                 }
            //             });
            //         }
            //         if (Dependency && StatusId !== 3) {
            //             Nodify('Warning!', 'warning', "Please close dependency task");
            //         }
            //         else {
            //             setTNAId(TNAId);
            //             setTNAProtoFitId(TNAProtoFitId);
            //             setChildTask(ChildTaskName);
            //             setParentTask(ParentTask);

            //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //             setTaskStatus(StatusId);
            //             setStyleId(Styleid);
            //             setSampleSubModalpopup(true);
            //         }
            //     } else {
            //         setTNAId(TNAId);
            //         setTNAProtoFitId(TNAProtoFitId);
            //         setChildTask(ChildTaskName);
            //         setParentTask(ParentTask);

            //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            //         setTaskStatus(StatusId);
            //         setStyleId(Styleid);
            //         setSampleSubModalpopup(true);
            //     }
            // }
            // else {
            setTNAId(TNAId);
            setTNAProtoFitId(TNAProtoFitId);
            setChildTask(ChildTaskName);
            setParentTask(ParentTask);

            setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
            setTaskStatus(StatusId);
            setStyleId(Styleid);
            setSampleSubModalpopup(true);

            // }
        }

    }

    // const OpenProtoFitSamplepopup = (TNAId, TNAProtoFitId, index, subIndex, childIndex, TaskName) => {
    //     setTNAId(TNAId);
    //     setTNAProtoFitId(TNAProtoFitId);
    //     setTNATaskName(TaskName);
    //     setSampleSubModalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }


    const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex, ParentTask, StatusId, Styleid, Operation, arrDependency, intDependency) => {
        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            TnaService.GetProtoFitSMSSubmission(Operation, TNAProtoFitId).then((response) => {
                if (response.data.length !== 0) {
                    //     if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
                    //         let Dependency = false;
                    //         if (values[index].SelectedDependency.length > 0) {
                    //             let arrstatus = [];
                    //             arrstatus = values[index].SelectedDependency.split(',');
                    //             if (arrstatus.length > 0) {
                    //                 arrstatus.forEach(element => {
                    //                     let val = [];
                    //                     val = element.split('-');
                    //                     let valIndex = values[parseInt(val[0]) - 1].StatusID
                    //                     if (valIndex !== 3) {
                    //                         Dependency = true;
                    //                     }
                    //                 });
                    //             }
                    //             if (Dependency && StatusId !== 3) {
                    //                 Nodify('Warning!', 'warning', "Please close dependency task");
                    //             }
                    //             else {
                    //                 setTNAProtoFitId(TNAProtoFitId);

                    //                 setParentTask(ParentTask);
                    //                 setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //                 setTaskStatus(StatusId);
                    //                 setStyleId(Styleid);
                    //                 setSampleAppModalpopup(true);
                    //             }
                    //         } else {
                    //             setTNAProtoFitId(TNAProtoFitId);

                    //             setParentTask(ParentTask);
                    //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    //             setTaskStatus(StatusId);
                    //             setStyleId(Styleid);
                    //             setSampleAppModalpopup(true);
                    //         }
                    //     }
                    //     else {
                    setTNAProtoFitId(TNAProtoFitId);

                    setParentTask(ParentTask);
                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                    setTaskStatus(StatusId);
                    setStyleId(Styleid);
                    setSampleAppModalpopup(true);
                    // }

                }
                else {
                    Nodify('Warning!', 'warning', "Submission not yet added for this task");
                    return false;
                }


            }).catch(() => { });

        }
    }

    // const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex) => {
    //     setTNAProtoFitId(TNAProtoFitId);
    //     setSampleAppModalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    const OpenAppearenceAfterWashpopup = (TNAId, AppareanceTestId, StyleId, index, subIndex, childIndex, StatusId, Styleid, arrDependency, intDependency) => {
        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {
                let Dependency = false;
                //     if (values[index].SelectedDependency) {
                //         let arrstatus = [];
                //         arrstatus = values[index].SelectedDependency.split(',');
                //         if (arrstatus.length > 0) {
                //             arrstatus.forEach(element => {
                //                 let val = [];
                //                 val = element.split('-');
                //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                //                 if (valIndex !== 3) {
                //                     Dependency = true;
                //                 }
                //             });
                //         }
                //         if (Dependency) {
                //             Nodify('Warning!', 'warning', "Please close dependency task");
                //         }
                //         else {
                //             setTNAId(TNAId);
                //             setAppareanceTestId(AppareanceTestId);
                //             setStyleId(StyleId)
                //             setApperancemodalpopup(true);
                //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                //             setTaskStatus(StatusId);
                //             setStyleId(Styleid);
                //         }
                //     } else {
                //         setTNAId(TNAId);
                //         setAppareanceTestId(AppareanceTestId);
                //         setStyleId(StyleId)
                //         setApperancemodalpopup(true);
                //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                //         setTaskStatus(StatusId);
                //         setStyleId(Styleid);
                //     }
                // }
                // else {
                setTNAId(TNAId);
                setAppareanceTestId(AppareanceTestId);
                setStyleId(StyleId)
                setApperancemodalpopup(true);
                setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
                setTaskStatus(StatusId);
                setStyleId(Styleid);
                //  }
            }
        }
    }

    // const OpenAppearenceAfterWashpopup = (TNAId, AppareanceTestId, StyleId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setAppareanceTestId(AppareanceTestId);
    //     setStyleId(StyleId)
    //     setApperancemodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }


    // const OpenLabdipSubmssionpopup = (TNAId, LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setLabdibFabricInfoId(LabdibFabricInfoId);
    //     setStyleId(StyleId)
    //     setLapmodal(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenLabdipSubmssionpopup = (TNAId, LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
    //     if (values[index].intDependency === 0) {
    //         let Dependency = false;
    //         if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.length > 0) {
    //             let arrstatus = [];
    //             arrstatus = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SelectedDependency.split(',');
    //             if (arrstatus.length > 0) {
    //                 arrstatus.forEach(element => {
    //                     let val = [];
    //                     val = element.split('-');
    //                     let valIndex = values[parseInt(val[0]) - 1].StatusID
    //                     if (valIndex !== 3) {
    //                         Dependency = true;
    //                     }
    //                 });
    //             }
    //             if (Dependency) {
    //                 Nodify('Warning!', 'warning', "Please close dependency task");
    //             }
    //             else {
    //                 // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
    //                 setTNAId(TNAId);
    //                 setLabdibFabricInfoId(LabdibFabricInfoId);
    //                 setStyleId(StyleId)
    //                 setLapmodal(true);
    //                 setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    //             }
    //         } else {
    //             //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
    //             setTNAId(TNAId);
    //             setLabdibFabricInfoId(LabdibFabricInfoId);
    //             setStyleId(StyleId)
    //             setLapmodal(true);
    //             setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    //         }
    //     }
    //     else {
    //         setTNAId(TNAId);
    //         setLabdibFabricInfoId(LabdibFabricInfoId);
    //         setStyleId(StyleId)
    //         setLapmodal(true);
    //         setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    //         //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;

    //     }

    // }

    // const OpenLabdipApprovalpopup = (LabdibFabricInfoId, StyleId, index, subIndex, childIndex) => {
    //     setLabdibFabricInfoId(LabdibFabricInfoId);
    //     setSubmittedOptionListId(LabdibFabricInfoId);
    //     setStyleId(StyleId)
    //     setOpenLabdipApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenStrikeoffSubmssionpopup = (TNAId, TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setStrikeOffmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenStrikeoffApprovalpopup = (TNAStrikeOffRefInfoId, index, subIndex, childIndex) => {
    //     setTNAStrikeOffRefInfoId(TNAStrikeOffRefInfoId);
    //     setSubmittedOptionListId(TNAStrikeOffRefInfoId);
    //     setColorAppCommentsId(TNAStrikeOffRefInfoId);
    //     setStrikeOffApppopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenTrimSubmssionpopup = (TNAId, TNATrimRefInfoId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setTNATrimRefInfoId(TNATrimRefInfoId);
    //     setTrimmodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenTrimApprovalpopup = (TNATrimRefInfoId, index, subIndex, childIndex) => {
    //     setTNATrimRefInfoId(TNATrimRefInfoId);
    //     setSubmittedOptionListId(TNATrimRefInfoId);
    //     setColorAppCommentsId(TNATrimRefInfoId);
    //     setTrimApprovalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenProtoFitSamplepopup = (TNAId, TNAProtoFitId, index, subIndex, childIndex, TaskName) => {
    //     setTNAId(TNAId);
    //     setTNAProtoFitId(TNAProtoFitId);
    //     setTNATaskName(TaskName);
    //     setSampleSubModalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }
    // const OpenProtoFitApprovalpopup = (TNAProtoFitId, index, subIndex, childIndex) => {
    //     setTNAProtoFitId(TNAProtoFitId);
    //     setSampleAppModalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }

    // const OpenAppearenceAfterWashpopup = (TNAId, AppareanceTestId, StyleId, index, subIndex, childIndex) => {
    //     setTNAId(TNAId);
    //     setAppareanceTestId(AppareanceTestId);
    //     setStyleId(StyleId)
    //     setApperancemodalpopup(true);
    //     setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex })
    // }


    const LapdipSubmissionCallback = (value, getOrderinfo, Operation, SwatchStatus) => {

        if (value === true) {
            setLapmodal(false);
            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.labdibFabricInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = Operation === 'Delete' ? '' : moment(new Date()).format("MM/DD/YYYY");
                        //IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, convertUTCDateToLocalDate(new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate)));
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate).toLocaleString();
                    }
                })

                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })
            if (SwatchStatus !== 0) {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = parseInt(SwatchStatus);
            }
            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 0;

            }
            //  loadCommonDependencyButtonStartstop();
            seteditLabdipSubmission(false);
            setdeleteLabdipSubmission(false);
            setLabdipswatchDownload(false);
            setLabdipswatchUpload(false);
            AutoDepandencyCloseonParentTaskClose();
            setInputFields(values);
        }
        else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = new Date();
            // })
            // loadCommonDependencyButtonStartstop();
            seteditLabdipSubmission(false);
            setdeleteLabdipSubmission(false);
            setLabdipswatchDownload(false);
            setLabdipswatchUpload(false);
            // setInputFields(values);
        }
    }

    const LapdipApprovalCallback = (value, getOrderinfo, Operation, SwatchStatus) => {
        if (value === true) {
            let childDate;
            setOpenLabdipApppopup(false);
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.labdibFabricInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = Operation === 'Delete' ? '' : moment(new Date()).format("MM/DD/YYYY");
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
                    }
                })
                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })
            if (Operation === 'upload') {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 3;
            }
            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = SwatchStatus;
            }
            seteditLabdipApproval(false);
            setdeleteLabdipApproval(false);
            setLabdipswatchDownload(false);
            setLabdipswatchUpload(false);
            AutoDepandencyCloseonParentTaskClose();
            setInputFields(values);
        }
        else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // })
            seteditLabdipApproval(false);
            setdeleteLabdipApproval(false);
            setLabdipswatchDownload(false);
            setLabdipswatchUpload(false);
            // setInputFields(values);
        }
    }
    const handleCloseTestingExFactoryFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenTestingConfirmationType = 0;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsExFactoryForm = 0;
        setInputFields(values);
    };
    const StrikeoffSubmissionCallback = (value, getOrderinfo, Operation, SwatchStatus) => {
        if (value === true) {
            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.tnaStrikeOffRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = Operation === 'Delete' ? '' : moment(new Date()).format("MM/DD/YYYY");
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
                    }
                })
            })
            IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);

            if (SwatchStatus !== 0) {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = parseInt(SwatchStatus);
            }
            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 0;

            }
            // loadCommonDependencyButtonStartstop();
            setInputFields(values);
            setdeleteStrikeOffSubmission(false);
            seteditStrikeOffSubmission(false);
            setStrikeOffmodalpopup(false);
            setStrikeoffswatchDownload(false);
            AutoDepandencyCloseonParentTaskClose();
            setStrikeoffswatchUpload(false);
        }
        else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = Operation === 'Delete' ? 2 : 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // })
            // loadCommonDependencyButtonStartstop();
            setdeleteStrikeOffSubmission(false);
            seteditStrikeOffSubmission(false);
            //setStrikeOffmodalpopup(false);
            setStrikeoffswatchDownload(false);
            setStrikeoffswatchUpload(false);
            // setInputFields(values);
        }
    }

    const StrikeoffApprovalCallback = (value, getOrderinfo, Operation, SwatchStatus) => {
        if (value === true) {
            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.tnaStrikeOffRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = Operation === 'Delete' ? '' : moment(new Date()).format("MM/DD/YYYY");
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
                    }
                })
                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })
            if (Operation === 'upload') {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 3;
            }

            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = SwatchStatus;
            }
            setInputFields(values);
            setdeleteStrikeOffApproval(false);
            seteditStrikeOffApproval(false);
            setStrikeOffApppopup(false);
            setStrikeoffswatchDownload(false);
            setStrikeoffswatchUpload(false);
            AutoDepandencyCloseonParentTaskClose();
        }
        else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // })
            // setInputFields(values);
            setdeleteStrikeOffApproval(false);
            seteditStrikeOffApproval(false);
            //setStrikeOffApppopup(false);
            setStrikeoffswatchDownload(false);
            setStrikeoffswatchUpload(false);
        }
    }

    const TrimSubmissionCallback = (value, getOrderinfo, Operation, SwatchStatus) => {
        if (value === true) {
            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.tnaTrimRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = Operation === 'Delete' ? '' : moment(new Date()).format("MM/DD/YYYY");
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
                    }
                })
                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })
            if (SwatchStatus !== 0) {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = parseInt(SwatchStatus);
            }
            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 0;

            }

            //  loadCommonDependencyButtonStartstop();
            setInputFields(values);
            setdeleteTrimSubmission(false);
            seteditTrimSubmission(false);
            setTrimmodalpopup(false);
            setTrimswatchDownload(false);
            setTrimswatchUpload(false);
            AutoDepandencyCloseonParentTaskClose();
        }
        else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowSubmitButton = Operation === 'Delete' ? 2 : 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // })
            // loadCommonDependencyButtonStartstop();
            setdeleteTrimSubmission(false);
            seteditTrimSubmission(false);
            //setTrimmodalpopup(false);
            setTrimswatchDownload(false);
            setTrimswatchUpload(false);
            //  setInputFields(values);
        }
    }

    const TrimApprovalCallback = (value, getOrderinfo, Operation, SwatchStatus) => {
        if (value === true) {
            let childDate;
            getOrderinfo.forEach((element, index) => {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
                    if (element.tnaTrimRefInfoId === element1.ChildId) {
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
                        values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = Operation === 'Delete' ? '' : moment(new Date()).format("MM/DD/YYYY");
                        childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
                    }
                })

                IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            })

            if (Operation === 'upload') {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = 3;
            }

            else {
                values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].SwatchStatus = SwatchStatus;
            }

            setInputFields(values);
            setTrimApprovalpopup(false);
            setdeleteTrimApproval(false);
            seteditTrimApproval(false);
            setTrimswatchDownload(false);
            setTrimswatchUpload(false);
            AutoDepandencyCloseonParentTaskClose();
        }
        else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList.forEach((element1, index1) => {
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].IsShowApprovalButton = Operation === 'Delete' ? 2 : 0;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].StatusID = Operation === 'Delete' ? 1 : 3;
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            //     values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[index1].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            // })

            // setInputFields(values);
            //setTrimApprovalpopup(false);
            setdeleteTrimApproval(false);
            seteditTrimApproval(false);
            setTrimswatchDownload(false);
            setTrimswatchUpload(false);
        }
    }
    const ProtoFitSMSSubmissionCallback = (value, Operation) => {
        if (value === true) {
            let childDate;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = Operation === 'Delete' ? '' : moment(new Date()).format("MM/DD/YYYY");
            childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
            IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            setInputFields(values);
            setSampleSubModalpopup(false);
            setdeleteProtoFitSmsSubmission(false);
            seteditProtoFitSmsSubmission(false);
            AutoDepandencyCloseonParentTaskClose();
        }
        else {
            setdeleteProtoFitSmsSubmission(false);
            seteditProtoFitSmsSubmission(false);
        }
    }

    const ProtoFitAprovalCallback = (value, Operation) => {
        if (value === true) {
            let childDate;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = Operation === 'Delete' ? '' : moment(new Date()).format("MM/DD/YYYY");
            childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
            IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            setInputFields(values);
            setdeleteProtoFitSmsApproval(false);
            seteditProtoFitSmsApproval(false);
            setSampleAppModalpopup(false);
            AutoDepandencyCloseonParentTaskClose();

        }
        else {
            setdeleteProtoFitSmsSubmission(false);
            seteditProtoFitSmsSubmission(false);
        }
    }
    const AppareanceTestSubmissionCallback = (value, Operation) => {
        if (value === true) {
            let childDate;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StrActualEndDate = Operation === 'Delete' ? '' : moment(new Date()).format("MM/DD/YYYY");
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowSubmitButton = Operation === 'Delete' ? 2 : 3;
            childDate = new Date(values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate).toLocaleString();
            IsParentDateShow = ManualCommentAdd(values, Indexes.Index, IsParentDateShow, childDate);
            setInputFields(values);
            seteditAppearanceAfterWash(false);
            setdeleteAppearanceAfterWash(false);
            setApperancemodalpopup(false);
            AutoDepandencyCloseonParentTaskClose();
        }

        else if (Operation === "Save") {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = new Date();
            values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowSubmitButton = 3;
            seteditAppearanceAfterWash(false);
            setdeleteAppearanceAfterWash(false);
            setApperancemodalpopup(false);
            setInputFields(values);
        }
        else {
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].StatusID = Operation === 'Delete' ? 1 : 3;
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ManualCloseDate = Operation === 'Delete' ? '' : new Date();
            // values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildActualEndDate = Operation === 'Delete' ? '' : new Date();
            //values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].IsShowSubmitButton = Operation === 'Delete' ? 2 : 3;
            //setInputFields(values);
            seteditAppearanceAfterWash(false);
            setdeleteAppearanceAfterWash(false);
            setApperancemodalpopup(false);
        }
    }
    function AutoDepandencyCloseonParentTaskClose() {

        let CurrrIndex = values[Indexes.Index].SubTaskList[Indexes.SubIndex].ChildTaskList[Indexes.ChildIndex].ChildIndex;

        let CurrentChildvalues = [];
        let IsAllTasksClosed = true;
        values.map((Main) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({ StatusID: SubChild.StatusID, ChildIndex: SubChild.ChildIndex })
                })
            })
        })
        values.map((Main) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {

                    if (SubChild.SelectedDependency !== '' && (SubChild.SelectedDependency.includes(CurrrIndex + "-ES")
                        || SubChild.SelectedDependency.includes(CurrrIndex + "-EE")
                        || SubChild.SelectedDependency.includes(CurrrIndex + "-SS")
                        || SubChild.SelectedDependency.includes(CurrrIndex + "-SE"))) {
                        if (SubChild.SelectedDependency.includes(',')) {
                            let splitmultiparentchildindex = SubChild.SelectedDependency.split(',');
                            splitmultiparentchildindex.map(depitem => {
                                let splitparentchildindex = depitem.split('-');
                                if (CurrentChildvalues.filter(x => x.ChildIndex === parseInt(splitparentchildindex[1]))[0].StatusID !== 3) {
                                    IsAllTasksClosed = false;
                                }
                            })
                            if (IsAllTasksClosed === true) {
                                SubChild.intDependency = 0;

                            }
                            else {
                                SubChild.intDependency = 1;
                            }
                        }
                        else {
                            SubChild.intDependency = 0;

                        }
                    }

                })
            })
        })
    }

    function loadCommonDependencyButtonStartstop() {
        values[Indexes.Index].SubTaskList.map((subTask, subIndex) => {
            subTask && subTask.ChildTaskList.map((ChildTaskList, childIndex) => {
                values[Indexes.Index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency = 1;
            });
        });
    }

    const EditComments = (index, Value, Childindex) => {
        values[index].Comments = Value;
        //values.Comments = Value;
        setEditedCommentIndex(Childindex);
        setInputFields(values);
    }

    const RemoveComments = (index, ChildIndex2) => {
        values[index].arrComment.splice(ChildIndex2, 1);
        values[index].Comments = '';
        setEditedCommentIndex('');
        values[index].CommentsCount = values[index].arrComment.length;
        setInputFields(values);


    }

    const handleChildManualclose = async (index, subIndex, childIndex, TaskName, TaskType, TaskID, ChildId, Status, ManualCloseComment, arrDependency,
        intDependency) => {

        let CurrentChildvalues = [];
        values.map((Main) => {
            Main.SubTaskList.map((Child) => {
                Child.ChildTaskList.map((SubChild) => {
                    CurrentChildvalues.push({
                        StatusID: SubChild.StatusID,
                        ChildId: SubChild.ChildId,
                        IsDepandency: SubChild.IsDepandency,
                        Depandency: SubChild.SelectedDependency,
                        intDependency: SubChild.intDependency,

                    })
                })
            })
        })


        //check all depandent tasks closed
        let InCompleteTasks = ChkAllDepandenctTasksClose(arrDependency);


        if (InCompleteTasks !== '' && intDependency === 0) {
            Nodify('Warning!', 'warning', "Depandendant Tasks should be close first(" + InCompleteTasks + ")");
        }
        //if depandent tasks closed then open popup
        else {
            showLoader();
            //check manual submit esist
            setComment(false)
            setdates(false)
            setSubmitRevice(false)
            setSubmitReschedule(false)
            if (Status !== 3) {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = '';
            }
            let Operation;
            if (TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                Operation = 1;
            }
            else if (TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT) {
                Operation = 3;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                Operation = 2;
            }
            else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                Operation = 4;
            }
            else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                Operation = 5;
            }

            else if (TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) {
                Operation = 6;
            }
            else if (TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL) {
                Operation = 8;
            }
            else if (TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL) {
                Operation = 9;
            }
            else if (TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL) {
                Operation = 10;
            }
            else if (TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
                Operation = 11;
            }
            else {
                Operation = 7;

            }


            if (Operation !== 7) { // check submission exists for approvals
                await TnaService.GetTNAManualCloseSubmissionList(ChildId, TaskName).then(async (response) => {
                    if (response.data.length !== 0) {
                        // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0 && (!ManualCloseComment)) {
                        //     let Dependency = false;
                        //     if (values[index].SelectedDependency) {
                        //         let arrstatus = [];
                        //         arrstatus = values[index].SelectedDependency.split(',');
                        //         if (arrstatus.length > 0) {
                        //             arrstatus.forEach(element => {

                        //                 let val = [];
                        //                 val = element.split('-');
                        //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                        //                 if (valIndex !== 3) {
                        //                     Dependency = true;
                        //                 }
                        //             });
                        //         }
                        //         if (Dependency) {
                        //             Nodify('Warning!', 'warning', "Please close dependency task");
                        //         }
                        //         else {
                        //             values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                        //             setInputFields(values);
                        //             hideLoader();
                        //         }
                        //     } else {
                        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                        //         setInputFields(values);
                        //         hideLoader();
                        //     }
                        // }
                        // else {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                        setInputFields(values);
                        hideLoader();
                        // }
                        //get manual close
                        let Operation;
                        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                            Operation = 1;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                            Operation = 3;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
                            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
                            Operation = 2;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                            Operation = 4;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                            Operation = 5;
                        }
                        //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                        else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                            || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
                            || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                            || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                            || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                            || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
                            Operation = 6;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                            Operation = 7;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                            Operation = 9;
                        }
                        else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                            Operation = 10;
                        }
                        else {
                            Operation = 8;
                        }


                        let params = {
                            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                            SupplierId: getTNASupplierID, TaskType: TaskType, TaskNameId: TaskID,
                            TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
                            Operation: Operation, ParentIdOrSubChildId: ChildId, IsProduction: IsProduction
                        };

                        await TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                            if (response.data) {
                                var result = response.data;
                                let manindex = 1;
                                let data = result.map((item) => {
                                    item.index = manindex;
                                    manindex = manindex + 1;

                                    if (item.subTaskInfoId === ChildId) {
                                        item.isSelected = 1;

                                        return item;
                                    } else {
                                        return item;
                                    }
                                });
                                let isSelectAll = 0;
                                if (data) {
                                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                                    isSelectAll = isSelectAll ? 1 : 0;
                                }
                                setselectall(isSelectAll);
                                if (Status !== 3) {
                                    data.map((r, i) => {
                                        CurrentChildvalues.map(c => {
                                            if (r.subTaskInfoId === c.ChildId) {
                                                data[i].IsDepandency = c.IsDepandency
                                                data[i].Depandency = c.Depandency
                                                result[i].dependencyStartstop = result[i].IsDepandency === 0 ? 1 : 0

                                            }
                                        })
                                    })
                                    setMultiCloseDatas(data);
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];
                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }
                                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
                                    hideLoader();
                                }
                                else {
                                    setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];
                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }
                                }
                                hideLoader();
                            }


                        }).catch(() => { hideLoader(); });
                    }
                    else {
                        hideLoader();
                        Nodify('Warning!', 'warning', "Manual Submission not yet added for this task");
                        return false;
                    }

                }).catch(() => { hideLoader(); });
            }
            else {
                let isAllowed = true;
                // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0 && (!ManualCloseComment)) {

                //     let Dependency = false;
                //     if (values[index].SelectedDependency) {

                //         let arrstatus = [];
                //         arrstatus = values[index].SelectedDependency.split(',');
                //         if (arrstatus.length > 0) {
                //             arrstatus.forEach(element => {

                //                 let val = [];
                //                 val = element.split('-');
                //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
                //                 if (valIndex !== 3) {
                //                     Dependency = true;
                //                 }
                //             });
                //         }
                //         if (Dependency) {
                //             isAllowed = false;
                //             Nodify('Warning!', 'warning', "Please close dependency task");
                //             hideLoader();
                //         }
                //         else { values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1; setInputFields(values); }
                //     } else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                setInputFields(values);
                //         //hideLoader();
                //     }
                // }

                if (isAllowed) {
                    // else {
                    //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                    //     setInputFields(values);

                    // }

                    //get manual close
                    let Operation;
                    if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                        Operation = 1;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                        || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                        Operation = 3;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
                        Operation = 2;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL) {
                        Operation = 4;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                        Operation = 5;
                    }
                    //|| TaskName.toLowerCase() === StaticTask.SMS_PURCHASE_ORDER
                    else if (TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER
                        || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE || TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW
                        || TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION || TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                        || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
                        Operation = 6;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH) {
                        Operation = 7;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                        Operation = 9;
                    }
                    else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                        Operation = 10;
                    }
                    else {
                        Operation = 8;
                    }

                    if (values[index].DetailTypeID === 4) {
                        let rowDetails = values[index].SubTaskList[subIndex].ChildTaskList[childIndex];

                        let params = {
                            Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
                            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                            SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
                            //SkuId: rowDetails.SubId,
                            SkuId: 0,
                            TaskOwnerIdList: values[index].TaskHolderOwnerIdList,
                            TaskHolderId: values[index].TaskHolderOrFollower, IsProduction: IsProduction

                        };
                        TnaService.GetSubChildMultExFactoryFormSKUList(params).then((response) => {
                            if (response.data) {
                                var result = response.data;
                                let manindex = 1;
                                let data = result.map((item) => {
                                    item.index = manindex;
                                    manindex = manindex + 1;

                                    if (item.tnaExFactoryId === ChildId) {
                                        item.isSelected = 1;
                                        return item;
                                    } else {
                                        return item;
                                    }
                                });
                                let isSelectAll = 0;
                                if (data) {
                                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                                    isSelectAll = isSelectAll ? 1 : 0;
                                }
                                setselectall(isSelectAll);
                                if (Status !== 3) {
                                    data.map((r, i) => {
                                        CurrentChildvalues.map(c => {
                                            if (r.subTaskInfoId === c.ChildId) {
                                                data[i].IsDepandency = c.IsDepandency
                                                data[i].Depandency = c.Depandency
                                                result[i].dependencyStartstop = result[i].IsDepandency === 0 ? 1 : 0
                                            }
                                        })
                                    })
                                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
                                    setMultiCloseDatas(data);
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];
                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }

                                }
                                else {
                                    setMultiCloseDatas(data.filter(x => x.tnaExFactoryId === ChildId));
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];
                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }
                                }

                                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                                setInputFields(values);
                                hideLoader();

                            }


                        }).catch(() => { hideLoader(); });

                    } else {
                        let params = {
                            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                            SupplierId: getTNASupplierID, TaskType: TaskType, TaskNameId: TaskID,
                            TaskTodoInProgressCompletedStatus: 9, StartDate: null, EndDate: null,
                            Operation: Operation, ParentIdOrSubChildId: ChildId, IsProduction: IsProduction
                        };

                        TnaService.GetSubChildMultiTaskManualCloseList(params).then((response) => {
                            if (response.data) {
                                var result = response.data;
                                let manindex = 1;
                                let data = result.map((item) => {
                                    item.index = manindex;
                                    manindex = manindex + 1;

                                    if (item.subTaskInfoId === ChildId) {
                                        item.isSelected = 1;

                                        return item;
                                    } else {
                                        return item;
                                    }
                                });
                                let isSelectAll = 0;
                                if (data) {
                                    isSelectAll = !!!data.find(d => d.isSelected === 0);
                                    isSelectAll = isSelectAll ? 1 : 0;
                                }
                                setselectall(isSelectAll);
                                if (Status !== 3) {
                                    data.map((r, i) => {
                                        CurrentChildvalues.map(c => {
                                            if (r.subTaskInfoId === c.ChildId) {
                                                data[i].IsDepandency = c.IsDepandency
                                                data[i].Depandency = c.Depandency
                                                result[i].dependencyStartstop = result[i].IsDepandency === 0 ? 1 : 0
                                            }
                                        })
                                    })
                                    setMultiCloseDatas(data);
                                    setIndexes({ Index: index, SubIndex: subIndex, ChildIndex: childIndex });
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];
                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }

                                }
                                else {
                                    setMultiCloseDatas(data.filter(x => x.subTaskInfoId === ChildId));
                                    if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                        TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                        || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                        let maindata = [...new Map(data.map(item =>
                                            [item['strikeofforTrimLogoId'], item])).values()];
                                        let ImageUrl = '';
                                        if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
                                            ImageUrl = 'Images/Style/Trim/';
                                        }
                                        else {
                                            ImageUrl = 'Images/Style/Strikeoff/';

                                        }
                                        maindata.map(x => {
                                            x.ImageUrl = ImageUrl;
                                        })
                                        setOrderInfoMulticloseMainDetails(maindata);
                                        DefaultExpandRowMultiSelect(data.filter(x => x.subTaskInfoId === ChildId)[0].strikeofforTrimLogoPath, data);
                                    }
                                }
                                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 1;
                                setInputFields(values);
                                hideLoader();
                            } else {
                                hideLoader();
                                Nodify('Warning!', 'warning', "No data in manual close");
                            }


                        }).catch(() => { hideLoader(); });
                    }
                }
            }
        }

    };

    const DefaultExpandRowMultiSelect = (strikeofforTrimLogoPath, list) => {

        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = false;
        let obj = {};
        isRowExpanded ? (obj[strikeofforTrimLogoPath] = false) : (obj[strikeofforTrimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeofforTrimLogoPath) :
            currentExpandedRows.concat(strikeofforTrimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);
    }

    const handleExpandRowMultiSelect = (strikeofforTrimLogoPath, list) => {
        setExpandedRowsMultiSelect([]);
        setexpandListMultiSelect(list.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath));
        let currentExpandedRows = expandedRowsMultiSelect;
        const isRowExpanded = currentExpandedRows.includes(strikeofforTrimLogoPath);
        let obj = {};
        isRowExpanded ? (obj[strikeofforTrimLogoPath] = false) : (obj[strikeofforTrimLogoPath] = true);
        setExpandStateMultiSelect(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== strikeofforTrimLogoPath) :
            currentExpandedRows.concat(strikeofforTrimLogoPath);
        setExpandedRowsMultiSelect(newExpandedRows);
    }
    const handleCloseManualPopup = (StatusID, cmt, date, index, subIndex, childIndex) => {
        if (StatusID !== 3) {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = '';
            // if (cmt !== '' || (date !== '' && date !== null)) {
            //     Nodify('Warning!', 'warning', "closing date or comments not add in this task,please completed fully");
            // }
            // if (cmt !== '' && (date !== '' || date == null)) {
            //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = "";

            // }
            // else {
            //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = '';
            // }
        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
        }
        setInputFields(values);
    }
    const SelectAll = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }

    const OnSelectRecords = (event, index) => {
        const value = [...MultiCloseDatas];

        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;

        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);

        setMultiCloseDatas(value);
    }


    const SelectAllST = (event, strikeofforTrimLogoPath, mainindex) => {

        let IsSelected = 0;
        var Maindetails = [...OrderInfoMulticloseMainDetails];
        if (event.target.checked === true) {
            IsSelected = 1;
            MultiCloseDatas.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath).forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultiCloseDatas.filter(x => x.strikeofforTrimLogoPath === strikeofforTrimLogoPath).forEach(element => {
                element.isSelected = 0;
            })
        }
        Maindetails[mainindex].isSelectedAll = IsSelected;
        setOrderInfoMulticloseMainDetails(Maindetails);

        //setselectall({ IsAllSelected: IsSelected, AllSelectedIndex: mainindex });
    }

    const OnSelectRecordsST = (event, index, mainindex) => {
        const value = [...MultiCloseDatas];
        var Maindetails = [...OrderInfoMulticloseMainDetails];
        if (value) {
            if (event.target.checked === true) {
                value[index].isSelected = 1;
            }
            else {
                value[index].isSelected = 0;
            }
            let isSelectAll = 0;
            if (value) {
                isSelectAll = !!!value.find(d => d.isSelected === 0);
                isSelectAll = isSelectAll ? 1 : 0;
            }

            Maindetails[mainindex].isSelectedAll = isSelectAll;
            setOrderInfoMulticloseMainDetails(Maindetails);
            // setselectall({ IsAllSelected: isSelectAll, AllSelectedIndex: mainindex });
            setMultiCloseDatas(value);
        }

    }
    const handleChangeIsManualCloseComments = (event, index, subIndex, childIndex, feild) => {
        //setComment(false);
        let input = '';


        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment = input;
        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = event;
            // ManualCloseData.ManualCloseDate = event;
        }
        setInputFields(values);
    }

    const SaveChildManulaCloseComments = (index, subIndex, childIndex, value, CloseDate, TaskName) => {
        if (value === '' || value === undefined) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
        }
        else if (CloseDate === '' || CloseDate === null) {
            setComment(true);
            Nodify('Warning!', 'warning', "Please enter Close Date");
        }
        else {
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID = 3;
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate = new Date();
            // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildActualEndDate = new Date();
            // setInputFields(values);


            let ChildStatus = 0;
            if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
                ChildStatus = 1;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT) {
                ChildStatus = 2;

            } else if (TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER ||
                TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT ||
                TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER ||
                TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {

                ChildStatus = 3;

            } else if (TaskName.toLowerCase() === StaticTask.SMS_PRODUCTION_FOLLOW || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_ORDER || TaskName.toLowerCase() === StaticTask.SMS_FABRIC_IN_HOUSE ||
                TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION || TaskName.toLowerCase() === StaticTask.SMS_APPROVAL || TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH
                || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION || TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                || TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION || TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                || TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION || TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) {
                ChildStatus = 4;
            }
            else if (TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL ||
                TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL) {
                ChildStatus = 5;
            } else if (values[index].DetailTypeID === 4) {
                ChildStatus = 7;
            }
            else if (TaskName.toLowerCase() === StaticTask.STRIKEOFF_TEST) {
                ChildStatus = 8;
            }
            else if (TaskName.toLowerCase() === StaticTask.TRIMS_TEST) {
                ChildStatus = 9;
            }

            let strValue = "";
            let selected = 0;
            for (let data of MultiCloseDatas) {
                if (strValue === "") {
                    if (data.isSelected === 1) {
                        strValue = data.subTaskInfoId.toString();
                        selected = selected + 1;
                    }
                } else {
                    if (data.isSelected === 1) {
                        strValue = strValue + "," + data.subTaskInfoId;
                    }
                }
            }
            if (selected === 0) {
                Nodify('Warning!', 'warning', "Select atleast one Task");
            }
            else {
                var ManualCloseData = { ...MultiManualCloseFeilds };
                ManualCloseData.ManualCloseDate = new Date(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate);
                ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // ManualCloseData.ActualEndDate = new Date(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate);
                // ManualCloseData.ActualEndDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
                // .setDate(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate.getDate() + 1);
                ManualCloseData.ManualCloseComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment;
                ManualCloseData.IdList = strValue;
                ManualCloseData.TaskStatus = ChildStatus;
                ManualCloseData.CreatedBy = 1;
                TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
                    if (res.data.outputResult === "2") {
                        Nodify('Success!', 'success', 'Task Manually Closed Successfully');

                        let CloseDate = convertUTCDateToLocalDate(new Date(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseDate));
                        MultiCloseDatas.filter(x => x.isSelected === 1).forEach(element => {
                            values[index].SubTaskList[subIndex].ChildTaskList.forEach((element1, index1) => {
                                if (element.subTaskInfoId === element1.ChildId) {
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].IsManualClose = 0;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].StatusID = 3;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].ManualCloseComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].ManualCloseDate = CloseDate;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].ChildActualEndDate = CloseDate;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].StrActualEndDate = moment(CloseDate).format("MM/DD/YYYY");
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].intDependency = element.dependencyStartstop;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].DependencyComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyComment;
                                    values[index].SubTaskList[subIndex].ChildTaskList[index1].DependencyDate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyDate;

                                }
                            })
                        })
                        IsParentDateShow = ManualCommentAdd(values, index, IsParentDateShow, CloseDate);
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsManualClose = 0;
                        AutoDepandencyCloseonParentTaskClose();
                        setInputFields(values);
                    }
                    else if (res.data.outputResult === "0") {
                        Nodify("Error!", "Error Occured!");
                    }
                });
            }

        }
    }
    // var ManualCloseData = {};
    // ManualCloseData.ManualCloseDate = new Date();
    // ManualCloseData.ManualCloseDate.setMinutes(ManualCloseData.ManualCloseDate.getMinutes() + 370);
    // ManualCloseData.ManualCloseComment = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualCloseComment;
    // ManualCloseData.IdList = ChildId.toString();
    // ManualCloseData.TaskStatus = ChildStatus;
    // ManualCloseData.CreatedBy = currentUser.employeeinformationID;
    // TnaService.UpdateTaskManualCloseDetails(ManualCloseData).then(res => {
    //     if (res.data.outputResult === "2") {
    //         Nodify('Success!', 'success', 'Task Manually Closed Successfully');




    //     }



    const showManualApproval = (index, subIndex, childIndex, childId, TaskName) => {
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }

        TnaService.getManualApproval(childId, Operation).then((response) => {
            if (response.data) {
                setmanualApprovalData(response.data);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 1;
                setInputFields(values);
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 1;
                setInputFields(values);
            }
        }).catch(() => { });
    }

    const hideManualApproval = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ManualApprovalType = 0;
        setInputFields(values);
    }

    const showExistingApproval = (index, subIndex, childIndex, childId, TaskName) => {
        let Operation;
        if (TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL) {
            Operation = 1;
        }
        else if (TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER) {
            Operation = 3;
        }
        else if (TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER
            || TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER) {
            Operation = 2;
        }

        TnaService.getExistingApproval(childId, Operation).then((response) => {
            if (response.data) {
                setexistingApprovalData(response.data);
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 1;
                setInputFields(values);
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 1;
                setInputFields(values);
            }
        }).catch(() => { });
    }

    const hideExistingApproval = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ExistingApprovalType = 0;
        setInputFields(values);
    }



    const OpenRevicePopup = (index, subIndex, childIndex, TNAId, TaskDetailId, TaskName, StartDate, EndDate, Duration, Type, EndDateChange) => {
        debugger;

        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }

        else {
            if (Type === "Main") {
                if (values[index].MainRevicedetails) {
                    setReviceComment(values[index].MainRevicedetails.ReviceComment)
                }
            }
            else {
                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails) {
                    setReviceComment(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.ReviceComment)
                }
            }


            TNAService.getTNATaskReviceList(TNAId, TaskDetailId).then((response) => {
                if (response.data) {

                    setSubReviceList(response.data);
                    if (Type === "Main") {
                        values[index].RevicePopup = 1;
                        values[index].ReviceSelected = 1;
                    }
                    else {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RevicePopup = 1;
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReviceSelected = 1;

                    }
                    setInputFields(values);
                }
            }).catch(() => { });

        }
    }
    const CloseRevicePopup = (index, subIndex, childIndex, Type, Action) => {
        setSubmitRevice(false)
        if (Type === 'Main') {
            values[index].RevicePopup = 0;

        }
        else {

            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RevicePopup = 0;

        }
        if (Action === 'Close') {
            if (Type === 'Main') {
                values[index].ReviceSelected = 0;
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReviceSelected = 0;
            }
        }

        setReviceComment('');
        setInputFields(values);
        setReviceAction('Add');
        // }

    }



    const handleChangeReviceComments = (event, feild) => {
        setSubmitRevice(false);
        let input = '';
        if (event.target.value.trim() !== '') {
            input = event.target.value;
        }
        setReviceComment(input);

    }

    const handleSaveRevice = (index, subIndex, childIndex, TNAId, TaskDetailId, TaskName, StartDate, EndDate, Duration, Type, EndDateChange, Comment) => {
        if ((!Comment)) {
            setSubmitRevice(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            ;

            if (Comment !== '') {
                if (Type !== 'Main') {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.StartDate = '';
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.EndDate = '';
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.Duration = 0;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.TNAId = TNAId;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.TaskDetailId = TaskDetailId;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.TaskName = TaskName;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.Operation = 1;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.Type = Type === "Main" ? "Main" : "Sub";
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.ReviceComment = Comment;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRevicedetails.Createdby = currentUser.employeeinformationID;
                }
                else {
                    values[index].MainRevicedetails.StartDate = '';
                    values[index].MainRevicedetails.EndDate = '';
                    values[index].MainRevicedetails.Duration = 0;
                    values[index].MainRevicedetails.TNAId = TNAId;
                    values[index].MainRevicedetails.TaskDetailId = TaskDetailId;
                    values[index].MainRevicedetails.TaskName = TaskName;
                    values[index].MainRevicedetails.Operation = 1;
                    values[index].MainRevicedetails.Type = Type === "Main" ? "Main" : "Sub";
                    values[index].MainRevicedetails.ReviceComment = Comment;
                    values[index].MainRevicedetails.Createdby = currentUser.employeeinformationID;
                }
            }
            if (Type === 'Main') {
                values[index].IsReviced = 1;
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsReviced = 1;
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReviceAdded = 1;
            }
            setInputFields(values);
            CloseRevicePopup(index, subIndex, childIndex, Type, 'Add');
            Nodify('Success!', 'success', "Comments Added Successfully");
            // }

        }
    }
    const OpenReschedulePopup = (index, subIndex, childIndex, TNAId, TaskDetailId, TaskName, StartDate, EndDate, Duration, Type, Status) => {


        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');

        }

        else {
            if (Type === "Main") {
                if (values[index].MainRescheduledetails) {
                    setRescheduleComment(values[index].MainRescheduledetails.RescheduleComment)
                }
            }
            else {
                if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails) {
                    setRescheduleComment(values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.RescheduleComment)
                }
            }

            TNAService.getTNATaskRescheduleList(TNAId, TaskDetailId).then((response) => {
                if (response.data) {
                    setSubRescheduleList(response.data);
                    if (Type === "Main") {
                        values[index].ReschedulePopup = 1;
                        values[index].RescheduleSelected = 1;
                    }
                    else {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReschedulePopup = 1;
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RescheduleSelected = 1;
                    }
                    setInputFields(values);
                }
            }).catch(() => { });

        }
    }
    const CloseReschedulePopup = (index, subIndex, childIndex, Type, Action) => {
        setSubmitReschedule(false)
        if (Type === "Main") {
            values[index].ReschedulePopup = 0;

        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReschedulePopup = 0;

        }

        if (Action === 'Close') {
            if (Type === 'Main') {
                values[index].RescheduleSelected = 0;
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RescheduleSelected = 0;
            }
        }

        setRescheduleComment('');
        setInputFields(values);
        setRescheduleAction('Add');

    }
    const handleSaveReschedule = (index, subIndex, childIndex, TNAId, TaskDetailId, TaskName, StartDate, EndDate, Duration, Type, EndDateChange, Comment) => {
        if ((!Comment)) {
            setSubmitReschedule(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        }
        else {
            if (Comment !== '') {
                if (Type !== 'Main') {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.StartDate = '';
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.EndDate = '';
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.Duration = 0;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.TNAId = TNAId;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.TaskDetailId = TaskDetailId;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.TaskName = TaskName;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.Operation = 1;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.Type = Type === "Main" ? "Main" : "Sub";
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.RescheduleComment = Comment;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubRescheduledetails.Createdby = currentUser.employeeinformationID;
                }
                else {
                    values[index].MainRescheduledetails.StartDate = '';
                    values[index].MainRescheduledetails.EndDate = '';
                    values[index].MainRescheduledetails.Duration = 0;
                    values[index].MainRescheduledetails.TNAId = TNAId;
                    values[index].MainRescheduledetails.TaskDetailId = TaskDetailId;
                    values[index].MainRescheduledetails.TaskName = TaskName;
                    values[index].MainRescheduledetails.Operation = 1;
                    values[index].MainRescheduledetails.Type = Type === "Main" ? "Main" : "Sub";
                    values[index].MainRescheduledetails.RescheduleComment = Comment;
                    values[index].MainRescheduledetails.Createdby = currentUser.employeeinformationID;
                }

            }
            if (Type === 'Main') {
                values[index].IsRescheduled = 1;
            }
            else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsRescheduled = 1;
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].RescheduleAdded = 1;
            }
            CloseReschedulePopup(index, subIndex, childIndex, Type, 'Add');
            IsParentDateShow = DateAffectReschedule(values, index, IsParentDateShow);

            setInputFields(values);
            // }


        }
    }
    const handleChangeRescheduleComments = (event, feild) => {
        setSubmitReschedule(false);

        let input = '';
        if (event.target.value.trim() !== '') {
            input = event.target.value;
        }
        setRescheduleComment(input);

    }

    /////////////////////////  Dependency Start & Stop /////////////////////////

    const handleChangeDependentStart = (index, subIndex, childIndex, event, feild, IsSingleTask) => {
        setSubmitDependencyStart(false);

        let input = '';


        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            if (IsSingleTask) {
                values[index].DependencyComment = input;
            } else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyComment = input;
            }
        }
        else {
            if (IsSingleTask) {
                values[index].DependencyDate = event;
            } else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyDate = event;
            }

        }
        setInputFields(values);

    }


    const OpenDependentStartPopup = (index, subIndex, childIndex, intDependency, TNAId, TaskID, ChildId, IsSingleTask) => {
        showLoader();
        setSubmitDependencyStart(false);
        // if (intDependency === 1) {
        //     if (IsSingleTask) {
        //         values[index].DependencyComment = "";
        //         values[index].DependencyDate = "";
        //         values[index].intDependency = 0;
        //     } else {
        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyComment = "";
        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyDate = "";
        //         values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency = 0;
        //     }
        //     setInputFields(values);
        //     hideLoader();
        // } else {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
            hideLoader();
        }
        else {
            let hasSingleTask = IsSingleTask ? 1 : 0;
            TNAService.getTNADependencyStartList(TNAId, TaskID, ChildId, hasSingleTask).then((response) => {
                if (response.data) {
                    setDependencyStartList(response.data);
                    if (IsSingleTask) {
                        values[index].IsDependencyStartPopup = true;
                        values[index].DependencyComment = "";
                        values[index].DependencyDate = intDependency === 1 ? new Date() : "";
                        values[index].intDependency = intDependency;
                    }
                    else {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsDependencyStartPopup = true;
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyComment = "";
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyDate = intDependency === 1 ? new Date() : "";
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency = intDependency;
                    }
                    setInputFields(values);
                    hideLoader();
                }
            }).catch(() => { hideLoader(); });

        }
        //}

    }

    const handleChangeMultiDependentStart = (index, subIndex, childIndex, event, feild, IsSingleTask) => {
        setSubmitDependencyStart(false);
        let input = '';

        if (feild === "Comment") {
            if (event.target.value.trim() !== '') {
                input = event.target.value;
            }
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyComment = input;
        }
        else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyDate = event;
        }
        setInputFields(values);

    }

    const CloseDependencyStartPopup = (index, subIndex, childIndex, IsSingleTask) => {
        if (IsSingleTask) {
            values[index].IsDependencyStartPopup = false;
        } else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsDependencyStartPopup = false;
        }
        setInputFields(values);
        // values[index].ReschedulePopup = 0;
        // values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ReschedulePopup = 0;
        // setSubRescheduledetails({ TNATaskRescheduleId: 0, TNAId: 0, TaskDetailId: 0, RescheduleDate: '', RescheduleComment: '', Operation: 0 })
        // setRescheduleTaskDetailId(0);
        // setRescheduleTnaId(0);
        // setRescheduleTnaTask('');
        // setInputFields(values);
        //setRescheduleAction('Add');
    }
    const OpenAllDependentStartPopup = (index, subIndex, childIndex, intDependency, TNAId, TaskID, ChildId, IsSingleTask) => {
        showLoader();
        if (intDependency === 1) {
            // if (IsSingleTask) {
            //     values[index].hasAllDependencyStart = 0;
            // } else {
            //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart = 0;
            // }
            // setInputFields(values);
            hideLoader();
        } else {
            if (values[index].TaskID === 0) {
                Nodify('Warning!', 'warning', 'Please select the task name.');
                hideLoader();
            }
            else {
                // let hasSingleTask = IsSingleTask ? 1 : 0;

                if (IsSingleTask) {
                    values[index].hasAllDependencyStart = 1;
                    values[index].hasAllDependencyStartPopup = true;
                }
                else {
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart = 1;
                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStartPopup = true;
                }
                setInputFields(values);
                hideLoader();
            }
        }
    }

    const handleSaveDependencyStart = (index, subIndex, childIndex, IsSingleTask, intDependency) => {
        ;
        showLoader();
        let dependencyComments = "";
        let dependencydate = null;
        let CommonId = 0;
        if (IsSingleTask) {
            dependencyComments = values[index].DependencyComment;
            dependencydate = values[index].DependencyDate;
        } else {
            dependencyComments = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyComment;
            dependencydate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].DependencyDate;
            CommonId = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].ChildId;
        }

        if (!dependencyComments || !dependencydate) {
            setSubmitDependencyStart(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            hideLoader();
            return false;
        }
        else {
            let TaskID = values[index].TaskID;
            let params = {
                TNAId: props.location.state.params.tnaid,
                TaskDetailId: TaskID,
                CommonId: CommonId,
                DependencyDate: convertUTCDateToLocalDate(new Date(dependencydate)),
                Comments: dependencyComments,
                IsSingleTask: IsSingleTask ? 1 : 0,
                DependencyStartstop: intDependency === 1 ? 0 : 1,
                CreatedBy: currentUser.employeeinformationID

            }

            TnaService.InsertTNADependencyStartHistory(params).then((res) => {
                if (res.data.outputResult === "1") {
                    if (IsSingleTask) {
                        values[index].IsDependencyStartPopup = false;
                        values[index].intDependency = intDependency === 1 ? 0 : 1;
                    }
                    else {
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsDependencyStartPopup = false;
                        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency = intDependency === 1 ? 0 : 1;
                    }
                    setInputFields(values);
                    Nodify('Success!', 'success', "Dependency Added Successfully");
                    hideLoader();
                } else if (res.data.outputResult === "2") {
                    Nodify('Warning!', 'warning', "This dependency date already added");
                    hideLoader();
                } else if (res.data.outputResult === "-1") {
                    Nodify('Warning!', 'warning', "Error Occured");
                    hideLoader();
                }
            })
        }
    }

    // const OpenAllDependentStartPopup = (index, subIndex, childIndex, intDependency, TNAId, TaskID, ChildId, IsSingleTask) => {
    //     showLoader();
    //     if (intDependency === 1) {
    //         // if (IsSingleTask) {
    //         //     values[index].hasAllDependencyStart = 0;
    //         // } else {
    //         //     values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart = 0;
    //         // }
    //         // setInputFields(values);
    //         hideLoader();
    //     } else {
    //         if (values[index].TaskID === 0) {
    //             Nodify('Warning!', 'warning', 'Please select the task name.');
    //             hideLoader();
    //         }
    //         else {
    //             // let hasSingleTask = IsSingleTask ? 1 : 0;

    //             if (IsSingleTask) {
    //                 values[index].hasAllDependencyStart = 1;
    //                 values[index].hasAllDependencyStartPopup = true;
    //             }
    //             else {
    //                 values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart = 1;
    //                 values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStartPopup = true;
    //             }
    //             setInputFields(values);
    //             hideLoader();
    //         }
    //     }
    // }




    const CloseAllDependencyStartPopup = (index, subIndex, childIndex, IsSingleTask, dependencyComments, dependencydate) => {

        if (IsSingleTask) {
            values[index].hasAllDependencyStartPopup = false;
            if (dependencyComments !== '' && dependencydate !== '') {
                values[index].hasAllDependencyStart = 1;
            }
        } else {
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStartPopup = false;
            if (dependencyComments !== '' && dependencydate !== '') {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart = 1;
            }

        }
        setInputFields(values);

    }

    const handleSaveAllDependencyStart = (index, subIndex, childIndex, TaskID, IsSingleTask) => {
        showLoader();
        let dependencyComments = "";
        let dependencydate = null;
        let CommonIdList = "";
        dependencyComments = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyComment;
        dependencydate = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].MultiDependencyDate;
        if (!dependencyComments || !dependencydate) {
            setSubmitDependencyStart(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            hideLoader();
            return false;
        }
        else {

            if (IsSingleTask) {
                values[index].hasAllDependencyStart = 1;

            } else {
                values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStart = 1;
            }
            let ManualCloseData = [...MultiCloseDatas];
            ManualCloseData = ManualCloseData.filter(d => d.dependencyStartstop !== 1);
            ManualCloseData.map((item) => {
                if (CommonIdList === "") {
                    CommonIdList = item.subTaskInfoId.toString();
                } else {
                    CommonIdList = CommonIdList + "," + item.subTaskInfoId.toString();
                }
                item.dependencyStartstop = 1;
            });

            let params = {
                TNAId: props.location.state.params.tnaid,
                TaskDetailId: TaskID,
                CommonIdList: CommonIdList,
                DependencyDate: convertUTCDateToLocalDate(new Date(dependencydate)),
                Comments: dependencyComments,
                IsSingleTask: IsSingleTask ? 1 : 0,
                CreatedBy: currentUser.employeeinformationID
            }

            TnaService.InsertTNAMultiDependencyStartHistory(params).then((res) => {
                if (res.data.outputResult === "1") {

                    values[index].SubTaskList[subIndex].ChildTaskList[childIndex].hasAllDependencyStartPopup = false;
                    setMultiCloseDatas(ManualCloseData);

                    setInputFields(values);
                    Nodify('Success!', 'success', "Dependency Added Successfully");
                    hideLoader();
                } else if (res.data.outputResult === "2") {
                    Nodify('Warning!', 'warning', "This dependency date already added");
                    hideLoader();
                } else if (res.data.outputResult === "-1") {
                    Nodify('Warning!', 'warning', "Error Occured");
                    hideLoader();
                }
            })
        }
    }

    function getSelectedDependencyMap(SelectedDependency) {
        let Dependency = true;
        if (SelectedDependency) {
            let arrstatus = [];
            arrstatus = SelectedDependency.split(',');
            if (arrstatus.length > 0) {
                arrstatus.map(element => {
                    let val = [];
                    val = element.split('-');
                    let valIndex = values[parseInt(val[0]) - 1].StatusID
                    if (valIndex !== 3) {
                        Dependency = false;
                    }
                });
            }

        }

        return Dependency;
    }








    // #region---------------------  Ex-Factory Form --------------------------------
    const handleExFactoryFormOpen = (index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType) => {

        let Operation = 1
        let Dependency = false;
        if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

            if (values[index].SelectedDependency) {
                let arrstatus = [];
                arrstatus = values[index].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {

                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {

                }
            }
        }
        const childTaskData = values[index].SubTaskList[subIndex].ChildTaskList[childIndex];
        if (!Dependency) {
            //  SubId(SKUID) set 0 for entire record showed
            let params = {
                Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
                BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
                SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
                tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
                Isproduction: IsProduction, TnaId: props.location.state.params.tnaid,
            };
            setAddInspectionListParam(params);
            setskusingleID(childTaskData.PurchaseOrderSkuID.toString())
            // TnaService.GetSubChildMultExFactoryFormSKUList(params).then((response) => {

            //    if (response.data) {

            // let data = response.data.map((item) => {
            //     if (item.tnaExFactoryId === TNAExFactoryId) {
            //         item.isSelected = 1;
            //         return item;
            //     } else {
            //         return item;
            //     }
            // });
            // let isSelectAll = 0;
            // if (data) {
            //     isSelectAll = !!!data.find(d => d.isSelected === 0);
            //     isSelectAll = isSelectAll ? 1 : 0;
            // }
            // setselectall(isSelectAll);
            // if (Status !== 3) {
            //     setMultExFactorySKUList(data);
            // }
            // else {
            //     setMultExFactorySKUList(data.filter(x => x.tnaExFactoryId === TNAExFactoryId));
            // }

            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsExFactoryForm = 1;
            setInputFields(values);
            //  }
            getInspectionInfo.TaskStatus = OperationType;
            setInspectionInfo(getInspectionInfo);
            //}).catch(() => { });
        }
    };
    function handleDeleteTestinspection(item) {

        let PackingParams = {
            Operation: 126,
            Id: item.tnaTestingInspectionID,
        }
        setIDTestTask({ showPopupDelete: true, Params: PackingParams });
    }

    function handleTestinInspecView(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, inputField, operation) {
        showLoader();
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingInsView = 1;
        setInputFields(values);
        TnaService.TestingreportList(TNAExFactoryId, operation, props.location.state.params.tnaid).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            setnewTestingReportlist(res.data)
            setTestingheaderdata(uniquevalues);
            hideLoader();
        })
        getTestingTaskInfo.TaskStatus = OperationType;
        getTestingTaskInfo.TaskType = TaskName;
        setTestingTaskInfo(getTestingTaskInfo);

        let params = {
            Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
            SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
            SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
            tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
            Isproduction: IsProduction
        };
        setQCAddInspectionListParam(params);
        dispatch(GetTestingMultExFactorySKUList(params));
    }

    function handleQCInspectionFormOpen(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType) {
        let Operation = 1
        let Dependency = false;
        if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

            if (values[index].SelectedDependency) {
                let arrstatus = [];
                arrstatus = values[index].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {

                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {

                }
            }
        }

        if (!Dependency) {
            //  SubId(SKUID) set 0 for entire record showed
            let params = {
                Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
                BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
                SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
                tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
                Isproduction: IsProduction, TnaId: props.location.state.params.tnaid,
            };
            setQCAddInspectionListParam(params);
            setQAInspectionPageView('AddInspection');
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQCinsectionFactory = 1;
            getQCInspectionInfo.TaskStatus = OperationType;
            getQCInspectionInfo.TaskType = TaskName;//is for Midline or pilotrun or set sizecheck
            setInputFields(values);
            setQCInspectionInfo(getQCInspectionInfo);
        }
    }
    function handleQAInspecView(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, inputField) {
        showLoader();
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQAInsView = 1;
        setInputFields(values);
        QcInspection.GetTNAExfacQAReportList(TNAExFactoryId).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            setnewQAReportlist(res.data)
            setQAheaderdata(uniquevalues);
            hideLoader();
        })
        getQCInspectionInfo.TaskStatus = OperationType;
        getQCInspectionInfo.TaskType = TaskName;
        setQCInspectionInfo(getQCInspectionInfo);

        let params = {
            Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
            SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
            SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
            tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
            Isproduction: IsProduction
        };

        setQCAddInspectionListParam(params);
        dispatch(GetSubChildMultExFactoryFormSKUList(params));

    }
    async function handleTestingTaskFormOpen(index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType, childTask) {
        let Operation = 1
        let Dependency = false;
        if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

            if (values[index].SelectedDependency) {
                let arrstatus = [];
                arrstatus = values[index].SelectedDependency.split(',');
                if (arrstatus.length > 0) {
                    arrstatus.forEach(element => {

                        let val = [];
                        val = element.split('-');
                        let valIndex = values[parseInt(val[0]) - 1].StatusID
                        if (valIndex !== 3) {
                            Dependency = true;
                        }
                    });
                }
                if (Dependency) {
                    Nodify('Warning!', 'warning', "Please close dependency task");
                }
                else {

                }
            }
        }
        const params = {
            StyleIDListMulti: props.location.state.params.styleId.toString(),
            CheckProgramSkuIDList: childTask.SKUIdList.toString()
        };

        const res = await TnaService.GetCheckProgramSKUList(params);

        if (res.data.outputResult === "1") {
            Nodify('Warning!', 'warning', 'There is no program task for some particular SKU');
            Dependency = false;
            return;
        }

        if (!Dependency) {
            //  SubId(SKUID) set 0 for entire record showed
            let params = {
                Operation: (TaskName === 'FABRIC TEST' || TaskName === 'YARN TEST' || TaskName === 'GARMENT TEST') ? 1 : TaskName === 'Strike off Test' ? 2 : 3,
                //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
                BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
                SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
                SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
                tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
                Isproduction: IsProduction, TnaId: props.location.state.params.tnaid, PurchaseOrderSkuid: (TaskName === 'Strike off Test' || TaskName === 'Trims Test') ? childTask.StrikeOffOrTrimInfoId : childTask.PurchaseOrderSkuID
            };
            setTestingTaskListParam(params);
            setTestingReportPageView('AddInspection')
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingTaskFactory = 1;
            getTestingTaskInfo.TaskStatus = OperationType;
            getTestingTaskInfo.TaskType = TaskName;//is for Midline or pilotrun or set sizecheck
            setInputFields(values);
            setTestingTaskInfo(getTestingTaskInfo);
        }
    }
    function pageRedirectForTestingInspection(val) {
        setTestingReportPageView(val)
    }

    const handleFinalInspecView = (index, subIndex, childIndex, TaskName, TaskID, SubId, Status, TaskOwnerIdList, TaskHolderId, TNAExFactoryId, SelectedDependency, OperationType) => {

        showLoader();
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsFinalInsView = 1;
        setInputFields(values);

        let balanceqty = 0
        FinalinspectionService.GetTNAExfacReportList(TNAExFactoryId).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];

            // res.data.map((inspc) => {
            //     if (balanceqty === 0) {
            //         balanceqty = (parseInt(inspc.poQty) + parseInt(inspc.tolerance)) - parseInt(inspc.inspectedQty);
            //         inspc.balanceQuantity = balanceqty;
            //     }
            //     else {
            //         inspc.balanceQuantity = (parseInt(inspc.poQty) + parseInt(inspc.tolerance)) - parseInt(inspc.inspectedQty);
            //         balanceqty = inspc.balanceQuantity;
            //     }


            // })
            setnewfinalReportlist(res.data)
            setnewheaderdata(uniquevalues)
            hideLoader();
        })

        getInspectionInfo.TaskStatus = OperationType;
        setInspectionInfo(getInspectionInfo);

        //get existing list
        let params = {
            Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
            BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
            SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
            SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
            tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId,
            Isproduction: IsProduction
        };
        setAddInspectionListParam(params);
        dispatch(GetSubChildMultExFactoryFormSKUList(params));
    }

    const handleCloseFinalInspecView = (index, subIndex, childIndex) => {
        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 0;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsFinalInsView = 0;
        setInputFields(values);
    };

    const handleFinalInspecViewDetails = async (type, TNAInspectionID, Action, inpectionpage, item) => {
        let isvalid = true;
        if (Action === 'View') {
            isvalid = true;
        } else {
            isvalid = false;
        }

        if (!isvalid) {
            let PackingParams = {
                Operation: 104,
                Id: TNAInspectionID,
            }
            await CommonService.CheckMappedItem(PackingParams).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This finalinspection has already approved in inspection Entry Tab, So action not allowed to Edit.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
                    let sizeandqtyfordisplay = "";
                    let NewArr = [];
                    NewArr = TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID))
                    NewArr.map((Purchaseorder) => {
                        if (sizeandqtyfordisplay === '') {
                            sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                        } else {
                            sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                        }
                    })
                    TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID)).map((element) => {
                        element.IscheckReportSelect = 1;
                        element.isSelected = 1;
                    })
                    getInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
                    getInspectionInfo.quantityinfo = getAddInspectionListParam
                    getInspectionInfo.details = props.location.state.params;
                    getInspectionInfo.type = type;
                    getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;
                    getInspectionInfo.TNAInspectionID = TNAInspectionID;
                    getInspectionInfo.Isproduction = IsProduction
                    getInspectionInfo.page = getFinalInspectionPageView;
                    getInspectionInfo.pageType = inpectionpage;
                    getInspectionInfo.Action = Action;
                    getInspectionInfo.ParentTask = "TNAView";
                    getInspectionInfo.Mode = "InspectionView";
                    getInspectionInfo.Mode = item.reportType === '2' ? 'ReInspection' : "InspectionView";
                    props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
                }
            })
        } else {
            const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
            let sizeandqtyfordisplay = "";
            let NewArr = [];
            NewArr = TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID))
            NewArr.map((Purchaseorder) => {
                if (sizeandqtyfordisplay === '') {
                    sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                }
            })
            TNAAddFinalInspectionList.filter(x => x.refInspecSKUID === parseInt(item.skuID)).map((element) => {
                element.IscheckReportSelect = 1;
                element.isSelected = 1;
            })
            getInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
            getInspectionInfo.quantityinfo = getAddInspectionListParam
            getInspectionInfo.details = props.location.state.params;
            getInspectionInfo.type = type;
            getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;
            getInspectionInfo.TNAInspectionID = TNAInspectionID;
            getInspectionInfo.Isproduction = IsProduction
            getInspectionInfo.page = getFinalInspectionPageView;
            getInspectionInfo.pageType = inpectionpage;
            getInspectionInfo.Action = Action;
            getInspectionInfo.ParentTask = "TNAView";
            getInspectionInfo.Mode = "InspectionView";
            getInspectionInfo.Mode = item.reportType === '2' ? 'ReInspection' : "InspectionView";
            props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
        }
    };
    function handleDeletefinalinspection(type, TNAInspectionID, Action) {
        let PackingParams = {
            Operation: 104,
            Id: TNAInspectionID,
        }
        CommonService.CheckMappedItem(PackingParams).then(res => {
            if (res.data.outputResult === "-2") {
                let Title = "Warning!";
                let message = "This finalinspection has already approved in inspection Entry Tab, So action not allowed to Delete.";
                let type = "warning";
                Nodify(Title, type, message);
            }
            else {
                setIDSingleTask({ showPopupDelete: true, Params: PackingParams });
            }
        })
    }



    const AddExFactoryForm = (index, subIndex, childIndex) => {

        let Multiselected = [];
        const Values = [...getfinalinspectionMulti]
        let isvalid = true;
        isvalid = !!getfinalinspectionMulti.find(x => x.isSelected === 1)
        if (isvalid) {
            Multiselected = Values.concat(getfinalinspectionMulti.filter(x => x.isSelected === 1))
            //setMultiCloseDatas(Multiselected)
            // setfinalinspectionMulti(Multiselected)


        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }



        //-----------------------below codes were commented to just ignore the functionality---------------------//
        // let strValue = "";
        // let selected = 0;
        // for (let data of MultExFactorySKUList) {
        //     if (strValue === "") {
        //         if (data.isSelected === 1) {
        //             strValue = data.tnaExFactoryId.toString();
        //             selected = selected + 1;
        //         }
        //     } else {
        //         if (data.isSelected === 1) {
        //             strValue = strValue + "," + data.tnaExFactoryId;
        //         }
        //     }
        // }
        // if (selected === 0) {
        //     Nodify('Warning!', 'warning', "Select atleast one Task");
        // }
        // else {
        //     let SkuId = values[index].SubTaskList[subIndex].ChildTaskList[childIndex].SubId;
        //     let TaskName = values[index].strTaskName;
        //     let TaskID = values[index].TaskID;
        //     let dataParams = {
        //         BuyerID: getTNABuyerID, SeasonID: getTNASeasonID,
        //         BrandID: getTNABrandID, SupplierID: getTNASupplierID,
        //         StyleId: props.location.state.params.styleId, SkuId: SkuId,
        //         TaskName: TaskName, TaskID: TaskID,
        //         TnaExFactoryIdList: strValue, MultExFactorySKUList: MultExFactorySKUList,
        //         StyleInfo: getStyleInfo

        //     };
        //     props.history.push(`/ReportCreationMain`, { params: dataParams });
        // }
        /////////////////temporary code////////////////-kokilapriya
        setIsAddFinalInspection(true);
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 1;
        //props.history.push(`/ReportCreationMain`, { params: '' });
    };


    const handleCloseExFactoryFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 0;
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsExFactoryForm = 0;
        setInputFields(values);
    };

    const handleCloseInspectionConfirmationPopup = (index, subIndex, childIndex) => {
        setIsAddFinalInspection(false);
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 0;

    };

    const CreateFinalInspection = (type, Inspectionpage) => { // type=1=> internal, 2=> external

        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = getfinalinspectionMulti.filter(x => x.isSelected === 1)
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        getfinalinspectionMulti.filter(x => x.isSelected === 1).map((x) => {
            if (x.isSelected === 1) {
                x.IscheckReportSelect = 1;
            } else {
                x.IscheckReportSelect = 0;
            }

        })

        if (getFinalInspectionPageView === "ReInspection") {
            let selectedInspIds = '';
            NewArr.map(x => {
                if (x.isSelected === 1) {
                    if (selectedInspIds === '') {
                        if (!selectedInspIds.includes(x.tnaFinalInspectionID)) {
                            selectedInspIds = x.tnaFinalInspectionID.toString();
                        }
                    }
                    else {
                        if (!selectedInspIds.includes(x.tnaFinalInspectionID)) {
                            selectedInspIds = selectedInspIds + "," + x.tnaFinalInspectionID.toString();
                        }
                    }
                }
            })

            let uniquevalues = [...new Map(NewArr.map(item =>
                [item['skuName'], item])).values()];

            uniquevalues.map(x => {
                let totqty = 0;
                if (NewArr.filter(v => v.skuName === x.skuName && v.isSelected === 1).length !== 0) {
                    let sameskudetails = NewArr.filter(v => v.skuName === x.skuName);
                    sameskudetails.map(s => {
                        if (totqty === 0) {
                            totqty = s.balanceQuantity;
                        }
                        else {
                            totqty = totqty + s.balanceQuantity;
                        }
                    })
                }
                x.balanceQuantity = totqty;
                x.FinalInspectionIds = selectedInspIds;
                totqty = 0;
            })

            getInspectionInfo.multiselecteddatas = uniquevalues;
            getInspectionInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
        }
        else {
            getInspectionInfo.multiselecteddatas = getfinalinspectionMulti;
        }

        getInspectionInfo.quantityinfo = getAddInspectionListParam
        getInspectionInfo.details = props.location.state.params;
        getInspectionInfo.TnaID = props.location.state.params.tnaid
        getInspectionInfo.type = type;//For InterNal Or External
        getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
        getInspectionInfo.Isproduction = IsProduction;
        getInspectionInfo.page = getFinalInspectionPageView;//For InspectionType(Reinspection Or New inspection)
        getInspectionInfo.pageType = 'AddInspection';//For HeaderName Add,view or edit
        getInspectionInfo.ParentTask = "TNAView";
        getInspectionInfo.Mode = "InspectionAdd";//For Some CalculationWorks

        // getInspectionInfo.buyerName = NewArr[0].buyerName;
        // getInspectionInfo.seasonName = NewArr[0].seasonName;
        // getInspectionInfo.brandName = NewArr[0].brandName;
        // getInspectionInfo.buyerID = NewArr[0].buyerID;
        // getInspectionInfo.brandID = NewArr[0].brandID;
        // getInspectionInfo.seasonID = NewArr[0].seasonID;
        // getInspectionInfo.styleID = NewArr[0].styleID;
        // getInspectionInfo.styleName = NewArr[0].styleName;
        // getInspectionInfo.supplierId = NewArr[0].supplierID;
        // props.location.state.params.styleID = NewArr[0].styleID;
        getInspectionInfo.Mode = getFinalInspectionPageView === "ReInspection" ? 'ReInspection' : "InspectionAdd";//For Some CalculationWorks

        props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
    }
    const handleCloseQCinspectionFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsQCinsectionFactory = 0;
        setInputFields(values);
    };
    const SelectAllExFactory = (event) => {
        let IsSelected = 0;

        if (event.target.checked === true) {
            IsSelected = 1;
            MultExFactorySKUList.forEach(element => {
                element.isSelected = 1;
            })
        }
        else {
            IsSelected = 0;
            MultExFactorySKUList.forEach(element => {
                element.isSelected = 0;
            })
        }
        setselectall(IsSelected);
    }
    function QCTestingCallback(value) {
        setTestinginspectionMulti(value)
    }
    function handleCloseTestingView(index, subIndex, childIndex) {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingInsView = 0;
        setInputFields(values);
    }
    function handleTestInspecViewDetails(item, ActionType) {

        const TNAAddFinalInspectionList = reducerState.tna.tnaAddTestinginspection !== undefined ? reducerState.tna.tnaAddTestinginspection : [];
        let sizeandqtyfordisplay = "";
        let NewArr = [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.refInspecSKUID.toString();
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
            }
        })
        getTestingTaskInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getTestingTaskInfo.quantityinfo = getTestingTaskListParam// For Binding Selected checkbox values in quantity inforamtion
        getTestingTaskInfo.details = props.location.state.params;
        getTestingTaskInfo.TnaID = props.location.state.params.tnaid
        getTestingTaskInfo.type = item.typeInternorExtern;//For InterNal Or External
        getTestingTaskInfo.SkuID = sizeandqtyfordisplay;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
        getTestingTaskInfo.Isproduction = IsProduction;
        getTestingTaskInfo.TestingInspectionID = item.tnaTestingInspectionID;
        getTestingTaskInfo.page = getTestingReportPageView;//For InspectionType(Reinspection Or New inspection)
        getTestingTaskInfo.pageType = ActionType;//For HeaderName Add,view or edit
        getTestingTaskInfo.ParentTask = "TNAView";//Task Based back or save redirect function
        props.history.push(`/createTesting`, { params: getTestingTaskInfo });
    }
    const handleCloseTestingTaskFormPopup = (index, subIndex, childIndex) => {
        values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsTestingTaskFactory = 0;
        setInputFields(values);
    };
    async function CreateTesting(type, Inspectionpage) { // type=1=> internal, 2=> external

        let isvalid = true;
        if (isvalid) {
            let sizeandqtyfordisplay = "";
            let NewArr = [];
            NewArr = getTestinginspectionMulti.filter(x => x.isSelected === 1)
            NewArr.map((Purchaseorder) => {
                if (sizeandqtyfordisplay === '') {
                    sizeandqtyfordisplay = Purchaseorder.refInspecSKUID.toString();
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.refInspecSKUID
                }
            })
            if (getTestingReportPageView === "ReInspection") {
                let selectedInspIds = '';
                NewArr.map(x => {
                    if (x.isSelected === 1) {
                        if (selectedInspIds === '') {
                            if (!selectedInspIds.includes(x.tnaTestingInspectionID)) {
                                selectedInspIds = x.tnaTestingInspectionID.toString();
                            }
                        }
                        else {
                            if (!selectedInspIds.includes(x.tnaTestingInspectionID)) {
                                selectedInspIds = selectedInspIds + "," + x.tnaTestingInspectionID.toString();
                            }
                        }
                    }
                })

                let uniquevalues = [...new Map(NewArr.map(item =>
                    [item['skuName'], item])).values()];

                uniquevalues.map(x => {
                    let totqty = 0;
                    x.FinalInspectionIds = selectedInspIds;
                })
                uniquevalues.map(x => {
                    let totqty = 0;
                    x.refInspecSKUID = x.skuID;
                })

                getTestingTaskInfo.multiselecteddatas = uniquevalues;
                getTestingTaskInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                    ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
            }
            else {
                getTestingTaskInfo.multiselecteddatas = getTestinginspectionMulti;
            }
            getTestingTaskInfo.supplierID = props.location.state.params.supplierId;
            getTestingTaskInfo.multiselecteddatas = getTestinginspectionMulti;
            getTestingTaskInfo.quantityinfo = getTestingTaskListParam// For Binding Selected checkbox values in quantity inforamtion
            getTestingTaskInfo.details = props.location.state.params;
            getTestingTaskInfo.TnaID = props.location.state.params.tnaid
            getTestingTaskInfo.type = type;//For InterNal Or External
            getTestingTaskInfo.SkuID = sizeandqtyfordisplay;//For Multiple SKUID like(4123,4122) for Packing and PackingImage
            getTestingTaskInfo.Isproduction = IsProduction;
            getTestingTaskInfo.page = getTestingReportPageView;//For InspectionType(Reinspection Or New inspection)
            getTestingTaskInfo.pageType = 'AddInspection';//For HeaderName Add,view or edit
            getTestingTaskInfo.ParentTask = "TNAView";//Task Based back or save redirect function
            props.history.push(`/createTesting`, { params: getTestingTaskInfo });
        }
    }
    async function AddQCFactoryForm() {
        let isvalid = false;
        let Isvalid = false
        Isvalid = !!!getQCfinalinspectionMulti.find(x => x.isSelected === 1)
        if (Isvalid) {
            Nodify('Warning!', 'warning', 'Please check atleast one checkbox');
            return false;
        }
        await FinalinspectionService.GetTNAFinalinspecTestingReportList(4, props.location.state.params.buyerID, props.location.state.params.brandID).then((res) => {
            if (res.data[0] !== undefined) {
                if (res.data[0].offerQty) {
                    isvalid = true
                }
            } else {
                Nodify('Warning!', 'warning', 'Please enter tolerance in master for this buyer')
            }
        })
        if (isvalid && !Isvalid) {
            let sizeandqtyfordisplay = "";
            let NewArr = [];
            NewArr = getQCfinalinspectionMulti.filter(x => x.isSelected === 1)
            NewArr.map((Purchaseorder) => {
                if (sizeandqtyfordisplay === '') {
                    sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
                } else {
                    sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
                }
            })
            if (getQAInspectionPageView === "ReInspection") {
                let selectedInspIds = '';
                NewArr.map(x => {
                    if (x.isSelected === 1) {
                        if (selectedInspIds === '') {
                            if (!selectedInspIds.includes(x.tnaqcInspectionID)) {
                                selectedInspIds = x.tnaqcInspectionID.toString();
                            }
                        }
                        else {
                            if (!selectedInspIds.includes(x.tnaqcInspectionID)) {
                                selectedInspIds = selectedInspIds + "," + x.tnaqcInspectionID.toString();
                            }
                        }
                    }
                })

                let uniquevalues = [...new Map(NewArr.map(item =>
                    [item['skuName'], item])).values()];

                uniquevalues.map(x => {
                    let totqty = 0;
                    x.FinalInspectionIds = selectedInspIds;
                })
                uniquevalues.map(x => {
                    let totqty = 0;
                    x.refInspecSKUID = x.skuID;
                })

                getQCInspectionInfo.multiselecteddatas = uniquevalues;
                getQCInspectionInfo.FinalInspectionIds = uniquevalues[0].reInspectionAgainst != '' && uniquevalues[0].reInspectionAgainst != null
                    ? uniquevalues[0].reInspectionAgainst : uniquevalues[0].FinalInspectionIds;
            }
            else {
                getQCInspectionInfo.multiselecteddatas = getQCfinalinspectionMulti;
            }
            getQCInspectionInfo.quantityinfo = getAddQCInspectionListParam// For Binding Selected checkbox values in quantity inforamtion
            getQCInspectionInfo.details = props.location.state.params;
            getQCInspectionInfo.TnaID = props.location.state.params.tnaid
            getQCInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
            getQCInspectionInfo.Isproduction = IsProduction;
            getQCInspectionInfo.Action = 'Add';
            getQCInspectionInfo.ParentTask = "TNAView";
            getQCInspectionInfo.Mode = getQAInspectionPageView;
            getQCInspectionInfo.IsAddorEditReinspection = getQAInspectionPageView === 'AddInspection' ? 'Add Inspection' : 'Add ReInspection';
            props.history.push(`/AddQCInspection`, { params: getQCInspectionInfo });
        }
    }
    const AddTestingExFactoryForm = (index, subIndex, childIndex) => {

        let Multiselected = [];
        const Values = [...getTestinginspectionMulti]
        let isvalid = true;
        let isvalidED = true;
        isvalid = !!getTestinginspectionMulti.find(x => x.isSelected === 1)
        if (isvalid) {
            Multiselected = Values.concat(getTestinginspectionMulti.filter(x => x.isSelected === 1))
            //setMultiCloseDatas(Multiselected)
            // setfinalinspectionMulti(Multiselected)


        } else {
            Nodify('Warning!', 'warning', 'Please check atleast one checkbox');
            isvalidED = false;
            return false;
        }
        // }
        /////////////////temporary code////////////////-kokilapriya
        if (isvalidED) {
            setIsAddFinalInspection(true);
            values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenTestingConfirmationType = 1;
        }
        //props.history.push(`/ReportCreationMain`, { params: '' });
    };

    const OnSelectExFactoryRecords = (event, index) => {
        const value = [...MultExFactorySKUList];

        if (event.target.checked === true) {
            value[index].isSelected = 1;
        }
        else {
            value[index].isSelected = 0;

        }

        let isSelectAll = 0;
        if (value) {
            isSelectAll = !!!value.find(d => d.isSelected === 0);
            isSelectAll = isSelectAll ? 1 : 0;
        }
        setselectall(isSelectAll);

        setMultExFactorySKUList(value);
    }

    const pageRedirectForFinalInspection = (Page) => {

        setFinalInspectionPageView(Page);
        props.location.state.params.page = "ReInspection";
        props.location.state.params.ParentTask = "ReInspection";
        props.location.state.params.Mode = "ReInspection";
    }

    function FinalInspectionCallback(value) {

        setfinalinspectionMulti(value)
        props.location.state.params.page = "InspectionAdd";
        props.location.state.params.ParentTask = "TNAEdit";
        props.location.state.params.Mode = "InspectionAdd";
    }
    // #endregion -------------------  End Ex-Factory Form -------------------------------------


    /////////////////////////  End /////////////////////////


    // Copy the TNAEdit page and paste it the new window,have empty Probs
    if (props.location.state === undefined) { return <Redirect to="/TNAList" />; }
    // End

    return (

        <Fragment>
            <ReactNotification />
            <div> {
                isOpen && (
                    <Lightbox
                        mainSrc={isOpen}
                        alt="bg image"

                        onCloseRequest={
                            () => setIsOpen(false)
                        } />
                )
            } </div>

            <div className="row">

                <div className="col-xs-12 col-md-12">
                    <div className="widget">
                        <div className="widget-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="widget-body" style={{ paddingBottom: "0" }}>
                                        {

                                            <>
                                                <div className="">
                                                    <CommonStyle props={props} Style={getStyleInfo} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {props.Action === undefined ?
                                <div className="row" style={{ marginTop: "1rem" }}>
                                    <div className="col-xs-12 col-md-12">
                                        <div className="flat radius-bordered">
                                            <div className="widget-header heading_box_style">


                                                <h3 className="heading_style_common">View TNA</h3>
                                                <div className="buttons-preview" align="right">
                                                    <span className="btn btn-primary"
                                                        onClick={
                                                            () => ResetOperation("Back")
                                                        }
                                                    >
                                                        <i className="fa fa-arrow-circle-left"></i>
                                                        &nbsp;Back</span>

                                                    <span className="btn btn-danger"
                                                        onClick={
                                                            () => ResetOperation()
                                                        } >
                                                        <i className="fa fa-times"></i>
                                                        &nbsp;Reset</span>


                                                    <button type="button" className="btn btn-success"
                                                        onClick={UpdateTNA}
                                                    >
                                                        <i className="fa fa-check right"></i>
                                                        &nbsp;{isLastEdit ? 'Update' : 'Save'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>

                                        <div className="col-md-12" style={{ overflowX: "auto", minHeight: "100px" }}>
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "110%" }}>
                                                <thead>
                                                    <tr >
                                                        <th style={{ width: "11rem" }} className="fixed-column-header-first-tna"> #</th>
                                                        <th style={{ width: "52rem" }}>Task Type<span className="text-danger">*</span></th>
                                                        <th style={{ width: "145rem" }}>Task Name<span className="text-danger">*</span></th>
                                                        <th style={{ width: "13rem" }}>Duration</th>
                                                        <th style={{ width: "38rem" }}>Plan Start Date</th>
                                                        <th style={{ width: "50rem" }}>Plan End Date</th>
                                                        <th style={{ width: "50rem" }}>Actual End Date</th>
                                                        <th style={{ width: "20rem" }}>Dependency</th>
                                                        <th style={{ width: "101rem" }}>Task Holder / Follower</th>
                                                        <th style={{ width: "75rem" }}>Leave Days</th>
                                                        <th style={{ width: "68rem" }}>Status</th>
                                                        <th style={{ width: "80rem" }}>AWB & Status</th>
                                                        <th style={{ width: "140rem" }} className="text-center fixed-column-header-Route">Action</th>

                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {

                                                        inputFields.map((inputField, index) => (
                                                            <Fragment key={`${inputField}~${index}`}>

                                                                <tr class="parent" data-child={"parent_" + index}>
                                                                    <td className="fixed-column-first-tna">
                                                                        {index + 1}&nbsp;
                                                                        {
                                                                            !inputField.IsSingleTask && <span id={"btn-toggle_" + index} class="btn-toggle " aria-expanded="false" onClick={() => handleToggle(index)} aria-labelledby="btn-toggle id-master">
                                                                                <i id={"icon-toggle_" + index} class="fa fa-plus-square-o clsPointer clscollapseexpandTNA"></i>
                                                                            </span>
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        <span title={inputField.TaskTypeName} className='input-icon icon-right'>
                                                                            <Reactselect className="basic-single" name="TaskType"
                                                                                id={
                                                                                    "TaskType" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "TaskType")}
                                                                                onBlur={() => CheckDuplicate(index, 'TaskType')}
                                                                                value={getTaskTypeList.filter(function (option) {
                                                                                    return option.value === inputField.TaskType;
                                                                                })}
                                                                                options={getTaskTypeList}
                                                                                menuPosition="fixed"
                                                                                isDisabled={inputField.IsNewRow}
                                                                                styles={submitted && inputField.TaskType === 0 ? styles : ''}
                                                                            ></Reactselect>
                                                                            {/* {
                                                                                <span style={{ color: "blue", fontWeight: "bold" }}>{inputField.TaskTypeName}</span>
                                                                            } */}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span title={inputField.strTaskName} className='input-icon icon-right'>
                                                                            {/* <Reactselect className="basic-single" name="TaskName"
                                                                                id={
                                                                                    "TaskName" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "TaskName")}
                                                                                onBlur={() => CheckDuplicate(index, 'TaskName')}
                                                                                value={getAllTaskList.filter(function (option) {
                                                                                    return option.value === inputField.TaskID;
                                                                                })}
                                                                                options={inputField.TaskType === 0 ? getAllTaskList : getAllTaskList.filter(x => x.taskTypeID === inputField.TaskType)}
                                                                                isOptionDisabled={(option) => option.isdisabled}
                                                                                menuPosition="fixed"
                                                                                isDisabled={inputField.IsNewRow}
                                                                                styles={submitted && inputField.TaskID === 0 ? styles : ''}
                                                                            ></Reactselect> */}

                                                                            {
                                                                                <span style={{ color: "blue", fontWeight: "bold" }}>{inputField.strTaskName}</span>

                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            (inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3)) &&
                                                                            <span className='input-icon icon-right'>
                                                                                <input type="text"
                                                                                    placeholder="Duration"
                                                                                    className={'form-control' + (
                                                                                        submitted && !inputField.Duration ? ' is-invalid' : ''
                                                                                    )}

                                                                                    value={inputField.IDuration}
                                                                                    disabled
                                                                                />
                                                                                &nbsp;
                                                                            </span>

                                                                        }

                                                                        <span className='input-icon icon-right'>
                                                                            <input type="text"
                                                                                placeholder="Duration"
                                                                                id={"Duration" + index}
                                                                                name="Duration"
                                                                                value={inputField.Duration}
                                                                                onChange={event => handleChange(event, index, "Duration")}
                                                                                onBlur={() => CheckDuplicate(index, 'Duration')}
                                                                                maxLength="3"
                                                                                // readOnly={!inputField.IsSingleTask}
                                                                                className={'form-control' + (
                                                                                    submitted && !inputField.Duration ? ' is-invalid' : ''
                                                                                )}
                                                                                style={{ border: submitted && !inputField.Duration ? '1px solid red' : '' }}
                                                                                disabled={inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3) ? false : true}
                                                                            />
                                                                        </span>
                                                                    </td>

                                                                    <td>
                                                                        {
                                                                            (inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3)) &&
                                                                            <span className='input-icon icon-right'>
                                                                                <input type="text"
                                                                                    placeholder="Duration"
                                                                                    className={'form-control' + (
                                                                                        submitted && !inputField.IStartDate ? ' is-invalid' : ''
                                                                                    )}

                                                                                    value={inputField.IStartDate}
                                                                                    disabled
                                                                                />
                                                                                &nbsp;
                                                                            </span>
                                                                        }

                                                                        <span className="input-icon icon-right">
                                                                            <DatePicker className="form-control min_width_Date"
                                                                                name="StartDate"
                                                                                id={
                                                                                    "StartDate" + index
                                                                                }
                                                                                selected={
                                                                                    inputField.StartDate
                                                                                }
                                                                                dateFormat="dd/MM/yyyy"
                                                                                readOnly={true}
                                                                                disabled={inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3) ? false : true}

                                                                            />

                                                                        </span>
                                                                    </td>



                                                                    <td>
                                                                        {
                                                                            (inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3)) &&
                                                                            <span className='input-icon icon-right'>
                                                                                <input type="text"
                                                                                    placeholder="Duration"
                                                                                    className={'form-control' + (
                                                                                        submitted && !inputField.IEndDate ? ' is-invalid' : ''
                                                                                    )}

                                                                                    value={inputField.IEndDate}
                                                                                    disabled
                                                                                />
                                                                                &nbsp;
                                                                            </span>
                                                                        }
                                                                        <span className="input-icon icon-right">
                                                                            <DatePicker className="form-control min_width_Date"
                                                                                name="EndDate"
                                                                                id={
                                                                                    "EndDate" + index
                                                                                }
                                                                                selected={
                                                                                    inputField.EndDate
                                                                                }
                                                                                onChange={
                                                                                    event => handleChange(event, index, "EndDate")
                                                                                }
                                                                                dateFormat="dd/MM/yyyy"
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="scroll"
                                                                                autoComplete="off"
                                                                                popperProps={{
                                                                                    positionFixed: true // use this to make the popper position: fixed
                                                                                }}
                                                                                //readOnly={Buttonvisible === 'View' ? true : false}
                                                                                customInput={inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3) ? <CustomInput /> : ''}
                                                                                disabled={inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3) ? false : true}
                                                                            // readOnly={!inputField.IsSingleTask}
                                                                            />

                                                                        </span>
                                                                    </td>


                                                                    <td>
                                                                        <span className="input-icon icon-right">
                                                                            <DatePicker className="form-control min_width_Date"
                                                                                name="StrActualEndDate"
                                                                                id={
                                                                                    "StrActualEndDate" + index
                                                                                }
                                                                                // selected = {}
                                                                                selected={Date.parse(moment(inputField.StrActualEndDate, 'MM/DD/YYYY').toISOString())}

                                                                                dateFormat="dd/MM/yyyy"
                                                                                readOnly={true}
                                                                            //disabled={inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3) ? false : true}

                                                                            />


                                                                            {/* {
                                                                                inputField.StrActualEndDate
                                                                            } */}

                                                                        </span>
                                                                    </td>


                                                                    <td title={inputField.Dependency}>

                                                                        {
                                                                            inputField.Dependency
                                                                        }

                                                                    </td>
                                                                    <td >
                                                                        {/* <input type="text"
                                                                            value={inputField.TaskHolderOwnerTxtList}
                                                                            title={inputField.TaskHolderOwnerTxtList}
                                                                            onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                            className={'form-control'}
                                                                            disabled={true}
                                                                            //disabled={Buttonvisible === 'View' ? true : false}
                                                                            //style={{ width: "100%", float: "left" }}
                                                                            style={{ width: "100%", float: "left", border: submitted && !inputField.TaskHolderOwnerTxtList ? '1px solid red' : '' }}
                                                                        /> */}
                                                                        <i class="fa fa-eye" title="Task Holder" onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}></i>&nbsp;&nbsp;&nbsp;
                                                                        {
                                                                            <span>
                                                                                {
                                                                                    getTaskHolderList.filter(d => d.value === inputField.TaskHolderOrFollower).map(task => {
                                                                                        return task.label;
                                                                                    })
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {/* <input type="text"
                                                                            value={inputField.LeaveDaysTxt}
                                                                            title={inputField.LeaveDaysTxt}
                                                                            //disabled={Buttonvisible === 'View' ? true : false}
                                                                            onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                                            className={'form-control'}
                                                                            disabled={true}
                                                                            style={{ width: "100%", float: "left" }}
                                                                        /> */}
                                                                        {inputField.LeaveDaysTxt}
                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            {/* {inputField.StatusName} */}
                                                                            <Reactselect className="basic-single" name="StatusID"
                                                                                id={
                                                                                    "Status" + index
                                                                                }
                                                                                isLoading={false}
                                                                                isClearable={true}
                                                                                isSearchable={true}
                                                                                onChange={event => handleChange(event, index, "StatusID")}
                                                                                value={getTNAStatusList.filter(function (option) {
                                                                                    return option.value === inputField.StatusID;
                                                                                })}
                                                                                isDisabled={inputField.StatusID === 3 ? true : inputField.IsSingleTask === false ? true : false}
                                                                                options={getTNAStatusList}
                                                                                isOptionDisabled={(option) => option.isdisabled}
                                                                                // isDisabled={inputField.StatusID === 3 ? true : false}
                                                                                // options={getTNAStatusList}
                                                                                //  isDisabled={true}

                                                                                menuPosition="fixed"
                                                                            ></Reactselect>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className='input-icon icon-right'>
                                                                            <input type="text"
                                                                                placeholder="AWB & Status"
                                                                                id={"AWBStatus" + index}
                                                                                name="AWBStatus"
                                                                                value={inputField.AWBStatus}
                                                                                onChange={event => handleChange(event, index, "AWBStatus")}
                                                                                className={'form-control' + (
                                                                                    submitted && !inputField.AWBStatus ? ' is-invalid' : ''
                                                                                )}
                                                                            />
                                                                        </span>
                                                                    </td>

                                                                    <td className="fixed-column-Route">
                                                                        {inputField.IsDependencyOpen === 1 &&

                                                                            <Modal show={inputField.IsDependencyOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleDependencyClose(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        {/* Task Name : {inputField.strTaskName} */}
                                                                                        Dependency
                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: "50px" }}>#</th>
                                                                                                <th style={{ width: "450px" }}>Task Name</th>
                                                                                                <th style={{ width: "200px" }}>Dependency</th>
                                                                                                <th style={{ width: "150px" }}>Log Days</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                inputField.arrDependency && inputField.arrDependency.map((ChildField, childIndex) => (
                                                                                                    <Fragment key={
                                                                                                        `${ChildField}~${childIndex}`
                                                                                                    }>
                                                                                                        <tr>
                                                                                                            <td>{childIndex + 1}</td>
                                                                                                            <td>{ChildField.TNATaskName}</td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <Reactselect className="basic-single" name="DependencyID"
                                                                                                                        // id={
                                                                                                                        //     "DependencyID" + TaskIndex
                                                                                                                        // }
                                                                                                                        isLoading={false}
                                                                                                                        isClearable={true}
                                                                                                                        isSearchable={true}
                                                                                                                        onChange={event => handleChildChange(event, index, childIndex, "DependencyID")}
                                                                                                                        value={getDependencyList.filter(function (option) {
                                                                                                                            return option.value === ChildField.DependencyID;
                                                                                                                        })}
                                                                                                                        options={getDependencyList}
                                                                                                                        isDisabled={childIndex === index ? true : ChildField.TNATaskName === '' ? true : false}
                                                                                                                    ></Reactselect>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <span className='input-icon icon-right'>
                                                                                                                    <input type="text"
                                                                                                                        placeholder="    Log days"
                                                                                                                        id={"LogDays" + index}
                                                                                                                        name="LogDays"
                                                                                                                        value={ChildField.LogDays}
                                                                                                                        onChange={event => handleChildChange(event, index, childIndex, "LogDays")}
                                                                                                                        maxLength="3"
                                                                                                                        className={'form-control' + (
                                                                                                                            submitted && !inputField.LogDays ? ' is-invalid' : ''
                                                                                                                        )}
                                                                                                                        disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </Fragment>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="success" onClick={() => handleDependencyClose(index)}>
                                                                                        Save
                                                                                    </Button>

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }

                                                                        {inputField.IsTHLeaveDaysOpen === 1 &&
                                                                            <Modal show={inputField.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysClose(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        Leave Days / In-charges - {inputField.strTaskName}
                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label> Task Holder / Follower  </label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                                        id={"TaskHolderOrFollower"}
                                                                                                        isLoading={false}
                                                                                                        isClearable={false}
                                                                                                        isSearchable={true}
                                                                                                        value={getTaskHolderList.filter(function (option) {
                                                                                                            return option.value === inputField.TaskHolderOrFollower;
                                                                                                        })}
                                                                                                        menuPosition="fixed"
                                                                                                    //filterOption={filterOption}
                                                                                                    //isDisabled={true}
                                                                                                    ></Reactselect>

                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label>Leave Days</label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="LeaveDays"
                                                                                                        id={
                                                                                                            "LeaveDays"
                                                                                                        }
                                                                                                        isLoading={false}
                                                                                                        isClearable={false}
                                                                                                        isSearchable={true}
                                                                                                        value={getLeaveDaysList.filter(function (option) {
                                                                                                            return option.value === inputField.LeaveDays;
                                                                                                        })}
                                                                                                        //isDisabled={true}
                                                                                                        // options={getLeaveDaysList}
                                                                                                        // styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                                                        menuPosition="fixed"
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {inputField.titleEmployee1 && <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label htmlFor={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {inputField.titleEmployee1 + " Employee"}<span className="text-danger">*</span> </label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                        //isDisabled={true}
                                                                                                        isLoading={false}
                                                                                                        isClearable={false}
                                                                                                        isSearchable={true}
                                                                                                        isMulti
                                                                                                        //onChange={event => handleChange(event, index, inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                        //options={inputField.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                        value={inputField.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList && inputField.arrTaskHolderBuyer : inputField.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList && inputField.arrFollowerCube : drpTNATaskOwnerList.supplierEmployeeList && inputField.arrFollowerSupplier}
                                                                                                    >  </Reactselect>

                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    }

                                                                                    {
                                                                                        inputField.titleEmployee2 && <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div className='form-group'>
                                                                                                    <label htmlFor={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {inputField.titleEmployee2 + " Employee"}</label>
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <Reactselect className="basic-single" name={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                            //isDisabled={true}
                                                                                                            isLoading={false}
                                                                                                            isClearable={false}
                                                                                                            isSearchable={true}
                                                                                                            isMulti
                                                                                                            //onChange={event => handleChange(event, index, inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                            //options={inputField.titleEmployee2 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee2 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                            value={inputField.titleEmployee2 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee2 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                        // options={IMPORTANCE}
                                                                                                        // value={getStyleList.arrImportance}
                                                                                                        //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                        >  </Reactselect>

                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {inputField.titleEmployee3 && <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className='form-group'>
                                                                                                <label htmlFor="FollowerSupplier" >Follower - {inputField.titleEmployee3}</label>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                        isDisabled={false}
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        isMulti
                                                                                                        //onChange={event => handleChange(event, index, inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                        //options={inputField.titleEmployee3 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee3 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                        value={inputField.titleEmployee3 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee3 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                                    // options={IMPORTANCE}
                                                                                                    // value={getStyleList.arrImportance}
                                                                                                    //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                    >  </Reactselect>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    }

                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="danger" onClick={() => handleTHLeaveDaysClose(index)}>
                                                                                        <i class="fa fa-close"></i>&nbsp;Close
                                                                                    </Button>

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }

                                                                        {

                                                                            inputField.IsPopupOpen === 1 &&


                                                                            <Modal show={inputField.IsPopupOpen === 1} size="md" backdrop="static" keyboard={false} onHide={() => handleClose(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Additional Task</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>#</th>
                                                                                                <th>Task Name</th>
                                                                                                {/* <th> <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                    <label>
                                                                                                        <input type="checkbox"
                                                                                                            name="IsSelectAll"
                                                                                                            id={"IsSelectAll_" + index}
                                                                                                            isMulti
                                                                                                            onChange={event => handleChange(event, index, "IsSelectAll")}
                                                                                                            checked={inputField.IsSelectAll === 1 ? true : false}
                                                                                                            className="colored-blue clearOpacity"
                                                                                                            disabled={inputField.TaskFieldEnable === 0 ? true : false}
                                                                                                        />
                                                                                                        <span className="text"></span>
                                                                                                    </label>
                                                                                                </div></th> */}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                inputField.FollowupDetails && inputField.FollowupDetails.map((ChildField, childIndex) => (
                                                                                                    <Fragment key={
                                                                                                        `${inputField.FollowupDetails}~${childIndex}`
                                                                                                    }>
                                                                                                        <tr>
                                                                                                            <td>{childIndex + 1}</td>
                                                                                                            <td>
                                                                                                                <input type="text"
                                                                                                                    value={ChildField.AddtionalTaskName}
                                                                                                                    className={'form-control'}
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </td>
                                                                                                            {/* <td>
                                                                                                                <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                                    <label>
                                                                                                                        <input type="checkbox"
                                                                                                                            name="IsTaskSelected"
                                                                                                                            id={"IsTaskSelected_" + index + "_" + childIndex}
                                                                                                                            isMulti
                                                                                                                            onChange={event => handleAddTaskChange(event, index, childIndex, "IsTaskSelected")}
                                                                                                                            checked={ChildField.IsTaskSelected === 1 ? true : false}
                                                                                                                            className="colored-blue clearOpacity"
                                                                                                                            disabled={inputField.TaskFieldEnable === 0 ? true : false}
                                                                                                                        />
                                                                                                                        <span className="text"></span>
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </td> */}
                                                                                                        </tr>
                                                                                                    </Fragment>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>

                                                                                    {/* <Button disabled={inputField.TaskFieldEnable === 0 ? true : false} variant="success" onClick={() => handleClose(index)}> Save </Button> */}

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }

                                                                        {/* Gowtham */}
                                                                        {

                                                                            inputField.IsComment === 1 &&
                                                                            <Modal show={inputField.IsComment === 1} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleCloseComment(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Comments</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Comments<span className="text-danger">*</span></th>
                                                                                                <th style={{ width: '16%' }}>Date</th>
                                                                                                <th style={{ width: '10%' }}>Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                inputField.arrComment && inputField.arrComment.map((commentId, index) => {
                                                                                                    return (
                                                                                                        commentId.TNAComments !== '' ?
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    {commentId.TNAComments}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {commentId.Date && moment(commentId.Date).format('DD/MM/YYYY')}
                                                                                                                    {/* &nbsp;
                                                                                                                    {commentId.Date && commentId.Date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                                    {/* {commentId.Date} */}
                                                                                                                    {/* {commentId.Date && commentId.Date.toLocaleDateString('en-US')}
                                                                                                                    &nbsp;
                                                                                                                    {commentId.Date && commentId.Date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        <Fragment>
                                                                                                                            <span style={{ padding: '0px', display: index === (inputField.arrComment.length - 1) ? "" : "none", float: "" }}>

                                                                                                                                <span>
                                                                                                                                    <span className="btn btn-success" title="Edit Comment" onClick={() => EditComments(commentId.TaskIndexName, commentId.TNAComments, inputField.arrComment.length)}>
                                                                                                                                        <i className="fa fa-edit"></i>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </Fragment>
                                                                                                                    }
                                                                                                                    &nbsp;

                                                                                                                    {


                                                                                                                        <Fragment>
                                                                                                                            <span>
                                                                                                                                <span>
                                                                                                                                    <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveComments(commentId.TaskIndexName, index)}>
                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </Fragment>
                                                                                                                    }
                                                                                                                </td>
                                                                                                            </tr> : ''
                                                                                                    );
                                                                                                })

                                                                                            }
                                                                                            <tr>
                                                                                                <td colSpan="6">
                                                                                                    <textarea
                                                                                                        rows="3"
                                                                                                        placeholder="Enter comments here..."
                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                        maxLength="500" name="Comments"
                                                                                                        id={"Comments"}
                                                                                                        value={inputField.Comments}
                                                                                                        onChange={event => handleChangeTNAComments(event, index)}
                                                                                                        style={{ width: "100%", border: getComment && (inputField.Comments === undefined || inputField.Comments === '') ? '1px solid red' : '' }}>

                                                                                                    </textarea>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>

                                                                                    {/* <Button variant="success" onClick={() => handleSave(index, inputField.arrComment.length, inputField.Comments)}>
                                                                                    Save
                                                                                </Button> */}

                                                                                    <Button variant="success" onClick={() => handleSave(index, inputField.arrComment.length, inputField.Comments, inputField.TaskID)}> {/* , skuItem.PurOrdSkuCommentsID, skuItem.PurchaseOrderID */}
                                                                                        Save
                                                                                    </Button>

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                        {/* Sathish */}
                                                                        {

                                                                            inputField.IsAttachment === 1 &&
                                                                            <Modal show={inputField.IsAttachment === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleCloseAttachment(index)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title> <span className="widget-caption" style={{ fontSize: '14px' }}>File  Upload <span style={{ color: 'red', fontSize: '14px' }}>(Upload Document Type: Excel, Word, PDF and Image)</span></span></Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12 col-lg-12">

                                                                                            <div className="row">
                                                                                                <div className="col-sm-8 col-lg-8">
                                                                                                    <div className='form-group'>
                                                                                                        <tr>
                                                                                                            <td> <input type="file" id="TNAFileUpload" style={{ fontSize: '14px' }}
                                                                                                                onChange={(e) => AppendFiles(e)}
                                                                                                                accept=".jpg,.jpeg,.png,.pdf,.xlsx,.doc,.docx" ></input></td>
                                                                                                        </tr>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-lg-12 col-sm-12">
                                                                                                <div className="row no-margin">
                                                                                                    {
                                                                                                        getFiles.length !== 0 ?
                                                                                                            getFiles.map((files, fileindex) => (
                                                                                                                files.TaskIndexName === getTaskIndexFileupload &&
                                                                                                                <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                    <tr title={files.FileName}>
                                                                                                                        <td className="cut_textInTNA">
                                                                                                                            <span style={{ marginRight: '7px' }}>
                                                                                                                                {
                                                                                                                                    files.FileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                        files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                            <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                            files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                files.FileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                }
                                                                                                                            </span>  {files.FileName}
                                                                                                                        </td>
                                                                                                                        <td className="button_style_uploda">

                                                                                                                            {
                                                                                                                                files.FileType === "application/pdf" || files.FileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.FileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.FileType === 'application/msword' ?
                                                                                                                                    <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNARelatedDocs/' + files.FileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                    <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                        onClick={
                                                                                                                                            event => ViewFile(event, files.FileName)
                                                                                                                                        }></i>
                                                                                                                            }


                                                                                                                            &nbsp;

                                                                                                                            <p title='Delete File' onClick={(e) => RemoveFiles(e, fileindex)}
                                                                                                                                className="btn btn-danger btn-xs delete">
                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                            </p>


                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                    {


                                                                                                                        <div> {
                                                                                                                            isOpenimg && (

                                                                                                                                <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNARelatedDocs/' + getImagename}
                                                                                                                                    alt="bg image"
                                                                                                                                    onCloseRequest={
                                                                                                                                        () => setIsOpenimg(false)
                                                                                                                                    } />
                                                                                                                            )
                                                                                                                        } </div>
                                                                                                                    }
                                                                                                                </div>

                                                                                                            ))
                                                                                                            : ''
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>



                                                                                </Modal.Body>
                                                                                <Modal.Footer>

                                                                                    <Button variant="success" onClick={() => handleCloseAttachment(index)}> Save </Button>

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                        {

                                                                            inputField.IsManual === 1 &&

                                                                            <Modal show={inputField.IsManual === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManual(index, inputField.ManualComments, inputField.StatusID)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Manual Close: {inputField.strTaskName}&nbsp;</Modal.Title>
                                                                                    {
                                                                                        inputField.StatusID === 3 &&

                                                                                        <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete(index, 0, 0, 'Parent')}>
                                                                                            <i className="fa fa-trash-o"></i>
                                                                                        </button>
                                                                                    }
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Comments<span className="text-danger">*</span> </th>
                                                                                                <th>Close Date<span className="text-danger">*</span> </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            <tr>
                                                                                                <td style={{ width: "600px" }}>
                                                                                                    <textarea
                                                                                                        rows="3"
                                                                                                        placeholder="Enter Comments here..."
                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                        maxLength="500" name="ManualComments"
                                                                                                        id={"ManualComments"}
                                                                                                        value={inputField.ManualComments}
                                                                                                        disabled={inputField.StatusID === 3}
                                                                                                        onChange={event => handleChangeTNAComments(event, index)}
                                                                                                        style={{ width: "100%", border: getmaincomment && inputField.ManualComments === '' ? '1px solid red' : '' }}>

                                                                                                    </textarea>
                                                                                                </td>
                                                                                                <td >
                                                                                                    <DatePicker className="form-control" name="HolidayDate"
                                                                                                        id={
                                                                                                            "HolidayDate" + index
                                                                                                        }

                                                                                                        selected={
                                                                                                            inputField.ManualCloseDate
                                                                                                        }
                                                                                                        onChange={event => handleChangeTNAComments(event, index, "ManualCloseDate")}
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        peekNextMonth
                                                                                                        showMonthDropdown
                                                                                                        // showYearDropdown
                                                                                                        dropdownMode="scroll"
                                                                                                        autoComplete="off"
                                                                                                        minDate={new Date()}
                                                                                                        maxDate={new Date()}
                                                                                                        customInput={<CustomInput />}
                                                                                                        disabled={inputField.StatusID === 3 ? true : false}
                                                                                                    // disabled

                                                                                                    // isClearable={inputField.HolidayDate === '' ? false : true}
                                                                                                    />


                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <table className="table table-bordered">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Style Name</th>
                                                                                                <th>Style No</th>
                                                                                                <th>ID Po</th>
                                                                                                <th>Details</th>
                                                                                                <th>Task Holder / Follower</th>
                                                                                                <th>
                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                            onChange={event => (SelectAll(event))}
                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                            className="colored-blue clearOpacity" />
                                                                                                        <span className="text"></span>
                                                                                                    </label>
                                                                                                </th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                MultiCloseDatas.length !== 0 ?
                                                                                                    MultiCloseDatas.map((item, index) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <tr>
                                                                                                                    <td>{item.styleName}</td>
                                                                                                                    <td>{item.styleNo}</td>
                                                                                                                    <td>{item.idPoNo}</td>
                                                                                                                    <td>{item.details}</td>
                                                                                                                    <td>{item.taskOwnerNameList}</td>
                                                                                                                    <td className="Actionbutton fixed-column">
                                                                                                                        <div style={{ width: "80px" }}>
                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                <input type="checkbox" name="IsSelected"

                                                                                                                                    onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                <span className="text"></span>
                                                                                                                            </label>

                                                                                                                        </div>

                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </>

                                                                                                        )
                                                                                                    })
                                                                                                    :
                                                                                                    <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                            }

                                                                                        </tbody>
                                                                                    </table>


                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    {
                                                                                        inputField.StatusID !== 3 &&
                                                                                        <Button variant="success" onClick={() => handleManual(index, inputField.ManualComments)}> Save </Button>
                                                                                    }

                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        }
                                                                        {
                                                                            inputField.RevicePopup === 1 &&
                                                                            <Modal show={inputField.RevicePopup === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseRevicePopup(index, 0, 0, 'Main', 'Close')}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        Revise History
                                                                                        {
                                                                                            getSubReviceList.length !== 0 ?
                                                                                                <>
                                                                                                    <br />
                                                                                                    (Initial Date  Duration : {getSubReviceList[0].duration} Start Date : {getSubReviceList[0].startDate} End Date : {getSubReviceList[0].endDate})
                                                                                                </>
                                                                                                : ''

                                                                                        }

                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>

                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Comments<span className="text-danger">*</span></th>
                                                                                                {/* <th>Revice Date<span className="text-danger">*</span></th> */}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            <tr>
                                                                                                <td style={{ width: "600px" }} >
                                                                                                    <textarea
                                                                                                        rows="3"
                                                                                                        placeholder="Enter Comments here..."
                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                        maxLength="500" name="EnterRemarks"
                                                                                                        id={"EnterRemarks"}
                                                                                                        value={ReviceComment}
                                                                                                        onChange={event => handleChangeReviceComments(event, "Comment")}
                                                                                                        style={{ width: "100%", border: Submitrevice && ReviceComment === '' ? '1px solid red' : '' }}>



                                                                                                    </textarea>
                                                                                                </td>

                                                                                            </tr>
                                                                                        </tbody>

                                                                                    </table>
                                                                                    {
                                                                                        <table className="table table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Duration</th>
                                                                                                    <th>Start Date</th>
                                                                                                    <th>End Date</th>
                                                                                                    <th>Comments</th>
                                                                                                    <th>Reviced Date</th>
                                                                                                    <th>Reviced By</th>
                                                                                                    {/* <th>Action </th> */}
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    getSubReviceList.length !== 0 ?
                                                                                                        getSubReviceList.filter(r => r.isInitialData !== 1).map((item, subindex) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <tr>
                                                                                                                        <td style={{ width: "80px" }}>
                                                                                                                            {item.duration}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.startDate}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.endDate}

                                                                                                                        </td>
                                                                                                                        <td style={{ width: "300px" }}>
                                                                                                                            {item.comments}
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.createdDate}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.revicedBy}

                                                                                                                        </td>

                                                                                                                    </tr>
                                                                                                                </>

                                                                                                            )
                                                                                                        })
                                                                                                        :
                                                                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                }

                                                                                            </tbody>
                                                                                        </table>


                                                                                    }

                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="success" onClick={() => handleSaveRevice(index, 0, 0, props.location.state.params.tnaid,
                                                                                        inputField.TNARouteApplyID, inputField.TaskName, inputField.StartDate, inputField.EndDate, inputField.Duration, 'Main',
                                                                                        inputField.EndDateChange, ReviceComment)}>
                                                                                        Add
                                                                                    </Button>
                                                                                </Modal.Footer>
                                                                            </Modal>

                                                                        }

                                                                        {
                                                                            (inputField.ReschedulePopup === 1) &&
                                                                            <Modal show={(inputField.ReschedulePopup === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseReschedulePopup(index, 0, 0, 'Main', 'Close')}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        Reschedule History
                                                                                        {
                                                                                            getSubRescheduleList.length !== 0 ?
                                                                                                <>
                                                                                                    <br />
                                                                                                    (Initial Date  Duration : <b>{getSubRescheduleList[0].duration}</b> &nbsp; &nbsp; Start Date : <b>{getSubRescheduleList[0].startDate}</b> &nbsp; &nbsp; End Date : <b>{getSubRescheduleList[0].endDate}</b> )
                                                                                                </>
                                                                                                : ''

                                                                                        }

                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>

                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Comments<span className="text-danger">*</span></th>
                                                                                                {/* <th>Revice Date<span className="text-danger">*</span></th> */}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            <tr>
                                                                                                <td style={{ width: "600px" }} >
                                                                                                    <textarea
                                                                                                        rows="3"
                                                                                                        placeholder="Enter Comments here..."
                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                        maxLength="500" name="EnterRemarks"
                                                                                                        id={"EnterRemarks"}
                                                                                                        value={RescheduleComment}
                                                                                                        onChange={event => handleChangeRescheduleComments(event, "Comment")}
                                                                                                        style={{ width: "100%", border: Submitreschedule && RescheduleComment === '' ? '1px solid red' : '' }}>



                                                                                                    </textarea>
                                                                                                </td>

                                                                                            </tr>
                                                                                        </tbody>

                                                                                    </table>
                                                                                    {
                                                                                        <table className="table table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Duration</th>
                                                                                                    <th>Start Date</th>
                                                                                                    <th>End Date</th>
                                                                                                    <th>Comments</th>
                                                                                                    <th>Reschedule Date</th>
                                                                                                    <th>Reschedule By</th>
                                                                                                    {/* <th>Action </th> */}
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {

                                                                                                    getSubRescheduleList.length !== 0 ?
                                                                                                        getSubRescheduleList.filter(r => r.isInitialData !== 1).map((item, subindex) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <tr>
                                                                                                                        <td style={{ width: "80px" }}>
                                                                                                                            {item.duration}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.startDate}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.endDate}

                                                                                                                        </td>
                                                                                                                        <td style={{ width: "300px" }}>
                                                                                                                            {item.comments}
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.createdDate}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.rescheduleBy}

                                                                                                                        </td>

                                                                                                                    </tr>
                                                                                                                </>

                                                                                                            )
                                                                                                        })
                                                                                                        :
                                                                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                }

                                                                                            </tbody>
                                                                                        </table>


                                                                                    }

                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    {
                                                                                        inputField.StatusID === 3 &&
                                                                                        <Button variant="success" onClick={() => handleSaveReschedule(index, 0, 0, props.location.state.params.tnaid,
                                                                                            inputField.TNARouteApplyID, inputField.TaskName, inputField.StartDate, inputField.EndDate, inputField.Duration, 'Main',
                                                                                            inputField.EndDateChange, RescheduleComment)}>
                                                                                            {/* {getReviceAction === 'Add' ? 'Save' : 'Update'} */}
                                                                                            Add
                                                                                        </Button>
                                                                                    }

                                                                                </Modal.Footer>
                                                                            </Modal>

                                                                        }

                                                                        {
                                                                            inputField.IsDependencyStartPopup && inputField.IsSingleTask &&
                                                                            <Modal show={inputField.IsDependencyStartPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseDependencyStartPopup(index, 0, 0, inputField.IsSingleTask)}>
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>
                                                                                        Dependency Start / Stop History

                                                                                    </Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>

                                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Dependencytable">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Date<span className="text-danger">*</span></th>
                                                                                                <th>Comments<span className="text-danger">*</span></th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            <tr>
                                                                                                <td >
                                                                                                    {
                                                                                                        <DatePicker className="form-control" name="DependencyDate"


                                                                                                            // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                                                                            selected={Date.parse(moment(inputField.DependencyDate, 'MM/DD/YYYY').toISOString())}
                                                                                                            onChange={event => handleChangeDependentStart(index, 0, 0, event, "CommentDate", true)}
                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                            peekNextMonth
                                                                                                            showMonthDropdown
                                                                                                            dropdownMode="scroll"
                                                                                                            autoComplete="off"
                                                                                                            minDate={new Date()}
                                                                                                            readOnly={inputField.intDependency === 1 ? true : false}
                                                                                                            customInput={<CustomInputDependency />}
                                                                                                            maxDate={new Date()}

                                                                                                        />


                                                                                                    }

                                                                                                </td>
                                                                                                <td style={{ width: "600px" }} >
                                                                                                    <textarea
                                                                                                        rows="3"
                                                                                                        placeholder="Enter Comments here..."
                                                                                                        autoFocus onFocus={""} autoComplete="off"
                                                                                                        maxLength="500" name="EnterRemarks"
                                                                                                        value={inputField.DependencyComment}
                                                                                                        onChange={event => handleChangeDependentStart(index, 0, 0, event, "Comment", true)}
                                                                                                        style={{ width: "100%", border: submitDependencyStart && !inputField.DependencyComment ? '1px solid red' : '' }}>
                                                                                                    </textarea>
                                                                                                </td>

                                                                                            </tr>
                                                                                        </tbody>

                                                                                    </table>
                                                                                    {
                                                                                        <table className="table table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Action</th>
                                                                                                    <th>Date</th>
                                                                                                    <th>Comments</th>
                                                                                                    <th>Created By</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    getDependencyStartList && getDependencyStartList.length !== 0 ?
                                                                                                        getDependencyStartList.map((item, itemIndex) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <tr style={{ border: itemIndex === 0 ? "1px solid red" : "" }}>
                                                                                                                        <td>
                                                                                                                            {item.dependencyStartstop === 1 ? "Stop" : "Start"}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.dependencyDate}

                                                                                                                        </td>

                                                                                                                        <td>
                                                                                                                            {item.comments}

                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            {item.createdBy}

                                                                                                                        </td>


                                                                                                                    </tr>
                                                                                                                </>

                                                                                                            )
                                                                                                        })
                                                                                                        :
                                                                                                        <tr><td colSpan="4" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                }

                                                                                            </tbody>
                                                                                        </table>


                                                                                    }

                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button variant="success" onClick={() => handleSaveDependencyStart(index, 0, 0, inputField.IsSingleTask, inputField.intDependency)}>

                                                                                        Save
                                                                                    </Button>
                                                                                </Modal.Footer>
                                                                            </Modal>

                                                                        }


                                                                        <button type="button" className="btn btn-xs btn-primary mb-2 " title={(getHeaderName === "Edit" ? "Edit" : getHeaderName === "Export" ? "Add" : "View") + " Additional Task"}
                                                                            onClick={() => handleFollowup(index)}>
                                                                            <b> {inputField.AdditionalFollowup} </b>
                                                                            <i className="fa fa-tasks"></i>
                                                                        </button>

                                                                        &nbsp;


                                                                        <button type="button" className="btn btn-xs btn-magenta mb-2 " title="Comments" onClick={() => handleComment(index, inputField.CommentsCount, props.location.state.params.tnaid, inputField.TaskID)}>
                                                                            <b> {inputField.CommentsCount} </b>
                                                                            <i className="fa fa-comment"></i>
                                                                        </button>
                                                                        &nbsp;

                                                                        <button type="button" className="btn btn-xs btn-blue mb-2 " title="Attachments" onClick={() => handleAttachment(index)}
                                                                        >
                                                                            <b> {inputField.AttachmentCount} </b>
                                                                            <i className="fa fa-upload"></i>
                                                                        </button>
                                                                        &nbsp;

                                                                        &nbsp;
                                                                        {inputField.IsSingleTask && inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false &&

                                                                            <button type="button" className={inputField.intDependency === 1 ? "btn btn-xs btn-success mb-2 " : "btn btn-xs btn-danger mb-2 "} title="Start and Stop(Dependency)"
                                                                                onClick={() => OpenDependentStartPopup(index, 0, 0, inputField.intDependency, props.location.state.params.tnaid, inputField.TaskID, 0, inputField.IsSingleTask)}
                                                                            >
                                                                                <i className={inputField.intDependency === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                            </button>
                                                                        }
                                                                        &nbsp;
                                                                        {
                                                                            // new Date() > new Date(childTask.EndDate) &&
                                                                            (inputField.StatusID !== 3 && inputField.IsSingleTask) && //inputField.ManualComments === '' &&
                                                                            <button type="button" className={parseInt(inputField.IsReviced) === 0 ? "btn btn-xs btn-yellow mb-2 " : "btn btn-xs btn-success mb-2 "} title='Revise'
                                                                                onClick={() => OpenRevicePopup(index, 0, 0, props.location.state.params.tnaid,
                                                                                    inputField.TNARouteApplyID, inputField.TaskName, inputField.StartDate, inputField.EndDate, inputField.Duration, 'Main', inputField.EndDateChange)}
                                                                            >
                                                                                <i className="fa fa-retweet"></i>
                                                                            </button>
                                                                        }
                                                                        &nbsp;
                                                                        {
                                                                            // new Date() > new Date(childTask.EndDate) &&
                                                                            ((inputField.StatusID === 3 || inputField.IsRescheduled === 1) && inputField.IsSingleTask) && //inputField.ManualComments === '' &&
                                                                            <button type="button" className={inputField.IsRescheduled === 0 ? "btn btn-xs btn-light mb-2 " : "btn btn-xs btn-success mb-2 "} title='Reschedule'
                                                                                onClick={() => OpenReschedulePopup(index, 0, 0, props.location.state.params.tnaid,
                                                                                    inputField.TNARouteApplyID, inputField.TaskName, inputField.StartDate, inputField.EndDate, inputField.Duration, 'Main')}
                                                                            >
                                                                                <i class="fa fa-calendar"></i>
                                                                            </button>
                                                                        }
                                                                        &nbsp;

                                                                        {isLastEdit && inputField.IsSingleTask ?

                                                                            <Fragment>&nbsp;<button type="button" className={inputField.ManualComments === '' || inputField.ManualComments === null ? "btn btn-xs btn-darkorange mb-2 " : "btn btn-xs btn-success mb-2 "} title="Manual Close"
                                                                                onClick={() => handleManualclose(index, inputField.TNARouteApplyID, inputField.StatusID)}
                                                                            >
                                                                                {/* disabled={inputField.StatusID === 3 ? true : false} */}
                                                                                {/* <b>{inputField.AdditionalManual} </b> */}
                                                                                <i className="fa fa-user"></i>&nbsp;
                                                                                <i className="fa fa-close"></i>
                                                                            </button>
                                                                            </Fragment>
                                                                            : ''
                                                                        }

                                                                        {/* {inputField.IsSingleTask &&
                                                                           <Fragment>&nbsp;<button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                            // onClick={() => handleAttachment(index)}
                                                                            >
                                                                                <i className="fa fa-file-text-o"></i>
                                                                            </button>
                                                                        </Fragment>
                                                                        } */}



                                                                        {/* <span title="TNA Closed" onClick={() => OpenProtoFitSamplepopup(1, 1)} class="btn btn-info btn-xs edit"><i class="fa fa-edit">ProtoFitSample Sub</i></span> */}
                                                                        {/* <span title="TNA Closed" onClick={() => OpenLabdipSubmssionpopup(1, 2, 48)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> LapDip Sub</i></span>
                                                                        <span title="TNA Closed" onClick={() => OpenStrikeoffSubmssionpopup(1, 1)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Strikeoff Sub</i></span>
                                                                        <span title="TNA Closed" onClick={() => OpenStrikeoffApprovalpopup(1)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Approval</i></span>
                                                                        <span title="TNA Closed" onClick={() => OpenTrimSubmssionpopup(1, 1)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Trim Sub</i></span>
                                                                        <span title="TNA Closed" onClick={() => OpenProtoFitSamplepopup(1, 1)} class="btn btn-info btn-xs edit"><i class="fa fa-edit">ProtoFitSample Sub</i></span> */}
                                                                        {/* <span title="TNA Closed" onClick={() => OpenLabdipSubmssionpopup()} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> LapDip Sub</i></span>
                                                                        &nbsp;
                                                                        <span title="TNA Closed" onClick={() => OpenLabdipApprovalpopup()} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Approval</i></span>

                                                                        &nbsp;
                                                                        <span title="TNA Closed" onClick={() => OpenStrikeoffApprovalpopup()} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Approval</i></span>
                                                                        <span title="TNA Closed" onClick={() => OpenTrimSubmssionpopup()} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Trim Sub</i></span>
                                                                        &nbsp;
                                                                        <span title="TNA Closed" onClick={() => OpenTrimApprovalpopup()} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Approval</i></span>
                                                                        <span title="TNA Closed" onClick={() => setSampleSubModalpopup(true)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Sample Sub</i></span>
                                                                        &nbsp;
                                                                        <span title="TNA Closed" onClick={() => setSampleAppModalpopup(true)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Approval</i></span>
                                                                        <span title="TNA Closed" onClick={() => setApperancemodalpopup(true)} class="btn btn-info btn-xs edit"><i class="fa fa-edit"> Wash Sub</i></span> */}
                                                                    </td>
                                                                </tr>
                                                                {

                                                                    inputField.SubTaskList && inputField.SubTaskList.map((subTask, subIndex) => (

                                                                        <Fragment key={
                                                                            `${subTask}~${subIndex}`
                                                                        }>

                                                                            <tr class={"parentChild parent_" + index + " hidden"} data-child={"parentChild_" + index + "_" + subIndex} >
                                                                                <td className="fixed-column-first-tna">
                                                                                    <span id={"btn-toggle_parentChild_" + index + "_" + subIndex} class="btn-toggle " aria-expanded={subTask.IsExpanded} onClick={() => handleChildToggle(index, subIndex)} >
                                                                                        <i id={"icon-toggle_parentChild_" + index + "_" + subIndex} class={subTask.IsExpanded ? "fa fa-minus-square-o clsPointer clscollapseexpand-child" : "fa fa-plus-square-o clsPointer clscollapseexpand-child"}></i>
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        subTask.MainId > 0 ? <span> {subTask.Code} </span> : <span style={{ color: "blue" }}> {inputField.strTaskName} </span>
                                                                                    }

                                                                                </td>
                                                                                <td colSpan="10">
                                                                                    {subTask.MainId > 0 &&
                                                                                        (subTask.ColorOrName === "" || subTask.ColorOrName === null) ?
                                                                                        <div className="trwidth_manual" style={{ textAlign: "center" }}>
                                                                                            <img className="width_manual"
                                                                                                src={subTask.ImagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.ImageUrl + subTask.ImagePath}
                                                                                                alt="Logo"
                                                                                                onClick={
                                                                                                    () => setIsOpen(subTask.ImagePath === null ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + subTask.ImageUrl + subTask.ImagePath)
                                                                                                }
                                                                                            />
                                                                                        </div> :
                                                                                        <div style={{ background: subTask.ColorOrName, width: '120px', height: '25px', marginLeft: '20px', borderRadius: '2px', display: 'inline-block' }}> </div>

                                                                                    }


                                                                                </td>

                                                                                <td className="fixed-column-Route"></td>

                                                                            </tr>

                                                                            {
                                                                                subTask.ChildTaskList && subTask.ChildTaskList.map((childTask, childIndex) => (

                                                                                    <Fragment key={
                                                                                        `${childTask}~${childIndex}`
                                                                                    }>
                                                                                        <tr class={subTask.IsExpanded ? "child_" + index + " parentChild_" + index + "_" + subIndex + " " : "child_" + index + " parentChild_" + index + "_" + subIndex + " hidden"} >

                                                                                            <td className="fixed-column-first-tna"><b>{(index + 1)}</b>  {"-" + childTask.ChildIndex}</td>
                                                                                            <td colSpan="2">
                                                                                                {
                                                                                                    inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER || inputField.strTaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                        inputField.strTaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION || inputField.strTaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL
                                                                                                        ?
                                                                                                        <>

                                                                                                            <i class="fa fa-eye" title="SKU_Size Details" onClick={() => handleSkuAndStyleDetails(childTask.SubId, childTask.MainId, childTask.StrikeOffOrTrimInfoId, inputField.strTaskName, childTask.SKUIdList, childTask.StyleStrikeOffGarmentSizeIdList, childTask)}></i>
                                                                                                            <span>{" " + childTask.Name}</span>
                                                                                                        </>

                                                                                                        : childTask.Name
                                                                                                }</td>


                                                                                            <td>

                                                                                                {
                                                                                                    (childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3)) &&
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <input type="text"
                                                                                                            placeholder="Duration"
                                                                                                            // id={"Duration" + index}
                                                                                                            // name="Duration"
                                                                                                            value={childTask.IDuration}
                                                                                                            onChange={event => handleChangeChild(event, index, subIndex, childIndex, "Duration")}
                                                                                                            onBlur={() => CheckChildDuplicate(index, subIndex, childIndex, 'Duration')}
                                                                                                            maxLength="3"
                                                                                                            className={'form-control' + (
                                                                                                                submitted && !childTask.Duration ? ' is-invalid' : ''
                                                                                                            )}
                                                                                                            style={{ border: submitted && !childTask.Duration ? '1px solid red' : '' }}
                                                                                                            disabled
                                                                                                        />
                                                                                                        &nbsp;
                                                                                                    </span>

                                                                                                }


                                                                                                <span className='input-icon icon-right'>
                                                                                                    <input type="text"
                                                                                                        placeholder="Duration"
                                                                                                        // id={"Duration" + index}
                                                                                                        // name="Duration"
                                                                                                        value={childTask.Duration}
                                                                                                        onChange={event => handleChangeChild(event, index, subIndex, childIndex, "Duration")}
                                                                                                        onBlur={() => CheckChildDuplicate(index, subIndex, childIndex, 'Duration')}
                                                                                                        maxLength="3"
                                                                                                        className={'form-control' + (
                                                                                                            submitted && !childTask.Duration ? ' is-invalid' : ''
                                                                                                        )}
                                                                                                        style={{ border: submitted && !childTask.Duration ? '1px solid red' : '' }}
                                                                                                        disabled={childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3) ? false : true}
                                                                                                    />
                                                                                                </span>
                                                                                            </td>

                                                                                            <td>

                                                                                                {
                                                                                                    (childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3)) &&
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <DatePicker className="form-control min_width_Date"
                                                                                                            name="ChildStartdate"
                                                                                                            id={
                                                                                                                "ChildStartdate" + index + '_' + subIndex + '_' + childIndex
                                                                                                            }
                                                                                                            selected={Date.parse(moment(childTask.IStartDate, 'MM/DD/YYYY').toISOString())}
                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                            readOnly={true}
                                                                                                            disabled

                                                                                                        />
                                                                                                        &nbsp;
                                                                                                    </span>
                                                                                                }

                                                                                                <span className="input-icon icon-right">
                                                                                                    <DatePicker className="form-control min_width_Date"
                                                                                                        name="ChildStartdate"
                                                                                                        id={
                                                                                                            "ChildStartdate" + index + '_' + subIndex + '_' + childIndex
                                                                                                        }
                                                                                                        selected={Date.parse(moment(childTask.StartDate, 'MM/DD/YYYY').toISOString())}
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        readOnly={true}


                                                                                                    />

                                                                                                </span>
                                                                                            </td>

                                                                                            <td>

                                                                                                {
                                                                                                    (childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3)) &&
                                                                                                    <span className='input-icon icon-right'>
                                                                                                        <DatePicker className="form-control min_width_Date"
                                                                                                            name="ChildStartdate"
                                                                                                            id={
                                                                                                                "ChildStartdate" + index + '_' + subIndex + '_' + childIndex
                                                                                                            }
                                                                                                            selected={Date.parse(moment(childTask.IEndDate, 'MM/DD/YYYY').toISOString())}
                                                                                                            dateFormat="dd/MM/yyyy"
                                                                                                            readOnly={true}
                                                                                                            disabled

                                                                                                        />
                                                                                                        &nbsp;
                                                                                                    </span>
                                                                                                }


                                                                                                <span className="input-icon icon-right">
                                                                                                    <DatePicker className="form-control min_width_Date"
                                                                                                        name="ChildEnddate"
                                                                                                        id={
                                                                                                            "ChildEnddate" + index + '_' + subIndex + '_' + childIndex
                                                                                                        }

                                                                                                        selected={Date.parse(moment(childTask.EndDate, 'DD/MM/YYYY').toISOString())}
                                                                                                        onChange={
                                                                                                            event => handleChangeChild(event, index, subIndex, childIndex, "EndDate")
                                                                                                        }
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        peekNextMonth
                                                                                                        showMonthDropdown
                                                                                                        showYearDropdown
                                                                                                        dropdownMode="scroll"
                                                                                                        autoComplete="off"
                                                                                                        popperProps={{
                                                                                                            positionFixed: true // use this to make the popper position: fixed
                                                                                                        }}
                                                                                                        //readOnly={Buttonvisible === 'View' ? true : false}
                                                                                                        customInput={childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3) ? <CustomInput /> : ''}
                                                                                                        disabled={childTask.ReviceSelected === 1 || (childTask.RescheduleSelected === 1 && childTask.StatusID === 3) ? false : true}
                                                                                                    />
                                                                                                    {/* {
                                                                                                        childTask.TotalHoliday
                                                                                                    } */}

                                                                                                </span>
                                                                                            </td>


                                                                                            <td>

                                                                                                <span className="input-icon icon-right">
                                                                                                    {/* <DatePicker className="form-control"
                                                                                                        name="ChildActualEndDate"
                                                                                                        id={
                                                                                                            "ChildActualEndDate" + index + '_' + subIndex + '_' + childIndex
                                                                                                        }
                                                                                                        selected={Date.parse(moment(childTask.ChildActualEndDate, 'MM/DD/YYYY').toISOString())}
                                                                                                        dateFormat="MM/dd/yyyy"
                                                                                                        readOnly={true}

                                                                                                    /> */}
                                                                                                    {
                                                                                                        childTask.StrActualEndDate === "Manual" ?


                                                                                                            <p><a style={{ cursor: "pointer" }} onClick={event => showManualApproval(index, subIndex, childIndex,
                                                                                                                childTask.SubId, childTask.TaskName)}>
                                                                                                                {childTask.StrActualEndDate}

                                                                                                            </a></p> :
                                                                                                            childTask.StrActualEndDate === "Existing" ?
                                                                                                                <p><a style={{ cursor: "pointer" }} onClick={event => showExistingApproval(index, subIndex, childIndex,
                                                                                                                    childTask.SubId, childTask.TaskName)}>
                                                                                                                    {childTask.StrActualEndDate}


                                                                                                                </a></p> :
                                                                                                                <DatePicker className="form-control min_width_Date"
                                                                                                                    name="StrActualEndDate"
                                                                                                                    id={
                                                                                                                        "StrActualEndDate" + index
                                                                                                                    }
                                                                                                                    selected={Date.parse(moment(childTask.StrActualEndDate, 'MM/DD/YYYY').toISOString())}

                                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                                    readOnly={true}
                                                                                                                //disabled={inputField.ReviceSelected === 1 || (inputField.RescheduleSelected === 1 && inputField.StatusID === 3) ? false : true}

                                                                                                                />
                                                                                                        // childTask.StrActualEndDate
                                                                                                    }

                                                                                                    {
                                                                                                        childTask.ManualApprovalType === 1 && (
                                                                                                            <>
                                                                                                                <Modal show={childTask.ManualApprovalType} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideManualApproval(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Manual Approval Information
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>

                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">

                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th style={{ width: "300px" }}>Comments<span className="text-danger">*</span></th>
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>

                                                                                                                                <tr>
                                                                                                                                    <td >
                                                                                                                                        <textarea
                                                                                                                                            rows="3"
                                                                                                                                            placeholder="Enter Comments here..."
                                                                                                                                            autoFocus onFocus={""} autoComplete="off"
                                                                                                                                            maxLength="500" name="EnterRemarks"
                                                                                                                                            id={"EnterRemarks"}
                                                                                                                                            value={manualApprovalData.manualCloseComment}
                                                                                                                                            style={{ width: "100%" }}
                                                                                                                                        >
                                                                                                                                        </textarea>
                                                                                                                                    </td>

                                                                                                                                </tr>

                                                                                                                                <tr>
                                                                                                                                    <td colSpan={2}>
                                                                                                                                        <table className="table table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                            <tbody>  <tr>

                                                                                                                                                <div className="col-lg-12 col-sm-12">
                                                                                                                                                    <div className="row no-margin">
                                                                                                                                                        {
                                                                                                                                                            manualApprovalData.files && manualApprovalData.files.length !== 0 ?
                                                                                                                                                                manualApprovalData.files.map((files, fileindex) => (
                                                                                                                                                                    <> {
                                                                                                                                                                        <div className="col-lg-4 col-sm-4 file_list_upload">
                                                                                                                                                                            <tr title={files.fileName}>
                                                                                                                                                                                <td className="cut_textInTNA">
                                                                                                                                                                                    <span style={{ marginRight: '7px' }}>
                                                                                                                                                                                        {
                                                                                                                                                                                            files.fileType === "application/pdf" ? <i class="fa fa-file-pdf-o fa-lg pdf_color"></i> :
                                                                                                                                                                                                files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                                                                                                                                                                    <i class="fa fa-file-excel-o fa-lg excel_icon"></i> :
                                                                                                                                                                                                    files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                                                                                                                                                                                                        files.fileType === 'application/msword' ? <i class="fa fa-file-word-o word_icon" aria-hidden="true"></i> :
                                                                                                                                                                                                        <i class="fa fa-picture-o fa-lg image_icon_style"></i>
                                                                                                                                                                                        }
                                                                                                                                                                                    </span>  {files.fileName}
                                                                                                                                                                                </td>

                                                                                                                                                                                <td className="button_style_uploda">

                                                                                                                                                                                    &nbsp;
                                                                                                                                                                                    {
                                                                                                                                                                                        files.FileType === "application/pdf" || files.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || files.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || files.fileType === 'application/msword' ?
                                                                                                                                                                                            <a type="button" title="Download" class="btn btn-xs btn-primary" target="_blank" href={window.$APIBaseURL + 'Images/TNAImages/' + files.fileName} download><i class="fa fa-download"></i> </a> :
                                                                                                                                                                                            <i className="btn btn-info fa fa-eye" title="View Logo"
                                                                                                                                                                                                onClick={
                                                                                                                                                                                                    event => ViewFile(event, files.fileName)
                                                                                                                                                                                                }></i>
                                                                                                                                                                                    }
                                                                                                                                                                                </td>
                                                                                                                                                                            </tr>



                                                                                                                                                                            {

                                                                                                                                                                                <div> {
                                                                                                                                                                                    isOpenimg && (

                                                                                                                                                                                        <Lightbox mainSrc={window.$APIBaseURL + 'Images/TNAImages/' + getImagename}
                                                                                                                                                                                            alt="bg image"
                                                                                                                                                                                            onCloseRequest={
                                                                                                                                                                                                () => setIsOpenimg(false)
                                                                                                                                                                                            } />
                                                                                                                                                                                    )
                                                                                                                                                                                } </div>
                                                                                                                                                                            }
                                                                                                                                                                        </div>
                                                                                                                                                                    }
                                                                                                                                                                    </>
                                                                                                                                                                ))
                                                                                                                                                                : <div style={{ textAlign: 'center', color: '#df5138' }}>
                                                                                                                                                                    <span>No Attachments Found</span>
                                                                                                                                                                </div>
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </tr>
                                                                                                                                            </tbody>


                                                                                                                                        </table>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </Modal.Body>

                                                                                                                </Modal>
                                                                                                            </>
                                                                                                        )
                                                                                                    }

                                                                                                    {
                                                                                                        childTask.ExistingApprovalType === 1 ?
                                                                                                            <Modal show={childTask.ExistingApprovalType === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideExistingApproval(index, subIndex, childIndex)}>
                                                                                                                <Modal.Header closeButton>
                                                                                                                    <Modal.Title>
                                                                                                                        Existing Approval Information
                                                                                                                    </Modal.Title>
                                                                                                                </Modal.Header>
                                                                                                                <Modal.Body>

                                                                                                                    <table className="table table-bordered">
                                                                                                                        <thead>
                                                                                                                            <tr>
                                                                                                                                <th>Season Name</th>
                                                                                                                                <th>Approval Date</th>
                                                                                                                                <th>Details</th>
                                                                                                                                <th>Approved By</th>


                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            {
                                                                                                                                existingApprovalData && existingApprovalData.length !== 0 ?
                                                                                                                                    <>

                                                                                                                                        <tr>
                                                                                                                                            <td>{existingApprovalData[0].seasonName}</td>
                                                                                                                                            <td>{existingApprovalData[0].approvalDate}</td>
                                                                                                                                            <td>{existingApprovalData[0].details}</td>
                                                                                                                                            <td>{existingApprovalData[0].approvedBy}</td>

                                                                                                                                        </tr>
                                                                                                                                    </> :
                                                                                                                                    <tr>
                                                                                                                                        <td colSpan={4}>
                                                                                                                                            <div className="text-danger" align="center"> <span>No Records Found</span></div>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                            }

                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </Modal.Body>

                                                                                                            </Modal> : ''
                                                                                                    }

                                                                                                </span>
                                                                                            </td>

                                                                                            <td>
                                                                                                {/* {index === 0 ? '' : <input type="text"
                                                                                                    value={inputField.SelectedDependency}
                                                                                                    title={inputField.SelectedDependency}
                                                                                                    //onClick={() => handleDependency(index,subIndex, childIndex, false)}
                                                                                                    className={'form-control'}
                                                                                                    readOnly={true}
                                                                                                    style={{ width: "100%", float: "left" }}
                                                                                                />
                                                                                                } */}
                                                                                                {
                                                                                                    inputField.DetailTypeID !== 4 ?
                                                                                                        childTask.SelectedDependency : childTask.SelectedDependency + childTask.AutoText
                                                                                                }


                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    <span>
                                                                                                        {
                                                                                                            getTaskHolderList.filter(d => d.value === inputField.TaskHolderOrFollower).map(task => {
                                                                                                                return task.label;
                                                                                                            })
                                                                                                        }
                                                                                                    </span>
                                                                                                }


                                                                                            </td>
                                                                                            <td>
                                                                                                {/* <input type="text"
                                                                                                    value={inputField.LeaveDaysTxt}
                                                                                                    title={inputField.LeaveDaysTxt}
                                                                                                    //onClick={() => handleTaskHolderLeaveDaysChild(index, subIndex, childIndex, childTask.IsTHFirstTime)}
                                                                                                    className={'form-control'}
                                                                                                    readOnly={true}
                                                                                                    style={{ width: "100%", float: "left" }}
                                                                                                /> */}
                                                                                                {
                                                                                                    childTask.LeaveDaysTxt
                                                                                                }

                                                                                            </td>
                                                                                            <td>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="StatusID"
                                                                                                        // id={
                                                                                                        //     "Status" + index
                                                                                                        // }
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        onChange={event => handleChangeChild(event, index, subIndex, childIndex, "StatusID")}
                                                                                                        value={getTNAStatusList.filter(function (option) {
                                                                                                            return option.value === childTask.StatusID;
                                                                                                        })}
                                                                                                        options={getTNAStatusList}
                                                                                                        isDisabled={true}
                                                                                                        menuPosition="fixed"
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <input type="text"
                                                                                                        placeholder="AWB & Status"
                                                                                                        // id={"AWBStatus" + index}
                                                                                                        // name="AWBStatus"
                                                                                                        value={childTask.AWBStatus}
                                                                                                        //onChange={event => handleChangeChild(event, index, subIndex, childIndex, "AWBStatus")}
                                                                                                        className={'form-control' + (
                                                                                                            submitted && !childTask.AWBStatus ? ' is-invalid' : ''
                                                                                                        )}
                                                                                                        readOnly={true}
                                                                                                    />
                                                                                                </span>
                                                                                            </td>
                                                                                            <td className="fixed-column-Route">

                                                                                                {/* <Modal show={childTask.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysChildClose(index, subIndex, childIndex,)}>
                                                                                                    <Modal.Header closeButton>
                                                                                                        <Modal.Title>
                                                                                                            Leave Days
                                                                                                        </Modal.Title>
                                                                                                    </Modal.Header>
                                                                                                    <Modal.Body>
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-12">
                                                                                                                <div className='form-group'>
                                                                                                                    <label> Task Holder / Follower </label>
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <Reactselect className="basic-single"
                                                                                                                            //name="TaskHolderOrFollower"
                                                                                                                            // id={"TaskHolderOrFollower"}
                                                                                                                            isLoading={false}
                                                                                                                            isClearable={true}
                                                                                                                            isSearchable={true}
                                                                                                                            onChange={event => handleChangeChild(event, index, subIndex, childIndex, "TaskHolderOrFollower")}
                                                                                                                            value={getTaskHolderList.filter(function (option) {
                                                                                                                                return option.value === childTask.TaskHolderOrFollower;
                                                                                                                            })}
                                                                                                                            options={getTaskHolderList}
                                                                                                                            styles={submitted && childTask.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                                            menuPosition="fixed"
                                                                                                                            filterOption={filterOption}
                                                                                                                        ></Reactselect>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-12">
                                                                                                                <div className='form-group'>
                                                                                                                    <label>Leave Days</label>
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <Reactselect className="basic-single"
                                                                                                                            //                     name="LeaveDays"
                                                                                                                            // id={
                                                                                                                            //     "LeaveDays"
                                                                                                                            // }
                                                                                                                            isLoading={false}
                                                                                                                            isClearable={true}
                                                                                                                            isSearchable={true}
                                                                                                                            onChange={event => handleChangeChild(event, index, subIndex, childIndex, "LeaveDays")}
                                                                                                                            value={getLeaveDaysList.filter(function (option) {
                                                                                                                                return option.value === childTask.LeaveDays;
                                                                                                                            })}
                                                                                                                            options={getLeaveDaysList}
                                                                                                                            styles={submitted && childTask.LeaveDays === 0 ? styles : ''}
                                                                                                                            menuPosition="fixed"

                                                                                                                        ></Reactselect>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {childTask.titleEmployee1 && <div className="row">
                                                                                                            <div className="col-md-12">
                                                                                                                <div className='form-group'>
                                                                                                                    <label htmlFor={childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {childTask.titleEmployee1} </label>
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <Reactselect className="basic-single" name={childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                                            isDisabled={false}
                                                                                                                            isLoading={false}
                                                                                                                            isClearable={true}
                                                                                                                            isSearchable={true}
                                                                                                                            isMulti
                                                                                                                            onChange={event => handleChangeChild(event, index, subIndex, childIndex, childTask.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                            options={childTask.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : childTask.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                            value={childTask.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList && childTask.arrTaskHolderBuyer : childTask.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList && childTask.arrFollowerCube : drpTNATaskOwnerList.supplierEmployeeList && childTask.arrFollowerSupplier}
                                                                                                                        >  </Reactselect>

                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        }

                                                                                                        {
                                                                                                            childTask.titleEmployee2 && <div className="row">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className='form-group'>
                                                                                                                        <label htmlFor={childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {childTask.titleEmployee2}</label>
                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                            <Reactselect className="basic-single" name={childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                                                isDisabled={false}
                                                                                                                                isLoading={false}
                                                                                                                                isClearable={true}
                                                                                                                                isSearchable={true}
                                                                                                                                isMulti
                                                                                                                                onChange={event => handleChangeChild(event, index, subIndex, childIndex, childTask.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                                options={childTask.titleEmployee2 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : childTask.titleEmployee2 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                                value={childTask.titleEmployee2 === "Buyer" ? childTask.arrTaskHolderBuyer : childTask.titleEmployee2 === "Cube" ? childTask.arrFollowerCube : childTask.arrFollowerSupplier}
                                                                                                                            // options={IMPORTANCE}
                                                                                                                            // value={getStyleList.arrImportance}
                                                                                                                            //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                                                            >  </Reactselect>

                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {childTask.titleEmployee3 && <div className="row">
                                                                                                            <div className="col-md-12">
                                                                                                                <div className='form-group'>
                                                                                                                    <label htmlFor="FollowerSupplier" >Follower - {childTask.titleEmployee3}</label>
                                                                                                                    <span className='input-icon icon-right'>
                                                                                                                        <Reactselect className="basic-single" name={childTask.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                                                            isDisabled={false}
                                                                                                                            isLoading={false}
                                                                                                                            isClearable={true}
                                                                                                                            isSearchable={true}
                                                                                                                            isMulti
                                                                                                                            onChange={event => handleChangeChild(event, index, subIndex, childIndex, childTask.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : childTask.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                                                            options={childTask.titleEmployee3 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : childTask.titleEmployee3 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                                                            value={childTask.titleEmployee3 === "Buyer" ? childTask.arrTaskHolderBuyer : childTask.titleEmployee3 === "Cube" ? childTask.arrFollowerCube : childTask.arrFollowerSupplier}

                                                                                                                        >  </Reactselect>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        }

                                                                                                    </Modal.Body>
                                                                                                    <Modal.Footer>
                                                                                                        <Button variant="success" onClick={() => handleTHLeaveDaysChildClose(index, subIndex, childIndex)}>
                                                                                                            Save
                                                                                                        </Button>

                                                                                                    </Modal.Footer>
                                                                                                </Modal> */}
                                                                                                {childTask.IsAddChildTaskOpen === 1 &&
                                                                                                    <Modal show={childTask.IsAddChildTaskOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleAddChildTaskClose(index, subIndex, childIndex,)}>
                                                                                                        <Modal.Header closeButton>
                                                                                                            <Modal.Title>
                                                                                                                Add Task
                                                                                                            </Modal.Title>
                                                                                                        </Modal.Header>
                                                                                                        <Modal.Body>
                                                                                                            <div className="row">
                                                                                                                <div className="col-md-12">
                                                                                                                    <div className='form-group'>
                                                                                                                        <label> Task </label>
                                                                                                                        <span className='input-icon icon-right'>
                                                                                                                            <Reactselect className="basic-single"
                                                                                                                                isLoading={false}
                                                                                                                                isClearable={true}
                                                                                                                                isSearchable={true}
                                                                                                                                onChange={event => handleChangeChildTask(event)}
                                                                                                                                // value={childTask.filter(function (option) {
                                                                                                                                //     return option.value === childTask.subId;
                                                                                                                                // })}
                                                                                                                                options={childTask.drpChildTaskList}
                                                                                                                                // styles={childTask.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                                                                menuPosition="fixed"
                                                                                                                                filterOption={filterOption}
                                                                                                                            ></Reactselect>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>





                                                                                                        </Modal.Body>
                                                                                                        <Modal.Footer>
                                                                                                            <Button variant="success" onClick={() => handleAddRowChildTask(index, subIndex, childIndex)}>
                                                                                                                Add
                                                                                                            </Button>

                                                                                                        </Modal.Footer>
                                                                                                    </Modal>
                                                                                                }

                                                                                                {/* <button type="button" className="btn btn-xs btn-magenta" title="Comments" onClick={() => handleComment(index)}>
                                                                                                    <i className="fa fa-comment"></i>
                                                                                                </button>

                                                                                                &nbsp;  <button type="button" className="btn btn-xs btn-blue" title="Attachments" onClick={() => handleAttachment(index)}
                                                                                                >
                                                                                                    <i className="fa fa-upload"></i>
                                                                                                </button>
                                                                                                 &nbsp;  <button type="button" className="btn btn-xs btn-success" title="Depedency Start and Stop"
                                                                                                    onClick={() => handleDependent(index, "Start")}
                                                                                                >
                                                                                                    <i className="fa fa-thumbs-o-up"></i>

                                                                                                </button> */}

                                                                                                &nbsp;
                                                                                                {
                                                                                                    isLastEdit === true ?
                                                                                                        <>


                                                                                                            {
                                                                                                                childTask.IsManualClose === 1 ?
                                                                                                                    <Modal show={childTask.IsManualClose === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseManualPopup(childTask.StatusID, childTask.ManualCloseComment, childTask.ManualCloseDate, index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {childTask.StatusID == 3 ? 'View ' : ''}Manual Close  : {inputField.strTaskName} &nbsp;
                                                                                                                                {
                                                                                                                                    childTask.StatusID === 3 &&

                                                                                                                                    <button type="button" className="btn btn-xs btn-danger" title="Delete Manual Close" onClick={() => handledelete(index, subIndex, childIndex, childTask.TaskID, childTask.ChildId, childTask.TaskName)}>
                                                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                                                    </button>
                                                                                                                                }

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Comments <span className="text-danger">*</span></th>
                                                                                                                                        <th>Close Date <span className="text-danger">*</span></th>
                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>

                                                                                                                                    <tr>
                                                                                                                                        <td style={{ width: "600px" }} >
                                                                                                                                            <textarea
                                                                                                                                                rows="3"
                                                                                                                                                placeholder="Enter Comments here..."
                                                                                                                                                autoFocus onFocus={""} autoComplete="off"
                                                                                                                                                maxLength="500" name="EnterRemarks"
                                                                                                                                                id={"EnterRemarks"}
                                                                                                                                                value={childTask.ManualCloseComment}
                                                                                                                                                disabled={childTask.StatusID === 3}
                                                                                                                                                onChange={event => handleChangeIsManualCloseComments(event, index, subIndex, childIndex, "Comment")}
                                                                                                                                                style={{ width: "100%", border: getComment && childTask.ManualCloseComment === '' ? '1px solid red' : '' }}>

                                                                                                                                            </textarea>
                                                                                                                                        </td>
                                                                                                                                        <td>
                                                                                                                                            <DatePicker className="form-control" name="HolidayDate"
                                                                                                                                                id={
                                                                                                                                                    "HolidayDate" + index
                                                                                                                                                }
                                                                                                                                                selected={
                                                                                                                                                    childTask.ManualCloseDate
                                                                                                                                                }
                                                                                                                                                onChange={event => handleChangeIsManualCloseComments(event, index, subIndex, childIndex, "CommentDate")}
                                                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                                                peekNextMonth
                                                                                                                                                showMonthDropdown
                                                                                                                                                disabled={childTask.StatusID === 3}
                                                                                                                                                // showYearDropdown
                                                                                                                                                dropdownMode="scroll"
                                                                                                                                                autoComplete="off"
                                                                                                                                                minDate={new Date()}
                                                                                                                                                maxDate={new Date()}
                                                                                                                                                // style={{ border: getComment && childTask.ManualCloseDate === '' ? '1px solid red' : '' }}
                                                                                                                                                customInput={<CustomInputman />}
                                                                                                                                            // isClearable={inputField.HolidayDate === '' ? false : true}
                                                                                                                                            />
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                            {
                                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_ORDER_TO_NOMINATED_SUPPLIER || childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_NOMINATED_SUPPLIER ||
                                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_RECEIPT_FROM_BUYER || childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT ||
                                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_LOCAL_SUPPLIER
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_LOCAL_SUPPLIER
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_BUYER || childTask.TaskName.toLowerCase() === StaticTask.TRIM_ORDER_TO_NOMINATED_SUPPLIER
                                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.TRIM_RECEIPT_FROM_NOMINATED_SUPPLIER
                                                                                                                                    ?
                                                                                                                                    <>
                                                                                                                                        {
                                                                                                                                            OrderInfoMulticloseMainDetails.length !== 0 ?

                                                                                                                                                <table className="table table-bordered">

                                                                                                                                                    <tbody>
                                                                                                                                                        {
                                                                                                                                                            OrderInfoMulticloseMainDetails.map((comment, mainindex) => (
                                                                                                                                                                <Fragment>
                                                                                                                                                                    <tr>
                                                                                                                                                                        <td>
                                                                                                                                                                            <div className="row">
                                                                                                                                                                                <div className="col-lg-8">
                                                                                                                                                                                    <i
                                                                                                                                                                                        className={
                                                                                                                                                                                            expandStateMultiSelect[comment.strikeofforTrimLogoPath] ?
                                                                                                                                                                                                'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                                                                                                                        }
                                                                                                                                                                                        onClick={() => handleExpandRowMultiSelect(comment.strikeofforTrimLogoPath, MultiCloseDatas)}
                                                                                                                                                                                    ></i>
                                                                                                                                                                                    <span className="BuyerPaddingLeft">
                                                                                                                                                                                        {
                                                                                                                                                                                            comment.strikeofforTrimCode
                                                                                                                                                                                        }
                                                                                                                                                                                        -
                                                                                                                                                                                        {
                                                                                                                                                                                            comment.strikeofforTrimName
                                                                                                                                                                                        }
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div className="col-lg-4">
                                                                                                                                                                                    <span className="Actionbutton">
                                                                                                                                                                                        <img src={window.$APIBaseURL + comment.ImageUrl + comment.strikeofforTrimLogoPath} height="50px" width="50px"></img>
                                                                                                                                                                                    </span>
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </td>

                                                                                                                                                                    </tr>
                                                                                                                                                                    <>
                                                                                                                                                                        {
                                                                                                                                                                            expandedRowsMultiSelect.includes(comment.strikeofforTrimLogoPath) ?

                                                                                                                                                                                <tr>
                                                                                                                                                                                    <td>
                                                                                                                                                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                                                                                                                            <thead>
                                                                                                                                                                                                <tr>
                                                                                                                                                                                                    <th>Style Name</th>
                                                                                                                                                                                                    <th>Style No</th>
                                                                                                                                                                                                    <th>PO/ID No</th>
                                                                                                                                                                                                    <th>Details</th>
                                                                                                                                                                                                    <th>Task Holder / Follower</th>
                                                                                                                                                                                                    <th>
                                                                                                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                            <input type="checkbox" name="IsSelected"

                                                                                                                                                                                                                onChange={event => (SelectAllST(event, comment.strikeofforTrimLogoPath, mainindex))}
                                                                                                                                                                                                                //  checked={(selectall.IsAllSelected === 1 && selectall.AllSelectedIndex === mainindex) ? true : false}
                                                                                                                                                                                                                checked={comment.isSelectedAll}
                                                                                                                                                                                                                className="colored-blue clearOpacity" />
                                                                                                                                                                                                            <span className="text"></span>
                                                                                                                                                                                                        </label>

                                                                                                                                                                                                    </th>

                                                                                                                                                                                                </tr>
                                                                                                                                                                                            </thead>
                                                                                                                                                                                            <tbody>
                                                                                                                                                                                                {
                                                                                                                                                                                                    expandListMultiSelect.map((item, index) => (
                                                                                                                                                                                                        <Fragment>

                                                                                                                                                                                                            <tr>
                                                                                                                                                                                                                <td>{item.styleName}</td>
                                                                                                                                                                                                                <td>{item.styleNo}</td>
                                                                                                                                                                                                                <td>{item.idPoNo}</td>
                                                                                                                                                                                                                <td>{item.details}</td>
                                                                                                                                                                                                                <td>{item.taskOwnerNameList}</td>
                                                                                                                                                                                                                <td className="Actionbutton fixed-column">
                                                                                                                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                                                            <input type="checkbox" name="IsSelected"

                                                                                                                                                                                                                                onChange={event => (OnSelectRecordsST(event, item.index - 1, mainindex))}
                                                                                                                                                                                                                                checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                                                                className="colored-blue clearOpacity" />
                                                                                                                                                                                                                            <span className="text"></span>
                                                                                                                                                                                                                        </label>

                                                                                                                                                                                                                    </div>

                                                                                                                                                                                                                </td>

                                                                                                                                                                                                            </tr>

                                                                                                                                                                                                        </Fragment>
                                                                                                                                                                                                    ))

                                                                                                                                                                                                }

                                                                                                                                                                                            </tbody>
                                                                                                                                                                                        </table>
                                                                                                                                                                                    </td>
                                                                                                                                                                                </tr> : null
                                                                                                                                                                        }
                                                                                                                                                                    </>
                                                                                                                                                                </Fragment>
                                                                                                                                                            ))
                                                                                                                                                        }






                                                                                                                                                    </tbody>
                                                                                                                                                </table>
                                                                                                                                                :
                                                                                                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                                                                    <thead>
                                                                                                                                                        <tr>
                                                                                                                                                            <th>Style Name</th>
                                                                                                                                                            <th>Style No</th>
                                                                                                                                                            <th>PO/ID No</th>
                                                                                                                                                            <th>Details</th>
                                                                                                                                                            <th>Task Holder / Follower</th>
                                                                                                                                                            <th>Action  </th>

                                                                                                                                                        </tr>
                                                                                                                                                    </thead>
                                                                                                                                                    <tbody>
                                                                                                                                                        <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                                                    </tbody>
                                                                                                                                                </table>


                                                                                                                                        }
                                                                                                                                    </>
                                                                                                                                    :
                                                                                                                                    <table className="table table-bordered">
                                                                                                                                        <thead>
                                                                                                                                            <tr>
                                                                                                                                                <th>Style Name</th>
                                                                                                                                                <th>Style No</th>
                                                                                                                                                <th>PO/ID No</th>
                                                                                                                                                <th>SKU Name</th>
                                                                                                                                                <th>Task Holder / Follower</th>
                                                                                                                                                <th>
                                                                                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                                                                                        <input type="checkbox" name="IsSelected"

                                                                                                                                                            onChange={event => (SelectAll(event))}
                                                                                                                                                            checked={selectall === 1 ? true : false}
                                                                                                                                                            className="colored-blue clearOpacity" />
                                                                                                                                                        <span className="text"></span>
                                                                                                                                                    </label>&nbsp;&nbsp;&nbsp;


                                                                                                                                                    <span style={{ float: 'right' }}>
                                                                                                                                                        <button type="button" className={childTask.hasAllDependencyStart === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                            onClick={() => OpenAllDependentStartPopup(index, subIndex, childIndex, childTask.hasAllDependencyStart, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                        >
                                                                                                                                                            <i className={childTask.hasAllDependencyStart === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                        </button>
                                                                                                                                                    </span>
                                                                                                                                                </th>

                                                                                                                                            </tr>
                                                                                                                                        </thead>
                                                                                                                                        <tbody>
                                                                                                                                            {

                                                                                                                                                MultiCloseDatas.map((item, index) => {
                                                                                                                                                    return (
                                                                                                                                                        <>
                                                                                                                                                            <tr>
                                                                                                                                                                <td>{item.styleName}</td>
                                                                                                                                                                <td>{item.styleNo}</td>
                                                                                                                                                                <td>{item.idPoNo}</td>
                                                                                                                                                                <td>{item.details}</td>
                                                                                                                                                                <td>{item.taskOwnerNameList}</td>
                                                                                                                                                                <td className="Actionbutton fixed-column">
                                                                                                                                                                    <div style={{ width: "80px" }}>
                                                                                                                                                                        {


                                                                                                                                                                            // (item.dependencyStartstop === 1 || (!inputField.SelectedDependency) || getSelectedDependencyMap(inputField.SelectedDependency) === true || item.isSelected === 1
                                                                                                                                                                            //  ) &&
                                                                                                                                                                            item.IsDepandency === 0 &&
                                                                                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                                                                                <input type="checkbox" name="IsSelected"
                                                                                                                                                                                    onChange={event => (OnSelectRecords(event, index))}
                                                                                                                                                                                    checked={item.isSelected === 1 ? true : false}
                                                                                                                                                                                    className="colored-blue clearOpacity" />
                                                                                                                                                                                <span className="text"></span>
                                                                                                                                                                            </label>
                                                                                                                                                                        }&nbsp;&nbsp;&nbsp;

                                                                                                                                                                        {
                                                                                                                                                                            // StartStopInfo && StartStopInfo.isAdd !== 0 &&
                                                                                                                                                                            // inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && childTask.StatusID !== 3 &&
                                                                                                                                                                            item.Depandency !== '' &&
                                                                                                                                                                            <button type="button" className={(item.dependencyStartstop === 1 || item.isSelected === 1) ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                                                                            // onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, childTask.intDependency, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                                                                            >
                                                                                                                                                                                <i className={item.dependencyStartstop === 1 || item.isSelected === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                                                                            </button>
                                                                                                                                                                        }
                                                                                                                                                                    </div>

                                                                                                                                                                </td>
                                                                                                                                                            </tr>
                                                                                                                                                        </>

                                                                                                                                                    )
                                                                                                                                                })
                                                                                                                                            }

                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                            }
                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                childTask.StatusID !== 3 &&
                                                                                                                                <Button variant="success" onClick={() => SaveChildManulaCloseComments(index, subIndex, childIndex, childTask.ManualCloseComment, childTask.ManualCloseDate, inputField.strTaskName)}>
                                                                                                                                    Save
                                                                                                                                </Button>


                                                                                                                            }


                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }
                                                                                                            {
                                                                                                                <Modal show={childTask.IsOpenTestingConfirmationType === 1} size="sm" dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingExFactoryFormPopup(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Inspection Type Confirm
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-success" onClick={() => CreateTesting(1)}>Internal</button>
                                                                                                                            </div>
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-primary" onClick={() => CreateTesting(2)}>External</button>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Modal.Body>

                                                                                                                </Modal>
                                                                                                            }
                                                                                                            {
                                                                                                                <Modal show={childTask.IsOpenInspectionConfirmationType === 1} size="sm" dragable backdrop="static" keyboard={false} onHide={() => handleCloseInspectionConfirmationPopup(index, subIndex, childIndex)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            CONFIRM INSPECTION TYPE
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-success" onClick={() => CreateFinalInspection(1)}>Internal</button>
                                                                                                                            </div>
                                                                                                                            <div className="col-md-6 btn-action-center">
                                                                                                                                <button className="btn btn-sm btn-primary" onClick={() => CreateFinalInspection(2)}>External</button>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Modal.Body>
                                                                                                                    {/* <Modal.Footer>
                                                                                                                        {
                                                                                                                            childTask.StatusID !== 3 &&
                                                                                                                            <Button variant="success">
                                                                                                                                Save
                                                                                                                            </Button>
                                                                                                                        }
                                                                                                                    </Modal.Footer> */}
                                                                                                                </Modal>
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsQCinsectionFactory === 1 ?
                                                                                                                    <Modal show={childTask.IsQCinsectionFactory === 1} dialogClassName="commonwidth" size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseQCinspectionFormPopup(index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                                                                                ADD {inputField.strTaskName} &nbsp; {"(Style Name - " + getInspectionInfo.styleName + ")"}

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                                                                                <li className="active">
                                                                                                                                    <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForQAInspection('AddInspection')}>
                                                                                                                                        <h5>Add Inspection </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                                {
                                                                                                                                    inputField.strTaskName !== 'In and Mid Line Inspection' && inputField.strTaskName !== 'PP Meeting' ? <li>
                                                                                                                                        <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForQAInspection('ReInspection')}>
                                                                                                                                            <h5>Re Inspection </h5>
                                                                                                                                        </a>
                                                                                                                                    </li> : ''}
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                {getQAInspectionPageView === 'AddInspection' ? <>
                                                                                                                                    <AddQCInspection props={props} QCfinalInspectionList={getAddQCInspectionListParam}
                                                                                                                                        QCcommonSubmitApprovalParams={commonSubmitApprovalParams} QCFinalInspectionCallback={QCFinalInspectionCallback} />
                                                                                                                                </> :
                                                                                                                                    <><QAReInspection props={props} Reinspectionparams={getAddQCInspectionListParam}
                                                                                                                                        QCInspectionCallback={QCInspectionCallback} Taskname={inputField.strTaskName} /></>
                                                                                                                                }
                                                                                                                            </div>

                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                getQCfinalinspectionMulti.length === 0 ? '' :
                                                                                                                                    <Button variant="success" onClick={() => AddQCFactoryForm(index, subIndex, childIndex)}>
                                                                                                                                        Save
                                                                                                                                    </Button>
                                                                                                                            }
                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsTestingTaskFactory === 1 ?
                                                                                                                    <Modal show={childTask.IsTestingTaskFactory === 1} dialogClassName="commonwidth" size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingTaskFormPopup(index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>

                                                                                                                                ADD &nbsp;{inputField.strTaskName}

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                                                                                <li className="active">
                                                                                                                                    <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForTestingInspection('AddInspection')}>
                                                                                                                                        <h5>New Testing</h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                                <li>
                                                                                                                                    <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForTestingInspection('ReInspection')}>
                                                                                                                                        <h5>Re Testing </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                {getTestingReportPageView === 'AddInspection' ? <>
                                                                                                                                    {(inputField.strTaskName === 'FABRIC TEST' || inputField.strTaskName === 'YARN TEST') ? <NewTesting props={props} QCfinalInspectionList={getTestingTaskListParam} Taskname={inputField.strTaskName}
                                                                                                                                        QCcommonSubmitApprovalParams={commonSubmitApprovalParams} QCFinalInspectionCallback={QCTestingCallback} /> : <TestingPopup props={props} QCfinalInspectionList={getTestingTaskListParam} Taskname={inputField.strTaskName}
                                                                                                                                            QCcommonSubmitApprovalParams={commonSubmitApprovalParams} QCFinalInspectionCallback={QCTestingCallback} />}
                                                                                                                                </> :
                                                                                                                                    <><ReTesting props={props} Reinspectionparams={getTestingTaskListParam}
                                                                                                                                        QCFinalInspectionCallback={QCTestingCallback} Taskname={inputField.strTaskName} /></>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                childTask.StatusID !== 3 &&
                                                                                                                                <Button variant="success" onClick={() => AddTestingExFactoryForm(index, subIndex, childIndex)}>
                                                                                                                                    ADD
                                                                                                                                </Button>
                                                                                                                            }
                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsExFactoryForm === 1 ?
                                                                                                                    <Modal show={childTask.IsExFactoryForm === 1} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseExFactoryFormPopup(index, subIndex, childIndex)}>
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {/* QC Internal Inspection Add  : {inputField.strTaskName} &nbsp; */}
                                                                                                                                ADD {inputField.strTaskName} &nbsp;

                                                                                                                            </Modal.Title>
                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            <ul className="nav nav-tabs nav-justified" id="tab_newinspaction">
                                                                                                                                <li className="active">
                                                                                                                                    <a data-toggle="tab" href="#idaddinspection" onClick={() => pageRedirectForFinalInspection('AddInspection')}>
                                                                                                                                        <h5>Add Inspection </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                                <li>
                                                                                                                                    <a data-toggle="tab" href="#idreinspection" onClick={() => pageRedirectForFinalInspection('ReInspection')}>
                                                                                                                                        <h5>Re Inspection </h5>
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div className="tab-content">
                                                                                                                                {getFinalInspectionPageView === "AddInspection" ?
                                                                                                                                    <>
                                                                                                                                        <AddInspection props={props} finalInspectionList={getAddInspectionListParam} skuSelectData={getskusingleID}
                                                                                                                                            commonSubmitApprovalParams={commonSubmitApprovalParams} FinalInspectionCallback={FinalInspectionCallback} />
                                                                                                                                    </> : ''}
                                                                                                                                {getFinalInspectionPageView === "ReInspection" ?
                                                                                                                                    <><ReInspection props={props} Reinspectionparams={getAddInspectionListParam}
                                                                                                                                        FinalInspectionCallback={FinalInspectionCallback} /></> : ''}
                                                                                                                            </div>
                                                                                                                        </Modal.Body>
                                                                                                                        <Modal.Footer>
                                                                                                                            {
                                                                                                                                childTask.StatusID !== 3 &&
                                                                                                                                <Button variant="success" onClick={() => AddExFactoryForm(index, subIndex, childIndex)}>
                                                                                                                                    Save
                                                                                                                                </Button>
                                                                                                                            }
                                                                                                                        </Modal.Footer>
                                                                                                                    </Modal> : ''
                                                                                                            }
                                                                                                            &nbsp;
                                                                                                            {(childTask.IsShowSubmitButton === 1 || childTask.IsShowSubmitButton === 2) && childTask.StatusID !== 3 && childTask.Isdraft !== 1 && childTask.IsQAdraft !== 1 && childTask.IsTestDraft !== 1 && childTask.IsStrikedraft !== 1 && childTask.IsTrimDraft !== 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-darkorange"} title="Manual Close"
                                                                                                                    onClick={() => handleChildManualclose(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskType, inputField.TaskID, childTask.ChildId, childTask.StatusID, childTask.ManualCloseComment,
                                                                                                                        childTask.arrDependency, childTask.intDependency)}
                                                                                                                >
                                                                                                                    <i className="fa fa-user"></i>&nbsp;
                                                                                                                    <i className="fa fa-close"></i>
                                                                                                                </button>
                                                                                                                </Fragment>
                                                                                                                : (childTask.ManualCloseComment) && childTask.StatusID === 3 ?
                                                                                                                    <button type="button" className={childTask.ManualCloseComment === '' || childTask.ManualCloseComment === null || childTask.ManualCloseComment === undefined ? "btn btn-xs btn-darkorange" : "btn btn-xs btn-success"} title="Manual Close"
                                                                                                                        onClick={() => handleChildManualclose(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskType, inputField.TaskID, childTask.ChildId, childTask.StatusID, childTask.ManualCloseComment)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-user"></i>&nbsp;
                                                                                                                        <i className="fa fa-close"></i>
                                                                                                                    </button>
                                                                                                                    : ''
                                                                                                            }
                                                                                                            &nbsp;
                                                                                                            {inputField.DetailTypeID === 4 && childTask.StatusID !== 3 && childTask.TaskName === 'FINAL INSPECTION' &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        childTask.StatusID !== 4 ?
                                                                                                                            <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-Report"} style={{ color: '' }} title="Report Create"
                                                                                                                                onClick={() => handleExFactoryFormOpen(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 1)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-external-link"></i>

                                                                                                                            </button>
                                                                                                                            </Fragment>
                                                                                                                            : ""
                                                                                                                    }

                                                                                                                </>
                                                                                                            }
                                                                                                            {childTask.Isdraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Report View"
                                                                                                                    onClick={() => handleFinalInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }{
                                                                                                                childTask.IsQAInsView === 1 ?
                                                                                                                    <Modal show={(childTask.IsQAInsView === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseQAInspecView(index, subIndex, childIndex)} >
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {`${inputField.strTaskName} List`}
                                                                                                                            </Modal.Title>

                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            {
                                                                                                                                getQAheaderData.length !== 0 && getQAheaderData.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; Focus PO No:- {item.pono}
                                                                                                                                                ,&nbsp; &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp;
                                                                                                                                                <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('DD/MM/YYYY')}</h5>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Report No. & Date</th>
                                                                                                                                        <th>Factory</th>
                                                                                                                                        <th>Product Qty</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                        <th>Action</th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getnewQAReportlist.length !== 0 && getnewQAReportlist.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td >
                                                                                                                                                            {item.reportNo}
                                                                                                                                                        </td>

                                                                                                                                                        <td>
                                                                                                                                                            {item.factory}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.productQty}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.createdBy}
                                                                                                                                                        </td>
                                                                                                                                                        <td style={{ width: "13%" }}>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                                                                onClick={() => handleQAInspecViewDetails(item, 'View')}>
                                                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                                                                onClick={() => handleQAInspecViewDetails(item, 'Edit')}>
                                                                                                                                                                <i className="fa fa-edit"></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            {getnewQAReportlist.length === (index + 1) && <button type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                                                                                                                onClick={() => handleDeleteQAinspection(item, 'Delete')}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>
                                                                                                                                                            }

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </tbody>

                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                    </Modal>
                                                                                                                    : ""

                                                                                                            }
                                                                                                            {childTask.StatusID !== 3 && (childTask.TaskName === 'Strike off Test' || childTask.TaskName === "Trims Test" ||
                                                                                                                childTask.TaskName === "FABRIC TEST" || childTask.TaskName === "YARN TEST" || childTask.TaskName === "GARMENT TEST") &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        childTask.StatusID !== 4 ?
                                                                                                                            <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-Report"} style={{ color: '' }} title="Testing Report Create"
                                                                                                                                onClick={() => handleTestingTaskFormOpen(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId,
                                                                                                                                    inputField.SelectedDependency, 1, childTask)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-external-link"></i>

                                                                                                                            </button>
                                                                                                                            </Fragment>
                                                                                                                            : ""
                                                                                                                    }

                                                                                                                </>
                                                                                                            }
                                                                                                            {inputField.DetailTypeID === 4 && childTask.StatusID !== 3 && (childTask.TaskName === 'Pilot Run' || childTask.TaskName === "In and Mid Line Inspection" ||
                                                                                                                childTask.TaskName === "Size Set check" || childTask.TaskName === "PP Meeting") &&
                                                                                                                <>
                                                                                                                    {
                                                                                                                        childTask.StatusID !== 4 ?
                                                                                                                            <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-Report"} style={{ color: '' }} title="Inspection Create"
                                                                                                                                onClick={() => handleQCInspectionFormOpen(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 1)}
                                                                                                                            >
                                                                                                                                <i className="fa fa-external-link"></i>

                                                                                                                            </button>
                                                                                                                            </Fragment>
                                                                                                                            : ""
                                                                                                                    }

                                                                                                                </>
                                                                                                            }
                                                                                                            {childTask.IsQAdraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="QA Report View"
                                                                                                                    onClick={() => handleQAInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, inputField)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {(childTask.TaskName === "FABRIC TEST" || childTask.TaskName === "YARN TEST" || childTask.TaskName === "GARMENT TEST") && childTask.IsTestDraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Test Report View"
                                                                                                                    onClick={() => handleTestinInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, inputField, 1)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {childTask.TaskName === 'Strike off Test' && childTask.IsStrikedraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Test Report View"
                                                                                                                    onClick={() => handleTestinInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, inputField, 2)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {childTask.TaskName === 'Trims Test' && childTask.IsTrimDraft === 1 ?
                                                                                                                <Fragment>&nbsp;<button type="button" className={"btn btn-xs btn-ReportView"} title="Test Report View"
                                                                                                                    onClick={() => handleTestinInspecView(index, subIndex, childIndex, inputField.strTaskName, inputField.TaskID, childTask.SubId, childTask.StatusID, inputField.TaskHolderOwnerIdList, inputField.TaskHolderOrFollower, childTask.ChildId, inputField.SelectedDependency, 2, inputField, 3)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check-square"></i>

                                                                                                                </button>
                                                                                                                </Fragment> : ""
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsTestingInsView === 1 ?
                                                                                                                    <Modal show={(childTask.IsTestingInsView === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseTestingView(index, subIndex, childIndex)} >
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {`${childTask.TaskName} List`}
                                                                                                                            </Modal.Title>

                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            {
                                                                                                                                getTestingheaderData.length !== 0 && getTestingheaderData.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; Focus PO No:- {item.pono}
                                                                                                                                                ,&nbsp; &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp;
                                                                                                                                                <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('MM/DD/YYYY')}</h5>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Report No. & Date</th>
                                                                                                                                        <th>Test Type</th>
                                                                                                                                        <th>Test Order Qty</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                        <th>Report Conclusion</th>
                                                                                                                                        <th>Approval Result</th>
                                                                                                                                        {/* <th>Factory</th>
                                                                                        <th>Product Qty</th> */}
                                                                                                                                        <th>Action</th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getnewTestingReportlist.length !== 0 && getnewTestingReportlist.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td >
                                                                                                                                                            {item.reportNo}
                                                                                                                                                        </td>

                                                                                                                                                        <td>
                                                                                                                                                            {item.testType}
                                                                                                                                                        </td>
                                                                                                                                                        {/* <td>
                                                                                                            {/* {item.offerQty} */}
                                                                                                                                                        {/* </td> */}
                                                                                                                                                        <td>
                                                                                                                                                            {item.testOrderQty}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.createdBy}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.reportConclusion}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.approvalResult}
                                                                                                                                                        </td>
                                                                                                                                                        <td style={{ width: "13%" }}>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                                                                onClick={() => handleTestInspecViewDetails(item, 'ViewInspection')}>
                                                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                                                                onClick={() => handleTestInspecViewDetails(item, 'EditInspection')}>
                                                                                                                                                                <i className="fa fa-edit"></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            {getnewTestingReportlist.length === (index + 1) && <button type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                                                                                                                onClick={() => handleDeleteTestinspection(item, 'Delete')}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>
                                                                                                                                                            }

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </tbody>

                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                    </Modal>
                                                                                                                    : ""

                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsQAInsView === 1 ?
                                                                                                                    <Modal show={(childTask.IsQAInsView === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseQAInspecView(index, subIndex, childIndex)} >
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                {`${inputField.strTaskName} List`}
                                                                                                                            </Modal.Title>

                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            {
                                                                                                                                getQAheaderData.length !== 0 && getQAheaderData.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; Focus PO No:- {item.pono}
                                                                                                                                                ,&nbsp; &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp;
                                                                                                                                                <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('DD/MM/YYYY')}</h5>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Report No. & Date</th>
                                                                                                                                        <th>Factory</th>
                                                                                                                                        <th>Product Qty</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                        <th>Action</th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getnewQAReportlist.length !== 0 && getnewQAReportlist.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td >
                                                                                                                                                            {item.reportNo}
                                                                                                                                                        </td>

                                                                                                                                                        <td>
                                                                                                                                                            {item.factory}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.productQty}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.createdBy}
                                                                                                                                                        </td>
                                                                                                                                                        <td style={{ width: "13%" }}>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                                                                onClick={() => handleQAInspecViewDetails(item, 'View')}>
                                                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                                                                onClick={() => handleQAInspecViewDetails(item, 'Edit')}>
                                                                                                                                                                <i className="fa fa-edit"></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            {getnewTestingReportlist.length === (index + 1) && item.approvalResult !== "Approved" && (
                                                                                                                                                                <button type="button" className="btn btn-xs btn-danger" title="Delete FinalList"
                                                                                                                                                                    onClick={() => handleDeleteTestinspection(item, 'Delete')}>
                                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                                </button>
                                                                                                                                                            )}

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </tbody>

                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                    </Modal>
                                                                                                                    : ""

                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsFinalInsView === 1 ?
                                                                                                                    <Modal show={(childTask.IsFinalInsView === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleCloseFinalInspecView(index, subIndex, childIndex)} >
                                                                                                                        <Modal.Header closeButton>
                                                                                                                            <Modal.Title>
                                                                                                                                Final Inspection List
                                                                                                                            </Modal.Title>

                                                                                                                        </Modal.Header>
                                                                                                                        <Modal.Body>
                                                                                                                            {
                                                                                                                                getnewheaderdata.length !== 0 && getnewheaderdata.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <>
                                                                                                                                            <h5>Sku ID:-{item.skuID},&nbsp; &nbsp; PO/ID No:- {item.idno},&nbsp; &nbsp; Focus PO No:- {item.pono},&nbsp; &nbsp;Style:- {item.styleName},&nbsp; &nbsp; SKU:- {item.skuName},&nbsp; &nbsp; <b>PO Qty:- {item.poQty}</b>&nbsp; &nbsp;Ex-Fac:- {moment(item.exFacdate).format('DD/MM/YYYY')}</h5>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }

                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Report No. & Date</th>
                                                                                                                                        <th>Report Type</th>
                                                                                                                                        <th>Factory</th>
                                                                                                                                        <th>Inspected Qty</th>
                                                                                                                                        <th>Bal. for Inspection</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                        <th>Report Conclusion</th>
                                                                                                                                        <th>Approval Result</th>
                                                                                                                                        <th>Action</th>

                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getnewfinalReportlist.length !== 0 && getnewfinalReportlist.map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td >
                                                                                                                                                            {item.reportNo}

                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.reportType}

                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.factory}

                                                                                                                                                        </td>
                                                                                                                                                        <td >
                                                                                                                                                            {item.inspectedQty}
                                                                                                                                                        </td>
                                                                                                                                                        <td >
                                                                                                                                                            {item.balanceQuantity}
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            {item.createdBy}

                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            -
                                                                                                                                                        </td>
                                                                                                                                                        <td>
                                                                                                                                                            -

                                                                                                                                                        </td>
                                                                                                                                                        <td style={{ width: "13%" }}>
                                                                                                                                                            <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                                                                                                                onClick={() => handleFinalInspecViewDetails(item.confirmInspectionType, item.tnaFinalInspectionID, 'View', 'ViewInspection', item)}>
                                                                                                                                                                <i className="fa fa-eye "></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Edit FinalList'
                                                                                                                                                                onClick={() => handleFinalInspecViewDetails(item.confirmInspectionType, item.tnaFinalInspectionID, 'Edit', "EditInspection", item)}>
                                                                                                                                                                <i className="fa fa-edit"></i>
                                                                                                                                                            </button>
                                                                                                                                                            &nbsp;
                                                                                                                                                            {getnewfinalReportlist.length === (index + 1) && <button type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                                                                                                                                onClick={() => handleDeletefinalinspection(item.confirmInspectionType, item.tnaFinalInspectionID, 'delete')}
                                                                                                                                                            >
                                                                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                                                                            </button>
                                                                                                                                                            }

                                                                                                                                                        </td>

                                                                                                                                                    </tr>
                                                                                                                                                </>

                                                                                                                                            )

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </tbody>

                                                                                                                            </table>


                                                                                                                        </Modal.Body>
                                                                                                                    </Modal>
                                                                                                                    : ""

                                                                                                            }
                                                                                                            &nbsp;
                                                                                                            {
                                                                                                                (childTask.RevicePopup === 1) &&
                                                                                                                <Modal show={(childTask.RevicePopup === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseRevicePopup(index, subIndex, childIndex, 'Sub', 'Close')}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Revise History
                                                                                                                            {
                                                                                                                                getSubReviceList.length !== 0 ?
                                                                                                                                    <>
                                                                                                                                        <br />
                                                                                                                                        (Initial Date  Duration : {getSubReviceList[0].duration} Start Date : {getSubReviceList[0].startDate} End Date : {getSubReviceList[0].endDate})
                                                                                                                                    </>
                                                                                                                                    : ''

                                                                                                                            }

                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>

                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th>Comments<span className="text-danger">*</span></th>
                                                                                                                                    {/* <th>Revice Date<span className="text-danger">*</span></th> */}
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>

                                                                                                                                <tr>
                                                                                                                                    <td style={{ width: "600px" }} >
                                                                                                                                        <textarea
                                                                                                                                            rows="3"
                                                                                                                                            placeholder="Enter Comments here..."
                                                                                                                                            autoFocus onFocus={""} autoComplete="off"
                                                                                                                                            maxLength="500" name="EnterRemarks"
                                                                                                                                            id={"EnterRemarks"}
                                                                                                                                            value={ReviceComment}
                                                                                                                                            onChange={event => handleChangeReviceComments(event, "Comment")}
                                                                                                                                            style={{ width: "100%", border: Submitrevice && ReviceComment === '' ? '1px solid red' : '' }}>



                                                                                                                                        </textarea>
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                            </tbody>

                                                                                                                        </table>
                                                                                                                        {
                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Duration</th>
                                                                                                                                        <th>Start Date</th>
                                                                                                                                        <th>End Date</th>
                                                                                                                                        <th>Comments</th>
                                                                                                                                        <th>Reviced Date</th>
                                                                                                                                        <th>Reviced By</th>
                                                                                                                                        {/* <th>Action </th> */}
                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getSubReviceList.length !== 0 ?
                                                                                                                                            getSubReviceList.filter(r => r.isInitialData !== 1).map((item, subindex) => {
                                                                                                                                                return (
                                                                                                                                                    <>
                                                                                                                                                        <tr>
                                                                                                                                                            <td style={{ width: "80px" }}>
                                                                                                                                                                {item.duration}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.startDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.endDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td style={{ width: "300px" }}>
                                                                                                                                                                {item.comments}
                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.createdDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.revicedBy}

                                                                                                                                                            </td>
                                                                                                                                                            {/* <td>
                                                                                                                                                                {
                                                                                                                                                                    <>
                                                                                                                                                                        <span style={{ padding: '2px', display: subindex === (getSubReviceList.length - 1) ? "" : "none" }}>
                                                                                                                                                                            <span>
                                                                                                                                                                                <span className="btn btn-success" title="Edit Comment" onClick={() => EditReviceComments(item.tnaTaskReviceId, item.comments, item.reviceDate)}>
                                                                                                                                                                                    <i className="fa fa-edit"></i>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </>
                                                                                                                                                                }
                                                                                                                                                                {

                                                                                                                                                                    <>
                                                                                                                                                                        <span style={{ padding: '2px', display: item.comments === '' ? "none" : "" }}>
                                                                                                                                                                            <span>
                                                                                                                                                                                <span className="btn btn-danger" title="Delete Comment" onClick={() => RemoveReviceComments(item.tnaTaskReviceId, index, subIndex, childIndex)}>
                                                                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                                                                </span>
                                                                                                                                                                            </span>
                                                                                                                                                                        </span>
                                                                                                                                                                    </>
                                                                                                                                                                }
                                                                                                                                                            </td> */}
                                                                                                                                                        </tr>
                                                                                                                                                    </>

                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                                                    }

                                                                                                                                </tbody>
                                                                                                                            </table>


                                                                                                                        }

                                                                                                                    </Modal.Body>
                                                                                                                    <Modal.Footer>
                                                                                                                        <Button variant="success" onClick={() => handleSaveRevice(index, subIndex, childIndex, props.location.state.params.tnaid,
                                                                                                                            childTask.ChildId, childTask.TaskName, childTask.StartDate, childTask.EndDate, childTask.Duration, 'Sub',
                                                                                                                            childTask.EndDateChange, ReviceComment)}>
                                                                                                                            {/* {getReviceAction === 'Add' ? 'Save' : 'Update'} */}
                                                                                                                            Add
                                                                                                                        </Button>
                                                                                                                    </Modal.Footer>
                                                                                                                </Modal>

                                                                                                            }

                                                                                                            {
                                                                                                                (childTask.ReschedulePopup === 1) &&
                                                                                                                <Modal show={(childTask.ReschedulePopup === 1)} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseReschedulePopup(index, subIndex, childIndex, 'Sub', 'Close')}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Reschedule History
                                                                                                                            {
                                                                                                                                getSubRescheduleList.length !== 0 ?
                                                                                                                                    <>
                                                                                                                                        <br />
                                                                                                                                        (Initial Date  Duration : <b> {getSubRescheduleList[0].duration}</b> &nbsp; &nbsp; Start Date : <b>{getSubRescheduleList[0].startDate} </b>&nbsp; &nbsp; End Date : <b>{getSubRescheduleList[0].endDate}</b> )
                                                                                                                                    </>
                                                                                                                                    : ''

                                                                                                                            }

                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>

                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th>Comments<span className="text-danger">*</span></th>
                                                                                                                                    {/* <th>Revice Date<span className="text-danger">*</span></th> */}
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>

                                                                                                                                <tr>
                                                                                                                                    <td style={{ width: "600px" }} >
                                                                                                                                        <textarea
                                                                                                                                            rows="3"
                                                                                                                                            placeholder="Enter Comments here..."
                                                                                                                                            autoFocus onFocus={""} autoComplete="off"
                                                                                                                                            maxLength="500" name="EnterRemarks"
                                                                                                                                            id={"EnterRemarks"}
                                                                                                                                            value={RescheduleComment}
                                                                                                                                            onChange={event => handleChangeRescheduleComments(event, "Comment")}
                                                                                                                                            style={{ width: "100%", border: Submitreschedule && RescheduleComment === '' ? '1px solid red' : '' }}>



                                                                                                                                        </textarea>
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                            </tbody>

                                                                                                                        </table>
                                                                                                                        {
                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Duration</th>
                                                                                                                                        <th>Start Date</th>
                                                                                                                                        <th>End Date</th>
                                                                                                                                        <th>Comments</th>
                                                                                                                                        <th>Reschedule Date</th>
                                                                                                                                        <th>Reschedule By</th>
                                                                                                                                        {/* <th>Action </th> */}
                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {

                                                                                                                                        getSubRescheduleList.length !== 0 ?
                                                                                                                                            getSubRescheduleList.filter(r => r.isInitialData !== 1).map((item, subindex) => {
                                                                                                                                                return (
                                                                                                                                                    <>
                                                                                                                                                        <tr>
                                                                                                                                                            <td style={{ width: "80px" }}>
                                                                                                                                                                {item.duration}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.startDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.endDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td style={{ width: "300px" }}>
                                                                                                                                                                {item.comments}
                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.createdDate}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.rescheduleBy}

                                                                                                                                                            </td>

                                                                                                                                                        </tr>
                                                                                                                                                    </>

                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <tr><td colSpan="8" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                                                    }

                                                                                                                                </tbody>
                                                                                                                            </table>


                                                                                                                        }

                                                                                                                    </Modal.Body>
                                                                                                                    <Modal.Footer>
                                                                                                                        {
                                                                                                                            childTask.StatusID === 3 &&
                                                                                                                            <Button variant="success" onClick={() => handleSaveReschedule(index, subIndex, childIndex, props.location.state.params.tnaid,
                                                                                                                                childTask.ChildId, childTask.TaskName, childTask.StartDate, childTask.EndDate, childTask.Duration, 'Sub',
                                                                                                                                childTask.EndDateChange, RescheduleComment)}>
                                                                                                                                {/* {getReviceAction === 'Add' ? 'Save' : 'Update'} */}
                                                                                                                                Add
                                                                                                                            </Button>
                                                                                                                        }

                                                                                                                    </Modal.Footer>
                                                                                                                </Modal>

                                                                                                            }

                                                                                                            &nbsp;
                                                                                                            {
                                                                                                                StartStopInfo && StartStopInfo.isAdd !== 0 && childTask.IsShowSubmitButton !== 0 &&
                                                                                                                    childTask.StatusID !== 3 && childTask.IsDepandency === 1 ?

                                                                                                                    <button type="button" className={childTask.intDependency === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                        onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, childTask.intDependency, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                    >
                                                                                                                        <i className={childTask.intDependency === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                    </button> :
                                                                                                                    childTask.StatusID !== 3 && childTask.IsDepandency === 0 && childTask.SelectedDependency !== '' ?
                                                                                                                        <button type="button" className={childTask.intDependency === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                            onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, childTask.intDependency, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                        >
                                                                                                                            <i className={"fa fa-thumbs-o-up"}></i>

                                                                                                                        </button>
                                                                                                                        : ''


                                                                                                            }

                                                                                                            {/* {inputField.SelectedDependency && getSelectedDependencyMap(inputField.SelectedDependency) === false && (!childTask.ManualCloseComment) &&
                                                                                                                childTask.IsShowSubmitButton !== 0 && childTask.StatusID !== 3 &&

                                                                                                                <button type="button" className={childTask.intDependency === 1 ? "btn btn-xs btn-success" : "btn btn-xs btn-danger"} title="Start and Stop(Dependency)"
                                                                                                                    onClick={() => OpenDependentStartPopup(index, subIndex, childIndex, childTask.intDependency, props.location.state.params.tnaid, inputField.TaskID, childTask.ChildId, inputField.IsSingleTask)}
                                                                                                                >
                                                                                                                    <i className={childTask.intDependency === 1 ? "fa fa-thumbs-o-up" : "fa fa-thumbs-o-down"}></i>

                                                                                                                </button>
                                                                                                            }&nbsp; */}



                                                                                                            &nbsp;
                                                                                                            {

                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                        onClick={() => OpenLabdipSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId,
                                                                                                                            props.location.state.params.styleId, index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus,
                                                                                                                            childTask.arrDependency, childTask.intDependency
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <i className="fa fa-send"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.StatusID === 3 && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing')
                                                                                                                        && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Successfully Submitted'
                                                                                                                            onClick={() => OpenLabdipSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId,
                                                                                                                                props.location.state.params.styleId, index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye"></i>
                                                                                                                        </button> :
                                                                                                                        childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_SUBMISSION && childTask.IsShowSubmitButton === 0 ?
                                                                                                                            <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                                <i className="fa fa-check"></i>
                                                                                                                            </button>
                                                                                                                            : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?

                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                    onClick={() => OpenLabdipApprovalpopup(childTask.ChildId, props.location.state.params.styleId
                                                                                                                        , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency, childTask.intDependency)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.StatusID === 3
                                                                                                                    && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='Successfully Approved'
                                                                                                                        onClick={() => OpenLabdipApprovalpopup(childTask.ChildId, props.location.state.params.styleId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.LAB_DIP_APPROVAL && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenTrimSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId
                                                                                                                        , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency, childTask.intDependency)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.StatusID === 3 &&
                                                                                                                    (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success"
                                                                                                                        onClick={() => OpenTrimSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye"></i>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.TRIM_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                    onClick={() => OpenTrimApprovalpopup(childTask.ChildId
                                                                                                                        , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency, childTask.intDependency)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.StatusID === 3
                                                                                                                    && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='Successfully Approved'
                                                                                                                        onClick={() => OpenTrimApprovalpopup(childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.TRIM_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenStrikeoffSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId
                                                                                                                        , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency, childTask.intDependency)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.StatusID === 3
                                                                                                                    && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='Successfully Submitted'
                                                                                                                        onClick={() => OpenStrikeoffSubmssionpopup(props.location.state.params.tnaid, childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus)}

                                                                                                                    >
                                                                                                                        <i className="fa fa-eye"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_SUBMISSION_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approve Form"
                                                                                                                    onClick={() => OpenStrikeoffApprovalpopup(childTask.ChildId
                                                                                                                        , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus, childTask.arrDependency, childTask.intDependency)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button> :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.StatusID === 3
                                                                                                                    && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='Successfully Approved'
                                                                                                                        onClick={() => OpenStrikeoffApprovalpopup(childTask.ChildId
                                                                                                                            , index, subIndex, childIndex, childTask.StatusID, childTask.SwatchStatus)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye"></i>
                                                                                                                    </button> :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.STRIKE_OFF_APPROVAL_LOCAL_DEVELOPMENT && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-check"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }


                                                                                                            {(childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION
                                                                                                                || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION
                                                                                                                || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION
                                                                                                                || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION
                                                                                                                || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION) && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenProtoFitSamplepopup(props.location.state.params.tnaid, childTask.ChildId, index, subIndex, childIndex,
                                                                                                                        childTask.Name, childTask.TaskName, childTask.StatusID, props.location.state.params.styleId, childTask.arrDependency, childTask.intDependency
                                                                                                                    )}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button>
                                                                                                                :
                                                                                                                (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION
                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION
                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION
                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION
                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION) && childTask.StatusID === 3 && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='View Submission'
                                                                                                                        onClick={() => OpenProtoFitSamplepopup(props.location.state.params.tnaid, childTask.ChildId, index, subIndex, childIndex,
                                                                                                                            childTask.Name, childTask.TaskName, childTask.StatusID, props.location.state.params.styleId
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye"></i>
                                                                                                                    </button>

                                                                                                                    :
                                                                                                                    (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_SUBMISSION || childTask.TaskName.toLowerCase() === StaticTask.SMS_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_SUBMISSION
                                                                                                                        || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_SUBMISSION) && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                            <i className="fa fa-send"></i>
                                                                                                                        </button>
                                                                                                                        : ''

                                                                                                            }



                                                                                                            {(childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                                                                                                                || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                                                                                                                || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                                                                                                                || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                                                                                                                || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Approval Form"
                                                                                                                    onClick={() => OpenProtoFitApprovalpopup(childTask.ChildId, index, subIndex, childIndex, childTask.TaskName, childTask.StatusID
                                                                                                                        , props.location.state.params.styleId, childTask.TaskName === 'Proto Sample Approval' ? 7 : childTask.TaskName === 'Fit Sample Approval' ? 8 : 9, childTask.arrDependency, childTask.intDependency)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check"></i>
                                                                                                                </button>
                                                                                                                :
                                                                                                                (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL
                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.SIZE_SET_APPROVAL
                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.PRE_PRODUCTION_APPROVAL
                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.SHIPMENT_APPROVAL
                                                                                                                    || childTask.TaskName.toLowerCase() === StaticTask.PHOTO_APPROVAL) && childTask.StatusID === 3 && childTask.ManualCloseComment === '' ?
                                                                                                                    <button type="button" className="btn btn-xs btn-success" title='Successfully Approved'
                                                                                                                        onClick={() => OpenProtoFitApprovalpopup(childTask.ChildId, index, subIndex, childIndex, childTask.TaskName, childTask.StatusID
                                                                                                                            , props.location.state.params.styleId, childTask.TaskName === 'Proto Sample Approval' ? 7 : childTask.TaskName === 'Fit Sample Approval' ? 8 : 9)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-eye"></i>
                                                                                                                    </button>

                                                                                                                    // :
                                                                                                                    // (childTask.TaskName.toLowerCase() === StaticTask.PROTO_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.FIT_SAMPLE_APPROVAL || childTask.TaskName.toLowerCase() === StaticTask.SMS_APPROVAL) && childTask.IsShowSubmitButton === 0 ?
                                                                                                                    //     <button type="button" className="btn btn-xs btn-warning" title='Already Updated' disabled>
                                                                                                                    //         <i className="fa fa-check"></i>
                                                                                                                    //     </button>
                                                                                                                    : ''

                                                                                                            }
                                                                                                            {childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 2 && childTask.StatusID !== 3 ?
                                                                                                                <button type="button" className="btn btn-xs btn-warning" title="Submit Form"
                                                                                                                    onClick={() => OpenAppearenceAfterWashpopup(props.location.state.params.tnaid, childTask.ChildId, props.location.state.params.styleId,
                                                                                                                        index, subIndex, childIndex, childTask.StatusID, props.location.state.params.styleId, childTask.arrDependency, childTask.intDependency)}
                                                                                                                >
                                                                                                                    <i className="fa fa-send"></i>
                                                                                                                </button>
                                                                                                                :
                                                                                                                childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 3 && childTask.StatusID !== 3 ?
                                                                                                                    <button type="button" className="btn btn-xs btn-warning" title="Approval Form"
                                                                                                                        onClick={() => OpenAppearenceAfterWashpopup(props.location.state.params.tnaid, childTask.ChildId, props.location.state.params.styleId,
                                                                                                                            index, subIndex, childIndex, childTask.StatusID, props.location.state.params.styleId)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-check"></i>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.StatusID === 3 && childTask.IsShowSubmitButton !== 0 && childTask.ManualCloseComment === '' ?
                                                                                                                        <button type="button" className="btn btn-xs btn-success" title='Successfully Submitted'
                                                                                                                            onClick={() => OpenAppearenceAfterWashpopup(props.location.state.params.tnaid, childTask.ChildId, props.location.state.params.styleId,
                                                                                                                                index, subIndex, childIndex, childTask.StatusID, props.location.state.params.styleId)}
                                                                                                                        >
                                                                                                                            <i className="fa fa-eye"></i>
                                                                                                                        </button>

                                                                                                                        // :
                                                                                                                        // childTask.TaskName.toLowerCase() === StaticTask.APPEARANCE_AFTER_WASH && childTask.IsShowSubmitButton === 0 ?
                                                                                                                        //     <button type="button" className="btn btn-xs btn-success" title='Already Updated' disabled>
                                                                                                                        //         <i className="fa fa-check"></i>
                                                                                                                        //     </button>
                                                                                                                        : ''

                                                                                                            }
                                                                                                            &nbsp;
                                                                                                            {
                                                                                                                // new Date() > new Date(childTask.EndDate) &&
                                                                                                                childTask.StatusID !== 3 && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') &&
                                                                                                                (childTask.TaskName !== 'Strike off Order to Nominated Supplier' && childTask.TaskName !== 'Strike off Receipt From Nominated Supplier' &&
                                                                                                                    childTask.TaskName !== 'Strike off Receipt from Buyer' && childTask.TaskName !== 'Trim Receipt From Buyer' &&
                                                                                                                    childTask.TaskName !== 'Trim Order to Nominated Supplier' && childTask.TaskName !== 'Trim Receipt from Nominated Supplier') && //childTask.ManualCloseComment === '' &&
                                                                                                                <button type="button" className={childTask.IsReviced === 0 ? "btn btn-xs btn-yellow" : "btn btn-xs btn-success"} title='Child Revise'
                                                                                                                    onClick={() => OpenRevicePopup(index, subIndex, childIndex, props.location.state.params.tnaid,
                                                                                                                        childTask.ChildId, childTask.TaskName, childTask.StartDate, childTask.EndDate, childTask.Duration, 'Sub', childTask.EndDateChange)}
                                                                                                                >
                                                                                                                    <i className="fa fa-retweet"></i>
                                                                                                                </button>
                                                                                                            }
                                                                                                            &nbsp;
                                                                                                            {
                                                                                                                // new Date() > new Date(childTask.EndDate) &&
                                                                                                                (childTask.StatusID === 3 || childTask.IsRescheduled === 1) && (childTask.ChildActualEndDate !== 'Manual' && childTask.ChildActualEndDate !== 'Existing') &&
                                                                                                                (childTask.TaskName !== 'Strike off Order to Nominated Supplier' && childTask.TaskName !== 'Strike off Receipt From Nominated Supplier' &&
                                                                                                                    childTask.TaskName !== 'Strike off Receipt from Buyer' && childTask.TaskName !== 'Trim Receipt From Buyer' &&
                                                                                                                    childTask.TaskName !== 'Trim Order to Nominated Supplier' && childTask.TaskName !== 'Trim Receipt from Nominated Supplier') && //childTask.ManualCloseComment === '' &&
                                                                                                                <button type="button" className={childTask.IsRescheduled === 0 ? "btn btn-xs btn-light" : "btn btn-xs btn-success"} title='Child Reschedule '
                                                                                                                    onClick={() => OpenReschedulePopup(index, subIndex, childIndex, props.location.state.params.tnaid,
                                                                                                                        childTask.ChildId, childTask.TaskName, childTask.StartDate, childTask.EndDate, childTask.Duration, 'Sub', childTask.EndDateChange, childTask.StatusID)}
                                                                                                                >
                                                                                                                    <i class="fa fa-calendar"></i>
                                                                                                                </button>
                                                                                                            }
                                                                                                            {
                                                                                                                childTask.IsDependencyStartPopup && !inputField.IsSingleTask &&
                                                                                                                <Modal show={childTask.IsDependencyStartPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseDependencyStartPopup(index, subIndex, childIndex, inputField.IsSingleTask)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Dependency Start / Stop History

                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>

                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Dependencytable">
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th>Date<span className="text-danger">*</span></th>
                                                                                                                                    <th>Comments<span className="text-danger">*</span></th>

                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>

                                                                                                                                <tr>
                                                                                                                                    <td >
                                                                                                                                        {
                                                                                                                                            <DatePicker className="form-control" name="DependencyDate"


                                                                                                                                                // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                                                                                                                selected={Date.parse(moment(childTask.DependencyDate, 'MM/DD/YYYY').toISOString())}
                                                                                                                                                onChange={event => handleChangeDependentStart(index, subIndex, childIndex, event, "CommentDate", false)}
                                                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                                                peekNextMonth
                                                                                                                                                showMonthDropdown
                                                                                                                                                dropdownMode="scroll"
                                                                                                                                                autoComplete="off"
                                                                                                                                                minDate={new Date()}
                                                                                                                                                readOnly={childTask.intDependency === 1 ? true : false}
                                                                                                                                                customInput={<CustomInputDependency />}
                                                                                                                                                maxDate={new Date()}
                                                                                                                                            />


                                                                                                                                        }

                                                                                                                                    </td>
                                                                                                                                    <td style={{ width: "600px" }} >
                                                                                                                                        <textarea
                                                                                                                                            rows="3"
                                                                                                                                            placeholder="Enter Comments here..."
                                                                                                                                            autoFocus onFocus={""} autoComplete="off"
                                                                                                                                            maxLength="500" name="EnterRemarks"
                                                                                                                                            value={childTask.DependencyComment}
                                                                                                                                            onChange={event => handleChangeDependentStart(index, subIndex, childIndex, event, "Comment", false)}
                                                                                                                                            style={{ width: "100%", border: submitDependencyStart && !childTask.DependencyComment ? '1px solid red' : '' }}>
                                                                                                                                        </textarea>
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                            </tbody>

                                                                                                                        </table>
                                                                                                                        {
                                                                                                                            <table className="table table-bordered">
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>Action</th>
                                                                                                                                        <th>Date</th>
                                                                                                                                        <th>Comments</th>
                                                                                                                                        <th>Created By</th>
                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                <tbody>
                                                                                                                                    {
                                                                                                                                        getDependencyStartList && getDependencyStartList.length !== 0 ?
                                                                                                                                            getDependencyStartList.map((item, itemIndex) => {
                                                                                                                                                return (
                                                                                                                                                    <>
                                                                                                                                                        <tr>
                                                                                                                                                            <td>
                                                                                                                                                                {item.dependencyStartstop === 1 ? "Stop" : "Start"}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.dependencyDate}

                                                                                                                                                            </td>

                                                                                                                                                            <td>
                                                                                                                                                                {item.comments}

                                                                                                                                                            </td>
                                                                                                                                                            <td>
                                                                                                                                                                {item.createdBy}

                                                                                                                                                            </td>


                                                                                                                                                        </tr>
                                                                                                                                                    </>

                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                            :
                                                                                                                                            <tr><td colSpan="4" className='norecordfound'><span>No Records Found</span></td></tr>

                                                                                                                                    }

                                                                                                                                </tbody>
                                                                                                                            </table>


                                                                                                                        }

                                                                                                                    </Modal.Body>
                                                                                                                    <Modal.Footer>
                                                                                                                        <Button variant="success" onClick={() => handleSaveDependencyStart(index, subIndex, childIndex, inputField.IsSingleTask, childTask.intDependency)}>

                                                                                                                            Save
                                                                                                                        </Button>
                                                                                                                    </Modal.Footer>
                                                                                                                </Modal>

                                                                                                            }

                                                                                                            {
                                                                                                                childTask.hasAllDependencyStartPopup && !inputField.IsSingleTask &&
                                                                                                                <Modal show={childTask.hasAllDependencyStartPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => CloseAllDependencyStartPopup(index, subIndex, childIndex, inputField.IsSingleTask, childTask.MultiDependencyComment, childTask.MultiDependencyDate)}>
                                                                                                                    <Modal.Header closeButton>
                                                                                                                        <Modal.Title>
                                                                                                                            Multi Dependency
                                                                                                                        </Modal.Title>
                                                                                                                    </Modal.Header>
                                                                                                                    <Modal.Body>

                                                                                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Dependencytable">
                                                                                                                            <thead>
                                                                                                                                <tr>
                                                                                                                                    <th>Date<span className="text-danger">*</span></th>
                                                                                                                                    <th>Comments<span className="text-danger">*</span></th>

                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>

                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        {
                                                                                                                                            <DatePicker className="form-control" name="DependencyDate"


                                                                                                                                                // selected={new Date(ManualInfo[0].manualCloseDate)}
                                                                                                                                                selected={Date.parse(moment(childTask.MultiDependencyDate, 'MM/DD/YYYY').toISOString())}
                                                                                                                                                onChange={event => handleChangeMultiDependentStart(index, subIndex, childIndex, event, "CommentDate", false)}
                                                                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                                                                peekNextMonth
                                                                                                                                                showMonthDropdown
                                                                                                                                                dropdownMode="scroll"
                                                                                                                                                autoComplete="off"
                                                                                                                                                minDate={new Date()}
                                                                                                                                                //  maxDate={new Date()}
                                                                                                                                                customInput={<CustomInputDependency />}

                                                                                                                                            />


                                                                                                                                        }

                                                                                                                                    </td>
                                                                                                                                    <td style={{ width: "600px" }} >
                                                                                                                                        <textarea
                                                                                                                                            rows="3"
                                                                                                                                            placeholder="Enter Comments here..."
                                                                                                                                            autoFocus onFocus={""} autoComplete="off"
                                                                                                                                            maxLength="500" name="EnterRemarks"
                                                                                                                                            value={childTask.MultiDependencyComment}
                                                                                                                                            onChange={event => handleChangeMultiDependentStart(index, subIndex, childIndex, event, "Comment", false)}
                                                                                                                                            style={{ width: "100%", border: submitDependencyStart && !childTask.MultiDependencyComment ? '1px solid red' : '' }}>
                                                                                                                                        </textarea>
                                                                                                                                    </td>

                                                                                                                                </tr>
                                                                                                                            </tbody>

                                                                                                                        </table>
                                                                                                                    </Modal.Body>
                                                                                                                    <Modal.Footer>
                                                                                                                        <Button variant="success" onClick={() => handleSaveAllDependencyStart(index, subIndex, childIndex, inputField.TaskID, inputField.IsSingleTask)}>

                                                                                                                            Save
                                                                                                                        </Button>
                                                                                                                    </Modal.Footer>
                                                                                                                </Modal>

                                                                                                            }

                                                                                                        </>

                                                                                                        : ''
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </Fragment>

                                                                    ))

                                                                }

                                                            </Fragment>
                                                        ))
                                                    }
                                                    {
                                                        inputFields.length === 0 ?
                                                            <tr>
                                                                <td colSpan="11" className='norecordfound'><span>No Records Found</span></td>
                                                                {/* {
                                                                    inputFields.length === 0 ?
                                                                        <tr>
                                                                            <td colSpan="10" className='norecordfound'><span>No Records Found</span></td>

                                                                        </tr> : ''
                                                                } */}
                                                            </tr> : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>

                                        <span className="btn btn-primary"
                                            onClick={
                                                () => ResetOperation("Back")
                                            }
                                        >
                                            <i className="fa fa-arrow-circle-left"></i>
                                            &nbsp;Back</span>

                                        <span className="btn btn-danger"
                                            onClick={
                                                () => ResetOperation()
                                            } >
                                            <i className="fa fa-times"></i>
                                            &nbsp;Reset</span>


                                        <button type="button" className="btn btn-success"
                                            onClick={UpdateTNA}
                                        >
                                            <i className="fa fa-check right"></i>
                                            &nbsp;{isLastEdit ? 'Update' : 'Save'}</button>

                                    </div>
                                </div>
                            </div>



                            {getLapmodal === true ? (
                                <Modal
                                    show={getLapmodal === true}
                                    size="xl"
                                    dragable
                                    backdrop="static"
                                    keyboard={false}
                                    dialogClassName="commonwidth"
                                    onHide={() => handleLabDip()}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {
                                                TaskStatus === 3 && editLabdipSubmission === false ?
                                                    "View " : editLabdipSubmission === true ? 'Update ' : ''
                                            }
                                            Lab Dip Submission

                                            {
                                                TaskStatus === 3 ?
                                                    <>
                                                        {
                                                            (SwatchStatus === 0 || SwatchStatus === 1) ?
                                                                <span title='Download Swatch Card' style={{ 'margin-left': '810px' }} onClick={() => setLabdipswatchDownload(true)}>
                                                                    <i class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i>
                                                                </span>
                                                                : ''

                                                        }
                                                        &nbsp;

                                                        {
                                                            SwatchStatus === 1 ?
                                                                <span title='Upload Submitted Swatch Card'>
                                                                    <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setLabdipswatchUpload(true)}></i>
                                                                </span>
                                                                : ''

                                                        }
                                                        &nbsp;
                                                        {
                                                            SwatchStatus === 2 ?
                                                                <span title='Edit Form' onClick={() => seteditLabdipSubmission(true)}
                                                                    style={{ pointerEvents: Labdipdisable === true ? "none" : '', opacity: Labdipdisable === true ? '0.6' : '', 'margin-left': '680px' }}
                                                                    className="btn btn-info btn-xs edit">
                                                                    <i className="fa fa-edit"></i>
                                                                </span> :
                                                                <span title='Edit Form' onClick={() => seteditLabdipSubmission(true)}
                                                                    style={{ pointerEvents: Labdipdisable === true ? "none" : '', opacity: Labdipdisable === true ? '0.6' : '' }}
                                                                    className="btn btn-info btn-xs edit">
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                        }

                                                        &nbsp;
                                                        <span title='Delete Form'
                                                            style={{ pointerEvents: Labdipdisable === true ? "none" : '', opacity: Labdipdisable === true ? '0.6' : '' }}
                                                            className="btn btn-danger btn-xs delete" onClick={() => setdeleteLabdipSubmission(true)}>
                                                            <i className="fa fa-trash-o"></i>
                                                        </span>
                                                    </> : ''
                                            }
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <LabdibSubmission props={props}
                                            TNAId={TNAId}
                                            StyleId={StyleId}
                                            LabdibFabricInfoId={LabdibFabricInfoId}
                                            LapdipSubmissionCallback={LapdipSubmissionCallback}
                                            commonParams={commonSubmitApprovalParams}
                                            Indexes={Indexes}
                                            values={values}
                                            editLabdipSubmission={editLabdipSubmission}
                                            deleteLabdipSubmission={deleteLabdipSubmission}
                                            TaskStatus={TaskStatus}
                                            LabdipswatchDownload={LabdipswatchDownload}
                                            LabdipswatchUpload={LabdipswatchUpload}
                                            SwatchStatus={SwatchStatus}
                                            AllChildvalues={AllChildvalues}
                                        > </LabdibSubmission>
                                    </Modal.Body>
                                    <Modal.Footer></Modal.Footer>
                                </Modal>
                            ) : (
                                ""
                            )}
                            {getOpenLabdipApppopup === true ? (
                                <Modal
                                    show={getOpenLabdipApppopup === true}
                                    size="xl"
                                    dragable
                                    backdrop="static"
                                    dialogClassName="commonwidth"
                                    keyboard={false}
                                    onHide={() => handleLabDipApp()}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            {

                                                TaskStatus === 3 && editLabdipApproval === false ?
                                                    "View " : editLabdipApproval === true ? 'Update ' : ''
                                            }
                                            Lab Dip  Approval

                                            {

                                                TaskStatus === 3 ?
                                                    <>


                                                        {
                                                            SwatchStatus !== 3 ?
                                                                <span title='Upload Approved Swatch Card' style={{ 'margin-left': '810px' }}>
                                                                    <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setLabdipswatchUpload(true)}></i>
                                                                </span>
                                                                : ''

                                                        }
                                                        {/* &nbsp;
                                                            {
                                                                SwatchStatus === 3 ?
                                                                    <span title='Download Approved Swatch Card' style={{ 'margin-left': '670px' }}>
                                                                        <i class="fa fa-lg fa-download" aria-hidden="true" onClick={() => setLabdipswatchDownload(true)}></i>
                                                                    </span>
                                                                    : ''

                                                            } */}
                                                        &nbsp;
                                                        {
                                                            SwatchStatus === 3 ?
                                                                <span title='Edit Form' style={{ 'margin-left': '700px' }} onClick={() => seteditLabdipApproval(true)}
                                                                    className="btn btn-info btn-xs edit">
                                                                    <i className="fa fa-edit"></i>
                                                                </span> :
                                                                <span title='Edit Form' onClick={() => seteditLabdipApproval(true)}
                                                                    className="btn btn-info btn-xs edit">
                                                                    <i className="fa fa-edit"></i>
                                                                </span>

                                                        }
                                                        {/* <span title='Edit Form' onClick={() => seteditLabdipApproval(true)}
                                                            className="btn btn-info btn-xs edit">
                                                            <i className="fa fa-edit"></i>
                                                        </span> */}

                                                        &nbsp;
                                                        <span title='Delete Form'
                                                            className="btn btn-danger btn-xs delete" onClick={() => setdeleteLabdipApproval(true)}>
                                                            <i className="fa fa-trash-o"></i>
                                                        </span>
                                                    </> : ''
                                            }
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <LabdibApproval props={props}
                                            LapdipApprovalCallback={LapdipApprovalCallback}
                                            StyleId={StyleId}
                                            LabdibFabricInfoId={LabdibFabricInfoId}
                                            SubmittedOptionListId={SubmittedOptionListId}
                                            editLabdipApproval={editLabdipApproval}
                                            deleteLabdipApproval={deleteLabdipApproval}
                                            TaskStatus={TaskStatus}
                                            LabdipApprovalList={LabdipApprovalList}
                                            LabdipswatchDownload={LabdipswatchDownload}
                                            LabdipswatchUpload={LabdipswatchUpload}
                                            SwatchStatus={SwatchStatus}
                                            AllChildvalues={AllChildvalues}
                                        ></LabdibApproval>
                                    </Modal.Body>
                                    <Modal.Footer></Modal.Footer>
                                </Modal>
                            ) : (
                                ""
                            )}
                            {
                                strikeoffModalpopup === true ?

                                    <Modal dialogClassName="commonwidth" show={strikeoffModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleStrikeoffSub()}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {
                                                    TaskStatus === 3 && editStrikeOffSubmission === false ?
                                                        "View " : editStrikeOffSubmission === true ? 'Update ' : ''
                                                }
                                                Strike Off Submission
                                                {
                                                    TaskStatus === 3 ?
                                                        <>
                                                            {
                                                                (SwatchStatus === 0 || SwatchStatus === 1) ?
                                                                    <span title='Download Swatch Card' style={{ 'margin-left': '788px' }} onClick={() => setStrikeoffswatchDownload(true)}>
                                                                        <i class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i>
                                                                    </span>
                                                                    : ''

                                                            }
                                                            &nbsp;

                                                            {
                                                                SwatchStatus === 1 ?
                                                                    <span title='Upload Submitted Swatch Card'>
                                                                        <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setStrikeoffswatchUpload(true)}></i>
                                                                    </span>
                                                                    : ''

                                                            }
                                                            &nbsp;
                                                            {
                                                                SwatchStatus === 2 ?
                                                                    <span title='Edit Form'
                                                                        style={{ pointerEvents: Strikeoffdisable === true ? "none" : '', opacity: Strikeoffdisable === true ? '0.6' : '', 'margin-left': '650px' }} onClick={() => seteditStrikeOffSubmission(true)}
                                                                        className="btn btn-info btn-xs edit">
                                                                        <i className="fa fa-edit"></i>
                                                                    </span> :
                                                                    <span title='Edit Form' onClick={() => seteditStrikeOffSubmission(true)}
                                                                        style={{ pointerEvents: Strikeoffdisable === true ? "none" : '', opacity: Strikeoffdisable === true ? '0.6' : '' }}
                                                                        className="btn btn-info btn-xs edit">
                                                                        <i className="fa fa-edit"></i>
                                                                    </span>
                                                            }


                                                            &nbsp;
                                                            <span title='Delete Form'
                                                                style={{ pointerEvents: Strikeoffdisable === true ? "none" : '', opacity: Strikeoffdisable === true ? '0.6' : '' }}
                                                                className="btn btn-danger btn-xs delete" onClick={() => setdeleteStrikeOffSubmission(true)}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </> : ''
                                                }
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <StrikeOffSubmission props={props}
                                                StrikeoffSubmissionCallback={StrikeoffSubmissionCallback}
                                                TNAId={TNAId}
                                                TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                                commonParams={commonSubmitApprovalParams}
                                                Indexes={Indexes}
                                                values={values}
                                                editStrikeOffSubmission={editStrikeOffSubmission}
                                                deleteStrikeOffSubmission={delteStrikeOffSubmission}
                                                TaskStatus={TaskStatus}
                                                StrikeoffswatchDownload={StrikeoffswatchDownload}
                                                StrikeoffswatchUpload={StrikeoffswatchUpload}
                                                SwatchStatus={SwatchStatus}
                                                AllChildvalues={AllChildvalues}
                                            ></StrikeOffSubmission>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }
                            {
                                getstrikeoffApppopup === true ?

                                    <Modal dialogClassName="commonwidth" show={getstrikeoffApppopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleStrikeOffApp(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {
                                                    TaskStatus === 3 && editStrikeOffApproval === false ?
                                                        "View " : editStrikeOffApproval === true ? 'Update ' : ''

                                                }
                                                Strike Off Approval
                                                {
                                                    TaskStatus === 3 ?
                                                        <>
                                                            {
                                                                SwatchStatus !== 3 ?
                                                                    <span title='Upload Approved Swatch Card' style={{ 'margin-left': '810px' }}>
                                                                        <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setStrikeoffswatchUpload(true)}></i>
                                                                    </span>
                                                                    : ''

                                                            }
                                                            {/* &nbsp;
                                                            {
                                                                SwatchStatus === 3 ?
                                                                    <span title='Download Approved Swatch Card' style={{ 'margin-left': '670px' }}>
                                                                        <i class="fa fa-lg fa-download" aria-hidden="true" onClick={() => setLabdipswatchDownload(true)}></i>
                                                                    </span>
                                                                    : ''

                                                            } */}
                                                            &nbsp;
                                                            {
                                                                SwatchStatus === 3 ?
                                                                    <span title='Edit Form' style={{ 'margin-left': '700px' }} onClick={() => seteditStrikeOffApproval(true)}
                                                                        className="btn btn-info btn-xs edit">
                                                                        <i className="fa fa-edit"></i>
                                                                    </span> :
                                                                    <span title='Edit Form' onClick={() => seteditStrikeOffApproval(true)}
                                                                        className="btn btn-info btn-xs edit">
                                                                        <i className="fa fa-edit"></i>
                                                                    </span>

                                                            }

                                                            &nbsp;
                                                            <span title='Delete Form'
                                                                className="btn btn-danger btn-xs delete" onClick={() => setdeleteStrikeOffApproval(true)}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </> : ''
                                                }
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <StrikeApproval props={props}
                                                StrikeoffApprovalCallback={StrikeoffApprovalCallback}
                                                TNAStrikeOffRefInfoId={TNAStrikeOffRefInfoId}
                                                SubmittedOptionListId={SubmittedOptionListId}
                                                ColorAppCommentsId={ColorAppCommentsId}
                                                editStrikeOffApproval={editStrikeOffApproval}
                                                delteStrikeOffApproval={delteStrikeOffApproval}
                                                TaskStatus={TaskStatus}
                                                StrikeoffswatchDownload={StrikeoffswatchDownload}
                                                StrikeoffswatchUpload={StrikeoffswatchUpload}
                                                SwatchStatus={SwatchStatus}
                                                AllChildvalues={AllChildvalues}
                                            ></StrikeApproval>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }

                            {
                                getTrimmodalpopup === true ?

                                    <Modal dialogClassName="commonwidth" show={getTrimmodalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handletrimmPopupClose()}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {
                                                    TaskStatus === 3 && editTrimSubmission === false ?
                                                        "View " : editTrimSubmission === true ? 'Update ' : ''
                                                }
                                                Trim Submission
                                                {
                                                    TaskStatus === 3 ?
                                                        <>
                                                            {
                                                                (SwatchStatus === 0 || SwatchStatus === 1) ?
                                                                    <span title='Download Swatch Card' style={{ 'margin-left': '840px' }} onClick={() => setTrimswatchDownload(true)}>
                                                                        <i class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i>
                                                                    </span>
                                                                    : ''

                                                            }
                                                            &nbsp;

                                                            {
                                                                SwatchStatus === 1 ?
                                                                    <span title='Upload Submitted Swatch Card'>
                                                                        <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setTrimswatchUpload(true)}></i>
                                                                    </span>
                                                                    : ''

                                                            }
                                                            &nbsp;
                                                            {
                                                                SwatchStatus === 2 ?
                                                                    <span title='Edit Form'
                                                                        style={{ pointerEvents: Trimdisable === true ? "none" : '', opacity: Trimdisable === true ? '0.6' : '', 'margin-left': '680px' }} onClick={() => seteditTrimSubmission(true)}
                                                                        className="btn btn-info btn-xs edit">
                                                                        <i className="fa fa-edit"></i>
                                                                    </span> :
                                                                    <span title='Edit Form' onClick={() => seteditTrimSubmission(true)}
                                                                        style={{ pointerEvents: Trimdisable === true ? "none" : '', opacity: Trimdisable === true ? '0.6' : '' }}
                                                                        className="btn btn-info btn-xs edit">
                                                                        <i className="fa fa-edit"></i>
                                                                    </span>
                                                            }

                                                            &nbsp;
                                                            <span title='Delete Form'
                                                                style={{ pointerEvents: Trimdisable === true ? "none" : '', opacity: Trimdisable === true ? '0.6' : '' }}
                                                                className="btn btn-danger btn-xs delete" onClick={() => setdeleteTrimSubmission(true)}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </> : ''
                                                }
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <TrimSubmission props={props}
                                                TrimSubmissionCallback={TrimSubmissionCallback}
                                                TNAId={TNAId}
                                                TNATrimRefInfoId={TNATrimRefInfoId}
                                                commonParams={commonSubmitApprovalParams}
                                                Indexes={Indexes}
                                                values={values}
                                                editTrimSubmission={editTrimSubmission}
                                                delteTrimSubmission={delteTrimSubmission}
                                                TaskStatus={TaskStatus}
                                                TrimswatchDownload={TrimswatchDownload}
                                                TrimswatchUpload={TrimswatchUpload}
                                                SwatchStatus={SwatchStatus}
                                                AllChildvalues={AllChildvalues}
                                            ></TrimSubmission>


                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }
                            {
                                getTrimApprovalpopup === true ?

                                    <Modal dialogClassName="commonwidth" show={getTrimApprovalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleTrimappPopupClose()}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {
                                                    TaskStatus === 3 && editTrimApproval === false ?
                                                        "View " : editTrimApproval === true ? 'Update ' : ''

                                                }Trim Approval
                                                {
                                                    TaskStatus === 3 ?
                                                        <>
                                                            {
                                                                SwatchStatus !== 3 ?
                                                                    <span title='Upload Approved Swatch Card' style={{ 'margin-left': '870px' }}>
                                                                        <i class="fa fa-lg fa-upload" aria-hidden="true" onClick={() => setTrimswatchUpload(true)}></i>
                                                                    </span>
                                                                    : ''

                                                            }
                                                            {/* &nbsp;
                                                            {
                                                                SwatchStatus === 3 ?
                                                                    <span title='Download Approved Swatch Card' style={{ 'margin-left': '670px' }}>
                                                                        <i class="fa fa-lg fa-download" aria-hidden="true" onClick={() => setLabdipswatchDownload(true)}></i>
                                                                    </span>
                                                                    : ''

                                                            } */}
                                                            &nbsp;
                                                            {
                                                                SwatchStatus === 3 ?
                                                                    <span title='Edit Form' style={{ 'margin-left': '700px' }} onClick={() => seteditTrimApproval(true)}
                                                                        className="btn btn-info btn-xs edit">
                                                                        <i className="fa fa-edit"></i>
                                                                    </span> :
                                                                    <span title='Edit Form' onClick={() => seteditTrimApproval(true)}
                                                                        className="btn btn-info btn-xs edit">
                                                                        <i className="fa fa-edit"></i>
                                                                    </span>

                                                            }
                                                            &nbsp;
                                                            <span title='Delete Form'
                                                                className="btn btn-danger btn-xs delete" onClick={() => setdeleteTrimApproval(true)}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </> : ''
                                                }
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <TrimApproval props={props}
                                                TrimApprovalCallback={TrimApprovalCallback}
                                                TNATrimRefInfoId={TNATrimRefInfoId}
                                                SubmittedOptionListId={SubmittedOptionListId}
                                                ColorAppCommentsId={ColorAppCommentsId}
                                                editTrimApproval={editTrimApproval}
                                                deleteTrimApproval={deleteTrimApproval}
                                                TaskStatus={TaskStatus}
                                                TrimswatchDownload={TrimswatchDownload}
                                                TrimswatchUpload={TrimswatchUpload}
                                                SwatchStatus={SwatchStatus}
                                                AllChildvalues={AllChildvalues}
                                            ></TrimApproval>

                                        </Modal.Body>
                                        <Modal.Footer>
                                        </Modal.Footer>

                                    </Modal> : ''
                            }

                            {
                                getSampleSubModalpopup === true ?

                                    <Modal dialogClassName="commonwidth" show={getSampleSubModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotoSubApp(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {
                                                    TaskStatus === 3 && editProtoFitSmsSubmission === false ?
                                                        "View " : editProtoFitSmsSubmission === true ? 'Update ' : ''
                                                }
                                                {ParentTask}
                                                {
                                                    TaskStatus === 3 ?
                                                        <>

                                                            <span
                                                                style={{ pointerEvents: protofitsmsDisable === true ? "none" : '', opacity: protofitsmsDisable === true ? '0.6' : '', 'margin-left': '800px' }} title='Edit Form' onClick={() => seteditProtoFitSmsSubmission(true)}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span title='Delete Form'
                                                                style={{ pointerEvents: protofitsmsDisable === true ? "none" : '', opacity: protofitsmsDisable === true ? '0.6' : '' }}
                                                                className="btn btn-danger btn-xs delete"
                                                                onClick={() => setdeleteProtoFitSmsSubmission(true)}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>

                                                        </> : ''

                                                }
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <ProtoFitSMSSubmission props={props}
                                                ProtoFitSMSSubmissionCallback={ProtoFitSMSSubmissionCallback}
                                                TNAId={TNAId}
                                                TNAProtoFitId={TNAProtoFitId}
                                                ChildTask={ChildTask}
                                                ParentTask={ParentTask}
                                                editProtoFitSmsSubmission={editProtoFitSmsSubmission}
                                                delteProtoFitSmsSubmission={delteProtoFitSmsSubmission}
                                                TaskStatus={TaskStatus}
                                                StyleId={StyleId}
                                            ></ProtoFitSMSSubmission>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }
                            {
                                getSampleAppModalpopup === true ?

                                    <Modal dialogClassName="commonwidth" show={getSampleAppModalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => handleprotofitApp(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {
                                                    TaskStatus === 3 && editProtoFitSmsApproval === false ?
                                                        "View " : editProtoFitSmsApproval === true ? 'Update ' : ''

                                                }
                                                {ParentTask}
                                                {
                                                    TaskStatus === 3 ?
                                                        <>
                                                            <span title='Edit Form' style={{ 'margin-left': '1020px' }} onClick={() => seteditProtoFitSmsApproval(true)}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span title='Delete Form'
                                                                className="btn btn-danger btn-xs delete" onClick={() => setdeleteProtoFitSmsApproval(true)}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </> : ''
                                                }
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <ProtoFitSMSApproval props={props}
                                                ProtoFitAprovalCallback={ProtoFitAprovalCallback}
                                                TNAId={TNAId}
                                                TNAProtoFitId={TNAProtoFitId}
                                                ChildTask={ChildTask}
                                                ParentTask={ParentTask}
                                                editProtoFitSmsApproval={editProtoFitSmsApproval}
                                                delteProtoFitSmsApproval={delteProtoFitSmsApproval}
                                                TaskStatus={TaskStatus}
                                                StyleId={StyleId}
                                            ></ProtoFitSMSApproval>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''
                            }
                            {
                                getApperancemodalpopup === true ?

                                    <Modal dialogClassName="commonwidth" show={getApperancemodalpopup === true} size="xl" dragable backdrop="static" keyboard={false} onHide={() => HandleAfterWashList(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {
                                                    TaskStatus === 3 && editAppearanceAfterWash === false ?
                                                        "View " : editAppearanceAfterWash === true ? 'Update ' : ''

                                                }
                                                Appearance After Wash
                                                {
                                                    TaskStatus === 3 ?
                                                        <>
                                                            <span title='Edit Form' style={{ 'margin-left': '620px' }} onClick={() => seteditAppearanceAfterWash(true)}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span title='Delete Form'
                                                                className="btn btn-danger btn-xs delete" onClick={() => setdeleteAppearanceAfterWash(true)}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </> : ''
                                                }
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <AfterWashList props={props}
                                                AppareanceTestSubmissionCallback={AppareanceTestSubmissionCallback}
                                                TNAId={TNAId}
                                                StyleId={StyleId}
                                                AppareanceTestId={AppareanceTestId}
                                                editAppearanceAfterWash={editAppearanceAfterWash}
                                                deleteAppearanceAfterWash={deleteAppearanceAfterWash}
                                                TaskStatus={TaskStatus}
                                            ></AfterWashList>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>

                                    </Modal> : ''

                            }
                            {
                                isSkuAndStyleDetailPopup &&
                                <Modal show={isSkuAndStyleDetailPopup} size="lg" dragable backdrop="static" keyboard={false} onHide={() => hideSkuAndStyleDetail()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            SKU and Size Details
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>SKU Name</th>
                                                    <th>Size Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{SkuAndStyleDetails.skuNameList}</td>
                                                    <td>{SkuAndStyleDetails.sizeNameList ? SkuAndStyleDetails.sizeNameList : " - "}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="button" className="btn btn-danger" disabled={buttonLoader} onClick={hideSkuAndStyleDetail}>
                                            <i className="fa fa-times"></i>&nbsp;
                                            Close
                                        </button>
                                    </Modal.Footer>
                                </Modal>
                            }
                            {
                                getIDSingleTask.showPopupDelete ? <SweetAlertPopup data={
                                    getIDSingleTask.Params
                                }
                                    deleteCallback={delCallback}
                                    showpopup={true}
                                    pageActionId={FinalinspectionID}
                                    Msg={"Final Inspection deleted successfully"} /> : null
                            }
                            {
                                getIDQATask.showPopupDelete ? <SweetAlertPopup data={
                                    getIDQATask.Params
                                }
                                    deleteCallback={delQaCallback}
                                    showpopup={true}
                                    pageActionId={QAInspectionId}
                                    Msg={"QA Inspection deleted successfully"} /> : null
                            }
                            {
                                getIDTestTask.showPopupDelete ? <SweetAlertPopup data={
                                    getIDTestTask.Params
                                }
                                    deleteCallback={delCallback}
                                    showpopup={true}
                                    pageActionId={TestingMainlist}
                                    Msg={"Test Inspection deleted successfully"} /> : null
                            }
                            {getsID.showPopupDelete ? <SweetAlertPopup

                                // data={''}
                                data={
                                    getsID.Params
                                }
                                deleteCallback={DeleteCallback}
                                showpopup={true}
                                pageActionId={getsID.TNAManualClose}
                                Msg={'Manual Close Deleted Sucessfully'}
                            /> : null}
                            {
                                getid.showPopupConfirm ? <ConfirmationPopup props={props} data={
                                    getid.Params
                                }
                                    deleteCallback={Delcallback1}
                                    showpopup={true}
                                /> : null
                            }


                            {getID.showPopupDelete ? <SweetAlertPopup
                                // data={''}
                                data={
                                    getID.Params
                                }
                                deleteCallback={DeleteCallbacks}
                                showpopup={true}
                                pageActionId={getID.TNAManualCloses}
                                Msg={'Manual Close Deleted Sucessfully'}
                            /> : null}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </Fragment>


    );
}

export default EditTNAView;


function ManualCommentAdd(values, index, IsParentDateShow, CloseDate) {

    for (let indexSubTaskList = 0; indexSubTaskList < values[index].SubTaskList.length; indexSubTaskList++) {
        for (let indexSubTaskList1 = 0; indexSubTaskList1 < values[index].SubTaskList[indexSubTaskList].ChildTaskList.length; indexSubTaskList1++) {
            if (values[index].SubTaskList[indexSubTaskList].ChildTaskList[indexSubTaskList1].ChildActualEndDate === null) {
                IsParentDateShow = true;
            }

        }
    }

    if (!IsParentDateShow) {
        values[index].ActualEndDate = CloseDate;
        values[index].StrActualEndDate = CloseDate;

    }
    return IsParentDateShow;
}

function DateAffectDelete(values, Indexes, IsParentDateShow) {

    for (let indexSubTaskList = 0; indexSubTaskList < values[Indexes.Index].SubTaskList.length; indexSubTaskList++) {
        for (let indexSubTaskList1 = 0; indexSubTaskList1 < values[Indexes.Index].SubTaskList[indexSubTaskList].ChildTaskList.length; indexSubTaskList1++) {
            if (values[Indexes.Index].SubTaskList[indexSubTaskList].ChildTaskList[indexSubTaskList1].ChildActualEndDate === null) {
                IsParentDateShow = true;
            }

        }
    }

    if (IsParentDateShow) {
        values[Indexes.Index].ActualEndDate = null;
        values[Indexes.Index].StrActualEndDate = null;
        values[Indexes].StatusID = 1; // For 
    }
    return IsParentDateShow;
}

function DateAffectReschedule(values, Indexes, IsParentDateShow) {

    for (let indexSubTaskList = 0; indexSubTaskList < values[Indexes].SubTaskList.length; indexSubTaskList++) {
        for (let indexSubTaskList1 = 0; indexSubTaskList1 < values[Indexes].SubTaskList[indexSubTaskList].ChildTaskList.length; indexSubTaskList1++) {
            if (values[Indexes].SubTaskList[indexSubTaskList].ChildTaskList[indexSubTaskList1].ChildActualEndDate === null || values[Indexes].SubTaskList[indexSubTaskList].ChildTaskList[indexSubTaskList1].ChildActualEndDate === '') {
                IsParentDateShow = true;
            }

        }
    }

    if (IsParentDateShow) {
        ;
        values[Indexes].ActualEndDate = null;
        values[Indexes].ManualCloseDate = null;
        //values[Indexes].RouteApplyStatus = 1;
        values[Indexes].StatusID = 1;
        values[Indexes].IsRescheduled = 1;

        // values[Indexes.Index].ActualEndDate = null;
    }
    return IsParentDateShow;
}