import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react';


import SearchRetain from "../../../Common/SearchRetain";
import TestFieldService from './../../../../services/Master/testfieldservice';
import styleService from '../../../../services/Style/styleService';
import Reactselect from 'react-select';
import DatePicker from "react-datepicker";
import BrandStandardFabric from './BrandStandardFabric';
import BrandStandardGarment from './BrandStandardGarment';
import BrandStandardStrikeOff from './BrandStandardStrikeOff';
import BrandStandardYarn from './BrandStandardYarn';
import BrandStandardTrims from './BrandStandardTrims';
import { retrieveGender } from './../../../../actions/gender';
import { retrieveColorDepth } from './../../../../actions/colordepth';
import { retrieveTestField } from './../../../../actions/testfield';
import { retrieveFabricType } from './../../../../actions/fabricType';
import BrandWiseStandardService from './../../../../services/Master/Brandwisestandard';
import { useDispatch, useSelector } from "react-redux";
import StyleService from "./../../../../services/Style/styleService";
import FabricService from '../../../../services/Master/FabricService';
import Nodify from "../../../Common/ReactNotification";
import ReactNotification from 'react-notifications-component';
import useFullPageLoader from '../../../../hooks/useFullPageLoader';
import ConfirmationPopup from "../../../Common/ConfirmationPopup";

const BrandWiseStandardRegister = (props, { AddColorDepthCallbacktoParent }) => {

    const EmptyInputFields = {
        BrandStandardID: 0
        , BuyerID: 0
        , BrandID: 0
        , InternalRefID: ''
        , BuyerVersionDate: ''
        , ReceivedDate: ''
        , EffectFromDate: ''
    };
    const [inputFields, setInputFields] = useState([EmptyInputFields]);
    //options
    const [getBuyerOptions, setBuyerOptions] = useState([]);
    const [getBrandOptions, setBrandOptions] = useState([]);
    const [getGenderOptions, setGenderOptions] = useState([]);
    const [getCurrentBuyerID, setCurrentBuyerID] = useState([]);
    const [getCurrentBrandID, setCurrentBrandID] = useState([]);
    const [getColorDepthOptions, setColorDepthOptions] = useState([]);
    const [getFieldOptions, setFieldOptions] = useState([]);
    const [getGroupTypeFabricOptions, setGroupTypeFabricOptions] = useState([]);
    // const [getGroupTypeOptions, setGroupTypeOptions] = useState([]);
    const [getResultReqType, setResultReqType] = useState([]);
    const [getIsReloadColorDepthOfParent, setIsReloadColorDepthOfParent] = useState({});
    const [getIsReloadTestFieldOfParent, setIsReloadTestFieldOfParent] = useState({});
    const [getFabricTestNames, setFabricTestNames] = useState([]);
    const [getStandardOptions, setStandardOptions] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getFabricTabData, setFabricTabData] = useState([]);
    const [getAllTabData, setAllTabData] = useState([]);
    const [getYarnTabData, setYarnTabData] = useState([]);
    const [getGarmentTabData, setGarmentTabData] = useState([]);
    const [getStrikeOffTabData, setStrikeOffTabData] = useState([]);
    const [getTrimsTabData, setTrimsTabData] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getExistingBrandwiseList, setExistingBrandwiseList] = useState([]);
    const [getisParentSaveFailed, setisParentSaveFailed] = useState(false);
    const [getCurrentTab, setCurrentTab] = useState('FABRIC');
    const [getRedirectConfirmation, setRedirectConfirmation] = useState({ showPopupConfirm: false, Params: {} });
    const [getIsReloadTestStandardOfParent, gstIsReloadTestStandardOfParent] = useState({});
    let BuyerListOptions = [] = [];
    const [submitted, setSubmitted] = useState(false);
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    let styles1 = {
        control: styles => ({ ...styles, innerHeight: '100px', outerHeight: '100px' }),
    }

    const values = [...inputFields];
    const [getMaxEffectFromDate, setMaxEffectFromDate] = useState('');
    //reducer
    const reducerState = useSelector((state) => state);
    const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;
    const dispatch = useDispatch();
    const commentsGender = reducerState.gender.genderList;
    const commentsColorDepth = reducerState.ColorDepthReducer.ColorDepthList.item1;
    const commentsTestField = reducerState.testfield.TestFieldNameList.item1;

    const handleBuyerChange = (e) => {
        let values = [...inputFields];
        if (e !== null) {
            values[0].BuyerID = e.id;
            setCurrentBuyerID(e.id);
            StyleService.LoadBrandThemeStoryEndCustomer(1, e.id).then((response) => {
                if (response.data) {
                    setBrandOptions(response.data.filter((data) => {
                        return data.buyerMapID == 1;
                    }));
                }
            }).catch(() => { });
        }
        else if (e === null) {
            setCurrentBuyerID(0);
            values[0].InternalRefID = "";
            values[0].BuyerID = 0;
            values[0].BrandID = 0;
            setBrandOptions(null);
        }
        setInputFields(values);
    }

    const handleBrandChange = (e) => {
        let values = [...inputFields];
        if (e !== null) {
            values[0].BrandID = e.id;
            setCurrentBrandID(e.id);
            let InternalRefID = getExistingBrandwiseList.filter((data) => {
                return data.buyerID === values[0].BuyerID && data.brandID === values[0].BrandID;
            });
            if (InternalRefID.length == 0) {
                values[0].InternalRefID = 1;
            }
            else {
                values[0].InternalRefID = InternalRefID[0].internalRefID + 1;
            }
        }
        else if (e === null) {
            setCurrentBrandID(0);
            values[0].BrandID = 0;
            values[0].InternalRefID = "";
        }
        if (getExistingBrandwiseList.length > 0) {
            var MaxRefID = Math.max(...getExistingBrandwiseList.map(elements => {
                return elements.internalRefID;
            }));
            var MaxDate = getExistingBrandwiseList.filter(data => {
                return data.internalRefID == MaxRefID;
            })[0].formattedEffectFromDate;
            let [day, month, year] = MaxDate.split('/')
            const dateObj = new Date(+year, +month - 1, +day)
            setMaxEffectFromDate(dateObj);
        }
        else {
            setMaxEffectFromDate('');
        }
        setInputFields(values);
    }


    const TabRedirection = (tab) => {
        setCurrentTab(tab);
    }
    useEffect(() => {
       ;
        let GenderSizeID = 0;
        dispatch(retrieveGender(GenderSizeID));
        dispatch(retrieveColorDepth());
        dispatch(retrieveTestField());
        dispatch(retrieveFabricType(0, "F"));
        showLoader();
        FabricService.LoadFabricGroupTypeList().then((response) => {
            if (response.data) {
                setGroupTypeFabricOptions(response.data.item1);
            }
        }).catch(() => {
            hideLoader();
        });


        BrandWiseStandardService.LoadMappedTestsForBrandwiseStandard().then((response) => {
            if (response.data) {
               ;
                setFabricTestNames(response.data.item1.filter((data) => {

                    data.parentFields = [{
                        OrderID: 0
                        , CreatedOn: ''
                        , Group: ''
                        , FabricDetails: []
                        , FabricDetailsForEdit: ""
                        , IsExpand: 0
                        , StandardResultRequirement: [
                            {
                                CreatedOn: new Date()
                                , EffectFromDate: ''
                                //, StandardID: 0
                                , StandardID: 0
                                , StandardIDForEdit: ""
                                , GenderID: 0
                                , InternalGrid: [
                                    {
                                        ColorDepth: ""
                                        , ColorDepthForEdit: []
                                        , Field: ""
                                        , FieldForEdit: []
                                        , ResultType: 0
                                        , MinResult: ''
                                        , MaxResult: ''
                                    }
                                ]

                            }
                        ]

                    }];
                    //data.status = 1; // for initial assigned for ACTIVE status
                    return data;
                }));
                setStandardOptions((response.data.item2));
                hideLoader()
            }
        }).catch(() => { hideLoader() });

        BrandWiseStandardService.LoadBrandWiseStandardMasterList().then((response) => {
            setExistingBrandwiseList(response.data.item1);
        }).catch(() => { hideLoader() });

    }, [dispatch])

    useEffect(() => {
        dispatch(retrieveColorDepth());
    }, [dispatch, getIsReloadColorDepthOfParent]);

    useEffect(() => {
        dispatch(retrieveTestField());
    }, [dispatch, getIsReloadTestFieldOfParent]);

    useEffect(() => {
        if (commentsGender !== undefined && commentsGender.length !== 0) {
            let computedGenderOptions = commentsGender.map((data => {
                data.id = data.genderSizeID;
                data.value = data.genderSizeID;
                data.label = data.genderSizeName;
                data.name = data.genderSizeName;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setGenderOptions(computedGenderOptions);
        }
    }, [commentsGender]);

    useEffect(() => {
        if (commentsColorDepth !== undefined && commentsColorDepth.length !== 0) {
            let computedColorDepthOptions = commentsColorDepth.map((data => {
                data.id = data.colorDepthID;
                data.value = data.colorDepthID;
                data.label = data.colorDepth;
                data.name = data.colorDepth;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setColorDepthOptions(computedColorDepthOptions);
        }
    }, [commentsColorDepth]);

    useEffect(() => {
        if (commentsTestField !== undefined && commentsTestField.length !== 0) {
            let computedFieldOptions = commentsTestField.map((data => {
                data.id = data.testFieldID;
                data.value = data.testFieldID;
                data.label = data.testField;
                data.name = data.testField;
                return { id: data.id, name: data.name, label: data.label, value: data.value };
            }));
            setFieldOptions(computedFieldOptions);
        }
    }, [commentsTestField]);


    useEffect(() => {
        BrandWiseStandardService.LoadMappedTestsForBrandwiseStandard().then((response) => {
            if (response.data) {
                setStandardOptions((response.data.item2));
                gstIsReloadTestStandardOfParent([]);
            }
        }
        )
    }, [dispatch, getIsReloadTestStandardOfParent.length > 0]);

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
            />
        )
    }

    const handleTextBoxChange = (e) => {
        let Value = [...inputFields];
        if (e.target.name === "internalversionref") {
            if (e.target.value.trim() !== '') {
                setSubmitted(true);
            }
            else {
                setSubmitted(false);
            }
            Value[0].InternalRefID = e.target.value;
        }
        else if (e.target.name === "buyerversiondate") {
            let inputText = '';
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
                Value[0].BuyerVersionDate = inputText;
            }
            else {
                Value[0].BuyerVersionDate = "";
            }
        }
        setInputFields(Value);
    }

    const handleChangeDate = (index, e, FieldName) => {
        let Value = [...inputFields];
        if (FieldName === "ReceivedDate") {
            if (e !== null) {
                Value[0].ReceivedDate = e;
            }
            else {
                Value[0].ReceivedDate = null;
            }
            Value[0].ReceivedDate = e;
        }
        if (FieldName === "EffectFromDate") {
            if (e !== null) {
                Value[0].EffectFromDate = e;
            }
            else {
                Value[0].EffectFromDate = null;
            }
            Value[0].EffectFromDate = e;
        }
        setInputFields(Value);
    }

    const AddedColorDepthData = (isColorValueUpdated) => {
        setIsReloadColorDepthOfParent(isColorValueUpdated);
    }

    const AddedTestFieldData = (isFieldValueUpdated) => {
       ;
        setIsReloadTestFieldOfParent(isFieldValueUpdated);
    }


    useEffect(() => {
        styleService.LoadBuyer(2)
            .then((response) => {
                if (response.data) {
                    BuyerListOptions = response.data;
                    setBuyerOptions(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));
                }
            })
            .catch(() => { });
        BrandWiseStandardService.GetRequiredResultOption()
            .then((response) => {
                if (response.data) {
                    setResultReqType(response.data.item1);
                }
            })
            .catch(() => { });
    }, []);

    const ParentStatusUpdationCallBack = (testNameID, statusID) => {
        let Values = [...getFabricTestNames];
       ;
        Values.forEach(element => {
            if (element.testNameID === testNameID) {
                element.status = statusID
            }
        });
        setFabricTestNames(Values);
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/BrandWiseStandardList')
        } else {
            window.location.reload();
        }
    }

    const InsertBrandWiseStandardMaster = (e) => {
        setSubmitted(true);
        setButtonLoader(true);
        showLoader();
        let values = [...inputFields];
        if ((values[0].BuyerID === 0 || values[0].BuyerID === null || values[0].BuyerID === undefined)
            || (values[0].BrandID === 0 || values[0].BrandID === null || values[0].BrandID === undefined)
            || (values[0].BuyerVersionDate === "" || values[0].BuyerVersionDate === null || values[0].BuyerVersionDate === undefined)
            || (values[0].ReceivedDate === "" || values[0].ReceivedDate === null || values[0].ReceivedDate === undefined)
            || (values[0].EffectFromDate === "" || values[0].EffectFromDate === null || values[0].EffectFromDate === undefined)) {
            Nodify('Warning!', 'warning', 'Please fill all mandatory(*) fields.');
            hideLoader();
            setButtonLoader(false);
        }
        else {
            let ReceievdOn= new Date(values[0].ReceivedDate)
            ReceievdOn.setMinutes(ReceievdOn.getMinutes() + 370);
            let EffectDate = new Date(values[0].EffectFromDate)
            EffectDate.setMinutes(EffectDate.getMinutes() + 370);
            let Param = {
                ID: 0
                , BuyerID: values[0].BuyerID
                , BrandID: values[0].BrandID
                , InternalRefID: values[0].InternalRefID
                , BuyerVersionAndDate: values[0].BuyerVersionDate
                // , ReceievdOn: values[0].ReceivedDate
                // , EffectFromDate: values[0].EffectFromDate
                , ReceievdOn: ReceievdOn
                , EffectFromDate: EffectDate
                , CreatedBy: currentUser.employeeinformationID
                , TypBrandMaster: []
                , BrandwiseStandardWithTestType: []
                , BrandwiseStandardInternalResultDetails: []
            }
            //masters
            setAllTabData([]);
            Array.prototype.push.apply(getAllTabData, getFabricTabData);
            Array.prototype.push.apply(getAllTabData, getYarnTabData);
            Array.prototype.push.apply(getAllTabData, getGarmentTabData);
            Array.prototype.push.apply(getAllTabData, getStrikeOffTabData);
            Array.prototype.push.apply(getAllTabData, getTrimsTabData);
            getAllTabData.forEach((ele) => {
                ele.parentFields.forEach(((data, index) => {
                    let createddate= new Date(data.CreatedOn)
                    createddate.setMinutes(createddate.getMinutes() + 370);
                    Param.TypBrandMaster.push({
                        TestType: ele.TestType
                        , TestID: ele.testNameID
                         //, CreatedOn: new Date(data.CreatedOn)
                        , CreatedOn: createddate
                        , Group: data.Group
                        , FabricDetails: data.FabricDetails
                        , BuyerBrandID: 0
                        , ID: 0
                        , FabricIndexID: index
                    });
                }))
            })
            // //standards
            getAllTabData.forEach((ele) => {
                ele.parentFields.forEach(((data, index) => {
                    data.StandardResultRequirement.forEach((standard, standardindex) => {
                        let CreatedOn= new Date(standard.EffectFromDate)
                        CreatedOn.setMinutes(CreatedOn.getMinutes() + 370);
                        let EffectFromDate = new Date(standard.EffectFromDate)
                        EffectFromDate.setMinutes(EffectFromDate.getMinutes() + 370);
                        Param.BrandwiseStandardWithTestType.push({
                            ID: 0
                            //, CreatedOn: new Date(standard.CreatedOn)
                            ,CreatedOn: CreatedOn
                            //, EffectFromDate: new Date(standard.EffectFromDate)
                            ,EffectFromDate: EffectFromDate
                            , Standard: standard.StandardID
                            , GenderID: standard.GenderID.toString()
                            , TestID: ele.testNameID
                            , FabricIndexID: index
                            , StandardIndexID: standardindex
                            , GenderIndexID: standardindex
                            , TestType: ele.TestType
                        });
                    })

                }))
            })
            // //internal data
            getAllTabData.forEach((ele) => {
                ele.parentFields.forEach(((data, dataindex) => {
                    data.StandardResultRequirement.forEach(((standard, standardindex) => {
                        standard.InternalGrid.forEach(((internal, internalindex) => {
                            Param.BrandwiseStandardInternalResultDetails.push({
                                ID: 0
                                , TestID: ele.testNameID
                                , ColorDepth: internal.ColorDepth
                                , Field: internal.Field
                                // , ResultType: internal.ResultType != null ? internal.ResultType.id : 0
                                , ResultType: internal.ResultType != null ? internal.ResultType : 0
                                , MinResult: internal.MinResult.toString()
                                , MaxResult: internal.MaxResult.toString()
                                , FabricIndexID: dataindex
                                , StandardIndexID: standardindex
                                , InternalIndexID: internalindex
                                , TestTypeID: ele.TestType
                            });
                        }))
                    }))

                }))
            });
            // BrandWiseStandardService.InsertBrandWiseStandardMaster(Param).then((res) => {
            //     hideLoader();
            //     var page = "Remove";
            //     // let params = { tabPage: "BrandwiseStandard", Func: "Add" };
            //     //setRedirectConfirmation({ showPopupConfirm: true, Params: params });
            //     setButtonLoader(false);
            //     SearchRetain(page);
            //     let Func = 'Add';
            //     if (res.data.outputResult === "1") {
            //         Func = 'Add';
            //         PageRedirect(Func);
            //     }
            //     else if (res.data.outputResult === "0" || res.data.outputResult === "-1") {
            //         hideLoader();
            //         setButtonLoader(false);
            //         ValidationPopup("Error Occured!");
            //     }
            // }).catch((err) => {
            //     setButtonLoader(false);
            //     hideLoader();
            // });
            // check madatories
            let CheckMandoryFields = [...getAllTabData];
            if (CheckMandoryFields.length < 1) {
                setisParentSaveFailed(true);
                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                setButtonLoader(false);
                hideLoader();
                return false;
            }
            else {
                let FabricData = CheckMandoryFields.filter((data => {
                    return data.TestType === 1;
                }));
                let YarnData = CheckMandoryFields.filter((data => {
                    return data.TestType === 2;
                }))
                let GarmentData = CheckMandoryFields.filter((data => {
                    return data.TestType === 3;
                }))
                let StrikeOffData = CheckMandoryFields.filter((data => {
                    return data.TestType === 4;
                }))
                let TrimsData = CheckMandoryFields.filter((data => {
                    return data.TestType === 5;
                }))
                let IsFabricTabDataExists = getFabricTestNames.filter((data => {
                    if (data.testType.split(" ").join('').toLowerCase() === "fabrictest") {
                        return data;
                    }
                }));
                let IsYarnTabDataExists = getFabricTestNames.filter((data => {
                    if (data.testType.split(" ").join('').toLowerCase() === "yarntest") {
                        return data;
                    }
                }));
                let IsGarmentTabDataExists = getFabricTestNames.filter((data => {
                    if (data.testType.split(" ").join('').toLowerCase() === "garmenttest") {
                        return data;
                    }
                }));
                let IsStrikeOffTabDataExists = getFabricTestNames.filter((data => {
                    if (data.testType.split(" ").join('').toLowerCase() === "strikeofftest") {
                        return data;
                    }
                }));
                let IsTrimsTabDataExists = getFabricTestNames.filter((data => {
                    if (data.testType.split(" ").join('').toLowerCase() === "trimstest") {
                        return data;
                    }
                }));
                if ((FabricData.length <= 0 && IsFabricTabDataExists.length > 0)
                    || (YarnData.length <= 0 && IsYarnTabDataExists.length > 0)
                    || (GarmentData.length <= 0 && IsGarmentTabDataExists.length > 0)
                    || (StrikeOffData.length <= 0 && IsStrikeOffTabDataExists.length > 0)
                    || (TrimsData.length <= 0 && IsTrimsTabDataExists.length > 0)) {
                    if (FabricData.length <= 0 && IsFabricTabDataExists.length > 0) {
                        setisParentSaveFailed(true);
                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Fabric Tab.');
                    }
                    else if (YarnData.length <= 0 && IsYarnTabDataExists.length > 0) {
                        setisParentSaveFailed(true);
                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Yarn Tab.');
                    }
                    else if (GarmentData.length <= 0 && IsGarmentTabDataExists.length > 0) {
                        setisParentSaveFailed(true);
                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Garment Tab.');
                    }
                    else if (StrikeOffData.length <= 0 && IsStrikeOffTabDataExists.length > 0) {
                        setisParentSaveFailed(true);
                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Strike Off Tab.');
                    }
                    else if (TrimsData.length <= 0 && IsTrimsTabDataExists.length > 0) {
                        setisParentSaveFailed(true);
                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Trims Tab.');
                    }
                    //Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setButtonLoader(false);
                    hideLoader();
                    return false;
                }
                else {
                    if (IsFabricTabDataExists.length > 0) {
                        for (var i = 0; i < FabricData[0].parentFields.length; i++) {
                            let FabricDetails = FabricData[0].parentFields;
                            let SandardDetails = FabricData[0].parentFields[i].StandardResultRequirement;
                            if (!FabricDetails[i].Group || !FabricDetails[i].CreatedOn || !FabricDetails[i].FabricDetails) {
                                hideLoader();
                                setisParentSaveFailed(true);
                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Fabric tab.');
                                setButtonLoader(false);
                                return false;
                            }
                            else {
                                for (var j = 0; j < SandardDetails.length; j++) {
                                    let InternalDetails = FabricData[0].parentFields[i].StandardResultRequirement[j].InternalGrid;
                                    if (!SandardDetails[j].CreatedOn || !SandardDetails[j].EffectFromDate || !SandardDetails[j].GenderID
                                        || !SandardDetails[j].StandardID) {
                                        hideLoader();
                                        setisParentSaveFailed(true);
                                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Fabric tab.');
                                        setButtonLoader(false);
                                        return false;
                                    }
                                    else {
                                        for (var k = 0; k < InternalDetails.length; k++) {
                                            if (!InternalDetails[k].ColorDepth || !InternalDetails[k].Field || !InternalDetails[k].ResultType
                                                || !InternalDetails[k].MinResult || ((InternalDetails[k].ResultType !== 3 && InternalDetails[k].ResultType != 4)
                                                    && (!InternalDetails[k].MaxResult))) {
                                                hideLoader();
                                                setisParentSaveFailed(true);
                                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Fabric tab.');
                                                setButtonLoader(false);
                                                return false;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (IsYarnTabDataExists.length > 0) {
                        for (var i = 0; i < YarnData[0].parentFields.length; i++) {
                            let FabricDetails = YarnData[0].parentFields;
                            let SandardDetails = YarnData[0].parentFields[i].StandardResultRequirement;
                            if (!FabricDetails[i].Group || !FabricDetails[i].CreatedOn || !FabricDetails[i].FabricDetails) {
                                hideLoader();
                                setisParentSaveFailed(true);
                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Yarn tab.');
                                setButtonLoader(false);
                                return false;
                            }
                            else {
                                for (var j = 0; j < SandardDetails.length; j++) {
                                    let InternalDetails = YarnData[0].parentFields[i].StandardResultRequirement[j].InternalGrid;
                                    if (!SandardDetails[j].CreatedOn || !SandardDetails[j].EffectFromDate || !SandardDetails[j].GenderID
                                        || !SandardDetails[j].StandardID) {
                                        hideLoader();
                                        setisParentSaveFailed(true);
                                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Yarn tab.');
                                        setButtonLoader(false);
                                        return false;
                                    }
                                    else {
                                        for (var k = 0; k < InternalDetails.length; k++) {
                                            if (!InternalDetails[k].ColorDepth || !InternalDetails[k].Field || !InternalDetails[k].ResultType
                                                || !InternalDetails[k].MinResult || ((InternalDetails[k].ResultType != 3 && InternalDetails[k].ResultType != 4)
                                                    && (!InternalDetails[k].MaxResult))) {
                                                setisParentSaveFailed(true);
                                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Yarn tab.');
                                                setButtonLoader(false);
                                                hideLoader();
                                                return false;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (IsGarmentTabDataExists.length > 0) {
                        for (var i = 0; i < GarmentData[0].parentFields.length; i++) {
                            let FabricDetails = GarmentData[0].parentFields;
                            let SandardDetails = GarmentData[0].parentFields[i].StandardResultRequirement;
                            if (!FabricDetails[i].Group || !FabricDetails[i].CreatedOn || !FabricDetails[i].FabricDetails) {
                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Garment tab.');
                                setisParentSaveFailed(true);
                                setButtonLoader(false);
                                hideLoader();
                                return false;
                            }
                            else {
                                for (var j = 0; j < SandardDetails.length; j++) {
                                    let InternalDetails = GarmentData[0].parentFields[i].StandardResultRequirement[j].InternalGrid;
                                    if (!SandardDetails[j].CreatedOn || !SandardDetails[j].EffectFromDate || !SandardDetails[j].GenderID
                                        || !SandardDetails[j].StandardID) {
                                        setisParentSaveFailed(true);
                                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Garment tab.');
                                        setButtonLoader(false);
                                        hideLoader();
                                        return false;
                                    }
                                    else {
                                        for (var k = 0; k < InternalDetails.length; k++) {
                                            if (!InternalDetails[k].ColorDepth || !InternalDetails[k].Field || !InternalDetails[k].ResultType
                                                || !InternalDetails[k].MinResult || ((InternalDetails[k].ResultType != 3 && InternalDetails[k].ResultType != 4)
                                                    && (!InternalDetails[k].MaxResult))) {
                                                setisParentSaveFailed(true);
                                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Garment tab.');
                                                setButtonLoader(false);
                                                hideLoader();
                                                return false;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (IsStrikeOffTabDataExists.length > 0) {
                        for (var i = 0; i < StrikeOffData[0].parentFields.length; i++) {
                            let FabricDetails = StrikeOffData[0].parentFields;
                            let SandardDetails = StrikeOffData[0].parentFields[i].StandardResultRequirement;
                            if (!FabricDetails[i].Group || !FabricDetails[i].CreatedOn || !FabricDetails[i].FabricDetails) {
                                setisParentSaveFailed(true);
                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Strike Off tab.');
                                setButtonLoader(false);
                                hideLoader();
                                return false;
                            }
                            else {
                                for (var j = 0; j < SandardDetails.length; j++) {
                                    let InternalDetails = StrikeOffData[0].parentFields[i].StandardResultRequirement[j].InternalGrid;
                                    if (!SandardDetails[j].CreatedOn || !SandardDetails[j].EffectFromDate || !SandardDetails[j].GenderID
                                        || !SandardDetails[j].StandardID) {
                                        setisParentSaveFailed(true);
                                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Strike Off tab.');
                                        setButtonLoader(false);
                                        hideLoader();
                                        return false;
                                    }
                                    else {
                                        for (var k = 0; k < InternalDetails.length; k++) {
                                            if (!InternalDetails[k].ColorDepth || !InternalDetails[k].Field || !InternalDetails[k].ResultType
                                                || !InternalDetails[k].MinResult || ((InternalDetails[k].ResultType != 3 && InternalDetails[k].ResultType != 4)
                                                    && (!InternalDetails[k].MaxResult))) {
                                                setisParentSaveFailed(true);
                                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Strike Off tab.');
                                                setButtonLoader(false);
                                                hideLoader();
                                                return false;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (IsTrimsTabDataExists.length > 0) {
                        for (var i = 0; i < TrimsData[0].parentFields.length; i++) {
                            let FabricDetails = TrimsData[0].parentFields;
                            let SandardDetails = TrimsData[0].parentFields[i].StandardResultRequirement;
                            if (!FabricDetails[i].Group || !FabricDetails[i].CreatedOn || !FabricDetails[i].FabricDetails) {
                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Trims tab.');
                                setisParentSaveFailed(true);
                                setButtonLoader(false);
                                hideLoader();
                                return false;
                            }
                            else {
                                for (var j = 0; j < SandardDetails.length; j++) {
                                    let InternalDetails = TrimsData[0].parentFields[i].StandardResultRequirement[j].InternalGrid;
                                    if (!SandardDetails[j].CreatedOn || !SandardDetails[j].EffectFromDate || !SandardDetails[j].GenderID
                                        || !SandardDetails[j].StandardID) {
                                        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Trims tab.');
                                        setisParentSaveFailed(true);
                                        setButtonLoader(false);
                                        hideLoader();
                                        return false;
                                    }
                                    else {
                                        for (var k = 0; k < InternalDetails.length; k++) {
                                            if (!InternalDetails[k].ColorDepth || !InternalDetails[k].Field || !InternalDetails[k].ResultType
                                                || !InternalDetails[k].MinResult || ((InternalDetails[k].ResultType != 3 && InternalDetails[k].ResultType != 4)
                                                    && (!InternalDetails[k].MaxResult))) {
                                                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields in Trims tab.');
                                                setisParentSaveFailed(true);
                                                setButtonLoader(false);
                                                hideLoader();
                                                return false;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    setisParentSaveFailed(false);
                    BrandWiseStandardService.InsertBrandWiseStandardMaster(Param).then((res) => {
                        hideLoader();
                        var page = "Remove";
                        // let params = { tabPage: "BrandwiseStandard", Func: "Add" };
                        //setRedirectConfirmation({ showPopupConfirm: true, Params: params });
                        setButtonLoader(false);
                        SearchRetain(page);
                        let Func = 'Add';
                        if (res.data.outputResult === "1") {
                            Func = 'Add';
                            PageRedirect(Func);
                        }
                        else if (res.data.outputResult === "0" || res.data.outputResult === "-1") {
                            hideLoader();
                            setButtonLoader(false);
                            ValidationPopup("Error Occured!");
                        }
                    }).catch((err) => {
                        setButtonLoader(false);
                        hideLoader();
                    });
                }
            }

        }
    }

    const redirectionConfirmation = (value) => {

    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/BrandWiseStandardList", state: { message: Func } });
    }

    const SubmitFabricDataCallBack = (value) => {
       ;
        value.forEach((ele => {
            ele.TestType = 1;
        }));
        setFabricTabData(value);
    }

    const SubmitYarnDataCallBack = (value) => {
        value.forEach((ele => {
            ele.TestType = 2;
        }));
        setYarnTabData(value);
    }

    const SubmitGarmentDataCallBack = (value) => {
        value.forEach((ele => {
            ele.TestType = 3;
        }));
        setGarmentTabData(value);
    }

    const SubmitStrikeOffDataCallBack = (value) => {
        value.forEach((ele => {
            ele.TestType = 4;
        }));
        setStrikeOffTabData(value);
    }

    const SubmitTrimsDataCallBack = (value) => {
        value.forEach((ele => {
            ele.TestType = 5;
        }));
        setTrimsTabData(value);
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const AddedTestStandardData = (isFieldValueUpdated) => {
        gstIsReloadTestStandardOfParent(isFieldValueUpdated);
    }

    return (
        <>
            <div className="page-body">
                <div className="row">
                    <ReactNotification />
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="widget flat radius-bordered">
                            <div className="widget-header heading_box_style">
                                <h3 className="heading_style_common"> Add Brand Wise Standard</h3>
                                <div className="buttons-preview" align="right">
                                    <span className="btn btn-primary" onClick={() => ResetOperation("Back")} >
                                        <i className="fa fa-arrow-circle-left"></i>
                                        &nbsp;Back
                                    </span>
                                    <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                        <i className="fa fa-times"></i>
                                        &nbsp;Reset
                                    </span>
                                    <button type="button" className="btn btn-success" onClick={InsertBrandWiseStandardMaster} disabled={buttonLoader}>
                                        <i className="fa fa-check right"></i>&nbsp;
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        {
                                            inputFields.map((inputField, index) => (
                                                <Fragment key={
                                                    `${inputField}~${index}`
                                                }>
                                                    <div className="row row_left10">
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Buyer<span className="text-danger">*</span>
                                                                </label>
                                                                <Reactselect className="basic-single" name="BuyerID"
                                                                    id={
                                                                        "BuyerID"
                                                                    }
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    onChange={handleBuyerChange}
                                                                    value={getBuyerOptions.filter(function (option) {
                                                                        return option.value === inputField.BuyerID;
                                                                    })}
                                                                    options={getBuyerOptions}
                                                                    autoFocus
                                                                    styles={submitted && inputField.BuyerID === 0 ? styles : styles1}

                                                                ></Reactselect>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Brand<span className="text-danger">*</span>
                                                                </label>
                                                                <Reactselect className="basic-single" name="BuyerID"
                                                                    id={
                                                                        "BrandID"
                                                                    }
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    onChange={handleBrandChange}
                                                                    value={getBrandOptions != null && getBrandOptions.filter(function (option) {
                                                                        return option.value === inputField.BrandID;
                                                                    })}
                                                                    options={getBrandOptions}
                                                                    styles={submitted && inputField.BrandID === 0 ? styles : styles1}

                                                                ></Reactselect>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Internal Version Ref
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type='test' className='form-control' name="internalref"
                                                                        disabled value={inputField.InternalRefID} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Buyer Version and Date<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right">
                                                                    <input type='test' className='form-control' name="buyerversiondate"
                                                                        onChange={handleTextBoxChange} id="buyerversiondate"
                                                                        style={{ border: submitted && !inputField.BuyerVersionDate ? '1px solid red' : '' }}
                                                                        autoComplete="off" maxLength="200" value={inputField.BuyerVersionDate} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Received Date<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right"
                                                                    style={{ border: submitted && !inputField.ReceivedDate ? '1px solid red' : '' }}>
                                                                    <DatePicker className="form-control" name="ReceivedDate"
                                                                        id={
                                                                            "ReceivedDate"
                                                                        }
                                                                        selected={
                                                                            inputField.ReceivedDate
                                                                        }
                                                                        onChange={
                                                                            event => handleChangeDate(index, event, "ReceivedDate")
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        customInput={<CustomInput />}
                                                                        isClearable={inputField.ReceivedDate === null ? false : true}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 divder_style">
                                                            <div className="form-group">
                                                                <label>Effect From Date<span className="text-danger">*</span>
                                                                </label>
                                                                <span className="input-icon icon-right"
                                                                    style={{ border: submitted && !inputField.EffectFromDate ? '1px solid red' : '' }}>
                                                                    <DatePicker className="form-control" name="ReceivedDate"
                                                                        id={
                                                                            "EffectFromDate"
                                                                        }
                                                                        selected={
                                                                            inputField.EffectFromDate
                                                                        }
                                                                        onChange={
                                                                            event => handleChangeDate(index, event, "EffectFromDate")
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        minDate={getMaxEffectFromDate}
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        customInput={<CustomInput />}
                                                                        isClearable={inputField.EffectFromDate === null ? false : true}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))}
                                    </form>
                                    <div className='col-md-12' style={{ marginTop: '15px' }}>
                                        <div className="tabbable" style={{ width: "100%" }}>
                                            <ul className="nav nav-tabs nav-justified" id="myTab5" >
                                                <li className="active" id="liFabric" style={{ zIndex: '0' }}>
                                                    <a data-toggle="tab" href="#idFabric" id="aidfabric" onClick={() => TabRedirection('FABRIC')}>
                                                        <h5>FABRIC</h5>
                                                    </a>
                                                </li>
                                                <li className="" id="liYarn" >
                                                    <a data-toggle="tab" href="#idYarn" id="aidyarn" style={{ zIndex: '0' }} onClick={() => TabRedirection('YARN')}>
                                                        <h5>YARN</h5>
                                                    </a>
                                                </li>

                                                <li className="" id="liGarment">
                                                    <a data-toggle="tab" href="#idGarment" id="aidgarment" style={{ zIndex: '0' }} onClick={() => TabRedirection('GARMENT')}>
                                                        <h5>GARMENT</h5>
                                                    </a>
                                                </li>
                                                <li className="" id="liStikrOff">
                                                    <a data-toggle="tab" href="#idStrikeOff" id="aidstrikeoff" style={{ zIndex: '0' }} onClick={() => TabRedirection('STRIKE OFF')}>
                                                        <h5>STRIKE OFF</h5>
                                                    </a>
                                                </li>
                                                <li className="" id="liTrims">
                                                    <a data-toggle="tab" href="#idTrims" id="aidtrims" style={{ zIndex: '0' }} onClick={() => TabRedirection('TRIMS')}>
                                                        <h5>TRIMS</h5>
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content Dashboard" style={{ padding: "0px", marginBottom: '20px' }}>
                                                <div id="idFabric" className="active tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    {getCurrentTab === "FABRIC" && <BrandStandardFabric props={props} GenderOptions={getGenderOptions}
                                                        ColorDepthOptions={getColorDepthOptions} FieldOptions={getFieldOptions}
                                                        ResultReqTypeOptions={getResultReqType} GroupTypeOptions={getGroupTypeFabricOptions}
                                                        ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} FabricTestNameList={getFabricTestNames}
                                                        StandardOptions={getStandardOptions}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack}
                                                        SubmitFabricDataCallBack={SubmitFabricDataCallBack} BuyerID={getCurrentBuyerID}
                                                        BrandID={getCurrentBrandID} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed}></BrandStandardFabric>}
                                                </div>
                                                <div id="idYarn" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    {getCurrentTab === "YARN" && <BrandStandardYarn props={props} GenderOptions={getGenderOptions}
                                                        ColorDepthOptions={getColorDepthOptions} FieldOptions={getFieldOptions}
                                                        ResultReqTypeOptions={getResultReqType} GroupTypeOptions={getGroupTypeFabricOptions}
                                                        YarnTestNameList={getFabricTestNames} StandardOptions={getStandardOptions}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack}
                                                        ColorDepthParentCall={AddedColorDepthData} TestFieldParentCall={AddedTestFieldData}
                                                        SubmitYarnDataCallBack={SubmitYarnDataCallBack} BuyerID={getCurrentBuyerID}
                                                        BrandID={getCurrentBrandID} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed}></BrandStandardYarn>}
                                                </div>
                                                <div id="idGarment" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    {getCurrentTab === "GARMENT" && <BrandStandardGarment props={props} GarmentTestNameList={getFabricTestNames}
                                                        GroupTypeOptions={getGroupTypeFabricOptions} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions} ResultReqTypeOptions={getResultReqType}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} SubmitGarmentDataCallBack={SubmitGarmentDataCallBack}
                                                        BuyerID={getCurrentBuyerID}
                                                        BrandID={getCurrentBrandID} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed}></BrandStandardGarment>}
                                                </div>
                                                <div id="idStrikeOff" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    {getCurrentTab === "STRIKE OFF" && <BrandStandardStrikeOff props={props} StrikeOffTestNameList={getFabricTestNames}
                                                        GroupTypeOptions={getGroupTypeFabricOptions} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions} ResultReqTypeOptions={getResultReqType}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} SubmitStrikeOffDataCallBack={SubmitStrikeOffDataCallBack}
                                                        BuyerID={getCurrentBuyerID}
                                                        BrandID={getCurrentBrandID} TestStandardParentCall={AddedTestStandardData}
                                                        IsSaveFailed={getisParentSaveFailed}></BrandStandardStrikeOff>}
                                                </div>
                                                <div id="idTrims" className="tab-pane col-lg-12" style={{ padding: "0px" }} >
                                                    {getCurrentTab === "TRIMS" && <BrandStandardTrims props={props} TrimsNameList={getFabricTestNames}
                                                        GroupTypeOptions={getGroupTypeFabricOptions} StandardOptions={getStandardOptions}
                                                        GenderOptions={getGenderOptions} ColorDepthOptions={getColorDepthOptions}
                                                        FieldOptions={getFieldOptions} ResultReqTypeOptions={getResultReqType}
                                                        StatusUpdationCallBack={ParentStatusUpdationCallBack} ColorDepthParentCall={AddedColorDepthData}
                                                        TestFieldParentCall={AddedTestFieldData} SubmitTrimsDataCallBack={SubmitTrimsDataCallBack}
                                                        BuyerID={getCurrentBuyerID}
                                                        BrandID={getCurrentBrandID} TestStandardParentCall={AddedTestStandardData} IsSaveFailed={getisParentSaveFailed}></BrandStandardTrims>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="" style={{ marginTop: '10px' }}>
                                        <div className="buttons-preview" style={{ marginTop: '10px' }} align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")} >
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" onClick={InsertBrandWiseStandardMaster} disabled={buttonLoader}>
                                                <i className="fa fa-check right"></i>&nbsp;
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                getRedirectConfirmation.showPopupConfirm ? <ConfirmationPopup props={props} data={
                                    getRedirectConfirmation.Params
                                }
                                    deleteCallback={redirectionConfirmation}
                                    showpopup={true}
                                /> : null
                            }
                        </div>
                    </div>
                </div>
            </div >
            {loader}
        </>
    )
}
export default BrandWiseStandardRegister;